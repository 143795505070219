import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { AppDispatch, RootStateType } from '@b2d/redux/types';
import { Typography, List, ListItem, Box } from '@mui/material';
import { CenterCircularProgress, NoDataCard } from '@europrocurement/flexy-components';
import {
    DEF_FREQUENCE_SLICE_NAME,
    DefFrequence,
} from '@europrocurement/l2d-domain/reducers/achats/slices/defFrequenceSlice';
import { RubriqueFacturation, TauxTva } from '@europrocurement/l2d-domain';
import { getRubFac, getTauxTva, getDefFrequence } from '../../redux/RootStore';

const AchatsRoot: React.FunctionComponent = function () {
    const dispatch = useDispatch<AppDispatch>();

    const rubFactsDataSource: DataSource<RubriqueFacturation> = useSelector(
        (s: RootStateType) => s.dossiers.rubfac.main,
    );

    const tauxTvaDatasource: DataSource<TauxTva> = useSelector(
        (s: RootStateType) => s.dossiers.txtva.main,
    );

    const defFrequenceDatasource: DataSource<DefFrequence> = useSelector(
        (s: RootStateType) => s.achats[DEF_FREQUENCE_SLICE_NAME].main,
    );

    const optionsToLoadAtStartUp = useMemo(
        () => [
            { dataSource: tauxTvaDatasource, action: getTauxTva },
            { dataSource: rubFactsDataSource, action: getRubFac },
            { dataSource: defFrequenceDatasource, action: getDefFrequence },
        ],
        [defFrequenceDatasource, rubFactsDataSource, tauxTvaDatasource],
    );

    const loadInitialOptions = useCallback(() => {
        optionsToLoadAtStartUp.forEach(({ dataSource, action }) => {
            const { status } = dataSource;
            if (status === 'idle') {
                dispatch(action({}));
            }
        });
    }, [dispatch, optionsToLoadAtStartUp]);

    const optionsStatusMap = useMemo(
        () =>
            optionsToLoadAtStartUp.reduce(
                (acc, { dataSource }) => {
                    const { slicename, status } = dataSource;
                    acc[slicename] = status;
                    return acc;
                },
                {} as Record<string, string>,
            ),
        [optionsToLoadAtStartUp],
    );

    const allOptionsAreLoaded = useMemo(
        () => Object.values(optionsStatusMap).every((status) => status === 'succeeded'),
        [optionsStatusMap],
    );

    const failingOptions = useMemo(
        () =>
            Object.entries(optionsStatusMap)
                .filter(([, status]) => status === 'failed')
                .map(([name]) => name),
        [optionsStatusMap],
    );

    useEffect(() => {
        loadInitialOptions();
    }, [loadInitialOptions]);

    if (failingOptions.length > 0) {
        const additionalData: ReactNode = (
            <>
                <Typography variant="h6">Sources de données en erreur :</Typography>
                <List>
                    {failingOptions.map((option) => (
                        <ListItem
                            key={option}
                            sx={{ fontWeight: 'bold' }}
                        >
                            {option}
                        </ListItem>
                    ))}
                </List>
            </>
        );

        return (
            <Box height="80vh">
                <NoDataCard additionalData={additionalData} />
            </Box>
        );
    }

    if (!allOptionsAreLoaded) {
        return <CenterCircularProgress />;
    }

    return (
        <KeycloakRoute roles="api-tiers:tiers-read">
            <Outlet />
        </KeycloakRoute>
    );
};

export default AchatsRoot;
