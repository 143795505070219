/**
 * Centralize common wordings
 */

const checkConsole = 'Veuillez consulter la console pour obtenir plus de détails.';

const refreshPage = 'Veuillez rafraîchir la page.';

const retryLater = 'Veuillez réessayer plus tard.';

const contactSupport = 'Si le problème persiste, veuillez contacter le support.';

const genericError = 'Un problème est survenu.';

const waitOrSupport = `${retryLater} ${contactSupport}`;

const visibilityTranslation = {
    FULL: 'Complète',
    PARTIAL: 'Partielle',
};

export {
    visibilityTranslation,
    checkConsole,
    refreshPage,
    retryLater,
    contactSupport,
    genericError,
    waitOrSupport,
};
