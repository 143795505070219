import * as React from 'react';
import { FieldValues } from 'react-hook-form';
import { Box } from '@mui/system';
import useMultiStep, { FormStep } from './useMultiStep';
import MultiStepFormHeader from './MultiStepFormHeader';
import { MultiStepContextProps } from './types';
import MultiStepFormFooter from './MultiStepFormFooter';
import { ModelForm } from '../../../forms/types';

export type MultiStepFormProps = {
    steps: FormStep<MultiStepContextProps>[];
    initiaValues: FieldValues;
    submitAction: (fieldValues: FieldValues) => void;
    isCreate?: boolean;
} & Pick<ModelForm<unknown>, 'afterSubmit' | 'isCreate'>;

const MultiStepForm: React.FunctionComponent<MultiStepFormProps> = function ({
    steps,
    initiaValues,
    submitAction,
    isCreate = true,
}) {
    const multiStepForm = useMultiStep(steps, initiaValues, submitAction);
    const {
        currentStep: { step },
    } = multiStepForm;

    const { component: CurrentStep } = step;
    const multiStepProps = { steps, multiStepForm, submitAction, isCreate };

    return (
        <Box
            minWidth="50vw"
            minHeight="80vh"
            display="flex"
            flexDirection="column"
        >
            <MultiStepFormHeader {...multiStepProps} />
            <CurrentStep {...multiStepProps} />
            <MultiStepFormFooter {...multiStepProps} />
        </Box>
    );
};

export default MultiStepForm;
