import React, { useState } from 'react';
import { Grid, GridProps, Input, InputProps } from '@mui/material';
import { FlexySelect, OptionType } from '../../atoms';
import getDayMonthPickerValue from './getDayMonthPickerValue';

export type DayMonthPickerProps = {
    dayplaceholder?: string;
    monthplaceholder?: string;
    gridProps?: GridProps;
    onChange?: (value: string) => void;
} & Omit<InputProps, 'onChange'>;

const days = Array.from({ length: 31 }, (_, i) => ({
    label: `${String(i + 1).padStart(2, '0')}`,
    value: `${String(i + 1).padStart(2, '0')}`,
}));

const months = Array.from({ length: 12 }, (_, i) => ({
    label: `${String(i + 1).padStart(2, '0')}`,
    value: `${String(i + 1).padStart(2, '0')}`,
}));

const DayMonthPicker: React.FunctionComponent<DayMonthPickerProps> = function ({
    dayplaceholder = 'Jour',
    monthplaceholder = 'Mois',
    defaultValue,
    value,
    onChange,
    gridProps = { spacing: 1 },
    size,
    ...inputProps
}: DayMonthPickerProps) {
    const [stringDay, stringMonth] = getDayMonthPickerValue(defaultValue, value);

    const day: OptionType = {
        label: stringDay,
        value: stringDay,
    };

    const month: OptionType = {
        label: stringMonth,
        value: stringMonth,
    };

    const [daySelected, setDaySelected] = useState<OptionType>(day);
    const [monthSelected, setMonthSelected] = useState<OptionType>(month);

    const [innerValue, setInnerValue] = useState<string>(
        `${daySelected.value}/${monthSelected.value}`,
    );

    React.useEffect(() => {
        const newValue = `${daySelected.value}/${monthSelected.value}`;
        setInnerValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    }, [daySelected.value, monthSelected.value, onChange]);

    const dayPickerHandler = (dayOption: OptionType) => {
        setDaySelected(dayOption);
    };

    const monthPickerHandler = (monthOption: OptionType) => {
        setMonthSelected(monthOption);
    };

    return (
        <>
            <Grid
                container
                {...gridProps}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    xl={6}
                >
                    <FlexySelect
                        name={`${inputProps.name}_InnerDay`}
                        options={days}
                        value={daySelected}
                        placeholder={dayplaceholder}
                        onChange={dayPickerHandler}
                        size={size}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    xl={6}
                >
                    <FlexySelect
                        name={`${inputProps.name}_InnerMonth`}
                        options={months}
                        value={monthSelected}
                        placeholder={monthplaceholder}
                        onChange={monthPickerHandler}
                        size={size}
                    />
                </Grid>
            </Grid>
            <Input
                sx={{ display: 'none' }}
                value={innerValue}
                {...inputProps}
                type="hidden"
            />
        </>
    );
};

export default DayMonthPicker;
