import React from 'react';
import { BooleanBadge, FlexySelect, OptionType } from '@europrocurement/flexy-components';
import { Card, Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { User } from '../UserTabs/type';

export type UserG3Props = {
    user: User;
    handlePatchData: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
    ) => void;
    handleValidationModal: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => void;
    marques: Array<OptionType>;
};

export const UserG3: React.FunctionComponent<UserG3Props> = function (props) {
    const theme = useTheme();
    const { handlePatchData, user, marques, handleValidationModal } = props;

    const rights = [
        { field: 'hasG3Access', label: 'Accès G3', value: user.hasG3Access || false },
        { field: 'isEleveur', label: 'Eleveur', value: user.isEleveur || false },
        {
            field: 'isG3Recetteur',
            label: 'Recetteur',
            value: user.isG3Recetteur || false,
        },
    ];

    /**
     * If the data selected has changed : send the new to the API (patch)
     * Note : marques is a relation, so the option value is format into IRI
     * @param option the selected option values
     */
    const handleSelect = (option: OptionType) => {
        if (option.value !== user.marque?.idMarque) {
            handlePatchData({ marque: `/utilisateur/marques/${option.value}` });
        }
    };

    return (
        <Card
            sx={{
                width: '100%',
                margin: '15px 0',
                padding: '15px',
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Typography
                variant="l2dh6"
                sx={{
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                    padding: '5px 0 5px 30px',
                    marginLeft: '-15px',
                }}
            >
                Application G3
            </Typography>
            <Grid
                sx={{
                    display: 'flex',
                    width: '100%',
                    margin: '15px 0 10px 10px',
                    gap: '15px',
                    alignItems: 'center',
                }}
            >
                {rights.map(({ field, label, value }) => (
                    <BooleanBadge
                        key={field}
                        label={label}
                        value={value}
                        icon
                        toggleAction={() =>
                            handleValidationModal({ [field]: !value }, field, value)
                        }
                    />
                ))}
                <Box
                    display="flex"
                    sx={{ alignItems: 'center', gap: '10px', width: '50%' }}
                    data-testid="marque"
                >
                    <Typography variant="l2dh7">Marque : </Typography>

                    <FlexySelect
                        placeholder="Choisir une marque"
                        value={marques.find((option) => option.value === user.marque?.idMarque)}
                        options={marques}
                        onChange={(option: OptionType) => handleSelect(option)}
                    />
                </Box>
            </Grid>
        </Card>
    );
};
