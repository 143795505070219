import React from 'react';
import { Box, SxProps } from '@mui/material';

type Props = { sx?: SxProps };

const HorizontalDivider: React.FunctionComponent<Props> = function ({ sx }) {
    const dividerStyle = {
        minWidth: '15px',
        width: '100%',
        minHeight: '1px',
        backgroundColor: 'gray',
        opacity: 0.2,
        ...sx,
    };
    return (
        <Box
            sx={dividerStyle}
            data-testid="HorizontalDivider-test-id"
        />
    );
};

export default HorizontalDivider;
