import {
    ObjectGroupsCreate,
    ObjectGroupFormData,
    ObjectGroupColor,
} from '@europrocurement/flexy-components';
import React from 'react';

export type CreateDossierGroupProps = {
    colors: Array<ObjectGroupColor>;
    onSubmit: (newGroup: ObjectGroupFormData) => void;
    onCancel: () => void;
};

const CreateDossierGroup: React.FunctionComponent<CreateDossierGroupProps> = function (props) {
    const { colors, onSubmit: handleSubmit, onCancel: handleCancel } = props;

    return (
        <ObjectGroupsCreate
            title="Créer un groupe de dossiers"
            fieldLabel="Nom"
            submitButtonLabel="Créer un groupe"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            colors={colors}
        />
    );
};

export default CreateDossierGroup;
