import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import DossierRoutingContext from './DossierRoutingContext';

export type DossierRoutingProviderProps = {
    orderBasePath: string;
    dossierBasePath: string;
};

const DossierRoutingProvider: React.FunctionComponent<
    PropsWithChildren<DossierRoutingProviderProps>
> = function ({ orderBasePath, dossierBasePath, children }) {
    const getOrderRoute = useCallback(
        (id: number): string => `/${orderBasePath}/${id}`,
        [orderBasePath],
    );
    const getDossierRoute = useCallback(
        (id: number): string => `/${dossierBasePath}/${id}`,
        [dossierBasePath],
    );
    const getDossiersListeRoute = useCallback(
        (): string => `/${dossierBasePath}`,
        [dossierBasePath],
    );

    const contextValue = useMemo(
        () => ({ getOrderRoute, getDossierRoute, getDossiersListeRoute }),
        [getOrderRoute, getDossierRoute, getDossiersListeRoute],
    );

    return (
        <DossierRoutingContext.Provider value={contextValue}>
            {children}
        </DossierRoutingContext.Provider>
    );
};

export default DossierRoutingProvider;
