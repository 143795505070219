import React from 'react';

import Typography from '@mui/material/Typography';
import { Box, SxProps, TypographyProps } from '@mui/material';

export type PriceLigneProps = {
    label: React.ReactNode;
    price: React.ReactNode;
    labelTypographyProps?: TypographyProps;
    priceTypographyProps?: TypographyProps;
    sx?: SxProps;
};

const PriceLigne: React.FunctionComponent<PriceLigneProps> = function ({
    label,
    price,
    labelTypographyProps,
    priceTypographyProps,
    sx,
}) {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                paddingRight: '30px',
                width: '100%',
                paddingTop: '4px',
                paddingBottom: '4px',
                lineHeight: '24px',
                ...sx,
            }}
        >
            <Typography
                {...{ variant: 'l2dbodys', color: 'text.gris70' }}
                {...labelTypographyProps}
            >
                {label}
            </Typography>
            <Typography
                {...{ variant: 'l2dbodys', color: 'text.gris70' }}
                {...priceTypographyProps}
            >
                {price}
            </Typography>
        </Box>
    );
};

export default PriceLigne;
