import React, { ReactNode, useState } from 'react';

import { Box, Table, TableContainer, SxProps, useTheme, useMediaQuery } from '@mui/material';

import { Scrollbar } from '@europrocurement/flexy-components';

import type {
    EuroprocApiResponseStatus,
    Pagination as PaginationType,
} from '@europrocurement/l2d-redux-utils';
import { ColumnDatatable } from '../ColumnDatatable';
import FlexyFilters from '../FlexyFilters/FlexyFilters';
import { SearchOpts } from '../DatatableHeader';
import FlexyDatatableHeader, {
    FlexyDatatableHeaderProps,
} from '../FlexyDatatableHeader/FlexyDatatableHeader';
import { FlexyDatatablePagination } from '../FlexyDatatablePagination';
import FlexyDatatableMobileSort from '../FlexyDatatableMobileSort';
import { FlexyDatatableBodyLoading } from '../FlexyDatatableBodyLoading';
import { FlexyDatatableBodyIdle } from '../FlexyDatatableBodyIdle';
import { FlexyDatatableBodyFailed } from '../FlexyDatatableBodyFailed';
import {
    FlexyDatatableBodySucceded,
    FlexyDatatableBodySuccededProps,
} from '../FlexyDatatableBodySucceded';
import { FlexyDatatableBodySuccededRowProps } from '../FlexyDatatableBodySuccededRow';
import { RowSelectionAction } from '../FlexyDatatableSelectionAction';

export type DatatablePagination = PaginationType & {
    onPageChange: (page: number) => void;
    onItemsPerPageChange: (itemsPerPage: number) => void;
};

export type FlexyDatatableProps<T extends Record<string, unknown>> = {
    status: EuroprocApiResponseStatus;
    setColumns?: React.Dispatch<React.SetStateAction<ColumnDatatable<T>[]>>;
    columnLabelVariant?: FlexyDatatableHeaderProps<T>['columnLabelVariant'];
    pagination?: DatatablePagination;
    searchOpts?: SearchOpts;
    filtersControl?: boolean;
    sx?: SxProps;
    computeSxRow?: FlexyDatatableBodySuccededRowProps<T>['computeSxRow'];
    hideColumnOptions?: boolean;
    rowsActions?: Array<RowSelectionAction>;
    buttonAction?: ReactNode;
} & FlexyDatatableBodySuccededProps<T>;

const FlexyDatatableTable = function <T extends Record<string, unknown>>({
    status,
    setColumns,
    columnLabelVariant,
    pagination,
    searchOpts,
    // handleExport,
    filtersControl = true,
    hideColumnOptions = false,
    sx = {},
    rowsActions = [],
    buttonAction,
    ...succeededProps
}: FlexyDatatableProps<T>) {
    const { onPageChange, onItemsPerPageChange, itemsPerPage } = {
        ...pagination,
    } as DatatablePagination;

    const theme = useTheme();
    const { breakpoints } = theme;
    const downSmBreakpoint = useMediaQuery(breakpoints.down('sm'));
    const [sortingAnchorEl, setSortingAnchorEl] = useState<null | HTMLElement>(null);
    const handleHeaderClick = (event: React.MouseEvent<HTMLElement>) => {
        setSortingAnchorEl(event.currentTarget);
    };

    const mobilePopover = downSmBreakpoint ? (
        <FlexyDatatableMobileSort
            columns={succeededProps.columns}
            orderOpts={searchOpts?.ordersOpts}
            anchorEl={sortingAnchorEl}
            onClose={() => setSortingAnchorEl(null)}
        />
    ) : null;

    const paginationRef = React.useRef<HTMLDivElement>(null);
    const filterRef = React.useRef<HTMLDivElement>(null);
    const succeededRowProps = {
        ...succeededProps,
        rowsActions,
    };
    return (
        <Box
            sx={{
                width: '100%',
                maxHeight: '100%',
            }}
        >
            {filtersControl ? (
                <Box ref={filterRef}>
                    <FlexyFilters
                        searchOpts={searchOpts}
                        buttonAction={buttonAction}
                    />
                </Box>
            ) : null}
            {mobilePopover}
            <TableContainer
                sx={{
                    overflow: 'hidden',
                    maxHeight: `calc(100% - ${
                        (paginationRef?.current && paginationRef?.current.clientHeight) || 52
                    }px - ${(filterRef?.current && filterRef?.current.clientHeight) || 0}px )`,
                    ...sx,
                }}
            >
                <Scrollbar style={{ width: '100%' }}>
                    <Table
                        stickyHeader
                        aria-label="simple table"
                        sx={{
                            whiteSpace: 'nowrap',
                            border: 'solid 1px',
                            borderColor: theme.palette.grey[300],
                            borderRadius: '12px',
                            backgroundColor: 'background.paper',
                        }}
                    >
                        <FlexyDatatableHeader
                            columns={succeededProps.columns}
                            searchOpts={searchOpts}
                            columnLabelVariant={columnLabelVariant}
                            hideColumnOptions={hideColumnOptions}
                            setColumns={setColumns}
                            itemsPerPage={Math.min(itemsPerPage, succeededProps.data.length)}
                            rowsActions={rowsActions}
                            onClick={handleHeaderClick}
                        />
                        {/* Switch selon le status */}
                        {
                            {
                                succeeded: <FlexyDatatableBodySucceded {...succeededRowProps} />,
                                loading: <FlexyDatatableBodyLoading />,
                                idle: <FlexyDatatableBodyIdle />,
                                failed: <FlexyDatatableBodyFailed />,
                            }[status]
                        }
                    </Table>
                </Scrollbar>
            </TableContainer>
            {pagination && status === 'succeeded' ? (
                <Box data-testid="DatatablePagination">
                    <FlexyDatatablePagination
                        page={pagination.page}
                        rowsPerPage={itemsPerPage}
                        count={pagination.total || 0}
                        onRowsPerPageChange={onItemsPerPageChange}
                        onPageChange={onPageChange}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

export default FlexyDatatableTable;
