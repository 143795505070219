import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as TiersConfigurationParameters,
    Configuration,
    ObjectGroupApi,
} from '../../../openApi/ApiTiers';
import { OBJECT_GROUP_SLICE_NAME, TIERS_REDUCER_NAME } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ObjectGroupApiObject = any & Record<string, unknown>;

export type ObjectGroup = ObjectGroupApiObject;

const mapperObjectGroups = function (objectGroup: ObjectGroupApiObject) {
    return {
        ...objectGroup,
    } as ObjectGroup;
};

export function createObjectGroupSlice(configuration: TiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new ObjectGroupApi(conf);

    const fetchObjectGroupCollection: FetchCollectionData<ObjectGroupApiObject> = ({
        pagination,
        xIdSociete,
    }) =>
        api
            .searchObjectGroupByTypeObjectGroupCollection({
                type: 'sousClient',
                xIdSociete,
                page: pagination.page + 1,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<ObjectGroupApiObject>>;

    const fetchObjectGroupItem: FetchItemDataType<ObjectGroupApiObject> = () =>
        new Promise(() => {
            console.error('fetchObjectGroupItem called but should not');
        });

    const { slice: objectGroupSlice, dataSourcesThunks: objectGroupDataSourcesThunks } =
        SliceFactory.createSlice<ObjectGroupApiObject, ObjectGroup>(
            OBJECT_GROUP_SLICE_NAME,
            TIERS_REDUCER_NAME,
            [],
            fetchObjectGroupCollection,
            fetchObjectGroupItem,
            mapperObjectGroups,
        );

    return { objectGroupSlice, objectGroupDataSourcesThunks };
}
