import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { FormStructure } from '@europrocurement/flexy-form';
import {
    SectionHeadControlProps,
    UseHeaderSectionStructureProps,
} from '@b2d/pages/Achats/components/forms/formElements/types';
import {
    defaultProduits,
    replaceProduits,
} from '@b2d/pages/Achats/components/forms/functions/produitFunctions';
import { resetFournisseursFields } from '@b2d/pages/Achats/components/forms/functions/fournisseurFunctions';
import useHandleStatement from '@b2d/pages/Achats/components/forms/formElements/utils/useHandleStatement';
import { headerStructure } from '@b2d/pages/Achats/components/forms/formElements/fragments/headerSection';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { AppDispatch } from '@b2d/redux/types';
import { HeaderSwitch } from '@europrocurement/flexy-components';
import useDomain from '@b2d/hooks/useDomain';

const useHeaderSectionStructure = ({
    formContext,
    handleUpdateAvoir,
    mode = { type: 'register', nature: 'simple' },
    mediaObject,
    achatsFromStatementDataSource,
    isInterne,
    facture,
    headerSwitches,
    headerChildren,
}: UseHeaderSectionStructureProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { setDomainForced } = useDomain();

    const {
        addStatementButton,
        accessStatementButton,
        fetchInvoicesFromStatement,
        stateHandleStatement,
    } = useHandleStatement({
        mode,
        facture,
        mediaObject,
        achatsFromStatementDataSource,
    });

    const handleSwitchFormalityPublication = useCallback(
        (switchValue: boolean) => {
            resetFournisseursFields(formContext);
            if (switchValue) {
                // TODO: il faut pouvoir effacer les autocomplete de l'autre section dossier à ce moment là ;
                dispatch({ type: ACTIONS.RESET });
                setDomainForced(3);
                replaceProduits(formContext, defaultProduits(3, false));
            } else {
                // TODO: puis effacer l'autocomplete de l'autre section dossier ici.
                dispatch({ type: ACTIONS.RESET });
                replaceProduits(formContext, defaultProduits(1, false));
            }

            fetchInvoicesFromStatement();
        },
        [dispatch, formContext, fetchInvoicesFromStatement, setDomainForced],
    );

    const overwriteHeaderSwitches = useMemo<Array<HeaderSwitch>>(() => {
        let temporaryHeaderSwitches: Array<HeaderSwitch> = [];

        if (headerSwitches) {
            temporaryHeaderSwitches = [...temporaryHeaderSwitches, ...headerSwitches];
        }

        return temporaryHeaderSwitches;
    }, [headerSwitches]);

    const overwriteHeaderChildren = useMemo<Array<SectionHeadControlProps>>(() => {
        let temporaryHeaderChildren: Array<SectionHeadControlProps> = [];

        if (Array.isArray(headerChildren)) {
            temporaryHeaderChildren = [...temporaryHeaderChildren, ...headerChildren];
        } else {
            temporaryHeaderChildren.push(headerChildren);
        }

        temporaryHeaderChildren.push(addStatementButton);
        temporaryHeaderChildren.push(accessStatementButton);

        return temporaryHeaderChildren;
    }, [accessStatementButton, addStatementButton, headerChildren]);

    const headerSectionStructure = useMemo<Array<FormStructure>>(
        () =>
            headerStructure({
                onUpdateAvoir: mode.nature === 'simple' ? handleUpdateAvoir : undefined,
                onUpdateDomaine:
                    mode.type === 'register' && mode.nature === 'simple'
                        ? handleSwitchFormalityPublication
                        : undefined,
                mode,
                headerSwitches: overwriteHeaderSwitches,
                headerChildren: overwriteHeaderChildren,
                isInterne,
            }),
        [
            handleUpdateAvoir,
            mode,
            handleSwitchFormalityPublication,
            overwriteHeaderSwitches,
            overwriteHeaderChildren,
            isInterne,
        ],
    );

    return {
        headerSectionStructure,
        stateHeaderSectionStructure: stateHandleStatement,
    };
};

export default useHeaderSectionStructure;
