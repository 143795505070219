import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import {
    ColumnDatatable,
    FilterDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import {
    ExceptionFacturante,
    ExceptionFacturanteApiObject,
} from '@europrocurement/l2d-domain/reducers/achats/slices/exceptionFacturante';
import { usePatchExceptionFacturante } from '@b2d/hooks/exceptionFacturanteHooks';
import ExceptionFacturanteSwitchesGroup from '../fragments/ExceptionFacturanteSwitchesGroup';

export type ExceptionsFacturantesListComponentProps = {
    dataSource: DataSource<ExceptionFacturante>;
    fetchData: getDataThunkType<ExceptionFacturanteApiObject>;
    filtersControl?: boolean;
    filterFilters?: (
        filtre: FilterDatatable,
        index: number,
        array: FiltersDatatableList,
    ) => unknown;
    disabledToggles?: Partial<Record<'manuel' | 'bloquant' | 'relanceAuto', boolean>>;
};

const ExceptionsFacturantesListComponent: React.FunctionComponent<ExceptionsFacturantesListComponentProps> =
    function ({
        dataSource,
        fetchData,
        filtersControl = true,
        filterFilters = () => true,
        disabledToggles,
    }) {
        const navigate = useNavigate();

        if (!!filtersControl === true) {
            console.log(filtersControl, filterFilters);
        }

        const patchExceptionFacturante = usePatchExceptionFacturante();

        const columns: ColumnDatatable<ExceptionFacturante>[] = React.useMemo(
            () => [
                {
                    label: 'Id',
                    isDisplayed: true,
                    render: ({ id }) => id,
                    field: 'id',
                },
                {
                    label: 'Code Rejet',
                    isDisplayed: true,
                    render: ({ codeRejet }) => codeRejet?.split('/')[3],
                    field: 'codeRejet',
                },
                {
                    label: 'Configuration',
                    isDisplayed: true,
                    onClickCell: () => {},
                    render: (exceptionFacturante) => (
                        <ExceptionFacturanteSwitchesGroup
                            fetchData={fetchData}
                            exceptionFacturante={exceptionFacturante}
                            patchExceptionFacturante={patchExceptionFacturante}
                            disabledToggles={disabledToggles}
                        />
                    ),
                },
                {
                    label: 'Actions',
                    render: () => null,
                    isDisplayed: true,
                },
            ],
            [disabledToggles, fetchData, patchExceptionFacturante],
        );

        const filters: FiltersDatatableList = [];

        return (
            <StoreDatatable
                dataSource={dataSource}
                columns={columns}
                fetchData={fetchData}
                filters={filters}
                localStorageKey="FlexyStoreDatatableRejectCodes"
                localStorageRefreshDate={new Date('2023-06-23T09:42:00.000Z')}
                fetchWithoutFilter
                onClickRow={(e: React.MouseEvent<HTMLElement>, item: ExceptionFacturante) =>
                    navigate(`${item.code}`, {
                        replace: true,
                    })
                }
                onWheelClickRow={(e: React.MouseEvent<HTMLElement>, item: ExceptionFacturante) =>
                    navigate(`${item.code}`, {
                        replace: true,
                    })
                }
            />
        );
    };

export default ExceptionsFacturantesListComponent;
