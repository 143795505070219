import {
    OffresJsonldOffreRead,
    OffresOffreCreate,
    OffresOffreUpdate,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { OfferSchema } from './schema';

/** API -> Form -> API Serialization */

export const input: (entity?: OffresJsonldOffreRead) => OfferSchema = (entity = undefined) => {
    const defaultValues = {
        label: '',
        visibility: 'FULL' as const,
        domainId: null as unknown as number,
        iconeName: '',
        calculCombineId: null as unknown as number,
        calculMultipleId: null as unknown as number,
    };

    if (!entity) {
        return defaultValues;
    }

    // Field renaming by aliasing destructured api object
    const {
        libelle: label,
        visibilite: visibility,
        domaine: domain,
        iconeName,
        calculCombine,
        calculMultiple,
    } = entity;

    return {
        label: label ?? defaultValues.label,
        visibility: visibility ?? defaultValues.visibility,
        domainId: domain?.id as number,
        iconeName: iconeName ?? defaultValues.iconeName,
        calculCombineId: calculCombine?.id as number,
        calculMultipleId: calculMultiple?.id as number,
    };
};

/** Format request payload to match Api expectations */
export const output = (formData: OfferSchema): OffresOffreCreate | OffresOffreUpdate => {
    const { label, visibility, domainId, iconeName, calculCombineId, calculMultipleId } = formData;

    const createPayload: OffresOffreCreate = {
        libelle: label,
        visibilite: visibility,
        domaine: domainId as unknown as string, // API type ask string but expect number ......
        iconeName,
        calculCombine: calculCombineId as unknown as string,
        calculMultiple: calculMultipleId as unknown as string,
    };
    // const updatePayload: OffresOffreUpdate = {};

    return createPayload;
};
