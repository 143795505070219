import { Box, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { FactureAchatApiObject } from '@europrocurement/l2d-domain';
import { useParams } from 'react-router';
import { CodeRejetsChipList, type CodeRejetsChipType } from '../../widgets/CodeRejetsChipList';
import FactureAchatEtats from '../../widgets/FactureAchatEtats';
import StatusList from '../StatusList';

type InvoiceDataCardProps = {
    invoice?: FactureAchatApiObject;
    rejectCodes?: Array<CodeRejetsChipType>;
    headerControllers?: ReactNode;
};

const InvoiceDataCard: React.FunctionComponent<InvoiceDataCardProps> = function ({
    invoice,
    rejectCodes,
    headerControllers,
}: InvoiceDataCardProps) {
    const { statementid } = useParams();

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'start',
                        justifyContent: 'space-between',
                        gap: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'center',
                            gap: 1,
                        }}
                    >
                        {statementid ? (
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                            >
                                Relevé : {statementid}
                            </Typography>
                        ) : null}
                        {invoice && invoice.createdAt ? (
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                            >
                                {`Facture saisie le : ${new Date(invoice.createdAt).toLocaleDateString()}`}
                            </Typography>
                        ) : null}
                        {invoice && invoice.createdBy ? (
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                            >
                                {`Facture saisie par : ${invoice.createdBy}`}
                            </Typography>
                        ) : null}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '15px',
                                padding: '0px',
                                alignItems: 'center',
                            }}
                        >
                            {invoice && rejectCodes && rejectCodes.length > 0 ? (
                                <>
                                    <Typography
                                        variant="h5"
                                        fontWeight="bold"
                                    >
                                        Codes rejets :{' '}
                                    </Typography>
                                    <CodeRejetsChipList
                                        rejets={rejectCodes}
                                        full
                                    />
                                </>
                            ) : (
                                <Typography
                                    variant="h5"
                                    fontWeight="bold"
                                >
                                    Pas de code rejet
                                </Typography>
                            )}
                        </Box>
                        {invoice ? <FactureAchatEtats factureAchats={invoice} /> : null}
                    </Box>
                    <StatusList itemToWatch={invoice} />
                </Stack>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                }}
            >
                {headerControllers}
            </Box>
        </Box>
    );
};

export default InvoiceDataCard;
