import * as React from 'react';

import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';

type UtilisateurActionMenuProps = {
    itemId: number | string;
};

/**
 * See Prescripteurs ActionMenu for original version.
 */
const UtilisateurActionMenu: React.FunctionComponent<UtilisateurActionMenuProps> = function ({
    itemId,
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`${itemId}`}
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>
                            Ouvrir dans un nouvel onglet
                        </Typography>
                    </Link>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UtilisateurActionMenu;
