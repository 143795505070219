/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FlexyDate, InformationCardItem } from '@europrocurement/flexy-components';
import { AnnonceLegaleApiGetPricingAnnonceLegaleItemRequest } from '@europrocurement/l2d-domain/openApi/ApiAnnoncesLegales';
import { useSelector } from 'react-redux';
import {
    cachedDepartmentsSelector,
    useAnnoncesLegalesService,
    useSociete,
} from '@europrocurement/l2d-domain';
import { dateToISOYmd } from '@europrocurement/l2d-utils';
import SelectField from './FormInputs/SelectField';
import useFetchCollection from '../Package/Form/useFetchCollection';
import { ArticleFormPricingState } from '../Package/Form/types';

type ComponentProps = {
    subTypeId?: number;
    setArticlePricingInformations: (informations: ArticleFormPricingState) => void;
};

const AdvertPricing: React.FunctionComponent<ComponentProps> = function (props) {
    const { legalAdvertApi } = useAnnoncesLegalesService();

    const { subTypeId, setArticlePricingInformations } = props;
    const xIdSociete = useSociete();
    const DEFAULT_DEPARTMENT_CODE = '75';

    const departmentsOptions = useSelector(cachedDepartmentsSelector) ?? [];
    const [priceToDisplay, setPrice] = React.useState<string | null>(null);
    const [departmentCode, setDeparmentCode] = React.useState(DEFAULT_DEPARTMENT_CODE);
    const [date, setDate] = React.useState(new Date());
    const [error, setError] = React.useState(false);

    const pricingCall = () => {
        if (!subTypeId) {
            return new Promise(() => {});
        }
        const requestParameters: AnnonceLegaleApiGetPricingAnnonceLegaleItemRequest = {
            id: subTypeId,
            xIdSociete,
            departmentCode,
            date: dateToISOYmd(date),
        };
        return legalAdvertApi.getPricingAnnonceLegaleItem(requestParameters);
    };

    const {
        result,
        fetch: fetchPricingInformations,
        hasError,
        isFetching,
    } = useFetchCollection<{
        stype_au_forfait: boolean | null;
        montant_forfait_HT: string | null;
    }>(pricingCall);

    React.useEffect(() => {
        const fetchSubTypePricing = () => {
            setPrice(null);
            setError(false);
            fetchPricingInformations();
        };

        if (subTypeId) {
            fetchSubTypePricing();
        }
    }, [subTypeId, departmentCode, date]); // Fetch subtype pricing information if one of thoses three values are updated

    const price = result?.montant_forfait_HT;
    const isFlatRate = result.stype_au_forfait === true;

    React.useEffect(() => {
        if (!isFetching) {
            if (price && price !== priceToDisplay) {
                setPrice(price);
            }
            setError(hasError);
        }
    }, [price, hasError, isFetching]);

    React.useEffect(() => {
        setArticlePricingInformations({
            isFlatRate,
            flatPrice: price ?? '',
        });
    }, [isFlatRate, price]);

    if (!subTypeId && !isFetching) {
        return <p>Selectionnez un sous type pour obtenir un aperçu de sa tarification</p>;
    }

    if (isFetching) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                width="75%"
                margin="auto"
            >
                <Typography
                    textAlign="center"
                    fontSize={15}
                    sx={{ opacity: 0.7, margin: '20px 0' }}
                >
                    Chargement de la tarification ...
                </Typography>
                <LinearProgress />
            </Box>
        );
    }

    if (error) {
        return <p>Une erreur est survenue, veuillez réessayer</p>;
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
                <>
                    <Typography
                        fontSize={15}
                        sx={{ opacity: 0.7, margin: '20px 0' }}
                    >
                        Informations concernant la tarification
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            marginTop: '5px',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                        }}
                    >
                        <Box>
                            <InformationCardItem
                                label="Tarification au forfait"
                                value={isFlatRate ? 'Oui' : 'Non'}
                            />
                        </Box>
                        {isFlatRate && (
                            <Box sx={{ marginTop: '10px' }}>
                                <InformationCardItem
                                    label="Montant"
                                    value={isFlatRate ? price : '---'}
                                />
                            </Box>
                        )}
                    </Box>
                </>
            </Box>
            <Box sx={{ width: '40%' }}>
                <Typography
                    fontSize={15}
                    sx={{ opacity: 0.7, margin: '20px 0' }}
                >
                    Options ( Date & Département )
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        marginTop: '5px',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                    }}
                >
                    <Box>
                        <FlexyDate
                            value={date}
                            onChange={setDate}
                        />
                    </Box>
                    <Box sx={{ marginTop: '10px' }}>
                        <SelectField
                            name="departmentCode"
                            onChange={setDeparmentCode}
                            value={departmentCode}
                            options={departmentsOptions}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AdvertPricing;
