import * as React from 'react';

import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { genericError, waitOrSupport } from '@europrocurement/l2d-utils/function-utils';
import { CircularProgress, Tooltip, styled } from '@mui/material';
import { useHandleError } from '@europrocurement/l2d-hooks';
import usePromises from '@europrocurement/l2d-hooks/hooks/usePromises';
import { NamedAxiosPromise } from '@europrocurement/flexy-components/types/NamedAxiosPromise';
import Switch from '../Switch/Switch';

export type SwitchAsyncProps = {
    requestsOnToggle?: () => Array<NamedAxiosPromise>;
    onLoaded?: () => void;
    defaultChecked?: boolean;
    disabled?: boolean;
};

const StyledSwitchContainer = styled('div')<{ isFetching: boolean; displayableError: unknown }>(
    ({ isFetching, displayableError }) => ({
        position: 'relative',
        opacity: isFetching || displayableError ? 0.7 : 1,
        pointerEvents: isFetching ? 'none' : 'auto',
        transition: 'opacity 0.3s ease',
    }),
);

const OverlayContainer = styled('div')(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
}));

const StyledSwitch = styled(Switch)<{ displayableError: unknown }>(({
    theme,
    displayableError,
}) => {
    const errorColor = displayableError ? theme.palette.error[900] : undefined;
    const lighterErrorColor = displayableError ? theme.palette.error[100] : undefined;

    const commonStyles = {
        color: lighterErrorColor,
        backgroundColor: lighterErrorColor,
        transition: 'background-color 0.3s ease',
    };
    const commonStylesDark = {
        color: errorColor,
        backgroundColor: errorColor,
        transition: 'background-color 0.3s ease',
    };

    let commonIcons = {};

    if (displayableError) {
        commonIcons = {
            content: '""',
            color: 'transparent',
            backgroundColor: 'inherit',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        };
    }

    return {
        '& .MuiSwitch-switchBase': {
            ...commonStyles,
            '&:before': commonStylesDark,
            '&:hover': commonStyles,
            '&.Mui-checked': {
                ...commonStyles,
                '&:before': commonStylesDark,
                '&:hover': commonStyles,
                '& .MuiTypography-root': {
                    ...commonStylesDark,
                    '&:hover': commonStylesDark,
                    ...commonIcons,
                },
            },
            '& .MuiTypography-root': {
                ...commonStylesDark,
                '&.Mui-checked': commonStylesDark,
                ...commonIcons,
            },
        },
    };
});

/**
 * A reusable async-enabled switch component that integrates with dynamic callbacks.
 *
 * This component is designed to handle asynchronous operations triggered by the switch's toggle.
 * It supports dynamic error handling, loading states, and triggers a custom callback when the operation succeeds.
 *
 * @param {Function} requestsOnToggle - A function that returns a `NamedAxiosPromise` array. (queue of Axios requests)
 * to be executed when the switch is toggled. Optional.
 * @param {boolean} [defaultChecked=false] - The default state of the switch (on/off) when rendered.
 * @param {Function} [onLoaded] - A callback function triggered when the async operation succeeds. Optional.
 *
 * @returns {React.ReactElement} A styled switch component with built-in async handling.
 */
const SwitchAsync: React.FunctionComponent<SwitchAsyncProps> = function ({
    requestsOnToggle,
    onLoaded,
    defaultChecked = false,
    disabled = false,
}) {
    const { isFetching, call, hasErrors, isLoaded, fetchedErrors } = usePromises();
    const { handleFailure } = useHandleError();
    const [logicError, setLogicError] = React.useState<unknown>();
    const [displayableError, setDisplayableError] = React.useState<unknown>();

    /**
     * Used to determine if errors should be detailed.
     */
    const roleChecker = UseKeycloakCheckRole();
    const isTech = roleChecker('realm:debug');

    const handleClickEvent = React.useCallback(async () => {
        if (!requestsOnToggle) return;
        try {
            await call(requestsOnToggle());
        } catch (error) {
            setLogicError(error);
        }
    }, [call, requestsOnToggle]);

    /**
     * Errors handling
     */
    React.useEffect(() => {
        if (!logicError && !hasErrors) return;

        let errorObjectToFormat;

        if (!isTech) {
            errorObjectToFormat = {
                message: `${genericError} ${waitOrSupport}`,
            };
        } else {
            errorObjectToFormat = logicError || fetchedErrors;
        }

        handleFailure({ error: errorObjectToFormat, setError: setDisplayableError });
    }, [fetchedErrors, handleFailure, hasErrors, isTech, logicError]);

    React.useEffect(() => {
        if (isLoaded && !hasErrors) {
            onLoaded?.();
        }
    }, [isLoaded, hasErrors, onLoaded]);

    const icon = React.useMemo(() => {
        if (isFetching) {
            return (
                <CircularProgress
                    color="primary"
                    size={24}
                />
            );
        }
        return undefined;
    }, [isFetching]);

    return (
        <Tooltip title={displayableError ? `${displayableError}` : ''}>
            <StyledSwitchContainer
                isFetching={isFetching}
                displayableError={displayableError}
            >
                {!!displayableError || !!isFetching ? (
                    <OverlayContainer>{icon}</OverlayContainer>
                ) : undefined}

                <StyledSwitch
                    key={`${defaultChecked}`}
                    checked={defaultChecked}
                    onClick={handleClickEvent}
                    disabled={isFetching || disabled}
                    icon={icon}
                    checkedIcon={icon}
                    displayableError={displayableError}
                />
            </StyledSwitchContainer>
        </Tooltip>
    );
};

export default SwitchAsync;
