import {
    ActionButton,
    ActionButtonProps,
    CustomAvatar,
    LazyBreadcrumb,
    ModalContext,
} from '@europrocurement/flexy-components';
import { plusIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import {
    SousClient,
    SousClientsSelector,
    useTiersService,
    TiersAppDispatch,
    SousClientApi,
    useTiersServiceSelector,
    selectedPrescripteursSelector,
} from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Typography, useTheme, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { Box } from '@mui/system';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import { AnyAction } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { PrescriberClientForm } from '@europrocurement/l2d-modules/modules/PrescriberClient/components/AddPrescriberClient';
import {
    useDossierFormalite,
    DossierFormalite,
} from '@europrocurement/l2d-modules/modules/Dossiers/hooks';

import ActionMenuSocietes from '../../components/ActionMenuSocietes';
import { TiersObjectGroup, useSocietesObjectGroup } from '../../hooks';
import { useSocietesSearch } from '../../providers/SocietesSearchProvider';

type ComponentListeSocieteProps = {
    showPrescripteur: boolean;
};

const ListeSocietes: React.FunctionComponent<ComponentListeSocieteProps> = function ({
    showPrescripteur = false,
}) {
    const { xIdSociete } = useSelector(customizerSelector);
    const { searchTiersObjectGroup } = useSocietesObjectGroup();
    const { searchDossierFormalite } = useDossierFormalite();
    const { modalActions } = React.useContext(ModalContext);

    const dispatch = useDispatch<TiersAppDispatch>();
    const prescripteur = useTiersServiceSelector(selectedPrescripteursSelector);

    const datasource: DataSource<SousClient> = useSelector(SousClientsSelector).main;
    const { getSousClients } = useTiersService();

    const navigate = useNavigate();

    const theme = useTheme();
    const { palette } = theme;
    const breadcrumbItems = [
        {
            link: '/accueil',
            icon: {
                icon: ['fasl', 'house'] as IconProp,
                sx: { display: 'flex', alignItems: 'center' },
            },
        },
        {
            link: '/societes',
            path: 'Sociétés',
        },
        'Sociétés',
    ];

    const handleRedirect = (e: React.MouseEvent<HTMLElement>, item: SousClientApi) => {
        e.preventDefault();
        dispatch({
            type: `dossiersOpenSearch/setmainFilter`,
            payload: {
                key: 'clientId',
                value: [item.id],
            },
        });
        navigate('/dossiers');
    };

    function normalizeString(sData: string | undefined, type: string = 'phone') {
        if (sData === undefined) {
            return '';
        }
        // Supprimer tous les espaces existants
        const cleaned = sData.replace(/\s+/g, '');

        if (type === 'siren') {
            return cleaned.match(/.{1,3}/g)?.join(' ') || '';
        }
        // Vérifier si le numéro commence par un indicatif (+33, etc.)
        const match = cleaned.match(/^(\+\d+)(.*)$/);
        if (match) {
            const internationalCode = match[1];
            const restOfNumber = match[2];
            const formattedRest = restOfNumber.match(/.{1,2}/g)?.join(' ') || '';
            return `${internationalCode} ${formattedRest}`.trim();
        }

        // Si pas d'indicatif, formater en blocs de 2 chiffres
        return cleaned.match(/.{1,2}/g)?.join(' ') || '';
    }

    const { setIsSearchSocietePath } = useSocietesSearch();

    useEffect(() => {
        setIsSearchSocietePath(true);
        return () => setIsSearchSocietePath(false);
    }, [setIsSearchSocietePath]);

    const sousClientColumns: ColumnDatatable[] = [
        {
            isDisplayed: true,
            sortable: true,
            label: 'Denomination',
            field: 'raisonSociale',
            render: 'raisonSociale',
            sx: {
                fontWeight: 500,
                paddingLeft: '40px',
            },
        },
        {
            isDisplayed: true,
            sortable: false,
            label: 'Adresse',
            field: 'adresses',
            render: (sousClient: SousClientApi) => {
                const adressePrincipal = sousClient.adresses.find((adresse) => adresse.defaut);
                if (adressePrincipal) {
                    const fields = [
                        adressePrincipal.adresse1,
                        adressePrincipal.adresse2,
                        adressePrincipal.adresse3,
                        adressePrincipal.adresse4,
                        adressePrincipal.codePostal,
                        adressePrincipal.ville,
                    ];
                    const adressConcaten = fields
                        .filter((field) => field != null && field.trim() !== '')
                        .join(' ');

                    return (
                        <Typography
                            variant="l2dbodyxs"
                            sx={{
                                color: palette.grey[700],
                            }}
                        >
                            {adressConcaten}
                        </Typography>
                    );
                }
                return null;
            },
        },
        {
            isDisplayed: true,
            label: 'SIREN',
            sortable: true,
            field: 'siren',
            render: (sousClient: SousClientApi) => (
                <Typography
                    variant="l2dbodyxs"
                    sx={{
                        color: palette.grey[700],
                    }}
                >
                    {normalizeString(sousClient.siren, 'siren')}
                </Typography>
            ),
        },
        {
            isDisplayed: true,
            label: 'Téléphone',
            field: 'contacts',
            sortable: false,
            render: (sousClient: SousClientApi) => {
                const contactPrincipal = sousClient.contacts.find((contact) => contact.principal);
                if (contactPrincipal) {
                    const telephonesContactPrincipal = contactPrincipal.telephonesContact.find(
                        (tel) => tel.defaut,
                    );
                    const mobileContactPrincipal = contactPrincipal.mobilesContact.find(
                        (mobile) => mobile.defaut,
                    );
                    const numeroToShow =
                        telephonesContactPrincipal !== undefined
                            ? telephonesContactPrincipal.telephone
                            : mobileContactPrincipal?.mobile;
                    return (
                        <Box
                            sx={{
                                color: palette.grey[700],

                                display: 'flex',
                                flexDirection: 'row',
                                gap: '5px',
                            }}
                        >
                            <FaOptionIcon icon={['fasl', 'phone']} />
                            <Typography
                                variant="l2dbodyxs"
                                sx={{
                                    color: palette.grey[700],
                                }}
                            >
                                {normalizeString(numeroToShow)}
                            </Typography>
                        </Box>
                    );
                }
                return null;
            },
        },
        {
            isDisplayed: true,
            sortable: false,
            label: 'Email',
            field: 'contacts',
            render: (sousClient: SousClientApi) => {
                const contactPrincipal = sousClient.contacts.find((contact) => contact.principal);
                if (contactPrincipal) {
                    const emailContactPrincipal = contactPrincipal.emailsContact.find(
                        (email) => email.defaut,
                    );
                    return (
                        <Box
                            sx={{
                                color: palette.grey[700],

                                display: 'flex',
                                flexDirection: 'row',
                                gap: '5px',
                            }}
                        >
                            <FaOptionIcon icon={['fasl', 'envelope']} />
                            <Typography
                                variant="l2dbodyxs"
                                sx={{
                                    color: palette.grey[700],
                                }}
                            >
                                <a
                                    href={`mailto:${emailContactPrincipal?.email}`}
                                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                                >
                                    {emailContactPrincipal?.email}
                                </a>
                            </Typography>
                        </Box>
                    );
                }
                return null;
            },
        },
        {
            isDisplayed: true,
            label: 'Dos...',
            headerTooltip: 'Dossiers',
            field: 'fCollectionDossier',
            sortable: true,
            render: (sousClient: SousClientApi) => {
                const aDoss = sousClient?.fCollectionDossier;
                let nbDossFinal = 0;

                if (aDoss) {
                    nbDossFinal = aDoss.reduce((total, dossier) => {
                        // Vérifie si fProDossier est défini
                        if (!dossier.fProDossier) {
                            return total;
                        }

                        const count = dossier.fProDossier.filter(
                            (proDossier) => proDossier.fstat,
                        ).length;

                        return total + count;
                    }, 0);
                }
                return (
                    <Tooltip title="Accéder aux dossiers">
                        <Box
                            sx={{
                                color: 'text.gris70',

                                display: 'flex',
                                flexDirection: 'row',
                                gap: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            <FaOptionIcon icon={['fasl', 'folder']} />
                            <Typography
                                variant="l2dtableCell"
                                component="span"
                            >
                                {nbDossFinal}
                            </Typography>
                        </Box>
                    </Tooltip>
                );
            },
            onClickCell: handleRedirect,
        },
        {
            isDisplayed: true,
            label: 'For...',
            headerTooltip: 'Formaliste',
            field: 'formaliste.nom',
            sortable: true,
            render: (sousClient: SousClientApi) => {
                const { formaliste } = sousClient;
                if (formaliste && formaliste !== undefined) {
                    return (
                        <Tooltip title={`${formaliste.prenom} ${formaliste.nom}`}>
                            <Box
                                sx={{
                                    borderRadius: '16px',
                                    width: '32px',
                                    height: '32px',
                                    margin: '0px 16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CustomAvatar
                                    sx={{
                                        width: '32px',
                                        height: '32px',
                                    }}
                                    firstName={formaliste.prenom}
                                    lastName={formaliste.nom}
                                />
                            </Box>
                        </Tooltip>
                    );
                }
                return null;
            },
        },
        {
            label: 'Actions',
            displayLabel: false,
            render: (sousClient: SousClientApi) => <ActionMenuSocietes sousClient={sousClient} />,
            isDisplayed: true,
            sortable: false,
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
    ];

    if (showPrescripteur) {
        sousClientColumns.unshift({
            isDisplayed: true,
            label: 'Prescripteur',
            sortable: true,
            onClickCell: (e: React.MouseEvent<HTMLElement>, item: SousClientApi) => {
                e.preventDefault();
                navigate(`/prescripteurs/liste/${item.idPrescripteur}?prescriber-detail=resume`);
            },
            render: (sousClient: SousClientApi) => {
                if (
                    sousClient.idPrescripteur &&
                    sousClient.firstnamePrescripteur !== undefined &&
                    sousClient.firstnamePrescripteur !== null &&
                    sousClient.lastnamePrescripteur !== undefined &&
                    sousClient.lastnamePrescripteur !== null
                ) {
                    return (
                        <Tooltip
                            title={`${sousClient.firstnamePrescripteur as string} ${sousClient.lastnamePrescripteur as string}`}
                        >
                            <Box
                                sx={{
                                    borderRadius: '16px',
                                    width: '32px',
                                    height: '32px',
                                    margin: '0px 16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CustomAvatar
                                    sx={{
                                        width: '32px',
                                        height: '32px',
                                    }}
                                    firstName={sousClient.firstnamePrescripteur as string}
                                    lastName={sousClient.lastnamePrescripteur as string}
                                />
                            </Box>
                        </Tooltip>
                    );
                }
                return null;
            },
        });
    }

    const sousClientFilters: FiltersDatatableList = [
        {
            label: 'Sociétés',
            field: 'raisonSociale',
            type: 'text',
            icon: 'building-user',
        },
        {
            label: 'Formalités',
            field: 'formaliteFdoss',
            type: 'selectMultipleApi',
            icon: 'folder',
            title: 'Formalités',
            getItems: searchDossierFormalite,
            getItemLabel: (item: DossierFormalite) => item.libelle as string,
            getItemValue: (item: DossierFormalite) => item.id as unknown as string,
            getGroupLabel: (item: DossierFormalite) => item.groupementLibelle as string,
            getGroupValue: (item: DossierFormalite) => item.groupementId as unknown as string,
        },
        {
            label: 'Références dossiers',
            field: 'formaliteFdossNum',
            type: 'text',
            icon: 'note',
        },
        {
            label: 'Références clients',
            field: 'formaliteFdossSteRef',
            type: 'text',
            icon: 'user',
        },
        {
            label: 'Mes groupes',
            field: 'objectgroups',
            type: 'selectApi',
            icon: 'folder',
            searchFunction: searchTiersObjectGroup,
            renderItem: (item: TiersObjectGroup) => item.label as unknown as string,
            valueMap: (item: TiersObjectGroup) => item.id as unknown as string,
        },
    ];

    const handleSuccess = () => {
        // attente 1 seconde pour récupération du sous-client
        setTimeout(() => {
            dispatch({
                type: 'sousClient/setmainPagination',
                payload: {
                    page: 1,
                    itemsPerPage: 10,
                },
            });
            if (prescripteur !== undefined) {
                dispatch({
                    type: 'sousClient/setmainFilter',
                    payload: {
                        key: 'prescripteurId',
                        value: prescripteur.id,
                    },
                });
            }

            dispatch(getSousClients({}) as unknown as AnyAction);
            modalActions.close();
        }, 1000);
    };

    const handleCancel = () => {
        modalActions.close();
    };

    const hoverBackground = theme.palette.primary.light;
    const hoverColor = theme.palette.getContrastText(hoverBackground);
    const createClient: ActionButtonProps = {
        label: 'Ajouter une société',
        icon: plusIcon,
        action: () =>
            modalActions.call(
                <PrescriberClientForm
                    onCancel={handleCancel}
                    onSuccess={handleSuccess}
                />,
            ),
        sx: {
            padding: '12px 16px',
            borderRadius: '12px',
            lineHeight: '24px',
            fontFamily: 'Inter',
            fontSize: '16px',
            width: 'max-content',
            fontWeight: 500,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.getContrastText(theme.palette.primary.dark),
            ':hover': {
                backgroundColor: hoverBackground,
                color: hoverColor,
            },
            margin: '0',
        },
    };

    const modifySousClient = (e: React.MouseEvent<HTMLElement>, sousClient: SousClientApi) => {
        modalActions.call(
            <PrescriberClientForm
                onCancel={handleCancel}
                onSuccess={handleSuccess}
                sousClient={sousClient}
            />,
        );
    };

    return (
        <>
            <LazyBreadcrumb
                path={breadcrumbItems}
                separator=" / "
                sx={{ marginBottom: '1.5em' }}
            />
            <StoreDatatable
                buttonAction={<ActionButton {...createClient} />}
                dataSource={datasource}
                columns={sousClientColumns}
                fetchData={getSousClients}
                filters={sousClientFilters}
                localStorageKey="FlexyStoreDatatableSociete"
                observers={[xIdSociete]}
                sx={{ marginBottom: '2.25em', cursor: 'pointer' }}
                onClickRow={modifySousClient}
            />
        </>
    );
};

export default ListeSocietes;
