import React from 'react';
import { Box } from '@mui/system';
import { FlexyTabs, OptionsType, TabStructure } from '@europrocurement/flexy-components';
import { UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { User } from './type';
import UserHeader from '../UserHeader';
import UserInfos, { UserInfosProps } from '../UserInfos';
import { UserG3 } from '../UserG3/UserG3';
import { UserL2DF } from '../UserL2DF/UserL2DF';
import { UserCompo } from '../UserCompo/UserCompo';
import { UserGestionJustif } from '../UserGestionJustif/UserGestionJustif';
import { UserAnnuaire } from '../UserAnnuaire/UserAnnuaire';
import { UserSupport } from '../UserSupport/UserSupport';

export type UserPatchPayload = {
    field: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur;
};

export type UserTabsProps = {
    user: User;
    poles: OptionsType;
    services: OptionsType;
    groupes: OptionsType;
    marques: OptionsType;
    handlePatchData: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
    ) => void;
    handlePatchInfos: UserInfosProps['handlePatchInfos'];
    handleValidationModal: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => void;
};

const UserTabs: React.FunctionComponent<UserTabsProps> = function ({
    user,
    poles,
    services,
    groupes,
    marques,
    handlePatchData,
    handlePatchInfos,
    handleValidationModal,
}) {
    const tabs: Array<TabStructure> = [
        {
            tabTitle: 'Infos utilisateur',
            tabPanel: (
                <UserInfos
                    poles={poles}
                    services={services}
                    groupes={groupes}
                    user={user}
                    handlePatchInfos={handlePatchInfos}
                    handleValidationModal={handleValidationModal}
                />
            ),
            tabName: 'utilisateur',
        },
        {
            tabTitle: 'G3',
            tabPanel: (
                <UserG3
                    user={user}
                    handlePatchData={handlePatchData}
                    handleValidationModal={handleValidationModal}
                    marques={marques}
                />
            ),
            tabName: 'G3',
        },
        {
            tabTitle: 'L2D Formalités',
            tabPanel: (
                <UserL2DF
                    user={user}
                    handlePatchData={handlePatchData}
                    handleValidationModal={handleValidationModal}
                />
            ),
            tabName: 'L2DF',
        },
        {
            tabTitle: 'Gestion Justif',
            tabPanel: (
                <UserGestionJustif
                    user={user}
                    handlePatchData={handlePatchData}
                    handleValidationModal={handleValidationModal}
                />
            ),
            tabName: 'Justif',
        },
        {
            tabTitle: 'Compo',
            tabPanel: (
                <UserCompo
                    user={user}
                    handlePatchData={handlePatchData}
                    handleValidationModal={handleValidationModal}
                />
            ),
            tabName: 'composition-annonces',
        },
        {
            tabTitle: 'Annuaire',
            tabPanel: (
                <UserAnnuaire
                    user={user}
                    handleValidationModal={handleValidationModal}
                />
            ),
            tabName: 'annuaire',
        },
        {
            tabTitle: 'Support',
            tabPanel: (
                <UserSupport
                    user={user}
                    handleValidationModal={handleValidationModal}
                />
            ),
            tabName: 'support',
        },
    ];

    return (
        <Box>
            <UserHeader user={user} />

            <FlexyTabs
                tabs={tabs}
                scrollbar={false}
            />
        </Box>
    );
};

export default UserTabs;
