import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { useDispatch, useSelector } from 'react-redux';
import {
    documentTypes,
    FormaliteDocument,
    formalitiesDocumentSelector,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import { AppDispatch } from '@b2d/redux/types';
import DocumentsCard from './DocumentsCard';
import DocumentModal from './DocumentModal';
import useFormalityFormHandler from '../../hooks/useFormalityFormHandler';

type FormalityDocumentsProps = {
    formality: Formalite;
};

const FormalityDocuments: React.FunctionComponent<FormalityDocumentsProps> = function (props) {
    const { selectFormality } = useFormaliteService();

    const { formality } = props;
    const [documentModalOpen, setDocumentModalOpen] = useState(false);
    const [selectedType, setSelectedType] = useState<number>(0);
    const { updateFormality } = useFormalityFormHandler();
    const dispatch = useDispatch<AppDispatch>();
    const openDocumentsModal = (type: number) => {
        setSelectedType(type);
        setDocumentModalOpen(true);
    };
    const closeDocumentsModal = () => {
        setSelectedType(0);
        setDocumentModalOpen(false);
    };
    const onEditDocuments = (selectedItems: number[]) => {
        updateFormality(formality, { documents: selectedItems }).then(() => {
            closeDocumentsModal();
            dispatch(selectFormality({ id: formality.id as unknown as string }));
        });
    };
    const onRemoveDocument = (documentId: number) => {
        const documents = formality.documents.filter(
            (item: string | number) => item !== documentId,
        );
        onEditDocuments(documents as unknown[] as number[]);
    };
    const getDocumentTypeLabel = (type: number) => {
        const status = documentTypes.find((option) => option.value === type);
        if (!status) {
            return 'Aucun type';
        }

        return status.label;
    };
    const availableDocuments = useSelector(formalitiesDocumentSelector).data;
    const documentsCards = documentTypes.map((type) => {
        const documentsByType = availableDocuments.filter(
            (item: FormaliteDocument) =>
                item.id &&
                formality.documents.indexOf(item.id as unknown as string) !== -1 &&
                item.type === type.value,
        );

        return (
            <Grid
                key={`formality-documents-${type.value}`}
                item
                xs={4}
            >
                <DocumentsCard
                    title={getDocumentTypeLabel(type.value)}
                    documents={documentsByType}
                    type={type.value}
                    onOpenModal={openDocumentsModal}
                    onRemoveItem={onRemoveDocument}
                />
            </Grid>
        );
    });

    return (
        <Grid container>
            {documentsCards}
            <DocumentModal
                title={getDocumentTypeLabel(selectedType)}
                isOpen={documentModalOpen}
                onModalClose={closeDocumentsModal}
                onSubmitModal={onEditDocuments}
                itemsSelected={formality.documents as unknown[] as number[]}
                type={selectedType}
            />
        </Grid>
    );
};

export default FormalityDocuments;
