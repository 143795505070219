import {
    ArticlesJsonldArticleRead,
    TblRelTypeDocumentArticleRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { ArticleSchema } from './schema';

/** API -> Form -> API Serialization */

export type CegidConfiguration = {
    id: string;
    sp1: string;
    sp2: string;
    tl1: string;
    tl2: string;
    tl3: string;
};

export type ArticleUnits = 'F' | 'C' | 'U' | 'L';
export type ArticleVatCodes = 'TN' | 'TI' | 'TR' | 'TP' | 'NS';

export type ArticleFormInputs = {
    label: string;
    code: string;
    price: number | null;
    unit: ArticleUnits;
    companyId: number;
    cegidConfiguration?: CegidConfiguration;
    invoicingCategory: string | null;
    domainId: number;
    documents: TblRelTypeDocumentArticleRead[];
    vatCode: ArticleVatCodes;
};

/**
 * Generate initial formValues by formating api data & translate field names :
 * - CREATE : Initial values
 * - UPDATE : Format request API payload to match formData expectations
 */
export const input: (entity?: ArticlesJsonldArticleRead) => ArticleFormInputs = (
    entity = undefined,
) => {
    const defaultValues: ArticleFormInputs = {
        label: '',
        code: '',
        price: null,
        unit: 'F' as const,
        companyId: 0,
        invoicingCategory: null,
        domainId: 0,
        documents: [],
        vatCode: 'TN' as const,
    };

    if (!entity) {
        return defaultValues;
    }

    // Field renaming by aliasing destructured api object
    const {
        libelle: label,
        codeArticle: code,
        prixUnitaire: price,
        unite: unit,
        societe: company,
        cegidCompte,
        cegidSp1,
        cegidSp2,
        cegidTl1,
        cegidTl2,
        cegidTl3,
        rubArticle: invoicingCategory,
        domaine: domainId,
        natureTva: vatCode, // Type error
    } = entity;

    const documents = entity.documents ?? [];

    // [TOFIX] Typing error from Open Api generate / Open api definition / Api Property example
    const domainValue = domainId as unknown as {
        ['@id']: string;
        ['@type']: string;
        id: number;
    };

    const entityValue: ArticleFormInputs = {
        label: label ?? defaultValues.label,
        code: code ?? defaultValues.code,
        price: price ? parseFloat(price) : null,
        unit: (unit as ArticleUnits) ?? defaultValues.unit, // Correct API types to Enum if possible => unit: 'F' | 'C' | 'U' | 'L';
        companyId: company?.id ?? 0,
        cegidConfiguration: {
            id: cegidCompte ?? '',
            sp1: cegidSp1 ?? '',
            sp2: cegidSp2 ?? '',
            tl1: cegidTl1 ?? '',
            tl2: cegidTl2 ?? '',
            tl3: cegidTl3 ?? '',
        },
        invoicingCategory: invoicingCategory ?? defaultValues.invoicingCategory,
        domainId: domainValue.id,
        documents: documents?.map((doc) => doc.typeDocument as TblRelTypeDocumentArticleRead),
        vatCode: (vatCode as ArticleVatCodes) ?? defaultValues.vatCode, // Correct API types if possible => vatCode: 'TN' | 'TI' | 'TR' | 'TP' | 'NS' (No conversion needed)
    };

    return entityValue;
};

/** Format request payload to match Api expectations */
export const output = (formData: ArticleSchema) => {
    const emptyCegid = {
        cegidCompte: '',
        cegidSp1: '',
        cegidSp2: '',
        cegidTl1: '',
        cegidTl2: '',
        cegidTl3: '',
    };
    const { cegidConfiguration } = formData;
    const { id, sp1 = '', sp2, tl1, tl2, tl3 } = cegidConfiguration ?? {};
    const customInvoicingCategory = formData.invoicingCategory === 'DIV';
    const advertInvoicingCategory = formData.invoicingCategory === 'PUB';
    const priceValue = formData.price?.toString() ?? null;

    const formatedPayload = {
        libelle: formData.label,
        codeArticle: formData.code,
        prixUnitaire: advertInvoicingCategory ? null : priceValue,
        unite: advertInvoicingCategory ? null : formData.unit,
        ...(customInvoicingCategory
            ? {
                  cegidCompte: id,
                  cegidSp1: sp1,
                  cegidSp2: sp2,
                  cegidTl1: tl1,
                  cegidTl2: tl2,
                  cegidTl3: tl3,
              }
            : emptyCegid),
        rubArticle: formData.invoicingCategory,
        domaine: formData.domainId,
        documents: formData.documentIds?.map((documentId: number) => ({
            typeDocument: documentId,
        })),
        natureTva: advertInvoicingCategory ? 'TN' : formData.vatCode,
    };

    return formatedPayload;
};
