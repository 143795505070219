import { DossierStep } from '@europrocurement/l2d-domain';
import { TapesDeTraitementDesDossiersStepsDossierRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { createContext } from 'react';

const defaultSteps: Array<DossierStep> = [];
const defaultSetSteps: React.Dispatch<
    React.SetStateAction<TapesDeTraitementDesDossiersStepsDossierRead[]>
> = () => {};

const DossierStepContext = createContext({ steps: defaultSteps, setSteps: defaultSetSteps });

export default DossierStepContext;
