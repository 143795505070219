import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
    CardHeader,
    CardSection,
    Timeline,
    TimelineStepProps,
} from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { dateToFrenchFormat } from '@europrocurement/l2d-utils';
import InfoCard from '../InfoCard/InfoCard';
import PriorityChip from '../PriorityChip/PriorityChip';
import { PeoplePreview } from '../../liste';

export type DossierInformationsProps = {
    steps: Array<TimelineStepProps>;
    contactNom: string;
    contactPrenom: string;
    formalisteNom: string;
    formalistePrenom: string;
    critical: boolean;
    dateUpdate: string;
};

const DossierInformations: React.FunctionComponent<DossierInformationsProps> = function (props) {
    const {
        steps,
        contactNom,
        contactPrenom,
        formalisteNom,
        formalistePrenom,
        critical,
        dateUpdate,
    } = props;
    const { palette, breakpoints } = useTheme();
    const downMdBreakpoint = useMediaQuery(breakpoints.down('md'));
    const timelineStyle = {
        borderBottom: `solid 1px ${palette.grey[300]}`,
        padding: downMdBreakpoint ? '32px' : '16px 32px 32px 32px',
    };
    const infosStyle = {
        display: 'flex',
        flexDirection: downMdBreakpoint ? 'column' : 'row',
        marginTop: '16px',
        width: '100%',
    };
    const infoCardSx = {
        flexGrow: 1,
        padding: downMdBreakpoint ? '16px 32px' : '8px 32px',
        borderRight: downMdBreakpoint ? 'none' : `solid 1px ${palette.grey[300]}`,
    };
    const textStyle = {
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '19.2px',
        color: palette.grey[500],
    };

    const cardHeader = (
        <Box>
            <CardHeader
                title="Traitement du dossier"
                border={false}
            />
        </Box>
    );

    return (
        <CardSection
            header={cardHeader}
            sxContent={{ padding: '0 0 24px 0' }}
        >
            <Box sx={timelineStyle}>
                <Timeline steps={steps} />
            </Box>
            <Box sx={infosStyle}>
                <InfoCard
                    label="Assigné à"
                    sx={infoCardSx}
                >
                    <PeoplePreview
                        firstName={contactNom}
                        lastName={contactPrenom}
                        sx={{ margin: '0 10px 0 0' }}
                    />
                    <Typography
                        sx={textStyle}
                        component="span"
                    >
                        {`${contactPrenom} ${contactNom}`}
                    </Typography>
                </InfoCard>
                <InfoCard
                    label="Dernière mise à jour"
                    sx={infoCardSx}
                >
                    <FaOptionIcon
                        sx={{ fontSize: '12px', lineHeight: 0, marginRight: '5px' }}
                        icon={['fasl', 'calendar']}
                    />
                    <Typography
                        sx={textStyle}
                        component="span"
                    >
                        {dateToFrenchFormat(new Date(dateUpdate))}
                    </Typography>
                </InfoCard>
                <InfoCard
                    label="Priorité "
                    sx={infoCardSx}
                >
                    <PriorityChip critical={critical} />
                </InfoCard>
                <InfoCard
                    label="Formaliste attitré(e)"
                    sx={{ ...infoCardSx, borderRight: 'none' }}
                >
                    <PeoplePreview
                        firstName={formalistePrenom}
                        lastName={formalisteNom}
                        sx={{ margin: '0 10px 0 0' }}
                    />
                    <Typography
                        sx={textStyle}
                        component="span"
                    >
                        {`${formalistePrenom} ${formalisteNom}`}
                    </Typography>
                </InfoCard>
            </Box>
        </CardSection>
    );
};

export default DossierInformations;
