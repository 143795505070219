import React, { ReactNode } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type DossierAction = {
    label: ReactNode;
    icon: IconProp;
    isAdditional?: boolean;
    onClick: () => void;
};

export type DetailDossierActionMenuProps = {
    anchorEl: null | HTMLElement;
    actions: Array<DossierAction>;
    onClose: () => void;
};

const DetailDossierActionMenu: React.FunctionComponent<DetailDossierActionMenuProps> = function (
    props,
) {
    const { anchorEl, actions, onClose } = props;
    const open = Boolean(anchorEl);

    const handleClick = (action: DossierAction) => {
        action.onClick();
        onClose();
    };

    return (
        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
            {actions
                .filter((action) => action?.isAdditional === true)
                .map((action) => (
                    <MenuItem
                        key={action.label as string}
                        onClick={() => handleClick(action)}
                    >
                        {action.label}
                    </MenuItem>
                ))}
        </Menu>
    );
};

export default DetailDossierActionMenu;
