/* eslint-disable @typescript-eslint/no-unused-vars */
import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import { createContext } from 'react';

export type DossierAction = 'manageGroups' | 'cancel' | 'suspend' | 'export' | undefined;

let currentAction: DossierAction;

const dossiers: Array<DossierOpenSearch> = [];
const dossiersUpdated: Array<DossierOpenSearch> = [];

const setDossiersUpdated: React.Dispatch<React.SetStateAction<Array<DossierOpenSearch>>> = () => {};

const triggerAction = (action: DossierAction, selectedDossier: Array<DossierOpenSearch>) => {};
const cancelCurrentAction = () => {};
const confirmCurrentAction = () => {};

const DossierActionContext = createContext({
    dossiers,
    currentAction,
    dossiersUpdated,
    setDossiersUpdated,
    triggerAction,
    cancelCurrentAction,
    confirmCurrentAction,
});

export default DossierActionContext;
