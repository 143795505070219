import {
    faFolderOpen,
    faHandshake,
    faNewspaper,
    faSignPosts,
} from '@fortawesome/pro-duotone-svg-icons';
import { NamedIconsType } from '../../types/types';

export const folioIcon: NamedIconsType = {
    props: {
        icon: faFolderOpen,
    },
    name: 'folio',
    displayName: 'Dossier',
};

export const publisherIcon: NamedIconsType = {
    props: {
        icon: faHandshake,
    },
    name: 'publisher',
    displayName: 'Fournisseur',
};

export const publicationIcon: NamedIconsType = {
    props: {
        icon: faSignPosts,
    },
    name: 'publication',
    displayName: 'Annonce',
};

export const newsPaperIcon: NamedIconsType = {
    props: {
        icon: faNewspaper,
    },
    name: 'newsPaper',
    displayName: 'Journal',
};

export const publicationNamedIcons: NamedIconsType[] = [folioIcon, publicationIcon, newsPaperIcon];
