import * as React from 'react';
import { useSelector } from 'react-redux';
import { lineBreakArticleId, sectionTitleArticleId } from '@europrocurement/l2d-domain';
import LineBreakLine from './LineBreakLine';
import SectionLine from './SectionLine';
import ArticleLine from './ArticleLine';
import { PackageLineProps } from './types';

const PackageLine: React.FunctionComponent<PackageLineProps> = function (lineProps) {
    const { article } = lineProps;

    const { articleId, invoicingCategory } = article;

    const sectionTitleId = useSelector(sectionTitleArticleId);
    const lineBreakId = useSelector(lineBreakArticleId);

    const libArticle = invoicingCategory === 'LIB';
    const articleLine = !libArticle;
    const sectionLine = articleId === sectionTitleId;
    const lineBreakLine = articleId === lineBreakId;

    return (
        <>
            {sectionLine && <SectionLine {...lineProps} />}
            {lineBreakLine && <LineBreakLine {...lineProps} />}
            {articleLine && <ArticleLine {...lineProps} />}
        </>
    );
};

export default PackageLine;
