import * as React from 'react';
import { Box } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { TooltipedIcon } from '@europrocurement/flexy-components';
import { Feedback } from './types';

type ComponentProps = {
    feedbacks: Feedback[];
    lineIndex: number;
    width?: ResponsiveStyleValue<string | number>;
};

const LineFeedbacks: React.FunctionComponent<ComponentProps> = function ({
    feedbacks,
    lineIndex,
    width = '40px',
}) {
    const keyGenerate = (name: string) => `line_feedback_${name}_${lineIndex}`;

    return (
        <Box
            display="flex"
            sx={{ opacity: 0.3 }}
            justifyContent="space-between"
            alignItems="center"
            width={width}
            marginX="30px"
        >
            {feedbacks.map(
                ({ condition, tooltip, icon, feedbackName }) =>
                    condition && (
                        <TooltipedIcon
                            key={keyGenerate(feedbackName)}
                            tooltip={tooltip}
                            icon={icon}
                        />
                    ),
            )}
        </Box>
    );
};

export default LineFeedbacks;
