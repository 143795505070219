import React from 'react';
import { BooleanBadge } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Typography, useTheme } from '@mui/material';
import { UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { User } from '../UserTabs/type';

type UserAccessProps = {
    user: User;
    handleValidationModal: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => void;
};

type AccessListType = {
    field: string;
    label: string;
    value: boolean;
};

export const UserAccess: React.FunctionComponent<UserAccessProps> = function (props) {
    const { user, handleValidationModal } = props;
    const theme = useTheme();

    const accessList: Array<AccessListType> = [
        {
            field: 'isAdminApps',
            label: 'Admin Apps',
            value: user.isAdminApps || false,
        },
        { field: 'hasG3Access', label: 'G3', value: user.hasG3Access || false },
        {
            field: 'hasAccessL2DF',
            label: 'L2DF',
            value: user.hasAccessL2DF || false,
        },
    ];

    const accessRightsList: Array<AccessListType> = [
        {
            field: 'usdAppsAnnuaireOption',
            label: 'Annuaire - Droit de modif.',
            value: user.droitUtilisateur?.usdAppsAnnuaireOption || false,
        },
        {
            field: 'usdAppsJalgest',
            label: 'JAL GEST',
            value: user.droitUtilisateur?.usdAppsJalgest || false,
        },
        {
            field: 'usdAppsXy',
            label: 'Pointage XY',
            value: user.droitUtilisateur?.usdAppsXy || false,
        },
        {
            field: 'usdAppsMdc',
            label: 'Compo papier',
            value: user.droitUtilisateur?.usdAppsMdc || false,
        },
        {
            field: 'usdAppsMdcSpel',
            label: 'Compo SPEL',
            value: user.droitUtilisateur?.usdAppsMdcSpel || false,
        },
    ];

    return (
        <Box>
            {user.isDeleted ? (
                <Box data-testid="container" />
            ) : (
                <Box
                    sx={{
                        borderRadius: '15px',
                        padding: '10px',
                        marginTop: '10px',

                        gap: '15px',
                        backgroundColor: theme.palette.background.paper,
                        border: 1,
                        borderColor: theme.palette.grey[300],
                        display: 'flex',
                    }}
                >
                    <Typography
                        variant="l2dh7"
                        sx={{ margin: '5px 0 0 10px', minWidth: 'fit-content', height: '100%' }}
                    >
                        Accès :
                    </Typography>
                    <Box
                        display="flex"
                        sx={{ flexWrap: 'wrap', alignItems: 'center' }}
                    >
                        {accessList.map(({ field, label, value }) => (
                            <BooleanBadge
                                key={field}
                                label={label}
                                value={value}
                                icon
                                toggleAction={() =>
                                    handleValidationModal({ [field]: !value }, field, value)
                                }
                            />
                        ))}

                        {accessRightsList.map(({ field, label, value }) => (
                            <BooleanBadge
                                key={field}
                                label={label}
                                value={value}
                                icon
                                toggleAction={() => {
                                    handleValidationModal(
                                        {
                                            droitUtilisateur: { [field]: !value },
                                        },
                                        field,
                                        value,
                                    );
                                }}
                            />
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};
