import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionButton2 } from '../../atoms';

export type ToolbarPreviousActionProps = {
    path?: string;
};

const ToolbarPreviousAction: React.FunctionComponent<ToolbarPreviousActionProps> = function (
    props,
) {
    const navigate = useNavigate();
    const { path } = props;

    return (
        <ActionButton2
            onClick={() => (path ? navigate(path) : navigate(-1))}
            icon={['fasl', 'arrow-left']}
        />
    );
};

export default ToolbarPreviousAction;
