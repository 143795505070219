import { Box, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

export type BillingInfoCardProps = {
    label: string;
    value: ReactNode;
};

const BillingInfoCard: React.FunctionComponent<BillingInfoCardProps> = function (props) {
    const { label, value } = props;
    const { palette } = useTheme();

    const labelSx = {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '25.2px',
        marginBottom: '4px',
    };

    const wrapperSx = {
        display: 'flex',
        gap: '0 8px',
        flexWrap: 'wrap',
        borderRadius: '12px',
        padding: '6px 20px',
        background: palette.grey[100],
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '24px',
    };

    return (
        <Box data-testid="billing-info-card">
            <Box sx={labelSx}>{label}</Box>
            <Box sx={wrapperSx}>{value}</Box>
        </Box>
    );
};

export default BillingInfoCard;
