/* eslint-disable no-param-reassign */
import { AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem } from '@europrocurement/l2d-domain/openApi/ApiAnnoncesLegales';
import { TypeDeDocumentJsonldTypedocumentRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { createSlice } from '@reduxjs/toolkit';
import { OFFER_CACHED_KEY } from '../constants';

export type GenericCachedResult<T> = Array<T> | null;
type CacheddDocument = TypeDeDocumentJsonldTypedocumentRead['typeDocument'];
type CachedSubType = {
    label: string;
    value: number;
    category: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem['categorie'];
};
export type CachedOption = {
    label: string;
    value: number;
};

export type CachedStore = {
    documents: GenericCachedResult<CacheddDocument>;
    subTypes: GenericCachedResult<CachedSubType>;
    departments: GenericCachedResult<CachedOption>;
    origins: GenericCachedResult<CachedOption>;
    offers: GenericCachedResult<CachedOption>;
    groups: GenericCachedResult<CachedOption>;
    domains: GenericCachedResult<CachedOption>;
};

const initialCacheState: CachedStore = {
    documents: null,
    subTypes: null,
    departments: null,
    origins: null,
    offers: null,
    groups: null,
    domains: null,
};

const offerCacheSlice = createSlice({
    name: OFFER_CACHED_KEY,
    initialState: initialCacheState,
    reducers: {
        updateCachedDocuments(state, action) {
            state.documents = action.payload;
        },
        updateCachedSubTypes(state, action) {
            state.subTypes = action.payload;
        },
        updateCachedDepartments(state, action) {
            state.departments = action.payload;
        },
        updateCachedOrigins(state, action) {
            state.origins = action.payload;
        },
        updateCachedOffers(state, action) {
            state.offers = action.payload;
        },
        updateCachedGroups(state, action) {
            state.groups = action.payload;
        },
        updateCachedDomains(state, action) {
            state.domains = action.payload;
        },
    },
});

export const {
    updateCachedDocuments,
    updateCachedSubTypes,
    updateCachedDepartments,
    updateCachedOrigins,
    updateCachedOffers,
    updateCachedGroups,
    updateCachedDomains,
} = offerCacheSlice.actions;

export default offerCacheSlice;
