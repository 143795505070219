import React from 'react';
import { Card, Grid } from '@mui/material';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { BorderedBox, CustomDivider, Information } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { Box } from '@mui/system';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import useFormulaire from '../../hooks/useFormulaire';

type FormalityCardProps = {
    formality: Formalite;
    handleEditMainInformation: () => void;
};

const FormalityCard: React.FunctionComponent<FormalityCardProps> = function (props) {
    const { formality, handleEditMainInformation } = props;
    const { findFormulaire } = useFormulaire();
    const formulaire = formality.formulaire
        ? findFormulaire(formality.formulaire as unknown as number)
        : null;

    return (
        <Card>
            <BorderedBox
                actionBarProps={{ editAction: handleEditMainInformation }}
                borderWidth={0}
            >
                <Grid
                    container
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid
                        item
                        xs={12}
                        lg={6}
                    >
                        <BorderedBox
                            boxTitle="Informations générales"
                            borderWidth={3}
                        >
                            {formality?.iconeName ? (
                                <Box
                                    sx={{
                                        fontSize: '40px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FaOptionIcon
                                        icon={['fad', formality?.iconeName as IconName]}
                                        withShape
                                        shapeSize={60}
                                    />
                                </Box>
                            ) : null}

                            <Information
                                label="Libellé"
                                value={formality.libelle as string}
                            />
                            <CustomDivider />

                            <Information
                                label="Informations supplémentaires"
                                value={formality.informationsSupplementaires || undefined}
                            />

                            <CustomDivider />
                            <Information
                                label="Formulaire"
                                value={formulaire?.libelle}
                            />
                        </BorderedBox>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                    >
                        <BorderedBox
                            boxTitle="Informations complémentaires"
                            borderWidth={3}
                        >
                            <Grid
                                container
                                spacing={2}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                >
                                    <Information
                                        label="Catégorie"
                                        value={formality?.categorie?.libelle as string}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    sx={{ fontSize: '40px' }}
                                >
                                    {formality?.categorie?.iconeName ? (
                                        <FaOptionIcon
                                            icon={[
                                                'fad',
                                                formality?.categorie?.iconeName as IconName,
                                            ]}
                                            withShape
                                            shapeSize={60}
                                        />
                                    ) : null}
                                </Grid>
                            </Grid>

                            <CustomDivider />
                            <Information
                                label="Rang d'ordonnancement"
                                value={formality.rang}
                            />
                        </BorderedBox>
                    </Grid>
                </Grid>
            </BorderedBox>
        </Card>
    );
};

export default FormalityCard;
