import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { CodeRejet, CodeRejetApiObject } from '@europrocurement/l2d-domain';
import {
    ColumnDatatable,
    FilterDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { usePatchRejectCode } from '@b2d/hooks/rejectCodeHooks';
import CodeRejetSwitchesGroup from '../fragments/CodeRejetSwitchesGroup';

export type RejectCodesListComponentProps = {
    dataSource: DataSource<CodeRejet>;
    fetchData: getDataThunkType<CodeRejetApiObject>;
    filtersControl?: boolean;
    filterFilters?: (
        filtre: FilterDatatable,
        index: number,
        array: FiltersDatatableList,
    ) => unknown;
    disabledToggles?: Partial<Record<'manuel' | 'bloquant' | 'relanceAuto', boolean>>;
};

const RejectCodesListComponent: React.FunctionComponent<RejectCodesListComponentProps> = function ({
    dataSource,
    fetchData,
    filtersControl = true,
    filterFilters = () => true,
    disabledToggles,
}) {
    const navigate = useNavigate();

    if (!!filtersControl === true) {
        console.log(filtersControl, filterFilters);
    }

    // const handleCreateExceptionPublisherButtonClick = React.useCallback(() => {
    //     modalActions.call(
    //         <FormModal
    //             key={`${modalFrequencyPickFormContext}-${xIdSociete}`}
    //             title="ERCA1 > Choisir un fournisseur > Configurer Exception"
    //             icon={dateInput}
    //             context="Choisir le ou les fournisseurs pour lequel l'exception sur ERCA1 doit être effectuée."
    //             inputNature="select"
    //             choices={[
    //                 {
    //                     label: 'Fournisseur 1',
    //                 },
    //                 {
    //                     label: 'Fournisseur 2',
    //                     defaultSelected: true,
    //                 },
    //                 {
    //                     label: 'Fournisseur 3',
    //                 },
    //                 {
    //                     label: 'Fournisseur 4',
    //                 },
    //                 {
    //                     label: 'Fournisseur 5',
    //                 },
    //                 {
    //                     label: 'Fournisseur 6',
    //                 },
    //                 {
    //                     label: 'Fournisseur 7',
    //                 },
    //                 {
    //                     label: 'Fournisseur 8',
    //                 },
    //                 {
    //                     label: 'Fournisseur 9',
    //                 },
    //                 {
    //                     label: 'Fournisseur 10',
    //                 },
    //                 {
    //                     label: 'Fournisseur 11',
    //                 },
    //                 {
    //                     label: 'Fournisseur 12',
    //                 },
    //                 {
    //                     label: 'Fournisseur 13',
    //                 },
    //             ]}
    //             submitActions={[
    //                 {
    //                     type: 'confirm',
    //                     action: () => {
    //                         console.warn('TODO ! - Need to handle exception creation endpoint');
    //                         console.log('Confirmed ! ');
    //                     },
    //                 },
    //                 {
    //                     type: 'cancel',
    //                     action: () => {
    //                         console.warn('TODO ! - Need to handle exception creation endpoint');
    //                         console.log('Canceled ! ');
    //                     },
    //                 },
    //             ]}
    //         />,
    //     );
    // }, [modalActions, modalFrequencyPickFormContext, xIdSociete]);

    const patchRejectCode = usePatchRejectCode();

    const columns: ColumnDatatable<CodeRejet>[] = React.useMemo(
        () => [
            {
                label: 'Code',
                isDisplayed: true,
                render: ({ code }) => code,
                field: 'code',
            },
            {
                label: 'Libelle',
                isDisplayed: true,
                render: ({ libelle }) => libelle,
                field: 'libelle',
            },
            {
                label: 'Configuration',
                isDisplayed: true,
                onClickCell: () => {},
                render: (rejectCode) => (
                    <CodeRejetSwitchesGroup
                        fetchData={fetchData}
                        rejectCode={rejectCode}
                        patchRejectCode={patchRejectCode}
                        disabledToggles={disabledToggles}
                    />
                ),
            },
            {
                label: 'Actions',
                render: () => {},
                // code ? (
                //     <ListActionButton
                //         tooltip="Créer une exception fournisseur"
                //         onClick={(event) => {
                //             event.stopPropagation();
                //             handleCreateExceptionPublisherButtonClick();
                //         }}
                //         namedIcon={publisherIcon}
                //     />
                // ) : null,
                isDisplayed: true,
            },
        ],
        [disabledToggles, fetchData, patchRejectCode],
    );

    const filters: FiltersDatatableList = [];

    return (
        <StoreDatatable
            dataSource={dataSource}
            columns={columns}
            fetchData={fetchData}
            filters={filters}
            localStorageKey="FlexyStoreDatatableRejectCodes"
            localStorageRefreshDate={new Date('2023-06-23T09:42:00.000Z')}
            fetchWithoutFilter
            onClickRow={(e: React.MouseEvent<HTMLElement>, item: CodeRejet) =>
                navigate(`${item.code}`, {
                    replace: true,
                })
            }
            onWheelClickRow={(e: React.MouseEvent<HTMLElement>, item: CodeRejet) =>
                navigate(`${item.code}`, {
                    replace: true,
                })
            }
        />
    );
};

export default RejectCodesListComponent;
