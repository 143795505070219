import * as React from 'react';
import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { EmailLink, FlexyHeaderForm } from '@europrocurement/flexy-components';
import { SignataireType } from '@europrocurement/l2d-domain';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import SignatureStatus from './SignatureStatus';

export type SignataireCardProps = Pick<
    SignataireType,
    'nom' | 'prenom' | 'mobile' | 'email' | 'commentaire' | 'signature' | 'refus' | 'lien' | 'id'
>;

const SignataireCard: React.FunctionComponent<SignataireCardProps> = function (props) {
    // Styles
    const { nom, prenom, mobile, email, commentaire, signature, refus, lien, id } = props;

    const signataireRowsWrapperStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
    };

    const columnDisplayStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    };

    const renderRowStyle = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
    };

    const noteStyle = {
        paddingTop: '15px',
    };

    const capitalizeFirstLetter = (string: string) =>
        string
            .split(/\s/)
            .map((word) => {
                if (word && word !== '')
                    return word[0].toUpperCase() + word.substring(1).toLowerCase();
                return '';
            })
            .join(' ');

    const formatPhoneNumber = (phoneNumber: string) =>
        phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');

    const formatLastName = (string: string) => string.toUpperCase();

    const renderSignataireNameAndAvatar = () => (
        <Box
            sx={{ display: 'flex', gap: '12px' }}
            alignItems="start"
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    padding: '0px',
                    alignItems: 'start',
                }}
            >
                <Typography
                    display="inline"
                    sx={{
                        fontSize: '24px',
                    }}
                >
                    <SignatureStatus
                        tooltip
                        signataires={[{ signature, refus }]}
                    />
                </Typography>

                <Typography variant="h2">{`${capitalizeFirstLetter(prenom)} ${formatLastName(
                    nom,
                )}`}</Typography>
            </Box>
        </Box>
    );

    const renderCommentaire = () => (
        <>
            <FlexyHeaderForm
                label="Notes"
                outlined
                sx={{ paddingBottom: 0 }}
            />
            <span
                style={noteStyle}
                aria-hidden="true"
            >
                {commentaire}
            </span>
        </>
    );

    return (
        <Card sx={columnDisplayStyle}>
            {renderSignataireNameAndAvatar()}

            <>
                <FlexyHeaderForm
                    label="Coordonnées"
                    outlined
                    sx={{ paddingBottom: 0 }}
                />
                <Box sx={signataireRowsWrapperStyle}>
                    <Box>
                        <Box sx={renderRowStyle}>
                            <FaOptionIcon icon={faPhone} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0px 0px 0px 10px ',
                                }}
                            >
                                <Typography key={`${id}-${mobile}`}>
                                    {formatPhoneNumber(mobile)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={renderRowStyle}>
                            <FaOptionIcon icon={faEnvelope} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0px 0px 0px 10px ',
                                }}
                            >
                                <Typography key={`${id}-${email}`}>
                                    <EmailLink
                                        email={email}
                                        tooltip="Envoyer un mail a ce signataire"
                                    />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {lien ? (
                    <>
                        <FlexyHeaderForm
                            label="Lien de signature"
                            outlined
                            sx={{ paddingBottom: 0 }}
                        />
                        <Box sx={signataireRowsWrapperStyle}>
                            <Link
                                style={noteStyle}
                                target="_blank"
                                to={lien}
                            >
                                {lien}
                            </Link>
                        </Box>
                    </>
                ) : (
                    ''
                )}
            </>
            {commentaire && renderCommentaire()}
        </Card>
    );
};

export default SignataireCard;
