import React from 'react';
import { Box, useTheme } from '@mui/material';

export type CountBadgeProps = {
    count: number;
    color?: 'primary' | 'secondary' | 'info' | 'warning' | 'error';
    bgVariant?: 'main' | 'dark' | 'light';
    contrastText?: boolean;
};

const CountBadge: React.FunctionComponent<CountBadgeProps> = function ({
    count,
    color = 'primary',
    bgVariant = 'light',
    contrastText = false,
}) {
    const { palette } = useTheme();
    const textColor = contrastText ? palette[color].contrastText : 'inherit';
    const backgroundColor = palette[color][bgVariant];

    return (
        <Box
            data-testid="CountBadge-test-id"
            sx={{
                fontSize: '12px',
                fontWeight: '700',
                lineHeight: '14.4px',
                padding: '2px 5px',
                borderRadius: '16px',
                backgroundColor,
                color: textColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {count}
        </Box>
    );
};

export default CountBadge;
