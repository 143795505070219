import * as React from 'react';
import { Box, Button, DialogTitle, Grid } from '@mui/material';
import { useModal } from '@europrocurement/flexy-components/providers';

export type ModalConfirmProps = {
    modalTitle?: string | undefined;
    confirmLabel?: string | undefined;
    cancelLabel?: string | undefined;
    message?: string | undefined;
    onCancel?: () => void;
    onConfirm: () => void;
};

const ModalConfirm: React.FunctionComponent<ModalConfirmProps> = function (props) {
    const { modalTitle, confirmLabel, cancelLabel, message, onCancel, onConfirm } = props;
    const { modalActions } = useModal();

    const cancel = () => {
        modalActions.close();

        if (onCancel) {
            onCancel();
        }
    };

    const confirm = () => {
        modalActions.close();
        onConfirm();
    };

    const title = (
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            {modalTitle || 'Confirmation'}
        </DialogTitle>
    );
    const confirmBtn = (
        <Button
            color="primary"
            variant="contained"
            onClick={confirm}
            style={{ marginTop: '8px', marginBottom: '8px' }}
        >
            {confirmLabel || 'Confirmer'}
        </Button>
    );
    const cancelBtn = (
        <Button
            color="primary"
            variant="outlined"
            onClick={cancel}
            style={{ marginTop: '8px', marginBottom: '8px' }}
        >
            {cancelLabel || 'Annuler'}
        </Button>
    );

    return (
        <>
            {title}
            <Box>{message || 'Êtes-vous sûr de vouloir effectuer cette action ?'}</Box>
            <Box sx={{ marginTop: '20px' }}>
                <Grid
                    gap="0 20px"
                    justifyContent="flex-end"
                    container
                >
                    {cancelBtn}
                    {confirmBtn}
                </Grid>
            </Box>
        </>
    );
};

export default ModalConfirm;
