import { Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

export type UuidDisplayProps = {
    id?: string | null;
};

const UuidDisplay: React.FunctionComponent<UuidDisplayProps> = function ({
    id = "Pas d'id",
}: UuidDisplayProps) {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        if (!id) {
            console.error('Rien à copier !');
            return;
        }

        navigator.clipboard.writeText(id);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const shortenedId = id ? `${id.slice(0, 8)}...` : ' - ';

    return (
        <Tooltip
            title={copied ? 'Copié !' : 'Copier'}
            placement="bottom"
            enterDelay={copied ? 0 : 1000}
            enterNextDelay={500}
        >
            <Typography
                onClick={handleCopy}
                sx={{
                    cursor: id ? 'paste' : 'default',
                    transition: 'color 0.2s ease',
                    '&:hover': {
                        color: id ? 'primary.main' : 'inherit',
                    },
                }}
            >
                {shortenedId}
            </Typography>
        </Tooltip>
    );
};

export default UuidDisplay;
