import React, { ReactNode } from 'react';
import { Chip, darken, lighten, useTheme } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

const themeColor = ['primary', 'secondary', 'warning', 'success', 'error'] as const;
type ThemeColor = (typeof themeColor)[number];
type HexColor = string;

export type InformationChipProps = {
    label: ReactNode;
    color?: ThemeColor | HexColor;
    icon?: IconProp;
};

const InformationChip: React.FunctionComponent<InformationChipProps> = function ({
    label,
    icon,
    color = 'primary',
}) {
    const isThemeColor = (str: string): str is ThemeColor => themeColor.includes(str as ThemeColor);
    const { palette } = useTheme();
    const baseColor = isThemeColor(color) ? palette[color].main : color;
    const chiptStyle = {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: '14px',
        borderRadius: '8px',
        backgroundColor: lighten(baseColor, 0.92),
        color: darken(baseColor, 0.3),
        padding: '16px 7px',
    };

    const iconStyle = {
        fontSize: '0.7em',
        paddingLeft: '7px',
    };

    return (
        <Chip
            data-testid="InformationChip-test-id"
            label={label}
            {...(icon && {
                icon: (
                    <FaOptionIcon
                        data-testid="InformationChip-icon-test-id"
                        icon={icon}
                        sx={iconStyle}
                    />
                ),
            })}
            sx={chiptStyle}
        />
    );
};

export default InformationChip;
