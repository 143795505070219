import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import { Alert } from '@mui/material';
import { overrideStepSchema } from './validation';
import CatalogOverrideModule from '../Component/CatalogOverrideModule';
import useStepForm from '../../components/form/MultiStepForm/useStepForm';
import { MultiStepContextProps } from '../../components/form/MultiStepForm/types';

/** Form third step : Package overrides
 * 1) List all articles of package
 * 2) User can also add additional articles
 */
const OverrideStep: React.FunctionComponent<MultiStepContextProps> = function (props) {
    // 1a - Form definition / multi step behaviour

    const { multiStepForm } = props;
    const { setCurrentFormContext, formValues } = multiStepForm;

    const overrideStepForm = useForm({
        mode: 'onBlur',
        defaultValues: formValues,
        resolver: zodResolver(overrideStepSchema),
    });

    useStepForm(overrideStepForm, multiStepForm);

    React.useEffect(() => {
        setCurrentFormContext(overrideStepForm);
    }, [overrideStepForm, setCurrentFormContext]);

    return (
        <Box sx={{ marginTop: '15px' }}>
            <Box sx={{ marginBottom: '10px' }}>
                <FlexyHeaderForm
                    sx={{ marginTop: 0 }}
                    label="Contenu du package"
                    outlined
                />
            </Box>
            <Alert
                sx={{ marginTop: '20px' }}
                severity="info"
            >
                Pour chacun des articles suivants il est possible de surcharger le libellé, le prix
                unitaire ainsi que la quantité
            </Alert>
            <form noValidate>
                <CatalogOverrideModule context={overrideStepForm} />
            </form>
        </Box>
    );
};

export default OverrideStep;
