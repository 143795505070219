import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/system';
import { Backdrop, CircularProgress } from '@mui/material';
import { useParcoursFormalite } from './provider';

const ParcoursFormaliteOutlet: React.FunctionComponent = function () {
    const { loading } = useParcoursFormalite();

    return (
        <>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ marginTop: '20px' }}>
                <Outlet />
            </Box>
        </>
    );
};

export default ParcoursFormaliteOutlet;
