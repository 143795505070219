import { FlexyForm, FormObject, FormStructure } from '@europrocurement/flexy-form';
import {
    TypeDeDocumentJsonldTypedocumentCreate,
    TypeDeDocumentJsonldTypedocumentUpdate,
    TblTypeDocumentTypedocumentRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { Box } from '@mui/material';
import * as React from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { ModalContext } from '@europrocurement/flexy-components';
import { useSelector } from 'react-redux';
import {
    documentTypes,
    domainSelector,
    useOffreService,
    useSociete,
} from '@europrocurement/l2d-domain';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { FILE_NAME, maximumLengthRule } from '@europrocurement/l2d-modules/validation';
import { documentStatusOptions } from './options';
import { ModelForm } from '../forms/types';
import { formatPayload } from '../forms/format';

const Form: React.FunctionComponent<ModelForm<TblTypeDocumentTypedocumentRead>> = function (props) {
    const { documentModel: model } = useOffreService();
    const { entity = {}, isCreate = false, afterSubmit } = props;
    const { modalActions } = React.useContext(ModalContext);
    const { request } = useApiRequest();
    const companyId = useSociete();

    const EMPTY_FILE = entity.vide === true;

    const documentTypeOption = documentTypes.find((option) => option.value === entity.type);
    const documentStatusOption = documentStatusOptions.find(
        (option) => option.value === entity.statut,
    );

    const defaultValues = {
        ...entity,
        type: documentTypeOption,
        statut: documentStatusOption,
        libelleDF: null,
        fichierHelp: 'nom_de_fichier', // Not used for the moment
    };

    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues as FieldValues,
    });

    // Prefetched options
    const domainsOptions = useSelector(domainSelector).main.data;

    const formatOutputSchema = {
        type: (type: { value: number }) => type.value,
        statut: (statut: { value: number }) => statut.value,
        ...(isCreate && { domaine: (statut: { ['@id']: string }) => statut['@id'] }),
    };

    const DocumentFormStructure: FormStructure[] = [
        {
            type: 'text',
            name: 'libelle',
            inputlabel: 'Libellé',
            xs: 12,
            placeholder: 'Libellé du document',
            required: true,
            rules: {
                required: 'Vous devez renseigner le libelle',
                validate: {
                    ...maximumLengthRule(200),
                },
            },
        },
        {
            type: 'textarea',
            name: 'help',
            inputlabel: 'Explication / description du document',
            xs: 12,
            placeholder: 'Description du document ...',
            multiline: true,
            rows: 4,
            rules: {
                validate: {
                    ...maximumLengthRule(1000),
                },
            },
        },
        {
            type: 'text',
            name: 'name',
            inputlabel: 'Nom du fichier',
            xs: 12,
            placeholder: 'example_document',
            rules: {
                required: 'Vous devez renseigner le nom du fichier',
                pattern: {
                    value: FILE_NAME,
                    message: "L'exemple de nom de fichier est invalide",
                },
                ...maximumLengthRule(80),
            },
        },
        {
            type: 'select',
            name: 'type',
            inputlabel: 'Type de document',
            placeholder: 'Type de document',
            xs: 11,
            options: documentTypes,
            rules: {
                required: 'Vous devez renseigner le type de document',
            },
        },
        {
            type: 'select',
            name: 'statut',
            inputlabel: 'Statut du document',
            placeholder: 'Statut du document',
            xs: 12,
            options: documentStatusOptions,
            rules: {
                required: 'Vous devez renseigner le statut du document',
            },
        },
        {
            isDisplayable: !EMPTY_FILE,
            type: 'boolean',
            name: 'signable',
            inputlabel: 'Signable',
            xs: 12,
        },
        {
            isDisplayable: isCreate && !EMPTY_FILE, // No domain edition allowed
            type: 'select',
            name: 'domaine',
            inputlabel: 'Domaine',
            options: domainsOptions,
            getOptionLabel: (option: { libelle: string }) => option.libelle,
            getOptionValue: (option: { id: number }) => option.id.toString(),
            rules: {
                required: "La sélection d'un domaine est obligatoire",
            },
            xs: 12,
            placeholder: 'Sélectionnez un domaine',
        },
    ];

    const successCallback = () => {
        modalActions.reset();
        afterSubmit();
    };

    const createModel: SubmitHandler<FormObject> = async (formValues) => {
        const formData = formatPayload(
            formValues,
            formatOutputSchema,
        ) as TypeDeDocumentJsonldTypedocumentCreate;
        const createRequest = model.create({
            typeDeDocumentJsonldTypedocumentCreate: formData,
            xIdSociete: companyId,
        });
        await request(createRequest, { successCallback });
    };

    const updateModel: SubmitHandler<FormObject> = async (formValues) => {
        const { id } = formValues;
        if (id) {
            const formData = formatPayload(
                formValues,
                formatOutputSchema,
            ) as TypeDeDocumentJsonldTypedocumentUpdate;
            const updateRequest = model.update({
                id: id.toString(),
                typeDeDocumentJsonldTypedocumentUpdate: formData,
                xIdSociete: companyId,
            });
            await request(updateRequest, { successCallback });
        }
    };
    return (
        <Box>
            <FlexyForm
                formContext={formContext}
                formObject={(isCreate ? {} : entity) as FormObject}
                formStructure={DocumentFormStructure}
                onSubmit={isCreate ? createModel : updateModel}
            />
        </Box>
    );
};

export default Form;
