import React from 'react';
import { RouteObject } from 'react-router-dom';
import { ordersBasePath, orderDetailPath } from './constants';
import OrderRoot, { OrderDetail } from './views';

export const OrderRouter: RouteObject = {
    path: ordersBasePath,
    element: <OrderRoot />,
    children: [
        {
            path: orderDetailPath,
            element: <OrderDetail />,
        },
    ],
};
