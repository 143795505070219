import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-duotone-svg-icons';
import { Box, Input, useTheme } from '@mui/material';
import { type FlexyTextFieldProps } from '../../atoms';

export type SearchBarGlobalProps = {
    sx?: React.CSSProperties;
    placeHolder?: string;
    value: string | null | undefined;
    onChange: FlexyTextFieldProps['onChange'];
};

const SearchBarGlobal: React.FunctionComponent<SearchBarGlobalProps> = function ({
    sx,
    placeHolder,
    value,
    onChange,
}: SearchBarGlobalProps) {
    const [innerValue, setInnerValue] = React.useState(value || '');
    const theme = useTheme();
    const { palette } = theme;
    return (
        <Box
            sx={{
                backgroundColor: palette.background.default,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '15px',
                borderRadius: '12px',
                padding: '5px 15px',
                width: '100%',
                ...sx,
            }}
        >
            <FontAwesomeIcon icon={faSearch} />
            <Input
                value={innerValue}
                onChange={(e) => {
                    setInnerValue(e.target.value);
                    if (onChange) {
                        onChange(e);
                    }
                }}
                role="searchbox"
                placeholder={placeHolder || 'Rechercher'}
                sx={{
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    fontSize: '0.938rem', // 15px
                    lineHeight: '1.4',
                    '&:before': { borderBottom: '0' },
                }}
                inputMode="search"
                disableUnderline
                fullWidth
            />
        </Box>
    );
};

export default SearchBarGlobal;
