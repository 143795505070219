import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import DossierActionContext, { DossierAction } from './DossierActionContext';

const DossierActionProvider: React.FunctionComponent<PropsWithChildren> = function ({ children }) {
    const [dossiers, setDossiers] = useState<Array<DossierOpenSearch>>([]);
    const [currentAction, setCurrentAction] = useState<DossierAction>();
    const [dossiersUpdated, setDossiersUpdated] = useState<Array<DossierOpenSearch>>([]);

    const triggerAction = useCallback(
        (action: DossierAction, selectedDossiers: Array<DossierOpenSearch>) => {
            setCurrentAction(action);
            setDossiers(selectedDossiers);
            setDossiersUpdated([]);
        },
        [],
    );

    const cancelCurrentAction = useCallback(() => {
        setCurrentAction(undefined);
        setDossiers([]);
    }, []);

    const confirmCurrentAction = useCallback(() => {
        setCurrentAction(undefined);
        setDossiersUpdated(dossiers);
        setDossiers([]);
    }, [dossiers]);

    const contextValue = useMemo(
        () => ({
            dossiers,
            currentAction,
            dossiersUpdated,
            setDossiersUpdated,
            triggerAction,
            cancelCurrentAction,
            confirmCurrentAction,
        }),
        [
            dossiers,
            currentAction,
            dossiersUpdated,
            setDossiersUpdated,
            triggerAction,
            cancelCurrentAction,
            confirmCurrentAction,
        ],
    );

    return (
        <DossierActionContext.Provider value={contextValue}>
            {children}
        </DossierActionContext.Provider>
    );
};

export default DossierActionProvider;
