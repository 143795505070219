import { useMediaQuery, useTheme } from '@mui/system';
import React, { createContext, useContext } from 'react';

export type FullLayoutContextType = {
    isSidebarOpen: boolean;
    jobName?: string;
    setJobName: React.Dispatch<React.SetStateAction<string>>;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isCustomizerDrawerOpen: boolean;
    setCustomizerDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isProfileHeaderVisible: boolean;
    setProfileHeaderVisible: React.Dispatch<React.SetStateAction<boolean>>;
    toggleCustomizerDrawer: () => void;
};

export const FullLayoutContext = createContext<FullLayoutContextType>({
    isSidebarOpen: true,
    setSidebarOpen: () => {},
    isCustomizerDrawerOpen: false,
    setCustomizerDrawerOpen: () => {},
    isProfileHeaderVisible: true,
    setProfileHeaderVisible: () => {},
    toggleCustomizerDrawer: () => {},
    setJobName: () => {},
});

export type FullLayoutProviderProps = {
    children: React.ReactNode;
};

export const FullLayoutProvider: React.FunctionComponent<FullLayoutProviderProps> = function ({
    children,
}) {
    const theme = useTheme();

    const smBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

    const [isSidebarOpen, setSidebarOpen] = React.useState(smBreakpoint);
    const [jobName, setJobName] = React.useState<string | undefined>(undefined);
    const [isCustomizerDrawerOpen, setCustomizerDrawerOpen] = React.useState(false);
    const [isProfileHeaderVisible, setProfileHeaderVisible] = React.useState(true);

    const toggleCustomizerDrawer = React.useCallback(() => {
        setCustomizerDrawerOpen(!isCustomizerDrawerOpen);
    }, [isCustomizerDrawerOpen]);

    const providerValue = React.useMemo(
        () => ({
            jobName,
            setJobName,
            isSidebarOpen,
            setSidebarOpen,
            isCustomizerDrawerOpen,
            setCustomizerDrawerOpen,
            toggleCustomizerDrawer,
            isProfileHeaderVisible,
            setProfileHeaderVisible,
        }),
        [
            isCustomizerDrawerOpen,
            isProfileHeaderVisible,
            isSidebarOpen,
            jobName,
            toggleCustomizerDrawer,
        ],
    );

    return (
        <FullLayoutContext.Provider value={providerValue}>{children}</FullLayoutContext.Provider>
    );
};

export const useFullLayout = () => useContext(FullLayoutContext);
