/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEventHandler, ReactNode } from 'react';

import {
    useForm,
    FieldValues,
    FormProvider,
    SubmitHandler,
    UseFormReturn,
    FormState,
    SubmitErrorHandler,
} from 'react-hook-form';

// import { DevTool } from '@hookform/devtools';

import { GridProps, Button, Box } from '@mui/material';
import { FormStructureRenderer } from '../FormStructureRenderer/FormStructureRenderer';
import {
    CustomOptionResolvers,
    FlexyFormInputProps,
    FormObject,
    FormStructure,
} from './FlexyFormTypes';

const defaultSubmitButtonLabel = 'Valider';
const defaultSubmitButtonPosition = 'right';

export type FlexyFormSubmitButtonRender = (
    formstate: FormState<FormObject>,
    innerHandleSubmit: FormEventHandler<HTMLFormElement>,
) => ReactNode;

/**
 * Properties list of FlexyForm
 *
 * @param formStructure Object to modify through the form
 * @param inputsProps Params defining size of field
 * @param gridProps Positioning of field in grid
 * @param formObject Default values of form context (not used if form context is specified)
 * @param onSubmit Submission success callback
 * @param onInvalid Submission failure callback
 * @param formContext Define specific form context
 * @param submitButton Object defining the submit button of the form
 * @param isSubmitting Active form loading state
 * @param isDisabled Active form submission disablement
 */
export type FlexyFormPropsType<T extends FieldValues> = {
    formStructure: FormStructure[];
    inputsProps?: FlexyFormInputProps;
    gridProps?: GridProps;
    formObject?: T;
    onSubmit: SubmitHandler<T>;
    onInvalid?: SubmitErrorHandler<T>;
    formContext?: UseFormReturn<T, any>;
    submitButton?: {
        displayed?: boolean;
        position?: 'left' | 'right';
        label?: string;
        render?: FlexyFormSubmitButtonRender;
        style?: React.CSSProperties;
    };
    customOptionResolvers?: CustomOptionResolvers;
    isSubmitting?: boolean;
    isDisabled?: boolean | ((id: number) => boolean);
};

/**
 * Permet de générer dynamiquement un formulaire en fonction d'un tableau (formStructure) passé en props.
 * Le composant va remplir un objet javascript (formObject).
 * La props onSubmit connaît l'existence de l'objet formObject et va traiter la soumission du formulaire.
 *
 * ## Règles de validation
 * Les règles de validation sont importé de [react-hook-form](https://react-hook-form.com/get-started#Applyvalidation) et devront être spécifié dans l'objet formStructure sous la propriété 'rules'.
 *
 * ## usage
 * ```jsx
 *
 *  const formObject = {
 *          lastName: '',
 *          firstName: ''
 *        }
 *
 *  const FormStructure: FormStructure[] = [
 *      {
 *          type: 'text',
 *          name: 'lastName',
 *          fullWidth: true,
 *          inputlabel: 'Nom',
 *          placeholder: 'Votre Nom',
 *          rules: {
 *              required: 'Vous devez renseigner votre nom',
 *              minLength: {
 *                  value: 2,
 *                  message: "Votre nom ne peut pas contenir qu'un seul caractère",
 *              },
 *          },
 *      },
 *      {
 *          type: 'text',
 *          name: 'firstName',
 *          inputlabel: 'Prénom',
 *          fullWidth: true,
 *          placeholder: 'Votre prénom',
 *          rules: {
 *              required: 'Vous devez renseigner votre prénom',
 *              minLength: {
 *                  value: 2,
 *                  message: "Votre prénom ne peut pas contenir qu'un seul caractère",
 *              },
 *          },
 *      },
 *
 * <FlexyForm onSubmit={onSubmit} formObject={formObject} formStructure={formStructure} />
 * ```
 */
// let renderCount = 0;

export const inputDefaultProps: FlexyFormInputProps = {
    size: 'medium',
    fullWidth: true,
};

export const defaultGridProps: {
    rowSpacing: 0;
    columnSpacing: 2;
} = {
    rowSpacing: 0,
    columnSpacing: 2,
};

export const FlexyForm: React.FunctionComponent<FlexyFormPropsType<FormObject>> = function ({
    submitButton = {
        displayed: true,
        position: defaultSubmitButtonPosition,
        label: defaultSubmitButtonLabel,
        style: {},
    },
    customOptionResolvers,
    ...props
}: FlexyFormPropsType<FormObject>) {
    const {
        onSubmit,
        formStructure,
        inputsProps = inputDefaultProps,
        gridProps = defaultGridProps,
        formObject,
        formContext,
        onInvalid,
        isDisabled = false,
    } = props;

    const defaults = formObject;

    const methods =
        formContext ||
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useForm({
            mode: 'onTouched',
            defaultValues: defaults as { [x: string]: any },
        });

    // const { handleSubmit, formState } = methods;
    const { handleSubmit, formState } = methods;
    const isSubmitting = props.isSubmitting ?? formState.isSubmitting;

    const innerHandleSubmit: () => FormEventHandler<HTMLFormElement> = () =>
        handleSubmit(onSubmit, onInvalid);

    return (
        <FormProvider {...methods}>
            {/* <h6>rendercount {renderCount} </h6> */}
            {/* <DevTool control={control} /> */}
            {/* <ReactJson src={formState.errors} /> */}
            <form
                data-testid="FlexyForm"
                onSubmit={innerHandleSubmit()}
                noValidate
            >
                <FormStructureRenderer
                    structure={formStructure}
                    basePath=""
                    inputsProps={inputsProps}
                    gridProps={gridProps}
                    customOptionResolvers={customOptionResolvers}
                />
                {submitButton?.render ? (
                    submitButton.render(formState, innerHandleSubmit)
                ) : submitButton?.displayed ||
                  submitButton?.displayed === undefined ||
                  !submitButton ? (
                    <Box
                        display="flex"
                        justifyContent={
                            submitButton?.position === 'left' ? 'flex-start' : 'flex-end'
                        }
                        alignItems={submitButton?.position === 'left' ? 'flex-start' : 'flex-end'}
                    >
                        <Button
                            type="submit"
                            disabled={isSubmitting || isDisabled}
                            variant="contained"
                            style={{
                                marginTop: '2rem',
                                marginBottom: '16px',
                                ...submitButton?.style,
                            }}
                        >
                            {submitButton?.label || defaultSubmitButtonLabel}
                        </Button>
                    </Box>
                ) : null}
            </form>
        </FormProvider>
    );
};

export default FlexyForm;
