import React from 'react';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    FORMALITES_REDUCER_NAME,
    LEGAL_STATUS_SLICE_NAME,
    INVOICING_CATEGORIES_SLICE_NAME,
    OFFRE_REDUCER_NAME,
    FORMALITE_DOCUMENT_SLICE_NAME,
    FORMULAIRE_REDUCER_NAME,
    FORMULAIRE_FORMALITE_SLICE_NAME,
    CATEGORY_SLICE_NAME,
    VATS_SLICE_NAME,
    DOMAINS_SLICE_NAME,
    useOffreService,
    useFormaliteService,
    useFormulaireService,
    REGLEDECALCULS_SLICE_NAME,
    FormulaireStateType,
} from '@europrocurement/l2d-domain';
import { AnyAction } from '@reduxjs/toolkit';
import { OfferStateType } from '@europrocurement/l2d-domain/reducers/offre/types';
import { FormalityStateType } from '@europrocurement/l2d-domain/reducers/formalites/types';

/** Types */
export type LoadableCollection =
    | 'vats'
    | 'domains'
    | 'invoicingCategories'
    | 'formalityCategories'
    | 'legalStatuses'
    | 'formalityDocuments'
    | 'regleDeCalculs'
    | 'formulairesFormalites';
type CollectionNames = LoadableCollection[];
type FetchActions = Record<LoadableCollection, DataSourcesThunksType<unknown>>;
type FetchingStatus = 'idle' | 'loading' | 'succeeded';
type StatusesSelectors = (
    store: OfferStateType & FormalityStateType & FormulaireStateType,
) => Record<LoadableCollection, FetchingStatus>;

/** DataSourcesThunk mapping */

/** Statuses selectors */
const statusesSelector: StatusesSelectors = (
    store: OfferStateType & FormalityStateType & FormulaireStateType,
) => ({
    vats: store[OFFRE_REDUCER_NAME][VATS_SLICE_NAME].main.status as FetchingStatus,
    domains: store[OFFRE_REDUCER_NAME][DOMAINS_SLICE_NAME].main.status as FetchingStatus,
    regleDeCalculs: store[OFFRE_REDUCER_NAME][REGLEDECALCULS_SLICE_NAME].main
        .status as FetchingStatus,
    invoicingCategories: store[OFFRE_REDUCER_NAME][INVOICING_CATEGORIES_SLICE_NAME].main
        .status as FetchingStatus,
    formalityCategories: store[FORMALITES_REDUCER_NAME][CATEGORY_SLICE_NAME].main
        .status as FetchingStatus,
    legalStatuses: store[FORMALITES_REDUCER_NAME][LEGAL_STATUS_SLICE_NAME].main
        .status as FetchingStatus,
    formalityDocuments: store[FORMALITES_REDUCER_NAME][FORMALITE_DOCUMENT_SLICE_NAME].main
        .status as FetchingStatus,
    formulairesFormalites: store[FORMULAIRE_REDUCER_NAME][FORMULAIRE_FORMALITE_SLICE_NAME].main
        .status as FetchingStatus,
});

/**
 * Fetch api collections for a given list of required resources.
 * Result is stored in Redux store | Loading status is available as a return
 * @param collectionNames
 */
const useLoadStore = (collectionNames: CollectionNames) => {
    const {
        vatsDataSourcesThunks,
        domainsDataSourcesThunks,
        invoicingCategoryDataSourcesThunks,
        regleDeCalculsDataSourcesThunks,
    } = useOffreService();

    const {
        categoryFormaliteDataSourcesThunks,
        legalStatusDataSourcesThunks,
        formaliteDocumentDataSourcesThunks,
    } = useFormaliteService();

    const { formulaireFormaliteDataSourcesThunks } = useFormulaireService();

    const actionsThunks: FetchActions = {
        vats: vatsDataSourcesThunks,
        domains: domainsDataSourcesThunks,
        invoicingCategories: invoicingCategoryDataSourcesThunks,
        formalityCategories: categoryFormaliteDataSourcesThunks,
        legalStatuses: legalStatusDataSourcesThunks,
        formalityDocuments: formaliteDocumentDataSourcesThunks,
        formulairesFormalites: formulaireFormaliteDataSourcesThunks,
        regleDeCalculs: regleDeCalculsDataSourcesThunks,
    };

    const dispatch = useDispatch();

    const loadingStatuses = useSelector(statusesSelector);

    const fetchAction = (collectionName: LoadableCollection) => {
        console.log(collectionName);
        const action = actionsThunks[collectionName].main.getData;
        dispatch(action({}) as unknown as AnyAction);
    };

    const setPagination = (collectionName: keyof FetchActions) => {
        const noPagination: LoadableCollection[] = ['invoicingCategories'];
        if (noPagination.includes(collectionName)) {
            dispatch({
                type: `${collectionName}/setmainPagination`,
                payload: { page: 0 },
            });
        }
    };

    const proceed = () => {
        collectionNames.forEach((collectionName) => {
            setPagination(collectionName);
            fetchAction(collectionName);
        });
    };

    // On mount : No deps required
    React.useEffect(() => {
        proceed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLoadingStatus = () => {
        const statuses = collectionNames.map((collectionName) => loadingStatuses[collectionName]);

        const succeeded = statuses.every((status) => status === 'succeeded');
        const loading = statuses.some((status) => status === 'loading');

        if (loading) {
            return 'loading';
        }

        if (succeeded) {
            return 'succeeded';
        }

        return 'idle';
    };

    return { getLoadingStatus };
};

export default useLoadStore;
