import { Box, useTheme } from '@mui/material';
import React from 'react';
import BillingAddressCard from '../BillingAddressCard/BillingAddressCard';
import SelectBillingAddress, {
    SelectBillingAddressProps,
} from '../SelectBillingAddress/SelectBillingAddress';

export type BillingAddressProps = {
    prescriberBilled: boolean;
    prescriberLabel: string;
    prescriberSiren: string;
    prescriberAddress: string;
} & SelectBillingAddressProps;

const BillingAddress: React.FunctionComponent<BillingAddressProps> = function (props) {
    const {
        prescriberBilled,
        prescriberLabel,
        prescriberSiren,
        prescriberAddress,
        clientId,
        addresses,
        onChange: handleChange,
    } = props;
    const { palette } = useTheme();

    const labelSx = {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '25.2px',
        marginBottom: '4px',
    };

    const addressWrapperSx = {
        borderRadius: '12px',
        padding: '6px 20px',
        background: palette.grey[100],
    };

    return (
        <Box data-testid="billing-address">
            <Box sx={labelSx}>Adresse de facturation :</Box>
            {prescriberBilled && (
                <Box sx={addressWrapperSx}>
                    <BillingAddressCard
                        label={prescriberLabel}
                        siren={prescriberSiren}
                        address={prescriberAddress}
                    />
                </Box>
            )}
            {!prescriberBilled && (
                <SelectBillingAddress
                    clientId={clientId}
                    addresses={addresses}
                    onChange={handleChange}
                />
            )}
        </Box>
    );
};

export default BillingAddress;
