import * as React from 'react';

import {
    PackageJsonldPackageCreate,
    PackageJsonldPackageUpdate,
    PackagePackageRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { SubmitHandler } from 'react-hook-form';
import { FormObject } from '@europrocurement/flexy-form';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { ModalContext } from '@europrocurement/flexy-components';
import { articleIcon } from '@europrocurement/l2d-icons';
import { useOffreService, useSociete } from '@europrocurement/l2d-domain';
import { ModelForm } from '../../forms/types';
import ConfigurationStep from './ConfigurationStep';
import ArticleStep from './ArticleStep';
import RelationStep from './RelationsStep';
import { ArticlesSchema, ConfigurationSchema, PackageFormData } from './validation';
import { useRank } from './useRank';
import MultiStepForm from '../../components/form/MultiStepForm/MultiStepForm';
import { ReadOnlyDocumentItem, ReadOnlyDocuments } from '../../components/DocumentsList/types';
import LoadingScreen from '../../forms/LoadingScreen';
import { apiArticlesToFormEditableArticles } from '../../Article/format';

/** Package Form : Splitted form in 3 steps
 *
 * 1) Configuration : Package main informations
 * 2) Articles : Package's list of articles
 * 3) Additional relationships : Documents, additional fields..
 *
 * This component handle :
 *
 * - Form steps definition
 * - input/output data formating / form initial values | requests
 *
 */
const Form: React.FunctionComponent<ModelForm<PackagePackageRead>> = function (props) {
    const { offerPackageModel: model } = useOffreService();

    /** Props / api requests & modals */
    const { entity = {}, isCreate = false, afterSubmit } = props;
    const companyId = useSociete();
    const { modalActions } = React.useContext(ModalContext);
    const { request } = useApiRequest();
    const successCallback = () => {
        modalActions.reset();
        afterSubmit();
    };

    /** Step definitions */
    const formSteps = [
        {
            title: 'Configuration générale',
            component: ConfigurationStep,
        },
        {
            title: 'Contenu du package',
            component: ArticleStep,
        },
        {
            title: 'Informations complémentaires',
            component: RelationStep,
        },
    ];

    const { getRankByArrayIndex } = useRank();

    /** Format Package articlesform values to valid creation | update payload */
    const formArticlesToPayload = (articles: ArticlesSchema['articles']) =>
        articles.map((formArticle, arrayIndex) => ({
            article: formArticle.articleId, // FIX api type must be a defined number and not a in depth relationship
            libelle: formArticle.label,
            quantite: formArticle.quantity,
            rang: getRankByArrayIndex(arrayIndex),
            tarif: formArticle.priceInformations?.isFlatRate ? null : formArticle.price?.toString(),
            sType: formArticle.subTypeId, // FIX api type must be a defined number and not a in depth relationship
        }));

    /** Format Package documents values to valid creation | update payload */
    const formDocumentsToPayload = (documents: PackageFormData['documents']) =>
        documents.map((docId) => ({
            typeDocument: docId,
        }));

    /** Format Package additional field values to valid creation | update payload */
    const formAdditionalFieldToPayload = (additionalFields: PackageFormData['additionalFields']) =>
        additionalFields.map((fieldId) => ({
            champComplementaire: fieldId,
        }));

    /** Format Package form values to valid creation | update payload */
    const formValuesToPayload = (formData: PackageFormData) => ({
        libelle: formData.label,
        nomCommercial: formData.commercialName,
        domaine: formData.domainId,
        formalite: formData.formalityId,
        articles: formArticlesToPayload(formData.articles),
        champsComplementaires: formAdditionalFieldToPayload(formData.additionalFields),
        actions: [],
        documents: formDocumentsToPayload(formData.documents), // Editable documents (Directly related)
    });

    /** Api requests | Creation & Update */
    const createModel: SubmitHandler<FormObject> = async (formValues) => {
        const payload = formValuesToPayload(formValues as PackageFormData);
        const createRequest = model.create({
            packageJsonldPackageCreate: payload as unknown as PackageJsonldPackageCreate,
            xIdSociete: companyId,
        });
        await request(createRequest, { successCallback });
    };

    const updateModel: SubmitHandler<FormObject> = async (formValues) => {
        const payload = formValuesToPayload(formValues as PackageFormData);
        if (entity.id) {
            const updateRequest = model.update({
                id: entity.id.toString(),
                packageJsonldPackageUpdate: payload as unknown as PackageJsonldPackageUpdate,
                xIdSociete: companyId,
            });
            await request(updateRequest, { successCallback });
        }
    };

    /** Creation form | Default values  */
    const getDefaultValues = () => ({
        label: '',
        commercialName: '',
        domainId: 0,
        formalityId: null,
        articles: [],
        actions: [],
        additionalFields: [],
        documents: [],
        readOnlyDocuments: {
            articles: [],
            options: [],
            formalities: [],
        }, // ReadOnly related documents (Articles documents / Formalities ...)
    });

    /** Update form | Default values from entity methods  */

    /** Step 1 Initial values */
    const getConfiguration = (): ConfigurationSchema => ({
        label: entity.libelle ?? '',
        commercialName: entity.nomCommercial ?? '',
        domainId: entity.domaine?.id ?? 0,
        formalityId: entity.formalite?.id,
    });

    /** Step 2 Initial values | Format Api values -> Form values */

    /** Step 3 Initial values : Todo implement */
    const getActions = () => [];
    const getAdditionalFields = () => ({
        additionalFields: isCreate
            ? []
            : entity.champsComplementaires?.map(
                  (champsComplementaire) => champsComplementaire?.champComplementaire,
              ),
    });

    const getDocuments = () => ({
        documents: isCreate ? [] : entity.documents?.map((document) => document?.typeDocument),
    });

    /** Collect all document and format them with related article label */
    const formatDocumentsFromArticles: () => ReadOnlyDocumentItem[] = () => {
        const { articles } = entity;
        const collectedAndFormattedDocuments = articles
            ?.map((article) =>
                article.article?.documents?.map(({ typeDocument: articleDocument }) => {
                    const articleLabel = article.article?.libelle;
                    const document = {
                        label: (articleDocument?.libelle as string) ?? '',
                        subTitle: (articleDocument?.help as unknown as string) ?? '',
                        originEntityName: 'Article',
                        originName: articleLabel as string,
                        originIcon: articleIcon,
                        id: articleDocument?.id as number,
                    };
                    return document;
                }),
            )
            .flat();
        const result = collectedAndFormattedDocuments?.filter((data) => data !== undefined);

        return result ?? [];
    };

    const getReadOnlyDocuments: () => { readOnlyDocuments: ReadOnlyDocuments } = () => {
        const articlesDocuments =
            formatDocumentsFromArticles() as unknown as Array<ReadOnlyDocumentItem>;
        const optionsDocuments = [] as Array<ReadOnlyDocumentItem>;
        const formalitiesDocuments = [] as Array<ReadOnlyDocumentItem>;

        return {
            readOnlyDocuments: {
                articles: articlesDocuments,
                options: optionsDocuments,
                formalities: formalitiesDocuments,
            }, // ReadOnly related documents (Articles documents / Formalities ...)
        };
    };

    /** Update form | Default values from api  */
    const getPackageFromEntity = () => ({
        ...getConfiguration(),
        ...apiArticlesToFormEditableArticles(entity.articles ?? []),
        ...getActions(),
        ...getAdditionalFields(),
        ...getDocuments(),
        ...getReadOnlyDocuments(),
    });

    const initiaValues = isCreate ? getDefaultValues() : getPackageFromEntity();
    const formAction = isCreate ? createModel : updateModel;

    const updateFormIsLoaded = !isCreate;
    // && relatedArticlesAreLoaded;
    const formIsReady = isCreate || updateFormIsLoaded;

    if (!formIsReady) {
        return <LoadingScreen />;
    }

    return (
        <MultiStepForm
            steps={formSteps}
            initiaValues={initiaValues}
            submitAction={formAction}
            afterSubmit={afterSubmit}
        />
    );
};

export default Form;
