/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { useSelector } from 'react-redux';
import { customizerSelector, OptionsSelector } from '@b2d/redux/RootStore';
import {
    EMAIL_VALIDATION_REGEX,
    LANDLINE_PHONE_NUMBER_REGEX,
    MOBILE_PHONE_NUMBER_REGEX,
    POST_CODE_VALIDATION_REGEX,
    exactLengthDigitsRule,
} from '@europrocurement/l2d-modules/validation';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Badge, Box, Card, Grid, Tooltip } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CreateIcon from '@mui/icons-material/Create';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';

import ExistingPrescribersModal from '@b2d/pages/Prescripteurs/component/ExistingPrescribersModal';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { useTiersService } from '@europrocurement/l2d-domain';
import CustomButtons from './CustomButtons';
import { formatPayload } from './dataStructure';
import { useSiret } from '../../detail/forms/useSiret';

export type ModalStateType = {
    isOpen: boolean;
    isTriggered: boolean;
};

const PrescriberForm: React.FunctionComponent = function () {
    // Utils / selectors
    const navigate = useNavigate();
    const { xIdSociete } = useSelector(customizerSelector);
    const optionsStore = useSelector(OptionsSelector);
    const counrtyOptionsCodeIso2 = optionsStore.countries.map((country) => country.codeIso2);
    const { prescribersApi } = useTiersService();
    // Popin logic

    const modalInitialState: ModalStateType = {
        isOpen: false,
        isTriggered: false,
    };

    const [modalStatus, setModalStatus] = React.useState<ModalStateType>(modalInitialState);
    const onModalClose = () => setModalStatus({ ...modalStatus, isOpen: false });

    const onExistingPrescribers = () => {
        setModalStatus({
            ...modalStatus,
            isOpen: true,
        });
    };

    // Options config
    const defaultCountryOption = optionsStore.countries.find(
        ({ codeIso2 }: { codeIso2: string }) => codeIso2 === 'FR',
    );
    const defaultCspOption = optionsStore.cspOptions.find(({ id }: { id: number }) => id === 0);
    const defaultWalletOption = optionsStore.walletOptions.find(
        ({ id }: { id: number }) => id === 1,
    );

    // FormContext
    const formDefaultValues: { [x: string]: any } = {
        codePays: defaultCountryOption,
    };

    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: formDefaultValues,
    });

    const { siretStatus, siretValidation, prescribersRelatedToSiret, prescribersLoaded } =
        useSiret(formContext);

    // On prescribersFetched
    React.useEffect(() => {
        if (prescribersLoaded && prescribersRelatedToSiret && !modalStatus.isTriggered) {
            if (prescribersRelatedToSiret.length > 0)
                setModalStatus({ isOpen: true, isTriggered: true });
        }
    }, [modalStatus.isTriggered, prescribersLoaded, prescribersRelatedToSiret]);

    const redirectToPrescriberDetail = (prescriberId: number) => {
        const prescriberDetailUrl = `/prescripteurs/liste/${prescriberId}`;
        navigate(prescriberDetailUrl);
    };

    const { request, requestState } = useApiRequest();

    const handleSiretInput = (event: { target: { value: string } }) => {
        const { value } = event.target;
        siretValidation(value);
    };

    const onSubmit = async (formData: { [key: string]: unknown }) => {
        const payload = formatPayload(formData, xIdSociete);

        const successCallback = (result: { data: { id: number } }) => {
            const prescriberId = result.data?.id;
            if (prescriberId) {
                redirectToPrescriberDetail(prescriberId);
            }
        };

        request(
            prescribersApi.createPrescripteurTiersCollection({
                tiersPrescripteurJsonldPrescripteurCreate: payload,
                xIdSociete,
            }),
            {
                successCallback,
            },
        );
    };

    const mainInformationsFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <CreateIcon sx={{ marginRight: '5px' }} />
                    Informations principales
                </>
            ),
            name: 'main_informations',
        },
        {
            type: 'text',
            name: 'raisonSociale',
            inputlabel: 'Raison sociale',
            xs: 6,
            // lg: 4,
            rules: {
                required: 'Vous devez renseigner une raison sociale',
            },
            placeholder: 'Raison sociale',
        },
        {
            type: 'boolean',
            name: 'personnePhysique',
            inputlabel: 'Personne physique',
            defaultValue: false,
            sm: 3,
            xs: 6,
            sx: { marginTop: '40px' },
        },
        {
            type: 'boolean',
            name: 'demo',
            inputlabel: 'Mode démonstration',
            defaultValue: false,
            sm: 3,
            xs: 12,
            sx: { marginTop: '40px' },
        },

        {
            type: 'number',
            name: 'siret',
            inputlabel: 'SIRET',
            xs: 11,
            lg: 4,
            placeholder: 'SIRET',
            value: siretStatus.value,
            rules: {
                validate: {
                    siret: () => siretStatus.isValid || 'Siret invalide',
                    ...exactLengthDigitsRule(14, 'Siret'),
                },
                onChange: (siretValue) => handleSiretInput(siretValue),
            },
        },
        {
            type: 'customItem',
            name: 'comptabilite',
            xs: 1,
            renderField: (currentFormContext: UseFormReturn) => {
                const numberOfExistingsPrescribers = prescribersRelatedToSiret?.length || 0;
                const isBadgeActive = !siretStatus.isEmpty && siretStatus.isValid;
                const canInteract = isBadgeActive && numberOfExistingsPrescribers > 0;

                const getSiretTooltipTitle = () => {
                    if (!isBadgeActive) {
                        return '';
                    }
                    if (numberOfExistingsPrescribers > 0) {
                        return `Voir le(s) ${numberOfExistingsPrescribers} prescripteur(s) associé(s) à ce numéro de Siret`;
                    }
                    return 'Aucun prescripteur associé à ce numéro de Siret ';
                };

                const formDisplaySiretError = currentFormContext.formState.errors.siret?.message;

                return (
                    <Tooltip title={getSiretTooltipTitle()}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="end"
                            sx={{
                                height: '100%',
                                cursor: canInteract ? 'pointer' : '',
                                paddingBottom: formDisplaySiretError ? '24px' : '0px',
                            }}
                        >
                            <Badge
                                badgeContent={isBadgeActive ? numberOfExistingsPrescribers : null}
                                color="primary"
                                onClick={
                                    numberOfExistingsPrescribers > 0
                                        ? onExistingPrescribers
                                        : () => {}
                                }
                            >
                                <PersonIcon
                                    fontSize="large"
                                    color="action"
                                />
                            </Badge>
                        </Box>
                    </Tooltip>
                );
            },
        },

        {
            type: 'text',
            name: 'siren',
            inputlabel: 'SIREN',
            xs: 12,
            lg: 3,
            placeholder: 'SIREN',
            disabled: true,
        },
        {
            type: 'text',
            name: 'tvaIntracommunautaire',
            inputlabel: 'TVA Intracommunautaire',
            xs: 12,
            lg: 4,
            placeholder: 'TVA',
        },
    ];

    const groupsFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <GroupsIcon sx={{ marginRight: '5px' }} />
                    Type de prescripteur
                </>
            ),
            xs: 12,
            name: 'groups',
        },
        {
            type: 'select',
            name: 'portefeuille',
            inputlabel: 'Portefeuille',
            options: optionsStore.walletOptions,
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: string }) => id,
            defaultValue: defaultWalletOption,
            xs: 12,
        },
        {
            type: 'select',
            name: 'csp',
            inputlabel: 'Catégorie socioprofessionnelle',
            options: optionsStore.cspOptions,
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: string }) => id,
            defaultValue: defaultCspOption,
            xs: 12,
        },
    ];

    const getValidation = (option: { codeIso2: string }) =>
        counrtyOptionsCodeIso2.includes(option.codeIso2) ||
        'Le code pays doit être au format ISO 3166-1 alpha-2';

    const addressFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <LocationOnIcon sx={{ marginRight: '5px' }} />
                    Adresse
                </>
            ),
            xs: 12,
            name: 'address',
        },
        // Required fields
        {
            type: 'text',
            name: 'adresse1',
            inputlabel: 'Addresse 1',
            xs: 12,
            lg: 12,
            rules: {
                required: 'Vous devez renseigner une adresse',
            },
            placeholder: 'Numéro, rue ...',
        },
        {
            type: 'text',
            name: 'adresse2',
            inputlabel: 'Adresse 2',
            xs: 12,
            lg: 12,
            placeholder: 'Appartement, batiment, entrée ...',
        },
        {
            type: 'text',
            name: 'adresse3',
            inputlabel: 'Adresse 3',
            xs: 12,
            lg: 6,
            placeholder: "Complément d'adresse ...",
        },
        {
            type: 'text',
            name: 'adresse4',
            inputlabel: 'Adresse 4',
            xs: 12,
            lg: 6,
            placeholder: 'cedex ? Boite postale ...',
        },
        {
            type: 'number',
            name: 'codePostal',
            inputlabel: 'Code Postal',
            sm: 12,
            md: 3,
            rules: {
                required: 'Vous devez renseigner le code postal',
                pattern: {
                    value: POST_CODE_VALIDATION_REGEX,
                    message: 'Le code postal doit être constitué de 5 chiffres',
                },
            },
        },
        {
            type: 'text',
            name: 'ville',
            inputlabel: 'Ville',
            sm: 12,
            lg: 5,
            rules: {
                required: 'Vous devez renseigner une ville',
            },
        },

        {
            type: 'select',
            name: 'codePays',
            inputlabel: 'code Pays',
            rules: {
                required: 'Vous devez renseigner un code pays',
                validate: {
                    countryCodeIso2: (option) => getValidation(option),
                },
            },
            sm: 12,
            md: 3,
            lg: 4,
            options: optionsStore.countries,
            getOptionLabel: (option: { libelle: string }) => option.libelle,
            getOptionValue: (option: { codeIso2: string }) => option.codeIso2,
        },
    ];

    const contactFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <PersonIcon sx={{ marginRight: '5px' }} />
                    Contact
                </>
            ),
            xs: 12,
            name: 'contact',
        },
        {
            type: 'select',
            inputlabel: 'Civilité',
            name: 'civilite',
            xs: 12,
            lg: 12,
            options: optionsStore.civilities,
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: string }) => id,
            placeholder: 'Civilité',
        },
        {
            type: 'text',
            name: 'nomContact',
            inputlabel: 'Nom',
            xs: 12,
            lg: 6,
            rules: {
                required: 'Vous devez renseigner le nom',
            },
        },
        {
            type: 'text',
            name: 'prenomContact',
            inputlabel: 'Prénom',
            xs: 12,
            lg: 6,
            rules: {
                required: 'Vous devez renseigner le prénom',
            },
        },
        {
            type: 'text',
            name: 'telephone',
            xs: 12,
            lg: 6,
            inputlabel: 'Téléphone',
            rules: {
                pattern: {
                    value: LANDLINE_PHONE_NUMBER_REGEX,
                    message: 'Le numero de téléphone est invalide',
                },
            },
        },
        {
            type: 'text',
            name: 'mobile',
            lg: 6,
            inputlabel: 'Mobile',
            xs: 12,
            rules: {
                pattern: {
                    value: MOBILE_PHONE_NUMBER_REGEX,
                    message: 'Le numero de téléphone est invalide',
                },
            },
        },
        {
            type: 'text',
            name: 'email',
            inputlabel: 'Email',
            xs: 12,
            lg: 12,
            rules: {
                required: "Vous devez renseigner l'email",
                pattern: {
                    value: EMAIL_VALIDATION_REGEX,
                    message: "L'Email est invalide",
                },
            },
        },
    ];

    const formProps = {
        formContext,
        formObject: {},
        onSubmit,
        submitButton: {
            displayed: false,
        },
    };

    return (
        <Card>
            <Box>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        lg={8}
                    >
                        <FlexyForm
                            formStructure={mainInformationsFormStructure}
                            {...formProps}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={4}
                    >
                        <FlexyForm
                            formStructure={groupsFormStructure}
                            {...formProps}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={8}
                    >
                        <FlexyForm
                            formStructure={addressFormStructure}
                            {...formProps}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={4}
                    >
                        <FlexyForm
                            formStructure={contactFormStructure}
                            {...formProps}
                        />
                    </Grid>
                </Grid>
            </Box>
            <CustomButtons
                onSubmit={formContext.handleSubmit(onSubmit)}
                isSubmitting={requestState.isProcessing}
            />
            <ExistingPrescribersModal
                existingPrescribers={prescribersRelatedToSiret}
                modalStatus={modalStatus}
                onModalClose={onModalClose}
            />
        </Card>
    );
};

export default PrescriberForm;
