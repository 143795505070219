import { MenuEntry } from '@europrocurement/flexy-components';
import { dossiersBasePath } from './constants';

export const DossierMenuEntries: MenuEntry[] = [
    {
        title: 'Formalités',
        icon: 'folders',
        children: [
            {
                title: 'Dossiers',
                icon: 'folders',
                href: `/${dossiersBasePath}`,
            },
        ],
    },
];
