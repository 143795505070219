import { ContactJsonldTiersReadCiviliteEnum } from '../openApi/ApiTiers';

export type Civilite = {
    id: ContactJsonldTiersReadCiviliteEnum;
    libelle: string;
    abreviation: string;
};

const civilites: Civilite[] = [
    {
        id: 102,
        libelle: 'Monsieur',
        abreviation: 'M.',
    },
    {
        id: 103,
        libelle: 'Madame',
        abreviation: 'Mme',
    },
    {
        id: 3509,
        libelle: 'Maître',
        abreviation: 'Me',
    },
    {
        id: 4520,
        libelle: 'Messieurs',
        abreviation: 'MM.',
    },
    {
        id: 4521,
        libelle: 'Mesdames',
        abreviation: 'Mmes',
    },
    {
        id: 4522,
        libelle: 'Maîtres',
        abreviation: 'Mes',
    },
];

export default civilites;
