import { TableBody, TableCell, TableRow, Typography } from '@mui/material';

import React, { useEffect } from 'react';
import useSelectItem from '../FlexyDatatableTable/useSelectItem';
import { FlexyDatatableBodySuccededRow } from '../FlexyDatatableBodySuccededRow';
import {
    FlexyDatatableBodySuccededRowProps,
    tableCellSx,
} from '../FlexyDatatableBodySuccededRow/FlexyDatatableBodySuccededRow';

export type FlexyDatatableBodySuccededProps<T extends Record<string, unknown>> = {
    data: Array<T>;
} & Omit<FlexyDatatableBodySuccededRowProps<T>, 'item'>;

export type FlexyDatatableData<T extends Record<string, unknown>> = T & {
    datatableIdentifier: string;
};

const FlexyDatatableBodySucceded = function <T extends Record<string, unknown>>({
    data,
    columns,
    ...rowProps
}: FlexyDatatableBodySuccededProps<T>) {
    const datatableDatas: Array<FlexyDatatableData<T>> = data.map((item, index) => ({
        ...item,
        datatableIdentifier: `datatableIdentifier${index}`,
    }));

    const { setCurrentData } = useSelectItem();

    useEffect(() => {
        setCurrentData(data);
    }, [data, setCurrentData]);

    const innerColumns = columns.filter((column) => column.isDisplayed !== false);

    return (
        <TableBody
            data-testid="FlexyDatatableBody"
            sx={{
                backgroundColor: 'transparent',
                overflow: 'hidden',
            }}
        >
            {' '}
            {datatableDatas.length !== 0 ? (
                datatableDatas.map((item: FlexyDatatableData<T>) => (
                    <FlexyDatatableBodySuccededRow
                        columns={innerColumns}
                        key={item.datatableIdentifier}
                        item={item}
                        {...rowProps}
                    />
                ))
            ) : (
                <TableRow
                    sx={{
                        height: '70px',
                    }}
                >
                    <TableCell
                        align="center"
                        colSpan={innerColumns.length}
                        sx={{
                            ...tableCellSx,
                            backgroundColor: 'danger',
                        }}
                    >
                        <Typography variant="l2dbodym">Rien à afficher</Typography>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    );
};

export default FlexyDatatableBodySucceded;
