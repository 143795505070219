import { useSociete, type FactureAchat } from '@europrocurement/l2d-domain';
import {
    FactureAchatApiGetDossiersFactureAchatItemRequest,
    FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollectionRequest,
    FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest,
    FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest,
    FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItemRequest,
    FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollectionRequest,
    FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { factureAchatApi, factureAchatLigneApi } from '@b2d/redux/RootStore';
import useToaster from '@b2d/hooks/useToaster';

import { useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';
import models from '@b2d/pages/Achats/models';
import { type FactureFormObject } from '../types';
import formProductLinesToApiProductLines from './dataTransformers/formToApi/formProductLinesToApiProductLines';

export const useGetDossiersFromAchat = function () {
    const xIdSociete = useSociete();

    return useMemo(
        () => async (idFacture: number, options?: AxiosRequestConfig) => {
            const requestParameters: FactureAchatApiGetDossiersFactureAchatItemRequest = {
                id: `${idFacture}`,
                xIdSociete,
            };

            const dossiersRes = await factureAchatApi.getDossiersFactureAchatItem(
                requestParameters,
                options,
            );
            if (dossiersRes.data.dossiers) {
                return dossiersRes.data.dossiers;
            }
            return [];
        },
        [xIdSociete],
    );
};

export const useGetLignes = function () {
    const xIdSociete = useSociete();

    return useMemo(
        () => (idFacture: number, idsDossiers: number[], options?: AxiosRequestConfig) => {
            const requestParameters: FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollectionRequest =
                {
                    xIdSociete,
                    page: 1,
                    itemsPerPage: 1000,
                    factureId: idFacture,
                    idDossier2: idsDossiers,
                };

            return factureAchatLigneApi
                .getLignesFactureAchatFactureAchatLigneCollection(requestParameters, options)
                .then((lignesRes) => {
                    if (lignesRes.data) {
                        return lignesRes.data['hydra:member'];
                    }
                    return [];
                });
        },
        [xIdSociete],
    );
};

export const useGetLignesFpro = function () {
    const xIdSociete = useSociete();

    return async (idFacture: number, idsDossiers: number[], options?: AxiosRequestConfig) => {
        const requestParameters: FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollectionRequest =
            {
                xIdSociete,
                page: 1,
                itemsPerPage: 1000,
                factureId: idFacture,
                idDossierFpro2: idsDossiers,
            };

        const lignesRes =
            await factureAchatLigneApi.getLignesFactureAchatFactureAchatLigneCollection(
                requestParameters,
                options,
            );
        if (lignesRes.data) {
            return lignesRes.data['hydra:member'];
        }
        return [];
    };
};

export type DeleteLigneProps = {
    idLigne: string;
    options?: AxiosRequestConfig;
    rawReturn?: boolean;
};

export const useDeleteLigne = function () {
    const xIdSociete = useSociete();

    return async ({
        idLigne,
        options,
    }: DeleteLigneProps): ReturnType<typeof models.invoicePurchase.deleteLine> => {
        const requestParameters: FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItemRequest =
            {
                id: idLigne,
                xIdSociete,
            };

        return models.invoicePurchase.deleteLine(requestParameters, options);
    };
};

export const usePostLigne = function () {
    const xIdSociete = useSociete();

    return async (
        ligne: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate,
        options?: AxiosRequestConfig,
    ): Promise<boolean> => {
        const requestParameters: FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest =
            {
                factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate: ligne,
                xIdSociete,
            };

        const lignesRes = await models.invoicePurchase.createLine(requestParameters, options);

        if (lignesRes.status === 201) {
            return true;
        }

        return false;
    };
};

export const useUpdateLigne = function () {
    const deleteLigne = useDeleteLigne();
    const addLigne = usePostLigne();

    return async (
        ligneIdToDelete: number | string,
        ligneToAdd: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate,
        deleteLigneOptions?: AxiosRequestConfig,
        addLignesOptions?: AxiosRequestConfig,
    ) => {
        const deleteResponse = await deleteLigne({
            idLigne: ligneIdToDelete.toString(),
            options: deleteLigneOptions,
        });

        if (deleteResponse.status === 204) {
            const addResponse = await addLigne(ligneToAdd, addLignesOptions);

            if (addResponse) {
                return true;
            }
        }

        return false;
    };
};

export const useGetRejets = function () {
    // const [rejetsStatus, setRejetsStatus] = useState<EuroprocApiResponseStatus>('idle');

    const xIdSociete = useSociete();

    return async (idFacture: number, idsDossiers: number[], options?: AxiosRequestConfig) => {
        // if(rejetsStatus === 'idle'){
        // setRejetsStatus('loading');
        const requestParameters: FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollectionRequest =
            {
                xIdSociete,
                page: 1,
                itemsPerPage: 1000,
                factureId: idFacture,
                idDossier2: idsDossiers,
            };

        const rejetsRes =
            await factureAchatApi.getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(
                requestParameters,
                options,
            );
        if (rejetsRes.data) {
            const apirejets = rejetsRes.data['hydra:member'];
            // setRejetsStatus('succeeded');
            return apirejets;
        }
        // setRejetsStatus('failed');

        // }
        return [];
    };
};

/**
 * Synchronise la facture avec G3.
 *
 * @param factureAchatId string
 * @returns Promise<boolean>
 */
export const useSynchroG3Facture = function () {
    const { handleApiResponse } = useToaster();
    const xIdSociete = useSociete();

    return async function (factureAchatId: string, options?: AxiosRequestConfig): Promise<boolean> {
        const requestParameters: FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest = {
            xIdSociete,
            id: `${factureAchatId}`,
            factureAchatJsonldFactureAchatSyncG3: {
                syncG3: true,
            },
        };

        const syncG3 = models.invoicePurchase.syncG3(requestParameters, options);

        await handleApiResponse(syncG3, undefined, false);

        return Promise.resolve(true);
    };
};

/**
 * Prépare le formulaire pour la mise à jour des lignes de la facture d'achat.
 *
 * @param factureForm
 * @param currentFacture
 * @param hasSetRsf
 * @param dossier
 * @returns
 */
export const useUpdateDossierOfMultiAnnonce = function () {
    const getLignes = useGetLignes();
    const deleteLigne = useDeleteLigne();
    const addLigne = usePostLigne();

    return async function (
        factureForm: FactureFormObject,
        currentFacture: FactureAchat,
        currentDossierId: number,
        getLignesOptions?: AxiosRequestConfig,
        deleteLignesOptions?: AxiosRequestConfig,
        addLignesOptions?: AxiosRequestConfig,
    ): Promise<boolean> {
        let currentLignes: Array<FactureAchatLigneJsonldFactureAchatRead> = [];

        if (!currentFacture.id || !currentFacture['@id']) {
            console.error('id et/ou @id de la facture courante manquant(s) !');
            return Promise.resolve(false);
        }

        const newProduits = formProductLinesToApiProductLines(factureForm, currentFacture['@id']);

        currentLignes = await getLignes(currentFacture.id, [currentDossierId], getLignesOptions);

        if (currentLignes.length === 0) {
            console.error('Pas de lignes pour ce dossier !');
            return Promise.resolve(false);
        }

        const deletePromises = currentLignes.map((ligne) => {
            if (!ligne.id) {
                console.error('id de ligne manquant !');
                return null;
            }

            return deleteLigne({
                idLigne: ligne.id.toString(),
                options: deleteLignesOptions,
            });
        });

        const productsPromises = newProduits.map((newLigne) =>
            addLigne(newLigne, addLignesOptions),
        );

        return Promise.all([...deletePromises, ...productsPromises])
            .then(() => Promise.resolve(true))
            .catch(() => Promise.resolve(false));
    };
};
