import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormHelperText,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, RegisterOptions, useForm } from 'react-hook-form';
import { FlexyFormLabel } from '@europrocurement/flexy-components';
import {
    AdresseJsonldTiersRead,
    TiersSousclientJsonldSousclientCreated,
    TiersSousclientJsonldSousclientUpdate,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { luhnValidate } from 'luhn-validate';
import { SousClient } from '@europrocurement/l2d-domain';
import { CompanyType } from '../../../hooks/useSirenSearch';
import { AddressFormatted } from '../../../hooks/usePlaceAutocomplete';
import useAddPrescriberClient, {
    AddPrescriberFormData,
} from '../../../hooks/useAddPrescriberClient';
import SirenAutocomplete from '../SirenAutocomplete/SirenAutocomplete';
import GoogleMapAutocomplete from '../GoogleMapAutocomplete/GoogleMapAutocomplete';
import ControlledPostalCodeInput, {
    PostalCodeChoice,
} from '../ControlledPostalCodeInput/ControlledPostalCodeInput';
import ControlledCountrySelect from '../ControlledCountrySelect/ControlledCountrySelect';
import ControlledNumberInput from '../ControlledPhoneNumberInput/ControlledPhoneNumberInput';

type prescriberClientFormProps = {
    onSuccess: (
        sousClient: TiersSousclientJsonldSousclientCreated | TiersSousclientJsonldSousclientUpdate,
    ) => void;
    onCancel?: () => void;
    sousClient?: SousClient;
};

const PrescriberClientForm: React.FunctionComponent<prescriberClientFormProps> = function ({
    onSuccess,
    onCancel,
    sousClient,
}) {
    const addressToString = (address: AdresseJsonldTiersRead) => {
        let addresseFact = '';
        if (address.adresseApi !== null && address.adresseApi !== '') {
            addresseFact = address.adresseApi ? address.adresseApi : '';
        } else {
            addresseFact = [
                address.adresse1,
                address.adresse2,
                address.adresse3,
                address.adresse4,
                address.codePostal,
                address.ville,
            ]
                .filter(Boolean)
                .join(' ');
        }
        return addresseFact;
    };
    const [visibleAddress, setVisibleAddress] = useState<string>('');
    const [showAddressDetails, setShowAddressDetails] = useState<boolean>(false);
    const [isPersonnePhysique, setIsPersonnePhysique] = useState<boolean>(false);
    const theme = useTheme();
    const { palette, breakpoints } = theme;
    const downLgBreakpoint = useMediaQuery(breakpoints.down('lg'));
    const downSmBreakpoint = useMediaQuery(breakpoints.down('sm'));
    const { isTiersNonFiable, isVenteIndirecte, sousClientExists } = useAddPrescriberClient();
    const [loading, setLoading] = useState<boolean>(false);

    const tiersNonFiable = isTiersNonFiable();
    const { handleAddForm } = useAddPrescriberClient();
    const { handleModifyForm } = useAddPrescriberClient();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const textInputStyle = {
        boxSizing: 'border-box',
        height: '56px',
        padding: '12px 20px',
        borderRadius: '12px',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '32px',
    };

    const commonInputProps = {
        inputProps: {
            sx: textInputStyle,
        },
    };

    const atLeastOnePhoneNumber = (
        data: string,
        formData: { phoneNumber: string; mobileNumber: string },
    ): string | boolean => {
        if (formData.phoneNumber === '' && formData.mobileNumber === '') {
            return 'Vous devez renseigner au moins un téléphone ou un mobile';
        }

        return true;
    };

    let defaultValues: AddPrescriberFormData = {
        localisationSocieteFr: true,
        personnePhysique: [isPersonnePhysique],
        siren: '',
        companyName: '',
        fullAddress: '',
        address: '',
        addressComplement: '',
        postalCode: '',
        city: '',
        country: '',
        civility: [],
        lastName: '',
        firstName: '',
        email: '',
        phoneNumber: '',
        mobileNumber: '',
        venteIndirecte: [isVenteIndirecte()],
        numeroTvaIntracommunautaire: '',
    };

    if (sousClient) {
        const addressPrincipal = sousClient.adresses.find((addr) => addr.defaut === true);
        const contactPrincipal = sousClient.contacts.find((obj) => obj.principal === true);
        let emailPrincipal = '';
        let mobilePrincipal = '';
        let telPrincipal = '';
        let emailId;
        let mobileId;
        let telephoneId;
        if (contactPrincipal !== undefined) {
            const oEmailPrincipal = contactPrincipal.emailsContact.find(
                (mail) => mail.defaut === true,
            );
            const oMobilePrincipal = contactPrincipal.mobilesContact.find(
                (obj) => obj.defaut === true,
            );
            const oTelPrincipal = contactPrincipal.telephonesContact.find(
                (obj) => obj.defaut === true,
            );

            emailPrincipal = oEmailPrincipal?.email ?? '';
            mobilePrincipal = oMobilePrincipal?.mobile ?? '';
            telPrincipal = oTelPrincipal?.telephone ?? '';
            emailId = oEmailPrincipal?.id ?? '';
            mobileId = oMobilePrincipal?.id ?? '';
            telephoneId = oTelPrincipal?.id ?? '';
        }

        defaultValues = {
            localisationSocieteFr: sousClient.tvaIntracommunautaire === '',
            personnePhysique: [sousClient.personnePhysique],
            siren: sousClient.siren || '',
            companyName: sousClient.raisonSociale || '',
            fullAddress: addressPrincipal !== undefined ? addressToString(addressPrincipal) : '',
            address: addressPrincipal !== undefined ? addressPrincipal.adresse1 : '',
            addressComplement:
                addressPrincipal !== undefined ? (addressPrincipal.adresse2 ?? '') : '',
            postalCode: addressPrincipal !== undefined ? addressPrincipal.codePostal : '',
            city: addressPrincipal !== undefined ? addressPrincipal.ville : '',
            country: addressPrincipal !== undefined ? addressPrincipal.codePays : '',
            civility: contactPrincipal?.civilite !== undefined ? [contactPrincipal.civilite] : [],
            lastName: contactPrincipal?.nomContact as string,
            firstName: contactPrincipal?.prenomContact as string,
            email: emailPrincipal,
            emailId: emailId !== undefined ? (emailId as unknown as number) : undefined,
            mobileId: mobileId !== undefined ? (mobileId as unknown as number) : undefined,
            telephoneId: telephoneId !== undefined ? (telephoneId as unknown as number) : undefined,
            phoneNumber: telPrincipal,
            mobileNumber: mobilePrincipal,
            venteIndirecte: [isVenteIndirecte()],
            numeroTvaIntracommunautaire: sousClient.tvaIntracommunautaire ?? '',
            contactId: contactPrincipal !== undefined ? contactPrincipal.id : undefined,
            adresseId: addressPrincipal !== undefined ? addressPrincipal.id : undefined,
        };

        if (visibleAddress === '') {
            setVisibleAddress(defaultValues.fullAddress);
        }
    }

    const formContext = useForm<FieldValues>({
        mode: 'onChange',
        defaultValues,
    });

    if (sousClient && !formContext.getValues('contactId')) {
        formContext.setValue('contactId', defaultValues.contactId);
    }
    if (sousClient && !formContext.getValues('adresseId')) {
        formContext.setValue('adresseId', defaultValues.adresseId);
    }

    const { watch } = formContext;

    watch('localisationSocieteFr');
    watch('personnePhysique');
    watch('civility');
    watch('lastName');
    watch('firstName');
    watch('phoneNumber');
    watch('mobileNumber');
    watch('country');

    const personnePhysique = formContext.getValues('personnePhysique')[0];
    const localisationSocieteFr = formContext.getValues('localisationSocieteFr');
    const civility = formContext.getValues('civility');
    const lastName = formContext.getValues('lastName');
    const firstName = formContext.getValues('firstName');
    const phoneNumber = formContext.getValues('phoneNumber');
    const mobileNumber = formContext.getValues('mobileNumber');
    const countryCode = formContext.getValues('country');

    useEffect(() => {
        setIsPersonnePhysique(personnePhysique);

        if (personnePhysique) {
            formContext.setValue('companyName', '');
            formContext.setValue('siren', '');
        }

        formContext.clearErrors();

        formContext.unregister('civility');
        formContext.unregister('lastName');
        formContext.unregister('firstName');
        formContext.setValue('civility', civility);
        formContext.setValue('lastName', lastName);
        formContext.setValue('firstName', firstName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personnePhysique]);

    useEffect(() => {
        if (phoneNumber || mobileNumber) {
            formContext.clearErrors('phoneNumber');
            formContext.clearErrors('mobileNumber');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneNumber, mobileNumber]);

    const majData = <T extends AddPrescriberFormData>(obj: T, newObj: Partial<T>): T => ({
        ...obj,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ...Object.fromEntries(Object.entries(newObj).filter(([key, v]) => v !== null)),
    });

    const onSubmit = (formData: AddPrescriberFormData) => {
        setLoading(true);
        setIsProcessing(true);
        if (sousClient !== undefined) {
            setIsProcessing(false);
            const newData = majData(defaultValues, formData);
            handleModifyForm(newData, sousClient.id as number)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                })
                .catch(() => {
                    const { setError } = formContext;
                    setError('root', {
                        type: 'custom',
                        message: 'Une erreur est survenue lors de la sauvegarde',
                    });
                })
                .finally(() => {
                    setIsProcessing(false);
                });
        } else {
            handleAddForm(formData)
                .then((response) => {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                })
                .catch(() => {
                    const { setError } = formContext;
                    setError('root', {
                        type: 'custom',
                        message: 'Une erreur est survenue lors de la sauvegarde',
                    });
                })
                .finally(() => {
                    setIsProcessing(false);
                });
        }
        setIsProcessing(false);
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const formStructure: FormStructure[] = [];

    formStructure.push({
        type: 'boolean',
        name: 'localisationSocieteFr',
        inputlabel: 'Le siège social est-il localisé en France ?',
        defaultValue: { localisationSocieteFr },
    });

    if (isPersonnePhysique && !localisationSocieteFr) {
        setIsPersonnePhysique(false);
        formContext.setValue('personnePhysique', [false]);
    }

    if (localisationSocieteFr) {
        formStructure.push({
            type: 'selectItems',
            name: 'personnePhysique',
            mode: 'inline',
            inputlabel: "Type d'entreprise",
            colInline: 6,
            items: [
                {
                    label: 'Entreprise',
                    value: false,
                },
                {
                    label: 'Personne',
                    value: true,
                },
            ],
            multiple: false,
            rules: {
                required: 'Vous devez renseigner un type de société',
            },
        });
    }

    if (!localisationSocieteFr) {
        formStructure.push({
            type: 'text',
            name: 'numeroTvaIntracommunautaire',
            inputlabel: 'Numéro fiscale',
            rules: {
                required: 'Vous devez renseigner le numéro fiscal',
            },
            placeholder: 'Numéro intracommunautaire (UE) / numéro fiscal',
            ...commonInputProps,
        });
    }

    if (!isPersonnePhysique) {
        if (localisationSocieteFr) {
            formStructure.push({
                type: 'customItem',
                name: 'siren',
                rules: {
                    required: 'Vous devez renseigner un numéro SIREN',
                    validate: async (data: string) => {
                        const isLuhnValid = luhnValidate(data.replace(/\s/g, ''));

                        if (!isLuhnValid) {
                            return 'Numéro SIREN invalide';
                        }

                        // if (data.length > 0) {
                        //     const isSirenAlreadyExists = await sousClientExists(data);

                        //     if (isSirenAlreadyExists) {
                        //         return 'Un client existe déjà avec ce numéro SIREN';
                        //     }
                        // }

                        return true;
                    },
                },
                placeholder: '123 456 789',
                renderField: (inputFormContext, inputProps) => {
                    const rules = inputProps.rules as RegisterOptions;
                    const propagateCompanyInfos = async (company: CompanyType) => {
                        const { setValue, setError, clearErrors } = inputFormContext;

                        setValue('siren', company.siren);
                        setValue('companyName', company.denomination);
                        setValue('fullAddress', company.addressFull);
                        setVisibleAddress(company.addressFull);
                        setValue('address', company.address);
                        setValue('addressComplement', '');
                        setValue('postalCode', company.postalCode);
                        setValue('city', company.city);
                        setValue('country', company.countryCode);
                        setValue(
                            'civility',
                            company.civilite ? String(company.civilite) : undefined,
                        );
                        setValue('lastName', company.lastName);
                        setValue('firstName', company.firstName);

                        clearErrors('siren');
                        clearErrors('companyName');
                        clearErrors('fullAddress');
                        clearErrors('address');
                        clearErrors('addressComplement');
                        clearErrors('postalCode');
                        clearErrors('city');
                        clearErrors('country');
                        clearErrors('civility');
                        clearErrors('lastName');
                        clearErrors('firstName');

                        const isSirenAlreadyExists = await sousClientExists(company.siren);

                        if (isSirenAlreadyExists) {
                            setError('siren', {
                                type: 'custom',
                                message: 'Un client existe déjà avec ce numéro SIREN',
                            });
                        }
                    };

                    const onError = (message: string) => {
                        formContext.setError('siren', {
                            type: 'custom',
                            message,
                        });
                    };

                    return (
                        <Controller
                            name="siren"
                            control={formContext.control}
                            rules={rules}
                            render={({ field: { onChange }, fieldState: { error } }) => (
                                <Box>
                                    <FlexyFormLabel
                                        required
                                        requiredTooltip="Le champ est requis"
                                    >
                                        Numéro SIREN
                                    </FlexyFormLabel>
                                    <SirenAutocomplete
                                        placeholder="123 456 789"
                                        onSelectItem={propagateCompanyInfos}
                                        onChange={onChange}
                                        hasError={error !== undefined}
                                        onError={onError}
                                        defaultValue={defaultValues.siren}
                                    />
                                    {error ? (
                                        <FormHelperText
                                            sx={{
                                                margin: '4px 14px 0px 14px',
                                            }}
                                            error
                                        >
                                            {error?.message}
                                        </FormHelperText>
                                    ) : null}
                                </Box>
                            )}
                        />
                    );
                },
            });
        }

        formStructure.push({
            type: 'text',
            name: 'companyName',
            inputlabel: 'Dénomination sociale',
            rules: {
                required: 'Vous devez renseigner une dénomination sociale',
            },
            placeholder: 'Denomination de la société',
            ...commonInputProps,
        });
    }

    if (!showAddressDetails) {
        formStructure.push({
            type: 'customItem',
            name: 'fullAddress',
            rules: {
                required: 'Vous devez renseigner une adresse',
            },
            display: !showAddressDetails,
            renderField: (inputFormContext, inputProps) => {
                const rules = inputProps.rules as RegisterOptions;

                return (
                    <Controller
                        name="fullAddress"
                        control={inputFormContext.control}
                        rules={rules}
                        render={({ field: { onChange }, fieldState: { error } }) => (
                            <Box>
                                <FlexyFormLabel required={rules.required !== undefined}>
                                    Adresse du siège social
                                </FlexyFormLabel>
                                <Box sx={{ position: 'relative' }}>
                                    <GoogleMapAutocomplete
                                        inputValue={visibleAddress}
                                        onKeyUp={onChange}
                                        onChange={(formattedAddress: AddressFormatted) => {
                                            const { setValue, clearErrors } = inputFormContext;

                                            setValue('address', formattedAddress.address);
                                            setValue(
                                                'addressComplement',
                                                formattedAddress.addressComplement,
                                            );
                                            setValue('city', formattedAddress.city);
                                            setValue('postalCode', formattedAddress.postalCode);
                                            setValue('country', formattedAddress.countryCode);

                                            clearErrors('address');
                                            clearErrors('addressComplement');
                                            clearErrors('city');
                                            clearErrors('postalCode');
                                            clearErrors('country');
                                        }}
                                        placeholder="Adresse complète"
                                        hasError={error !== undefined}
                                        isFrenchAddress={localisationSocieteFr}
                                    />
                                    {!showAddressDetails && (
                                        <Button
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                right: '10px',
                                                height: '56px',
                                            }}
                                            onClick={() => setShowAddressDetails((v) => !v)}
                                        >
                                            Éditer l&apos;adresse
                                        </Button>
                                    )}
                                    {error ? (
                                        <FormHelperText
                                            sx={{
                                                margin: '4px 14px 0px 14px',
                                            }}
                                            error
                                        >
                                            {error?.message}
                                        </FormHelperText>
                                    ) : null}
                                </Box>
                            </Box>
                        )}
                    />
                );
            },
        });
    }

    formStructure.push({
        type: 'text',
        name: 'address',
        inputlabel: 'Adresse',
        rules: {
            required: 'Vous devez renseigner une adresse',
        },
        placeholder: '15 Rue de la porte',
        display: showAddressDetails,
        ...commonInputProps,
    });

    formStructure.push({
        type: 'text',
        name: 'addressComplement',
        inputlabel: "Complément d'adresse",
        placeholder: 'Batiment, interphone... etc',
        display: showAddressDetails,
        ...commonInputProps,
    });
    let rulesRequiredCp;

    if (localisationSocieteFr) {
        rulesRequiredCp = { required: 'Vous devez renseigner un code postal' };
    }
    formStructure.push({
        type: 'customItem',
        name: 'postalCode',
        inputlabel: 'Code postal',
        display: showAddressDetails,
        rules: {
            ...rulesRequiredCp,
        },
        renderField: (inputFormContext, inputProps) => (
            <ControlledPostalCodeInput
                name={inputProps.name as string}
                rules={inputProps.rules}
                label={inputProps.inputlabel as string}
                isFrench={localisationSocieteFr}
                onSelectChoice={(item: PostalCodeChoice) => {
                    if (!item) {
                        return;
                    }

                    const { clearErrors, setValue } = inputFormContext;
                    setValue('city', item.metadata.city);
                    setValue('country', item.metadata.countryCode);
                    clearErrors('city');
                    clearErrors('country');
                }}
            />
        ),
    });

    formStructure.push({
        type: 'text',
        name: 'city',
        inputlabel: 'Ville',
        rules: {
            required: 'Vous devez renseigner une ville',
        },
        placeholder: 'Paris',
        display: showAddressDetails,
        ...commonInputProps,
    });

    formStructure.push({
        type: 'customItem',
        name: 'country',
        inputlabel: 'Pays',
        display: showAddressDetails,
        rules: {
            required: 'Vous devez renseigner un pays',
        },
        renderField: (inputFormContext, inputProps) => (
            <ControlledCountrySelect
                name={inputProps.name as string}
                rules={inputProps.rules as RegisterOptions}
                label={inputProps.inputlabel as string}
            />
        ),
    });

    formStructure.push({
        type: 'customItem',
        name: '',
        renderField: () => (
            <>
                <Box
                    sx={{
                        borderTop: '1px solid rgba(10, 26, 83, 0.1)',
                        marginTop: '40px',
                        paddingTop: '40px',
                    }}
                />
                <Typography
                    sx={{
                        fontWeight: 600,
                        marginBottom: '24px',
                    }}
                    variant="l2dh7"
                >
                    Contact de la société
                </Typography>
            </>
        ),
    });

    formStructure.push({
        type: 'selectItems',
        inputlabel: 'Civilité',
        mode: 'inline',
        colInline: 6,
        items: [
            {
                label: 'Monsieur',
                value: 102,
            },
            {
                label: 'Madame',
                value: 103,
            },
        ],
        multiple: false,
        required: false,
        name: 'civility',
        rules: isPersonnePhysique
            ? {
                  required: 'Vous devez renseigner une civilité',
              }
            : {},
    });

    formStructure.push({
        type: 'text',
        name: 'lastName',
        inputlabel: 'Nom',
        rules: isPersonnePhysique
            ? {
                  required: 'Vous devez renseigner un nom',
              }
            : {},
        placeholder: 'Nom',
        xs: 12,
        lg: 6,
        ...commonInputProps,
    });

    formStructure.push({
        type: 'text',
        name: 'firstName',
        inputlabel: 'Prénom',
        rules: isPersonnePhysique
            ? {
                  required: 'Vous devez renseigner un prénom',
              }
            : {},
        placeholder: 'Prénom',
        xs: 12,
        lg: 6,
        ...commonInputProps,
    });

    const emailValidate = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    formStructure.push({
        type: 'text',
        name: 'email',
        inputlabel: 'E-mail',
        rules: {
            required: tiersNonFiable ? 'Vous devez renseigner un email' : false,
            validate: (data: string) => emailValidate(data),
        },
        placeholder: 'mail@domain.com',
        ...commonInputProps,
    });

    formStructure.push({
        type: 'customItem',
        name: 'phoneNumber',
        inputlabel: 'Téléphone',
        rules: {
            validate: (data: string, formData: { phoneNumber: string; mobileNumber: string }) =>
                localisationSocieteFr ? atLeastOnePhoneNumber(data, formData) : true,
        },
        renderField: (inputFormContext, inputProps) => (
            <ControlledNumberInput
                name={inputProps.name as string}
                rules={inputProps.rules as RegisterOptions}
                label={inputProps.inputlabel as string}
                placeholder="01 02 03 04 05"
                countryCode={countryCode || 'FR'}
                defaultValue={defaultValues.phoneNumber}
            />
        ),
    });

    formStructure.push({
        type: 'customItem',
        name: 'mobileNumber',
        inputlabel: 'Mobile',
        rules: {
            validate: (data: string, formData: { phoneNumber: string; mobileNumber: string }) =>
                localisationSocieteFr ? atLeastOnePhoneNumber(data, formData) : true,
        },
        renderField: (inputFormContext, inputProps) => (
            <ControlledNumberInput
                name={inputProps.name as string}
                rules={inputProps.rules as RegisterOptions}
                label={inputProps.inputlabel as string}
                placeholder="06 02 03 04 05"
                countryCode={countryCode || 'FR'}
                defaultValue={defaultValues.mobileNumber}
            />
        ),
    });

    if (!tiersNonFiable) {
        formStructure.push({
            type: 'customItem',
            name: '',
            renderField: () => (
                <>
                    <Box
                        sx={{
                            borderTop: '1px solid rgba(10, 26, 83, 0.1)',
                            marginTop: '40px',
                            paddingTop: '40px',
                        }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 600,
                            marginBottom: '24px',
                        }}
                        variant="l2dh7"
                    >
                        Facturation
                    </Typography>
                </>
            ),
        });

        formStructure.push({
            type: 'selectItems',
            inputlabel: 'Recouvrement effectué par',
            mode: 'inline',
            colInline: 6,
            items: [
                {
                    label: 'Ma société',
                    value: false,
                },
                {
                    label: 'Legal2Digital',
                    value: true,
                },
            ],
            multiple: false,
            name: 'venteIndirecte',
            rules: {},
        });
    }

    const formProps = {
        formContext,
        formObject: defaultValues,
        onSubmit,
        submitButton: {
            displayed: false,
        },
    };

    const renderSubmitButton = () => (
        <Box
            sx={{
                backgroundColor: palette.background.dark,
                position: 'sticky',
                left: '0',
                right: '0',
                bottom: '0',
                display: 'flex',
                flexDirection: downLgBreakpoint ? 'column' : 'row',
                justifyContent: 'center',
                gap: '16px',
                height: downLgBreakpoint ? 'auto' : '100px',
                alignItems: 'center',
                padding: downLgBreakpoint ? '20px' : '',
            }}
        >
            <Button
                onClick={() => handleCancel()}
                sx={{
                    color: palette.text.primary,
                    padding: '16px 24px 16px 24px',
                    width: downLgBreakpoint ? '100%' : 'auto',
                    display: downLgBreakpoint ? 'block' : 'flex',
                }}
            >
                Annuler
            </Button>
            <Button
                type="submit"
                disabled={isProcessing}
                variant="contained"
                sx={{
                    backgroundColor: palette.primary.main,
                    color: palette.primary.contrastText,
                    padding: '16px 24px 16px 24px',
                    width: downLgBreakpoint ? '100%' : 'auto',
                    display: downLgBreakpoint ? 'block' : 'flex',
                }}
            >
                {sousClient !== undefined ? 'Modifier la société' : 'Ajouter la société'}
            </Button>
        </Box>
    );

    let formWidth = downLgBreakpoint ? 'calc(100vw - 200px)' : '1098px';

    if (downSmBreakpoint) {
        formWidth = '100%';
    }

    return (
        <Box
            sx={{
                width: formWidth,
                margin: '0 auto',
            }}
        >
            <Backdrop
                sx={(backDropTheme) => ({ color: '#fff', zIndex: backDropTheme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box
                sx={{
                    width: downLgBreakpoint ? '100%' : '60%',
                    maxWidth: downLgBreakpoint ? '100%' : '560px',
                    margin: '0 auto',
                }}
            >
                <Box
                    sx={{
                        marginBottom: '10px',
                    }}
                >
                    <Typography variant="l2dh6">Ajouter une société</Typography>
                </Box>
                <FlexyForm
                    onSubmit={onSubmit}
                    formObject={formProps.formObject}
                    formStructure={formStructure}
                    formContext={formContext}
                    submitButton={{ displayed: false, render: renderSubmitButton }}
                />
            </Box>
        </Box>
    );
};

export default PrescriberClientForm;
