import * as React from 'react';

import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { OffresOffreRead, OffresOffreUpdate } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { ModalContext } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'react-redux';

import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName, IconProp, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    domainSelector,
    regleDeCalculSelector,
    useOffreService,
} from '@europrocurement/l2d-domain';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import TextField from '../components/form/TextField';
import { offerSchema, OfferSchema } from './schema';
import { AbstractFormType } from '../forms/types';
import { formatOptionsByKeys } from '../forms/options';
import SelectField from '../components/FormInputs/SelectField';
import { input, output } from './transformApiEntity';
import FontAwesomeDocLink from '../components/FontAwesomeDocLink';

type OfferFormProps = AbstractFormType & {
    entity?: OffresOffreRead;
};
/** Article Form */
const Form: React.FunctionComponent<OfferFormProps> = function (props) {
    /** Generic requirements */
    const { afterSubmit, entity, isCreate = false } = props;
    const { modalActions } = React.useContext(ModalContext ?? null);
    const { xIdSociete: companyId } = useSelector(customizerSelector);
    const { request } = useApiRequest();
    const { offerModel } = useOffreService();

    /** React hook form */

    // Format entity input > Translate fields > Format
    const defaultValues = input(isCreate ? undefined : entity) as FieldValues;
    // Form configuration
    const formContext = useForm({
        mode: 'onBlur',
        defaultValues, // Pass abstract type when using a resolver
        resolver: zodResolver(offerSchema),
    });

    const fetchedDomainsOptions = useSelector(domainSelector).main.data;
    const domainsOptions = formatOptionsByKeys(fetchedDomainsOptions, 'id', 'libelle');

    const fetchedcalculCombineOptions = useSelector(regleDeCalculSelector).main.data;
    const calculCombineOptions = formatOptionsByKeys(fetchedcalculCombineOptions, 'id', 'libelle');

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = formContext;

    /** Specific validation rules */

    /** Submit logic */

    const successCallback = () => {
        modalActions.reset();
        afterSubmit();
    };

    const createModel: SubmitHandler<OfferSchema> = async (formValues) => {
        // Api platform type issue | iri and plain identifier must return string | number type
        const formData = output(formValues);

        const createRequest = offerModel.create({
            offresJsonldOffreCreate: formData,
            xIdSociete: companyId,
        });
        await request(createRequest, { successCallback });
    };

    const updateModel: SubmitHandler<OfferSchema> = async (formValues) => {
        const id = entity?.id ?? null;
        if (id) {
            // Api platform type issue | iri and plain identifier must return string | number type
            const formData = output(formValues);

            const updateRequest = offerModel.update({
                id: id.toString(),
                offresJsonldOffreUpdate: formData as OffresOffreUpdate,
                xIdSociete: companyId,
            });
            await request(updateRequest, { successCallback });
        }
    };

    const onSubmit = (formData: OfferSchema) =>
        isCreate ? createModel(formData) : updateModel(formData);

    const onError = (formData: unknown) => console.log('ON ERROR', formData);

    const iconPrefix = 'fad';

    const checkIfValidIcon = (value: string): value is IconName => {
        const icon = findIconDefinition({
            prefix: iconPrefix,
            iconName: value as IconName,
        });

        return !!icon;
    };

    const getRenderedIcon = (): IconName => {
        const formIcon = formContext.watch('iconeName');
        const iconIsValid = checkIfValidIcon(formIcon);
        const defaultIcon = 'image-slash';

        if (!iconIsValid) {
            return defaultIcon;
        }
        return formIcon;
    };

    const formIcon: IconProp = [iconPrefix, getRenderedIcon()];

    const iconStyle = {
        width: '50%',
        fontSize: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px',
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit, onError)}
            noValidate
        >
            <hr />
            <Box sx={{ minWidth: '800px' }}>
                <Controller
                    name="label"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            sx={{ width: '100%' }}
                            {...field}
                            name={field.name}
                            label="Libellé"
                            errors={errors}
                            required
                        />
                    )}
                />
                <Controller
                    name="visibility"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            sx={{ width: '100%' }}
                            {...field}
                            name={field.name}
                            label="Option de visibilité"
                            placeholder="Option de visibilité"
                            options={[
                                { label: 'Complète', value: 'FULL' },
                                { label: 'Partielle', value: 'PARTIAL' },
                            ]}
                            errors={errors}
                            required
                        />
                    )}
                />
                <Controller
                    name="domainId"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            placeholder="Sélectionnez un domaine"
                            name={field.name}
                            isDisabled={!isCreate}
                            label="Domaine"
                            options={domainsOptions}
                            errors={errors}
                        />
                    )}
                />

                <Controller
                    name="calculCombineId"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            sx={{ width: '100%' }}
                            {...field}
                            name={field.name}
                            label="Calcul collections combinées"
                            placeholder="Calcul appliqué pour les collections combinées"
                            options={calculCombineOptions}
                            errors={errors}
                            required
                        />
                    )}
                />
                <Controller
                    name="calculMultipleId"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            sx={{ width: '100%' }}
                            {...field}
                            name={field.name}
                            label="Calcul collections multiples"
                            placeholder="Calcul appliqué pour les collections multiples"
                            options={calculCombineOptions}
                            errors={errors}
                            required
                        />
                    )}
                />

                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    marginBottom="25px"
                >
                    <Box
                        width="50%"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Controller
                            name="iconeName"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    sx={{ width: '100%' }}
                                    {...field}
                                    name={field.name}
                                    label="Icône"
                                    errors={errors}
                                    required={false}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={iconStyle}>
                        <FaOptionIcon icon={formIcon} />
                    </Box>
                </Box>
                <FontAwesomeDocLink />

                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button
                        type="submit"
                        sx={{ marginRight: '10px' }}
                        variant="outlined"
                        style={{ marginTop: '2rem', marginBottom: '16px' }}
                        onClick={modalActions.reset}
                    >
                        Annuler
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                        style={{ marginTop: '2rem', marginBottom: '16px' }}
                    >
                        Valider
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

export default Form;
