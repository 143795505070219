import * as React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

export type LabeledIconProps = {
    label: string;
    icon: IconProp;
};

const LabeledIcon: React.FunctionComponent<LabeledIconProps> = function ({
    label,
    icon,
}: LabeledIconProps) {
    const wrapper = { display: 'flex', opacity: '0.7', marginY: '5px' };
    const iconStyle = { marginRight: '7px' };

    return (
        <Box sx={wrapper}>
            <FaOptionIcon
                icon={icon}
                sx={iconStyle}
                size="xs"
            />
            <Typography>{label}</Typography>
        </Box>
    );
};

export default LabeledIcon;
