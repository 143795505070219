import * as React from 'react';

import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import './ribbon.css';
import { useMediaQuery } from '@mui/system';
import { Box, Theme, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { FlexyLayoutState } from '@europrocurement/flexy-components/redux/storeConfig/store';
import { MenuContext, MenuContextType } from '@europrocurement/flexy-components/providers';
import L2DFSidebar, { L2DFSidebarProps } from '../L2DFSidebar';
import type { MenuEntry } from './MenuEntry';
import { MenuItemProps } from '../../molecules/MenuItem';
import useSidebar from './useSidebar';
import { useFullLayout } from '../../templates/FullLayout/FullLayoutContext';

export type SidebarProps = {
    menuEntries: MenuEntry[];
    logo: React.ReactNode;
    logoFolded: React.ReactNode;
    actions?: L2DFSidebarProps['actions'];
};

const Sidebar: React.FunctionComponent<SidebarProps> = function ({
    menuEntries,
    logo,
    logoFolded,
    actions = [],
}: SidebarProps) {
    const { isSidebarOpen, setSidebarOpen } = useFullLayout();

    const roleChecker = UseKeycloakCheckRole();
    const theme = useTheme();
    const smBreakpoint = useMediaQuery((t: Theme) => t.breakpoints.up('sm'));
    const folded = !isSidebarOpen;
    const { entryToItem, findActiveElements, findOpenElements } = useSidebar();

    const filterEntries = (entries: MenuEntry[]): MenuItemProps[] =>
        entries
            .filter((entry) => !entry.role || roleChecker(entry.role))
            .map((entry) => ({
                ...entryToItem(entry, theme.palette.primary, folded),
                childrenItems: entry.children ? filterEntries(entry.children) : [],
            }));

    const menuItems = filterEntries(menuEntries);

    const env = useSelector((state: FlexyLayoutState) => state.customizer.env);
    const [activeTree, setActiveTree] = React.useState<Array<string>>(
        findActiveElements(menuItems),
    );
    const [openElements, setOpenElements] = React.useState<Array<string>>(
        findOpenElements(menuItems),
    );
    const menuContextValue: MenuContextType = React.useMemo(
        () => ({
            activeTree,
            setActiveTree,
            openElements,
            setOpenElements,
        }),
        [activeTree, setActiveTree, openElements, setOpenElements],
    );

    if (!isSidebarOpen && !smBreakpoint) {
        return null;
    }

    return (
        <Box data-testid="test-id-Sidebar">
            {env !== 'prod' ? (
                <Box
                    id="env-ribbon"
                    className={`${env}${folded ? ' folded' : ''}`}
                >
                    {env}
                </Box>
            ) : null}
            <MenuContext.Provider value={menuContextValue}>
                <L2DFSidebar
                    actions={actions}
                    logo={logo}
                    logoFolded={logoFolded}
                    items={menuItems}
                    folded={folded}
                    onClose={() => setSidebarOpen(false)}
                />
            </MenuContext.Provider>
        </Box>
    );
};

export default Sidebar;
