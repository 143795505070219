import React from 'react';
import {
    selectedPrescripteursSelector,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import { UploaderProvider } from '@europrocurement/flexy-components';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { ParcoursFormaliteProvider } from './provider';
import ParcoursFormaliteOutlet from './ParcoursFormaliteOutlet';

export type ParcoursFormaliteRootProps = {
    back?: boolean;
};

const ParcoursFormaliteRoot: React.FunctionComponent<ParcoursFormaliteRootProps> = function ({
    back = false,
}) {
    // const prescripteur = useSelectedPrescriber();
    const prescripteur = useTiersServiceSelector(selectedPrescripteursSelector);

    const { setSidebarOpen } = useFullLayout();

    React.useEffect(() => {
        setSidebarOpen(false);
        return () => {
            setSidebarOpen(true);
        };
    }, [setSidebarOpen]);

    return prescripteur ? (
        <ParcoursFormaliteProvider
            back={back}
            prescripteur={prescripteur}
            // defaultCollectionId={373}
            // defaultDossierId={1005248}
        >
            <UploaderProvider>
                <ParcoursFormaliteOutlet />
            </UploaderProvider>
        </ParcoursFormaliteProvider>
    ) : null;
};

export default ParcoursFormaliteRoot;
