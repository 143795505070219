import React from 'react';
import {
    Fab,
    Drawer,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Divider,
    Box,
    Typography,
    Tooltip,
    Radio as CustomRadio,
    type SimplePaletteColorOptions,
    Grid,
} from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { type THEME_LIST, setTheme, setDarkMode } from '@europrocurement/l2d-domain';
import { customizerSelector } from '../../../redux/storeConfig/selectors';
import { NamedTheme, SidebarWidth, themesOptions } from '../../../assets/global/Theme-variable';
import { useFullLayout } from '../../templates/FullLayout/FullLayoutContext';

type CustomizerPropsType = {
    showButton?: boolean;
    // style?: React.CSSProperties;
};

const Customizer: React.FunctionComponent<CustomizerPropsType> = function (props) {
    const { isCustomizerDrawerOpen: showDrawer, setCustomizerDrawerOpen: setShowDrawer } =
        useFullLayout();

    const { showButton = true /* , style = undefined */ } = props;
    const customizer = useSelector(customizerSelector, shallowEqual);

    const dispatch = useDispatch();

    const thColors: { id: number; bgColor: string; iconColor: string; disp: THEME_LIST }[] =
        themesOptions.map((theme: NamedTheme, id: number) => {
            let bgColor = '#fff';
            let iconColor = '#000';

            if (theme.palette?.primary) {
                bgColor = (theme.palette.primary as SimplePaletteColorOptions).main;
                iconColor = (theme.palette.secondary as SimplePaletteColorOptions).main;
            }

            return {
                id,
                bgColor,
                iconColor,
                disp: theme.name,
            };
        });

    return (
        <div /* style={{ ...style }} */>
            {showButton ? (
                <Tooltip title="Paramètres">
                    <Fab
                        size="small"
                        color="default"
                        aria-label="settings"
                        sx={{ position: 'fixed', right: '15px', bottom: '15px' }}
                        onClick={() => setShowDrawer(true)}
                    >
                        <SettingsIcon />
                    </Fab>
                </Tooltip>
            ) : null}
            <Drawer
                anchor="right"
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                    },
                }}
            >
                <Box p={2}>
                    <Typography variant="h3">Paramètres</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                    {/* ------------ Dark light theme setting ------------- */}
                    <Typography
                        variant="h4"
                        gutterBottom
                    >
                        Options de thème
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="theme"
                            name="theme"
                            value={customizer.activeMode}
                            onChange={(event) =>
                                dispatch(setDarkMode(event.target.value as 'dark' | 'light'))
                            }
                        >
                            <FormControlLabel
                                value="light"
                                control={<CustomRadio />}
                                label="Thème claire"
                            />
                            <FormControlLabel
                                value="dark"
                                control={<CustomRadio />}
                                label="Thème sombre"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Box pt={3} />
                    {/* ------------ Navbar Color setting ------------- */}
                    <Typography
                        variant="h4"
                        gutterBottom
                    >
                        Couleurs du thème
                    </Typography>
                    <Grid
                        container
                        columns={4}
                        spacing={2}
                    >
                        {thColors.map((thcolor) => (
                            <Grid
                                item
                                xs={1}
                                key={thcolor.id}
                            >
                                <Tooltip
                                    title={thcolor.disp}
                                    placement="top"
                                    sx={{ margin: 'auto' }}
                                >
                                    <Fab
                                        color="primary"
                                        style={{ backgroundColor: thcolor.bgColor }}
                                        size="small"
                                        onClick={() => dispatch(setTheme(thcolor.disp))}
                                        aria-label={thcolor.bgColor}
                                    >
                                        {customizer.activeTheme === thcolor.disp ? (
                                            <CheckCircleIcon
                                                style={{ color: thcolor.iconColor }}
                                                fontSize="large"
                                            />
                                        ) : (
                                            <CircleIcon
                                                style={{ color: thcolor.iconColor }}
                                                fontSize="large"
                                            />
                                        )}
                                    </Fab>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>

                    <Box pt={3} />
                </Box>
            </Drawer>
        </div>
    );
};

export default Customizer;
