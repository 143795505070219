import React from 'react';
import { Box, Typography } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { dateToFrenchFormat } from '@europrocurement/l2d-utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type ModificationDateProps = {
    date?: Date;
    isCreationDate?: boolean;
};

const ModificationDate: React.FunctionComponent<ModificationDateProps> = function ({
    date,
    isCreationDate = false,
}) {
    const CALENDAR_ICON: IconProp = ['fasl', 'calendar']; // Mockup icon is far / Icon display issue with far prefix
    const sx = {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        width: '100%',
    };
    const displayDate = (): string | null => {
        if (!date) {
            return null;
        }
        const dateInformation = dateToFrenchFormat(date);
        const prefix = isCreationDate ? 'Créé' : 'Mis à jour';
        return `${prefix} le ${dateInformation}`;
    };
    return (
        <Box
            data-testid="ModificationDate-wrapper"
            sx={sx}
        >
            <FaOptionIcon
                data-testid="ModificationDate-icon"
                icon={CALENDAR_ICON}
                sx={{ alignSelf: 'center', marginRight: '10px' }}
            />
            <Typography data-testid="ModificationDate-display">{displayDate()}</Typography>
        </Box>
    );
};

export default ModificationDate;
