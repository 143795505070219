import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { CodeRejet, CodeRejetApiObject, useSociete } from '@europrocurement/l2d-domain';
import { FlexyIconButton, FlexyIconButtonProps, useModal } from '@europrocurement/flexy-components';
import { dateInput } from '@europrocurement/l2d-icons';
import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { NamedAxiosPromise } from '@europrocurement/flexy-components/types/NamedAxiosPromise';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { AppDispatch, RootStateType } from '@b2d/redux/types';
import { getCodeRejet, getDefFrequence, rejectCodesApi } from '@b2d/redux/RootStore';
import { ExtractPropertyType } from '@b2d/utils/types';
import { useForm } from 'react-hook-form';
import {
    DEF_FREQUENCE_SLICE_NAME,
    DefFrequence,
} from '@europrocurement/l2d-domain/reducers/achats/slices/defFrequenceSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    CodeRejetCodeRejetUpdate,
    CodeRejetJsonldCodeRejetRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import FormModal from '../modals/FormModal/FormModal';
import LabeledAsyncSwitch from './LabeledAsyncSwitch';
import { ChoiceProps } from '../modals/FormModal/types';

/**
 * CodeRejetSwitchesGroup Component
 *
 * A group of switches to manage the toggle states for different reject code fields.
 * Each switch triggers an API request to update the corresponding field dynamically.
 *
 * Props:
 * - `rejectCode`: The reject code object containing field values for toggles.
 * - `fetchData`: A Redux thunk function to refresh data after toggle updates.
 *
 * Features:
 * - Dynamically updates reject code fields (e.g., `manuel`, `bloquant`, `relanceAuto`).
 * - Supports handling multiple toggle switches with customizable actions.
 * - Includes additional buttons for contextual interactions (e.g., frequency selection).
 *
 * Example:
 * ```
 * <CodeRejetSwitchesGroup
 *   rejectCode={rejectCode}
 *   fetchData={fetchData}
 * />
 * ```
 */

type CodeRejetSwitchesGroupProps = {
    rejectCode: CodeRejet;
    fetchData: getDataThunkType<CodeRejetApiObject>;
    patchRejectCode: (
        code: string,
        payload: CodeRejetCodeRejetUpdate,
        options?: AxiosRequestConfig,
    ) => Array<{
        name: string;
        promise: Promise<AxiosResponse<CodeRejetJsonldCodeRejetRead, unknown>>;
    }>;
    disabledToggles?: Partial<Record<'manuel' | 'bloquant' | 'relanceAuto', boolean>>;
};

const CodeRejetSwitchesGroup: React.FunctionComponent<CodeRejetSwitchesGroupProps> = function ({
    rejectCode,
    fetchData,
    patchRejectCode,
    disabledToggles,
}: CodeRejetSwitchesGroupProps) {
    const xIdSociete = useSociete();
    const { modalActions } = useModal();
    const modalFrequencyPickFormContext = useForm({
        mode: 'onTouched',
    });
    const defFrequenceDatasource: DataSource<DefFrequence> = useSelector(
        (s: RootStateType) => s.achats[DEF_FREQUENCE_SLICE_NAME].main,
    );

    const dispatch = useDispatch<AppDispatch>();

    const selectedFrequency = React.useMemo(() => {
        if (defFrequenceDatasource.data.length === 0) {
            dispatch(getDefFrequence({}));
        }

        return defFrequenceDatasource.data.find(
            (defFrequence) => rejectCode.idFrequenceRelance === defFrequence.dfrId,
        );
    }, [defFrequenceDatasource.data, dispatch, rejectCode.idFrequenceRelance]);

    type ActionParameters = Parameters<Exclude<ChoiceProps['action'], undefined>>;
    type HandleActionOnChangeFrequencyType = (
        event: ActionParameters[0],
        checked: ActionParameters[1],
        defFrequence: DefFrequence,
    ) => void;

    const handleActionOnChangeFrequency = React.useCallback<HandleActionOnChangeFrequencyType>(
        (event, checked, defFrequence) => {
            if (!checked || !rejectCode.code) return;

            patchRejectCode(rejectCode.code, {
                idFrequenceRelance: defFrequence.dfrId,
            });

            setTimeout(() => {
                dispatch(getCodeRejet({}));
                dispatch(fetchData({}));
            }, 100);

            modalActions.close();
        },
        [dispatch, fetchData, modalActions, patchRejectCode, rejectCode.code],
    );

    const defFrequenceChoices: Array<ChoiceProps> = defFrequenceDatasource.data.map(
        (defFrequence) => ({
            label: defFrequence.dfrLibelle ? defFrequence.dfrLibelle : '???',
            defaultSelected:
                rejectCode.idFrequenceRelance === defFrequence.dfrId ||
                (!rejectCode.idFrequenceRelance && defFrequence.dfrId === 1),
            action: (event: React.ChangeEvent<Element>, checked: boolean) => {
                handleActionOnChangeFrequency(event, checked, defFrequence);
            },
        }),
    );

    const handleFrequencyPickButtonClick = React.useCallback<
        Exclude<ExtractPropertyType<FlexyIconButtonProps, 'onClick'>, undefined>
    >(() => {
        modalActions.call(
            <FormModal
                key={`${modalFrequencyPickFormContext}-${xIdSociete}`}
                title="Fréquence de relance"
                icon={dateInput}
                context="Définir la fréquence à laquelle la relance sera automatiquement déclenchée"
                uniqChoice
                inputNature="select"
                choices={defFrequenceChoices}
            />,
        );
    }, [modalActions, modalFrequencyPickFormContext, xIdSociete, defFrequenceChoices]);

    /**
     * Dynamically updates a specific field in the reject code.
     * @param field - The field to update in the reject code object.
     */
    const updateRejectCodeField = <K extends keyof CodeRejet>(
        field: K,
    ): AxiosPromise | undefined => {
        if (!rejectCode.code) {
            return undefined;
        }

        return rejectCodesApi.patchCodeRejetItem({
            code: rejectCode.code,
            codeRejetCodeRejetUpdate: {
                [field]: !rejectCode[field],
            },
            xIdSociete,
        });
    };

    /**
     * Generates a toggle request for a specific reject code field.
     * @param field - The field to toggle.
     * @returns An array of NamedAxiosPromise for toggle requests.
     */
    const createToggleRequest = (field: keyof CodeRejet): Array<NamedAxiosPromise> => [
        {
            name: `updateRejectCode${field.charAt(0).toUpperCase() + field.slice(1)}`,
            promise: updateRejectCodeField(field) as AxiosPromise,
        },
    ];

    return (
        <Box
            key={`${rejectCode.code}-configuration-toggles`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'space-evenly',
                gap: 1,
            }}
        >
            <LabeledAsyncSwitch
                key={`${rejectCode.code}-manuel_${rejectCode.manuel}`}
                requestsOnToggle={() => createToggleRequest('manuel')}
                fetchData={fetchData}
                defaultChecked={rejectCode.manuel}
                disabled={!!disabledToggles?.manuel}
                label="Manuel"
            />
            <LabeledAsyncSwitch
                key={`${rejectCode.code}-bloquant_${rejectCode.bloquant}`}
                requestsOnToggle={() => createToggleRequest('bloquant')}
                fetchData={fetchData}
                defaultChecked={rejectCode.bloquant}
                disabled={!!disabledToggles?.bloquant}
                label="Bloquant"
            />
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }}
            >
                <LabeledAsyncSwitch
                    key={`${rejectCode.code}-relanceAuto_${rejectCode.relanceAuto}`}
                    requestsOnToggle={() => createToggleRequest('relanceAuto')}
                    fetchData={fetchData}
                    defaultChecked={rejectCode.relanceAuto}
                    disabled={!!disabledToggles?.relanceAuto}
                    label="Relance auto"
                />
                {rejectCode.relanceAuto ? (
                    <FlexyIconButton
                        key={`${rejectCode.code}-relanceAuto_${rejectCode.relanceAuto}-icon`}
                        icon={dateInput}
                        iconOverwriteProps={{
                            size: 'sm',
                            color: 'secondary',
                        }}
                        displayTooltip
                        tooltipOverwriteProps={{
                            title: `Fréquence sélectionnée : ${selectedFrequency?.dfrLibelle || ' - '}`,
                        }}
                        onClick={handleFrequencyPickButtonClick}
                    />
                ) : null}
            </Stack>
        </Box>
    );
};

export default CodeRejetSwitchesGroup;
