import React from 'react';

import { Box } from '@mui/material';

import { DisplayFile } from '../../atoms';

type handleClickProps = {
    url: string;
    nom: string;
};

export type UrlFileProps = {
    url: string; // nom, poid , mimetype ( changer l'icone en fonction du mimetype )
    nom: string;
    size?: number;
    mimetype?: string;
    handleClick?: (props: handleClickProps) => void;
};

const UrlFile: React.FunctionComponent<UrlFileProps> = function ({
    url,
    nom,
    size,
    mimetype = '',
    handleClick,
}) {
    return (
        <Box>
            <DisplayFile
                url={url}
                nom={nom}
                size={size}
                mimetype={mimetype}
                handleClick={handleClick}
            />
        </Box>
    );
};

export default UrlFile;
