import {
    OptionValueOptionValueRead,
    PackagePackageRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { formatPrice, sortByRank } from '@europrocurement/l2d-utils';
import { generateUUID } from '../Package/Form/utils';

type ArticleRelatedModels = PackagePackageRead | OptionValueOptionValueRead;
type EditableApiArticles = ArticleRelatedModels['articles'];

/** Function that format ApiArticles to formArticles usable in ArticlesModule
 * (Draggable / rankable / overridable ...)
 * @param apiArticles
 */
export const apiArticlesToFormEditableArticles = (apiArticles: EditableApiArticles) => {
    if (!apiArticles) {
        return { articles: [] };
    }

    const formArticles = apiArticles.map((apiArticle) => ({
        id: generateUUID(), // DraggableId
        originalArticle: apiArticle.article,
        label: apiArticle.libelle ?? null,
        rank: apiArticle.rang ?? 0,
        articleId: apiArticle.article?.id ?? 0,
        price: apiArticle.tarif ? formatPrice(apiArticle.tarif) : null,
        quantity: apiArticle.quantite ?? 1,
        subTypeId: apiArticle.sType?.id as number,
        invoicingCategory: apiArticle.article?.rubArticle ?? null,
        priceInformations: {
            isFlatRate: false,
            flatPrice: '',
        },
    }));

    return {
        articles: sortByRank(formArticles),
    };
};
