import * as React from 'react';
import { InformationCard, InformationCardProps } from '@europrocurement/flexy-components';
import { ArticlesArticleRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { formatPrice } from '@europrocurement/l2d-utils';
import { JsonLdData, EntityPreview } from '../forms/types';
import { articleUnits, taxRates } from '../constants';

type Items = InformationCardProps['items'];

type EntityType = JsonLdData<JsonLdData<ArticlesArticleRead>>;

const ArticlePreview: React.FunctionComponent<EntityPreview<EntityType>> = function (props) {
    const { entity, onEdit } = props;

    const {
        libelle: label,
        codeArticle: code,
        prixUnitaire: price,
        societe: company,
        id,
        isArticleValid,
        natureTva: taxType,
        rubArticle: invoicingCategory,
        unite: unit,
        documents,
    } = entity;

    const {
        cegidCompte: cegidId,
        cegidSp1: sp1,
        cegidSp2: sp2,
        cegidTl1: tl1,
        cegidTl2: tl2,
        cegidTl3: tl3,
    } = entity;

    const companyLabel = company?.raisonSociale;

    const getPriceLabel = () => {
        switch (price) {
            case '0':
            case '0.0':
            case '0.00':
            case '0.000':
            case '0.0000':
                return 'Article gratuit';
            case null:
                return 'non défini';
            default:
                return `${formatPrice(price)} €`;
        }
    };

    const getUnitLabel = () => articleUnits[(unit as keyof typeof articleUnits) ?? 'default'];
    const getTaxRateLabel = () => taxRates[(taxType as keyof typeof taxRates) ?? 'default'];

    const cardItems: Items = [
        {
            label: 'ID',
            value: id?.toString() ?? null,
        },
        {
            label: 'Article valide',
            value: isArticleValid ? 'Oui' : 'Non',
        },
        {
            label: 'Rubrique de facturation',
            value: invoicingCategory ?? null,
        },
        {
            label: 'Unité de facturation',
            value: getUnitLabel() ?? null,
        },
        {
            label: 'Code',
            value: code ?? null,
        },
        {
            label: 'Prix unitaire',
            value: getPriceLabel() ?? null,
        },
        {
            label: 'Taux de TVA',
            value: getTaxRateLabel() ?? null,
        },
        {
            label: 'Société',
            value: companyLabel ?? null,
        },
    ];

    const cegidConfigurationItems: Items = [
        {
            label: 'Identifiant',
            value: cegidId ?? null,
        },
        {
            label: 'SP1',
            value: sp1 ?? null,
        },
        {
            label: 'S2',
            value: sp2 ?? null,
        },
        {
            label: 'TL1',
            value: tl1 ?? null,
        },
        {
            label: 'TL2',
            value: tl2 ?? null,
        },
        {
            label: 'TL3',
            value: tl3 ?? null,
        },
    ];

    const documentItems: () => Items = () => {
        if (!documents || documents.length === 0) {
            return [];
        }
        return documents?.map((doc, index) => ({
            label: (index + 1).toString(),
            value: doc.typeDocument?.libelle,
        }));
    };

    return (
        <>
            <InformationCard
                title={label ?? ''}
                items={cardItems}
                button={{
                    label: 'Éditer',
                    onClick: onEdit,
                }}
            />

            {invoicingCategory === 'DIV' && (
                <InformationCard
                    title="Configuration Cegid"
                    items={cegidConfigurationItems}
                />
            )}
            {documents && documents.length > 0 && (
                <InformationCard
                    title="Documents"
                    items={documentItems()}
                />
            )}
        </>
    );
};

export default ArticlePreview;
