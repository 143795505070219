import * as React from 'react';

import { Chip } from '@mui/material';
import { UtilisateurApiObject } from '@europrocurement/l2d-domain';

export type RightsChipsProps = {
    user: UtilisateurApiObject;
};

const RightsChips: React.FunctionComponent<RightsChipsProps> = function ({ user }) {
    const { hasG3Access, hasComptaSoftware, hasAccessL2DF, isAdminApps } = user;

    return (
        <div data-testid="chipsBox">
            {hasG3Access && (
                <Chip
                    label="G3"
                    sx={{ marginRight: 1 }}
                    data-testid="chipG3"
                />
            )}
            {hasComptaSoftware && (
                <Chip
                    label="CEGID"
                    sx={{ marginRight: 1 }}
                    data-testid="chipCEGID"
                />
            )}
            {hasAccessL2DF && (
                <Chip
                    label="L2DF"
                    sx={{ marginRight: 1 }}
                    data-testid="chipL2DF"
                />
            )}
            {isAdminApps && (
                <Chip
                    label="ADMIN"
                    sx={{ marginRight: 1 }}
                    data-testid="chipADMIN"
                />
            )}
        </div>
    );
};

export default RightsChips;
