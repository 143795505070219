import * as React from 'react';

import { CircularProgress, CircularProgressPropsColorOverrides, Palette } from '@mui/material';
import { Box, SxProps } from '@mui/system';

type PaletteItem = keyof Palette;

export type CenterCircularProgressProps = {
    color?: PaletteItem;
    sx?: SxProps;
};

const CenterCircularProgress: React.FunctionComponent<CenterCircularProgressProps> = function ({
    color = 'primary',
    sx,
}: CenterCircularProgressProps) {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx,
            }}
            data-testid="CenterCircularProgress"
        >
            <CircularProgress color={color as keyof CircularProgressPropsColorOverrides} />
        </Box>
    );
};

export default CenterCircularProgress;
