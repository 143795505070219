import * as React from 'react';
import { Box } from '@mui/material';
import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';

type LabeledIconProps = {
    label?: React.ReactNode;
    icon?: NamedIconsType;
};

const LabeledIcon: React.FunctionComponent<LabeledIconProps> = function (props) {
    const { label, icon } = props;

    return (
        <Box
            data-testid="LabeledIcon-test-id"
            display="flex"
            alignItems="center"
            sx={{ marginBottom: '10px' }}
        >
            {icon && (
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ marginRight: '10px' }}
                >
                    {icon && <FaOptionIcon {...icon.props} />}
                </Box>
            )}
            <Box
                display="flex"
                alignItems="center"
            >
                {label ?? ''}
            </Box>
        </Box>
    );
};

export default LabeledIcon;
