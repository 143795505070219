import { Avatar, Box, ListItemAvatar } from '@mui/material';
import * as React from 'react';
import { FormArticle, FormContextType } from './types';

type ComponentProps = {
    article: FormArticle;
    context: FormContextType;
};

const LineIndexInformation: React.FunctionComponent<ComponentProps> = function ({
    context,
    article,
}) {
    const { id } = article;

    const { getValues } = context;
    const { articles } = getValues();
    // Exclude Section & line breaks
    const filteredArticles = articles.filter(
        (item: FormArticle) => item.invoicingCategory !== 'LIB',
    ) as FormArticle[];
    const articleArrayIndex = filteredArticles.findIndex((articleItem) => articleItem.id === id);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <ListItemAvatar>
                <Avatar>{articleArrayIndex + 1}</Avatar>
            </ListItemAvatar>
        </Box>
    );
};

export default LineIndexInformation;
