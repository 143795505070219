import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { InformationChip } from '@europrocurement/flexy-components';

export type PriorityChipProps = {
    critical?: boolean;
};

const PriorityChip: React.FunctionComponent<PriorityChipProps> = function ({ critical = false }) {
    const { palette } = useTheme();
    const label = critical ? 'Urgent' : 'Normal';
    const color = critical ? 'error' : 'primary';
    const baseColor = palette[color].main;

    return (
        <InformationChip
            color={color}
            label={
                <Typography
                    variant="l2dbodys"
                    color={baseColor}
                >
                    {label}
                </Typography>
            }
        />
    );
};

export default PriorityChip;
