import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

export type DataIndicatorWidgetProps = {
    label: string;
    value?: string | null;
    loading?: boolean;
};

const DataIndicatorWidget: React.FunctionComponent<DataIndicatorWidgetProps> = function ({
    label,
    loading = false,
    value = '-',
}) {
    const labelStyle = {
        opacity: '0.6',
        fontSize: '17px',
        paddingBottom: '13px',
    };
    const valueStyle = {
        fontWeight: '500',
        fontSize: '24px',
    };

    return (
        <Box
            data-testid="DataIndicatorWidget-test-id"
            display="flex"
            flexDirection="column"
        >
            <Typography sx={labelStyle}>{label}</Typography>
            {loading ? (
                <Skeleton
                    variant="text"
                    sx={valueStyle}
                />
            ) : (
                <Typography sx={valueStyle}>{value ?? '-'}</Typography>
            )}
        </Box>
    );
};

export default DataIndicatorWidget;
