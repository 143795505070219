import { useMemo } from 'react';
import { debounce } from '@mui/material/utils';
import { useGoogleMapApi } from '@europrocurement/l2d-modules/hooks/useGoogleMapApi';

const scriptLoaded = { current: false };
const placeDetailService = { current: null };
const autocompleteService = { current: null };
const geocoderService = { current: null };

export type MainTextMatchedSubstrings = {
    offset: number;
    length: number;
};

export type StructuredFormatting = {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
};

export type PlaceType = {
    place_id: string;
    description: string;
    structured_formatting: StructuredFormatting;
};

export type AddressFormatted = {
    address: string;
    addressComplement?: string;
    postalCode: string;
    city: string;
    countryCode: string;
};

export type AddressComponent = {
    types: Array<string>;
    long_name: string;
    short_name: string;
};

export type GeocodedCity = {
    postalCode: string;
    city: string;
    countryCode: string;
};

export type GeocoderStatus =
    | 'OK'
    | 'ERROR'
    | 'INVALID_REQUEST'
    | 'OVER_QUERY_LIMIT'
    | 'REQUEST_DENIED'
    | 'UNKNOWN_ERROR'
    | 'ZERO_RESULTS';

const usePlaceAutocomplete = () => {
    const gmapApiKey = useGoogleMapApi();

    const getAutocompleteService = () => {
        if (!autocompleteService.current) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        return autocompleteService.current;
    };

    const getPlaceDetailService = () => {
        if (!placeDetailService.current) {
            placeDetailService.current = new window.google.maps.places.PlacesService(
                document.createElement('div'),
            );
        }

        return placeDetailService.current;
    };

    const getGecoderService = () => {
        if (!geocoderService.current) {
            geocoderService.current = new window.google.maps.Geocoder();
        }

        return geocoderService.current;
    };

    const filterAddressComponent = (
        components: Array<AddressComponent>,
        types: Array<string>,
        field: 'long_name' | 'short_name' = 'long_name',
    ) =>
        components
            .filter(
                (component) => component.types.filter((type) => types.includes(type)).length > 0,
            )
            .map((component: AddressComponent) =>
                component[field] !== undefined ? component[field] : component.long_name,
            )
            .join(', ');

    const formatAddress = (components: Array<AddressComponent>): AddressFormatted => {
        console.log(components);
        const address: AddressFormatted = {
            address: filterAddressComponent(components, ['street_number', 'route', 'plus_code']),
            addressComplement: filterAddressComponent(components, ['sublocality']) ?? '',
            city: filterAddressComponent(components, ['locality', 'postal_town']),
            postalCode: filterAddressComponent(components, ['postal_code']),
            countryCode: filterAddressComponent(components, ['country'], 'short_name'),
        };

        return address;
    };

    const getPlaceDetail = async (placeId: string): Promise<AddressComponent[]> =>
        new Promise((resolve, reject) => {
            const request = {
                placeId,
                fields: ['address_components'],
            };

            const service = getPlaceDetailService();

            if (!service) {
                reject();

                return;
            }

            service.getDetails(request, (place: { address_components: AddressComponent[] }) => {
                if (place.address_components) {
                    return resolve(place.address_components);
                }

                reject();

                return null;
            });
        });

    const loadScript = () => {
        if (scriptLoaded.current || window?.google?.maps) {
            return;
        }

        scriptLoaded.current = true;

        const script = document.createElement('script');
        script.setAttribute('async', '');
        script.setAttribute('id', 'google-map');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${gmapApiKey}&libraries=places&loading=async`;
        document.querySelector('head')?.appendChild(script);
    };

    const fetchSuggestions = useMemo(
        () =>
            debounce(
                (
                    request: {
                        input: string;
                        componentRestrictions?: { country: string };
                    },
                    callback: (results?: readonly PlaceType[]) => void,
                ) => {
                    getAutocompleteService().getPlacePredictions(request, callback);
                },
                400,
            ),
        [],
    );

    const geocodePostalCode = (searchTerm: string, isFrench: boolean): Promise<GeocodedCity[]> => {
        const service = getGecoderService();
        let request: { address: string; componentRestrictions?: unknown } = {
            address: searchTerm,
        };
        if (isFrench) {
            request = {
                ...request,
                componentRestrictions: {
                    country: 'FR',
                },
            };
        }
        return new Promise((resolve, reject) => {
            service.geocode(
                request,
                (
                    results: Array<{
                        postcode_localities: string[];
                        address_components: AddressComponent[];
                    }>,
                    status: GeocoderStatus,
                ) => {
                    if (status !== 'OK') {
                        reject();

                        return null;
                    }

                    const items: GeocodedCity[] = [];

                    results.forEach((item) => {
                        const postalCode = filterAddressComponent(item.address_components, [
                            'postal_code',
                        ]);
                        const countryCode = filterAddressComponent(
                            item.address_components,
                            ['country'],
                            'short_name',
                        );

                        if (item.postcode_localities) {
                            item.postcode_localities.forEach((locality) => {
                                items.push({
                                    postalCode,
                                    city: locality,
                                    countryCode,
                                });
                            });
                        } else {
                            items.push({
                                city: filterAddressComponent(item.address_components, ['locality']),
                                postalCode,
                                countryCode,
                            });
                        }
                    });

                    resolve(items);

                    return null;
                },
            );
        });
    };

    return { getPlaceDetail, loadScript, fetchSuggestions, formatAddress, geocodePostalCode };
};

export default usePlaceAutocomplete;
