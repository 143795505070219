import React, { useCallback, useEffect } from 'react';

import { CenterCircularProgress } from '@europrocurement/flexy-components';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import { Outlet } from 'react-router';

const RejectCodeRoot = function () {
    const loadInitialOptions = useCallback(() => {}, []);

    useEffect(() => {
        loadInitialOptions();
    }, [loadInitialOptions]);

    const isApplicationReady = () => true;

    return isApplicationReady() ? (
        <KeycloakRoute roles="realm:interne">
            <Outlet />
        </KeycloakRoute>
    ) : (
        <CenterCircularProgress />
    );
};

export default RejectCodeRoot;
