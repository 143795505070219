import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { ContextActionType } from '@europrocurement/flexy-components/providers';
import {
    SidebarWidth,
    SidebarFoldedWidth,
    paddingContent,
} from '../../../assets/global/Theme-variable';
import { type MenuEntry, HeaderProps, FooterProps, SidebarProps } from '../../organisms';
import { Scrollbar } from '../../molecules';
import { useFullLayout } from './FullLayoutContext';

export type FullLayoutProps = {
    menuEntries: MenuEntry[];
    footerProps: FooterProps;
    headerProps?: HeaderProps;
    contextActionMainIcon?: ReactNode;
    defaultContextActions?: ContextActionType[];
    logo: SidebarProps['logo'];
    logoFolded: SidebarProps['logoFolded'];
    sidebarActions?: SidebarProps['actions'];
    pageTitle?: string;
};

const FullLayoutOutlet: React.FunctionComponent = function () {
    const theme = useTheme();

    const smBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const hiddenSidebarContentPaddingLeft = smBreakpoint
        ? SidebarFoldedWidth + paddingContent
        : paddingContent;

    const { isSidebarOpen } = useFullLayout();

    return (
        <Scrollbar>
            <Box
                sx={{
                    paddingLeft: isSidebarOpen
                        ? `${SidebarWidth + paddingContent}px !important`
                        : `${hiddenSidebarContentPaddingLeft}px !important`,
                    paddingRight: `${paddingContent}px !important`,
                }}
            >
                <Outlet />
            </Box>
        </Scrollbar>
    );
};

export default FullLayoutOutlet;
