import { exceptionFacturanteApi, selectExceptionFacturante } from '@b2d/redux/RootStore';
import { AppDispatch, RootStateType } from '@b2d/redux/types';
import { useSociete } from '@europrocurement/l2d-domain';
import { AxiosRequestConfig } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import {
    ExceptionFacturanteApiPatchExceptionFacturanteItemRequest,
    PatchExceptionFacturanteItemRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { ExceptionFacturante } from '@europrocurement/l2d-domain/reducers/achats/slices/exceptionFacturante';

/**
 * Hooks that regroup exceptionFacturante actions / methods / accessors
 */

export const SelectedExceptionFacturantesSelector = (s: RootStateType) =>
    s.achats.exceptionFacturante.main.selected;

export const ExceptionFacturantesDatasourceSelector = (s: RootStateType) =>
    s.achats.exceptionFacturante.main;

export const ExceptionFacturantesDatasourceStatusSelector = (s: RootStateType) =>
    s.achats.exceptionFacturante.main.status;

export const useCurrentExceptionFacturante = (): ExceptionFacturante | undefined => {
    const exceptionFacturante: ExceptionFacturante | undefined = useSelector(
        SelectedExceptionFacturantesSelector,
    );

    return exceptionFacturante;
};

export const useExceptionFacturanteDatasource = (): DataSource<ExceptionFacturante> => {
    const datasource: DataSource<ExceptionFacturante> = useSelector(
        ExceptionFacturantesDatasourceSelector,
    );

    return datasource;
};

export const useExceptionFacturanteStatus = (): EuroprocApiResponseStatus => {
    const status: EuroprocApiResponseStatus = useSelector(
        ExceptionFacturantesDatasourceStatusSelector,
    );

    return status;
};

export const useExceptionFacturanteIsLoading = (): boolean => {
    const statusExceptionFacturante = useExceptionFacturanteStatus();
    const statusOfLoading: Array<EuroprocApiResponseStatus> = ['idle', 'loading'];

    return statusOfLoading.includes(statusExceptionFacturante);
};

export const useExceptionFacturanteIsFailed = (): boolean => {
    const statusExceptionFacturante = useExceptionFacturanteStatus();
    const statusFailing: Array<EuroprocApiResponseStatus> = ['failed'];

    return statusFailing.includes(statusExceptionFacturante);
};

export const useSelectFacturanteException = () => {
    const exceptionFacturante = useCurrentExceptionFacturante();
    const exceptionFacturanteStatus = useExceptionFacturanteStatus();
    const dispatch = useDispatch<AppDispatch>();

    return (
        exceptionFacturanteId: string,
        options?: AxiosRequestConfig,
        reload: boolean = false,
    ) => {
        if (reload) {
            dispatch(selectExceptionFacturante({ id: exceptionFacturanteId, options }));
        } else if (
            (!exceptionFacturante ||
                exceptionFacturante.id?.toString() !== exceptionFacturanteId) &&
            exceptionFacturanteStatus !== 'loading'
        ) {
            dispatch(selectExceptionFacturante({ id: exceptionFacturanteId, options }));
        }
    };
};

export const useUnSelectFacturanteException = () => {
    const dispatch = useDispatch<AppDispatch>();

    return () => {
        dispatch({ type: 'exceptionFacturante/deletemainSelected' });
    };
};

export const useReloadExceptionFacturante = () => {
    const exceptionFacturante = useCurrentExceptionFacturante();

    const unselect = useUnSelectFacturanteException();
    const select = useSelectFacturanteException();
    return (options?: AxiosRequestConfig) => {
        if (!exceptionFacturante || !exceptionFacturante.id) {
            console.error("Can't reload exceptionFacturante, exceptionFacturante is not selected");
            return;
        }
        unselect();
        select(exceptionFacturante.id.toString(), options, true);
    };
};

export const usePatchExceptionFacturante = () => {
    const xIdSociete = useSociete();

    return (
        id: string,
        payload: PatchExceptionFacturanteItemRequest,
        options?: AxiosRequestConfig,
    ) => {
        const requestParameters: ExceptionFacturanteApiPatchExceptionFacturanteItemRequest = {
            id,
            patchExceptionFacturanteItemRequest: payload,
            xIdSociete,
        };

        return [
            {
                name: 'patchExceptionFacturanteItem',
                promise: exceptionFacturanteApi.patchExceptionFacturanteItem(
                    requestParameters,
                    options,
                ),
            },
        ];
    };
};

export const useExceptionFacturanteUtils = (exceptionFacturanteToSelect?: string) => {
    const dispatch = useDispatch<AppDispatch>();
    const exceptionFacturante = useCurrentExceptionFacturante();

    if (!exceptionFacturante && !exceptionFacturanteToSelect) {
        throw new Error('missing selected exceptionFacturante and no forced exceptionFacturante');
    }

    if (
        exceptionFacturanteToSelect ||
        (exceptionFacturanteToSelect &&
            exceptionFacturante &&
            exceptionFacturante.id?.toString() !== exceptionFacturanteToSelect)
    ) {
        dispatch(selectExceptionFacturante({ id: exceptionFacturanteToSelect }));
    }

    const companyId = useSociete();

    const unselectFacturanteException = useUnSelectFacturanteException();
    const selectFacturanteException = useSelectFacturanteException();
    const reloadExceptionFacturante = useReloadExceptionFacturante();
    const patchExceptionFacturante = usePatchExceptionFacturante();

    return {
        exceptionFacturante,
        companyId,
        methods: {
            unselectFacturanteException,
            selectFacturanteException,
            reloadExceptionFacturante,
            patchExceptionFacturante,
        },
    };
};
