import { customizerSelector } from '@b2d/redux/RootStore';
import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { FaOptionIcon, formalityAddIcon } from '@europrocurement/l2d-icons';
import { CategoriesFormalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/categoriesFormalite';
import {
    ContextActionType,
    LazyBreadcrumb,
    useDumbActionsContext,
} from '@europrocurement/flexy-components';

import { Chip, Typography } from '@mui/material';
import {
    categoriesFormalitySelector,
    formalitiesSelector,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import FormalityListItemActions from './FormalityListItemActions';
import { FORMALITY_NEW } from '../../constants/paths';

const FormalitiesListing: React.FunctionComponent = function () {
    const { getFormalites } = useFormaliteService();
    const datasource = useSelector(formalitiesSelector);
    const { xIdSociete } = useSelector(customizerSelector);
    const categoriesDatasource = useSelector(categoriesFormalitySelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const contextActions: ContextActionType[] = [
        {
            name: 'Ajouter une formalité',
            icon: <FaOptionIcon {...formalityAddIcon.props} />,
            action: () => navigate(FORMALITY_NEW),
        },
    ];

    useDumbActionsContext(contextActions);

    const columns: ColumnDatatable<Formalite>[] = useMemo(
        () => [
            {
                label: 'Libellé',
                render: 'libelle',
                isDisplayed: true,
                sortable: true,
                field: 'libelle',
            },
            {
                label: 'Icône',
                render: ({ iconeName }) => {
                    if (iconeName === null) return null;

                    return <FaOptionIcon icon={['fad', iconeName]} />;
                },
                isDisplayed: true,
            },
            {
                label: 'Catégorie',
                render: (item: Formalite) => (
                    <>
                        <Typography
                            display="inline"
                            sx={{
                                fontSize: '20px',
                            }}
                        >
                            {item.categorie?.iconeName ? (
                                <FaOptionIcon icon={['fad', item.categorie?.iconeName]} />
                            ) : null}
                        </Typography>
                        <Typography
                            display="inline"
                            sx={{ marginLeft: '15px' }}
                        >
                            {item.categorie?.libelle ? item.categorie?.libelle : null}
                        </Typography>
                    </>
                ),
                isDisplayed: true,
                sortable: true,
                field: 'categorie',
            },
            {
                label: 'Statut',
                displayLabel: false,
                render: (item: Formalite) => (
                    <div>
                        {item.deleted ? (
                            <Chip
                                color="warning"
                                label="Inactif"
                            />
                        ) : (
                            <Chip
                                color="success"
                                label="Actif"
                            />
                        )}
                    </div>
                ),
                isDisplayed: false,
            },
            {
                label: 'Actions',
                displayLabel: false,
                render: (item) =>
                    item.id && (
                        <FormalityListItemActions
                            item={item}
                            onItemUpdate={() => dispatch(getFormalites({}))}
                        />
                    ),
                isDisplayed: true,
            },
        ],
        [dispatch, getFormalites],
    );

    const filters: FiltersDatatableList = [
        {
            label: 'Catégorie',
            field: 'categorie',
            type: 'staticSelect',
            options: categoriesDatasource.data.map((category: CategoriesFormalite) => ({
                value: category.id,
                label: category.libelle,
            })),
            renderFilterList: (value) =>
                categoriesDatasource.data.find(
                    (category: CategoriesFormalite) => category.id === value,
                )?.libelle,
        },
        {
            label: 'Formalités inactives ?',
            field: 'deleted',
            type: 'boolean',
        },
    ];

    return datasource ? (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Formalités', link: '/prescripteurs/liste' },
                    "Recherche d'une formalité",
                    'Liste des résultats',
                ]}
            />
            <StoreDatatable
                dataSource={datasource}
                columns={columns}
                fetchData={getFormalites}
                filters={filters}
                localStorageKey="FlexyStoreDatatable"
                onClickRow={(_: React.MouseEvent<HTMLElement>, { id }: Formalite) =>
                    navigate(`${id}`)
                }
                observers={[xIdSociete]}
            />
        </>
    ) : null;
};

export default FormalitiesListing;
