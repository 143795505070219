import {
    OptionValueJsonldOptionValueCreate,
    OptionsJsonldOptionRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { FaOptionIcon, previewIcon } from '@europrocurement/l2d-icons';
import OptionCheckBoxPreview from './OptionCheckBoxPreview';
import OptionRadioPreview from './OptionRadioPreview';
import OptionSelectPreview from './OptionSelectPreview';
import { JsonLdData } from '../../forms/types';

type OfferOptionPreviewProps = {
    entity: JsonLdData<OptionsJsonldOptionRead> & {
        values: OptionValueJsonldOptionValueCreate[];
    };
};

const OptionValuesPreview: React.FunctionComponent<OfferOptionPreviewProps> = function (props) {
    const { entity: option } = props;

    const { values: optionValues } = option;

    const { libelle: optionLabel = '' } = option;
    const numberOfOptions = optionValues.length;

    const getHtmlFieldType = () => {
        if (numberOfOptions <= 0) {
            return null;
        }

        if (numberOfOptions === 1) {
            return 'checkbox';
        }
        if (numberOfOptions > 1 && numberOfOptions <= 3) {
            return 'radio';
        }

        return 'select';
    };
    const htmlFieldType = getHtmlFieldType();

    const getPreview = () => {
        switch (htmlFieldType) {
            case null:
                return (
                    <p style={{ opacity: '0.4' }}>
                        (Ajoutez une valeur à l&apos;option pour en obtenir l&apos;aperçu)
                    </p>
                );
            case 'checkbox':
                return <OptionCheckBoxPreview optionValue={optionValues[0]} />;
            case 'radio':
                return (
                    <OptionRadioPreview
                        optionValues={optionValues}
                        optionLabel={optionLabel}
                    />
                );
            case 'select':
                return (
                    <OptionSelectPreview
                        optionValues={optionValues}
                        optionLabel={optionLabel}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ margin: '15px 0px' }}
            >
                <FaOptionIcon
                    {...previewIcon.props}
                    size="xl"
                    sx={{ marginRight: '20px' }}
                />

                <Typography>Aperçu de l&apos;option</Typography>
            </Box>
            <Divider />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {getPreview()}
            </Box>
        </Box>
    );
};

export default OptionValuesPreview;
