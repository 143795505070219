/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

export const DEFAULT_ERROR_MESSAGE = 'Une erreur est survenue';

/**
 * useHandleError - A reusable hook to handle various types of error feedback in an application.
 *
 * This hook provides utilities to handle general error feedback, API validation errors,
 * and failure cases in a standardized way across the application. It integrates with
 * the `notistack` library to display snackbar notifications for error messages.
 *
 * It now also supports status management for cases where the hook needs to update
 * loading/error/success states in addition to showing error messages.
 *
 * @returns {object} - Returns an object with `handleFailure`, `classicErrorFeedback` and `handleApiValidationErrorMessages` functions.
 */
const useHandleError = () => {
    const { enqueueSnackbar } = useSnackbar();

    type HandleApiValidationErrorMessagesProps = {
        validationErrors: any[];
    };

    /**
     * handleApiValidationErrorMessages
     *
     *    - Handles and displays API validation errors in a consistent format.
     *    - Expects an array of validation errors, each containing a `message` and `property`.
     *    - Displays error messages using the `notistack` enqueueSnackbar function.
     *
     *    @param {object} HandleApiValidationErrorMessagesProps
     *    @param {Array}  validationErrors - An array of validation errors from the API.
     */
    const handleApiValidationErrorMessages = useCallback(
        ({ validationErrors }: HandleApiValidationErrorMessagesProps) => {
            validationErrors.forEach(
                ({ message, property }: { message: string; property: string }) => {
                    const [propertyName] = property.split('.').slice(-1);
                    const messageContent = `${propertyName ? `${propertyName}: ` : ''}${message}`;
                    enqueueSnackbar(messageContent, { variant: 'error' });
                },
            );
        },
        [enqueueSnackbar],
    );

    type ClassicErrorFeedbackProps = {
        customMessage?: string;
    };

    /**
     * classicErrorFeedback
     *
     *    - Provides a generic error feedback mechanism that displays a default error message
     *      or a custom message (if provided).
     *    - Useful for general error handling where specific error details are not needed.
     *
     *    @param {object} ClassicErrorFeedbackProps
     *    @param {string} [customMessage] - An optional custom error message to display.
     */
    const classicErrorFeedback = useCallback(
        ({ customMessage }: ClassicErrorFeedbackProps) => {
            enqueueSnackbar(customMessage ?? DEFAULT_ERROR_MESSAGE, { variant: 'error' });
        },
        [enqueueSnackbar],
    );

    type HandleFailureProps = {
        error: any;
        withToaster?: boolean;
        failureCallback?: (error: any) => void;
        setError?: (message: string) => void;
    };

    /**
     * handleFailure
     *
     *    - A general error handling function that processes different types of failures:
     *      - It can display validation errors if available.
     *      - It can also show a general error message using `classicErrorFeedback`.
     *      - Allows the developer to pass a custom failure callback for additional handling.
     *      - Optionally updates status flags (loading, error, success) when used in conjunction
     *        with status management in a component.
     *
     *    @param {object} HandleFailureProps
     *    @param {any}    error - The error object returned from the API.
     *    @param {boolean} [withToaster=true] - Whether or not to show a snackbar notification.
     *    @param {function} [failureCallback] - A custom callback function to handle the error further.
     *    @param {function} [setError] - Optionally set error to update status.
     *
     * Example Usage:
     *
     * ```jsx
     * import useHandleError from 'path/to/hooks/useHandleError';
     *
     * const MyComponent = () => {
     *     const { handleFailure } = useHandleError();
     *
     *     const apiRequest = async () => {
     *         try {
     *             // API call logic here
     *         } catch (error) {
     *             handleFailure({ error, withToaster: true, setError });
     *         }
     *     };
     *
     *     return <div>MyComponent</div>;
     * };
     * ```
     */
    const handleFailure = useCallback(
        ({ error, withToaster = true, failureCallback, setError }: HandleFailureProps) => {
            if (failureCallback) {
                failureCallback(error);
            }

            if (setError) {
                setError(error.message ?? DEFAULT_ERROR_MESSAGE);
            }

            if (withToaster) {
                const validationErrors = error?.response?.data?.exception?.violations;

                let messagedErrors = DEFAULT_ERROR_MESSAGE;

                if (error?.response?.data?.exception?.message) {
                    messagedErrors = error?.response?.data?.exception?.message;
                } else if (error?.message) {
                    messagedErrors = error?.message;
                }

                if (validationErrors) {
                    handleApiValidationErrorMessages({ validationErrors });
                } else {
                    classicErrorFeedback({ customMessage: messagedErrors });
                }
            }
        },
        [classicErrorFeedback, handleApiValidationErrorMessages],
    );

    return { handleFailure, classicErrorFeedback, handleApiValidationErrorMessages };
};

export default useHandleError;
