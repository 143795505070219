import { useContext } from 'react';
import { DevisApi } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import {
    DevisLignesApi,
    FactureApi,
    FacturesMultiplesApi,
} from '@europrocurement/l2d-domain/openApi/ApiFacturation';
import { QuoteLineModel } from '@europrocurement/l2d-domain/reducers/facturation/Models/quoteLine';
import { FacturationReducerType, QuoteModel } from '@europrocurement/l2d-domain/reducers';
import L2DApiContext from '../L2DApiContext';

export const useFacturationService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.facturation) {
        throw new Error('Facturation service is not registred in L2DApiProvider');
    }

    const { apis, slices, thunks, fetchItems, getItems, models, reducer } = context.facturation;

    return {
        devisApi: apis.devisApi as DevisApi,
        devisLignesApi: apis.devisLignesApi as DevisLignesApi,
        factureApi: apis.factureApi as FactureApi,
        facturesMultiplesApi: apis.facturesMultiplesApi as FacturesMultiplesApi,

        factureFormaliteSlice: slices.factureFormaliteSlice,
        devisSlice: slices.devisSlice,
        devisLigneSlice: slices.devisLigneSlice,

        factureFormaliteDataSourcesThunks: thunks.factureFormaliteDataSourcesThunks,
        devisDataSourcesThunks: thunks.devisDataSourcesThunks,
        devisLigneDataSourcesThunks: thunks.devisLigneDataSourcesThunks,

        getFacturesFormalite: fetchItems.getFacturesFormalite,
        getDevis: fetchItems.getDevis,
        getDevisLignes: fetchItems.getDevisLignes,

        selectFacturesFormalite: getItems.selectFacturesFormalite,
        selectDevis: getItems.selectDevis,
        selectDevisLignes: getItems.selectDevisLignes,

        quoteModel: models.quote as QuoteModel,
        quoteLineModel: models.quoteLine as QuoteLineModel,

        reducer: reducer as FacturationReducerType,
    };
};
