import { toISOString } from '@europrocurement/l2d-utils';

import type {
    FactureAchatCreate,
    FactureFormObject,
} from '@b2d/pages/Achats/components/forms/types';
import type { MediaObject } from '@europrocurement/l2d-domain';
import type { FactureAchatJsonldFactureAchatCreateFactureAchatWrite } from '@europrocurement/l2d-domain/openApi/ApiAchats';

import { checkConsole, contactSupport } from '@europrocurement/l2d-utils/function-utils';
import formProductLinesToApiProductLines from './formProductLinesToApiProductLines';

/**
 * Define the fields which doesn't change depending on the type of invoice.
 *
 * @param formInvoicePurchase
 * @param mediaObject
 * @param invoiceIri - Needed only in case the line must be associated to existing invoice.
 * @returns
 */
export const setCommonApiInvoicePurchaseFields = function (
    formInvoicePurchase: FactureFormObject,
    mediaObject: MediaObject,
    invoiceIri?: string,
): FactureAchatJsonldFactureAchatCreateFactureAchatWrite {
    const factureAchatFormated: FactureAchatCreate = {
        cloture: true,
        avoir: formInvoicePurchase.avoir,
        hasSetRsf: false,
        dateFacture: toISOString(formInvoicePurchase.date_facture),
        numeroFacture: formInvoicePurchase.numero_facture,
        idEntiteFacturante: formInvoicePurchase.fournisseur.id
            ? +formInvoicePurchase.fournisseur.id
            : undefined,
        libelleFacturante: formInvoicePurchase.fournisseur.libelle,
        idSociete: mediaObject.idSociete,
        codesRejets: [],
        notes: [],
        pdfFacture: mediaObject['@id'],
        idReleveAchat: formInvoicePurchase.id_statement,
        lignes: formProductLinesToApiProductLines(formInvoicePurchase, invoiceIri),
        ht: formInvoicePurchase.total_ht ? formInvoicePurchase.total_ht?.toString() : undefined,
        tva: formInvoicePurchase.total_tva ? formInvoicePurchase.total_tva?.toString() : undefined,
        ttc: formInvoicePurchase.total_ttc ? formInvoicePurchase.total_ttc?.toString() : undefined,
    };

    return factureAchatFormated;
};

/**
 * Define fields of api invoice purchase as publication.
 *
 * @param formInvoicePurchase
 * @returns
 */
export const setApiInvoicePurchasePublicationFields = function (
    formInvoicePurchase: FactureFormObject,
): FactureAchatJsonldFactureAchatCreateFactureAchatWrite {
    return {
        idJal: formInvoicePurchase?.dossier?.prestations[0]?.jal?.id
            ? formInvoicePurchase?.dossier?.prestations[0]?.jal?.id
            : null,
        libelleAnnonceur: formInvoicePurchase?.nom_annonceur || null,
    };
};

/**
 * Define fields of api invoice purchase as formality
 *
 * @param formInvoicePurchase
 * @returns
 */
export const setApiInvoicePurchaseFormalityFields = function (
    formInvoicePurchase: FactureFormObject,
): FactureAchatJsonldFactureAchatCreateFactureAchatWrite {
    return {
        idJal: formInvoicePurchase?.dossier?.prestations[0]?.jal?.id
            ? formInvoicePurchase?.dossier?.prestations[0]?.jal?.id
            : null,
        libelleAnnonceur: formInvoicePurchase?.dossier_formalite?.societeDenomination || null,
    };
};

/**
 * Convert form invoice purchase into api invoice purchase.
 * Result is ready to be sent as a request parameter to POST a new invoice purchase.
 *
 * @param formInvoicePurchase
 * @param mediaObject
 * @returns
 */
export default (
    formInvoicePurchase: FactureFormObject,
    mediaObject: MediaObject,
): FactureAchatJsonldFactureAchatCreateFactureAchatWrite => {
    let apiInvoicePurchase = setCommonApiInvoicePurchaseFields(formInvoicePurchase, mediaObject);

    if (formInvoicePurchase.dossier && !formInvoicePurchase.dossier_formalite) {
        apiInvoicePurchase = {
            ...apiInvoicePurchase,
            ...setApiInvoicePurchasePublicationFields(formInvoicePurchase),
        };
    } else if (formInvoicePurchase.dossier_formalite && !formInvoicePurchase.dossier) {
        apiInvoicePurchase = {
            ...apiInvoicePurchase,
            ...setApiInvoicePurchaseFormalityFields(formInvoicePurchase),
        };
    } else {
        console.error(formInvoicePurchase);
        throw new Error(
            `La facture est associée aux deux ou aucun des types de dossier possibles. ${contactSupport} ${checkConsole}`,
        );
    }

    return apiInvoicePurchase;
};
