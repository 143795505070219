/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    FACTURATION_REDUCER_NAME,
    FORMALITES_REDUCER_NAME,
    FORMULAIRE_REDUCER_NAME,
    OFFRE_REDUCER_NAME,
    TIERS_REDUCER_NAME,
    UTILISATEUR_REDUCER_NAME,
    buildFacturationRedux,
    buildFormaliteRedux,
    buildFormulairesRedux,
    buildOfferRedux,
    buildTiersRedux,
    buildUtilisateurRedux,
} from '@europrocurement/l2d-domain';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { standaloneReducerBase } from './RootStore';

const appReducer = combineReducers({
    [OFFRE_REDUCER_NAME]: buildOfferRedux({}).reducer,
    [FORMALITES_REDUCER_NAME]: buildFormaliteRedux({}).reducer,
    [TIERS_REDUCER_NAME]: buildTiersRedux({}).reducer,
    [UTILISATEUR_REDUCER_NAME]: buildUtilisateurRedux({}).reducer,
    [FORMULAIRE_REDUCER_NAME]: buildFormulairesRedux({}).reducer,
    [FACTURATION_REDUCER_NAME]: buildFacturationRedux({}).reducer,
    ...standaloneReducerBase,
});

const appStore = configureStore({
    reducer: appReducer,
});

export type RootStateType = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector;
