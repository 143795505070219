import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { formatPrice } from '@europrocurement/l2d-utils';

import { useSelector } from 'react-redux';
import { FlexyInput } from '@europrocurement/flexy-components';
import { cachedSubTypesOptionsSelector } from '@europrocurement/l2d-domain';
import SelectField from '../../components/FormInputs/SelectField';
import TextField from '../../components/form/TextField';
import { ArticleAdditionalInformations, ArticlesSchema } from '../Form/validation';
import AdvertPricing from '../../components/AdvertPricing';
import { ArticleFormPricingState } from '../Form/types';

type ComponentProps = {
    articlePath: string;
    context: UseFormReturn<FieldValues, unknown>;
    article: ArticlesSchema['articles'][number] & ArticleAdditionalInformations;
    isOptionForm?: boolean;
};

const ArticleOverrideSubForm: React.FunctionComponent<ComponentProps> = function ({
    articlePath,
    context,
    article,
    isOptionForm,
}) {
    const { subTypeId } = article;

    const sTypeOptions = useSelector(cachedSubTypesOptionsSelector);
    const defaultPricingState = {
        isFlatRate: false,
        flatPrice: null,
    };

    const [pricingInformations, setArticlePricingInformations] =
        useState<ArticleFormPricingState>(defaultPricingState);

    if (!sTypeOptions) {
        return <CircularProgress />;
    }

    const getInputName = (key: string) => `${articlePath}.${key}`;

    const updatePricingInformations = (informations: ArticleFormPricingState) => {
        setArticlePricingInformations(informations);
        const path = getInputName('priceInformations');
        context.setValue(path, informations);
    };

    const isAdvertCategory = article.originalArticle?.rubArticle === 'PUB';

    const {
        formState: { errors },
        control,
    } = context;

    const originalLabel = article.originalArticle?.libelle;
    const originalPrice = article.originalArticle?.prixUnitaire;

    const pricePlaceholder = () => {
        if (originalPrice) {
            return `${formatPrice(originalPrice)} €`;
        }
        return 'Aucun prix défini';
    };

    const { isFlatRate, flatPrice } = pricingInformations;

    return (
        <Box>
            {isAdvertCategory && (
                <>
                    <Controller
                        name={getInputName('subTypeId')}
                        control={control}
                        render={({ field }) => (
                            <SelectField
                                {...field}
                                required
                                label="Sous-type"
                                placeholder="Sélectionnez un sous-type"
                                name={field.name}
                                blurInputOnSelect
                                options={sTypeOptions}
                                errors={errors}
                            />
                        )}
                    />
                    <AdvertPricing
                        subTypeId={subTypeId ?? undefined}
                        setArticlePricingInformations={updatePricingInformations}
                    />
                </>
            )}
            <Controller
                name={getInputName('label')}
                control={control}
                render={({ field }) => (
                    <TextField
                        sx={{ width: '100%' }}
                        {...field}
                        name={field.name}
                        placeholder={originalLabel ?? ''}
                        label="Libellé"
                        errors={errors}
                        required={false}
                    />
                )}
            />

            {isFlatRate ? (
                <FlexyInput
                    sx={{ width: '100%' }}
                    disabled
                    value={flatPrice}
                    inputlabel="Tarif (au forfait)"
                    size="small"
                    type="text"
                    variant="outlined"
                    name=""
                />
            ) : (
                <Controller
                    name={getInputName('price')}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            sx={{ width: '100%' }}
                            {...field}
                            name={field.name}
                            placeholder={pricePlaceholder()}
                            label="Tarif"
                            errors={errors}
                            required={false}
                        />
                    )}
                />
            )}
            {isOptionForm && (
                <Controller
                    name={getInputName('regle')}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            sx={{ width: '100%' }}
                            {...field}
                            name={field.name}
                            placeholder="Règle"
                            label="Règle"
                            errors={errors}
                            required={false}
                        />
                    )}
                />
            )}
        </Box>
    );
};

export default ArticleOverrideSubForm;
