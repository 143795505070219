import { OFFER_CACHED_KEY, OFFRE_REDUCER_NAME } from '@europrocurement/l2d-domain';
import { OfferReducerType } from '@europrocurement/l2d-domain/reducers/offre/types';

export const offerCacheSelector = (reduxState: OfferReducerType) =>
    reduxState[OFFRE_REDUCER_NAME][OFFER_CACHED_KEY]; // state.offre.cached ...

export const cachedDepartmentsSelector = (s: OfferReducerType) =>
    s[OFFRE_REDUCER_NAME][OFFER_CACHED_KEY].departments;

export const cachedOriginsSelector = (s: OfferReducerType) =>
    s[OFFRE_REDUCER_NAME][OFFER_CACHED_KEY].origins;

export const cachedSubTypesSelector = (s: OfferReducerType) =>
    s[OFFRE_REDUCER_NAME][OFFER_CACHED_KEY].subTypes;

export const cachedOffersSelector = (s: OfferReducerType) =>
    s[OFFRE_REDUCER_NAME][OFFER_CACHED_KEY].offers;

export const cachedGroupsSelector = (s: OfferReducerType) =>
    s[OFFRE_REDUCER_NAME][OFFER_CACHED_KEY].groups;

export const cachedDomainsSelector = (s: OfferReducerType) =>
    s[OFFRE_REDUCER_NAME][OFFER_CACHED_KEY].domains;
