import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { FlexyIconButton, FlexyIconButtonProps, useModal } from '@europrocurement/flexy-components';
import { dateInput } from '@europrocurement/l2d-icons';
import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { NamedAxiosPromise } from '@europrocurement/flexy-components/types/NamedAxiosPromise';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { exceptionFacturanteApi, getDefFrequence } from '@b2d/redux/RootStore';
import { AppDispatch, RootStateType } from '@b2d/redux/types';
import { useSociete } from '@europrocurement/l2d-domain';

import { ExtractPropertyType } from '@b2d/utils/types';
import { useForm } from 'react-hook-form';
import {
    ExceptionFacturante,
    ExceptionFacturanteApiObject,
} from '@europrocurement/l2d-domain/reducers/achats/slices/exceptionFacturante';
import {
    ExceptionFacturanteJsonldExceptionFacturanteRead,
    PatchExceptionFacturanteItemRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { useDispatch, useSelector } from 'react-redux';
import {
    DEF_FREQUENCE_SLICE_NAME,
    DefFrequence,
} from '@europrocurement/l2d-domain/reducers/achats/slices/defFrequenceSlice';
import FormModal from '../modals/FormModal/FormModal';
import LabeledAsyncSwitch from './LabeledAsyncSwitch';
import { ChoiceProps } from '../modals/FormModal/types';

/**
 * ExceptionFacturanteSwitchesGroup Component
 *
 * A group of switches to manage the toggle states for different reject code fields.
 * Each switch triggers an API request to update the corresponding field dynamically.
 *
 * Props:
 * - `rejectCode`: The reject code object containing field values for toggles.
 * - `fetchData`: A Redux thunk function to refresh data after toggle updates.
 *
 * Features:
 * - Dynamically updates reject code fields (e.g., `manuel`, `bloquant`, `relanceAuto`).
 * - Supports handling multiple toggle switches with customizable actions.
 * - Includes additional buttons for contextual interactions (e.g., frequency selection).
 *
 * Example:
 * ```
 * <ExceptionFacturanteSwitchesGroup
 *   exceptionFacturante={exceptionFacturante}
 *   fetchData={fetchData}
 * />
 * ```
 */

type ExceptionFacturanteSwitchesGroupProps = {
    exceptionFacturante: ExceptionFacturante;
    fetchData: getDataThunkType<ExceptionFacturanteApiObject>;
    patchExceptionFacturante: (
        id: string,
        payload: PatchExceptionFacturanteItemRequest,
        options?: AxiosRequestConfig,
    ) => Array<{
        name: string;
        promise: Promise<AxiosResponse<ExceptionFacturanteJsonldExceptionFacturanteRead, unknown>>;
    }>;
    disabledToggles?: Partial<Record<'manuel' | 'bloquant' | 'relanceAuto', boolean>>;
};

const ExceptionFacturanteSwitchesGroup: React.FunctionComponent<ExceptionFacturanteSwitchesGroupProps> =
    function ({
        exceptionFacturante,
        fetchData,
        patchExceptionFacturante,
        disabledToggles,
    }: ExceptionFacturanteSwitchesGroupProps) {
        const xIdSociete = useSociete();
        const { modalActions } = useModal();
        const modalFrequencyPickFormContext = useForm({
            mode: 'onTouched',
        });
        const defFrequenceDatasource: DataSource<DefFrequence> = useSelector(
            (s: RootStateType) => s.achats[DEF_FREQUENCE_SLICE_NAME].main,
        );

        const dispatch = useDispatch<AppDispatch>();

        const selectedFrequency = React.useMemo(() => {
            if (defFrequenceDatasource.data.length === 0) {
                dispatch(getDefFrequence({}));
            }

            return defFrequenceDatasource.data.find(
                (defFrequence) => exceptionFacturante.idFrequenceRelance === defFrequence.dfrId,
            );
        }, [defFrequenceDatasource.data, dispatch, exceptionFacturante.idFrequenceRelance]);

        type ActionParameters = Parameters<Exclude<ChoiceProps['action'], undefined>>;
        type HandleActionOnChangeFrequencyType = (
            event: ActionParameters[0],
            checked: ActionParameters[1],
            defFrequence: DefFrequence,
        ) => void;

        const handleActionOnChangeFrequency = React.useCallback<HandleActionOnChangeFrequencyType>(
            (event, checked, defFrequence) => {
                if (!checked || !exceptionFacturante.id) return;

                patchExceptionFacturante(exceptionFacturante.id.toString(), {
                    idFrequenceRelance: defFrequence.dfrId,
                });

                setTimeout(() => {
                    dispatch(fetchData({}));
                }, 100);

                modalActions.close();
            },
            [dispatch, fetchData, modalActions, patchExceptionFacturante, exceptionFacturante.id],
        );

        const defFrequenceChoices: Array<ChoiceProps> = defFrequenceDatasource.data.map(
            (defFrequence) => ({
                label: defFrequence.dfrLibelle ? defFrequence.dfrLibelle : '???',
                defaultSelected:
                    exceptionFacturante.idFrequenceRelance === defFrequence.dfrId ||
                    (!exceptionFacturante.idFrequenceRelance && defFrequence.dfrId === 1),
                action: (event: React.ChangeEvent<Element>, checked: boolean) => {
                    handleActionOnChangeFrequency(event, checked, defFrequence);
                },
            }),
        );

        const handleFrequencyPickButtonClick = React.useCallback<
            Exclude<ExtractPropertyType<FlexyIconButtonProps, 'onClick'>, undefined>
        >(() => {
            modalActions.call(
                <FormModal
                    key={`${modalFrequencyPickFormContext}-${xIdSociete}`}
                    title="Fréquence de relance"
                    icon={dateInput}
                    context="Définir la fréquence à laquelle la relance sera automatiquement déclenchée"
                    uniqChoice
                    inputNature="select"
                    choices={defFrequenceChoices}
                />,
            );
        }, [modalActions, modalFrequencyPickFormContext, xIdSociete, defFrequenceChoices]);

        /**
         * Dynamically updates a specific field in the publisher exception.
         * @param field - The field to update in the reject code object.
         */
        const updateExceptionFacturanteField = <K extends keyof ExceptionFacturante>(
            field: K,
        ): AxiosPromise | undefined => {
            if (!exceptionFacturante.id) {
                return undefined;
            }

            return exceptionFacturanteApi.patchExceptionFacturanteItem({
                id: exceptionFacturante.id.toString(),
                patchExceptionFacturanteItemRequest: {
                    [field]: !exceptionFacturante[field],
                },
                xIdSociete,
            });
        };

        /**
         * Generates a toggle request for a specific reject code field.
         * @param field - The field to toggle.
         * @returns An array of NamedAxiosPromise for toggle requests.
         */
        const createToggleRequest = (
            field: keyof ExceptionFacturante,
        ): Array<NamedAxiosPromise> => [
            {
                name: `updateExceptionFacturante${field.charAt(0).toUpperCase() + field.slice(1)}`,
                promise: updateExceptionFacturanteField(field) as AxiosPromise,
            },
        ];

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    justifyContent: 'space-evenly',
                    gap: 1,
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <LabeledAsyncSwitch
                        key={`${exceptionFacturante.relanceAuto}`}
                        requestsOnToggle={() => createToggleRequest('relanceAuto')}
                        fetchData={fetchData}
                        defaultChecked={!!exceptionFacturante.relanceAuto}
                        disabled={!!disabledToggles?.relanceAuto}
                        label="Relance auto"
                    />
                    {exceptionFacturante.relanceAuto ? (
                        <FlexyIconButton
                            icon={dateInput}
                            iconOverwriteProps={{
                                size: 'sm',
                                color: 'secondary',
                            }}
                            displayTooltip
                            tooltipOverwriteProps={{
                                title: `Fréquence sélectionnée : ${selectedFrequency?.dfrLibelle || ' - '}`,
                            }}
                            onClick={handleFrequencyPickButtonClick}
                        />
                    ) : null}
                </Stack>
            </Box>
        );
    };

export default ExceptionFacturanteSwitchesGroup;
