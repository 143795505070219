import React from 'react';
import { SelectItem } from '@europrocurement/flexy-components';
import { Box, useMediaQuery, useTheme } from '@mui/material';

export type BillingRecipientItemProps = {
    label: string;
    value: boolean;
};

export type BillingRecipientProps = {
    prescriberBilled: boolean;
    items: Array<BillingRecipientItemProps>;
    onChange: (prescriberBilled: boolean) => void;
};

const BillingRecipient: React.FunctionComponent<BillingRecipientProps> = function (props) {
    const { prescriberBilled, items, onChange: handleChange } = props;
    const { breakpoints } = useTheme();
    const downMd = useMediaQuery(breakpoints.down('md'));

    const labelSx = {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '25.2px',
        marginBottom: '4px',
    };

    const formWrapperSx = {
        display: 'flex',
        gap: '8px',
        flexDirection: downMd ? 'column' : 'row',
    };

    return (
        <Box data-testid="billing-recipient">
            <Box sx={labelSx}>Facture éditée au nom de :</Box>
            <Box sx={formWrapperSx}>
                {items.map((item) => (
                    <SelectItem
                        key={`client-${item.value}`}
                        label={item.label}
                        value={item.value}
                        isChecked={item.value === prescriberBilled}
                        onChange={(v) => handleChange(v as boolean)}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default BillingRecipient;
