import React from 'react';
import { Card } from '@mui/material';
import { useModal } from '@europrocurement/flexy-components/providers';

import { FlexyForm } from '@europrocurement/flexy-form';
import {
    InformationCard,
    InformationCardItemProps,
    OptionsType,
    OptionType,
} from '@europrocurement/flexy-components';
import { UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { FieldValues, useForm } from 'react-hook-form';
import {
    companyOptions,
    createUserFormStructure,
} from '@b2d/pages/Utilisateurs/functions/createUserFormStructure';
import { type User } from '../UserTabs/type';
import { UserAccess } from '../UserAccess/UserAccess';

type ClassicValues = {
    prenom: User['prenom'];
    nom: User['nom'];
    initiales: User['initiales'];
    user: User['user'];
    mail: User['mail'];
    ligneDirecte: User['ligneDirecte'];
    portable: User['portable'];
    poste: User['poste'];
    fonction: User['fonction'];
};

type BaseForm = {
    pole: OptionType | undefined;
    service: OptionType | undefined;
    userGroupe: OptionType | undefined;
    idSociete: OptionType | undefined;
    idSocieteEmployeur: OptionType | undefined;
} & ClassicValues;

export type UserFormReturn = {
    pole: string | undefined;
    service: string | undefined;
    userGroupe: string | undefined;
    idSociete: number | undefined;
    idSocieteEmployeur: number | undefined;
} & ClassicValues;

export type UserInfosProps = {
    user: User;
    poles: OptionsType;
    services: OptionsType;
    groupes: OptionsType;
    handlePatchInfos: (
        payload: UserFormReturn,
        dirtyFields?: {
            [key: string]: boolean;
        },
    ) => void;
    handleValidationModal: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => void;
};

const UserInfos: React.FunctionComponent<UserInfosProps> = function ({
    user,
    services,
    poles,
    groupes,
    handlePatchInfos,
    handleValidationModal,
}) {
    /**
     * List all the user contact informations
     */
    const contactInfos: Array<InformationCardItemProps> = [
        { label: 'Prénom', value: user.prenom },
        { label: 'Nom', value: user.nom },
        { label: 'Initiales', value: user.initiales },
        { label: 'Login', value: user.user },
        { label: 'Mail', value: user.mail },
        { label: 'Ligne directe', value: user.ligneDirecte },
        { label: 'Portable', value: user.portable },
        { label: 'Poste interne', value: user.poste },
        { label: 'Fonction', value: user.fonction },
        // { label: 'Antenne', value: `${user.idAntenne}` || 0 }, sera ajouté par la suite
        { label: 'Pole', value: user.pole?.libelle },
        { label: 'Service', value: user.service?.libelle },
        { label: 'Groupe', value: user.userGroupe?.groupName },
        { label: 'Libellé Société', value: user.libelleSociete },
        { label: 'Libellé Société employeur', value: user.libelleSocieteEmployeur },
    ];

    // form default values (initial values from the API)
    const defaultValues: BaseForm = {
        prenom: user.prenom,
        nom: user.nom,
        initiales: user.initiales,
        user: user.user,
        mail: user.mail,
        ligneDirecte: user?.ligneDirecte ?? '',
        portable: user.portable,
        poste: user.poste || null,
        fonction: user.fonction,

        pole: poles.find((option) => option.value === user.pole?.id),
        service: services.find((option) => option.value === user.service?.id),
        userGroupe: groupes.find((option) => option.value === user.userGroupe?.id),
        idSociete: companyOptions.find((option) => option.value === user.idSociete),
        idSocieteEmployeur: companyOptions.find(
            (option) => option.value === user.idSocieteEmployeur,
        ),
    };

    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues as FieldValues,
    });

    /**
     * Function that catch the difference between the initial form data and the submitted form data
     * @param initial
     * @param current
     * @returns payload : patch object
     */
    const getModifiedValues = (current: BaseForm) => {
        let formatedMail = current.mail;

        if (current.mail === '') {
            formatedMail = null;
        }

        const payload: UserFormReturn = {
            ...current,
            idSociete: current.idSociete?.value as number | undefined,
            idSocieteEmployeur: current.idSocieteEmployeur?.value as number | undefined,
            service: `/utilisateur/services/${current.service?.value}` as string | undefined,
            pole: `/utilisateur/poles/${current.pole?.value}` as string | undefined,
            userGroupe: `/utilisateur/groupes/${current.userGroupe?.value}` as string | undefined,
            mail: formatedMail,
        };

        return payload;
    };

    const { modalActions } = useModal();

    /**
     * Get the form data, patch them and close the modal
     * @param currentFormValues
     */
    const onUserInfoSubmit = (currentFormValues: BaseForm) => {
        const payload = getModifiedValues(currentFormValues);
        const {
            formState: { dirtyFields },
        } = formContext;
        handlePatchInfos(payload, dirtyFields);
    };

    const modifyUser = () => {
        modalActions.call(
            <FlexyForm
                formObject={defaultValues}
                formContext={formContext}
                formStructure={createUserFormStructure(services, poles, groupes)}
                onSubmit={onUserInfoSubmit}
                gridProps={{ maxWidth: 'md', rowSpacing: 0, columnSpacing: 2 }}
            />,
        );
    };

    return (
        <>
            <UserAccess
                user={user}
                handleValidationModal={handleValidationModal}
            />
            <Card
                data-testid="info-card"
                sx={{ width: '100%', margin: '10px 0 0 0', padding: '0' }}
            >
                <InformationCard
                    title="Informations utilisateur"
                    button={{
                        label: 'Modifier',
                        onClick: modifyUser,
                    }}
                    items={contactInfos}
                    subtitle=""
                />
            </Card>
        </>
    );
};
export default UserInfos;
