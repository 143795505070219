import { SkeletonArray } from '@europrocurement/flexy-components';
import { TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';

const FlexyDatatableBodyLoading: React.FunctionComponent = function () {
    return (
        <TableBody data-testid="FlexyDatatableBody">
            <TableRow>
                <TableCell
                    data-testid="StatusLoading"
                    colSpan={12}
                    sx={{ height: '45vh' }}
                >
                    <SkeletonArray
                        withHeader={false}
                        columns={4}
                        rows={8}
                    />
                </TableCell>
            </TableRow>
        </TableBody>
    );
};

export default FlexyDatatableBodyLoading;
