import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import type { MediaObjectApiPatchMediaObjectItemRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { toISOString } from '@europrocurement/l2d-utils';
import models from '@b2d/pages/Achats/models';
import { useApiRequest } from '@europrocurement/l2d-hooks';

type SetStartTreatmentDateProps = {
    mediaObjectId?: string;
    newDate?: string;
};

export type UseHandleCleanIOInvoiceFormProps = {
    noAutomaticClean?: boolean;
};

/**
 * Logic to handle the invoice form exit.
 * By default, will automatically bring back availability of the selected MediaObject on unmount of current component.
 *
 * @param noAutomaticClean - Avoid to automatically bring back availability of the selected MediaObject on unmount of current component.
 */
const useHandleCleanIOInvoiceForm = ({
    noAutomaticClean = false,
}: UseHandleCleanIOInvoiceFormProps) => {
    const { mediaobjectid } = useParams();
    const { request } = useApiRequest();

    const [noAutomaticCleanValue, setNoAutomaticCleanValue] =
        useState<typeof noAutomaticClean>(noAutomaticClean);

    const setDateToIsoString = (date: number): string =>
        toISOString(new Date(new Date().setDate(date)));

    const yesterday: string = useMemo(() => setDateToIsoString(new Date().getDate() - 1), []);
    const now: string = useMemo(() => setDateToIsoString(new Date().getDate()), []);

    /**
     * Patch MediaObject by id, to set new start treatment date.
     *
     * @param mediaObjectId - Define MediaObject to patch.
     * @param newDate - New value of start treatment date.
     */
    const setStartTreatmentDate = useCallback(
        async ({ mediaObjectId, newDate }: SetStartTreatmentDateProps) => {
            if (!mediaObjectId) return;

            const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                id: mediaObjectId,
                mediaObject: {
                    dateDebutTraitement: newDate,
                },
            };

            await request(models.mediaObject.patch(requestParameters), {
                withToaster: false,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    /**
     * Set current MediaObject as in use, by patching start treatment date value to right now.
     */
    const turnCurrentMediaObjectInUse = useCallback(async () => {
        await setStartTreatmentDate({ mediaObjectId: mediaobjectid, newDate: now });
    }, [mediaobjectid, now, setStartTreatmentDate]);

    /**
     * Set current MediaObject as available, by patching start treatment date value to yesterday.
     */
    const turnCurrentMediaObjectAvailable = useCallback(async () => {
        await setStartTreatmentDate({ mediaObjectId: mediaobjectid, newDate: yesterday });
    }, [mediaobjectid, yesterday, setStartTreatmentDate]);

    /**
     * Actions to do to properly enter invoice form.
     *
     *  1. Set current MediaObject in treatment.
     */
    const inUseProcess = useCallback(async () => {
        await turnCurrentMediaObjectInUse();
    }, [turnCurrentMediaObjectInUse]);

    /**
     * Actions to do to properly exit invoice form.
     *
     *  1. Set current MediaObject available for treatment.
     */
    const cleanProcess = useCallback(async () => {
        await turnCurrentMediaObjectAvailable();
    }, [turnCurrentMediaObjectAvailable]);

    /**
     * Run the callback after cleaning.
     *
     * @param callback - Custom action to trigger after cleaning.
     * @return The callback return.
     */
    const actionAfterClean = useCallback(
        async <T>(callback: () => T) => {
            setNoAutomaticCleanValue(true);
            await cleanProcess();
            return callback();
        },
        [cleanProcess],
    );

    /**
     * Triggers the clean-up on component unmount.
     */
    useEffect(
        () => () => {
            if (noAutomaticCleanValue) return;
            cleanProcess();
        },
        [noAutomaticCleanValue, cleanProcess],
    );

    return {
        cleanProcess,
        inUseProcess,
        actionAfterClean,
    };
};

export default useHandleCleanIOInvoiceForm;
