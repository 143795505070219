import React, { useEffect, useState } from 'react';
import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FlexyFormLabel } from '@europrocurement/flexy-components';
import { useDispatch, useSelector } from 'react-redux';
import { PaysApiObject, PaysSelector, useTiersService } from '@europrocurement/l2d-domain';
import { AnyAction } from '@reduxjs/toolkit';
import { EuroprocStore } from '@europrocurement/l2d-redux-utils';

export type ControlledCountrySelectProps = {
    name: string;
    rules: RegisterOptions;
    label?: string;
};

type CountryChoice = {
    label: string;
    value: string;
};

const ControlledCountrySelect: React.FunctionComponent<ControlledCountrySelectProps> = function (
    props,
) {
    const { name, rules, label } = props;
    const { control } = useFormContext();
    const dispatch = useDispatch();
    const datasource: EuroprocStore<PaysApiObject> = useSelector(PaysSelector);
    const { getPays } = useTiersService();
    const [choices, setChoices] = useState<Array<CountryChoice>>([]);

    useEffect(() => {
        if (datasource.main.status === 'idle') {
            dispatch(getPays({}) as unknown as AnyAction);

            return;
        }

        if (datasource.main.status !== 'succeeded') {
            return;
        }

        const c = datasource.main.data.map((country) => ({
            value: country.codeIso2 as string,
            label: country.libelle as string,
        }));
        setChoices(c);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasource.main.status]);

    const findChoiceByValue = (currentValue: string): CountryChoice | null => {
        const defaultValue = choices.find((choice) => currentValue === choice.value);

        return defaultValue || null;
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                    {label && label !== '' ? (
                        <FlexyFormLabel
                            required={!!rules?.required}
                            requiredTooltip={rules?.required ? 'Le champ est requis' : undefined}
                        >
                            {label}
                        </FlexyFormLabel>
                    ) : null}

                    <Autocomplete
                        options={choices}
                        renderInput={(params) => (
                            <TextField
                                error={error !== undefined}
                                {...params}
                                placeholder="Choisissez votre pays"
                            />
                        )}
                        clearIcon={null}
                        freeSolo
                        onChange={(
                            event: React.FormEvent<HTMLInputElement>,
                            newValue: CountryChoice | null,
                        ) => {
                            onChange(newValue?.value || '');
                        }}
                        value={findChoiceByValue(value)}
                    />

                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledCountrySelect;
