import React from 'react';

import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { useTheme } from '@mui/system';
import { Box, Button, Grid, Typography, TypographyProps } from '@mui/material';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

export type BasicButtonType = {
    label?: string;
    onClick: () => void;
    color?: TypographyProps['color'];
    icon?: IconDefinition;
    underline?: boolean;
    disabled?: boolean;
    testId?: string;
};

const BasicButton: React.FunctionComponent<BasicButtonType> = function ({
    label = 'Précédent',
    onClick,
    color,
    icon,
    underline = false,
    disabled = false,
    testId,
}: BasicButtonType) {
    const theme = useTheme();
    return (
        <Button
            data-testid={testId ?? 'test-id-basic-btn'}
            onClick={onClick}
            sx={{
                marginLeft: '0px',
                paddingLeft: '0px',
                '&:hover': {
                    textDecoration: underline ? 'underline' : '',
                    textDecorationColor: color || theme.palette.primary.dark,
                },
            }}
            disabled={disabled}
        >
            {' '}
            <Grid
                container
                sx={{
                    marginLeft: '0px',
                }}
            >
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        paddingLeft: '0px',
                    }}
                >
                    <Box>
                        {icon && (
                            <FaOptionIcon
                                icon={icon}
                                color={color || theme.palette.primary.dark}
                                sx={{
                                    fontSize: '13px',
                                    position: 'relative',
                                    top: '1px',
                                    marginX: '5px',
                                }}
                            />
                        )}
                    </Box>
                    <Typography
                        className="previous-label"
                        color={color || theme.palette.primary.dark}
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        </Button>
    );
};

export default BasicButton;
