import { OffresApi, OffresOffreRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { offerIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';

import { type ModelConfiguration, type ModelMethods, type ModelItem } from '../../..';

import { baseOffersSelector } from '../selectors';

import { OFFERS_SLICE_NAME } from '../constants';

const modelKey = 'offer';
const modelName = 'Offer';
const sliceName = OFFERS_SLICE_NAME;

type ModelStructure = OffresOffreRead;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
};

export type OfferEndpoint = {
    list: InstanceType<typeof OffresApi>['apiOffresGetCollection'];
    delete: InstanceType<typeof OffresApi>['apiOffresIdDelete'];
    dependencies: InstanceType<typeof OffresApi>['apiOffresIdDependenciesGet'];
    read: InstanceType<typeof OffresApi>['apiOffresIdGet'];
    update: InstanceType<typeof OffresApi>['apiOffresIdPut'];
    create: InstanceType<typeof OffresApi>['apiOffresPost'];
};

export type OfferModel = ModelItem<ModelStructure> & OfferEndpoint;

export const buildOffreModel = (
    api: OffresApi,
    datasourcesThunks: DataSourcesThunksType<OffresOffreRead>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Offres',
        sliceName,
        selector: baseOffersSelector,
        api,
        datasourcesThunks,
        icon: offerIcon,
    };

    const endpoints: OfferEndpoint = {
        list: api.apiOffresGetCollection.bind(api),
        delete: api.apiOffresIdDelete.bind(api),
        dependencies: api.apiOffresIdDependenciesGet.bind(api),
        read: api.apiOffresIdGet.bind(api),
        update: api.apiOffresIdPut.bind(api),
        create: api.apiOffresPost.bind(api),
    };

    const model: OfferModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };
    return model;
};
