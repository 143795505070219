import React from 'react';
import { BooleanBadge } from '@europrocurement/flexy-components';
import { Card, Grid, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { User } from '../UserTabs/type';

export type UserCompoProps = {
    user: User;
    handlePatchData: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
    ) => void;
    handleValidationModal: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => void;
};

export const UserCompo: React.FunctionComponent<UserCompoProps> = function ({
    user,
    handlePatchData,
    handleValidationModal,
}) {
    const theme = useTheme();

    // verify the input data modification before patch to the API
    const handleInputChange = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    ) => {
        const newValue = event.target.value;

        if (newValue !== '' && newValue !== undefined && newValue !== null) {
            // verify if the value has been changed before send it to the API
            if (event.target.id === 'paper') {
                if (newValue !== user.droitUtilisateur?.usdAppsMdcOption) {
                    handlePatchData({ droitUtilisateur: { usdAppsMdcOption: newValue } });
                }
            } else if (event.target.id === 'spel') {
                if (newValue !== user.droitUtilisateur?.usdAppsMdcSpelOption) {
                    handlePatchData({ droitUtilisateur: { usdAppsMdcSpelOption: newValue } });
                }
            }
        }
    };

    return (
        <Card
            sx={{
                width: '100%',
                margin: '15px 0',
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography
                variant="l2dh6"
                sx={{
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                    padding: '5px 0 5px 30px',
                    marginLeft: '-15px',
                }}
            >
                Application Composition des annonces
            </Typography>
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    gap: '15px',
                    width: '100%',
                    margin: '10px',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '15px',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: '10px',
                    }}
                >
                    <BooleanBadge
                        label="Accès à l'application (annonces papier)"
                        value={user.droitUtilisateur?.usdAppsMdc}
                        icon
                        toggleAction={() =>
                            handleValidationModal(
                                {
                                    droitUtilisateur: {
                                        usdAppsMdc: !user.droitUtilisateur?.usdAppsMdc,
                                    },
                                },
                                'usdAppsMdc',
                                user.droitUtilisateur?.usdAppsMdc as boolean,
                            )
                        }
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            width: '70%',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="l2dh7"
                            sx={{ marginRight: '10px' }}
                            data-testid="input-Mdc-Option"
                        >
                            Numéro des JAL à gérer :
                        </Typography>
                        <TextField
                            data-testid="Mdc-input"
                            id="paper"
                            placeholder={user.droitUtilisateur?.usdAppsMdcOption || 'Aucun'}
                            onBlur={(event) => handleInputChange(event)}
                            defaultValue={user.droitUtilisateur?.usdAppsMdcOption}
                            sx={{ width: '70%' }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '15px',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: '10px',
                    }}
                >
                    <BooleanBadge
                        key="usdAppsMdcSpel"
                        label="Accès à l'application (annonces SPEL)"
                        value={user.droitUtilisateur?.usdAppsMdcSpel}
                        icon
                        toggleAction={() =>
                            handleValidationModal(
                                {
                                    droitUtilisateur: {
                                        usdAppsMdcSpel: !user.droitUtilisateur?.usdAppsMdcSpel,
                                    },
                                },
                                'usdAppsMdcSpel',
                                user.droitUtilisateur?.usdAppsMdcSpel as boolean,
                            )
                        }
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            width: '70%',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="l2dh7"
                            sx={{ marginRight: '10px' }}
                            data-testid="input-Mdc-Spel-Option"
                        >
                            Numéro des JAL à gérer :
                        </Typography>
                        <TextField
                            data-testid="spel-input"
                            id="spel"
                            placeholder={user.droitUtilisateur?.usdAppsMdcSpelOption || 'Aucun'}
                            onBlur={(event) => handleInputChange(event)}
                            defaultValue={user.droitUtilisateur?.usdAppsMdcSpelOption}
                            sx={{ width: '70%' }}
                        />
                    </Box>
                </Box>
            </Grid>
        </Card>
    );
};
