import React, { useCallback, useContext, useEffect } from 'react';
import RecapFormalityShow from '@europrocurement/l2d-modules/modules/ParcoursFormalite/components/RecapFormalityShow';
import { Box, Container } from '@mui/system';
import {
    CollectionDeDossierCollectiondossierRead,
    FichiersDossierJsonldDocumentfileRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { MESSAGE_FROM_PRESCRIBER, useFormaliteService } from '@europrocurement/l2d-domain';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { CircularProgress } from '@mui/material';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { useParcoursFormalite, useParcoursFormalitreHeader } from '../provider';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import { loadDossier } from '../functions/docFunctions';
import { DossierPrescriberContext } from '../../Dossiers/providers';

const StepRecap: React.FunctionComponent = function () {
    const snackbar = useSnackbar();

    const {
        getCurrentDossier,
        getCurrentCollection,
        getPrescripteur,
        currentSousClient,
        setLoading,
    } = useParcoursFormalite();

    React.useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    const { fichiersDossierApi, dossierApi, collectionApi, messageApi } = useFormaliteService();
    const { sousClients, prescriberLoaded, setPrescriberId } = useContext(DossierPrescriberContext);
    const kc = UseKeycloakService();

    const collection = getCurrentCollection();
    const dossier = getCurrentDossier();
    const navigate = useNavigate();
    const prescripteur = getPrescripteur();
    const sousClient = currentSousClient;

    const [currentDocs, setCurrentDocs] =
        React.useState<Array<FichiersDossierJsonldDocumentfileRead> | null>(null);

    useEffect(() => {
        setPrescriberId(prescripteur.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescripteur]);

    const reloadDossierAndDocs = useCallback(async () => {
        if (dossier) {
            const prom = loadDossier(dossier, fichiersDossierApi);
            return await prom.then((res) => {
                const fichiers = res.data['hydra:member'];
                setCurrentDocs(fichiers);
            });
        }
        return Promise.resolve();
    }, [dossier, fichiersDossierApi]);

    useEffect(() => {
        if (dossier && dossier.id && !currentDocs) {
            reloadDossierAndDocs();
        }
    }, [currentDocs, dossier, fichiersDossierApi, reloadDossierAndDocs]);

    const { changeParcoursSteps, setIsParcoursPath } = useParcoursFormalitreHeader();

    const { setProfileHeaderVisible } = useFullLayout();
    React.useEffect(() => {
        changeParcoursSteps(4);
        setProfileHeaderVisible(false);
        setIsParcoursPath(true);
        return () => {
            setIsParcoursPath(false);
            setProfileHeaderVisible(true);
        };
    }, [setProfileHeaderVisible, changeParcoursSteps, setIsParcoursPath]);

    const onSubmitHandler = useCallback(
        async (
            innerCollection: CollectionDeDossierCollectiondossierRead,
            refInterne: string | null,
            commentaires: string | null,
            dossierUrgent: boolean | null,
            typeFacturation: number,
            clientId: number,
        ) => {
            setLoading(true);
            if (innerCollection.dossiers?.length !== 1) {
                throw new Error('error in dossier length');
            }
            const innerDossier = innerCollection.dossiers[0];

            const freshDossier = (await dossierApi.apiDossiersIdGet({ id: `${innerDossier.id}` }))
                .data;
            await dossierApi.apiDossiersIdPut({
                id: `${innerDossier.id}`,
                dossierJsonldDossierUpdate: {
                    ...freshDossier,
                    societeRef: refInterne,
                },
            });

            if (!collection || !collection.id) {
                throw new Error('error in Collection');
            }

            const freshCollection = (
                await collectionApi.apiCollectionsDossiersIdGet({ id: `${collection.id}` })
            ).data;

            if (!freshCollection || !freshCollection.id) {
                throw new Error('missing freshCollection');
            }

            if (!freshCollection.client) {
                throw new Error('missing client');
            }
            if (!freshCollection.prescripteur) {
                throw new Error('missing prescripteur');
            }

            await collectionApi.apiCollectionsDossiersIdValidatePut({
                id: `${freshCollection?.id}`,
                collectionDeDossierJsonldCollectiondossierUpdate: {
                    prescripteur: freshCollection.prescripteur?.replace(
                        '/tiers/tiers',
                        '/formalite/tiers',
                    ),
                    urgent: dossierUrgent === true,
                    client: `/formalite/tiers/${clientId}`,
                    infoComp: freshCollection.infoComp,
                    libelle: freshCollection.libelle,
                },
            });

            if (commentaires && commentaires !== '') {
                await messageApi.apiMessagesPost({
                    messageJsonldMessageCreate: {
                        expediteur: MESSAGE_FROM_PRESCRIBER,
                        expediteurNom: kc.getName(),
                        message: commentaires,
                        dossier: `/formalite/dossiers/${innerDossier.id}`,
                    },
                });
            }

            snackbar.enqueueSnackbar('Dossier envoyé !');
            navigate('/dossiers/');
        },
        [setLoading, dossierApi, collection, collectionApi, snackbar, navigate, messageApi, kc],
    );

    if (!collection || !currentDocs) {
        return null;
    }

    return (
        <Container maxWidth="lg">
            {prescriberLoaded ? (
                <RecapFormalityShow
                    onPrevious={() => {
                        navigate(parcoursFormalitePathResolver('stepDocs'));
                    }}
                    sousClients={sousClients}
                    collection={collection}
                    fichiers={currentDocs}
                    prescripteur={prescripteur}
                    sousClient={sousClient}
                    onSubmit={onSubmitHandler}
                />
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
                    <CircularProgress />
                </Box>
            )}
        </Container>
    );
};

export default StepRecap;
