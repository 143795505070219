import React from 'react';

import { fillProduits } from '@b2d/pages/Achats/components/forms/functions/produitFunctions';
import {
    type RubriqueFacturation,
    type RubriqueFacturationApiObject,
} from '@europrocurement/l2d-domain';
import type { FormStructure } from '@europrocurement/flexy-form';
import type { LigneProduit } from '@b2d/pages/Achats/components/forms/types';
import type { ProductsArrayItemStructureProps } from '@b2d/pages/Achats/components/forms/formElements/types';
import { OptionsType } from '@europrocurement/flexy-components';
import AdditionalDetail from './AdditionalDetail';

export default ({
    rubFacts,
    txTva,
    mode,
    hasBlured,
    openModal,
    handleTotalsLock,
}: ProductsArrayItemStructureProps): Array<FormStructure> => [
    {
        type: 'hidden',
        name: 'idLigne',
    },
    {
        type: 'hidden',
        name: 'idLigneVente',
    },
    {
        type: 'select',
        name: 'rubriqueFacturation',
        inputlabel: 'Rubrique',
        options: [...rubFacts] as OptionsType,
        getOptionValue: (item: RubriqueFacturation) => `${item.id || 0}`,
        getOptionLabel: (item: RubriqueFacturation) => {
            if (item.id === 99) {
                return 'RSF';
            }
            return (
                rubFacts.find((rubFact: RubriqueFacturationApiObject) => item.id === rubFact.id)
                    ?.code || ''
            );
        },
        onChangeInput: (inputValues, inputInfos, formContextOnChangeInput) => {
            const produit = formContextOnChangeInput.getValue(
                inputInfos.objectPath,
            ) as Partial<LigneProduit>;
            const rubFacSelected = inputValues as unknown as RubriqueFacturation;
            const txTvaFieldPath = `${inputInfos.objectPath}.txtva`;

            if (rubFacSelected.code === 'FRN') {
                formContextOnChangeInput.setValue(txTvaFieldPath, {
                    label: 0,
                    value: 0,
                });
            } else {
                formContextOnChangeInput.setValue(txTvaFieldPath, {
                    label: 20,
                    value: 20,
                });
            }

            fillProduits(formContextOnChangeInput, produit, +inputInfos.base.replace(/^\D+/g, ''));
            if (handleTotalsLock) {
                handleTotalsLock(formContextOnChangeInput);
            }
        },
        rules:
            mode.type === 'register'
                ? {
                      required: 'Rubrique manquante',
                  }
                : undefined,
        disabled: mode.type === 'update' && mode.lock,
        isDisabled: mode.type === 'update' && mode.lock,
        xs: 10,
        sm: 2,
        md: 2,
        lg: 2,
    },
    {
        type: 'number',
        name: 'ht',
        inputlabel: 'HT',
        placeholder: '0',
        disabled: mode.type === 'update' && mode.lock,
        inputProps: {
            step: 1,
            min: 0,
        },
        onChangeInput: (event, input, formContextOnChangeInput) => {
            const produit = formContextOnChangeInput.getValue(
                input.objectPath,
            ) as Partial<LigneProduit>;
            fillProduits(formContextOnChangeInput, produit, +input.base.replace(/^\D+/g, ''));
            if (handleTotalsLock) {
                handleTotalsLock(formContextOnChangeInput);
            }
        },
        onBlurInput: (event, input, formContextOnChangeInput) => {
            if (!hasBlured) {
                const htRub = formContextOnChangeInput.getValue(`${input.base}rubriqueFacturation`);
                const inputValue = formContextOnChangeInput.getValue(input.path);
                const typeRemise = formContextOnChangeInput.getValue('type_remise_fournisseur');

                if (
                    openModal &&
                    htRub &&
                    htRub.id === 1 &&
                    inputValue &&
                    typeRemise === 'RSF Inclus (Remise sur facture)'
                ) {
                    openModal();
                }
            }
        },
        rules:
            mode.type === 'register'
                ? {
                      required: 'Prix HT manquant',
                  }
                : undefined,
        xs: 10,
        sm: 2,
        md: 2,
        lg: 2,
    },
    {
        type: 'select',
        name: 'txtva',
        inputlabel: 'Taux TVA (%)',
        options: txTva.map((tva) => ({
            label: tva.toString(),
            value: tva.toString(),
        })) as OptionsType,
        onChangeInput: (event, input, formContextOnChangeInput) => {
            console.log(event, input, formContextOnChangeInput);
            const produit = formContextOnChangeInput.getValue(
                input.objectPath,
            ) as Partial<LigneProduit>;
            fillProduits(formContextOnChangeInput, produit, +input.base.replace(/^\D+/g, ''));
            if (handleTotalsLock) {
                handleTotalsLock(formContextOnChangeInput);
            }
        },
        rules:
            mode.type === 'register'
                ? {
                      required: 'Taux TVA manquant',
                  }
                : undefined,
        disabled: mode.type === 'update' && mode.lock,
        isDisabled: mode.type === 'update' && mode.lock,
        xs: 10,
        sm: 2,
        md: 2,
        lg: 2,
    },
    {
        type: 'number',
        name: 'tva',
        inputlabel: 'TVA',
        placeholder: '0',
        disabled: true,
        inputProps: {
            step: 0.01,
            min: 0,
        },
        onChangeInput: (event, input, formContextOnChangeInput) => {
            if (handleTotalsLock) {
                handleTotalsLock(formContextOnChangeInput);
            }
        },
        xs: 10,
        sm: 2,
        md: 2,
        lg: 2,
    },
    {
        type: 'number',
        name: 'ttc',
        inputlabel: 'TTC',
        placeholder: '0',
        disabled: true,
        inputProps: {
            step: 0.01,
            min: 0,
        },
        onChangeInput: (event, input, formContextOnChangeInput) => {
            if (handleTotalsLock) {
                handleTotalsLock(formContextOnChangeInput);
            }
        },
        xs: 10,
        sm: 2,
        md: 2,
        lg: 2,
    },
    {
        type: 'customItem',
        name: 'additionalData',
        renderField: (formContext, props) => (
            <AdditionalDetail
                formContext={formContext}
                props={props}
            />
        ),
    },
];
