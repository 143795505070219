import React, { ReactNode } from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { TableCell as MaterialTableCel, TableRow as MaterialTableRow } from '@mui/material';
import { useTheme } from '@mui/system';
import { castIriToId } from '@europrocurement/l2d-utils';
import TableCel from './TableCel';
import DisableTableCel from './DisableTableCel';

export type TableRowProps = {
    formality: Formalite;
    formalities: Formalite[];
    categoryTableCel?: ReactNode;
    onChange: (formality: Formalite, compatibility: Formalite) => void;
};

const TableRow: React.FunctionComponent<TableRowProps> = function (props) {
    const { formality, formalities, categoryTableCel, onChange } = props;
    const theme = useTheme();
    const borderColor = theme.palette.grey[300];
    const headingCelStyle = {
        width: '250px',
        height: '80px',
        maxWidth: '25%',
        borderLeft: `solid 1px ${borderColor}`,
        borderBottom: `solid 1px ${borderColor}`,
        backgroundColor: theme.palette.background.default,
    };
    const compatibilities = (formality.compatibilities || []).map((compatibility) => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(compatibility as unknown as number)) {
            return castIriToId(compatibility);
        }

        return compatibility;
    });

    const cels = formalities.map((otherFormality: Formalite) => {
        if (otherFormality.id === formality.id) {
            return <DisableTableCel key={`table-cel-${formality.id}-${otherFormality.id}`} />;
        }

        return (
            <TableCel
                key={`table-cel-${formality.id}-${otherFormality.id}`}
                formality={formality}
                compatibility={otherFormality}
                compatible={compatibilities.indexOf(otherFormality.id as number) === -1}
                onChange={onChange}
            />
        );
    });

    return (
        <MaterialTableRow>
            {categoryTableCel}
            <MaterialTableCel
                sx={headingCelStyle}
                key={`table-cel-${formality.id}-0`}
            >
                {formality.libelle}
            </MaterialTableCel>
            {cels}
        </MaterialTableRow>
    );
};

export default TableRow;
