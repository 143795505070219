import { useUtilisateurService, Utilisateur } from '@europrocurement/l2d-domain';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

const useUtilisateur = () => {
    const keycloakService = UseKeycloakService();
    const { utilisateurApi } = useUtilisateurService();

    const getUserByEmail = (emailAddress: string): Promise<Utilisateur> =>
        new Promise((resolve, reject) => {
            utilisateurApi
                .apiUtilisateursGetCollection({ mail: emailAddress })
                .then((response) => {
                    const user = response.data['hydra:member'][0] ?? null;

                    if (user && user.mail === emailAddress) {
                        resolve(user as Utilisateur);

                        return;
                    }

                    reject();
                })
                .catch(() => {
                    reject();
                });
        });

    const getUserId = async (): Promise<number> => {
        const emailAddress = keycloakService.getEmail() ?? null;

        if (!emailAddress) {
            return new Promise((resolve, reject) => {
                reject();
            });
        }

        const user = await getUserByEmail(emailAddress);

        return user?.id as number;
    };

    return { getUserId };
};

export default useUtilisateur;
