import React from 'react';

import { Button } from '@mui/material';

type HeaderButtonProps = {
    content: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

/**
 * Bouton formalisé pour entête de section
 *
 * @param content
 * @param onClick
 * @returns ReactNode
 */
const HeaderButton: React.FunctionComponent<HeaderButtonProps> = function ({
    content,
    onClick,
}: HeaderButtonProps) {
    return (
        <Button
            onClick={onClick}
            variant="outlined"
            color="secondary"
            size="small"
        >
            {content}
        </Button>
    );
};

export default HeaderButton;
