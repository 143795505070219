import { Devis, FactureFormalite } from '@europrocurement/l2d-domain';
import { createContext } from 'react';

const estimates: Array<Devis> = [];
const invoices: Array<FactureFormalite> = [];
const estimatesLoaded: boolean = false;
const invoicesLoaded: boolean = false;

const BillingContext = createContext({
    estimates,
    invoices,
    estimatesLoaded,
    invoicesLoaded,
});

export default BillingContext;
