import { StepperProps } from '@europrocurement/flexy-components';
import React, { createContext, useContext, ReactNode, useState } from 'react';

interface ParcoursFormalitreHeaderContextType {
    isParcoursPath: boolean;
    setIsParcoursPath: (value: boolean) => void;
    changeParcoursSteps: (step: number, progress?: number) => void;
    steps: StepperProps['steps'];
}

const ParcoursFormalitreHeaderContext = createContext<
    ParcoursFormalitreHeaderContextType | undefined
>(undefined);

interface ParcoursFormalitreHeaderProviderProps {
    children: ReactNode;
}

export const ParcoursFormalitreHeaderProvider: React.FunctionComponent<ParcoursFormalitreHeaderProviderProps> =
    function ({ children }) {
        const [isParcoursPath, setIsParcoursPath] = useState(false);

        const [currentStep, setCurrentStep] = React.useState<number>(0);
        const [currentProgress, setCurrentProgress] = React.useState<number>(0);

        const [steps, setStpes] = React.useState<StepperProps['steps']>([]);

        const changeParcoursSteps = React.useCallback(
            (step: number, progress: number = 15) => {
                if (currentStep !== step || currentProgress !== progress) {
                    setCurrentStep(step);
                    setCurrentProgress(progress);
                    const updatedSteps = [
                        {
                            name: 'step_company',
                            text: 'Société',
                            progress: 0,
                        },
                        {
                            name: 'step_formality',
                            text: 'Formalité',
                            progress: 0,
                        },
                        {
                            name: 'step_documents',
                            text: 'Documents',
                            progress: 0,
                        },
                        {
                            name: 'step_summary',
                            text: 'Récapitulatif',
                            progress: 0,
                        },
                    ].map((item, i) => {
                        if (i < step - 1) return { ...item, progress: 100 };
                        if (i === step - 1) return { ...item, progress };
                        return { ...item, progress: 0 };
                    });
                    setStpes(updatedSteps);
                }
            },
            [currentProgress, currentStep],
        );

        const value = React.useMemo(
            () => ({ isParcoursPath, setIsParcoursPath, steps, changeParcoursSteps }),
            [changeParcoursSteps, isParcoursPath, steps],
        );
        return (
            <ParcoursFormalitreHeaderContext.Provider value={value}>
                {children}
            </ParcoursFormalitreHeaderContext.Provider>
        );
    };

export const useParcoursFormalitreHeader = (): ParcoursFormalitreHeaderContextType => {
    const context = useContext(ParcoursFormalitreHeaderContext);
    if (context === undefined) {
        throw new Error(
            'useParcoursFormalitreHeader must be used within a ParcoursFormalitreHeaderProvider',
        );
    }
    return context;
};
