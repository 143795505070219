import { createContext } from 'react';

export type DocumentPreview = {
    fileName: string;
    content: Blob;
};

let documentPreview: DocumentPreview | undefined;
const setDocumentPreview: React.Dispatch<
    React.SetStateAction<DocumentPreview | undefined>
> = () => {};

const DocumentPreviewContext = createContext({
    documentPreview,
    setDocumentPreview,
});

export default DocumentPreviewContext;
