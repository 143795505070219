import React from 'react';
import { Box, Typography } from '@mui/material';
import { NestedDossierFormaliteOpenSearch } from '@europrocurement/l2d-domain';
import DossierFormaliteLabel from '../DossierFormaliteLabel/DossierFormaliteLabel';

export type DossierCompanyFormalityProps = {
    libelle: string;
    formalites: Array<NestedDossierFormaliteOpenSearch>;
};

const DossierCompanyFormality: React.FunctionComponent<DossierCompanyFormalityProps> = function (
    props,
) {
    const { libelle, formalites } = props;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="l2dtableCell">{libelle}</Typography>
            <DossierFormaliteLabel formalites={formalites} />
        </Box>
    );
};

export default DossierCompanyFormality;
