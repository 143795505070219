import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import models from '@b2d/pages/Achats/models';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@b2d/redux/types';
import { customizerSelector, getFactureAchatFromStatement } from '@b2d/redux/RootStore';

import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import { navigateToRecapStatement } from '@b2d/utils/navigationHelper';
import { useTiersService } from '@europrocurement/l2d-domain';
import { UseHandleStatementProps } from '../types';
import HeaderButton from '../../../fragments/HeaderButton';
import StatementHeaderButton from '../../../fragments/StatementHeaderButton';

const useHandleStatement = ({
    mode,
    facture,
    mediaObject,
    achatsFromStatementDataSource,
}: UseHandleStatementProps) => {
    const [temporaryStatementId, setTemporaryStatementId] = useState<number>();
    const [supplierFromStatement, setSupplierFromStatement] = useState<number>();

    const { selectFournisseurAc } = useTiersService();

    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const { xIdSociete } = useSelector(customizerSelector);
    const { statementid } = useParams();
    const navigate = useNavigate();

    const searchForExistingInvoiceInStatement = useCallback<(statementId: number) => void>(
        (statementId: number) => {
            if (!achatsFromStatementDataSource) {
                console.error('Missing DataSource to handle statement !');
                return;
            }

            dispatch({
                type: `${achatsFromStatementDataSource.slicename}/set${achatsFromStatementDataSource.name}Filter`,
                payload: { key: 'cloture', value: true },
            });

            dispatch({
                type: `${achatsFromStatementDataSource.slicename}/set${achatsFromStatementDataSource.name}Filter`,
                payload: { key: 'deleted', value: false },
            });

            dispatch({
                type: `${achatsFromStatementDataSource.slicename}/set${achatsFromStatementDataSource.name}Filter`,
                payload: { key: 'idReleveAchat', value: statementId },
            });

            setTimeout(() => {
                dispatch(getFactureAchatFromStatement({}));
            }, 1000);
        },
        [achatsFromStatementDataSource, dispatch],
    );

    const createTemporaryStatement = useCallback(() => {
        if (mediaObject) {
            models.invoiceStatement
                .create({
                    xIdSociete,
                    releveAchatJsonldReleveAchatCreateReleveAchatWrite: {
                        pdfFacture: mediaObject['@id'],
                    },
                })
                .then((response) => {
                    if (response && response.data && response.data.id) {
                        setTemporaryStatementId(response.data.id);
                        searchForExistingInvoiceInStatement(response.data.id);
                    }
                })
                .catch((reason) => {
                    console.error(reason);
                });
        } else {
            enqueueSnackbar('PDF manquant !', { variant: 'error' });
        }
    }, [enqueueSnackbar, mediaObject, searchForExistingInvoiceInStatement, xIdSociete]);

    const handleClickButtonToStatement = useCallback(() => {
        if (facture && facture.id_statement) {
            navigateToRecapStatement(navigate, facture.id_statement);
        }
    }, [facture, navigate]);

    const addStatementButton = useMemo<ReactNode>(() => {
        if (mode.type === 'update') return null;

        return (
            <StatementHeaderButton
                statementId={temporaryStatementId}
                onClick={() => {
                    createTemporaryStatement();
                }}
            />
        );
    }, [createTemporaryStatement, mode.type, temporaryStatementId]);

    const accessStatementButton = useMemo<ReactNode>(() => {
        if (!facture || !facture.id_statement) {
            // console.error(
            //     'Missing invoice or associate id statement, access to statement resume impossible !',
            // );
            return null;
        }

        return (
            <HeaderButton
                content="Récapitulatif de Relevé"
                onClick={handleClickButtonToStatement}
            />
        );
    }, [facture, handleClickButtonToStatement]);

    useEffect(() => {
        if (statementid && `${temporaryStatementId}` !== statementid) {
            models.invoiceStatement
                .read({
                    id: statementid,
                    xIdSociete,
                })
                .then((response) => {
                    if (response && response.data && response.data.id) {
                        setTemporaryStatementId(response.data.id);
                        searchForExistingInvoiceInStatement(response.data.id);
                    }
                });
        }
    }, [searchForExistingInvoiceInStatement, statementid, temporaryStatementId, xIdSociete]);

    /**
     * Select the publisher from the first invoice associated to the current statement.
     *
     * RG-Achat-60
     *
     * Invoices from the same statement should keeps the same publisher.
     */
    useEffect(() => {
        if (!achatsFromStatementDataSource) {
            // console.error("Missing DataSource to select statement's provider !");
            return;
        }

        if (
            achatsFromStatementDataSource.data.length > 0 &&
            achatsFromStatementDataSource.data[0].idEntiteFacturante
        ) {
            setSupplierFromStatement(achatsFromStatementDataSource.data[0].idEntiteFacturante);

            dispatch(
                selectFournisseurAc({
                    id: achatsFromStatementDataSource.data[0].idEntiteFacturante,
                }),
            );
        }
    }, [achatsFromStatementDataSource, dispatch, selectFournisseurAc]);

    const fetchInvoicesFromStatement = useCallback(() => {
        if (!temporaryStatementId) {
            // console.error('Missing statement id to fetch invoices !');
            return;
        }

        setTimeout(() => {
            searchForExistingInvoiceInStatement(temporaryStatementId);
        }, 1000);
    }, [searchForExistingInvoiceInStatement, temporaryStatementId]);

    return {
        addStatementButton,
        accessStatementButton,
        fetchInvoicesFromStatement,
        stateHandleStatement: {
            temporaryStatementId: {
                value: temporaryStatementId,
                set: setTemporaryStatementId,
            },
            supplierFromStatement: {
                value: supplierFromStatement,
                set: setSupplierFromStatement,
            },
        },
    };
};

export default useHandleStatement;
