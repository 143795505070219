import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import {
    DossierOpenSearch,
    DossierOpenSearchSelector,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import SelectedDossierContext from './SelectedDossierContext';
import { UpdateDossierPayload, useDossier, useDossierSiblings } from '../hooks';

const SelectedDossierProvider: React.FunctionComponent<PropsWithChildren> = function ({
    children,
}) {
    const [selectedDossier, setSelectedDossier] = useState<DossierOpenSearch>({
        formalites: [],
    });
    const [collectionId, setCollectionId] = useState<number>();
    const [siblings, setSiblings] = useState<Array<DossierOpenSearch>>([]);
    const dispatch = useDispatch();
    const dossierDataSource: DataSource<DossierOpenSearch> = useSelector(DossierOpenSearchSelector);
    const { idDossier } = useParams();
    const { selectOpenSearchDossier } = useFormaliteService();
    const { getDossierSiblings } = useDossierSiblings();
    const { updateDossier: patchDossier } = useDossier();

    const updateDossier = useCallback(
        (payload: UpdateDossierPayload) => {
            setSelectedDossier({
                ...selectedDossier,
                ...payload,
            });
            patchDossier(selectedDossier.id as number, payload);
        },
        [selectedDossier, patchDossier],
    );

    useEffect(() => {
        if (!dossierDataSource?.selected || dossierDataSource?.selected?.id !== idDossier) {
            dispatch(
                selectOpenSearchDossier({
                    id: idDossier as unknown as number,
                }) as unknown as AnyAction,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idDossier]);

    useEffect(() => {
        if (dossierDataSource.selectedStatus === 'succeeded') {
            setSelectedDossier(dossierDataSource.selected as DossierOpenSearch);
            setCollectionId(dossierDataSource.selected?.collectionId as number);
        }

        return () => {
            setCollectionId(undefined);
        };
    }, [dossierDataSource]);

    useEffect(() => {
        if (collectionId) {
            getDossierSiblings(collectionId).then(setSiblings);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionId]);

    const contextValue = useMemo(
        () => ({
            dossier: selectedDossier,
            setDossier: setSelectedDossier,
            siblings,
            setSiblings,
            updateDossier,
        }),
        [selectedDossier, setSelectedDossier, siblings, setSiblings, updateDossier],
    );

    if (dossierDataSource.selectedStatus !== 'succeeded') {
        return (
            <Box
                display="flex"
                justifyContent="center"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '50px',
                }}
            >
                {dossierDataSource.selectedStatus === 'loading' ||
                dossierDataSource.selectedStatus === 'idle' ? (
                    <CircularProgress />
                ) : (
                    <p>Une erreur est survenue</p>
                )}
            </Box>
        );
    }

    return (
        <SelectedDossierContext.Provider value={contextValue}>
            {children}
        </SelectedDossierContext.Provider>
    );
};

export default SelectedDossierProvider;
