import * as React from 'react';

import { Box, CircularProgress, Grid } from '@mui/material';
import { FlexyHeaderForm, ModalContext } from '@europrocurement/flexy-components';
import {
    CoordonneesEmailJsonldCoordonneeUpdate,
    EmailPrescripteurReadNotificationsEmails,
    EmailPrescripteurWriteNotificationsEmails,
    FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum,
    TiersPrescripteurPrescripteurReadNotificationsEmails,
    TiersPrescripteurPrescripteurReadNotificationsFrequences,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import usePromises from '@europrocurement/l2d-hooks/hooks/usePromises';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';
import { useTiersService } from '@europrocurement/l2d-domain';
import EmailList from './EmailList';
import AddEmail from './AddEmail';
import NotificationsFrequencies from './NotificationsFrequencies';
import ActionModal from '../../forms/ActionModal';

type UpdateNotificationPreferencesEmailsResult = {
    data: TiersPrescripteurPrescripteurReadNotificationsEmails;
};

type UpdateNotificationPreferencesFrequencyResult = {
    data: TiersPrescripteurPrescripteurReadNotificationsFrequences;
};

const FormalitiesNotifications: React.FunctionComponent = function () {
    const { modalActions } = React.useContext(ModalContext);
    const { coordonneesApi, prescribersApi } = useTiersService();

    const [apiFetched, setApiFetched] = React.useState<boolean>(false);

    // 1 - Component Data / On mount fetch data
    const { isFetching, isLoaded, fetchedData, call: fetchApi } = usePromises();

    const { prescriber, companyId } = usePrescriberUtils();

    const prescriberId = `${prescriber.id}`;

    React.useEffect(() => {
        if (apiFetched) {
            return;
        }
        const getEmailsNotificationsPrescripteurTiersItem = () =>
            prescribersApi.getEmailsNotificationsPrescripteurTiersItem({
                id: prescriberId,
                xIdSociete: companyId,
            });

        const getFrequencesNotificationsPrescripteurTiersItem = () =>
            prescribersApi.getFrequencesNotificationsPrescripteurTiersItem({
                id: prescriberId,
                xIdSociete: companyId,
            });

        const notificationPromises = [
            {
                name: 'presciberEmails',
                promise: getEmailsNotificationsPrescripteurTiersItem(),
                accessorKey: 'preferencesNotification.emails',
            },
            {
                name: 'presciberNotificationFrequency',
                promise: getFrequencesNotificationsPrescripteurTiersItem(),
                accessorKey: 'preferencesNotification.frequences.0.frequence',
            },
        ];
        fetchApi(notificationPromises);
        setApiFetched(true);
    }, [apiFetched, companyId, fetchApi, prescriberId, prescribersApi]);

    // 2 - Component state

    const defaultFrequency = 0;

    const [frequency, setFrequency] = React.useState<number>(defaultFrequency);
    const [emailList, setEmailList] = React.useState<EmailPrescripteurReadNotificationsEmails[]>();

    const removeEmailFromList = (deletedEmailId: number | undefined) => {
        if (!deletedEmailId || !emailList) {
            return;
        }

        const updatedEmailList = emailList.filter(
            (emailItem: EmailPrescripteurReadNotificationsEmails) =>
                emailItem.id !== deletedEmailId,
        );
        setEmailList(updatedEmailList);
    };

    // Api response handling

    const handleFetchedData = React.useCallback(() => {
        // Retreive fetched data
        const fetchedEmails = fetchedData.presciberEmails;
        const fetchedFrequency = fetchedData.presciberNotificationFrequency;

        // Set states
        setEmailList(fetchedEmails);
        setFrequency(fetchedFrequency);
    }, [fetchedData.presciberEmails, fetchedData.presciberNotificationFrequency]);

    React.useEffect(() => {
        if (isLoaded && fetchedData) {
            handleFetchedData();
        }
    }, [isLoaded, fetchedData, handleFetchedData]);

    // Emails update

    // 4 - Update methods (emails & frequency)

    const { request: updateFrequency, requestState: updateFrequencyState } = useApiRequest();
    const { request: updateEmails, requestState: updateEmailsState } = useApiRequest();
    const { request: deleteEmailApiCall } = useApiRequest();

    // 4a - EmailList update / store value callback

    const emailListPayload = (emailListParameter: EmailPrescripteurWriteNotificationsEmails[]) => ({
        preferencesNotification: {
            emails: emailListParameter,
        },
    });

    const updateEmailListRequest = (
        emailListParameter: EmailPrescripteurWriteNotificationsEmails[],
    ) =>
        prescribersApi.updateEmailsNotificationsPrescripteurTiersItem({
            id: prescriberId,
            xIdSociete: companyId,
            tiersPrescripteurPrescripteurWriteNotificationsEmails:
                emailListPayload(emailListParameter),
        });

    const updateEmailList = (emailListParameter: EmailPrescripteurWriteNotificationsEmails[]) => {
        const successCallback = (result: UpdateNotificationPreferencesEmailsResult) => {
            const updatedEmailList = result.data.preferencesNotification?.emails ?? undefined;
            setEmailList(updatedEmailList);
        };

        updateEmails(updateEmailListRequest(emailListParameter), { successCallback });
    };

    // 4b - Frequency update / store value callback

    const frequencyPayload = (
        newValue: FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum,
    ) => ({
        preferencesNotification: {
            frequences: [
                {
                    societe: {
                        id: companyId,
                    },
                    frequence: newValue,
                },
            ],
        },
    });

    const updateFrequencyRequest = (
        newValue: FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum,
    ) =>
        prescribersApi.updateFrequencesNotificationsPrescripteurTiersItem({
            id: prescriberId,
            xIdSociete: companyId,
            tiersPrescripteurPrescripteurWriteNotificationsFrequences: frequencyPayload(newValue),
        });

    const updateFrequencyValue = (
        newValue: FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum,
    ) => {
        const successCallback = (result: UpdateNotificationPreferencesFrequencyResult) => {
            const updatedFrequence = result.data.preferencesNotification.frequences?.[0].frequence;
            if (updatedFrequence) {
                setFrequency(updatedFrequence);
            }
        };

        updateFrequency(updateFrequencyRequest(newValue), { successCallback });
    };

    const deleteEmail = (deletedEmail: CoordonneesEmailJsonldCoordonneeUpdate) => {
        modalActions.call(
            <ActionModal
                onClose={modalActions.close}
                asyncAction={() => {
                    if (typeof deletedEmail.id === 'undefined') {
                        return;
                    }

                    const successCallback = () => {
                        modalActions.close();

                        removeEmailFromList(deletedEmail.id);
                    };

                    const deleteEmailRequest = (deletedEmailId: string) =>
                        coordonneesApi.deleteEmailCoordonneesItem({
                            xIdSociete: companyId,
                            id: deletedEmailId,
                        });

                    deleteEmailApiCall(deleteEmailRequest(deletedEmail.id.toString()), {
                        successCallback,
                    });
                }}
                title="Suppression d'un email"
                actionText={`Voulez-vous supprimer l'email : ${deletedEmail.email} ?`}
                actionLabel="Confirmer suppression"
            />,
        );
    };

    // 5 - Render logic

    if (isFetching || isLoaded === false) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Grid
                container
                spacing={4}
                rowSpacing={1}
            >
                <Grid
                    item
                    xl={7}
                    lg={12}
                    md={12}
                    xs={12}
                >
                    <FlexyHeaderForm
                        label="Liste des emails (notification & rappel)"
                        outlined
                    />
                    <EmailList
                        emails={emailList ?? null}
                        updateEmails={updateEmailList}
                        isUpdating={updateEmailsState.isProcessing}
                        deleteEmail={deleteEmail}
                    />
                </Grid>

                <Grid
                    item
                    xl={5}
                    lg={12}
                    md={12}
                    xs={12}
                >
                    {emailList ? (
                        <AddEmail
                            emails={emailList}
                            updateEmails={updateEmailList}
                        />
                    ) : null}
                    <NotificationsFrequencies
                        frequency={frequency}
                        updateFrequency={updateFrequencyValue}
                        isUpdating={updateFrequencyState.isProcessing}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default FormalitiesNotifications;
