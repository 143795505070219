import { achatsConfiguration, mediaobjectDataSourcesThunks } from '@b2d/redux/RootStore';
import { RootStateType } from '@b2d/redux/types';

import {
    ACHATS_REDUCER_NAME,
    Configuration,
    MEDIAOBJECT_SLICE_NAME,
    type MediaObject,
} from '@europrocurement/l2d-domain';
import ICONS from '@b2d/icons';
import { MediaObjectApi } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { ModelConfiguration, ModelItem, ModelMethods } from '@b2d/pages/Achats/models/types';

const modelKey = 'media_object';
const modelName = 'MediaObject';
const sliceName = MEDIAOBJECT_SLICE_NAME;
const apiConfiguration = new Configuration(achatsConfiguration);
const api = new MediaObjectApi(apiConfiguration);
const baseSelector = (state: RootStateType) => state[ACHATS_REDUCER_NAME][sliceName];
const datasourcesThunks = mediaobjectDataSourcesThunks;

type ModelStructure = MediaObject & Record<string, unknown>;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'MediaObject',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: ICONS.models[modelKey],
    color: 'blue',
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.numeroFacture}`,
    getItemSecondaryLabel: (model: ModelStructure) =>
        `${model.dateFacture} (${model.multiAnnonces ? 'Multiple' : 'Simple'})`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.listMediaObjectMediaObjectCollection>) =>
        api.listMediaObjectMediaObjectCollection(...args),
    listMyOwn: (...args: Parameters<typeof api.listMyMediaObjectMediaObjectCollection>) =>
        api.listMyMediaObjectMediaObjectCollection(...args),
    delete: (...args: Parameters<typeof api.deleteMediaObjectItem>) =>
        api.deleteMediaObjectItem(...args),
    patch: (...args: Parameters<typeof api.patchMediaObjectItem>) =>
        api.patchMediaObjectItem(...args),
    read: (...args: Parameters<typeof api.getMediaObjectItem>) => api.getMediaObjectItem(...args),
    create: (...args: Parameters<typeof api.postMediaObjectCollection>) =>
        api.postMediaObjectCollection(...args),
};
type Endpoints = typeof endpoints;

const model: ModelItem<ModelStructure> & Endpoints = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export default model;
