import * as React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

export type RoleChipProps = {
    label: string;
};

const RoleChip: React.FunctionComponent<RoleChipProps> = function ({ label }: RoleChipProps) {
    const backgroundColor = '#eef3ff';
    const textColor = '#2962ff';

    return (
        <Box
            data-testid="RoleChip-test-id"
            sx={{
                backgroundColor,
                borderRadius: '5px',
                marginX: '5px',
                marginBottom: '10px',
                maxHeight: '40px',
            }}
        >
            <Typography
                fontSize={14}
                sx={{ color: textColor, padding: '10px' }}
            >
                {label}
            </Typography>
        </Box>
    );
};

export default RoleChip;
