/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Tiers Api
 * API de gestion des tiers
 *
 * The version of the OpenAPI document: 1.10.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AdresseFournisseurReadFournisseurReadTiers
 */
export interface AdresseFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {number}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'facturation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'placeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'adresseApi'?: string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'temporaryAddress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface AdresseJsonldFournisseurReadFournisseurReadTiers
 */
export interface AdresseJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'facturation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'placeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'adresseApi'?: string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'temporaryAddress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * @type AdresseJsonldFournisseurReadFournisseurReadTiersContext
 * @export
 */
export type AdresseJsonldFournisseurReadFournisseurReadTiersContext = AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOf | string;

/**
 * 
 * @export
 * @interface AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOf
 */
export interface AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOf
     */
    'hydra': AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOfHydraEnum;
}

export const AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOfHydraEnum = typeof AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOfHydraEnum[keyof typeof AdresseJsonldFournisseurReadFournisseurReadTiersContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface AdresseJsonldPrescripteurCreate
 */
export interface AdresseJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    'codePays': string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseJsonldPrescripteurCreate
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdresseJsonldPrescripteurCreated
 */
export interface AdresseJsonldPrescripteurCreated {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdresseJsonldPrescripteurCreated
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldPrescripteurCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface AdresseJsonldPrescripteurWriteAdresses
 */
export interface AdresseJsonldPrescripteurWriteAdresses {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'facturation'?: boolean;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseJsonldPrescripteurWriteAdresses
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdresseJsonldSousclientCreate
 */
export interface AdresseJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdresseJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreate
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreate
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreate
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreate
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreate
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreate
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreate
     */
    'codePays': string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseJsonldSousclientCreate
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdresseJsonldSousclientCreated
 */
export interface AdresseJsonldSousclientCreated {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdresseJsonldSousclientCreated
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldSousclientCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface AdresseJsonldSousclientWriteAdresses
 */
export interface AdresseJsonldSousclientWriteAdresses {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'facturation'?: boolean;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseJsonldSousclientWriteAdresses
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdresseJsonldTiersRead
 */
export interface AdresseJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdresseJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseJsonldTiersRead
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseJsonldTiersRead
     */
    'facturation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldTiersRead
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldTiersRead
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'placeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'adresseApi'?: string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseJsonldTiersRead
     */
    'temporaryAddress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface AdresseJsonldTiersReadTiersReadExpanded
 */
export interface AdresseJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'facturation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'placeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'adresseApi'?: string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'temporaryAddress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseJsonldTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface AdressePrescripteurCreate
 */
export interface AdressePrescripteurCreate {
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurCreate
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurCreate
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurCreate
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurCreate
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurCreate
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurCreate
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurCreate
     */
    'codePays': string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdressePrescripteurCreate
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdressePrescripteurCreated
 */
export interface AdressePrescripteurCreated {
    /**
     * 
     * @type {number}
     * @memberof AdressePrescripteurCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface AdressePrescripteurWriteAdresses
 */
export interface AdressePrescripteurWriteAdresses {
    /**
     * 
     * @type {number}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'facturation'?: boolean;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdressePrescripteurWriteAdresses
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdresseSousclientCreate
 */
export interface AdresseSousclientCreate {
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientCreate
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientCreate
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientCreate
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientCreate
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientCreate
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientCreate
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientCreate
     */
    'codePays': string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseSousclientCreate
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdresseSousclientCreated
 */
export interface AdresseSousclientCreated {
    /**
     * 
     * @type {number}
     * @memberof AdresseSousclientCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
 */
export interface AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient {
    /**
     * 
     * @type {number}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'facturation'?: boolean;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdresseSousclientWriteAdresses
 */
export interface AdresseSousclientWriteAdresses {
    /**
     * 
     * @type {number}
     * @memberof AdresseSousclientWriteAdresses
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientWriteAdresses
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientWriteAdresses
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientWriteAdresses
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientWriteAdresses
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientWriteAdresses
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientWriteAdresses
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseSousclientWriteAdresses
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseSousclientWriteAdresses
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseSousclientWriteAdresses
     */
    'facturation'?: boolean;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseSousclientWriteAdresses
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdresseTiersRead
 */
export interface AdresseTiersRead {
    /**
     * 
     * @type {number}
     * @memberof AdresseTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseTiersRead
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseTiersRead
     */
    'facturation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdresseTiersRead
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdresseTiersRead
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'placeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'adresseApi'?: string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseTiersRead
     */
    'temporaryAddress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface AdresseTiersReadTiersReadExpanded
 */
export interface AdresseTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'facturation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'placeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'adresseApi'?: string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'temporaryAddress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface AdressesAdresseAdresseCreate
 */
export interface AdressesAdresseAdresseCreate {
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseCreate
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseCreate
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseCreate
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseCreate
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseCreate
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseCreate
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseCreate
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdressesAdresseAdresseCreate
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdressesAdresseAdresseCreate
     */
    'facturation'?: boolean;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdressesAdresseAdresseCreate
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdressesAdresseAdresseRead
 */
export interface AdressesAdresseAdresseRead {
    /**
     * 
     * @type {number}
     * @memberof AdressesAdresseAdresseRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseRead
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseRead
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseRead
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseRead
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseRead
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseRead
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseRead
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdressesAdresseAdresseRead
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdressesAdresseAdresseRead
     */
    'facturation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdressesAdresseAdresseRead
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdressesAdresseAdresseRead
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseRead
     */
    'placeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseRead
     */
    'adresseApi'?: string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdressesAdresseAdresseRead
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdressesAdresseAdresseUpdate
 */
export interface AdressesAdresseAdresseUpdate {
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'facturation'?: boolean;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdressesAdresseAdresseUpdate
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdressesAdresseJsonldAdresseCreate
 */
export interface AdressesAdresseJsonldAdresseCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'facturation'?: boolean;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdressesAdresseJsonldAdresseCreate
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdressesAdresseJsonldAdresseRead
 */
export interface AdressesAdresseJsonldAdresseRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'facturation'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'placeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'adresseApi'?: string;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdressesAdresseJsonldAdresseRead
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface AdressesAdresseJsonldAdresseUpdate
 */
export interface AdressesAdresseJsonldAdresseUpdate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'adresse1': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'codePostal': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'codePays': string;
    /**
     * adresse par défaut ?
     * @type {boolean}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'defaut'?: boolean;
    /**
     * adresse de facturation ?
     * @type {boolean}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'facturation'?: boolean;
    /**
     * adresse temporaire ?
     * @type {boolean}
     * @memberof AdressesAdresseJsonldAdresseUpdate
     */
    'temporaryAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnelleCategorieSocioProfessionnelleCspRead
 */
export interface CategorieSocioProfessionnelleCategorieSocioProfessionnelleCspRead {
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnelleCategorieSocioProfessionnelleCspRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleCategorieSocioProfessionnelleCspRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleCategorieSocioProfessionnelleCspRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead
 */
export interface CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead {
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnelleJsonldPrescripteurCreate
 */
export interface CategorieSocioProfessionnelleJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CategorieSocioProfessionnelleJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnelleJsonldPrescripteurCreate
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnelleJsonldPrescripteurWriteRelationSociete
 */
export interface CategorieSocioProfessionnelleJsonldPrescripteurWriteRelationSociete {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CategorieSocioProfessionnelleJsonldPrescripteurWriteRelationSociete
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldPrescripteurWriteRelationSociete
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldPrescripteurWriteRelationSociete
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnelleJsonldPrescripteurWriteRelationSociete
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnelleJsonldTiersRead
 */
export interface CategorieSocioProfessionnelleJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CategorieSocioProfessionnelleJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnelleJsonldTiersRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldTiersRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldTiersRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded
 */
export interface CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead
 */
export interface CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead {
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnellePortefeuillePortefeuilleRead
 */
export interface CategorieSocioProfessionnellePortefeuillePortefeuilleRead {
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnellePortefeuillePortefeuilleRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnellePortefeuillePortefeuilleRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnellePortefeuillePortefeuilleRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnellePrescripteurCreate
 */
export interface CategorieSocioProfessionnellePrescripteurCreate {
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnellePrescripteurCreate
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnellePrescripteurWriteRelationSociete
 */
export interface CategorieSocioProfessionnellePrescripteurWriteRelationSociete {
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnellePrescripteurWriteRelationSociete
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnelleTiersRead
 */
export interface CategorieSocioProfessionnelleTiersRead {
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnelleTiersRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleTiersRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleTiersRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieSocioProfessionnelleTiersReadTiersReadExpanded
 */
export interface CategorieSocioProfessionnelleTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof CategorieSocioProfessionnelleTiersReadTiersReadExpanded
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleTiersReadTiersReadExpanded
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieSocioProfessionnelleTiersReadTiersReadExpanded
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile
 */
export interface ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldPrescripteurReadConditionsCommercialesClearFile}
     * @memberof ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    'societe': SocieteJsonldPrescripteurReadConditionsCommercialesClearFile;
    /**
     * Suppression des fichiers formalité
     * @type {boolean}
     * @memberof ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    'fClearDoc'?: boolean;
    /**
     * Delai en jour a atteindre pour la suppression des fichiers
     * @type {any}
     * @memberof ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    'clearDocDelai'?: any;
    /**
     * Envoi du mail de récap de suppression des fichiers
     * @type {boolean}
     * @memberof ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    'fClearDocMail'?: boolean;
    /**
     * Mails destinataires séparés par des ;
     * @type {string}
     * @memberof ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    'clearDocMail'?: string;
}
/**
 * 
 * @export
 * @interface ClearFileInfosPrescripteurReadConditionsCommercialesClearFile
 */
export interface ClearFileInfosPrescripteurReadConditionsCommercialesClearFile {
    /**
     * 
     * @type {SocietePrescripteurReadConditionsCommercialesClearFile}
     * @memberof ClearFileInfosPrescripteurReadConditionsCommercialesClearFile
     */
    'societe': SocietePrescripteurReadConditionsCommercialesClearFile;
    /**
     * Suppression des fichiers formalité
     * @type {boolean}
     * @memberof ClearFileInfosPrescripteurReadConditionsCommercialesClearFile
     */
    'fClearDoc'?: boolean;
    /**
     * Delai en jour a atteindre pour la suppression des fichiers
     * @type {any}
     * @memberof ClearFileInfosPrescripteurReadConditionsCommercialesClearFile
     */
    'clearDocDelai'?: any;
    /**
     * Envoi du mail de récap de suppression des fichiers
     * @type {boolean}
     * @memberof ClearFileInfosPrescripteurReadConditionsCommercialesClearFile
     */
    'fClearDocMail'?: boolean;
    /**
     * Mails destinataires séparés par des ;
     * @type {string}
     * @memberof ClearFileInfosPrescripteurReadConditionsCommercialesClearFile
     */
    'clearDocMail'?: string;
}
/**
 * 
 * @export
 * @interface ClearFileInfosPrescripteurWriteConditionsCommercialesClearFile
 */
export interface ClearFileInfosPrescripteurWriteConditionsCommercialesClearFile {
    /**
     * 
     * @type {SocietePrescripteurWriteConditionsCommercialesClearFile}
     * @memberof ClearFileInfosPrescripteurWriteConditionsCommercialesClearFile
     */
    'societe': SocietePrescripteurWriteConditionsCommercialesClearFile;
    /**
     * Suppression des fichiers formalité
     * @type {boolean}
     * @memberof ClearFileInfosPrescripteurWriteConditionsCommercialesClearFile
     */
    'fClearDoc'?: boolean;
    /**
     * Delai en jour a atteindre pour la suppression des fichiers
     * @type {any}
     * @memberof ClearFileInfosPrescripteurWriteConditionsCommercialesClearFile
     */
    'clearDocDelai'?: any;
    /**
     * Envoi du mail de récap de suppression des fichiers
     * @type {boolean}
     * @memberof ClearFileInfosPrescripteurWriteConditionsCommercialesClearFile
     */
    'fClearDocMail'?: boolean;
    /**
     * Mails destinataires séparés par des ;
     * @type {string}
     * @memberof ClearFileInfosPrescripteurWriteConditionsCommercialesClearFile
     */
    'clearDocMail'?: string;
}
/**
 * 
 * @export
 * @interface CommercialJsonldPrescripteurReadCommerciaux
 */
export interface CommercialJsonldPrescripteurReadCommerciaux {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'actif'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'suivi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'dateDebutSuivi'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'dateFinSuivi'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'surveillance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'dateDebutSurveillance'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'dateFinSurveillance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'newbiz'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'dateDebutNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'dateFinNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'note'?: string;
    /**
     * 
     * @type {UtilisateurJsonldPrescripteurReadCommerciaux}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'utilisateur'?: UtilisateurJsonldPrescripteurReadCommerciaux;
    /**
     * 
     * @type {SocieteJsonldPrescripteurReadCommerciaux}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'societe'?: SocieteJsonldPrescripteurReadCommerciaux;
    /**
     * 
     * @type {DomaineJsonldPrescripteurReadCommerciaux}
     * @memberof CommercialJsonldPrescripteurReadCommerciaux
     */
    'domaine'?: DomaineJsonldPrescripteurReadCommerciaux;
}
/**
 * 
 * @export
 * @interface CommercialJsonldTiersReadTiersReadExpanded
 */
export interface CommercialJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'actif'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'suivi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'dateDebutSuivi'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'dateFinSuivi'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'surveillance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'dateDebutSurveillance'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'dateFinSurveillance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'newbiz'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'dateDebutNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'dateFinNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'note'?: string;
    /**
     * 
     * @type {UtilisateurJsonldTiersReadTiersReadExpanded}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'utilisateur'?: UtilisateurJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {SocieteJsonldTiersReadTiersReadExpanded}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'societe'?: SocieteJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {DomaineJsonldTiersReadTiersReadExpanded}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'domaine'?: DomaineJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialJsonldTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface CommercialPrescripteurReadCommerciaux
 */
export interface CommercialPrescripteurReadCommerciaux {
    /**
     * 
     * @type {number}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'actif'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'suivi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'dateDebutSuivi'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'dateFinSuivi'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'surveillance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'dateDebutSurveillance'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'dateFinSurveillance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'newbiz'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'dateDebutNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'dateFinNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'note'?: string;
    /**
     * 
     * @type {UtilisateurPrescripteurReadCommerciaux}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'utilisateur'?: UtilisateurPrescripteurReadCommerciaux;
    /**
     * 
     * @type {SocietePrescripteurReadCommerciaux}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'societe'?: SocietePrescripteurReadCommerciaux;
    /**
     * 
     * @type {DomainePrescripteurReadCommerciaux}
     * @memberof CommercialPrescripteurReadCommerciaux
     */
    'domaine'?: DomainePrescripteurReadCommerciaux;
}
/**
 * 
 * @export
 * @interface CommercialTiersReadTiersReadExpanded
 */
export interface CommercialTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'actif'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'suivi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'dateDebutSuivi'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'dateFinSuivi'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'surveillance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'dateDebutSurveillance'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'dateFinSurveillance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'newbiz'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'dateDebutNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'dateFinNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'note'?: string;
    /**
     * 
     * @type {UtilisateurTiersReadTiersReadExpanded}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'utilisateur'?: UtilisateurTiersReadTiersReadExpanded;
    /**
     * 
     * @type {SocieteTiersReadTiersReadExpanded}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'societe'?: SocieteTiersReadTiersReadExpanded;
    /**
     * 
     * @type {DomaineTiersReadTiersReadExpanded}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'domaine'?: DomaineTiersReadTiersReadExpanded;
    /**
     * 
     * @type {string}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommercialTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface Commerciaux
 */
export interface Commerciaux {
    /**
     * 
     * @type {number}
     * @memberof Commerciaux
     */
    'id'?: number;
    /**
     * 
     * @type {TblUtilisateur}
     * @memberof Commerciaux
     */
    'user'?: TblUtilisateur;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'tiers'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Commerciaux
     */
    'suivi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'dateDebutSuivi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'dateFinSuivi'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Commerciaux
     */
    'surveillance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'dateDebutSurveillance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'dateFinSurveillance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Commerciaux
     */
    'newbiz'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'dateDebutNewbiz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'dateFinNewbiz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {TblUtilisateur}
     * @memberof Commerciaux
     */
    'userCreation'?: TblUtilisateur;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {TblUtilisateur}
     * @memberof Commerciaux
     */
    'userMaj'?: TblUtilisateur;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'note'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Commerciaux
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {TblSociete}
     * @memberof Commerciaux
     */
    'societe'?: TblSociete;
    /**
     * 
     * @type {TblDefDomaine}
     * @memberof Commerciaux
     */
    'domaine'?: TblDefDomaine | null;
    /**
     * 
     * @type {TblUtilisateur}
     * @memberof Commerciaux
     */
    'userMAJ'?: TblUtilisateur | null;
    /**
     * 
     * @type {string}
     * @memberof Commerciaux
     */
    'dateMAJ'?: string | null;
}
/**
 * 
 * @export
 * @interface CommerciauxCommercialJsonldPrescripteurWriteCommercial
 */
export interface CommerciauxCommercialJsonldPrescripteurWriteCommercial {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'suivi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'dateDebutSuivi'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'dateFinSuivi'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'surveillance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'dateDebutSurveillance'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'dateFinSurveillance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'newbiz'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'dateDebutNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'dateFinNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'note'?: string;
    /**
     * 
     * @type {UtilisateurJsonldPrescripteurWriteCommercial}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'utilisateur'?: UtilisateurJsonldPrescripteurWriteCommercial;
    /**
     * 
     * @type {SocieteJsonldPrescripteurWriteCommercial}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'societe'?: SocieteJsonldPrescripteurWriteCommercial;
    /**
     * 
     * @type {DomaineJsonldPrescripteurWriteCommercial}
     * @memberof CommerciauxCommercialJsonldPrescripteurWriteCommercial
     */
    'domaine'?: DomaineJsonldPrescripteurWriteCommercial;
}
/**
 * 
 * @export
 * @interface CommerciauxCommercialPrescripteurWriteCommercial
 */
export interface CommerciauxCommercialPrescripteurWriteCommercial {
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'suivi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'dateDebutSuivi'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'dateFinSuivi'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'surveillance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'dateDebutSurveillance'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'dateFinSurveillance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'newbiz'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'dateDebutNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'dateFinNewbiz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'note'?: string;
    /**
     * 
     * @type {UtilisateurPrescripteurWriteCommercial}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'utilisateur'?: UtilisateurPrescripteurWriteCommercial;
    /**
     * 
     * @type {SocietePrescripteurWriteCommercial}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'societe'?: SocietePrescripteurWriteCommercial;
    /**
     * 
     * @type {DomainePrescripteurWriteCommercial}
     * @memberof CommerciauxCommercialPrescripteurWriteCommercial
     */
    'domaine'?: DomainePrescripteurWriteCommercial;
}
/**
 * 
 * @export
 * @interface CommerciauxJsonld
 */
export interface CommerciauxJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CommerciauxJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommerciauxJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {TblUtilisateurJsonld}
     * @memberof CommerciauxJsonld
     */
    'user'?: TblUtilisateurJsonld;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'tiers'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxJsonld
     */
    'suivi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'dateDebutSuivi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'dateFinSuivi'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxJsonld
     */
    'surveillance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'dateDebutSurveillance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'dateFinSurveillance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxJsonld
     */
    'newbiz'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'dateDebutNewbiz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'dateFinNewbiz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {TblUtilisateurJsonld}
     * @memberof CommerciauxJsonld
     */
    'userCreation'?: TblUtilisateurJsonld;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {TblUtilisateurJsonld}
     * @memberof CommerciauxJsonld
     */
    'userMaj'?: TblUtilisateurJsonld;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'note'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommerciauxJsonld
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {TblSocieteJsonld}
     * @memberof CommerciauxJsonld
     */
    'societe'?: TblSocieteJsonld;
    /**
     * 
     * @type {TblDefDomaineJsonld}
     * @memberof CommerciauxJsonld
     */
    'domaine'?: TblDefDomaineJsonld | null;
    /**
     * 
     * @type {TblUtilisateurJsonld}
     * @memberof CommerciauxJsonld
     */
    'userMAJ'?: TblUtilisateurJsonld | null;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxJsonld
     */
    'dateMAJ'?: string | null;
}
/**
 * 
 * @export
 * @interface CommerciauxTiersJsonldPrescripteurReadCommerciaux
 */
export interface CommerciauxTiersJsonldPrescripteurReadCommerciaux {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CommerciauxTiersJsonldPrescripteurReadCommerciaux
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxTiersJsonldPrescripteurReadCommerciaux
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommerciauxTiersJsonldPrescripteurReadCommerciaux
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<CommercialJsonldPrescripteurReadCommerciaux>}
     * @memberof CommerciauxTiersJsonldPrescripteurReadCommerciaux
     */
    'commerciaux'?: Array<CommercialJsonldPrescripteurReadCommerciaux>;
}
/**
 * 
 * @export
 * @interface CommerciauxTiersPrescripteurReadCommerciaux
 */
export interface CommerciauxTiersPrescripteurReadCommerciaux {
    /**
     * 
     * @type {Array<CommercialPrescripteurReadCommerciaux>}
     * @memberof CommerciauxTiersPrescripteurReadCommerciaux
     */
    'commerciaux'?: Array<CommercialPrescripteurReadCommerciaux>;
}
/**
 * 
 * @export
 * @interface ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
 */
export interface ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldPrescripteurReadConditionsCommercialesFormalites}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'societe': SocieteJsonldPrescripteurReadConditionsCommercialesFormalites;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'auForfait'?: boolean;
    /**
     * Envoi de la facture papier
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'factureCourrier'?: boolean;
    /**
     * 1: Prescripteur, 2: Client envoyé au Prescripteur, 3: Client
     * @type {number}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'typeFacture'?: ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum;
    /**
     * Activer le dépôt simplifié de dossier par FTP
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'depotDossierFtp'?: boolean;
    /**
     * Activer la signature électronique
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'signatureElectronique'?: boolean;
    /**
     * Flag qui indique si le prescripteur est un partenaire de formalité
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'partenaireFormalite'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'factureSimplifiee'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'logoFormalite'?: string;
}

export const ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum = typeof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum[keyof typeof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum];

/**
 * 
 * @export
 * @interface ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
 */
export interface ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'societe': SocieteJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'auForfait'?: boolean;
    /**
     * Envoi de la facture papier
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'factureCourrier'?: boolean;
    /**
     * 1: Prescripteur, 2: Client envoyé au Prescripteur, 3: Client
     * @type {number}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'typeFacture'?: ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum;
    /**
     * Activer le dépôt simplifié de dossier par FTP
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'depotDossierFtp'?: boolean;
    /**
     * Activer la signature électronique
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'signatureElectronique'?: boolean;
    /**
     * Flag qui indique si le prescripteur est un partenaire de formalité
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'partenaireFormalite'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'factureSimplifiee'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'logoFormalite'?: string;
}

export const ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum = typeof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum[keyof typeof ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum];

/**
 * 
 * @export
 * @interface ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
 */
export interface ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites {
    /**
     * 
     * @type {SocietePrescripteurReadConditionsCommercialesFormalites}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'societe': SocietePrescripteurReadConditionsCommercialesFormalites;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'auForfait'?: boolean;
    /**
     * Envoi de la facture papier
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'factureCourrier'?: boolean;
    /**
     * 1: Prescripteur, 2: Client envoyé au Prescripteur, 3: Client
     * @type {number}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'typeFacture'?: ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum;
    /**
     * Activer le dépôt simplifié de dossier par FTP
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'depotDossierFtp'?: boolean;
    /**
     * Activer la signature électronique
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'signatureElectronique'?: boolean;
    /**
     * Flag qui indique si le prescripteur est un partenaire de formalité
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'partenaireFormalite'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'factureSimplifiee'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites
     */
    'logoFormalite'?: string;
}

export const ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum = typeof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum[keyof typeof ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalitesTypeFactureEnum];

/**
 * 
 * @export
 * @interface ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
 */
export interface ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire {
    /**
     * 
     * @type {SocietePrescripteurReadConditionsCommercialesGrilleTarifaire}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'societe': SocietePrescripteurReadConditionsCommercialesGrilleTarifaire;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'auForfait'?: boolean;
    /**
     * Envoi de la facture papier
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'factureCourrier'?: boolean;
    /**
     * 1: Prescripteur, 2: Client envoyé au Prescripteur, 3: Client
     * @type {number}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'typeFacture'?: ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum;
    /**
     * Activer le dépôt simplifié de dossier par FTP
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'depotDossierFtp'?: boolean;
    /**
     * Activer la signature électronique
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'signatureElectronique'?: boolean;
    /**
     * Flag qui indique si le prescripteur est un partenaire de formalité
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'partenaireFormalite'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'factureSimplifiee'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'logoFormalite'?: string;
}

export const ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum = typeof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum[keyof typeof ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaireTypeFactureEnum];

/**
 * 
 * @export
 * @interface ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
 */
export interface ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites {
    /**
     * 
     * @type {SocietePrescripteurWriteConditionsCommercialesFormalites}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'societe': SocietePrescripteurWriteConditionsCommercialesFormalites;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'auForfait'?: boolean;
    /**
     * Envoi de la facture papier
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'factureCourrier'?: boolean;
    /**
     * 1: Prescripteur, 2: Client envoyé au Prescripteur, 3: Client
     * @type {number}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'typeFacture'?: ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalitesTypeFactureEnum;
    /**
     * Activer le dépôt simplifié de dossier par FTP
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'depotDossierFtp'?: boolean;
    /**
     * Activer la signature électronique
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'signatureElectronique'?: boolean;
    /**
     * Flag qui indique si le prescripteur est un partenaire de formalité
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'partenaireFormalite'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * Flag Email sous client obligatoire
     * @type {boolean}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'factureSimplifiee'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites
     */
    'logoFormalite'?: string;
}

export const ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalitesTypeFactureEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalitesTypeFactureEnum = typeof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalitesTypeFactureEnum[keyof typeof ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalitesTypeFactureEnum];

/**
 * 
 * @export
 * @interface ConditionsCommercialesPrescripteurWriteConditionsCommercialesGrilleForfaitaire
 */
export interface ConditionsCommercialesPrescripteurWriteConditionsCommercialesGrilleForfaitaire {
    /**
     * 
     * @type {SocietePrescripteurWriteConditionsCommercialesGrilleForfaitaire}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'societe': SocietePrescripteurWriteConditionsCommercialesGrilleForfaitaire;
    /**
     * 
     * @type {Array<ForfaitFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire>}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'forfaitsFormalites': Array<ForfaitFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire>;
    /**
     * 
     * @type {Array<ForfaitAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire>}
     * @memberof ConditionsCommercialesPrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'forfaitsAnnoncesLegales': Array<ForfaitAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire>;
}
/**
 * 
 * @export
 * @interface ContactFournisseurReadFournisseurReadTiers
 */
export interface ContactFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {number}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'civilite': ContactFournisseurReadFournisseurReadTiersCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'sha1'?: string;
    /**
     * 
     * @type {Array<TelephoneFournisseurReadFournisseurReadTiers>}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'telephonesContact': Array<TelephoneFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {Array<MobileFournisseurReadFournisseurReadTiers>}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'mobilesContact': Array<MobileFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {Array<FaxFournisseurReadFournisseurReadTiers>}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'faxsContact': Array<FaxFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {Array<EmailFournisseurReadFournisseurReadTiers>}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'emailsContact': Array<EmailFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {string}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}

export const ContactFournisseurReadFournisseurReadTiersCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactFournisseurReadFournisseurReadTiersCiviliteEnum = typeof ContactFournisseurReadFournisseurReadTiersCiviliteEnum[keyof typeof ContactFournisseurReadFournisseurReadTiersCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactJsonldFournisseurReadFournisseurReadTiers
 */
export interface ContactJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'civilite': ContactJsonldFournisseurReadFournisseurReadTiersCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'sha1'?: string;
    /**
     * 
     * @type {Array<TelephoneJsonldFournisseurReadFournisseurReadTiers>}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'telephonesContact': Array<TelephoneJsonldFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {Array<MobileJsonldFournisseurReadFournisseurReadTiers>}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'mobilesContact': Array<MobileJsonldFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {Array<FaxJsonldFournisseurReadFournisseurReadTiers>}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'faxsContact': Array<FaxJsonldFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {Array<EmailJsonldFournisseurReadFournisseurReadTiers>}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'emailsContact': Array<EmailJsonldFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}

export const ContactJsonldFournisseurReadFournisseurReadTiersCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactJsonldFournisseurReadFournisseurReadTiersCiviliteEnum = typeof ContactJsonldFournisseurReadFournisseurReadTiersCiviliteEnum[keyof typeof ContactJsonldFournisseurReadFournisseurReadTiersCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactJsonldPrescripteurCreate
 */
export interface ContactJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactJsonldPrescripteurCreate
     */
    'civilite': ContactJsonldPrescripteurCreateCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurCreate
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurCreate
     */
    'prenomContact': string;
    /**
     * 
     * @type {Array<TelephoneJsonldPrescripteurCreate>}
     * @memberof ContactJsonldPrescripteurCreate
     */
    'telephonesContact': Array<TelephoneJsonldPrescripteurCreate>;
    /**
     * 
     * @type {Array<MobileJsonldPrescripteurCreate>}
     * @memberof ContactJsonldPrescripteurCreate
     */
    'mobilesContact': Array<MobileJsonldPrescripteurCreate>;
    /**
     * 
     * @type {Array<FaxJsonldPrescripteurCreate>}
     * @memberof ContactJsonldPrescripteurCreate
     */
    'faxsContact': Array<FaxJsonldPrescripteurCreate>;
    /**
     * 
     * @type {Array<EmailJsonldPrescripteurCreate>}
     * @memberof ContactJsonldPrescripteurCreate
     */
    'emailsContact': Array<EmailJsonldPrescripteurCreate>;
}

export const ContactJsonldPrescripteurCreateCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactJsonldPrescripteurCreateCiviliteEnum = typeof ContactJsonldPrescripteurCreateCiviliteEnum[keyof typeof ContactJsonldPrescripteurCreateCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactJsonldPrescripteurCreated
 */
export interface ContactJsonldPrescripteurCreated {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactJsonldPrescripteurCreated
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactJsonldPrescripteurCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ContactJsonldPrescripteurWriteContacts
 */
export interface ContactJsonldPrescripteurWriteContacts {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'civilite': ContactJsonldPrescripteurWriteContactsCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldPrescripteurWriteContacts
     */
    'note'?: string;
}

export const ContactJsonldPrescripteurWriteContactsCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactJsonldPrescripteurWriteContactsCiviliteEnum = typeof ContactJsonldPrescripteurWriteContactsCiviliteEnum[keyof typeof ContactJsonldPrescripteurWriteContactsCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactJsonldSousclientCreate
 */
export interface ContactJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactJsonldSousclientCreate
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactJsonldSousclientCreate
     */
    'civilite': ContactJsonldSousclientCreateCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientCreate
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientCreate
     */
    'prenomContact': string;
    /**
     * 
     * @type {Array<TelephoneJsonldSousclientCreate>}
     * @memberof ContactJsonldSousclientCreate
     */
    'telephonesContact': Array<TelephoneJsonldSousclientCreate>;
    /**
     * 
     * @type {Array<MobileJsonldSousclientCreate>}
     * @memberof ContactJsonldSousclientCreate
     */
    'mobilesContact': Array<MobileJsonldSousclientCreate>;
    /**
     * 
     * @type {Array<FaxJsonldSousclientCreate>}
     * @memberof ContactJsonldSousclientCreate
     */
    'faxsContact': Array<FaxJsonldSousclientCreate>;
    /**
     * 
     * @type {Array<EmailJsonldSousclientCreate>}
     * @memberof ContactJsonldSousclientCreate
     */
    'emailsContact': Array<EmailJsonldSousclientCreate>;
}

export const ContactJsonldSousclientCreateCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactJsonldSousclientCreateCiviliteEnum = typeof ContactJsonldSousclientCreateCiviliteEnum[keyof typeof ContactJsonldSousclientCreateCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactJsonldSousclientCreated
 */
export interface ContactJsonldSousclientCreated {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactJsonldSousclientCreated
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactJsonldSousclientCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ContactJsonldSousclientWriteContacts
 */
export interface ContactJsonldSousclientWriteContacts {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactJsonldSousclientWriteContacts
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientWriteContacts
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientWriteContacts
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactJsonldSousclientWriteContacts
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactJsonldSousclientWriteContacts
     */
    'civilite': ContactJsonldSousclientWriteContactsCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientWriteContacts
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldSousclientWriteContacts
     */
    'prenomContact': string;
}

export const ContactJsonldSousclientWriteContactsCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactJsonldSousclientWriteContactsCiviliteEnum = typeof ContactJsonldSousclientWriteContactsCiviliteEnum[keyof typeof ContactJsonldSousclientWriteContactsCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactJsonldTiersRead
 */
export interface ContactJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactJsonldTiersRead
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactJsonldTiersRead
     */
    'civilite': ContactJsonldTiersReadCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersRead
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersRead
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersRead
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldTiersRead
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldTiersRead
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldTiersRead
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldTiersRead
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersRead
     */
    'sha1'?: string;
    /**
     * 
     * @type {Array<TelephoneJsonldTiersRead>}
     * @memberof ContactJsonldTiersRead
     */
    'telephonesContact': Array<TelephoneJsonldTiersRead>;
    /**
     * 
     * @type {Array<MobileJsonldTiersRead>}
     * @memberof ContactJsonldTiersRead
     */
    'mobilesContact': Array<MobileJsonldTiersRead>;
    /**
     * 
     * @type {Array<FaxJsonldTiersRead>}
     * @memberof ContactJsonldTiersRead
     */
    'faxsContact': Array<FaxJsonldTiersRead>;
    /**
     * 
     * @type {Array<EmailJsonldTiersRead>}
     * @memberof ContactJsonldTiersRead
     */
    'emailsContact': Array<EmailJsonldTiersRead>;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersRead
     */
    'dateMaj'?: string | null;
}

export const ContactJsonldTiersReadCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactJsonldTiersReadCiviliteEnum = typeof ContactJsonldTiersReadCiviliteEnum[keyof typeof ContactJsonldTiersReadCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactJsonldTiersReadTiersReadExpanded
 */
export interface ContactJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'civilite': ContactJsonldTiersReadTiersReadExpandedCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'sha1'?: string;
    /**
     * 
     * @type {Array<TelephoneJsonldTiersReadTiersReadExpanded>}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'telephonesContact': Array<TelephoneJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<MobileJsonldTiersReadTiersReadExpanded>}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'mobilesContact': Array<MobileJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<FaxJsonldTiersReadTiersReadExpanded>}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'faxsContact': Array<FaxJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<EmailJsonldTiersReadTiersReadExpanded>}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'emailsContact': Array<EmailJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactJsonldTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}

export const ContactJsonldTiersReadTiersReadExpandedCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactJsonldTiersReadTiersReadExpandedCiviliteEnum = typeof ContactJsonldTiersReadTiersReadExpandedCiviliteEnum[keyof typeof ContactJsonldTiersReadTiersReadExpandedCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactPrescripteurCreate
 */
export interface ContactPrescripteurCreate {
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactPrescripteurCreate
     */
    'civilite': ContactPrescripteurCreateCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactPrescripteurCreate
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPrescripteurCreate
     */
    'prenomContact': string;
    /**
     * 
     * @type {Array<TelephonePrescripteurCreate>}
     * @memberof ContactPrescripteurCreate
     */
    'telephonesContact': Array<TelephonePrescripteurCreate>;
    /**
     * 
     * @type {Array<MobilePrescripteurCreate>}
     * @memberof ContactPrescripteurCreate
     */
    'mobilesContact': Array<MobilePrescripteurCreate>;
    /**
     * 
     * @type {Array<FaxPrescripteurCreate>}
     * @memberof ContactPrescripteurCreate
     */
    'faxsContact': Array<FaxPrescripteurCreate>;
    /**
     * 
     * @type {Array<EmailPrescripteurCreate>}
     * @memberof ContactPrescripteurCreate
     */
    'emailsContact': Array<EmailPrescripteurCreate>;
}

export const ContactPrescripteurCreateCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactPrescripteurCreateCiviliteEnum = typeof ContactPrescripteurCreateCiviliteEnum[keyof typeof ContactPrescripteurCreateCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactPrescripteurCreated
 */
export interface ContactPrescripteurCreated {
    /**
     * 
     * @type {number}
     * @memberof ContactPrescripteurCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ContactPrescripteurWriteContacts
 */
export interface ContactPrescripteurWriteContacts {
    /**
     * 
     * @type {number}
     * @memberof ContactPrescripteurWriteContacts
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactPrescripteurWriteContacts
     */
    'civilite': ContactPrescripteurWriteContactsCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactPrescripteurWriteContacts
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPrescripteurWriteContacts
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPrescripteurWriteContacts
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactPrescripteurWriteContacts
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactPrescripteurWriteContacts
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactPrescripteurWriteContacts
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactPrescripteurWriteContacts
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactPrescripteurWriteContacts
     */
    'note'?: string;
}

export const ContactPrescripteurWriteContactsCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactPrescripteurWriteContactsCiviliteEnum = typeof ContactPrescripteurWriteContactsCiviliteEnum[keyof typeof ContactPrescripteurWriteContactsCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactSousclientCreate
 */
export interface ContactSousclientCreate {
    /**
     * 
     * @type {number}
     * @memberof ContactSousclientCreate
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactSousclientCreate
     */
    'civilite': ContactSousclientCreateCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactSousclientCreate
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactSousclientCreate
     */
    'prenomContact': string;
    /**
     * 
     * @type {Array<TelephoneSousclientCreate>}
     * @memberof ContactSousclientCreate
     */
    'telephonesContact': Array<TelephoneSousclientCreate>;
    /**
     * 
     * @type {Array<MobileSousclientCreate>}
     * @memberof ContactSousclientCreate
     */
    'mobilesContact': Array<MobileSousclientCreate>;
    /**
     * 
     * @type {Array<FaxSousclientCreate>}
     * @memberof ContactSousclientCreate
     */
    'faxsContact': Array<FaxSousclientCreate>;
    /**
     * 
     * @type {Array<EmailSousclientCreate>}
     * @memberof ContactSousclientCreate
     */
    'emailsContact': Array<EmailSousclientCreate>;
}

export const ContactSousclientCreateCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactSousclientCreateCiviliteEnum = typeof ContactSousclientCreateCiviliteEnum[keyof typeof ContactSousclientCreateCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactSousclientCreated
 */
export interface ContactSousclientCreated {
    /**
     * 
     * @type {number}
     * @memberof ContactSousclientCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
 */
export interface ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient {
    /**
     * 
     * @type {number}
     * @memberof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'civilite': ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclientCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'prenomContact': string;
    /**
     * 
     * @type {Array<TelephoneSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>}
     * @memberof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'telephonesContact': Array<TelephoneSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>;
    /**
     * 
     * @type {Array<MobileSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>}
     * @memberof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'mobilesContact': Array<MobileSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>;
    /**
     * 
     * @type {Array<FaxSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>}
     * @memberof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'faxsContact': Array<FaxSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>;
    /**
     * 
     * @type {Array<EmailSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>}
     * @memberof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'emailsContact': Array<EmailSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>;
}

export const ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclientCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclientCiviliteEnum = typeof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclientCiviliteEnum[keyof typeof ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclientCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactSousclientWriteContacts
 */
export interface ContactSousclientWriteContacts {
    /**
     * 
     * @type {number}
     * @memberof ContactSousclientWriteContacts
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactSousclientWriteContacts
     */
    'civilite': ContactSousclientWriteContactsCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactSousclientWriteContacts
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactSousclientWriteContacts
     */
    'prenomContact': string;
}

export const ContactSousclientWriteContactsCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactSousclientWriteContactsCiviliteEnum = typeof ContactSousclientWriteContactsCiviliteEnum[keyof typeof ContactSousclientWriteContactsCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactTiersRead
 */
export interface ContactTiersRead {
    /**
     * 
     * @type {number}
     * @memberof ContactTiersRead
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactTiersRead
     */
    'civilite': ContactTiersReadCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersRead
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersRead
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersRead
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactTiersRead
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactTiersRead
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactTiersRead
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactTiersRead
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersRead
     */
    'sha1'?: string;
    /**
     * 
     * @type {Array<TelephoneTiersRead>}
     * @memberof ContactTiersRead
     */
    'telephonesContact': Array<TelephoneTiersRead>;
    /**
     * 
     * @type {Array<MobileTiersRead>}
     * @memberof ContactTiersRead
     */
    'mobilesContact': Array<MobileTiersRead>;
    /**
     * 
     * @type {Array<FaxTiersRead>}
     * @memberof ContactTiersRead
     */
    'faxsContact': Array<FaxTiersRead>;
    /**
     * 
     * @type {Array<EmailTiersRead>}
     * @memberof ContactTiersRead
     */
    'emailsContact': Array<EmailTiersRead>;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersRead
     */
    'dateMaj'?: string | null;
}

export const ContactTiersReadCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactTiersReadCiviliteEnum = typeof ContactTiersReadCiviliteEnum[keyof typeof ContactTiersReadCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactTiersReadTiersReadExpanded
 */
export interface ContactTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'civilite': ContactTiersReadTiersReadExpandedCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'sha1'?: string;
    /**
     * 
     * @type {Array<TelephoneTiersReadTiersReadExpanded>}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'telephonesContact': Array<TelephoneTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<MobileTiersReadTiersReadExpanded>}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'mobilesContact': Array<MobileTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<FaxTiersReadTiersReadExpanded>}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'faxsContact': Array<FaxTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<EmailTiersReadTiersReadExpanded>}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'emailsContact': Array<EmailTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}

export const ContactTiersReadTiersReadExpandedCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactTiersReadTiersReadExpandedCiviliteEnum = typeof ContactTiersReadTiersReadExpandedCiviliteEnum[keyof typeof ContactTiersReadTiersReadExpandedCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactsContactContactCreate
 */
export interface ContactsContactContactCreate {
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactsContactContactCreate
     */
    'civilite': ContactsContactContactCreateCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactCreate
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactCreate
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactCreate
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactCreate
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactCreate
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactCreate
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactCreate
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactCreate
     */
    'note'?: string;
    /**
     * 
     * @type {Array<TelephoneContactCreate>}
     * @memberof ContactsContactContactCreate
     */
    'telephonesContact': Array<TelephoneContactCreate>;
    /**
     * 
     * @type {Array<MobileContactCreate>}
     * @memberof ContactsContactContactCreate
     */
    'mobilesContact': Array<MobileContactCreate>;
    /**
     * 
     * @type {Array<FaxContactCreate>}
     * @memberof ContactsContactContactCreate
     */
    'faxsContact': Array<FaxContactCreate>;
    /**
     * 
     * @type {Array<EmailContactCreate>}
     * @memberof ContactsContactContactCreate
     */
    'emailsContact': Array<EmailContactCreate>;
}

export const ContactsContactContactCreateCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactsContactContactCreateCiviliteEnum = typeof ContactsContactContactCreateCiviliteEnum[keyof typeof ContactsContactContactCreateCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactsContactContactRead
 */
export interface ContactsContactContactRead {
    /**
     * 
     * @type {number}
     * @memberof ContactsContactContactRead
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactsContactContactRead
     */
    'civilite': ContactsContactContactReadCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactRead
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactRead
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactRead
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactRead
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactRead
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactRead
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactRead
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactRead
     */
    'sha1'?: string;
    /**
     * 
     * @type {Array<TelephoneContactRead>}
     * @memberof ContactsContactContactRead
     */
    'telephonesContact': Array<TelephoneContactRead>;
    /**
     * 
     * @type {Array<MobileContactRead>}
     * @memberof ContactsContactContactRead
     */
    'mobilesContact': Array<MobileContactRead>;
    /**
     * 
     * @type {Array<FaxContactRead>}
     * @memberof ContactsContactContactRead
     */
    'faxsContact': Array<FaxContactRead>;
    /**
     * 
     * @type {Array<EmailContactRead>}
     * @memberof ContactsContactContactRead
     */
    'emailsContact': Array<EmailContactRead>;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactRead
     */
    'dateMaj'?: string | null;
}

export const ContactsContactContactReadCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactsContactContactReadCiviliteEnum = typeof ContactsContactContactReadCiviliteEnum[keyof typeof ContactsContactContactReadCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactsContactContactUpdate
 */
export interface ContactsContactContactUpdate {
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactsContactContactUpdate
     */
    'civilite': ContactsContactContactUpdateCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactUpdate
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactUpdate
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactUpdate
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactUpdate
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactUpdate
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactUpdate
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactContactUpdate
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactContactUpdate
     */
    'note'?: string;
}

export const ContactsContactContactUpdateCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactsContactContactUpdateCiviliteEnum = typeof ContactsContactContactUpdateCiviliteEnum[keyof typeof ContactsContactContactUpdateCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactsContactContactWriteEmailsPrescripteur
 */
export interface ContactsContactContactWriteEmailsPrescripteur {
    /**
     * 
     * @type {Array<EmailContactWriteEmailsPrescripteur>}
     * @memberof ContactsContactContactWriteEmailsPrescripteur
     */
    'emailsContact': Array<EmailContactWriteEmailsPrescripteur>;
}
/**
 * 
 * @export
 * @interface ContactsContactContactWriteEmailsSousclient
 */
export interface ContactsContactContactWriteEmailsSousclient {
    /**
     * 
     * @type {Array<EmailContactWriteEmailsSousclient>}
     * @memberof ContactsContactContactWriteEmailsSousclient
     */
    'emailsContact': Array<EmailContactWriteEmailsSousclient>;
}
/**
 * 
 * @export
 * @interface ContactsContactContactWriteFaxsPrescripteur
 */
export interface ContactsContactContactWriteFaxsPrescripteur {
    /**
     * 
     * @type {Array<FaxContactWriteFaxsPrescripteur>}
     * @memberof ContactsContactContactWriteFaxsPrescripteur
     */
    'faxsContact': Array<FaxContactWriteFaxsPrescripteur>;
}
/**
 * 
 * @export
 * @interface ContactsContactContactWriteFaxsSousclient
 */
export interface ContactsContactContactWriteFaxsSousclient {
    /**
     * 
     * @type {Array<FaxContactWriteFaxsSousclient>}
     * @memberof ContactsContactContactWriteFaxsSousclient
     */
    'faxsContact': Array<FaxContactWriteFaxsSousclient>;
}
/**
 * 
 * @export
 * @interface ContactsContactContactWriteMobilesPrescripteur
 */
export interface ContactsContactContactWriteMobilesPrescripteur {
    /**
     * 
     * @type {Array<MobileContactWriteMobilesPrescripteur>}
     * @memberof ContactsContactContactWriteMobilesPrescripteur
     */
    'mobilesContact': Array<MobileContactWriteMobilesPrescripteur>;
}
/**
 * 
 * @export
 * @interface ContactsContactContactWriteMobilesSousclient
 */
export interface ContactsContactContactWriteMobilesSousclient {
    /**
     * 
     * @type {Array<MobileContactWriteMobilesSousclient>}
     * @memberof ContactsContactContactWriteMobilesSousclient
     */
    'mobilesContact': Array<MobileContactWriteMobilesSousclient>;
}
/**
 * 
 * @export
 * @interface ContactsContactContactWriteTelsPrescripteur
 */
export interface ContactsContactContactWriteTelsPrescripteur {
    /**
     * 
     * @type {Array<TelephoneContactWriteTelsPrescripteur>}
     * @memberof ContactsContactContactWriteTelsPrescripteur
     */
    'telephonesContact': Array<TelephoneContactWriteTelsPrescripteur>;
}
/**
 * 
 * @export
 * @interface ContactsContactContactWriteTelsSousclient
 */
export interface ContactsContactContactWriteTelsSousclient {
    /**
     * 
     * @type {Array<TelephoneContactWriteTelsSousclient>}
     * @memberof ContactsContactContactWriteTelsSousclient
     */
    'telephonesContact': Array<TelephoneContactWriteTelsSousclient>;
}
/**
 * 
 * @export
 * @interface ContactsContactJsonldContactCreate
 */
export interface ContactsContactJsonldContactCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactCreate
     */
    '@type'?: string;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactsContactJsonldContactCreate
     */
    'civilite': ContactsContactJsonldContactCreateCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactCreate
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactCreate
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactCreate
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactCreate
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactCreate
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactCreate
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactCreate
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactCreate
     */
    'note'?: string;
    /**
     * 
     * @type {Array<TelephoneJsonldContactCreate>}
     * @memberof ContactsContactJsonldContactCreate
     */
    'telephonesContact': Array<TelephoneJsonldContactCreate>;
    /**
     * 
     * @type {Array<MobileJsonldContactCreate>}
     * @memberof ContactsContactJsonldContactCreate
     */
    'mobilesContact': Array<MobileJsonldContactCreate>;
    /**
     * 
     * @type {Array<FaxJsonldContactCreate>}
     * @memberof ContactsContactJsonldContactCreate
     */
    'faxsContact': Array<FaxJsonldContactCreate>;
    /**
     * 
     * @type {Array<EmailJsonldContactCreate>}
     * @memberof ContactsContactJsonldContactCreate
     */
    'emailsContact': Array<EmailJsonldContactCreate>;
}

export const ContactsContactJsonldContactCreateCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactsContactJsonldContactCreateCiviliteEnum = typeof ContactsContactJsonldContactCreateCiviliteEnum[keyof typeof ContactsContactJsonldContactCreateCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactsContactJsonldContactRead
 */
export interface ContactsContactJsonldContactRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactsContactJsonldContactRead
     */
    'id'?: number;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactsContactJsonldContactRead
     */
    'civilite': ContactsContactJsonldContactReadCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactRead
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactRead
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactRead
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactRead
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactRead
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactRead
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactRead
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactRead
     */
    'sha1'?: string;
    /**
     * 
     * @type {Array<TelephoneJsonldContactRead>}
     * @memberof ContactsContactJsonldContactRead
     */
    'telephonesContact': Array<TelephoneJsonldContactRead>;
    /**
     * 
     * @type {Array<MobileJsonldContactRead>}
     * @memberof ContactsContactJsonldContactRead
     */
    'mobilesContact': Array<MobileJsonldContactRead>;
    /**
     * 
     * @type {Array<FaxJsonldContactRead>}
     * @memberof ContactsContactJsonldContactRead
     */
    'faxsContact': Array<FaxJsonldContactRead>;
    /**
     * 
     * @type {Array<EmailJsonldContactRead>}
     * @memberof ContactsContactJsonldContactRead
     */
    'emailsContact': Array<EmailJsonldContactRead>;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactRead
     */
    'dateMaj'?: string | null;
}

export const ContactsContactJsonldContactReadCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactsContactJsonldContactReadCiviliteEnum = typeof ContactsContactJsonldContactReadCiviliteEnum[keyof typeof ContactsContactJsonldContactReadCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactsContactJsonldContactUpdate
 */
export interface ContactsContactJsonldContactUpdate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactUpdate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactUpdate
     */
    '@type'?: string;
    /**
     * identifiant civilité cf. /tiers/civilites
     * @type {number}
     * @memberof ContactsContactJsonldContactUpdate
     */
    'civilite': ContactsContactJsonldContactUpdateCiviliteEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactUpdate
     */
    'nomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactUpdate
     */
    'prenomContact': string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactUpdate
     */
    'fonction': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactUpdate
     */
    'principal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactUpdate
     */
    'comptabilite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactUpdate
     */
    'administrateur'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactsContactJsonldContactUpdate
     */
    'isExit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactUpdate
     */
    'note'?: string;
}

export const ContactsContactJsonldContactUpdateCiviliteEnum = {
    NUMBER_0: 0,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_3509: 3509,
    NUMBER_4520: 4520,
    NUMBER_4521: 4521,
    NUMBER_4522: 4522,
    NUMBER_2512: 2512,
    NUMBER_3518: 3518,
    NUMBER_3519: 3519,
    NUMBER_3520: 3520
} as const;

export type ContactsContactJsonldContactUpdateCiviliteEnum = typeof ContactsContactJsonldContactUpdateCiviliteEnum[keyof typeof ContactsContactJsonldContactUpdateCiviliteEnum];

/**
 * 
 * @export
 * @interface ContactsContactJsonldContactWriteEmailsPrescripteur
 */
export interface ContactsContactJsonldContactWriteEmailsPrescripteur {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactWriteEmailsPrescripteur
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteEmailsPrescripteur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteEmailsPrescripteur
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<EmailJsonldContactWriteEmailsPrescripteur>}
     * @memberof ContactsContactJsonldContactWriteEmailsPrescripteur
     */
    'emailsContact': Array<EmailJsonldContactWriteEmailsPrescripteur>;
}
/**
 * 
 * @export
 * @interface ContactsContactJsonldContactWriteEmailsSousclient
 */
export interface ContactsContactJsonldContactWriteEmailsSousclient {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactWriteEmailsSousclient
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteEmailsSousclient
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteEmailsSousclient
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<EmailJsonldContactWriteEmailsSousclient>}
     * @memberof ContactsContactJsonldContactWriteEmailsSousclient
     */
    'emailsContact': Array<EmailJsonldContactWriteEmailsSousclient>;
}
/**
 * 
 * @export
 * @interface ContactsContactJsonldContactWriteFaxsPrescripteur
 */
export interface ContactsContactJsonldContactWriteFaxsPrescripteur {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactWriteFaxsPrescripteur
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteFaxsPrescripteur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteFaxsPrescripteur
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<FaxJsonldContactWriteFaxsPrescripteur>}
     * @memberof ContactsContactJsonldContactWriteFaxsPrescripteur
     */
    'faxsContact': Array<FaxJsonldContactWriteFaxsPrescripteur>;
}
/**
 * 
 * @export
 * @interface ContactsContactJsonldContactWriteFaxsSousclient
 */
export interface ContactsContactJsonldContactWriteFaxsSousclient {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactWriteFaxsSousclient
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteFaxsSousclient
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteFaxsSousclient
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<FaxJsonldContactWriteFaxsSousclient>}
     * @memberof ContactsContactJsonldContactWriteFaxsSousclient
     */
    'faxsContact': Array<FaxJsonldContactWriteFaxsSousclient>;
}
/**
 * 
 * @export
 * @interface ContactsContactJsonldContactWriteMobilesPrescripteur
 */
export interface ContactsContactJsonldContactWriteMobilesPrescripteur {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactWriteMobilesPrescripteur
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteMobilesPrescripteur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteMobilesPrescripteur
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<MobileJsonldContactWriteMobilesPrescripteur>}
     * @memberof ContactsContactJsonldContactWriteMobilesPrescripteur
     */
    'mobilesContact': Array<MobileJsonldContactWriteMobilesPrescripteur>;
}
/**
 * 
 * @export
 * @interface ContactsContactJsonldContactWriteMobilesSousclient
 */
export interface ContactsContactJsonldContactWriteMobilesSousclient {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactWriteMobilesSousclient
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteMobilesSousclient
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteMobilesSousclient
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<MobileJsonldContactWriteMobilesSousclient>}
     * @memberof ContactsContactJsonldContactWriteMobilesSousclient
     */
    'mobilesContact': Array<MobileJsonldContactWriteMobilesSousclient>;
}
/**
 * 
 * @export
 * @interface ContactsContactJsonldContactWriteTelsPrescripteur
 */
export interface ContactsContactJsonldContactWriteTelsPrescripteur {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactWriteTelsPrescripteur
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteTelsPrescripteur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteTelsPrescripteur
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<TelephoneJsonldContactWriteTelsPrescripteur>}
     * @memberof ContactsContactJsonldContactWriteTelsPrescripteur
     */
    'telephonesContact': Array<TelephoneJsonldContactWriteTelsPrescripteur>;
}
/**
 * 
 * @export
 * @interface ContactsContactJsonldContactWriteTelsSousclient
 */
export interface ContactsContactJsonldContactWriteTelsSousclient {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ContactsContactJsonldContactWriteTelsSousclient
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteTelsSousclient
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsContactJsonldContactWriteTelsSousclient
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<TelephoneJsonldContactWriteTelsSousclient>}
     * @memberof ContactsContactJsonldContactWriteTelsSousclient
     */
    'telephonesContact': Array<TelephoneJsonldContactWriteTelsSousclient>;
}
/**
 * 
 * @export
 * @interface CoordonneesEmailCoordonneeRead
 */
export interface CoordonneesEmailCoordonneeRead {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailCoordonneeRead
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CoordonneesEmailCoordonneeRead
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesEmailCoordonneeRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesEmailCoordonneeRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesEmailCoordonneeUpdate
 */
export interface CoordonneesEmailCoordonneeUpdate {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailCoordonneeUpdate
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CoordonneesEmailCoordonneeUpdate
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesEmailCoordonneeUpdate
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesEmailCoordonneeUpdate
     */
    'defaut'?: boolean;
    /**
     * coordonnée supprimée
     * @type {boolean}
     * @memberof CoordonneesEmailCoordonneeUpdate
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesEmailJsonld
 */
export interface CoordonneesEmailJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CoordonneesEmailJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface CoordonneesEmailJsonldCoordonneeRead
 */
export interface CoordonneesEmailJsonldCoordonneeRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CoordonneesEmailJsonldCoordonneeRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailJsonldCoordonneeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailJsonldCoordonneeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailJsonldCoordonneeRead
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CoordonneesEmailJsonldCoordonneeRead
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesEmailJsonldCoordonneeRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesEmailJsonldCoordonneeRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesEmailJsonldCoordonneeUpdate
 */
export interface CoordonneesEmailJsonldCoordonneeUpdate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CoordonneesEmailJsonldCoordonneeUpdate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailJsonldCoordonneeUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailJsonldCoordonneeUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesEmailJsonldCoordonneeUpdate
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CoordonneesEmailJsonldCoordonneeUpdate
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesEmailJsonldCoordonneeUpdate
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesEmailJsonldCoordonneeUpdate
     */
    'defaut'?: boolean;
    /**
     * coordonnée supprimée
     * @type {boolean}
     * @memberof CoordonneesEmailJsonldCoordonneeUpdate
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesFaxCoordonneeRead
 */
export interface CoordonneesFaxCoordonneeRead {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesFaxCoordonneeRead
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesFaxCoordonneeRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesFaxCoordonneeRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesFaxCoordonneeUpdate
 */
export interface CoordonneesFaxCoordonneeUpdate {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesFaxCoordonneeUpdate
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesFaxCoordonneeUpdate
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesFaxCoordonneeUpdate
     */
    'defaut'?: boolean;
    /**
     * coordonnée supprimée
     * @type {boolean}
     * @memberof CoordonneesFaxCoordonneeUpdate
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesFaxJsonldCoordonneeRead
 */
export interface CoordonneesFaxJsonldCoordonneeRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CoordonneesFaxJsonldCoordonneeRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesFaxJsonldCoordonneeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesFaxJsonldCoordonneeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesFaxJsonldCoordonneeRead
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesFaxJsonldCoordonneeRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesFaxJsonldCoordonneeRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesFaxJsonldCoordonneeUpdate
 */
export interface CoordonneesFaxJsonldCoordonneeUpdate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CoordonneesFaxJsonldCoordonneeUpdate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesFaxJsonldCoordonneeUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesFaxJsonldCoordonneeUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesFaxJsonldCoordonneeUpdate
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesFaxJsonldCoordonneeUpdate
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesFaxJsonldCoordonneeUpdate
     */
    'defaut'?: boolean;
    /**
     * coordonnée supprimée
     * @type {boolean}
     * @memberof CoordonneesFaxJsonldCoordonneeUpdate
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesMobileCoordonneeRead
 */
export interface CoordonneesMobileCoordonneeRead {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesMobileCoordonneeRead
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesMobileCoordonneeRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesMobileCoordonneeRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesMobileCoordonneeUpdate
 */
export interface CoordonneesMobileCoordonneeUpdate {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesMobileCoordonneeUpdate
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesMobileCoordonneeUpdate
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesMobileCoordonneeUpdate
     */
    'defaut'?: boolean;
    /**
     * coordonnée supprimée
     * @type {boolean}
     * @memberof CoordonneesMobileCoordonneeUpdate
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesMobileJsonldCoordonneeRead
 */
export interface CoordonneesMobileJsonldCoordonneeRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CoordonneesMobileJsonldCoordonneeRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesMobileJsonldCoordonneeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesMobileJsonldCoordonneeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesMobileJsonldCoordonneeRead
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesMobileJsonldCoordonneeRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesMobileJsonldCoordonneeRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesMobileJsonldCoordonneeUpdate
 */
export interface CoordonneesMobileJsonldCoordonneeUpdate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CoordonneesMobileJsonldCoordonneeUpdate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesMobileJsonldCoordonneeUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesMobileJsonldCoordonneeUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesMobileJsonldCoordonneeUpdate
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesMobileJsonldCoordonneeUpdate
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesMobileJsonldCoordonneeUpdate
     */
    'defaut'?: boolean;
    /**
     * coordonnée supprimée
     * @type {boolean}
     * @memberof CoordonneesMobileJsonldCoordonneeUpdate
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesTelephoneCoordonneeRead
 */
export interface CoordonneesTelephoneCoordonneeRead {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesTelephoneCoordonneeRead
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesTelephoneCoordonneeRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesTelephoneCoordonneeRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesTelephoneCoordonneeUpdate
 */
export interface CoordonneesTelephoneCoordonneeUpdate {
    /**
     * 
     * @type {string}
     * @memberof CoordonneesTelephoneCoordonneeUpdate
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesTelephoneCoordonneeUpdate
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesTelephoneCoordonneeUpdate
     */
    'defaut'?: boolean;
    /**
     * coordonnée supprimée
     * @type {boolean}
     * @memberof CoordonneesTelephoneCoordonneeUpdate
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesTelephoneJsonldCoordonneeRead
 */
export interface CoordonneesTelephoneJsonldCoordonneeRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CoordonneesTelephoneJsonldCoordonneeRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesTelephoneJsonldCoordonneeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesTelephoneJsonldCoordonneeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesTelephoneJsonldCoordonneeRead
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesTelephoneJsonldCoordonneeRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesTelephoneJsonldCoordonneeRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface CoordonneesTelephoneJsonldCoordonneeUpdate
 */
export interface CoordonneesTelephoneJsonldCoordonneeUpdate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof CoordonneesTelephoneJsonldCoordonneeUpdate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesTelephoneJsonldCoordonneeUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesTelephoneJsonldCoordonneeUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordonneesTelephoneJsonldCoordonneeUpdate
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof CoordonneesTelephoneJsonldCoordonneeUpdate
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof CoordonneesTelephoneJsonldCoordonneeUpdate
     */
    'defaut'?: boolean;
    /**
     * coordonnée supprimée
     * @type {boolean}
     * @memberof CoordonneesTelephoneJsonldCoordonneeUpdate
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateCommercialPrescripteurCommerciauxCollection400Response
 */
export interface CreateCommercialPrescripteurCommerciauxCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof CreateCommercialPrescripteurCommerciauxCollection400Response
     */
    'error': string;
    /**
     * 
     * @type {CreateCommercialPrescripteurCommerciauxCollection400ResponseException}
     * @memberof CreateCommercialPrescripteurCommerciauxCollection400Response
     */
    'exception': CreateCommercialPrescripteurCommerciauxCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface CreateCommercialPrescripteurCommerciauxCollection400ResponseException
 */
export interface CreateCommercialPrescripteurCommerciauxCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof CreateCommercialPrescripteurCommerciauxCollection400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCommercialPrescripteurCommerciauxCollection400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<CreateCommercialPrescripteurCommerciauxCollection400ResponseExceptionViolationsInner>}
     * @memberof CreateCommercialPrescripteurCommerciauxCollection400ResponseException
     */
    'violations'?: Array<CreateCommercialPrescripteurCommerciauxCollection400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface CreateCommercialPrescripteurCommerciauxCollection400ResponseExceptionViolationsInner
 */
export interface CreateCommercialPrescripteurCommerciauxCollection400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof CreateCommercialPrescripteurCommerciauxCollection400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof CreateCommercialPrescripteurCommerciauxCollection400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface CreatePrescripteurAdresseAdressesCollection400Response
 */
export interface CreatePrescripteurAdresseAdressesCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof CreatePrescripteurAdresseAdressesCollection400Response
     */
    'error': string;
    /**
     * 
     * @type {CreatePrescripteurAdresseAdressesCollection400ResponseException}
     * @memberof CreatePrescripteurAdresseAdressesCollection400Response
     */
    'exception': CreatePrescripteurAdresseAdressesCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface CreatePrescripteurAdresseAdressesCollection400ResponseException
 */
export interface CreatePrescripteurAdresseAdressesCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof CreatePrescripteurAdresseAdressesCollection400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePrescripteurAdresseAdressesCollection400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<CreatePrescripteurAdresseAdressesCollection400ResponseExceptionViolationsInner>}
     * @memberof CreatePrescripteurAdresseAdressesCollection400ResponseException
     */
    'violations'?: Array<CreatePrescripteurAdresseAdressesCollection400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface CreatePrescripteurAdresseAdressesCollection400ResponseExceptionViolationsInner
 */
export interface CreatePrescripteurAdresseAdressesCollection400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof CreatePrescripteurAdresseAdressesCollection400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof CreatePrescripteurAdresseAdressesCollection400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface CreatePrescripteurContactContactsCollection400Response
 */
export interface CreatePrescripteurContactContactsCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof CreatePrescripteurContactContactsCollection400Response
     */
    'error': string;
    /**
     * 
     * @type {CreatePrescripteurContactContactsCollection400ResponseException}
     * @memberof CreatePrescripteurContactContactsCollection400Response
     */
    'exception': CreatePrescripteurContactContactsCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface CreatePrescripteurContactContactsCollection400ResponseException
 */
export interface CreatePrescripteurContactContactsCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof CreatePrescripteurContactContactsCollection400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePrescripteurContactContactsCollection400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<CreatePrescripteurContactContactsCollection400ResponseExceptionViolationsInner>}
     * @memberof CreatePrescripteurContactContactsCollection400ResponseException
     */
    'violations'?: Array<CreatePrescripteurContactContactsCollection400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface CreatePrescripteurContactContactsCollection400ResponseExceptionViolationsInner
 */
export interface CreatePrescripteurContactContactsCollection400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof CreatePrescripteurContactContactsCollection400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof CreatePrescripteurContactContactsCollection400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface CreatePrescripteurTiersCollection400Response
 */
export interface CreatePrescripteurTiersCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof CreatePrescripteurTiersCollection400Response
     */
    'error': string;
    /**
     * 
     * @type {CreatePrescripteurTiersCollection400ResponseException}
     * @memberof CreatePrescripteurTiersCollection400Response
     */
    'exception': CreatePrescripteurTiersCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface CreatePrescripteurTiersCollection400ResponseException
 */
export interface CreatePrescripteurTiersCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof CreatePrescripteurTiersCollection400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePrescripteurTiersCollection400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<CreatePrescripteurTiersCollection400ResponseExceptionViolationsInner>}
     * @memberof CreatePrescripteurTiersCollection400ResponseException
     */
    'violations'?: Array<CreatePrescripteurTiersCollection400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface CreatePrescripteurTiersCollection400ResponseExceptionViolationsInner
 */
export interface CreatePrescripteurTiersCollection400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof CreatePrescripteurTiersCollection400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof CreatePrescripteurTiersCollection400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface CreateSousclientTiersCollection400Response
 */
export interface CreateSousclientTiersCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof CreateSousclientTiersCollection400Response
     */
    'error': string;
    /**
     * 
     * @type {CreateSousclientTiersCollection400ResponseException}
     * @memberof CreateSousclientTiersCollection400Response
     */
    'exception': CreateSousclientTiersCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface CreateSousclientTiersCollection400ResponseException
 */
export interface CreateSousclientTiersCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof CreateSousclientTiersCollection400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSousclientTiersCollection400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<CreateSousclientTiersCollection400ResponseExceptionViolationsInner>}
     * @memberof CreateSousclientTiersCollection400ResponseException
     */
    'violations'?: Array<CreateSousclientTiersCollection400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface CreateSousclientTiersCollection400ResponseExceptionViolationsInner
 */
export interface CreateSousclientTiersCollection400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof CreateSousclientTiersCollection400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof CreateSousclientTiersCollection400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DomaineJsonldPrescripteurReadCommerciaux
 */
export interface DomaineJsonldPrescripteurReadCommerciaux {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof DomaineJsonldPrescripteurReadCommerciaux
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldPrescripteurReadCommerciaux
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldPrescripteurReadCommerciaux
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomaineJsonldPrescripteurReadCommerciaux
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldPrescripteurReadCommerciaux
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface DomaineJsonldPrescripteurWriteCommercial
 */
export interface DomaineJsonldPrescripteurWriteCommercial {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof DomaineJsonldPrescripteurWriteCommercial
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldPrescripteurWriteCommercial
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldPrescripteurWriteCommercial
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomaineJsonldPrescripteurWriteCommercial
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DomaineJsonldTiersReadTiersReadExpanded
 */
export interface DomaineJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof DomaineJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomaineJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldTiersReadTiersReadExpanded
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface DomainePrescripteurReadCommerciaux
 */
export interface DomainePrescripteurReadCommerciaux {
    /**
     * 
     * @type {number}
     * @memberof DomainePrescripteurReadCommerciaux
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainePrescripteurReadCommerciaux
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface DomainePrescripteurWriteCommercial
 */
export interface DomainePrescripteurWriteCommercial {
    /**
     * 
     * @type {number}
     * @memberof DomainePrescripteurWriteCommercial
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DomaineTiersReadTiersReadExpanded
 */
export interface DomaineTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof DomaineTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomaineTiersReadTiersReadExpanded
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface EmailContactCreate
 */
export interface EmailContactCreate {
    /**
     * 
     * @type {string}
     * @memberof EmailContactCreate
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailContactCreate
     */
    'factureEmail'?: boolean;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailContactCreate
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailContactRead
 */
export interface EmailContactRead {
    /**
     * 
     * @type {string}
     * @memberof EmailContactRead
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailContactRead
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailContactRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailContactRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailContactRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailContactWriteEmailsPrescripteur
 */
export interface EmailContactWriteEmailsPrescripteur {
    /**
     * 
     * @type {string}
     * @memberof EmailContactWriteEmailsPrescripteur
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailContactWriteEmailsPrescripteur
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailContactWriteEmailsPrescripteur
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailContactWriteEmailsPrescripteur
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailContactWriteEmailsSousclient
 */
export interface EmailContactWriteEmailsSousclient {
    /**
     * 
     * @type {string}
     * @memberof EmailContactWriteEmailsSousclient
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailContactWriteEmailsSousclient
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailContactWriteEmailsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailContactWriteEmailsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailFournisseurReadFournisseurReadTiers
 */
export interface EmailFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {string}
     * @memberof EmailFournisseurReadFournisseurReadTiers
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof EmailFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailJsonldContactCreate
 */
export interface EmailJsonldContactCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldContactCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactCreate
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailJsonldContactCreate
     */
    'factureEmail'?: boolean;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailJsonldContactCreate
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailJsonldContactRead
 */
export interface EmailJsonldContactRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldContactRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactRead
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailJsonldContactRead
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailJsonldContactRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailJsonldContactRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailJsonldContactWriteEmailsPrescripteur
 */
export interface EmailJsonldContactWriteEmailsPrescripteur {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldContactWriteEmailsPrescripteur
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactWriteEmailsPrescripteur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactWriteEmailsPrescripteur
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactWriteEmailsPrescripteur
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailJsonldContactWriteEmailsPrescripteur
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailJsonldContactWriteEmailsPrescripteur
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailJsonldContactWriteEmailsPrescripteur
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailJsonldContactWriteEmailsSousclient
 */
export interface EmailJsonldContactWriteEmailsSousclient {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldContactWriteEmailsSousclient
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactWriteEmailsSousclient
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactWriteEmailsSousclient
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldContactWriteEmailsSousclient
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailJsonldContactWriteEmailsSousclient
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailJsonldContactWriteEmailsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailJsonldContactWriteEmailsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailJsonldFournisseurReadFournisseurReadTiers
 */
export interface EmailJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldFournisseurReadFournisseurReadTiers
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof EmailJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailJsonldFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailJsonldPrescripteurCreate
 */
export interface EmailJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldPrescripteurCreate
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface EmailJsonldPrescripteurReadNotificationsEmails
 */
export interface EmailJsonldPrescripteurReadNotificationsEmails {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldPrescripteurReadNotificationsEmails
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldPrescripteurReadNotificationsEmails
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldPrescripteurReadNotificationsEmails
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldPrescripteurReadNotificationsEmails
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailJsonldPrescripteurReadNotificationsEmails
     */
    'notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailJsonldPrescripteurReadNotificationsEmails
     */
    'rappel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailJsonldPrescripteurReadNotificationsEmails
     */
    'hasContact'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailJsonldPrescripteurReadNotificationsEmails
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailJsonldPrescripteurReadNotificationsEmails
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailJsonldSousclientCreate
 */
export interface EmailJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldSousclientCreate
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface EmailJsonldTiersRead
 */
export interface EmailJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersRead
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailJsonldTiersRead
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailJsonldTiersRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailJsonldTiersRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailJsonldTiersReadTiersReadExpanded
 */
export interface EmailJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof EmailJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersReadTiersReadExpanded
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailJsonldTiersReadTiersReadExpanded
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailJsonldTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailJsonldTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailPrescripteurCreate
 */
export interface EmailPrescripteurCreate {
    /**
     * 
     * @type {string}
     * @memberof EmailPrescripteurCreate
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface EmailPrescripteurReadNotificationsEmails
 */
export interface EmailPrescripteurReadNotificationsEmails {
    /**
     * 
     * @type {string}
     * @memberof EmailPrescripteurReadNotificationsEmails
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailPrescripteurReadNotificationsEmails
     */
    'notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailPrescripteurReadNotificationsEmails
     */
    'rappel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailPrescripteurReadNotificationsEmails
     */
    'hasContact'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailPrescripteurReadNotificationsEmails
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailPrescripteurReadNotificationsEmails
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailPrescripteurWriteNotificationsEmails
 */
export interface EmailPrescripteurWriteNotificationsEmails {
    /**
     * 
     * @type {string}
     * @memberof EmailPrescripteurWriteNotificationsEmails
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailPrescripteurWriteNotificationsEmails
     */
    'notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailPrescripteurWriteNotificationsEmails
     */
    'rappel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailPrescripteurWriteNotificationsEmails
     */
    'hasContact'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailSousclientCreate
 */
export interface EmailSousclientCreate {
    /**
     * 
     * @type {string}
     * @memberof EmailSousclientCreate
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface EmailSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
 */
export interface EmailSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient {
    /**
     * 
     * @type {string}
     * @memberof EmailSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface EmailTiersRead
 */
export interface EmailTiersRead {
    /**
     * 
     * @type {string}
     * @memberof EmailTiersRead
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTiersRead
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailTiersRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailTiersRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailTiersReadTiersReadExpanded
 */
export interface EmailTiersReadTiersReadExpanded {
    /**
     * 
     * @type {string}
     * @memberof EmailTiersReadTiersReadExpanded
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTiersReadTiersReadExpanded
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof EmailTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FaxContactCreate
 */
export interface FaxContactCreate {
    /**
     * 
     * @type {string}
     * @memberof FaxContactCreate
     */
    'fax': string;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxContactCreate
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface FaxContactRead
 */
export interface FaxContactRead {
    /**
     * 
     * @type {string}
     * @memberof FaxContactRead
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxContactRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxContactRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaxContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaxContactRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FaxContactWriteFaxsPrescripteur
 */
export interface FaxContactWriteFaxsPrescripteur {
    /**
     * 
     * @type {string}
     * @memberof FaxContactWriteFaxsPrescripteur
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxContactWriteFaxsPrescripteur
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxContactWriteFaxsPrescripteur
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface FaxContactWriteFaxsSousclient
 */
export interface FaxContactWriteFaxsSousclient {
    /**
     * 
     * @type {string}
     * @memberof FaxContactWriteFaxsSousclient
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxContactWriteFaxsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxContactWriteFaxsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface FaxFournisseurReadFournisseurReadTiers
 */
export interface FaxFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {string}
     * @memberof FaxFournisseurReadFournisseurReadTiers
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaxFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaxFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FaxJsonldContactCreate
 */
export interface FaxJsonldContactCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FaxJsonldContactCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactCreate
     */
    'fax': string;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxJsonldContactCreate
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface FaxJsonldContactRead
 */
export interface FaxJsonldContactRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FaxJsonldContactRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactRead
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxJsonldContactRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxJsonldContactRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FaxJsonldContactWriteFaxsPrescripteur
 */
export interface FaxJsonldContactWriteFaxsPrescripteur {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FaxJsonldContactWriteFaxsPrescripteur
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactWriteFaxsPrescripteur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactWriteFaxsPrescripteur
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactWriteFaxsPrescripteur
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxJsonldContactWriteFaxsPrescripteur
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxJsonldContactWriteFaxsPrescripteur
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface FaxJsonldContactWriteFaxsSousclient
 */
export interface FaxJsonldContactWriteFaxsSousclient {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FaxJsonldContactWriteFaxsSousclient
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactWriteFaxsSousclient
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactWriteFaxsSousclient
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldContactWriteFaxsSousclient
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxJsonldContactWriteFaxsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxJsonldContactWriteFaxsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface FaxJsonldFournisseurReadFournisseurReadTiers
 */
export interface FaxJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FaxJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldFournisseurReadFournisseurReadTiers
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxJsonldFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FaxJsonldPrescripteurCreate
 */
export interface FaxJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FaxJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldPrescripteurCreate
     */
    'fax': string;
}
/**
 * 
 * @export
 * @interface FaxJsonldSousclientCreate
 */
export interface FaxJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FaxJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldSousclientCreate
     */
    'fax': string;
}
/**
 * 
 * @export
 * @interface FaxJsonldTiersRead
 */
export interface FaxJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FaxJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersRead
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxJsonldTiersRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxJsonldTiersRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FaxJsonldTiersReadTiersReadExpanded
 */
export interface FaxJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FaxJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersReadTiersReadExpanded
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxJsonldTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaxJsonldTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FaxPrescripteurCreate
 */
export interface FaxPrescripteurCreate {
    /**
     * 
     * @type {string}
     * @memberof FaxPrescripteurCreate
     */
    'fax': string;
}
/**
 * 
 * @export
 * @interface FaxSousclientCreate
 */
export interface FaxSousclientCreate {
    /**
     * 
     * @type {string}
     * @memberof FaxSousclientCreate
     */
    'fax': string;
}
/**
 * 
 * @export
 * @interface FaxSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
 */
export interface FaxSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient {
    /**
     * 
     * @type {string}
     * @memberof FaxSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface FaxTiersRead
 */
export interface FaxTiersRead {
    /**
     * 
     * @type {string}
     * @memberof FaxTiersRead
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxTiersRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxTiersRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaxTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaxTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FaxTiersReadTiersReadExpanded
 */
export interface FaxTiersReadTiersReadExpanded {
    /**
     * 
     * @type {string}
     * @memberof FaxTiersReadTiersReadExpanded
     */
    'fax': string;
    /**
     * 
     * @type {number}
     * @memberof FaxTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof FaxTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaxTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaxTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface ForfaitAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire
 */
export interface ForfaitAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire {
    /**
     * 
     * @type {TarifAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire}
     * @memberof ForfaitAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'tarifAnnonceLegaleSociete'?: TarifAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire;
    /**
     * Montant du tarif à appliquer pour le prescripteur, passer à null pour réstaurer le tarif de la société
     * @type {string}
     * @memberof ForfaitAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'tarifPrescripteur'?: string;
}
/**
 * 
 * @export
 * @interface ForfaitFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire
 */
export interface ForfaitFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire {
    /**
     * 
     * @type {TarifFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire}
     * @memberof ForfaitFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'tarifFormalite': TarifFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire;
    /**
     * Montant pour le prescripteur, passer à null pour réstaurer le tarif de la société
     * @type {string}
     * @memberof ForfaitFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'honorairesEnregistrementRpiPrescripteur'?: string;
    /**
     * Montant pour le prescripteur, passer à null pour réstaurer le tarif de la société
     * @type {string}
     * @memberof ForfaitFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'honorairesDossierPrescripteur'?: string;
}
/**
 * 
 * @export
 * @interface FournisseurFournisseurFournisseurRead
 */
export interface FournisseurFournisseurFournisseurRead {
    /**
     * identifiant du fournisseur
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'siret'?: string;
    /**
     * Code fournisseur présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'fournisseurCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'codeFournisseurCegid'?: string;
    /**
     * TVA sur débits / sur encaissements ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'tvaSurDebits'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'tvaIntracommunautaire'?: string;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'clientCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'codeClientCegid'?: string;
    /**
     * 
     * @type {TypeRemiseFournisseurRead}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'typeRemise'?: TypeRemiseFournisseurRead;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'typeFactureEmail'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'regieExt'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'origineRegie'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'fournisseurEtranger'?: boolean;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'rrrImport'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'tiers'?: object;
    /**
     * 
     * @type {SocieteFournisseurRead}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'societe'?: SocieteFournisseurRead;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'societeReference'?: number;
    /**
     * remise sur facture inclus dans la ligne de PUB ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'rsfInclus'?: boolean;
    /**
     * fournisseur supprimé ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'deleted'?: boolean;
    /**
     * note concernant le fournisseur
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'notes'?: string;
    /**
     * domaine d\'annonce, d\'adjudication, de formalité, ...
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FournisseurFournisseurFournisseurReadFournisseurReadTiers
 */
export interface FournisseurFournisseurFournisseurReadFournisseurReadTiers {
    /**
     * identifiant du fournisseur
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'siret'?: string;
    /**
     * Code fournisseur présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'fournisseurCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'codeFournisseurCegid'?: string;
    /**
     * TVA sur débits / sur encaissements ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'tvaSurDebits'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'tvaIntracommunautaire'?: string;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'clientCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'codeClientCegid'?: string;
    /**
     * 
     * @type {TypeRemiseFournisseurReadFournisseurReadTiers}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'typeRemise'?: TypeRemiseFournisseurReadFournisseurReadTiers;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'typeFactureEmail'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'regieExt'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'origineRegie'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'fournisseurEtranger'?: boolean;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'rrrImport'?: boolean;
    /**
     * 
     * @type {TiersFournisseurReadFournisseurReadTiers}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'tiers'?: TiersFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {SocieteFournisseurReadFournisseurReadTiers}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'societe'?: SocieteFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'societeReference'?: number;
    /**
     * remise sur facture inclus dans la ligne de PUB ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'rsfInclus'?: boolean;
    /**
     * fournisseur supprimé ?
     * @type {boolean}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'deleted'?: boolean;
    /**
     * note concernant le fournisseur
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'notes'?: string;
    /**
     * domaine d\'annonce, d\'adjudication, de formalité, ...
     * @type {number}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FournisseurFournisseurJsonldFournisseurRead
 */
export interface FournisseurFournisseurJsonldFournisseurRead {
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    '@type'?: string;
    /**
     * identifiant du fournisseur
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'siret'?: string;
    /**
     * Code fournisseur présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'fournisseurCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'codeFournisseurCegid'?: string;
    /**
     * TVA sur débits / sur encaissements ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'tvaSurDebits'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'tvaIntracommunautaire'?: string;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'clientCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'codeClientCegid'?: string;
    /**
     * 
     * @type {TypeRemiseJsonldFournisseurRead}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'typeRemise'?: TypeRemiseJsonldFournisseurRead;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'typeFactureEmail'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'regieExt'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'origineRegie'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'fournisseurEtranger'?: boolean;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'rrrImport'?: boolean;
    /**
     * 
     * @type {TiersJsonldFournisseurRead}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'tiers'?: TiersJsonldFournisseurRead;
    /**
     * 
     * @type {SocieteJsonldFournisseurRead}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'societe'?: SocieteJsonldFournisseurRead;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'societeReference'?: number;
    /**
     * remise sur facture inclus dans la ligne de PUB ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'rsfInclus'?: boolean;
    /**
     * fournisseur supprimé ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'deleted'?: boolean;
    /**
     * note concernant le fournisseur
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'notes'?: string;
    /**
     * domaine d\'annonce, d\'adjudication, de formalité, ...
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
 */
export interface FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * identifiant du fournisseur
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'siret'?: string;
    /**
     * Code fournisseur présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'fournisseurCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'codeFournisseurCegid'?: string;
    /**
     * TVA sur débits / sur encaissements ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'tvaSurDebits'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'tvaIntracommunautaire'?: string;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'clientCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'codeClientCegid'?: string;
    /**
     * 
     * @type {TypeRemiseJsonldFournisseurReadFournisseurReadTiers}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'typeRemise'?: TypeRemiseJsonldFournisseurReadFournisseurReadTiers;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'typeFactureEmail'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'regieExt'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'origineRegie'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'fournisseurEtranger'?: boolean;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'rrrImport'?: boolean;
    /**
     * 
     * @type {TiersJsonldFournisseurReadFournisseurReadTiers}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'tiers'?: TiersJsonldFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {SocieteJsonldFournisseurReadFournisseurReadTiers}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'societe'?: SocieteJsonldFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'societeReference'?: number;
    /**
     * remise sur facture inclus dans la ligne de PUB ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'rsfInclus'?: boolean;
    /**
     * fournisseur supprimé ?
     * @type {boolean}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'deleted'?: boolean;
    /**
     * note concernant le fournisseur
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'notes'?: string;
    /**
     * domaine d\'annonce, d\'adjudication, de formalité, ...
     * @type {number}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FournisseurJsonldPrescripteurReadFournisseurs
 */
export interface FournisseurJsonldPrescripteurReadFournisseurs {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    '@type'?: string;
    /**
     * identifiant du fournisseur
     * @type {number}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'siret'?: string;
    /**
     * Code fournisseur présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'fournisseurCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'codeFournisseurCegid'?: string;
    /**
     * TVA sur débits / sur encaissements ?
     * @type {boolean}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'tvaSurDebits'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'tvaIntracommunautaire'?: string;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'clientCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'codeClientCegid'?: string;
    /**
     * 
     * @type {TypeRemiseJsonldPrescripteurReadFournisseurs}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'typeRemise'?: TypeRemiseJsonldPrescripteurReadFournisseurs;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'typeFactureEmail'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'regieExt'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'origineRegie'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'fournisseurEtranger'?: boolean;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'rrrImport'?: boolean;
    /**
     * 
     * @type {SocieteJsonldPrescripteurReadFournisseurs}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'societe'?: SocieteJsonldPrescripteurReadFournisseurs;
    /**
     * 
     * @type {number}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'societeReference'?: number;
    /**
     * remise sur facture inclus dans la ligne de PUB ?
     * @type {boolean}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'rsfInclus'?: boolean;
    /**
     * fournisseur supprimé ?
     * @type {boolean}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'deleted'?: boolean;
    /**
     * note concernant le fournisseur
     * @type {string}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'notes'?: string;
    /**
     * domaine d\'annonce, d\'adjudication, de formalité, ...
     * @type {number}
     * @memberof FournisseurJsonldPrescripteurReadFournisseurs
     */
    'ddmId'?: number;
}
/**
 * 
 * @export
 * @interface FournisseurJsonldTiersReadTiersReadExpanded
 */
export interface FournisseurJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FournisseurJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FournisseurJsonldTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FournisseurPrescripteurReadFournisseurs
 */
export interface FournisseurPrescripteurReadFournisseurs {
    /**
     * identifiant du fournisseur
     * @type {number}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'siret'?: string;
    /**
     * Code fournisseur présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'fournisseurCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'codeFournisseurCegid'?: string;
    /**
     * TVA sur débits / sur encaissements ?
     * @type {boolean}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'tvaSurDebits'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'tvaIntracommunautaire'?: string;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'clientCegid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'codeClientCegid'?: string;
    /**
     * 
     * @type {TypeRemisePrescripteurReadFournisseurs}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'typeRemise'?: TypeRemisePrescripteurReadFournisseurs;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'factureEmail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'typeFactureEmail'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'regieExt'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'origineRegie'?: number;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'fournisseurEtranger'?: boolean;
    /**
     * Code client présent sur CEGID ?
     * @type {boolean}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'rrrImport'?: boolean;
    /**
     * 
     * @type {SocietePrescripteurReadFournisseurs}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'societe'?: SocietePrescripteurReadFournisseurs;
    /**
     * 
     * @type {number}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'societeReference'?: number;
    /**
     * remise sur facture inclus dans la ligne de PUB ?
     * @type {boolean}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'rsfInclus'?: boolean;
    /**
     * fournisseur supprimé ?
     * @type {boolean}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'deleted'?: boolean;
    /**
     * note concernant le fournisseur
     * @type {string}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'notes'?: string;
    /**
     * domaine d\'annonce, d\'adjudication, de formalité, ...
     * @type {number}
     * @memberof FournisseurPrescripteurReadFournisseurs
     */
    'ddmId'?: number;
}
/**
 * 
 * @export
 * @interface FournisseurTiersReadTiersReadExpanded
 */
export interface FournisseurTiersReadTiersReadExpanded {
    /**
     * 
     * @type {string}
     * @memberof FournisseurTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FournisseurTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDosStatutJsonldTiersRead
 */
export interface FproDosStatutJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FproDosStatutJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDosStatutJsonldTiersRead
     */
    'fstatId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldTiersRead
     */
    'fstatLib'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDosStatutJsonldTiersReadTiersReadExpanded
 */
export interface FproDosStatutJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FproDosStatutJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDosStatutJsonldTiersReadTiersReadExpanded
     */
    'fstatId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldTiersReadTiersReadExpanded
     */
    'fstatLib'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDosStatutTiersRead
 */
export interface FproDosStatutTiersRead {
    /**
     * 
     * @type {number}
     * @memberof FproDosStatutTiersRead
     */
    'fstatId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutTiersRead
     */
    'fstatLib'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDosStatutTiersReadTiersReadExpanded
 */
export interface FproDosStatutTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof FproDosStatutTiersReadTiersReadExpanded
     */
    'fstatId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutTiersReadTiersReadExpanded
     */
    'fstatLib'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldTiersRead
 */
export interface FproDossierJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FproDossierJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldTiersRead
     */
    'fdosId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldTiersRead
     */
    'fdosNumero'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldTiersRead
     */
    'fdosSteRef'?: string | null;
    /**
     * 
     * @type {FproDosStatutJsonldTiersRead}
     * @memberof FproDossierJsonldTiersRead
     */
    'fstat'?: FproDosStatutJsonldTiersRead;
    /**
     * Get the value of fdos
     * @type {Array<FproPrestationJsonldTiersRead>}
     * @memberof FproDossierJsonldTiersRead
     */
    'fdos'?: Array<FproPrestationJsonldTiersRead>;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldTiersReadTiersReadExpanded
 */
export interface FproDossierJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FproDossierJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldTiersReadTiersReadExpanded
     */
    'fdosId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldTiersReadTiersReadExpanded
     */
    'fdosNumero'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldTiersReadTiersReadExpanded
     */
    'fdosSteRef'?: string | null;
    /**
     * 
     * @type {FproDosStatutJsonldTiersReadTiersReadExpanded}
     * @memberof FproDossierJsonldTiersReadTiersReadExpanded
     */
    'fstat'?: FproDosStatutJsonldTiersReadTiersReadExpanded;
    /**
     * Get the value of fdos
     * @type {Array<FproPrestationJsonldTiersReadTiersReadExpanded>}
     * @memberof FproDossierJsonldTiersReadTiersReadExpanded
     */
    'fdos'?: Array<FproPrestationJsonldTiersReadTiersReadExpanded>;
}
/**
 * 
 * @export
 * @interface FproDossierTiersRead
 */
export interface FproDossierTiersRead {
    /**
     * 
     * @type {number}
     * @memberof FproDossierTiersRead
     */
    'fdosId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierTiersRead
     */
    'fdosNumero'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierTiersRead
     */
    'fdosSteRef'?: string | null;
    /**
     * 
     * @type {FproDosStatutTiersRead}
     * @memberof FproDossierTiersRead
     */
    'fstat'?: FproDosStatutTiersRead;
    /**
     * Get the value of fdos
     * @type {Array<FproPrestationTiersRead>}
     * @memberof FproDossierTiersRead
     */
    'fdos'?: Array<FproPrestationTiersRead>;
}
/**
 * 
 * @export
 * @interface FproDossierTiersReadTiersReadExpanded
 */
export interface FproDossierTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof FproDossierTiersReadTiersReadExpanded
     */
    'fdosId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierTiersReadTiersReadExpanded
     */
    'fdosNumero'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierTiersReadTiersReadExpanded
     */
    'fdosSteRef'?: string | null;
    /**
     * 
     * @type {FproDosStatutTiersReadTiersReadExpanded}
     * @memberof FproDossierTiersReadTiersReadExpanded
     */
    'fstat'?: FproDosStatutTiersReadTiersReadExpanded;
    /**
     * Get the value of fdos
     * @type {Array<FproPrestationTiersReadTiersReadExpanded>}
     * @memberof FproDossierTiersReadTiersReadExpanded
     */
    'fdos'?: Array<FproPrestationTiersReadTiersReadExpanded>;
}
/**
 * 
 * @export
 * @interface FproFormaliteArticleJsonldTiersRead
 */
export interface FproFormaliteArticleJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FproFormaliteArticleJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteArticleJsonldTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldTiersRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface FproFormaliteArticleJsonldTiersReadTiersReadExpanded
 */
export interface FproFormaliteArticleJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FproFormaliteArticleJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteArticleJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldTiersReadTiersReadExpanded
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface FproFormaliteArticleTiersRead
 */
export interface FproFormaliteArticleTiersRead {
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteArticleTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleTiersRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface FproFormaliteArticleTiersReadTiersReadExpanded
 */
export interface FproFormaliteArticleTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteArticleTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleTiersReadTiersReadExpanded
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface FproPrestationJsonldTiersRead
 */
export interface FproPrestationJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FproPrestationJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {FproFormaliteArticleJsonldTiersRead}
     * @memberof FproPrestationJsonldTiersRead
     */
    'formalite'?: FproFormaliteArticleJsonldTiersRead;
}
/**
 * 
 * @export
 * @interface FproPrestationJsonldTiersReadTiersReadExpanded
 */
export interface FproPrestationJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FproPrestationJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproPrestationJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {FproFormaliteArticleJsonldTiersReadTiersReadExpanded}
     * @memberof FproPrestationJsonldTiersReadTiersReadExpanded
     */
    'formalite'?: FproFormaliteArticleJsonldTiersReadTiersReadExpanded;
}
/**
 * 
 * @export
 * @interface FproPrestationTiersRead
 */
export interface FproPrestationTiersRead {
    /**
     * 
     * @type {FproFormaliteArticleTiersRead}
     * @memberof FproPrestationTiersRead
     */
    'formalite'?: FproFormaliteArticleTiersRead;
}
/**
 * 
 * @export
 * @interface FproPrestationTiersReadTiersReadExpanded
 */
export interface FproPrestationTiersReadTiersReadExpanded {
    /**
     * 
     * @type {FproFormaliteArticleTiersReadTiersReadExpanded}
     * @memberof FproPrestationTiersReadTiersReadExpanded
     */
    'formalite'?: FproFormaliteArticleTiersReadTiersReadExpanded;
}
/**
 * 
 * @export
 * @interface FrequenceNotificationJsonldPrescripteurReadNotificationsFrequences
 */
export interface FrequenceNotificationJsonldPrescripteurReadNotificationsFrequences {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof FrequenceNotificationJsonldPrescripteurReadNotificationsFrequences
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof FrequenceNotificationJsonldPrescripteurReadNotificationsFrequences
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrequenceNotificationJsonldPrescripteurReadNotificationsFrequences
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldPrescripteurReadNotificationsFrequences}
     * @memberof FrequenceNotificationJsonldPrescripteurReadNotificationsFrequences
     */
    'societe': SocieteJsonldPrescripteurReadNotificationsFrequences;
    /**
     * 0: Désactivé, 1: Quotidien, 2: Hebdomadaire, 3: Mensuel
     * @type {number}
     * @memberof FrequenceNotificationJsonldPrescripteurReadNotificationsFrequences
     */
    'frequence'?: FrequenceNotificationJsonldPrescripteurReadNotificationsFrequencesFrequenceEnum;
}

export const FrequenceNotificationJsonldPrescripteurReadNotificationsFrequencesFrequenceEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FrequenceNotificationJsonldPrescripteurReadNotificationsFrequencesFrequenceEnum = typeof FrequenceNotificationJsonldPrescripteurReadNotificationsFrequencesFrequenceEnum[keyof typeof FrequenceNotificationJsonldPrescripteurReadNotificationsFrequencesFrequenceEnum];

/**
 * 
 * @export
 * @interface FrequenceNotificationPrescripteurReadNotificationsFrequences
 */
export interface FrequenceNotificationPrescripteurReadNotificationsFrequences {
    /**
     * 
     * @type {SocietePrescripteurReadNotificationsFrequences}
     * @memberof FrequenceNotificationPrescripteurReadNotificationsFrequences
     */
    'societe': SocietePrescripteurReadNotificationsFrequences;
    /**
     * 0: Désactivé, 1: Quotidien, 2: Hebdomadaire, 3: Mensuel
     * @type {number}
     * @memberof FrequenceNotificationPrescripteurReadNotificationsFrequences
     */
    'frequence'?: FrequenceNotificationPrescripteurReadNotificationsFrequencesFrequenceEnum;
}

export const FrequenceNotificationPrescripteurReadNotificationsFrequencesFrequenceEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FrequenceNotificationPrescripteurReadNotificationsFrequencesFrequenceEnum = typeof FrequenceNotificationPrescripteurReadNotificationsFrequencesFrequenceEnum[keyof typeof FrequenceNotificationPrescripteurReadNotificationsFrequencesFrequenceEnum];

/**
 * 
 * @export
 * @interface FrequenceNotificationPrescripteurWriteNotificationsFrequences
 */
export interface FrequenceNotificationPrescripteurWriteNotificationsFrequences {
    /**
     * 
     * @type {SocietePrescripteurWriteNotificationsFrequences}
     * @memberof FrequenceNotificationPrescripteurWriteNotificationsFrequences
     */
    'societe': SocietePrescripteurWriteNotificationsFrequences;
    /**
     * 0: Désactivé, 1: Quotidien, 2: Hebdomadaire, 3: Mensuel
     * @type {number}
     * @memberof FrequenceNotificationPrescripteurWriteNotificationsFrequences
     */
    'frequence'?: FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum;
}

export const FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum = typeof FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum[keyof typeof FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum];

/**
 * 
 * @export
 * @interface GetAdresseAdressesItem401Response
 */
export interface GetAdresseAdressesItem401Response {
    /**
     * 
     * @type {string}
     * @memberof GetAdresseAdressesItem401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetAdresseAdressesItem403Response
 */
export interface GetAdresseAdressesItem403Response {
    /**
     * 
     * @type {string}
     * @memberof GetAdresseAdressesItem403Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetAdresseAdressesItem403ResponseException}
     * @memberof GetAdresseAdressesItem403Response
     */
    'exception'?: GetAdresseAdressesItem403ResponseException;
}
/**
 * 
 * @export
 * @interface GetAdresseAdressesItem403ResponseException
 */
export interface GetAdresseAdressesItem403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetAdresseAdressesItem403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAdresseAdressesItem403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetAdresseAdressesItem404Response
 */
export interface GetAdresseAdressesItem404Response {
    /**
     * 
     * @type {string}
     * @memberof GetAdresseAdressesItem404Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetAdresseAdressesItem404ResponseException}
     * @memberof GetAdresseAdressesItem404Response
     */
    'exception'?: GetAdresseAdressesItem404ResponseException;
}
/**
 * 
 * @export
 * @interface GetAdresseAdressesItem404ResponseException
 */
export interface GetAdresseAdressesItem404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetAdresseAdressesItem404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAdresseAdressesItem404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetCodeClientTiersCollection200Response
 */
export interface GetCodeClientTiersCollection200Response {
    /**
     * 
     * @type {string}
     * @memberof GetCodeClientTiersCollection200Response
     */
    'codeClient'?: string;
}
/**
 * 
 * @export
 * @interface GetCodeClientTiersCollection400Response
 */
export interface GetCodeClientTiersCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof GetCodeClientTiersCollection400Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetCodeClientTiersCollection400ResponseException}
     * @memberof GetCodeClientTiersCollection400Response
     */
    'exception'?: GetCodeClientTiersCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface GetCodeClientTiersCollection400ResponseException
 */
export interface GetCodeClientTiersCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetCodeClientTiersCollection400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCodeClientTiersCollection400ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetCodeClientTiersCollection404Response
 */
export interface GetCodeClientTiersCollection404Response {
    /**
     * 
     * @type {string}
     * @memberof GetCodeClientTiersCollection404Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetCodeClientTiersCollection404ResponseException}
     * @memberof GetCodeClientTiersCollection404Response
     */
    'exception'?: GetCodeClientTiersCollection404ResponseException;
}
/**
 * 
 * @export
 * @interface GetCodeClientTiersCollection404ResponseException
 */
export interface GetCodeClientTiersCollection404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetCodeClientTiersCollection404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCodeClientTiersCollection404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetCodeClientTiersCollectionRequest
 */
export interface GetCodeClientTiersCollectionRequest {
    /**
     * Identifiant de la société du client
     * @type {number}
     * @memberof GetCodeClientTiersCollectionRequest
     */
    'idSociete'?: number;
}
/**
 * 
 * @export
 * @interface GetContactsContactsCollection200Response
 */
export interface GetContactsContactsCollection200Response {
    /**
     * 
     * @type {Array<ContactsContactJsonldContactRead>}
     * @memberof GetContactsContactsCollection200Response
     */
    'hydra:member': Array<ContactsContactJsonldContactRead>;
    /**
     * 
     * @type {number}
     * @memberof GetContactsContactsCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof GetContactsContactsCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof GetContactsContactsCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetListTiersFournisseurCollection200Response
 */
export interface GetListTiersFournisseurCollection200Response {
    /**
     * 
     * @type {Array<FournisseurFournisseurJsonldFournisseurRead>}
     * @memberof GetListTiersFournisseurCollection200Response
     */
    'hydra:member': Array<FournisseurFournisseurJsonldFournisseurRead>;
    /**
     * 
     * @type {number}
     * @memberof GetListTiersFournisseurCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof GetListTiersFournisseurCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof GetListTiersFournisseurCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetListTiersTiersCollection200Response
 */
export interface GetListTiersTiersCollection200Response {
    /**
     * 
     * @type {Array<TiersTiersJsonldTiersReadTiersReadExpanded>}
     * @memberof GetListTiersTiersCollection200Response
     */
    'hydra:member': Array<TiersTiersJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {number}
     * @memberof GetListTiersTiersCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof GetListTiersTiersCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof GetListTiersTiersCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetListeCivilitesTblParametreReferencesCollection200Response
 */
export interface GetListeCivilitesTblParametreReferencesCollection200Response {
    /**
     * 
     * @type {Array<TblParametreReferencesCiviliteJsonldCiviliteReadCollection>}
     * @memberof GetListeCivilitesTblParametreReferencesCollection200Response
     */
    'hydra:member': Array<TblParametreReferencesCiviliteJsonldCiviliteReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof GetListeCivilitesTblParametreReferencesCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof GetListeCivilitesTblParametreReferencesCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof GetListeCivilitesTblParametreReferencesCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetListeCspCategorieSocioProfessionnelleCollection200Response
 */
export interface GetListeCspCategorieSocioProfessionnelleCollection200Response {
    /**
     * 
     * @type {Array<CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead>}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200Response
     */
    'hydra:member': Array<CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead>;
    /**
     * 
     * @type {number}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch
 */
export interface GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearchHydraMappingInner
 */
export interface GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView
 */
export interface GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface GetListeCspCategorieSocioProfessionnelleCollection400Response
 */
export interface GetListeCspCategorieSocioProfessionnelleCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection400Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection400ResponseException}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection400Response
     */
    'exception'?: GetListeCspCategorieSocioProfessionnelleCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface GetListeCspCategorieSocioProfessionnelleCollection400ResponseException
 */
export interface GetListeCspCategorieSocioProfessionnelleCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetListeCspCategorieSocioProfessionnelleCollection400ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetListeModePaiementModePaiementCollection200Response
 */
export interface GetListeModePaiementModePaiementCollection200Response {
    /**
     * 
     * @type {Array<ModePaiementModePaiementJsonldModePaiementRead>}
     * @memberof GetListeModePaiementModePaiementCollection200Response
     */
    'hydra:member': Array<ModePaiementModePaiementJsonldModePaiementRead>;
    /**
     * 
     * @type {number}
     * @memberof GetListeModePaiementModePaiementCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof GetListeModePaiementModePaiementCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof GetListeModePaiementModePaiementCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetListePaysTblPaysCollection200Response
 */
export interface GetListePaysTblPaysCollection200Response {
    /**
     * 
     * @type {Array<TblPaysJsonldPaysRead>}
     * @memberof GetListePaysTblPaysCollection200Response
     */
    'hydra:member': Array<TblPaysJsonldPaysRead>;
    /**
     * 
     * @type {number}
     * @memberof GetListePaysTblPaysCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof GetListePaysTblPaysCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof GetListePaysTblPaysCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetListePortefeuilleCategorieSocioProfessionnelleCollection200Response
 */
export interface GetListePortefeuilleCategorieSocioProfessionnelleCollection200Response {
    /**
     * 
     * @type {Array<CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead>}
     * @memberof GetListePortefeuilleCategorieSocioProfessionnelleCollection200Response
     */
    'hydra:member': Array<CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead>;
    /**
     * 
     * @type {number}
     * @memberof GetListePortefeuilleCategorieSocioProfessionnelleCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof GetListePortefeuilleCategorieSocioProfessionnelleCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof GetListePortefeuilleCategorieSocioProfessionnelleCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetLogoSpecificationsTblParametreReferencesCollection200Response
 */
export interface GetLogoSpecificationsTblParametreReferencesCollection200Response {
    /**
     * 
     * @type {Array<TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection>}
     * @memberof GetLogoSpecificationsTblParametreReferencesCollection200Response
     */
    'hydra:member': Array<TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof GetLogoSpecificationsTblParametreReferencesCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof GetLogoSpecificationsTblParametreReferencesCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof GetLogoSpecificationsTblParametreReferencesCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetRegimeTvaTiersCollection200Response
 */
export interface GetRegimeTvaTiersCollection200Response {
    /**
     * 
     * @type {string}
     * @memberof GetRegimeTvaTiersCollection200Response
     */
    'cegidRegimeTva'?: string;
}
/**
 * 
 * @export
 * @interface GetRegimeTvaTiersCollection400Response
 */
export interface GetRegimeTvaTiersCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof GetRegimeTvaTiersCollection400Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetRegimeTvaTiersCollection400ResponseException}
     * @memberof GetRegimeTvaTiersCollection400Response
     */
    'exception'?: GetRegimeTvaTiersCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface GetRegimeTvaTiersCollection400ResponseException
 */
export interface GetRegimeTvaTiersCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetRegimeTvaTiersCollection400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRegimeTvaTiersCollection400ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetRegimeTvaTiersCollection404Response
 */
export interface GetRegimeTvaTiersCollection404Response {
    /**
     * 
     * @type {string}
     * @memberof GetRegimeTvaTiersCollection404Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetRegimeTvaTiersCollection404ResponseException}
     * @memberof GetRegimeTvaTiersCollection404Response
     */
    'exception'?: GetRegimeTvaTiersCollection404ResponseException;
}
/**
 * 
 * @export
 * @interface GetRegimeTvaTiersCollection404ResponseException
 */
export interface GetRegimeTvaTiersCollection404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetRegimeTvaTiersCollection404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRegimeTvaTiersCollection404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetRegimeTvaTiersCollectionRequest
 */
export interface GetRegimeTvaTiersCollectionRequest {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof GetRegimeTvaTiersCollectionRequest
     */
    'idTiers'?: number;
    /**
     * le code insee de la commune du tiers
     * @type {string}
     * @memberof GetRegimeTvaTiersCollectionRequest
     */
    'codeInsee'?: string;
    /**
     * le code postal de la commune du tiers
     * @type {string}
     * @memberof GetRegimeTvaTiersCollectionRequest
     */
    'codePostal'?: string;
    /**
     * le nom de la commune du tiers
     * @type {string}
     * @memberof GetRegimeTvaTiersCollectionRequest
     */
    'ville'?: string;
}
/**
 * 
 * @export
 * @interface MobileContactCreate
 */
export interface MobileContactCreate {
    /**
     * 
     * @type {string}
     * @memberof MobileContactCreate
     */
    'mobile': string;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileContactCreate
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface MobileContactRead
 */
export interface MobileContactRead {
    /**
     * 
     * @type {string}
     * @memberof MobileContactRead
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileContactRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileContactRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MobileContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileContactRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface MobileContactWriteMobilesPrescripteur
 */
export interface MobileContactWriteMobilesPrescripteur {
    /**
     * 
     * @type {string}
     * @memberof MobileContactWriteMobilesPrescripteur
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileContactWriteMobilesPrescripteur
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileContactWriteMobilesPrescripteur
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface MobileContactWriteMobilesSousclient
 */
export interface MobileContactWriteMobilesSousclient {
    /**
     * 
     * @type {string}
     * @memberof MobileContactWriteMobilesSousclient
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileContactWriteMobilesSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileContactWriteMobilesSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface MobileFournisseurReadFournisseurReadTiers
 */
export interface MobileFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {string}
     * @memberof MobileFournisseurReadFournisseurReadTiers
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MobileFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface MobileJsonldContactCreate
 */
export interface MobileJsonldContactCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof MobileJsonldContactCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactCreate
     */
    'mobile': string;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileJsonldContactCreate
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface MobileJsonldContactRead
 */
export interface MobileJsonldContactRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof MobileJsonldContactRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactRead
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileJsonldContactRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileJsonldContactRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface MobileJsonldContactWriteMobilesPrescripteur
 */
export interface MobileJsonldContactWriteMobilesPrescripteur {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof MobileJsonldContactWriteMobilesPrescripteur
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactWriteMobilesPrescripteur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactWriteMobilesPrescripteur
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactWriteMobilesPrescripteur
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileJsonldContactWriteMobilesPrescripteur
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileJsonldContactWriteMobilesPrescripteur
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface MobileJsonldContactWriteMobilesSousclient
 */
export interface MobileJsonldContactWriteMobilesSousclient {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof MobileJsonldContactWriteMobilesSousclient
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactWriteMobilesSousclient
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactWriteMobilesSousclient
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldContactWriteMobilesSousclient
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileJsonldContactWriteMobilesSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileJsonldContactWriteMobilesSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface MobileJsonldFournisseurReadFournisseurReadTiers
 */
export interface MobileJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof MobileJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldFournisseurReadFournisseurReadTiers
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileJsonldFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface MobileJsonldPrescripteurCreate
 */
export interface MobileJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof MobileJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldPrescripteurCreate
     */
    'mobile': string;
}
/**
 * 
 * @export
 * @interface MobileJsonldSousclientCreate
 */
export interface MobileJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof MobileJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldSousclientCreate
     */
    'mobile': string;
}
/**
 * 
 * @export
 * @interface MobileJsonldTiersRead
 */
export interface MobileJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof MobileJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersRead
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileJsonldTiersRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileJsonldTiersRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface MobileJsonldTiersReadTiersReadExpanded
 */
export interface MobileJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof MobileJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersReadTiersReadExpanded
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileJsonldTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileJsonldTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface MobilePrescripteurCreate
 */
export interface MobilePrescripteurCreate {
    /**
     * 
     * @type {string}
     * @memberof MobilePrescripteurCreate
     */
    'mobile': string;
}
/**
 * 
 * @export
 * @interface MobileSousclientCreate
 */
export interface MobileSousclientCreate {
    /**
     * 
     * @type {string}
     * @memberof MobileSousclientCreate
     */
    'mobile': string;
}
/**
 * 
 * @export
 * @interface MobileSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
 */
export interface MobileSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient {
    /**
     * 
     * @type {string}
     * @memberof MobileSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface MobileTiersRead
 */
export interface MobileTiersRead {
    /**
     * 
     * @type {string}
     * @memberof MobileTiersRead
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileTiersRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileTiersRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MobileTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface MobileTiersReadTiersReadExpanded
 */
export interface MobileTiersReadTiersReadExpanded {
    /**
     * 
     * @type {string}
     * @memberof MobileTiersReadTiersReadExpanded
     */
    'mobile': string;
    /**
     * 
     * @type {number}
     * @memberof MobileTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof MobileTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MobileTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface ModePaiementJsonldPrescripteurWriteRelationSociete
 */
export interface ModePaiementJsonldPrescripteurWriteRelationSociete {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ModePaiementJsonldPrescripteurWriteRelationSociete
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldPrescripteurWriteRelationSociete
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldPrescripteurWriteRelationSociete
     */
    '@type'?: string;
    /**
     * identifiant du mode de paiement
     * @type {number}
     * @memberof ModePaiementJsonldPrescripteurWriteRelationSociete
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ModePaiementJsonldTiersRead
 */
export interface ModePaiementJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ModePaiementJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldTiersRead
     */
    '@type'?: string;
    /**
     * identifiant du mode de paiement
     * @type {number}
     * @memberof ModePaiementJsonldTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldTiersRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldTiersRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ModePaiementJsonldTiersReadTiersReadExpanded
 */
export interface ModePaiementJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ModePaiementJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * identifiant du mode de paiement
     * @type {number}
     * @memberof ModePaiementJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldTiersReadTiersReadExpanded
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementJsonldTiersReadTiersReadExpanded
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ModePaiementModePaiementJsonldModePaiementRead
 */
export interface ModePaiementModePaiementJsonldModePaiementRead {
    /**
     * 
     * @type {string}
     * @memberof ModePaiementModePaiementJsonldModePaiementRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementModePaiementJsonldModePaiementRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ModePaiementModePaiementJsonldModePaiementRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * identifiant du mode de paiement
     * @type {number}
     * @memberof ModePaiementModePaiementJsonldModePaiementRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementModePaiementJsonldModePaiementRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementModePaiementJsonldModePaiementRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ModePaiementModePaiementModePaiementRead
 */
export interface ModePaiementModePaiementModePaiementRead {
    /**
     * identifiant du mode de paiement
     * @type {number}
     * @memberof ModePaiementModePaiementModePaiementRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementModePaiementModePaiementRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementModePaiementModePaiementRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ModePaiementPrescripteurWriteRelationSociete
 */
export interface ModePaiementPrescripteurWriteRelationSociete {
    /**
     * identifiant du mode de paiement
     * @type {number}
     * @memberof ModePaiementPrescripteurWriteRelationSociete
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ModePaiementTiersRead
 */
export interface ModePaiementTiersRead {
    /**
     * identifiant du mode de paiement
     * @type {number}
     * @memberof ModePaiementTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementTiersRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementTiersRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ModePaiementTiersReadTiersReadExpanded
 */
export interface ModePaiementTiersReadTiersReadExpanded {
    /**
     * identifiant du mode de paiement
     * @type {number}
     * @memberof ModePaiementTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementTiersReadTiersReadExpanded
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModePaiementTiersReadTiersReadExpanded
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ObjectGroupJsonldObjectgroupCreate
 */
export interface ObjectGroupJsonldObjectgroupCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ObjectGroupJsonldObjectgroupCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupCreate
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupJsonldObjectgroupCreate
     */
    'id'?: number;
    /**
     * Get value of type
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupCreate
     */
    'type'?: string;
    /**
     * Get value of the label
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupCreate
     */
    'label'?: string;
    /**
     * Get the value of color
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupCreate
     */
    'color'?: string | null;
    /**
     * Get the value of iconName
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupCreate
     */
    'iconName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectGroupJsonldObjectgroupCreate
     */
    'tiers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ObjectGroupJsonldObjectgroupRead
 */
export interface ObjectGroupJsonldObjectgroupRead {
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ObjectGroupJsonldObjectgroupRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupJsonldObjectgroupRead
     */
    'id'?: number;
    /**
     * Get value of type
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupRead
     */
    'type'?: string;
    /**
     * Get value of the label
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupRead
     */
    'label'?: string;
    /**
     * Get the value of color
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupRead
     */
    'color'?: string | null;
    /**
     * Get the value of iconName
     * @type {string}
     * @memberof ObjectGroupJsonldObjectgroupRead
     */
    'iconName'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectGroupJsonldTiersEditObjectGroup
 */
export interface ObjectGroupJsonldTiersEditObjectGroup {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ObjectGroupJsonldTiersEditObjectGroup
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldTiersEditObjectGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldTiersEditObjectGroup
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupJsonldTiersEditObjectGroup
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ObjectGroupJsonldTiersRead
 */
export interface ObjectGroupJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ObjectGroupJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldTiersRead
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupJsonldTiersRead
     */
    'id'?: number;
    /**
     * Get value of type
     * @type {string}
     * @memberof ObjectGroupJsonldTiersRead
     */
    'type'?: string;
    /**
     * Get value of the label
     * @type {string}
     * @memberof ObjectGroupJsonldTiersRead
     */
    'label'?: string;
    /**
     * Get the value of color
     * @type {string}
     * @memberof ObjectGroupJsonldTiersRead
     */
    'color'?: string | null;
    /**
     * Get the value of iconName
     * @type {string}
     * @memberof ObjectGroupJsonldTiersRead
     */
    'iconName'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectGroupJsonldTiersReadTiersReadExpanded
 */
export interface ObjectGroupJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof ObjectGroupJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGroupJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * Get value of type
     * @type {string}
     * @memberof ObjectGroupJsonldTiersReadTiersReadExpanded
     */
    'type'?: string;
    /**
     * Get value of the label
     * @type {string}
     * @memberof ObjectGroupJsonldTiersReadTiersReadExpanded
     */
    'label'?: string;
    /**
     * Get the value of color
     * @type {string}
     * @memberof ObjectGroupJsonldTiersReadTiersReadExpanded
     */
    'color'?: string | null;
    /**
     * Get the value of iconName
     * @type {string}
     * @memberof ObjectGroupJsonldTiersReadTiersReadExpanded
     */
    'iconName'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectGroupObjectgroupCreate
 */
export interface ObjectGroupObjectgroupCreate {
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupObjectgroupCreate
     */
    'id'?: number;
    /**
     * Get value of type
     * @type {string}
     * @memberof ObjectGroupObjectgroupCreate
     */
    'type'?: string;
    /**
     * Get value of the label
     * @type {string}
     * @memberof ObjectGroupObjectgroupCreate
     */
    'label'?: string;
    /**
     * Get the value of color
     * @type {string}
     * @memberof ObjectGroupObjectgroupCreate
     */
    'color'?: string | null;
    /**
     * Get the value of iconName
     * @type {string}
     * @memberof ObjectGroupObjectgroupCreate
     */
    'iconName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectGroupObjectgroupCreate
     */
    'tiers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ObjectGroupObjectgroupRead
 */
export interface ObjectGroupObjectgroupRead {
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupObjectgroupRead
     */
    'id'?: number;
    /**
     * Get value of type
     * @type {string}
     * @memberof ObjectGroupObjectgroupRead
     */
    'type'?: string;
    /**
     * Get value of the label
     * @type {string}
     * @memberof ObjectGroupObjectgroupRead
     */
    'label'?: string;
    /**
     * Get the value of color
     * @type {string}
     * @memberof ObjectGroupObjectgroupRead
     */
    'color'?: string | null;
    /**
     * Get the value of iconName
     * @type {string}
     * @memberof ObjectGroupObjectgroupRead
     */
    'iconName'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectGroupTiersEditObjectGroup
 */
export interface ObjectGroupTiersEditObjectGroup {
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupTiersEditObjectGroup
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ObjectGroupTiersRead
 */
export interface ObjectGroupTiersRead {
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupTiersRead
     */
    'id'?: number;
    /**
     * Get value of type
     * @type {string}
     * @memberof ObjectGroupTiersRead
     */
    'type'?: string;
    /**
     * Get value of the label
     * @type {string}
     * @memberof ObjectGroupTiersRead
     */
    'label'?: string;
    /**
     * Get the value of color
     * @type {string}
     * @memberof ObjectGroupTiersRead
     */
    'color'?: string | null;
    /**
     * Get the value of iconName
     * @type {string}
     * @memberof ObjectGroupTiersRead
     */
    'iconName'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectGroupTiersReadTiersReadExpanded
 */
export interface ObjectGroupTiersReadTiersReadExpanded {
    /**
     * Get the value of id
     * @type {number}
     * @memberof ObjectGroupTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * Get value of type
     * @type {string}
     * @memberof ObjectGroupTiersReadTiersReadExpanded
     */
    'type'?: string;
    /**
     * Get value of the label
     * @type {string}
     * @memberof ObjectGroupTiersReadTiersReadExpanded
     */
    'label'?: string;
    /**
     * Get the value of color
     * @type {string}
     * @memberof ObjectGroupTiersReadTiersReadExpanded
     */
    'color'?: string | null;
    /**
     * Get the value of iconName
     * @type {string}
     * @memberof ObjectGroupTiersReadTiersReadExpanded
     */
    'iconName'?: string | null;
}
/**
 * 
 * @export
 * @interface OpensearchIndexTiersByIdsTiersCollection200Response
 */
export interface OpensearchIndexTiersByIdsTiersCollection200Response {
    /**
     * OK/KO résultat de la tentative d\'indexation du tiers
     * @type {string}
     * @memberof OpensearchIndexTiersByIdsTiersCollection200Response
     */
    '4'?: string;
    /**
     * OK/KO résultat de la tentative d\'indexation du tiers
     * @type {string}
     * @memberof OpensearchIndexTiersByIdsTiersCollection200Response
     */
    '14'?: string;
    /**
     * OK/KO résultat de la tentative d\'indexation du tiers
     * @type {string}
     * @memberof OpensearchIndexTiersByIdsTiersCollection200Response
     */
    '1114'?: string;
    /**
     * OK/KO résultat de la tentative d\'indexation du tiers
     * @type {string}
     * @memberof OpensearchIndexTiersByIdsTiersCollection200Response
     */
    '3114'?: string;
}
/**
 * 
 * @export
 * @interface OpensearchIndexTiersByIdsTiersCollection400Response
 */
export interface OpensearchIndexTiersByIdsTiersCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof OpensearchIndexTiersByIdsTiersCollection400Response
     */
    'error'?: string;
    /**
     * 
     * @type {OpensearchIndexTiersByIdsTiersCollection400ResponseException}
     * @memberof OpensearchIndexTiersByIdsTiersCollection400Response
     */
    'exception'?: OpensearchIndexTiersByIdsTiersCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface OpensearchIndexTiersByIdsTiersCollection400ResponseException
 */
export interface OpensearchIndexTiersByIdsTiersCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof OpensearchIndexTiersByIdsTiersCollection400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof OpensearchIndexTiersByIdsTiersCollection400ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface OpensearchIndexTiersByIdsTiersCollectionRequest
 */
export interface OpensearchIndexTiersByIdsTiersCollectionRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof OpensearchIndexTiersByIdsTiersCollectionRequest
     */
    'ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface OrigineFournisseurReadFournisseurReadTiers
 */
export interface OrigineFournisseurReadFournisseurReadTiers {
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OrigineFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * Designation du site d\'origine
     * @type {string}
     * @memberof OrigineFournisseurReadFournisseurReadTiers
     */
    'designation'?: string;
}
/**
 * 
 * @export
 * @interface OrigineJsonldFournisseurReadFournisseurReadTiers
 */
export interface OrigineJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof OrigineJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OrigineJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * Designation du site d\'origine
     * @type {string}
     * @memberof OrigineJsonldFournisseurReadFournisseurReadTiers
     */
    'designation'?: string;
}
/**
 * 
 * @export
 * @interface OrigineJsonldPrescripteurCreate
 */
export interface OrigineJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof OrigineJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OrigineJsonldPrescripteurCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface OrigineJsonldSousclientCreate
 */
export interface OrigineJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof OrigineJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OrigineJsonldSousclientCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface OrigineJsonldTiersRead
 */
export interface OrigineJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof OrigineJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldTiersRead
     */
    '@type'?: string;
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OrigineJsonldTiersRead
     */
    'id'?: number;
    /**
     * Designation du site d\'origine
     * @type {string}
     * @memberof OrigineJsonldTiersRead
     */
    'designation'?: string;
}
/**
 * 
 * @export
 * @interface OrigineJsonldTiersReadTiersReadExpanded
 */
export interface OrigineJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof OrigineJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrigineJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OrigineJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * Designation du site d\'origine
     * @type {string}
     * @memberof OrigineJsonldTiersReadTiersReadExpanded
     */
    'designation'?: string;
}
/**
 * 
 * @export
 * @interface OriginePrescripteurCreate
 */
export interface OriginePrescripteurCreate {
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OriginePrescripteurCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface OrigineSousclientCreate
 */
export interface OrigineSousclientCreate {
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OrigineSousclientCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface OrigineTiersRead
 */
export interface OrigineTiersRead {
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OrigineTiersRead
     */
    'id'?: number;
    /**
     * Designation du site d\'origine
     * @type {string}
     * @memberof OrigineTiersRead
     */
    'designation'?: string;
}
/**
 * 
 * @export
 * @interface OrigineTiersReadTiersReadExpanded
 */
export interface OrigineTiersReadTiersReadExpanded {
    /**
     * Identifiant du site d\'origine
     * @type {number}
     * @memberof OrigineTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * Designation du site d\'origine
     * @type {string}
     * @memberof OrigineTiersReadTiersReadExpanded
     */
    'designation'?: string;
}
/**
 * 
 * @export
 * @interface PortefeuilleJsonldPrescripteurCreate
 */
export interface PortefeuilleJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof PortefeuilleJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PortefeuilleJsonldPrescripteurCreate
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PortefeuilleJsonldPrescripteurWriteRelationSociete
 */
export interface PortefeuilleJsonldPrescripteurWriteRelationSociete {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof PortefeuilleJsonldPrescripteurWriteRelationSociete
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldPrescripteurWriteRelationSociete
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldPrescripteurWriteRelationSociete
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PortefeuilleJsonldPrescripteurWriteRelationSociete
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PortefeuilleJsonldTiersRead
 */
export interface PortefeuilleJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof PortefeuilleJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PortefeuilleJsonldTiersRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldTiersRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldTiersRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface PortefeuilleJsonldTiersReadTiersReadExpanded
 */
export interface PortefeuilleJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof PortefeuilleJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PortefeuilleJsonldTiersReadTiersReadExpanded
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldTiersReadTiersReadExpanded
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleJsonldTiersReadTiersReadExpanded
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface PortefeuillePrescripteurCreate
 */
export interface PortefeuillePrescripteurCreate {
    /**
     * 
     * @type {number}
     * @memberof PortefeuillePrescripteurCreate
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PortefeuillePrescripteurWriteRelationSociete
 */
export interface PortefeuillePrescripteurWriteRelationSociete {
    /**
     * 
     * @type {number}
     * @memberof PortefeuillePrescripteurWriteRelationSociete
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PortefeuilleTiersRead
 */
export interface PortefeuilleTiersRead {
    /**
     * 
     * @type {number}
     * @memberof PortefeuilleTiersRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleTiersRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleTiersRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface PortefeuilleTiersReadTiersReadExpanded
 */
export interface PortefeuilleTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof PortefeuilleTiersReadTiersReadExpanded
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleTiersReadTiersReadExpanded
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortefeuilleTiersReadTiersReadExpanded
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface PreferencesNotificationJsonldPrescripteurReadNotificationsEmails
 */
export interface PreferencesNotificationJsonldPrescripteurReadNotificationsEmails {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof PreferencesNotificationJsonldPrescripteurReadNotificationsEmails
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof PreferencesNotificationJsonldPrescripteurReadNotificationsEmails
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreferencesNotificationJsonldPrescripteurReadNotificationsEmails
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<EmailJsonldPrescripteurReadNotificationsEmails>}
     * @memberof PreferencesNotificationJsonldPrescripteurReadNotificationsEmails
     */
    'emails': Array<EmailJsonldPrescripteurReadNotificationsEmails>;
}
/**
 * 
 * @export
 * @interface PreferencesNotificationJsonldPrescripteurReadNotificationsFrequences
 */
export interface PreferencesNotificationJsonldPrescripteurReadNotificationsFrequences {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof PreferencesNotificationJsonldPrescripteurReadNotificationsFrequences
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof PreferencesNotificationJsonldPrescripteurReadNotificationsFrequences
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreferencesNotificationJsonldPrescripteurReadNotificationsFrequences
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<FrequenceNotificationJsonldPrescripteurReadNotificationsFrequences>}
     * @memberof PreferencesNotificationJsonldPrescripteurReadNotificationsFrequences
     */
    'frequences': Array<FrequenceNotificationJsonldPrescripteurReadNotificationsFrequences>;
}
/**
 * 
 * @export
 * @interface PreferencesNotificationPrescripteurReadNotificationsEmails
 */
export interface PreferencesNotificationPrescripteurReadNotificationsEmails {
    /**
     * 
     * @type {Array<EmailPrescripteurReadNotificationsEmails>}
     * @memberof PreferencesNotificationPrescripteurReadNotificationsEmails
     */
    'emails': Array<EmailPrescripteurReadNotificationsEmails>;
}
/**
 * 
 * @export
 * @interface PreferencesNotificationPrescripteurReadNotificationsFrequences
 */
export interface PreferencesNotificationPrescripteurReadNotificationsFrequences {
    /**
     * 
     * @type {Array<FrequenceNotificationPrescripteurReadNotificationsFrequences>}
     * @memberof PreferencesNotificationPrescripteurReadNotificationsFrequences
     */
    'frequences': Array<FrequenceNotificationPrescripteurReadNotificationsFrequences>;
}
/**
 * 
 * @export
 * @interface PreferencesNotificationPrescripteurWriteNotificationsEmails
 */
export interface PreferencesNotificationPrescripteurWriteNotificationsEmails {
    /**
     * 
     * @type {Array<EmailPrescripteurWriteNotificationsEmails>}
     * @memberof PreferencesNotificationPrescripteurWriteNotificationsEmails
     */
    'emails': Array<EmailPrescripteurWriteNotificationsEmails>;
}
/**
 * 
 * @export
 * @interface PreferencesNotificationPrescripteurWriteNotificationsFrequences
 */
export interface PreferencesNotificationPrescripteurWriteNotificationsFrequences {
    /**
     * 
     * @type {Array<FrequenceNotificationPrescripteurWriteNotificationsFrequences>}
     * @memberof PreferencesNotificationPrescripteurWriteNotificationsFrequences
     */
    'frequences': Array<FrequenceNotificationPrescripteurWriteNotificationsFrequences>;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiers
 */
export interface RelationSocieteTiers {
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tiers'?: string;
    /**
     * 
     * @type {TblSociete}
     * @memberof RelationSocieteTiers
     */
    'societe'?: TblSociete;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'codeClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'referenceClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'compteClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'compteGaleClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'codeFournisseur'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'referenceFournisseur'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'compteFournisseur'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'compteGaleFournisseur'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'idEtat'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'dateContratClient'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'contratClient'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'fichierContratClient'?: string;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'remiseClient'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'typeRemise'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'info'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'limiteEncours'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'devisAttestationFactureCorrespondance'?: string;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'idCommercial'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'commercial'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'clientBloque'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'motifBlocage'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'dateBlocage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'distributeur'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'clientDivers'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'prescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'remiseGp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'remiseHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'aSecurisee'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'remiseFormalite'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'justifGratuiGp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'justifGratuitHg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'remiseVisibleGp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'remiseVisibleHGg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'rectificatifGratuitGp'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'releve'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'justificatif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'accompagnement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'nombreJustificatif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'avoirFactureG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'avoirFacureHg'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'portefeuille'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'nomCLientCegid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'vacationHg'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'codeClientCegid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'totem'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'deleted'?: number | null;
    /**
     * 
     * @type {TblUtilisateur}
     * @memberof RelationSocieteTiers
     */
    'userCreation'?: TblUtilisateur | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {TblUtilisateur}
     * @memberof RelationSocieteTiers
     */
    'userMAJ'?: TblUtilisateur | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'livraison'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'pasContactFacture'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'axe1'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'justificatifPdfRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'clientSuivi'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'adresserFactureAuClient'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'typeReglementRrr'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'frequenceRrr'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'frequenceReleveFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'justificatifPdf'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'justificatifPapier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'idTypeJustificatifPapier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'attestationMultiDepartement'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'allowZeroJustificatif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'bloquerPublicationBodacc'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'masquerEnteteTexteSeule'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'codeRegroupement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'afficherCodeRegroupement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'masquerInfosFactureLivraison'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'masquerInfosReglement'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'texteRemplacementInfosReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'masquerFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'devisJalNonAutorise'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'pdfBodacc'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'vacationBodaccHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'emailReleves'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'cegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'codePortefeuille'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'codeEleveur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'codeCategorieSocioprofessionelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'typeClient'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'statClient'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'tiersGroupe2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'parrain'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'formaliteForfaitisee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'coefRefractionHonoraireFormalite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'formaliteAcompte'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'formaliteMontantAcompte'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'idSocieteCliente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'indiceDomicilie'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'conditionCoValidee'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'delegationPaiement'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'qualitePaiement'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'annonceXY'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'traitementRrManuel'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'mailAutoAttestationApAppro'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'mailAutoAttestationApG3'?: boolean | null;
    /**
     * 
     * @type {TblOrigine}
     * @memberof RelationSocieteTiers
     */
    'origine'?: TblOrigine | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'parrainageRemiseGp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'parrainageRemiseHG'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'userNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'tiersNonFiable'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'infoEmailNonFiable'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'relanceEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'comparateurPrix'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'factureCorrespondanceClient'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'formaliteFactureType'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'qualifCondCommerciales'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'communicationNonRelancable'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'userRecouvrementSuivi'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'communicationRecouvrementSuivi'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'releveEncoursClients'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'relanceEmailDonneurOrdreIndirect'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'fproDossierFtp'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'fproFtpLogin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'fproFtpPassword'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'tauxRemiseAvisNationnaux'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'parrainageVacations'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'expeditionFacturePapier'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'ancienCodeClientCegid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'spelPrefere'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'categorieSocioProfessionelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'spelModerationAvantWeb'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'justifSpelGpGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'justifSpelHgGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'paiementDematerialise'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxRrrIg1Ligne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxRrrIg2Ligne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxRrrHgLigne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxRrrIg1Forfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxRrrIg2Forfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxRrrHgForfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxAvoIg1Ligne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxAvoIg2Ligne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxAvoHgLigne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxAvoIg1Forfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxAvoIg2Forfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'tauxAvoHgForfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'coefForfaitLogoIg1'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'coefForfaitLogoIg2'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'coefForfaitlogoHg'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'fraisTechInterdit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'stopControleForfait'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'sousClientNonRelancable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'signatureElectronique'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'approDocumentsParMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'attestationsCertifiees'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'paiementEnLigneLiquidation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'fraisTechLogoInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'idDefinitionTypeRRR'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'excluCadeauFinAnnee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'attestationPerso'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'logoAttestation'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'libAttestationPerso'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'forfaitPcColNI'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'montantForfaitPcColNI'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'rectifGratuitIg1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'rectifGratuitIg2'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'rectifGratuitHg'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'frequenceRappelsFormalites'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'blocageDnid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'idMra'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'modePaiement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'partenaireFormalite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'factureSimplifiee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'logoFormalite'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'plateformeB2BAutorise'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'devisDetaille'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'fClearDoc'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiers
     */
    'clearDocDelai'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'fClearDocMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiers
     */
    'clearDocMail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiers
     */
    'fproDossierFTP'?: boolean;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersFournisseurReadFournisseurReadTiers
 */
export interface RelationSocieteTiersFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {SocieteFournisseurReadFournisseurReadTiers}
     * @memberof RelationSocieteTiersFournisseurReadFournisseurReadTiers
     */
    'societe'?: SocieteFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersFournisseurReadFournisseurReadTiers
     */
    'raisonSociale'?: string;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersJsonld
 */
export interface RelationSocieteTiersJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof RelationSocieteTiersJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tiers'?: string;
    /**
     * 
     * @type {TblSocieteJsonld}
     * @memberof RelationSocieteTiersJsonld
     */
    'societe'?: TblSocieteJsonld;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'codeClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'referenceClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'compteClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'compteGaleClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'codeFournisseur'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'referenceFournisseur'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'compteFournisseur'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'compteGaleFournisseur'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'idEtat'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'dateContratClient'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'contratClient'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'fichierContratClient'?: string;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'remiseClient'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'typeRemise'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'info'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'limiteEncours'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'devisAttestationFactureCorrespondance'?: string;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'idCommercial'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'commercial'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'clientBloque'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'motifBlocage'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'dateBlocage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'distributeur'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'clientDivers'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'prescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'remiseGp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'remiseHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'aSecurisee'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'remiseFormalite'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'justifGratuiGp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'justifGratuitHg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'remiseVisibleGp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'remiseVisibleHGg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'rectificatifGratuitGp'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'releve'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'justificatif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'accompagnement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'nombreJustificatif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'avoirFactureG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'avoirFacureHg'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'portefeuille'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'nomCLientCegid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'vacationHg'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'codeClientCegid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'totem'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'deleted'?: number | null;
    /**
     * 
     * @type {TblUtilisateurJsonld}
     * @memberof RelationSocieteTiersJsonld
     */
    'userCreation'?: TblUtilisateurJsonld | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {TblUtilisateurJsonld}
     * @memberof RelationSocieteTiersJsonld
     */
    'userMAJ'?: TblUtilisateurJsonld | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'livraison'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'pasContactFacture'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'axe1'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'justificatifPdfRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'clientSuivi'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'adresserFactureAuClient'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'typeReglementRrr'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'frequenceRrr'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'frequenceReleveFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'justificatifPdf'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'justificatifPapier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'idTypeJustificatifPapier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'attestationMultiDepartement'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'allowZeroJustificatif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'bloquerPublicationBodacc'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'masquerEnteteTexteSeule'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'codeRegroupement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'afficherCodeRegroupement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'masquerInfosFactureLivraison'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'masquerInfosReglement'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'texteRemplacementInfosReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'masquerFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'devisJalNonAutorise'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'pdfBodacc'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'vacationBodaccHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'emailReleves'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'cegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'codePortefeuille'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'codeEleveur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'codeCategorieSocioprofessionelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'typeClient'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'statClient'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'tiersGroupe2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'parrain'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'formaliteForfaitisee'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'coefRefractionHonoraireFormalite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'formaliteAcompte'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'formaliteMontantAcompte'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'idSocieteCliente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'indiceDomicilie'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'conditionCoValidee'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'delegationPaiement'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'qualitePaiement'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'annonceXY'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'traitementRrManuel'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'mailAutoAttestationApAppro'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'mailAutoAttestationApG3'?: boolean | null;
    /**
     * 
     * @type {TblOrigineJsonld}
     * @memberof RelationSocieteTiersJsonld
     */
    'origine'?: TblOrigineJsonld | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'parrainageRemiseGp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'parrainageRemiseHG'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'userNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'tiersNonFiable'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'infoEmailNonFiable'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'relanceEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'comparateurPrix'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'factureCorrespondanceClient'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'formaliteFactureType'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'qualifCondCommerciales'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'communicationNonRelancable'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'userRecouvrementSuivi'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'communicationRecouvrementSuivi'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'releveEncoursClients'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'relanceEmailDonneurOrdreIndirect'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'fproDossierFtp'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'fproFtpLogin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'fproFtpPassword'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxRemiseAvisNationnaux'?: number;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'parrainageVacations'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'expeditionFacturePapier'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'ancienCodeClientCegid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'spelPrefere'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'categorieSocioProfessionelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'spelModerationAvantWeb'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'justifSpelGpGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'justifSpelHgGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'paiementDematerialise'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxRrrIg1Ligne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxRrrIg2Ligne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxRrrHgLigne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxRrrIg1Forfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxRrrIg2Forfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxRrrHgForfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxAvoIg1Ligne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxAvoIg2Ligne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxAvoHgLigne'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxAvoIg1Forfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxAvoIg2Forfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'tauxAvoHgForfait'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'coefForfaitLogoIg1'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'coefForfaitLogoIg2'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'coefForfaitlogoHg'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'fraisTechInterdit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'stopControleForfait'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'sousClientNonRelancable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'signatureElectronique'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'approDocumentsParMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'attestationsCertifiees'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'paiementEnLigneLiquidation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'fraisTechLogoInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'idDefinitionTypeRRR'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'excluCadeauFinAnnee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'attestationPerso'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'logoAttestation'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'libAttestationPerso'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'forfaitPcColNI'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'montantForfaitPcColNI'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'rectifGratuitIg1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'rectifGratuitIg2'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'rectifGratuitHg'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'frequenceRappelsFormalites'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'blocageDnid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'idMra'?: number;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'modePaiement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'partenaireFormalite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'factureSimplifiee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'logoFormalite'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'plateformeB2BAutorise'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'devisDetaille'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'fClearDoc'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonld
     */
    'clearDocDelai'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'fClearDocMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonld
     */
    'clearDocMail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonld
     */
    'fproDossierFTP'?: boolean;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersJsonldFournisseurReadFournisseurReadTiers
 */
export interface RelationSocieteTiersJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof RelationSocieteTiersJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldFournisseurReadFournisseurReadTiers}
     * @memberof RelationSocieteTiersJsonldFournisseurReadFournisseurReadTiers
     */
    'societe'?: SocieteJsonldFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldFournisseurReadFournisseurReadTiers
     */
    'raisonSociale'?: string;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersJsonldPrescripteurCreate
 */
export interface RelationSocieteTiersJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof RelationSocieteTiersJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldPrescripteurCreate}
     * @memberof RelationSocieteTiersJsonldPrescripteurCreate
     */
    'societe'?: SocieteJsonldPrescripteurCreate;
    /**
     * 
     * @type {PortefeuilleJsonldPrescripteurCreate}
     * @memberof RelationSocieteTiersJsonldPrescripteurCreate
     */
    'portefeuille'?: PortefeuilleJsonldPrescripteurCreate;
    /**
     * 
     * @type {CategorieSocioProfessionnelleJsonldPrescripteurCreate}
     * @memberof RelationSocieteTiersJsonldPrescripteurCreate
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnelleJsonldPrescripteurCreate;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonldPrescripteurCreate
     */
    'delaiPaiement'?: number;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersJsonldSousclientCreate
 */
export interface RelationSocieteTiersJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof RelationSocieteTiersJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldSousclientCreate}
     * @memberof RelationSocieteTiersJsonldSousclientCreate
     */
    'societe'?: SocieteJsonldSousclientCreate;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersJsonldTiersRead
 */
export interface RelationSocieteTiersJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldTiersRead}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'societe'?: SocieteJsonldTiersRead;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'codeClient'?: string;
    /**
     * 
     * @type {PortefeuilleJsonldTiersRead}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'portefeuille'?: PortefeuilleJsonldTiersRead;
    /**
     * 
     * @type {CategorieSocioProfessionnelleJsonldTiersRead}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnelleJsonldTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'tiersNonFiable'?: boolean;
    /**
     * Quadruplet de bit définissant les valeurs de Devis Attestation Facture Correspondance
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'dafc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'plateformeB2BAutorise'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {ModePaiementJsonldTiersRead}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'modePaiement'?: ModePaiementJsonldTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'partenaireFormalite'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'logoFormalite'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'devisDetaille'?: boolean;
    /**
     * 
     * @type {UtilisateurJsonldTiersRead}
     * @memberof RelationSocieteTiersJsonldTiersRead
     */
    'chargeDeRecouvrement'?: UtilisateurJsonldTiersRead | null;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersJsonldTiersReadTiersReadExpanded
 */
export interface RelationSocieteTiersJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'societe'?: SocieteJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'codeClient'?: string;
    /**
     * 
     * @type {PortefeuilleJsonldTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'portefeuille'?: PortefeuilleJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnelleJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'tiersNonFiable'?: boolean;
    /**
     * Quadruplet de bit définissant les valeurs de Devis Attestation Facture Correspondance
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'dafc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'plateformeB2BAutorise'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {ModePaiementJsonldTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'modePaiement'?: ModePaiementJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'partenaireFormalite'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'logoFormalite'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'devisDetaille'?: boolean;
    /**
     * 
     * @type {UtilisateurJsonldTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersJsonldTiersReadTiersReadExpanded
     */
    'chargeDeRecouvrement'?: UtilisateurJsonldTiersReadTiersReadExpanded | null;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersPrescripteurCreate
 */
export interface RelationSocieteTiersPrescripteurCreate {
    /**
     * 
     * @type {SocietePrescripteurCreate}
     * @memberof RelationSocieteTiersPrescripteurCreate
     */
    'societe'?: SocietePrescripteurCreate;
    /**
     * 
     * @type {PortefeuillePrescripteurCreate}
     * @memberof RelationSocieteTiersPrescripteurCreate
     */
    'portefeuille'?: PortefeuillePrescripteurCreate;
    /**
     * 
     * @type {CategorieSocioProfessionnellePrescripteurCreate}
     * @memberof RelationSocieteTiersPrescripteurCreate
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnellePrescripteurCreate;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersPrescripteurCreate
     */
    'delaiPaiement'?: number;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
 */
export interface RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    '@type'?: string;
    /**
     * 
     * @type {PortefeuilleJsonldPrescripteurWriteRelationSociete}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'portefeuille'?: PortefeuilleJsonldPrescripteurWriteRelationSociete;
    /**
     * 
     * @type {CategorieSocioProfessionnelleJsonldPrescripteurWriteRelationSociete}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnelleJsonldPrescripteurWriteRelationSociete;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'tiersNonFiable'?: boolean;
    /**
     * Quadruplet de bit définissant les valeurs de Devis Attestation Facture Correspondance
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'dafc'?: string;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {ModePaiementJsonldPrescripteurWriteRelationSociete}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'modePaiement'?: ModePaiementJsonldPrescripteurWriteRelationSociete;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'partenaireFormalite'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'logoFormalite'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete
     */
    'devisDetaille'?: boolean;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
 */
export interface RelationSocieteTiersRelationSocieteTiersJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {SocieteJsonldTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'societe'?: SocieteJsonldTiersRead;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'codeClient'?: string;
    /**
     * 
     * @type {PortefeuilleJsonldTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'portefeuille'?: PortefeuilleJsonldTiersRead;
    /**
     * 
     * @type {CategorieSocioProfessionnelleJsonldTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnelleJsonldTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'tiersNonFiable'?: boolean;
    /**
     * Quadruplet de bit définissant les valeurs de Devis Attestation Facture Correspondance
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'dafc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'plateformeB2BAutorise'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {ModePaiementJsonldTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'modePaiement'?: ModePaiementJsonldTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'partenaireFormalite'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'logoFormalite'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'devisDetaille'?: boolean;
    /**
     * 
     * @type {UtilisateurJsonldTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersJsonldTiersRead
     */
    'chargeDeRecouvrement'?: UtilisateurJsonldTiersRead | null;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
 */
export interface RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete {
    /**
     * 
     * @type {PortefeuillePrescripteurWriteRelationSociete}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'portefeuille'?: PortefeuillePrescripteurWriteRelationSociete;
    /**
     * 
     * @type {CategorieSocioProfessionnellePrescripteurWriteRelationSociete}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnellePrescripteurWriteRelationSociete;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'tiersNonFiable'?: boolean;
    /**
     * Quadruplet de bit définissant les valeurs de Devis Attestation Facture Correspondance
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'dafc'?: string;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {ModePaiementPrescripteurWriteRelationSociete}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'modePaiement'?: ModePaiementPrescripteurWriteRelationSociete;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'partenaireFormalite'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'logoFormalite'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersPrescripteurWriteRelationSociete
     */
    'devisDetaille'?: boolean;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersRelationSocieteTiersTiersRead
 */
export interface RelationSocieteTiersRelationSocieteTiersTiersRead {
    /**
     * 
     * @type {SocieteTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'societe'?: SocieteTiersRead;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'codeClient'?: string;
    /**
     * 
     * @type {PortefeuilleTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'portefeuille'?: PortefeuilleTiersRead;
    /**
     * 
     * @type {CategorieSocioProfessionnelleTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnelleTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'tiersNonFiable'?: boolean;
    /**
     * Quadruplet de bit définissant les valeurs de Devis Attestation Facture Correspondance
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'dafc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'plateformeB2BAutorise'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {ModePaiementTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'modePaiement'?: ModePaiementTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'partenaireFormalite'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'logoFormalite'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'devisDetaille'?: boolean;
    /**
     * 
     * @type {UtilisateurTiersRead}
     * @memberof RelationSocieteTiersRelationSocieteTiersTiersRead
     */
    'chargeDeRecouvrement'?: UtilisateurTiersRead | null;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersSousclientCreate
 */
export interface RelationSocieteTiersSousclientCreate {
    /**
     * 
     * @type {SocieteSousclientCreate}
     * @memberof RelationSocieteTiersSousclientCreate
     */
    'societe'?: SocieteSousclientCreate;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersTiersRead
 */
export interface RelationSocieteTiersTiersRead {
    /**
     * 
     * @type {SocieteTiersRead}
     * @memberof RelationSocieteTiersTiersRead
     */
    'societe'?: SocieteTiersRead;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersTiersRead
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersTiersRead
     */
    'codeClient'?: string;
    /**
     * 
     * @type {PortefeuilleTiersRead}
     * @memberof RelationSocieteTiersTiersRead
     */
    'portefeuille'?: PortefeuilleTiersRead;
    /**
     * 
     * @type {CategorieSocioProfessionnelleTiersRead}
     * @memberof RelationSocieteTiersTiersRead
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnelleTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersRead
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersRead
     */
    'tiersNonFiable'?: boolean;
    /**
     * Quadruplet de bit définissant les valeurs de Devis Attestation Facture Correspondance
     * @type {string}
     * @memberof RelationSocieteTiersTiersRead
     */
    'dafc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersRead
     */
    'plateformeB2BAutorise'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersTiersRead
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {ModePaiementTiersRead}
     * @memberof RelationSocieteTiersTiersRead
     */
    'modePaiement'?: ModePaiementTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersRead
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersRead
     */
    'partenaireFormalite'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof RelationSocieteTiersTiersRead
     */
    'logoFormalite'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersRead
     */
    'devisDetaille'?: boolean;
    /**
     * 
     * @type {UtilisateurTiersRead}
     * @memberof RelationSocieteTiersTiersRead
     */
    'chargeDeRecouvrement'?: UtilisateurTiersRead | null;
}
/**
 * 
 * @export
 * @interface RelationSocieteTiersTiersReadTiersReadExpanded
 */
export interface RelationSocieteTiersTiersReadTiersReadExpanded {
    /**
     * 
     * @type {SocieteTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'societe'?: SocieteTiersReadTiersReadExpanded;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'codeClient'?: string;
    /**
     * 
     * @type {PortefeuilleTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'portefeuille'?: PortefeuilleTiersReadTiersReadExpanded;
    /**
     * 
     * @type {CategorieSocioProfessionnelleTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'categorieSocioProfessionnelle'?: CategorieSocioProfessionnelleTiersReadTiersReadExpanded;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'clientXl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'tiersNonFiable'?: boolean;
    /**
     * Quadruplet de bit définissant les valeurs de Devis Attestation Facture Correspondance
     * @type {string}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'dafc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'plateformeB2BAutorise'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {ModePaiementTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'modePaiement'?: ModePaiementTiersReadTiersReadExpanded;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'emailSousclientObligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'partenaireFormalite'?: boolean;
    /**
     * Logo en base64
     * @type {string}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'logoFormalite'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'devisDetaille'?: boolean;
    /**
     * 
     * @type {UtilisateurTiersReadTiersReadExpanded}
     * @memberof RelationSocieteTiersTiersReadTiersReadExpanded
     */
    'chargeDeRecouvrement'?: UtilisateurTiersReadTiersReadExpanded | null;
}
/**
 * 
 * @export
 * @interface SearchObjectGroupByTypeObjectGroupCollection200Response
 */
export interface SearchObjectGroupByTypeObjectGroupCollection200Response {
    /**
     * 
     * @type {Array<ObjectGroupJsonldObjectgroupRead>}
     * @memberof SearchObjectGroupByTypeObjectGroupCollection200Response
     */
    'hydra:member': Array<ObjectGroupJsonldObjectgroupRead>;
    /**
     * 
     * @type {number}
     * @memberof SearchObjectGroupByTypeObjectGroupCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof SearchObjectGroupByTypeObjectGroupCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof SearchObjectGroupByTypeObjectGroupCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface SearchTiersGroupTypeTiersCollection200Response
 */
export interface SearchTiersGroupTypeTiersCollection200Response {
    /**
     * 
     * @type {Array<TiersJsonldTiersRead>}
     * @memberof SearchTiersGroupTypeTiersCollection200Response
     */
    'hydra:member': Array<TiersJsonldTiersRead>;
    /**
     * 
     * @type {number}
     * @memberof SearchTiersGroupTypeTiersCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView}
     * @memberof SearchTiersGroupTypeTiersCollection200Response
     */
    'hydra:view'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch}
     * @memberof SearchTiersGroupTypeTiersCollection200Response
     */
    'hydra:search'?: GetListeCspCategorieSocioProfessionnelleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface SocieteFournisseurRead
 */
export interface SocieteFournisseurRead {
    /**
     * 
     * @type {number}
     * @memberof SocieteFournisseurRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteFournisseurRead
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteFournisseurRead
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocieteFournisseurReadFournisseurReadTiers
 */
export interface SocieteFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {number}
     * @memberof SocieteFournisseurReadFournisseurReadTiers
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteFournisseurReadFournisseurReadTiers
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteFournisseurReadFournisseurReadTiers
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocieteJsonldFournisseurRead
 */
export interface SocieteJsonldFournisseurRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldFournisseurRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldFournisseurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldFournisseurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldFournisseurRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldFournisseurRead
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldFournisseurRead
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocieteJsonldFournisseurReadFournisseurReadTiers
 */
export interface SocieteJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldFournisseurReadFournisseurReadTiers
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldFournisseurReadFournisseurReadTiers
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldFournisseurReadFournisseurReadTiers
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocieteJsonldPrescripteurCreate
 */
export interface SocieteJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldPrescripteurCreate
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocieteJsonldPrescripteurReadCommerciaux
 */
export interface SocieteJsonldPrescripteurReadCommerciaux {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldPrescripteurReadCommerciaux
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadCommerciaux
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadCommerciaux
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldPrescripteurReadCommerciaux
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadCommerciaux
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadCommerciaux
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocieteJsonldPrescripteurReadConditionsCommercialesClearFile
 */
export interface SocieteJsonldPrescripteurReadConditionsCommercialesClearFile {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocieteJsonldPrescripteurReadConditionsCommercialesFormalites
 */
export interface SocieteJsonldPrescripteurReadConditionsCommercialesFormalites {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocieteJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
 */
export interface SocieteJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocieteJsonldPrescripteurReadFournisseurs
 */
export interface SocieteJsonldPrescripteurReadFournisseurs {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldPrescripteurReadFournisseurs
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadFournisseurs
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadFournisseurs
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldPrescripteurReadFournisseurs
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadFournisseurs
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadFournisseurs
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocieteJsonldPrescripteurReadNotificationsFrequences
 */
export interface SocieteJsonldPrescripteurReadNotificationsFrequences {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldPrescripteurReadNotificationsFrequences
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadNotificationsFrequences
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurReadNotificationsFrequences
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldPrescripteurReadNotificationsFrequences
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocieteJsonldPrescripteurWriteCommercial
 */
export interface SocieteJsonldPrescripteurWriteCommercial {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldPrescripteurWriteCommercial
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurWriteCommercial
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldPrescripteurWriteCommercial
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldPrescripteurWriteCommercial
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocieteJsonldSousclientCreate
 */
export interface SocieteJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldSousclientCreate
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocieteJsonldTiersRead
 */
export interface SocieteJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldTiersRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldTiersRead
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldTiersRead
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocieteJsonldTiersReadTiersReadExpanded
 */
export interface SocieteJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof SocieteJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof SocieteJsonldTiersReadTiersReadExpanded
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldTiersReadTiersReadExpanded
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteJsonldTiersReadTiersReadExpanded
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurCreate
 */
export interface SocietePrescripteurCreate {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurCreate
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurReadCommerciaux
 */
export interface SocietePrescripteurReadCommerciaux {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurReadCommerciaux
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocietePrescripteurReadCommerciaux
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietePrescripteurReadCommerciaux
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurReadConditionsCommercialesClearFile
 */
export interface SocietePrescripteurReadConditionsCommercialesClearFile {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurReadConditionsCommercialesClearFile
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurReadConditionsCommercialesFormalites
 */
export interface SocietePrescripteurReadConditionsCommercialesFormalites {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurReadConditionsCommercialesFormalites
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurReadConditionsCommercialesGrilleTarifaire
 */
export interface SocietePrescripteurReadConditionsCommercialesGrilleTarifaire {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurReadFournisseurs
 */
export interface SocietePrescripteurReadFournisseurs {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurReadFournisseurs
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocietePrescripteurReadFournisseurs
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietePrescripteurReadFournisseurs
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurReadNotificationsFrequences
 */
export interface SocietePrescripteurReadNotificationsFrequences {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurReadNotificationsFrequences
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurWriteCommercial
 */
export interface SocietePrescripteurWriteCommercial {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurWriteCommercial
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurWriteConditionsCommercialesClearFile
 */
export interface SocietePrescripteurWriteConditionsCommercialesClearFile {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurWriteConditionsCommercialesClearFile
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurWriteConditionsCommercialesFormalites
 */
export interface SocietePrescripteurWriteConditionsCommercialesFormalites {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurWriteConditionsCommercialesFormalites
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurWriteConditionsCommercialesGrilleForfaitaire
 */
export interface SocietePrescripteurWriteConditionsCommercialesGrilleForfaitaire {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocietePrescripteurWriteNotificationsFrequences
 */
export interface SocietePrescripteurWriteNotificationsFrequences {
    /**
     * 
     * @type {number}
     * @memberof SocietePrescripteurWriteNotificationsFrequences
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocieteSousclientCreate
 */
export interface SocieteSousclientCreate {
    /**
     * 
     * @type {number}
     * @memberof SocieteSousclientCreate
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SocieteTiersRead
 */
export interface SocieteTiersRead {
    /**
     * 
     * @type {number}
     * @memberof SocieteTiersRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteTiersRead
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteTiersRead
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface SocieteTiersReadTiersReadExpanded
 */
export interface SocieteTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof SocieteTiersReadTiersReadExpanded
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SocieteTiersReadTiersReadExpanded
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocieteTiersReadTiersReadExpanded
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface TarifAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire
 */
export interface TarifAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire {
    /**
     * 
     * @type {number}
     * @memberof TarifAnnonceLegalePrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TarifFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire
 */
export interface TarifFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire {
    /**
     * 
     * @type {number}
     * @memberof TarifFormalitePrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblCollectionDossierJsonldTiersRead
 */
export interface TblCollectionDossierJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TblCollectionDossierJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TblCollectionDossierJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCollectionDossierJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblCollectionDossierJsonldTiersRead
     */
    'idCollection'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCollectionDossierJsonldTiersRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {TiersJsonldTiersRead}
     * @memberof TblCollectionDossierJsonldTiersRead
     */
    'idPrescripteur'?: TiersJsonldTiersRead;
    /**
     * 
     * @type {Array<FproDossierJsonldTiersRead>}
     * @memberof TblCollectionDossierJsonldTiersRead
     */
    'fProDossier'?: Array<FproDossierJsonldTiersRead>;
}
/**
 * 
 * @export
 * @interface TblCollectionDossierJsonldTiersReadTiersReadExpanded
 */
export interface TblCollectionDossierJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TblCollectionDossierJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TblCollectionDossierJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCollectionDossierJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblCollectionDossierJsonldTiersReadTiersReadExpanded
     */
    'idCollection'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCollectionDossierJsonldTiersReadTiersReadExpanded
     */
    'numero'?: string | null;
    /**
     * 
     * @type {TiersJsonldTiersReadTiersReadExpanded}
     * @memberof TblCollectionDossierJsonldTiersReadTiersReadExpanded
     */
    'idPrescripteur'?: TiersJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {Array<FproDossierJsonldTiersReadTiersReadExpanded>}
     * @memberof TblCollectionDossierJsonldTiersReadTiersReadExpanded
     */
    'fProDossier'?: Array<FproDossierJsonldTiersReadTiersReadExpanded>;
}
/**
 * 
 * @export
 * @interface TblCollectionDossierTiersRead
 */
export interface TblCollectionDossierTiersRead {
    /**
     * 
     * @type {number}
     * @memberof TblCollectionDossierTiersRead
     */
    'idCollection'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCollectionDossierTiersRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {TiersTiersRead}
     * @memberof TblCollectionDossierTiersRead
     */
    'idPrescripteur'?: TiersTiersRead;
    /**
     * 
     * @type {Array<FproDossierTiersRead>}
     * @memberof TblCollectionDossierTiersRead
     */
    'fProDossier'?: Array<FproDossierTiersRead>;
}
/**
 * 
 * @export
 * @interface TblCollectionDossierTiersReadTiersReadExpanded
 */
export interface TblCollectionDossierTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof TblCollectionDossierTiersReadTiersReadExpanded
     */
    'idCollection'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCollectionDossierTiersReadTiersReadExpanded
     */
    'numero'?: string | null;
    /**
     * 
     * @type {TiersTiersReadTiersReadExpanded}
     * @memberof TblCollectionDossierTiersReadTiersReadExpanded
     */
    'idPrescripteur'?: TiersTiersReadTiersReadExpanded;
    /**
     * 
     * @type {Array<FproDossierTiersReadTiersReadExpanded>}
     * @memberof TblCollectionDossierTiersReadTiersReadExpanded
     */
    'fProDossier'?: Array<FproDossierTiersReadTiersReadExpanded>;
}
/**
 * 
 * @export
 * @interface TblDefDomaine
 */
export interface TblDefDomaine {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaine
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaine
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaine
     */
    'info'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDefDomaine
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaine
     */
    'code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDefDomaine
     */
    'reporting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblDefDomaine
     */
    'ancienHeader'?: boolean;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonld
 */
export interface TblDefDomaineJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TblDefDomaineJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonld
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonld
     */
    'info'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDefDomaineJsonld
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonld
     */
    'code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDefDomaineJsonld
     */
    'reporting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblDefDomaineJsonld
     */
    'ancienHeader'?: boolean;
}
/**
 * 
 * @export
 * @interface TblOrigine
 */
export interface TblOrigine {
    /**
     * 
     * @type {number}
     * @memberof TblOrigine
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOrigine
     */
    'origine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblOrigine
     */
    'initiale'?: string | null;
    /**
     * 
     * @type {TblSociete}
     * @memberof TblOrigine
     */
    'societe'?: TblSociete | null;
    /**
     * 
     * @type {string}
     * @memberof TblOrigine
     */
    'baseUrl'?: string;
}
/**
 * 
 * @export
 * @interface TblOrigineJsonld
 */
export interface TblOrigineJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TblOrigineJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOrigineJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonld
     */
    'origine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonld
     */
    'initiale'?: string | null;
    /**
     * 
     * @type {TblSocieteJsonld}
     * @memberof TblOrigineJsonld
     */
    'societe'?: TblSocieteJsonld | null;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonld
     */
    'baseUrl'?: string;
}
/**
 * 
 * @export
 * @interface TblParametreReferences
 */
export interface TblParametreReferences {
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferences
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferences
     */
    'nomParam'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferences
     */
    'etatParam'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferences
     */
    'infoParam'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferences
     */
    'idCategorie'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblParametreReferences
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferences
     */
    'userMAJ'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferences
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferences
     */
    'infoBlocage'?: string;
    /**
     * 
     * @type {TblSociete}
     * @memberof TblParametreReferences
     */
    'societe'?: TblSociete | null;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferences
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblParametreReferences
     */
    'aVerifier'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferences
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferences
     */
    'dateCreation'?: string | null;
}
/**
 * 
 * @export
 * @interface TblParametreReferencesCiviliteCiviliteReadCollection
 */
export interface TblParametreReferencesCiviliteCiviliteReadCollection {
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferencesCiviliteCiviliteReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesCiviliteCiviliteReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesCiviliteCiviliteReadCollection
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface TblParametreReferencesCiviliteJsonldCiviliteReadCollection
 */
export interface TblParametreReferencesCiviliteJsonldCiviliteReadCollection {
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesCiviliteJsonldCiviliteReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesCiviliteJsonldCiviliteReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferencesCiviliteJsonldCiviliteReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesCiviliteJsonldCiviliteReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesCiviliteJsonldCiviliteReadCollection
     */
    'abreviation'?: string;
}
/**
 * 
 * @export
 * @interface TblParametreReferencesJsonld
 */
export interface TblParametreReferencesJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TblParametreReferencesJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferencesJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesJsonld
     */
    'nomParam'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesJsonld
     */
    'etatParam'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesJsonld
     */
    'infoParam'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferencesJsonld
     */
    'idCategorie'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblParametreReferencesJsonld
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferencesJsonld
     */
    'userMAJ'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesJsonld
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesJsonld
     */
    'infoBlocage'?: string;
    /**
     * 
     * @type {TblSocieteJsonld}
     * @memberof TblParametreReferencesJsonld
     */
    'societe'?: TblSocieteJsonld | null;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesJsonld
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblParametreReferencesJsonld
     */
    'aVerifier'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferencesJsonld
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesJsonld
     */
    'dateCreation'?: string | null;
}
/**
 * 
 * @export
 * @interface TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection
 */
export interface TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection {
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoSpecificationJsonldLogoSpecificationReadCollection
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface TblParametreReferencesLogoSpecificationLogoSpecificationReadCollection
 */
export interface TblParametreReferencesLogoSpecificationLogoSpecificationReadCollection {
    /**
     * 
     * @type {number}
     * @memberof TblParametreReferencesLogoSpecificationLogoSpecificationReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoSpecificationLogoSpecificationReadCollection
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoSpecificationLogoSpecificationReadCollection
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoSpecificationLogoSpecificationReadCollection
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface TblParametreReferencesLogoValidation
 */
export interface TblParametreReferencesLogoValidation {
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoValidation
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface TblParametreReferencesLogoValidationJsonld
 */
export interface TblParametreReferencesLogoValidationJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TblParametreReferencesLogoValidationJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoValidationJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoValidationJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblParametreReferencesLogoValidationJsonld
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface TblPaysJsonldPaysRead
 */
export interface TblPaysJsonldPaysRead {
    /**
     * 
     * @type {string}
     * @memberof TblPaysJsonldPaysRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysJsonldPaysRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TblPaysJsonldPaysRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {number}
     * @memberof TblPaysJsonldPaysRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPaysJsonldPaysRead
     */
    'codeIso2'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysJsonldPaysRead
     */
    'codeIso3'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysJsonldPaysRead
     */
    'codeNumerique'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysJsonldPaysRead
     */
    'codePays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysJsonldPaysRead
     */
    'codePaysRattachement'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPaysJsonldPaysRead
     */
    'codeActualite'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPaysJsonldPaysRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysJsonldPaysRead
     */
    'libelleOfficiel'?: string;
}
/**
 * 
 * @export
 * @interface TblPaysPaysRead
 */
export interface TblPaysPaysRead {
    /**
     * 
     * @type {number}
     * @memberof TblPaysPaysRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPaysPaysRead
     */
    'codeIso2'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysPaysRead
     */
    'codeIso3'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysPaysRead
     */
    'codeNumerique'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysPaysRead
     */
    'codePays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysPaysRead
     */
    'codePaysRattachement'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPaysPaysRead
     */
    'codeActualite'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPaysPaysRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPaysPaysRead
     */
    'libelleOfficiel'?: string;
}
/**
 * 
 * @export
 * @interface TblSociete
 */
export interface TblSociete {
    /**
     * 
     * @type {number}
     * @memberof TblSociete
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'pays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'libPays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'effectif'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'sigle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'codeNaf'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'codeApe'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblSociete
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'userCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblSociete
     */
    'userMAJ'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'siteWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'piedDePage'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'compteBanque'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'numeroRcs'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'numeroTvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'abreviationRaisonSociale'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblSociete
     */
    'tvaSurEncaissement'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'regimeTvaCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'telephoneCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblSociete
     */
    'facturanteUtilise'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'codeBic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'headerFacture'?: string | null;
}
/**
 * 
 * @export
 * @interface TblSocieteJsonld
 */
export interface TblSocieteJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TblSocieteJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblSocieteJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'pays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'libPays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'effectif'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'sigle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'codeNaf'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'codeApe'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblSocieteJsonld
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'userCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblSocieteJsonld
     */
    'userMAJ'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'siteWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'piedDePage'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'compteBanque'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'numeroRcs'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'numeroTvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'abreviationRaisonSociale'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblSocieteJsonld
     */
    'tvaSurEncaissement'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'regimeTvaCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'telephoneCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblSocieteJsonld
     */
    'facturanteUtilise'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'codeBic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'headerFacture'?: string | null;
}
/**
 * 
 * @export
 * @interface TblUtilisateur
 */
export interface TblUtilisateur {
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateur
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateur
     */
    'idUserGroupe'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'initiales'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateur
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'user'?: string | null;
    /**
     * 
     * @type {TblUtilisateur}
     * @memberof TblUtilisateur
     */
    'userMAJ'?: TblUtilisateur | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'poste'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateur
     */
    'eleveur'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateur
     */
    'groupe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateur
     */
    'g3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateur
     */
    'formaliste'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateur
     */
    'societe'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateur
     */
    'vpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateur
     */
    'g3Recetteur'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateur
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateur
     */
    'comptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateur
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateur
     */
    'idUserDematFacile'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateur
     */
    'idMarque'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateur
     */
    'fpro'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateur
     */
    'inAnnuaire'?: boolean;
}
/**
 * 
 * @export
 * @interface TblUtilisateurJsonld
 */
export interface TblUtilisateurJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TblUtilisateurJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateurJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateurJsonld
     */
    'idUserGroupe'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'initiales'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateurJsonld
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'user'?: string | null;
    /**
     * 
     * @type {TblUtilisateurJsonld}
     * @memberof TblUtilisateurJsonld
     */
    'userMAJ'?: TblUtilisateurJsonld | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'poste'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateurJsonld
     */
    'eleveur'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateurJsonld
     */
    'groupe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateurJsonld
     */
    'g3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateurJsonld
     */
    'formaliste'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateurJsonld
     */
    'societe'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateurJsonld
     */
    'vpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateurJsonld
     */
    'g3Recetteur'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateurJsonld
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateurJsonld
     */
    'comptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonld
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateurJsonld
     */
    'idUserDematFacile'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateurJsonld
     */
    'idMarque'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateurJsonld
     */
    'fpro'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblUtilisateurJsonld
     */
    'inAnnuaire'?: boolean;
}
/**
 * 
 * @export
 * @interface TelephoneContactCreate
 */
export interface TelephoneContactCreate {
    /**
     * 
     * @type {string}
     * @memberof TelephoneContactCreate
     */
    'telephone': string;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneContactCreate
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface TelephoneContactRead
 */
export interface TelephoneContactRead {
    /**
     * 
     * @type {string}
     * @memberof TelephoneContactRead
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneContactRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneContactRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TelephoneContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TelephoneContactRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface TelephoneContactWriteTelsPrescripteur
 */
export interface TelephoneContactWriteTelsPrescripteur {
    /**
     * 
     * @type {string}
     * @memberof TelephoneContactWriteTelsPrescripteur
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneContactWriteTelsPrescripteur
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneContactWriteTelsPrescripteur
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface TelephoneContactWriteTelsSousclient
 */
export interface TelephoneContactWriteTelsSousclient {
    /**
     * 
     * @type {string}
     * @memberof TelephoneContactWriteTelsSousclient
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneContactWriteTelsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneContactWriteTelsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface TelephoneFournisseurReadFournisseurReadTiers
 */
export interface TelephoneFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {string}
     * @memberof TelephoneFournisseurReadFournisseurReadTiers
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TelephoneFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TelephoneFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface TelephoneJsonldContactCreate
 */
export interface TelephoneJsonldContactCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TelephoneJsonldContactCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactCreate
     */
    'telephone': string;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneJsonldContactCreate
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface TelephoneJsonldContactRead
 */
export interface TelephoneJsonldContactRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TelephoneJsonldContactRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactRead
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneJsonldContactRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneJsonldContactRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface TelephoneJsonldContactWriteTelsPrescripteur
 */
export interface TelephoneJsonldContactWriteTelsPrescripteur {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TelephoneJsonldContactWriteTelsPrescripteur
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactWriteTelsPrescripteur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactWriteTelsPrescripteur
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactWriteTelsPrescripteur
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneJsonldContactWriteTelsPrescripteur
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneJsonldContactWriteTelsPrescripteur
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface TelephoneJsonldContactWriteTelsSousclient
 */
export interface TelephoneJsonldContactWriteTelsSousclient {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TelephoneJsonldContactWriteTelsSousclient
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactWriteTelsSousclient
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactWriteTelsSousclient
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldContactWriteTelsSousclient
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneJsonldContactWriteTelsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneJsonldContactWriteTelsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface TelephoneJsonldFournisseurReadFournisseurReadTiers
 */
export interface TelephoneJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TelephoneJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldFournisseurReadFournisseurReadTiers
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneJsonldFournisseurReadFournisseurReadTiers
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldFournisseurReadFournisseurReadTiers
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldFournisseurReadFournisseurReadTiers
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface TelephoneJsonldPrescripteurCreate
 */
export interface TelephoneJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TelephoneJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldPrescripteurCreate
     */
    'telephone': string;
}
/**
 * 
 * @export
 * @interface TelephoneJsonldSousclientCreate
 */
export interface TelephoneJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TelephoneJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldSousclientCreate
     */
    'telephone': string;
}
/**
 * 
 * @export
 * @interface TelephoneJsonldTiersRead
 */
export interface TelephoneJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TelephoneJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersRead
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneJsonldTiersRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneJsonldTiersRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface TelephoneJsonldTiersReadTiersReadExpanded
 */
export interface TelephoneJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TelephoneJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersReadTiersReadExpanded
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneJsonldTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TelephoneJsonldTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface TelephonePrescripteurCreate
 */
export interface TelephonePrescripteurCreate {
    /**
     * 
     * @type {string}
     * @memberof TelephonePrescripteurCreate
     */
    'telephone': string;
}
/**
 * 
 * @export
 * @interface TelephoneSousclientCreate
 */
export interface TelephoneSousclientCreate {
    /**
     * 
     * @type {string}
     * @memberof TelephoneSousclientCreate
     */
    'telephone': string;
}
/**
 * 
 * @export
 * @interface TelephoneSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
 */
export interface TelephoneSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient {
    /**
     * 
     * @type {string}
     * @memberof TelephoneSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface TelephoneTiersRead
 */
export interface TelephoneTiersRead {
    /**
     * 
     * @type {string}
     * @memberof TelephoneTiersRead
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneTiersRead
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneTiersRead
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TelephoneTiersRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TelephoneTiersRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface TelephoneTiersReadTiersReadExpanded
 */
export interface TelephoneTiersReadTiersReadExpanded {
    /**
     * 
     * @type {string}
     * @memberof TelephoneTiersReadTiersReadExpanded
     */
    'telephone': string;
    /**
     * 
     * @type {number}
     * @memberof TelephoneTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * coordonnée à utiliser par défaut ?
     * @type {boolean}
     * @memberof TelephoneTiersReadTiersReadExpanded
     */
    'defaut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TelephoneTiersReadTiersReadExpanded
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TelephoneTiersReadTiersReadExpanded
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface TiersFournisseurReadFournisseurReadTiers
 */
export interface TiersFournisseurReadFournisseurReadTiers {
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'venteIndirecte'?: boolean;
    /**
     * Créer un compte utilisateur Keycloak et un login G3 ?
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'creerLogin'?: boolean;
    /**
     * 
     * @type {Array<AdresseFournisseurReadFournisseurReadTiers>}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'adresses': Array<AdresseFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {Array<ContactFournisseurReadFournisseurReadTiers>}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'contacts': Array<ContactFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {OrigineFournisseurReadFournisseurReadTiers}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'origine': OrigineFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {Array<RelationSocieteTiersFournisseurReadFournisseurReadTiers>}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'relationsSocietes': Array<RelationSocieteTiersFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurFournisseurReadFournisseurReadTiers}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'formaliste'?: UtilisateurFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {boolean}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {string}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersFournisseurReadFournisseurReadTiers
     */
    'noteFormaliste'?: string;
}
/**
 * 
 * @export
 * @interface TiersJsonld
 */
export interface TiersJsonld {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersJsonld
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TiersJsonldFournisseurRead
 */
export interface TiersJsonldFournisseurRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersJsonldFournisseurRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TiersJsonldFournisseurReadFournisseurReadTiers
 */
export interface TiersJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'venteIndirecte'?: boolean;
    /**
     * Créer un compte utilisateur Keycloak et un login G3 ?
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'creerLogin'?: boolean;
    /**
     * 
     * @type {Array<AdresseJsonldFournisseurReadFournisseurReadTiers>}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'adresses': Array<AdresseJsonldFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {Array<ContactJsonldFournisseurReadFournisseurReadTiers>}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'contacts': Array<ContactJsonldFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {OrigineJsonldFournisseurReadFournisseurReadTiers}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'origine': OrigineJsonldFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {Array<RelationSocieteTiersJsonldFournisseurReadFournisseurReadTiers>}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'relationsSocietes': Array<RelationSocieteTiersJsonldFournisseurReadFournisseurReadTiers>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurJsonldFournisseurReadFournisseurReadTiers}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'formaliste'?: UtilisateurJsonldFournisseurReadFournisseurReadTiers;
    /**
     * 
     * @type {boolean}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldFournisseurReadFournisseurReadTiers
     */
    'noteFormaliste'?: string;
}
/**
 * 
 * @export
 * @interface TiersJsonldTiersEditObjectGroup
 */
export interface TiersJsonldTiersEditObjectGroup {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersJsonldTiersEditObjectGroup
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersEditObjectGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersEditObjectGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TiersJsonldTiersEditObjectGroup
     */
    'id'?: number;
    /**
     * 
     * @type {Array<ObjectGroupJsonldTiersEditObjectGroup>}
     * @memberof TiersJsonldTiersEditObjectGroup
     */
    'objectgroups'?: Array<ObjectGroupJsonldTiersEditObjectGroup>;
}
/**
 * 
 * @export
 * @interface TiersJsonldTiersEditedObjectGroup
 */
export interface TiersJsonldTiersEditedObjectGroup {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersJsonldTiersEditedObjectGroup
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersEditedObjectGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersEditedObjectGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TiersJsonldTiersEditedObjectGroup
     */
    'objectgroups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TiersJsonldTiersRead
 */
export interface TiersJsonldTiersRead {
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersRead
     */
    'firstnamePrescripteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersRead
     */
    'lastnamePrescripteur'?: string | null;
}
/**
 * 
 * @export
 * @interface TiersJsonldTiersReadTiersReadExpanded
 */
export interface TiersJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersReadTiersReadExpanded
     */
    'firstnamePrescripteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TiersJsonldTiersReadTiersReadExpanded
     */
    'lastnamePrescripteur'?: string | null;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurCreate
 */
export interface TiersPrescripteurJsonldPrescripteurCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'tvaIntracommunautaire'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'venteIndirecte'?: boolean;
    /**
     * Créer un compte utilisateur Keycloak et un login G3 ?
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'creerLogin'?: boolean;
    /**
     * 
     * @type {Array<AdresseJsonldPrescripteurCreate>}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'adresses': Array<AdresseJsonldPrescripteurCreate>;
    /**
     * 
     * @type {Array<ContactJsonldPrescripteurCreate>}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'contacts': Array<ContactJsonldPrescripteurCreate>;
    /**
     * 
     * @type {OrigineJsonldPrescripteurCreate}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'origine': OrigineJsonldPrescripteurCreate;
    /**
     * 
     * @type {Array<RelationSocieteTiersJsonldPrescripteurCreate>}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'relationsSocietes': Array<RelationSocieteTiersJsonldPrescripteurCreate>;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreate
     */
    'noteFormaliste'?: string;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurCreated
 */
export interface TiersPrescripteurJsonldPrescripteurCreated {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurCreated
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreated
     */
    '@type'?: string;
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersPrescripteurJsonldPrescripteurCreated
     */
    'id'?: number;
    /**
     * 
     * @type {Array<AdresseJsonldPrescripteurCreated>}
     * @memberof TiersPrescripteurJsonldPrescripteurCreated
     */
    'adresses': Array<AdresseJsonldPrescripteurCreated>;
    /**
     * 
     * @type {Array<ContactJsonldPrescripteurCreated>}
     * @memberof TiersPrescripteurJsonldPrescripteurCreated
     */
    'contacts': Array<ContactJsonldPrescripteurCreated>;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreated
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurCreated
     */
    'noteFormaliste'?: string;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile
 */
export interface TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile>}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile
     */
    'clearFileInfos': Array<ClearFileInfosJsonldPrescripteurReadConditionsCommercialesClearFile>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesFormalites
 */
export interface TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesFormalites {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites>}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesFormalites
     */
    'conditionsCommerciales': Array<ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
 */
export interface TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire>}
     * @memberof TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'conditionsCommerciales': Array<ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurReadNotificationsEmails
 */
export interface TiersPrescripteurJsonldPrescripteurReadNotificationsEmails {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurReadNotificationsEmails
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadNotificationsEmails
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadNotificationsEmails
     */
    '@type'?: string;
    /**
     * 
     * @type {PreferencesNotificationJsonldPrescripteurReadNotificationsEmails}
     * @memberof TiersPrescripteurJsonldPrescripteurReadNotificationsEmails
     */
    'preferencesNotification': PreferencesNotificationJsonldPrescripteurReadNotificationsEmails;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences
 */
export interface TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences
     */
    '@type'?: string;
    /**
     * 
     * @type {PreferencesNotificationJsonldPrescripteurReadNotificationsFrequences}
     * @memberof TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences
     */
    'preferencesNotification': PreferencesNotificationJsonldPrescripteurReadNotificationsFrequences;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurUpdate
 */
export interface TiersPrescripteurJsonldPrescripteurUpdate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    'tvaIntracommunautaire'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldPrescripteurUpdate
     */
    'venteIndirecte'?: boolean;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurWriteAdresses
 */
export interface TiersPrescripteurJsonldPrescripteurWriteAdresses {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteAdresses
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteAdresses
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteAdresses
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<AdresseJsonldPrescripteurWriteAdresses>}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteAdresses
     */
    'adresses': Array<AdresseJsonldPrescripteurWriteAdresses>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurWriteContacts
 */
export interface TiersPrescripteurJsonldPrescripteurWriteContacts {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteContacts
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteContacts
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteContacts
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<ContactJsonldPrescripteurWriteContacts>}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteContacts
     */
    'contacts': Array<ContactJsonldPrescripteurWriteContacts>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldPrescripteurWriteFormaliste
 */
export interface TiersPrescripteurJsonldPrescripteurWriteFormaliste {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteFormaliste
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteFormaliste
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteFormaliste
     */
    '@type'?: string;
    /**
     * 
     * @type {UtilisateurJsonldPrescripteurWriteFormaliste}
     * @memberof TiersPrescripteurJsonldPrescripteurWriteFormaliste
     */
    'formaliste'?: UtilisateurJsonldPrescripteurWriteFormaliste;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurJsonldTiersRead
 */
export interface TiersPrescripteurJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    '@type'?: string;
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {Array<AdresseJsonldTiersRead>}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'adresses': Array<AdresseJsonldTiersRead>;
    /**
     * 
     * @type {Array<ContactJsonldTiersRead>}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'contacts': Array<ContactJsonldTiersRead>;
    /**
     * 
     * @type {OrigineJsonldTiersRead}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'origine': OrigineJsonldTiersRead;
    /**
     * 
     * @type {Array<RelationSocieteTiersJsonldTiersRead>}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'relationsSocietes': Array<RelationSocieteTiersJsonldTiersRead>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurJsonldTiersRead}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'formaliste'?: UtilisateurJsonldTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'dateMAJ'?: any;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<ObjectGroupJsonldTiersRead>}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'objectgroups'?: Array<ObjectGroupJsonldTiersRead>;
    /**
     * 
     * @type {Array<TblCollectionDossierJsonldTiersRead>}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'fCollectionDossier'?: Array<TblCollectionDossierJsonldTiersRead>;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'domaines'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'fEtabEtranger'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'firstnamePrescripteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurJsonldTiersRead
     */
    'lastnamePrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurCreate
 */
export interface TiersPrescripteurPrescripteurCreate {
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'tvaIntracommunautaire'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'venteIndirecte'?: boolean;
    /**
     * Créer un compte utilisateur Keycloak et un login G3 ?
     * @type {boolean}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'creerLogin'?: boolean;
    /**
     * 
     * @type {Array<AdressePrescripteurCreate>}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'adresses': Array<AdressePrescripteurCreate>;
    /**
     * 
     * @type {Array<ContactPrescripteurCreate>}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'contacts': Array<ContactPrescripteurCreate>;
    /**
     * 
     * @type {OriginePrescripteurCreate}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'origine': OriginePrescripteurCreate;
    /**
     * 
     * @type {Array<RelationSocieteTiersPrescripteurCreate>}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'relationsSocietes': Array<RelationSocieteTiersPrescripteurCreate>;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurCreate
     */
    'noteFormaliste'?: string;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurCreated
 */
export interface TiersPrescripteurPrescripteurCreated {
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersPrescripteurPrescripteurCreated
     */
    'id'?: number;
    /**
     * 
     * @type {Array<AdressePrescripteurCreated>}
     * @memberof TiersPrescripteurPrescripteurCreated
     */
    'adresses': Array<AdressePrescripteurCreated>;
    /**
     * 
     * @type {Array<ContactPrescripteurCreated>}
     * @memberof TiersPrescripteurPrescripteurCreated
     */
    'contacts': Array<ContactPrescripteurCreated>;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurCreated
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurCreated
     */
    'noteFormaliste'?: string;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurReadConditionsCommercialesClearFile
 */
export interface TiersPrescripteurPrescripteurReadConditionsCommercialesClearFile {
    /**
     * 
     * @type {Array<ClearFileInfosPrescripteurReadConditionsCommercialesClearFile>}
     * @memberof TiersPrescripteurPrescripteurReadConditionsCommercialesClearFile
     */
    'clearFileInfos': Array<ClearFileInfosPrescripteurReadConditionsCommercialesClearFile>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurReadConditionsCommercialesFormalites
 */
export interface TiersPrescripteurPrescripteurReadConditionsCommercialesFormalites {
    /**
     * 
     * @type {Array<ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites>}
     * @memberof TiersPrescripteurPrescripteurReadConditionsCommercialesFormalites
     */
    'conditionsCommerciales': Array<ConditionsCommercialesPrescripteurReadConditionsCommercialesFormalites>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurReadConditionsCommercialesGrilleTarifaire
 */
export interface TiersPrescripteurPrescripteurReadConditionsCommercialesGrilleTarifaire {
    /**
     * 
     * @type {Array<ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire>}
     * @memberof TiersPrescripteurPrescripteurReadConditionsCommercialesGrilleTarifaire
     */
    'conditionsCommerciales': Array<ConditionsCommercialesPrescripteurReadConditionsCommercialesGrilleTarifaire>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurReadNotificationsEmails
 */
export interface TiersPrescripteurPrescripteurReadNotificationsEmails {
    /**
     * 
     * @type {PreferencesNotificationPrescripteurReadNotificationsEmails}
     * @memberof TiersPrescripteurPrescripteurReadNotificationsEmails
     */
    'preferencesNotification': PreferencesNotificationPrescripteurReadNotificationsEmails;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurReadNotificationsFrequences
 */
export interface TiersPrescripteurPrescripteurReadNotificationsFrequences {
    /**
     * 
     * @type {PreferencesNotificationPrescripteurReadNotificationsFrequences}
     * @memberof TiersPrescripteurPrescripteurReadNotificationsFrequences
     */
    'preferencesNotification': PreferencesNotificationPrescripteurReadNotificationsFrequences;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurUpdate
 */
export interface TiersPrescripteurPrescripteurUpdate {
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurUpdate
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurUpdate
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurUpdate
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurPrescripteurUpdate
     */
    'tvaIntracommunautaire'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersPrescripteurPrescripteurUpdate
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurPrescripteurUpdate
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurPrescripteurUpdate
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurPrescripteurUpdate
     */
    'venteIndirecte'?: boolean;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurWriteAdresses
 */
export interface TiersPrescripteurPrescripteurWriteAdresses {
    /**
     * 
     * @type {Array<AdressePrescripteurWriteAdresses>}
     * @memberof TiersPrescripteurPrescripteurWriteAdresses
     */
    'adresses': Array<AdressePrescripteurWriteAdresses>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile
 */
export interface TiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile {
    /**
     * 
     * @type {Array<ClearFileInfosPrescripteurWriteConditionsCommercialesClearFile>}
     * @memberof TiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile
     */
    'clearFileInfos': Array<ClearFileInfosPrescripteurWriteConditionsCommercialesClearFile>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites
 */
export interface TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites {
    /**
     * 
     * @type {Array<ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites>}
     * @memberof TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites
     */
    'conditionsCommerciales': Array<ConditionsCommercialesPrescripteurWriteConditionsCommercialesFormalites>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire
 */
export interface TiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire {
    /**
     * 
     * @type {Array<ConditionsCommercialesPrescripteurWriteConditionsCommercialesGrilleForfaitaire>}
     * @memberof TiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire
     */
    'conditionsCommerciales': Array<ConditionsCommercialesPrescripteurWriteConditionsCommercialesGrilleForfaitaire>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurWriteContacts
 */
export interface TiersPrescripteurPrescripteurWriteContacts {
    /**
     * 
     * @type {Array<ContactPrescripteurWriteContacts>}
     * @memberof TiersPrescripteurPrescripteurWriteContacts
     */
    'contacts': Array<ContactPrescripteurWriteContacts>;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurWriteFormaliste
 */
export interface TiersPrescripteurPrescripteurWriteFormaliste {
    /**
     * 
     * @type {UtilisateurPrescripteurWriteFormaliste}
     * @memberof TiersPrescripteurPrescripteurWriteFormaliste
     */
    'formaliste'?: UtilisateurPrescripteurWriteFormaliste;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurWriteNotificationsEmails
 */
export interface TiersPrescripteurPrescripteurWriteNotificationsEmails {
    /**
     * 
     * @type {PreferencesNotificationPrescripteurWriteNotificationsEmails}
     * @memberof TiersPrescripteurPrescripteurWriteNotificationsEmails
     */
    'preferencesNotification': PreferencesNotificationPrescripteurWriteNotificationsEmails;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurPrescripteurWriteNotificationsFrequences
 */
export interface TiersPrescripteurPrescripteurWriteNotificationsFrequences {
    /**
     * 
     * @type {PreferencesNotificationPrescripteurWriteNotificationsFrequences}
     * @memberof TiersPrescripteurPrescripteurWriteNotificationsFrequences
     */
    'preferencesNotification': PreferencesNotificationPrescripteurWriteNotificationsFrequences;
}
/**
 * 
 * @export
 * @interface TiersPrescripteurTiersRead
 */
export interface TiersPrescripteurTiersRead {
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersPrescripteurTiersRead
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersPrescripteurTiersRead
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersPrescripteurTiersRead
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersPrescripteurTiersRead
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurTiersRead
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurTiersRead
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurTiersRead
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurTiersRead
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurTiersRead
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurTiersRead
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersPrescripteurTiersRead
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurTiersRead
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurTiersRead
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {Array<AdresseTiersRead>}
     * @memberof TiersPrescripteurTiersRead
     */
    'adresses': Array<AdresseTiersRead>;
    /**
     * 
     * @type {Array<ContactTiersRead>}
     * @memberof TiersPrescripteurTiersRead
     */
    'contacts': Array<ContactTiersRead>;
    /**
     * 
     * @type {OrigineTiersRead}
     * @memberof TiersPrescripteurTiersRead
     */
    'origine': OrigineTiersRead;
    /**
     * 
     * @type {Array<RelationSocieteTiersTiersRead>}
     * @memberof TiersPrescripteurTiersRead
     */
    'relationsSocietes': Array<RelationSocieteTiersTiersRead>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurTiersRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurTiersRead}
     * @memberof TiersPrescripteurTiersRead
     */
    'formaliste'?: UtilisateurTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof TiersPrescripteurTiersRead
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersPrescripteurTiersRead
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurTiersRead
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurTiersRead
     */
    'dateMAJ'?: any;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurTiersRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersPrescripteurTiersRead
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<ObjectGroupTiersRead>}
     * @memberof TiersPrescripteurTiersRead
     */
    'objectgroups'?: Array<ObjectGroupTiersRead>;
    /**
     * 
     * @type {Array<TblCollectionDossierTiersRead>}
     * @memberof TiersPrescripteurTiersRead
     */
    'fCollectionDossier'?: Array<TblCollectionDossierTiersRead>;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurTiersRead
     */
    'domaines'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurTiersRead
     */
    'fEtabEtranger'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurTiersRead
     */
    'firstnamePrescripteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersPrescripteurTiersRead
     */
    'lastnamePrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface TiersSousclientJsonldSousclientCreate
 */
export interface TiersSousclientJsonldSousclientCreate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'tvaIntracommunautaire'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'venteIndirecte'?: boolean;
    /**
     * Créer un compte utilisateur Keycloak et un login G3 ?
     * @type {boolean}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'creerLogin'?: boolean;
    /**
     * 
     * @type {Array<AdresseJsonldSousclientCreate>}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'adresses': Array<AdresseJsonldSousclientCreate>;
    /**
     * 
     * @type {Array<ContactJsonldSousclientCreate>}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'contacts': Array<ContactJsonldSousclientCreate>;
    /**
     * 
     * @type {OrigineJsonldSousclientCreate}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'origine': OrigineJsonldSousclientCreate;
    /**
     * 
     * @type {Array<RelationSocieteTiersJsonldSousclientCreate>}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'relationsSocietes': Array<RelationSocieteTiersJsonldSousclientCreate>;
    /**
     * 
     * @type {number}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'objectgroups'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientJsonldSousclientCreate
     */
    'fEtabEtranger'?: any;
}
/**
 * 
 * @export
 * @interface TiersSousclientJsonldSousclientCreated
 */
export interface TiersSousclientJsonldSousclientCreated {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersSousclientJsonldSousclientCreated
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreated
     */
    '@type'?: string;
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersSousclientJsonldSousclientCreated
     */
    'id'?: number;
    /**
     * 
     * @type {Array<AdresseJsonldSousclientCreated>}
     * @memberof TiersSousclientJsonldSousclientCreated
     */
    'adresses': Array<AdresseJsonldSousclientCreated>;
    /**
     * 
     * @type {Array<ContactJsonldSousclientCreated>}
     * @memberof TiersSousclientJsonldSousclientCreated
     */
    'contacts': Array<ContactJsonldSousclientCreated>;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreated
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientCreated
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TiersSousclientJsonldSousclientCreated
     */
    'objectgroups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TiersSousclientJsonldSousclientUpdate
 */
export interface TiersSousclientJsonldSousclientUpdate {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    'tvaIntracommunautaire'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientJsonldSousclientUpdate
     */
    'fEtabEtranger'?: any;
}
/**
 * 
 * @export
 * @interface TiersSousclientJsonldSousclientWriteAdresses
 */
export interface TiersSousclientJsonldSousclientWriteAdresses {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersSousclientJsonldSousclientWriteAdresses
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientWriteAdresses
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientWriteAdresses
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<AdresseJsonldSousclientWriteAdresses>}
     * @memberof TiersSousclientJsonldSousclientWriteAdresses
     */
    'adresses': Array<AdresseJsonldSousclientWriteAdresses>;
}
/**
 * 
 * @export
 * @interface TiersSousclientJsonldSousclientWriteContacts
 */
export interface TiersSousclientJsonldSousclientWriteContacts {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersSousclientJsonldSousclientWriteContacts
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientWriteContacts
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldSousclientWriteContacts
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<ContactJsonldSousclientWriteContacts>}
     * @memberof TiersSousclientJsonldSousclientWriteContacts
     */
    'contacts': Array<ContactJsonldSousclientWriteContacts>;
}
/**
 * 
 * @export
 * @interface TiersSousclientJsonldTiersRead
 */
export interface TiersSousclientJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersSousclientJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    '@type'?: string;
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {Array<AdresseJsonldTiersRead>}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'adresses': Array<AdresseJsonldTiersRead>;
    /**
     * 
     * @type {Array<ContactJsonldTiersRead>}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'contacts': Array<ContactJsonldTiersRead>;
    /**
     * 
     * @type {OrigineJsonldTiersRead}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'origine': OrigineJsonldTiersRead;
    /**
     * 
     * @type {Array<RelationSocieteTiersJsonldTiersRead>}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'relationsSocietes': Array<RelationSocieteTiersJsonldTiersRead>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurJsonldTiersRead}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'formaliste'?: UtilisateurJsonldTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'dateMAJ'?: any;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<ObjectGroupJsonldTiersRead>}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'objectgroups'?: Array<ObjectGroupJsonldTiersRead>;
    /**
     * 
     * @type {Array<TblCollectionDossierJsonldTiersRead>}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'fCollectionDossier'?: Array<TblCollectionDossierJsonldTiersRead>;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'domaines'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'fEtabEtranger'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'firstnamePrescripteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientJsonldTiersRead
     */
    'lastnamePrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface TiersSousclientSousclientCreate
 */
export interface TiersSousclientSousclientCreate {
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientCreate
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientCreate
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientCreate
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientCreate
     */
    'tvaIntracommunautaire'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersSousclientSousclientCreate
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientSousclientCreate
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientSousclientCreate
     */
    'venteIndirecte'?: boolean;
    /**
     * Créer un compte utilisateur Keycloak et un login G3 ?
     * @type {boolean}
     * @memberof TiersSousclientSousclientCreate
     */
    'creerLogin'?: boolean;
    /**
     * 
     * @type {Array<AdresseSousclientCreate>}
     * @memberof TiersSousclientSousclientCreate
     */
    'adresses': Array<AdresseSousclientCreate>;
    /**
     * 
     * @type {Array<ContactSousclientCreate>}
     * @memberof TiersSousclientSousclientCreate
     */
    'contacts': Array<ContactSousclientCreate>;
    /**
     * 
     * @type {OrigineSousclientCreate}
     * @memberof TiersSousclientSousclientCreate
     */
    'origine': OrigineSousclientCreate;
    /**
     * 
     * @type {Array<RelationSocieteTiersSousclientCreate>}
     * @memberof TiersSousclientSousclientCreate
     */
    'relationsSocietes': Array<RelationSocieteTiersSousclientCreate>;
    /**
     * 
     * @type {number}
     * @memberof TiersSousclientSousclientCreate
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientCreate
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientCreate
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TiersSousclientSousclientCreate
     */
    'objectgroups'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientSousclientCreate
     */
    'fEtabEtranger'?: any;
}
/**
 * 
 * @export
 * @interface TiersSousclientSousclientCreated
 */
export interface TiersSousclientSousclientCreated {
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersSousclientSousclientCreated
     */
    'id'?: number;
    /**
     * 
     * @type {Array<AdresseSousclientCreated>}
     * @memberof TiersSousclientSousclientCreated
     */
    'adresses': Array<AdresseSousclientCreated>;
    /**
     * 
     * @type {Array<ContactSousclientCreated>}
     * @memberof TiersSousclientSousclientCreated
     */
    'contacts': Array<ContactSousclientCreated>;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientCreated
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientCreated
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TiersSousclientSousclientCreated
     */
    'objectgroups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TiersSousclientSousclientUpdate
 */
export interface TiersSousclientSousclientUpdate {
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientUpdate
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientUpdate
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientUpdate
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientUpdate
     */
    'tvaIntracommunautaire'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersSousclientSousclientUpdate
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientSousclientUpdate
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientSousclientUpdate
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientSousclientUpdate
     */
    'fEtabEtranger'?: any;
}
/**
 * 
 * @export
 * @interface TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
 */
export interface TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient {
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'tvaIntracommunautaire'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {Array<AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'adresses': Array<AdresseSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>;
    /**
     * 
     * @type {Array<ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'contacts': Array<ContactSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient>;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient
     */
    'fEtabEtranger'?: any;
}
/**
 * 
 * @export
 * @interface TiersSousclientSousclientWriteAdresses
 */
export interface TiersSousclientSousclientWriteAdresses {
    /**
     * 
     * @type {Array<AdresseSousclientWriteAdresses>}
     * @memberof TiersSousclientSousclientWriteAdresses
     */
    'adresses': Array<AdresseSousclientWriteAdresses>;
}
/**
 * 
 * @export
 * @interface TiersSousclientSousclientWriteContacts
 */
export interface TiersSousclientSousclientWriteContacts {
    /**
     * 
     * @type {Array<ContactSousclientWriteContacts>}
     * @memberof TiersSousclientSousclientWriteContacts
     */
    'contacts': Array<ContactSousclientWriteContacts>;
}
/**
 * 
 * @export
 * @interface TiersSousclientTiersRead
 */
export interface TiersSousclientTiersRead {
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersSousclientTiersRead
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersSousclientTiersRead
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersSousclientTiersRead
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersSousclientTiersRead
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientTiersRead
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientTiersRead
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientTiersRead
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientTiersRead
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientTiersRead
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientTiersRead
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersSousclientTiersRead
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientTiersRead
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientTiersRead
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {Array<AdresseTiersRead>}
     * @memberof TiersSousclientTiersRead
     */
    'adresses': Array<AdresseTiersRead>;
    /**
     * 
     * @type {Array<ContactTiersRead>}
     * @memberof TiersSousclientTiersRead
     */
    'contacts': Array<ContactTiersRead>;
    /**
     * 
     * @type {OrigineTiersRead}
     * @memberof TiersSousclientTiersRead
     */
    'origine': OrigineTiersRead;
    /**
     * 
     * @type {Array<RelationSocieteTiersTiersRead>}
     * @memberof TiersSousclientTiersRead
     */
    'relationsSocietes': Array<RelationSocieteTiersTiersRead>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientTiersRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurTiersRead}
     * @memberof TiersSousclientTiersRead
     */
    'formaliste'?: UtilisateurTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof TiersSousclientTiersRead
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersSousclientTiersRead
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientTiersRead
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientTiersRead
     */
    'dateMAJ'?: any;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientTiersRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersSousclientTiersRead
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<ObjectGroupTiersRead>}
     * @memberof TiersSousclientTiersRead
     */
    'objectgroups'?: Array<ObjectGroupTiersRead>;
    /**
     * 
     * @type {Array<TblCollectionDossierTiersRead>}
     * @memberof TiersSousclientTiersRead
     */
    'fCollectionDossier'?: Array<TblCollectionDossierTiersRead>;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientTiersRead
     */
    'domaines'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientTiersRead
     */
    'fEtabEtranger'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientTiersRead
     */
    'firstnamePrescripteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersSousclientTiersRead
     */
    'lastnamePrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface TiersTiersEditObjectGroup
 */
export interface TiersTiersEditObjectGroup {
    /**
     * 
     * @type {number}
     * @memberof TiersTiersEditObjectGroup
     */
    'id'?: number;
    /**
     * 
     * @type {Array<ObjectGroupTiersEditObjectGroup>}
     * @memberof TiersTiersEditObjectGroup
     */
    'objectgroups'?: Array<ObjectGroupTiersEditObjectGroup>;
}
/**
 * 
 * @export
 * @interface TiersTiersEditedObjectGroup
 */
export interface TiersTiersEditedObjectGroup {
    /**
     * 
     * @type {Array<string>}
     * @memberof TiersTiersEditedObjectGroup
     */
    'objectgroups'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TiersTiersJsonldPrescripteurReadCommerciaux
 */
export interface TiersTiersJsonldPrescripteurReadCommerciaux {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersTiersJsonldPrescripteurReadCommerciaux
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldPrescripteurReadCommerciaux
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldPrescripteurReadCommerciaux
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<CommercialJsonldPrescripteurReadCommerciaux>}
     * @memberof TiersTiersJsonldPrescripteurReadCommerciaux
     */
    'commerciaux'?: Array<CommercialJsonldPrescripteurReadCommerciaux>;
}
/**
 * 
 * @export
 * @interface TiersTiersJsonldPrescripteurReadFournisseurs
 */
export interface TiersTiersJsonldPrescripteurReadFournisseurs {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersTiersJsonldPrescripteurReadFournisseurs
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldPrescripteurReadFournisseurs
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldPrescripteurReadFournisseurs
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<FournisseurJsonldPrescripteurReadFournisseurs>}
     * @memberof TiersTiersJsonldPrescripteurReadFournisseurs
     */
    'fournisseurs'?: Array<FournisseurJsonldPrescripteurReadFournisseurs>;
}
/**
 * 
 * @export
 * @interface TiersTiersJsonldTiersRead
 */
export interface TiersTiersJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersTiersJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    '@type'?: string;
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersTiersJsonldTiersRead
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersRead
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersRead
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersRead
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersRead
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersRead
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersRead
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {Array<AdresseJsonldTiersRead>}
     * @memberof TiersTiersJsonldTiersRead
     */
    'adresses': Array<AdresseJsonldTiersRead>;
    /**
     * 
     * @type {Array<ContactJsonldTiersRead>}
     * @memberof TiersTiersJsonldTiersRead
     */
    'contacts': Array<ContactJsonldTiersRead>;
    /**
     * 
     * @type {OrigineJsonldTiersRead}
     * @memberof TiersTiersJsonldTiersRead
     */
    'origine': OrigineJsonldTiersRead;
    /**
     * 
     * @type {Array<RelationSocieteTiersJsonldTiersRead>}
     * @memberof TiersTiersJsonldTiersRead
     */
    'relationsSocietes': Array<RelationSocieteTiersJsonldTiersRead>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurJsonldTiersRead}
     * @memberof TiersTiersJsonldTiersRead
     */
    'formaliste'?: UtilisateurJsonldTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersRead
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersTiersJsonldTiersRead
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersRead
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersRead
     */
    'dateMAJ'?: any;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersRead
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<ObjectGroupJsonldTiersRead>}
     * @memberof TiersTiersJsonldTiersRead
     */
    'objectgroups'?: Array<ObjectGroupJsonldTiersRead>;
    /**
     * 
     * @type {Array<TblCollectionDossierJsonldTiersRead>}
     * @memberof TiersTiersJsonldTiersRead
     */
    'fCollectionDossier'?: Array<TblCollectionDossierJsonldTiersRead>;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersRead
     */
    'domaines'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersRead
     */
    'fEtabEtranger'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersRead
     */
    'firstnamePrescripteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersRead
     */
    'lastnamePrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface TiersTiersJsonldTiersReadTiersReadExpanded
 */
export interface TiersTiersJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {Array<AdresseJsonldTiersReadTiersReadExpanded>}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'adresses': Array<AdresseJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<ContactJsonldTiersReadTiersReadExpanded>}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'contacts': Array<ContactJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {OrigineJsonldTiersReadTiersReadExpanded}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'origine': OrigineJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {Array<RelationSocieteTiersJsonldTiersReadTiersReadExpanded>}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'relationsSocietes': Array<RelationSocieteTiersJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurJsonldTiersReadTiersReadExpanded}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'formaliste'?: UtilisateurJsonldTiersReadTiersReadExpanded;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'dateMAJ'?: any;
    /**
     * 
     * @type {Array<FournisseurJsonldTiersReadTiersReadExpanded>}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'fournisseurs'?: Array<FournisseurJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<CommercialJsonldTiersReadTiersReadExpanded>}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'commerciaux'?: Array<CommercialJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<ObjectGroupJsonldTiersReadTiersReadExpanded>}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'objectgroups'?: Array<ObjectGroupJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<TblCollectionDossierJsonldTiersReadTiersReadExpanded>}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'fCollectionDossier'?: Array<TblCollectionDossierJsonldTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'domaines'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'fEtabEtranger'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'firstnamePrescripteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersJsonldTiersReadTiersReadExpanded
     */
    'lastnamePrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface TiersTiersJsonldTiersWriteNote
 */
export interface TiersTiersJsonldTiersWriteNote {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TiersTiersJsonldTiersWriteNote
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersWriteNote
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersWriteNote
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersWriteNote
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersJsonldTiersWriteNote
     */
    'noteFormaliste'?: string;
}
/**
 * 
 * @export
 * @interface TiersTiersPrescripteurReadCommerciaux
 */
export interface TiersTiersPrescripteurReadCommerciaux {
    /**
     * 
     * @type {Array<CommercialPrescripteurReadCommerciaux>}
     * @memberof TiersTiersPrescripteurReadCommerciaux
     */
    'commerciaux'?: Array<CommercialPrescripteurReadCommerciaux>;
}
/**
 * 
 * @export
 * @interface TiersTiersPrescripteurReadFournisseurs
 */
export interface TiersTiersPrescripteurReadFournisseurs {
    /**
     * 
     * @type {Array<FournisseurPrescripteurReadFournisseurs>}
     * @memberof TiersTiersPrescripteurReadFournisseurs
     */
    'fournisseurs'?: Array<FournisseurPrescripteurReadFournisseurs>;
}
/**
 * 
 * @export
 * @interface TiersTiersRead
 */
export interface TiersTiersRead {
    /**
     * 
     * @type {string}
     * @memberof TiersTiersRead
     */
    'firstnamePrescripteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersRead
     */
    'lastnamePrescripteur'?: string | null;
}
/**
 * 
 * @export
 * @interface TiersTiersReadTiersReadExpanded
 */
export interface TiersTiersReadTiersReadExpanded {
    /**
     * 
     * @type {string}
     * @memberof TiersTiersReadTiersReadExpanded
     */
    'firstnamePrescripteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersReadTiersReadExpanded
     */
    'lastnamePrescripteur'?: string | null;
}
/**
 * 
 * @export
 * @interface TiersTiersTiersRead
 */
export interface TiersTiersTiersRead {
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersTiersTiersRead
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersTiersTiersRead
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersTiersTiersRead
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersTiersTiersRead
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersRead
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersRead
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersRead
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersRead
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersRead
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersRead
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersTiersTiersRead
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersRead
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersRead
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersRead
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {Array<AdresseTiersRead>}
     * @memberof TiersTiersTiersRead
     */
    'adresses': Array<AdresseTiersRead>;
    /**
     * 
     * @type {Array<ContactTiersRead>}
     * @memberof TiersTiersTiersRead
     */
    'contacts': Array<ContactTiersRead>;
    /**
     * 
     * @type {OrigineTiersRead}
     * @memberof TiersTiersTiersRead
     */
    'origine': OrigineTiersRead;
    /**
     * 
     * @type {Array<RelationSocieteTiersTiersRead>}
     * @memberof TiersTiersTiersRead
     */
    'relationsSocietes': Array<RelationSocieteTiersTiersRead>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurTiersRead}
     * @memberof TiersTiersTiersRead
     */
    'formaliste'?: UtilisateurTiersRead;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersRead
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersTiersTiersRead
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersRead
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersRead
     */
    'dateMAJ'?: any;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersRead
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersRead
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<ObjectGroupTiersRead>}
     * @memberof TiersTiersTiersRead
     */
    'objectgroups'?: Array<ObjectGroupTiersRead>;
    /**
     * 
     * @type {Array<TblCollectionDossierTiersRead>}
     * @memberof TiersTiersTiersRead
     */
    'fCollectionDossier'?: Array<TblCollectionDossierTiersRead>;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersRead
     */
    'domaines'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersRead
     */
    'fEtabEtranger'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersRead
     */
    'firstnamePrescripteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersRead
     */
    'lastnamePrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface TiersTiersTiersReadTiersReadExpanded
 */
export interface TiersTiersTiersReadTiersReadExpanded {
    /**
     * identifiant du prescripteur
     * @type {number}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * Le tiers est un prescripteur ?
     * @type {boolean}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'isPrescripteur'?: boolean;
    /**
     * Le tiers est un sousclient ?
     * @type {boolean}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'isSousclient'?: boolean;
    /**
     * Le tiers est un fournisseur ?
     * @type {boolean}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'isFournisseur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'raisonSociale': string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'siret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'tvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'regimeTva'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'urlSocieteDotCom'?: string;
    /**
     * true si personne physique, false si personne morale
     * @type {boolean}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'personnePhysique': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'demo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'clientSuivi'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {Array<AdresseTiersReadTiersReadExpanded>}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'adresses': Array<AdresseTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<ContactTiersReadTiersReadExpanded>}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'contacts': Array<ContactTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {OrigineTiersReadTiersReadExpanded}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'origine': OrigineTiersReadTiersReadExpanded;
    /**
     * 
     * @type {Array<RelationSocieteTiersTiersReadTiersReadExpanded>}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'relationsSocietes': Array<RelationSocieteTiersTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {UtilisateurTiersReadTiersReadExpanded}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'formaliste'?: UtilisateurTiersReadTiersReadExpanded;
    /**
     * 
     * @type {boolean}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'chorus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'idPrescripteur': number;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'dateMAJ'?: any;
    /**
     * 
     * @type {Array<FournisseurTiersReadTiersReadExpanded>}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'fournisseurs'?: Array<FournisseurTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'noteFormaliste'?: string;
    /**
     * 
     * @type {Array<CommercialTiersReadTiersReadExpanded>}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'commerciaux'?: Array<CommercialTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<ObjectGroupTiersReadTiersReadExpanded>}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'objectgroups'?: Array<ObjectGroupTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {Array<TblCollectionDossierTiersReadTiersReadExpanded>}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'fCollectionDossier'?: Array<TblCollectionDossierTiersReadTiersReadExpanded>;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'domaines'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'fEtabEtranger'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'firstnamePrescripteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof TiersTiersTiersReadTiersReadExpanded
     */
    'lastnamePrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface TiersTiersTiersWriteNote
 */
export interface TiersTiersTiersWriteNote {
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersWriteNote
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiersTiersTiersWriteNote
     */
    'noteFormaliste'?: string;
}
/**
 * 
 * @export
 * @interface TypeRemiseFournisseurRead
 */
export interface TypeRemiseFournisseurRead {
    /**
     * 
     * @type {number}
     * @memberof TypeRemiseFournisseurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseFournisseurRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseFournisseurRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TypeRemiseFournisseurReadFournisseurReadTiers
 */
export interface TypeRemiseFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {number}
     * @memberof TypeRemiseFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseFournisseurReadFournisseurReadTiers
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseFournisseurReadFournisseurReadTiers
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TypeRemiseJsonldFournisseurRead
 */
export interface TypeRemiseJsonldFournisseurRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TypeRemiseJsonldFournisseurRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldFournisseurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldFournisseurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeRemiseJsonldFournisseurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldFournisseurRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldFournisseurRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TypeRemiseJsonldFournisseurReadFournisseurReadTiers
 */
export interface TypeRemiseJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TypeRemiseJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeRemiseJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldFournisseurReadFournisseurReadTiers
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldFournisseurReadFournisseurReadTiers
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TypeRemiseJsonldPrescripteurReadFournisseurs
 */
export interface TypeRemiseJsonldPrescripteurReadFournisseurs {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof TypeRemiseJsonldPrescripteurReadFournisseurs
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldPrescripteurReadFournisseurs
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldPrescripteurReadFournisseurs
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeRemiseJsonldPrescripteurReadFournisseurs
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldPrescripteurReadFournisseurs
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeRemiseJsonldPrescripteurReadFournisseurs
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TypeRemisePrescripteurReadFournisseurs
 */
export interface TypeRemisePrescripteurReadFournisseurs {
    /**
     * 
     * @type {number}
     * @memberof TypeRemisePrescripteurReadFournisseurs
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeRemisePrescripteurReadFournisseurs
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeRemisePrescripteurReadFournisseurs
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAdresseAdressesItem400Response
 */
export interface UpdateAdresseAdressesItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdresseAdressesItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateAdresseAdressesItem400ResponseException}
     * @memberof UpdateAdresseAdressesItem400Response
     */
    'exception': UpdateAdresseAdressesItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateAdresseAdressesItem400ResponseException
 */
export interface UpdateAdresseAdressesItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdresseAdressesItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdresseAdressesItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateAdresseAdressesItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateAdresseAdressesItem400ResponseException
     */
    'violations'?: Array<UpdateAdresseAdressesItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateAdresseAdressesItem400ResponseExceptionViolationsInner
 */
export interface UpdateAdresseAdressesItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateAdresseAdressesItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateAdresseAdressesItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAdressesPrescripteurTiersItem400Response
 */
export interface UpdateAdressesPrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdressesPrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateAdressesPrescripteurTiersItem400ResponseException}
     * @memberof UpdateAdressesPrescripteurTiersItem400Response
     */
    'exception': UpdateAdressesPrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateAdressesPrescripteurTiersItem400ResponseException
 */
export interface UpdateAdressesPrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdressesPrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdressesPrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateAdressesPrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateAdressesPrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdateAdressesPrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateAdressesPrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateAdressesPrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateAdressesPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateAdressesPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400Response
 */
export interface UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseException}
     * @memberof UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400Response
     */
    'exception': UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseException
 */
export interface UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateConditionsCommercialesFormalitesPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateContactContactsItem400Response
 */
export interface UpdateContactContactsItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateContactContactsItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateContactContactsItem400ResponseException}
     * @memberof UpdateContactContactsItem400Response
     */
    'exception': UpdateContactContactsItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateContactContactsItem400ResponseException
 */
export interface UpdateContactContactsItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateContactContactsItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateContactContactsItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateContactContactsItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateContactContactsItem400ResponseException
     */
    'violations'?: Array<UpdateContactContactsItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateContactContactsItem400ResponseExceptionViolationsInner
 */
export interface UpdateContactContactsItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateContactContactsItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateContactContactsItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateContactsPrescripteurTiersItem400Response
 */
export interface UpdateContactsPrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateContactsPrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateContactsPrescripteurTiersItem400ResponseException}
     * @memberof UpdateContactsPrescripteurTiersItem400Response
     */
    'exception': UpdateContactsPrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateContactsPrescripteurTiersItem400ResponseException
 */
export interface UpdateContactsPrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateContactsPrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateContactsPrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateContactsPrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateContactsPrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdateContactsPrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateContactsPrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateContactsPrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateContactsPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateContactsPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDroitsPrescripteurTiersItem400Response
 */
export interface UpdateDroitsPrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateDroitsPrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateDroitsPrescripteurTiersItem400ResponseException}
     * @memberof UpdateDroitsPrescripteurTiersItem400Response
     */
    'exception': UpdateDroitsPrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateDroitsPrescripteurTiersItem400ResponseException
 */
export interface UpdateDroitsPrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateDroitsPrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDroitsPrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateDroitsPrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateDroitsPrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdateDroitsPrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateDroitsPrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateDroitsPrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateDroitsPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateDroitsPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDroitsPrescripteurTiersItemRequest
 */
export interface UpdateDroitsPrescripteurTiersItemRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDroitsPrescripteurTiersItemRequest
     */
    'accesPlateformeB2B'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateEmailCoordonneesItem400Response
 */
export interface UpdateEmailCoordonneesItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailCoordonneesItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateEmailCoordonneesItem400ResponseException}
     * @memberof UpdateEmailCoordonneesItem400Response
     */
    'exception': UpdateEmailCoordonneesItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateEmailCoordonneesItem400ResponseException
 */
export interface UpdateEmailCoordonneesItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailCoordonneesItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmailCoordonneesItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateEmailCoordonneesItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateEmailCoordonneesItem400ResponseException
     */
    'violations'?: Array<UpdateEmailCoordonneesItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateEmailCoordonneesItem400ResponseExceptionViolationsInner
 */
export interface UpdateEmailCoordonneesItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateEmailCoordonneesItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateEmailCoordonneesItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEmailsContactPrescripteurContactsItem400Response
 */
export interface UpdateEmailsContactPrescripteurContactsItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailsContactPrescripteurContactsItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateEmailsContactPrescripteurContactsItem400ResponseException}
     * @memberof UpdateEmailsContactPrescripteurContactsItem400Response
     */
    'exception': UpdateEmailsContactPrescripteurContactsItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateEmailsContactPrescripteurContactsItem400ResponseException
 */
export interface UpdateEmailsContactPrescripteurContactsItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailsContactPrescripteurContactsItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmailsContactPrescripteurContactsItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateEmailsContactPrescripteurContactsItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateEmailsContactPrescripteurContactsItem400ResponseException
     */
    'violations'?: Array<UpdateEmailsContactPrescripteurContactsItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateEmailsContactPrescripteurContactsItem400ResponseExceptionViolationsInner
 */
export interface UpdateEmailsContactPrescripteurContactsItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateEmailsContactPrescripteurContactsItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateEmailsContactPrescripteurContactsItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEmailsNotificationsPrescripteurTiersItem400Response
 */
export interface UpdateEmailsNotificationsPrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailsNotificationsPrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateEmailsNotificationsPrescripteurTiersItem400ResponseException}
     * @memberof UpdateEmailsNotificationsPrescripteurTiersItem400Response
     */
    'exception': UpdateEmailsNotificationsPrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateEmailsNotificationsPrescripteurTiersItem400ResponseException
 */
export interface UpdateEmailsNotificationsPrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailsNotificationsPrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmailsNotificationsPrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateEmailsNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateEmailsNotificationsPrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdateEmailsNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateEmailsNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateEmailsNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateEmailsNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateEmailsNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateFaxCoordonneesItem400Response
 */
export interface UpdateFaxCoordonneesItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateFaxCoordonneesItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateFaxCoordonneesItem400ResponseException}
     * @memberof UpdateFaxCoordonneesItem400Response
     */
    'exception': UpdateFaxCoordonneesItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateFaxCoordonneesItem400ResponseException
 */
export interface UpdateFaxCoordonneesItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateFaxCoordonneesItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFaxCoordonneesItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateFaxCoordonneesItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateFaxCoordonneesItem400ResponseException
     */
    'violations'?: Array<UpdateFaxCoordonneesItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateFaxCoordonneesItem400ResponseExceptionViolationsInner
 */
export interface UpdateFaxCoordonneesItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateFaxCoordonneesItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateFaxCoordonneesItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateFaxsContactPrescripteurContactsItem400Response
 */
export interface UpdateFaxsContactPrescripteurContactsItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateFaxsContactPrescripteurContactsItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateFaxsContactPrescripteurContactsItem400ResponseException}
     * @memberof UpdateFaxsContactPrescripteurContactsItem400Response
     */
    'exception': UpdateFaxsContactPrescripteurContactsItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateFaxsContactPrescripteurContactsItem400ResponseException
 */
export interface UpdateFaxsContactPrescripteurContactsItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateFaxsContactPrescripteurContactsItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFaxsContactPrescripteurContactsItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateFaxsContactPrescripteurContactsItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateFaxsContactPrescripteurContactsItem400ResponseException
     */
    'violations'?: Array<UpdateFaxsContactPrescripteurContactsItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateFaxsContactPrescripteurContactsItem400ResponseExceptionViolationsInner
 */
export interface UpdateFaxsContactPrescripteurContactsItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateFaxsContactPrescripteurContactsItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateFaxsContactPrescripteurContactsItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateFormalistePrescripteurTiersItem400Response
 */
export interface UpdateFormalistePrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateFormalistePrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateFormalistePrescripteurTiersItem400ResponseException}
     * @memberof UpdateFormalistePrescripteurTiersItem400Response
     */
    'exception': UpdateFormalistePrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateFormalistePrescripteurTiersItem400ResponseException
 */
export interface UpdateFormalistePrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateFormalistePrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFormalistePrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateFormalistePrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateFormalistePrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdateFormalistePrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateFormalistePrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateFormalistePrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateFormalistePrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateFormalistePrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateFrequencesNotificationsPrescripteurTiersItem400Response
 */
export interface UpdateFrequencesNotificationsPrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateFrequencesNotificationsPrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseException}
     * @memberof UpdateFrequencesNotificationsPrescripteurTiersItem400Response
     */
    'exception': UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseException
 */
export interface UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateFrequencesNotificationsPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateGrilleForfaitairePrescripteurTiersItem400Response
 */
export interface UpdateGrilleForfaitairePrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateGrilleForfaitairePrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateGrilleForfaitairePrescripteurTiersItem400ResponseException}
     * @memberof UpdateGrilleForfaitairePrescripteurTiersItem400Response
     */
    'exception': UpdateGrilleForfaitairePrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateGrilleForfaitairePrescripteurTiersItem400ResponseException
 */
export interface UpdateGrilleForfaitairePrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateGrilleForfaitairePrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateGrilleForfaitairePrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateGrilleForfaitairePrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateGrilleForfaitairePrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdateGrilleForfaitairePrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateGrilleForfaitairePrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateGrilleForfaitairePrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateGrilleForfaitairePrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateGrilleForfaitairePrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateInfoClearDocPrescripteurTiersItem400Response
 */
export interface UpdateInfoClearDocPrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateInfoClearDocPrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateInfoClearDocPrescripteurTiersItem400ResponseException}
     * @memberof UpdateInfoClearDocPrescripteurTiersItem400Response
     */
    'exception': UpdateInfoClearDocPrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateInfoClearDocPrescripteurTiersItem400ResponseException
 */
export interface UpdateInfoClearDocPrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateInfoClearDocPrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateInfoClearDocPrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateInfoClearDocPrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateInfoClearDocPrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdateInfoClearDocPrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateInfoClearDocPrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateInfoClearDocPrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateInfoClearDocPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateInfoClearDocPrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMobileCoordonneesItem400Response
 */
export interface UpdateMobileCoordonneesItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateMobileCoordonneesItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateMobileCoordonneesItem400ResponseException}
     * @memberof UpdateMobileCoordonneesItem400Response
     */
    'exception': UpdateMobileCoordonneesItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateMobileCoordonneesItem400ResponseException
 */
export interface UpdateMobileCoordonneesItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateMobileCoordonneesItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMobileCoordonneesItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateMobileCoordonneesItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateMobileCoordonneesItem400ResponseException
     */
    'violations'?: Array<UpdateMobileCoordonneesItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateMobileCoordonneesItem400ResponseExceptionViolationsInner
 */
export interface UpdateMobileCoordonneesItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateMobileCoordonneesItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateMobileCoordonneesItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMobilesContactPrescripteurContactsItem400Response
 */
export interface UpdateMobilesContactPrescripteurContactsItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateMobilesContactPrescripteurContactsItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateMobilesContactPrescripteurContactsItem400ResponseException}
     * @memberof UpdateMobilesContactPrescripteurContactsItem400Response
     */
    'exception': UpdateMobilesContactPrescripteurContactsItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateMobilesContactPrescripteurContactsItem400ResponseException
 */
export interface UpdateMobilesContactPrescripteurContactsItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateMobilesContactPrescripteurContactsItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMobilesContactPrescripteurContactsItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateMobilesContactPrescripteurContactsItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateMobilesContactPrescripteurContactsItem400ResponseException
     */
    'violations'?: Array<UpdateMobilesContactPrescripteurContactsItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateMobilesContactPrescripteurContactsItem400ResponseExceptionViolationsInner
 */
export interface UpdateMobilesContactPrescripteurContactsItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateMobilesContactPrescripteurContactsItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateMobilesContactPrescripteurContactsItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateNoteTiersTiersItem400Response
 */
export interface UpdateNoteTiersTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteTiersTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateNoteTiersTiersItem400ResponseException}
     * @memberof UpdateNoteTiersTiersItem400Response
     */
    'exception': UpdateNoteTiersTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateNoteTiersTiersItem400ResponseException
 */
export interface UpdateNoteTiersTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteTiersTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNoteTiersTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateNoteTiersTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateNoteTiersTiersItem400ResponseException
     */
    'violations'?: Array<UpdateNoteTiersTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateNoteTiersTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateNoteTiersTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateNoteTiersTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateNoteTiersTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePrescripteurTiersItem400Response
 */
export interface UpdatePrescripteurTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdatePrescripteurTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdatePrescripteurTiersItem400ResponseException}
     * @memberof UpdatePrescripteurTiersItem400Response
     */
    'exception': UpdatePrescripteurTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdatePrescripteurTiersItem400ResponseException
 */
export interface UpdatePrescripteurTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdatePrescripteurTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePrescripteurTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdatePrescripteurTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdatePrescripteurTiersItem400ResponseException
     */
    'violations'?: Array<UpdatePrescripteurTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdatePrescripteurTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdatePrescripteurTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdatePrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdatePrescripteurTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRelSousClientObjectGroupTiersItem400Response
 */
export interface UpdateRelSousClientObjectGroupTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateRelSousClientObjectGroupTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateRelSousClientObjectGroupTiersItem400ResponseException}
     * @memberof UpdateRelSousClientObjectGroupTiersItem400Response
     */
    'exception': UpdateRelSousClientObjectGroupTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateRelSousClientObjectGroupTiersItem400ResponseException
 */
export interface UpdateRelSousClientObjectGroupTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateRelSousClientObjectGroupTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRelSousClientObjectGroupTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateRelSousClientObjectGroupTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateRelSousClientObjectGroupTiersItem400ResponseException
     */
    'violations'?: Array<UpdateRelSousClientObjectGroupTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateRelSousClientObjectGroupTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateRelSousClientObjectGroupTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateRelSousClientObjectGroupTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateRelSousClientObjectGroupTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRelationSocietePrescripteurRelationSocieteTiersItem400Response
 */
export interface UpdateRelationSocietePrescripteurRelationSocieteTiersItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateRelationSocietePrescripteurRelationSocieteTiersItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseException}
     * @memberof UpdateRelationSocietePrescripteurRelationSocieteTiersItem400Response
     */
    'exception': UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseException
 */
export interface UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseException
     */
    'violations'?: Array<UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseExceptionViolationsInner
 */
export interface UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateRelationSocietePrescripteurRelationSocieteTiersItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTelephoneCoordonneesItem400Response
 */
export interface UpdateTelephoneCoordonneesItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateTelephoneCoordonneesItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateTelephoneCoordonneesItem400ResponseException}
     * @memberof UpdateTelephoneCoordonneesItem400Response
     */
    'exception': UpdateTelephoneCoordonneesItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateTelephoneCoordonneesItem400ResponseException
 */
export interface UpdateTelephoneCoordonneesItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateTelephoneCoordonneesItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTelephoneCoordonneesItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateTelephoneCoordonneesItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateTelephoneCoordonneesItem400ResponseException
     */
    'violations'?: Array<UpdateTelephoneCoordonneesItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateTelephoneCoordonneesItem400ResponseExceptionViolationsInner
 */
export interface UpdateTelephoneCoordonneesItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateTelephoneCoordonneesItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateTelephoneCoordonneesItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTelephonesContactPrescripteurContactsItem400Response
 */
export interface UpdateTelephonesContactPrescripteurContactsItem400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateTelephonesContactPrescripteurContactsItem400Response
     */
    'error': string;
    /**
     * 
     * @type {UpdateTelephonesContactPrescripteurContactsItem400ResponseException}
     * @memberof UpdateTelephonesContactPrescripteurContactsItem400Response
     */
    'exception': UpdateTelephonesContactPrescripteurContactsItem400ResponseException;
}
/**
 * 
 * @export
 * @interface UpdateTelephonesContactPrescripteurContactsItem400ResponseException
 */
export interface UpdateTelephonesContactPrescripteurContactsItem400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof UpdateTelephonesContactPrescripteurContactsItem400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTelephonesContactPrescripteurContactsItem400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<UpdateTelephonesContactPrescripteurContactsItem400ResponseExceptionViolationsInner>}
     * @memberof UpdateTelephonesContactPrescripteurContactsItem400ResponseException
     */
    'violations'?: Array<UpdateTelephonesContactPrescripteurContactsItem400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface UpdateTelephonesContactPrescripteurContactsItem400ResponseExceptionViolationsInner
 */
export interface UpdateTelephonesContactPrescripteurContactsItem400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof UpdateTelephonesContactPrescripteurContactsItem400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof UpdateTelephonesContactPrescripteurContactsItem400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UtilisateurFournisseurReadFournisseurReadTiers
 */
export interface UtilisateurFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {number}
     * @memberof UtilisateurFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurFournisseurReadFournisseurReadTiers
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurFournisseurReadFournisseurReadTiers
     */
    'prenom'?: string;
}
/**
 * 
 * @export
 * @interface UtilisateurJsonldFournisseurReadFournisseurReadTiers
 */
export interface UtilisateurJsonldFournisseurReadFournisseurReadTiers {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof UtilisateurJsonldFournisseurReadFournisseurReadTiers
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldFournisseurReadFournisseurReadTiers
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldFournisseurReadFournisseurReadTiers
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateurJsonldFournisseurReadFournisseurReadTiers
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldFournisseurReadFournisseurReadTiers
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldFournisseurReadFournisseurReadTiers
     */
    'prenom'?: string;
}
/**
 * 
 * @export
 * @interface UtilisateurJsonldPrescripteurReadCommerciaux
 */
export interface UtilisateurJsonldPrescripteurReadCommerciaux {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    'portable'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurReadCommerciaux
     */
    'poste'?: string;
}
/**
 * 
 * @export
 * @interface UtilisateurJsonldPrescripteurWriteCommercial
 */
export interface UtilisateurJsonldPrescripteurWriteCommercial {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof UtilisateurJsonldPrescripteurWriteCommercial
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurWriteCommercial
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurWriteCommercial
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateurJsonldPrescripteurWriteCommercial
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface UtilisateurJsonldPrescripteurWriteFormaliste
 */
export interface UtilisateurJsonldPrescripteurWriteFormaliste {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof UtilisateurJsonldPrescripteurWriteFormaliste
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurWriteFormaliste
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldPrescripteurWriteFormaliste
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateurJsonldPrescripteurWriteFormaliste
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface UtilisateurJsonldTiersRead
 */
export interface UtilisateurJsonldTiersRead {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof UtilisateurJsonldTiersRead
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateurJsonldTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersRead
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersRead
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersRead
     */
    'portable'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersRead
     */
    'poste'?: string;
}
/**
 * 
 * @export
 * @interface UtilisateurJsonldTiersReadTiersReadExpanded
 */
export interface UtilisateurJsonldTiersReadTiersReadExpanded {
    /**
     * 
     * @type {AdresseJsonldFournisseurReadFournisseurReadTiersContext}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    '@context'?: AdresseJsonldFournisseurReadFournisseurReadTiersContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    'portable'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurJsonldTiersReadTiersReadExpanded
     */
    'poste'?: string;
}
/**
 * 
 * @export
 * @interface UtilisateurPrescripteurReadCommerciaux
 */
export interface UtilisateurPrescripteurReadCommerciaux {
    /**
     * 
     * @type {number}
     * @memberof UtilisateurPrescripteurReadCommerciaux
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurPrescripteurReadCommerciaux
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurPrescripteurReadCommerciaux
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurPrescripteurReadCommerciaux
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurPrescripteurReadCommerciaux
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurPrescripteurReadCommerciaux
     */
    'portable'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurPrescripteurReadCommerciaux
     */
    'poste'?: string;
}
/**
 * 
 * @export
 * @interface UtilisateurPrescripteurWriteCommercial
 */
export interface UtilisateurPrescripteurWriteCommercial {
    /**
     * 
     * @type {number}
     * @memberof UtilisateurPrescripteurWriteCommercial
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface UtilisateurPrescripteurWriteFormaliste
 */
export interface UtilisateurPrescripteurWriteFormaliste {
    /**
     * 
     * @type {number}
     * @memberof UtilisateurPrescripteurWriteFormaliste
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface UtilisateurTiersRead
 */
export interface UtilisateurTiersRead {
    /**
     * 
     * @type {number}
     * @memberof UtilisateurTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersRead
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersRead
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersRead
     */
    'portable'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersRead
     */
    'poste'?: string;
}
/**
 * 
 * @export
 * @interface UtilisateurTiersReadTiersReadExpanded
 */
export interface UtilisateurTiersReadTiersReadExpanded {
    /**
     * 
     * @type {number}
     * @memberof UtilisateurTiersReadTiersReadExpanded
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersReadTiersReadExpanded
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersReadTiersReadExpanded
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersReadTiersReadExpanded
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersReadTiersReadExpanded
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersReadTiersReadExpanded
     */
    'portable'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurTiersReadTiersReadExpanded
     */
    'poste'?: string;
}

/**
 * AdressesApi - axios parameter creator
 * @export
 */
export const AdressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer une nouvelle adresse pour un prescripteur
         * @summary Créer une nouvelle adresse pour un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {AdressesAdresseJsonldAdresseCreate} adressesAdresseJsonldAdresseCreate The new Adresses resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurAdresseAdressesCollection: async (tiers: number, adressesAdresseJsonldAdresseCreate: AdressesAdresseJsonldAdresseCreate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('createPrescripteurAdresseAdressesCollection', 'tiers', tiers)
            // verify required parameter 'adressesAdresseJsonldAdresseCreate' is not null or undefined
            assertParamExists('createPrescripteurAdresseAdressesCollection', 'adressesAdresseJsonldAdresseCreate', adressesAdresseJsonldAdresseCreate)
            const localVarPath = `/tiers/prescripteurs/{tiers}/adresses`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adressesAdresseJsonldAdresseCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une adresse
         * @summary Récupérer les informations d\'une adresse
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdresseAdressesItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdresseAdressesItem', 'id', id)
            const localVarPath = `/tiers/adresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier une adresse
         * @summary Modifier une adresse
         * @param {string} id Resource identifier
         * @param {AdressesAdresseJsonldAdresseUpdate} adressesAdresseJsonldAdresseUpdate The updated Adresses resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdresseAdressesItem: async (id: string, adressesAdresseJsonldAdresseUpdate: AdressesAdresseJsonldAdresseUpdate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdresseAdressesItem', 'id', id)
            // verify required parameter 'adressesAdresseJsonldAdresseUpdate' is not null or undefined
            assertParamExists('updateAdresseAdressesItem', 'adressesAdresseJsonldAdresseUpdate', adressesAdresseJsonldAdresseUpdate)
            const localVarPath = `/tiers/adresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adressesAdresseJsonldAdresseUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdressesApi - functional programming interface
 * @export
 */
export const AdressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdressesApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer une nouvelle adresse pour un prescripteur
         * @summary Créer une nouvelle adresse pour un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {AdressesAdresseJsonldAdresseCreate} adressesAdresseJsonldAdresseCreate The new Adresses resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPrescripteurAdresseAdressesCollection(tiers: number, adressesAdresseJsonldAdresseCreate: AdressesAdresseJsonldAdresseCreate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdressesAdresseJsonldAdresseRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrescripteurAdresseAdressesCollection(tiers, adressesAdresseJsonldAdresseCreate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdressesApi.createPrescripteurAdresseAdressesCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une adresse
         * @summary Récupérer les informations d\'une adresse
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdresseAdressesItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdressesAdresseJsonldAdresseRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdresseAdressesItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdressesApi.getAdresseAdressesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier une adresse
         * @summary Modifier une adresse
         * @param {string} id Resource identifier
         * @param {AdressesAdresseJsonldAdresseUpdate} adressesAdresseJsonldAdresseUpdate The updated Adresses resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdresseAdressesItem(id: string, adressesAdresseJsonldAdresseUpdate: AdressesAdresseJsonldAdresseUpdate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdressesAdresseJsonldAdresseRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdresseAdressesItem(id, adressesAdresseJsonldAdresseUpdate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdressesApi.updateAdresseAdressesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdressesApi - factory interface
 * @export
 */
export const AdressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdressesApiFp(configuration)
    return {
        /**
         * Créer une nouvelle adresse pour un prescripteur
         * @summary Créer une nouvelle adresse pour un prescripteur
         * @param {AdressesApiCreatePrescripteurAdresseAdressesCollection0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurAdresseAdressesCollection(requestParameters: AdressesApiCreatePrescripteurAdresseAdressesCollection0Request, options?: RawAxiosRequestConfig): AxiosPromise<AdressesAdresseJsonldAdresseRead> {
            return localVarFp.createPrescripteurAdresseAdressesCollection(requestParameters.tiers, requestParameters.adressesAdresseJsonldAdresseCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une adresse
         * @summary Récupérer les informations d\'une adresse
         * @param {AdressesApiGetAdresseAdressesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdresseAdressesItem(requestParameters: AdressesApiGetAdresseAdressesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdressesAdresseJsonldAdresseRead> {
            return localVarFp.getAdresseAdressesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier une adresse
         * @summary Modifier une adresse
         * @param {AdressesApiUpdateAdresseAdressesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdresseAdressesItem(requestParameters: AdressesApiUpdateAdresseAdressesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdressesAdresseJsonldAdresseRead> {
            return localVarFp.updateAdresseAdressesItem(requestParameters.id, requestParameters.adressesAdresseJsonldAdresseUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPrescripteurAdresseAdressesCollection operation in AdressesApi.
 * @export
 * @interface AdressesApiCreatePrescripteurAdresseAdressesCollection0Request
 */
export interface AdressesApiCreatePrescripteurAdresseAdressesCollection0Request {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof AdressesApiCreatePrescripteurAdresseAdressesCollection0
     */
    readonly tiers: number

    /**
     * The new Adresses resource
     * @type {AdressesAdresseJsonldAdresseCreate}
     * @memberof AdressesApiCreatePrescripteurAdresseAdressesCollection0
     */
    readonly adressesAdresseJsonldAdresseCreate: AdressesAdresseJsonldAdresseCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AdressesApiCreatePrescripteurAdresseAdressesCollection0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof AdressesApiCreatePrescripteurAdresseAdressesCollection0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getAdresseAdressesItem operation in AdressesApi.
 * @export
 * @interface AdressesApiGetAdresseAdressesItemRequest
 */
export interface AdressesApiGetAdresseAdressesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AdressesApiGetAdresseAdressesItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AdressesApiGetAdresseAdressesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof AdressesApiGetAdresseAdressesItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateAdresseAdressesItem operation in AdressesApi.
 * @export
 * @interface AdressesApiUpdateAdresseAdressesItemRequest
 */
export interface AdressesApiUpdateAdresseAdressesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AdressesApiUpdateAdresseAdressesItem
     */
    readonly id: string

    /**
     * The updated Adresses resource
     * @type {AdressesAdresseJsonldAdresseUpdate}
     * @memberof AdressesApiUpdateAdresseAdressesItem
     */
    readonly adressesAdresseJsonldAdresseUpdate: AdressesAdresseJsonldAdresseUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AdressesApiUpdateAdresseAdressesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof AdressesApiUpdateAdresseAdressesItem
     */
    readonly xIdUser?: number
}

/**
 * AdressesApi - object-oriented interface
 * @export
 * @class AdressesApi
 * @extends {BaseAPI}
 */
export class AdressesApi extends BaseAPI {
    /**
     * Créer une nouvelle adresse pour un prescripteur
     * @summary Créer une nouvelle adresse pour un prescripteur
     * @param {AdressesApiCreatePrescripteurAdresseAdressesCollection0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdressesApi
     */
    public createPrescripteurAdresseAdressesCollection(requestParameters: AdressesApiCreatePrescripteurAdresseAdressesCollection0Request, options?: RawAxiosRequestConfig) {
        return AdressesApiFp(this.configuration).createPrescripteurAdresseAdressesCollection(requestParameters.tiers, requestParameters.adressesAdresseJsonldAdresseCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une adresse
     * @summary Récupérer les informations d\'une adresse
     * @param {AdressesApiGetAdresseAdressesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdressesApi
     */
    public getAdresseAdressesItem(requestParameters: AdressesApiGetAdresseAdressesItemRequest, options?: RawAxiosRequestConfig) {
        return AdressesApiFp(this.configuration).getAdresseAdressesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier une adresse
     * @summary Modifier une adresse
     * @param {AdressesApiUpdateAdresseAdressesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdressesApi
     */
    public updateAdresseAdressesItem(requestParameters: AdressesApiUpdateAdresseAdressesItemRequest, options?: RawAxiosRequestConfig) {
        return AdressesApiFp(this.configuration).updateAdresseAdressesItem(requestParameters.id, requestParameters.adressesAdresseJsonldAdresseUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CategorieSocioProfessionnelleApi - axios parameter creator
 * @export
 */
export const CategorieSocioProfessionnelleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'une catégorie socioprofessionnelle
         * @summary Récupérer les informations d\'une catégorie socioprofessionnelle
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCspCategorieSocioProfessionnelleItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCspCategorieSocioProfessionnelleItem', 'id', id)
            const localVarPath = `/tiers/categories-socioprofessionnelles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des catégories socioprofessionnelles
         * @summary Obtenir la liste des catégories socioprofessionnelles
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListeCspCategorieSocioProfessionnelleCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/categories-socioprofessionnelles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategorieSocioProfessionnelleApi - functional programming interface
 * @export
 */
export const CategorieSocioProfessionnelleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategorieSocioProfessionnelleApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'une catégorie socioprofessionnelle
         * @summary Récupérer les informations d\'une catégorie socioprofessionnelle
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCspCategorieSocioProfessionnelleItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCspCategorieSocioProfessionnelleItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategorieSocioProfessionnelleApi.getCspCategorieSocioProfessionnelleItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des catégories socioprofessionnelles
         * @summary Obtenir la liste des catégories socioprofessionnelles
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListeCspCategorieSocioProfessionnelleCollection(xIdSociete?: number, xIdUser?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListeCspCategorieSocioProfessionnelleCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListeCspCategorieSocioProfessionnelleCollection(xIdSociete, xIdUser, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategorieSocioProfessionnelleApi.getListeCspCategorieSocioProfessionnelleCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CategorieSocioProfessionnelleApi - factory interface
 * @export
 */
export const CategorieSocioProfessionnelleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategorieSocioProfessionnelleApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'une catégorie socioprofessionnelle
         * @summary Récupérer les informations d\'une catégorie socioprofessionnelle
         * @param {CategorieSocioProfessionnelleApiGetCspCategorieSocioProfessionnelleItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCspCategorieSocioProfessionnelleItem(requestParameters: CategorieSocioProfessionnelleApiGetCspCategorieSocioProfessionnelleItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategorieSocioProfessionnelleCategorieSocioProfessionnelleJsonldCspRead> {
            return localVarFp.getCspCategorieSocioProfessionnelleItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des catégories socioprofessionnelles
         * @summary Obtenir la liste des catégories socioprofessionnelles
         * @param {CategorieSocioProfessionnelleApiGetListeCspCategorieSocioProfessionnelleCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListeCspCategorieSocioProfessionnelleCollection(requestParameters: CategorieSocioProfessionnelleApiGetListeCspCategorieSocioProfessionnelleCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListeCspCategorieSocioProfessionnelleCollection200Response> {
            return localVarFp.getListeCspCategorieSocioProfessionnelleCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCspCategorieSocioProfessionnelleItem operation in CategorieSocioProfessionnelleApi.
 * @export
 * @interface CategorieSocioProfessionnelleApiGetCspCategorieSocioProfessionnelleItemRequest
 */
export interface CategorieSocioProfessionnelleApiGetCspCategorieSocioProfessionnelleItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CategorieSocioProfessionnelleApiGetCspCategorieSocioProfessionnelleItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CategorieSocioProfessionnelleApiGetCspCategorieSocioProfessionnelleItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CategorieSocioProfessionnelleApiGetCspCategorieSocioProfessionnelleItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getListeCspCategorieSocioProfessionnelleCollection operation in CategorieSocioProfessionnelleApi.
 * @export
 * @interface CategorieSocioProfessionnelleApiGetListeCspCategorieSocioProfessionnelleCollectionRequest
 */
export interface CategorieSocioProfessionnelleApiGetListeCspCategorieSocioProfessionnelleCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CategorieSocioProfessionnelleApiGetListeCspCategorieSocioProfessionnelleCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CategorieSocioProfessionnelleApiGetListeCspCategorieSocioProfessionnelleCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof CategorieSocioProfessionnelleApiGetListeCspCategorieSocioProfessionnelleCollection
     */
    readonly page?: number
}

/**
 * CategorieSocioProfessionnelleApi - object-oriented interface
 * @export
 * @class CategorieSocioProfessionnelleApi
 * @extends {BaseAPI}
 */
export class CategorieSocioProfessionnelleApi extends BaseAPI {
    /**
     * Récupérer les informations d\'une catégorie socioprofessionnelle
     * @summary Récupérer les informations d\'une catégorie socioprofessionnelle
     * @param {CategorieSocioProfessionnelleApiGetCspCategorieSocioProfessionnelleItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieSocioProfessionnelleApi
     */
    public getCspCategorieSocioProfessionnelleItem(requestParameters: CategorieSocioProfessionnelleApiGetCspCategorieSocioProfessionnelleItemRequest, options?: RawAxiosRequestConfig) {
        return CategorieSocioProfessionnelleApiFp(this.configuration).getCspCategorieSocioProfessionnelleItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des catégories socioprofessionnelles
     * @summary Obtenir la liste des catégories socioprofessionnelles
     * @param {CategorieSocioProfessionnelleApiGetListeCspCategorieSocioProfessionnelleCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieSocioProfessionnelleApi
     */
    public getListeCspCategorieSocioProfessionnelleCollection(requestParameters: CategorieSocioProfessionnelleApiGetListeCspCategorieSocioProfessionnelleCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return CategorieSocioProfessionnelleApiFp(this.configuration).getListeCspCategorieSocioProfessionnelleCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CivilitesApi - axios parameter creator
 * @export
 */
export const CivilitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des civilités
         * @summary Obtenir la liste des civilités
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListeCivilitesTblParametreReferencesCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/civilites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CivilitesApi - functional programming interface
 * @export
 */
export const CivilitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CivilitesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des civilités
         * @summary Obtenir la liste des civilités
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListeCivilitesTblParametreReferencesCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListeCivilitesTblParametreReferencesCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListeCivilitesTblParametreReferencesCollection(xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CivilitesApi.getListeCivilitesTblParametreReferencesCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CivilitesApi - factory interface
 * @export
 */
export const CivilitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CivilitesApiFp(configuration)
    return {
        /**
         * Obtenir la liste des civilités
         * @summary Obtenir la liste des civilités
         * @param {CivilitesApiGetListeCivilitesTblParametreReferencesCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListeCivilitesTblParametreReferencesCollection(requestParameters: CivilitesApiGetListeCivilitesTblParametreReferencesCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListeCivilitesTblParametreReferencesCollection200Response> {
            return localVarFp.getListeCivilitesTblParametreReferencesCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getListeCivilitesTblParametreReferencesCollection operation in CivilitesApi.
 * @export
 * @interface CivilitesApiGetListeCivilitesTblParametreReferencesCollectionRequest
 */
export interface CivilitesApiGetListeCivilitesTblParametreReferencesCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CivilitesApiGetListeCivilitesTblParametreReferencesCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CivilitesApiGetListeCivilitesTblParametreReferencesCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof CivilitesApiGetListeCivilitesTblParametreReferencesCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CivilitesApiGetListeCivilitesTblParametreReferencesCollection
     */
    readonly itemsPerPage?: number
}

/**
 * CivilitesApi - object-oriented interface
 * @export
 * @class CivilitesApi
 * @extends {BaseAPI}
 */
export class CivilitesApi extends BaseAPI {
    /**
     * Obtenir la liste des civilités
     * @summary Obtenir la liste des civilités
     * @param {CivilitesApiGetListeCivilitesTblParametreReferencesCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilitesApi
     */
    public getListeCivilitesTblParametreReferencesCollection(requestParameters: CivilitesApiGetListeCivilitesTblParametreReferencesCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return CivilitesApiFp(this.configuration).getListeCivilitesTblParametreReferencesCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommerciauxApi - axios parameter creator
 * @export
 */
export const CommerciauxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Ajouter un commercial au prescripteur
         * @summary Ajouter un commercial au prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {CommerciauxCommercialJsonldPrescripteurWriteCommercial} commerciauxCommercialJsonldPrescripteurWriteCommercial The new Commerciaux resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommercialPrescripteurCommerciauxCollection: async (tiers: number, commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('createCommercialPrescripteurCommerciauxCollection', 'tiers', tiers)
            // verify required parameter 'commerciauxCommercialJsonldPrescripteurWriteCommercial' is not null or undefined
            assertParamExists('createCommercialPrescripteurCommerciauxCollection', 'commerciauxCommercialJsonldPrescripteurWriteCommercial', commerciauxCommercialJsonldPrescripteurWriteCommercial)
            const localVarPath = `/tiers/prescripteurs/{tiers}/commerciaux`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commerciauxCommercialJsonldPrescripteurWriteCommercial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer le commercial d\'un prescripteur
         * @summary Supprimer le commercial d\'un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommercialPrescripteurCommerciauxItem: async (tiers: number, id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('deleteCommercialPrescripteurCommerciauxItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCommercialPrescripteurCommerciauxItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{tiers}/commerciaux/{id}`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des commerciaux liés à un prescripteur
         * @summary Obtenir la liste des commerciaux liés à un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommerciauxPrescripteurTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCommerciauxPrescripteurTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/commerciaux`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les données d\'un commercial d\'un prescripteur
         * @summary Mettre à jour les données d\'un commercial d\'un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {string} id Resource identifier
         * @param {CommerciauxCommercialJsonldPrescripteurWriteCommercial} commerciauxCommercialJsonldPrescripteurWriteCommercial The updated Commerciaux resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommercialPrescripteurCommerciauxItem: async (tiers: number, id: string, commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateCommercialPrescripteurCommerciauxItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCommercialPrescripteurCommerciauxItem', 'id', id)
            // verify required parameter 'commerciauxCommercialJsonldPrescripteurWriteCommercial' is not null or undefined
            assertParamExists('updateCommercialPrescripteurCommerciauxItem', 'commerciauxCommercialJsonldPrescripteurWriteCommercial', commerciauxCommercialJsonldPrescripteurWriteCommercial)
            const localVarPath = `/tiers/prescripteurs/{tiers}/commerciaux/{id}`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commerciauxCommercialJsonldPrescripteurWriteCommercial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommerciauxApi - functional programming interface
 * @export
 */
export const CommerciauxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommerciauxApiAxiosParamCreator(configuration)
    return {
        /**
         * Ajouter un commercial au prescripteur
         * @summary Ajouter un commercial au prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {CommerciauxCommercialJsonldPrescripteurWriteCommercial} commerciauxCommercialJsonldPrescripteurWriteCommercial The new Commerciaux resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommercialPrescripteurCommerciauxCollection(tiers: number, commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommerciauxTiersJsonldPrescripteurReadCommerciaux>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommercialPrescripteurCommerciauxCollection(tiers, commerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerciauxApi.createCommercialPrescripteurCommerciauxCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer le commercial d\'un prescripteur
         * @summary Supprimer le commercial d\'un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommercialPrescripteurCommerciauxItem(tiers: number, id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommercialPrescripteurCommerciauxItem(tiers, id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerciauxApi.deleteCommercialPrescripteurCommerciauxItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des commerciaux liés à un prescripteur
         * @summary Obtenir la liste des commerciaux liés à un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommerciauxPrescripteurTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldPrescripteurReadCommerciaux>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommerciauxPrescripteurTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerciauxApi.getCommerciauxPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les données d\'un commercial d\'un prescripteur
         * @summary Mettre à jour les données d\'un commercial d\'un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {string} id Resource identifier
         * @param {CommerciauxCommercialJsonldPrescripteurWriteCommercial} commerciauxCommercialJsonldPrescripteurWriteCommercial The updated Commerciaux resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommercialPrescripteurCommerciauxItem(tiers: number, id: string, commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommerciauxTiersJsonldPrescripteurReadCommerciaux>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommercialPrescripteurCommerciauxItem(tiers, id, commerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommerciauxApi.updateCommercialPrescripteurCommerciauxItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommerciauxApi - factory interface
 * @export
 */
export const CommerciauxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommerciauxApiFp(configuration)
    return {
        /**
         * Ajouter un commercial au prescripteur
         * @summary Ajouter un commercial au prescripteur
         * @param {CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommercialPrescripteurCommerciauxCollection(requestParameters: CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0Request, options?: RawAxiosRequestConfig): AxiosPromise<CommerciauxTiersJsonldPrescripteurReadCommerciaux> {
            return localVarFp.createCommercialPrescripteurCommerciauxCollection(requestParameters.tiers, requestParameters.commerciauxCommercialJsonldPrescripteurWriteCommercial, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer le commercial d\'un prescripteur
         * @summary Supprimer le commercial d\'un prescripteur
         * @param {CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommercialPrescripteurCommerciauxItem(requestParameters: CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCommercialPrescripteurCommerciauxItem(requestParameters.tiers, requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des commerciaux liés à un prescripteur
         * @summary Obtenir la liste des commerciaux liés à un prescripteur
         * @param {CommerciauxApiGetCommerciauxPrescripteurTiersItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommerciauxPrescripteurTiersItem(requestParameters: CommerciauxApiGetCommerciauxPrescripteurTiersItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldPrescripteurReadCommerciaux> {
            return localVarFp.getCommerciauxPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les données d\'un commercial d\'un prescripteur
         * @summary Mettre à jour les données d\'un commercial d\'un prescripteur
         * @param {CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommercialPrescripteurCommerciauxItem(requestParameters: CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<CommerciauxTiersJsonldPrescripteurReadCommerciaux> {
            return localVarFp.updateCommercialPrescripteurCommerciauxItem(requestParameters.tiers, requestParameters.id, requestParameters.commerciauxCommercialJsonldPrescripteurWriteCommercial, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCommercialPrescripteurCommerciauxCollection operation in CommerciauxApi.
 * @export
 * @interface CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0Request
 */
export interface CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0Request {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0
     */
    readonly tiers: number

    /**
     * The new Commerciaux resource
     * @type {CommerciauxCommercialJsonldPrescripteurWriteCommercial}
     * @memberof CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0
     */
    readonly commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for deleteCommercialPrescripteurCommerciauxItem operation in CommerciauxApi.
 * @export
 * @interface CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0Request
 */
export interface CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0Request {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0
     */
    readonly tiers: number

    /**
     * Resource identifier
     * @type {string}
     * @memberof CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getCommerciauxPrescripteurTiersItem operation in CommerciauxApi.
 * @export
 * @interface CommerciauxApiGetCommerciauxPrescripteurTiersItem0Request
 */
export interface CommerciauxApiGetCommerciauxPrescripteurTiersItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CommerciauxApiGetCommerciauxPrescripteurTiersItem0
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommerciauxApiGetCommerciauxPrescripteurTiersItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CommerciauxApiGetCommerciauxPrescripteurTiersItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateCommercialPrescripteurCommerciauxItem operation in CommerciauxApi.
 * @export
 * @interface CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0Request
 */
export interface CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0Request {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0
     */
    readonly tiers: number

    /**
     * Resource identifier
     * @type {string}
     * @memberof CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0
     */
    readonly id: string

    /**
     * The updated Commerciaux resource
     * @type {CommerciauxCommercialJsonldPrescripteurWriteCommercial}
     * @memberof CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0
     */
    readonly commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0
     */
    readonly xIdUser?: number
}

/**
 * CommerciauxApi - object-oriented interface
 * @export
 * @class CommerciauxApi
 * @extends {BaseAPI}
 */
export class CommerciauxApi extends BaseAPI {
    /**
     * Ajouter un commercial au prescripteur
     * @summary Ajouter un commercial au prescripteur
     * @param {CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerciauxApi
     */
    public createCommercialPrescripteurCommerciauxCollection(requestParameters: CommerciauxApiCreateCommercialPrescripteurCommerciauxCollection0Request, options?: RawAxiosRequestConfig) {
        return CommerciauxApiFp(this.configuration).createCommercialPrescripteurCommerciauxCollection(requestParameters.tiers, requestParameters.commerciauxCommercialJsonldPrescripteurWriteCommercial, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer le commercial d\'un prescripteur
     * @summary Supprimer le commercial d\'un prescripteur
     * @param {CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerciauxApi
     */
    public deleteCommercialPrescripteurCommerciauxItem(requestParameters: CommerciauxApiDeleteCommercialPrescripteurCommerciauxItem0Request, options?: RawAxiosRequestConfig) {
        return CommerciauxApiFp(this.configuration).deleteCommercialPrescripteurCommerciauxItem(requestParameters.tiers, requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des commerciaux liés à un prescripteur
     * @summary Obtenir la liste des commerciaux liés à un prescripteur
     * @param {CommerciauxApiGetCommerciauxPrescripteurTiersItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerciauxApi
     */
    public getCommerciauxPrescripteurTiersItem(requestParameters: CommerciauxApiGetCommerciauxPrescripteurTiersItem0Request, options?: RawAxiosRequestConfig) {
        return CommerciauxApiFp(this.configuration).getCommerciauxPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les données d\'un commercial d\'un prescripteur
     * @summary Mettre à jour les données d\'un commercial d\'un prescripteur
     * @param {CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerciauxApi
     */
    public updateCommercialPrescripteurCommerciauxItem(requestParameters: CommerciauxApiUpdateCommercialPrescripteurCommerciauxItem0Request, options?: RawAxiosRequestConfig) {
        return CommerciauxApiFp(this.configuration).updateCommercialPrescripteurCommerciauxItem(requestParameters.tiers, requestParameters.id, requestParameters.commerciauxCommercialJsonldPrescripteurWriteCommercial, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer un nouveau contact pour un prescripteur
         * @summary Créer un nouveau contact pour un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {ContactsContactJsonldContactCreate} contactsContactJsonldContactCreate The new Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurContactContactsCollection: async (tiers: number, contactsContactJsonldContactCreate: ContactsContactJsonldContactCreate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('createPrescripteurContactContactsCollection', 'tiers', tiers)
            // verify required parameter 'contactsContactJsonldContactCreate' is not null or undefined
            assertParamExists('createPrescripteurContactContactsCollection', 'contactsContactJsonldContactCreate', contactsContactJsonldContactCreate)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un contact
         * @summary Récupérer les informations d\'un contact
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactContactsItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContactContactsItem', 'id', id)
            const localVarPath = `/tiers/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations des contacts
         * @summary Récupérer les informations des contacts
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {string} [q] 
         * @param {number} [tiersId] 
         * @param {Array<number>} [tiersId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsContactsCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, q?: string, tiersId?: number, tiersId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (tiersId !== undefined) {
                localVarQueryParameter['tiers.id'] = tiersId;
            }

            if (tiersId2) {
                localVarQueryParameter['tiers.id[]'] = tiersId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier un contact
         * @summary Modifier un contact
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactUpdate} contactsContactJsonldContactUpdate The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactContactsItem: async (id: string, contactsContactJsonldContactUpdate: ContactsContactJsonldContactUpdate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContactContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactUpdate' is not null or undefined
            assertParamExists('updateContactContactsItem', 'contactsContactJsonldContactUpdate', contactsContactJsonldContactUpdate)
            const localVarPath = `/tiers/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteEmailsPrescripteur} contactsContactJsonldContactWriteEmailsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsContactPrescripteurContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteEmailsPrescripteur: ContactsContactJsonldContactWriteEmailsPrescripteur, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateEmailsContactPrescripteurContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmailsContactPrescripteurContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteEmailsPrescripteur' is not null or undefined
            assertParamExists('updateEmailsContactPrescripteurContactsItem', 'contactsContactJsonldContactWriteEmailsPrescripteur', contactsContactJsonldContactWriteEmailsPrescripteur)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts/{id}/emails`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteEmailsPrescripteur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un sous-client. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteEmailsSousclient} contactsContactJsonldContactWriteEmailsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsContactSousclientContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteEmailsSousclient: ContactsContactJsonldContactWriteEmailsSousclient, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateEmailsContactSousclientContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmailsContactSousclientContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteEmailsSousclient' is not null or undefined
            assertParamExists('updateEmailsContactSousclientContactsItem', 'contactsContactJsonldContactWriteEmailsSousclient', contactsContactJsonldContactWriteEmailsSousclient)
            const localVarPath = `/tiers/sousclients/{tiers}/contacts/{id}/emails`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteEmailsSousclient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteFaxsPrescripteur} contactsContactJsonldContactWriteFaxsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxsContactPrescripteurContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteFaxsPrescripteur: ContactsContactJsonldContactWriteFaxsPrescripteur, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateFaxsContactPrescripteurContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFaxsContactPrescripteurContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteFaxsPrescripteur' is not null or undefined
            assertParamExists('updateFaxsContactPrescripteurContactsItem', 'contactsContactJsonldContactWriteFaxsPrescripteur', contactsContactJsonldContactWriteFaxsPrescripteur)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts/{id}/faxs`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteFaxsPrescripteur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteFaxsSousclient} contactsContactJsonldContactWriteFaxsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxsContactSousclientContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteFaxsSousclient: ContactsContactJsonldContactWriteFaxsSousclient, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateFaxsContactSousclientContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFaxsContactSousclientContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteFaxsSousclient' is not null or undefined
            assertParamExists('updateFaxsContactSousclientContactsItem', 'contactsContactJsonldContactWriteFaxsSousclient', contactsContactJsonldContactWriteFaxsSousclient)
            const localVarPath = `/tiers/sousclients/{tiers}/contacts/{id}/faxs`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteFaxsSousclient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteMobilesPrescripteur} contactsContactJsonldContactWriteMobilesPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobilesContactPrescripteurContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteMobilesPrescripteur: ContactsContactJsonldContactWriteMobilesPrescripteur, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateMobilesContactPrescripteurContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMobilesContactPrescripteurContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteMobilesPrescripteur' is not null or undefined
            assertParamExists('updateMobilesContactPrescripteurContactsItem', 'contactsContactJsonldContactWriteMobilesPrescripteur', contactsContactJsonldContactWriteMobilesPrescripteur)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts/{id}/mobiles`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteMobilesPrescripteur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteMobilesSousclient} contactsContactJsonldContactWriteMobilesSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobilesContactSousclientContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteMobilesSousclient: ContactsContactJsonldContactWriteMobilesSousclient, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateMobilesContactSousclientContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMobilesContactSousclientContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteMobilesSousclient' is not null or undefined
            assertParamExists('updateMobilesContactSousclientContactsItem', 'contactsContactJsonldContactWriteMobilesSousclient', contactsContactJsonldContactWriteMobilesSousclient)
            const localVarPath = `/tiers/sousclients/{tiers}/contacts/{id}/mobiles`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteMobilesSousclient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteTelsPrescripteur} contactsContactJsonldContactWriteTelsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephonesContactPrescripteurContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteTelsPrescripteur: ContactsContactJsonldContactWriteTelsPrescripteur, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateTelephonesContactPrescripteurContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTelephonesContactPrescripteurContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteTelsPrescripteur' is not null or undefined
            assertParamExists('updateTelephonesContactPrescripteurContactsItem', 'contactsContactJsonldContactWriteTelsPrescripteur', contactsContactJsonldContactWriteTelsPrescripteur)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts/{id}/telephones`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteTelsPrescripteur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteTelsSousclient} contactsContactJsonldContactWriteTelsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephonesContactSousclientContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteTelsSousclient: ContactsContactJsonldContactWriteTelsSousclient, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateTelephonesContactSousclientContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTelephonesContactSousclientContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteTelsSousclient' is not null or undefined
            assertParamExists('updateTelephonesContactSousclientContactsItem', 'contactsContactJsonldContactWriteTelsSousclient', contactsContactJsonldContactWriteTelsSousclient)
            const localVarPath = `/tiers/sousclients/{tiers}/contacts/{id}/telephones`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteTelsSousclient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer un nouveau contact pour un prescripteur
         * @summary Créer un nouveau contact pour un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {ContactsContactJsonldContactCreate} contactsContactJsonldContactCreate The new Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPrescripteurContactContactsCollection(tiers: number, contactsContactJsonldContactCreate: ContactsContactJsonldContactCreate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrescripteurContactContactsCollection(tiers, contactsContactJsonldContactCreate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.createPrescripteurContactContactsCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un contact
         * @summary Récupérer les informations d\'un contact
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactContactsItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactContactsItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.getContactContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations des contacts
         * @summary Récupérer les informations des contacts
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {string} [q] 
         * @param {number} [tiersId] 
         * @param {Array<number>} [tiersId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactsContactsCollection(xIdSociete?: number, xIdUser?: number, page?: number, q?: string, tiersId?: number, tiersId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContactsContactsCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactsContactsCollection(xIdSociete, xIdUser, page, q, tiersId, tiersId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.getContactsContactsCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier un contact
         * @summary Modifier un contact
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactUpdate} contactsContactJsonldContactUpdate The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactContactsItem(id: string, contactsContactJsonldContactUpdate: ContactsContactJsonldContactUpdate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactContactsItem(id, contactsContactJsonldContactUpdate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.updateContactContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteEmailsPrescripteur} contactsContactJsonldContactWriteEmailsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailsContactPrescripteurContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteEmailsPrescripteur: ContactsContactJsonldContactWriteEmailsPrescripteur, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailsContactPrescripteurContactsItem(tiers, id, contactsContactJsonldContactWriteEmailsPrescripteur, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.updateEmailsContactPrescripteurContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un sous-client. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteEmailsSousclient} contactsContactJsonldContactWriteEmailsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailsContactSousclientContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteEmailsSousclient: ContactsContactJsonldContactWriteEmailsSousclient, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailsContactSousclientContactsItem(tiers, id, contactsContactJsonldContactWriteEmailsSousclient, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.updateEmailsContactSousclientContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteFaxsPrescripteur} contactsContactJsonldContactWriteFaxsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFaxsContactPrescripteurContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteFaxsPrescripteur: ContactsContactJsonldContactWriteFaxsPrescripteur, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFaxsContactPrescripteurContactsItem(tiers, id, contactsContactJsonldContactWriteFaxsPrescripteur, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.updateFaxsContactPrescripteurContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteFaxsSousclient} contactsContactJsonldContactWriteFaxsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFaxsContactSousclientContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteFaxsSousclient: ContactsContactJsonldContactWriteFaxsSousclient, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFaxsContactSousclientContactsItem(tiers, id, contactsContactJsonldContactWriteFaxsSousclient, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.updateFaxsContactSousclientContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteMobilesPrescripteur} contactsContactJsonldContactWriteMobilesPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMobilesContactPrescripteurContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteMobilesPrescripteur: ContactsContactJsonldContactWriteMobilesPrescripteur, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMobilesContactPrescripteurContactsItem(tiers, id, contactsContactJsonldContactWriteMobilesPrescripteur, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.updateMobilesContactPrescripteurContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteMobilesSousclient} contactsContactJsonldContactWriteMobilesSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMobilesContactSousclientContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteMobilesSousclient: ContactsContactJsonldContactWriteMobilesSousclient, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMobilesContactSousclientContactsItem(tiers, id, contactsContactJsonldContactWriteMobilesSousclient, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.updateMobilesContactSousclientContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteTelsPrescripteur} contactsContactJsonldContactWriteTelsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTelephonesContactPrescripteurContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteTelsPrescripteur: ContactsContactJsonldContactWriteTelsPrescripteur, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTelephonesContactPrescripteurContactsItem(tiers, id, contactsContactJsonldContactWriteTelsPrescripteur, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.updateTelephonesContactPrescripteurContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteTelsSousclient} contactsContactJsonldContactWriteTelsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTelephonesContactSousclientContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteTelsSousclient: ContactsContactJsonldContactWriteTelsSousclient, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTelephonesContactSousclientContactsItem(tiers, id, contactsContactJsonldContactWriteTelsSousclient, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.updateTelephonesContactSousclientContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactsApiFp(configuration)
    return {
        /**
         * Créer un nouveau contact pour un prescripteur
         * @summary Créer un nouveau contact pour un prescripteur
         * @param {ContactsApiCreatePrescripteurContactContactsCollection0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurContactContactsCollection(requestParameters: ContactsApiCreatePrescripteurContactContactsCollection0Request, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.createPrescripteurContactContactsCollection(requestParameters.tiers, requestParameters.contactsContactJsonldContactCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un contact
         * @summary Récupérer les informations d\'un contact
         * @param {ContactsApiGetContactContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactContactsItem(requestParameters: ContactsApiGetContactContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.getContactContactsItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations des contacts
         * @summary Récupérer les informations des contacts
         * @param {ContactsApiGetContactsContactsCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsContactsCollection(requestParameters: ContactsApiGetContactsContactsCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetContactsContactsCollection200Response> {
            return localVarFp.getContactsContactsCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.q, requestParameters.tiersId, requestParameters.tiersId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier un contact
         * @summary Modifier un contact
         * @param {ContactsApiUpdateContactContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactContactsItem(requestParameters: ContactsApiUpdateContactContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateContactContactsItem(requestParameters.id, requestParameters.contactsContactJsonldContactUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un prescripteur
         * @param {ContactsApiUpdateEmailsContactPrescripteurContactsItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsContactPrescripteurContactsItem(requestParameters: ContactsApiUpdateEmailsContactPrescripteurContactsItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateEmailsContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteEmailsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un sous-client. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un sous-client
         * @param {ContactsApiUpdateEmailsContactSousclientContactsItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsContactSousclientContactsItem(requestParameters: ContactsApiUpdateEmailsContactSousclientContactsItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateEmailsContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteEmailsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur
         * @param {ContactsApiUpdateFaxsContactPrescripteurContactsItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxsContactPrescripteurContactsItem(requestParameters: ContactsApiUpdateFaxsContactPrescripteurContactsItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateFaxsContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteFaxsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client
         * @param {ContactsApiUpdateFaxsContactSousclientContactsItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxsContactSousclientContactsItem(requestParameters: ContactsApiUpdateFaxsContactSousclientContactsItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateFaxsContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteFaxsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur
         * @param {ContactsApiUpdateMobilesContactPrescripteurContactsItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobilesContactPrescripteurContactsItem(requestParameters: ContactsApiUpdateMobilesContactPrescripteurContactsItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateMobilesContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteMobilesPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client
         * @param {ContactsApiUpdateMobilesContactSousclientContactsItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobilesContactSousclientContactsItem(requestParameters: ContactsApiUpdateMobilesContactSousclientContactsItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateMobilesContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteMobilesSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur
         * @param {ContactsApiUpdateTelephonesContactPrescripteurContactsItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephonesContactPrescripteurContactsItem(requestParameters: ContactsApiUpdateTelephonesContactPrescripteurContactsItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateTelephonesContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteTelsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client
         * @param {ContactsApiUpdateTelephonesContactSousclientContactsItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephonesContactSousclientContactsItem(requestParameters: ContactsApiUpdateTelephonesContactSousclientContactsItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateTelephonesContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteTelsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPrescripteurContactContactsCollection operation in ContactsApi.
 * @export
 * @interface ContactsApiCreatePrescripteurContactContactsCollection0Request
 */
export interface ContactsApiCreatePrescripteurContactContactsCollection0Request {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof ContactsApiCreatePrescripteurContactContactsCollection0
     */
    readonly tiers: number

    /**
     * The new Contacts resource
     * @type {ContactsContactJsonldContactCreate}
     * @memberof ContactsApiCreatePrescripteurContactContactsCollection0
     */
    readonly contactsContactJsonldContactCreate: ContactsContactJsonldContactCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiCreatePrescripteurContactContactsCollection0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiCreatePrescripteurContactContactsCollection0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getContactContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiGetContactContactsItemRequest
 */
export interface ContactsApiGetContactContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiGetContactContactsItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiGetContactContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiGetContactContactsItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getContactsContactsCollection operation in ContactsApi.
 * @export
 * @interface ContactsApiGetContactsContactsCollectionRequest
 */
export interface ContactsApiGetContactsContactsCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiGetContactsContactsCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiGetContactsContactsCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ContactsApiGetContactsContactsCollection
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof ContactsApiGetContactsContactsCollection
     */
    readonly q?: string

    /**
     * 
     * @type {number}
     * @memberof ContactsApiGetContactsContactsCollection
     */
    readonly tiersId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ContactsApiGetContactsContactsCollection
     */
    readonly tiersId2?: Array<number>
}

/**
 * Request parameters for updateContactContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiUpdateContactContactsItemRequest
 */
export interface ContactsApiUpdateContactContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateContactContactsItem
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactUpdate}
     * @memberof ContactsApiUpdateContactContactsItem
     */
    readonly contactsContactJsonldContactUpdate: ContactsContactJsonldContactUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiUpdateContactContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiUpdateContactContactsItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateEmailsContactPrescripteurContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiUpdateEmailsContactPrescripteurContactsItem0Request
 */
export interface ContactsApiUpdateEmailsContactPrescripteurContactsItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateEmailsContactPrescripteurContactsItem0
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateEmailsContactPrescripteurContactsItem0
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteEmailsPrescripteur}
     * @memberof ContactsApiUpdateEmailsContactPrescripteurContactsItem0
     */
    readonly contactsContactJsonldContactWriteEmailsPrescripteur: ContactsContactJsonldContactWriteEmailsPrescripteur

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiUpdateEmailsContactPrescripteurContactsItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiUpdateEmailsContactPrescripteurContactsItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateEmailsContactSousclientContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiUpdateEmailsContactSousclientContactsItem0Request
 */
export interface ContactsApiUpdateEmailsContactSousclientContactsItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateEmailsContactSousclientContactsItem0
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateEmailsContactSousclientContactsItem0
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteEmailsSousclient}
     * @memberof ContactsApiUpdateEmailsContactSousclientContactsItem0
     */
    readonly contactsContactJsonldContactWriteEmailsSousclient: ContactsContactJsonldContactWriteEmailsSousclient

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiUpdateEmailsContactSousclientContactsItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiUpdateEmailsContactSousclientContactsItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateFaxsContactPrescripteurContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiUpdateFaxsContactPrescripteurContactsItem0Request
 */
export interface ContactsApiUpdateFaxsContactPrescripteurContactsItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateFaxsContactPrescripteurContactsItem0
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateFaxsContactPrescripteurContactsItem0
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteFaxsPrescripteur}
     * @memberof ContactsApiUpdateFaxsContactPrescripteurContactsItem0
     */
    readonly contactsContactJsonldContactWriteFaxsPrescripteur: ContactsContactJsonldContactWriteFaxsPrescripteur

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiUpdateFaxsContactPrescripteurContactsItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiUpdateFaxsContactPrescripteurContactsItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateFaxsContactSousclientContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiUpdateFaxsContactSousclientContactsItem0Request
 */
export interface ContactsApiUpdateFaxsContactSousclientContactsItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateFaxsContactSousclientContactsItem0
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateFaxsContactSousclientContactsItem0
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteFaxsSousclient}
     * @memberof ContactsApiUpdateFaxsContactSousclientContactsItem0
     */
    readonly contactsContactJsonldContactWriteFaxsSousclient: ContactsContactJsonldContactWriteFaxsSousclient

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiUpdateFaxsContactSousclientContactsItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiUpdateFaxsContactSousclientContactsItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateMobilesContactPrescripteurContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiUpdateMobilesContactPrescripteurContactsItem0Request
 */
export interface ContactsApiUpdateMobilesContactPrescripteurContactsItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateMobilesContactPrescripteurContactsItem0
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateMobilesContactPrescripteurContactsItem0
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteMobilesPrescripteur}
     * @memberof ContactsApiUpdateMobilesContactPrescripteurContactsItem0
     */
    readonly contactsContactJsonldContactWriteMobilesPrescripteur: ContactsContactJsonldContactWriteMobilesPrescripteur

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiUpdateMobilesContactPrescripteurContactsItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiUpdateMobilesContactPrescripteurContactsItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateMobilesContactSousclientContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiUpdateMobilesContactSousclientContactsItem0Request
 */
export interface ContactsApiUpdateMobilesContactSousclientContactsItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateMobilesContactSousclientContactsItem0
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateMobilesContactSousclientContactsItem0
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteMobilesSousclient}
     * @memberof ContactsApiUpdateMobilesContactSousclientContactsItem0
     */
    readonly contactsContactJsonldContactWriteMobilesSousclient: ContactsContactJsonldContactWriteMobilesSousclient

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiUpdateMobilesContactSousclientContactsItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiUpdateMobilesContactSousclientContactsItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateTelephonesContactPrescripteurContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiUpdateTelephonesContactPrescripteurContactsItem0Request
 */
export interface ContactsApiUpdateTelephonesContactPrescripteurContactsItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateTelephonesContactPrescripteurContactsItem0
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateTelephonesContactPrescripteurContactsItem0
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteTelsPrescripteur}
     * @memberof ContactsApiUpdateTelephonesContactPrescripteurContactsItem0
     */
    readonly contactsContactJsonldContactWriteTelsPrescripteur: ContactsContactJsonldContactWriteTelsPrescripteur

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiUpdateTelephonesContactPrescripteurContactsItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiUpdateTelephonesContactPrescripteurContactsItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateTelephonesContactSousclientContactsItem operation in ContactsApi.
 * @export
 * @interface ContactsApiUpdateTelephonesContactSousclientContactsItem0Request
 */
export interface ContactsApiUpdateTelephonesContactSousclientContactsItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateTelephonesContactSousclientContactsItem0
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof ContactsApiUpdateTelephonesContactSousclientContactsItem0
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteTelsSousclient}
     * @memberof ContactsApiUpdateTelephonesContactSousclientContactsItem0
     */
    readonly contactsContactJsonldContactWriteTelsSousclient: ContactsContactJsonldContactWriteTelsSousclient

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ContactsApiUpdateTelephonesContactSousclientContactsItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ContactsApiUpdateTelephonesContactSousclientContactsItem0
     */
    readonly xIdUser?: number
}

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
    /**
     * Créer un nouveau contact pour un prescripteur
     * @summary Créer un nouveau contact pour un prescripteur
     * @param {ContactsApiCreatePrescripteurContactContactsCollection0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public createPrescripteurContactContactsCollection(requestParameters: ContactsApiCreatePrescripteurContactContactsCollection0Request, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).createPrescripteurContactContactsCollection(requestParameters.tiers, requestParameters.contactsContactJsonldContactCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un contact
     * @summary Récupérer les informations d\'un contact
     * @param {ContactsApiGetContactContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public getContactContactsItem(requestParameters: ContactsApiGetContactContactsItemRequest, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).getContactContactsItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations des contacts
     * @summary Récupérer les informations des contacts
     * @param {ContactsApiGetContactsContactsCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public getContactsContactsCollection(requestParameters: ContactsApiGetContactsContactsCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).getContactsContactsCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.q, requestParameters.tiersId, requestParameters.tiersId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier un contact
     * @summary Modifier un contact
     * @param {ContactsApiUpdateContactContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateContactContactsItem(requestParameters: ContactsApiUpdateContactContactsItemRequest, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateContactContactsItem(requestParameters.id, requestParameters.contactsContactJsonldContactUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des emails d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
     * @summary Mettre à jour la liste des emails d\'un contact d\'un prescripteur
     * @param {ContactsApiUpdateEmailsContactPrescripteurContactsItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateEmailsContactPrescripteurContactsItem(requestParameters: ContactsApiUpdateEmailsContactPrescripteurContactsItem0Request, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateEmailsContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteEmailsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des emails d\'un contact d\'un sous-client. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
     * @summary Mettre à jour la liste des emails d\'un contact d\'un sous-client
     * @param {ContactsApiUpdateEmailsContactSousclientContactsItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateEmailsContactSousclientContactsItem(requestParameters: ContactsApiUpdateEmailsContactSousclientContactsItem0Request, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateEmailsContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteEmailsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur
     * @param {ContactsApiUpdateFaxsContactPrescripteurContactsItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateFaxsContactPrescripteurContactsItem(requestParameters: ContactsApiUpdateFaxsContactPrescripteurContactsItem0Request, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateFaxsContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteFaxsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client
     * @param {ContactsApiUpdateFaxsContactSousclientContactsItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateFaxsContactSousclientContactsItem(requestParameters: ContactsApiUpdateFaxsContactSousclientContactsItem0Request, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateFaxsContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteFaxsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur
     * @param {ContactsApiUpdateMobilesContactPrescripteurContactsItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateMobilesContactPrescripteurContactsItem(requestParameters: ContactsApiUpdateMobilesContactPrescripteurContactsItem0Request, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateMobilesContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteMobilesPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client
     * @param {ContactsApiUpdateMobilesContactSousclientContactsItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateMobilesContactSousclientContactsItem(requestParameters: ContactsApiUpdateMobilesContactSousclientContactsItem0Request, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateMobilesContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteMobilesSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur
     * @param {ContactsApiUpdateTelephonesContactPrescripteurContactsItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateTelephonesContactPrescripteurContactsItem(requestParameters: ContactsApiUpdateTelephonesContactPrescripteurContactsItem0Request, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateTelephonesContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteTelsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client
     * @param {ContactsApiUpdateTelephonesContactSousclientContactsItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateTelephonesContactSousclientContactsItem(requestParameters: ContactsApiUpdateTelephonesContactSousclientContactsItem0Request, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateTelephonesContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteTelsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CoordonneesApi - axios parameter creator
 * @export
 */
export const CoordonneesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Suppression d\'un email
         * @summary Suppression d\'un email
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailCoordonneesItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmailCoordonneesItem', 'id', id)
            const localVarPath = `/tiers/emails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un email
         * @summary Récupérer les informations d\'un email
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailCoordonneesItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmailCoordonneesItem', 'id', id)
            const localVarPath = `/tiers/emails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un fax
         * @summary Récupérer les informations d\'un fax
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaxCoordonneesItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFaxCoordonneesItem', 'id', id)
            const localVarPath = `/tiers/faxs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un mobile
         * @summary Récupérer les informations d\'un mobile
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileCoordonneesItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMobileCoordonneesItem', 'id', id)
            const localVarPath = `/tiers/mobiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un telephone
         * @summary Récupérer les informations d\'un telephone
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelephoneCoordonneesItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTelephoneCoordonneesItem', 'id', id)
            const localVarPath = `/tiers/telephones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier un email
         * @summary Modifier un email
         * @param {string} id Resource identifier
         * @param {CoordonneesEmailJsonldCoordonneeUpdate} coordonneesEmailJsonldCoordonneeUpdate The updated Coordonnees resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailCoordonneesItem: async (id: string, coordonneesEmailJsonldCoordonneeUpdate: CoordonneesEmailJsonldCoordonneeUpdate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmailCoordonneesItem', 'id', id)
            // verify required parameter 'coordonneesEmailJsonldCoordonneeUpdate' is not null or undefined
            assertParamExists('updateEmailCoordonneesItem', 'coordonneesEmailJsonldCoordonneeUpdate', coordonneesEmailJsonldCoordonneeUpdate)
            const localVarPath = `/tiers/emails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coordonneesEmailJsonldCoordonneeUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier un fax
         * @summary Modifier un fax
         * @param {string} id Resource identifier
         * @param {CoordonneesFaxJsonldCoordonneeUpdate} coordonneesFaxJsonldCoordonneeUpdate The updated Coordonnees resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxCoordonneesItem: async (id: string, coordonneesFaxJsonldCoordonneeUpdate: CoordonneesFaxJsonldCoordonneeUpdate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFaxCoordonneesItem', 'id', id)
            // verify required parameter 'coordonneesFaxJsonldCoordonneeUpdate' is not null or undefined
            assertParamExists('updateFaxCoordonneesItem', 'coordonneesFaxJsonldCoordonneeUpdate', coordonneesFaxJsonldCoordonneeUpdate)
            const localVarPath = `/tiers/faxs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coordonneesFaxJsonldCoordonneeUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier un mobile
         * @summary Modifier un mobile
         * @param {string} id Resource identifier
         * @param {CoordonneesMobileJsonldCoordonneeUpdate} coordonneesMobileJsonldCoordonneeUpdate The updated Coordonnees resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobileCoordonneesItem: async (id: string, coordonneesMobileJsonldCoordonneeUpdate: CoordonneesMobileJsonldCoordonneeUpdate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMobileCoordonneesItem', 'id', id)
            // verify required parameter 'coordonneesMobileJsonldCoordonneeUpdate' is not null or undefined
            assertParamExists('updateMobileCoordonneesItem', 'coordonneesMobileJsonldCoordonneeUpdate', coordonneesMobileJsonldCoordonneeUpdate)
            const localVarPath = `/tiers/mobiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coordonneesMobileJsonldCoordonneeUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier un telephone
         * @summary Modifier un telephone
         * @param {string} id Resource identifier
         * @param {CoordonneesTelephoneJsonldCoordonneeUpdate} coordonneesTelephoneJsonldCoordonneeUpdate The updated Coordonnees resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephoneCoordonneesItem: async (id: string, coordonneesTelephoneJsonldCoordonneeUpdate: CoordonneesTelephoneJsonldCoordonneeUpdate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTelephoneCoordonneesItem', 'id', id)
            // verify required parameter 'coordonneesTelephoneJsonldCoordonneeUpdate' is not null or undefined
            assertParamExists('updateTelephoneCoordonneesItem', 'coordonneesTelephoneJsonldCoordonneeUpdate', coordonneesTelephoneJsonldCoordonneeUpdate)
            const localVarPath = `/tiers/telephones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coordonneesTelephoneJsonldCoordonneeUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoordonneesApi - functional programming interface
 * @export
 */
export const CoordonneesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoordonneesApiAxiosParamCreator(configuration)
    return {
        /**
         * Suppression d\'un email
         * @summary Suppression d\'un email
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmailCoordonneesItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmailCoordonneesItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoordonneesApi.deleteEmailCoordonneesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un email
         * @summary Récupérer les informations d\'un email
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailCoordonneesItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoordonneesEmailJsonldCoordonneeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailCoordonneesItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoordonneesApi.getEmailCoordonneesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un fax
         * @summary Récupérer les informations d\'un fax
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFaxCoordonneesItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoordonneesFaxJsonldCoordonneeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFaxCoordonneesItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoordonneesApi.getFaxCoordonneesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un mobile
         * @summary Récupérer les informations d\'un mobile
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMobileCoordonneesItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoordonneesMobileJsonldCoordonneeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMobileCoordonneesItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoordonneesApi.getMobileCoordonneesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un telephone
         * @summary Récupérer les informations d\'un telephone
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTelephoneCoordonneesItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoordonneesTelephoneJsonldCoordonneeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTelephoneCoordonneesItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoordonneesApi.getTelephoneCoordonneesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier un email
         * @summary Modifier un email
         * @param {string} id Resource identifier
         * @param {CoordonneesEmailJsonldCoordonneeUpdate} coordonneesEmailJsonldCoordonneeUpdate The updated Coordonnees resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailCoordonneesItem(id: string, coordonneesEmailJsonldCoordonneeUpdate: CoordonneesEmailJsonldCoordonneeUpdate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoordonneesEmailJsonldCoordonneeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailCoordonneesItem(id, coordonneesEmailJsonldCoordonneeUpdate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoordonneesApi.updateEmailCoordonneesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier un fax
         * @summary Modifier un fax
         * @param {string} id Resource identifier
         * @param {CoordonneesFaxJsonldCoordonneeUpdate} coordonneesFaxJsonldCoordonneeUpdate The updated Coordonnees resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFaxCoordonneesItem(id: string, coordonneesFaxJsonldCoordonneeUpdate: CoordonneesFaxJsonldCoordonneeUpdate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoordonneesFaxJsonldCoordonneeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFaxCoordonneesItem(id, coordonneesFaxJsonldCoordonneeUpdate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoordonneesApi.updateFaxCoordonneesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier un mobile
         * @summary Modifier un mobile
         * @param {string} id Resource identifier
         * @param {CoordonneesMobileJsonldCoordonneeUpdate} coordonneesMobileJsonldCoordonneeUpdate The updated Coordonnees resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMobileCoordonneesItem(id: string, coordonneesMobileJsonldCoordonneeUpdate: CoordonneesMobileJsonldCoordonneeUpdate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoordonneesMobileJsonldCoordonneeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMobileCoordonneesItem(id, coordonneesMobileJsonldCoordonneeUpdate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoordonneesApi.updateMobileCoordonneesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier un telephone
         * @summary Modifier un telephone
         * @param {string} id Resource identifier
         * @param {CoordonneesTelephoneJsonldCoordonneeUpdate} coordonneesTelephoneJsonldCoordonneeUpdate The updated Coordonnees resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTelephoneCoordonneesItem(id: string, coordonneesTelephoneJsonldCoordonneeUpdate: CoordonneesTelephoneJsonldCoordonneeUpdate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoordonneesTelephoneJsonldCoordonneeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTelephoneCoordonneesItem(id, coordonneesTelephoneJsonldCoordonneeUpdate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoordonneesApi.updateTelephoneCoordonneesItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CoordonneesApi - factory interface
 * @export
 */
export const CoordonneesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoordonneesApiFp(configuration)
    return {
        /**
         * Suppression d\'un email
         * @summary Suppression d\'un email
         * @param {CoordonneesApiDeleteEmailCoordonneesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailCoordonneesItem(requestParameters: CoordonneesApiDeleteEmailCoordonneesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteEmailCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un email
         * @summary Récupérer les informations d\'un email
         * @param {CoordonneesApiGetEmailCoordonneesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailCoordonneesItem(requestParameters: CoordonneesApiGetEmailCoordonneesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CoordonneesEmailJsonldCoordonneeRead> {
            return localVarFp.getEmailCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un fax
         * @summary Récupérer les informations d\'un fax
         * @param {CoordonneesApiGetFaxCoordonneesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaxCoordonneesItem(requestParameters: CoordonneesApiGetFaxCoordonneesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CoordonneesFaxJsonldCoordonneeRead> {
            return localVarFp.getFaxCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un mobile
         * @summary Récupérer les informations d\'un mobile
         * @param {CoordonneesApiGetMobileCoordonneesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMobileCoordonneesItem(requestParameters: CoordonneesApiGetMobileCoordonneesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CoordonneesMobileJsonldCoordonneeRead> {
            return localVarFp.getMobileCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un telephone
         * @summary Récupérer les informations d\'un telephone
         * @param {CoordonneesApiGetTelephoneCoordonneesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelephoneCoordonneesItem(requestParameters: CoordonneesApiGetTelephoneCoordonneesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CoordonneesTelephoneJsonldCoordonneeRead> {
            return localVarFp.getTelephoneCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier un email
         * @summary Modifier un email
         * @param {CoordonneesApiUpdateEmailCoordonneesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailCoordonneesItem(requestParameters: CoordonneesApiUpdateEmailCoordonneesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CoordonneesEmailJsonldCoordonneeRead> {
            return localVarFp.updateEmailCoordonneesItem(requestParameters.id, requestParameters.coordonneesEmailJsonldCoordonneeUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier un fax
         * @summary Modifier un fax
         * @param {CoordonneesApiUpdateFaxCoordonneesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxCoordonneesItem(requestParameters: CoordonneesApiUpdateFaxCoordonneesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CoordonneesFaxJsonldCoordonneeRead> {
            return localVarFp.updateFaxCoordonneesItem(requestParameters.id, requestParameters.coordonneesFaxJsonldCoordonneeUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier un mobile
         * @summary Modifier un mobile
         * @param {CoordonneesApiUpdateMobileCoordonneesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobileCoordonneesItem(requestParameters: CoordonneesApiUpdateMobileCoordonneesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CoordonneesMobileJsonldCoordonneeRead> {
            return localVarFp.updateMobileCoordonneesItem(requestParameters.id, requestParameters.coordonneesMobileJsonldCoordonneeUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier un telephone
         * @summary Modifier un telephone
         * @param {CoordonneesApiUpdateTelephoneCoordonneesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephoneCoordonneesItem(requestParameters: CoordonneesApiUpdateTelephoneCoordonneesItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CoordonneesTelephoneJsonldCoordonneeRead> {
            return localVarFp.updateTelephoneCoordonneesItem(requestParameters.id, requestParameters.coordonneesTelephoneJsonldCoordonneeUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteEmailCoordonneesItem operation in CoordonneesApi.
 * @export
 * @interface CoordonneesApiDeleteEmailCoordonneesItemRequest
 */
export interface CoordonneesApiDeleteEmailCoordonneesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CoordonneesApiDeleteEmailCoordonneesItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CoordonneesApiDeleteEmailCoordonneesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CoordonneesApiDeleteEmailCoordonneesItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getEmailCoordonneesItem operation in CoordonneesApi.
 * @export
 * @interface CoordonneesApiGetEmailCoordonneesItemRequest
 */
export interface CoordonneesApiGetEmailCoordonneesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CoordonneesApiGetEmailCoordonneesItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CoordonneesApiGetEmailCoordonneesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CoordonneesApiGetEmailCoordonneesItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getFaxCoordonneesItem operation in CoordonneesApi.
 * @export
 * @interface CoordonneesApiGetFaxCoordonneesItemRequest
 */
export interface CoordonneesApiGetFaxCoordonneesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CoordonneesApiGetFaxCoordonneesItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CoordonneesApiGetFaxCoordonneesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CoordonneesApiGetFaxCoordonneesItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getMobileCoordonneesItem operation in CoordonneesApi.
 * @export
 * @interface CoordonneesApiGetMobileCoordonneesItemRequest
 */
export interface CoordonneesApiGetMobileCoordonneesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CoordonneesApiGetMobileCoordonneesItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CoordonneesApiGetMobileCoordonneesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CoordonneesApiGetMobileCoordonneesItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getTelephoneCoordonneesItem operation in CoordonneesApi.
 * @export
 * @interface CoordonneesApiGetTelephoneCoordonneesItemRequest
 */
export interface CoordonneesApiGetTelephoneCoordonneesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CoordonneesApiGetTelephoneCoordonneesItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CoordonneesApiGetTelephoneCoordonneesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CoordonneesApiGetTelephoneCoordonneesItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateEmailCoordonneesItem operation in CoordonneesApi.
 * @export
 * @interface CoordonneesApiUpdateEmailCoordonneesItemRequest
 */
export interface CoordonneesApiUpdateEmailCoordonneesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CoordonneesApiUpdateEmailCoordonneesItem
     */
    readonly id: string

    /**
     * The updated Coordonnees resource
     * @type {CoordonneesEmailJsonldCoordonneeUpdate}
     * @memberof CoordonneesApiUpdateEmailCoordonneesItem
     */
    readonly coordonneesEmailJsonldCoordonneeUpdate: CoordonneesEmailJsonldCoordonneeUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CoordonneesApiUpdateEmailCoordonneesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CoordonneesApiUpdateEmailCoordonneesItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateFaxCoordonneesItem operation in CoordonneesApi.
 * @export
 * @interface CoordonneesApiUpdateFaxCoordonneesItemRequest
 */
export interface CoordonneesApiUpdateFaxCoordonneesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CoordonneesApiUpdateFaxCoordonneesItem
     */
    readonly id: string

    /**
     * The updated Coordonnees resource
     * @type {CoordonneesFaxJsonldCoordonneeUpdate}
     * @memberof CoordonneesApiUpdateFaxCoordonneesItem
     */
    readonly coordonneesFaxJsonldCoordonneeUpdate: CoordonneesFaxJsonldCoordonneeUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CoordonneesApiUpdateFaxCoordonneesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CoordonneesApiUpdateFaxCoordonneesItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateMobileCoordonneesItem operation in CoordonneesApi.
 * @export
 * @interface CoordonneesApiUpdateMobileCoordonneesItemRequest
 */
export interface CoordonneesApiUpdateMobileCoordonneesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CoordonneesApiUpdateMobileCoordonneesItem
     */
    readonly id: string

    /**
     * The updated Coordonnees resource
     * @type {CoordonneesMobileJsonldCoordonneeUpdate}
     * @memberof CoordonneesApiUpdateMobileCoordonneesItem
     */
    readonly coordonneesMobileJsonldCoordonneeUpdate: CoordonneesMobileJsonldCoordonneeUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CoordonneesApiUpdateMobileCoordonneesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CoordonneesApiUpdateMobileCoordonneesItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateTelephoneCoordonneesItem operation in CoordonneesApi.
 * @export
 * @interface CoordonneesApiUpdateTelephoneCoordonneesItemRequest
 */
export interface CoordonneesApiUpdateTelephoneCoordonneesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CoordonneesApiUpdateTelephoneCoordonneesItem
     */
    readonly id: string

    /**
     * The updated Coordonnees resource
     * @type {CoordonneesTelephoneJsonldCoordonneeUpdate}
     * @memberof CoordonneesApiUpdateTelephoneCoordonneesItem
     */
    readonly coordonneesTelephoneJsonldCoordonneeUpdate: CoordonneesTelephoneJsonldCoordonneeUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CoordonneesApiUpdateTelephoneCoordonneesItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof CoordonneesApiUpdateTelephoneCoordonneesItem
     */
    readonly xIdUser?: number
}

/**
 * CoordonneesApi - object-oriented interface
 * @export
 * @class CoordonneesApi
 * @extends {BaseAPI}
 */
export class CoordonneesApi extends BaseAPI {
    /**
     * Suppression d\'un email
     * @summary Suppression d\'un email
     * @param {CoordonneesApiDeleteEmailCoordonneesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoordonneesApi
     */
    public deleteEmailCoordonneesItem(requestParameters: CoordonneesApiDeleteEmailCoordonneesItemRequest, options?: RawAxiosRequestConfig) {
        return CoordonneesApiFp(this.configuration).deleteEmailCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un email
     * @summary Récupérer les informations d\'un email
     * @param {CoordonneesApiGetEmailCoordonneesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoordonneesApi
     */
    public getEmailCoordonneesItem(requestParameters: CoordonneesApiGetEmailCoordonneesItemRequest, options?: RawAxiosRequestConfig) {
        return CoordonneesApiFp(this.configuration).getEmailCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un fax
     * @summary Récupérer les informations d\'un fax
     * @param {CoordonneesApiGetFaxCoordonneesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoordonneesApi
     */
    public getFaxCoordonneesItem(requestParameters: CoordonneesApiGetFaxCoordonneesItemRequest, options?: RawAxiosRequestConfig) {
        return CoordonneesApiFp(this.configuration).getFaxCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un mobile
     * @summary Récupérer les informations d\'un mobile
     * @param {CoordonneesApiGetMobileCoordonneesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoordonneesApi
     */
    public getMobileCoordonneesItem(requestParameters: CoordonneesApiGetMobileCoordonneesItemRequest, options?: RawAxiosRequestConfig) {
        return CoordonneesApiFp(this.configuration).getMobileCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un telephone
     * @summary Récupérer les informations d\'un telephone
     * @param {CoordonneesApiGetTelephoneCoordonneesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoordonneesApi
     */
    public getTelephoneCoordonneesItem(requestParameters: CoordonneesApiGetTelephoneCoordonneesItemRequest, options?: RawAxiosRequestConfig) {
        return CoordonneesApiFp(this.configuration).getTelephoneCoordonneesItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier un email
     * @summary Modifier un email
     * @param {CoordonneesApiUpdateEmailCoordonneesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoordonneesApi
     */
    public updateEmailCoordonneesItem(requestParameters: CoordonneesApiUpdateEmailCoordonneesItemRequest, options?: RawAxiosRequestConfig) {
        return CoordonneesApiFp(this.configuration).updateEmailCoordonneesItem(requestParameters.id, requestParameters.coordonneesEmailJsonldCoordonneeUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier un fax
     * @summary Modifier un fax
     * @param {CoordonneesApiUpdateFaxCoordonneesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoordonneesApi
     */
    public updateFaxCoordonneesItem(requestParameters: CoordonneesApiUpdateFaxCoordonneesItemRequest, options?: RawAxiosRequestConfig) {
        return CoordonneesApiFp(this.configuration).updateFaxCoordonneesItem(requestParameters.id, requestParameters.coordonneesFaxJsonldCoordonneeUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier un mobile
     * @summary Modifier un mobile
     * @param {CoordonneesApiUpdateMobileCoordonneesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoordonneesApi
     */
    public updateMobileCoordonneesItem(requestParameters: CoordonneesApiUpdateMobileCoordonneesItemRequest, options?: RawAxiosRequestConfig) {
        return CoordonneesApiFp(this.configuration).updateMobileCoordonneesItem(requestParameters.id, requestParameters.coordonneesMobileJsonldCoordonneeUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier un telephone
     * @summary Modifier un telephone
     * @param {CoordonneesApiUpdateTelephoneCoordonneesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoordonneesApi
     */
    public updateTelephoneCoordonneesItem(requestParameters: CoordonneesApiUpdateTelephoneCoordonneesItemRequest, options?: RawAxiosRequestConfig) {
        return CoordonneesApiFp(this.configuration).updateTelephoneCoordonneesItem(requestParameters.id, requestParameters.coordonneesTelephoneJsonldCoordonneeUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FournisseursApi - axios parameter creator
 * @export
 */
export const FournisseursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'un fournisseur
         * @summary Récupérer les informations d\'un fournisseur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFournisseurFournisseurItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFournisseurFournisseurItem', 'id', id)
            const localVarPath = `/tiers/fournisseurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des fournisseurs liés à un prescripteur
         * @summary Obtenir la liste des fournisseurs liés à un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFournisseursPrescripteurTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFournisseursPrescripteurTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/fournisseurs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des fournisseurs
         * @summary Obtenir la liste des fournisseurs
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [deleted] 
         * @param {string} [siren] 
         * @param {string} [libelle] 
         * @param {string} [recherche] 
         * @param {number} [societeId] 
         * @param {Array<number>} [societeId2] 
         * @param {number} [ddmId] 
         * @param {Array<number>} [ddmId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTiersFournisseurCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, deleted?: boolean, siren?: string, libelle?: string, recherche?: string, societeId?: number, societeId2?: Array<number>, ddmId?: number, ddmId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/fournisseurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (siren !== undefined) {
                localVarQueryParameter['siren'] = siren;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }

            if (recherche !== undefined) {
                localVarQueryParameter['recherche'] = recherche;
            }

            if (societeId !== undefined) {
                localVarQueryParameter['societe.id'] = societeId;
            }

            if (societeId2) {
                localVarQueryParameter['societe.id[]'] = societeId2;
            }

            if (ddmId !== undefined) {
                localVarQueryParameter['ddmId'] = ddmId;
            }

            if (ddmId2) {
                localVarQueryParameter['ddmId[]'] = ddmId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FournisseursApi - functional programming interface
 * @export
 */
export const FournisseursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FournisseursApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'un fournisseur
         * @summary Récupérer les informations d\'un fournisseur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFournisseurFournisseurItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFournisseurFournisseurItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FournisseursApi.getFournisseurFournisseurItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des fournisseurs liés à un prescripteur
         * @summary Obtenir la liste des fournisseurs liés à un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFournisseursPrescripteurTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldPrescripteurReadFournisseurs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFournisseursPrescripteurTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FournisseursApi.getFournisseursPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des fournisseurs
         * @summary Obtenir la liste des fournisseurs
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [deleted] 
         * @param {string} [siren] 
         * @param {string} [libelle] 
         * @param {string} [recherche] 
         * @param {number} [societeId] 
         * @param {Array<number>} [societeId2] 
         * @param {number} [ddmId] 
         * @param {Array<number>} [ddmId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTiersFournisseurCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, deleted?: boolean, siren?: string, libelle?: string, recherche?: string, societeId?: number, societeId2?: Array<number>, ddmId?: number, ddmId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersFournisseurCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTiersFournisseurCollection(xIdSociete, xIdUser, page, itemsPerPage, deleted, siren, libelle, recherche, societeId, societeId2, ddmId, ddmId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FournisseursApi.getListTiersFournisseurCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FournisseursApi - factory interface
 * @export
 */
export const FournisseursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FournisseursApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'un fournisseur
         * @summary Récupérer les informations d\'un fournisseur
         * @param {FournisseursApiGetFournisseurFournisseurItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFournisseurFournisseurItem(requestParameters: FournisseursApiGetFournisseurFournisseurItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<FournisseurFournisseurJsonldFournisseurReadFournisseurReadTiers> {
            return localVarFp.getFournisseurFournisseurItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des fournisseurs liés à un prescripteur
         * @summary Obtenir la liste des fournisseurs liés à un prescripteur
         * @param {FournisseursApiGetFournisseursPrescripteurTiersItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFournisseursPrescripteurTiersItem(requestParameters: FournisseursApiGetFournisseursPrescripteurTiersItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldPrescripteurReadFournisseurs> {
            return localVarFp.getFournisseursPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des fournisseurs
         * @summary Obtenir la liste des fournisseurs
         * @param {FournisseursApiGetListTiersFournisseurCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTiersFournisseurCollection(requestParameters: FournisseursApiGetListTiersFournisseurCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersFournisseurCollection200Response> {
            return localVarFp.getListTiersFournisseurCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, requestParameters.deleted, requestParameters.siren, requestParameters.libelle, requestParameters.recherche, requestParameters.societeId, requestParameters.societeId2, requestParameters.ddmId, requestParameters.ddmId2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getFournisseurFournisseurItem operation in FournisseursApi.
 * @export
 * @interface FournisseursApiGetFournisseurFournisseurItemRequest
 */
export interface FournisseursApiGetFournisseurFournisseurItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FournisseursApiGetFournisseurFournisseurItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FournisseursApiGetFournisseurFournisseurItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof FournisseursApiGetFournisseurFournisseurItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getFournisseursPrescripteurTiersItem operation in FournisseursApi.
 * @export
 * @interface FournisseursApiGetFournisseursPrescripteurTiersItem0Request
 */
export interface FournisseursApiGetFournisseursPrescripteurTiersItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FournisseursApiGetFournisseursPrescripteurTiersItem0
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FournisseursApiGetFournisseursPrescripteurTiersItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof FournisseursApiGetFournisseursPrescripteurTiersItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getListTiersFournisseurCollection operation in FournisseursApi.
 * @export
 * @interface FournisseursApiGetListTiersFournisseurCollectionRequest
 */
export interface FournisseursApiGetListTiersFournisseurCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly deleted?: boolean

    /**
     * 
     * @type {string}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly siren?: string

    /**
     * 
     * @type {string}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly libelle?: string

    /**
     * 
     * @type {string}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly recherche?: string

    /**
     * 
     * @type {number}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly societeId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly societeId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly ddmId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FournisseursApiGetListTiersFournisseurCollection
     */
    readonly ddmId2?: Array<number>
}

/**
 * FournisseursApi - object-oriented interface
 * @export
 * @class FournisseursApi
 * @extends {BaseAPI}
 */
export class FournisseursApi extends BaseAPI {
    /**
     * Récupérer les informations d\'un fournisseur
     * @summary Récupérer les informations d\'un fournisseur
     * @param {FournisseursApiGetFournisseurFournisseurItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FournisseursApi
     */
    public getFournisseurFournisseurItem(requestParameters: FournisseursApiGetFournisseurFournisseurItemRequest, options?: RawAxiosRequestConfig) {
        return FournisseursApiFp(this.configuration).getFournisseurFournisseurItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des fournisseurs liés à un prescripteur
     * @summary Obtenir la liste des fournisseurs liés à un prescripteur
     * @param {FournisseursApiGetFournisseursPrescripteurTiersItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FournisseursApi
     */
    public getFournisseursPrescripteurTiersItem(requestParameters: FournisseursApiGetFournisseursPrescripteurTiersItem0Request, options?: RawAxiosRequestConfig) {
        return FournisseursApiFp(this.configuration).getFournisseursPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des fournisseurs
     * @summary Obtenir la liste des fournisseurs
     * @param {FournisseursApiGetListTiersFournisseurCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FournisseursApi
     */
    public getListTiersFournisseurCollection(requestParameters: FournisseursApiGetListTiersFournisseurCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return FournisseursApiFp(this.configuration).getListTiersFournisseurCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, requestParameters.deleted, requestParameters.siren, requestParameters.libelle, requestParameters.recherche, requestParameters.societeId, requestParameters.societeId2, requestParameters.ddmId, requestParameters.ddmId2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogoApi - axios parameter creator
 * @export
 */
export const LogoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des spécifications logo
         * @summary Obtenir la liste des spécifications logo
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogoSpecificationsTblParametreReferencesCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/logo-specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des spécifications logo
         * @summary Valide un fichier base64 sur la base des paramètres de la table paramètre reference
         * @param {TblParametreReferencesLogoValidationJsonld} tblParametreReferencesLogoValidationJsonld The new TblParametreReferences resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateLogoTblParametreReferencesCollection: async (tblParametreReferencesLogoValidationJsonld: TblParametreReferencesLogoValidationJsonld, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tblParametreReferencesLogoValidationJsonld' is not null or undefined
            assertParamExists('validateLogoTblParametreReferencesCollection', 'tblParametreReferencesLogoValidationJsonld', tblParametreReferencesLogoValidationJsonld)
            const localVarPath = `/tiers/logo-validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tblParametreReferencesLogoValidationJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogoApi - functional programming interface
 * @export
 */
export const LogoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogoApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des spécifications logo
         * @summary Obtenir la liste des spécifications logo
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogoSpecificationsTblParametreReferencesCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLogoSpecificationsTblParametreReferencesCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogoSpecificationsTblParametreReferencesCollection(xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogoApi.getLogoSpecificationsTblParametreReferencesCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des spécifications logo
         * @summary Valide un fichier base64 sur la base des paramètres de la table paramètre reference
         * @param {TblParametreReferencesLogoValidationJsonld} tblParametreReferencesLogoValidationJsonld The new TblParametreReferences resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateLogoTblParametreReferencesCollection(tblParametreReferencesLogoValidationJsonld: TblParametreReferencesLogoValidationJsonld, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TblParametreReferencesJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateLogoTblParametreReferencesCollection(tblParametreReferencesLogoValidationJsonld, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogoApi.validateLogoTblParametreReferencesCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LogoApi - factory interface
 * @export
 */
export const LogoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogoApiFp(configuration)
    return {
        /**
         * Obtenir la liste des spécifications logo
         * @summary Obtenir la liste des spécifications logo
         * @param {LogoApiGetLogoSpecificationsTblParametreReferencesCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogoSpecificationsTblParametreReferencesCollection(requestParameters: LogoApiGetLogoSpecificationsTblParametreReferencesCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetLogoSpecificationsTblParametreReferencesCollection200Response> {
            return localVarFp.getLogoSpecificationsTblParametreReferencesCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des spécifications logo
         * @summary Valide un fichier base64 sur la base des paramètres de la table paramètre reference
         * @param {LogoApiValidateLogoTblParametreReferencesCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateLogoTblParametreReferencesCollection(requestParameters: LogoApiValidateLogoTblParametreReferencesCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<TblParametreReferencesJsonld> {
            return localVarFp.validateLogoTblParametreReferencesCollection(requestParameters.tblParametreReferencesLogoValidationJsonld, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getLogoSpecificationsTblParametreReferencesCollection operation in LogoApi.
 * @export
 * @interface LogoApiGetLogoSpecificationsTblParametreReferencesCollectionRequest
 */
export interface LogoApiGetLogoSpecificationsTblParametreReferencesCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof LogoApiGetLogoSpecificationsTblParametreReferencesCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof LogoApiGetLogoSpecificationsTblParametreReferencesCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof LogoApiGetLogoSpecificationsTblParametreReferencesCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof LogoApiGetLogoSpecificationsTblParametreReferencesCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for validateLogoTblParametreReferencesCollection operation in LogoApi.
 * @export
 * @interface LogoApiValidateLogoTblParametreReferencesCollectionRequest
 */
export interface LogoApiValidateLogoTblParametreReferencesCollectionRequest {
    /**
     * The new TblParametreReferences resource
     * @type {TblParametreReferencesLogoValidationJsonld}
     * @memberof LogoApiValidateLogoTblParametreReferencesCollection
     */
    readonly tblParametreReferencesLogoValidationJsonld: TblParametreReferencesLogoValidationJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof LogoApiValidateLogoTblParametreReferencesCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof LogoApiValidateLogoTblParametreReferencesCollection
     */
    readonly xIdUser?: number
}

/**
 * LogoApi - object-oriented interface
 * @export
 * @class LogoApi
 * @extends {BaseAPI}
 */
export class LogoApi extends BaseAPI {
    /**
     * Obtenir la liste des spécifications logo
     * @summary Obtenir la liste des spécifications logo
     * @param {LogoApiGetLogoSpecificationsTblParametreReferencesCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogoApi
     */
    public getLogoSpecificationsTblParametreReferencesCollection(requestParameters: LogoApiGetLogoSpecificationsTblParametreReferencesCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return LogoApiFp(this.configuration).getLogoSpecificationsTblParametreReferencesCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des spécifications logo
     * @summary Valide un fichier base64 sur la base des paramètres de la table paramètre reference
     * @param {LogoApiValidateLogoTblParametreReferencesCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogoApi
     */
    public validateLogoTblParametreReferencesCollection(requestParameters: LogoApiValidateLogoTblParametreReferencesCollectionRequest, options?: RawAxiosRequestConfig) {
        return LogoApiFp(this.configuration).validateLogoTblParametreReferencesCollection(requestParameters.tblParametreReferencesLogoValidationJsonld, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ModePaiementApi - axios parameter creator
 * @export
 */
export const ModePaiementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des modes de paiement
         * @summary Obtenir la liste des modes de paiement
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListeModePaiementModePaiementCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/modes-paiements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un mode de paiement
         * @summary Récupérer les informations d\'un mode de paiement
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModePaiementModePaiementItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getModePaiementModePaiementItem', 'id', id)
            const localVarPath = `/tiers/modes-paiements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModePaiementApi - functional programming interface
 * @export
 */
export const ModePaiementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModePaiementApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des modes de paiement
         * @summary Obtenir la liste des modes de paiement
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListeModePaiementModePaiementCollection(xIdSociete?: number, xIdUser?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListeModePaiementModePaiementCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListeModePaiementModePaiementCollection(xIdSociete, xIdUser, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModePaiementApi.getListeModePaiementModePaiementCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un mode de paiement
         * @summary Récupérer les informations d\'un mode de paiement
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModePaiementModePaiementItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModePaiementModePaiementJsonldModePaiementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModePaiementModePaiementItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModePaiementApi.getModePaiementModePaiementItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ModePaiementApi - factory interface
 * @export
 */
export const ModePaiementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModePaiementApiFp(configuration)
    return {
        /**
         * Obtenir la liste des modes de paiement
         * @summary Obtenir la liste des modes de paiement
         * @param {ModePaiementApiGetListeModePaiementModePaiementCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListeModePaiementModePaiementCollection(requestParameters: ModePaiementApiGetListeModePaiementModePaiementCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListeModePaiementModePaiementCollection200Response> {
            return localVarFp.getListeModePaiementModePaiementCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un mode de paiement
         * @summary Récupérer les informations d\'un mode de paiement
         * @param {ModePaiementApiGetModePaiementModePaiementItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModePaiementModePaiementItem(requestParameters: ModePaiementApiGetModePaiementModePaiementItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ModePaiementModePaiementJsonldModePaiementRead> {
            return localVarFp.getModePaiementModePaiementItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getListeModePaiementModePaiementCollection operation in ModePaiementApi.
 * @export
 * @interface ModePaiementApiGetListeModePaiementModePaiementCollectionRequest
 */
export interface ModePaiementApiGetListeModePaiementModePaiementCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ModePaiementApiGetListeModePaiementModePaiementCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ModePaiementApiGetListeModePaiementModePaiementCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ModePaiementApiGetListeModePaiementModePaiementCollection
     */
    readonly page?: number
}

/**
 * Request parameters for getModePaiementModePaiementItem operation in ModePaiementApi.
 * @export
 * @interface ModePaiementApiGetModePaiementModePaiementItemRequest
 */
export interface ModePaiementApiGetModePaiementModePaiementItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ModePaiementApiGetModePaiementModePaiementItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ModePaiementApiGetModePaiementModePaiementItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ModePaiementApiGetModePaiementModePaiementItem
     */
    readonly xIdUser?: number
}

/**
 * ModePaiementApi - object-oriented interface
 * @export
 * @class ModePaiementApi
 * @extends {BaseAPI}
 */
export class ModePaiementApi extends BaseAPI {
    /**
     * Obtenir la liste des modes de paiement
     * @summary Obtenir la liste des modes de paiement
     * @param {ModePaiementApiGetListeModePaiementModePaiementCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModePaiementApi
     */
    public getListeModePaiementModePaiementCollection(requestParameters: ModePaiementApiGetListeModePaiementModePaiementCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ModePaiementApiFp(this.configuration).getListeModePaiementModePaiementCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un mode de paiement
     * @summary Récupérer les informations d\'un mode de paiement
     * @param {ModePaiementApiGetModePaiementModePaiementItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModePaiementApi
     */
    public getModePaiementModePaiementItem(requestParameters: ModePaiementApiGetModePaiementModePaiementItemRequest, options?: RawAxiosRequestConfig) {
        return ModePaiementApiFp(this.configuration).getModePaiementModePaiementItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ObjectGroupApi - axios parameter creator
 * @export
 */
export const ObjectGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an objectGroup
         * @summary Create Object Group
         * @param {ObjectGroupJsonldObjectgroupCreate} objectGroupJsonldObjectgroupCreate The new Object-Group resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createObjectGroupObjectGroupCollection: async (objectGroupJsonldObjectgroupCreate: ObjectGroupJsonldObjectgroupCreate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectGroupJsonldObjectgroupCreate' is not null or undefined
            assertParamExists('createObjectGroupObjectGroupCollection', 'objectGroupJsonldObjectgroupCreate', objectGroupJsonldObjectgroupCreate)
            const localVarPath = `/tiers/object-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectGroupJsonldObjectgroupCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Object-Group resource.
         * @summary Retrieves a Object-Group resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectGroupItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getObjectGroupItem', 'id', id)
            const localVarPath = `/tiers/object-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher des groupes d\'objet avec le type qui leurs sont liés
         * @summary Rechercher des groupes d\'objets par type
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {string} [type] 
         * @param {Array<string>} [type2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchObjectGroupByTypeObjectGroupCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, type?: string, type2?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/object-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (type2) {
                localVarQueryParameter['type[]'] = type2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * associer un tiers dans un groupe d\'objet dans la table de relation
         * @summary Ajouter un client dans un groupe d\'objet
         * @param {string} id Resource identifier
         * @param {TiersJsonldTiersEditedObjectGroup} tiersJsonldTiersEditedObjectGroup The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelSousClientObjectGroupTiersItem: async (id: string, tiersJsonldTiersEditedObjectGroup: TiersJsonldTiersEditedObjectGroup, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRelSousClientObjectGroupTiersItem', 'id', id)
            // verify required parameter 'tiersJsonldTiersEditedObjectGroup' is not null or undefined
            assertParamExists('updateRelSousClientObjectGroupTiersItem', 'tiersJsonldTiersEditedObjectGroup', tiersJsonldTiersEditedObjectGroup)
            const localVarPath = `/tiers/sous-clients/{id}/object-groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersJsonldTiersEditedObjectGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObjectGroupApi - functional programming interface
 * @export
 */
export const ObjectGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObjectGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an objectGroup
         * @summary Create Object Group
         * @param {ObjectGroupJsonldObjectgroupCreate} objectGroupJsonldObjectgroupCreate The new Object-Group resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createObjectGroupObjectGroupCollection(objectGroupJsonldObjectgroupCreate: ObjectGroupJsonldObjectgroupCreate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectGroupJsonldObjectgroupCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createObjectGroupObjectGroupCollection(objectGroupJsonldObjectgroupCreate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ObjectGroupApi.createObjectGroupObjectGroupCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Object-Group resource.
         * @summary Retrieves a Object-Group resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObjectGroupItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectGroupJsonldObjectgroupRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObjectGroupItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ObjectGroupApi.getObjectGroupItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Rechercher des groupes d\'objet avec le type qui leurs sont liés
         * @summary Rechercher des groupes d\'objets par type
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {string} [type] 
         * @param {Array<string>} [type2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchObjectGroupByTypeObjectGroupCollection(xIdSociete?: number, xIdUser?: number, page?: number, type?: string, type2?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchObjectGroupByTypeObjectGroupCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchObjectGroupByTypeObjectGroupCollection(xIdSociete, xIdUser, page, type, type2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ObjectGroupApi.searchObjectGroupByTypeObjectGroupCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * associer un tiers dans un groupe d\'objet dans la table de relation
         * @summary Ajouter un client dans un groupe d\'objet
         * @param {string} id Resource identifier
         * @param {TiersJsonldTiersEditedObjectGroup} tiersJsonldTiersEditedObjectGroup The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRelSousClientObjectGroupTiersItem(id: string, tiersJsonldTiersEditedObjectGroup: TiersJsonldTiersEditedObjectGroup, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersJsonldTiersEditObjectGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRelSousClientObjectGroupTiersItem(id, tiersJsonldTiersEditedObjectGroup, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ObjectGroupApi.updateRelSousClientObjectGroupTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ObjectGroupApi - factory interface
 * @export
 */
export const ObjectGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObjectGroupApiFp(configuration)
    return {
        /**
         * Create an objectGroup
         * @summary Create Object Group
         * @param {ObjectGroupApiCreateObjectGroupObjectGroupCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createObjectGroupObjectGroupCollection(requestParameters: ObjectGroupApiCreateObjectGroupObjectGroupCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectGroupJsonldObjectgroupCreate> {
            return localVarFp.createObjectGroupObjectGroupCollection(requestParameters.objectGroupJsonldObjectgroupCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Object-Group resource.
         * @summary Retrieves a Object-Group resource.
         * @param {ObjectGroupApiGetObjectGroupItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectGroupItem(requestParameters: ObjectGroupApiGetObjectGroupItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectGroupJsonldObjectgroupRead> {
            return localVarFp.getObjectGroupItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher des groupes d\'objet avec le type qui leurs sont liés
         * @summary Rechercher des groupes d\'objets par type
         * @param {ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchObjectGroupByTypeObjectGroupCollection(requestParameters: ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SearchObjectGroupByTypeObjectGroupCollection200Response> {
            return localVarFp.searchObjectGroupByTypeObjectGroupCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.type, requestParameters.type2, options).then((request) => request(axios, basePath));
        },
        /**
         * associer un tiers dans un groupe d\'objet dans la table de relation
         * @summary Ajouter un client dans un groupe d\'objet
         * @param {ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelSousClientObjectGroupTiersItem(requestParameters: ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<TiersJsonldTiersEditObjectGroup> {
            return localVarFp.updateRelSousClientObjectGroupTiersItem(requestParameters.id, requestParameters.tiersJsonldTiersEditedObjectGroup, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createObjectGroupObjectGroupCollection operation in ObjectGroupApi.
 * @export
 * @interface ObjectGroupApiCreateObjectGroupObjectGroupCollectionRequest
 */
export interface ObjectGroupApiCreateObjectGroupObjectGroupCollectionRequest {
    /**
     * The new Object-Group resource
     * @type {ObjectGroupJsonldObjectgroupCreate}
     * @memberof ObjectGroupApiCreateObjectGroupObjectGroupCollection
     */
    readonly objectGroupJsonldObjectgroupCreate: ObjectGroupJsonldObjectgroupCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ObjectGroupApiCreateObjectGroupObjectGroupCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ObjectGroupApiCreateObjectGroupObjectGroupCollection
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getObjectGroupItem operation in ObjectGroupApi.
 * @export
 * @interface ObjectGroupApiGetObjectGroupItemRequest
 */
export interface ObjectGroupApiGetObjectGroupItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ObjectGroupApiGetObjectGroupItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ObjectGroupApiGetObjectGroupItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ObjectGroupApiGetObjectGroupItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for searchObjectGroupByTypeObjectGroupCollection operation in ObjectGroupApi.
 * @export
 * @interface ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollectionRequest
 */
export interface ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollection
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollection
     */
    readonly type?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollection
     */
    readonly type2?: Array<string>
}

/**
 * Request parameters for updateRelSousClientObjectGroupTiersItem operation in ObjectGroupApi.
 * @export
 * @interface ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0Request
 */
export interface ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersJsonldTiersEditedObjectGroup}
     * @memberof ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0
     */
    readonly tiersJsonldTiersEditedObjectGroup: TiersJsonldTiersEditedObjectGroup

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0
     */
    readonly xIdUser?: number
}

/**
 * ObjectGroupApi - object-oriented interface
 * @export
 * @class ObjectGroupApi
 * @extends {BaseAPI}
 */
export class ObjectGroupApi extends BaseAPI {
    /**
     * Create an objectGroup
     * @summary Create Object Group
     * @param {ObjectGroupApiCreateObjectGroupObjectGroupCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectGroupApi
     */
    public createObjectGroupObjectGroupCollection(requestParameters: ObjectGroupApiCreateObjectGroupObjectGroupCollectionRequest, options?: RawAxiosRequestConfig) {
        return ObjectGroupApiFp(this.configuration).createObjectGroupObjectGroupCollection(requestParameters.objectGroupJsonldObjectgroupCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Object-Group resource.
     * @summary Retrieves a Object-Group resource.
     * @param {ObjectGroupApiGetObjectGroupItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectGroupApi
     */
    public getObjectGroupItem(requestParameters: ObjectGroupApiGetObjectGroupItemRequest, options?: RawAxiosRequestConfig) {
        return ObjectGroupApiFp(this.configuration).getObjectGroupItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher des groupes d\'objet avec le type qui leurs sont liés
     * @summary Rechercher des groupes d\'objets par type
     * @param {ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectGroupApi
     */
    public searchObjectGroupByTypeObjectGroupCollection(requestParameters: ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ObjectGroupApiFp(this.configuration).searchObjectGroupByTypeObjectGroupCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.type, requestParameters.type2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * associer un tiers dans un groupe d\'objet dans la table de relation
     * @summary Ajouter un client dans un groupe d\'objet
     * @param {ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectGroupApi
     */
    public updateRelSousClientObjectGroupTiersItem(requestParameters: ObjectGroupApiUpdateRelSousClientObjectGroupTiersItem0Request, options?: RawAxiosRequestConfig) {
        return ObjectGroupApiFp(this.configuration).updateRelSousClientObjectGroupTiersItem(requestParameters.id, requestParameters.tiersJsonldTiersEditedObjectGroup, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OpenSearchApi - axios parameter creator
 * @export
 */
export const OpenSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Désindexer un prescripteur ou un sous-client sur OpenSearch
         * @summary Désindexer un prescripteur ou un sous-client sur OpenSearch
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opensearchDeleteTiersTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('opensearchDeleteTiersTiersItem', 'id', id)
            const localVarPath = `/tiers/opensearch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Indexer un lot de tiers sur OpenSearch
         * @summary Indexer un lot de tiers sur OpenSearch
         * @param {OpensearchIndexTiersByIdsTiersCollectionRequest} opensearchIndexTiersByIdsTiersCollectionRequest Une liste d\&#39;identifiants de tiers à indexer
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opensearchIndexTiersByIdsTiersCollection: async (opensearchIndexTiersByIdsTiersCollectionRequest: OpensearchIndexTiersByIdsTiersCollectionRequest, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'opensearchIndexTiersByIdsTiersCollectionRequest' is not null or undefined
            assertParamExists('opensearchIndexTiersByIdsTiersCollection', 'opensearchIndexTiersByIdsTiersCollectionRequest', opensearchIndexTiersByIdsTiersCollectionRequest)
            const localVarPath = `/tiers/opensearch/index-by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(opensearchIndexTiersByIdsTiersCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Indexer un prescripteur ou un sous-client sur OpenSearch
         * @summary Indexer un prescripteur ou un sous-client sur OpenSearch
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opensearchIndexTiersTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('opensearchIndexTiersTiersItem', 'id', id)
            const localVarPath = `/tiers/opensearch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenSearchApi - functional programming interface
 * @export
 */
export const OpenSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpenSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * Désindexer un prescripteur ou un sous-client sur OpenSearch
         * @summary Désindexer un prescripteur ou un sous-client sur OpenSearch
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opensearchDeleteTiersTiersItem(id: string, xIdSociete?: number, xIdUser?: number, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opensearchDeleteTiersTiersItem(id, xIdSociete, xIdUser, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenSearchApi.opensearchDeleteTiersTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Indexer un lot de tiers sur OpenSearch
         * @summary Indexer un lot de tiers sur OpenSearch
         * @param {OpensearchIndexTiersByIdsTiersCollectionRequest} opensearchIndexTiersByIdsTiersCollectionRequest Une liste d\&#39;identifiants de tiers à indexer
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opensearchIndexTiersByIdsTiersCollection(opensearchIndexTiersByIdsTiersCollectionRequest: OpensearchIndexTiersByIdsTiersCollectionRequest, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpensearchIndexTiersByIdsTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opensearchIndexTiersByIdsTiersCollection(opensearchIndexTiersByIdsTiersCollectionRequest, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenSearchApi.opensearchIndexTiersByIdsTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Indexer un prescripteur ou un sous-client sur OpenSearch
         * @summary Indexer un prescripteur ou un sous-client sur OpenSearch
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opensearchIndexTiersTiersItem(id: string, xIdSociete?: number, xIdUser?: number, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opensearchIndexTiersTiersItem(id, xIdSociete, xIdUser, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpenSearchApi.opensearchIndexTiersTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OpenSearchApi - factory interface
 * @export
 */
export const OpenSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpenSearchApiFp(configuration)
    return {
        /**
         * Désindexer un prescripteur ou un sous-client sur OpenSearch
         * @summary Désindexer un prescripteur ou un sous-client sur OpenSearch
         * @param {OpenSearchApiOpensearchDeleteTiersTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opensearchDeleteTiersTiersItem(requestParameters: OpenSearchApiOpensearchDeleteTiersTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.opensearchDeleteTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Indexer un lot de tiers sur OpenSearch
         * @summary Indexer un lot de tiers sur OpenSearch
         * @param {OpenSearchApiOpensearchIndexTiersByIdsTiersCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opensearchIndexTiersByIdsTiersCollection(requestParameters: OpenSearchApiOpensearchIndexTiersByIdsTiersCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<OpensearchIndexTiersByIdsTiersCollection200Response> {
            return localVarFp.opensearchIndexTiersByIdsTiersCollection(requestParameters.opensearchIndexTiersByIdsTiersCollectionRequest, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Indexer un prescripteur ou un sous-client sur OpenSearch
         * @summary Indexer un prescripteur ou un sous-client sur OpenSearch
         * @param {OpenSearchApiOpensearchIndexTiersTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opensearchIndexTiersTiersItem(requestParameters: OpenSearchApiOpensearchIndexTiersTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.opensearchIndexTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for opensearchDeleteTiersTiersItem operation in OpenSearchApi.
 * @export
 * @interface OpenSearchApiOpensearchDeleteTiersTiersItemRequest
 */
export interface OpenSearchApiOpensearchDeleteTiersTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof OpenSearchApiOpensearchDeleteTiersTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OpenSearchApiOpensearchDeleteTiersTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof OpenSearchApiOpensearchDeleteTiersTiersItem
     */
    readonly xIdUser?: number

    /**
     * 
     * @type {object}
     * @memberof OpenSearchApiOpensearchDeleteTiersTiersItem
     */
    readonly body?: object
}

/**
 * Request parameters for opensearchIndexTiersByIdsTiersCollection operation in OpenSearchApi.
 * @export
 * @interface OpenSearchApiOpensearchIndexTiersByIdsTiersCollectionRequest
 */
export interface OpenSearchApiOpensearchIndexTiersByIdsTiersCollectionRequest {
    /**
     * Une liste d\&#39;identifiants de tiers à indexer
     * @type {OpensearchIndexTiersByIdsTiersCollectionRequest}
     * @memberof OpenSearchApiOpensearchIndexTiersByIdsTiersCollection
     */
    readonly opensearchIndexTiersByIdsTiersCollectionRequest: OpensearchIndexTiersByIdsTiersCollectionRequest

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OpenSearchApiOpensearchIndexTiersByIdsTiersCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof OpenSearchApiOpensearchIndexTiersByIdsTiersCollection
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for opensearchIndexTiersTiersItem operation in OpenSearchApi.
 * @export
 * @interface OpenSearchApiOpensearchIndexTiersTiersItemRequest
 */
export interface OpenSearchApiOpensearchIndexTiersTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof OpenSearchApiOpensearchIndexTiersTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OpenSearchApiOpensearchIndexTiersTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof OpenSearchApiOpensearchIndexTiersTiersItem
     */
    readonly xIdUser?: number

    /**
     * 
     * @type {object}
     * @memberof OpenSearchApiOpensearchIndexTiersTiersItem
     */
    readonly body?: object
}

/**
 * OpenSearchApi - object-oriented interface
 * @export
 * @class OpenSearchApi
 * @extends {BaseAPI}
 */
export class OpenSearchApi extends BaseAPI {
    /**
     * Désindexer un prescripteur ou un sous-client sur OpenSearch
     * @summary Désindexer un prescripteur ou un sous-client sur OpenSearch
     * @param {OpenSearchApiOpensearchDeleteTiersTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSearchApi
     */
    public opensearchDeleteTiersTiersItem(requestParameters: OpenSearchApiOpensearchDeleteTiersTiersItemRequest, options?: RawAxiosRequestConfig) {
        return OpenSearchApiFp(this.configuration).opensearchDeleteTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Indexer un lot de tiers sur OpenSearch
     * @summary Indexer un lot de tiers sur OpenSearch
     * @param {OpenSearchApiOpensearchIndexTiersByIdsTiersCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSearchApi
     */
    public opensearchIndexTiersByIdsTiersCollection(requestParameters: OpenSearchApiOpensearchIndexTiersByIdsTiersCollectionRequest, options?: RawAxiosRequestConfig) {
        return OpenSearchApiFp(this.configuration).opensearchIndexTiersByIdsTiersCollection(requestParameters.opensearchIndexTiersByIdsTiersCollectionRequest, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Indexer un prescripteur ou un sous-client sur OpenSearch
     * @summary Indexer un prescripteur ou un sous-client sur OpenSearch
     * @param {OpenSearchApiOpensearchIndexTiersTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenSearchApi
     */
    public opensearchIndexTiersTiersItem(requestParameters: OpenSearchApiOpensearchIndexTiersTiersItemRequest, options?: RawAxiosRequestConfig) {
        return OpenSearchApiFp(this.configuration).opensearchIndexTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaysApi - axios parameter creator
 * @export
 */
export const PaysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des pays
         * @summary Obtenir la liste des pays
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListePaysTblPaysCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/pays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un pays via son id
         * @summary Récupérer les informations d\'un pays via son id
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaysTblPaysItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPaysTblPaysItem', 'id', id)
            const localVarPath = `/tiers/pays/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaysApi - functional programming interface
 * @export
 */
export const PaysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaysApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des pays
         * @summary Obtenir la liste des pays
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListePaysTblPaysCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListePaysTblPaysCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListePaysTblPaysCollection(xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaysApi.getListePaysTblPaysCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un pays via son id
         * @summary Récupérer les informations d\'un pays via son id
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaysTblPaysItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TblPaysJsonldPaysRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaysTblPaysItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaysApi.getPaysTblPaysItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaysApi - factory interface
 * @export
 */
export const PaysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaysApiFp(configuration)
    return {
        /**
         * Obtenir la liste des pays
         * @summary Obtenir la liste des pays
         * @param {PaysApiGetListePaysTblPaysCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListePaysTblPaysCollection(requestParameters: PaysApiGetListePaysTblPaysCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListePaysTblPaysCollection200Response> {
            return localVarFp.getListePaysTblPaysCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un pays via son id
         * @summary Récupérer les informations d\'un pays via son id
         * @param {PaysApiGetPaysTblPaysItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaysTblPaysItem(requestParameters: PaysApiGetPaysTblPaysItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TblPaysJsonldPaysRead> {
            return localVarFp.getPaysTblPaysItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getListePaysTblPaysCollection operation in PaysApi.
 * @export
 * @interface PaysApiGetListePaysTblPaysCollectionRequest
 */
export interface PaysApiGetListePaysTblPaysCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PaysApiGetListePaysTblPaysCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PaysApiGetListePaysTblPaysCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof PaysApiGetListePaysTblPaysCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PaysApiGetListePaysTblPaysCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getPaysTblPaysItem operation in PaysApi.
 * @export
 * @interface PaysApiGetPaysTblPaysItemRequest
 */
export interface PaysApiGetPaysTblPaysItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PaysApiGetPaysTblPaysItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PaysApiGetPaysTblPaysItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PaysApiGetPaysTblPaysItem
     */
    readonly xIdUser?: number
}

/**
 * PaysApi - object-oriented interface
 * @export
 * @class PaysApi
 * @extends {BaseAPI}
 */
export class PaysApi extends BaseAPI {
    /**
     * Obtenir la liste des pays
     * @summary Obtenir la liste des pays
     * @param {PaysApiGetListePaysTblPaysCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaysApi
     */
    public getListePaysTblPaysCollection(requestParameters: PaysApiGetListePaysTblPaysCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return PaysApiFp(this.configuration).getListePaysTblPaysCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un pays via son id
     * @summary Récupérer les informations d\'un pays via son id
     * @param {PaysApiGetPaysTblPaysItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaysApi
     */
    public getPaysTblPaysItem(requestParameters: PaysApiGetPaysTblPaysItemRequest, options?: RawAxiosRequestConfig) {
        return PaysApiFp(this.configuration).getPaysTblPaysItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PortefeuilleApi - axios parameter creator
 * @export
 */
export const PortefeuilleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des portefeuilles
         * @summary Obtenir la liste des portefeuilles
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListePortefeuilleCategorieSocioProfessionnelleCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/portefeuille`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un portefeuille
         * @summary Récupérer les informations d\'un portefeuille
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortefeuilleCategorieSocioProfessionnelleItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPortefeuilleCategorieSocioProfessionnelleItem', 'id', id)
            const localVarPath = `/tiers/portefeuille/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortefeuilleApi - functional programming interface
 * @export
 */
export const PortefeuilleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortefeuilleApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des portefeuilles
         * @summary Obtenir la liste des portefeuilles
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListePortefeuilleCategorieSocioProfessionnelleCollection(xIdSociete?: number, xIdUser?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListePortefeuilleCategorieSocioProfessionnelleCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListePortefeuilleCategorieSocioProfessionnelleCollection(xIdSociete, xIdUser, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortefeuilleApi.getListePortefeuilleCategorieSocioProfessionnelleCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un portefeuille
         * @summary Récupérer les informations d\'un portefeuille
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortefeuilleCategorieSocioProfessionnelleItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortefeuilleCategorieSocioProfessionnelleItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortefeuilleApi.getPortefeuilleCategorieSocioProfessionnelleItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PortefeuilleApi - factory interface
 * @export
 */
export const PortefeuilleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortefeuilleApiFp(configuration)
    return {
        /**
         * Obtenir la liste des portefeuilles
         * @summary Obtenir la liste des portefeuilles
         * @param {PortefeuilleApiGetListePortefeuilleCategorieSocioProfessionnelleCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListePortefeuilleCategorieSocioProfessionnelleCollection(requestParameters: PortefeuilleApiGetListePortefeuilleCategorieSocioProfessionnelleCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListePortefeuilleCategorieSocioProfessionnelleCollection200Response> {
            return localVarFp.getListePortefeuilleCategorieSocioProfessionnelleCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un portefeuille
         * @summary Récupérer les informations d\'un portefeuille
         * @param {PortefeuilleApiGetPortefeuilleCategorieSocioProfessionnelleItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortefeuilleCategorieSocioProfessionnelleItem(requestParameters: PortefeuilleApiGetPortefeuilleCategorieSocioProfessionnelleItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategorieSocioProfessionnellePortefeuilleJsonldPortefeuilleRead> {
            return localVarFp.getPortefeuilleCategorieSocioProfessionnelleItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getListePortefeuilleCategorieSocioProfessionnelleCollection operation in PortefeuilleApi.
 * @export
 * @interface PortefeuilleApiGetListePortefeuilleCategorieSocioProfessionnelleCollectionRequest
 */
export interface PortefeuilleApiGetListePortefeuilleCategorieSocioProfessionnelleCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PortefeuilleApiGetListePortefeuilleCategorieSocioProfessionnelleCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PortefeuilleApiGetListePortefeuilleCategorieSocioProfessionnelleCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof PortefeuilleApiGetListePortefeuilleCategorieSocioProfessionnelleCollection
     */
    readonly page?: number
}

/**
 * Request parameters for getPortefeuilleCategorieSocioProfessionnelleItem operation in PortefeuilleApi.
 * @export
 * @interface PortefeuilleApiGetPortefeuilleCategorieSocioProfessionnelleItemRequest
 */
export interface PortefeuilleApiGetPortefeuilleCategorieSocioProfessionnelleItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PortefeuilleApiGetPortefeuilleCategorieSocioProfessionnelleItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PortefeuilleApiGetPortefeuilleCategorieSocioProfessionnelleItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PortefeuilleApiGetPortefeuilleCategorieSocioProfessionnelleItem
     */
    readonly xIdUser?: number
}

/**
 * PortefeuilleApi - object-oriented interface
 * @export
 * @class PortefeuilleApi
 * @extends {BaseAPI}
 */
export class PortefeuilleApi extends BaseAPI {
    /**
     * Obtenir la liste des portefeuilles
     * @summary Obtenir la liste des portefeuilles
     * @param {PortefeuilleApiGetListePortefeuilleCategorieSocioProfessionnelleCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortefeuilleApi
     */
    public getListePortefeuilleCategorieSocioProfessionnelleCollection(requestParameters: PortefeuilleApiGetListePortefeuilleCategorieSocioProfessionnelleCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return PortefeuilleApiFp(this.configuration).getListePortefeuilleCategorieSocioProfessionnelleCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un portefeuille
     * @summary Récupérer les informations d\'un portefeuille
     * @param {PortefeuilleApiGetPortefeuilleCategorieSocioProfessionnelleItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortefeuilleApi
     */
    public getPortefeuilleCategorieSocioProfessionnelleItem(requestParameters: PortefeuilleApiGetPortefeuilleCategorieSocioProfessionnelleItemRequest, options?: RawAxiosRequestConfig) {
        return PortefeuilleApiFp(this.configuration).getPortefeuilleCategorieSocioProfessionnelleItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PrescripteursApi - axios parameter creator
 * @export
 */
export const PrescripteursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Ajouter un commercial au prescripteur
         * @summary Ajouter un commercial au prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {CommerciauxCommercialJsonldPrescripteurWriteCommercial} commerciauxCommercialJsonldPrescripteurWriteCommercial The new Commerciaux resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommercialPrescripteurCommerciauxCollection: async (tiers: number, commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('createCommercialPrescripteurCommerciauxCollection', 'tiers', tiers)
            // verify required parameter 'commerciauxCommercialJsonldPrescripteurWriteCommercial' is not null or undefined
            assertParamExists('createCommercialPrescripteurCommerciauxCollection', 'commerciauxCommercialJsonldPrescripteurWriteCommercial', commerciauxCommercialJsonldPrescripteurWriteCommercial)
            const localVarPath = `/tiers/prescripteurs/{tiers}/commerciaux`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commerciauxCommercialJsonldPrescripteurWriteCommercial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer une nouvelle adresse pour un prescripteur
         * @summary Créer une nouvelle adresse pour un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {AdressesAdresseJsonldAdresseCreate} adressesAdresseJsonldAdresseCreate The new Adresses resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurAdresseAdressesCollection: async (tiers: number, adressesAdresseJsonldAdresseCreate: AdressesAdresseJsonldAdresseCreate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('createPrescripteurAdresseAdressesCollection', 'tiers', tiers)
            // verify required parameter 'adressesAdresseJsonldAdresseCreate' is not null or undefined
            assertParamExists('createPrescripteurAdresseAdressesCollection', 'adressesAdresseJsonldAdresseCreate', adressesAdresseJsonldAdresseCreate)
            const localVarPath = `/tiers/prescripteurs/{tiers}/adresses`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adressesAdresseJsonldAdresseCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un nouveau contact pour un prescripteur
         * @summary Créer un nouveau contact pour un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {ContactsContactJsonldContactCreate} contactsContactJsonldContactCreate The new Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurContactContactsCollection: async (tiers: number, contactsContactJsonldContactCreate: ContactsContactJsonldContactCreate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('createPrescripteurContactContactsCollection', 'tiers', tiers)
            // verify required parameter 'contactsContactJsonldContactCreate' is not null or undefined
            assertParamExists('createPrescripteurContactContactsCollection', 'contactsContactJsonldContactCreate', contactsContactJsonldContactCreate)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un nouveau préscripteur
         * @summary Créer un nouveau préscripteur
         * @param {TiersPrescripteurJsonldPrescripteurCreate} tiersPrescripteurJsonldPrescripteurCreate The new Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurTiersCollection: async (tiersPrescripteurJsonldPrescripteurCreate: TiersPrescripteurJsonldPrescripteurCreate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiersPrescripteurJsonldPrescripteurCreate' is not null or undefined
            assertParamExists('createPrescripteurTiersCollection', 'tiersPrescripteurJsonldPrescripteurCreate', tiersPrescripteurJsonldPrescripteurCreate)
            const localVarPath = `/tiers/prescripteurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurJsonldPrescripteurCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer le commercial d\'un prescripteur
         * @summary Supprimer le commercial d\'un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommercialPrescripteurCommerciauxItem: async (tiers: number, id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('deleteCommercialPrescripteurCommerciauxItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCommercialPrescripteurCommerciauxItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{tiers}/commerciaux/{id}`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Générer un code client CEGID pour une société
         * @summary Générer un code client CEGID pour une société
         * @param {GetCodeClientTiersCollectionRequest} getCodeClientTiersCollectionRequest L\&#39;identifiant de la société
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeClientTiersCollection: async (getCodeClientTiersCollectionRequest: GetCodeClientTiersCollectionRequest, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCodeClientTiersCollectionRequest' is not null or undefined
            assertParamExists('getCodeClientTiersCollection', 'getCodeClientTiersCollectionRequest', getCodeClientTiersCollectionRequest)
            const localVarPath = `/tiers/code-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCodeClientTiersCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des commerciaux liés à un prescripteur
         * @summary Obtenir la liste des commerciaux liés à un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommerciauxPrescripteurTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCommerciauxPrescripteurTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/commerciaux`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @summary Récupérer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailsNotificationsPrescripteurTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmailsNotificationsPrescripteurTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/notifications/emails`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des fournisseurs liés à un prescripteur
         * @summary Obtenir la liste des fournisseurs liés à un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFournisseursPrescripteurTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFournisseursPrescripteurTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/fournisseurs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @summary Récupérer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrequencesNotificationsPrescripteurTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFrequencesNotificationsPrescripteurTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/notifications/frequences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les grilles forfaitaires des formalités par société d\'un prescripteur
         * @summary Récupérer les grilles forfaitaires des formalités par société d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [societe] Récupérer la grille tarifaire pour une société en particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrilleForfaitairePrescripteurTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, societe?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGrilleForfaitairePrescripteurTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/conditions-commerciales/forfaits-formalites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (societe !== undefined) {
                localVarQueryParameter['societe'] = societe;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @summary Récupérer les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [societe] Récupérer les information de suppression des docs pour une société en particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoClearDocPrescripteurTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, societe?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInfoClearDocPrescripteurTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/clear-files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (societe !== undefined) {
                localVarQueryParameter['societe'] = societe;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [search] Perform a search operation on OpenSearch, data must be in json format. example: &#x60;{\&quot;query\&quot;: {\&quot;match\&quot;: {\&quot;relationsSocietes.societe.id\&quot;: 1}}}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTiersTiersCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer le chiffre d\'affaire der l\'année en cours pour un prescripteur
         * @summary Récupérer le chiffre d\'affaire der l\'année en cours pour un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriberAnnualSalesTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrescriberAnnualSalesTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/chiffre-affaire-annuel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer l\'encours d\'un prescripteur
         * @summary Récupérer l\'encours d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriberOutstandingTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrescriberOutstandingTiersItem', 'id', id)
            const localVarPath = `/tiers/prescripteurs/{id}/encours`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer le régime tva du tiers en fonction de sa localisation
         * @summary Récupérer le régime tva du tiers en fonction de sa localisation
         * @param {GetRegimeTvaTiersCollectionRequest} getRegimeTvaTiersCollectionRequest Au moins un de ces paramètres :  - &#x60;idTiers&#x60;  - &#x60;codeInsee&#x60;  - &#x60;codePostal&#x60; + &#x60;ville&#x60;
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegimeTvaTiersCollection: async (getRegimeTvaTiersCollectionRequest: GetRegimeTvaTiersCollectionRequest, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getRegimeTvaTiersCollectionRequest' is not null or undefined
            assertParamExists('getRegimeTvaTiersCollection', 'getRegimeTvaTiersCollectionRequest', getRegimeTvaTiersCollectionRequest)
            const localVarPath = `/tiers/regime-tva`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getRegimeTvaTiersCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiersTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTiersTiersItem', 'id', id)
            const localVarPath = `/tiers/tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @param {Array<string>} emailAddresses Une ou plusieurs adresses emails sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailAddressesTiersCollection: async (emailAddresses: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddresses' is not null or undefined
            assertParamExists('searchTiersByEmailAddressesTiersCollection', 'emailAddresses', emailAddresses)
            const localVarPath = `/tiers/tiers/rechercher-par-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (emailAddresses) {
                localVarQueryParameter['email_addresses[]'] = emailAddresses;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @param {Array<string>} emailDomains Un ou plusieurs domaines d\&#39;adresse email sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailDomainsTiersCollection: async (emailDomains: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailDomains' is not null or undefined
            assertParamExists('searchTiersByEmailDomainsTiersCollection', 'emailDomains', emailDomains)
            const localVarPath = `/tiers/tiers/rechercher-par-domaine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (emailDomains) {
                localVarQueryParameter['email_domains[]'] = emailDomains;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersGroupTypeTiersCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/tiers-groupe/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des adresses d\'un prescripteur. Attention : la liste COMPLETE des adresses du prescripteur est à passer dans la requête, les adresses absentes seront supprimées.
         * @summary Mettre à jour la liste des adresses d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurJsonldPrescripteurWriteAdresses} tiersPrescripteurJsonldPrescripteurWriteAdresses The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdressesPrescripteurTiersItem: async (id: string, tiersPrescripteurJsonldPrescripteurWriteAdresses: TiersPrescripteurJsonldPrescripteurWriteAdresses, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdressesPrescripteurTiersItem', 'id', id)
            // verify required parameter 'tiersPrescripteurJsonldPrescripteurWriteAdresses' is not null or undefined
            assertParamExists('updateAdressesPrescripteurTiersItem', 'tiersPrescripteurJsonldPrescripteurWriteAdresses', tiersPrescripteurJsonldPrescripteurWriteAdresses)
            const localVarPath = `/tiers/prescripteurs/{id}/adresses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurJsonldPrescripteurWriteAdresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les données d\'un commercial d\'un prescripteur
         * @summary Mettre à jour les données d\'un commercial d\'un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {string} id Resource identifier
         * @param {CommerciauxCommercialJsonldPrescripteurWriteCommercial} commerciauxCommercialJsonldPrescripteurWriteCommercial The updated Commerciaux resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommercialPrescripteurCommerciauxItem: async (tiers: number, id: string, commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateCommercialPrescripteurCommerciauxItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCommercialPrescripteurCommerciauxItem', 'id', id)
            // verify required parameter 'commerciauxCommercialJsonldPrescripteurWriteCommercial' is not null or undefined
            assertParamExists('updateCommercialPrescripteurCommerciauxItem', 'commerciauxCommercialJsonldPrescripteurWriteCommercial', commerciauxCommercialJsonldPrescripteurWriteCommercial)
            const localVarPath = `/tiers/prescripteurs/{tiers}/commerciaux/{id}`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commerciauxCommercialJsonldPrescripteurWriteCommercial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mise à jour des conditions commerciales sur les formalités d\'un prescripeur
         * @summary Mise à jour des conditions commerciales sur les formalités d\'un prescripeur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites} tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionsCommercialesFormalitesPrescripteurTiersItem: async (id: string, tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites: TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConditionsCommercialesFormalitesPrescripteurTiersItem', 'id', id)
            // verify required parameter 'tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites' is not null or undefined
            assertParamExists('updateConditionsCommercialesFormalitesPrescripteurTiersItem', 'tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites', tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites)
            const localVarPath = `/tiers/prescripteurs/{id}/conditions-commerciales/formalites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des contacts d\'un prescripteur. Attention : la liste COMPLETE des contacts du prescripteur est à passer dans la requête, les contacts absents seront supprimées.
         * @summary Mettre à jour la liste des contacts d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurJsonldPrescripteurWriteContacts} tiersPrescripteurJsonldPrescripteurWriteContacts The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactsPrescripteurTiersItem: async (id: string, tiersPrescripteurJsonldPrescripteurWriteContacts: TiersPrescripteurJsonldPrescripteurWriteContacts, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContactsPrescripteurTiersItem', 'id', id)
            // verify required parameter 'tiersPrescripteurJsonldPrescripteurWriteContacts' is not null or undefined
            assertParamExists('updateContactsPrescripteurTiersItem', 'tiersPrescripteurJsonldPrescripteurWriteContacts', tiersPrescripteurJsonldPrescripteurWriteContacts)
            const localVarPath = `/tiers/prescripteurs/{id}/contacts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurJsonldPrescripteurWriteContacts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mise à jour des droits d\'un prescripeur
         * @summary Mise à jour des droits d\'un prescripeur
         * @param {string} id Resource identifier
         * @param {UpdateDroitsPrescripteurTiersItemRequest} updateDroitsPrescripteurTiersItemRequest Les droits du prescripteur
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDroitsPrescripteurTiersItem: async (id: string, updateDroitsPrescripteurTiersItemRequest: UpdateDroitsPrescripteurTiersItemRequest, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDroitsPrescripteurTiersItem', 'id', id)
            // verify required parameter 'updateDroitsPrescripteurTiersItemRequest' is not null or undefined
            assertParamExists('updateDroitsPrescripteurTiersItem', 'updateDroitsPrescripteurTiersItemRequest', updateDroitsPrescripteurTiersItemRequest)
            const localVarPath = `/tiers/prescripteurs/{id}/droits`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDroitsPrescripteurTiersItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteEmailsPrescripteur} contactsContactJsonldContactWriteEmailsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsContactPrescripteurContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteEmailsPrescripteur: ContactsContactJsonldContactWriteEmailsPrescripteur, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateEmailsContactPrescripteurContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmailsContactPrescripteurContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteEmailsPrescripteur' is not null or undefined
            assertParamExists('updateEmailsContactPrescripteurContactsItem', 'contactsContactJsonldContactWriteEmailsPrescripteur', contactsContactJsonldContactWriteEmailsPrescripteur)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts/{id}/emails`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteEmailsPrescripteur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Configurer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @summary Configurer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteNotificationsEmails} tiersPrescripteurPrescripteurWriteNotificationsEmails The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsNotificationsPrescripteurTiersItem: async (id: string, tiersPrescripteurPrescripteurWriteNotificationsEmails: TiersPrescripteurPrescripteurWriteNotificationsEmails, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmailsNotificationsPrescripteurTiersItem', 'id', id)
            // verify required parameter 'tiersPrescripteurPrescripteurWriteNotificationsEmails' is not null or undefined
            assertParamExists('updateEmailsNotificationsPrescripteurTiersItem', 'tiersPrescripteurPrescripteurWriteNotificationsEmails', tiersPrescripteurPrescripteurWriteNotificationsEmails)
            const localVarPath = `/tiers/prescripteurs/{id}/notifications/emails`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurPrescripteurWriteNotificationsEmails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteFaxsPrescripteur} contactsContactJsonldContactWriteFaxsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxsContactPrescripteurContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteFaxsPrescripteur: ContactsContactJsonldContactWriteFaxsPrescripteur, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateFaxsContactPrescripteurContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFaxsContactPrescripteurContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteFaxsPrescripteur' is not null or undefined
            assertParamExists('updateFaxsContactPrescripteurContactsItem', 'contactsContactJsonldContactWriteFaxsPrescripteur', contactsContactJsonldContactWriteFaxsPrescripteur)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts/{id}/faxs`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteFaxsPrescripteur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour le formaliste du prescripteur
         * @summary Mettre à jour le formaliste du prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurJsonldPrescripteurWriteFormaliste} tiersPrescripteurJsonldPrescripteurWriteFormaliste The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFormalistePrescripteurTiersItem: async (id: string, tiersPrescripteurJsonldPrescripteurWriteFormaliste: TiersPrescripteurJsonldPrescripteurWriteFormaliste, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFormalistePrescripteurTiersItem', 'id', id)
            // verify required parameter 'tiersPrescripteurJsonldPrescripteurWriteFormaliste' is not null or undefined
            assertParamExists('updateFormalistePrescripteurTiersItem', 'tiersPrescripteurJsonldPrescripteurWriteFormaliste', tiersPrescripteurJsonldPrescripteurWriteFormaliste)
            const localVarPath = `/tiers/prescripteurs/{id}/formaliste`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurJsonldPrescripteurWriteFormaliste, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Configurer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @summary Configurer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteNotificationsFrequences} tiersPrescripteurPrescripteurWriteNotificationsFrequences The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFrequencesNotificationsPrescripteurTiersItem: async (id: string, tiersPrescripteurPrescripteurWriteNotificationsFrequences: TiersPrescripteurPrescripteurWriteNotificationsFrequences, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFrequencesNotificationsPrescripteurTiersItem', 'id', id)
            // verify required parameter 'tiersPrescripteurPrescripteurWriteNotificationsFrequences' is not null or undefined
            assertParamExists('updateFrequencesNotificationsPrescripteurTiersItem', 'tiersPrescripteurPrescripteurWriteNotificationsFrequences', tiersPrescripteurPrescripteurWriteNotificationsFrequences)
            const localVarPath = `/tiers/prescripteurs/{id}/notifications/frequences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurPrescripteurWriteNotificationsFrequences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les tarifs des formalités et des annonces légales par société d\'un prescripteur
         * @summary Mettre à jour les tarifs des formalités et des annonces légales par société d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire} tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGrilleForfaitairePrescripteurTiersItem: async (id: string, tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire: TiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGrilleForfaitairePrescripteurTiersItem', 'id', id)
            // verify required parameter 'tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire' is not null or undefined
            assertParamExists('updateGrilleForfaitairePrescripteurTiersItem', 'tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire', tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire)
            const localVarPath = `/tiers/prescripteurs/{id}/conditions-commerciales/forfaits-formalites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @summary Mettre à jour les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile} tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfoClearDocPrescripteurTiersItem: async (id: string, tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile: TiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInfoClearDocPrescripteurTiersItem', 'id', id)
            // verify required parameter 'tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile' is not null or undefined
            assertParamExists('updateInfoClearDocPrescripteurTiersItem', 'tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile', tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile)
            const localVarPath = `/tiers/prescripteurs/{id}/clear-files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteMobilesPrescripteur} contactsContactJsonldContactWriteMobilesPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobilesContactPrescripteurContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteMobilesPrescripteur: ContactsContactJsonldContactWriteMobilesPrescripteur, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateMobilesContactPrescripteurContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMobilesContactPrescripteurContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteMobilesPrescripteur' is not null or undefined
            assertParamExists('updateMobilesContactPrescripteurContactsItem', 'contactsContactJsonldContactWriteMobilesPrescripteur', contactsContactJsonldContactWriteMobilesPrescripteur)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts/{id}/mobiles`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteMobilesPrescripteur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la note d\'un tiers.
         * @summary Mettre à jour la note d\'un tiers
         * @param {string} id Resource identifier
         * @param {TiersTiersJsonldTiersWriteNote} tiersTiersJsonldTiersWriteNote The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteTiersTiersItem: async (id: string, tiersTiersJsonldTiersWriteNote: TiersTiersJsonldTiersWriteNote, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNoteTiersTiersItem', 'id', id)
            // verify required parameter 'tiersTiersJsonldTiersWriteNote' is not null or undefined
            assertParamExists('updateNoteTiersTiersItem', 'tiersTiersJsonldTiersWriteNote', tiersTiersJsonldTiersWriteNote)
            const localVarPath = `/tiers/tiers/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersTiersJsonldTiersWriteNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour un prescripteur
         * @summary Mettre à jour un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurJsonldPrescripteurUpdate} tiersPrescripteurJsonldPrescripteurUpdate The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrescripteurTiersItem: async (id: string, tiersPrescripteurJsonldPrescripteurUpdate: TiersPrescripteurJsonldPrescripteurUpdate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePrescripteurTiersItem', 'id', id)
            // verify required parameter 'tiersPrescripteurJsonldPrescripteurUpdate' is not null or undefined
            assertParamExists('updatePrescripteurTiersItem', 'tiersPrescripteurJsonldPrescripteurUpdate', tiersPrescripteurJsonldPrescripteurUpdate)
            const localVarPath = `/tiers/prescripteurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersPrescripteurJsonldPrescripteurUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les information de la relation entre le prescripteur et la societe
         * @summary Mettre à jour les information de la relation entre le prescripteur et la societe
         * @param {number} tiers Identifiant du tiers
         * @param {string} societe Identifiant de la societe
         * @param {RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete} relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete The updated RelationSocieteTiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelationSocietePrescripteurRelationSocieteTiersItem: async (tiers: number, societe: string, relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete: RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateRelationSocietePrescripteurRelationSocieteTiersItem', 'tiers', tiers)
            // verify required parameter 'societe' is not null or undefined
            assertParamExists('updateRelationSocietePrescripteurRelationSocieteTiersItem', 'societe', societe)
            // verify required parameter 'relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete' is not null or undefined
            assertParamExists('updateRelationSocietePrescripteurRelationSocieteTiersItem', 'relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete', relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete)
            const localVarPath = `/tiers/prescripteurs/{tiers}/societes/{societe}`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"societe"}}`, encodeURIComponent(String(societe)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteTelsPrescripteur} contactsContactJsonldContactWriteTelsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephonesContactPrescripteurContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteTelsPrescripteur: ContactsContactJsonldContactWriteTelsPrescripteur, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateTelephonesContactPrescripteurContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTelephonesContactPrescripteurContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteTelsPrescripteur' is not null or undefined
            assertParamExists('updateTelephonesContactPrescripteurContactsItem', 'contactsContactJsonldContactWriteTelsPrescripteur', contactsContactJsonldContactWriteTelsPrescripteur)
            const localVarPath = `/tiers/prescripteurs/{tiers}/contacts/{id}/telephones`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteTelsPrescripteur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrescripteursApi - functional programming interface
 * @export
 */
export const PrescripteursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrescripteursApiAxiosParamCreator(configuration)
    return {
        /**
         * Ajouter un commercial au prescripteur
         * @summary Ajouter un commercial au prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {CommerciauxCommercialJsonldPrescripteurWriteCommercial} commerciauxCommercialJsonldPrescripteurWriteCommercial The new Commerciaux resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommercialPrescripteurCommerciauxCollection(tiers: number, commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommerciauxTiersJsonldPrescripteurReadCommerciaux>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommercialPrescripteurCommerciauxCollection(tiers, commerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.createCommercialPrescripteurCommerciauxCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer une nouvelle adresse pour un prescripteur
         * @summary Créer une nouvelle adresse pour un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {AdressesAdresseJsonldAdresseCreate} adressesAdresseJsonldAdresseCreate The new Adresses resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPrescripteurAdresseAdressesCollection(tiers: number, adressesAdresseJsonldAdresseCreate: AdressesAdresseJsonldAdresseCreate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdressesAdresseJsonldAdresseRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrescripteurAdresseAdressesCollection(tiers, adressesAdresseJsonldAdresseCreate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.createPrescripteurAdresseAdressesCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un nouveau contact pour un prescripteur
         * @summary Créer un nouveau contact pour un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {ContactsContactJsonldContactCreate} contactsContactJsonldContactCreate The new Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPrescripteurContactContactsCollection(tiers: number, contactsContactJsonldContactCreate: ContactsContactJsonldContactCreate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrescripteurContactContactsCollection(tiers, contactsContactJsonldContactCreate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.createPrescripteurContactContactsCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un nouveau préscripteur
         * @summary Créer un nouveau préscripteur
         * @param {TiersPrescripteurJsonldPrescripteurCreate} tiersPrescripteurJsonldPrescripteurCreate The new Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPrescripteurTiersCollection(tiersPrescripteurJsonldPrescripteurCreate: TiersPrescripteurJsonldPrescripteurCreate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPrescripteurTiersCollection(tiersPrescripteurJsonldPrescripteurCreate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.createPrescripteurTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer le commercial d\'un prescripteur
         * @summary Supprimer le commercial d\'un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommercialPrescripteurCommerciauxItem(tiers: number, id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommercialPrescripteurCommerciauxItem(tiers, id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.deleteCommercialPrescripteurCommerciauxItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Générer un code client CEGID pour une société
         * @summary Générer un code client CEGID pour une société
         * @param {GetCodeClientTiersCollectionRequest} getCodeClientTiersCollectionRequest L\&#39;identifiant de la société
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCodeClientTiersCollection(getCodeClientTiersCollectionRequest: GetCodeClientTiersCollectionRequest, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCodeClientTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCodeClientTiersCollection(getCodeClientTiersCollectionRequest, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getCodeClientTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des commerciaux liés à un prescripteur
         * @summary Obtenir la liste des commerciaux liés à un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommerciauxPrescripteurTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldPrescripteurReadCommerciaux>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommerciauxPrescripteurTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getCommerciauxPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @summary Récupérer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailsNotificationsPrescripteurTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurReadNotificationsEmails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailsNotificationsPrescripteurTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getEmailsNotificationsPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des fournisseurs liés à un prescripteur
         * @summary Obtenir la liste des fournisseurs liés à un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFournisseursPrescripteurTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldPrescripteurReadFournisseurs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFournisseursPrescripteurTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getFournisseursPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @summary Récupérer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrequencesNotificationsPrescripteurTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrequencesNotificationsPrescripteurTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getFrequencesNotificationsPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les grilles forfaitaires des formalités par société d\'un prescripteur
         * @summary Récupérer les grilles forfaitaires des formalités par société d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [societe] Récupérer la grille tarifaire pour une société en particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrilleForfaitairePrescripteurTiersItem(id: string, xIdSociete?: number, xIdUser?: number, societe?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrilleForfaitairePrescripteurTiersItem(id, xIdSociete, xIdUser, societe, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getGrilleForfaitairePrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @summary Récupérer les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [societe] Récupérer les information de suppression des docs pour une société en particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoClearDocPrescripteurTiersItem(id: string, xIdSociete?: number, xIdUser?: number, societe?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoClearDocPrescripteurTiersItem(id, xIdSociete, xIdUser, societe, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getInfoClearDocPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [search] Perform a search operation on OpenSearch, data must be in json format. example: &#x60;{\&quot;query\&quot;: {\&quot;match\&quot;: {\&quot;relationsSocietes.societe.id\&quot;: 1}}}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTiersTiersCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTiersTiersCollection(xIdSociete, xIdUser, page, itemsPerPage, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getListTiersTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer le chiffre d\'affaire der l\'année en cours pour un prescripteur
         * @summary Récupérer le chiffre d\'affaire der l\'année en cours pour un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrescriberAnnualSalesTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrescriberAnnualSalesTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getPrescriberAnnualSalesTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer l\'encours d\'un prescripteur
         * @summary Récupérer l\'encours d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrescriberOutstandingTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrescriberOutstandingTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getPrescriberOutstandingTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer le régime tva du tiers en fonction de sa localisation
         * @summary Récupérer le régime tva du tiers en fonction de sa localisation
         * @param {GetRegimeTvaTiersCollectionRequest} getRegimeTvaTiersCollectionRequest Au moins un de ces paramètres :  - &#x60;idTiers&#x60;  - &#x60;codeInsee&#x60;  - &#x60;codePostal&#x60; + &#x60;ville&#x60;
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegimeTvaTiersCollection(getRegimeTvaTiersCollectionRequest: GetRegimeTvaTiersCollectionRequest, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRegimeTvaTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegimeTvaTiersCollection(getRegimeTvaTiersCollectionRequest, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getRegimeTvaTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTiersTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTiersTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.getTiersTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @param {Array<string>} emailAddresses Une ou plusieurs adresses emails sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTiersByEmailAddressesTiersCollection(emailAddresses: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTiersByEmailAddressesTiersCollection(emailAddresses, xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.searchTiersByEmailAddressesTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @param {Array<string>} emailDomains Un ou plusieurs domaines d\&#39;adresse email sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTiersByEmailDomainsTiersCollection(emailDomains: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTiersByEmailDomainsTiersCollection(emailDomains, xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.searchTiersByEmailDomainsTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTiersGroupTypeTiersCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchTiersGroupTypeTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTiersGroupTypeTiersCollection(xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.searchTiersGroupTypeTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des adresses d\'un prescripteur. Attention : la liste COMPLETE des adresses du prescripteur est à passer dans la requête, les adresses absentes seront supprimées.
         * @summary Mettre à jour la liste des adresses d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurJsonldPrescripteurWriteAdresses} tiersPrescripteurJsonldPrescripteurWriteAdresses The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdressesPrescripteurTiersItem(id: string, tiersPrescripteurJsonldPrescripteurWriteAdresses: TiersPrescripteurJsonldPrescripteurWriteAdresses, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdressesPrescripteurTiersItem(id, tiersPrescripteurJsonldPrescripteurWriteAdresses, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateAdressesPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les données d\'un commercial d\'un prescripteur
         * @summary Mettre à jour les données d\'un commercial d\'un prescripteur
         * @param {number} tiers Identifiant du tiers
         * @param {string} id Resource identifier
         * @param {CommerciauxCommercialJsonldPrescripteurWriteCommercial} commerciauxCommercialJsonldPrescripteurWriteCommercial The updated Commerciaux resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommercialPrescripteurCommerciauxItem(tiers: number, id: string, commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommerciauxTiersJsonldPrescripteurReadCommerciaux>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommercialPrescripteurCommerciauxItem(tiers, id, commerciauxCommercialJsonldPrescripteurWriteCommercial, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateCommercialPrescripteurCommerciauxItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mise à jour des conditions commerciales sur les formalités d\'un prescripeur
         * @summary Mise à jour des conditions commerciales sur les formalités d\'un prescripeur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites} tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConditionsCommercialesFormalitesPrescripteurTiersItem(id: string, tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites: TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesFormalites>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConditionsCommercialesFormalitesPrescripteurTiersItem(id, tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateConditionsCommercialesFormalitesPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des contacts d\'un prescripteur. Attention : la liste COMPLETE des contacts du prescripteur est à passer dans la requête, les contacts absents seront supprimées.
         * @summary Mettre à jour la liste des contacts d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurJsonldPrescripteurWriteContacts} tiersPrescripteurJsonldPrescripteurWriteContacts The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactsPrescripteurTiersItem(id: string, tiersPrescripteurJsonldPrescripteurWriteContacts: TiersPrescripteurJsonldPrescripteurWriteContacts, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactsPrescripteurTiersItem(id, tiersPrescripteurJsonldPrescripteurWriteContacts, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateContactsPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mise à jour des droits d\'un prescripeur
         * @summary Mise à jour des droits d\'un prescripeur
         * @param {string} id Resource identifier
         * @param {UpdateDroitsPrescripteurTiersItemRequest} updateDroitsPrescripteurTiersItemRequest Les droits du prescripteur
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDroitsPrescripteurTiersItem(id: string, updateDroitsPrescripteurTiersItemRequest: UpdateDroitsPrescripteurTiersItemRequest, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDroitsPrescripteurTiersItemRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDroitsPrescripteurTiersItem(id, updateDroitsPrescripteurTiersItemRequest, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateDroitsPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteEmailsPrescripteur} contactsContactJsonldContactWriteEmailsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailsContactPrescripteurContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteEmailsPrescripteur: ContactsContactJsonldContactWriteEmailsPrescripteur, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailsContactPrescripteurContactsItem(tiers, id, contactsContactJsonldContactWriteEmailsPrescripteur, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateEmailsContactPrescripteurContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Configurer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @summary Configurer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteNotificationsEmails} tiersPrescripteurPrescripteurWriteNotificationsEmails The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailsNotificationsPrescripteurTiersItem(id: string, tiersPrescripteurPrescripteurWriteNotificationsEmails: TiersPrescripteurPrescripteurWriteNotificationsEmails, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurReadNotificationsEmails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailsNotificationsPrescripteurTiersItem(id, tiersPrescripteurPrescripteurWriteNotificationsEmails, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateEmailsNotificationsPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteFaxsPrescripteur} contactsContactJsonldContactWriteFaxsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFaxsContactPrescripteurContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteFaxsPrescripteur: ContactsContactJsonldContactWriteFaxsPrescripteur, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFaxsContactPrescripteurContactsItem(tiers, id, contactsContactJsonldContactWriteFaxsPrescripteur, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateFaxsContactPrescripteurContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour le formaliste du prescripteur
         * @summary Mettre à jour le formaliste du prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurJsonldPrescripteurWriteFormaliste} tiersPrescripteurJsonldPrescripteurWriteFormaliste The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFormalistePrescripteurTiersItem(id: string, tiersPrescripteurJsonldPrescripteurWriteFormaliste: TiersPrescripteurJsonldPrescripteurWriteFormaliste, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFormalistePrescripteurTiersItem(id, tiersPrescripteurJsonldPrescripteurWriteFormaliste, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateFormalistePrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Configurer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @summary Configurer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteNotificationsFrequences} tiersPrescripteurPrescripteurWriteNotificationsFrequences The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFrequencesNotificationsPrescripteurTiersItem(id: string, tiersPrescripteurPrescripteurWriteNotificationsFrequences: TiersPrescripteurPrescripteurWriteNotificationsFrequences, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFrequencesNotificationsPrescripteurTiersItem(id, tiersPrescripteurPrescripteurWriteNotificationsFrequences, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateFrequencesNotificationsPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les tarifs des formalités et des annonces légales par société d\'un prescripteur
         * @summary Mettre à jour les tarifs des formalités et des annonces légales par société d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire} tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGrilleForfaitairePrescripteurTiersItem(id: string, tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire: TiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGrilleForfaitairePrescripteurTiersItem(id, tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateGrilleForfaitairePrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @summary Mettre à jour les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile} tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInfoClearDocPrescripteurTiersItem(id: string, tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile: TiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInfoClearDocPrescripteurTiersItem(id, tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateInfoClearDocPrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteMobilesPrescripteur} contactsContactJsonldContactWriteMobilesPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMobilesContactPrescripteurContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteMobilesPrescripteur: ContactsContactJsonldContactWriteMobilesPrescripteur, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMobilesContactPrescripteurContactsItem(tiers, id, contactsContactJsonldContactWriteMobilesPrescripteur, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateMobilesContactPrescripteurContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la note d\'un tiers.
         * @summary Mettre à jour la note d\'un tiers
         * @param {string} id Resource identifier
         * @param {TiersTiersJsonldTiersWriteNote} tiersTiersJsonldTiersWriteNote The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNoteTiersTiersItem(id: string, tiersTiersJsonldTiersWriteNote: TiersTiersJsonldTiersWriteNote, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoteTiersTiersItem(id, tiersTiersJsonldTiersWriteNote, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateNoteTiersTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour un prescripteur
         * @summary Mettre à jour un prescripteur
         * @param {string} id Resource identifier
         * @param {TiersPrescripteurJsonldPrescripteurUpdate} tiersPrescripteurJsonldPrescripteurUpdate The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrescripteurTiersItem(id: string, tiersPrescripteurJsonldPrescripteurUpdate: TiersPrescripteurJsonldPrescripteurUpdate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersPrescripteurJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrescripteurTiersItem(id, tiersPrescripteurJsonldPrescripteurUpdate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updatePrescripteurTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les information de la relation entre le prescripteur et la societe
         * @summary Mettre à jour les information de la relation entre le prescripteur et la societe
         * @param {number} tiers Identifiant du tiers
         * @param {string} societe Identifiant de la societe
         * @param {RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete} relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete The updated RelationSocieteTiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRelationSocietePrescripteurRelationSocieteTiersItem(tiers: number, societe: string, relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete: RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelationSocieteTiersRelationSocieteTiersJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRelationSocietePrescripteurRelationSocieteTiersItem(tiers, societe, relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateRelationSocietePrescripteurRelationSocieteTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteTelsPrescripteur} contactsContactJsonldContactWriteTelsPrescripteur The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTelephonesContactPrescripteurContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteTelsPrescripteur: ContactsContactJsonldContactWriteTelsPrescripteur, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTelephonesContactPrescripteurContactsItem(tiers, id, contactsContactJsonldContactWriteTelsPrescripteur, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrescripteursApi.updateTelephonesContactPrescripteurContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PrescripteursApi - factory interface
 * @export
 */
export const PrescripteursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrescripteursApiFp(configuration)
    return {
        /**
         * Ajouter un commercial au prescripteur
         * @summary Ajouter un commercial au prescripteur
         * @param {PrescripteursApiCreateCommercialPrescripteurCommerciauxCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommercialPrescripteurCommerciauxCollection(requestParameters: PrescripteursApiCreateCommercialPrescripteurCommerciauxCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CommerciauxTiersJsonldPrescripteurReadCommerciaux> {
            return localVarFp.createCommercialPrescripteurCommerciauxCollection(requestParameters.tiers, requestParameters.commerciauxCommercialJsonldPrescripteurWriteCommercial, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer une nouvelle adresse pour un prescripteur
         * @summary Créer une nouvelle adresse pour un prescripteur
         * @param {PrescripteursApiCreatePrescripteurAdresseAdressesCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurAdresseAdressesCollection(requestParameters: PrescripteursApiCreatePrescripteurAdresseAdressesCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdressesAdresseJsonldAdresseRead> {
            return localVarFp.createPrescripteurAdresseAdressesCollection(requestParameters.tiers, requestParameters.adressesAdresseJsonldAdresseCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un nouveau contact pour un prescripteur
         * @summary Créer un nouveau contact pour un prescripteur
         * @param {PrescripteursApiCreatePrescripteurContactContactsCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurContactContactsCollection(requestParameters: PrescripteursApiCreatePrescripteurContactContactsCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.createPrescripteurContactContactsCollection(requestParameters.tiers, requestParameters.contactsContactJsonldContactCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un nouveau préscripteur
         * @summary Créer un nouveau préscripteur
         * @param {PrescripteursApiCreatePrescripteurTiersCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrescripteurTiersCollection(requestParameters: PrescripteursApiCreatePrescripteurTiersCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurCreated> {
            return localVarFp.createPrescripteurTiersCollection(requestParameters.tiersPrescripteurJsonldPrescripteurCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer le commercial d\'un prescripteur
         * @summary Supprimer le commercial d\'un prescripteur
         * @param {PrescripteursApiDeleteCommercialPrescripteurCommerciauxItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommercialPrescripteurCommerciauxItem(requestParameters: PrescripteursApiDeleteCommercialPrescripteurCommerciauxItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCommercialPrescripteurCommerciauxItem(requestParameters.tiers, requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Générer un code client CEGID pour une société
         * @summary Générer un code client CEGID pour une société
         * @param {PrescripteursApiGetCodeClientTiersCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeClientTiersCollection(requestParameters: PrescripteursApiGetCodeClientTiersCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetCodeClientTiersCollection200Response> {
            return localVarFp.getCodeClientTiersCollection(requestParameters.getCodeClientTiersCollectionRequest, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des commerciaux liés à un prescripteur
         * @summary Obtenir la liste des commerciaux liés à un prescripteur
         * @param {PrescripteursApiGetCommerciauxPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommerciauxPrescripteurTiersItem(requestParameters: PrescripteursApiGetCommerciauxPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldPrescripteurReadCommerciaux> {
            return localVarFp.getCommerciauxPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @summary Récupérer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @param {PrescripteursApiGetEmailsNotificationsPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailsNotificationsPrescripteurTiersItem(requestParameters: PrescripteursApiGetEmailsNotificationsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurReadNotificationsEmails> {
            return localVarFp.getEmailsNotificationsPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des fournisseurs liés à un prescripteur
         * @summary Obtenir la liste des fournisseurs liés à un prescripteur
         * @param {PrescripteursApiGetFournisseursPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFournisseursPrescripteurTiersItem(requestParameters: PrescripteursApiGetFournisseursPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldPrescripteurReadFournisseurs> {
            return localVarFp.getFournisseursPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @summary Récupérer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @param {PrescripteursApiGetFrequencesNotificationsPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrequencesNotificationsPrescripteurTiersItem(requestParameters: PrescripteursApiGetFrequencesNotificationsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences> {
            return localVarFp.getFrequencesNotificationsPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les grilles forfaitaires des formalités par société d\'un prescripteur
         * @summary Récupérer les grilles forfaitaires des formalités par société d\'un prescripteur
         * @param {PrescripteursApiGetGrilleForfaitairePrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrilleForfaitairePrescripteurTiersItem(requestParameters: PrescripteursApiGetGrilleForfaitairePrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire> {
            return localVarFp.getGrilleForfaitairePrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.societe, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @summary Récupérer les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @param {PrescripteursApiGetInfoClearDocPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoClearDocPrescripteurTiersItem(requestParameters: PrescripteursApiGetInfoClearDocPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile> {
            return localVarFp.getInfoClearDocPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.societe, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {PrescripteursApiGetListTiersTiersCollection0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTiersTiersCollection(requestParameters: PrescripteursApiGetListTiersTiersCollection0Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersTiersCollection200Response> {
            return localVarFp.getListTiersTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer le chiffre d\'affaire der l\'année en cours pour un prescripteur
         * @summary Récupérer le chiffre d\'affaire der l\'année en cours pour un prescripteur
         * @param {PrescripteursApiGetPrescriberAnnualSalesTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriberAnnualSalesTiersItem(requestParameters: PrescripteursApiGetPrescriberAnnualSalesTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersJsonldTiersRead> {
            return localVarFp.getPrescriberAnnualSalesTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer l\'encours d\'un prescripteur
         * @summary Récupérer l\'encours d\'un prescripteur
         * @param {PrescripteursApiGetPrescriberOutstandingTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriberOutstandingTiersItem(requestParameters: PrescripteursApiGetPrescriberOutstandingTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersJsonldTiersRead> {
            return localVarFp.getPrescriberOutstandingTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer le régime tva du tiers en fonction de sa localisation
         * @summary Récupérer le régime tva du tiers en fonction de sa localisation
         * @param {PrescripteursApiGetRegimeTvaTiersCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegimeTvaTiersCollection(requestParameters: PrescripteursApiGetRegimeTvaTiersCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetRegimeTvaTiersCollection200Response> {
            return localVarFp.getRegimeTvaTiersCollection(requestParameters.getRegimeTvaTiersCollectionRequest, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @param {PrescripteursApiGetTiersTiersItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiersTiersItem(requestParameters: PrescripteursApiGetTiersTiersItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldTiersRead> {
            return localVarFp.getTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @param {PrescripteursApiSearchTiersByEmailAddressesTiersCollection0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailAddressesTiersCollection(requestParameters: PrescripteursApiSearchTiersByEmailAddressesTiersCollection0Request, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersTiersCollection200Response> {
            return localVarFp.searchTiersByEmailAddressesTiersCollection(requestParameters.emailAddresses, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @param {PrescripteursApiSearchTiersByEmailDomainsTiersCollection0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailDomainsTiersCollection(requestParameters: PrescripteursApiSearchTiersByEmailDomainsTiersCollection0Request, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersTiersCollection200Response> {
            return localVarFp.searchTiersByEmailDomainsTiersCollection(requestParameters.emailDomains, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {PrescripteursApiSearchTiersGroupTypeTiersCollection0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersGroupTypeTiersCollection(requestParameters: PrescripteursApiSearchTiersGroupTypeTiersCollection0Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<SearchTiersGroupTypeTiersCollection200Response> {
            return localVarFp.searchTiersGroupTypeTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des adresses d\'un prescripteur. Attention : la liste COMPLETE des adresses du prescripteur est à passer dans la requête, les adresses absentes seront supprimées.
         * @summary Mettre à jour la liste des adresses d\'un prescripteur
         * @param {PrescripteursApiUpdateAdressesPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdressesPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateAdressesPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldTiersRead> {
            return localVarFp.updateAdressesPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurJsonldPrescripteurWriteAdresses, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les données d\'un commercial d\'un prescripteur
         * @summary Mettre à jour les données d\'un commercial d\'un prescripteur
         * @param {PrescripteursApiUpdateCommercialPrescripteurCommerciauxItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommercialPrescripteurCommerciauxItem(requestParameters: PrescripteursApiUpdateCommercialPrescripteurCommerciauxItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<CommerciauxTiersJsonldPrescripteurReadCommerciaux> {
            return localVarFp.updateCommercialPrescripteurCommerciauxItem(requestParameters.tiers, requestParameters.id, requestParameters.commerciauxCommercialJsonldPrescripteurWriteCommercial, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mise à jour des conditions commerciales sur les formalités d\'un prescripeur
         * @summary Mise à jour des conditions commerciales sur les formalités d\'un prescripeur
         * @param {PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionsCommercialesFormalitesPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesFormalites> {
            return localVarFp.updateConditionsCommercialesFormalitesPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des contacts d\'un prescripteur. Attention : la liste COMPLETE des contacts du prescripteur est à passer dans la requête, les contacts absents seront supprimées.
         * @summary Mettre à jour la liste des contacts d\'un prescripteur
         * @param {PrescripteursApiUpdateContactsPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactsPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateContactsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldTiersRead> {
            return localVarFp.updateContactsPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurJsonldPrescripteurWriteContacts, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mise à jour des droits d\'un prescripeur
         * @summary Mise à jour des droits d\'un prescripeur
         * @param {PrescripteursApiUpdateDroitsPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDroitsPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateDroitsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateDroitsPrescripteurTiersItemRequest> {
            return localVarFp.updateDroitsPrescripteurTiersItem(requestParameters.id, requestParameters.updateDroitsPrescripteurTiersItemRequest, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un prescripteur
         * @param {PrescripteursApiUpdateEmailsContactPrescripteurContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsContactPrescripteurContactsItem(requestParameters: PrescripteursApiUpdateEmailsContactPrescripteurContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateEmailsContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteEmailsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Configurer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @summary Configurer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
         * @param {PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsNotificationsPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurReadNotificationsEmails> {
            return localVarFp.updateEmailsNotificationsPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteNotificationsEmails, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur
         * @param {PrescripteursApiUpdateFaxsContactPrescripteurContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxsContactPrescripteurContactsItem(requestParameters: PrescripteursApiUpdateFaxsContactPrescripteurContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateFaxsContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteFaxsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour le formaliste du prescripteur
         * @summary Mettre à jour le formaliste du prescripteur
         * @param {PrescripteursApiUpdateFormalistePrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFormalistePrescripteurTiersItem(requestParameters: PrescripteursApiUpdateFormalistePrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldTiersRead> {
            return localVarFp.updateFormalistePrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurJsonldPrescripteurWriteFormaliste, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Configurer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @summary Configurer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
         * @param {PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFrequencesNotificationsPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurReadNotificationsFrequences> {
            return localVarFp.updateFrequencesNotificationsPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteNotificationsFrequences, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les tarifs des formalités et des annonces légales par société d\'un prescripteur
         * @summary Mettre à jour les tarifs des formalités et des annonces légales par société d\'un prescripteur
         * @param {PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGrilleForfaitairePrescripteurTiersItem(requestParameters: PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesGrilleTarifaire> {
            return localVarFp.updateGrilleForfaitairePrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @summary Mettre à jour les informations commerciales concernant la suppression des docs d\'un prescripteur
         * @param {PrescripteursApiUpdateInfoClearDocPrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfoClearDocPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateInfoClearDocPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldPrescripteurReadConditionsCommercialesClearFile> {
            return localVarFp.updateInfoClearDocPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur
         * @param {PrescripteursApiUpdateMobilesContactPrescripteurContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobilesContactPrescripteurContactsItem(requestParameters: PrescripteursApiUpdateMobilesContactPrescripteurContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateMobilesContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteMobilesPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la note d\'un tiers.
         * @summary Mettre à jour la note d\'un tiers
         * @param {PrescripteursApiUpdateNoteTiersTiersItem0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteTiersTiersItem(requestParameters: PrescripteursApiUpdateNoteTiersTiersItem0Request, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldTiersRead> {
            return localVarFp.updateNoteTiersTiersItem(requestParameters.id, requestParameters.tiersTiersJsonldTiersWriteNote, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour un prescripteur
         * @summary Mettre à jour un prescripteur
         * @param {PrescripteursApiUpdatePrescripteurTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrescripteurTiersItem(requestParameters: PrescripteursApiUpdatePrescripteurTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersPrescripteurJsonldTiersRead> {
            return localVarFp.updatePrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurJsonldPrescripteurUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les information de la relation entre le prescripteur et la societe
         * @summary Mettre à jour les information de la relation entre le prescripteur et la societe
         * @param {PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelationSocietePrescripteurRelationSocieteTiersItem(requestParameters: PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<RelationSocieteTiersRelationSocieteTiersJsonldTiersRead> {
            return localVarFp.updateRelationSocietePrescripteurRelationSocieteTiersItem(requestParameters.tiers, requestParameters.societe, requestParameters.relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur
         * @param {PrescripteursApiUpdateTelephonesContactPrescripteurContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephonesContactPrescripteurContactsItem(requestParameters: PrescripteursApiUpdateTelephonesContactPrescripteurContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateTelephonesContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteTelsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCommercialPrescripteurCommerciauxCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiCreateCommercialPrescripteurCommerciauxCollectionRequest
 */
export interface PrescripteursApiCreateCommercialPrescripteurCommerciauxCollectionRequest {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof PrescripteursApiCreateCommercialPrescripteurCommerciauxCollection
     */
    readonly tiers: number

    /**
     * The new Commerciaux resource
     * @type {CommerciauxCommercialJsonldPrescripteurWriteCommercial}
     * @memberof PrescripteursApiCreateCommercialPrescripteurCommerciauxCollection
     */
    readonly commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiCreateCommercialPrescripteurCommerciauxCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiCreateCommercialPrescripteurCommerciauxCollection
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for createPrescripteurAdresseAdressesCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiCreatePrescripteurAdresseAdressesCollectionRequest
 */
export interface PrescripteursApiCreatePrescripteurAdresseAdressesCollectionRequest {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof PrescripteursApiCreatePrescripteurAdresseAdressesCollection
     */
    readonly tiers: number

    /**
     * The new Adresses resource
     * @type {AdressesAdresseJsonldAdresseCreate}
     * @memberof PrescripteursApiCreatePrescripteurAdresseAdressesCollection
     */
    readonly adressesAdresseJsonldAdresseCreate: AdressesAdresseJsonldAdresseCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiCreatePrescripteurAdresseAdressesCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiCreatePrescripteurAdresseAdressesCollection
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for createPrescripteurContactContactsCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiCreatePrescripteurContactContactsCollectionRequest
 */
export interface PrescripteursApiCreatePrescripteurContactContactsCollectionRequest {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof PrescripteursApiCreatePrescripteurContactContactsCollection
     */
    readonly tiers: number

    /**
     * The new Contacts resource
     * @type {ContactsContactJsonldContactCreate}
     * @memberof PrescripteursApiCreatePrescripteurContactContactsCollection
     */
    readonly contactsContactJsonldContactCreate: ContactsContactJsonldContactCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiCreatePrescripteurContactContactsCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiCreatePrescripteurContactContactsCollection
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for createPrescripteurTiersCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiCreatePrescripteurTiersCollectionRequest
 */
export interface PrescripteursApiCreatePrescripteurTiersCollectionRequest {
    /**
     * The new Tiers resource
     * @type {TiersPrescripteurJsonldPrescripteurCreate}
     * @memberof PrescripteursApiCreatePrescripteurTiersCollection
     */
    readonly tiersPrescripteurJsonldPrescripteurCreate: TiersPrescripteurJsonldPrescripteurCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiCreatePrescripteurTiersCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiCreatePrescripteurTiersCollection
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for deleteCommercialPrescripteurCommerciauxItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiDeleteCommercialPrescripteurCommerciauxItemRequest
 */
export interface PrescripteursApiDeleteCommercialPrescripteurCommerciauxItemRequest {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof PrescripteursApiDeleteCommercialPrescripteurCommerciauxItem
     */
    readonly tiers: number

    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiDeleteCommercialPrescripteurCommerciauxItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiDeleteCommercialPrescripteurCommerciauxItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiDeleteCommercialPrescripteurCommerciauxItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getCodeClientTiersCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetCodeClientTiersCollectionRequest
 */
export interface PrescripteursApiGetCodeClientTiersCollectionRequest {
    /**
     * L\&#39;identifiant de la société
     * @type {GetCodeClientTiersCollectionRequest}
     * @memberof PrescripteursApiGetCodeClientTiersCollection
     */
    readonly getCodeClientTiersCollectionRequest: GetCodeClientTiersCollectionRequest

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetCodeClientTiersCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetCodeClientTiersCollection
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getCommerciauxPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetCommerciauxPrescripteurTiersItemRequest
 */
export interface PrescripteursApiGetCommerciauxPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiGetCommerciauxPrescripteurTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetCommerciauxPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetCommerciauxPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getEmailsNotificationsPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetEmailsNotificationsPrescripteurTiersItemRequest
 */
export interface PrescripteursApiGetEmailsNotificationsPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiGetEmailsNotificationsPrescripteurTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetEmailsNotificationsPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetEmailsNotificationsPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getFournisseursPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetFournisseursPrescripteurTiersItemRequest
 */
export interface PrescripteursApiGetFournisseursPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiGetFournisseursPrescripteurTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetFournisseursPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetFournisseursPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getFrequencesNotificationsPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetFrequencesNotificationsPrescripteurTiersItemRequest
 */
export interface PrescripteursApiGetFrequencesNotificationsPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiGetFrequencesNotificationsPrescripteurTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetFrequencesNotificationsPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetFrequencesNotificationsPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getGrilleForfaitairePrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetGrilleForfaitairePrescripteurTiersItemRequest
 */
export interface PrescripteursApiGetGrilleForfaitairePrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiGetGrilleForfaitairePrescripteurTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetGrilleForfaitairePrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetGrilleForfaitairePrescripteurTiersItem
     */
    readonly xIdUser?: number

    /**
     * Récupérer la grille tarifaire pour une société en particulier
     * @type {number}
     * @memberof PrescripteursApiGetGrilleForfaitairePrescripteurTiersItem
     */
    readonly societe?: number
}

/**
 * Request parameters for getInfoClearDocPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetInfoClearDocPrescripteurTiersItemRequest
 */
export interface PrescripteursApiGetInfoClearDocPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiGetInfoClearDocPrescripteurTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetInfoClearDocPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetInfoClearDocPrescripteurTiersItem
     */
    readonly xIdUser?: number

    /**
     * Récupérer les information de suppression des docs pour une société en particulier
     * @type {number}
     * @memberof PrescripteursApiGetInfoClearDocPrescripteurTiersItem
     */
    readonly societe?: number
}

/**
 * Request parameters for getListTiersTiersCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetListTiersTiersCollection0Request
 */
export interface PrescripteursApiGetListTiersTiersCollection0Request {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetListTiersTiersCollection0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetListTiersTiersCollection0
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof PrescripteursApiGetListTiersTiersCollection0
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PrescripteursApiGetListTiersTiersCollection0
     */
    readonly itemsPerPage?: number

    /**
     * Perform a search operation on OpenSearch, data must be in json format. example: &#x60;{\&quot;query\&quot;: {\&quot;match\&quot;: {\&quot;relationsSocietes.societe.id\&quot;: 1}}}&#x60;
     * @type {string}
     * @memberof PrescripteursApiGetListTiersTiersCollection0
     */
    readonly search?: string
}

/**
 * Request parameters for getPrescriberAnnualSalesTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetPrescriberAnnualSalesTiersItemRequest
 */
export interface PrescripteursApiGetPrescriberAnnualSalesTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiGetPrescriberAnnualSalesTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetPrescriberAnnualSalesTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetPrescriberAnnualSalesTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getPrescriberOutstandingTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetPrescriberOutstandingTiersItemRequest
 */
export interface PrescripteursApiGetPrescriberOutstandingTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiGetPrescriberOutstandingTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetPrescriberOutstandingTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetPrescriberOutstandingTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getRegimeTvaTiersCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetRegimeTvaTiersCollectionRequest
 */
export interface PrescripteursApiGetRegimeTvaTiersCollectionRequest {
    /**
     * Au moins un de ces paramètres :  - &#x60;idTiers&#x60;  - &#x60;codeInsee&#x60;  - &#x60;codePostal&#x60; + &#x60;ville&#x60;
     * @type {GetRegimeTvaTiersCollectionRequest}
     * @memberof PrescripteursApiGetRegimeTvaTiersCollection
     */
    readonly getRegimeTvaTiersCollectionRequest: GetRegimeTvaTiersCollectionRequest

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetRegimeTvaTiersCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetRegimeTvaTiersCollection
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getTiersTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiGetTiersTiersItem0Request
 */
export interface PrescripteursApiGetTiersTiersItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiGetTiersTiersItem0
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiGetTiersTiersItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiGetTiersTiersItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for searchTiersByEmailAddressesTiersCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiSearchTiersByEmailAddressesTiersCollection0Request
 */
export interface PrescripteursApiSearchTiersByEmailAddressesTiersCollection0Request {
    /**
     * Une ou plusieurs adresses emails sur lesquelles effectuer la recherche
     * @type {Array<string>}
     * @memberof PrescripteursApiSearchTiersByEmailAddressesTiersCollection0
     */
    readonly emailAddresses: Array<string>

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiSearchTiersByEmailAddressesTiersCollection0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiSearchTiersByEmailAddressesTiersCollection0
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof PrescripteursApiSearchTiersByEmailAddressesTiersCollection0
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PrescripteursApiSearchTiersByEmailAddressesTiersCollection0
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for searchTiersByEmailDomainsTiersCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiSearchTiersByEmailDomainsTiersCollection0Request
 */
export interface PrescripteursApiSearchTiersByEmailDomainsTiersCollection0Request {
    /**
     * Un ou plusieurs domaines d\&#39;adresse email sur lesquelles effectuer la recherche
     * @type {Array<string>}
     * @memberof PrescripteursApiSearchTiersByEmailDomainsTiersCollection0
     */
    readonly emailDomains: Array<string>

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiSearchTiersByEmailDomainsTiersCollection0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiSearchTiersByEmailDomainsTiersCollection0
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof PrescripteursApiSearchTiersByEmailDomainsTiersCollection0
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PrescripteursApiSearchTiersByEmailDomainsTiersCollection0
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for searchTiersGroupTypeTiersCollection operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiSearchTiersGroupTypeTiersCollection0Request
 */
export interface PrescripteursApiSearchTiersGroupTypeTiersCollection0Request {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiSearchTiersGroupTypeTiersCollection0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiSearchTiersGroupTypeTiersCollection0
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof PrescripteursApiSearchTiersGroupTypeTiersCollection0
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PrescripteursApiSearchTiersGroupTypeTiersCollection0
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for updateAdressesPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateAdressesPrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdateAdressesPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateAdressesPrescripteurTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersPrescripteurJsonldPrescripteurWriteAdresses}
     * @memberof PrescripteursApiUpdateAdressesPrescripteurTiersItem
     */
    readonly tiersPrescripteurJsonldPrescripteurWriteAdresses: TiersPrescripteurJsonldPrescripteurWriteAdresses

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateAdressesPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateAdressesPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateCommercialPrescripteurCommerciauxItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateCommercialPrescripteurCommerciauxItemRequest
 */
export interface PrescripteursApiUpdateCommercialPrescripteurCommerciauxItemRequest {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof PrescripteursApiUpdateCommercialPrescripteurCommerciauxItem
     */
    readonly tiers: number

    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateCommercialPrescripteurCommerciauxItem
     */
    readonly id: string

    /**
     * The updated Commerciaux resource
     * @type {CommerciauxCommercialJsonldPrescripteurWriteCommercial}
     * @memberof PrescripteursApiUpdateCommercialPrescripteurCommerciauxItem
     */
    readonly commerciauxCommercialJsonldPrescripteurWriteCommercial: CommerciauxCommercialJsonldPrescripteurWriteCommercial

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateCommercialPrescripteurCommerciauxItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateCommercialPrescripteurCommerciauxItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateConditionsCommercialesFormalitesPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites}
     * @memberof PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItem
     */
    readonly tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites: TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateContactsPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateContactsPrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdateContactsPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateContactsPrescripteurTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersPrescripteurJsonldPrescripteurWriteContacts}
     * @memberof PrescripteursApiUpdateContactsPrescripteurTiersItem
     */
    readonly tiersPrescripteurJsonldPrescripteurWriteContacts: TiersPrescripteurJsonldPrescripteurWriteContacts

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateContactsPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateContactsPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateDroitsPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateDroitsPrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdateDroitsPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateDroitsPrescripteurTiersItem
     */
    readonly id: string

    /**
     * Les droits du prescripteur
     * @type {UpdateDroitsPrescripteurTiersItemRequest}
     * @memberof PrescripteursApiUpdateDroitsPrescripteurTiersItem
     */
    readonly updateDroitsPrescripteurTiersItemRequest: UpdateDroitsPrescripteurTiersItemRequest

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateDroitsPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateDroitsPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateEmailsContactPrescripteurContactsItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateEmailsContactPrescripteurContactsItemRequest
 */
export interface PrescripteursApiUpdateEmailsContactPrescripteurContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateEmailsContactPrescripteurContactsItem
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateEmailsContactPrescripteurContactsItem
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteEmailsPrescripteur}
     * @memberof PrescripteursApiUpdateEmailsContactPrescripteurContactsItem
     */
    readonly contactsContactJsonldContactWriteEmailsPrescripteur: ContactsContactJsonldContactWriteEmailsPrescripteur

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateEmailsContactPrescripteurContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateEmailsContactPrescripteurContactsItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateEmailsNotificationsPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersPrescripteurPrescripteurWriteNotificationsEmails}
     * @memberof PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItem
     */
    readonly tiersPrescripteurPrescripteurWriteNotificationsEmails: TiersPrescripteurPrescripteurWriteNotificationsEmails

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateFaxsContactPrescripteurContactsItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateFaxsContactPrescripteurContactsItemRequest
 */
export interface PrescripteursApiUpdateFaxsContactPrescripteurContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateFaxsContactPrescripteurContactsItem
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateFaxsContactPrescripteurContactsItem
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteFaxsPrescripteur}
     * @memberof PrescripteursApiUpdateFaxsContactPrescripteurContactsItem
     */
    readonly contactsContactJsonldContactWriteFaxsPrescripteur: ContactsContactJsonldContactWriteFaxsPrescripteur

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateFaxsContactPrescripteurContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateFaxsContactPrescripteurContactsItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateFormalistePrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateFormalistePrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdateFormalistePrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateFormalistePrescripteurTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersPrescripteurJsonldPrescripteurWriteFormaliste}
     * @memberof PrescripteursApiUpdateFormalistePrescripteurTiersItem
     */
    readonly tiersPrescripteurJsonldPrescripteurWriteFormaliste: TiersPrescripteurJsonldPrescripteurWriteFormaliste

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateFormalistePrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateFormalistePrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateFrequencesNotificationsPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersPrescripteurPrescripteurWriteNotificationsFrequences}
     * @memberof PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItem
     */
    readonly tiersPrescripteurPrescripteurWriteNotificationsFrequences: TiersPrescripteurPrescripteurWriteNotificationsFrequences

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateGrilleForfaitairePrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire}
     * @memberof PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItem
     */
    readonly tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire: TiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateInfoClearDocPrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateInfoClearDocPrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdateInfoClearDocPrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateInfoClearDocPrescripteurTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile}
     * @memberof PrescripteursApiUpdateInfoClearDocPrescripteurTiersItem
     */
    readonly tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile: TiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateInfoClearDocPrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateInfoClearDocPrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateMobilesContactPrescripteurContactsItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateMobilesContactPrescripteurContactsItemRequest
 */
export interface PrescripteursApiUpdateMobilesContactPrescripteurContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateMobilesContactPrescripteurContactsItem
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateMobilesContactPrescripteurContactsItem
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteMobilesPrescripteur}
     * @memberof PrescripteursApiUpdateMobilesContactPrescripteurContactsItem
     */
    readonly contactsContactJsonldContactWriteMobilesPrescripteur: ContactsContactJsonldContactWriteMobilesPrescripteur

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateMobilesContactPrescripteurContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateMobilesContactPrescripteurContactsItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateNoteTiersTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateNoteTiersTiersItem0Request
 */
export interface PrescripteursApiUpdateNoteTiersTiersItem0Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateNoteTiersTiersItem0
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersTiersJsonldTiersWriteNote}
     * @memberof PrescripteursApiUpdateNoteTiersTiersItem0
     */
    readonly tiersTiersJsonldTiersWriteNote: TiersTiersJsonldTiersWriteNote

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateNoteTiersTiersItem0
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateNoteTiersTiersItem0
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updatePrescripteurTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdatePrescripteurTiersItemRequest
 */
export interface PrescripteursApiUpdatePrescripteurTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdatePrescripteurTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersPrescripteurJsonldPrescripteurUpdate}
     * @memberof PrescripteursApiUpdatePrescripteurTiersItem
     */
    readonly tiersPrescripteurJsonldPrescripteurUpdate: TiersPrescripteurJsonldPrescripteurUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdatePrescripteurTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdatePrescripteurTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateRelationSocietePrescripteurRelationSocieteTiersItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItemRequest
 */
export interface PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItemRequest {
    /**
     * Identifiant du tiers
     * @type {number}
     * @memberof PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItem
     */
    readonly tiers: number

    /**
     * Identifiant de la societe
     * @type {string}
     * @memberof PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItem
     */
    readonly societe: string

    /**
     * The updated RelationSocieteTiers resource
     * @type {RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete}
     * @memberof PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItem
     */
    readonly relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete: RelationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateTelephonesContactPrescripteurContactsItem operation in PrescripteursApi.
 * @export
 * @interface PrescripteursApiUpdateTelephonesContactPrescripteurContactsItemRequest
 */
export interface PrescripteursApiUpdateTelephonesContactPrescripteurContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateTelephonesContactPrescripteurContactsItem
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof PrescripteursApiUpdateTelephonesContactPrescripteurContactsItem
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteTelsPrescripteur}
     * @memberof PrescripteursApiUpdateTelephonesContactPrescripteurContactsItem
     */
    readonly contactsContactJsonldContactWriteTelsPrescripteur: ContactsContactJsonldContactWriteTelsPrescripteur

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PrescripteursApiUpdateTelephonesContactPrescripteurContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof PrescripteursApiUpdateTelephonesContactPrescripteurContactsItem
     */
    readonly xIdUser?: number
}

/**
 * PrescripteursApi - object-oriented interface
 * @export
 * @class PrescripteursApi
 * @extends {BaseAPI}
 */
export class PrescripteursApi extends BaseAPI {
    /**
     * Ajouter un commercial au prescripteur
     * @summary Ajouter un commercial au prescripteur
     * @param {PrescripteursApiCreateCommercialPrescripteurCommerciauxCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public createCommercialPrescripteurCommerciauxCollection(requestParameters: PrescripteursApiCreateCommercialPrescripteurCommerciauxCollectionRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).createCommercialPrescripteurCommerciauxCollection(requestParameters.tiers, requestParameters.commerciauxCommercialJsonldPrescripteurWriteCommercial, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer une nouvelle adresse pour un prescripteur
     * @summary Créer une nouvelle adresse pour un prescripteur
     * @param {PrescripteursApiCreatePrescripteurAdresseAdressesCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public createPrescripteurAdresseAdressesCollection(requestParameters: PrescripteursApiCreatePrescripteurAdresseAdressesCollectionRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).createPrescripteurAdresseAdressesCollection(requestParameters.tiers, requestParameters.adressesAdresseJsonldAdresseCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un nouveau contact pour un prescripteur
     * @summary Créer un nouveau contact pour un prescripteur
     * @param {PrescripteursApiCreatePrescripteurContactContactsCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public createPrescripteurContactContactsCollection(requestParameters: PrescripteursApiCreatePrescripteurContactContactsCollectionRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).createPrescripteurContactContactsCollection(requestParameters.tiers, requestParameters.contactsContactJsonldContactCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un nouveau préscripteur
     * @summary Créer un nouveau préscripteur
     * @param {PrescripteursApiCreatePrescripteurTiersCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public createPrescripteurTiersCollection(requestParameters: PrescripteursApiCreatePrescripteurTiersCollectionRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).createPrescripteurTiersCollection(requestParameters.tiersPrescripteurJsonldPrescripteurCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer le commercial d\'un prescripteur
     * @summary Supprimer le commercial d\'un prescripteur
     * @param {PrescripteursApiDeleteCommercialPrescripteurCommerciauxItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public deleteCommercialPrescripteurCommerciauxItem(requestParameters: PrescripteursApiDeleteCommercialPrescripteurCommerciauxItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).deleteCommercialPrescripteurCommerciauxItem(requestParameters.tiers, requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Générer un code client CEGID pour une société
     * @summary Générer un code client CEGID pour une société
     * @param {PrescripteursApiGetCodeClientTiersCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getCodeClientTiersCollection(requestParameters: PrescripteursApiGetCodeClientTiersCollectionRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getCodeClientTiersCollection(requestParameters.getCodeClientTiersCollectionRequest, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des commerciaux liés à un prescripteur
     * @summary Obtenir la liste des commerciaux liés à un prescripteur
     * @param {PrescripteursApiGetCommerciauxPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getCommerciauxPrescripteurTiersItem(requestParameters: PrescripteursApiGetCommerciauxPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getCommerciauxPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
     * @summary Récupérer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
     * @param {PrescripteursApiGetEmailsNotificationsPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getEmailsNotificationsPrescripteurTiersItem(requestParameters: PrescripteursApiGetEmailsNotificationsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getEmailsNotificationsPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des fournisseurs liés à un prescripteur
     * @summary Obtenir la liste des fournisseurs liés à un prescripteur
     * @param {PrescripteursApiGetFournisseursPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getFournisseursPrescripteurTiersItem(requestParameters: PrescripteursApiGetFournisseursPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getFournisseursPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
     * @summary Récupérer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
     * @param {PrescripteursApiGetFrequencesNotificationsPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getFrequencesNotificationsPrescripteurTiersItem(requestParameters: PrescripteursApiGetFrequencesNotificationsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getFrequencesNotificationsPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les grilles forfaitaires des formalités par société d\'un prescripteur
     * @summary Récupérer les grilles forfaitaires des formalités par société d\'un prescripteur
     * @param {PrescripteursApiGetGrilleForfaitairePrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getGrilleForfaitairePrescripteurTiersItem(requestParameters: PrescripteursApiGetGrilleForfaitairePrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getGrilleForfaitairePrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.societe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations commerciales concernant la suppression des docs d\'un prescripteur
     * @summary Récupérer les informations commerciales concernant la suppression des docs d\'un prescripteur
     * @param {PrescripteursApiGetInfoClearDocPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getInfoClearDocPrescripteurTiersItem(requestParameters: PrescripteursApiGetInfoClearDocPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getInfoClearDocPrescripteurTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.societe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des tiers (prescripteurs et sous-client)
     * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
     * @param {PrescripteursApiGetListTiersTiersCollection0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getListTiersTiersCollection(requestParameters: PrescripteursApiGetListTiersTiersCollection0Request = {}, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getListTiersTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer le chiffre d\'affaire der l\'année en cours pour un prescripteur
     * @summary Récupérer le chiffre d\'affaire der l\'année en cours pour un prescripteur
     * @param {PrescripteursApiGetPrescriberAnnualSalesTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getPrescriberAnnualSalesTiersItem(requestParameters: PrescripteursApiGetPrescriberAnnualSalesTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getPrescriberAnnualSalesTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer l\'encours d\'un prescripteur
     * @summary Récupérer l\'encours d\'un prescripteur
     * @param {PrescripteursApiGetPrescriberOutstandingTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getPrescriberOutstandingTiersItem(requestParameters: PrescripteursApiGetPrescriberOutstandingTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getPrescriberOutstandingTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer le régime tva du tiers en fonction de sa localisation
     * @summary Récupérer le régime tva du tiers en fonction de sa localisation
     * @param {PrescripteursApiGetRegimeTvaTiersCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getRegimeTvaTiersCollection(requestParameters: PrescripteursApiGetRegimeTvaTiersCollectionRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getRegimeTvaTiersCollection(requestParameters.getRegimeTvaTiersCollectionRequest, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
     * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
     * @param {PrescripteursApiGetTiersTiersItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public getTiersTiersItem(requestParameters: PrescripteursApiGetTiersTiersItem0Request, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).getTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
     * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
     * @param {PrescripteursApiSearchTiersByEmailAddressesTiersCollection0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public searchTiersByEmailAddressesTiersCollection(requestParameters: PrescripteursApiSearchTiersByEmailAddressesTiersCollection0Request, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).searchTiersByEmailAddressesTiersCollection(requestParameters.emailAddresses, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
     * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
     * @param {PrescripteursApiSearchTiersByEmailDomainsTiersCollection0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public searchTiersByEmailDomainsTiersCollection(requestParameters: PrescripteursApiSearchTiersByEmailDomainsTiersCollection0Request, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).searchTiersByEmailDomainsTiersCollection(requestParameters.emailDomains, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des tiers (prescripteurs et sous-client)
     * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
     * @param {PrescripteursApiSearchTiersGroupTypeTiersCollection0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public searchTiersGroupTypeTiersCollection(requestParameters: PrescripteursApiSearchTiersGroupTypeTiersCollection0Request = {}, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).searchTiersGroupTypeTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des adresses d\'un prescripteur. Attention : la liste COMPLETE des adresses du prescripteur est à passer dans la requête, les adresses absentes seront supprimées.
     * @summary Mettre à jour la liste des adresses d\'un prescripteur
     * @param {PrescripteursApiUpdateAdressesPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateAdressesPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateAdressesPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateAdressesPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurJsonldPrescripteurWriteAdresses, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les données d\'un commercial d\'un prescripteur
     * @summary Mettre à jour les données d\'un commercial d\'un prescripteur
     * @param {PrescripteursApiUpdateCommercialPrescripteurCommerciauxItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateCommercialPrescripteurCommerciauxItem(requestParameters: PrescripteursApiUpdateCommercialPrescripteurCommerciauxItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateCommercialPrescripteurCommerciauxItem(requestParameters.tiers, requestParameters.id, requestParameters.commerciauxCommercialJsonldPrescripteurWriteCommercial, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mise à jour des conditions commerciales sur les formalités d\'un prescripeur
     * @summary Mise à jour des conditions commerciales sur les formalités d\'un prescripeur
     * @param {PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateConditionsCommercialesFormalitesPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateConditionsCommercialesFormalitesPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateConditionsCommercialesFormalitesPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des contacts d\'un prescripteur. Attention : la liste COMPLETE des contacts du prescripteur est à passer dans la requête, les contacts absents seront supprimées.
     * @summary Mettre à jour la liste des contacts d\'un prescripteur
     * @param {PrescripteursApiUpdateContactsPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateContactsPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateContactsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateContactsPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurJsonldPrescripteurWriteContacts, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mise à jour des droits d\'un prescripeur
     * @summary Mise à jour des droits d\'un prescripeur
     * @param {PrescripteursApiUpdateDroitsPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateDroitsPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateDroitsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateDroitsPrescripteurTiersItem(requestParameters.id, requestParameters.updateDroitsPrescripteurTiersItemRequest, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des emails d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
     * @summary Mettre à jour la liste des emails d\'un contact d\'un prescripteur
     * @param {PrescripteursApiUpdateEmailsContactPrescripteurContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateEmailsContactPrescripteurContactsItem(requestParameters: PrescripteursApiUpdateEmailsContactPrescripteurContactsItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateEmailsContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteEmailsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Configurer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
     * @summary Configurer la liste des adresses email recevant les notifications et/ou rappels d\'un prescripteur
     * @param {PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateEmailsNotificationsPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateEmailsNotificationsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateEmailsNotificationsPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteNotificationsEmails, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un prescripteur
     * @param {PrescripteursApiUpdateFaxsContactPrescripteurContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateFaxsContactPrescripteurContactsItem(requestParameters: PrescripteursApiUpdateFaxsContactPrescripteurContactsItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateFaxsContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteFaxsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour le formaliste du prescripteur
     * @summary Mettre à jour le formaliste du prescripteur
     * @param {PrescripteursApiUpdateFormalistePrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateFormalistePrescripteurTiersItem(requestParameters: PrescripteursApiUpdateFormalistePrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateFormalistePrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurJsonldPrescripteurWriteFormaliste, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Configurer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
     * @summary Configurer les paramètres de fréquence de rappel pour les formalités par société d\'un prescripteur
     * @param {PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateFrequencesNotificationsPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateFrequencesNotificationsPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateFrequencesNotificationsPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteNotificationsFrequences, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les tarifs des formalités et des annonces légales par société d\'un prescripteur
     * @summary Mettre à jour les tarifs des formalités et des annonces légales par société d\'un prescripteur
     * @param {PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateGrilleForfaitairePrescripteurTiersItem(requestParameters: PrescripteursApiUpdateGrilleForfaitairePrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateGrilleForfaitairePrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteConditionsCommercialesGrilleForfaitaire, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations commerciales concernant la suppression des docs d\'un prescripteur
     * @summary Mettre à jour les informations commerciales concernant la suppression des docs d\'un prescripteur
     * @param {PrescripteursApiUpdateInfoClearDocPrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateInfoClearDocPrescripteurTiersItem(requestParameters: PrescripteursApiUpdateInfoClearDocPrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateInfoClearDocPrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurPrescripteurWriteConditionsCommercialesClearFile, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un prescripteur
     * @param {PrescripteursApiUpdateMobilesContactPrescripteurContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateMobilesContactPrescripteurContactsItem(requestParameters: PrescripteursApiUpdateMobilesContactPrescripteurContactsItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateMobilesContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteMobilesPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la note d\'un tiers.
     * @summary Mettre à jour la note d\'un tiers
     * @param {PrescripteursApiUpdateNoteTiersTiersItem0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateNoteTiersTiersItem(requestParameters: PrescripteursApiUpdateNoteTiersTiersItem0Request, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateNoteTiersTiersItem(requestParameters.id, requestParameters.tiersTiersJsonldTiersWriteNote, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour un prescripteur
     * @summary Mettre à jour un prescripteur
     * @param {PrescripteursApiUpdatePrescripteurTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updatePrescripteurTiersItem(requestParameters: PrescripteursApiUpdatePrescripteurTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updatePrescripteurTiersItem(requestParameters.id, requestParameters.tiersPrescripteurJsonldPrescripteurUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les information de la relation entre le prescripteur et la societe
     * @summary Mettre à jour les information de la relation entre le prescripteur et la societe
     * @param {PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateRelationSocietePrescripteurRelationSocieteTiersItem(requestParameters: PrescripteursApiUpdateRelationSocietePrescripteurRelationSocieteTiersItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateRelationSocietePrescripteurRelationSocieteTiersItem(requestParameters.tiers, requestParameters.societe, requestParameters.relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un prescripteur
     * @param {PrescripteursApiUpdateTelephonesContactPrescripteurContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescripteursApi
     */
    public updateTelephonesContactPrescripteurContactsItem(requestParameters: PrescripteursApiUpdateTelephonesContactPrescripteurContactsItemRequest, options?: RawAxiosRequestConfig) {
        return PrescripteursApiFp(this.configuration).updateTelephonesContactPrescripteurContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteTelsPrescripteur, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RelationSocieteTiersApi - axios parameter creator
 * @export
 */
export const RelationSocieteTiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a RelationSocieteTiers resource.
         * @summary Retrieves a RelationSocieteTiers resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelationSocieteTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRelationSocieteTiersItem', 'id', id)
            const localVarPath = `/tiers/relation_societe_tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RelationSocieteTiersApi - functional programming interface
 * @export
 */
export const RelationSocieteTiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RelationSocieteTiersApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a RelationSocieteTiers resource.
         * @summary Retrieves a RelationSocieteTiers resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelationSocieteTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelationSocieteTiersJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelationSocieteTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RelationSocieteTiersApi.getRelationSocieteTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RelationSocieteTiersApi - factory interface
 * @export
 */
export const RelationSocieteTiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RelationSocieteTiersApiFp(configuration)
    return {
        /**
         * Retrieves a RelationSocieteTiers resource.
         * @summary Retrieves a RelationSocieteTiers resource.
         * @param {RelationSocieteTiersApiGetRelationSocieteTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelationSocieteTiersItem(requestParameters: RelationSocieteTiersApiGetRelationSocieteTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<RelationSocieteTiersJsonld> {
            return localVarFp.getRelationSocieteTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRelationSocieteTiersItem operation in RelationSocieteTiersApi.
 * @export
 * @interface RelationSocieteTiersApiGetRelationSocieteTiersItemRequest
 */
export interface RelationSocieteTiersApiGetRelationSocieteTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof RelationSocieteTiersApiGetRelationSocieteTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof RelationSocieteTiersApiGetRelationSocieteTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof RelationSocieteTiersApiGetRelationSocieteTiersItem
     */
    readonly xIdUser?: number
}

/**
 * RelationSocieteTiersApi - object-oriented interface
 * @export
 * @class RelationSocieteTiersApi
 * @extends {BaseAPI}
 */
export class RelationSocieteTiersApi extends BaseAPI {
    /**
     * Retrieves a RelationSocieteTiers resource.
     * @summary Retrieves a RelationSocieteTiers resource.
     * @param {RelationSocieteTiersApiGetRelationSocieteTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationSocieteTiersApi
     */
    public getRelationSocieteTiersItem(requestParameters: RelationSocieteTiersApiGetRelationSocieteTiersItemRequest, options?: RawAxiosRequestConfig) {
        return RelationSocieteTiersApiFp(this.configuration).getRelationSocieteTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SousClientsApi - axios parameter creator
 * @export
 */
export const SousClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Les données telephonesContact et emailsContact ne sont obligatoires que en cas de prescripteur non fiable. En cas de client non fiable venteIndirecte est forcée à 1.
         * @summary Créer un nouveau sous-client
         * @param {TiersSousclientJsonldSousclientCreate} tiersSousclientJsonldSousclientCreate The new Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSousclientTiersCollection: async (tiersSousclientJsonldSousclientCreate: TiersSousclientJsonldSousclientCreate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiersSousclientJsonldSousclientCreate' is not null or undefined
            assertParamExists('createSousclientTiersCollection', 'tiersSousclientJsonldSousclientCreate', tiersSousclientJsonldSousclientCreate)
            const localVarPath = `/tiers/sousclients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersSousclientJsonldSousclientCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Suppression d\'un sousclient.
         * @summary Suppression d\'un sousclient
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSousclientTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSousclientTiersItem', 'id', id)
            const localVarPath = `/tiers/sousclients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [search] Perform a search operation on OpenSearch, data must be in json format. example: &#x60;{\&quot;query\&quot;: {\&quot;match\&quot;: {\&quot;relationsSocietes.societe.id\&quot;: 1}}}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTiersTiersCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiersTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTiersTiersItem', 'id', id)
            const localVarPath = `/tiers/tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @param {Array<string>} emailAddresses Une ou plusieurs adresses emails sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailAddressesTiersCollection: async (emailAddresses: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddresses' is not null or undefined
            assertParamExists('searchTiersByEmailAddressesTiersCollection', 'emailAddresses', emailAddresses)
            const localVarPath = `/tiers/tiers/rechercher-par-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (emailAddresses) {
                localVarQueryParameter['email_addresses[]'] = emailAddresses;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @param {Array<string>} emailDomains Un ou plusieurs domaines d\&#39;adresse email sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailDomainsTiersCollection: async (emailDomains: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailDomains' is not null or undefined
            assertParamExists('searchTiersByEmailDomainsTiersCollection', 'emailDomains', emailDomains)
            const localVarPath = `/tiers/tiers/rechercher-par-domaine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (emailDomains) {
                localVarQueryParameter['email_domains[]'] = emailDomains;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersGroupTypeTiersCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/tiers-groupe/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour l\'adresse d\'un sousclient.
         * @summary Mettre à jour l\'adresse d\'un sousclient
         * @param {string} id Resource identifier
         * @param {TiersSousclientJsonldSousclientWriteAdresses} tiersSousclientJsonldSousclientWriteAdresses The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdressesSousclientTiersItem: async (id: string, tiersSousclientJsonldSousclientWriteAdresses: TiersSousclientJsonldSousclientWriteAdresses, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdressesSousclientTiersItem', 'id', id)
            // verify required parameter 'tiersSousclientJsonldSousclientWriteAdresses' is not null or undefined
            assertParamExists('updateAdressesSousclientTiersItem', 'tiersSousclientJsonldSousclientWriteAdresses', tiersSousclientJsonldSousclientWriteAdresses)
            const localVarPath = `/tiers/sousclients/{id}/adresses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersSousclientJsonldSousclientWriteAdresses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour le contact d\'un sousclient.
         * @summary Mettre à jour le contact d\'un sousclient
         * @param {string} id Resource identifier
         * @param {TiersSousclientJsonldSousclientWriteContacts} tiersSousclientJsonldSousclientWriteContacts The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactsSousclientTiersItem: async (id: string, tiersSousclientJsonldSousclientWriteContacts: TiersSousclientJsonldSousclientWriteContacts, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContactsSousclientTiersItem', 'id', id)
            // verify required parameter 'tiersSousclientJsonldSousclientWriteContacts' is not null or undefined
            assertParamExists('updateContactsSousclientTiersItem', 'tiersSousclientJsonldSousclientWriteContacts', tiersSousclientJsonldSousclientWriteContacts)
            const localVarPath = `/tiers/sousclients/{id}/contacts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersSousclientJsonldSousclientWriteContacts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un sous-client. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteEmailsSousclient} contactsContactJsonldContactWriteEmailsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsContactSousclientContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteEmailsSousclient: ContactsContactJsonldContactWriteEmailsSousclient, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateEmailsContactSousclientContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmailsContactSousclientContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteEmailsSousclient' is not null or undefined
            assertParamExists('updateEmailsContactSousclientContactsItem', 'contactsContactJsonldContactWriteEmailsSousclient', contactsContactJsonldContactWriteEmailsSousclient)
            const localVarPath = `/tiers/sousclients/{tiers}/contacts/{id}/emails`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteEmailsSousclient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteFaxsSousclient} contactsContactJsonldContactWriteFaxsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxsContactSousclientContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteFaxsSousclient: ContactsContactJsonldContactWriteFaxsSousclient, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateFaxsContactSousclientContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFaxsContactSousclientContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteFaxsSousclient' is not null or undefined
            assertParamExists('updateFaxsContactSousclientContactsItem', 'contactsContactJsonldContactWriteFaxsSousclient', contactsContactJsonldContactWriteFaxsSousclient)
            const localVarPath = `/tiers/sousclients/{tiers}/contacts/{id}/faxs`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteFaxsSousclient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour un Sous-Client
         * @summary Mettre à jour un Sous-Client
         * @param {string} id Resource identifier
         * @param {TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient} tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFullSousclientTiersItem: async (id: string, tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient: TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFullSousclientTiersItem', 'id', id)
            // verify required parameter 'tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient' is not null or undefined
            assertParamExists('updateFullSousclientTiersItem', 'tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient', tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient)
            const localVarPath = `/tiers/sousclients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteMobilesSousclient} contactsContactJsonldContactWriteMobilesSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobilesContactSousclientContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteMobilesSousclient: ContactsContactJsonldContactWriteMobilesSousclient, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateMobilesContactSousclientContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMobilesContactSousclientContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteMobilesSousclient' is not null or undefined
            assertParamExists('updateMobilesContactSousclientContactsItem', 'contactsContactJsonldContactWriteMobilesSousclient', contactsContactJsonldContactWriteMobilesSousclient)
            const localVarPath = `/tiers/sousclients/{tiers}/contacts/{id}/mobiles`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteMobilesSousclient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la note d\'un tiers.
         * @summary Mettre à jour la note d\'un tiers
         * @param {string} id Resource identifier
         * @param {TiersTiersJsonldTiersWriteNote} tiersTiersJsonldTiersWriteNote The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteTiersTiersItem: async (id: string, tiersTiersJsonldTiersWriteNote: TiersTiersJsonldTiersWriteNote, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNoteTiersTiersItem', 'id', id)
            // verify required parameter 'tiersTiersJsonldTiersWriteNote' is not null or undefined
            assertParamExists('updateNoteTiersTiersItem', 'tiersTiersJsonldTiersWriteNote', tiersTiersJsonldTiersWriteNote)
            const localVarPath = `/tiers/tiers/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersTiersJsonldTiersWriteNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * associer un tiers dans un groupe d\'objet dans la table de relation
         * @summary Ajouter un client dans un groupe d\'objet
         * @param {string} id Resource identifier
         * @param {TiersJsonldTiersEditedObjectGroup} tiersJsonldTiersEditedObjectGroup The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelSousClientObjectGroupTiersItem: async (id: string, tiersJsonldTiersEditedObjectGroup: TiersJsonldTiersEditedObjectGroup, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRelSousClientObjectGroupTiersItem', 'id', id)
            // verify required parameter 'tiersJsonldTiersEditedObjectGroup' is not null or undefined
            assertParamExists('updateRelSousClientObjectGroupTiersItem', 'tiersJsonldTiersEditedObjectGroup', tiersJsonldTiersEditedObjectGroup)
            const localVarPath = `/tiers/sous-clients/{id}/object-groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersJsonldTiersEditedObjectGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour un Sous-Client
         * @summary Mettre à jour un Sous-Client
         * @param {string} id Resource identifier
         * @param {TiersSousclientJsonldSousclientUpdate} tiersSousclientJsonldSousclientUpdate The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSousclientTiersItem: async (id: string, tiersSousclientJsonldSousclientUpdate: TiersSousclientJsonldSousclientUpdate, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSousclientTiersItem', 'id', id)
            // verify required parameter 'tiersSousclientJsonldSousclientUpdate' is not null or undefined
            assertParamExists('updateSousclientTiersItem', 'tiersSousclientJsonldSousclientUpdate', tiersSousclientJsonldSousclientUpdate)
            const localVarPath = `/tiers/sousclients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersSousclientJsonldSousclientUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteTelsSousclient} contactsContactJsonldContactWriteTelsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephonesContactSousclientContactsItem: async (tiers: string, id: string, contactsContactJsonldContactWriteTelsSousclient: ContactsContactJsonldContactWriteTelsSousclient, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tiers' is not null or undefined
            assertParamExists('updateTelephonesContactSousclientContactsItem', 'tiers', tiers)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTelephonesContactSousclientContactsItem', 'id', id)
            // verify required parameter 'contactsContactJsonldContactWriteTelsSousclient' is not null or undefined
            assertParamExists('updateTelephonesContactSousclientContactsItem', 'contactsContactJsonldContactWriteTelsSousclient', contactsContactJsonldContactWriteTelsSousclient)
            const localVarPath = `/tiers/sousclients/{tiers}/contacts/{id}/telephones`
                .replace(`{${"tiers"}}`, encodeURIComponent(String(tiers)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactsContactJsonldContactWriteTelsSousclient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SousClientsApi - functional programming interface
 * @export
 */
export const SousClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SousClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * Les données telephonesContact et emailsContact ne sont obligatoires que en cas de prescripteur non fiable. En cas de client non fiable venteIndirecte est forcée à 1.
         * @summary Créer un nouveau sous-client
         * @param {TiersSousclientJsonldSousclientCreate} tiersSousclientJsonldSousclientCreate The new Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSousclientTiersCollection(tiersSousclientJsonldSousclientCreate: TiersSousclientJsonldSousclientCreate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersSousclientJsonldSousclientCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSousclientTiersCollection(tiersSousclientJsonldSousclientCreate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.createSousclientTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Suppression d\'un sousclient.
         * @summary Suppression d\'un sousclient
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSousclientTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSousclientTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.deleteSousclientTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [search] Perform a search operation on OpenSearch, data must be in json format. example: &#x60;{\&quot;query\&quot;: {\&quot;match\&quot;: {\&quot;relationsSocietes.societe.id\&quot;: 1}}}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTiersTiersCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTiersTiersCollection(xIdSociete, xIdUser, page, itemsPerPage, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.getListTiersTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTiersTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTiersTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.getTiersTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @param {Array<string>} emailAddresses Une ou plusieurs adresses emails sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTiersByEmailAddressesTiersCollection(emailAddresses: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTiersByEmailAddressesTiersCollection(emailAddresses, xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.searchTiersByEmailAddressesTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @param {Array<string>} emailDomains Un ou plusieurs domaines d\&#39;adresse email sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTiersByEmailDomainsTiersCollection(emailDomains: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTiersByEmailDomainsTiersCollection(emailDomains, xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.searchTiersByEmailDomainsTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTiersGroupTypeTiersCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchTiersGroupTypeTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTiersGroupTypeTiersCollection(xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.searchTiersGroupTypeTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour l\'adresse d\'un sousclient.
         * @summary Mettre à jour l\'adresse d\'un sousclient
         * @param {string} id Resource identifier
         * @param {TiersSousclientJsonldSousclientWriteAdresses} tiersSousclientJsonldSousclientWriteAdresses The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdressesSousclientTiersItem(id: string, tiersSousclientJsonldSousclientWriteAdresses: TiersSousclientJsonldSousclientWriteAdresses, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersSousclientJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdressesSousclientTiersItem(id, tiersSousclientJsonldSousclientWriteAdresses, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateAdressesSousclientTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour le contact d\'un sousclient.
         * @summary Mettre à jour le contact d\'un sousclient
         * @param {string} id Resource identifier
         * @param {TiersSousclientJsonldSousclientWriteContacts} tiersSousclientJsonldSousclientWriteContacts The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactsSousclientTiersItem(id: string, tiersSousclientJsonldSousclientWriteContacts: TiersSousclientJsonldSousclientWriteContacts, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersSousclientJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactsSousclientTiersItem(id, tiersSousclientJsonldSousclientWriteContacts, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateContactsSousclientTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un sous-client. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteEmailsSousclient} contactsContactJsonldContactWriteEmailsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailsContactSousclientContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteEmailsSousclient: ContactsContactJsonldContactWriteEmailsSousclient, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailsContactSousclientContactsItem(tiers, id, contactsContactJsonldContactWriteEmailsSousclient, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateEmailsContactSousclientContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteFaxsSousclient} contactsContactJsonldContactWriteFaxsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFaxsContactSousclientContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteFaxsSousclient: ContactsContactJsonldContactWriteFaxsSousclient, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFaxsContactSousclientContactsItem(tiers, id, contactsContactJsonldContactWriteFaxsSousclient, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateFaxsContactSousclientContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour un Sous-Client
         * @summary Mettre à jour un Sous-Client
         * @param {string} id Resource identifier
         * @param {TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient} tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFullSousclientTiersItem(id: string, tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient: TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersSousclientJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFullSousclientTiersItem(id, tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateFullSousclientTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteMobilesSousclient} contactsContactJsonldContactWriteMobilesSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMobilesContactSousclientContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteMobilesSousclient: ContactsContactJsonldContactWriteMobilesSousclient, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMobilesContactSousclientContactsItem(tiers, id, contactsContactJsonldContactWriteMobilesSousclient, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateMobilesContactSousclientContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la note d\'un tiers.
         * @summary Mettre à jour la note d\'un tiers
         * @param {string} id Resource identifier
         * @param {TiersTiersJsonldTiersWriteNote} tiersTiersJsonldTiersWriteNote The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNoteTiersTiersItem(id: string, tiersTiersJsonldTiersWriteNote: TiersTiersJsonldTiersWriteNote, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoteTiersTiersItem(id, tiersTiersJsonldTiersWriteNote, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateNoteTiersTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * associer un tiers dans un groupe d\'objet dans la table de relation
         * @summary Ajouter un client dans un groupe d\'objet
         * @param {string} id Resource identifier
         * @param {TiersJsonldTiersEditedObjectGroup} tiersJsonldTiersEditedObjectGroup The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRelSousClientObjectGroupTiersItem(id: string, tiersJsonldTiersEditedObjectGroup: TiersJsonldTiersEditedObjectGroup, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersJsonldTiersEditObjectGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRelSousClientObjectGroupTiersItem(id, tiersJsonldTiersEditedObjectGroup, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateRelSousClientObjectGroupTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour un Sous-Client
         * @summary Mettre à jour un Sous-Client
         * @param {string} id Resource identifier
         * @param {TiersSousclientJsonldSousclientUpdate} tiersSousclientJsonldSousclientUpdate The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSousclientTiersItem(id: string, tiersSousclientJsonldSousclientUpdate: TiersSousclientJsonldSousclientUpdate, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersSousclientJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSousclientTiersItem(id, tiersSousclientJsonldSousclientUpdate, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateSousclientTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client
         * @param {string} tiers Resource identifier
         * @param {string} id Resource identifier
         * @param {ContactsContactJsonldContactWriteTelsSousclient} contactsContactJsonldContactWriteTelsSousclient The updated Contacts resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTelephonesContactSousclientContactsItem(tiers: string, id: string, contactsContactJsonldContactWriteTelsSousclient: ContactsContactJsonldContactWriteTelsSousclient, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsContactJsonldContactRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTelephonesContactSousclientContactsItem(tiers, id, contactsContactJsonldContactWriteTelsSousclient, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SousClientsApi.updateTelephonesContactSousclientContactsItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SousClientsApi - factory interface
 * @export
 */
export const SousClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SousClientsApiFp(configuration)
    return {
        /**
         * Les données telephonesContact et emailsContact ne sont obligatoires que en cas de prescripteur non fiable. En cas de client non fiable venteIndirecte est forcée à 1.
         * @summary Créer un nouveau sous-client
         * @param {SousClientsApiCreateSousclientTiersCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSousclientTiersCollection(requestParameters: SousClientsApiCreateSousclientTiersCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersSousclientJsonldSousclientCreated> {
            return localVarFp.createSousclientTiersCollection(requestParameters.tiersSousclientJsonldSousclientCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Suppression d\'un sousclient.
         * @summary Suppression d\'un sousclient
         * @param {SousClientsApiDeleteSousclientTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSousclientTiersItem(requestParameters: SousClientsApiDeleteSousclientTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSousclientTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {SousClientsApiGetListTiersTiersCollection1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTiersTiersCollection(requestParameters: SousClientsApiGetListTiersTiersCollection1Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersTiersCollection200Response> {
            return localVarFp.getListTiersTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @param {SousClientsApiGetTiersTiersItem1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiersTiersItem(requestParameters: SousClientsApiGetTiersTiersItem1Request, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldTiersRead> {
            return localVarFp.getTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @param {SousClientsApiSearchTiersByEmailAddressesTiersCollection1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailAddressesTiersCollection(requestParameters: SousClientsApiSearchTiersByEmailAddressesTiersCollection1Request, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersTiersCollection200Response> {
            return localVarFp.searchTiersByEmailAddressesTiersCollection(requestParameters.emailAddresses, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @param {SousClientsApiSearchTiersByEmailDomainsTiersCollection1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailDomainsTiersCollection(requestParameters: SousClientsApiSearchTiersByEmailDomainsTiersCollection1Request, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersTiersCollection200Response> {
            return localVarFp.searchTiersByEmailDomainsTiersCollection(requestParameters.emailDomains, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {SousClientsApiSearchTiersGroupTypeTiersCollection1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersGroupTypeTiersCollection(requestParameters: SousClientsApiSearchTiersGroupTypeTiersCollection1Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<SearchTiersGroupTypeTiersCollection200Response> {
            return localVarFp.searchTiersGroupTypeTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour l\'adresse d\'un sousclient.
         * @summary Mettre à jour l\'adresse d\'un sousclient
         * @param {SousClientsApiUpdateAdressesSousclientTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdressesSousclientTiersItem(requestParameters: SousClientsApiUpdateAdressesSousclientTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersSousclientJsonldTiersRead> {
            return localVarFp.updateAdressesSousclientTiersItem(requestParameters.id, requestParameters.tiersSousclientJsonldSousclientWriteAdresses, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour le contact d\'un sousclient.
         * @summary Mettre à jour le contact d\'un sousclient
         * @param {SousClientsApiUpdateContactsSousclientTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactsSousclientTiersItem(requestParameters: SousClientsApiUpdateContactsSousclientTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersSousclientJsonldTiersRead> {
            return localVarFp.updateContactsSousclientTiersItem(requestParameters.id, requestParameters.tiersSousclientJsonldSousclientWriteContacts, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des emails d\'un contact d\'un sous-client. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
         * @summary Mettre à jour la liste des emails d\'un contact d\'un sous-client
         * @param {SousClientsApiUpdateEmailsContactSousclientContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailsContactSousclientContactsItem(requestParameters: SousClientsApiUpdateEmailsContactSousclientContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateEmailsContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteEmailsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client
         * @param {SousClientsApiUpdateFaxsContactSousclientContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaxsContactSousclientContactsItem(requestParameters: SousClientsApiUpdateFaxsContactSousclientContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateFaxsContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteFaxsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour un Sous-Client
         * @summary Mettre à jour un Sous-Client
         * @param {SousClientsApiUpdateFullSousclientTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFullSousclientTiersItem(requestParameters: SousClientsApiUpdateFullSousclientTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersSousclientJsonldTiersRead> {
            return localVarFp.updateFullSousclientTiersItem(requestParameters.id, requestParameters.tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client
         * @param {SousClientsApiUpdateMobilesContactSousclientContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobilesContactSousclientContactsItem(requestParameters: SousClientsApiUpdateMobilesContactSousclientContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateMobilesContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteMobilesSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la note d\'un tiers.
         * @summary Mettre à jour la note d\'un tiers
         * @param {SousClientsApiUpdateNoteTiersTiersItem1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteTiersTiersItem(requestParameters: SousClientsApiUpdateNoteTiersTiersItem1Request, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldTiersRead> {
            return localVarFp.updateNoteTiersTiersItem(requestParameters.id, requestParameters.tiersTiersJsonldTiersWriteNote, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * associer un tiers dans un groupe d\'objet dans la table de relation
         * @summary Ajouter un client dans un groupe d\'objet
         * @param {SousClientsApiUpdateRelSousClientObjectGroupTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelSousClientObjectGroupTiersItem(requestParameters: SousClientsApiUpdateRelSousClientObjectGroupTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersJsonldTiersEditObjectGroup> {
            return localVarFp.updateRelSousClientObjectGroupTiersItem(requestParameters.id, requestParameters.tiersJsonldTiersEditedObjectGroup, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour un Sous-Client
         * @summary Mettre à jour un Sous-Client
         * @param {SousClientsApiUpdateSousclientTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSousclientTiersItem(requestParameters: SousClientsApiUpdateSousclientTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersSousclientJsonldTiersRead> {
            return localVarFp.updateSousclientTiersItem(requestParameters.id, requestParameters.tiersSousclientJsonldSousclientUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
         * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client
         * @param {SousClientsApiUpdateTelephonesContactSousclientContactsItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelephonesContactSousclientContactsItem(requestParameters: SousClientsApiUpdateTelephonesContactSousclientContactsItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContactsContactJsonldContactRead> {
            return localVarFp.updateTelephonesContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteTelsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSousclientTiersCollection operation in SousClientsApi.
 * @export
 * @interface SousClientsApiCreateSousclientTiersCollectionRequest
 */
export interface SousClientsApiCreateSousclientTiersCollectionRequest {
    /**
     * The new Tiers resource
     * @type {TiersSousclientJsonldSousclientCreate}
     * @memberof SousClientsApiCreateSousclientTiersCollection
     */
    readonly tiersSousclientJsonldSousclientCreate: TiersSousclientJsonldSousclientCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiCreateSousclientTiersCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiCreateSousclientTiersCollection
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for deleteSousclientTiersItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiDeleteSousclientTiersItemRequest
 */
export interface SousClientsApiDeleteSousclientTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiDeleteSousclientTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiDeleteSousclientTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiDeleteSousclientTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for getListTiersTiersCollection operation in SousClientsApi.
 * @export
 * @interface SousClientsApiGetListTiersTiersCollection1Request
 */
export interface SousClientsApiGetListTiersTiersCollection1Request {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiGetListTiersTiersCollection1
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiGetListTiersTiersCollection1
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof SousClientsApiGetListTiersTiersCollection1
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof SousClientsApiGetListTiersTiersCollection1
     */
    readonly itemsPerPage?: number

    /**
     * Perform a search operation on OpenSearch, data must be in json format. example: &#x60;{\&quot;query\&quot;: {\&quot;match\&quot;: {\&quot;relationsSocietes.societe.id\&quot;: 1}}}&#x60;
     * @type {string}
     * @memberof SousClientsApiGetListTiersTiersCollection1
     */
    readonly search?: string
}

/**
 * Request parameters for getTiersTiersItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiGetTiersTiersItem1Request
 */
export interface SousClientsApiGetTiersTiersItem1Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiGetTiersTiersItem1
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiGetTiersTiersItem1
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiGetTiersTiersItem1
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for searchTiersByEmailAddressesTiersCollection operation in SousClientsApi.
 * @export
 * @interface SousClientsApiSearchTiersByEmailAddressesTiersCollection1Request
 */
export interface SousClientsApiSearchTiersByEmailAddressesTiersCollection1Request {
    /**
     * Une ou plusieurs adresses emails sur lesquelles effectuer la recherche
     * @type {Array<string>}
     * @memberof SousClientsApiSearchTiersByEmailAddressesTiersCollection1
     */
    readonly emailAddresses: Array<string>

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiSearchTiersByEmailAddressesTiersCollection1
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiSearchTiersByEmailAddressesTiersCollection1
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof SousClientsApiSearchTiersByEmailAddressesTiersCollection1
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof SousClientsApiSearchTiersByEmailAddressesTiersCollection1
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for searchTiersByEmailDomainsTiersCollection operation in SousClientsApi.
 * @export
 * @interface SousClientsApiSearchTiersByEmailDomainsTiersCollection1Request
 */
export interface SousClientsApiSearchTiersByEmailDomainsTiersCollection1Request {
    /**
     * Un ou plusieurs domaines d\&#39;adresse email sur lesquelles effectuer la recherche
     * @type {Array<string>}
     * @memberof SousClientsApiSearchTiersByEmailDomainsTiersCollection1
     */
    readonly emailDomains: Array<string>

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiSearchTiersByEmailDomainsTiersCollection1
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiSearchTiersByEmailDomainsTiersCollection1
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof SousClientsApiSearchTiersByEmailDomainsTiersCollection1
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof SousClientsApiSearchTiersByEmailDomainsTiersCollection1
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for searchTiersGroupTypeTiersCollection operation in SousClientsApi.
 * @export
 * @interface SousClientsApiSearchTiersGroupTypeTiersCollection1Request
 */
export interface SousClientsApiSearchTiersGroupTypeTiersCollection1Request {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiSearchTiersGroupTypeTiersCollection1
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiSearchTiersGroupTypeTiersCollection1
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof SousClientsApiSearchTiersGroupTypeTiersCollection1
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof SousClientsApiSearchTiersGroupTypeTiersCollection1
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for updateAdressesSousclientTiersItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateAdressesSousclientTiersItemRequest
 */
export interface SousClientsApiUpdateAdressesSousclientTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateAdressesSousclientTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersSousclientJsonldSousclientWriteAdresses}
     * @memberof SousClientsApiUpdateAdressesSousclientTiersItem
     */
    readonly tiersSousclientJsonldSousclientWriteAdresses: TiersSousclientJsonldSousclientWriteAdresses

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateAdressesSousclientTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateAdressesSousclientTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateContactsSousclientTiersItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateContactsSousclientTiersItemRequest
 */
export interface SousClientsApiUpdateContactsSousclientTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateContactsSousclientTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersSousclientJsonldSousclientWriteContacts}
     * @memberof SousClientsApiUpdateContactsSousclientTiersItem
     */
    readonly tiersSousclientJsonldSousclientWriteContacts: TiersSousclientJsonldSousclientWriteContacts

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateContactsSousclientTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateContactsSousclientTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateEmailsContactSousclientContactsItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateEmailsContactSousclientContactsItemRequest
 */
export interface SousClientsApiUpdateEmailsContactSousclientContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateEmailsContactSousclientContactsItem
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateEmailsContactSousclientContactsItem
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteEmailsSousclient}
     * @memberof SousClientsApiUpdateEmailsContactSousclientContactsItem
     */
    readonly contactsContactJsonldContactWriteEmailsSousclient: ContactsContactJsonldContactWriteEmailsSousclient

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateEmailsContactSousclientContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateEmailsContactSousclientContactsItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateFaxsContactSousclientContactsItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateFaxsContactSousclientContactsItemRequest
 */
export interface SousClientsApiUpdateFaxsContactSousclientContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateFaxsContactSousclientContactsItem
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateFaxsContactSousclientContactsItem
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteFaxsSousclient}
     * @memberof SousClientsApiUpdateFaxsContactSousclientContactsItem
     */
    readonly contactsContactJsonldContactWriteFaxsSousclient: ContactsContactJsonldContactWriteFaxsSousclient

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateFaxsContactSousclientContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateFaxsContactSousclientContactsItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateFullSousclientTiersItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateFullSousclientTiersItemRequest
 */
export interface SousClientsApiUpdateFullSousclientTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateFullSousclientTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient}
     * @memberof SousClientsApiUpdateFullSousclientTiersItem
     */
    readonly tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient: TiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateFullSousclientTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateFullSousclientTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateMobilesContactSousclientContactsItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateMobilesContactSousclientContactsItemRequest
 */
export interface SousClientsApiUpdateMobilesContactSousclientContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateMobilesContactSousclientContactsItem
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateMobilesContactSousclientContactsItem
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteMobilesSousclient}
     * @memberof SousClientsApiUpdateMobilesContactSousclientContactsItem
     */
    readonly contactsContactJsonldContactWriteMobilesSousclient: ContactsContactJsonldContactWriteMobilesSousclient

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateMobilesContactSousclientContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateMobilesContactSousclientContactsItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateNoteTiersTiersItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateNoteTiersTiersItem1Request
 */
export interface SousClientsApiUpdateNoteTiersTiersItem1Request {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateNoteTiersTiersItem1
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersTiersJsonldTiersWriteNote}
     * @memberof SousClientsApiUpdateNoteTiersTiersItem1
     */
    readonly tiersTiersJsonldTiersWriteNote: TiersTiersJsonldTiersWriteNote

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateNoteTiersTiersItem1
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateNoteTiersTiersItem1
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateRelSousClientObjectGroupTiersItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateRelSousClientObjectGroupTiersItemRequest
 */
export interface SousClientsApiUpdateRelSousClientObjectGroupTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateRelSousClientObjectGroupTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersJsonldTiersEditedObjectGroup}
     * @memberof SousClientsApiUpdateRelSousClientObjectGroupTiersItem
     */
    readonly tiersJsonldTiersEditedObjectGroup: TiersJsonldTiersEditedObjectGroup

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateRelSousClientObjectGroupTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateRelSousClientObjectGroupTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateSousclientTiersItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateSousclientTiersItemRequest
 */
export interface SousClientsApiUpdateSousclientTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateSousclientTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersSousclientJsonldSousclientUpdate}
     * @memberof SousClientsApiUpdateSousclientTiersItem
     */
    readonly tiersSousclientJsonldSousclientUpdate: TiersSousclientJsonldSousclientUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateSousclientTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateSousclientTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for updateTelephonesContactSousclientContactsItem operation in SousClientsApi.
 * @export
 * @interface SousClientsApiUpdateTelephonesContactSousclientContactsItemRequest
 */
export interface SousClientsApiUpdateTelephonesContactSousclientContactsItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateTelephonesContactSousclientContactsItem
     */
    readonly tiers: string

    /**
     * Resource identifier
     * @type {string}
     * @memberof SousClientsApiUpdateTelephonesContactSousclientContactsItem
     */
    readonly id: string

    /**
     * The updated Contacts resource
     * @type {ContactsContactJsonldContactWriteTelsSousclient}
     * @memberof SousClientsApiUpdateTelephonesContactSousclientContactsItem
     */
    readonly contactsContactJsonldContactWriteTelsSousclient: ContactsContactJsonldContactWriteTelsSousclient

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof SousClientsApiUpdateTelephonesContactSousclientContactsItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof SousClientsApiUpdateTelephonesContactSousclientContactsItem
     */
    readonly xIdUser?: number
}

/**
 * SousClientsApi - object-oriented interface
 * @export
 * @class SousClientsApi
 * @extends {BaseAPI}
 */
export class SousClientsApi extends BaseAPI {
    /**
     * Les données telephonesContact et emailsContact ne sont obligatoires que en cas de prescripteur non fiable. En cas de client non fiable venteIndirecte est forcée à 1.
     * @summary Créer un nouveau sous-client
     * @param {SousClientsApiCreateSousclientTiersCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public createSousclientTiersCollection(requestParameters: SousClientsApiCreateSousclientTiersCollectionRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).createSousclientTiersCollection(requestParameters.tiersSousclientJsonldSousclientCreate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Suppression d\'un sousclient.
     * @summary Suppression d\'un sousclient
     * @param {SousClientsApiDeleteSousclientTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public deleteSousclientTiersItem(requestParameters: SousClientsApiDeleteSousclientTiersItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).deleteSousclientTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des tiers (prescripteurs et sous-client)
     * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
     * @param {SousClientsApiGetListTiersTiersCollection1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public getListTiersTiersCollection(requestParameters: SousClientsApiGetListTiersTiersCollection1Request = {}, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).getListTiersTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
     * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
     * @param {SousClientsApiGetTiersTiersItem1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public getTiersTiersItem(requestParameters: SousClientsApiGetTiersTiersItem1Request, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).getTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
     * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
     * @param {SousClientsApiSearchTiersByEmailAddressesTiersCollection1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public searchTiersByEmailAddressesTiersCollection(requestParameters: SousClientsApiSearchTiersByEmailAddressesTiersCollection1Request, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).searchTiersByEmailAddressesTiersCollection(requestParameters.emailAddresses, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
     * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
     * @param {SousClientsApiSearchTiersByEmailDomainsTiersCollection1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public searchTiersByEmailDomainsTiersCollection(requestParameters: SousClientsApiSearchTiersByEmailDomainsTiersCollection1Request, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).searchTiersByEmailDomainsTiersCollection(requestParameters.emailDomains, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des tiers (prescripteurs et sous-client)
     * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
     * @param {SousClientsApiSearchTiersGroupTypeTiersCollection1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public searchTiersGroupTypeTiersCollection(requestParameters: SousClientsApiSearchTiersGroupTypeTiersCollection1Request = {}, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).searchTiersGroupTypeTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour l\'adresse d\'un sousclient.
     * @summary Mettre à jour l\'adresse d\'un sousclient
     * @param {SousClientsApiUpdateAdressesSousclientTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateAdressesSousclientTiersItem(requestParameters: SousClientsApiUpdateAdressesSousclientTiersItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateAdressesSousclientTiersItem(requestParameters.id, requestParameters.tiersSousclientJsonldSousclientWriteAdresses, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour le contact d\'un sousclient.
     * @summary Mettre à jour le contact d\'un sousclient
     * @param {SousClientsApiUpdateContactsSousclientTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateContactsSousclientTiersItem(requestParameters: SousClientsApiUpdateContactsSousclientTiersItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateContactsSousclientTiersItem(requestParameters.id, requestParameters.tiersSousclientJsonldSousclientWriteContacts, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des emails d\'un contact d\'un sous-client. Attention : la liste COMPLETE des emails du contact est à passer dans la requête, les emails absents seront supprimées.
     * @summary Mettre à jour la liste des emails d\'un contact d\'un sous-client
     * @param {SousClientsApiUpdateEmailsContactSousclientContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateEmailsContactSousclientContactsItem(requestParameters: SousClientsApiUpdateEmailsContactSousclientContactsItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateEmailsContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteEmailsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de fax du contact est à passer dans la requête, les numéros de fax absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de fax d\'un contact d\'un sous-client
     * @param {SousClientsApiUpdateFaxsContactSousclientContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateFaxsContactSousclientContactsItem(requestParameters: SousClientsApiUpdateFaxsContactSousclientContactsItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateFaxsContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteFaxsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour un Sous-Client
     * @summary Mettre à jour un Sous-Client
     * @param {SousClientsApiUpdateFullSousclientTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateFullSousclientTiersItem(requestParameters: SousClientsApiUpdateFullSousclientTiersItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateFullSousclientTiersItem(requestParameters.id, requestParameters.tiersSousclientSousclientUpdateSousclientWriteAdressesSousclientWriteContactsContactWriteTelsSousclientContactWriteMobilesSousclientContactWriteFaxsSousclientContactWriteEmailsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de mobile du contact est à passer dans la requête, les numéros de mobile absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de mobile d\'un contact d\'un sous-client
     * @param {SousClientsApiUpdateMobilesContactSousclientContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateMobilesContactSousclientContactsItem(requestParameters: SousClientsApiUpdateMobilesContactSousclientContactsItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateMobilesContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteMobilesSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la note d\'un tiers.
     * @summary Mettre à jour la note d\'un tiers
     * @param {SousClientsApiUpdateNoteTiersTiersItem1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateNoteTiersTiersItem(requestParameters: SousClientsApiUpdateNoteTiersTiersItem1Request, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateNoteTiersTiersItem(requestParameters.id, requestParameters.tiersTiersJsonldTiersWriteNote, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * associer un tiers dans un groupe d\'objet dans la table de relation
     * @summary Ajouter un client dans un groupe d\'objet
     * @param {SousClientsApiUpdateRelSousClientObjectGroupTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateRelSousClientObjectGroupTiersItem(requestParameters: SousClientsApiUpdateRelSousClientObjectGroupTiersItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateRelSousClientObjectGroupTiersItem(requestParameters.id, requestParameters.tiersJsonldTiersEditedObjectGroup, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour un Sous-Client
     * @summary Mettre à jour un Sous-Client
     * @param {SousClientsApiUpdateSousclientTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateSousclientTiersItem(requestParameters: SousClientsApiUpdateSousclientTiersItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateSousclientTiersItem(requestParameters.id, requestParameters.tiersSousclientJsonldSousclientUpdate, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client. Attention : la liste COMPLETE des numéros de téléphone du contact est à passer dans la requête, les numéros de téléphone absents seront supprimées.
     * @summary Mettre à jour la liste des numéros de téléphone d\'un contact d\'un sous-client
     * @param {SousClientsApiUpdateTelephonesContactSousclientContactsItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SousClientsApi
     */
    public updateTelephonesContactSousclientContactsItem(requestParameters: SousClientsApiUpdateTelephonesContactSousclientContactsItemRequest, options?: RawAxiosRequestConfig) {
        return SousClientsApiFp(this.configuration).updateTelephonesContactSousclientContactsItem(requestParameters.tiers, requestParameters.id, requestParameters.contactsContactJsonldContactWriteTelsSousclient, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TiersApi - axios parameter creator
 * @export
 */
export const TiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [search] Perform a search operation on OpenSearch, data must be in json format. example: &#x60;{\&quot;query\&quot;: {\&quot;match\&quot;: {\&quot;relationsSocietes.societe.id\&quot;: 1}}}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTiersTiersCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiersTiersItem: async (id: string, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTiersTiersItem', 'id', id)
            const localVarPath = `/tiers/tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @param {Array<string>} emailAddresses Une ou plusieurs adresses emails sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailAddressesTiersCollection: async (emailAddresses: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddresses' is not null or undefined
            assertParamExists('searchTiersByEmailAddressesTiersCollection', 'emailAddresses', emailAddresses)
            const localVarPath = `/tiers/tiers/rechercher-par-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (emailAddresses) {
                localVarQueryParameter['email_addresses[]'] = emailAddresses;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @param {Array<string>} emailDomains Un ou plusieurs domaines d\&#39;adresse email sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailDomainsTiersCollection: async (emailDomains: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailDomains' is not null or undefined
            assertParamExists('searchTiersByEmailDomainsTiersCollection', 'emailDomains', emailDomains)
            const localVarPath = `/tiers/tiers/rechercher-par-domaine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (emailDomains) {
                localVarQueryParameter['email_domains[]'] = emailDomains;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersGroupTypeTiersCollection: async (xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tiers/tiers-groupe/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour la note d\'un tiers.
         * @summary Mettre à jour la note d\'un tiers
         * @param {string} id Resource identifier
         * @param {TiersTiersJsonldTiersWriteNote} tiersTiersJsonldTiersWriteNote The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteTiersTiersItem: async (id: string, tiersTiersJsonldTiersWriteNote: TiersTiersJsonldTiersWriteNote, xIdSociete?: number, xIdUser?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNoteTiersTiersItem', 'id', id)
            // verify required parameter 'tiersTiersJsonldTiersWriteNote' is not null or undefined
            assertParamExists('updateNoteTiersTiersItem', 'tiersTiersJsonldTiersWriteNote', tiersTiersJsonldTiersWriteNote)
            const localVarPath = `/tiers/tiers/{id}/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }

            if (xIdUser != null) {
                localVarHeaderParameter['X-Id-User'] = typeof xIdUser === 'string'
                    ? xIdUser
                    : JSON.stringify(xIdUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiersTiersJsonldTiersWriteNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TiersApi - functional programming interface
 * @export
 */
export const TiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TiersApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [search] Perform a search operation on OpenSearch, data must be in json format. example: &#x60;{\&quot;query\&quot;: {\&quot;match\&quot;: {\&quot;relationsSocietes.societe.id\&quot;: 1}}}&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTiersTiersCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTiersTiersCollection(xIdSociete, xIdUser, page, itemsPerPage, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.getListTiersTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTiersTiersItem(id: string, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTiersTiersItem(id, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.getTiersTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @param {Array<string>} emailAddresses Une ou plusieurs adresses emails sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTiersByEmailAddressesTiersCollection(emailAddresses: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTiersByEmailAddressesTiersCollection(emailAddresses, xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.searchTiersByEmailAddressesTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @param {Array<string>} emailDomains Un ou plusieurs domaines d\&#39;adresse email sur lesquelles effectuer la recherche
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTiersByEmailDomainsTiersCollection(emailDomains: Array<string>, xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListTiersTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTiersByEmailDomainsTiersCollection(emailDomains, xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.searchTiersByEmailDomainsTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTiersGroupTypeTiersCollection(xIdSociete?: number, xIdUser?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchTiersGroupTypeTiersCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTiersGroupTypeTiersCollection(xIdSociete, xIdUser, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.searchTiersGroupTypeTiersCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour la note d\'un tiers.
         * @summary Mettre à jour la note d\'un tiers
         * @param {string} id Resource identifier
         * @param {TiersTiersJsonldTiersWriteNote} tiersTiersJsonldTiersWriteNote The updated Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [xIdUser] Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNoteTiersTiersItem(id: string, tiersTiersJsonldTiersWriteNote: TiersTiersJsonldTiersWriteNote, xIdSociete?: number, xIdUser?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersTiersJsonldTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoteTiersTiersItem(id, tiersTiersJsonldTiersWriteNote, xIdSociete, xIdUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.updateNoteTiersTiersItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TiersApi - factory interface
 * @export
 */
export const TiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TiersApiFp(configuration)
    return {
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {TiersApiGetListTiersTiersCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTiersTiersCollection(requestParameters: TiersApiGetListTiersTiersCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersTiersCollection200Response> {
            return localVarFp.getListTiersTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
         * @param {TiersApiGetTiersTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiersTiersItem(requestParameters: TiersApiGetTiersTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldTiersRead> {
            return localVarFp.getTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
         * @param {TiersApiSearchTiersByEmailAddressesTiersCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailAddressesTiersCollection(requestParameters: TiersApiSearchTiersByEmailAddressesTiersCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersTiersCollection200Response> {
            return localVarFp.searchTiersByEmailAddressesTiersCollection(requestParameters.emailAddresses, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
         * @param {TiersApiSearchTiersByEmailDomainsTiersCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersByEmailDomainsTiersCollection(requestParameters: TiersApiSearchTiersByEmailDomainsTiersCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetListTiersTiersCollection200Response> {
            return localVarFp.searchTiersByEmailDomainsTiersCollection(requestParameters.emailDomains, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des tiers (prescripteurs et sous-client)
         * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
         * @param {TiersApiSearchTiersGroupTypeTiersCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTiersGroupTypeTiersCollection(requestParameters: TiersApiSearchTiersGroupTypeTiersCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SearchTiersGroupTypeTiersCollection200Response> {
            return localVarFp.searchTiersGroupTypeTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour la note d\'un tiers.
         * @summary Mettre à jour la note d\'un tiers
         * @param {TiersApiUpdateNoteTiersTiersItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteTiersTiersItem(requestParameters: TiersApiUpdateNoteTiersTiersItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<TiersTiersJsonldTiersRead> {
            return localVarFp.updateNoteTiersTiersItem(requestParameters.id, requestParameters.tiersTiersJsonldTiersWriteNote, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getListTiersTiersCollection operation in TiersApi.
 * @export
 * @interface TiersApiGetListTiersTiersCollectionRequest
 */
export interface TiersApiGetListTiersTiersCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TiersApiGetListTiersTiersCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof TiersApiGetListTiersTiersCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof TiersApiGetListTiersTiersCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TiersApiGetListTiersTiersCollection
     */
    readonly itemsPerPage?: number

    /**
     * Perform a search operation on OpenSearch, data must be in json format. example: &#x60;{\&quot;query\&quot;: {\&quot;match\&quot;: {\&quot;relationsSocietes.societe.id\&quot;: 1}}}&#x60;
     * @type {string}
     * @memberof TiersApiGetListTiersTiersCollection
     */
    readonly search?: string
}

/**
 * Request parameters for getTiersTiersItem operation in TiersApi.
 * @export
 * @interface TiersApiGetTiersTiersItemRequest
 */
export interface TiersApiGetTiersTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof TiersApiGetTiersTiersItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TiersApiGetTiersTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof TiersApiGetTiersTiersItem
     */
    readonly xIdUser?: number
}

/**
 * Request parameters for searchTiersByEmailAddressesTiersCollection operation in TiersApi.
 * @export
 * @interface TiersApiSearchTiersByEmailAddressesTiersCollectionRequest
 */
export interface TiersApiSearchTiersByEmailAddressesTiersCollectionRequest {
    /**
     * Une ou plusieurs adresses emails sur lesquelles effectuer la recherche
     * @type {Array<string>}
     * @memberof TiersApiSearchTiersByEmailAddressesTiersCollection
     */
    readonly emailAddresses: Array<string>

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TiersApiSearchTiersByEmailAddressesTiersCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof TiersApiSearchTiersByEmailAddressesTiersCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof TiersApiSearchTiersByEmailAddressesTiersCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TiersApiSearchTiersByEmailAddressesTiersCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for searchTiersByEmailDomainsTiersCollection operation in TiersApi.
 * @export
 * @interface TiersApiSearchTiersByEmailDomainsTiersCollectionRequest
 */
export interface TiersApiSearchTiersByEmailDomainsTiersCollectionRequest {
    /**
     * Un ou plusieurs domaines d\&#39;adresse email sur lesquelles effectuer la recherche
     * @type {Array<string>}
     * @memberof TiersApiSearchTiersByEmailDomainsTiersCollection
     */
    readonly emailDomains: Array<string>

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TiersApiSearchTiersByEmailDomainsTiersCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof TiersApiSearchTiersByEmailDomainsTiersCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof TiersApiSearchTiersByEmailDomainsTiersCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TiersApiSearchTiersByEmailDomainsTiersCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for searchTiersGroupTypeTiersCollection operation in TiersApi.
 * @export
 * @interface TiersApiSearchTiersGroupTypeTiersCollectionRequest
 */
export interface TiersApiSearchTiersGroupTypeTiersCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TiersApiSearchTiersGroupTypeTiersCollection
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof TiersApiSearchTiersGroupTypeTiersCollection
     */
    readonly xIdUser?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof TiersApiSearchTiersGroupTypeTiersCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TiersApiSearchTiersGroupTypeTiersCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for updateNoteTiersTiersItem operation in TiersApi.
 * @export
 * @interface TiersApiUpdateNoteTiersTiersItemRequest
 */
export interface TiersApiUpdateNoteTiersTiersItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof TiersApiUpdateNoteTiersTiersItem
     */
    readonly id: string

    /**
     * The updated Tiers resource
     * @type {TiersTiersJsonldTiersWriteNote}
     * @memberof TiersApiUpdateNoteTiersTiersItem
     */
    readonly tiersTiersJsonldTiersWriteNote: TiersTiersJsonldTiersWriteNote

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TiersApiUpdateNoteTiersTiersItem
     */
    readonly xIdSociete?: number

    /**
     * Identifiant de l\&#39;utilisateur à l\&#39;origine de la requête
     * @type {number}
     * @memberof TiersApiUpdateNoteTiersTiersItem
     */
    readonly xIdUser?: number
}

/**
 * TiersApi - object-oriented interface
 * @export
 * @class TiersApi
 * @extends {BaseAPI}
 */
export class TiersApi extends BaseAPI {
    /**
     * Obtenir la liste des tiers (prescripteurs et sous-client)
     * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
     * @param {TiersApiGetListTiersTiersCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public getListTiersTiersCollection(requestParameters: TiersApiGetListTiersTiersCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).getListTiersTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un tiers (prescripteur ou sous-client)
     * @summary Récupérer les informations d\'un tiers (prescripteur ou sous-client)
     * @param {TiersApiGetTiersTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public getTiersTiersItem(requestParameters: TiersApiGetTiersTiersItemRequest, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).getTiersTiersItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
     * @summary Rechercher des tiers (prescripteurs et sous-client) par une ou plusieurs adresses emails
     * @param {TiersApiSearchTiersByEmailAddressesTiersCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public searchTiersByEmailAddressesTiersCollection(requestParameters: TiersApiSearchTiersByEmailAddressesTiersCollectionRequest, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).searchTiersByEmailAddressesTiersCollection(requestParameters.emailAddresses, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
     * @summary Rechercher des tiers (prescripteurs et sous-client) par un ou plusieurs domaine d\'adresse email
     * @param {TiersApiSearchTiersByEmailDomainsTiersCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public searchTiersByEmailDomainsTiersCollection(requestParameters: TiersApiSearchTiersByEmailDomainsTiersCollectionRequest, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).searchTiersByEmailDomainsTiersCollection(requestParameters.emailDomains, requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des tiers (prescripteurs et sous-client)
     * @summary Obtenir la liste des tiers (prescripteurs et sous-client)
     * @param {TiersApiSearchTiersGroupTypeTiersCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public searchTiersGroupTypeTiersCollection(requestParameters: TiersApiSearchTiersGroupTypeTiersCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).searchTiersGroupTypeTiersCollection(requestParameters.xIdSociete, requestParameters.xIdUser, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour la note d\'un tiers.
     * @summary Mettre à jour la note d\'un tiers
     * @param {TiersApiUpdateNoteTiersTiersItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public updateNoteTiersTiersItem(requestParameters: TiersApiUpdateNoteTiersTiersItemRequest, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).updateNoteTiersTiersItem(requestParameters.id, requestParameters.tiersTiersJsonldTiersWriteNote, requestParameters.xIdSociete, requestParameters.xIdUser, options).then((request) => request(this.axios, this.basePath));
    }
}



