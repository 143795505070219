import React, { useCallback } from 'react';

import { AxiosResponse, AxiosPromise, AxiosHeaders } from 'axios';

/*  This hook handle collection of Api request :
 *  --------------------------------------------
 *  @input : Array of named promises
 *  - NamedPromise : {
 *      name: Name of the promise (used in result formating)
 *      promise: Promise
 *      accessorKey: optionnal ( key or path to result targeted value )
 *  }
 *
 *  example :  {
 *              name: 'getEmailsNotificationsPrescripteurTiersItem',
 *              promise: getEmailsNotificationsPrescripteurTiersItem(),
 *              accessorKey: 'preferencesNotification.emails',
 *          },
 *
 *  @return :
 *  - isFetching: boolean, true during data fetching cycle
 *  - isLoaded: boolean, mark the end of data fetching cycle
 *  - hasErrors : boolean, true if one or more promise is rejected
 *  - results : contains promises raw result (can be fullfilled or rejected)
 *  - fetchedData : contains formated result based on given accessorKey and name of promises
 *  - call : main method, used to fetch data from api based on a given configuration
 *  - reset : reset to initialState
 *
 *  -> Important missing feature : error handling / actually we return only fullfilled / success response
 */

const initalState = {
    isFetching: false,
    isLoaded: false,
    hasErrors: false,
    promiseInformations: [],
    fetchedData: [],
    fetchedError: [],
    results: [],
};

export type JsonLDFullFilledResponse = {
    status: 'fulfilled';
    value: {
        config: unknown;
        data: {
            '@id': string;
            '@context': unknown;
            '@type': string;
            [key: string]: unknown;
        };
        headers: AxiosHeaders;
        status: 200;
        statusText: 'OK';
    };
};

type NamedAxiosPromise = {
    name: string;
    promise: AxiosPromise;
    accessorKey?: string;
};

type PromiseInformation = {
    name: string;
    accessorKey?: string;
};

const usePromises = () => {
    const [isFetching, setIsFetching] = React.useState<boolean>(initalState.isFetching);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(initalState.isLoaded);
    const [hasErrors, setHasErrors] = React.useState<boolean>(initalState.hasErrors);
    const [promiseInformations, setPromiseInformations] = React.useState<PromiseInformation[]>(
        initalState.promiseInformations,
    );

    // Type with dynamic key, based on paramaters passed in hook.
    // => namedPromise.name : result | result[accessorKey]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [fetchedData, setFetchedData] = React.useState<any>(initalState.fetchedData);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [fetchedErrors, setFetchedErrors] = React.useState<any>(initalState.fetchedError);
    const [results, setResults] = React.useState<PromiseFulfilledResult<unknown>[]>([]);

    const call = useCallback(
        async (inputs: Array<NamedAxiosPromise>) => {
            setIsFetching(true);

            const promises = inputs.map(({ promise }) => promise);
            const promiseInformationsObject = inputs.map((namedPromise: NamedAxiosPromise) => ({
                name: namedPromise.name,
                accessorKey: namedPromise.accessorKey,
            }));
            setPromiseInformations(promiseInformationsObject);

            const fullfilledResults: PromiseFulfilledResult<unknown>[] = [];

            await Promise.allSettled(promises).then(
                (promiseResults: PromiseSettledResult<AxiosResponse<unknown, unknown>>[]) => {
                    promiseResults.forEach(
                        (result: PromiseSettledResult<AxiosResponse<unknown, unknown>>) => {
                            const { status } = result;

                            if (status === 'fulfilled') {
                                fullfilledResults.push(result);
                            }

                            if (status === 'rejected') {
                                setFetchedErrors(result.reason);
                                setHasErrors(true);
                            }
                        },
                    );

                    setResults(hasErrors ? fetchedErrors : fullfilledResults);
                },
            );

            setIsFetching(false);
            setIsLoaded(true);
        },
        [fetchedErrors, hasErrors],
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getDataFromPath = (pathString: string, object: any) => {
        const path = pathString.split('.');

        let result = object;

        for (let index = 0; index < path.length; ++index) {
            const key = path[index];
            if (key in result) {
                result = result[key];
            }
        }
        return result;
    };

    const extractDataFromAccessorkey = useCallback(
        (data: JsonLDFullFilledResponse['value']['data'], accessorKey: string) => {
            const isPath = accessorKey.indexOf('.') > -1; // key contains dot
            if (!isPath) {
                return data[accessorKey];
            }

            return getDataFromPath(accessorKey, data);
        },
        [],
    );

    const formatDataFromFullFilledResponse = useCallback(
        (resultItems: PromiseFulfilledResult<unknown>[]) => {
            let formatedResult = {};

            resultItems.forEach((result: JsonLDFullFilledResponse, index: number) => {
                const { value } = result;

                const data = value?.data;

                const accessorKey = promiseInformations[index].accessorKey ?? 'hydra:member';

                if (data) {
                    formatedResult = {
                        ...formatedResult,
                        [promiseInformations[index].name]: extractDataFromAccessorkey(
                            data,
                            accessorKey,
                        ),
                    };
                }
            });

            setFetchedData(formatedResult);
        },
        [extractDataFromAccessorkey, promiseInformations],
    );

    React.useEffect(() => {
        if (isLoaded && results) {
            if (!hasErrors) {
                formatDataFromFullFilledResponse(results as JsonLDFullFilledResponse[]);
            }
        }
    }, [formatDataFromFullFilledResponse, hasErrors, isLoaded, results]);

    const reset = () => {
        setResults(initalState.results);
        setFetchedData(initalState.fetchedData);
    };

    return { isFetching, isLoaded, hasErrors, results, fetchedData, fetchedErrors, call, reset };
};

export default usePromises;
