import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { FaOptionIcon, addItem } from '@europrocurement/l2d-icons';
import { GroupementGroupementRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import { AnyAction } from '@reduxjs/toolkit';
import { useOffreService } from '@europrocurement/l2d-domain';
import ModalFormTitle from '../components/form/ModalFormTitle';
import Form from './Form';
import { displayCustomValue, getStringColumn } from '../listings';
import ListingWithPreview from '../components/ListingWithPreview';

const Panel: React.FunctionComponent = function () {
    const { modalActions } = React.useContext(ModalContext);

    const { offerGroupModel: model } = useOffreService();

    const dispatch = useDispatch();

    const reloadList = () =>
        dispatch(model.datasourcesThunks.main.getData({}) as unknown as AnyAction);

    const listingButton = {
        label: 'Créer un groupement',
        icon: addItem,
        action: () =>
            modalActions.call(
                <>
                    <ModalFormTitle
                        icon={model.icon}
                        title="Création d'un groupement"
                    />
                    <Form
                        isCreate
                        afterSubmit={reloadList}
                    />
                </>,
            ),
    };

    const columns: ColumnDatatable<GroupementGroupementRead>[] = [
        getStringColumn('libelle'),
        {
            label: 'Domaine',
            displayLabel: true,
            render: ({ domaine: domain }) => (
                <Box sx={{ justifyContent: 'end' }}>
                    <p>{domain?.libelle}</p>
                </Box>
            ),
            isDisplayed: true,
            sortable: false,
            field: 'domaine',
        },
        {
            label: 'Icône',
            displayLabel: true,
            render: ({ iconeName: iconName }) => {
                if (iconName) {
                    return <FaOptionIcon icon={['fad', iconName as IconName]} />;
                }
                return null;
            },
            isDisplayed: true,
        },
        displayCustomValue(
            'dependencies',
            (catalogs) => (catalogs.length > 0 ? catalogs.length.toString() : '(vide)'),
            'Nombre de catalogue(s)',
        ),
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des groupements"
            listingButton={listingButton}
            listingColumns={columns}
            form={Form}
            model={model}
        />
    );
};

export default Panel;
