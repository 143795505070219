import React from 'react';

import { Box } from '@mui/material';
import { SkeletonMosaic } from '@europrocurement/flexy-components';

const StatsCardsSkeleton: React.FunctionComponent = function () {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                height: '100%',
                width: '100%',
            }}
        >
            <SkeletonMosaic
                columns={3}
                rows={3}
            />
        </Box>
    );
};

export default StatsCardsSkeleton;
