import React from 'react';

// import StarterKit from '@tiptap/starter-kit';
// import {
//     MenuButtonBold,
//     MenuButtonItalic,
//     MenuControlsContainer,
//     MenuDivider,
//     MenuSelectHeading,
//     RichTextEditor,
//     type RichTextEditorRef,
// } from 'mui-tiptap';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { mailApi } from '@b2d/redux/RootStore';
import { MailApiSendMailMailCollectionRequest } from '@europrocurement/l2d-domain/openApi/ApiMail';
import { MailForm } from '@europrocurement/l2d-modules';
// import { APIENV, getCustomiserState } from '@europrocurement/l2d-domain';
// import KcAdminClient from '@keycloak/keycloak-admin-client';

type SupportFormProps = {
    screenShoot?: File;
};

// const impersonate = async (
//     userId: string,
//     token: string,
//     url: string,
//     realm: string,
//     client: string,
// ) =>
//     fetch(`${url}/realms/${realm}/protocol/openid-connect/token`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: new URLSearchParams({
//             grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
//             subject_token: token,
//             client_id: client,
//             audience: 'back-to-digital',
//             // requested_token_type: 'urn:ietf:params:oauth:token-type:access_token',
//             requested_subject: userId,
//         }),
//     })
//         .then((response) => {
//             console.log(response);
//             if (response.status === 200) {
//                 console.log('Impersonation success');
//                 // window.location.reload();
//             } else {
//                 console.log('Impersonation failed');
//             }
//             return response.json();
//         })
//         .catch((error) => {
//             console.error('Error:', error);
//         });

// fetch(`${kcConf.url}/admin/realms/${kcConf.realm}/users/${userid}/impersonation`, {
//     method: 'POST',
// })
//     .then((response) => {
//         console.log(response);
//         if (response.status === 200) {
//             console.log('Impersonation success');
//             window.location.reload();
//         } else {
//             console.log('Impersonation failed');
//         }
//     })
//     .catch((error) => {
//         console.error('Error:', error);
//     });
const SupportForm: React.FunctionComponent<SupportFormProps> = function ({ screenShoot }) {
    const keycloak = UseKeycloakService();

    // const kcConf = APIENV.keycloak[getCustomiserState().env];

    console.log(keycloak.getEmail());

    // const [gokc, setgokc] = React.useState(false);

    // React.useEffect(() => {
    //     if (
    //         !gokc &&
    //         kcConf.url &&
    //         kcConf.realm &&
    //         keycloak.getEmail() === 't.guerin@europrocurement.net'
    //     ) {
    //         setgokc(true);
    //         const kcAdminClient = new KcAdminClient();

    //         kcAdminClient.setConfig({
    //             baseUrl: kcConf.url,
    //             realmName: kcConf.realm,
    //         });

    //         kcAdminClient.setAccessToken(`${keycloak.getKc().token}`);

    //         kcAdminClient.users
    //             .find({ first: 0, max: 10, email: 'k.bourbasquet@europrocurement.net' })
    //             .then((users) => {
    //                 console.log(users);
    //                 if (users.length > 0 && users[0].id) {
    //                     impersonate(
    //                         users[0].id,
    //                         keycloak.getKc().token || '',
    //                         kcConf.url || '',
    //                         kcConf.realm || '',
    //                         kcConf.clientId,
    //                     ).then((response) => {
    //                         console.log('je suis la', response);
    //                         // keycloak.set;
    //                     });

    //                     // kcAdminClient.users
    //                     //     .impersonation(
    //                     //         {
    //                     //             realm: kcConf.realm,
    //                     //             id: users[0].id,
    //                     //         },
    //                     //         {
    //                     //             user: users[0].id,
    //                     //             realm: kcConf.realm,
    //                     //         },
    //                     //     )
    //                     //     .then((response) => {
    //                     //         console.log(response);
    //                     //         // window.location.reload();
    //                     //     });
    //                 }
    //             });

    //         // const userid = 's.billaud@legal2digital.fr';
    //         // fetch(`${kcConf.url}/admin/realms/${kcConf.realm}/users/${userid}/impersonation`, {
    //         //     method: 'POST',
    //         // })
    //         //     .then((response) => {
    //         //         console.log(response);
    //         //         if (response.status === 200) {
    //         //             console.log('Impersonation success');
    //         //             window.location.reload();
    //         //         } else {
    //         //             console.log('Impersonation failed');
    //         //         }
    //         //     })
    //         //     .catch((error) => {
    //         //         console.error('Error:', error);
    //         //     });
    //     }
    // }, [gokc, kcConf.clientId, kcConf.realm, kcConf.url, keycloak]);

    console.log(screenShoot);

    // const rteRef = useRef<RichTextEditorRef>(null);

    const baseContent = `
<p>
    Bonjour
    <br>
</p>
<p>
    Je vous remonte une annomalie concernant back-to-digital : 
</p>
<p>
<br>
[Description de l'annomalie]
<br>
<br>
L'annomalie est survenue le ${new Date().toLocaleDateString()} à ${new Date().toLocaleTimeString()}.
<br>
<br>
Cordialement,
<br>
<br>
${keycloak.getName()}
</p>`;

    const sendMail = async (mailPayload: MailApiSendMailMailCollectionRequest) => {
        // const file = await srcToFile(screenShoot.src, 'screenShoot.png', 'image/png');

        mailApi.sendMailMailCollection(mailPayload);
        // console.log(rteRef.current?.editor?.getHTML());
    };

    return (
        <MailForm
            defaultValues={{
                tos: [keycloak.getEmail()],
                from: keycloak.getEmail(),
                fromName: keycloak.getName(),
                replies: [keycloak.getEmail()],
                tags: ['support'],
                subject: 'Anomalie back-to-digital',
                body: baseContent,
            }}
            override={{
                ccs: {
                    isDisplayable: false,
                    name: 'ccs',
                    type: 'text',
                },
                tos: {
                    disabled: true,
                    name: 'tos',
                    type: 'text',
                },
                bccs: {
                    isDisplayable: false,
                    name: 'bccs',
                    type: 'text',
                },
                tags: {
                    isDisplayable: false,
                    name: 'tags',
                    type: 'text',
                },
            }}
            attachments={screenShoot ? [screenShoot] : []}
            sendMailCallback={sendMail}
        />
    );

    /* <RichTextEditor
                ref={rteRef}
                extensions={[StarterKit]} // Or any Tiptap extensions you wish!
                content={baseContent}
                // Initial content for the editor
                // Optionally include `renderControls` for a menu-bar atop the editor:
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuSelectHeading />
                        <MenuDivider />
                        <MenuButtonBold />
                        <MenuButtonItalic />
                        {/* Add more controls of your choosing here 
                    </MenuControlsContainer>
                )}
            /> */
};

export default SupportForm;
