import React from 'react';
import { Box, Typography } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

export type DossierDateCreationProps = {
    date: Date;
};

const DossierDateCreation: React.FunctionComponent<DossierDateCreationProps> = function ({ date }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
                color: 'text.gris70',
            }}
        >
            <FaOptionIcon icon={['fasl', 'calendar']} />
            <Typography
                variant="l2dtableCell"
                component="span"
            >
                {date.toLocaleDateString('fr', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                })}
            </Typography>
        </Box>
    );
};

export default DossierDateCreation;
