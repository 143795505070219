import * as React from 'react';

import { AutocompleteStore } from '@europrocurement/flexy-form';
import { shallowEqual, useSelector } from 'react-redux';
import { getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { AUTOCOMPLETE_KEY, ModelItem } from '@europrocurement/l2d-domain';

/*
 * Async list :
 * - Search action
 * - Autocomplete list
 * - On select action
 */

export type AsyncSelectProps = {
    onSelectItem: (item: unknown) => void;
    inputLabel: string;
    placeholder?: string;
    defaultValue?: unknown;
    renderField: string;
    required?: boolean;
    model: ModelItem<unknown>;
    reset?: boolean;
    disabled?: boolean;
};

const AsyncSelect: React.FunctionComponent<AsyncSelectProps> = function (props) {
    const {
        inputLabel,
        placeholder,
        onSelectItem,
        required,
        renderField,
        defaultValue,
        model,
        reset = false,
        disabled = false,
    } = props;

    const { selector, datasourcesThunks, getItemDefaultLabel } = model;

    const modelSelector = useSelector(selector, shallowEqual);

    const noOptionTextFn = React.useCallback((reason: 'noinput' | 'unsearchable' | 'nooptions') => {
        switch (reason) {
            case 'noinput':
                return 'Complétez ce champs pour effectuer une recherche';
            case 'unsearchable':
                return 'La saisie est invalide';
            case 'nooptions':
            default:
                return 'Aucun résultat pour cette recherche';
        }
    }, []);

    return (
        <AutocompleteStore
            key={modelSelector.main.name}
            dataSource={modelSelector[AUTOCOMPLETE_KEY]}
            fetchData={
                datasourcesThunks[AUTOCOMPLETE_KEY].getData as getDataThunkType<{
                    [key: string]: unknown;
                }>
            }
            renderField={renderField}
            defaultInputValue={defaultValue?.[renderField as never]}
            idProperty="id"
            inputlabel={inputLabel}
            required={required ?? false}
            inputProps={{
                size: 'small',
                placeholder,
            }}
            // searchability={(terms) => terms.length > 3} // Cause maximum update depth exceeded.
            onChange={(event, newValue) => onSelectItem(newValue)}
            getOptionLabel={(item: unknown) => getItemDefaultLabel(item) ?? 'DEFAULT LABEL'}
            simpleRenderOption={(item: unknown) => getItemDefaultLabel(item) ?? 'DEFAULT LABEL'}
            reset={reset}
            noOptionsTextFn={noOptionTextFn}
            disabled={disabled}
        />
    );
};

export default AsyncSelect;
