import React from 'react';

import { GridIndicator } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { ReadOnlyField, defaultGridProps } from '@europrocurement/flexy-form';

export type ViewFolioFormalitySectionProps = {
    numero: string;
    societeDenomination: string;
};

const ViewFolioFormalitySection: React.FunctionComponent<ViewFolioFormalitySectionProps> =
    function ({ numero, societeDenomination }) {
        return (
            <Box sx={{ marginTop: '10px' }}>
                <GridIndicator sx={{ display: 'none' }} />
                <Grid
                    container
                    {...defaultGridProps}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                    >
                        <ReadOnlyField
                            inputLabel="Numéro de dossier"
                            value={numero || 'N/A'}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                    >
                        <ReadOnlyField
                            inputLabel="Dénomination société"
                            value={societeDenomination || 'N/A'}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    };

export default ViewFolioFormalitySection;
