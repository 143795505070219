import React, { PropsWithChildren } from 'react';
import { Box, lighten, List, Typography, useTheme } from '@mui/material';

export type AutocompleteListboxProps = {
    title?: string;
};

const AutocompleteListbox: React.FunctionComponent<PropsWithChildren<AutocompleteListboxProps>> =
    function (props) {
        const { children, title, ...otherProps } = props;
        const { palette } = useTheme();

        const titleSx = {
            display: 'block',
            marginBottom: '10px',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '160%',
            color: lighten(palette.secondary.main, 0.5),
        };

        return (
            <Box {...otherProps}>
                {title && (
                    <Typography
                        component="span"
                        sx={titleSx}
                    >
                        {title}
                    </Typography>
                )}
                <List sx={{ padding: 0 }}>{children}</List>
            </Box>
        );
    };

export default AutocompleteListbox;
