import * as React from 'react';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { FieldErrors } from 'react-hook-form';
import LineActionButton from './LineActionButton';
import { ArticleAdditionalInformations, ArticlesSchema } from '../Form/validation';
import TextField from '../../components/form/TextField';

type FormArticle = ArticlesSchema['articles'][number] & ArticleAdditionalInformations;

type ComponentProps = {
    article: FormArticle;
    setQuantity: (quantity: number) => void;
    inputName: string;
    errors: FieldErrors;
};

const QuantityModule: React.FunctionComponent<ComponentProps> = function ({
    article,
    setQuantity,
    inputName,
    errors,
}) {
    const { quantity } = article;
    const QUANTITY_LIMIT = 99;

    const incrementQuantity = () => {
        setQuantity(quantity + 1);
    };
    const decrementQuantity = () => {
        setQuantity(quantity - 1);
    };
    const quantityInputStyle = {
        maxWidth: '50px',
        margin: '0 20px',
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography
                    sx={{ opacity: 0.5 }}
                    fontSize={15}
                    fontStyle="italic"
                >
                    quantité
                </Typography>
            </Box>
            <TextField
                ref={() => {}} // ?
                name={inputName}
                label=""
                required={false}
                onBlur={() => {}}
                sx={quantityInputStyle}
                value={article.quantity}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                    const { value } = event.currentTarget;
                    if (value.length < 3) {
                        const parsed = parseInt(value, 10);
                        if (!Number.isNaN(parsed)) {
                            setQuantity(parsed);
                        }
                    }
                }}
                errors={errors}
            />
            <LineActionButton
                actionName="decrement quantity"
                icon={faMinus}
                onClick={decrementQuantity}
                disabled={article.quantity <= 1}
                bordered
                sx={{ marginRight: '15px' }}
            />
            <LineActionButton
                actionName="increment quantity"
                icon={faPlus}
                onClick={incrementQuantity}
                disabled={article.quantity >= QUANTITY_LIMIT}
                bordered
                sx={{ marginRight: '15px' }}
            />
        </Box>
    );
};

export default QuantityModule;
