import React, { useContext } from 'react';
import { Box, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { CardHeader, CardSection } from '@europrocurement/flexy-components';
import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { useNavigate } from 'react-router-dom';
import {
    DossierCompanyFormality,
    DossierStepCompletion,
    DossierDateCreation,
    DossierNotifications,
    DossierStatus,
    DossierReferences,
} from '../../liste';
import { DossierRoutingContext } from '../../../providers';

export type DossierSiblingsProps = {
    orderNumber: string;
    siblings: Array<DossierOpenSearch>;
};

const DossierSiblings: React.FunctionComponent<DossierSiblingsProps> = function (props) {
    const { orderNumber, siblings } = props;
    const navigate = useNavigate();
    const { getDossierRoute } = useContext(DossierRoutingContext);

    const titleSx = {
        fontSize: '20px',
        lineHeight: '28px',
    };
    const headingSx = {
        borderLeftWidth: '2px',
        minHeight: 'auto',
    };

    const linkStyle = {
        borderRadius: '5px',
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#11111111',
        },
    };
    const linkIconStyle = {
        fontSize: '16px',
    };

    const cardHeader = (
        <Box>
            <CardHeader
                title={`Autres formalités de la commande #${orderNumber}`}
                titleSx={titleSx}
                sx={headingSx}
            />
        </Box>
    );

    const items = siblings.map((dossier, index) => {
        const cellSx = index < siblings.length - 1 ? {} : { border: 'none' };

        return (
            <TableRow key={dossier.numero}>
                <TableCell sx={cellSx}>
                    <DossierReferences
                        itemReference={dossier.numero as string}
                        companyReference={dossier.societeRef as string}
                        direction="column"
                    />
                </TableCell>
                <TableCell sx={cellSx}>
                    <DossierCompanyFormality
                        libelle={dossier.societeDenomination as string}
                        formalites={dossier.formalites?.map((formalite) => ({
                            id: formalite?.id as number,
                            libelle: formalite?.libelle as string,
                        }))}
                    />
                </TableCell>
                <TableCell sx={cellSx}>
                    <DossierStepCompletion
                        step={dossier.stepLabel as string}
                        currentCompletion={dossier.stepCompletion as number}
                    />
                </TableCell>
                <TableCell sx={cellSx}>
                    {dossier.dateCreation && (
                        <DossierDateCreation date={new Date(dossier.dateCreation)} />
                    )}
                </TableCell>
                <TableCell sx={cellSx}>
                    <DossierNotifications documentsCount={dossier.documentsCount as number} />
                </TableCell>
                <TableCell sx={cellSx}>
                    <DossierStatus
                        label={dossier.statutLibelle as string}
                        color="primary"
                    />
                </TableCell>
                <TableCell sx={cellSx}>
                    <Box>
                        <IconButton
                            onClick={() => navigate(getDossierRoute(dossier.id as number))}
                            sx={linkStyle}
                        >
                            <FaOptionIcon
                                icon={['fasl', 'chevron-right']}
                                sx={linkIconStyle}
                                size="xs"
                            />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
        );
    });

    return (
        <CardSection
            header={cardHeader}
            sxContent={{ padding: '24px 32px' }}
        >
            <Table>
                <TableBody>{items}</TableBody>
            </Table>
        </CardSection>
    );
};

export default DossierSiblings;
