/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    DefFrequenceApi,
    DefFrequenceApiGetDefFrequencesDefFrequenceCollectionRequest,
    DefFrequenceJsonldDefFrequenceRead,
} from '../../../openApi/ApiAchats';
import { ACHATS_REDUCER_NAME } from '../constants';

export type DefFrequenceApiObject = DefFrequenceJsonldDefFrequenceRead & Record<string, unknown>;

export type DefFrequence = DefFrequenceApiObject;

export const DEF_FREQUENCE_SLICE_NAME = 'frequence';

export const DEF_FREQUENCE_AUTOCOMPLETE_DATASOURCE_NAME = 'autocompleteFrequence';

export const additionalFrequencesDataSources: Array<string> = [
    DEF_FREQUENCE_AUTOCOMPLETE_DATASOURCE_NAME,
];

const mapperDefFrequence = function (defFrequence: DefFrequence) {
    return {
        ...defFrequence,
    } as DefFrequence;
};

export function createDefFrequenceSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new DefFrequenceApi(conf);

    const fetchDefFrequencesCollection: FetchCollectionData<DefFrequenceApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let dfrId: number | undefined;
        let dfrLibelle: string | undefined;

        if (filters.dfrId) {
            dfrId = filters.dfrId as number;
        }

        if (filters.dfrLibelle) {
            dfrLibelle = filters.dfrLibelle as string;
        }

        const requestParameters: DefFrequenceApiGetDefFrequencesDefFrequenceCollectionRequest = {
            xIdSociete,
            dfrId,
            dfrLibelle,
        };

        return api
            .getDefFrequencesDefFrequenceCollection(requestParameters)
            .then((res) => res.data) as Promise<ApiCollectionResponse<DefFrequence>>;
    };

    const fetchFrequencesCollectionByDfrLibelle: FetchCollectionData<DefFrequence> = ({
        search,
        pagination,
        xIdSociete,
    }) => {
        const requestParameters: DefFrequenceApiGetDefFrequencesDefFrequenceCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: 20,
            dfrLibelle: search,
        };

        return api
            .getDefFrequencesDefFrequenceCollection(requestParameters)
            .then((res) => res.data) as Promise<ApiCollectionResponse<DefFrequence>>;
    };

    const fetchDefFrequencesItem: FetchItemDataType<DefFrequence> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getDefFrequenceDefFrequenceItem({
                dfrId: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<DefFrequenceApiObject>;

    const { slice: defFrequenceSlice, dataSourcesThunks: defFrequenceDataSourcesThunks } =
        SliceFactory.createSlice<DefFrequenceApiObject, DefFrequence>(
            DEF_FREQUENCE_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            additionalFrequencesDataSources,
            fetchDefFrequencesCollection,
            fetchDefFrequencesItem,
            mapperDefFrequence,
            {
                cloture: true,
                deleted: false,
            },
            {
                data: {
                    fetchByDfrLibelle: fetchFrequencesCollectionByDfrLibelle,
                },
            },
            [
                {
                    field: 'updatedAt',
                    value: 'desc',
                },
            ],
        );

    return { defFrequenceSlice, defFrequenceDataSourcesThunks };
}
