import * as React from 'react';

import DOMPurify from 'dompurify';
import { Tooltip } from '@mui/material';

export type TruncatedHtmlTextProps = {
    /**
     * The HTML content to be displayed
     * @default ''
     */
    content?: string | null;
    /**
     * Maximum number of characters to show in the truncated view
     * @default 25
     */
    maxLength?: number;
    /**
     * Maximum width of the tooltip
     * @default 500
     */
    tooltipMaxWidth?: number;
    /**
     * Maximum height of the tooltip
     * @default 400
     */
    tooltipMaxHeight?: number;
    /**
     * Tooltip placement
     * @default "right"
     */
    tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
};

const TruncatedHtmlText: React.FunctionComponent<TruncatedHtmlTextProps> = function ({
    content = '',
    maxLength = 25,
    tooltipMaxWidth = 500,
    tooltipMaxHeight = 400,
    tooltipPlacement = 'right',
}) {
    const safeContent = content || '';
    const sanitizedHtml = DOMPurify.sanitize(safeContent);
    const isTruncated = safeContent.length > maxLength;

    return (
        <Tooltip
            title={
                <div
                    ref={(node) => {
                        const setHtml = (element: HTMLElement, html: string) => {
                            const div = element;
                            div.innerHTML = html;
                        };

                        if (node) {
                            setHtml(node, sanitizedHtml);
                        }
                    }}
                    style={{
                        maxWidth: tooltipMaxWidth,
                        maxHeight: tooltipMaxHeight,
                        overflow: 'auto',
                    }}
                />
            }
            placement={tooltipPlacement}
            arrow
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <span>{safeContent.substring(0, maxLength) + (isTruncated ? '...' : '')}</span>
            </div>
        </Tooltip>
    );
};

export default TruncatedHtmlText;
