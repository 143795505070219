import * as React from 'react';
import { MenuEntry } from '@europrocurement/flexy-components';
import FormalitiesListing from './views/listing/FormalitiesListing';
import FormalityAdd from './views/add/FormalityAdd';
import FormalityRoot from './views/FormalityRoot';
import FormalityShow from './views/show/FormalityShow';
import SelectionSocieteShow from './views/show/SelectionSocieteShow';
import CompatibilitiesShow from './views/compatibilities/CompatibilitiesShow';
import {
    FORMALITIES_BASE_BATH,
    FORMALITY_COMPATIBILITIES,
    FORMALITY_SELECTION,
    FORMALITY_LIST,
    SOCIETE_SELECTION,
    FORMALITY_RECAP,
} from './constants/paths';
import RecapFormalityShowWrapper from './views/show/RecapFormalityShowWrapper';

export const FormalityRouter = {
    path: FORMALITIES_BASE_BATH,
    element: <FormalityRoot />,
    children: [
        {
            path: 'liste',
            children: [
                {
                    path: '',
                    index: true,
                    element: <FormalitiesListing />,
                },
                {
                    path: ':formalityId',
                    element: <FormalityShow />,
                },
            ],
        },
        {
            path: 'compatibilites',
            element: <CompatibilitiesShow />,
        },
        {
            path: 'ajouter',
            element: <FormalityAdd />,
        },
        {
            path: 'selectionsociete',
            element: <SelectionSocieteShow />,
        },
        {
            path: 'recap',
            element: <RecapFormalityShowWrapper />,
        },
    ],
};

export const FormalityMenuEntries: MenuEntry[] = [
    {
        title: 'Gestion des Formalités',
        icon: 'gavel',
        role: 'realm:interne',
        children: [
            {
                title: 'Liste des formalités',
                icon: 'gavel',
                href: FORMALITY_LIST,
                role: 'realm:interne',
            },
            {
                title: 'Liste des compatibilités',
                icon: 'gavel',
                href: FORMALITY_COMPATIBILITIES,
                role: 'realm:interne',
            },
            {
                title: 'Sélection formalité',
                icon: 'gavel',
                href: FORMALITY_SELECTION,
                role: 'realm:interne',
            },
            {
                title: 'Sélection société',
                icon: 'gavel',
                href: SOCIETE_SELECTION,
            },
            {
                title: 'Récap dossier formalité',
                icon: 'gavel',
                href: FORMALITY_RECAP,
                role: 'realm:interne',
            },
        ],
    },
];

export default FormalityRouter;
