import AdditionalQuoteLine from '@b2d/hooks/additionalProductsData/AdditionalQuoteLine';
import { AppDispatch, RootStateType } from '@b2d/redux/types';
import { FactureFormSelector } from '@b2d/redux/RootStore';
import { getDevis, getDevisLigne, selectDevis } from '@b2d/redux/subReducers';
import {
    Devis,
    DevisLigne,
    Dossier,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    RubriqueFacturation,
} from '@europrocurement/l2d-domain';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Box } from '@mui/material';
import _ from 'lodash';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomItemRenderFieldProps, ExtandedFormContext } from '@europrocurement/flexy-form';
import apiInvoiceQuoteProductToFormInvoicePurchaseProduct from '../../../functions/dataTransformers/apiToForm/apiInvoiceQuoteProductToFormInvoicePurchaseProduct';
import { LigneProduit, TableauProduits } from '../../../types';

type QuoteDetailProps = {
    formContext: ExtandedFormContext;
    props: CustomItemRenderFieldProps;
};

const QuoteDetail: React.FunctionComponent<QuoteDetailProps> = function ({
    formContext,
    props,
}: QuoteDetailProps) {
    const quoteDataSource: DataSource<Devis> = useSelector(
        (s: RootStateType) => s.facturation.devis.main,
        _.isEqual,
    );

    const quoteLineDataSource: DataSource<DevisLigne> = useSelector(
        (s: RootStateType) => s.facturation.devisLigne.main,
        _.isEqual,
    );

    const quoteSelected: Devis | undefined = useSelector(
        (s: RootStateType) => s.facturation.devis.main.selected,
        _.isEqual,
    );

    const publicationFolioSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos[DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME].selected,
        _.isEqual,
    );

    const invoiceCategoriesDataSource: DataSource<RubriqueFacturation> = useSelector(
        (s: RootStateType) => s.dossiers.rubfac.main,
        _.isEqual,
    );

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');
    const dispatch = useDispatch<AppDispatch>();
    const state = useSelector(FactureFormSelector);
    const shouldDisplay = useMemo(
        () => (isInterne ? state.displayDetails : false),
        [isInterne, state.displayDetails],
    );

    useEffect(() => {
        if (!publicationFolioSelected || !publicationFolioSelected?.numero) return;

        /**
         * Filtering
         */
        dispatch({
            type: `${quoteDataSource.slicename}/set${quoteDataSource.name}Filter`,
            payload: { key: 'lignesIdAnnonce', value: publicationFolioSelected.numero },
        });

        /**
         * Retrieve
         */
        dispatch(getDevis({}));
    }, [dispatch, publicationFolioSelected, quoteDataSource.name, quoteDataSource.slicename]);

    /**
     * If we successfully get one and only one quote, then select it.
     */
    useEffect(() => {
        if (quoteDataSource.data.length !== 1 || !quoteDataSource.data[0].id) return;

        dispatch(
            selectDevis({
                id: quoteDataSource.data[0].id.toString(),
            }),
        );
    }, [dispatch, quoteDataSource.data]);

    /**
     * RG-Achat-80
     *
     * Invoice Categories to exclude from the quote lines.
     * https://legal2digital.atlassian.net/browse/PA-362
     */
    const irrelevantCategories = useMemo(() => ['AVO', 'RED', 'RED2', 'LIB'], []);

    const filteredCategories = useMemo(
        () =>
            invoiceCategoriesDataSource.data
                .filter(
                    (category) => category.code && !irrelevantCategories.includes(category.code),
                )
                .map((filteredCategory) => filteredCategory.code),
        [invoiceCategoriesDataSource.data, irrelevantCategories],
    );

    const hasProperties = (obj: Record<string, unknown>, keys: string[]): boolean =>
        keys.every((key) => Object.prototype.hasOwnProperty.call(obj, key));

    const quoteLineStateConditionToFetch = useMemo(() => {
        const isFirstFetch = quoteLineDataSource.status === 'idle';

        const isCorrectlyFiltered = hasProperties(quoteLineDataSource.filters, [
            'devisId',
            'rubfac2',
        ]);

        return isFirstFetch || !isCorrectlyFiltered;
    }, [quoteLineDataSource.filters, quoteLineDataSource.status]);

    /**
     * Filter the quote lines DataSource to get only lines of selected quote, with correct invoice category.
     */
    const applyFiltersToQuoteLines = useCallback(() => {
        if (
            !quoteSelected ||
            !quoteSelected.lignes ||
            quoteSelected.lignes?.length === 0 ||
            !quoteLineStateConditionToFetch
        )
            return;

        /**
         * Filtering
         */
        dispatch({
            type: `${quoteLineDataSource.slicename}/set${quoteLineDataSource.name}Filter`,
            payload: { key: 'devisId', value: quoteSelected.id },
        });

        dispatch({
            type: `${quoteLineDataSource.slicename}/set${quoteLineDataSource.name}Filter`,
            payload: { key: 'rubfac2', value: filteredCategories },
        });

        dispatch(getDevisLigne({}));
    }, [
        dispatch,
        filteredCategories,
        quoteLineDataSource.name,
        quoteLineDataSource.slicename,
        quoteLineStateConditionToFetch,
        quoteSelected,
    ]);

    /**
     * If there is a selected quote,
     * - Apply filters to retrieve corresponding lines ;
     * - Format lines API objects into form objects ;
     * - Returns the array of formatted quote lines.
     */
    const foundQuotes = useMemo<TableauProduits>(() => {
        if (!quoteSelected || !quoteSelected.lignes || quoteSelected.lignes?.length === 0)
            return [];

        applyFiltersToQuoteLines();

        const formattedQuoteLines = quoteLineDataSource.data.map((quoteLine) =>
            apiInvoiceQuoteProductToFormInvoicePurchaseProduct(
                quoteLine,
                invoiceCategoriesDataSource.data,
            ),
        );

        return formattedQuoteLines;
    }, [
        applyFiltersToQuoteLines,
        invoiceCategoriesDataSource.data,
        quoteLineDataSource.data,
        quoteSelected,
    ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [subForm, setSubForm] = useState<any>();
    const [quoteLineFound, setQuoteLineFound] = useState<Partial<LigneProduit>>();

    useEffect(() => {
        if (!subForm) return;

        const foundLine = foundQuotes.find(
            (produitQuote: LigneProduit) => produitQuote.idLigneVente === subForm.idLigneVente,
        );

        if (foundLine && quoteLineFound !== foundLine) {
            setQuoteLineFound(foundLine);
        }
    }, [foundQuotes, quoteLineFound, subForm]);

    const res: ReactNode = useMemo(() => {
        if (!shouldDisplay) return null;

        setSubForm(formContext.getValue(props.objectPath));

        if (!subForm || !foundQuotes) {
            return null;
        }

        return (
            <Box sx={{ height: '20px' }}>
                {quoteLineFound ? <AdditionalQuoteLine lineProductQuote={quoteLineFound} /> : null}
            </Box>
        );
    }, [formContext, foundQuotes, props.objectPath, quoteLineFound, shouldDisplay, subForm]);

    return res;
};

export default QuoteDetail;
