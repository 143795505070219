import React, { useEffect, useMemo, useState } from 'react';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { useForm } from 'react-hook-form';
import { Box, Button, Stack, Typography } from '@mui/material';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import {
    FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME,
    FactureAchat,
    Fournisseur,
    FournisseurSelector,
    useTiersService,
} from '@europrocurement/l2d-domain';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootStateType } from '@b2d/redux/types';
import { FactureFormSelector, customizerSelector, factureAchatApi } from '@b2d/redux/RootStore';
import { useNavigate } from 'react-router';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from 'notistack';
import { useBackFromMediaObjectEdit } from '@b2d/pages/Achats/achatRouterHooks';
import { FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { confirmationSaisieFactureModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import _ from 'lodash';
import usePublisherSectionStructure from '@b2d/pages/Achats/components/forms/formElements/hooks/usePublisherSectionStructure';
import useSwitchLockValues from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import useUpdateDiscountRate from '@b2d/pages/Achats/hooks/useUpdateDiscountRate';
import useUpdateTotals from '@b2d/pages/Achats/hooks/useUpdateTotals';
import useDomain from '@b2d/hooks/useDomain';
import { useResetAllForm } from '../../functions/generalFunctions';
import { useMultiAnnonceContext } from '../../../../views/forms/multi/RegisterMultiInvoiceView';
import NoteModalFormFournisseur from '../../../informationWidget/NoteModalFormFournisseur';
import { updateFormWithFournisseur, isSocieteGroupe } from '../../functions/fournisseurFunctions';

import { EnteteFactureForm } from '../../types';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import StatusList from '../../../fragments/StatusList';
import useHeaderSectionStructure from '../../formElements/hooks/useHeaderSectionStructure';
import { ModeProps, SectionHeadControlProps } from '../../formElements/types';
import useHandleAdornment from '../../formElements/utils/useHandleAdornment';
import formHeaderSectionToApiInvoicePurchasePost from '../../functions/dataTransformers/formToApi/formHeaderSectionToApiInvoicePurchasePost';

export const EnteteSaisieMultiple = function () {
    const { getFournisseurAc, selectFournisseurAc } = useTiersService();

    const { mediaObject } = useMultiAnnonceContext();
    const { xIdSociete } = useSelector(customizerSelector);
    const { enqueueSnackbar } = useSnackbar();
    const { resetDomain } = useDomain();

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const state = useSelector(FactureFormSelector);

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const formContext = useForm({
        mode: 'onTouched',
        // defaultValues: {},
    });

    const resetForm = useResetAllForm();

    const [validSubmit, setValidSubmit] = useState<boolean>(false);
    const [modalConfirmSubmit, setModalConfirmSubmit] = useState<boolean>(false);

    const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures,
        shallowEqual,
    );

    const achatsFromStatementDataSource: DataSource<FactureAchat> = useSelector(
        (s: RootStateType) => s.achats.factureachat[FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME],
        _.isEqual,
    );

    const fournisseurSelected: Fournisseur | undefined =
        useSelector(FournisseurSelector).autocompleteFactures.selected;

    const [supplierFromStatement, setSupplierFromStatement] = useState<number>();

    const publisherMode = useMemo<ModeProps>(() => ({ type: 'register', nature: 'multiple' }), []);

    const publisherChildrenList = useMemo<Array<SectionHeadControlProps>>(() => {
        const component: Array<SectionHeadControlProps> = [];

        if (
            fournisseurSelected &&
            fournisseurSelected?.notes &&
            fournisseurSelected.notes.length > 0
        ) {
            component.push({
                component: (
                    <NoteModalFormFournisseur
                        noteFournisseur={fournisseurSelected.notes as string}
                    />
                ),
                side: 'left',
            });
        }
        return component;
    }, [fournisseurSelected]);

    const { stateSwitchLockValues } = useSwitchLockValues();
    const { updateTotals } = useUpdateTotals({ formContext, stateSwitchLockValues });
    const { modalDiscountRate } = useUpdateDiscountRate({
        formContext,
        stateSwitchLockValues,
        fournisseur: fournisseurSelected,
        overwriteAfterUpdate: () => {
            updateTotals({
                reasonToTriggerConfirmationModal: !stateSwitchLockValues.totals.value,
            });
        },
    });

    /**
     * This loops
     */
    const { publisherAdornment } = useHandleAdornment({
        formContext,
        modalDiscountRate,
        fournisseur: fournisseurSelected,
        stateSwitchLockValues,
    });

    const { publisherStructure } = usePublisherSectionStructure({
        dataSource: fournisseursDataSource,
        fetchData: getFournisseurAc,
        defaultInputValue: state.defaultSiren,
        mode: publisherMode,
        headerChildren: publisherChildrenList,
        adornment: publisherAdornment,
    });

    const headerMode = useMemo<ModeProps>(() => ({ type: 'register', nature: 'multiple' }), []);

    const { headerSectionStructure, stateHeaderSectionStructure } = useHeaderSectionStructure({
        formContext,
        mode: headerMode,
        isInterne,
    });

    useEffect(() => {
        if (
            achatsFromStatementDataSource.data.length > 0 &&
            achatsFromStatementDataSource.data[0].idEntiteFacturante
        ) {
            setSupplierFromStatement(achatsFromStatementDataSource.data[0].idEntiteFacturante);

            dispatch(
                selectFournisseurAc({
                    id: achatsFromStatementDataSource.data[0].idEntiteFacturante,
                }),
            );
        }
    }, [
        achatsFromStatementDataSource.data,
        dispatch,
        selectFournisseurAc,
        setSupplierFromStatement,
        supplierFromStatement,
    ]);

    /**
     * Define publishers availability depending on the domain.
     *
     * Business rules :
     *  1. If user is not internal to the company, do not filter by ddmId.
     *  2. If id company of Legal2Digital (12), filter by ddmId, otherwise force filter on ddmId 0.
     *
     * !!Trigger rerender!!
     */
    // const filterPublisherAutocomplete = useCallback(() => {
    //     if (!isInterne || !formContext.getValues('facture_formalite')) {
    //         dispatch({
    //             type: `${FOURNISSEUR_SLICE_NAME}/delete${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Filter`,
    //             payload: {
    //                 field: 'ddmId',
    //             },
    //         });
    //     } else {
    //         dispatch({
    //             type: `${FOURNISSEUR_SLICE_NAME}/set${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Filter`,
    //             payload: {
    //                 key: 'ddmId',
    //                 value: xIdSociete === 12 ? state.defDomain : 0,
    //             },
    //         });
    //     }
    // }, [dispatch, formContext, isInterne, state.defDomain, xIdSociete]);

    const factureFormStructure: FormStructure[] = useMemo(
        () =>
            // filterPublisherAutocomplete();
            [...headerSectionStructure, ...publisherStructure],
        [headerSectionStructure, publisherStructure],
    );

    const handleSubmit = async function (data: EnteteFactureForm) {
        if (isSocieteGroupe(fournisseursDataSource.selected?.societeReference)) {
            enqueueSnackbar(
                <Typography>
                    Impossible de valider l&apos;en-tête avec un fournisseur du Groupe
                </Typography>,
                { variant: 'error' },
            );
            return;
        }

        const cloneHeaderFormData = _.clone(data);

        if (stateHeaderSectionStructure.temporaryStatementId.value) {
            cloneHeaderFormData.id_statement =
                stateHeaderSectionStructure.temporaryStatementId.value;
        }

        const factureToPost = formHeaderSectionToApiInvoicePurchasePost(
            cloneHeaderFormData,
            xIdSociete,
            mediaObject,
        );

        const requestParameters: FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest = {
            factureAchatJsonldFactureAchatCreateFactureAchatWrite: factureToPost,
            xIdSociete,
        };

        const resFactureAchat =
            await factureAchatApi.createFactureAchatFactureAchatCollection(requestParameters);

        resetForm({ formContext });

        const res = generateB2DPath('formmultiadddossier', {
            factureAchat: resFactureAchat.data as FactureAchat,
            mediaObjectId: mediaObject.id,
        });

        if (res.status === 'OK') {
            resetDomain();
            navigate(res.path);
        } else {
            // TODO
        }
    };

    const onCancel = useBackFromMediaObjectEdit();

    /**
     * Afficher infos fournisseur
     *
     * RG : https://legal2digital.atlassian.net/browse/PA-53
     *
     * Si le N° de dossier / annonce change :
     * Remplir les champs Siren, Raison sociale, Type remise
     * Ajouter une ligne de produit : Remise
     *
     * @see fournisseursDataSource.selected
     * @see updateRSFFournisseur
     */
    useEffect(() => {
        const fournisseur = fournisseursDataSource.selected;

        if (fournisseur !== undefined) {
            formContext.setValue('fournisseur', fournisseur);
            formContext.setValue('siren_fournisseur', fournisseur);
            formContext.trigger('siren_fournisseur');
        }

        updateFormWithFournisseur(formContext, fournisseur, {
            siren: (siren: string) => {
                if (siren !== state.defaultSiren && state.defDomain !== 3) {
                    dispatch({ type: ACTIONS.SET_SIREN, payload: siren });
                }
            },
        });
    }, [
        dispatch,
        formContext,
        fournisseursDataSource.selected,
        state.defaultSiren,
        state.defDomain,
    ]);

    return (
        <>
            <ConfirmationModal
                isModalOpen={modalConfirmSubmit}
                closeModal={() => {
                    setModalConfirmSubmit(false);
                }}
                messages={confirmationSaisieFactureModalMessages}
                actionOnValidation={() => {
                    formContext.handleSubmit((factureForm) => {
                        setValidSubmit(true);

                        handleSubmit(factureForm as EnteteFactureForm);

                        setModalConfirmSubmit(false);
                    })();
                    setModalConfirmSubmit(false);
                }}
                actionOnCancellation={() => {
                    setValidSubmit(false);
                    setModalConfirmSubmit(false);
                }}
            />

            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 1,
                        }}
                    >
                        <Typography
                            color="secondary"
                            typography="h1"
                        >
                            Saisie de la facturante
                        </Typography>
                        <StatusList itemToWatch={mediaObject} />
                    </Stack>
                </Box>
            </Box>

            <FlexyForm
                formObject={{}}
                formStructure={factureFormStructure}
                onSubmit={() => {
                    setModalConfirmSubmit(true);
                }}
                formContext={formContext}
                submitButton={{
                    render: ({ isSubmitSuccessful, isSubmitting, isLoading, isValidating }) => {
                        const reasonToLock: boolean =
                            (isSubmitSuccessful || isSubmitting || isLoading || isValidating) &&
                            validSubmit;

                        return (
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Button
                                    color="error"
                                    disabled={reasonToLock}
                                    variant="contained"
                                    endIcon={<ClearIcon />}
                                    onClick={() => {
                                        onCancel();
                                    }}
                                    style={{ marginTop: '16px', marginBottom: '16px' }}
                                >
                                    Annuler la saisie
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={reasonToLock}
                                    variant="contained"
                                    endIcon={<CheckIcon />}
                                    style={{ marginTop: '16px', marginBottom: '16px' }}
                                >
                                    Valider l&apos;en-tête de facture
                                </Button>
                            </Box>
                        );
                    },
                }}
            />
        </>
    );
};

export default EnteteSaisieMultiple;
