import React, { ReactNode } from 'react';
import { experimentalStyled, Container, Box, Theme } from '@mui/material';

import { PageContainer } from '@europrocurement/flexy-components/pages';
import {
    ActionsProvider,
    ContextActionType,
    useModal,
} from '@europrocurement/flexy-components/providers';
import { TopbarHeight, footerHeight } from '../../../assets/global/Theme-variable';
import Customizer from '../../organisms/Customizer';
import {
    Sidebar,
    Header,
    Footer,
    type MenuEntry,
    HeaderProps,
    FooterProps,
    SidebarProps,
} from '../../organisms';
import { Modalizer } from '../../molecules';
import ContextActionsMenu from '../../organisms/ContextActionsMenu/ContextActionsMenu';
import FullLayoutOutlet from './FullLayoutOutlet';

export type FullLayoutProps = {
    menuEntries: MenuEntry[];
    footerProps: FooterProps;
    headerProps?: HeaderProps;
    contextActionMainIcon?: ReactNode;
    defaultContextActions?: ContextActionType[];
    logo: SidebarProps['logo'];
    logoFolded: SidebarProps['logoFolded'];
    sidebarActions?: SidebarProps['actions'];
    pageTitle?: string;
};

const MainWrapper = experimentalStyled('div')(() => ({
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
}));

const PageWrapper = experimentalStyled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',

    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
        paddingTop: TopbarHeight,
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: '64px',
    },
}));

const FullLayout = function (props: FullLayoutProps) {
    const {
        menuEntries,
        footerProps,
        headerProps,
        contextActionMainIcon,
        defaultContextActions = [],
        logo,
        logoFolded,
        sidebarActions = [],
        pageTitle = 'Back2Digital',
    } = props;

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
    );

    const { modalStatus, modalActions } = useModal();

    return (
        <PageContainer
            title={pageTitle}
            description="Backoffice Legal2digital"
        >
            <ActionsProvider defaultActions={defaultContextActions}>
                <MainWrapper
                    style={{
                        height: isMobile ? 'auto' : '100vh',
                    }}
                    data-testid="test-id-FullLayout"
                >
                    <Header {...headerProps} />

                    <Sidebar
                        menuEntries={menuEntries}
                        logo={logo}
                        logoFolded={logoFolded}
                        actions={sidebarActions}
                    />

                    <PageWrapper>
                        <Container
                            maxWidth={false}
                            sx={{
                                height: isMobile
                                    ? 'auto'
                                    : `calc(100vh - ${footerHeight}px - ${TopbarHeight}px)`,
                            }}
                        >
                            <FullLayoutOutlet />
                            <Footer {...footerProps} />
                        </Container>
                    </PageWrapper>
                    <ContextActionsMenu mainIcon={contextActionMainIcon} />
                    <Customizer showButton={false} />
                </MainWrapper>
            </ActionsProvider>
            <Modalizer
                open={modalStatus.isOpen}
                fullWidth={modalStatus.isFullWidth}
                isDraggable={modalStatus.isDraggable}
                onClose={() => modalActions.close()}
                maxWidth="xl"
            >
                <Box>{modalStatus.content}</Box>
            </Modalizer>
        </PageContainer>
    );
};

export default FullLayout;
