import { AxiosRequestConfig } from 'axios';
import { DefFrequence } from '@europrocurement/l2d-domain/reducers/achats/slices/defFrequenceSlice';
import { useSociete } from '@europrocurement/l2d-domain';
import { useSliceUtilities } from './useSliceUtilities';

export type UseFrequencyProps = {
    dataSourceName?: string;
};

/**
 * useFrequency
 *
 * Fournit des utilitaires pour interagir avec le slice `frequence`.
 * Utilise `useSliceUtilities` pour centraliser les sélecteurs et actions.
 */

export const useFrequency = ({ dataSourceName = 'main' }: UseFrequencyProps) => {
    const {
        dataSource: frequencyDatasource,
        selectedItem: frequency,
        status,
        isLoading,
        isFailed,
        actions,
    } = useSliceUtilities({
        sliceName: 'frequence',
        dataSourceName,
    });

    const companyId = useSociete();

    // Méthodes spécifiques à `frequence`
    const selectFrequency = (id: number, options?: AxiosRequestConfig, reload = false) => {
        if (reload) {
            actions.selectItem(id, options);
        } else if (!frequency || frequency.dfrId !== id) {
            actions.selectItem(id, options);
        }
    };

    const unselectFrequency = () => {
        actions.unselectItem();
    };

    const reloadFrequency = (options?: AxiosRequestConfig) => {
        if (!frequency || !frequency.dfrId) {
            console.warn(
                'ReloadFrequency: No frequency is selected. This action requires a selected frequency.',
            );
            return;
        }
        unselectFrequency();
        selectFrequency(frequency.dfrId, options, true);
    };

    const safeMethods = {
        selectFrequency,
        unselectFrequency,
        reloadFrequency: (options?: AxiosRequestConfig) => {
            if (!frequency) {
                console.warn('ReloadFrequency: No frequency is selected. Action is ignored.');
                return;
            }
            reloadFrequency(options);
        },
    };

    return {
        frequency: frequency as DefFrequence | null,
        dataSource: frequencyDatasource,
        status,
        isLoading,
        isFailed,
        companyId,
        actions: safeMethods,
    };
};
