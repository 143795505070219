import React, { ReactNode, useMemo } from 'react';

import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { Box, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import {
    contactSupport,
    genericError,
    retryLater,
} from '@europrocurement/l2d-utils/function-utils';

export type NoDataCardProps = {
    messageOverwrite?: ReactNode;
    additionalData?: ReactNode;
};

const NoDataCard: React.FunctionComponent<NoDataCardProps> = function ({
    messageOverwrite,
    additionalData,
}: NoDataCardProps) {
    const roleChecker = UseKeycloakCheckRole();
    const isTech = roleChecker('realm:debug');

    const commonSx: SxProps<Theme> = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const mainMessage = useMemo<ReactNode>(() => {
        if (messageOverwrite) {
            return messageOverwrite;
        }

        return (
            <>
                <Typography variant="h3">{genericError}</Typography>
                <Typography variant="subtitle2">
                    {`
                    ${retryLater} 
                    ${!isTech ? contactSupport : ''}
                `}
                </Typography>
            </>
        );
    }, [isTech, messageOverwrite]);

    const hoverStyle = useMemo<SxProps | undefined>(() => {
        if (!isTech || !additionalData) return undefined;

        return {
            '&:hover': {
                background: 'rgba(128, 128, 128, 0.1)',
                transform: 'scale(1.02)',
            },
        };
    }, [additionalData, isTech]);

    return (
        <Box
            sx={{
                ...commonSx,
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Tooltip title={isTech ? additionalData : undefined}>
                <Box
                    sx={{
                        ...commonSx,
                        ...hoverStyle,
                        padding: '1%',
                        justifyContent: 'space-between',
                        position: 'relative',
                        borderRadius: '8px',
                        transition: 'all 0.3s ease',
                        gap: 2,
                    }}
                >
                    <FaOptionIcon
                        icon={faExclamationTriangle}
                        sx={{ transition: 'color 0.3s ease' }}
                        size="2x"
                    />
                    <Box
                        sx={{
                            ...commonSx,
                            justifyContent: 'space-between',
                        }}
                    >
                        {mainMessage}
                    </Box>
                </Box>
            </Tooltip>
        </Box>
    );
};

export default NoDataCard;
