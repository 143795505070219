import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import DossierPrescriberContext from './DossierPrescriberContext';
import useDossierPrescripteur, { Tiers } from '../hooks/useDossierPrescripteur';

const DossierPrescriberProvider: React.FunctionComponent<PropsWithChildren> = function ({
    children,
}) {
    const { getPrescriber, getSousClients } = useDossierPrescripteur();
    const [prescriberId, setPrescriberId] = useState<number | undefined>();
    const [prescriber, setPrescriber] = useState<Tiers | undefined>();
    const [prescriberLoaded, setPrescriberLoaded] = useState<boolean>(false);
    const [sousClients, setSousClients] = useState<Tiers[]>([]);

    const loadPrescriber = async (newPrescriberId: number) => {
        setPrescriberLoaded(false);
        getPrescriber(newPrescriberId)
            .then((result) => {
                setPrescriberId(prescriberId);
                setPrescriber(result as unknown as Tiers);
            })
            .catch(() => {
                setPrescriberId(undefined);
                setPrescriber(undefined);
            })
            .finally(() => setPrescriberLoaded(true));

        getSousClients(newPrescriberId)
            .then((result) => {
                setSousClients(result);
            })
            .catch(() => {
                setSousClients([]);
            });
    };
    useEffect(() => {
        if (prescriberId) {
            loadPrescriber(prescriberId);
        } else {
            setPrescriber(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescriberId]);

    const contextValue = useMemo(
        () => ({
            prescriberId,
            setPrescriberId,
            prescriber,
            setPrescriber,
            sousClients,
            prescriberLoaded,
        }),
        [prescriberId, setPrescriberId, prescriber, setPrescriber, sousClients, prescriberLoaded],
    );

    return (
        <DossierPrescriberContext.Provider value={contextValue}>
            {children}
        </DossierPrescriberContext.Provider>
    );
};

export default DossierPrescriberProvider;
