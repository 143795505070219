import {
    DossierOpenSearch,
    useFormaliteService,
    useSociete,
    useTiersService,
} from '@europrocurement/l2d-domain';
import { ContactsContactJsonldContactCreate } from '@europrocurement/l2d-domain/openApi/ApiTiers';

export type ContactItem = {
    id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
};

export type ExportFormData = {
    recipients: Array<ContactItem>;
    message: string;
};

export type CompanyItem = {
    value: number;
    label: string;
};

export type ContactFormData = {
    gender: Array<number>;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    company: CompanyItem;
};

const useExportDossier = () => {
    const { contactsApi } = useTiersService();
    const { dossierApi } = useFormaliteService();
    const xIdSociete = useSociete();

    let createContactTimeout: number | undefined;
    let searchTimeout: number | undefined;

    const createContact = (contact: ContactFormData): Promise<ContactItem> =>
        new Promise((resolve, reject) => {
            clearTimeout(createContactTimeout);
            createContactTimeout = setTimeout(() => {
                const payload = {
                    civilite: contact.gender[0],
                    nomContact: contact.lastName,
                    prenomContact: contact.firstName,
                    telephonesContact: [{ telephone: contact.phoneNumber }],
                    emailsContact: [{ email: contact.emailAddress }],
                    principal: false,
                    comptabilite: false,
                };

                contactsApi
                    .createPrescripteurContactContactsCollection({
                        xIdSociete,
                        tiers: contact.company.value,
                        contactsContactJsonldContactCreate:
                            payload as ContactsContactJsonldContactCreate,
                    })
                    .then((res) => {
                        resolve({
                            id: res.data?.id as number,
                            firstName: res.data?.nomContact as string,
                            lastName: res.data?.prenomContact as string,
                            emailAddress: res.data?.emailsContact.find(
                                (emailAddress) => emailAddress?.defaut === true,
                            )?.email as string,
                        });
                    })
                    .catch((err) => reject(err));
            }, 300);
        });

    const searchContacts = (
        term: string,
        prescriberIds: Array<number>,
    ): Promise<Array<ContactItem>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
                contactsApi
                    .getContactsContactsCollection({ q: term, tiersId2: prescriberIds })
                    .then((res) => {
                        const items = res.data['hydra:member'] || [];

                        resolve(
                            items
                                .map((item) => ({
                                    id: item?.id as number,
                                    firstName: item?.nomContact as string,
                                    lastName: item?.prenomContact as string,
                                    emailAddress: item?.emailsContact.find(
                                        (emailAddress) => emailAddress?.defaut === true,
                                    )?.email as string,
                                }))
                                .filter(
                                    (item) => item.firstName && item.lastName && item.emailAddress,
                                ),
                        );
                    })
                    .catch((err) => reject(err));
            }, 300);
        });

    const submitExport = (
        formData: ExportFormData,
        dossiers: Array<DossierOpenSearch>,
    ): Promise<void> =>
        new Promise((resolve, reject) => {
            const requestParams = {
                dossiers: dossiers.map((dossier) => dossier?.id as number),
                contacts: formData.recipients.map((recipient) => recipient.id),
                message: formData.message,
            };
            dossierApi
                .apiDossiersReportPost({ dossierJsonld: requestParams })
                .then(() => resolve())
                .catch(() => reject());
        });

    return { createContact, searchContacts, submitExport };
};

export default useExportDossier;
