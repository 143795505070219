import React from 'react';
import {
    useDumbActionsContext,
    FlexyTabs,
    ContextActionType,
} from '@europrocurement/flexy-components';

import { usePublisherUtils } from '@b2d/hooks/publisherHooks';
import { Box } from '@mui/material';
import PurchaseInvoiceSettings from './tabs/purchaseInvoiceSettings/PurchaseInvoiceSettings';
import MainInformations from './tabs/mainInformations/MainInformations';

const PublisherInformationsCard: React.FunctionComponent = function () {
    const { publisher } = usePublisherUtils();

    // Component specific context actions

    const contextActions: ContextActionType[] = [];

    useDumbActionsContext(contextActions);

    if (!publisher.id) {
        return null;
    }

    const publisherTabs = [
        {
            tabName: 'information-general',
            tabTitle: 'Informations Général',
            tabPanel: <MainInformations />,
        },
        {
            tabName: 'parametrages-achats',
            tabTitle: 'Paramétrages Achats',
            tabPanel: <PurchaseInvoiceSettings />,
        },
    ];

    return (
        <Box
            sx={{
                overflow: 'hidden',
            }}
        >
            <FlexyTabs
                routerParameterName="prescripteurTab"
                sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
                tabs={publisherTabs}
            />
        </Box>
    );
};

export default PublisherInformationsCard;
