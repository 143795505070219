import React from 'react';
import { Box, Typography } from '@mui/material';

export type DossierPrescriberProps = {
    label: string;
};

const DossierPrescriber: React.FunctionComponent<DossierPrescriberProps> = function ({ label }) {
    return (
        <Box>
            <Typography
                variant="l2dtableCell"
                color="text.gris70"
            >
                {label}
            </Typography>
        </Box>
    );
};

export default DossierPrescriber;
