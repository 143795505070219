import { RootStateType } from '@b2d/redux/types';

import { invoiceStatementDataSourcesThunks, achatsConfiguration } from '@b2d/redux/RootStore';
import { ACHATS_REDUCER_NAME, Configuration } from '@europrocurement/l2d-domain';
import ICONS from '@b2d/icons';
import { INVOICE_STATEMENT_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/achats/slices/invoiceStatementSlice';
import {
    ReleveAchatApi,
    ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { ModelConfiguration, ModelItem, ModelMethods } from '@b2d/pages/Achats/models//types';

const modelKey = 'statement';
const modelName = 'InvoiceStatement';
const sliceName = INVOICE_STATEMENT_SLICE_NAME;
const apiConfiguration = new Configuration(achatsConfiguration);
const api = new ReleveAchatApi(apiConfiguration);
const baseSelector = (state: RootStateType) => state[ACHATS_REDUCER_NAME][sliceName];
const datasourcesThunks = invoiceStatementDataSourcesThunks;

type ModelStructure =
    ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup &
        Record<string, unknown>;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'InvoiceStatement',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: ICONS.models[modelKey],
    optionIcon: ICONS.options[modelKey],
    color: 'orange',
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.id}`,
    getItemSecondaryLabel: (model: ModelStructure) => `${model.updatedAt} (${model.pdfFacture})`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.getRelevesAchatsReleveAchatCollection>) =>
        api.getRelevesAchatsReleveAchatCollection(...args),
    patch: (...args: Parameters<typeof api.patchReleveAchatReleveAchatItem>) =>
        api.patchReleveAchatReleveAchatItem(...args),
    read: (...args: Parameters<typeof api.getReleveAchatReleveAchatItem>) =>
        api.getReleveAchatReleveAchatItem(...args),
    sum: (...args: Parameters<typeof api.getReleveAchatSumReleveAchatItem>) =>
        api.getReleveAchatSumReleveAchatItem(...args),
    update: (...args: Parameters<typeof api.updateReleveAchatReleveAchatItem>) =>
        api.updateReleveAchatReleveAchatItem(...args),
    create: (...args: Parameters<typeof api.createReleveAchatReleveAchatCollection>) =>
        api.createReleveAchatReleveAchatCollection(...args),
};
type Endpoints = typeof endpoints;

const model: ModelItem<ModelStructure> & Endpoints = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export default model;
