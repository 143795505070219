import { ObjectGroupColor, ObjectGroupType } from '@europrocurement/flexy-components';
import { useFormaliteService } from '@europrocurement/l2d-domain';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const useDossierGroup = () => {
    const { dossierObjectGroupApi } = useFormaliteService();

    const getDossierGroups = (searchTerm: string): Promise<Array<ObjectGroupType>> =>
        new Promise((resolve, reject) => {
            const requestParameter = { label: searchTerm };

            dossierObjectGroupApi
                .apiObjectGroupsGetCollection(requestParameter)
                .then((res) => {
                    const rawData = res.data['hydra:member'] ?? [];

                    resolve(
                        rawData.map((item) => ({
                            id: item.id as number,
                            type: item.type as string,
                            label: item.label as string,
                            color: item.color as ObjectGroupColor,
                            icone: item.icon as IconName,
                        })),
                    );
                })
                .catch(() => reject());
        });

    const createDossierGroup = (newObjectGroup: ObjectGroupType): Promise<ObjectGroupType> =>
        new Promise((resolve, reject) => {
            const requestParameter = {
                objectGroupsJsonldObjectGroupCreate: {
                    label: newObjectGroup.label,
                    color: newObjectGroup.color,
                    icon: 'folders',
                },
            };

            dossierObjectGroupApi
                .apiObjectGroupsPost(requestParameter)
                .then((res) => {
                    resolve({
                        id: res.data?.id as number,
                        type: res.data?.type as string,
                        label: res.data?.label as string,
                        color: res.data?.color as ObjectGroupColor,
                        icone: res.data?.icon as IconName,
                    });
                })
                .catch(() => reject());
        });

    return { getDossierGroups, createDossierGroup };
};

export default useDossierGroup;
