import { ObjectGroup, useTiersService } from '@europrocurement/l2d-domain';
import {
    ObjectGroupApiCreateObjectGroupObjectGroupCollectionRequest,
    ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollectionRequest,
    ObjectGroupJsonldObjectgroupCreate,
    ObjectGroupJsonldObjectgroupRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import { useSelector } from 'react-redux';

export type TiersObjectGroup = ObjectGroupJsonldObjectgroupRead & Record<string, unknown>;

const useSocietesObjectGroup = () => {
    const { objectGroupApi } = useTiersService();
    let searchObjectGroupTimeout: number | undefined;
    const { xIdSociete } = useSelector(customizerSelector);

    const searchTiersObjectGroup = (
        type: string,
    ): Promise<Array<ObjectGroupJsonldObjectgroupRead>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchObjectGroupTimeout);
            let requestParameters: ObjectGroupApiSearchObjectGroupByTypeObjectGroupCollectionRequest =
                { type: 'sousClient' };
            if (xIdSociete !== null) {
                requestParameters = { xIdSociete, ...requestParameters };
            }

            searchObjectGroupTimeout = setTimeout(() => {
                objectGroupApi
                    .searchObjectGroupByTypeObjectGroupCollection(requestParameters)
                    .then((res) =>
                        resolve(
                            res.data['hydra:member'].filter(
                                (item) =>
                                    item.label !== undefined &&
                                    (item.label
                                        .toLowerCase()
                                        .includes(
                                            type.toLowerCase(),
                                        ) as unknown as Array<TiersObjectGroup>),
                            ),
                        ),
                    )
                    .catch((err) => {
                        reject(err);
                    });
            }, 300);
        });

    return { searchTiersObjectGroup };
};

const useAddSocietesObjectGroup = () => {
    const { objectGroupApi } = useTiersService();
    let addObjectGroupTimeout: number | undefined;
    const { xIdSociete } = useSelector(customizerSelector);

    const addObjectGroup = (
        label: string,
        color: string,
        idTiers: string | number,
    ): Promise<Array<ObjectGroupJsonldObjectgroupCreate>> =>
        new Promise((resolve, reject) => {
            clearTimeout(addObjectGroupTimeout);
            let requestParameters: ObjectGroupApiCreateObjectGroupObjectGroupCollectionRequest = {
                objectGroupJsonldObjectgroupCreate: {
                    type: 'sousClient',
                    label,
                    color,
                    tiers: [`/tiers/tiers/${idTiers}`],
                },
            };
            if (xIdSociete !== null) {
                requestParameters = { xIdSociete, ...requestParameters };
            }

            addObjectGroupTimeout = setTimeout(() => {
                objectGroupApi
                    .createObjectGroupObjectGroupCollection(requestParameters)
                    .then((res) => {
                        resolve(res.data as Array<ObjectGroup>);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }, 300);
        });

    return { addObjectGroup };
};

export { useSocietesObjectGroup, useAddSocietesObjectGroup };
