import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

export type BillingAddressCardProps = {
    label: string;
    siren: string;
    address: string;
};

const BillingAddressCard: React.FunctionComponent<BillingAddressCardProps> = function (props) {
    const { label, siren, address } = props;
    const { palette } = useTheme();

    const wrapperSx = {
        display: 'flex',
        gap: '0 8px',
        flexWrap: 'wrap',
    };

    const addressLabelSx = {
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '24px',
    };

    const addressSirenSx = {
        ...addressLabelSx,
        fontWeight: '500',
    };

    const addressSx = {
        width: '100%',
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '15.6px',
        color: palette.grey[500],
    };

    return (
        <Box sx={wrapperSx}>
            {label && <Typography sx={addressLabelSx}>{label}</Typography>}
            {siren && <Typography sx={addressSirenSx}>Siren n°{siren}</Typography>}
            {address && <Typography sx={addressSx}>{address}</Typography>}
        </Box>
    );
};

export default BillingAddressCard;
