/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';

import _ from 'lodash';
import { FormHelperText } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FlexyInput, FlexyInputProps, FlexySelectProps } from '@europrocurement/flexy-components';

export type FormInputInformations = {
    base: string;
    name: string;
    path: string;
    objectPath: string;
};

export type FormFieldChangeCallback = (
    e: React.SyntheticEvent,
    fieldContext: FormInputInformations,
    formContext: any,
) => void;

export type ControlledSelectProps = {
    name: string;
    rules: RegisterOptions;
    required?: boolean;
    options: FlexySelectProps['options'] | Promise<FlexySelectProps['options']>;
    onChangeInput?: FormFieldChangeCallback;
    extandedFormContext?: any;
} & FlexyInputProps<FlexySelectProps>;

/**
 * A controlled select component that integrates with react-hook-form
 * and supports asynchronous options loading and form field mutation.
 *
 * The onChangeInput callback allows for modifying any form field value
 * after the select value changes, with access to the form context and field information.
 * This enables dynamic field updates based on select field changes.
 */
const ControlledSelect: React.FunctionComponent<ControlledSelectProps> = function ({
    name,
    rules,
    options,
    onChangeInput,
    extandedFormContext,
    ...inputProps
}) {
    const { control } = useFormContext();

    // Extract base path and field name from the full name
    const basePath = name.substring(0, name.lastIndexOf('.') + 1) || '';
    const fieldName = name.substring(name.lastIndexOf('.') + 1);

    const [asyncItems, setAsyncItems] = React.useState<FlexySelectProps['options']>([]);
    const [loadingText, setLoadingText] = React.useState<string>('Chargement ...');

    if (Array.isArray(options)) {
        if (!_.isEqual(asyncItems, options)) {
            setAsyncItems(options);
        }
    } else {
        options.then((items) => {
            setAsyncItems(items);
            setLoadingText('Aucun resultat');
        });
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                // formState,
            }) => (
                <>
                    <FlexyInput
                        {...inputProps}
                        name={name}
                        onChange={(e: React.SyntheticEvent, val: any) => {
                            onChange(e, val);

                            if (onChangeInput) {
                                const fieldContext: FormInputInformations = {
                                    base: basePath,
                                    name: fieldName,
                                    path: name,
                                    objectPath: basePath.replace(/\.$/, ''),
                                };

                                onChangeInput(e, fieldContext, extandedFormContext);
                            }
                        }}
                        value={value}
                        // defaultValue={value}
                        options={asyncItems}
                        noOptionsMessage={() => loadingText}
                    />
                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledSelect;
