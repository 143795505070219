import { NamedIconsType } from '@europrocurement/l2d-icons/types';
import {
    faAdd,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faEdit,
    faEye,
    faSearch,
    faUser,
    faUserSlash,
} from '@fortawesome/pro-duotone-svg-icons';
import { faClipboard, faClipboardCheck } from '@fortawesome/pro-regular-svg-icons';

export const createIcon: NamedIconsType = {
    props: { icon: faAdd },
    name: 'create',
    displayName: 'create',
};

export const updateIcon: NamedIconsType = {
    props: { icon: faEdit },
    name: 'update',
    displayName: 'update',
};

export const searchIcon: NamedIconsType = {
    props: { icon: faSearch },
    name: 'search',
    displayName: 'search',
};

export const clearIcon: NamedIconsType = {
    props: { icon: faUser },
    name: 'clear',
    displayName: 'clear',
};

export const removeUserIcon: NamedIconsType = {
    props: { icon: faUserSlash },
    name: 'removeUser',
    displayName: 'removeUser',
};

export const refreshIcon: NamedIconsType = {
    props: { icon: faUser },
    name: 'refresh',
    displayName: 'refresh',
};

export const previewIcon: NamedIconsType = {
    props: {
        icon: faEye,
    },
    name: 'preview',
    displayName: 'preview',
};

export const downIcon: NamedIconsType = {
    props: { icon: faChevronDown },
    name: 'down',
    displayName: 'down',
};

export const upIcon: NamedIconsType = {
    props: { icon: faChevronUp },
    name: 'up',
    displayName: 'up',
};

export const rightIcon: NamedIconsType = {
    props: { icon: faChevronRight },
    name: 'right',
    displayName: 'right',
};

export const leftIcon: NamedIconsType = {
    props: { icon: faChevronLeft },
    name: 'left',
    displayName: 'left',
};

export const copyIcon: NamedIconsType = {
    props: { icon: faClipboard },
    name: 'copy',
    displayName: 'copy',
};

export const copiedIcon: NamedIconsType = {
    props: { icon: faClipboardCheck },
    name: 'copied',
    displayName: 'copied',
};

export const userActionIcons: NamedIconsType[] = [
    createIcon,
    updateIcon,
    searchIcon,
    clearIcon,
    refreshIcon,
    previewIcon,
    downIcon,
    upIcon,
    rightIcon,
    leftIcon,
    copyIcon,
    copiedIcon,
    removeUserIcon,
];
