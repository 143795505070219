import { combineReducers } from '@reduxjs/toolkit';

import {
    // Reducers statiques :
    RoutingReducer,

    // Tiers :
    ConfigurationParameters,
    createTiersReducer,
    Configuration,
    TIERS_REDUCER_NAME,
    PAYS_SLICE_NAME,
    WALLET_OPTIONS_SLICE_NAME,
    CIVILITIES_SLICE_NAME,
    CSP_OPTIONS_SLICE_NAME,

    // Mail :
    ConfigurationParameters as MailConfigurationParameters,

    // Achats :
    createMediaobjectReducer,
    createFactureAchatReducer,
    createCategorieStatsReducer,
    createCodeRejetStatsReducer,
    createReminderMailReducer,
    WorkflowMailApi,
    MediaObjectApi,
    FactureAchatApi,
    FactureAchatNoteApi,
    ACHATS_REDUCER_NAME,
    CATEGORIE_STATS_SLICE_NAME,
    CODE_REJET_STATS_SLICE_NAME,
    FACTURES_ACHATS_SLICE_NAME,
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
    MEDIAOBJECT_SLICE_NAME,

    // Dossiers :
    createRubriqueFacturationReducer,
    createTauxTvaReducer,
    createJournalReducer,
    createDossierReducer,
    createFactureVenteReducer,
    createCommandeReducer,
    DOSSIERS_REDUCER_NAME,
    COMMANDES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    FACTURE_VENTE_SLICE_NAME,
    JOURNAL_SLICE_NAME,
    RUBFACT_SLICE_NAME,
    TXTVA_SLICE_NAME,
    APIENV,
    FACTURES_ACHATS_PAUSE_DATASOURCE_NAME,
    FactureAchatLigneApi,
    DossierApi,

    // Formalités :
    APIS_ENUM,

    // Others ...
    createReleveAchatReducer,
    FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME,
    SIGNATURE_REDUCER_NAME,
    MailApi,
    getCustomiserState,
    createCodeRejetReducer,
    createExceptionFacturanteReducer,
    createDefFrequenceReducer,
    getApiConfiguration,
    UtilisateurApi,
} from '@europrocurement/l2d-domain';

import { CUSTOMIZER_REDUCER_NAME, CustomizerReducer } from '@europrocurement/l2d-modules';

import {
    CategorieSocioProfessionnelleJsonldTiersRead,
    PortefeuilleJsonldTiersRead,
    TblParametreReferencesCiviliteJsonldCiviliteReadCollection,
    TblPaysJsonldPaysRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { TauxTvaApi } from '@europrocurement/l2d-domain/openApi/ApiDossiers';

import { INVOICE_STATEMENT_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/achats/slices/invoiceStatementSlice';

import {
    CodeRejetApi,
    DefFrequenceApi,
    ExceptionFacturanteApi,
    MediaObjectNoteApi,
    ReleveAchatApi,
    ReminderMailApi,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    ViewFunctionMap,
    ViewName,
} from '@b2d/pages/Achats/views/lists/UnregisteredInvoicesViews/hooks/types';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import {
    CODES_REJETS_FOURNISSEUR_DATASOURCE_NAME,
    CODES_REJETS_SLICE_NAME,
} from '@europrocurement/l2d-domain/reducers/achats/slices/codeRejetSlice';
import { EXCEPTIONS_FACTURANTES_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/achats/slices/exceptionFacturante';
import { DEF_FREQUENCE_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/achats/slices/defFrequenceSlice';
import { REMINDER_MAIL_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/achats/slices/reminderMailSlice';
import { FactureFormReducer } from './FactureFormReducer';

// Keycloak token
import { updateToken } from '../keycloak';

import { combinedDossierSignatureReducer } from './subReducers/DossierSignature/Reducer';

import { type RootStateType } from './types';

export { customizerSelector } from '@europrocurement/l2d-modules';

const configTiers: ConfigurationParameters = getApiConfiguration(APIS_ENUM.TIERS, updateToken);
const configAchats: ConfigurationParameters = getApiConfiguration(APIS_ENUM.ACHATS, updateToken);
const configDossiers: ConfigurationParameters = getApiConfiguration(
    APIS_ENUM.DOSSIERS,
    updateToken,
);
const configUtilisateurs: ConfigurationParameters = getApiConfiguration(
    APIS_ENUM.UTILISATEUR,
    updateToken,
);
const configFacturation: ConfigurationParameters = getApiConfiguration(
    APIS_ENUM.FACTURATION,
    updateToken,
);

export const utilisateurApi = new UtilisateurApi(new Configuration(configUtilisateurs));

export const defFrequenceApi = new DefFrequenceApi(new Configuration(configAchats));
export const exceptionFacturanteApi = new ExceptionFacturanteApi(new Configuration(configAchats));
export const reminderMailApi = new ReminderMailApi(new Configuration(configAchats));
export const rejectCodesApi = new CodeRejetApi(new Configuration(configAchats));

export const {
    prescripteurSlice,
    prescripteurDataSourcesThunks,
    sousClientSlice,
    sousClientDataSourcesThunks,
    fournisseurSlice,
    fournisseurDataSourcesThunks,
    tiersFournisseurSlice,
    tiersFournisseurDataSourcesThunks,
    paysSlice,
    cspOptionsSlice,
    walletOptionsSlice,
    paysDataSourcesThunks,
    civilitiesSlice,
    walletOptionsDataSourcesThunks,
    cspOptionsDataSourcesThunks,
    civilitiesDataSourcesThunks,
} = createTiersReducer(configTiers);

/**
 * Mail
 */
const configMail: MailConfigurationParameters = {
    basePath: APIENV.apis.mail[getCustomiserState().api.mail],
    accessToken: updateToken,
};

export const mailConfiguration = new Configuration(configMail);

/**
 * Achats
 */
export const achatsConfiguration = new Configuration(configAchats);

export const mailApi = new MailApi(mailConfiguration);
export const workflowMailApi = new WorkflowMailApi(achatsConfiguration);
export const mediaObjectApi = new MediaObjectApi(achatsConfiguration);
export const mediaObjectNoteApi = new MediaObjectNoteApi(achatsConfiguration);
export const statementApi = new ReleveAchatApi(achatsConfiguration);
export const factureAchatApi = new FactureAchatApi(achatsConfiguration);
export const factureAchatLigneApi = new FactureAchatLigneApi(achatsConfiguration);
export const factureAchatNoteApi = new FactureAchatNoteApi(achatsConfiguration);

export const { categorieStatsSlice, categorieStatsDataSourcesThunks } =
    createCategorieStatsReducer(configAchats);

export const { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks } =
    createCodeRejetStatsReducer(configAchats);

export const { defFrequenceSlice, defFrequenceDataSourcesThunks } =
    createDefFrequenceReducer(configAchats);

export const { exceptionFacturanteSlice, exceptionFacturanteDataSourcesThunks } =
    createExceptionFacturanteReducer(configAchats);

export const { codeRejetSlice, codeRejetDataSourcesThunks } = createCodeRejetReducer(configAchats);

export const { reminderMailSlice, reminderMailDataSourcesThunks } =
    createReminderMailReducer(configAchats);

export const { invoiceStatementSlice, invoiceStatementDataSourcesThunks } =
    createReleveAchatReducer(configAchats);

export const { factureAchatSlice, factureAchatDataSourcesThunks } =
    createFactureAchatReducer(configAchats);

export const { mediaobjectSlice, mediaobjectDataSourcesThunks } =
    createMediaobjectReducer(configAchats);

/**
 * Dossiers
 */

export const dossiersConfiguration = new Configuration(configDossiers);

export const dossierApi = new DossierApi(dossiersConfiguration);
export const tauxTvaApi = new TauxTvaApi(dossiersConfiguration);

/*
 * Formalités
 */

export const { commandeSlice, commandeDataSourcesThunks } = createCommandeReducer(configDossiers);

export const { dossierSlice, dossierDataSourcesThunks } = createDossierReducer(configDossiers);

export const { factureVenteSlice, factureVenteDataSourcesThunks } =
    createFactureVenteReducer(configDossiers);

export const { journalSlice, journalDataSourcesThunks } = createJournalReducer(configDossiers);

export const { rubriqueFacturationSlice, rubriqueFacturationDataSourcesThunks } =
    createRubriqueFacturationReducer(configDossiers);

export const { tauxTvaSlice, tauxTvaDataSourcesThunks } = createTauxTvaReducer(configDossiers);

// Generate Redux Store Structure
export const standaloneReducerBase = {
    [CUSTOMIZER_REDUCER_NAME]: CustomizerReducer,
    factureForm: FactureFormReducer,
    [ACHATS_REDUCER_NAME]: combineReducers({
        [CATEGORIE_STATS_SLICE_NAME]: categorieStatsSlice.reducer,
        [CODE_REJET_STATS_SLICE_NAME]: codeRejetStatsSlice.reducer,
        [CODES_REJETS_SLICE_NAME]: codeRejetSlice.reducer,
        [REMINDER_MAIL_SLICE_NAME]: reminderMailSlice.reducer,
        [EXCEPTIONS_FACTURANTES_SLICE_NAME]: exceptionFacturanteSlice.reducer,
        [DEF_FREQUENCE_SLICE_NAME]: defFrequenceSlice.reducer,
        [INVOICE_STATEMENT_SLICE_NAME]: invoiceStatementSlice.reducer,
        [FACTURES_ACHATS_SLICE_NAME]: factureAchatSlice.reducer,
        [MEDIAOBJECT_SLICE_NAME]: mediaobjectSlice.reducer,
    }),
    [DOSSIERS_REDUCER_NAME]: combineReducers({
        [COMMANDES_SLICE_NAME]: commandeSlice.reducer,
        [DOSSIERS_SLICE_NAME]: dossierSlice.reducer,
        [FACTURE_VENTE_SLICE_NAME]: factureVenteSlice.reducer,
        [JOURNAL_SLICE_NAME]: journalSlice.reducer,
        [RUBFACT_SLICE_NAME]: rubriqueFacturationSlice.reducer,
        [TXTVA_SLICE_NAME]: tauxTvaSlice.reducer,
    }),
    [SIGNATURE_REDUCER_NAME]: combinedDossierSignatureReducer,
    routing: RoutingReducer,
};

/**
 * FactureForm
 */
export const FactureFormSelector = (s: RootStateType) => s.factureForm;

export const RoutingSelector = (s: RootStateType) => s.routing.routing;

/**
 * Tiers
 */

type OptionsStore = (store: RootStateType) => {
    countries: TblPaysJsonldPaysRead[];
    cspOptions: CategorieSocioProfessionnelleJsonldTiersRead[];
    walletOptions: PortefeuilleJsonldTiersRead[];
    civilities: TblParametreReferencesCiviliteJsonldCiviliteReadCollection[];
};

export const OptionsSelector: OptionsStore = (store: RootStateType) => ({
    countries: store[TIERS_REDUCER_NAME][PAYS_SLICE_NAME].main.data,
    cspOptions: store[TIERS_REDUCER_NAME][CSP_OPTIONS_SLICE_NAME].main.data,
    walletOptions: store[TIERS_REDUCER_NAME][WALLET_OPTIONS_SLICE_NAME].main.data,
    civilities: store[TIERS_REDUCER_NAME][CIVILITIES_SLICE_NAME].main.data,
});
export const OptionsLoaded = (store: RootStateType) => {
    type OptionSlice =
        | typeof PAYS_SLICE_NAME
        | typeof CSP_OPTIONS_SLICE_NAME
        | typeof WALLET_OPTIONS_SLICE_NAME
        | typeof CIVILITIES_SLICE_NAME;

    const optionSlicesNames = [
        PAYS_SLICE_NAME,
        CSP_OPTIONS_SLICE_NAME,
        WALLET_OPTIONS_SLICE_NAME,
        CIVILITIES_SLICE_NAME,
    ];

    // eslint-disable-next-line consistent-return
    optionSlicesNames.forEach((sliceName: OptionSlice) => {
        const sliceStatus = store[TIERS_REDUCER_NAME][sliceName].main.status;
        if (sliceStatus !== 'succeeded') {
            return false;
        }
    });

    return true;
};

export const getFromSpecifiedDataSourceThunks = <T, A extends string>(
    dataSourcesThunks: DataSourcesThunksType<T>,
    dataSourceName: A,
) => dataSourcesThunks[dataSourceName].getData;

/**
 * !! Currently not generic because ViewFunctionMap and ViewName are strongly associated to MediaObject !!
 */
export const getSpecifiedDataSourceByViewName = <T, A extends ViewName>(
    dataSourcesThunks: DataSourcesThunksType<T>,
    dataSourceNameFromView: A,
) =>
    getFromSpecifiedDataSourceThunks(
        dataSourcesThunks,
        `${ViewFunctionMap[dataSourceNameFromView]}`,
    );

/**
 * Achats
 */
export const CategorieStatsSelector = (s: RootStateType) => s.achats.categoriestats;
export const getCategorieStats = categorieStatsDataSourcesThunks.main.getData;
export const selectCategorieStats = categorieStatsDataSourcesThunks.main.getSelected;

export const CodeRejetStatsSelector = (s: RootStateType) => s.achats.coderejetstats;
export const getCodeRejetStats = codeRejetStatsDataSourcesThunks.main.getData;
export const selectCodeRejetStats = codeRejetStatsDataSourcesThunks.main.getSelected;

export const DefFrequenceSelector = (s: RootStateType) => s.achats[DEF_FREQUENCE_SLICE_NAME];
export const getDefFrequence = defFrequenceDataSourcesThunks.main.getData;
export const selectDefFrequence = defFrequenceDataSourcesThunks.main.getSelected;

export const ExceptionFacturanteSelector = (s: RootStateType) => s.achats.exceptionFacturante;
export const getExceptionFacturante = exceptionFacturanteDataSourcesThunks.main.getData;
export const selectExceptionFacturante = exceptionFacturanteDataSourcesThunks.main.getSelected;

export const ReminderMailSelector = (s: RootStateType) => s.achats.coderejet;
export const getReminderMail = reminderMailDataSourcesThunks.main.getData;
export const selectReminderMail = reminderMailDataSourcesThunks.main.getSelected;

export const CodeRejetSelector = (s: RootStateType) => s.achats.coderejet;
export const getCodeRejet = codeRejetDataSourcesThunks.main.getData;
export const selectCodeRejet = codeRejetDataSourcesThunks.main.getSelected;

export const getCodeRejetFournisseur =
    codeRejetDataSourcesThunks[CODES_REJETS_FOURNISSEUR_DATASOURCE_NAME].getData;
export const selectCodeRejetFournisseur =
    codeRejetDataSourcesThunks[CODES_REJETS_FOURNISSEUR_DATASOURCE_NAME].getSelected;

export const InvoiceStatementSelector = (s: RootStateType) => s.achats.statement;
export const getInvoiceStatement = invoiceStatementDataSourcesThunks.main.getData;
export const selectInvoiceStatement = invoiceStatementDataSourcesThunks.main.getSelected;

export const FactureachatSelector = (s: RootStateType) => s.achats.factureachat;
export const getFactureAchat = factureAchatDataSourcesThunks.main.getData;
export const selectFactureAchat = factureAchatDataSourcesThunks.main.getSelected;

export const getFactureAchatFromStatement =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME].getData;
export const selectFactureAchatFromStatement =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME].getSelected;

export const getFactureAchatPause =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_PAUSE_DATASOURCE_NAME].getData;
export const selectFactureAchatPause =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_STATS_DATASOURCE_NAME].getSelected;

export const getFactureAchatStats =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_STATS_DATASOURCE_NAME].getData;
export const selectFactureAchatStats =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_STATS_DATASOURCE_NAME].getSelected;

export const MediaObjectSelector = (s: RootStateType) => s.achats.mediaobject;

export const getMediaObjectByViewName = <T extends ViewName>(dataSourceName: T) =>
    getSpecifiedDataSourceByViewName(mediaobjectDataSourcesThunks, dataSourceName);

export const getMediaObject = mediaobjectDataSourcesThunks.main.getData;
export const selectMediaObject = mediaobjectDataSourcesThunks.main.getSelected;

/**
 * Dossiers
 */

export const CommandeSelector = (s: RootStateType) => s.dossiers.commande;
export const getCommande = commandeDataSourcesThunks.main.getData;
export const selectCommande = commandeDataSourcesThunks.main.getSelected;

export const DossierSelector = (s: RootStateType) => s.dossiers.dos;
export const getDossier = dossierDataSourcesThunks.main.getData;
export const selectDossier = dossierDataSourcesThunks.main.getSelected;
export const selectAutocompleteDossier = dossierDataSourcesThunks.autocompleteDossiers.getSelected;
export const getAutocompleteDossier = dossierDataSourcesThunks.autocompleteDossiers.getData;

export const FactureVenteSelector = (s: RootStateType) => s.dossiers.facturevente;
export const getFactureVente = factureVenteDataSourcesThunks.main.getData;
export const selectFactureVente = factureVenteDataSourcesThunks.main.getSelected;

export const JournalSelector = (s: RootStateType) => s.dossiers.jal;
export const getJournal = journalDataSourcesThunks.main.getData;
export const selectJournal = journalDataSourcesThunks.main.getSelected;

export const RubriqueFacturationSelector = (s: RootStateType) => s.dossiers.rubfac;
export const getRubFac = rubriqueFacturationDataSourcesThunks.main.getData;
export const selectRubFac = rubriqueFacturationDataSourcesThunks.main.getSelected;

export const TauxTvaSelector = (s: RootStateType) => s.dossiers.txtva;
export const getTauxTva = tauxTvaDataSourcesThunks.main.getData;
export const selectTauxTva = tauxTvaDataSourcesThunks.main.getSelected;

/**
 * Facturation
 */
export const facturationConfiguration = new Configuration(configFacturation);
