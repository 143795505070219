import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import { AppDispatch } from '@b2d/redux/types';

import {
    customizerSelector,
    factureAchatApi,
    getFactureAchatPause,
    mediaObjectApi,
} from '@b2d/redux/RootStore';
import { downloadFromUrl } from '@europrocurement/l2d-utils';

import generateB2DPath from '@b2d/utils/generateB2DPath';
import { FactureAchat } from '@europrocurement/l2d-domain';

import {
    FactureAchatApiDeleteFactureAchatFactureAchatItemRequest,
    MediaObjectApiPatchMediaObjectItemRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { removeFactureModalMessages } from '../../constants/wording/modals';
import ConfirmationModal from '../modals/ConfirmationModal';

export type FactureAchatsActionMenuType = {
    factureAchat: FactureAchat;
    onNavigate: (factureAchat: FactureAchat) => void;
    onSync: (factureAchat: FactureAchat) => void;
    afterDelete?: () => void;
};

const FactureAchatsActionMenu: React.FunctionComponent<FactureAchatsActionMenuType> = function ({
    factureAchat,
    onNavigate,
    onSync,
    afterDelete,
}) {
    const dispatch = useDispatch<AppDispatch>();
    const { request } = useApiRequest();

    const { xIdSociete } = useSelector(customizerSelector);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [modalRemoveFactureOpen, setModalRemoveFactureOpen] = useState<boolean>(false);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Action lors du clique du bouton de téléchargement du PDF.
     * Dispatch n'étant pas asynchrone, le reste de la logique
     * passe par un useEffect.
     */
    const onDownload = useCallback(async (factureToDo: FactureAchat) => {
        if (factureToDo.pdfFacture && factureToDo.pdfFacture.id) {
            const mediaObject = await mediaObjectApi.getMediaObjectItem({
                id: `${factureToDo.pdfFacture.id}`,
            });
            if (mediaObject.data && mediaObject.data.contentUrl) {
                downloadFromUrl(
                    mediaObject.data.contentUrl,
                    mediaObject.data.originalName
                        ? mediaObject.data.originalName
                        : `pdf-facture-${factureToDo.pdfFacture.id}.pdf`,
                );
            }
        }
    }, []);

    const onDelete = useCallback(
        async (factureAchatToDelete: FactureAchat, isMediaObjectDelete?: boolean) => {
            if (!factureAchatToDelete.id) return;

            let mediaObjectId: string | null = null;

            if (factureAchatToDelete.pdfFacture && factureAchatToDelete.pdfFacture.id) {
                mediaObjectId = factureAchatToDelete.pdfFacture.id?.toString();
            }

            const successCallback = () => {
                // Pour l'instant ne s'applique qu'à la liste des factures en pause.
                // Plus tard, si on ajoute d'autres listes ce dispatch devra peut-être changer.

                if (afterDelete) {
                    afterDelete();
                }

                dispatch(getFactureAchatPause({}));

                if (isMediaObjectDelete && mediaObjectId) {
                    const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                        id: mediaObjectId,
                        mediaObject: {
                            deleted: true,
                        },
                    };

                    request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                        withToaster: true,
                    });
                }
            };

            const requestParameters: FactureAchatApiDeleteFactureAchatFactureAchatItemRequest = {
                id: factureAchatToDelete.id.toString(),
                xIdSociete,
            };

            request(factureAchatApi.deleteFactureAchatFactureAchatItem(requestParameters), {
                withToaster: true,
                successCallback,
            });
        },
        [afterDelete, dispatch, request, xIdSociete],
    );

    const response = generateB2DPath('formfactureachat', { factureAchat });

    return (
        <Box
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <ConfirmationModal
                isModalOpen={modalRemoveFactureOpen}
                closeModal={() => {
                    setModalRemoveFactureOpen(false);
                }}
                messages={removeFactureModalMessages}
                actionOnValidation={() => {
                    onDelete(factureAchat, true);
                    setModalRemoveFactureOpen(false);
                }}
                actionOnCancellation={() => {
                    onDelete(factureAchat, false);
                    setModalRemoveFactureOpen(false);
                }}
            />
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <Button
                        onClick={() => {
                            onNavigate(factureAchat);
                        }}
                        style={{ textDecoration: 'none', padding: '0' }}
                        size="small"
                    >
                        <Typography sx={{ color: 'text.primary' }}>Consulter</Typography>
                    </Button>
                </MenuItem>
                {factureAchat.id ? (
                    <MenuItem
                        onClick={() => {
                            setModalRemoveFactureOpen(true);
                            handleClose();
                        }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>Supprimer</Typography>
                    </MenuItem>
                ) : (
                    <MenuItem>Erreur, facture manquante</MenuItem>
                )}
                {response.status === 'OK' ? (
                    <MenuItem onClick={handleClose}>
                        <Link
                            to={response.path}
                            target="_blank"
                            style={{ textDecoration: 'none' }}
                        >
                            <Typography sx={{ color: 'text.primary' }}>
                                Ouvrir dans un nouvel onglet
                            </Typography>
                        </Link>
                    </MenuItem>
                ) : (
                    <MenuItem>Erreur, facture manquante</MenuItem>
                )}
                {factureAchat.id ? (
                    <MenuItem
                        onClick={() => {
                            onDownload(factureAchat);
                            handleClose();
                        }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>Afficher PDF</Typography>
                    </MenuItem>
                ) : (
                    <MenuItem>Erreur, facture manquante</MenuItem>
                )}
                {factureAchat.id && factureAchat.cloture === true ? (
                    <MenuItem
                        onClick={() => {
                            onSync(factureAchat);
                            handleClose();
                        }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>Synchroniser avec G3</Typography>
                    </MenuItem>
                ) : null}
            </Menu>
        </Box>
    );
};

export default FactureAchatsActionMenu;
