import React, { useCallback, useMemo, useState } from 'react';

import {
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    DossierFormalite,
    DossierFormaliteApiObject,
    FactureAchatApiObject,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import { FaOptionIcon, folioIcon } from '@europrocurement/l2d-icons';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import { FlexyForm, FormObject } from '@europrocurement/flexy-form';
import { RootStateType } from '@b2d/redux/types';
import { SubmitHandler } from 'react-hook-form';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';

import { DataSource } from '@europrocurement/l2d-redux-utils';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import useLoadingStatus from '@b2d/hooks/useLoading';
import useSectionDataManager from '@b2d/hooks/useSectionDataManager';
import { ModeProps } from '../../formElements/types';
import { FactureFormObject, ToggableSection } from '../../types';
import HeaderStructure from '../../../fragments/HeaderStructure';
import SkeletonLoader from '../../../SkeletonLoader';
import { folioFormalitySectionStructure } from '../../formElements/fragments/folioFormalitySection';
import ViewFolioFormalitySection from '../../formElements/fragments/ViewFolioFormalitySection';

export type UpdateFolioFormalitySectionFormProps = {
    invoice: FactureAchatApiObject;
    lines: FactureAchatLigneJsonldFactureAchatRead[];
    folioFormality: DossierFormaliteApiObject;
    onFetchNewLines: () => void;
} & ToggableSection;

const UpdateFolioFormalitySectionForm: React.FunctionComponent<UpdateFolioFormalitySectionFormProps> =
    function ({ invoice, lines, folioFormality, onFetchNewLines, switchLockValues }) {
        const { getAutocompleteDossierFormalite } = useFormaliteService();

        const { getSwitchLockValues, stateSwitchLockValues } = switchLockValues;

        const [isLoading, setIsLoading] = useState<boolean>(false);

        const folioMode = useMemo<ModeProps>(
            () => ({ type: 'update', lock: stateSwitchLockValues.folio.value }),
            [stateSwitchLockValues.folio.value],
        );

        const foliosFormalityDatasource: DataSource<DossierFormalite> = useSelector(
            (s: RootStateType) =>
                s.formalites.dosform[DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME],
            _.isEqual,
        );

        const folioFormalityStructure = useMemo(
            () =>
                folioFormalitySectionStructure({
                    disabled: true,
                    mode: folioMode,
                    autocompleteProps: {
                        formality: {
                            dataSource: foliosFormalityDatasource,
                            fetchData: getAutocompleteDossierFormalite,
                            defaultValue: folioFormality?.numero?.toString() || undefined,
                        },
                    },
                    onSelectOption: (
                        event,
                        newValue: DossierFormalite,
                        reason,
                        details,
                        formContext,
                    ) => {
                        formContext.setValue(
                            'denomination_societe_formalite',
                            newValue?.societeDenomination?.toString() || undefined,
                        );
                    },
                    hideHeader: true,
                }),
            [
                folioFormality?.numero,
                folioMode,
                foliosFormalityDatasource,
                getAutocompleteDossierFormalite,
            ],
        );

        if (!invoice.id) {
            throw new Error(
                "La facture n'as pas d'id, si vous voyez ce message, Sylvain vous autorise a demander a Kevin de faire du piano. ",
            );
        }

        if (lines.length === 0) {
            throw new Error("La facture n'as pas de ligne. Aucun lien avec un dossier");
        }

        const { folio: folioSection } = useSectionDataManager();
        const { updateFolioFormality } = folioSection();

        const handleSubmit = useCallback<SubmitHandler<FormObject>>(
            async (values: Pick<FactureFormObject, 'numero_dossier_formalite'>) => {
                setIsLoading(true);

                updateFolioFormality({
                    invoice,
                    lines,
                    newFolio: values,
                    onFetchNewLines,
                    isAlreadySyncG3: invoice.syncG3,
                }).finally(() => {
                    stateSwitchLockValues.folio.set(true);

                    setIsLoading(false);
                });
            },
            [invoice, lines, onFetchNewLines, stateSwitchLockValues.folio, updateFolioFormality],
        );

        const formObject = useMemo<Partial<FactureFormObject>>(() => {
            if (!folioFormality.societeDenomination) {
                throw new Error("Le dossier de formalité n'a pas de dénomination de société.");
            }

            return {
                denomination_societe_formalite: folioFormality.societeDenomination || 'N/A',
            };
        }, [folioFormality.societeDenomination]);

        const readyToDisplay = useMemo<boolean>(
            () => !!(!isLoading && invoice.id && folioFormality.id),
            [isLoading, invoice.id, folioFormality.id],
        );

        const { loading } = useLoadingStatus({ checkReady: () => readyToDisplay });

        return (
            <SkeletonLoader
                isLoading={loading}
                type="FormSection"
            >
                <>
                    <FlexyHeaderForm
                        label={
                            <HeaderStructure
                                icon={
                                    <FaOptionIcon
                                        {...folioIcon.props}
                                        size="sm"
                                    />
                                }
                                title={`${folioIcon.displayName} Formalité`}
                            />
                        }
                        switches={[
                            ...(!invoice?.syncCegid
                                ? [getSwitchLockValues({ sectionToBlock: 'folio' })]
                                : []),
                        ]}
                        outlined
                    />
                    {stateSwitchLockValues.folio.value ? (
                        <ViewFolioFormalitySection
                            numero={folioFormality?.numero?.toString() || 'N/A'}
                            societeDenomination={folioFormality?.societeDenomination || 'N/A'}
                        />
                    ) : (
                        <FlexyForm
                            onSubmit={handleSubmit}
                            formObject={formObject}
                            formStructure={folioFormalityStructure}
                        />
                    )}
                </>
            </SkeletonLoader>
        );
    };

export default UpdateFolioFormalitySectionForm;
