import React, { useMemo } from 'react';

import { FiltersDatatableList } from '@europrocurement/flexy-datatable';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';

import useFetchFilteredDataUnregisteredInvoicesView from './hooks/useFetchFilteredDataUnregisteredInvoicesView';
import SkeletonLoader from '../../../components/SkeletonLoader';
import UnregisteredInvoicesList from '../../../components/lists/UnregisteredInvoicesList/UnregisteredInvoicesList';

const UnregisteredInvoicesView: React.FunctionComponent = function () {
    const additionalFilters: FiltersDatatableList = useMemo(() => [], []);

    const { status, dataSource } = useFetchFilteredDataUnregisteredInvoicesView({
        viewName: 'unregistered',
    });

    return (
        <>
            <LazyBreadcrumb
                path={[{ path: 'Portail achats', link: '/achats/liste' }, 'Factures à traiter']}
            />
            <SkeletonLoader
                isLoading={status.loading}
                type="List"
            >
                <UnregisteredInvoicesList
                    additionalFilters={additionalFilters}
                    dataSource={dataSource}
                />
            </SkeletonLoader>
        </>
    );
};

export default UnregisteredInvoicesView;
