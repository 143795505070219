import React, { MouseEvent } from 'react';
import { Box, Typography } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

export type DossierReferencesProps = {
    itemReference?: string;
    companyReference?: string;
    collectionReference?: string;
    onCollectionNumberClick?: (event: MouseEvent<HTMLSpanElement>) => void;
    canFilterOnCollection?: boolean;
    direction?: 'row' | 'column';
};

const DossierReferences: React.FunctionComponent<DossierReferencesProps> = function ({
    itemReference,
    companyReference,
    direction = 'row',
    collectionReference,
    onCollectionNumberClick,
    canFilterOnCollection,
}) {
    const wrapperSx = {
        display: 'flex',
        flexDirection: direction,
        gap: direction === 'row' ? '0 5px' : '0 5px',
    };

    return (
        <Box>
            <Box sx={wrapperSx}>
                {itemReference && (
                    <Typography
                        variant="l2dtableCell"
                        color="text.gris70"
                    >
                        {itemReference}
                    </Typography>
                )}
                {companyReference && (
                    <Typography
                        color="text.gris50"
                        variant="l2dtableCell"
                    >
                        {companyReference}
                    </Typography>
                )}
            </Box>
            <Box sx={wrapperSx}>
                {collectionReference && (
                    <Typography
                        variant="l2dtableCell"
                        color="text.gris50"
                        fontSize="0.75rem"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0 4px',
                            cursor: canFilterOnCollection ? 'pointer' : 'auto',
                        }}
                        onClick={onCollectionNumberClick}
                    >
                        {canFilterOnCollection && (
                            <FaOptionIcon icon={['fad', 'link-horizontal']} />
                        )}
                        #{collectionReference}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default DossierReferences;
