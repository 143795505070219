import { useDispatch } from 'react-redux';
import { filterOptionsProps } from '@b2d/pages/Achats/components/forms/types';
import { AppDispatch } from '@b2d/redux/types';
import { Filter, Order, Pagination, Search, Selected } from '@europrocurement/l2d-redux-utils';

/**
 * useLazyDispatch
 *
 * A custom hook that provides a type-safe dispatch function for various targets within a Redux slice.
 * It helps developers quickly find the available targets, actions, and expected payloads, allowing for
 * efficient and safe dispatch of Redux actions.
 *
 * The available targets correspond to different state sections (Data, Filter, Order, etc.), and each target
 * has specific actions (set, reset, clear, delete) that can be dispatched, some of which require payloads.
 * The hook enforces type safety, ensuring the correct payload structure for each target-action combination.
 *
 * Possible actions are defined from SliceFactory.ts
 *
 * @param {filterOptionsProps} props - Contains the `sliceName` and `dataSourceName` used to build the action type.
 *
 * @returns {Function} - A `dispatchReset` function that dispatches actions for a specific target, action, and optional payload.
 *
 * Available targets:
 * - Data
 * - Filter
 * - Order
 * - Pagination
 * - Search
 * - Selected
 *
 * Available actions per target:
 * - Data: clear
 * - Filter: set, reset, delete
 * - Order: set, reset
 * - Pagination: set, reset
 * - Search: set, reset
 * - Selected: set, delete
 *
 * Example usage:
 *
 * ```typescript
 * const dispatchAction = useLazyDispatch({ sliceName: 'userSlice', dataSourceName: 'main' });
 *
 * // Dispatch a reset action for the Order target
 * dispatchAction({ target: 'Order', action: 'reset' })();
 *
 * // Dispatch a set action for the Filter target with a payload
 * dispatchAction({
 *   target: 'Filter',
 *   action: 'set',
 *   payload: { key: 'status', value: 'active' },
 * })();
 *
 * // Dispatch a delete action for the Selected target
 * dispatchAction({ target: 'Selected', action: 'delete', payload: { field: 'category' } })();
 * ```
 */
const useLazyDispatch = ({ sliceName, dataSourceName }: filterOptionsProps) => {
    const dispatch = useDispatch<AppDispatch>();

    // List of possible targets
    type TargetsList = ['Data', 'Filter', 'Order', 'Pagination', 'Search', 'Selected'];

    // Available actions for each target
    type ActionsMapping = {
        Data: ['clear'];
        Filter: ['set', 'reset', 'delete'];
        Order: ['set', 'reset'];
        Pagination: ['set', 'reset'];
        Search: ['set', 'reset'];
        Selected: ['set', 'delete'];
    };

    type Target = TargetsList[number];
    type ActionForTarget<T extends Target> = ActionsMapping[T][number]; // Valid actions for each target

    // Define the payload structure for the 'set' action, based on the target
    type TargetPayloadsForSet<T extends Target> = T extends 'Search'
        ? Search
        : T extends 'Order'
          ? Order
          : T extends 'Filter'
            ? Filter
            : T extends 'Pagination'
              ? Pagination
              : T extends 'Selected'
                ? Selected
                : never;

    // Define the expected payload for each action, based on the target and action
    type ActionPayloads<T extends Target, A extends ActionForTarget<T>> = A extends 'set'
        ? TargetPayloadsForSet<T>
        : A extends 'delete'
          ? { field: string }
          : undefined;

    // Props for the dispatchReset function, ensuring correct target, action, and payload
    type DispatchResetProps<T extends Target, A extends ActionForTarget<T>> = {
        target: T;
        action: A;
        payload?: ActionPayloads<T, A>; // Optional payload based on action and target
    };

    /**
     * lazyDispatch
     *
     * This function dispatches the specified action for the given target with an optional payload.
     * It automatically builds the correct action type string based on the slice and data source names,
     * and ensures the payload matches the expected structure.
     *
     * @template T - The target (e.g., Filter, Order)
     * @template A - The action (e.g., set, reset, delete)
     * @param {DispatchResetProps<T, A>} props - Contains the target, action, and optional payload.
     * @returns {Function} - A function to be invoked to dispatch the action.
     */
    const lazyDispatch = <T extends Target, A extends ActionForTarget<T>>({
        target,
        action,
        payload,
    }: DispatchResetProps<T, A>) => {
        const type = `${sliceName}/${action}${dataSourceName}${target}`;
        return () => {
            if (payload) {
                dispatch({
                    type,
                    payload,
                });
            } else {
                dispatch({ type });
            }
        };
    };

    return lazyDispatch;
};

export default useLazyDispatch;
