import React, { BaseSyntheticEvent, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack } from '@mui/material';
import {
    setXIdSociete,
    CustomizerReducerType,
    BDD_SWITCH_SOCIETES_IDS_ARRAY,
    type BDD_SWITCH_SOCIETES_IDS_TYPE,
    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID,
} from '@europrocurement/l2d-domain';
import { FlexyLayoutState } from '@europrocurement/flexy-components/redux/storeConfig/store';

const TogglesSociete: React.FunctionComponent = function () {
    const dispatch = useDispatch();

    const xIdSociete = useSelector((state: FlexyLayoutState) => state.customizer.xIdSociete);

    const handleChangeSociete = (e: BaseSyntheticEvent) => {
        dispatch(setXIdSociete(e.target.value as CustomizerReducerType['xIdSociete']));
    };

    const isSelected = useCallback(
        (selectedButtonValue: BDD_SWITCH_SOCIETES_IDS_TYPE) =>
            !!(`${selectedButtonValue}` === `${xIdSociete}`),
        [xIdSociete],
    );

    return (
        <Stack
            alignItems="center"
            display="flex"
            flexDirection="row"
            gap={1}
        >
            {BDD_SWITCH_SOCIETES_IDS_ARRAY.map((value) => (
                <Button
                    key={value}
                    onClick={(event) => {
                        handleChangeSociete(event);
                    }}
                    color="primary"
                    variant={isSelected(value) ? 'contained' : 'outlined'}
                    value={value}
                >
                    {BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[value]}
                </Button>
            ))}
        </Stack>
    );
};

TogglesSociete.defaultProps = {
    size: 'medium',
    color: 'error',
    tooltip: 'Supprimer',
    onClick: undefined,
    disabled: false,
};

export default TogglesSociete;
