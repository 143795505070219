import {
    SliceFactory,
    FetchCollectionData,
    ApiCollectionResponse,
    FetchItemDataType,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as DossierStepApiConfigurationParameters,
    Configuration,
    TapesDeTraitementDesDossiersStepsDossierRead,
    TapesDeTraitementDesDossiersApi,
    TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollectionRequest,
} from '../../../openApi/ApiFormalite';
import { DOSSIER_STEP_SLICE_NAME, FORMALITES_REDUCER_NAME } from '../constants';

export type DossierStepApiObject = TapesDeTraitementDesDossiersStepsDossierRead;

export type DossierStep = DossierStepApiObject;

export const mapperDossierStep = function (step: DossierStep) {
    return {
        ...step,
    } as DossierStep;
};

export function createDossierStepSlice(configuration: DossierStepApiConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new TapesDeTraitementDesDossiersApi(conf);

    const fetchDossierStepCollection: FetchCollectionData<DossierStep> = ({ pagination }) => {
        const options = undefined;
        const requestParameters: TapesDeTraitementDesDossiersApiApiEtapesDossiersGetCollectionRequest =
            {
                page: pagination.page + 1,
                itemsPerPage: 100,
            };

        return api
            .apiEtapesDossiersGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<DossierStep>>;
    };

    const fetchDossierStepItem: FetchItemDataType<DossierStep> = () =>
        new Promise(() => {
            console.error('fetchCivilitiesItem called but should not');
        });

    const { slice: dossierStepSlice, dataSourcesThunks: dossierStepDataSourcesThunks } =
        SliceFactory.createSlice<DossierStep, DossierStep>(
            DOSSIER_STEP_SLICE_NAME,
            FORMALITES_REDUCER_NAME,
            [],
            fetchDossierStepCollection,
            fetchDossierStepItem,
            mapperDossierStep,
            {},
        );

    return { dossierStepSlice, dossierStepDataSourcesThunks };
}
