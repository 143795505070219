import { Chip, ChipProps } from '@mui/material';
import React from 'react';

const STATUS_VALUES = ['pending', 'error', 'sent'] as const;
type StatusType = (typeof STATUS_VALUES)[number] | undefined | null;

export interface ChipStatusProps extends Omit<ChipProps, 'color'> {
    status: StatusType | string;
}
const ChipStatus: React.FunctionComponent<ChipStatusProps> = function ({
    status,
    ...props
}: ChipStatusProps) {
    if (status === undefined || status === null) {
        return null;
    }

    const statusConfig = {
        pending: {
            color: 'warning',
            label: 'En attente',
        },
        error: {
            color: 'error',
            label: 'Erreur',
        },
        sent: {
            color: 'success',
            label: 'Envoyé',
        },
    };

    const isValidStatus = (STATUS_VALUES as readonly string[]).includes(status);

    if (!isValidStatus) {
        return (
            <Chip
                size="small"
                variant="outlined"
                color="default"
                label={status}
                {...props}
            />
        );
    }

    const config = statusConfig[status as keyof typeof statusConfig];

    return (
        <Chip
            size="small"
            color={config.color as ChipProps['color']}
            label={config.label}
            {...props}
        />
    );
};

export default ChipStatus;
