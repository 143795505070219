import React from 'react';
import { InformationCard } from '@europrocurement/flexy-components';
import { useTheme } from '@mui/material';

export type DossierFormalitesProps = {
    formalities: Array<{ label: string }>;
};

const DossierFormalites: React.FunctionComponent<DossierFormalitesProps> = function (props) {
    const { formalities } = props;
    const { palette } = useTheme();
    const title = formalities.length > 1 ? 'Formalités' : 'Formalité';

    return (
        <InformationCard
            title={title}
            subtitle={<div />}
            items={formalities.map((formality, index) => ({
                label: formalities.length > 1 ? `Formalité n°${index + 1}` : 'Type de formalité',
                value: formality.label,
            }))}
            sx={{
                marginBottom: '20px',
                boxShadow: `2px 2px 6px 0px ${palette.grey[300]}`,
            }}
        />
    );
};

export default DossierFormalites;
