import * as React from 'react';
import { Box, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Controller } from 'react-hook-form';
import { FormArticle, PackageLineProps } from './types';
import SortableList from '../../components/SortableList/SortableList';
import LineActions from './LineActions';
import useListItemHoverEffect from './useHoverEffect';
import TextField from '../../components/form/TextField';
import LineWarnings from './LineWarnings';
import useOnHover from './useOnHover';

const SectionLine: React.FunctionComponent<PackageLineProps> = function ({
    index,
    article,
    context,
    warnings,
}) {
    const listHoverProps = useListItemHoverEffect();

    const [editionMode, setEditionMode] = React.useState(true);

    const inputReference = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (editionMode && inputReference.current) {
            inputReference.current.focus();
        }
    }, [editionMode]);
    const {
        setValue,
        getValues,
        formState: { errors },
        control,
    } = context;

    const onRemove = () => {
        const updatedList = getValues().articles as FormArticle[];
        setValue(
            'articles',
            updatedList.filter((formArticle) => formArticle.id !== article.id),
        );
    };

    const actions = [
        {
            icon: faTrash,
            onClick: onRemove,
            actionName: 'remove',
        },
    ];

    const { onHoverProps, onHover } = useOnHover();

    const buttonStyle: React.CSSProperties = {
        cursor: onHover ? 'pointer' : '',
    };

    const emptyLabel = article.label === (null || '');

    const labelEditor = (
        <Controller
            name={`articles.${index}.label`}
            control={control}
            render={({ field }) => (
                <Box // Trick to prevent empty typography label from FlexInput
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <TextField
                        sx={{ width: '100%', margin: 0 }}
                        {...field}
                        inputRef={inputReference}
                        name={field.name}
                        placeholder="Libellé de la section"
                        errors={errors}
                        required={false}
                        onBlur={() => {
                            setEditionMode(false);
                        }}
                    />
                </Box>
            )}
        />
    );
    const labelDisplay = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => setEditionMode(true)}
            {...onHoverProps}
            sx={{ margin: '10px 14px', ...buttonStyle, opacity: emptyLabel ? 0.5 : 1 }}
        >
            <Typography>{emptyLabel ? '( Aucun titre )' : article.label}</Typography>
        </Box>
    );
    const line = (
        <Box
            sx={{
                marginTop: '3px',
                opacity: '0.6',
                border: '1px solid',
                maxHeight: '1px',
                maxWidth: '85%',
            }}
        />
    );

    const sectionLineContent = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                display="flex"
                flexDirection="column"
            >
                <Typography sx={{ marginRight: '10px' }}>Section</Typography>
                {line}
            </Box>
            {editionMode ? labelEditor : labelDisplay}
        </Box>
    );

    const theme = useTheme();

    return (
        <>
            <Box sx={{ marginTop: '17px', marginBottom: 'auto' }}>
                <SortableList.DragHandle />
            </Box>

            <Box width="100%">
                <ListItem
                    sx={{
                        ...listHoverProps,
                        margin: '5px',
                        background: theme.palette.primary.light,
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <ListItemText primary={sectionLineContent} />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <LineWarnings
                                warnings={warnings}
                                lineIndex={index}
                            />
                            <LineActions
                                actions={actions}
                                lineIndex={index}
                            />
                        </Box>
                    </Box>
                </ListItem>
            </Box>
        </>
    );
};

export default SectionLine;
