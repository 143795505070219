import { MenuEntry } from '@europrocurement/flexy-components';

import { PublisherMenuEntries } from '@b2d/pages/Fournisseurs/PublisherRouter';
import { PrescripteurMenuEntries } from '../pages/Prescripteurs/PrescripteurRouter';
import { SignatureMenuEntries } from '../pages/Signature/SignatureRouter';
import { AchatsMenuEntries } from '../pages/Achats/AchatsRouter';
import { FormalityMenuEntries } from '../pages/Formalities/FormalityRouter';
import { UtilisateursMenuEntries } from '../pages/Utilisateurs/UtilisateursRouter';
import { DossierMenuEntries } from '../pages/Dossiers';

const menuEntries: MenuEntry[] = [
    {
        title: 'Dashboard',
        icon: 'house',
        href: '/accueil',
    },

    // Prescripteur
    ...PrescripteurMenuEntries,

    // Publisher
    ...PublisherMenuEntries,

    // Comptabilité
    ...AchatsMenuEntries,

    // Import signature menu entries
    ...SignatureMenuEntries,

    // Dossiers
    ...DossierMenuEntries,
    {
        title: 'Sociétés',
        icon: 'building-user',
        href: 'societes/',
    },

    // Administration
    {
        title: 'Administration',
        icon: 'gear',
        role: 'realm:interne',
        children: [
            {
                title: 'Gestion des offres marketing',
                icon: 'tag',
                href: '/admin/offres',
                role: 'realm:interne',
            },
            ...FormalityMenuEntries,
            ...UtilisateursMenuEntries,
        ],
    },
];

export default menuEntries;
