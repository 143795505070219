import { RubriqueFacturationApi } from '@europrocurement/l2d-domain/openApi/ApiDossiers';
import { combineReducers } from '@reduxjs/toolkit';
import {
    ActionDetailApi,
    ArticlesApi,
    CatalogueApi,
    ChampComplmentaireApi,
    DomainesApi,
    GroupementApi,
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration as OffreConfiguration,
    OffresApi,
    OptionsApi,
    OptionValueApi,
    OriginesApi,
    PackageApi,
    TypeDeDocumentApi,
    TypeTvaApi,
    RegleDeCalculApi,
} from '../../openApi/ApiOffre';
import { createArticlesSlice } from './slices/articleSlice';
import { createCatalogsSlice } from './slices/catalogSlice';
import { createDocumentsSlice } from './slices/documentSlice';
import { createDomainsSlice } from './slices/domainsSlice';
import { createOfferAdditionalFieldsSlice } from './slices/offerAdditionalFieldSlice';
import { createOfferOptionsSlice } from './slices/offerOptionSlice';
import { createOfferOptionValuesSlice } from './slices/offerOptionValueSlice';
import { createOffersSlice } from './slices/offerSlice';
import { createOfferGroupSlice } from './slices/offerGroupSlice';
import { createPackagesSlice } from './slices/packageSlice';
import { createVatsSlice } from './slices/vatsSlice';
import { createActionsSlice } from './slices/actionSlice';
import { createInvoicingCategorySlice } from './slices/invoicingCategorySlice';
import articleLibSlice from './slices/articleLibSlice';
import offerCacheSlice from './slices/offerCacheSlice';

import {
    OFFER_CACHED_KEY,
    REDUX_LIB_ARTICLE,
    ARTICLES_SLICE_NAME,
    CATALOGS_SLICE_NAME,
    DOCUMENT_SLICE_NAME,
    DOMAINS_SLICE_NAME,
    OFFER_ADDITIONAL_FIELDS_SLICE_NAME,
    OFFER_OPTIONS_SLICE_NAME,
    OFFER_OPTION_VALUES_SLICE_NAME,
    OFFERS_SLICE_NAME,
    OFFER_GROUP_SLICE_NAME,
    PACKAGES_SLICE_NAME,
    VATS_SLICE_NAME,
    OFFER_ACTIONS_SLICE_NAME,
    INVOICING_CATEGORIES_SLICE_NAME,
    REGLEDECALCULS_SLICE_NAME,
} from './constants';

import { buildActionModel } from './Models/action';
import { buildAdditionalFieldModel } from './Models/additionalField';
import { buildArticleModel } from './Models/article';
import { buildCatalogModel } from './Models/catalog';
import { buildDocumentModel } from './Models/document';
import { buildOffreModel } from './Models/offer';
import { buildOfferOptionModel } from './Models/offerOption';
import { buildOfferPackageModel } from './Models/offerPackage';
import { buildOfferOptionValueModel } from './Models/offerOptionValue';
import { buildOfferGroupModel } from './Models/offerGroup';
import { createRegleDeCalculsSlice } from './slices/regleDeCalculsSlice';

export { type OffreConfigurationParameters };

export function createOfferReducer(configuration: OffreConfigurationParameters) {
    return {
        ...createOffersSlice(configuration),
        ...createCatalogsSlice(configuration),
        ...createPackagesSlice(configuration),
        ...createArticlesSlice(configuration),
        ...createActionsSlice(configuration),
        ...createOfferOptionsSlice(configuration),
        ...createOfferOptionValuesSlice(configuration),
        ...createOfferAdditionalFieldsSlice(configuration),
        ...createDocumentsSlice(configuration),
        ...createDomainsSlice(configuration),
        ...createRegleDeCalculsSlice(configuration),
        ...createVatsSlice(configuration),
        ...createOfferGroupSlice(configuration),
        ...createActionsSlice(configuration),
        ...createInvoicingCategorySlice(configuration),
        ...createRegleDeCalculsSlice(configuration),
    };
}

export function buildOfferRedux(configuration: OffreConfigurationParameters) {
    const offerConfiguration = new OffreConfiguration(configuration);

    const offersApi = new OffresApi(offerConfiguration);
    const actionsApi = new ActionDetailApi(offerConfiguration);
    const catalogsApi = new CatalogueApi(offerConfiguration);
    const articlesApi = new ArticlesApi(offerConfiguration);
    const domaineApi = new DomainesApi(offerConfiguration);
    const additionalFieldApi = new ChampComplmentaireApi(offerConfiguration);
    const actionDetailApi = new ActionDetailApi(offerConfiguration);
    const packageApi = new PackageApi(offerConfiguration);
    const originApi = new OriginesApi(offerConfiguration);
    const offerOptionsApi = new OptionsApi(offerConfiguration);
    const offerOptionValuesApi = new OptionValueApi(offerConfiguration);
    const groupementApi = new GroupementApi(offerConfiguration);
    const documentsApi = new TypeDeDocumentApi(offerConfiguration);
    const vatApi = new TypeTvaApi(offerConfiguration);
    const domainApi = new DomainesApi(offerConfiguration);
    const invoicingCategoryApi = new RubriqueFacturationApi(offerConfiguration);
    const regleDeCalculApi = new RegleDeCalculApi(offerConfiguration);

    const {
        offersSlice,
        offersDataSourcesThunks,
        catalogsSlice,
        packagesSlice,
        articlesSlice,
        actionsSlice,
        offerOptionsSlice,
        offerOptionValuesSlice,
        offerGroupsSlice,
        offerAdditionalFieldsSlice,
        documentsSlice,
        vatsSlice,
        domainsSlice,
        invoicingCategorySlice,
        regleDeCalculsSlice,
        catalogsDataSourcesThunks,
        packagesDataSourcesThunks,
        actionsDataSourcesThunks,
        articlesDataSourcesThunks,
        offerOptionsDataSourcesThunks,
        offerAdditionalFieldsDataSourcesThunks,
        offerOptionValuesDataSourcesThunks,
        offerGroupsDataSourcesThunks,
        documentsDataSourcesThunks,
        vatsDataSourcesThunks,
        domainsDataSourcesThunks,
        invoicingCategoryDataSourcesThunks,
        regleDeCalculsDataSourcesThunks,
    } = createOfferReducer(offerConfiguration);

    const combinedOfferReducers = combineReducers({
        [OFFERS_SLICE_NAME]: offersSlice.reducer,
        [CATALOGS_SLICE_NAME]: catalogsSlice.reducer,
        [PACKAGES_SLICE_NAME]: packagesSlice.reducer,
        [VATS_SLICE_NAME]: vatsSlice.reducer,
        [ARTICLES_SLICE_NAME]: articlesSlice.reducer,
        [DOMAINS_SLICE_NAME]: domainsSlice.reducer,
        [OFFER_OPTIONS_SLICE_NAME]: offerOptionsSlice.reducer,
        [OFFER_OPTION_VALUES_SLICE_NAME]: offerOptionsSlice.reducer,
        [OFFER_ACTIONS_SLICE_NAME]: actionsSlice.reducer,
        [OFFER_ADDITIONAL_FIELDS_SLICE_NAME]: offerAdditionalFieldsSlice.reducer,
        [OFFER_GROUP_SLICE_NAME]: offerGroupsSlice.reducer,
        [DOCUMENT_SLICE_NAME]: documentsSlice.reducer,
        [INVOICING_CATEGORIES_SLICE_NAME]: invoicingCategorySlice.reducer,
        [REDUX_LIB_ARTICLE]: articleLibSlice.reducer,
        [OFFER_CACHED_KEY]: offerCacheSlice.reducer,
        [REGLEDECALCULS_SLICE_NAME]: regleDeCalculsSlice.reducer,
    });

    return {
        // [OFFRE] Configuration

        serviceConfigurationParameters: configuration,
        serviceConfiguration: offerConfiguration,
        reducer: combinedOfferReducers,

        apis: {
            offersApi,
            actionsApi,
            catalogsApi,
            articlesApi,
            domaineApi,
            additionalFieldApi,
            actionDetailApi,
            packageApi,
            originApi,
            offerOptionsApi,
            offerOptionValuesApi,
            groupementApi,
            documentsApi,
            vatApi,
            domainApi,
            invoicingCategoryApi,
            regleDeCalculApi,
        },

        slices: {
            offersSlice,
            catalogsSlice,
            packagesSlice,
            articlesSlice,
            actionsSlice,
            offerOptionsSlice,
            offerOptionValuesSlice,
            offerGroupsSlice,
            offerAdditionalFieldsSlice,
            documentsSlice,
            vatsSlice,
            domainsSlice,
            invoicingCategorySlice,
            regleDeCalculsSlice,
        },
        thunks: {
            offersDataSourcesThunks,
            catalogsDataSourcesThunks,
            packagesDataSourcesThunks,
            actionsDataSourcesThunks,
            articlesDataSourcesThunks,
            offerOptionsDataSourcesThunks,
            offerAdditionalFieldsDataSourcesThunks,
            offerOptionValuesDataSourcesThunks,
            offerGroupsDataSourcesThunks,
            documentsDataSourcesThunks,
            vatsDataSourcesThunks,
            domainsDataSourcesThunks,
            invoicingCategoryDataSourcesThunks,
            regleDeCalculsDataSourcesThunks,
        },
        fetchItems: {
            getOffers: offersDataSourcesThunks.main.getData,
            getCatalogs: catalogsDataSourcesThunks.main.getData,
            getPackages: packagesDataSourcesThunks.main.getData,
            getArticles: articlesDataSourcesThunks.main.getData,
            getOfferOptions: offerOptionsDataSourcesThunks.main.getData,
            getOfferAdditionalFields: offerAdditionalFieldsDataSourcesThunks.main.getData,
            getVats: vatsDataSourcesThunks.main.getData,
        },

        getItems: {
            selectOffer: offersDataSourcesThunks.main.getSelected,
            selectCatalog: catalogsDataSourcesThunks.main.getSelected,
            selectPackage: packagesDataSourcesThunks.main.getSelected,
            selectArticle: articlesDataSourcesThunks.main.getSelected,
            selectOfferOption: offerOptionsDataSourcesThunks.main.getSelected,
            selectOfferAdditionalField: offerAdditionalFieldsDataSourcesThunks.main.getSelected,
        },

        models: {
            action: buildActionModel(actionsApi, actionsDataSourcesThunks),
            additionalField: buildAdditionalFieldModel(
                additionalFieldApi,
                offerAdditionalFieldsDataSourcesThunks,
            ),
            article: buildArticleModel(articlesApi, articlesDataSourcesThunks),
            catalog: buildCatalogModel(catalogsApi, catalogsDataSourcesThunks),
            document: buildDocumentModel(documentsApi, documentsDataSourcesThunks),
            offer: buildOffreModel(offersApi, offersDataSourcesThunks),
            offerGroup: buildOfferGroupModel(groupementApi, offerGroupsDataSourcesThunks),
            offerOption: buildOfferOptionModel(offerOptionsApi, offerOptionsDataSourcesThunks),
            offerOptionValue: buildOfferOptionValueModel(
                offerOptionValuesApi,
                offerOptionValuesDataSourcesThunks,
            ),
            offerPackage: buildOfferPackageModel(packageApi, packagesDataSourcesThunks),
        },
    };
}
