import React from 'react';
import { Card, Grid, Typography, useTheme } from '@mui/material';
import { UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { BooleanBadge } from '@europrocurement/flexy-components';
import { User } from '../UserTabs/type';

type UserSupportProps = {
    user: User;
    handleValidationModal: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => void;
};

export const UserSupport: React.FunctionComponent<UserSupportProps> = function ({
    user,
    handleValidationModal,
}) {
    const theme = useTheme();

    return (
        <Card
            sx={{
                width: '100%',
                margin: '15px 0',
                padding: '15px',
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Typography
                variant="l2dh6"
                sx={{
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                    padding: '5px 0 5px 30px',
                    marginLeft: '-15px',
                }}
            >
                Support en ligne
            </Typography>

            <Grid
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '15px',
                    width: '100%',
                    margin: '10px',
                    alignItems: 'center',
                }}
            >
                <BooleanBadge
                    label="Accès à l'application support en ligne"
                    value={user.droitUtilisateur?.usdAppsSupport}
                    icon
                    toggleAction={() =>
                        handleValidationModal(
                            {
                                droitUtilisateur: {
                                    usdAppsSupport: !user.droitUtilisateur?.usdAppsSupport,
                                },
                            },
                            'usdAppsSupport',
                            user.droitUtilisateur?.usdAppsSupport as boolean,
                        )
                    }
                />

                <BooleanBadge
                    label="Droit d'administration du support en ligne"
                    value={user.droitUtilisateur?.usdAppsSupportOption}
                    icon
                    toggleAction={() =>
                        handleValidationModal(
                            {
                                droitUtilisateur: {
                                    usdAppsSupportOption:
                                        !user.droitUtilisateur?.usdAppsSupportOption,
                                },
                            },
                            'usdAppsSupportOption',
                            user.droitUtilisateur?.usdAppsSupportOption as boolean,
                        )
                    }
                />
            </Grid>
        </Card>
    );
};
