import {
    Configuration,
    GroupesApi,
    GroupesApiApiGroupesGetCollectionRequest,
} from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import {
    ApiCollectionResponse,
    FetchCollectionData,
    FetchItemDataType,
    SliceFactory,
} from '@europrocurement/l2d-redux-utils';
import {
    type Mutable,
    type UtilisateursConfigurationParameters,
    type GroupsUtilisateurApiObject,
} from '../types';
import { GROUPES_SLICE_NAME, UTILISATEUR_REDUCER_NAME } from '../constants';

export type Groupes = GroupsUtilisateurApiObject;

// function declared for the slice Factory
const mapperServices = function (groupes: Groupes) {
    return {
        ...groupes,
    } as Groupes;
};

/**
 * Function that fetch the collection of groupes and store it into the global state Redux
 * @param configuration API configuration parameters
 * @returns the response of API request (get collection/get by id)
 */
export function createGroupesSlice(configuration: UtilisateursConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new GroupesApi(conf);

    const fetchGroupesCollection: FetchCollectionData<Groupes> = ({
        pagination: { page },
        xIdSociete,
    }) => {
        const input: Mutable<GroupesApiApiGroupesGetCollectionRequest> = {
            xIdSociete,
            page: page + 1,
            itemsPerPage: 50,
            gieGroup: true,
        };

        return api
            .apiGroupesGetCollection(input)
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<Groupes>>;
    };

    // No /groupes/id route on API but needed to the sliceFactory
    const fetchGroupesItem: FetchItemDataType<Groupes> = () =>
        new Promise(() => {
            console.error('fetchGroupesItem called but should not');
        });

    // Use the utilisateur reducer for the constant because it depends to Utilisateur API
    const { slice: groupesSlice, dataSourcesThunks: groupesDataSourcesThunks } =
        SliceFactory.createSlice<GroupsUtilisateurApiObject, Groupes>(
            GROUPES_SLICE_NAME,
            UTILISATEUR_REDUCER_NAME,
            [],
            fetchGroupesCollection,
            fetchGroupesItem,
            mapperServices,
            {},
        );
    return { groupesSlice, groupesDataSourcesThunks };
}
