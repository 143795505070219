import React from 'react';
import { Outlet } from 'react-router';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import { CenterCircularProgress } from '@europrocurement/flexy-components';
import { useLoadStore } from '@europrocurement/l2d-modules';

const FormaliteRoot = function () {
    const { getLoadingStatus } = useLoadStore([
        'formalityCategories',
        'legalStatuses',
        'formalityDocuments',
        'formulairesFormalites',
    ]);

    if (getLoadingStatus() !== 'succeeded') {
        return <CenterCircularProgress />;
    }

    return (
        <KeycloakRoute roles="realm:interne">
            <Outlet />
        </KeycloakRoute>
    );
};

export default FormaliteRoot;
