import { ConfigurationParameters as AchatsConfigurationParameters } from '../../openApi/ApiAchats';
import { createMediaObjectSlice } from './slices/mediaObjectsSlice';
import { createFactureAchatSlice } from './slices/factureAchatSlice';
import { createCategorieStatsSlice } from './slices/categorieStatsSlice';
import { createCodeRejetStatsSlice } from './slices/codeRejetStatsSlice';
import { createReleveAchatSlice } from './slices/invoiceStatementSlice';
import { createCodeRejetSlice } from './slices/codeRejetSlice';
import { createExceptionFacturanteSlice } from './slices/exceptionFacturante';
import { createDefFrequenceSlice } from './slices/defFrequenceSlice';
import { createReminderMailSlice } from './slices/reminderMailSlice';

export { type AchatsConfigurationParameters };

export function createMediaobjectReducer(configuration: AchatsConfigurationParameters) {
    const { mediaobjectSlice, mediaobjectDataSourcesThunks } =
        createMediaObjectSlice(configuration);

    return { mediaobjectSlice, mediaobjectDataSourcesThunks };
}

export function createReleveAchatReducer(configuration: AchatsConfigurationParameters) {
    const { invoiceStatementSlice, invoiceStatementDataSourcesThunks } =
        createReleveAchatSlice(configuration);

    return { invoiceStatementSlice, invoiceStatementDataSourcesThunks };
}

export function createFactureAchatReducer(configuration: AchatsConfigurationParameters) {
    const { factureAchatSlice, factureAchatDataSourcesThunks } =
        createFactureAchatSlice(configuration);

    return { factureAchatSlice, factureAchatDataSourcesThunks };
}

export function createCategorieStatsReducer(configuration: AchatsConfigurationParameters) {
    const { categorieStatsSlice, categorieStatsDataSourcesThunks } =
        createCategorieStatsSlice(configuration);

    return { categorieStatsSlice, categorieStatsDataSourcesThunks };
}

export function createCodeRejetStatsReducer(configuration: AchatsConfigurationParameters) {
    const { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks } =
        createCodeRejetStatsSlice(configuration);

    return { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks };
}

export function createCodeRejetReducer(configuration: AchatsConfigurationParameters) {
    const { codeRejetSlice, codeRejetDataSourcesThunks } = createCodeRejetSlice(configuration);

    return { codeRejetSlice, codeRejetDataSourcesThunks };
}

export function createReminderMailReducer(configuration: AchatsConfigurationParameters) {
    const { reminderMailSlice, reminderMailDataSourcesThunks } =
        createReminderMailSlice(configuration);

    return { reminderMailSlice, reminderMailDataSourcesThunks };
}

export function createExceptionFacturanteReducer(configuration: AchatsConfigurationParameters) {
    const { exceptionFacturanteSlice, exceptionFacturanteDataSourcesThunks } =
        createExceptionFacturanteSlice(configuration);

    return { exceptionFacturanteSlice, exceptionFacturanteDataSourcesThunks };
}

export function createDefFrequenceReducer(configuration: AchatsConfigurationParameters) {
    const { defFrequenceSlice, defFrequenceDataSourcesThunks } =
        createDefFrequenceSlice(configuration);

    return { defFrequenceSlice, defFrequenceDataSourcesThunks };
}
