import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CodeRejet } from '@europrocurement/l2d-domain';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { DataSource } from '@europrocurement/l2d-redux-utils';

import { CodeRejetSelector, getCodeRejet } from '@b2d/redux/RootStore';
import { AppDispatch } from '@b2d/redux/types';

import RejectCodesListComponent from '../../components/lists/RejectCodesListComponent';

const RejectCodesList: React.FunctionComponent = function () {
    const codesRejetsDataSource: DataSource<CodeRejet> = useSelector(CodeRejetSelector).main;

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getCodeRejet({}));
    }, [dispatch]);

    return (
        <>
            <LazyBreadcrumb
                path={[{ path: 'Portail achats', link: '/achats/liste' }, 'Codes Rejets']}
            />
            <RejectCodesListComponent
                filtersControl
                dataSource={codesRejetsDataSource}
                fetchData={getCodeRejet}
            />
        </>
    );
};

export default RejectCodesList;
