/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormReturn, FieldValues } from 'react-hook-form';
import { FormObject } from '@europrocurement/flexy-form';
import {
    DevisLigne,
    Dossier,
    DossierFormalite,
    FACTURE_VENTE_SLICE_NAME,
    FactureAchat,
    FactureVente,
    Fournisseur,
    RubriqueFacturation,
} from '@europrocurement/l2d-domain';
import { EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { selectFactureVente } from '@b2d/redux/RootStore';
import { TblFligneFactureVenteRead } from '@europrocurement/l2d-domain/openApi/ApiDossiers';
import _ from 'lodash';
import { AppDispatch } from '@europrocurement/flexy-components/redux/storeConfig/store';
import { UseSwitchLockValuesProps } from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import { jsonLdIdScraper } from '@europrocurement/l2d-utils';
import type {
    TotauxFactureAchatType,
    FactureFormObject,
    TableauProduits,
    LigneProduit,
} from '@b2d/pages/Achats/components/forms/types';
import { useSnackbar } from 'notistack';
import { useVerbose } from '@europrocurement/flexy-components';
import { RootStateType } from '@b2d/redux';
import {
    calculatorProduit,
    convertToNumberAndFormatToString,
    roundTo,
    updateFormTotaux,
} from './calculsProduits';
import apiInvoiceSellProductToFormInvoicePurchaseProduct from './dataTransformers/apiToForm/apiInvoiceSellProductToFormInvoicePurchaseProduct';

// TODO: Gérer ça en BDD
const mainInvoiceCategoryByDomain = [1, 13, 1, 1, 1, 6, 1, 17, 1, 1];
const rubriqueCodeById = [
    'PUB',
    'PUBNS',
    'JUS',
    'FRS',
    'FRN',
    'ADJ',
    'VAC',
    'REG',
    'LIB',
    'DIV',
    'RBF',
    'ADJI',
    'MPU',
    'LOGO',
    'FRT',
    'ENT',
    'EPU',
];

export const findProduitByIdRubFact = function (
    formContext: UseFormReturn<Partial<FactureFormObject>, any>,
    idRubFact: number,
) {
    return formContext
        .getValues()
        .produits?.find((item) => item.rubriqueFacturation?.id === idRubFact || false);
};

/**
 * Retourne un tableau de produit contenant les lignes par défaut selon :
 *
 *     - Le domaine de la vente ou de la commande
 *     - Si la RSF s'applique
 *
 * @param idDdm
 * @param isRsf
 * @returns TableauProduits
 */
export const defaultProduits = function (
    idDdm: number = 1,
    isRsf: boolean = false,
): TableauProduits {
    const tableau: TableauProduits = [];

    const baseProduit: LigneProduit = {
        ordre: 1,
        rubriqueFacturation: {
            id: 1,
            code: 'PUB',
        },
        ht: undefined,
        tva: undefined,
        txtva: {
            label: 20,
            value: 20,
        },
        ttc: undefined,
    };

    switch (idDdm) {
        case 2:
            const produitMpu = _.cloneDeep(baseProduit);
            produitMpu.rubriqueFacturation = {
                id: 13,
                code: 'MPU',
            };
            tableau.push(produitMpu);
            break;
        case 3:
            const produitFrs = _.cloneDeep(baseProduit);
            produitFrs.rubriqueFacturation = {
                id: 4,
                code: 'FRS',
            };
            const produitFrn = _.cloneDeep(baseProduit);
            produitFrn.rubriqueFacturation = {
                id: 5,
                code: 'FRN',
            };
            produitFrn.txtva = {
                label: 0,
                value: 0,
            };

            tableau.push(produitFrs);
            tableau.push(produitFrn);
            break;
        case 6:
            const produitAdj = _.cloneDeep(baseProduit);
            produitAdj.rubriqueFacturation = {
                id: 6,
                code: 'ADJ',
            };
            tableau.push(produitAdj);
            break;
        case 8:
            const produitEpu = _.cloneDeep(baseProduit);
            produitEpu.rubriqueFacturation = {
                id: 17,
                code: 'EPU',
            };
            tableau.push(produitEpu);
            break;
        case 4:
        case 5:
        case 7:
        case 9:
        default:
            const defaultProduit = _.cloneDeep(baseProduit);
            tableau.push(defaultProduit);
            break;
    }

    if (isRsf) {
        const produitRsf = _.cloneDeep(baseProduit);
        produitRsf.ordre = 2;
        produitRsf.rubriqueFacturation = {
            id: 99,
            code: 'RSF',
        };
        tableau.push(produitRsf);
    } else {
        const rsfToRemove = tableau.findIndex((ligne) => ligne.rubriqueFacturation?.code === 'RSF');

        if (rsfToRemove) delete tableau[rsfToRemove];
    }

    return tableau;
};

/**
 * Met à jour le tableau de produits de la section "Rubriques"
 *
 * @param formContext
 * @param produits
 */
export const replaceProduits = function (
    formContext: UseFormReturn<FormObject>,
    produits: Array<LigneProduit>,
) {
    // On ne remplace uniquement si ce n'est pas les mêmes produits
    if (!_.isEqual(produits, formContext.getValues('produits'))) {
        // formContext.setValue('produits', produits);
        formContext.setValue(
            'produits',
            produits.map((item) => {
                const newProduit = { ...item };
                if (newProduit.ttc) {
                    newProduit.ttc = convertToNumberAndFormatToString(+newProduit.ttc);
                }
                return newProduit;
            }),
        );
    }
};

/**
 * Ordonnance les lignes grâce à la valeur de 'ordre' d'une ligneProduit.
 * Si 'ordre' n'est pas précisé, la ligne sera en bas du tableau.
 *
 * @param produits
 * @returns
 */
export const organiserProduits = function (produits: TableauProduits) {
    produits?.sort((a, b) => {
        let aOrdre = a.ordre;
        let bOrdre = b.ordre;

        if (!aOrdre)
            aOrdre =
                produits.length === 2 && a.rubriqueFacturation?.code !== 'RSF'
                    ? produits.length - 1
                    : produits.length;
        if (!bOrdre)
            bOrdre =
                produits.length === 2 && a.rubriqueFacturation?.code !== 'RSF'
                    ? produits.length + 1
                    : produits.length;
        return aOrdre - bOrdre;
    });

    return produits;
};

/**
 * Complète les champs TVA et TTC d'une ligne de produit pour un index donné.
 *
 * @param formContext
 * @param produit
 * @param index
 */
export const fillProduits = function (
    formContext: UseFormReturn<FieldValues, any>,
    produit: Partial<LigneProduit>,
    index: number,
) {
    const valeurs = calculatorProduit(produit);
    formContext.setValue(`produits.${index}.tva`, convertToNumberAndFormatToString(valeurs.tva));
    formContext.setValue(`produits.${index}.ttc`, convertToNumberAndFormatToString(valeurs.ttc));
};

export type ActionOnChangeTauxRemiseProps = {
    formContext: UseFormReturn<Partial<FactureFormObject>, any>;
    stateSwitchLockValues: UseSwitchLockValuesProps['stateSwitchLockValues'];
    produitConcernedByRsfExist: (produitConcernedByRsf: number) => void;
    produitConcernedByRsfDoNotExist: (message: string) => void;
};

export const atLeastOneInvoiceCategoryIsNotUniq = (produits: TableauProduits) => {
    const occurrences: Record<string, number> = {};

    produits.forEach((ligneProduit) => {
        if (ligneProduit.rubriqueFacturation && ligneProduit.rubriqueFacturation.code) {
            const { code } = ligneProduit.rubriqueFacturation;

            if (occurrences[code]) {
                occurrences[code] += 1;
            } else {
                occurrences[code] = 1;
            }
        }
    });

    return _.some(occurrences, (occurrence) => occurrence > 1);
};

export const moreThanOneMainInvoiceCategory = (produits: TableauProduits): boolean =>
    _.filter(produits, (ligneProduit) => {
        const id = ligneProduit.rubriqueFacturation?.id;
        return id && mainInvoiceCategoryByDomain.includes(id);
    }).length >= 2;

/**
 * Determine use cases where the suggestions reliability is too low
 */
export const complexMainInvoiceCategoryCase = (produits: TableauProduits): boolean =>
    atLeastOneInvoiceCategoryIsNotUniq(produits) && moreThanOneMainInvoiceCategory(produits);

export const useActionOnChangeTauxRemise = function () {
    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomain);

    const { enqueueSnackbar } = useSnackbar();

    return ({
        formContext,
        stateSwitchLockValues,
        produitConcernedByRsfExist,
        produitConcernedByRsfDoNotExist,
    }: ActionOnChangeTauxRemiseProps) => {
        const produits = formContext.getValues('produits');

        if (produits) {
            const idDomainIndex = domaine > 0 ? domaine : 1;
            const rubFactId = mainInvoiceCategoryByDomain[idDomainIndex - 1];

            const produitConcernedByRsf = produits.findIndex(
                (item) => item.rubriqueFacturation?.id === rubFactId,
            );

            if (produitConcernedByRsf === -1) {
                console.error(
                    `No discount applicable. Current domain : ${domaine}, Concerned category : ${rubFactId}`,
                );
                enqueueSnackbar({
                    message: "Pas de produit concerné par l'application de la remise.",
                    variant: 'error',
                });
            }

            const produitConcernedByRsfHt = produits[produitConcernedByRsf].ht;

            if (complexMainInvoiceCategoryCase(produits)) {
                const message = `Le cas de ce dossier est trop complexe. Traitez-le manuellement.`;
                produitConcernedByRsfDoNotExist(message);
                return;
            }

            if (produitConcernedByRsfHt && produitConcernedByRsfHt > 0) {
                produitConcernedByRsfExist(produitConcernedByRsf);
            } else {
                const message = `La valeur HT de la ligne ${
                    rubriqueCodeById[rubFactId - 1]
                } est nécessaire pour modifier le taux de remise.`;
                produitConcernedByRsfDoNotExist(message);
            }

            updateFormTotaux({
                formContext,
                reasonToTriggerUpdate: stateSwitchLockValues.totals.value,
            });
        }
    };
};

/**
 * Hook pour mettre à jour les infos de la section "Rubriques"
 * si un dossier et une facture de vente sont sélectionnés.
 * Sinon, s'il y a un dossier mais pas de vente, alors :
 *      - Cacher le switch "Infos ventes".
 * Sinon, s'il n'y a aucun des deux, alors :
 *      - Supprimer la facture de vente du state;
 *      - Vider la liste de produits.
 *
 * @returns void
 */
export const updateFormWithVentes = function (
    formContext: UseFormReturn<FormObject>,
    dossierSelected: Dossier | null,
    dossierFormaliteSelected: DossierFormalite | null,
    fournisseurSelected: Fournisseur | null,
    venteSelected: FactureVente | null,
    callbacks?: {
        onDossierNotSelect?: () => void;
        onVenteSelected?: () => void;
        onVenteNotSelected?: (idFactureVente: number) => void;
    },
    updateProduits?: boolean,
    ddmId?: number,
) {
    let idFactureVenteDossierSelected: number;

    if (dossierSelected && dossierSelected.facture) {
        idFactureVenteDossierSelected = jsonLdIdScraper(dossierSelected.facture);
        if (!venteSelected || venteSelected.id !== idFactureVenteDossierSelected) {
            if (callbacks?.onVenteNotSelected) {
                callbacks.onVenteNotSelected(idFactureVenteDossierSelected);
            }
        } else if (callbacks?.onVenteSelected) {
            callbacks.onVenteSelected();
        }
    } else if ((dossierSelected || dossierFormaliteSelected) && venteSelected) {
        if (callbacks?.onVenteSelected) {
            callbacks.onVenteSelected();
        }
    } else if (!dossierSelected && !dossierFormaliteSelected) {
        // S'il n'y a pas de dossier sélectionné ,
        // on affiche les lignes par défaut d'un domaine PUB.
        // Si le fournisseur est RSF, on ajoute une ligne RSF.
        if (callbacks?.onDossierNotSelect) {
            callbacks.onDossierNotSelect();
        }

        if (updateProduits === false) return;

        let needReplace = false;
        const currentProduits = formContext.getValues('produits') as TableauProduits | undefined;

        if (currentProduits) {
            // Si on a au moins une ligne mappée avec une vente on remplace
            for (let index = 0; index < currentProduits.length; index++) {
                if (
                    !(
                        currentProduits[index].idLigneVente === undefined ||
                        `${currentProduits[index].idLigneVente}` === ''
                    )
                ) {
                    needReplace = true;
                }
            }

            if (
                fournisseurSelected &&
                fournisseurSelected.typeRemise &&
                fournisseurSelected?.typeRemise.code === 'RSF'
            ) {
                if (!findProduitByIdRubFact(formContext, 99)) {
                    needReplace = true;
                }
            }
            // Si on a pas assez de lignes on remplace
            if (
                (currentProduits.length < 2 &&
                    fournisseurSelected &&
                    fournisseurSelected.typeRemise &&
                    fournisseurSelected?.typeRemise.code === 'RSF') ||
                currentProduits.length < 1
            ) {
                needReplace = true;
            }
        } else {
            // Si on a pas de produits on remplace
            needReplace = true;
        }
        if (
            needReplace &&
            fournisseurSelected &&
            fournisseurSelected.typeRemise &&
            fournisseurSelected?.typeRemise.code === 'RSF'
        ) {
            // Ligne produit par défaut avec RSF
            replaceProduits(formContext, defaultProduits(ddmId, true));
        } else if (needReplace) {
            // Ligne produit par défaut sans RSF
            replaceProduits(formContext, defaultProduits(ddmId, false));
        }
    }
};

// Numéro dossier JAL : 1272353
// Numéro dossier JAL complexe : 957499

const remiseSurProduitByDomaine = (domaine: number, produit: LigneProduit): LigneProduit => {
    if (
        produit.rubriqueFacturation &&
        produit.rubriqueFacturation.id === mainInvoiceCategoryByDomain[domaine - 1]
    ) {
        // eslint-disable-next-line no-param-reassign
        produit.ordre = 1;
    }
    return produit;
};

export const useUpdateFormWithVentesAndEvents = function () {
    const dispatch = useDispatch<AppDispatch>();
    const stateDefDomaine = useSelector((s: RootStateType) => s.factureForm.defDomain, _.isEqual);
    const stateDisplaySwitchToSeeDetails = useSelector(
        (s: RootStateType) => s.factureForm.displaySwitchToSeeDetails,
        _.isEqual,
    );

    return (
        formContext: UseFormReturn<FormObject>,
        dossierSelected: Dossier | null,
        dossierFormaliteSelected: DossierFormalite | null,
        fournisseurSelected: Fournisseur | null,
        ventesDataSourceSelected: FactureVente | null,
        ventesDataSourceStatus: EuroprocApiResponseStatus,
        updateProduits?: boolean,
    ) => {
        updateFormWithVentes(
            formContext,
            dossierSelected,
            dossierFormaliteSelected,
            fournisseurSelected,
            ventesDataSourceSelected,
            {
                onDossierNotSelect: () => {
                    if (ventesDataSourceSelected) {
                        dispatch({
                            type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
                        });
                    }
                },
                onVenteSelected: () => {
                    if (!stateDisplaySwitchToSeeDetails) {
                        dispatch({
                            type: ACTIONS.DISPLAY_SWITCH_TO_SEE_DETAILS,
                            payload: true,
                        });
                    }
                },
                onVenteNotSelected: (idFactureVente) => {
                    if (
                        ventesDataSourceStatus !== 'loading' &&
                        (!ventesDataSourceSelected ||
                            ventesDataSourceSelected.id !== idFactureVente)
                    ) {
                        dispatch(selectFactureVente({ id: idFactureVente }));
                    }

                    if (stateDisplaySwitchToSeeDetails) {
                        dispatch({
                            type: ACTIONS.DISPLAY_SWITCH_TO_SEE_DETAILS,
                            payload: false,
                        });
                    }
                },
            },
            updateProduits !== false,
            stateDefDomaine,
        );
    };
};

/**
 * Supprime les produits vides d'une facture en erreur
 *
 * @param formContext
 * @returns
 */
export const removeEmptyProduits = function (
    formContext: UseFormReturn<Partial<FactureFormObject>>,
) {
    const produits = formContext.getValues(`produits`) as TableauProduits;

    if (!formContext.formState.errors.produits?.length) {
        return;
    }
    const toRemove: Array<number> = [];

    for (let index = 0; index < formContext.formState.errors.produits.length; index++) {
        if (formContext.formState.errors.produits[index] !== undefined) {
            toRemove.push(index);
        }
    }
    formContext.setValue(
        'produits',
        produits.reduce((p: TableauProduits, c: LigneProduit, i) => {
            if (toRemove.indexOf(i) === -1) {
                p.push(c);
            }
            return p;
        }, []),
    );
};

export const useSyncAchatProduitsWithQuote = function () {
    const dispatch = useDispatch();
    const stateDisplaySwitchToSeeDetails = useSelector(
        (s: RootStateType) => s.factureForm.displaySwitchToSeeDetails,
        _.isEqual,
    );

    const stateDetails = useSelector(
        (s: RootStateType) => s.factureForm.productsDetails,
        _.isEqual,
    );

    const domain = useSelector((s: RootStateType) => s.factureForm.defDomain);

    const verbose = useVerbose();

    verbose.setLevel(2);

    return (
        quotesLines: Array<DevisLigne> | undefined,
        formContext: UseFormReturn<Partial<FactureFormObject>>,
        invoiceCategories: Array<RubriqueFacturation>,
        folio?: Dossier | undefined,
        change?: boolean,
    ) => {
        // Rubriques du formulaire
        let produitsAchat: Partial<LigneProduit & { toDelete?: boolean }>[] =
            formContext.getValues().produits || [];

        // Actually same prop but used for quote.
        const produitsAchatIdVente = produitsAchat.map((produitAchat) => produitAchat.idLigneVente);
        let reassignProduits = false;
        const changeAchat = change !== false; // si true => on change les achats

        if (quotesLines) {
            const productsDetails: DevisLigne[] = []; // Rempli avec les lignes de ventes à afficher
            const produitsAchatAjoute: DevisLigne[] = []; // Rempli avec les achats à afficher
            const produitsAchatSupprime: DevisLigne[] = []; // Rempli avec les achats à ne pas afficher
            const idRubFactToDelete: string[] = [];

            let hasMatch = false;
            let hasRsf = false;

            verbose.log(
                '00 - init',
                'productsDetails',
                productsDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            // Pour chaque produit du formulaire :
            // Verifier s'il est bien dans la facture OU RSF.
            produitsAchat.forEach((produitAchat) => {
                // on ne supprime pas le rsf
                if (produitAchat.rubriqueFacturation?.id === 99) {
                    hasRsf = true;
                    return;
                }
                // on ne supprime pas une ligne vide
                if (!produitAchat.rubriqueFacturation || !produitAchat.idLigneVente) {
                    return;
                }

                // on supprime si l'idLigneVente de l'achat ne correspond pas
                // à l'id du produit de vente.
                if (
                    quotesLines
                        ?.map((produitVente) => produitVente.id)
                        ?.indexOf(produitAchat.idLigneVente) === -1
                ) {
                    hasMatch = true;
                    if (!produitAchat.ht) {
                        // eslint-disable-next-line no-param-reassign
                        produitAchat.toDelete = true;
                        produitsAchatSupprime.push({
                            rubriqueFacturation:
                                produitAchat.rubriqueFacturation &&
                                `${produitAchat.rubriqueFacturation.id}`,
                        });
                    }
                }
            });

            verbose.log(
                '10 - apres suppr produit en trop',
                'productsDetails',
                productsDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            // 1 - Prendre chaque produit de la facture
            // 2 - Pour chaque produit, rechercher dans les produits du formulaire s'il y est
            // 3 - S'il n'y est pas, on l'ajoute.
            quotesLines.forEach((produitVente) => {
                if (produitVente.rubfac !== 'LIB' && produitVente.rubfac !== 'FRT') {
                    productsDetails.push(produitVente);

                    // Assure l'unicité de type pour chaque ligne d'achat à ajouter
                    if (changeAchat && produitsAchatIdVente.indexOf(produitVente.id) === -1) {
                        hasMatch = true;
                        produitsAchatAjoute.push(produitVente);
                        if (produitVente.rubfac) {
                            idRubFactToDelete.push(produitVente.rubfac);
                        }
                    } else if (produitsAchatIdVente.indexOf(produitVente.id) !== -1) {
                        hasMatch = true;
                    }
                }
            });

            verbose.log(
                '20 - apres ajout produit facture',
                'productsDetails',
                productsDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            if (
                !_.isEqual(
                    productsDetails.map((item: RubriqueFacturation) =>
                        apiInvoiceSellProductToFormInvoicePurchaseProduct(item, invoiceCategories),
                    ),
                    stateDetails,
                )
            ) {
                dispatch({ type: ACTIONS.INIT_PRODUCTS_DETAILS });
                dispatch({ type: ACTIONS.UPDATE_PRODUCTS_DETAILS, payload: productsDetails });
            }

            if (!changeAchat) {
                return;
            }

            verbose.log('changeAchat', changeAchat);

            // Supprime les lignes qui sont flagées
            if (produitsAchatSupprime.length > 0) {
                hasMatch = true;
                produitsAchat = produitsAchat.filter((produitAchat) => produitAchat.toDelete);
                reassignProduits = true;
            }

            // Supprime les lignes qui ne sont pas RSF ou à afficher

            produitsAchat = produitsAchat.filter((produitAchat) => {
                // s'il est dans ma liste de rubfact a supprimer => on delete
                if (
                    produitAchat.rubriqueFacturation?.code &&
                    idRubFactToDelete.indexOf(produitAchat.rubriqueFacturation?.code) !== -1
                )
                    return false;
                // sinon on delete pas
                return true;
            });

            verbose.log(
                '30 - apres delete rubfact a delete',
                'productsDetails',
                productsDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            // Ajoute les lignes à afficher (i.e: PUB, JUS, RSF, ...)
            if (produitsAchatAjoute.length > 0) {
                produitsAchatAjoute.forEach((produitAchatAjoute) => {
                    let toAdd: Partial<LigneProduit> = {
                        idLigneVente: produitAchatAjoute.id,
                        txtva: {
                            label: 20,
                            value: 20,
                        },
                    };
                    if (produitAchatAjoute.rubfac && produitAchatAjoute.rubfac !== '') {
                        const rubId = invoiceCategories.find(
                            (rubFact: RubriqueFacturation) =>
                                rubFact.code === produitAchatAjoute.rubfac,
                        )?.id;

                        /**
                         * If the invoice category is not allowed, do not add the invoice category to the line
                         * Still add the line, though... (Is it wrong ?)
                         */

                        verbose.log('rubId: ', rubId);

                        if (!rubId) return;

                        verbose.log('toAdd: ', toAdd);
                        verbose.log('produitAchatAjoute.rubfac: ', produitAchatAjoute.rubfac);

                        toAdd.rubriqueFacturation = {
                            code: produitAchatAjoute.rubfac ? produitAchatAjoute.rubfac : undefined,
                            id: rubId,
                        };

                        if (produitAchatAjoute.rubfac === 'FRN') {
                            toAdd.txtva = {
                                label: 0,
                                value: 0,
                            };
                        }

                        if (
                            !moreThanOneMainInvoiceCategory(produitsAchat) &&
                            mainInvoiceCategoryByDomain.includes(rubId)
                        ) {
                            toAdd.ordre = 1;
                        }
                    }

                    if (domain) {
                        toAdd = remiseSurProduitByDomaine(domain, toAdd);
                    }

                    produitsAchat.push(toAdd);
                });

                hasMatch = true;

                reassignProduits = true;
            }

            verbose.log(
                '40 - apres ajout ligne a afficher',
                'productsDetails',
                productsDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            if (productsDetails && productsDetails.length > 0 && !stateDisplaySwitchToSeeDetails) {
                dispatch({
                    type: ACTIONS.DISPLAY_SWITCH_TO_SEE_DETAILS,
                    payload: true,
                });
            }

            if (reassignProduits) {
                replaceProduits(formContext, organiserProduits(produitsAchat));
            } else if (hasMatch === false) {
                replaceProduits(formContext, defaultProduits(domain, hasRsf));
            }
        }
    };
};

/**
 *
 * @returns Synchronise les produits de la vente et de l'achats
 */
export const useSyncAchatProduitsWithVente = function () {
    const dispatch = useDispatch();
    const stateDisplaySwitchToSeeDetails = useSelector(
        (s: RootStateType) => s.factureForm.displaySwitchToSeeDetails,
        _.isEqual,
    );

    // Don't care anymore ?
    const stateProductsDetails = useSelector(
        (s: RootStateType) => s.factureForm.productsDetails,
        _.isEqual,
    );
    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomain);

    const verbose = useVerbose();

    return (
        factureVenteLignes: TblFligneFactureVenteRead[] | undefined,
        formContext: UseFormReturn<Partial<FactureFormObject>>,
        rubFacts: RubriqueFacturation[],
        dossier?: Dossier | undefined,
        change?: boolean,
        formalite?: boolean,
    ) => {
        // Rubriques du formulaire
        let produitsAchat: Partial<LigneProduit & { toDelete?: boolean }>[] =
            formContext.getValues().produits || [];

        const produitsAchatIdVente = produitsAchat.map((produitAchat) => produitAchat.idLigneVente);

        let reassignProduits = false;
        const changeAchat = change !== false; // si true => on change les achats

        if (factureVenteLignes) {
            const productDetails: TblFligneFactureVenteRead[] = []; // Rempli avec les lignes de ventes à afficher
            const produitsAchatAjoute: TblFligneFactureVenteRead[] = []; // Rempli avec les achats à afficher
            const produitsAchatSupprime: TblFligneFactureVenteRead[] = []; // Rempli avec les achats à ne pas afficher
            const idRubFactToDelete: string[] = [];

            let hasMatch = false;
            let hasRsf = false;

            verbose.log(
                '00 - init',
                'productDetails',
                productDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            // Pour chaque produit du formulaire :
            // Verifier s'il est bien dans la facture OU RSF.
            produitsAchat.forEach((produitAchat) => {
                // on ne supprime pas le rsf
                if (produitAchat.rubriqueFacturation?.id === 99) {
                    hasRsf = true;
                    return;
                }
                // on ne supprime pas une ligne vide
                if (!produitAchat.rubriqueFacturation || !produitAchat.idLigneVente) {
                    return;
                }

                // on supprime si l'idLigneVente de l'achat ne correspond pas
                // à l'id du produit de vente.
                if (
                    factureVenteLignes
                        ?.map((produitVente) => produitVente.id)
                        ?.indexOf(produitAchat.idLigneVente) === -1
                ) {
                    hasMatch = true;
                    if (!produitAchat.ht) {
                        // eslint-disable-next-line no-param-reassign
                        produitAchat.toDelete = true;
                        produitsAchatSupprime.push({
                            rubriqueFacturation:
                                produitAchat.rubriqueFacturation &&
                                `${produitAchat.rubriqueFacturation.id}`,
                        });
                    }
                }
            });

            verbose.log(
                '10 - apres suppr produit en trop',
                'productDetails',
                productDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            // 1 - Prendre chaque produit de la facture
            // 2 - Pour chaque produit, rechercher dans les produits du formulaire s'il y est
            // 3 - S'il n'y est pas, on l'ajoute.
            factureVenteLignes.forEach((produitVente) => {
                if (
                    produitVente.affiche &&
                    produitVente.rubriqueFacturation !== 'LIB' &&
                    produitVente.rubriqueFacturation !== 'FRT'
                ) {
                    productDetails.push(produitVente);

                    // Assure l'unicité de type pour chaque ligne d'achat à ajouter
                    if (changeAchat && produitsAchatIdVente.indexOf(produitVente.id) === -1) {
                        if (
                            dossier &&
                            dossier.prestations &&
                            produitVente.idAnnonce === dossier.prestations[0].id
                        ) {
                            hasMatch = true;
                            produitsAchatAjoute.push(produitVente);
                            if (produitVente.rubriqueFacturation) {
                                idRubFactToDelete.push(produitVente.rubriqueFacturation);
                            }
                        } else if (formalite) {
                            hasMatch = true;
                            produitsAchatAjoute.push(produitVente);
                        }
                    } else if (produitsAchatIdVente.indexOf(produitVente.id) !== -1) {
                        hasMatch = true;
                    }
                }
            });

            verbose.log(
                '20 - apres ajout produit facture',
                'productDetails',
                productDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            if (
                !_.isEqual(
                    productDetails.map((item: RubriqueFacturation) =>
                        apiInvoiceSellProductToFormInvoicePurchaseProduct(item, rubFacts),
                    ),
                    stateProductsDetails,
                )
            ) {
                dispatch({ type: ACTIONS.INIT_PRODUCTS_DETAILS });
                dispatch({ type: ACTIONS.UPDATE_PRODUCTS_DETAILS, payload: productDetails });
            }

            if (!changeAchat) {
                return;
            }

            verbose.log('changeAchat', changeAchat);

            // Supprime les lignes qui sont flagées
            if (produitsAchatSupprime.length > 0) {
                hasMatch = true;
                produitsAchat = produitsAchat.filter((produitAchat) => produitAchat.toDelete);
                reassignProduits = true;
            }

            // Supprime les lignes qui ne sont pas RSF ou à afficher

            produitsAchat = produitsAchat.filter((produitAchat) => {
                // s'il est dans ma liste de rubfact a supprimer => on delete
                if (
                    produitAchat.rubriqueFacturation?.code &&
                    idRubFactToDelete.indexOf(produitAchat.rubriqueFacturation?.code) !== -1
                )
                    return false;
                // sinon on delete pas
                return true;
            });

            verbose.log(
                '30 - apres delete rubfact a delete',
                'productDetails',
                productDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            // Ajoute les lignes à afficher (i.e: PUB, JUS, RSF, ...)
            if (produitsAchatAjoute.length > 0) {
                produitsAchatAjoute.forEach((produitAchatAjoute) => {
                    let toAdd: Partial<LigneProduit> = {
                        idLigneVente: produitAchatAjoute.id,
                        txtva: {
                            label: 20,
                            value: 20,
                        },
                    };
                    if (
                        produitAchatAjoute.rubriqueFacturation &&
                        produitAchatAjoute.rubriqueFacturation !== ''
                    ) {
                        const rubId = rubFacts.find(
                            (rubFact: RubriqueFacturation) =>
                                rubFact.code === produitAchatAjoute.rubriqueFacturation,
                        )?.id;

                        /**
                         * If the invoice category is not allowed, do not add the invoice category to the line
                         * Still add the line, though... (Is it wrong ?)
                         */
                        if (!rubId) return;

                        toAdd.rubriqueFacturation = {
                            code: produitAchatAjoute.rubriqueFacturation,
                            id: rubId,
                        };

                        if (produitAchatAjoute.rubriqueFacturation === 'FRN') {
                            toAdd.txtva = {
                                label: 0,
                                value: 0,
                            };
                        }
                    }

                    if (domaine) {
                        toAdd = remiseSurProduitByDomaine(domaine, toAdd);
                    }

                    produitsAchat.push(toAdd);
                });

                hasMatch = true;

                reassignProduits = true;
            }

            verbose.log(
                '40 - apres ajout ligne a afficher',
                'productDetails',
                productDetails,
                'produitsAchatAjoute',
                produitsAchatAjoute,
                'produitsAchatSupprime',
                produitsAchatSupprime,
                'idRubFactToDelete',
                idRubFactToDelete,
            );

            if (productDetails && productDetails.length > 0 && !stateDisplaySwitchToSeeDetails) {
                dispatch({
                    type: ACTIONS.DISPLAY_SWITCH_TO_SEE_DETAILS,
                    payload: true,
                });
            }

            if (reassignProduits) {
                verbose.log('reassignProduits: ', reassignProduits, 'produitsAchat', produitsAchat);
                replaceProduits(formContext, organiserProduits(produitsAchat));
            } else if (hasMatch === false) {
                verbose.log(
                    'hasMatch: ',
                    hasMatch,
                    'defaultProduits(domaine, hasRsf): ',
                    defaultProduits(domaine, hasRsf),
                );
                replaceProduits(formContext, defaultProduits(domaine, hasRsf));
            }

            // replaceProduits(formContext, organiserProduits(produitsAchat));
        }
    };
};

/**
 * Test s'il existe au moins une prestation du dossier ayant
 * la même entité facturante que la facture d'achat.
 *
 * @param factureAchat
 * @param dossier
 * @returns boolean
 */
export const isDossierFromFournisseur = function (
    factureAchat: FactureAchat,
    dossier: Dossier,
): boolean {
    const prestations = dossier.prestations?.find(
        (value) => value.idEntiteFacturante === factureAchat.idEntiteFacturante,
    );

    return prestations !== undefined;
};

export const ajouterLigne = function (
    formContext: UseFormReturn<Partial<FactureFormObject>, any>,
    produit: Partial<LigneProduit>,
) {
    let newProduitsForm: TableauProduits = [];

    // Supprime les produits sans idLigneVente du tableau de produits (les lignes par défauts, sauf RSF)
    const produitsValues = formContext
        .getValues('produits')
        ?.filter((produitToFilter) => !!produitToFilter?.idLigneVente);

    if (produitsValues) {
        newProduitsForm = [...produitsValues];
    }

    newProduitsForm.push(produit);

    newProduitsForm = organiserProduits(newProduitsForm);

    // newProduitsForm.forEach((LigneProduit, index) => {
    //     if (LigneProduit.rubriqueFacturation === undefined) newProduitsForm.splice(index);
    // });

    replaceProduits(formContext, newProduitsForm);
};

export const ajouterLigneRSF = function (
    formContext: UseFormReturn<Partial<FactureFormObject>>,
    fournisseur: Fournisseur | undefined,
): Promise<void> {
    return new Promise((resolve) => {
        if (fournisseur && fournisseur.typeRemise?.code === 'RSF') {
            setTimeout(() => {
                const produitRsf = findProduitByIdRubFact(formContext, 99);
                if (!produitRsf) {
                    ajouterLigne(formContext, {
                        ordre: 2,
                        rubriqueFacturation: {
                            id: 99,
                            code: 'RSF',
                        },
                        ht: undefined,
                        tva: undefined,
                        txtva: {
                            label: 20,
                            value: 20,
                        },
                        ttc: undefined,
                    });
                }
                resolve();
            }, 300);
        } else {
            // Resolve immediately if the condition is not met
            resolve();
        }
    });
};

export const findRubFactById = (rubFacts: RubriqueFacturation[], id: number | undefined) =>
    rubFacts.find((rubFact: RubriqueFacturation) => rubFact.id === id);

/**
 * Additionne les valeurs des lignes de détails
 *
 * @param factureAchat
 * @returns TotauxFactureAchatType
 */
export const getTotaux = function (factureAchat: FactureAchat): TotauxFactureAchatType {
    const totaux: TotauxFactureAchatType = {
        ht: 0,
        tva: 0,
        ttc: 0,
    };

    if (factureAchat.ht) {
        totaux.ht = +factureAchat.ht;
    }

    if (factureAchat.tva) {
        totaux.tva = +factureAchat.tva;
    }

    if (factureAchat.ttc) {
        totaux.ttc = +factureAchat.ttc;
    }

    Object.keys(totaux).forEach((key: keyof TotauxFactureAchatType) => {
        totaux[key] = roundTo(totaux[key], 4);
    });

    return totaux;
};
