/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react';

const getOrderRoute = (id: number): string => '';
const getDossierRoute = (id: number): string => '';
const getDossiersListeRoute = (): string => '';

const DossierStepContext = createContext({ getOrderRoute, getDossierRoute, getDossiersListeRoute });

export default DossierStepContext;
