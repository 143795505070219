import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, SxProps } from '@mui/material';
import * as React from 'react';

export type LineActionButtonProps = {
    onClick: () => void;
    icon: IconDefinition;
    actionName: string;
    disabled?: boolean;
    bordered?: boolean;
    sx?: SxProps;
};

const LineActionButton: React.FunctionComponent<LineActionButtonProps> = function ({
    icon,
    onClick,
    actionName,
    disabled,
    bordered,
    sx,
}) {
    const buttonSize = '40px';

    const buttonStyle = {
        maxWidth: buttonSize,
        minWidth: buttonSize,
        maxHeight: buttonSize,
        minHeight: buttonSize,
        border: bordered ? '1px solid' : '',
        ...sx,
    };

    return (
        <Button
            aria-roledescription={actionName}
            sx={buttonStyle}
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <FontAwesomeIcon icon={icon} />
        </Button>
    );
};

export default LineActionButton;
