import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { useCurrentPrescriber } from './useCurrentPrescriber';

export const useCurrentContact = () => {
    const kc = UseKeycloakService();
    const prescripteur = useCurrentPrescriber();

    const currContact = prescripteur.contacts.find(
        (contact) =>
            contact.emailsContact.find(
                (email) => email.email.toLocaleLowerCase() === kc.getEmail().toLocaleLowerCase(),
            ) !== undefined,
    );

    // if (!currContact) {
    //     throw new Error('missing contact');
    // }

    return currContact;
};
