import { AppDispatch, RootStateType } from '@b2d/redux/types';
import { selectFactureVente } from '@b2d/redux/RootStore';
import { Commande, FactureVente } from '@europrocurement/l2d-domain';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsonLdIdScraper } from '@europrocurement/l2d-utils';
import { EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { useVerbose } from '@europrocurement/flexy-components';
import useAbstractFolioDataSourceSelected from './useAbstractFolioDataSourceSelected';

/**
 * The purpose of this hook is to find the Most Valuable Sell in the given data from Redux.
 *
 * MVS Definition below:
 *
 * Dossier
 * - Dos.Vente
 * - Dos.Commande
 *
 * Dos.Commande > Dos.Vente
 *
 * Commande
 * - Com.Devis
 * - Com.Vente
 *
 * Com.Vente > Com.Devis
 *
 * e.g. :
 * - if Dos.Vente && Com.Vente => return Com.Vente
 * - if Dos.Vente && Com.Devis && !Com.Vente => return ??? (Dos.Vente ?)
 */
const useFinderMVSell = () => {
    const verbose = useVerbose();

    const dispatch = useDispatch<AppDispatch>();

    const sellSelectedStatus: EuroprocApiResponseStatus = useSelector(
        (s: RootStateType) => s.dossiers.facturevente.main.selectedStatus,
        _.isEqual,
    );

    const { folioSelected } = useAbstractFolioDataSourceSelected();
    const commandSelected: Commande | undefined = useSelector(
        (s: RootStateType) => s.dossiers.commande.main.selected,
        _.isEqual,
    );
    const sellSelected: FactureVente | undefined = useSelector(
        (s: RootStateType) => s.dossiers.facturevente.main.selected,
        _.isEqual,
    );

    const isSameIdThanCurrentSelectedSell = useCallback(
        (newSellId?: number) => !!(sellSelected && newSellId === sellSelected.id),
        [sellSelected],
    );

    const selectNewSell = useCallback(
        (newSellToSelectId?: number) => {
            if (!newSellToSelectId || isSameIdThanCurrentSelectedSell(newSellToSelectId)) return;

            dispatch(
                selectFactureVente({
                    id: newSellToSelectId,
                }),
            );
        },
        [dispatch, isSameIdThanCurrentSelectedSell],
    );

    /**
     * Vente de dossier
     * Commande de dossier
     * Vente de commande
     * Dossier de commande
     * Vente de dossier de commande
     *
     * OÙ Dossier < Commande
     */

    /**
     * Allowed to change if:
     *
     * - The request is not already loading or have not failed.
     * - Or if there is no sell selected currently.
     */
    const sellSelectedAllowedToChange = useMemo(
        () =>
            !!(
                !!(sellSelectedStatus !== 'loading' && sellSelectedStatus !== 'failed') ||
                !!(sellSelectedStatus !== 'loading' && !sellSelected)
            ),
        [sellSelected, sellSelectedStatus],
    );

    /**
     * Mandatory to select sell from command.
     */
    const isSelectedFolioExistsInCommandFolios = useMemo(() => {
        if (commandSelected && commandSelected.dossiers && commandSelected.dossiers?.length > 0) {
            commandSelected.dossiers.find((folio) => {
                if (!folioSelected?.id) return false;

                return jsonLdIdScraper(folio) === folioSelected.id;
            });
        }
    }, [commandSelected, folioSelected?.id]);

    const findSellInFolio = useCallback((): boolean => {
        if (!folioSelected) return false;

        const sellIri = folioSelected.facture;

        if (sellIri) {
            selectNewSell(jsonLdIdScraper(sellIri));
            return true;
        }

        return false;
    }, [folioSelected, selectNewSell]);

    const findSellInCommand = useCallback((): boolean => {
        if (!commandSelected) return false;

        const sellId = commandSelected.idFacture;

        if (sellId && isSelectedFolioExistsInCommandFolios && sellSelectedAllowedToChange) {
            selectNewSell(sellId);
            return true;
        }

        return false;
    }, [
        sellSelectedAllowedToChange,
        isSelectedFolioExistsInCommandFolios,
        commandSelected,
        selectNewSell,
    ]);

    /** First check is there is at least a sell at all. */
    /** If not, return undefined */
    /** If one, return it */
    /** If more than one, then apply logic defined above */

    /**
     * Will find sell in command first ;
     * If not found, will check folio ;
     * If not found trigger log.
     */
    const sellFinder = useCallback(() => {
        const isSellInCommand = findSellInCommand();

        if (isSellInCommand) {
            verbose.log('Sells found into the command !');
            return 'command';
        }

        const isSellInFolio = findSellInFolio();

        if (isSellInFolio) {
            verbose.log('Sells found into the folio !');
            return 'folio';
        }

        verbose.log('no sells found');
        return undefined;
    }, [findSellInCommand, findSellInFolio, verbose]);

    return {
        sellFinder,
    };
};

export default useFinderMVSell;
