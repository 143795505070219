/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Container } from '@mui/system';
import { CircularProgress } from '@mui/material';
import {
    PrescriberHeader,
    ModificationDate,
    FlexyTabs,
    TabStructure,
    Toolbar,
    ToolbarProps,
    NotFound,
    ModificationDateProps,
} from '@europrocurement/flexy-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { useSociete, useTiersService } from '@europrocurement/l2d-domain';
import { getSanitizedId } from '@b2d/utils/sanitize';
import { useParams } from 'react-router';
import { TiersPrescripteurTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { useSelectPrescriber, useUnSelectPrescriber } from '@b2d/hooks/prescriberHooks';
import {
    Billing,
    Contacts,
    Activities,
    LegalAdvert,
    Offers,
    Pricing,
    Resume,
    Formalities,
} from './sections';

import { PRESCRIBER_LIST } from '../../constants/paths';

const PrescriberDetail: React.FunctionComponent = function () {
    const { request, requestState } = useApiRequest<TiersPrescripteurTiersRead>();
    const { response: prescriber, success, isLoaded, notFound } = requestState;
    const { prescribersApi } = useTiersService();
    const { idPrescripteur: id } = useParams();

    const selectPrescriber = useSelectPrescriber();
    const unSelectPrescriber = useUnSelectPrescriber();

    React.useEffect(() => {
        if (id) {
            selectPrescriber(+id);
        }
        return () => {
            unSelectPrescriber();
        };
    }, []);

    const xIdSociete = useSociete();
    const getPrescriber = () => prescribersApi.getTiersTiersItem({ id: id ?? '', xIdSociete });

    /** Toolbar / Breadcrumb ** LazyBreadcrumb path */
    const breadcrumpPath = [
        {
            icon: {
                icon: ['fasl', 'house'] as IconProp,
                sx: {
                    alignItems: 'center',
                    display: 'flex',
                },
            },
            path: 'Accueil',
            link: '/',
        },
        {
            link: PRESCRIBER_LIST,
            path: 'Prescripteurs',
        },
        '',
    ];

    /** Toolbar ** actions ( waiting definition ) */
    const sendSomething = () => {};
    const editPrescriber = () => {};
    const toggleDropdown = () => {};

    /** DEV: Used to filter availables / WIP tabs */
    const tabsShown = ['resume', 'infos', 'formalities'];

    const toolbarActions: ToolbarProps['actions'] = [
        {
            onClick: sendSomething,
            icon: ['fasl', 'paper-plane'],
        },
        {
            onClick: editPrescriber,
            icon: ['fasl', 'pen'],
        },
        {
            onClick: toggleDropdown,
            icon: ['fasl', 'ellipsis'],
        },
    ];
    const getModificationDateProps = (): ModificationDateProps => {
        const creationDate = prescriber?.dateCreation;
        const updateDate = prescriber?.dateMAJ;
        const isCreationDate = !updateDate && creationDate;

        return {
            date: new Date(isCreationDate ? creationDate : updateDate),
            isCreationDate,
        };
    };

    const responseWrapper = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50px',
    };

    const canRender = prescriber && isLoaded && success;
    const fetchPrescriber = () =>
        request(getPrescriber(), {
            withToaster: false,
        });

    useEffect(() => {
        if (getSanitizedId(id)) {
            fetchPrescriber();
        }
    }, []);

    if (!canRender) {
        if (notFound) {
            return <NotFound />;
        }

        return (
            <Container maxWidth="lg">
                <Box
                    display="flex"
                    justifyContent="center"
                    sx={responseWrapper}
                >
                    {!isLoaded ? <CircularProgress /> : <p>Une erreur est survenue</p>}
                </Box>
            </Container>
        );
    }
    const tabProps = {
        prescriber,
        fetchPrescriber,
    };

    const tabs: Array<TabStructure> = [
        {
            tabName: 'resume',
            tabTitle: 'Synthèse',
            tabPanel: <Resume {...tabProps} />,
        },
        {
            tabName: 'infos',
            tabTitle: 'Information & contacts',
            tabPanel: <Contacts {...tabProps} />,
        },
        {
            tabName: 'legal-advert',
            tabTitle: 'Annonces légales',
            tabPanel: <LegalAdvert />,
            count: 4,
        },
        {
            tabName: 'formalities',
            tabTitle: 'Formalités',
            tabPanel: <Formalities {...tabProps} />,
        },
        {
            tabName: 'billing',
            tabTitle: 'Comptabilités',
            tabPanel: <Billing />,
        },
        {
            tabName: 'offers',
            tabTitle: 'Offres',
            tabPanel: <Offers />,
        },
        {
            tabName: 'pricing',
            tabTitle: 'Grille tarifaire',
            tabPanel: <Pricing />,
        },
        {
            tabName: 'activities',
            tabTitle: 'Activités',
            tabPanel: <Activities />,
        },
    ];

    const hasTimestamp = prescriber?.dateMAJ || prescriber?.dateCreation;
    return (
        <Container maxWidth="lg">
            <Toolbar
                path={breadcrumpPath}
                actions={toolbarActions}
                previousPath={PRESCRIBER_LIST}
            >
                {hasTimestamp && (
                    <Box sx={{ opacity: 0.5 }}>
                        <ModificationDate {...getModificationDateProps()} />
                    </Box>
                )}
            </Toolbar>
            <PrescriberHeader prescriber={prescriber} />
            <FlexyTabs
                routerParameterName="prescriber-detail"
                sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
                tabs={tabs.filter((tab) => tabsShown.includes(tab.tabName))}
                scrollbar={false}
            />
        </Container>
    );
};

export default PrescriberDetail;
