import * as React from 'react';
import { Box } from '@mui/material';
import LineActionButton, { LineActionButtonProps } from './LineActionButton';

type ComponentProps = {
    actions: LineActionButtonProps[];
    lineIndex: number;
    disabled?: boolean;
};

const LineActions: React.FunctionComponent<ComponentProps> = function ({
    actions,
    lineIndex,
    disabled = false,
}) {
    const keyGenerate = (actionName: string) => `Line_action_${actionName}_${lineIndex}`;
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {actions.map(({ actionName, ...rest }) => (
                <LineActionButton
                    key={keyGenerate(actionName)}
                    {...{ actionName, disabled, ...rest }}
                />
            ))}
        </Box>
    );
};

export default LineActions;
