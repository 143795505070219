import React, { useEffect } from 'react';
import { setXIdSociete, useSociete } from '@europrocurement/l2d-domain';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';
import { CenterCircularProgress } from '@europrocurement/flexy-components';

/**
 * UtilisateurRouter wrapper for setting default society L2D.
 */
export const UtilisateursOutlet: React.FunctionComponent = function () {
    const dispatch = useDispatch();
    const xIdSociete = useSociete();

    useEffect(() => {
        if (xIdSociete !== 12) {
            dispatch(setXIdSociete(12));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (xIdSociete === 12) {
        return <Outlet />;
    }
    return <CenterCircularProgress />;
};
