import { DossierOpenSearch, useFormaliteService } from '@europrocurement/l2d-domain';

const useDossierSiblings = () => {
    const { dossierOpenSearchApi } = useFormaliteService();

    const getDossierSiblings = (collectionId: number) =>
        new Promise((resolve, reject) => {
            dossierOpenSearchApi
                .apiOpensearchDossiersGetCollection({ collectionId: [collectionId] })
                .then((res) => res.data['hydra:member'] as Array<DossierOpenSearch>)
                .then((res) => resolve(res))
                .catch(() => reject());
        });

    return { getDossierSiblings };
};

export default useDossierSiblings;
