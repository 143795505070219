/** Catalog listing : filters options */

import { FilterOptionValue } from '@europrocurement/flexy-datatable';
import { CachedOption } from '@europrocurement/l2d-domain';
import { useSelector } from 'react-redux';
import { formatOptionsByKeys } from '../forms/options';
import {
    cachedDomainsSelector,
    cachedGroupsSelector,
    cachedOffersSelector,
    cachedOriginsSelector,
} from '../cache/selectors';

/** Select redux data and format them as options */
const useFilterOptions = () => {
    const cachedDomains = useSelector(cachedDomainsSelector);
    const cachedOrigins = useSelector(cachedOriginsSelector);
    const cachedOffers = useSelector(cachedOffersSelector);
    const cachedGroups = useSelector(cachedGroupsSelector);

    const originOptions = formatOptionsByKeys(
        cachedOrigins ?? [],
        'id' as keyof CachedOption,
        'origine' as keyof CachedOption,
    ) as FilterOptionValue[];

    const offerOptions = formatOptionsByKeys(
        cachedOffers ?? [],
        'id' as keyof CachedOption,
        'libelle' as keyof CachedOption,
    ) as FilterOptionValue[];

    const groupOptions = formatOptionsByKeys(
        cachedGroups ?? [],
        'id' as keyof CachedOption,
        'libelle' as keyof CachedOption,
    ) as FilterOptionValue[];

    const domainOptions = formatOptionsByKeys(
        cachedDomains ?? [],
        'id' as keyof CachedOption,
        'libelle' as keyof CachedOption,
    ) as FilterOptionValue[];

    return {
        origins: originOptions,
        offers: offerOptions,
        domains: domainOptions,
        groups: groupOptions,
    };
};
export default useFilterOptions;
