import React, { ReactNode } from 'react';
import { FlexyFormLabel } from '@europrocurement/flexy-components';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormHelperText, Input } from '@mui/material';

export type ControlledTextareaProps = {
    name: string;
    inputlabel?: ReactNode;
    rules?: RegisterOptions;
    required?: boolean;
};

const ControlledTextarea: React.FunctionComponent<ControlledTextareaProps> = function ({
    name,
    rules,
    inputlabel,
    required,
    ...inputProps
}) {
    const { control } = useFormContext();

    const widgetProps = {
        ...inputProps,
        sx: {
            width: '100%',
            minHeight: '250px',
            verticalAlign: 'top',
            display: 'flex',
            alignItems: 'flex-start',
        },
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                    {inputlabel && inputlabel !== '' ? (
                        <FlexyFormLabel
                            required={required}
                            data-testid="test-id-FlexyInput-label"
                        >
                            {inputlabel}
                        </FlexyFormLabel>
                    ) : null}

                    <Input
                        {...widgetProps}
                        multiline
                        value={value}
                        onChange={onChange}
                    />
                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledTextarea;
