import React from 'react';
import { MenuEntry } from '@europrocurement/flexy-components';

import { publisherIcon } from '@europrocurement/l2d-icons';
import PublisherDetail from './views/detail/PublisherDetail';
import PublishersListing from './views/listing/PublishersListing';
import PublisherRoot from './views/PublisherRoot';
import { PUBLISHERS_LIST, PUBLISHERS_REMINDER_LIST, publishersBasePath } from './constants/paths';
import PublishersRemindersListing from './views/listing/PublishersRemindersListing';

export const PublisherRouter = {
    path: publishersBasePath,
    element: <PublisherRoot />,
    children: [
        {
            path: 'liste',
            children: [
                {
                    path: '',
                    index: true,
                    element: <PublishersListing />,
                },
                {
                    path: ':idPublisher',
                    element: <PublisherDetail />,
                },
            ],
        },
        {
            path: 'rappels',
            element: <PublishersRemindersListing />,
        },
    ],
};

export const PublisherMenuEntries: MenuEntry[] = [
    {
        title: `${publisherIcon.displayName}s`,
        icon: publisherIcon,
        href: PUBLISHERS_LIST,
        role: 'realm:interne',
        children: [
            {
                title: 'Rappels',
                icon: 'reply-clock',
                href: PUBLISHERS_REMINDER_LIST,
                role: 'realm:interne',
            },
        ],
    },
];

export default PublisherRouter;
