import React from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { Box, Card } from '@mui/material';
import { useSelector } from 'react-redux';
import { LegalStatus, legalStatusSelector } from '@europrocurement/l2d-domain';
import NoResultPreview from '../../components/NoResultPreview';
import StaticDatatable from '../../components/StaticDatable';
import TableRowActions from '../../components/TableRowActions';

type FormalityLegalStatusesCardProps = {
    formality: Formalite;
    onUpdateLegalStatuses: (selectedItems: (string | number)[]) => void;
};

const FormalityLegalStatusesCard: React.FunctionComponent<FormalityLegalStatusesCardProps> =
    function (props) {
        const { formality, onUpdateLegalStatuses } = props;
        const availableStatuses = useSelector(legalStatusSelector).data;
        const formalityLegalStatuses = formality.legalStatuses ? formality.legalStatuses : [];
        const rows = availableStatuses
            .filter(
                (item: LegalStatus) =>
                    item.id && formalityLegalStatuses.indexOf(item.id as unknown as string) !== -1,
            )
            .sort((a: LegalStatus, b: LegalStatus) => {
                const aLabel = a.libelle as unknown as string;
                const bLabel = b.libelle as unknown as string;

                if (aLabel.toLowerCase() < bLabel.toLowerCase()) {
                    return -1;
                }

                if (aLabel.toLowerCase() > bLabel.toLowerCase()) {
                    return 1;
                }

                return 0;
            });

        const onRemoveLegalStatus = (legalStatus: LegalStatus) => {
            const statuses = formalityLegalStatuses.filter(
                (item: string | number) => item !== legalStatus.id,
            );
            onUpdateLegalStatuses(statuses);
        };

        if (!rows.length) {
            return (
                <Card>
                    <NoResultPreview message="Aucune forme juridique n'est associée à cette formalité" />
                </Card>
            );
        }

        const celStyle = {
            display: 'flex',
            alignItems: 'center',
            minHeight: '40px',
        };
        const columns = [
            {
                label: 'Abréviation',
                field: 'abreviation',
                isDisplayed: true,
                render: (item: LegalStatus) => <Box style={celStyle}>{item.abreviation}</Box>,
            },
            {
                label: 'Nom',
                field: 'libelle',
                isDisplayed: true,
                render: (item: LegalStatus) => <Box style={celStyle}>{item.libelle}</Box>,
            },
            {
                label: 'Actions',
                displayLabel: false,
                isDisplayed: true,
                render: (item: LegalStatus) => (
                    <TableRowActions
                        itemId={item.id as unknown as string}
                        removeItem={() => {
                            onRemoveLegalStatus(item);
                        }}
                    />
                ),
            },
        ];

        return (
            <Card>
                <StaticDatatable
                    data={rows}
                    columns={columns}
                    totalItemsCount={formality?.legalStatuses?.length || 0}
                />
            </Card>
        );
    };

export default FormalityLegalStatusesCard;
