/* eslint-disable react-hooks/exhaustive-deps */
import { BasicButton, FlexyFormLabel, ActionButton } from '@europrocurement/flexy-components';
import { faPlus, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ContactFormValues } from './form';

const MAX_ADDITIONAL_CONTACTS = 10;

type Props = {
    type: 'phone' | 'email';
    context: UseFormReturn<ContactFormValues, unknown>;
};

/*
 * Optional contacts managment ( emails / phones ):
 * - Contact can be added / removed
 * - List has a max limit ( MAX_ADDITIONAL_CONTACTS )
 * - Unfilled, empty fields are cleaned on validation
 */
const OptionalContactList: React.FunctionComponent<Props> = function (props) {
    const { type, context } = props;
    const {
        formState: { errors, isSubmitting },
        control,
        getValues,
        setValue,
        watch,
    } = context;

    const isPhone = type === 'phone';
    const typeLabel = isPhone ? 'un téléphone' : 'une adresse e-mail';
    const baseInput = isPhone ? 'optionalPhones' : ('optionalEmails' as const);
    const inputLabel = isPhone ? 'Téléphone' : 'E-mail';
    const label = `Ajouter ${typeLabel}`;
    const list = getValues()[baseInput] ?? [];
    const contactLength = list.length;
    const maxContactsReached = contactLength >= MAX_ADDITIONAL_CONTACTS;

    useEffect(() => {}, [watch(baseInput)]);

    /**
     * Remove all empty contacts from list
     */
    const cleanEmptyContacts = (): void => {
        const newList = list.filter((element) => element !== '');
        setValue(baseInput, newList);
    };

    /**
     * Delete contact in optional contacts list
     */
    const removeContact = (deletedIndex: number): void => {
        const newList = list.filter((_, index) => index !== deletedIndex);
        setValue(baseInput, newList);
    };

    /**
     * Append a new contact to the optional contacts list
     */
    const appendNewInput = (): void => {
        if (maxContactsReached) {
            return;
        }
        const newList = [...list, ''];
        setValue(baseInput, newList);
    };

    /**
     * Remove all unecessary contacts at form validation ( submit )
     */
    useEffect(() => {
        if (isSubmitting) {
            cleanEmptyContacts();
        }
    }, [isSubmitting]);

    return (
        <Box sx={{ marginTop: '7px' }}>
            {list &&
                list.length > 0 &&
                list.map((_contact, index) => {
                    const inputName = `${baseInput}.${index}` as const;
                    const key = `${index}_contact_${baseInput}`;
                    const errorMessage = errors?.[baseInput]?.[index]?.message;

                    return (
                        <Box key={key}>
                            <FlexyFormLabel>{inputLabel}</FlexyFormLabel>
                            <Controller
                                name={inputName}
                                control={control}
                                render={({ field }) => (
                                    <Box sx={{ display: 'flex' }}>
                                        <TextField
                                            sx={{ width: '100%' }}
                                            {...field}
                                            name={field.name}
                                            error={!!errorMessage} // TODO
                                            helperText={errorMessage} // TODO
                                            required={false}
                                        />
                                        <ActionButton
                                            onClick={() => removeContact(index)}
                                            icon={faTrash}
                                            sx={{ marginLeft: '5px' }}
                                        />
                                    </Box>
                                )}
                            />
                        </Box>
                    );
                })}
            {!maxContactsReached && (
                <Box sx={{ marginTop: '8px' }}>
                    <BasicButton
                        icon={faPlus}
                        onClick={appendNewInput}
                        label={label}
                    />
                </Box>
            )}
        </Box>
    );
};

export default OptionalContactList;
