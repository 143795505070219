import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import { useDispatch } from 'react-redux';
import {
    COMMANDES_SLICE_NAME,
    DOSSIERS_FORMALITES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    FACTURE_VENTE_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { useMultiAnnonceContext } from '../../../../views/forms/multi/RegisterMultiInvoiceView';

type NextEditDossierSaisieMultipleProps = {
    isFormalite?: boolean;
};

export const NextEditDossierSaisieMultiple: React.FunctionComponent<NextEditDossierSaisieMultipleProps> =
    function ({ isFormalite = false }: NextEditDossierSaisieMultipleProps) {
        const { factureachatid, dossiernum } = useParams();
        const { mediaObject } = useMultiAnnonceContext();
        const navigate = useNavigate();
        const dispatch = useDispatch();

        if (!factureachatid) {
            console.error('FactureAchatId is undefined !');
        }

        useEffect(() => {
            dispatch({
                type: `${DOSSIERS_FORMALITES_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${DOSSIERS_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${COMMANDES_SLICE_NAME}/deletemainSelected`,
            });

            const res = generateB2DPath(
                isFormalite ? 'formmultieditfprodossier' : 'formmultieditdossier',
                {
                    factureAchat: { id: Number(factureachatid) },
                    dossiernum,
                    mediaObjectId: mediaObject.id,
                },
            );

            if (res.status === 'OK') {
                navigate(res.path);
            } else {
                // TODO
            }
        }, [dispatch, dossiernum, factureachatid, isFormalite, mediaObject, navigate]);

        return null;
    };

export default NextEditDossierSaisieMultiple;
