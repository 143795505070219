import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';

import {
    ColumnDatatable,
    FilterDatatable,
    FilterOptionValue,
    FiltersDatatableList,
} from '@europrocurement/flexy-datatable';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import { CatalogueJsonldCatalogueRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { UnknownAction } from 'redux';
import { addItem } from '@europrocurement/l2d-icons';
import { useOffreService } from '@europrocurement/l2d-domain';
import ListingWithPreview from '../components/ListingWithPreview';
import Form from './Form/Form';

import { displayCustomValue, getStringColumn } from '../listings';
import useFilterOptions from './useFilterOptions';

const Panel: React.FunctionComponent = function () {
    const { modalActions } = React.useContext(ModalContext);

    const { catalogModel: model } = useOffreService();
    const filterOptions = useFilterOptions();

    const dispatch = useDispatch();

    const reloadList = () =>
        dispatch(model.datasourcesThunks.main.getData({}) as unknown as UnknownAction);

    const listingButton = {
        label: 'Créer un nouveau catalogue',
        icon: addItem,
        action: () =>
            modalActions.call(
                <Form
                    isCreate
                    afterSubmit={reloadList}
                />,
            ),
    };

    const columns: ColumnDatatable<CatalogueJsonldCatalogueRead>[] = [
        {
            ...getStringColumn('id'),

            sortable: true,
        },
        {
            ...getStringColumn('nomCommercial', undefined, 60),
            sortable: true,
        },
        {
            ...getStringColumn('dateDebut', 'Début'),
            sortable: true,
        },
        {
            ...getStringColumn('dateFin', 'Fin'),
            sortable: true,
        },
        {
            ...displayCustomValue('groupement', (group) => group.libelle, 'Groupement', 14),
            sortable: true,
        },
        {
            ...displayCustomValue('offre', (offer) => offer.libelle, 'Offre'),
            sortable: true,
        },
        {
            label: 'Domaine',
            displayLabel: true,
            render: (item) => {
                const domain = item.domaine as { libelle?: string };
                return (
                    <Box sx={{ justifyContent: 'end' }}>
                        <p>{domain?.libelle ?? '(aucun domaine)'}</p>
                    </Box>
                );
            },
            isDisplayed: true,
            sortable: false,
            field: 'domaine',
        },
    ];

    /** Generic function : Generate filter object */
    const getFilter = (
        label: string,
        field: string,
        options: FilterOptionValue[],
    ): FilterDatatable => ({
        label,
        field,
        type: 'staticSelect' as const,
        options,
        renderFilterList: (value: string) => {
            const selectedOption = options.find((option) => option.value === value);
            return selectedOption ? selectedOption.label : 'Aucun';
        },
    });

    const filters: FiltersDatatableList = [
        getFilter('Domaine', 'domaineId', filterOptions.domains),
        getFilter('Offre', 'offreId', filterOptions.offers),
        getFilter('Groupement', 'groupementId', filterOptions.groups),
        getFilter('Origine', 'origineId', filterOptions.origins),
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des catalogues"
            listingButton={listingButton}
            listingColumns={columns}
            form={Form}
            model={model}
            filters={filters}
            filtersControl
        />
    );
};

export default Panel;
