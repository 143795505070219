import React, { useCallback, useEffect, useState } from 'react';

import {
    CategorieStatsSelector,
    customizerSelector,
    factureAchatApi,
    getCategorieStats,
    getCodeRejetStats,
    getFactureAchatStats,
} from '@b2d/redux/RootStore';
// import { DataCard } from '@europrocurement/flexy-components';
import { invoiceToTreatManuallyIcon } from '@europrocurement/l2d-icons';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { CategorieStats } from '@europrocurement/l2d-domain';
import useLazyDispatch from '@b2d/hooks/useLazyDispatch';
// import useAsyncStatus from '@b2d/hooks/useAsyncStatus';
// import useHandleError from '@europrocurement/l2d-hooks/hooks/useHandleError';
// import CentralDataCardStat from './components/CentralDataCardStat';
import { AppDispatch } from '@b2d/redux/types';
import WidgetWithAction from './components/WidgetWithAction';

// All

const FacturesATraiterManuellement: React.FunctionComponent = function () {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { xIdSociete } = useSelector(customizerSelector);

    const categoriesDataSource: DataSource<CategorieStats> =
        useSelector(CategorieStatsSelector).main;

    const lazyDispatch = useLazyDispatch({
        sliceName: categoriesDataSource.slicename,
        dataSourceName: categoriesDataSource.name,
    });

    const [
        countOfUnregisteredInvoicesToTreatManually,
        setCountOfUnregisteredInvoicesToTreatManually,
    ] = useState<string>();

    useEffect(() => {
        factureAchatApi
            .getFacturesAchatsFactureAchatCollection({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: true,
                deleted: false,
                codesRejetsCodeRejetCategorie: 'VE',
                codesRejetsEtat: 'todo',
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setCountOfUnregisteredInvoicesToTreatManually(
                        `${res.data['hydra:totalItems']}`,
                    );
                }
            });
    }, [xIdSociete]);

    const clearPreviousFilters = useCallback(() => {
        lazyDispatch({
            target: 'Filter',
            action: 'reset',
        })();
    }, [lazyDispatch]);

    const applyFilters = useCallback(() => {
        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload: {
                key: 'codesRejetsEtat',
                value: 'todo',
            },
        })();
        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload: {
                key: 'categories',
                value: ['VE'],
            },
        })();
    }, [lazyDispatch]);

    const beforeActionCallback = useCallback(() => {
        clearPreviousFilters();
        applyFilters();
    }, [applyFilters, clearPreviousFilters]);

    const actionCallback = useCallback(async () => {
        dispatch(getCategorieStats({}));
        dispatch(getCodeRejetStats({}));
        await dispatch(getFactureAchatStats({}));
    }, [dispatch]);

    const onActionSuccessCallback = useCallback(() => {
        navigate('/achats/stats?tab=detail');
    }, [navigate]);

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <WidgetWithAction
                title="Factures à traiter manuellement"
                icon={invoiceToTreatManuallyIcon}
                actionMessage="Traiter"
                actionCallback={actionCallback}
                data={countOfUnregisteredInvoicesToTreatManually}
                paragraph="Factures en VEAA, VEADJ, VEMP :"
                color="VE"
                loadingMessage="Application des filtres..."
                beforeActionCallback={beforeActionCallback}
                onActionSuccessCallback={onActionSuccessCallback}
            />
        </Grid>
    );
};

export default FacturesATraiterManuellement;
