/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { FlexyForm, FormObject, FormStructure } from '@europrocurement/flexy-form';
import HeaderStructure from '@b2d/pages/Achats/components/fragments/HeaderStructure';
import { FaOptionIcon, publisherIcon } from '@europrocurement/l2d-icons';
import {
    CodeRejet,
    CODES_REJETS_AUTOCOMPLETE_DATASOURCE_NAME,
    Fournisseur,
    useSociete,
} from '@europrocurement/l2d-domain';
import { ExceptionFacturanteApiPostExceptionFacturanteCollectionRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { useCurrentPublisher, usePublisherDatasource } from '@b2d/hooks/publisherHooks';
import {
    codeRejetDataSourcesThunks,
    defFrequenceDataSourcesThunks,
    exceptionFacturanteApi,
    fournisseurDataSourcesThunks,
} from '@b2d/redux/RootStore';

import { AppDispatch } from '@b2d/redux/types';

import {
    DEF_FREQUENCE_AUTOCOMPLETE_DATASOURCE_NAME,
    DefFrequence,
} from '@europrocurement/l2d-domain/reducers/achats/slices/defFrequenceSlice';
import { useFrequency } from '@b2d/hooks/useFrequency';
import { useRejectCode } from '@b2d/hooks/useRejectCode';
import { useDispatch } from 'react-redux';

// import { castIriToId } from '@b2d/utils/iriParser';

type CreateExceptionFormProps = {
    afterSubmit: () => void;
};

const CreateExceptionForm: React.FunctionComponent<CreateExceptionFormProps> = function ({
    afterSubmit,
}: CreateExceptionFormProps) {
    const publisherDatasource = usePublisherDatasource();
    const publisherSelected = useCurrentPublisher();
    const publisherFetchData = fournisseurDataSourcesThunks.main.getData;
    const isPublisherAlreadySelected = !publisherSelected || !publisherSelected.id;

    const { dataSource: rejectCodeDatasource } = useRejectCode({});
    const { dataSource: rejectCodeAutocompleteDatasource } = useRejectCode({
        dataSourceName: CODES_REJETS_AUTOCOMPLETE_DATASOURCE_NAME,
    });
    const rejectCodeFetchData = codeRejetDataSourcesThunks.main.getData;
    const rejectCodeAutocompleteFetchData =
        codeRejetDataSourcesThunks[CODES_REJETS_AUTOCOMPLETE_DATASOURCE_NAME].data.fetchByCode;

    const { dataSource: frequencyDatasource } = useFrequency({});
    const { dataSource: frequencyAutocompleteDatasource } = useFrequency({
        dataSourceName: DEF_FREQUENCE_AUTOCOMPLETE_DATASOURCE_NAME,
    });
    const frequencyFetchData = defFrequenceDataSourcesThunks.main.getData;
    const frequencyAutocompleteFetchData =
        defFrequenceDataSourcesThunks[DEF_FREQUENCE_AUTOCOMPLETE_DATASOURCE_NAME].data
            .fetchByDfrLibelle;

    const initialValues: ExceptionFacturanteApiPostExceptionFacturanteCollectionRequest['postExceptionFacturanteCollectionRequest'] =
        {
            idFacturante: publisherSelected?.id || -1,
            codeRejet: 'ERCA1',
            relanceAuto: false,
            idFrequenceRelance: 1,
        };

    const xIdSociete = useSociete();

    const handleSubmit = React.useCallback<
        NonNullable<React.ComponentProps<typeof FlexyForm>['onSubmit']>
    >(
        async (values: FormObject) => {
            console.log(values);
            let fineRejectCode = `/achats/code_rejets/${values.codeRejet}`;
            let fineFrequency = `/achats/def_frequences/${values.idFrequenceRelance}`;

            if (
                typeof values.codeRejet === 'object' &&
                (values.codeRejet as CodeRejet)['@id'] !== undefined
            ) {
                fineRejectCode = (values.codeRejet as CodeRejet)['@id'] as unknown as string;
            }

            if (
                typeof values.idFrequenceRelance === 'object' &&
                (values.idFrequenceRelance as DefFrequence).dfrId !== undefined
            ) {
                fineFrequency = (values.idFrequenceRelance as DefFrequence)
                    .dfrId as unknown as string;
            }

            console.log(fineRejectCode);

            const requestParameters: ExceptionFacturanteApiPostExceptionFacturanteCollectionRequest =
                {
                    xIdSociete,
                    postExceptionFacturanteCollectionRequest: {
                        idFacturante: values.idFacturante as number,
                        relanceAuto: values.relanceAuto as boolean,
                        idFrequenceRelance: fineFrequency as unknown as number,
                        codeRejet: fineRejectCode,
                    },
                };

            exceptionFacturanteApi
                .postExceptionFacturanteCollection(requestParameters)
                .then((res) => {
                    console.log(res);
                    if (afterSubmit) {
                        afterSubmit();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        [afterSubmit, xIdSociete],
    );

    const dispatch = useDispatch<AppDispatch>();

    const defaultRejectCode = React.useMemo(() => {
        if (!rejectCodeDatasource.data || rejectCodeDatasource.data.length === 0) {
            dispatch(rejectCodeFetchData({}));
        }
        // const code = castIriToId(initialValues.codeRejet);
        return rejectCodeDatasource.data.find(
            (codeRejet: CodeRejet) => codeRejet.code === initialValues.codeRejet,
        );
    }, [dispatch, initialValues.codeRejet, rejectCodeDatasource.data, rejectCodeFetchData]);

    const defaultFrequency = React.useMemo(() => {
        if (!frequencyDatasource.data || frequencyDatasource.data.length === 0) {
            dispatch(frequencyFetchData({}));
        }

        return frequencyDatasource.data.find(
            (frequency: DefFrequence) => frequency.dfrId === initialValues.idFrequenceRelance,
        );
    }, [dispatch, frequencyDatasource.data, frequencyFetchData, initialValues.idFrequenceRelance]);

    const selectPublisherFormStructure: FormStructure = React.useMemo(
        () => ({
            type: 'autocompletestore',
            name: 'idFacturante',
            inputlabel: 'Fournisseur',
            dataSource: publisherDatasource,
            fetchData: publisherFetchData,
            renderField: 'id',
            idProperty: 'id',
            debounce: 1000,
            rules: {
                required: 'Vous devez renseigner le Fournisseur.',
            },
            events: {
                onClear: (formContext: any) => {
                    formContext.setValue('idFacturante', '');
                },
            },
            getOptionLabel: (item: Fournisseur) => `${item.id}` || '',
            // Se rends dans les options
            simpleRenderOption: (item: Fournisseur) => `${item.id}`,
            searchability: (terms: string) =>
                terms.length >= 2 ? true : 'Veuillez saisir au moins 2 caractères',
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
        }),
        [publisherDatasource, publisherFetchData],
    );

    const selectRejectCodeFormStructure: FormStructure = React.useMemo(
        () => ({
            type: 'autocompletestore',
            name: 'codeRejet',
            inputlabel: 'Code Rejet',
            dataSource: rejectCodeAutocompleteDatasource,
            fetchData: rejectCodeAutocompleteFetchData,
            renderField: 'code',
            idProperty: 'code',
            debounce: 1000,
            defaultInputValue: defaultRejectCode ? defaultRejectCode.code : undefined,
            defaultValue: defaultRejectCode ? defaultRejectCode.code : undefined,
            rules: {
                required: 'Vous devez renseigner le Code Rejet.',
            },
            events: {
                onClear: (formContext: any) => {
                    formContext.setValue('codeRejet', '');
                },
            },
            getOptionLabel: (item: CodeRejet) => `${item.code}` || '',
            // Se rends dans les options
            simpleRenderOption: (item: CodeRejet) => `${item.code}`,
            searchability: (terms: string) =>
                terms.length >= 2 ? true : 'Veuillez saisir au moins 2 caractères',
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
        }),
        [defaultRejectCode, rejectCodeAutocompleteDatasource, rejectCodeAutocompleteFetchData],
    );

    const selectFrequencyFormStructure: FormStructure = React.useMemo(
        () => ({
            type: 'autocompletestore',
            name: 'idFrequenceRelance',
            inputlabel: 'Fréquence de Relance',
            dataSource: frequencyAutocompleteDatasource,
            fetchData: frequencyAutocompleteFetchData,
            renderField: 'dfrLibelle',
            idProperty: 'dfrId',
            debounce: 1000,
            defaultInputValue: defaultFrequency ? defaultFrequency.dfrLibelle : undefined,
            defaultValue: defaultFrequency ? defaultFrequency.dfrLibelle : undefined,
            rules: {
                required: 'Vous devez renseigner la fréquence de la relance.',
            },
            events: {
                onClear: (formContext: any) => {
                    formContext.setValue('idFrequenceRelance', '');
                },
            },
            getOptionLabel: (item: DefFrequence) => `${item.dfrLibelle}` || 'Pas de fréquence',
            // Se rends dans les options
            simpleRenderOption: (item: DefFrequence) => `${item.dfrLibelle || 'Pas de libelle'}`,
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
        }),
        [defaultFrequency, frequencyAutocompleteDatasource, frequencyAutocompleteFetchData],
    );

    const createExceptionFormStructure: Array<FormStructure> = React.useMemo(
        () => [
            {
                type: 'header',
                label: (
                    <HeaderStructure
                        icon={<FaOptionIcon {...publisherIcon.props} />}
                        title="Création d'une exception"
                    />
                ),
                sx: {
                    marginTop: '0px',
                },
                name: 'create_exception_header',
            },
            ...(isPublisherAlreadySelected ? [selectPublisherFormStructure] : []),
            ...[selectRejectCodeFormStructure],
            {
                type: 'boolean',
                name: 'relanceAuto',
                inputlabel: 'Relance Auto',
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
            },
            ...[selectFrequencyFormStructure],
        ],
        [
            isPublisherAlreadySelected,
            selectFrequencyFormStructure,
            selectPublisherFormStructure,
            selectRejectCodeFormStructure,
        ],
    );

    return (
        <FlexyForm
            onSubmit={handleSubmit}
            formObject={initialValues as unknown as FormObject}
            formStructure={createExceptionFormStructure}
        />
    );
};

export default CreateExceptionForm;
