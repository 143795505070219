import React from 'react';
import { BooleanBadge } from '@europrocurement/flexy-components';
import { Card, Grid, TextField, Typography, useTheme } from '@mui/material';
import { UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { User } from '../UserTabs/type';

export type UserGestionJustifProps = {
    user: User;
    handlePatchData: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
    ) => void;
    handleValidationModal: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => void;
};

export const UserGestionJustif: React.FunctionComponent<UserGestionJustifProps> = function ({
    user,
    handlePatchData,
    handleValidationModal,
}) {
    const theme = useTheme();

    // verify the input data modification before patch to the API
    const handleInputChange = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    ) => {
        const newValue = event.target.value;

        if (newValue !== '' && newValue !== undefined && newValue !== null) {
            // verify if the value has been changed before send it to the API
            if (newValue !== user.droitUtilisateur?.usdAppsJalgestOption) {
                handlePatchData({ droitUtilisateur: { usdAppsJalgestOption: newValue } });
            }
        }
    };

    return (
        <Card
            sx={{
                width: '100%',
                margin: '15px 0',
                padding: '15px',
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Typography
                variant="l2dh6"
                sx={{
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                    padding: '5px 0 5px 30px',
                    marginLeft: '-15px',
                }}
            >
                Transfert des Journaux d&apos;Annonces légales
            </Typography>
            <Grid
                sx={{
                    display: 'flex',
                    gap: '15px',
                    width: '100%',
                    margin: '10px',
                    alignItems: 'center',
                }}
            >
                <BooleanBadge
                    label="Accès à l'application JAL GEST"
                    value={user.droitUtilisateur?.usdAppsJalgest}
                    icon
                    toggleAction={() =>
                        handleValidationModal(
                            {
                                droitUtilisateur: {
                                    usdAppsJalgest: !user.droitUtilisateur?.usdAppsJalgest,
                                },
                            },
                            'usdAppsJalgest',
                            user.droitUtilisateur?.usdAppsJalgest as boolean,
                        )
                    }
                />

                <Typography variant="l2dh7">Numéro des JAL à gérer :</Typography>
                <TextField
                    data-testid="jaljest-option"
                    placeholder={user.droitUtilisateur?.usdAppsJalgestOption || 'Aucun'}
                    onBlur={(event) => handleInputChange(event)}
                    defaultValue={user.droitUtilisateur?.usdAppsJalgestOption}
                    sx={{ width: '50%' }}
                />
            </Grid>
            <Typography
                variant="l2dh6"
                sx={{
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                    paddingLeft: '30px',
                    margin: '30px 0 0 -15px',
                }}
            >
                Application Pointage des annonces XY
            </Typography>

            <BooleanBadge
                label="Accès au pointage des annonces XY"
                value={user.droitUtilisateur?.usdAppsXy}
                icon
                toggleAction={() =>
                    handleValidationModal(
                        {
                            droitUtilisateur: {
                                usdAppsXy: !user.droitUtilisateur?.usdAppsXy,
                            },
                        },
                        'usdAppsXy',
                        user.droitUtilisateur?.usdAppsXy as boolean,
                    )
                }
                sx={{ margin: '5px 0 0 5px' }}
            />
        </Card>
    );
};
