const getDayMonthPickerValue = function (defaultValue: unknown, value: unknown) {
    let stringDay: string = '01';
    let stringMonth: string = '01';

    if (defaultValue && typeof defaultValue === 'string') {
        [stringDay, stringMonth] = defaultValue.split('/');
    }
    if (value && typeof value === 'string') {
        [stringDay, stringMonth] = value.split('/');
    }

    if (
        stringDay === null ||
        stringMonth === null ||
        stringDay === undefined ||
        stringMonth === undefined ||
        stringDay === '' ||
        stringMonth === ''
    ) {
        stringDay = '01';
        stringMonth = '01';
    }

    return [stringDay, stringMonth];
};

export default getDayMonthPickerValue;
