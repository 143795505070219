import React, { ReactNode } from 'react';
import { Box, Button, SxProps, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faBarsFilter, faChevronDown, faChevronUp } from '@fortawesome/pro-duotone-svg-icons';
import CountBadge from '@europrocurement/flexy-components/components/atoms/CountBadge';
import { SearchOpts } from '../DatatableHeader';

export type FlexyFiltersToggleButtonProps = {
    searchOpts?: SearchOpts;
    showFilter: boolean;
    setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
    // buttonAction?: {
    //     show?: boolean;
    //     functionAction: () => void;
    //     label?: string;
    //     icon?: IconName;
    // };
    buttonAction?: ReactNode;
};

const boxStyleButtonOptional: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
};

const chevronStyle = {
    fontSize: '8px',
    lineHeight: '8px',
};

const FlexyFiltersToggleButton: React.FunctionComponent<FlexyFiltersToggleButtonProps> = function ({
    searchOpts,
    showFilter,
    setShowFilters,
    buttonAction,
}: FlexyFiltersToggleButtonProps) {
    const activeFiltersKeys = Object.keys(searchOpts?.filtersOpts?.activeFilters || {});
    const filters = searchOpts?.filtersOpts?.filters || [];
    const filtersKeys = filters.map((f) => f.field);
    const activeFiltersCount = activeFiltersKeys.filter((f) => filtersKeys.includes(f)).length;
    const filtersCount = filtersKeys.length;
    const theme = useTheme();

    // const hoverBackground = theme.palette.primary.light;
    // const hoverColor = theme.palette.getContrastText(hoverBackground);

    if (!filtersCount) {
        return null;
    }

    const counter = activeFiltersCount > 0 ? <CountBadge count={activeFiltersCount} /> : null;

    return (
        <Box sx={boxStyleButtonOptional}>
            {/* {buttonAction ? (
                <Button
                    sx={{
                        alignItems: 'baseline',
                        marginRight: '10px',
                        padding: '12px 16px 12px 16px',
                        borderRadius: '12px',
                        lineHeight: '24px',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        width: 'max-content',
                        fontWeight: 500,
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.getContrastText(theme.palette.primary.dark),
                        ':hover': {
                            backgroundColor: hoverBackground,
                            color: hoverColor,
                        },
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                    }}
                    onClick={buttonAction?.functionAction}
                >
                    {buttonAction?.icon ? (
                        <FaOptionIcon icon={['fasl', buttonAction.icon as IconName]} />
                    ) : null}
                    {buttonAction?.label ?? 'Nouveau'}
                </Button>
            ) : null} */}
            {buttonAction}

            <Button
                sx={{
                    padding: '12px 16px 12px 16px',
                    borderRadius: '12px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    fontFamily: 'Inter',
                    fontSize: '15px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    textAlign: 'left',
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.primary.dark,
                    borderColor: theme.palette.grey[300],
                }}
                onClick={() => {
                    setShowFilters((v) => !v);
                }}
            >
                <FaOptionIcon icon={faBarsFilter} />
                Filtrer
                {counter}
                {showFilter ? (
                    <Box sx={chevronStyle}>
                        <FaOptionIcon icon={faChevronDown} />
                    </Box>
                ) : (
                    <Box sx={chevronStyle}>
                        <FaOptionIcon icon={faChevronUp} />
                    </Box>
                )}
            </Button>
        </Box>
    );
};

export default FlexyFiltersToggleButton;
