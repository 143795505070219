import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { useDispatch } from 'react-redux';

import { todoIcon } from '@europrocurement/l2d-icons';
import { useFormaliteService } from '@europrocurement/l2d-domain';
import FormalityLegalStatusesCard from './FormalityLegalStatusesCard';
import LegalStatusModal from './LegalStatusModal';
import useFormalityFormHandler from '../../hooks/useFormalityFormHandler';
import CardTitle from '../../components/CardTitle';
import ActionButton from '../../components/ActionButton';

type FormalityLegalStatusesTabProps = {
    formality: Formalite;
};

const FormalityLegalStatusesTab: React.FunctionComponent<FormalityLegalStatusesTabProps> =
    function (props) {
        const { selectFormality } = useFormaliteService();

        const { formality } = props;
        const [editModalOpen, setEditModalOpen] = useState(false);
        const { updateFormality } = useFormalityFormHandler();
        const dispatch = useDispatch();

        const onOpenLegalStatusesModal = () => {
            setEditModalOpen(true);
        };
        const onCloseLegalStatusesModal = () => {
            setEditModalOpen(false);
        };
        const onEditLegalStatusesModal = (selectedItems: (string | number)[]) => {
            updateFormality(formality, { legalStatuses: selectedItems }).then(() => {
                dispatch(selectFormality({ id: formality.id }));
            });
        };

        return (
            <>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={10}
                    >
                        <CardTitle title="Liste des formes juridiques associées" />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={2}
                    >
                        <ActionButton
                            label="Modifier la liste"
                            onClick={onOpenLegalStatusesModal}
                            icon={todoIcon}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormalityLegalStatusesCard
                            formality={formality}
                            onUpdateLegalStatuses={onEditLegalStatusesModal}
                        />
                    </Grid>
                </Grid>
                <LegalStatusModal
                    isOpen={editModalOpen}
                    onModalClose={onCloseLegalStatusesModal}
                    onSubmitModal={onEditLegalStatusesModal}
                    itemsSelected={formality.legalStatuses}
                />
            </>
        );
    };

export default FormalityLegalStatusesTab;
