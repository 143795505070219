import React, { BaseSyntheticEvent, MouseEvent, useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { Box, Button, Stack, Typography, Menu, MenuItem } from '@mui/material';
import {
    CustomizerReducerType,
    setXIdSociete,
    type BDD_SWITCH_SOCIETES_IDS_TYPE,
    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID,
} from '@europrocurement/l2d-domain';
import { FlexyLayoutState } from '@europrocurement/flexy-components/redux/storeConfig/store';

export type DropdownSocieteProps = {
    externalDisabler?: boolean;
};

const DropdownSociete: React.FunctionComponent<DropdownSocieteProps> = function ({
    externalDisabler = false,
}: DropdownSocieteProps) {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const xIdSociete = useSelector((state: FlexyLayoutState) => state.customizer.xIdSociete);

    const [isOnUnauthorizedPage, setIsOnUnauthorizedPage] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClickSelectIdSociete = (event: MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleCloseSelectIdSociete = () => setAnchorEl(null);
    const handleChangeSociete = (e: BaseSyntheticEvent) => {
        dispatch(setXIdSociete(e.target.value as CustomizerReducerType['xIdSociete']));
        handleCloseSelectIdSociete();
    };

    /**
     * Verrouille l'accès à la fonction de changement de société tout en la gardant visible.
     */
    useEffect(() => {
        const listOfPath = ['achats', '/admin/offres', '/utilisateurs'];

        if (listOfPath.some((path) => pathname.includes(path))) {
            setIsOnUnauthorizedPage(true);
        } else {
            setIsOnUnauthorizedPage(false);
        }
    }, [pathname]);

    /**
     * Défini le libelle à afficher d'après xIdSociete sélectionné
     */
    const xIdSocieteInUse = useCallback(
        (newXIdSociete: BDD_SWITCH_SOCIETES_IDS_TYPE) => {
            if (newXIdSociete !== xIdSociete) {
                return BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[newXIdSociete];
            }

            return BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[xIdSociete];
        },
        [xIdSociete],
    );

    return (
        <Box>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickSelectIdSociete}
                disabled={isOnUnauthorizedPage || externalDisabler}
                color="inherit"
            >
                <Stack
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    <Typography
                        variant="l2dbodys"
                        color="text.gris50"
                    >
                        {xIdSocieteInUse(xIdSociete) || 'Veuillez sélectionner une société'}
                    </Typography>
                    {!isOnUnauthorizedPage && !externalDisabler ? (
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            flip={open ? 'vertical' : undefined}
                        />
                    ) : null}
                </Stack>
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseSelectIdSociete}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    value={2}
                    onClick={handleChangeSociete}
                >
                    2 - Affiches Parisiennes
                </MenuItem>
                <MenuItem
                    value={3}
                    onClick={handleChangeSociete}
                >
                    3 - Le Tout Lyon
                </MenuItem>
                <MenuItem
                    value={5}
                    onClick={handleChangeSociete}
                >
                    5 - Nouvelles Publications Commerciales
                </MenuItem>
                <MenuItem
                    value={12}
                    onClick={handleChangeSociete}
                >
                    12 - Legal2Digital
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default DropdownSociete;
