import { Box } from '@mui/material';
import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { OptionsJsonldOptionRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useDispatch } from 'react-redux';

import { addItem } from '@europrocurement/l2d-icons';
import { useOffreService } from '@europrocurement/l2d-domain';
import OfferOptionForm from './Form';
import { displayCustomValue, getStringColumn } from '../listings';
import ModalFormTitle from '../components/form/ModalFormTitle';
import ListingWithPreview from '../components/ListingWithPreview';

type HTMLFieldsPreviewType =
    | 'select'
    | 'date'
    | 'radio'
    | 'text'
    | 'textarea'
    | 'number'
    | 'radio'
    | 'checkbox';

const OfferOptionPanel: React.FunctionComponent = function () {
    const { modalActions } = React.useContext(ModalContext);

    const { offerOptionModel: model } = useOffreService();

    const dispatch = useDispatch();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reloadList = () => dispatch(model.datasourcesThunks.main.getData({}) as any);

    const getHtmlFieldTypeLabel = (htmlFieldType?: HTMLFieldsPreviewType) => {
        switch (htmlFieldType) {
            case 'select':
                return "Liste d'options";
            case 'checkbox':
                return 'Case à cocher';
            case 'text':
                return 'Champ texte';
            case 'textarea':
                return 'Zone de texte';
            case 'number':
                return 'Nombre';
            case 'date':
                return 'Champ date';
            case 'radio':
                return 'Bouton radio';
            default:
                return '';
        }
    };

    const listingButton = {
        label: 'Créer option',
        icon: addItem,
        action: () =>
            modalActions.call(
                <>
                    <ModalFormTitle
                        icon={model.icon}
                        title="Création d'une option"
                    />
                    <OfferOptionForm
                        isCreate
                        afterSubmit={reloadList}
                    />
                </>,
            ),
    };

    const columns: ColumnDatatable<OptionsJsonldOptionRead>[] = [
        getStringColumn('id'),
        getStringColumn('libelle', 'Libellé'),
        getStringColumn('description'),
        displayCustomValue('domaine', (domaine) => domaine.libelle, 'Domaine'),
        displayCustomValue(
            'visibleFront',
            (visibleFront) => (visibleFront ? 'oui' : 'non'),
            'Visible',
        ),
        displayCustomValue('obligatoire', (required) => (required ? 'oui' : 'non'), 'Obligatoire'),
        {
            label: 'Type de champ HTML',
            displayLabel: true,
            render: ({ htmlFieldType }) => (
                <Box sx={{ justifyContent: 'end' }}>
                    {/* should be enum Api side */}
                    <p>{getHtmlFieldTypeLabel(htmlFieldType as HTMLFieldsPreviewType)}</p>
                </Box>
            ),
            isDisplayed: true,
            sortable: false,
            field: 'htmlFieldType',
        },
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des options"
            listingButton={listingButton}
            listingColumns={columns}
            form={OfferOptionForm}
            model={model}
        />
    );
};

export default OfferOptionPanel;
