import React from 'react';
import { SelectItem } from '@europrocurement/flexy-components';
import { Box, useMediaQuery, useTheme } from '@mui/material';

export type BillingTypeItemProps = {
    label: string;
    value: number;
};

export type BillingTypeProps = {
    type: number;
    items: Array<BillingTypeItemProps>;
    onChange: (type: number) => void;
};

const BillingType: React.FunctionComponent<BillingTypeProps> = function (props) {
    const { type, items, onChange: handleChange } = props;
    const { breakpoints } = useTheme();
    const downMd = useMediaQuery(breakpoints.down('md'));

    const labelSx = {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '25.2px',
        marginBottom: '4px',
    };

    const formWrapperSx = {
        display: 'flex',
        gap: '8px',
        flexDirection: downMd ? 'column' : 'row',
    };

    return (
        <Box data-testid="billing-type">
            <Box sx={labelSx}>Recouvrement effectué par :</Box>
            <Box sx={formWrapperSx}>
                {items.map((item) => (
                    <SelectItem
                        key={`client-${item.value}`}
                        label={item.label}
                        value={item.value}
                        isChecked={item.value === type}
                        onChange={(v) => handleChange(v as number)}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default BillingType;
