import React, { useEffect, useState } from 'react';
import {
    BooleanBadge,
    CenterCircularProgress,
    LazyBreadcrumb,
    OptionType,
    Toolbar,
    useModal,
} from '@europrocurement/flexy-components';
// import {useTheme} from '@mui/system';
import { Container } from '@mui/system';
import {
    GroupesJsonldGroupReadCollection,
    MarquesJsonldSocieteMarqueReadCollection,
    PolesJsonldDefPoleReadCollection,
    ServicesJsonldServiceReadCollection,
    UtilisateursJsonldUtilisateurPatchUtilisateur,
    UtilisateursJsonldUtilisateurRead,
    UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
} from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { useParams } from 'react-router';
import {
    groupesSelector,
    marquesSelector,
    polesSelector,
    servicesSelector,
    useSociete,
    useUtilisateurService,
    useUtilisateursServiceSelector,
    UtilisateursAppDispatch,
    utilisateursSelector,
} from '@europrocurement/l2d-domain';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { B2DLogoIcon } from '@europrocurement/l2d-icons';
// import { faLockAlt, faLockOpenAlt } from '@fortawesome/pro-solid-svg-icons';
import { utilisateursBasePath, utilisateursListePath } from '../../constants/paths';
import { User } from '../../components/Utilisateur/UserTabs/type';
import { UserFormReturn } from '../../components/Utilisateur/UserInfos';
import { errorNotification, successNotification } from '../../functions/notificationToasters';
import { ErrorPageGeneric } from '../../ErrorPageGeneric';
import { ValidationModal } from '../../components/molecule/ValidationModal';
import { getValidationMessage } from '../../functions/getValidationMessages';
import UserTabs from '../../components/Utilisateur/UserTabs';
import { companyOptions } from '../../functions/createUserFormStructure';

type UserData = UtilisateursJsonldUtilisateurRead | UtilisateursJsonldUtilisateurPatchUtilisateur;

type PatchPayload =
    | UserFormReturn
    | UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur;

const UtilisateurFiche: React.FunctionComponent = function () {
    const { idUtilisateur } = useParams();
    const xIdSociete = useSociete();
    const dispatch = useDispatch<UtilisateursAppDispatch>();
    const { selectUtilisateurs, utilisateurApi } = useUtilisateurService();
    const { modalActions } = useModal();
    // const { palette } = useTheme();

    const [userData, setUserData] = useState<UserData>();
    const dataSource = useUtilisateursServiceSelector(utilisateursSelector);
    const user = dataSource.selected;

    const services = useUtilisateursServiceSelector(servicesSelector).data;
    const poles = useUtilisateursServiceSelector(polesSelector).data;
    const marques = useUtilisateursServiceSelector(marquesSelector).data;
    const groupes = useUtilisateursServiceSelector(groupesSelector).data;

    useEffect(() => {
        if (idUtilisateur) {
            // unselect the selected user to remove the previous user's data
            dispatch({ type: 'utilisateur/deletemainSelected' });

            // select the current user (same as the route)
            dispatch(selectUtilisateurs({ id: idUtilisateur }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // On component mount only

    // Is component ready to render content?
    useEffect(() => {
        const userLoaded = idUtilisateur && user && `${user.id}` === idUtilisateur;
        const dataSourceLoaded = dataSource.selectedStatus === 'succeeded';

        // if the user is the right one and his data have been retrieved, put them to the state
        if (userLoaded && dataSourceLoaded) {
            setUserData(user);
        }
    }, [dataSource.selectedStatus, idUtilisateur, user]);

    // format all data for the selects
    const servicesOptions: Array<OptionType> = [];
    services.map((service) =>
        servicesOptions.push({
            label: service.libelle as string,
            value: service.id,
        }),
    );

    const polesOptions: Array<OptionType> = [];
    poles.map((pole) =>
        polesOptions.push({
            label: pole.libelle as string,
            value: pole.id,
        }),
    );

    const marquesOptions: Array<OptionType> = [];
    marques.map((marque) =>
        marquesOptions.push({
            label: marque.libelle as string,
            value: marque.idMarque,
        }),
    );

    const groupesOptions: Array<OptionType> = [];
    groupes.map((groupe) =>
        groupesOptions.push({
            label: groupe.groupName as string,
            value: groupe.id,
        }),
    );

    if (!idUtilisateur) {
        return null;
    }

    if (dataSource.selectedStatus === 'failed') {
        return (
            <ErrorPageGeneric
                message="Utilisateur introuvable"
                statusCode={404}
                title="Page introuvable"
                description="This is Not Found page"
                details="L'identifiant saisit ne correspond à aucun utilisateur. Merci de vérifier votre saisie."
                returnButtonLabel="Retour à la liste des utilisateurs"
                returnUrl="/utilisateurs/utilisateurs"
                logo={<B2DLogoIcon style={{ height: '64px', marginTop: '40px' }} />}
            />
        );
    }

    if (!userData) {
        return <CenterCircularProgress sx={{ height: '70vh' }} />;
    }

    /**
     * Function that get the IRI as parameter, extract the id and get the object to return
     * @param stringToFormat IRI string
     */
    const getObjectFromIRI = (
        stringToFormat: string | null | undefined,
        objectKey: keyof User,
    ):
        | ServicesJsonldServiceReadCollection
        | MarquesJsonldSocieteMarqueReadCollection
        | PolesJsonldDefPoleReadCollection
        | GroupesJsonldGroupReadCollection
        | null
        | undefined => {
        if (stringToFormat !== null && stringToFormat !== undefined) {
            const id = parseInt(stringToFormat.replace(/[^0-9]/g, ''), 10);

            switch (objectKey) {
                case 'service':
                    return services.find((match) => match.id === id);

                case 'pole':
                    return poles.find((match) => match.id === id);

                case 'marque':
                    return marques.find((match) => match.idMarque === id);

                case 'userGroupe':
                    return groupes.find((match) => match.id === id);

                default:
                    break;
            }
        } else if (stringToFormat === undefined) {
            return undefined;
        }

        return null;
    };

    /**
     * On Patch API success, set the userData with the new value to rerender the current user data.
     * Notifies that the modification is done
     * @param response API Patch response success return
     */
    const onPatchSuccess = (
        response: AxiosResponse<UtilisateursJsonldUtilisateurPatchUtilisateur>,
    ) => {
        const { data } = response;

        // extract the id of the data from the IRI with regex
        const newService = getObjectFromIRI(data.service, 'service') as
            | ServicesJsonldServiceReadCollection
            | null
            | undefined;

        const newMarque = getObjectFromIRI(data.marque, 'marque') as
            | MarquesJsonldSocieteMarqueReadCollection
            | null
            | undefined;

        const newPole = getObjectFromIRI(data.pole, 'pole') as
            | PolesJsonldDefPoleReadCollection
            | null
            | undefined;

        const newGroupe = getObjectFromIRI(data.userGroupe, 'userGroupe') as
            | GroupesJsonldGroupReadCollection
            | null
            | undefined;

        // Those data are not returned by the patch, need to retrieve them and add it in the state for the information user update
        const newLibelleSociete = companyOptions.find(
            (company) => company.value === data.idSociete,
        )?.label;

        const newLibelleSocieteEmployeur = companyOptions.find(
            (company) => company.value === data.idSocieteEmployeur,
        )?.label;

        // replace the patch return object by the formated version to update the state
        const newData: UtilisateursJsonldUtilisateurRead = {
            ...data,
            service: newService,
            marque: newMarque,
            pole: newPole,
            userGroupe: newGroupe,
            libelleSociete: newLibelleSociete,
            libelleSocieteEmployeur: newLibelleSocieteEmployeur,
        };

        setUserData(newData);

        successNotification("L'utilisateur a bien été modifié");

        modalActions.close();
    };

    /**
     * Function that get the payload, format it if necessary, then patch it into the API
     * @param payload object from the patch or the UserInfos modification form
     * @param dirtyFields object that caught only modified fiels from the modification form
     */
    const patch = (
        payload: PatchPayload,
        dirtyFields?: {
            [key: string]: boolean;
        },
    ) => {
        let formValues = payload;

        if (typeof dirtyFields !== 'undefined') {
            const dirtyFieldKeys = Object.keys(dirtyFields) as Array<keyof PatchPayload>;

            // add only the modified form values to the payload object for the patch
            formValues = dirtyFieldKeys.reduce(
                (acc, current) => ({ ...acc, [current]: payload[current] }),
                {},
            );
        }

        const requestObject = {
            id: idUtilisateur,
            xIdSociete,
            utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur: formValues,
        };

        utilisateurApi
            .apiUtilisateursIdPatch(requestObject)
            .then((response) => {
                onPatchSuccess(response);
            })
            .catch((error) => {
                console.error(error);
                if (error.response.data.status === 422) {
                    errorNotification('Cet email existe déjà');
                } else {
                    errorNotification();
                }
            });
    };

    const breadcrumbPath = [
        {
            path: 'utilisateurs',
            link: `/${utilisateursBasePath}/${utilisateursListePath}`,
        },
        `utilisateur  ${userData.prenom} ${userData.nom}`,
        '',
    ];

    const handleModalSubmit = (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
    ) => {
        patch(payload);
    };

    const handleValidationModal = (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => {
        const message = getValidationMessage(value, field);

        modalActions.call(
            <ValidationModal
                message={message}
                onSubmit={() => handleModalSubmit(payload)}
            />,
        );
    };

    return (
        <Container maxWidth="lg">
            <Toolbar previousPath={`/${utilisateursBasePath}/${utilisateursListePath}`}>
                <LazyBreadcrumb path={breadcrumbPath} />
                <BooleanBadge
                    label={userData.isDeleted ? 'Compte désactivé' : 'Compte actif'}
                    value={!userData.isDeleted}
                    icon
                    toggleAction={() =>
                        handleValidationModal(
                            { isDeleted: !userData.isDeleted },
                            'isDeleted',
                            userData.isDeleted as boolean,
                        )
                    }
                />
            </Toolbar>

            <UserTabs
                services={servicesOptions}
                poles={polesOptions}
                groupes={groupesOptions}
                handlePatchData={patch}
                handlePatchInfos={patch}
                user={userData as User}
                marques={marquesOptions}
                handleValidationModal={handleValidationModal}
            />
        </Container>
    );
};

export default UtilisateurFiche;
