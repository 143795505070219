import React from 'react';
import { Avatar, Box, lighten, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

const ExportDossierFormTitle: React.FunctionComponent = function () {
    const { palette, breakpoints } = useTheme();
    const downMd = useMediaQuery(breakpoints.down('md'));

    const wrapperSx = {
        display: 'flex',
        gap: '0 8px',
        alignItems: 'center',
        background: 'none',
        border: `solid 1px ${palette.grey[300]}`,
        borderRadius: '12px',
        padding: '8px',
        transition: '0.1s ease',
        boxShadow: `0px 2px 2.5px 0px ${palette.grey[200]}`,
    };

    const avatarWrapperSx = {
        alignSelf: downMd ? 'flex-start' : 'auto',
        padding: '10px 8px',
    };

    const avatarSx = {
        display: 'flex',
        borderRadius: '8px',
        backgroundColor: lighten(palette.primary.main, 0.92),
        minHeight: '36px',
        minWidth: '36px',
    };

    const avatarIconSx = {
        fontSize: '16px',
        lineHeight: '16px',
        fontWeight: '400',
        color: palette.primary.main,
    };

    const infoWrapperSx = {
        display: 'flex',
        flexDirection: 'column',
    };

    const inlinedWrapperSx = {
        display: 'flex',
        flexDirection: downMd ? 'column' : 'row',
        alignItems: downMd ? 'flex-start' : 'center',
        gap: downMd ? '4px 0' : '0 4px',
    };

    const nameSx = {
        color: palette.primary.dark,
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: '500',
    };

    return (
        <Box
            sx={wrapperSx}
            data-testid="document"
        >
            <Box sx={avatarWrapperSx}>
                <Avatar
                    data-testid="document-icon"
                    sx={avatarSx}
                >
                    <FaOptionIcon
                        icon={['fasl', 'file-xls']}
                        sx={avatarIconSx}
                    />
                </Avatar>
            </Box>
            <Box sx={infoWrapperSx}>
                <Box sx={inlinedWrapperSx}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '0 4px' }}>
                        <Typography
                            component="span"
                            sx={nameSx}
                        >
                            Récapitulatif de dossiers
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ExportDossierFormTitle;
