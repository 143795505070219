import React, { useMemo } from 'react';

import { FiltersDatatableList, StoreDatatable } from '@europrocurement/flexy-datatable';
import { MediaObject, MediaObjectApiObject } from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import {
    getFromSpecifiedDataSourceThunks,
    mediaobjectDataSourcesThunks,
} from '@b2d/redux/RootStore';

import useUnregisteredInvoicesListColumns from './hooks/useUnregisteredInvoicesListColumns';
import useCheckInTreatmentInvoice from './hooks/useCheckInTreatmentInvoice';

export type UnregisteredInvoicesListProps = {
    dataSource: DataSource<MediaObjectApiObject>;
    additionalFilters?: FiltersDatatableList;
};

const UnregisteredInvoicesList: React.FunctionComponent<UnregisteredInvoicesListProps> = function ({
    dataSource,
    additionalFilters,
}: UnregisteredInvoicesListProps) {
    const selectedDataSource = useMemo(() => dataSource.selected, [dataSource.selected]);

    const getMediaObject = getFromSpecifiedDataSourceThunks(
        mediaobjectDataSourcesThunks,
        dataSource.name,
    );

    const { debutTraitementCheckModal } = useCheckInTreatmentInvoice({
        getMediaObject,
        selectedDataSource,
    });

    const { columns } = useUnregisteredInvoicesListColumns({
        getMediaObject,
        selectedDataSource,
        dataSource,
    });

    const filters: FiltersDatatableList = [
        {
            field: 'processingFacture',
            label: 'Pdf en traitement',
            type: 'boolean',
        },
    ];

    return dataSource ? (
        <StoreDatatable
            dataSource={dataSource}
            columns={columns}
            fetchData={getMediaObject}
            filters={[...(additionalFilters || []), ...filters]}
            localStorageKey="FlexyStoreDatatableMediaObject"
            fetchWithoutFilter={false}
            onClickRow={(e: React.MouseEvent<HTMLElement>, item: MediaObject) => {
                debutTraitementCheckModal({
                    mediaObjectToRegister: item,
                    dataSourceName: dataSource.name,
                });
            }} // Empêche la bonne redirection vers la facture liée (même en utilisant un Link)
            onWheelClickRow={(e: React.MouseEvent<HTMLElement>, item: MediaObject) =>
                debutTraitementCheckModal({
                    mediaObjectToRegister: item,
                    openNewTab: true,
                    dataSourceName: dataSource.name,
                })
            } // Empêche la bonne redirection vers la facture liée (même en utilisant un Link)
        />
    ) : null;
};

export default UnregisteredInvoicesList;
