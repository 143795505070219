import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    RegleDeCalculApi,
    RegleDeCalculRegleCalculRead,
} from '../../../openApi/ApiOffre';
import { OFFRE_REDUCER_NAME, REGLEDECALCULS_SLICE_NAME } from '../constants';

export type RegleDeCalculApiObject = RegleDeCalculRegleCalculRead & Record<string, unknown>;

export type RegleDeCalculs = RegleDeCalculApiObject;

const mapperRegleDeCalculs = function (regledecalculs: RegleDeCalculApiObject) {
    return {
        ...regledecalculs,
    } as RegleDeCalculs;
};

export function createRegleDeCalculsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new RegleDeCalculApi(conf);

    const fetchRegleDeCalculsCollection: FetchCollectionData<RegleDeCalculApiObject> = ({
        xIdSociete,
    }) =>
        api
            .apiRegleCalculGetCollection({ xIdSociete })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<RegleDeCalculApiObject>>;

    const fetchRegleDeCalculItem: FetchItemDataType<RegleDeCalculApiObject> = ({
        idItem,
        xIdSociete,
    }) =>
        api
            .apiRegleCalculIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<RegleDeCalculApiObject>;

    const { slice: regleDeCalculsSlice, dataSourcesThunks: regleDeCalculsDataSourcesThunks } =
        SliceFactory.createSlice<RegleDeCalculApiObject, RegleDeCalculs>(
            REGLEDECALCULS_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchRegleDeCalculsCollection,
            fetchRegleDeCalculItem,
            mapperRegleDeCalculs,
        );

    return { regleDeCalculsSlice, regleDeCalculsDataSourcesThunks };
}
