import { Tooltip } from '@mui/material';
import * as React from 'react';

export type EmailLinkProps = {
    email: string;
    tooltip?: string;
};

const EmailLink: React.FunctionComponent<EmailLinkProps> = function (props) {
    const { email, tooltip } = props;

    const emailLinkStyle = {
        color: 'inherit',
        textDecoration: 'inherit',
    };

    return (
        <Tooltip title={tooltip}>
            <a
                href={`mailto:${email}`}
                style={emailLinkStyle}
            >
                {email}
            </a>
        </Tooltip>
    );
};

export default EmailLink;
