import * as React from 'react';
import { Box } from '@mui/system';
import { Button, Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { FlexyFormLabel, SelectItem, SelectItemProps } from '../../atoms';

type title = { title?: string };
export type SelectItemBaseProps = Omit<SelectItemProps, 'design' | ' onChange'> & title;

export type SelectItemsProps = {
    mode?: 'block' | 'inline' | 'mixed' | 'singleLine' | 'basic';
    items: SelectItemBaseProps[];
    colBlock?: number;
    colInline?: number;
    mixedMaxLines?: number;
    gridColLength?: number;
    onChange: (values: Array<SelectItemProps['value']>) => void;
    value?: Array<SelectItemProps['value']>;
    label?: string;
    showMore?: boolean;
    showMoreLabel?: string;
    withTitle?: boolean;
    multiple?: boolean;
};

function onlyUnique<T>(value: T, index: number, array: Array<T>) {
    return array.indexOf(value) === index;
}

const SelectItems: React.FunctionComponent<SelectItemsProps> = function ({
    mode = 'mixed',
    items = [],
    colBlock = 3,
    colInline = 6,
    gridColLength = 12,
    mixedMaxLines = 2,
    onChange,
    value,
    label,
    showMore = false,
    showMoreLabel = 'de propositions',
    withTitle = false,
    multiple = true,
}: SelectItemsProps) {
    let innerItems: SelectItemsProps['items'];

    if (value) {
        innerItems = items.map((item) => {
            if (value.indexOf(item.value) !== -1) {
                return { ...item, isChecked: true };
            }
            return { ...item, isChecked: false };
        });
    } else {
        innerItems = items;
    }

    const allValues = innerItems.map((i) => i.value).filter(onlyUnique);
    if (allValues.length !== innerItems.length) {
        console.error('Two items or more with the same value found.');
    }

    const [hide, setHide] = React.useState(showMore);
    const [values, setValues] = React.useState(
        innerItems.filter((i) => i.isChecked).map((i) => i.value),
    );

    const onChangeWrapper: SelectItemProps['onChange'] = React.useCallback(
        (newValue: SelectItemProps['value'], checked: boolean) => {
            let tmpNewValues: SelectItemProps['value'][];
            if (multiple && checked) {
                tmpNewValues = [...values, newValue];
            } else if (checked) {
                tmpNewValues = [newValue];
            } else {
                tmpNewValues = [...values.filter((v) => v !== newValue)];
            }
            if (!_.isEqual(tmpNewValues, values)) {
                onChange(tmpNewValues);
                setValues(tmpNewValues);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [multiple, values, setValues],
    );

    let nbItemBlock = 0;
    if (mode === 'mixed') {
        nbItemBlock = mixedMaxLines * (12 / colBlock);
    } else if (mode === 'block') {
        nbItemBlock = innerItems.length;
    }

    let cssHide: React.CSSProperties = {};
    if (hide && mode === 'mixed') {
        cssHide = { display: 'none' };
    }

    const gridblock = (
        <Box data-testid="test-id-block-box">
            <Grid
                container
                spacing={1}
                data-testid="test-id-selectItems"
            >
                {innerItems.slice(0, nbItemBlock).map((item, index, itemArray) => (
                    <React.Fragment key={`${item.value} ${item.label}`}>
                        {withTitle &&
                        item.title &&
                        (index === 0 || item.title !== itemArray[index - 1].title) ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            >
                                {' '}
                                <Typography
                                    component="h2"
                                    sx={{
                                        fontSize: '18px',
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                        ) : null}
                        <Grid
                            item
                            lg={colBlock}
                        >
                            <SelectItem
                                label={item.label}
                                icone={item.icone}
                                value={item.value}
                                baseline={item.baseline}
                                design="block"
                                // item.isChecked
                                isChecked={values.indexOf(item.value as string) > -1}
                                isDisabled={item.isDisabled}
                                onChange={onChangeWrapper}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );

    const gridinline = (
        <Box
            style={{ ...cssHide }}
            data-testid="test-id-inline-box"
        >
            <Grid
                container
                columns={gridColLength}
                spacing={1}
            >
                {innerItems.slice(nbItemBlock).map((item, index, itemArray) => (
                    <React.Fragment key={`${item.value} ${item.label}`}>
                        {withTitle &&
                        item.title &&
                        (index === 0 || item.title !== itemArray[index - 1].title) ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            >
                                {' '}
                                <Typography
                                    component="h2"
                                    sx={{
                                        fontSize: '18px',
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                        ) : null}
                        <Grid
                            item
                            lg={colInline}
                        >
                            <SelectItem
                                label={item.label}
                                baseline={item.baseline}
                                icone={item.icone}
                                value={item.value}
                                onChange={onChangeWrapper}
                                // item.isChecked
                                isChecked={values.indexOf(item.value as string) > -1}
                                isDisabled={item.isDisabled}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );

    const basic = (
        <Box
            display="flex"
            gap="10px"
            data-testid="test-id-basic-box"
            flexDirection="row"
            flexWrap="wrap"
        >
            {innerItems.map((item) => (
                <React.Fragment key={`${item.value} ${item.label}`}>
                    <SelectItem
                        label={item.label}
                        baseline={item.baseline}
                        icone={item.icone}
                        value={item.value}
                        fullWidth={false}
                        design="basic"
                        isChecked={values.indexOf(item.value as string) > -1}
                        isDisabled={item.isDisabled}
                        onChange={onChangeWrapper}
                    />
                </React.Fragment>
            ))}
        </Box>
    );

    const showMoreOrLess = (
        <Box
            sx={{ textAlign: 'center' }}
            data-testid="test-id-showMoreOrLess-box"
        >
            <Button
                variant="text"
                color="primary"
                onClick={() => {
                    setHide(!hide);
                }}
            >
                {' '}
                Afficher {hide ? 'plus' : 'moins'} {showMoreLabel}
            </Button>
        </Box>
    );

    return mode === 'basic' ? (
        basic
    ) : (
        <Box
            display="flex"
            flexDirection="column"
            gap="10px"
        >
            {label ? <FlexyFormLabel>{label}</FlexyFormLabel> : null}

            {mode !== 'inline' ? gridblock : null}
            {showMore && nbItemBlock < innerItems.length ? showMoreOrLess : null}
            {mode !== 'block' ? gridinline : null}
        </Box>
    );
};

export default SelectItems;
