import React, { createContext, useContext, ReactNode, useState } from 'react';

interface SocietesSearchContextType {
    isSearchSocietePath: boolean;
    setIsSearchSocietePath: (value: boolean) => void;
}

const SocietesSearchContext = createContext<SocietesSearchContextType | undefined>(undefined);

interface SocietesSearchProviderProps {
    children: ReactNode;
}

export const SocietesSearchProvider: React.FunctionComponent<SocietesSearchProviderProps> =
    function ({ children }) {
        const [isSearchSocietePath, setIsSearchSocietePath] = useState(false);

        const value = React.useMemo(
            () => ({ isSearchSocietePath, setIsSearchSocietePath }),
            [isSearchSocietePath, setIsSearchSocietePath],
        );
        return (
            <SocietesSearchContext.Provider value={value}>
                {children}
            </SocietesSearchContext.Provider>
        );
    };

export const useSocietesSearch = (): SocietesSearchContextType => {
    const context = useContext(SocietesSearchContext);
    if (context === undefined) {
        throw new Error('useSocietesSearch must be used within a SocietesSearchProvider');
    }
    return context;
};
