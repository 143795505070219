import React from 'react';

import { Box, Typography } from '@mui/material';
import {
    ContextActionType,
    NoDataCard,
    useDumbActionsContext,
} from '@europrocurement/flexy-components';
import { useRejectCodeUtils } from '@b2d/hooks/rejectCodeHooks';
import ConfigurationRow from './ConfigurationRow';

const RejectCodeInformationsCard: React.FunctionComponent = function () {
    const { rejectCode } = useRejectCodeUtils();

    // Component specific context actions

    const contextActions: ContextActionType[] = [];

    useDumbActionsContext(contextActions);

    if (!rejectCode) {
        return (
            <Box height="70vh">
                <NoDataCard
                    additionalData={
                        <Typography variant="body2">Pas de code rejet sélectionné</Typography>
                    }
                />
            </Box>
        );
    }

    return <ConfigurationRow />;
};

export default RejectCodeInformationsCard;
