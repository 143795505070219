/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    CodeRejetApi,
    CodeRejetJsonldCodeRejetRead,
    CodeRejetApiGetCodeRejetCollectionRequest,
} from '../../../openApi/ApiAchats';
import { ACHATS_REDUCER_NAME } from '../constants';

export type CodeRejetApiObject = CodeRejetJsonldCodeRejetRead & Record<string, unknown>;

export type CodeRejet = CodeRejetApiObject;

export const CODES_REJETS_SLICE_NAME = 'coderejet';

export const CODES_REJETS_FOURNISSEUR_DATASOURCE_NAME = 'fournisseur';
export const CODES_REJETS_AUTOCOMPLETE_DATASOURCE_NAME = 'autocompleteCoderejet';

export const additionalCodesRejetsDataSources: Array<string> = [
    CODES_REJETS_FOURNISSEUR_DATASOURCE_NAME,
    CODES_REJETS_AUTOCOMPLETE_DATASOURCE_NAME,
];

const mapperCodeRejet = function (codeRejet: CodeRejet) {
    return {
        ...codeRejet,
    } as CodeRejet;
};

export function createCodeRejetSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CodeRejetApi(conf);

    const fetchCodeRejetsCollection: FetchCollectionData<CodeRejetApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let code: string | undefined;

        if (filters.code) {
            code = filters.code as string;
        }

        const requestParameters: CodeRejetApiGetCodeRejetCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            code,
        };

        return api.getCodeRejetCollection(requestParameters).then((res) => res.data) as Promise<
            ApiCollectionResponse<CodeRejet>
        >;
    };

    const fetchCodeRejetsCollectionByCode: FetchCollectionData<CodeRejetApiObject> = ({
        search,
        pagination,
        filters,
        // orders,
        xIdSociete,
        // rootstate,
    }) => {
        const requestParameters: CodeRejetApiGetCodeRejetCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: 20,
            code: search,
        };

        return api.getCodeRejetCollection(requestParameters).then((res) => res.data) as Promise<
            ApiCollectionResponse<CodeRejetApiObject>
        >;
    };

    const fetchCodeRejetsItem: FetchItemDataType<CodeRejet> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getCodeRejetItem({
                code: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<CodeRejetApiObject>;

    const { slice: codeRejetSlice, dataSourcesThunks: codeRejetDataSourcesThunks } =
        SliceFactory.createSlice<CodeRejetApiObject, CodeRejet>(
            CODES_REJETS_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            additionalCodesRejetsDataSources,
            fetchCodeRejetsCollection,
            fetchCodeRejetsItem,
            mapperCodeRejet,
            {
                cloture: true,
                deleted: false,
            },
            {
                data: {
                    fetchByCode: fetchCodeRejetsCollectionByCode,
                },
            },
            [
                {
                    field: 'updatedAt',
                    value: 'desc',
                },
            ],
        );

    return { codeRejetSlice, codeRejetDataSourcesThunks };
}
