import { Dossier, DossierOpenSearch, useFormaliteService } from '@europrocurement/l2d-domain';

export type UpdateDossierPayload = {
    clientId?: number | undefined;
    typeFacturation?: number | undefined;
    objectGroups?: Array<number> | undefined;
};

const useDossier = () => {
    const { dossierApi } = useFormaliteService();

    const updateDossier = (id: number, payload: UpdateDossierPayload): Promise<Dossier> =>
        new Promise((resolve, reject) => {
            const requestParameter = {
                id: id as unknown as string,
                dossierDossierUpdatePartial: {
                    client: payload.clientId || undefined,
                    typeFacturation: payload.typeFacturation || undefined,
                    objectGroups: payload.objectGroups
                        ? (payload.objectGroups as Array<unknown> as Array<string>)
                        : undefined,
                },
            };

            dossierApi
                .apiDossiersIdPatch(requestParameter)
                .then((res) => {
                    resolve(res.data as Dossier);
                })
                .catch(() => {
                    reject();
                });
        });

    const addDossiersInGroups = (
        dossiers: Array<DossierOpenSearch>,
        newGroupIds: Array<number>,
    ): Promise<void> =>
        new Promise((resolve, reject) => {
            const promises = dossiers.map((dossier) =>
                updateDossier(dossier?.id as number, {
                    objectGroups: newGroupIds,
                }),
            );

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });

    return { updateDossier, addDossiersInGroups };
};

export default useDossier;
