/* eslint-disable @typescript-eslint/no-unused-vars */
import { Status } from '@europrocurement/l2d-domain';
import { createContext } from 'react';
import { DossierDocument, DossierFichier } from '../hooks';

const missingDocumentsCount: number = 0;

const statuses: Array<Status> = [];
const setStatuses: React.Dispatch<React.SetStateAction<Status[]>> = () => {};
const getStatusLabel = (statusId: number): string | undefined | null => (statusId ? null : null);

const documents: Array<DossierDocument> = [];
const fichiers: Array<DossierFichier> = [];
const addFichier = (fichier: DossierFichier) => {};
const addMultipleFichiers = (newFichiers: DossierFichier[]) => {};
const removeFichier = (fichier: DossierFichier) => {};
const replaceFichier = (oldFichier: DossierFichier, newFichier: DossierFichier) => {};
const getDownloadableDocumentsCount = (): number => 0;

const DocumentContext = createContext({
    missingDocumentsCount,
    statuses,
    setStatuses,
    getStatusLabel,
    documents,
    fichiers,
    addFichier,
    addMultipleFichiers,
    removeFichier,
    replaceFichier,
    getDownloadableDocumentsCount,
});

export default DocumentContext;
