import { enqueueSnackbar } from 'notistack';

/**
 * Show a green pop-up displaying the message provided
 */
export const successNotification = (message: string) => {
    enqueueSnackbar(message, {
        variant: 'success',
    });
};

/**
 * Show a red pop-up message that notifies "Une erreur est survenue"
 */
export const errorNotification = (message?: string) => {
    if (!message) {
        enqueueSnackbar('Une erreur est survenue', {
            variant: 'error',
        });
    } else {
        enqueueSnackbar(message, {
            variant: 'error',
        });
    }
};
