import React from 'react';
import { TypographyProps } from '@mui/material';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import BasicButton from '../BasicButton';

export type PreviousType = {
    onClick: () => void;
    color?: TypographyProps['color'];
    hidden?: boolean;
};

const Previous: React.FunctionComponent<PreviousType> = function ({
    onClick,
    color,
    hidden = true,
}: PreviousType) {
    const previousIcon = faArrowLeft;

    return hidden ? null : (
        <BasicButton
            testId="test-id-button-previous"
            icon={previousIcon}
            label="Précédent"
            color={color}
            underline
            onClick={onClick}
        />
    );
};

export default Previous;
