import {
    AdresseJsonldSousclientCreate,
    ContactJsonldSousclientCreate,
    ContactJsonldSousclientCreateCiviliteEnum,
    ContactJsonldSousclientWriteContacts,
    RelationSocieteTiersJsonldSousclientCreate,
    TiersSousclientJsonldSousclientCreate,
    TiersSousclientJsonldSousclientCreated,
    TiersSousclientJsonldSousclientUpdate,
    TiersSousclientJsonldSousclientWriteAdresses,
    TiersSousclientJsonldSousclientWriteContacts,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { AxiosResponse } from 'axios';
import { useSociete, useTiersService } from '@europrocurement/l2d-domain';
import { useCurrentPrescriber } from '@europrocurement/l2d-modules/hooks/useCurrentPrescriber';
import { filtersToElasticQuery } from '@europrocurement/l2d-redux-utils';
import { useOrigine } from '../../ParcoursFormalite/hooks';

export type AddPrescriberFormData = {
    localisationSocieteFr: boolean;
    personnePhysique: Array<boolean>;
    siren: string;
    companyName: string;
    fullAddress: string;
    address: string;
    addressComplement: string | null;
    postalCode: string;
    city: string;
    country: string;
    civility: Array<number>;
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber: string;
    mobileNumber: string;
    venteIndirecte: Array<boolean>;
    numeroTvaIntracommunautaire: string;
    contactId?: number;
    adresseId?: number;
    emailId?: number | undefined;
    mobileId?: number | undefined;
    telephoneId?: number | undefined;
};

const useAddPrescriberClient = () => {
    const { sousClientsApi } = useTiersService();
    const prescriber = useCurrentPrescriber();
    const xIdSociete = useSociete();
    const origine = useOrigine();

    if (!prescriber || !prescriber.id) {
        throw new Error('No prescriber id');
    }

    const getAddress = (formData: AddPrescriberFormData): AdresseJsonldSousclientCreate | null => {
        const { address, addressComplement, postalCode, city, country } = formData;

        return {
            adresse1: address,
            adresse2: addressComplement || '',
            adresse3: undefined,
            adresse4: undefined,
            codePostal: postalCode,
            ville: city,
            codePays: country,
        };
    };

    const getRelationSociete = (): RelationSocieteTiersJsonldSousclientCreate | null => ({
        societe: { id: xIdSociete },
    });

    const getEmail = (formData: AddPrescriberFormData) => {
        let oEmail: { email: string; defaut?: boolean; id?: number } = {
            email: formData.email,
            defaut: true,
        };
        if (formData.emailId !== undefined && (formData.emailId as unknown as string) !== '') {
            oEmail = {
                id: formData.emailId,
                defaut: true,
                ...oEmail,
            };
        }
        return oEmail;
    };

    const getTelephone = (formData: AddPrescriberFormData) => {
        let oTel: { telephone: string; defaut?: boolean; id?: number } = {
            telephone: formData.phoneNumber,
        };
        if (
            formData.telephoneId !== undefined &&
            (formData.telephoneId as unknown as string) !== ''
        ) {
            oTel = {
                id: formData.telephoneId,
                defaut: true,
                ...oTel,
            };
        }
        return oTel;
    };

    const getMobile = (formData: AddPrescriberFormData) => {
        let oMobile: { mobile: string; defaut?: boolean; id?: number } = {
            mobile: formData.mobileNumber,
            defaut: true,
        };
        if (formData.mobileId !== undefined && (formData.mobileId as unknown as string) !== '') {
            oMobile = {
                id: formData.mobileId,
                ...oMobile,
            };
        }
        return oMobile;
    };

    const getContact = (
        formData: AddPrescriberFormData,
    ): ContactJsonldSousclientCreate | ContactJsonldSousclientWriteContacts => {
        const { civility, lastName, firstName, email, phoneNumber, mobileNumber } = formData;

        const oEmail = getEmail(formData);
        const oTel = getTelephone(formData);
        const oMobile = getMobile(formData);

        let contact: ContactJsonldSousclientCreate = {
            civilite: (civility && civility[0]
                ? Number(civility[0])
                : null) as ContactJsonldSousclientCreateCiviliteEnum,
            nomContact: lastName,
            prenomContact: firstName,
            telephonesContact: phoneNumber ? [oTel] : [],
            emailsContact: email ? [oEmail] : [],
            mobilesContact: mobileNumber ? [oMobile] : [],
            faxsContact: [],
        };
        if (formData.contactId !== undefined) {
            contact = { id: formData.contactId, ...contact };
        }
        return contact;
    };

    const getPayload = (
        formData: AddPrescriberFormData,
    ): TiersSousclientJsonldSousclientCreate | TiersSousclientJsonldSousclientUpdate => {
        const contact = getContact(formData);
        const address = getAddress(formData);
        const relationSociete = getRelationSociete();

        return {
            raisonSociale: formData.companyName,
            siren: formData.siren,
            siret: undefined,
            personnePhysique: formData.personnePhysique[0],
            demo: false,
            venteIndirecte: formData.venteIndirecte[0],
            adresses: address ? [address] : [],
            origine: { id: origine },
            idPrescripteur: Number(prescriber.id),
            contacts: contact ? [contact as ContactJsonldSousclientCreate] : [],
            relationsSocietes: relationSociete ? [relationSociete] : [],
            tvaIntracommunautaire: formData.numeroTvaIntracommunautaire || '',
            fEtabEtranger: formData.country !== 'FR',
        };
    };

    const handleAddForm = async (
        formData: AddPrescriberFormData,
    ): Promise<AxiosResponse<TiersSousclientJsonldSousclientCreated>> => {
        const payload = getPayload(formData);

        console.log(payload);

        return sousClientsApi.createSousclientTiersCollection({
            xIdSociete,
            tiersSousclientJsonldSousclientCreate: payload as TiersSousclientJsonldSousclientCreate,
        });
    };

    const handleModifyForm = async (
        formData: AddPrescriberFormData,
        id: number,
    ): Promise<AxiosResponse<TiersSousclientJsonldSousclientUpdate>> => {
        const payload = {
            raisonSociale:
                formData.companyName === '' && formData.personnePhysique[0] === true
                    ? `${formData.firstName} ${formData.lastName}`
                    : formData.companyName,
            siren: formData.siren,
            siret: undefined,
            personnePhysique: formData.personnePhysique[0],
            demo: false,
            venteIndirecte: formData.venteIndirecte[0],
            origine: { id: origine },
            idPrescripteur: Number(prescriber.id),
            tvaIntracommunautaire: formData.numeroTvaIntracommunautaire || '',
            fEtabEtranger: formData.country !== 'FR',
        };

        const contact = {
            contacts: [{ id: formData.contactId, ...getContact(formData) }],
        } as TiersSousclientJsonldSousclientWriteContacts;

        const address = {
            adresses: [
                {
                    id: formData.adresseId,
                    facturation: true,
                    defaut: true,
                    ...getAddress(formData),
                },
            ],
        } as unknown as TiersSousclientJsonldSousclientWriteAdresses;

        sousClientsApi.updateAdressesSousclientTiersItem({
            xIdSociete,
            tiersSousclientJsonldSousclientWriteAdresses: address,
            id: id as unknown as string,
        });

        if (formData.phoneNumber !== undefined && formData.phoneNumber !== 'null') {
            const email = { emailsContact: [getEmail(formData)] };
            sousClientsApi.updateEmailsContactSousclientContactsItem({
                xIdSociete,
                id: formData.contactId as unknown as string,
                tiers: id as unknown as string,
                contactsContactJsonldContactWriteEmailsSousclient: email,
            });
        }

        if (formData.phoneNumber !== undefined && formData.phoneNumber !== 'null') {
            const tel = { telephonesContact: [getTelephone(formData)] };
            sousClientsApi.updateTelephonesContactSousclientContactsItem({
                xIdSociete,
                tiers: id as unknown as string,
                id: formData.contactId as unknown as string,
                contactsContactJsonldContactWriteTelsSousclient: tel,
            });
        }
        if (formData.mobileNumber !== undefined && formData.mobileNumber !== 'null') {
            const mobile = { mobilesContact: [getMobile(formData)] };
            sousClientsApi.updateMobilesContactSousclientContactsItem({
                xIdSociete,
                tiers: id as unknown as string,
                id: formData.contactId as unknown as string,
                contactsContactJsonldContactWriteMobilesSousclient: mobile,
            });
        }

        sousClientsApi.updateContactsSousclientTiersItem({
            xIdSociete,
            tiersSousclientJsonldSousclientWriteContacts: contact,
            id: id as unknown as string,
        });

        return sousClientsApi.updateSousclientTiersItem({
            xIdSociete,
            tiersSousclientJsonldSousclientUpdate: payload as TiersSousclientJsonldSousclientUpdate,
            id: id as unknown as string,
        });
    };

    const isTiersNonFiable = (): boolean => {
        const companyInfo = prescriber.relationsSocietes.find(
            (info) => info?.societe?.id === xIdSociete,
        );

        return companyInfo?.tiersNonFiable || false;
    };

    const sousClientExists = (siren: string): Promise<boolean> =>
        new Promise((resolve) => {
            sousClientsApi
                .getListTiersTiersCollection({
                    xIdSociete,
                    page: 1,
                    itemsPerPage: 999999,
                    search: filtersToElasticQuery({
                        isSousclient: true,
                        idPrescripteur: prescriber.id,
                    }),
                })
                .then((res) => res.data)
                .then((res) => {
                    const foundExisting = res['hydra:member'].find(
                        (sousClient) => sousClient.siren === siren,
                    );
                    resolve(foundExisting !== undefined);
                });
        });

    const isVenteIndirecte = (): boolean =>
        prescriber.venteIndirecte === true || isTiersNonFiable();

    return {
        handleAddForm,
        handleModifyForm,
        isTiersNonFiable,
        isVenteIndirecte,
        sousClientExists,
    };
};

export default useAddPrescriberClient;
