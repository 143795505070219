import { DossierFormalite, useFormaliteService } from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormalityAppDispatch,
    FormalityStateType,
} from '@europrocurement/l2d-domain/reducers/formalites/types';
import RecapFormalityShow from '@europrocurement/l2d-modules/src/modules/ParcoursFormalite/components/RecapFormalityShow';

const RecapFormalityShowWrapper: React.FunctionComponent = function () {
    const dossierFormaliteSelected: DossierFormalite | undefined = useSelector(
        (s: FormalityStateType) => s.formalites.dosform.main.selected,
        _.isEqual,
    );

    const foliosFormalityDatasource: DataSource<DossierFormalite> = useSelector(
        (s: FormalityStateType) => s.formalites.dosform.main,
        _.isEqual,
    );

    const dispatch = useDispatch<FormalityAppDispatch>();

    const { selectDossierFormalite, getDossierFormalite } = useFormaliteService();

    React.useEffect(() => {
        if (foliosFormalityDatasource.selectedStatus !== 'loading') {
            if (
                !dossierFormaliteSelected ||
                foliosFormalityDatasource.data[0].id !== dossierFormaliteSelected.id
            ) {
                if (foliosFormalityDatasource.data[0] && foliosFormalityDatasource.data[0].id) {
                    dispatch(
                        selectDossierFormalite({
                            id: `${foliosFormalityDatasource.data[0].id}`,
                        }),
                    );
                }
            }
        }
    }, [
        dispatch,
        dossierFormaliteSelected,
        foliosFormalityDatasource.data,
        foliosFormalityDatasource.selectedStatus,
        selectDossierFormalite,
    ]);

    React.useEffect(() => {
        if (
            foliosFormalityDatasource.status !== 'loading' &&
            foliosFormalityDatasource.status !== 'succeeded'
        ) {
            dispatch(getDossierFormalite({}));
        }
    }, [dispatch, foliosFormalityDatasource.status, getDossierFormalite]);

    return dossierFormaliteSelected ? (
        <RecapFormalityShow dossier={dossierFormaliteSelected} />
    ) : null;
};

export default RecapFormalityShowWrapper;
