import React from 'react';

import { Box } from '@mui/material';
import { getDataThunkType, type DataSource } from '@europrocurement/l2d-redux-utils';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { useDebouncedCallback } from 'use-debounce';
import SearchBarGlobal from '../SearchBarGlobal/SearchBarGlobal';

export type SearchBarGlobalDatasourceProps<T extends Record<string, unknown>> = {
    datasource: Pick<DataSource<T>, 'slicename' | 'name' | 'search'>;
    fetchFunction: getDataThunkType<T>;
};

const SearchBarGlobalDatasource: React.FunctionComponent<
    SearchBarGlobalDatasourceProps<Record<string, unknown>>
> = function ({ datasource, fetchFunction }) {
    const dispatch = useDispatch();
    const onSearchChange = useDebouncedCallback((search) => {
        dispatch({
            type: `${datasource.slicename}/set${datasource.name}Search`,
            payload: { search },
        });
        dispatch(fetchFunction({}) as unknown as AnyAction);
    }, 1000);

    return (
        <Box
            sx={{
                marginLeft: '30px',
                display: 'flex',
                width: '40%',
                height: '48px',
            }}
        >
            <SearchBarGlobal
                value={datasource.search}
                placeHolder="référence client, dossier, formalité, ..."
                onChange={(e) => {
                    onSearchChange(e.target.value);
                }}
            />
        </Box>
    );
};

export default SearchBarGlobalDatasource;
