import React from 'react';

import { MenuEntry } from '@europrocurement/flexy-components';

import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import {
    doneInvoiceIcon,
    invoiceIcon,
    myInvoiceIcon,
    pausedInvoiceIcon,
    pausedStatementIcon,
    rejectCodesOnInvoicesIcon,
    statsOnInvoicesIcon,
    todoInvoiceIcon,
} from '@europrocurement/l2d-icons';
import MediaObjectImportView from './views/imports/MediaObjectImportView';
import RegisterSimpleInvoiceView from './views/forms/simple/RegisterSimpleInvoiceView';
import UpdateSimpleInvoiceView from './views/forms/simple/UpdateSimpleInvoiceView';

import AchatsRoot from './AchatsRoot';
import StatsView from './views/stats/StatsView';
import RegisteredInvoicesView from './views/lists/RegisteredInvoicesView';
import RegisterMultiInvoiceView from './views/forms/multi/RegisterMultiInvoiceView';
import { EnteteSaisieMultiple } from './components/forms/multi/steps/EnteteSaisieMultiple';
import { DossierSaisieMultiple } from './components/forms/multi/steps/DossierSaisieMultiple';
import { RecapSaisieMultiple } from './components/forms/multi/steps/RecapSaisieMultiple';
import { NextDossierSaisieMultiple } from './components/forms/multi/steps/NextDossierSaisieMultiple';
import UnclosedRegisteredInvoicesView from './views/lists/UnclosedRegisteredInvoicesView';
import { NextEditDossierSaisieMultiple } from './components/forms/multi/steps/NextEditDossierSaisieMultiple';
import {
    achatsBasePath,
    consulterMultiFacturePath,
    downloadPath,
    invoiceStatementPath,
    listePath,
    modifierFacturePath,
    nextDossierMultiFacturePath,
    nextEditDossierMultiFacturePath,
    nextEditFproDossierMultiFacturePath,
    pausePath,
    recapFproMultiFacturePath,
    recapMultiFacturePath,
    summaryStatementPath,
    registerStatementPath,
    saisirDossierMultiFacturePath,
    saisirEditDossierMultiFacturePath,
    saisirEditFproDossierMultiFacturePath,
    saisirEnteteMultiFacturePath,
    saisirEnteteMultiFactureStatementPath,
    saisirFacturePath,
    saisirMultiFacturePath,
    statsPath,
    traitePath,
    uploadPath,
    readUnclosedStatementPath,
    internMediaObjectsPath,
    updateStatementPath,
    rejectCodesPath,
} from './constants/paths';
import achatsViewPathResolver from './achatsViewPathResolver';
import UnregisteredInvoicesView from './views/lists/UnregisteredInvoicesViews/UnregisteredInvoicesView';
import { RecapFproSaisieMultiple } from './components/forms/multi/steps/RecapFproSaisieMultiple';
import UnclosedInvoiceStatementsView from './views/lists/UnclosedInvoiceStatementsView';
import ReadStatementView from './views/forms/statement/ReadStatementView';
import SummaryStatementView from './views/forms/statement/SummaryStatementView';
import InternUnregisteredInvoicesView from './views/lists/InternUnregisteredInvoicesView';
import UpdateMultiInvoiceView from './views/forms/multi/UpdateMultiInvoiceView';
import UpdateMultiPublicationForm from './components/forms/multi/UpdateMultiPublicationForm';
import UpdateMultiFormalityForm from './components/forms/multi/UpdateMultiFormalityForm';
import RejectCodesList from './views/lists/RejectCodesList';
import RejectCodeDetail from './views/detail/RejectCodeDetail';
import RejectCodeRoot from './subroots/RejectCodeRoot';

const AchatsRouter = {
    path: achatsBasePath,
    element: <AchatsRoot />,
    children: [
        {
            path: listePath,
            children: [
                // La liste des factures a traiter
                {
                    path: '',
                    index: true,
                    element: <UnregisteredInvoicesView />,
                },
                // La liste des factures a traiter en interne
                {
                    path: internMediaObjectsPath,
                    index: true,
                    element: (
                        <KeycloakRoute roles="realm:interne">
                            <InternUnregisteredInvoicesView />
                        </KeycloakRoute>
                    ),
                },
                // Vue de la liste des relevés de facture non clôturé
                {
                    path: invoiceStatementPath,
                    index: true,
                    element: (
                        <KeycloakRoute roles="realm:interne">
                            <UnclosedInvoiceStatementsView />
                        </KeycloakRoute>
                    ),
                },
                // Télécharge le fichier PDF
                {
                    path: downloadPath,
                    index: true,
                    element: (
                        <>
                            <br />
                            **Téléchargement du PDF... ** <br />
                            <br /> la page doit se fermée directement et laisser le navigateur web
                            gérer le téléchargement.
                        </>
                    ),
                },
                // Saisie d'une nouvelle facture liée à un PDF
                {
                    path: saisirFacturePath,
                    index: true,
                    element: <RegisterSimpleInvoiceView />,
                },
                // Saisie d'une nouvelle facture multi-annonces liée à un PDF
                {
                    path: saisirMultiFacturePath,
                    abstract: true,
                    // index: true,
                    element: <RegisterMultiInvoiceView />,
                    children: [
                        {
                            path: saisirEnteteMultiFactureStatementPath,
                            index: true,
                            element: <EnteteSaisieMultiple />,
                        },
                        {
                            path: saisirEnteteMultiFacturePath,
                            index: true,
                            element: <EnteteSaisieMultiple />,
                        },
                        {
                            path: saisirDossierMultiFacturePath,
                            element: <DossierSaisieMultiple />,
                        },
                        {
                            path: nextDossierMultiFacturePath,
                            element: <NextDossierSaisieMultiple />,
                        },
                        {
                            path: saisirEditDossierMultiFacturePath,
                            element: <UpdateMultiPublicationForm />,
                        },
                        {
                            path: saisirEditFproDossierMultiFacturePath,
                            element: <UpdateMultiFormalityForm />,
                        },
                        {
                            path: nextEditDossierMultiFacturePath,
                            element: <NextEditDossierSaisieMultiple />,
                        },
                        {
                            path: nextEditFproDossierMultiFacturePath,
                            element: <NextEditDossierSaisieMultiple isFormalite />,
                        },
                        {
                            path: recapMultiFacturePath,
                            element: <RecapSaisieMultiple />,
                        },
                        {
                            path: recapFproMultiFacturePath,
                            element: <RecapFproSaisieMultiple />,
                        },
                    ],
                },
                // Modification
                {
                    path: modifierFacturePath,
                    index: true,
                    element: (
                        <KeycloakRoute roles="realm:interne">
                            <UpdateSimpleInvoiceView />
                        </KeycloakRoute>
                    ),
                },
                {
                    path: consulterMultiFacturePath,
                    index: true,
                    element: (
                        <KeycloakRoute roles="realm:interne">
                            <UpdateMultiInvoiceView />
                        </KeycloakRoute>
                    ),
                },
                // La liste des factures traitées
                {
                    path: traitePath,
                    index: true,
                    element: (
                        <KeycloakRoute roles="realm:interne">
                            <RegisteredInvoicesView />
                        </KeycloakRoute>
                    ),
                },
                // La liste des factures en pause
                {
                    path: pausePath,
                    index: true,
                    element: <UnclosedRegisteredInvoicesView />,
                },
                {
                    element: <RejectCodeRoot />,
                    children: [
                        {
                            path: rejectCodesPath,
                            children: [
                                {
                                    path: '',
                                    index: true,
                                    element: (
                                        <KeycloakRoute roles="realm:interne">
                                            <RejectCodesList />
                                        </KeycloakRoute>
                                    ),
                                },
                                {
                                    path: ':idRejectCode',
                                    element: (
                                        <KeycloakRoute roles="realm:interne">
                                            <RejectCodeDetail />
                                        </KeycloakRoute>
                                    ),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        // Saisie d'une nouvelle facture liée à un PDF et un relevé
        {
            path: registerStatementPath,
            index: true,
            element: (
                <KeycloakRoute roles="realm:interne">
                    <RegisterSimpleInvoiceView />
                </KeycloakRoute>
            ),
        },
        // Édition d'une facture liée à un PDF et un relevé
        {
            path: updateStatementPath,
            index: true,
            element: (
                <KeycloakRoute roles="realm:interne">
                    <UpdateSimpleInvoiceView />
                </KeycloakRoute>
            ),
        },
        // Consultation d'un relevé de factures
        {
            path: readUnclosedStatementPath,
            index: true,
            element: (
                <KeycloakRoute roles="realm:interne">
                    <ReadStatementView />
                </KeycloakRoute>
            ),
        },
        // Recap des factures d'un relevé
        {
            path: summaryStatementPath,
            index: true,
            element: (
                <KeycloakRoute roles="realm:interne">
                    <SummaryStatementView />
                </KeycloakRoute>
            ),
        },
        {
            path: uploadPath,
            element: (
                <KeycloakRoute roles="realm:interne">
                    <MediaObjectImportView />
                </KeycloakRoute>
            ),
        },
        {
            path: statsPath,
            element: (
                <KeycloakRoute roles="realm:interne">
                    <StatsView />
                </KeycloakRoute>
            ),
        },
    ],
};

export const AchatsMenuEntries: MenuEntry[] = [
    {
        title: 'Portail Achats',
        // role: 'realm:interne',
        icon: 'file-invoice-dollar',
        children: [
            {
                title: 'Import',
                icon: 'file-import',
                href: achatsViewPathResolver('uploadMediaObject'),
                role: 'realm:interne',
            },
            {
                title: 'Factures',
                icon: invoiceIcon,
                // role: 'realm:interne',
                children: [
                    {
                        title: 'À traiter',
                        icon: todoInvoiceIcon,
                        href: achatsViewPathResolver('listmediaobject'),
                        // role: 'realm:mada',
                    },
                    {
                        title: 'À traiter en interne',
                        icon: myInvoiceIcon,
                        href: achatsViewPathResolver('listmediaobjectinternally'),
                        role: 'realm:interne',
                    },
                    {
                        title: 'Traitées',
                        icon: doneInvoiceIcon,
                        role: 'realm:interne',
                        href: achatsViewPathResolver('listfacture'),
                    },
                    {
                        title: 'En pause',
                        icon: pausedInvoiceIcon,
                        href: achatsViewPathResolver('listfacturepause'),
                    },
                    {
                        title: 'Relevés non clôturés',
                        icon: pausedStatementIcon,
                        href: achatsViewPathResolver('listInvoiceStatement'),
                        role: 'realm:interne',
                    },
                ],
            },
            {
                title: 'Codes Rejets',
                icon: rejectCodesOnInvoicesIcon,
                href: achatsViewPathResolver('listRejectCodes'),
                role: 'realm:interne',
                children: [
                    {
                        title: 'Gestion des erreurs',
                        icon: statsOnInvoicesIcon,
                        href: achatsViewPathResolver('achatsstats'),
                        role: 'realm:interne',
                    },
                ],
            },
        ],
    },
];

export default AchatsRouter;
