import React, { useEffect, MouseEvent, useRef } from 'react';

import { useSnackbar } from 'notistack';

import {
    AppBar,
    Box,
    Toolbar,
    SxProps,
    Typography,
    Stack,
    useTheme,
    useMediaQuery,
} from '@mui/material';

import {
    CustomizerReducerType,
    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID,
    useSociete,
} from '@europrocurement/l2d-domain';

import { SidebarFoldedWidth, SidebarWidth } from '@europrocurement/flexy-components/assets';
import { useBasePath } from '@europrocurement/flexy-components/hooks/useBasePath';
import { useLocation } from 'react-router';
import drawerClosePaths from '@europrocurement/flexy-components/constants/drawerClosePaths';
import DropdownPrescriber from '../../atoms/DropdownPrescriber';
import DropdownSociete from '../../atoms/DropdownSociete';
import { useFullLayout } from '../../templates/FullLayout/FullLayoutContext';
import ExitButton from '../../atoms/ExitButton';
import ProfileDropdownOppener from '../../molecules/ProfileDropdownOppener';
import CloseSidebarButton from '../../atoms/CloseSidebarButton/CloseSidebarButton';

function isDrawerRoute(pathname: string) {
    return !drawerClosePaths.find((path) => pathname.includes(path));
}

export type HeaderProps = {
    sx?: SxProps;
    showSociete?: boolean;
    showPrescripteur?: boolean;
    handleClickExitButton?: (event: MouseEvent<HTMLButtonElement>) => void;
    customHeader?: React.ReactNode;
};

const Header: React.FunctionComponent<HeaderProps> = function ({
    sx,
    showSociete = true,
    showPrescripteur = false,
    handleClickExitButton,
    customHeader = null,
}: HeaderProps) {
    const xIdSociete = useSociete();

    const { enqueueSnackbar } = useSnackbar();

    const prevXIdSocieteRef = useRef<CustomizerReducerType['xIdSociete']>();

    useEffect(() => {
        const prevXIdSociete = prevXIdSocieteRef.current;

        if (prevXIdSociete !== undefined && prevXIdSociete !== xIdSociete) {
            enqueueSnackbar(
                <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="baseline"
                    gap={1}
                >
                    <Typography variant="caption">Nouvelle société sélectionnée :</Typography>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                    >
                        {BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[xIdSociete]}
                    </Typography>
                </Stack>,
                {
                    variant: 'info',
                    autoHideDuration: 5000,
                },
            );
        }

        prevXIdSocieteRef.current = xIdSociete;
    }, [xIdSociete, enqueueSnackbar]);

    const { isSidebarOpen, isProfileHeaderVisible } = useFullLayout();

    const theme = useTheme();

    const smBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

    const hiddenSidebarHeaderPaddingLeft = smBreakpoint ? SidebarFoldedWidth : 0;

    const basePath = useBasePath();
    const { pathname } = useLocation();

    const pathWithoutLastPart = typeof basePath === 'string' ? basePath : pathname;

    return (
        <AppBar
            sx={{
                backgroundColor: 'background.paper',
                backdropFilter: 'blur(8px)',
                ...sx,
                paddingLeft: isSidebarOpen
                    ? `${SidebarWidth}px !important`
                    : `${hiddenSidebarHeaderPaddingLeft}px !important`,
            }}
            elevation={0}
        >
            <Toolbar>
                {isDrawerRoute(pathWithoutLastPart) ? (
                    <CloseSidebarButton />
                ) : (
                    <ExitButton handleClickExitButton={handleClickExitButton} />
                )}
                {customHeader || null}
                <Box flexGrow={1} />

                {isProfileHeaderVisible ? (
                    <>
                        {showSociete ? <DropdownSociete /> : null}
                        {showPrescripteur ? <DropdownPrescriber /> : null}
                        {showPrescripteur || showSociete || customHeader ? (
                            <Box
                                sx={{
                                    width: '1px',
                                    backgroundColor: 'text.gris005',
                                    height: '32px',
                                    ml: 1,
                                    mr: 1,
                                }}
                            />
                        ) : null}
                        <ProfileDropdownOppener />
                    </>
                ) : null}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
