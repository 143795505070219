import React, { ReactNode, useMemo, useState } from 'react';

import VerboseContext, {
    verbosityColors,
    VerbosityLevel,
    verbosityNames,
    type VerboseContextProps,
} from './VerboseContext';

export type VerboseProviderType = {
    children: ReactNode;
    enable?: boolean;
    level?: VerbosityLevel;
};

const VerboseProvider: React.FunctionComponent<VerboseProviderType> = function ({
    children,
    enable = false,
    level = 3,
}) {
    const [overwriteLevel, setOverwriteLevel] = useState<VerbosityLevel>(level);

    const isValidVerbosityLevel = (value: unknown): value is VerbosityLevel =>
        typeof value === 'number' && [0, 1, 2, 3].includes(value);

    const verboseContextProviderValue = useMemo<VerboseContextProps>(
        () => ({
            enable,
            level: overwriteLevel,
            setLevel: (verboseLevel) => setOverwriteLevel(verboseLevel),
            log: (...data: unknown[]) => {
                if (!enable || !isValidVerbosityLevel(overwriteLevel)) return;

                const logHeaderContent = `---- [${verbosityNames[overwriteLevel]}] VERBOSE ---- : `;
                const logHeaderCss = `color: ${verbosityColors[overwriteLevel]};`;

                // Create an error without triggering it, to get the trace stack and retrieve from it the location.
                const errorStack = new Error().stack;
                const logInstanceLocation = errorStack?.split('\n')[2]?.trim();

                // eslint-disable-next-line no-console
                console.log(
                    `%c ${logHeaderContent}`,
                    logHeaderCss,
                    ...data,
                    logInstanceLocation ? `\n\nCalled ${logInstanceLocation}` : undefined,
                );
            },
        }),
        [enable, overwriteLevel],
    );

    return (
        <VerboseContext.Provider value={verboseContextProviderValue}>
            {children}
        </VerboseContext.Provider>
    );
};

export default VerboseProvider;
