import { Typography } from '@mui/material';
import React from 'react';

type AdditionalDataLineProps = {
    libelle: string;
    data: Array<string | [key: string, value: string]>;
};

const AdditionalDataLine: React.FunctionComponent<AdditionalDataLineProps> = function ({
    libelle,
    data,
}) {
    const formattedData = data.map((item) => {
        if (typeof item === 'string') {
            return item;
        }
        const [key, value] = item;
        return `${key}: ${value}`;
    });

    return (
        <Typography
            sx={{
                fontStyle: 'italic',
                color: 'text.disabled',
            }}
        >
            {`${libelle} : ${formattedData.join(' - ')}`}
        </Typography>
    );
};

export default AdditionalDataLine;
