import { forwardRef, useImperativeHandle } from 'react';
import { FlexyFilterWidgetProps } from '../DatatableHeader';

const FlexyBooleanFilter: React.FunctionComponent = forwardRef(
    (props: FlexyFilterWidgetProps, ref) => {
        const { filter, value } = props;

        const getValueOnFocus = () => {
            if (filter?.valueMap) {
                return filter.valueMap(value === null ? true : !value);
            }

            return value === null ? true : !value;
        };

        useImperativeHandle(ref, () => ({
            getValueOnFocus,
        }));

        return null;
    },
);

export default FlexyBooleanFilter;
