import React from 'react';

import { SearchBarGlobalDatasource } from '@europrocurement/flexy-components';
import { type DataSource } from '@europrocurement/l2d-redux-utils';
import { useSelector } from 'react-redux';
import { SousClient, SousClientsSelector, useTiersService } from '@europrocurement/l2d-domain';

const SocieteSearchBar: React.FunctionComponent = function () {
    const datasource: DataSource<SousClient> = useSelector(SousClientsSelector).main;
    const { getSousClients } = useTiersService();

    return (
        <SearchBarGlobalDatasource
            datasource={datasource}
            fetchFunction={getSousClients}
        />
    );
};

export default SocieteSearchBar;
