import React, { useState, useEffect, ReactNode } from 'react';
import { Grid, Box } from '@mui/material';
import { FlexyTextField } from '@europrocurement/flexy-components';
import { PreFilterDatatable, SearchOpts, PreFilter } from '../DatatableHeader';
import FlexyFiltersList from '../FlexyFiltersList';
import FlexyPreFilters from '../FlexyPreFilters';
import { FlexyFiltersToggleButton } from '../FlexyFiltersToggleButton';

export type FlexyFiltersProps = {
    searchOpts?: SearchOpts;
    buttonAction?: ReactNode;
};

const isPreFilterActive = (
    preFilterItem: PreFilterDatatable,
    activeCriterion: Record<string, unknown>,
    mustHaveAFilter: boolean,
): boolean => {
    if (mustHaveAFilter && preFilterItem.filters.length === 0) {
        return false;
    }
    const totalCount = preFilterItem.filters.reduce(
        (currentCount: number, f: PreFilter) =>
            activeCriterion[f.field] === f.value ? currentCount + 1 : currentCount,
        0,
    );

    return totalCount === preFilterItem.filters.length;
};

const FlexyFilters: React.FunctionComponent<FlexyFiltersProps> = function ({
    searchOpts,
    buttonAction,
}: FlexyFiltersProps) {
    const [showFilter, setShowFilters] = useState<boolean>(true);
    const [currentPreFilter, setCurrentPreFilter] = useState<PreFilterDatatable>();
    const preFiltersProps = searchOpts?.filtersOpts?.preFilters || { items: [] };
    const activePreFilters = searchOpts?.filtersOpts?.activePreFilter;
    const preFilters = preFiltersProps;

    useEffect(() => {
        let active = preFilters.items.find((item) =>
            isPreFilterActive(item, activePreFilters || {}, true),
        );

        if (!active) {
            active = preFilters.items.find((item) =>
                isPreFilterActive(item, activePreFilters || {}, false),
            );
        }

        if (active) {
            setCurrentPreFilter(active);
        }
    }, [preFilters, activePreFilters]);

    const applyPreFilter = (preFilter: PreFilterDatatable) => {
        setCurrentPreFilter(preFilter);
        searchOpts?.filtersOpts?.onUpdateFilters(preFilter);
    };

    return (
        <Grid
            container
            direction="column"
            alignItems="end"
        >
            <Grid
                item
                sx={{ width: '100%' }}
            >
                <Box
                    display="flex"
                    flexDirection="row-reverse"
                    justifyContent="space-between"
                    alignItems="end"
                    gap={2}
                >
                    <Box>
                        <FlexyFiltersToggleButton
                            searchOpts={searchOpts}
                            setShowFilters={setShowFilters}
                            showFilter={showFilter}
                            buttonAction={buttonAction}
                        />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        {preFilters.items.length > 0 && (
                            <FlexyPreFilters
                                filters={preFilters}
                                onClick={applyPreFilter}
                                current={currentPreFilter}
                            />
                        )}
                        {searchOpts?.showSearch ? (
                            <FlexyTextField
                                sx={{
                                    marginTop: '15px',
                                }}
                                inputProps={{
                                    style: {
                                        padding: '14px 14px',
                                    },
                                }}
                                defaultValue={searchOpts?.search || ''}
                                onChange={(e) => {
                                    if (searchOpts?.onSearchChange) {
                                        searchOpts?.onSearchChange(e.target.value);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                placeholder="Recherche textuelle"
                            />
                        ) : null}
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                alignSelf="start"
                sx={{ marginTop: '15px' }}
            >
                {showFilter && <FlexyFiltersList filtersOpts={searchOpts?.filtersOpts} />}
            </Grid>
        </Grid>
    );
};

export default FlexyFilters;
