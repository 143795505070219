import React from 'react';

import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';

import { DataCard } from '@europrocurement/flexy-components';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { CategorieStats } from '@europrocurement/l2d-domain';

import { getCategorieStats, CategorieStatsSelector } from '@b2d/redux/RootStore';
import { AppDispatch } from '@b2d/redux/types';

type CategoriesPanelProps = {
    afficherZeros: boolean;
    cardsProps: { [key: string]: JSX.Element };
};

const CategoriesPanel: React.FunctionComponent<CategoriesPanelProps> = function ({
    afficherZeros,
    cardsProps,
}) {
    const categoriesDataSource: DataSource<CategorieStats> =
        useSelector(CategorieStatsSelector).main;
    const categories = categoriesDataSource.data;

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
            >
                <Grid container>
                    {[...categories]
                        .sort((a, b) => {
                            if (!a.name || !b.name) return 0;
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0;
                        })
                        .map((categorie) =>
                            categorie.name &&
                            (!afficherZeros ? categorie.count && categorie.count !== 0 : true) ? (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={4}
                                    key={`${categorie.name} • ${categorie.libelle}`}
                                >
                                    <DataCard
                                        title={`${categorie.name} • ${categorie.libelle}`}
                                        icon={cardsProps[categorie.name]}
                                        color={categorie.name}
                                        tools={
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {
                                                        dispatch({
                                                            type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                                            payload: {
                                                                key: 'categories',
                                                                value: [categorie.name],
                                                            },
                                                        });
                                                        dispatch(getCategorieStats({}));
                                                        navigate('/achats/stats?tab=codeRejet');
                                                    }}
                                                >
                                                    Codes rejets {categorie.name}
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {
                                                        dispatch({
                                                            type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                                            payload: {
                                                                key: 'categories',
                                                                value: [categorie.name],
                                                            },
                                                        });
                                                        dispatch(getCategorieStats({}));
                                                        navigate('/achats/stats?tab=detail');
                                                    }}
                                                >
                                                    Détails {categorie.name}
                                                </Button>
                                            </>
                                        }
                                        sx={{
                                            minHeight: '90%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <strong>{categorie.count}</strong> Facture(s) en
                                            anomalie(s)
                                        </Typography>
                                    </DataCard>
                                </Grid>
                            ) : (
                                false
                            ),
                        )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CategoriesPanel;
