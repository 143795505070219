type PresciberAddress = {
    adresse1: string;
    adresse2?: string;
    adresse3?: string;
    adresse4?: string;
    codePostal: string;
    ville: string;
};
export const formatPrescriberAddress = (
    prescriberAddress: PresciberAddress,
    format: 'short' | 'long',
): string => {
    const {
        adresse1: address1,
        adresse2: address2,
        adresse3: address3,
        adresse4: address4,
        codePostal: zipCode,
        ville: city,
    } = prescriberAddress;
    const appendCity = (addressBase: string) => `${addressBase}, ${zipCode} ${city}`;

    if (format === 'short') {
        return appendCity(address1);
    }
    if (format === 'long') {
        const appendableAddresses = [address2, address3, address4];
        let appendedAddress = address1;
        appendableAddresses.forEach((value) => {
            if (value) {
                appendedAddress = `${appendedAddress} ${value}`;
            }
        });
        return appendCity(appendedAddress);
    }
    return '';
};
