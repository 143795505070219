import React, { useCallback, useEffect, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { mediaObjectApi } from '@b2d/redux/RootStore';
import { todoInvoiceIcon } from '@europrocurement/l2d-icons';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import WidgetWithAction from './components/WidgetWithAction';

// All

const FacturesATraiter: React.FunctionComponent = function () {
    const navigate = useNavigate();

    const [countOfUnregisteredInvoicesToTreat, setCountOfUnregisteredInvoicesToTreat] =
        useState<string>();

    useEffect(() => {
        mediaObjectApi
            .listMediaObjectMediaObjectCollection({
                page: 1,
                itemsPerPage: 0,
                // createdBy: '',
                forceInterne: false,
                dateDebutTraitementBefore: new Date(
                    new Date().getTime() - 2 * 60 * 1000,
                ).toISOString(),
                available: true,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setCountOfUnregisteredInvoicesToTreat(`${res.data['hydra:totalItems']}`);
                }
            });
    }, []);

    const actionCallback = useCallback(async () => {
        navigate(achatsViewPathResolver('listmediaobject'));
    }, [navigate]);

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <WidgetWithAction
                title="Factures à traiter"
                icon={todoInvoiceIcon}
                actionMessage="Traiter"
                actionCallback={actionCallback}
                data={countOfUnregisteredInvoicesToTreat}
                paragraph="Factures à traiter :"
                color="success"
            />
        </Grid>
    );
};

export default FacturesATraiter;
