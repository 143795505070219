import * as React from 'react';
import { FlexyTabs, TabStructure } from '@europrocurement/flexy-components';
import { SUB_TAB_ROUTER_PARAMETER } from '@b2d/pages/Prescripteurs/constants/paths';
import FormalitiesSettings from './Formalities/FormalitiesSettings';
import FormalitiesNotifications from './Formalities/FormalitiesNotifications';
import { PrescriberTabProps } from './types';

const Formalitie: React.FunctionComponent<PrescriberTabProps> = function (props) {
    const { prescriber } = props;
    const TABS: Array<TabStructure> = [
        {
            tabName: 'parametrage',
            tabTitle: 'Paramétrage',
            tabPanel: <FormalitiesSettings prescriberId={`${prescriber.id}`} />,
        },
        {
            tabName: 'notification',
            tabTitle: 'Notifications',
            tabPanel: <FormalitiesNotifications />,
        },
    ];

    return (
        <FlexyTabs
            orientation="vertical"
            tabs={TABS}
            inCard
            routerParameterName={SUB_TAB_ROUTER_PARAMETER}
        />
    );
};

export default Formalitie;
