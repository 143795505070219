import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';
import { Tooltip } from '@mui/material';
import * as React from 'react';

export type TooltipedIconProps = {
    tooltip: string | (React.JSX.Element | null)[];
    icon: NamedIconsType;
};

const TooltipedIcon: React.FunctionComponent<TooltipedIconProps> = function ({ tooltip, icon }) {
    return (
        <Tooltip title={tooltip}>
            <FaOptionIcon {...icon.props} />
        </Tooltip>
    );
};

export default TooltipedIcon;
