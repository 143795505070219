import * as React from 'react';
import { InformationCard, InformationCardProps } from '@europrocurement/flexy-components';
import { OffresOffreRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { visibilityTranslation } from '@europrocurement/l2d-modules/modules/JsonForms';
import { JsonLdData, EntityPreview } from '../forms/types';

type EntityType = JsonLdData<OffresOffreRead>;

const OfferPreview: React.FunctionComponent<EntityPreview<EntityType>> = function (props) {
    const { entity, onEdit } = props;

    const cardTitle = entity.libelle;
    const cardItems: InformationCardProps['items'] = [
        {
            label: 'Visibilité',
            value: entity.visibilite ? visibilityTranslation[entity.visibilite] : null,
        },
        {
            label: 'Domaine',
            value: entity.domaine?.libelle ?? null,
        },
        {
            label: 'Calcul collections combinées',
            value: entity.calculCombine?.libelle ?? null,
        },
        {
            label: 'Calculcollections multiples',
            value: entity.calculMultiple?.libelle ?? null,
        },
    ];

    return (
        <InformationCard
            title={cardTitle}
            items={cardItems}
            subtitle=""
            button={{
                label: 'Éditer',
                onClick: onEdit,
            }}
        />
    );
};

export default OfferPreview;
