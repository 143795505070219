import {
    DossierBody,
    DossierRoutingProvider,
    SelectedDossierProvider,
} from '@europrocurement/l2d-modules';
import { Container } from '@mui/material';
import React from 'react';
import { dossiersBasePath } from '../../constants';
import { ordersBasePath } from '../../../Orders';
import { DetailDossierHeader } from '../../components';

const DetailDossier: React.FunctionComponent = function () {
    return (
        <DossierRoutingProvider
            orderBasePath={ordersBasePath}
            dossierBasePath={dossiersBasePath}
        >
            <SelectedDossierProvider>
                <Container maxWidth="lg">
                    <DetailDossierHeader />
                    <DossierBody />
                </Container>
            </SelectedDossierProvider>
        </DossierRoutingProvider>
    );
};

export default DetailDossier;
