import React from 'react';
import { Box, Typography } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

export type DossierNotificationsProps = {
    documentsCount?: number;
    messagesCount?: number;
};

const DossierNotifications: React.FunctionComponent<DossierNotificationsProps> = function (props) {
    const { documentsCount = 0, messagesCount = 0 } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    color: 'text.gris70',

                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                }}
            >
                <FaOptionIcon icon={['fasl', 'paperclip']} />
                <Typography
                    variant="l2dtableCell"
                    component="span"
                >
                    {documentsCount}
                </Typography>
            </Box>
            <Box
                sx={{
                    color: 'text.gris70',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                }}
            >
                <FaOptionIcon icon={['fasl', 'message']} />
                <Typography
                    variant="l2dtableCell"
                    component="span"
                >
                    {messagesCount}
                </Typography>
            </Box>
        </Box>
    );
};

export default DossierNotifications;
