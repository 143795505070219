import {
    CatalogApiObject,
    CollectionDeDossierApi,
    Contact,
    Prescripteur,
    SousClientApi,
} from '@europrocurement/l2d-domain';
import {
    FproPrestationJsonldCollectiondossierCreate,
    PrestationApi,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export type createCollectionFormaliteProps = {
    prescripteur: Prescripteur;
    contact: Contact;
    // idFormalite: Array<number>;
    // idCatalogs: Array<number>;
    catalogs?: CatalogApiObject[];
    collectionApi: CollectionDeDossierApi;
    xIdSociete: number;
    libelle?: string;
    sousClient?: SousClientApi;
    infoComp?: string;
    societeRef?: string;
    societeDenomination?: string;
};

export const createCollectionFormalite = async ({
    prescripteur,
    contact,
    catalogs,
    collectionApi,
    xIdSociete,
    libelle,
    sousClient,
    infoComp,
    societeRef,
}: createCollectionFormaliteProps) => {
    if (!prescripteur.id) {
        throw new Error('missing prescriber');
    }

    if (!catalogs || catalogs.length === 0) {
        throw new Error('missing catalog');
    }
    if (!sousClient || !sousClient.id) {
        throw new Error('missing sousClient');
    }

    const mapPresta: FproPrestationJsonldCollectiondossierCreate[] = catalogs.map(
        (currentCatalog: CatalogApiObject) => {
            if (!currentCatalog['@id']) {
                throw new Error('missing catalog');
            }
            if (!currentCatalog.package) {
                throw new Error('missing package');
            }
            if (!currentCatalog.package.formalite) {
                throw new Error('missing formalite');
            }

            return {
                libelle: currentCatalog.nomCommercial, // "Changement de gérant du 05 juin",
                statut: '/formalite/statuts/1',
                deleted: false,
                catalogue: currentCatalog['@id'].replace(
                    '/offre/catalogues',
                    '/formalite/catalogues',
                ),
                formalite: currentCatalog.package.formalite.replace(
                    '/offre/formalite',
                    '/formalite/formalites',
                ),
                json: '{}',
            };
        },
    );

    const res = await collectionApi.apiCollectionsDossiersPost({
        collectionDeDossierJsonldCollectiondossierCreate: {
            dossiers: [
                {
                    contact: contact.id,
                    societeRef,
                    societeDenomination: sousClient.raisonSociale,
                    origine: 13,
                    // "idGreffe": 106,
                    societeSiren: sousClient.siren,
                    clientOpere: sousClient.id,
                    idFormeJuridique: 21,
                    formaliste: prescripteur.formaliste?.id,
                    // "acompteAttendu": "500.00",
                    // "affaire": "affaire du dossier",
                    prestations: mapPresta,
                },
            ],
            prescripteur: `/formalite/tiers/${prescripteur.id}`,
            client: `/formalite/tiers/${sousClient.id}`,
            societe: xIdSociete,
            libelle,
            infoComp,
        },
    });

    if (!res.data) {
        throw new Error('missing collection');
    }

    return res.data;
};

export const addDataToCollection = async (
    idCollection: number | string,
    collectionApi: CollectionDeDossierApi,
    data: string,
) => {
    const freshCollection = (
        await collectionApi.apiCollectionsDossiersIdGet({ id: `${idCollection}` })
    ).data;
    if (!freshCollection || !freshCollection.id) {
        throw new Error('error in Collection');
    }
    await collectionApi.apiCollectionsDossiersIdPut({
        id: `${idCollection}`,
        collectionDeDossierJsonldCollectiondossierUpdate: {
            ...freshCollection,
            // prescripteur: freshCollection.prescripteur?.replace('/tiers/tiers', '/formalite/tiers'),
            // client: freshCollection.client?.replace('/tiers/tiers', '/formalite/tiers'),
            infoComp: data,
        },
    });
};

export const updateJsonPrestaionFormalite = async (
    prestationFormaliteApi: PrestationApi,
    prestaId: number | null,
    json: string,
) => {
    // const prestaId = presta.id ? presta.id : castIriToId(presta['@id']);

    if (!prestaId) {
        throw new Error('missing prestaId');
    }
    const freshPresta = (
        await prestationFormaliteApi.apiPrestationsIdGet({
            id: `${prestaId}`,
        })
    ).data;

    if (!freshPresta.catalogue) {
        throw new Error('no catalog in presta');
    }

    if (!freshPresta.catalogue['@id']) {
        throw new Error('error in presta');
    }

    await prestationFormaliteApi.apiPrestationsIdPut({
        id: `${prestaId}`,
        prestationJsonldPrestationUpdate: {
            dossier: freshPresta.dossier,
            libelle: freshPresta.libelle,
            statut: freshPresta.statut,
            deleted: freshPresta.deleted,
            catalogue: freshPresta.catalogue['@id'],
            formalite: freshPresta.formalite,
            json: JSON.stringify({
                ...JSON.parse(freshPresta.json || '{}'),
                ...JSON.parse(json),
            }),
        },
    });
};
