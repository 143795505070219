import * as React from 'react';

import { Box, Divider, Typography } from '@mui/material';
import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';

type HeaderProps = {
    title: string;
    icon: NamedIconsType;
};

const Header: React.FunctionComponent<HeaderProps> = function ({ title, icon }) {
    return (
        <Box
            id="form_modal_header"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                width: '100%',
            }}
        >
            <Typography
                display="flex"
                alignItems="center"
                width="100%"
                color="secondary.main"
                padding={1}
                variant="h2"
                sx={{ marginTop: '15px', gap: '5px' }}
            >
                <FaOptionIcon {...icon.props} />
                {title}
            </Typography>
            <Divider
                sx={{
                    width: '100%',
                    backgroundColor: 'secondary.main',
                    borderWidth: '1px',
                }}
            />
        </Box>
    );
};

export default Header;
