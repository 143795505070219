import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Modal, useTheme } from '@mui/material';
import { ObjectGroupFormData, ObjectGroupType } from '@europrocurement/flexy-components';
import { enqueueSnackbar } from 'notistack';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import DossierActionContext from '../../../providers/DossierActionContext';
import DossierSearchGroup from '../DossierSearchGroup/DossierSearchGroup';
import CreateDossierGroup from '../CreateDossierGroup/CreateDossierGroup';
import useDossier from '../../../hooks/useDossier';
import useDossierGroup from '../../../hooks/useDossierGroup';

const DossierGroupModal: React.FunctionComponent = function () {
    const { currentAction, cancelCurrentAction, confirmCurrentAction, dossiers } =
        useContext(DossierActionContext);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedGroups, setSelectedGroups] = useState<Array<ObjectGroupType>>([]);
    const [creating, setCreating] = useState<boolean>(false);
    const { createDossierGroup } = useDossierGroup();
    const { addDossiersInGroups } = useDossier();
    const { palette } = useTheme();

    useEffect(() => {
        setModalOpen(currentAction === 'manageGroups');
        setCreating(false);
    }, [currentAction]);

    useEffect(() => {
        const tmp: Array<ObjectGroupType> = [];

        dossiers.forEach((dossier) => {
            const groups = dossier?.groups || [];
            groups.forEach((group) => {
                tmp.push(group as unknown as ObjectGroupType);
            });
        });

        setSelectedGroups(tmp);
    }, [dossiers]);

    const handleCloseModal = () => {
        setModalOpen(false);
        setCreating(false);
        cancelCurrentAction();
    };

    const handleSelectGroup = async (data: ObjectGroupType) => {
        try {
            await addDossiersInGroups(dossiers, [data?.id]);
            confirmCurrentAction();
            enqueueSnackbar('Votre dossier a bien été ajouté au groupe');
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Une erreur est survenue durant la sélection de votre groupe', {
                variant: 'error',
            });
        }
    };

    const handleEnableCreateMode = () => {
        setCreating(true);
    };

    const handleCancelCreateMode = () => {
        setCreating(false);
    };

    const handleCreate = async (newGroup: ObjectGroupFormData) => {
        const groupPayload = {
            label: newGroup.namegroup,
            color: newGroup.color,
        };

        try {
            const groupCreated = await createDossierGroup(groupPayload as ObjectGroupType);
            await addDossiersInGroups(dossiers, [groupCreated?.id]);
            confirmCurrentAction();
            enqueueSnackbar('Votre dossier a bien été ajouté au groupe');
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Une erreur est survenue durant la création de votre groupe', {
                variant: 'error',
            });
        }
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="create-modal-title"
            aria-describedby="create-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        fontSize: '16px',
                        position: 'absolute',
                        right: '20px',
                        top: '35px',
                        color: palette.grey[500],
                    }}
                >
                    <FaOptionIcon icon={['fas', 'close']} />
                </IconButton>
                {!creating && (
                    <DossierSearchGroup
                        selectedItems={selectedGroups}
                        onSubmit={handleSelectGroup}
                        onCreate={handleEnableCreateMode}
                    />
                )}
                {creating && (
                    <CreateDossierGroup
                        onSubmit={handleCreate}
                        onCancel={handleCancelCreateMode}
                        colors={[
                            'blue',
                            'red',
                            'danger',
                            'warning',
                            'success',
                            'deepPurple',
                            'pink',
                        ]}
                    />
                )}
            </Box>
        </Modal>
    );
};

export default DossierGroupModal;
