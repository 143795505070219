import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

export type AutocompleteOptionProps = {
    selected: boolean;
    option: unknown;
    getOptionLabel: (option: unknown) => ReactNode;
};

const AutocompleteOption: React.FunctionComponent<AutocompleteOptionProps> = function (props) {
    const { selected, option, getOptionLabel } = props;
    const containerSx = {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px',
        fontWeight: '500',
        fontSize: '15px',
    };
    const checkIconSx = {
        lineHeight: 0,
        color: 'primary.dark',
        fontSize: '14px',
        fontWeight: '400',
    };

    return (
        <Box
            component="span"
            sx={containerSx}
        >
            {getOptionLabel(option)}
            {selected && (
                <FaOptionIcon
                    sx={checkIconSx}
                    icon={['fas', 'check']}
                />
            )}
        </Box>
    );
};

export default AutocompleteOption;
