import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { civilites } from '@europrocurement/l2d-domain';
import { CompanyItem, ContactFormData } from '../../../hooks';

export type CreateContactFormProps = {
    processing: boolean;
    companies: Array<CompanyItem>;
    onSubmit: (contact: ContactFormData) => void;
    onCancel: () => void;
};

const civilitiesOptions = civilites.slice(0, 2).map((civilite) => ({
    label: civilite.libelle,
    value: civilite.id,
}));

const CreateContactForm: React.FunctionComponent<CreateContactFormProps> = function (props) {
    const { processing, companies, onSubmit, onCancel: handleCancel } = props;

    const handleSubmit: SubmitHandler<ContactFormData> = (formData: ContactFormData) => {
        onSubmit(formData);
    };

    const formStructure: FormStructure[] = [
        {
            type: 'selectItems',
            name: 'gender',
            inputlabel: 'Civilité',
            rules: {
                required: 'Ce champs est requis',
            },
            colInline: 6,
            items: civilitiesOptions,
            multiple: false,
            mode: 'inline',
        },
        {
            type: 'text',
            name: 'lastName',
            inputlabel: 'Nom',
            placeholder: 'Nom du contact',
            rules: {
                required: 'Ce champs est requis',
            },
        },
        {
            type: 'text',
            name: 'firstName',
            inputlabel: 'Prénom',
            placeholder: 'Prénom du contact',
            rules: {
                required: 'Ce champs est requis',
            },
        },
        {
            type: 'email',
            name: 'emailAddress',
            inputlabel: 'E-mail',
            placeholder: 'Adresse e-mail du contact',
            rules: {
                required: 'Ce champs est requis',
            },
        },
        {
            type: 'text',
            name: 'phoneNumber',
            inputlabel: 'Téléphone',
            placeholder: 'Téléphone du contact',
            rules: {
                required: 'Ce champs est requis',
            },
        },
        {
            type: 'select',
            name: 'company',
            inputlabel: 'Société',
            getOptionLabel: (option: CompanyItem) => option.label,
            getOptionValue: (option: CompanyItem) => option.value.toString(),
            options: companies,
            placeholder: 'Société du contact',
            rules: {
                required: 'Ce champs est requis',
            },
        },
    ];

    const formContext = useForm({
        mode: 'onChange',
    });

    const formProps = {
        formContext,
        formObject: {},
        onSubmit: handleSubmit,
        submitButton: {
            displayed: false,
        },
    };

    return (
        <Box>
            <Typography variant="l2dh6">Créer un nouveau contact</Typography>
            <Box sx={{ paddingBottom: '26px' }}>
                <FlexyForm
                    formStructure={formStructure}
                    {...formProps}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '32px',
                }}
            >
                <Button
                    variant="text"
                    sx={{
                        padding: '16px 0',
                        color: 'primary.dark',
                    }}
                    onClick={() => handleCancel()}
                    disabled={processing}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: 'primary.dark', padding: '16px 24px' }}
                    onClick={formContext.handleSubmit(handleSubmit)}
                    disabled={processing}
                >
                    Créer un contact
                </Button>
            </Box>
        </Box>
    );
};

export default CreateContactForm;
