// Contact form validation, inital values & api <-> form transformation methods

import {
    ContactJsonldTiersRead,
    ContactJsonldTiersReadCiviliteEnum,
    ContactsContactJsonldContactCreate,
    ContactsContactJsonldContactUpdate,
    ContactsContactJsonldContactWriteEmailsPrescripteur,
    ContactsContactJsonldContactWriteTelsPrescripteur,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { z, ZodSchema } from 'zod';

type ApiEntity = ContactJsonldTiersRead;
type CreationPayload = ContactsContactJsonldContactCreate;
type UpdatePayload = ContactsContactJsonldContactUpdate;
type PhonesUpdatePayload = ContactsContactJsonldContactWriteTelsPrescripteur;
type EmailsUpdatePayload = ContactsContactJsonldContactWriteEmailsPrescripteur;
type FormContactCollection = Array<{ value: string; default: boolean }>;

/**
 * Error messages
 */
const requiredFieldMessage = 'Ce champs est obligatoire';
const invalid = 'Format incorrect';
const maxLengthMessage = (limit: number) => `Ce champs ne peut dépasser les ${limit} catactères`;
const API_TIERS_PHONE_REGEX = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

/**
 * Custom fields definitions
 */
const phoneValidation = z
    .string()
    .regex(API_TIERS_PHONE_REGEX, invalid)
    .max(50, maxLengthMessage(50)); // TODO Specs: Phone specific validation rules
const emailValidation = z.string().email(invalid).max(50, maxLengthMessage(50)); // TODO Specs: Email specific validation rules
const civility = ContactJsonldTiersReadCiviliteEnum;

/**
 * Single value SelectItems wrapper
 *
 * Wrapper for Single Select Items value validation ( value wrapped in array )
 *
 * @param zElement - Zod validation to wrapped validation
 * @returns element wrapped in an array of length one
 */
const singleItem = (zElement: ZodSchema) => z.array(zElement).length(1);

/**
 * Zod validation schema of Contact form
 * @see Zod https://zod.dev
 * @returns contact form validation schema
 */
export const contactSchema = z.object({
    civilityId: z.nativeEnum(civility), // Code civilité venant de TBL_Param_Reference
    firstName: z.string().min(1, requiredFieldMessage).max(50, maxLengthMessage(50)), // Prénom
    lastName: z.string().min(1, requiredFieldMessage).max(50, maxLengthMessage(50)), // Nom
    position: z.string().max(255, maxLengthMessage(255)).optional(), // Fonction
    mainPhone: phoneValidation.min(1, requiredFieldMessage), // TéléphoneValidation principal
    optionalPhones: z.array(phoneValidation.or(z.literal(''))).optional(), // TéléphoneValidations optionnels
    mainEmail: emailValidation.min(1, requiredFieldMessage), // E-mail principal
    optionalEmails: z.array(emailValidation.or(z.literal(''))).optional(), // E-mail optionnels
    isAdmin: singleItem(z.boolean()), // Administrateur : True, Collaborateur : False
    isMainContact: z.boolean(), // Contact principal
    isBillingContact: z.boolean(), // Contact comptabilité
    hasLeft: z.boolean(), // Résilier l'accès
    note: z.string().max(255, maxLengthMessage(255)).optional(), // Note
});

// Contact form values type infered from Zod schema
export type ContactFormValues = z.infer<typeof contactSchema>;

const getEntityContacts = (
    apiEntity: ApiEntity,
): Pick<ContactFormValues, 'mainPhone' | 'mainEmail' | 'optionalPhones' | 'optionalEmails'> => {
    const phones: FormContactCollection = [
        ...apiEntity.telephonesContact.map(({ telephone, defaut }) => ({
            value: telephone,
            default: defaut ?? false,
        })),
        ...apiEntity.mobilesContact.map(({ mobile, defaut }) => ({
            value: mobile,
            default: defaut ?? false,
        })),
    ];
    const emails: FormContactCollection = apiEntity.emailsContact.map(({ email, defaut }) => ({
        value: email,
        default: defaut ?? false,
    }));

    const mainPhone = phones.find((phone) => phone.default === true)?.value;
    const optionalPhones = phones
        .filter((phone) => phone.value !== mainPhone)
        .map((phone) => phone.value);
    const mainEmail = emails.find((email) => email.default === true)?.value;
    const optionalEmails = emails
        .filter((email) => email.value !== mainEmail)
        .map((email) => email.value);

    return {
        mainPhone: mainPhone ?? '',
        optionalPhones,
        mainEmail: mainEmail ?? '',
        optionalEmails,
    };
};

/**
 * Transform a contact api entity to a valid contact form values.
 * @param apiEntity - The prescriber contact object returned by api.
 * @returns formValues - Form values procedeed by contact form
 */
const apiToFormContact = (apiEntity: ApiEntity): ContactFormValues => {
    const { mainPhone, optionalPhones, mainEmail, optionalEmails } = getEntityContacts(apiEntity);
    console.log(apiEntity);

    return {
        civilityId: apiEntity.civilite,
        firstName: apiEntity.prenomContact,
        lastName: apiEntity.nomContact,
        position: apiEntity.fonction,
        mainPhone,
        optionalPhones,
        mainEmail,
        optionalEmails,
        isAdmin: [apiEntity.administrateur ?? false],
        isMainContact: apiEntity.principal ?? true,
        isBillingContact: apiEntity.comptabilite ?? false,
        hasLeft: false,
        note: apiEntity.note ?? '',
    };
};

/**
 * Remove falsy values in string array ( undefined, empty strings .. )
 * @param list - Array to filter
 * @returns filteredList - Filtered array */
const filterDefinedValues = (list?: string[]): string[] => list?.filter((field) => !!field) ?? [];

/*
 * Convert form phones to contact api payload
 * @param formValues - Values provided by contact form
 * @returns payload - contact phones format expected by API */
export const formPhonesToPayload = (formValues: ContactFormValues): PhonesUpdatePayload => {
    const { mainPhone, optionalPhones } = formValues;

    const mainContactPhone = {
        telephone: mainPhone,
        defaut: true,
    };

    const optionals = filterDefinedValues(optionalPhones);

    const otherPhones =
        (optionals &&
            optionals.map((optionalPhone) => ({
                telephone: optionalPhone,
                defaut: false,
            }))) ??
        [];

    return {
        telephonesContact: [mainContactPhone, ...otherPhones],
    };
};

/**
 * Convert form emails to contact api payload
 * @param formValues - Values provided by contact form
 * @returns payload - contact emails format expected by API */
export const formEmailsToPayload = (formValues: ContactFormValues): EmailsUpdatePayload => {
    const { mainEmail, optionalEmails } = formValues;
    const mainContactEmail = {
        email: mainEmail,
        defaut: true,
    };

    const optionals = filterDefinedValues(optionalEmails);
    const otherEmails =
        (optionals &&
            optionals.map((optionalEmail) => ({
                email: optionalEmail,
                defaut: false,
            }))) ??
        [];
    return {
        emailsContact: [mainContactEmail, ...otherEmails],
    };
};

/**
 * Extract contact information payload from form values
 * @param formValues - Values provided by contact form
 * @returns creationPayload - The prescriber contact object expected by api. */
export const formToBasePayload = (
    formValues: ContactFormValues,
): Pick<
    CreationPayload,
    | 'civilite'
    | 'prenomContact'
    | 'nomContact'
    | 'fonction'
    | 'note'
    | 'isExit'
    | 'administrateur'
    | 'principal'
    | 'comptabilite'
> => ({
    civilite: formValues.civilityId,
    prenomContact: formValues.firstName,
    nomContact: formValues.lastName,
    fonction: formValues.position ?? '',
    note: formValues.note ?? '',
    principal: formValues.isMainContact ?? false,
    comptabilite: formValues.isBillingContact ?? false,
    isExit: formValues.hasLeft ?? false,
    administrateur: formValues.isAdmin[0], // Select Item with one value wrapped in array
});

/**
 * Extract contacts collection specific payload from form values
 * @param formValues - Values provided by contact form
 * @returns creationPayload - The prescriber contact collections ( phones, emails ) expected by api. */
export const formToPhonesAndEmailsPayload = (
    formValues: ContactFormValues,
): Pick<
    CreationPayload,
    'telephonesContact' | 'emailsContact' | 'mobilesContact' | 'faxsContact'
> => {
    const { telephonesContact } = formPhonesToPayload(formValues);
    const { emailsContact } = formEmailsToPayload(formValues);
    return {
        telephonesContact,
        emailsContact,
        mobilesContact: [],
        faxsContact: [],
    };
};

/**
 * Transform contact form to valid contact creation payload
 * @param formValues - Values provided by contact form
 * @returns creationPayload - The prescriber contact object expected by api. */
export const formToContactCreationPayload = (formValues: ContactFormValues): CreationPayload => ({
    ...formToBasePayload(formValues),
    ...formToPhonesAndEmailsPayload(formValues),
});

/**
 * Transform contact form to valid contact update payload
 * @param formValues - Values provided by contact form
 * @returns updatePayload - The prescriber contact object expected by api. */
export const formToContactUpdatePayload = (formValues: ContactFormValues): UpdatePayload => ({
    ...formToBasePayload(formValues),
});

/**
 * Contact form initial values
 * @constant
 * @default
 */
export const contactFormInitialValues: ContactFormValues = {
    civilityId: 0, // Single select item wrapper
    firstName: '',
    lastName: '',
    position: '',
    mainPhone: '',
    optionalPhones: [],
    mainEmail: '',
    optionalEmails: [],
    isAdmin: [false], // Single select item wrapper
    isMainContact: true,
    isBillingContact: true,
    hasLeft: false,
    note: '',
};

/**
 * Provide initial contact form values
 * No entity provided - Default creation form values
 * Entity provided - Form values hydrated with Api contact
 * @param [apiContact] - Optional contact api object
 * @returns formValues - form values used to prefill the form */
export const getContactFormInitialValues = (
    apiContact?: ContactJsonldTiersRead,
): ContactFormValues => {
    if (!apiContact) {
        return contactFormInitialValues;
    }
    return apiToFormContact(apiContact);
};
