import * as React from 'react';
import { AdresseTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { formatPrescriberAddress } from '@europrocurement/l2d-utils';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button, Typography } from '@mui/material';

export type AddressLinkProps = {
    address: AdresseTiersRead;
    icon?: IconProp;
    type?: 'text' | 'button';
};

const AddressLink: React.FunctionComponent<AddressLinkProps> = function (props) {
    const { address, icon, type = 'text' } = props;
    const [onHover, setHover] = React.useState<boolean>(false);

    const longAddress = formatPrescriberAddress(address, 'long');
    const shortAddress = formatPrescriberAddress(address, 'short');

    const openGoogleMapTab = () => {
        const mapQueryUrl = `https://www.google.com/maps/?q=${longAddress}`;
        window.open(mapQueryUrl, '_blank');
    };

    const hoverProps = {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
    };
    if (type === 'text') {
        const pointerOnHover = {
            cursor: onHover ? 'pointer' : '',
        };
        return (
            <Typography
                {...hoverProps}
                sx={pointerOnHover}
                onClick={openGoogleMapTab}
            >
                {shortAddress}
            </Typography>
        );
    }
    const subtitleElementStyle = {
        display: 'flex',
        opacity: 0.6,
        fontSize: '12px',
        fontWeight: '300',
        alignItems: 'center',
        marginRight: '10px',
        color: 'inherit',
    };

    return (
        <Button
            data-testid="AddressLink-test-id"
            onClick={openGoogleMapTab}
            sx={subtitleElementStyle}
        >
            {icon && (
                <FaOptionIcon
                    sx={{ marginRight: '4px' }}
                    icon={icon}
                />
            )}
            <Typography>{shortAddress}</Typography>
        </Button>
    );
};

export default AddressLink;
