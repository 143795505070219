export const achatsBasePath = 'achats';

export const saisirFacturePath = 'saisir/:mediaobjectid';
export const saisirMultiFacturePath = `${saisirFacturePath}/multi`;

export const saisirEnteteMultiFacturePath = 'entete';
export const saisirEnteteMultiFactureStatementPath = 'entete/:statementid';
export const saisirDossierMultiFacturePath = ':factureachatid/dossier';
export const nextDossierMultiFacturePath = ':factureachatid/next';
export const nextEditDossierMultiFacturePath = `${saisirDossierMultiFacturePath}/next/:dossiernum`;
export const saisirEditDossierMultiFacturePath = `${saisirDossierMultiFacturePath}/:dossiernum`;
export const nextEditFproDossierMultiFacturePath = `${saisirDossierMultiFacturePath}/next/formalite/:dossiernum`;
export const saisirEditFproDossierMultiFacturePath = `${saisirDossierMultiFacturePath}/formalite/:dossiernum`;
export const recapMultiFacturePath = ':factureachatid/recap';
export const recapFproMultiFacturePath = ':factureachatid/recap/formalite';

export const modifierFacturePath = 'modifier/:factureachatid/:mediaobjectid';
export const consulterMultiFacturePath = 'recap/:factureachatid/:mediaobjectid';
export const listePath = 'liste';
export const internMediaObjectsPath = 'intern-media-objects';
export const invoiceStatementPath = 'statement';
export const traitePath = 'fait';
export const pausePath = 'pause';
export const downloadPath = 'telecharger/:mediaobjectid';
export const uploadPath = 'import';
export const statsPath = 'stats';
export const rejectCodesPath = 'rejets';

export const registerStatementPath = `${invoiceStatementPath}/:statementid/write/:mediaobjectid`;
export const updateStatementPath = `${invoiceStatementPath}/:statementid/write/:mediaobjectid/:factureachatid`;
export const readUnclosedStatementPath = `${invoiceStatementPath}/:statementid/read`;
export const summaryStatementPath = `${invoiceStatementPath}/:statementid/summary`;
