/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import {
    TblRelArticleJsonldPackageRead,
    TblRelArticlePackageRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { FormContextType } from '../../Package/Components/types';
import CatalogArticleOverrideLine, {
    CatalogFormOverridableArticleItem,
} from './CatalogArticleOverrideLine';

type ComponentProps = {
    context: FormContextType;
};

/** Catalog override module :
 * - List all package article as line
 * - For each line allow edition for (label / price & quantity)
 */
const CatalogOverrideModule: React.FunctionComponent<ComponentProps> = function ({ context }) {
    const [articlesLoading, setArticlesLoading] = React.useState(false);

    const loadNewPackageContent = (articles: TblRelArticlePackageRead[]) => {
        setArticlesLoading(true);
        const formatedArticles = articles?.map((article: TblRelArticleJsonldPackageRead) => ({
            originalLabel: article.libelle ?? article.article?.libelle,
            originalPrice: article.tarif ?? article.article?.prixUnitaire,
            originalQuantity: article.quantite,
            articleRelationId: article.id as number, // Wrong api type / ID should be defined every time .
            label: null,
            price: null,
            quantity: null,
        }));
        context.setValue('catalogArticlesOverrides', formatedArticles);
        setArticlesLoading(false);
    };

    const { selectedPackage, catalogArticlesOverrides } = context.getValues();
    const articles = catalogArticlesOverrides as CatalogFormOverridableArticleItem[];
    const articleList = articles?.map((article, index) => (
        <CatalogArticleOverrideLine
            key={`override_catalog_line_${index + 1}`}
            article={article}
            context={context}
            index={index}
        />
    ));

    const fillOverridedArticles = () => {
        loadNewPackageContent(selectedPackage.articles);
    };

    const hasArticles = articles && articles.length > 0;
    React.useEffect(() => {
        if (!hasArticles && !articlesLoading) {
            fillOverridedArticles();
        }
    }, [hasArticles, articlesLoading]);

    if (articlesLoading || !hasArticles) {
        return <CircularProgress />;
    }

    return <Box>{articleList}</Box>;
};

export default CatalogOverrideModule;
