import React from 'react';
import { Box, Typography } from '@mui/material';
import InformationChip from '../../atoms/InformationChip';
import CustomAvatar from '../CustomAvatar';

const STATUSES_COLORS = {
    1: 'warning', // Prospection - orange
    2: 'success', // Actif - vert
    3: '#003dcc', // Régulier - bleu
    4: '#7b5cda', // Abandoniste - violet
    5: '#ea2bff', // Pas intéressé - rose
    6: 'error', //	Perdu - rouge
    999: 'secondary', // Autre - gris
} as const;

type Status = {
    id: keyof typeof STATUSES_COLORS;
    label: string;
};

export type CustomerServiceWidgetProps = {
    sector: string;
    firstName: string;
    lastName: string;
    status?: Status;
    avatar?: string;
};

const CustomerServiceWidget: React.FunctionComponent<CustomerServiceWidgetProps> = function ({
    sector,
    firstName,
    lastName,
    status,
    avatar,
}) {
    const sectorStyle = {
        opacity: 0.7,
        fontWeight: '500',
        marginBottom: '15px',
    };
    const nameWrapper = {
        marginTop: '11px',
    };
    const nameStyle = {
        opacity: 0.6,
        fontWeight: '400',
        paddingX: '3px',
    };

    const statusWrapper = {
        marginTop: '18px',
    };

    const avatarStyle = { marginRight: '10px' };

    return (
        <Box
            data-testid="CustomerServiceWidget-test-id"
            display="flex"
            flexDirection="column"
        >
            <Typography sx={sectorStyle}>{sector}</Typography>
            <Box display="flex">
                <CustomAvatar
                    firstName={firstName}
                    lastName={lastName}
                    sx={avatarStyle}
                    src={avatar}
                />
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={nameWrapper}
                    >
                        <Typography sx={nameStyle}>{firstName}</Typography>
                        <Typography sx={nameStyle}>{lastName}</Typography>
                    </Box>
                    {status && (
                        <Box sx={statusWrapper}>
                            <InformationChip
                                color={STATUSES_COLORS[status.id]}
                                label={status.label}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CustomerServiceWidget;
