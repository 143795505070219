import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { KeycloakProvider } from '@europrocurement/l2d-keycloak';
import { BrowserRouter } from 'react-router-dom';

import L2DApiProvider from '@europrocurement/l2d-domain/providers/L2DApiProvider/L2DApiProvider';
import { APIS_ENUM, getApiConfiguration } from '@europrocurement/l2d-domain';
import { SocietesSearchProvider } from '@europrocurement/l2d-modules';
import { ParcoursFormalitreHeaderProvider } from '@europrocurement/l2d-modules/modules/ParcoursFormalite/provider';
import App from './App';

import ErrorBoundary from './ErrorBoundary';

import { keycloak, updateToken } from './keycloak';
import ReduxProvider from './ReduxProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const apiConfiguration = {
    offre: getApiConfiguration(APIS_ENUM.OFFRE, updateToken),
    formalite: getApiConfiguration(APIS_ENUM.FORMALITE, updateToken),
    tiers: getApiConfiguration(APIS_ENUM.TIERS, updateToken),
    utilisateur: getApiConfiguration(APIS_ENUM.UTILISATEUR, updateToken),
    formulaire: getApiConfiguration(APIS_ENUM.FORMULAIRE, updateToken),
    facturation: getApiConfiguration(APIS_ENUM.FACTURATION, updateToken),
    annoncesLegales: getApiConfiguration(APIS_ENUM.AL, updateToken),
    geoloc: getApiConfiguration(APIS_ENUM.GEOLOC, updateToken),
};

root.render(
    <KeycloakProvider
        initOptions={{
            onLoad: 'login-required',
            checkLoginIframe: false,
        }}
        authClient={keycloak}
    >
        <ErrorBoundary>
            <L2DApiProvider apiConfiguration={apiConfiguration}>
                <React.StrictMode>
                    <BrowserRouter>
                        <ReduxProvider>
                            <SocietesSearchProvider>
                                <ParcoursFormalitreHeaderProvider>
                                    <App />
                                </ParcoursFormalitreHeaderProvider>
                            </SocietesSearchProvider>
                        </ReduxProvider>
                    </BrowserRouter>
                </React.StrictMode>
            </L2DApiProvider>
        </ErrorBoundary>
    </KeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
