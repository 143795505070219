import { TypeDeDocumentApi } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { documentIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';

import {
    type ModelConfiguration,
    type ModelMethods,
    type ModelItem,
    type DocumentApiObject,
} from '../../..';
import { documentTypes } from '../../../constants';
import { baseDocumentSelector } from '../selectors';

import { DOCUMENT_SLICE_NAME } from '../constants';

const modelKey = 'document';
const modelName = 'Document';
const sliceName = DOCUMENT_SLICE_NAME;

type ModelStructure = DocumentApiObject;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
    getItemSecondaryLabel: (model: ModelStructure) =>
        `${documentTypes.find((option) => option.value === model.type)?.label}`,
};

export type DocumentEndpoint = {
    list: InstanceType<typeof TypeDeDocumentApi>['apiTypesDocumentsGetCollection'];
    delete: InstanceType<typeof TypeDeDocumentApi>['apiTypesDocumentsIdDelete'];
    get: InstanceType<typeof TypeDeDocumentApi>['apiTypesDocumentsIdGet'];
    update: InstanceType<typeof TypeDeDocumentApi>['apiTypesDocumentsIdPut'];
    create: InstanceType<typeof TypeDeDocumentApi>['apiTypesDocumentsPost'];
};

export type DocumentModel = ModelItem<ModelStructure> & DocumentEndpoint;

export const buildDocumentModel = (
    api: TypeDeDocumentApi,
    datasourcesThunks: DataSourcesThunksType<DocumentApiObject>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        selector: baseDocumentSelector,
        type: 'Type de document',
        sliceName,
        api,
        datasourcesThunks,
        icon: documentIcon,
    };
    const endpoints = {
        list: api.apiTypesDocumentsGetCollection.bind(api),
        delete: api.apiTypesDocumentsIdDelete.bind(api),
        get: api.apiTypesDocumentsIdGet.bind(api),
        update: api.apiTypesDocumentsIdPut.bind(api),
        create: api.apiTypesDocumentsPost.bind(api),
    };

    const model: DocumentModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
