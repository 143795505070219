import * as React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { Link } from 'react-router-dom';
// import { B2D404Icon } from '@europrocurement/flexy-components/components';
import { L2DErrorImage, TextIconLink } from '@europrocurement/flexy-components/components/atoms';
import { arrowLeftIcon } from '@europrocurement/l2d-icons';

/* Modify ErrorPage component to add allows modification on logo and urls */

// Logo style example : <B2DLogoIcon style={{ height: '64px', marginTop: '40px' }} />

export type ErrorPageGenericType = {
    statusCode: number;
    message: string;
    children?: React.ReactNode;
    details?: string;
    title?: string;
    description?: string;
    logo?: React.ReactNode;
    returnUrl?: string;
    returnButtonLabel?: string;
};

/**
 * Generic component that render an error page. By default, return to home page.
 * @param statusCode (number)
 * @param message (string) : message above status code (h1 style)
 * @param details (string) : submessage
 * @param title (string) : in the component declaration (not rendered)
 * @param description (string): aria-description
 * @param logo (React.ReactNode) : BackToDigital or other logo (top page)
 * @param returnUrl (string): link url to navigation. By default : '/'.
 * @param returnButtonLabel (string): display label on the return button (bottom of the page). By default : "Retour à l'accueil"
 * @returns Error page component
 */
export const ErrorPageGeneric = function ({
    statusCode,
    message,
    children,
    title = 'Error',
    details = 'Une erreur est survenue. Merci de réessayer plus tard.',
    description = 'Page Erreur',
    logo,
    returnButtonLabel = "Retour à l'accueil",
    returnUrl = '/',
}: ErrorPageGenericType) {
    return (
        <Container
            maxWidth="lg"
            data-testid="test-id-ErrorPageGeneric"
            title={title}
            aria-description={description}
        >
            <Box
                display="flex"
                justifyContent="center"
                height="100px"
            >
                {logo}
            </Box>
            <Grid container>
                <Grid
                    item
                    sm={2}
                    md={6}
                    lg={6}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        textAlign="center"
                        justifyContent="center"
                        alignItems="flex-end"
                        padding="auto"
                        height="70vh"
                    >
                        <L2DErrorImage />
                    </Box>
                </Grid>
                <Grid
                    item
                    sm={6}
                    md={6}
                    lg={6}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        height="100%"
                    >
                        <Typography
                            variant="l2dh7"
                            sx={{
                                pt: 2,
                            }}
                            data-testid="error-status"
                        >
                            Erreur {statusCode}
                        </Typography>
                        <Typography
                            variant="l2dh2"
                            sx={{
                                pt: 1,
                                pb: 3,
                            }}
                            data-testid="error-content"
                        >
                            {message}
                        </Typography>
                        <Typography
                            variant="l2dbodym"
                            sx={{
                                pt: 1,
                                pb: 3,
                            }}
                        >
                            {details}
                        </Typography>
                        <Box
                            sx={{
                                marginTop: '32px',
                            }}
                        >
                            <TextIconLink
                                icon={arrowLeftIcon}
                                to={returnUrl}
                                btnProps={{
                                    component: Link,
                                }}
                                gap={1}
                            >
                                {returnButtonLabel}
                            </TextIconLink>
                            {children}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};
