import { FormObject, FormStructure } from '@europrocurement/flexy-form/components';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { jsonTextInput } from '../types/formTypes';
import { checkFieldValue } from '../functions';

const jsonTextInputToFormElement: (input: jsonTextInput) => FormStructure = (input) => {
    const baseField: FormStructure = {
        type: input.type,
        name: input.name,
        inputlabel: input.inputlabel,
        placeholder: input.placeholder,
        InputProps: {
            sx: { backgroundColor: '#ffff' },
            startAdornment: input.startAdornment,
            endAdornment: input.endAdornment,
        },
        xs: input.xs,
        sm: input.sm,
        md: input.md,
        lg: input.lg,
        sx: {
            fontSize: '14px',
            marginTop: '15px',
            marginBottom: '15px',
            fontWeight: '500',
        },
        tooltip: input.tooltip,
    };

    // comportement sur rules display
    if (input.rules && input.rules.display) {
        if (input.rules.display.fieldIsEqualTo) {
            const fieldNameToWatch = input.rules.display.fieldIsEqualTo.field;
            const valueToWatch = input.rules.display.fieldIsEqualTo.value;

            baseField.display = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, valueToWatch, fieldNameToWatch);
        }
        if (input.rules.display.isTrue) {
            const fieldNameToWatch = input.rules.display.isTrue;

            baseField.display = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, true, fieldNameToWatch);
        }
        if (input.rules.display.fieldIsChecked) {
            const fieldNameToWatch = input.rules.display.fieldIsChecked;

            baseField.display = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, true, fieldNameToWatch);
        }
        if (input.rules.display.fieldIsNotChecked) {
            const fieldNameToWatch = input.rules.display.fieldIsNotChecked;

            baseField.display = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, false, fieldNameToWatch);
        }
    }

    // comportement sur rules pattern
    if (input.rules && input.rules.pattern) {
        baseField.rules = {
            pattern: {
                value: new RegExp(input.rules.pattern.value),
                message: input.rules.pattern.message,
            },
        };
    }

    // comportement de toute la partie disabled
    if (input.disabled) {
        if (input.disabled === true) {
            baseField.disabled = true;
        } else if (input.disabled.isFalse) {
            const fieldNameToWatch =
                typeof input.disabled.isFalse === 'string'
                    ? input.disabled.isFalse
                    : input.disabled.isFalse.field;
            baseField.disabled = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                !checkFieldValue(formContext, true, fieldNameToWatch);
        } else if (input.disabled.isValid) {
            const fieldNameToWatch =
                typeof input.disabled.isValid === 'string'
                    ? input.disabled.isValid
                    : input.disabled.isValid.field;
            baseField.disabled = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                !checkFieldValue(formContext, true, fieldNameToWatch);
        } else if (input.disabled.fieldIsNotEqualTo) {
            const fieldNameToWatch = input.disabled.fieldIsNotEqualTo.field;
            const valueToWatch = input.disabled.fieldIsNotEqualTo.value;
            baseField.disabled = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                !checkFieldValue(formContext, valueToWatch, fieldNameToWatch);
        } else if (input.disabled.fieldIsEqualTo) {
            const fieldNameToWatch = input.disabled.fieldIsEqualTo.field;
            const valueToWatch = input.disabled.fieldIsEqualTo.value;
            baseField.disabled = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, valueToWatch, fieldNameToWatch);
        }
    }

    return baseField;
};

export default jsonTextInputToFormElement;
