/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Formulaire
 * API de gestion des formulaires
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesGetCollection200Response
 */
export interface ApiFormulaireFormalitesGetCollection200Response {
    /**
     * 
     * @type {Array<FormulaireFormaliteJsonld>}
     * @memberof ApiFormulaireFormalitesGetCollection200Response
     */
    'hydra:member': Array<FormulaireFormaliteJsonld>;
    /**
     * 
     * @type {number}
     * @memberof ApiFormulaireFormalitesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiFormulaireFormalitesGetCollection200ResponseHydraView}
     * @memberof ApiFormulaireFormalitesGetCollection200Response
     */
    'hydra:view'?: ApiFormulaireFormalitesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiFormulaireFormalitesGetCollection200ResponseHydraSearch}
     * @memberof ApiFormulaireFormalitesGetCollection200Response
     */
    'hydra:search'?: ApiFormulaireFormalitesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
 */
export interface ApiFormulaireFormalitesGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<ApiFormulaireFormalitesGetCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiFormulaireFormalitesGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiFormulaireFormalitesGetCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesGetCollection200ResponseHydraView
 */
export interface ApiFormulaireFormalitesGetCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet200ResponseInner
 */
export interface ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet200ResponseInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet200ResponseInner
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
 */
export interface ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response {
    /**
     * 
     * @type {Array<FormulaireListeSelectionJsonldFormulairelisteRead>}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
     */
    'hydra:member': Array<FormulaireListeSelectionJsonldFormulairelisteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiFormulaireFormalitesGetCollection200ResponseHydraView}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
     */
    'hydra:view'?: ApiFormulaireFormalitesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiFormulaireFormalitesGetCollection200ResponseHydraSearch}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
     */
    'hydra:search'?: ApiFormulaireFormalitesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesPost400Response
 */
export interface ApiFormulaireFormalitesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFormulaireFormalitesPost400Response
     */
    'error'?: string;
}
/**
 * FproFormaliteArticle
 * @export
 * @interface Formalite
 */
export interface Formalite {
    /**
     * 
     * @type {number}
     * @memberof Formalite
     */
    'id'?: number;
}
/**
 * FproFormaliteArticle
 * @export
 * @interface FormaliteJsonld
 */
export interface FormaliteJsonld {
    /**
     * 
     * @type {FormaliteJsonldContext}
     * @memberof FormaliteJsonld
     */
    '@context'?: FormaliteJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormaliteJsonld
     */
    'id'?: number;
}
/**
 * @type FormaliteJsonldContext
 * @export
 */
export type FormaliteJsonldContext = FormaliteJsonldContextOneOf | string;

/**
 * 
 * @export
 * @interface FormaliteJsonldContextOneOf
 */
export interface FormaliteJsonldContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldContextOneOf
     */
    'hydra': FormaliteJsonldContextOneOfHydraEnum;
}

export const FormaliteJsonldContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type FormaliteJsonldContextOneOfHydraEnum = typeof FormaliteJsonldContextOneOfHydraEnum[keyof typeof FormaliteJsonldContextOneOfHydraEnum];

/**
 * TblFormulaire
 * @export
 * @interface FormulaireFormalite
 */
export interface FormulaireFormalite {
    /**
     * 
     * @type {number}
     * @memberof FormulaireFormalite
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireFormalite
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireFormalite
     */
    'formulaire': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormulaireFormalite
     */
    'formalites'?: Array<string>;
}
/**
 * TblFormulaire
 * @export
 * @interface FormulaireFormaliteJsonld
 */
export interface FormulaireFormaliteJsonld {
    /**
     * 
     * @type {FormaliteJsonldContext}
     * @memberof FormulaireFormaliteJsonld
     */
    '@context'?: FormaliteJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FormulaireFormaliteJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireFormaliteJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormulaireFormaliteJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireFormaliteJsonld
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireFormaliteJsonld
     */
    'formulaire': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormulaireFormaliteJsonld
     */
    'formalites'?: Array<string>;
}
/**
 * FormulaireListeSelection
 * @export
 * @interface FormulaireListeSelectionFormulairelisteCreate
 */
export interface FormulaireListeSelectionFormulairelisteCreate {
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionFormulairelisteCreate
     */
    'formulaire': number;
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionFormulairelisteCreate
     */
    'formalite': number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionFormulairelisteCreate
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionFormulairelisteCreate
     */
    'liste': string;
}
/**
 * FormulaireListeSelection
 * @export
 * @interface FormulaireListeSelectionFormulairelisteRead
 */
export interface FormulaireListeSelectionFormulairelisteRead {
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'formulaire': number;
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'formalite': number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'liste': string;
}
/**
 * FormulaireListeSelection
 * @export
 * @interface FormulaireListeSelectionJsonldFormulairelisteCreate
 */
export interface FormulaireListeSelectionJsonldFormulairelisteCreate {
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteCreate
     */
    'formulaire': number;
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteCreate
     */
    'formalite': number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteCreate
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteCreate
     */
    'liste': string;
}
/**
 * FormulaireListeSelection
 * @export
 * @interface FormulaireListeSelectionJsonldFormulairelisteRead
 */
export interface FormulaireListeSelectionJsonldFormulairelisteRead {
    /**
     * 
     * @type {FormaliteJsonldContext}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    '@context'?: FormaliteJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'formulaire': number;
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'formalite': number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'liste': string;
}

/**
 * FormaliteApi - axios parameter creator
 * @export
 */
export const FormaliteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Formalite resource.
         * @summary Récupérer les informations d\'une formalite
         * @param {string} id Formalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalitesIdGet', 'id', id)
            const localVarPath = `/formulaire/formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormaliteApi - functional programming interface
 * @export
 */
export const FormaliteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormaliteApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a Formalite resource.
         * @summary Récupérer les informations d\'une formalite
         * @param {string} id Formalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormaliteJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormaliteApi.apiFormalitesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FormaliteApi - factory interface
 * @export
 */
export const FormaliteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormaliteApiFp(configuration)
    return {
        /**
         * Retrieves a Formalite resource.
         * @summary Récupérer les informations d\'une formalite
         * @param {FormaliteApiApiFormalitesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdGet(requestParameters: FormaliteApiApiFormalitesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormaliteJsonld> {
            return localVarFp.apiFormalitesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormalitesIdGet operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesIdGetRequest
 */
export interface FormaliteApiApiFormalitesIdGetRequest {
    /**
     * Formalite identifier
     * @type {string}
     * @memberof FormaliteApiApiFormalitesIdGet
     */
    readonly id: string
}

/**
 * FormaliteApi - object-oriented interface
 * @export
 * @class FormaliteApi
 * @extends {BaseAPI}
 */
export class FormaliteApi extends BaseAPI {
    /**
     * Retrieves a Formalite resource.
     * @summary Récupérer les informations d\'une formalite
     * @param {FormaliteApiApiFormalitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesIdGet(requestParameters: FormaliteApiApiFormalitesIdGetRequest, options?: RawAxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FormulaireFormaliteApi - axios parameter creator
 * @export
 */
export const FormulaireFormaliteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of FormulaireFormalite resources.
         * @summary Obtenir la liste des formulaires
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formulaire/formulaire_formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FormulaireFormalite resource.
         * @summary Récupérer un formulaire
         * @param {string} id FormulaireFormalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdGet', 'id', id)
            const localVarPath = `/formulaire/formulaire_formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the FormulaireFormalite resource.
         * @summary Modifier partiellement un formulaire
         * @param {string} id FormulaireFormalite identifier
         * @param {FormulaireFormalite} formulaireFormalite The updated FormulaireFormalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdPatch: async (id: string, formulaireFormalite: FormulaireFormalite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdPatch', 'id', id)
            // verify required parameter 'formulaireFormalite' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdPatch', 'formulaireFormalite', formulaireFormalite)
            const localVarPath = `/formulaire/formulaire_formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formulaireFormalite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FormulaireFormalite resource.
         * @summary Retrieves a FormulaireFormalite resource.
         * @param {string} id FormulaireFormalite identifier
         * @param {string} idFormalite FormulaireFormalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdformaliteIdFormaliteGet: async (id: string, idFormalite: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdformaliteIdFormaliteGet', 'id', id)
            // verify required parameter 'idFormalite' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdformaliteIdFormaliteGet', 'idFormalite', idFormalite)
            const localVarPath = `/formulaire/formulaire_formalites/{id}/formalite/{idFormalite}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idFormalite"}}`, encodeURIComponent(String(idFormalite)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a FormulaireFormalite resource.
         * @summary Créer un nouveau formulaire
         * @param {FormulaireFormaliteJsonld} formulaireFormaliteJsonld The new FormulaireFormalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesPost: async (formulaireFormaliteJsonld: FormulaireFormaliteJsonld, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formulaireFormaliteJsonld' is not null or undefined
            assertParamExists('apiFormulaireFormalitesPost', 'formulaireFormaliteJsonld', formulaireFormaliteJsonld)
            const localVarPath = `/formulaire/formulaire_formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formulaireFormaliteJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormulaireFormaliteApi - functional programming interface
 * @export
 */
export const FormulaireFormaliteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormulaireFormaliteApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of FormulaireFormalite resources.
         * @summary Obtenir la liste des formulaires
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormulaireFormalitesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormulaireFormaliteApi.apiFormulaireFormalitesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a FormulaireFormalite resource.
         * @summary Récupérer un formulaire
         * @param {string} id FormulaireFormalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormulaireFormaliteJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormulaireFormaliteApi.apiFormulaireFormalitesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the FormulaireFormalite resource.
         * @summary Modifier partiellement un formulaire
         * @param {string} id FormulaireFormalite identifier
         * @param {FormulaireFormalite} formulaireFormalite The updated FormulaireFormalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesIdPatch(id: string, formulaireFormalite: FormulaireFormalite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormulaireFormaliteJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesIdPatch(id, formulaireFormalite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormulaireFormaliteApi.apiFormulaireFormalitesIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a FormulaireFormalite resource.
         * @summary Retrieves a FormulaireFormalite resource.
         * @param {string} id FormulaireFormalite identifier
         * @param {string} idFormalite FormulaireFormalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesIdformaliteIdFormaliteGet(id: string, idFormalite: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormulaireFormaliteJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesIdformaliteIdFormaliteGet(id, idFormalite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormulaireFormaliteApi.apiFormulaireFormalitesIdformaliteIdFormaliteGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a FormulaireFormalite resource.
         * @summary Créer un nouveau formulaire
         * @param {FormulaireFormaliteJsonld} formulaireFormaliteJsonld The new FormulaireFormalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesPost(formulaireFormaliteJsonld: FormulaireFormaliteJsonld, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormulaireFormaliteJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesPost(formulaireFormaliteJsonld, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormulaireFormaliteApi.apiFormulaireFormalitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FormulaireFormaliteApi - factory interface
 * @export
 */
export const FormulaireFormaliteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormulaireFormaliteApiFp(configuration)
    return {
        /**
         * Retrieves the collection of FormulaireFormalite resources.
         * @summary Obtenir la liste des formulaires
         * @param {FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesGetCollection(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiFormulaireFormalitesGetCollection200Response> {
            return localVarFp.apiFormulaireFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FormulaireFormalite resource.
         * @summary Récupérer un formulaire
         * @param {FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdGet(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormulaireFormaliteJsonld> {
            return localVarFp.apiFormulaireFormalitesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the FormulaireFormalite resource.
         * @summary Modifier partiellement un formulaire
         * @param {FormulaireFormaliteApiApiFormulaireFormalitesIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdPatch(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormulaireFormaliteJsonld> {
            return localVarFp.apiFormulaireFormalitesIdPatch(requestParameters.id, requestParameters.formulaireFormalite, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FormulaireFormalite resource.
         * @summary Retrieves a FormulaireFormalite resource.
         * @param {FormulaireFormaliteApiApiFormulaireFormalitesIdformaliteIdFormaliteGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdformaliteIdFormaliteGet(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesIdformaliteIdFormaliteGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormulaireFormaliteJsonld> {
            return localVarFp.apiFormulaireFormalitesIdformaliteIdFormaliteGet(requestParameters.id, requestParameters.idFormalite, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a FormulaireFormalite resource.
         * @summary Créer un nouveau formulaire
         * @param {FormulaireFormaliteApiApiFormulaireFormalitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesPost(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormulaireFormaliteJsonld> {
            return localVarFp.apiFormulaireFormalitesPost(requestParameters.formulaireFormaliteJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormulaireFormalitesGetCollection operation in FormulaireFormaliteApi.
 * @export
 * @interface FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest
 */
export interface FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiFormulaireFormalitesIdGet operation in FormulaireFormaliteApi.
 * @export
 * @interface FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest
 */
export interface FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest {
    /**
     * FormulaireFormalite identifier
     * @type {string}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiFormulaireFormalitesIdPatch operation in FormulaireFormaliteApi.
 * @export
 * @interface FormulaireFormaliteApiApiFormulaireFormalitesIdPatchRequest
 */
export interface FormulaireFormaliteApiApiFormulaireFormalitesIdPatchRequest {
    /**
     * FormulaireFormalite identifier
     * @type {string}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesIdPatch
     */
    readonly id: string

    /**
     * The updated FormulaireFormalite resource
     * @type {FormulaireFormalite}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesIdPatch
     */
    readonly formulaireFormalite: FormulaireFormalite
}

/**
 * Request parameters for apiFormulaireFormalitesIdformaliteIdFormaliteGet operation in FormulaireFormaliteApi.
 * @export
 * @interface FormulaireFormaliteApiApiFormulaireFormalitesIdformaliteIdFormaliteGetRequest
 */
export interface FormulaireFormaliteApiApiFormulaireFormalitesIdformaliteIdFormaliteGetRequest {
    /**
     * FormulaireFormalite identifier
     * @type {string}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesIdformaliteIdFormaliteGet
     */
    readonly id: string

    /**
     * FormulaireFormalite identifier
     * @type {string}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesIdformaliteIdFormaliteGet
     */
    readonly idFormalite: string
}

/**
 * Request parameters for apiFormulaireFormalitesPost operation in FormulaireFormaliteApi.
 * @export
 * @interface FormulaireFormaliteApiApiFormulaireFormalitesPostRequest
 */
export interface FormulaireFormaliteApiApiFormulaireFormalitesPostRequest {
    /**
     * The new FormulaireFormalite resource
     * @type {FormulaireFormaliteJsonld}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesPost
     */
    readonly formulaireFormaliteJsonld: FormulaireFormaliteJsonld
}

/**
 * FormulaireFormaliteApi - object-oriented interface
 * @export
 * @class FormulaireFormaliteApi
 * @extends {BaseAPI}
 */
export class FormulaireFormaliteApi extends BaseAPI {
    /**
     * Retrieves the collection of FormulaireFormalite resources.
     * @summary Obtenir la liste des formulaires
     * @param {FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireFormaliteApi
     */
    public apiFormulaireFormalitesGetCollection(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return FormulaireFormaliteApiFp(this.configuration).apiFormulaireFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FormulaireFormalite resource.
     * @summary Récupérer un formulaire
     * @param {FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireFormaliteApi
     */
    public apiFormulaireFormalitesIdGet(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest, options?: RawAxiosRequestConfig) {
        return FormulaireFormaliteApiFp(this.configuration).apiFormulaireFormalitesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the FormulaireFormalite resource.
     * @summary Modifier partiellement un formulaire
     * @param {FormulaireFormaliteApiApiFormulaireFormalitesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireFormaliteApi
     */
    public apiFormulaireFormalitesIdPatch(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesIdPatchRequest, options?: RawAxiosRequestConfig) {
        return FormulaireFormaliteApiFp(this.configuration).apiFormulaireFormalitesIdPatch(requestParameters.id, requestParameters.formulaireFormalite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FormulaireFormalite resource.
     * @summary Retrieves a FormulaireFormalite resource.
     * @param {FormulaireFormaliteApiApiFormulaireFormalitesIdformaliteIdFormaliteGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireFormaliteApi
     */
    public apiFormulaireFormalitesIdformaliteIdFormaliteGet(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesIdformaliteIdFormaliteGetRequest, options?: RawAxiosRequestConfig) {
        return FormulaireFormaliteApiFp(this.configuration).apiFormulaireFormalitesIdformaliteIdFormaliteGet(requestParameters.id, requestParameters.idFormalite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a FormulaireFormalite resource.
     * @summary Créer un nouveau formulaire
     * @param {FormulaireFormaliteApiApiFormulaireFormalitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireFormaliteApi
     */
    public apiFormulaireFormalitesPost(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesPostRequest, options?: RawAxiosRequestConfig) {
        return FormulaireFormaliteApiFp(this.configuration).apiFormulaireFormalitesPost(requestParameters.formulaireFormaliteJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FormulaireListeSelectionApi - axios parameter creator
 * @export
 */
export const FormulaireListeSelectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a FormulaireListeSelection resource.
         * @summary Récupérer une liste de sélection d\'un formulaire
         * @param {number} idFormulaireFormalite 
         * @param {number} idFormalite 
         * @param {ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetTypeListeEnum} typeListe 
         * @param {string} [discriminant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet: async (idFormulaireFormalite: number, idFormalite: number, typeListe: ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetTypeListeEnum, discriminant?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idFormulaireFormalite' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet', 'idFormulaireFormalite', idFormulaireFormalite)
            // verify required parameter 'idFormalite' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet', 'idFormalite', idFormalite)
            // verify required parameter 'typeListe' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet', 'typeListe', typeListe)
            const localVarPath = `/formulaire/formulaire_formalites/{idFormulaireFormalite}/formalite/{idFormalite}/liste-selections/{typeListe}/{discriminant}`
                .replace(`{${"idFormulaireFormalite"}}`, encodeURIComponent(String(idFormulaireFormalite)))
                .replace(`{${"idFormalite"}}`, encodeURIComponent(String(idFormalite)))
                .replace(`{${"typeListe"}}`, encodeURIComponent(String(typeListe)))
                .replace(`{${"discriminant"}}`, encodeURIComponent(String(discriminant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of FormulaireListeSelection resources.
         * @summary Récupérer les surcharges de liste de sélection d\'un formulaire
         * @param {string} idFormulaireFormalite FormulaireListeSelection identifier
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection: async (idFormulaireFormalite: string, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idFormulaireFormalite' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection', 'idFormulaireFormalite', idFormulaireFormalite)
            const localVarPath = `/formulaire/formulaire_formalites/{idFormulaireFormalite}/liste-selections`
                .replace(`{${"idFormulaireFormalite"}}`, encodeURIComponent(String(idFormulaireFormalite)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a FormulaireListeSelection resource.
         * @summary Créer une nouvelle surcharge de liste de sélection
         * @param {FormulaireListeSelectionJsonldFormulairelisteCreate} formulaireListeSelectionJsonldFormulairelisteCreate The new FormulaireListeSelection resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormaliteslisteSelectionsPost: async (formulaireListeSelectionJsonldFormulairelisteCreate: FormulaireListeSelectionJsonldFormulairelisteCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formulaireListeSelectionJsonldFormulairelisteCreate' is not null or undefined
            assertParamExists('apiFormulaireFormaliteslisteSelectionsPost', 'formulaireListeSelectionJsonldFormulairelisteCreate', formulaireListeSelectionJsonldFormulairelisteCreate)
            const localVarPath = `/formulaire/formulaire_formalites/liste-selections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formulaireListeSelectionJsonldFormulairelisteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormulaireListeSelectionApi - functional programming interface
 * @export
 */
export const FormulaireListeSelectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormulaireListeSelectionApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a FormulaireListeSelection resource.
         * @summary Récupérer une liste de sélection d\'un formulaire
         * @param {number} idFormulaireFormalite 
         * @param {number} idFormalite 
         * @param {ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetTypeListeEnum} typeListe 
         * @param {string} [discriminant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(idFormulaireFormalite: number, idFormalite: number, typeListe: ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetTypeListeEnum, discriminant?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(idFormulaireFormalite, idFormalite, typeListe, discriminant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormulaireListeSelectionApi.apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the collection of FormulaireListeSelection resources.
         * @summary Récupérer les surcharges de liste de sélection d\'un formulaire
         * @param {string} idFormulaireFormalite FormulaireListeSelection identifier
         * @param {number} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(idFormulaireFormalite: string, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(idFormulaireFormalite, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormulaireListeSelectionApi.apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a FormulaireListeSelection resource.
         * @summary Créer une nouvelle surcharge de liste de sélection
         * @param {FormulaireListeSelectionJsonldFormulairelisteCreate} formulaireListeSelectionJsonldFormulairelisteCreate The new FormulaireListeSelection resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormaliteslisteSelectionsPost(formulaireListeSelectionJsonldFormulairelisteCreate: FormulaireListeSelectionJsonldFormulairelisteCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormulaireListeSelectionJsonldFormulairelisteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormaliteslisteSelectionsPost(formulaireListeSelectionJsonldFormulairelisteCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormulaireListeSelectionApi.apiFormulaireFormaliteslisteSelectionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FormulaireListeSelectionApi - factory interface
 * @export
 */
export const FormulaireListeSelectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormulaireListeSelectionApiFp(configuration)
    return {
        /**
         * Retrieves a FormulaireListeSelection resource.
         * @summary Récupérer une liste de sélection d\'un formulaire
         * @param {FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(requestParameters: FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet200ResponseInner>> {
            return localVarFp.apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(requestParameters.idFormulaireFormalite, requestParameters.idFormalite, requestParameters.typeListe, requestParameters.discriminant, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of FormulaireListeSelection resources.
         * @summary Récupérer les surcharges de liste de sélection d\'un formulaire
         * @param {FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(requestParameters: FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response> {
            return localVarFp.apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(requestParameters.idFormulaireFormalite, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a FormulaireListeSelection resource.
         * @summary Créer une nouvelle surcharge de liste de sélection
         * @param {FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormaliteslisteSelectionsPost(requestParameters: FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormulaireListeSelectionJsonldFormulairelisteRead> {
            return localVarFp.apiFormulaireFormaliteslisteSelectionsPost(requestParameters.formulaireListeSelectionJsonldFormulairelisteCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet operation in FormulaireListeSelectionApi.
 * @export
 * @interface FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest
 */
export interface FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest {
    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet
     */
    readonly idFormulaireFormalite: number

    /**
     * 
     * @type {number}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet
     */
    readonly idFormalite: number

    /**
     * 
     * @type {'assurance-maladie' | 'civilite' | 'controle-beneficiaire' | 'fonction' | 'genre' | 'nationalite' | 'nature-capital' | 'nature-gerance' | 'option-fiscale' | 'option-fiscale-option' | 'origine-fond' | 'personne' | 'regime-imposition' | 'situation-matrimoniale' | 'situation-matrimoniale-conjoint' | 'statut-exercice' | 'type-activite'}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet
     */
    readonly typeListe: ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetTypeListeEnum

    /**
     * 
     * @type {string}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet
     */
    readonly discriminant?: string
}

/**
 * Request parameters for apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection operation in FormulaireListeSelectionApi.
 * @export
 * @interface FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest
 */
export interface FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest {
    /**
     * FormulaireListeSelection identifier
     * @type {string}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection
     */
    readonly idFormulaireFormalite: string

    /**
     * The collection page number
     * @type {number}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection
     */
    readonly page?: number
}

/**
 * Request parameters for apiFormulaireFormaliteslisteSelectionsPost operation in FormulaireListeSelectionApi.
 * @export
 * @interface FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest
 */
export interface FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest {
    /**
     * The new FormulaireListeSelection resource
     * @type {FormulaireListeSelectionJsonldFormulairelisteCreate}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPost
     */
    readonly formulaireListeSelectionJsonldFormulairelisteCreate: FormulaireListeSelectionJsonldFormulairelisteCreate
}

/**
 * FormulaireListeSelectionApi - object-oriented interface
 * @export
 * @class FormulaireListeSelectionApi
 * @extends {BaseAPI}
 */
export class FormulaireListeSelectionApi extends BaseAPI {
    /**
     * Retrieves a FormulaireListeSelection resource.
     * @summary Récupérer une liste de sélection d\'un formulaire
     * @param {FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireListeSelectionApi
     */
    public apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(requestParameters: FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest, options?: RawAxiosRequestConfig) {
        return FormulaireListeSelectionApiFp(this.configuration).apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(requestParameters.idFormulaireFormalite, requestParameters.idFormalite, requestParameters.typeListe, requestParameters.discriminant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of FormulaireListeSelection resources.
     * @summary Récupérer les surcharges de liste de sélection d\'un formulaire
     * @param {FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireListeSelectionApi
     */
    public apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(requestParameters: FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return FormulaireListeSelectionApiFp(this.configuration).apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(requestParameters.idFormulaireFormalite, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a FormulaireListeSelection resource.
     * @summary Créer une nouvelle surcharge de liste de sélection
     * @param {FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireListeSelectionApi
     */
    public apiFormulaireFormaliteslisteSelectionsPost(requestParameters: FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest, options?: RawAxiosRequestConfig) {
        return FormulaireListeSelectionApiFp(this.configuration).apiFormulaireFormaliteslisteSelectionsPost(requestParameters.formulaireListeSelectionJsonldFormulairelisteCreate, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetTypeListeEnum = {
    AssuranceMaladie: 'assurance-maladie',
    Civilite: 'civilite',
    ControleBeneficiaire: 'controle-beneficiaire',
    Fonction: 'fonction',
    Genre: 'genre',
    Nationalite: 'nationalite',
    NatureCapital: 'nature-capital',
    NatureGerance: 'nature-gerance',
    OptionFiscale: 'option-fiscale',
    OptionFiscaleOption: 'option-fiscale-option',
    OrigineFond: 'origine-fond',
    Personne: 'personne',
    RegimeImposition: 'regime-imposition',
    SituationMatrimoniale: 'situation-matrimoniale',
    SituationMatrimonialeConjoint: 'situation-matrimoniale-conjoint',
    StatutExercice: 'statut-exercice',
    TypeActivite: 'type-activite'
} as const;
export type ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetTypeListeEnum = typeof ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetTypeListeEnum[keyof typeof ApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetTypeListeEnum];


