import React, { PropsWithChildren, ReactNode } from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

export type CardHeaderProps = {
    title: ReactNode;
    subtitle?: ReactNode;
    border?: boolean;
    sx?: SxProps;
    titleSx?: SxProps;
} & PropsWithChildren;

const CardHeader: React.FunctionComponent<PropsWithChildren<CardHeaderProps>> = function ({
    title,
    subtitle,
    border = true,
    titleSx,
    sx,
    children,
}) {
    const theme = useTheme();
    const color = theme.palette.primary.main;

    const borderHeight = '50px';
    const borderWidth = '3px';
    const offset = '30px';
    const headerOffset = { paddingTop: offset };

    const borderLeft = {
        minHeight: borderHeight,
        maxHeight: borderHeight,
        borderLeft: `solid ${borderWidth} ${color}`,
    };

    const titleStyle = {
        fontFamily: 'Inter',
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: '28px',
        ...titleSx,
    };
    const headingStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingX: '32px',
        minWidth: '100%',
        ...(border && borderLeft),
        ...sx,
    };

    const childrensWrapper = {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '32px',
    };

    return (
        <Box
            data-testid="CardHeader-test-id"
            className="heading"
            sx={headerOffset}
        >
            <Box sx={headingStyle}>
                <Typography
                    data-testid="CardHeader-title-test-id"
                    sx={titleStyle}
                >
                    {title}
                </Typography>
                <Box data-testid="CardHeader-subtitle-test-id">{subtitle}</Box>
            </Box>
            <Box sx={childrensWrapper}>{children}</Box>
        </Box>
    );
};

export default CardHeader;
