import { FormStructure } from '@europrocurement/flexy-form/components';
import { jsonDayMonthInput } from '../types/formTypes';

const jsonDayMonthInputToFormElement: (input: jsonDayMonthInput) => FormStructure = (input) => {
    const baseField: FormStructure = {
        type: input.type,
        name: input.name,
        inputlabel: input.inputlabel,
        dayplaceholder: input.dayplaceholder,
        monthplaceholder: input.monthplaceholder,
        xs: input.xs,
        sm: input.sm,
        md: input.md,
        lg: input.lg,
        sx: {
            fontSize: '14px',
            marginTop: '15px',
            marginBottom: '15px',
            fontWeight: '500',
        },
    };

    // comportement sur rules display
    if (input.rules && input.rules.copyValueTo) {
        baseField.onChangeInput = (e, inputPath, formContextOnChangeInput) => {
            const fieldToCopyTo = input.rules.copyValueTo as string;
            formContextOnChangeInput.setValue(fieldToCopyTo, e);
        };
    }

    return baseField;
};

export default jsonDayMonthInputToFormElement;
