import React, { useCallback, useEffect, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { customizerSelector, factureAchatApi } from '@b2d/redux/RootStore';
import { pausedInvoiceIcon } from '@europrocurement/l2d-icons';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import WidgetWithAction from './components/WidgetWithAction';

// All

const FacturesEnPause: React.FunctionComponent = function () {
    const navigate = useNavigate();

    const { xIdSociete } = useSelector(customizerSelector);

    const [countOfInvoicesResumed, setCountOfInvoicesResumed] = useState<string>();

    useEffect(() => {
        factureAchatApi
            .getFacturesAchatsFactureAchatCollection({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: false,
                deleted: false,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setCountOfInvoicesResumed(`${res.data['hydra:totalItems']}`);
                }
            });
    }, [xIdSociete]);

    const actionCallback = useCallback(async () => {
        navigate(achatsViewPathResolver('listfacturepause'));
    }, [navigate]);

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <WidgetWithAction
                title="Factures en pause"
                icon={pausedInvoiceIcon}
                actionMessage="Traiter"
                actionCallback={actionCallback}
                data={countOfInvoicesResumed}
                paragraph="Factures en pause :"
                color="warning"
            />
        </Grid>
    );
};

export default FacturesEnPause;
