import React from 'react';

import { Box, Typography, Card } from '@mui/material';

import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { useSnackbar } from 'notistack';
import { useFakeProgressBar } from '@europrocurement/flexy-components/hooks';
import { mimetypeToIcone, statusToColor } from '../DisplayFile';
import { useUploader } from '../../organisms/Uploader/useUploader';

import FileUpload, { type FileUploadProps } from '../../molecules/FileUpload';
import ProgressBar from '../ProgressBar';

export type TypedRequiredFileProps = {
    libelle: string;
    // id: number;
    accept?: string;
    formatInfo?: string;
    fileName?: string;
    maxMbSize?: number;
    pending?: boolean;
    isUploaded?: boolean;
    skipUploaderProvider?: boolean;
    onDrop?: (file: File) => void;
};

const TypedRequiredFile: React.FunctionComponent<TypedRequiredFileProps> = function ({
    // id,
    libelle,
    accept = 'application/pdf',
    formatInfo = '.pdf',
    maxMbSize = undefined,
    fileName = undefined,
    isUploaded = false,
    pending = false,
    skipUploaderProvider = false,
    onDrop = undefined,
}) {
    const { enqueueSnackbar } = useSnackbar();

    const { progress, started } = useFakeProgressBar();

    const { addFiles } = useUploader();

    const onFileAdd = (files: FileList | null) => {
        if (files !== null && files?.length > 0) {
            if (files?.length === 1) {
                if (!skipUploaderProvider) {
                    addFiles(files);
                }
                if (onDrop) {
                    onDrop(files[0]);
                }
            } else {
                enqueueSnackbar(
                    `Vous ne pouvez pas ajouter plusieurs document de type "${libelle}"`,
                    { variant: 'error' },
                );
            }
        } else {
            enqueueSnackbar('Erreur inconue, aucun fichiers', { variant: 'error' });
        }
    };

    const fileUploadProp: FileUploadProps = {
        accept,
        formatInfo,
        maxMbSize,
        inline: true,
        height: '36px',
        width: '70%',
        btnText: 'Sélectionez',
        dropText: 'ou glissez ici',
        border: false,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            onFileAdd(event.target.files);
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            onFileAdd(event.dataTransfer.files);
        },
    };

    return (
        <Card
            sx={{
                padding: '10px 15px 10px 15px',
                margin: '6px 0px 6px 0px',
                borderRadius: '12px',
                boxShadow: 'none',
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                gap="10px"
            >
                <Box
                    data-testid="test-id-status-color-box"
                    sx={{
                        backgroundColor: isUploaded ? 'primary.light' : 'text.gris005',
                        padding: '8px',
                        borderRadius: '10px',
                        fontSize: '14px',
                        width: 36,
                        height: 36,
                    }}
                >
                    <FaOptionIcon
                        data-testid="test-id-mimetype-icone"
                        icon={mimetypeToIcone('application/pdf')}
                        color={isUploaded ? 'primary.main' : statusToColor('idle')}
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'grid',
                        alignItems: 'center',
                    }}
                    aria-busy={pending}
                >
                    <Typography
                        color={isUploaded ? 'primary.main' : undefined}
                        variant="l2dcaption"
                    >
                        {libelle}
                    </Typography>
                    {fileName ? (
                        <Typography
                            color="text.gris70"
                            variant="l2dcaption"
                        >
                            {fileName} <FontAwesomeIcon icon={faCheckCircle} />
                        </Typography>
                    ) : null}
                    {pending || started ? <ProgressBar completion={progress} /> : null}
                </Box>
                {isUploaded || pending ? null : <FileUpload {...fileUploadProp} />}
            </Box>
        </Card>
    );
};

export default TypedRequiredFile;
