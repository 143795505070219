import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import { PackageJsonldPackageRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { UnknownAction } from 'redux';
import { addItem } from '@europrocurement/l2d-icons';
import { useOffreService } from '@europrocurement/l2d-domain';
import ListingWithPreview from '../components/ListingWithPreview';
import Form from './Form/Form';
import { displayCustomValue, getStringColumn } from '../listings';

const Panel: React.FunctionComponent = function () {
    const { modalActions } = React.useContext(ModalContext);

    const dispatch = useDispatch();

    const { offerPackageModel: model } = useOffreService();

    const reloadList = () =>
        dispatch(model.datasourcesThunks.main.getData({}) as unknown as UnknownAction);

    const listingButton = {
        label: 'Créer un nouveau package',
        icon: addItem,
        action: () =>
            modalActions.call(
                <Form
                    isCreate
                    afterSubmit={reloadList}
                />,
            ),
    };

    const columns: ColumnDatatable<PackageJsonldPackageRead>[] = [
        {
            ...getStringColumn('id'),
            sortable: true,
        },
        {
            ...getStringColumn('libelle', 'Libellé', 50),
            sortable: true,
        },
        {
            ...getStringColumn('nomCommercial', undefined, 25),
            sortable: true,
        },
        {
            ...displayCustomValue(
                'formalite' as keyof PackageJsonldPackageRead['formalite'],
                (formality: PackageJsonldPackageRead['formalite']) => formality?.libelle ?? '---',
                'Type de formalité',
                25,
            ),
            sortable: true,
        },
        {
            label: 'Domaine',
            displayLabel: true,
            render: (item) => {
                const domain = item.domaine as { libelle?: string };
                return (
                    <Box sx={{ justifyContent: 'end' }}>
                        <p>{domain?.libelle ?? '(aucun domaine)'}</p>
                    </Box>
                );
            },
            isDisplayed: true,
            sortable: false,
            field: 'domaine',
        },
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des packages"
            listingButton={listingButton}
            listingColumns={columns}
            form={Form}
            model={model}
        />
    );
};

export default Panel;
