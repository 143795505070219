import React, { useContext } from 'react';

import { Typography } from '@mui/material';
import { ModalContext } from '@europrocurement/flexy-components';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import ConfirmationModalContent from '../modals/ConfirmationModalContent';
import { invoiceStatementModalMessages } from '../../constants/wording/modals';
import HeaderButton from './HeaderButton';

type StatementHeaderButtonProps = {
    statementId?: number;
    onClick: () => void;
};

/**
 * Bouton permettant d'associer une facture à un relevé.
 *
 * @param statementId
 * @param onClick
 * @returns ReactNode
 */
const StatementHeaderButton: React.FunctionComponent<StatementHeaderButtonProps> = function ({
    statementId,
    onClick,
}: StatementHeaderButtonProps) {
    const { modalActions } = useContext(ModalContext);

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    if (!isInterne) return null;

    return !statementId ? (
        <HeaderButton
            content="Relevé de factures"
            onClick={() => {
                modalActions.call(
                    <ConfirmationModalContent
                        messages={invoiceStatementModalMessages}
                        actionOnValidation={onClick}
                    />,
                );
            }}
        />
    ) : (
        <Typography>Associée au relevé N°{statementId}</Typography>
    );
};

export default StatementHeaderButton;
