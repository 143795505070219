import { type OfferStateType } from './types';

import {
    OFFRE_REDUCER_NAME,
    specificsArticleLabels,
    ARTICLES_SLICE_NAME,
    CATALOGS_SLICE_NAME,
    DOCUMENT_SLICE_NAME,
    DOMAINS_SLICE_NAME,
    OFFER_ADDITIONAL_FIELDS_SLICE_NAME,
    OFFER_OPTIONS_SLICE_NAME,
    OFFERS_SLICE_NAME,
    PACKAGES_SLICE_NAME,
    VATS_SLICE_NAME,
    INVOICING_CATEGORIES_SLICE_NAME,
    REDUX_LIB_ARTICLE,
    OFFER_ACTIONS_SLICE_NAME,
    OFFER_GROUP_SLICE_NAME,
    OFFER_OPTION_VALUES_SLICE_NAME,
    REGLEDECALCULS_SLICE_NAME,
} from './constants';

export const OfferSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFERS_SLICE_NAME];
export const CatalogSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][CATALOGS_SLICE_NAME];
export const PackageSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][PACKAGES_SLICE_NAME];
export const ArticleSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][ARTICLES_SLICE_NAME];
export const OfferOptionsSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_OPTIONS_SLICE_NAME];
export const OfferAdditionalFieldsSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_ADDITIONAL_FIELDS_SLICE_NAME];
export const DocumentSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][DOCUMENT_SLICE_NAME];
export const vatSelector = (state: OfferStateType) => state[OFFRE_REDUCER_NAME][VATS_SLICE_NAME];
export const domainSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][DOMAINS_SLICE_NAME];

export const regleDeCalculSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][REGLEDECALCULS_SLICE_NAME];

// [OFFRE] - Store main selected accessors
export const selectedOffer = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFERS_SLICE_NAME].main.selected;
export const selectedCatalog = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][CATALOGS_SLICE_NAME].main.selected;
export const selectedPackage = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][PACKAGES_SLICE_NAME].main.selected;
export const selectedArticle = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][ARTICLES_SLICE_NAME].main.selected;
export const selectedOfferOption = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_OPTIONS_SLICE_NAME].main.selected;
export const selectedOfferAdditionalField = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_ADDITIONAL_FIELDS_SLICE_NAME].main.selected;

export const InvoicingCategoriesSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][INVOICING_CATEGORIES_SLICE_NAME];

export const sectionTitleArticleId = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][REDUX_LIB_ARTICLE].result?.find(
        (result) => result.libelle === specificsArticleLabels.section,
    )?.id;

export const lineBreakArticleId = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][REDUX_LIB_ARTICLE].result?.find(
        (result) => result.libelle === specificsArticleLabels.lineBreak,
    )?.id;

export const baseActionsSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_ACTIONS_SLICE_NAME];

export const baseAdditionalFieldsSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_ADDITIONAL_FIELDS_SLICE_NAME];

export const baseArticlesSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][ARTICLES_SLICE_NAME];

export const baseDocumentSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][DOCUMENT_SLICE_NAME];

export const baseOffersSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFERS_SLICE_NAME];

export const baseOfferGroupsSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_GROUP_SLICE_NAME];

export const baseOfferOptionsSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_OPTIONS_SLICE_NAME];

export const baseOfferOptionValuesSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_OPTION_VALUES_SLICE_NAME];

export const baseCatalogsSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][CATALOGS_SLICE_NAME];

export const baseofferPackageSelector = (state: OfferStateType) =>
    state[OFFRE_REDUCER_NAME][PACKAGES_SLICE_NAME];
