/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    ExceptionFacturanteApi,
    ExceptionFacturanteJsonldExceptionFacturanteRead,
    ExceptionFacturanteApiGetExceptionFacturanteCollectionRequest,
} from '../../../openApi/ApiAchats';
import { ACHATS_REDUCER_NAME } from '../constants';

export type ExceptionFacturanteApiObject = ExceptionFacturanteJsonldExceptionFacturanteRead &
    Record<string, unknown>;

export type ExceptionFacturante = ExceptionFacturanteApiObject;

export const EXCEPTIONS_FACTURANTES_SLICE_NAME = 'exceptionFacturante';

export const additionalExceptionsFacturantesDataSources: Array<string> = [];

const mapperExceptionFacturante = function (exceptionFacturante: ExceptionFacturante) {
    return {
        ...exceptionFacturante,
    } as ExceptionFacturante;
};

export function createExceptionFacturanteSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new ExceptionFacturanteApi(conf);

    const fetchExceptionFacturantesCollection: FetchCollectionData<
        ExceptionFacturanteApiObject
    > = ({ search, pagination, filters, orders, xIdSociete, rootstate }) => {
        let id: number | undefined;
        let codeRejetCode: string | undefined;
        let facturante: string | undefined;
        let frequenceRelance: string | undefined;
        let relanceAuto: boolean | undefined;
        let recherche: string | undefined;

        if (filters.id) {
            id = filters.id as number;
        }

        if (filters.codeRejetCode) {
            codeRejetCode = filters.codeRejetCode as string;
        }

        if (filters.facturante) {
            facturante = filters.facturante as string;
        }

        if (filters.frequenceRelance) {
            frequenceRelance = filters.frequenceRelance as string;
        }

        if (filters.relanceAuto !== undefined) {
            relanceAuto = filters.relanceAuto as boolean;
        }

        if (search && search !== '') {
            recherche = search;
        }

        const requestParameters: ExceptionFacturanteApiGetExceptionFacturanteCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            id,
            codeRejetCode,
            facturante,
            frequenceRelance,
            relanceAuto,
            recherche,
        };

        return api
            .getExceptionFacturanteCollection(requestParameters)
            .then((res) => res.data) as Promise<ApiCollectionResponse<ExceptionFacturante>>;
    };

    const fetchExceptionFacturantesItem: FetchItemDataType<ExceptionFacturante> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getExceptionFacturanteItem({
                id: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<ExceptionFacturanteApiObject>;

    const {
        slice: exceptionFacturanteSlice,
        dataSourcesThunks: exceptionFacturanteDataSourcesThunks,
    } = SliceFactory.createSlice<ExceptionFacturanteApiObject, ExceptionFacturante>(
        EXCEPTIONS_FACTURANTES_SLICE_NAME,
        ACHATS_REDUCER_NAME,
        additionalExceptionsFacturantesDataSources,
        fetchExceptionFacturantesCollection,
        fetchExceptionFacturantesItem,
        mapperExceptionFacturante,
        {},
        undefined,
        [
            {
                field: 'updatedAt',
                value: 'desc',
            },
        ],
    );

    return { exceptionFacturanteSlice, exceptionFacturanteDataSourcesThunks };
}
