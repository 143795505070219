import { FlexyHeaderForm, Modalizer } from '@europrocurement/flexy-components';
import { Box, Button, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormaliteDocument, formalitiesDocumentSelector } from '@europrocurement/l2d-domain';
import CheckboxListItem from '../../components/CheckboxListItem';

type DocumentModalProps = {
    title: string;
    isOpen: boolean;
    onModalClose: () => void;
    onSubmitModal: (selectedItems: unknown) => void;
    itemsSelected: number[];
    type: number;
};

type DocumentItem = {
    id: number;
    label: string;
    checked: boolean;
    type: number;
};

const DocumentModal: React.FunctionComponent<DocumentModalProps> = function (props) {
    const { title, isOpen, onModalClose, onSubmitModal, itemsSelected, type } = props;
    const [selectedItems, setSelectedItems] = useState<number[]>(itemsSelected);
    const [searchTerm, setSearchTerm] = useState('');
    const dataSource = useSelector(formalitiesDocumentSelector);
    const documentsItems = dataSource.data
        .map((item: FormaliteDocument) => ({
            id: item.id,
            label: item.libelle,
            checked: selectedItems.indexOf(item.id as number) !== -1,
            type: item.type,
        }))
        .filter(
            (item: DocumentItem) =>
                type === item.type &&
                (!searchTerm || item.label.toLowerCase().match(searchTerm.toLowerCase())),
        )
        .sort((a: DocumentItem, b: DocumentItem) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
            }

            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
            }

            return 0;
        });
    const onCheckItem = (documentId: number) => {
        if (selectedItems.indexOf(documentId) === -1) {
            const tmpArray = [...selectedItems];
            tmpArray.push(documentId);
            setSelectedItems(tmpArray);
        }
    };
    const onUncheckItem = (documentId: number) => {
        if (selectedItems.indexOf(documentId) !== -1) {
            const tmpArray = [...selectedItems];
            tmpArray.splice(tmpArray.indexOf(documentId), 1);
            setSelectedItems(tmpArray);
        }
    };
    const cancelModal = () => {
        onModalClose();
    };
    const submitModal = () => {
        setSelectedItems(selectedItems);
        onSubmitModal(selectedItems);
    };

    useEffect(() => {
        setSelectedItems(itemsSelected);
    }, [isOpen, itemsSelected]);

    const items = documentsItems.map((item: DocumentItem) => (
        <Grid
            key={item.id}
            item
            style={{ width: '100%' }}
        >
            <CheckboxListItem
                label={item.label}
                checked={item.checked}
                onUpdate={(isChecked: boolean) => {
                    if (isChecked) {
                        onCheckItem(item.id);
                    } else {
                        onUncheckItem(item.id);
                    }
                }}
            />
        </Grid>
    ));
    const submitBtn = (
        <Button
            key="document-modal-submit-btn"
            color="primary"
            variant="contained"
            onClick={submitModal}
            style={{ marginTop: '8px', marginBottom: '8px' }}
        >
            Confirmer
        </Button>
    );
    const cancelBtn = (
        <Button
            key="document-modal-cancel-btn"
            color="primary"
            variant="outlined"
            onClick={cancelModal}
            style={{ marginTop: '8px', marginBottom: '8px' }}
        >
            Annuler
        </Button>
    );

    return (
        <Modalizer
            open={isOpen}
            onClose={cancelModal}
            maxWidth="lg"
            fullWidth
            modalActions={[cancelBtn, submitBtn]}
        >
            <Box>
                <FlexyHeaderForm
                    outlined
                    label={title}
                />
                <TextField
                    fullWidth
                    placeholder="Filtrer la liste des documents"
                    sx={{ marginTop: '25px' }}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
                <Box sx={{ marginTop: '25px', maxHeight: '60vh', overflowY: 'scroll' }}>
                    <Grid
                        container
                        gap="10px 0"
                    >
                        {items}
                    </Grid>
                </Box>
            </Box>
        </Modalizer>
    );
};

export default DocumentModal;
