import React from 'react';

import { KeycloakHasRole, UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { Grid } from '@mui/material';
import FacturesATraiter from './Widgets/FacturesATraiter';
import FacturesEnPause from './Widgets/FacturesEnPause';
import RelevesNonClotures from './Widgets/RelevesNonClotures';
import MesFacturesATraiter from './Widgets/MesFacturesATraiter';
import FacturesInternesNonAttribuees from './Widgets/FacturesInternesNonAttribuees';
import FacturesASynchroniser from './Widgets/FacturesASynchroniser';
import FacturesAvecErreursTechniques from './Widgets/FacturesAvecErreursTechniques';
import FacturesEnERDO5 from './Widgets/FacturesEnERDO5';
import FacturesATraiterManuellement from './Widgets/FacturesATraiterManuellement';

const PortailAchatsWidgets: React.FunctionComponent = function () {
    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    return (
        <>
            <Grid
                container
                columns={isInterne ? 5 : 2}
            >
                <FacturesATraiter />
                <FacturesEnPause />
                <KeycloakHasRole kcRole="realm:interne">
                    <RelevesNonClotures />
                    <MesFacturesATraiter />
                    <FacturesInternesNonAttribuees />
                </KeycloakHasRole>
            </Grid>

            <KeycloakHasRole kcRole="realm:interne">
                <Grid
                    container
                    columns={isInterne ? 4 : 2}
                >
                    <FacturesASynchroniser />
                    <FacturesAvecErreursTechniques />
                    <FacturesEnERDO5 />
                    <FacturesATraiterManuellement />
                </Grid>
            </KeycloakHasRole>
        </>
    );
};

export default PortailAchatsWidgets;
