import React from 'react';
import { Box } from '@mui/material';
import BillingRecipient from '../BillingRecipient/BillingRecipient';
import BillingAddress from '../BillingAddress/BillingAddress';
import { AddressChoice } from '../SelectBillingAddress/SelectBillingAddress';
import BillingType from '../BillingType/BillingType';
import BillingInfoCard from '../BillingInfoCard/BillingInfoCard';
import BillingAddressCard, {
    BillingAddressCardProps,
} from '../BillingAddressCard/BillingAddressCard';

export const BILLING_TYPE_VI_PRESCRIBER = 1;
export const BILLING_TYPE_VI_L2D = 2;
export const BILLING_TYPE_VD = 3;

export type BillingInfoProps = {
    recipientId: number;
    prescriberId: number;
    prescriberLabel: string;
    prescriberAddress: string;
    prescriberSiren: string;
    onRecipientChange: (recipientId?: number) => void;
    onBillingTypeChange: (billingType?: number) => void;
    addresses: Array<AddressChoice> | undefined;
    billingType: number;
    canEdit: boolean;
    canEditBillingType?: boolean;
};

const BillingInfo: React.FunctionComponent<BillingInfoProps> = function (props) {
    const {
        recipientId,
        prescriberId,
        prescriberLabel,
        prescriberSiren,
        prescriberAddress,
        onRecipientChange: handleRecipientChange,
        onBillingTypeChange: handleBillingTypeChange,
        addresses,
        billingType,
        canEdit,
        canEditBillingType = true,
    } = props;
    const isPrescriberBilled = recipientId === prescriberId;

    const wrapperSx = {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
    };

    const onBilledClientChange = (newBilledClientId: number) => {
        handleRecipientChange(newBilledClientId);
    };

    const onPrescriberBilledChange = (prescriberBilled: boolean) => {
        if (prescriberBilled) {
            handleRecipientChange(prescriberId);

            return;
        }

        handleRecipientChange();
    };

    if (!canEdit) {
        const getBillingAddress = (): BillingAddressCardProps => {
            if (isPrescriberBilled || !addresses) {
                return {
                    label: prescriberLabel,
                    siren: prescriberSiren,
                    address: prescriberAddress,
                };
            }

            const address = addresses.find((d) => d.clientId === recipientId);

            return {
                label: address?.label as string,
                siren: address?.siren as string,
                address: address?.address as string,
            };
        };

        return (
            <Box
                sx={wrapperSx}
                data-testid="billing-info"
            >
                <BillingInfoCard
                    label="Adresse de facturation :"
                    value={<BillingAddressCard {...getBillingAddress()} />}
                />
                {!isPrescriberBilled && (
                    <BillingInfoCard
                        label="Recouvrement effectué par :"
                        value={billingType === BILLING_TYPE_VD ? prescriberLabel : 'Legal2Digital'}
                    />
                )}
            </Box>
        );
    }
    if (!addresses) {
        return (
            <Box
                sx={wrapperSx}
                data-testid="billing-edit-info"
            >
                <BillingAddressCard
                    label={prescriberLabel}
                    siren={prescriberSiren}
                    address={prescriberAddress}
                />
            </Box>
        );
    }
    return (
        <Box
            sx={wrapperSx}
            data-testid="billing-edit-info"
        >
            <BillingRecipient
                prescriberBilled={isPrescriberBilled}
                items={[
                    { label: prescriberLabel, value: true },
                    { label: 'Mon client', value: false },
                ]}
                onChange={onPrescriberBilledChange}
            />
            <BillingAddress
                prescriberBilled={isPrescriberBilled}
                prescriberLabel={prescriberLabel}
                prescriberSiren={prescriberSiren}
                prescriberAddress={prescriberAddress}
                clientId={recipientId}
                addresses={addresses}
                onChange={onBilledClientChange}
            />
            {!isPrescriberBilled && canEditBillingType && (
                <BillingType
                    type={billingType}
                    items={[
                        { label: prescriberLabel, value: BILLING_TYPE_VD },
                        { label: 'Legal2Digital', value: BILLING_TYPE_VI_L2D },
                    ]}
                    onChange={handleBillingTypeChange}
                />
            )}
        </Box>
    );
};

export default BillingInfo;
