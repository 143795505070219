import { LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import Document from './document.svg';
import Scanne from './scanne.svg';

import './animation.css';
import { useFakeProgressBar } from '../../../hooks';

export type DocIaProcessingProps = {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
};

const DocIaProcessing: React.FunctionComponent<DocIaProcessingProps> = function ({
    title = 'Nous préparons les informations du dossier',
    subTitle = 'Merci de patienter...',
}) {
    const { progress, progressBuffer, startProgress, started } = useFakeProgressBar(true, 20000);

    React.useEffect(() => {
        if (!started) {
            startProgress();
        }
    }, [startProgress, started]);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: '100px',
                width: '50%',
                left: '25vw',
                right: '25vw',
                gap: '16px',
                height: '30vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                alignItems: 'center',
            }}
        >
            <img
                style={{
                    height: '80px',
                }}
                src={Document}
                alt=""
            />
            <img
                style={{
                    position: 'absolute',
                    top: '190px',
                    width: '94px',
                    animation: 'MoveUpDown 3s linear infinite',
                }}
                src={Scanne}
                alt=""
            />
            <LinearProgress
                sx={{ width: '150px', height: '5px' }}
                variant="buffer"
                value={progress}
                valueBuffer={progressBuffer}
            />

            <Typography variant="l2dh6">{title}</Typography>
            <Typography variant="l2dbodym">{subTitle}</Typography>
        </Box>
    );
};

export default DocIaProcessing;
