import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { InformationChip } from '@europrocurement/flexy-components';

export type DossierStatusProps = {
    label: string;
    color: 'primary';
};

const DossierStatus: React.FunctionComponent<DossierStatusProps> = function (props) {
    const { label, color } = props;
    const { palette } = useTheme();
    const baseColor = palette[color].main;

    return (
        <InformationChip
            color={color}
            label={
                <Typography
                    variant="l2dbodys"
                    color={baseColor}
                >
                    {label}
                </Typography>
            }
        />
    );
};

export default DossierStatus;
