import * as React from 'react';
import { Box, Typography, Avatar, Button, Stack, Menu } from '@mui/material';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import md5 from 'md5';
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import { useFullLayout } from '../../templates/FullLayout/FullLayoutContext';

const ProfileDropdownOppener: React.FunctionComponent = function () {
    const keycloak = UseKeycloakService();

    const userimg = React.useCallback(
        () => `https://www.gravatar.com/avatar/${md5(keycloak.getEmail())}`,
        [keycloak],
    );
    const { jobName, toggleCustomizerDrawer } = useFullLayout();

    const logout = React.useCallback(() => keycloak.getKc().logout(), [keycloak]);

    const [profilDropdownOpened, setProfilDropdwnOpened] = React.useState<Element | null>(null);

    const handleClose = () => setProfilDropdwnOpened(null);
    const openProfilDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event.target instanceof Element) setProfilDropdwnOpened(event.currentTarget);
    };

    const oppenCustomizeFromProfile = () => {
        setProfilDropdwnOpened(null);
        toggleCustomizerDrawer();
    };

    return (
        <>
            <Button
                aria-label="menu"
                color="inherit"
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={openProfilDropdown}
            >
                <Stack
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    <Avatar
                        src={userimg()}
                        alt={userimg()}
                        sx={{
                            marginRight: '15px',
                            width: '32px',
                            height: '32px',
                        }}
                    />
                    <Typography
                        variant="l2dbodys"
                        color="text.gris50"
                    >
                        Bonjour,{' '}
                        <Typography
                            variant="l2dbodys"
                            color="text.primary"
                            fontWeight={700}
                        >
                            {keycloak.getName()}
                        </Typography>
                    </Typography>
                    <Typography
                        variant="l2dbodys"
                        color="text.gris70"
                        fontWeight={900}
                    >
                        <FontAwesomeIcon
                            width={16}
                            height={16}
                            icon={faChevronDown}
                            flip={profilDropdownOpened ? 'vertical' : undefined}
                        />
                    </Typography>
                </Stack>
            </Button>
            <Menu
                id="profile-menu"
                anchorEl={profilDropdownOpened}
                open={!!profilDropdownOpened}
                onClose={handleClose}
            >
                <Box width="385px">
                    <ProfileDropdown
                        jobName={jobName}
                        avatarSrc={userimg()}
                        logout={logout}
                        openSettings={oppenCustomizeFromProfile}
                    />
                </Box>
            </Menu>
        </>
    );
};

export default ProfileDropdownOppener;
