/* eslint-disable @typescript-eslint/no-unused-vars */
import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import { createContext } from 'react';

const selectedDossier: DossierOpenSearch = { formalites: [] };
const setSelectedDossier: React.Dispatch<React.SetStateAction<DossierOpenSearch>> = () => {};
const siblings: Array<DossierOpenSearch> = [];
const setSiblings: React.Dispatch<React.SetStateAction<DossierOpenSearch[]>> = () => {};
const updateDossier = (payload: Record<string, unknown>) => {};

const SelectedDossierContext = createContext({
    dossier: selectedDossier,
    setDossier: setSelectedDossier,
    siblings,
    setSiblings,
    updateDossier,
});

export default SelectedDossierContext;
