import React from 'react';

import { Box, SxProps, Typography, CardHeader, Divider } from '@mui/material';

import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';

export type LegendProps = {
    icons: NamedIconsType[];
    titre?: string;
    sx?: SxProps;
};

const Legend: React.FunctionComponent<LegendProps> = function ({
    icons,
    titre,
    sx = {
        margin: 2,
    },
}) {
    return (
        <Box sx={sx}>
            <Divider sx={{ marginBottom: '10px' }} />
            <CardHeader
                title={
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            sx={{ marginBottom: '10px' }}
                        >
                            {titre || 'Légende des icônes'}
                        </Typography>
                    </Box>
                }
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 4,
                }}
            >
                {icons.map((namedIcon: NamedIconsType) => (
                    <Box
                        key={namedIcon.name}
                        sx={{
                            // margin: 'auto',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            // alignItems: 'center',
                            // justifyContent: 'center',
                        }}
                    >
                        <FaOptionIcon {...namedIcon.props} /> {namedIcon.displayName}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Legend;
