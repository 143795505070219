import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, useTheme } from '@mui/system';

interface Position {
    x: number;
    y: number;
}

export type PdfRulerProps = {
    pdfHeight: number;
    isMarkerVisible?: boolean;
};

const PdfRuler: React.FunctionComponent<PdfRulerProps> = function ({
    pdfHeight,
    isMarkerVisible = false,
}: PdfRulerProps) {
    const theme = useTheme();
    const [rulerFrameHeight, setRulerFrameHeight] = useState(pdfHeight);
    const [position, setPosition] = useState<Position>(() => {
        const storedPosition = localStorage.getItem('rulerPosition');
        return storedPosition ? JSON.parse(storedPosition) : { x: 0, y: 0 };
    });
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [dragStartPosition, setDragStartPosition] = useState<Position>({ x: 0, y: 0 });
    const [markerWidth, setMarkerWidth] = useState(0);
    const marqueurRef = useRef<HTMLDivElement>(null);
    const viewerRef = useRef<HTMLDivElement>(null);

    // Mettre à jour la hauteur du cadre de la règle lorsque la hauteur du PDF change
    useEffect(() => {
        setRulerFrameHeight(pdfHeight);
    }, [pdfHeight]);

    // Ajuster la largeur du marqueur lors du redimensionnement de la fenêtre
    useEffect(() => {
        const handleResize = () => {
            if (isMarkerVisible) {
                const viewerWidth = viewerRef.current?.clientWidth || 0;
                setMarkerWidth(viewerWidth);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMarkerVisible]);

    // Ajuster la hauteur du visualiseur lors du redimensionnement de la fenêtre
    useEffect(() => {
        const handleResize = () => {
            if (viewerRef.current) {
                viewerRef.current.style.height = '100%';
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const disableTextSelection = () => {
        document.body.style.userSelect = 'none';
    };

    const enableTextSelection = () => {
        document.body.style.userSelect = 'auto';
    };

    // Gestionnaires d'événements pour le déplacement du marqueur
    const handleMouseDown = (event: React.MouseEvent) => {
        setIsDragging(true);
        disableTextSelection();
        setDragStartPosition({ x: event.clientX, y: event.clientY });
    };

    const handleMouseUp = useCallback(() => {
        if (isDragging) {
            setIsDragging(false);
            enableTextSelection();
        }
    }, [isDragging]);

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            if (isDragging && viewerRef.current && marqueurRef.current) {
                const offsetY = event.clientY - dragStartPosition.y;
                const newY = Math.max(0, position.y + offsetY);
                setPosition((prevPosition) => ({
                    ...prevPosition,
                    y: newY,
                }));
                setDragStartPosition({ x: event.clientX, y: event.clientY });
            }
        },
        [dragStartPosition.y, isDragging, position.y],
    );

    // Ajouter et supprimer les écouteurs d'événements de la souris lors du déplacement du marqueur
    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isDragging, dragStartPosition, position, handleMouseUp, handleMouseMove]);

    // Sauvegarder la position du marqueur dans le localStorage lorsqu'elle change
    useEffect(() => {
        localStorage.setItem('rulerPosition', JSON.stringify(position));
    }, [position]);

    return (
        <Box
            sx={{
                height: rulerFrameHeight,
                top: `-${rulerFrameHeight}px`,
            }}
        >
            {isMarkerVisible && (
                <Box
                    ref={marqueurRef}
                    sx={{
                        position: 'absolute',
                        // marginLeft: '65px',
                        left: position.x,
                        top: position.y,
                        width: `${markerWidth - 15}px`,
                        height: '3px',
                        display: 'flex',
                        zIndex: 10000,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'move',
                        backgroundImage: `repeating-linear-gradient(to right, ${theme.palette.error.main}, ${theme.palette.error.main} 2px, transparent 2px, transparent 4px)`,
                    }}
                    onMouseDown={handleMouseDown}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'transparent',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '0px',
                                width: '0',
                                height: '0',
                                borderTop: '16px solid transparent',
                                borderBottom: '16px solid transparent',
                                borderRight: `32px solid ${theme.palette.error.main}`,
                                transform: 'translate(-50%, -50%) rotate(180deg)',
                                transformOrigin: 'center',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: '0px',
                                width: '0',
                                height: '0',
                                borderTop: '8px solid transparent',
                                borderBottom: '8px solid transparent',
                                borderLeft: `16px solid ${theme.palette.error.main}`,
                                transform: 'translate(50%, -50%) rotate(180deg)',
                                transformOrigin: 'center',
                            }}
                        />
                    </Box>
                </Box>
            )}
            <Box
                ref={viewerRef}
                style={{ height: '100%' }}
            />
        </Box>
    );
};

export default PdfRuler;
