import * as React from 'react';

import { FormControlLabel, Switch } from '@mui/material';

import { ChoiceProps } from '../types';

type ToggleShapeProps = {
    choices: Array<ChoiceProps>;
    formState: Record<string, boolean>;
    handleCheckboxChange: (choice: ChoiceProps) => void;
};

const ToggleShape: React.FunctionComponent<ToggleShapeProps> = function ({
    choices,
    formState,
    handleCheckboxChange,
}) {
    return (
        <>
            {choices.map((choice) => (
                <FormControlLabel
                    key={choice.label}
                    label={choice.label}
                    control={
                        <Switch
                            checked={formState[choice.label]}
                            onChange={(event, checked) => {
                                handleCheckboxChange(choice);
                                if (choice.action) {
                                    choice.action(event, checked);
                                }
                            }}
                        />
                    }
                />
            ))}
        </>
    );
};

export default ToggleShape;
