export const UTILISATEUR_REDUCER_NAME = 'utilisateurs';
export const UTILISATEUR_SLICE_NAME = 'utilisateurs';

export const SERVICES_SLICE_NAME = 'services';

export const POLES_SLICE_NAME = 'poles';

export const MARQUES_SLICE_NAME = 'marques';

export const GROUPES_SLICE_NAME = 'groupes';
