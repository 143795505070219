import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { getOrderParameters, Orders } from '@europrocurement/l2d-domain/utils/orders';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    CatalogueApi,
    CatalogueJsonldCatalogueRead,
} from '../../../openApi/ApiOffre';
import { CATALOGS_SLICE_NAME, OFFRE_REDUCER_NAME } from '../constants';

export type CatalogApiObject = CatalogueJsonldCatalogueRead;

export type Catalogs = CatalogApiObject;

export const mapperCatalogs = function (catalogs: CatalogApiObject) {
    return {
        ...catalogs,
    } as Catalogs;
};

export function createCatalogsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CatalogueApi(conf);

    // apiPrescripteursCataloguesGetCollection
    const fetchCatalogsCollection: FetchCollectionData<CatalogApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
        filters,
        orders,
    }) => {
        /** Field mapper : Map column field name with api filter name */
        const ordersSchema = {
            groupement: 'groupementLibelle',
            offre: 'offreLibelle',
        };

        /** Get collection default orders */
        const defaultOrders: Orders = {
            orderOffreLibelle: 'asc',
            orderGroupementLibelle: 'asc',
            orderNomCommercial: 'asc',
        };

        const orderParameters = getOrderParameters(orders, defaultOrders, ordersSchema);

        const requestParameters = {
            xIdSociete,
            page: page + 1,
            itemsPerPage,
            ...filters,
            domaineSocietesSocieteId: xIdSociete,
            ...orderParameters,
        };

        return api
            .apiCataloguesGetCollection(requestParameters)
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<CatalogApiObject>>;
    };

    const fetchCatalogsItem: FetchItemDataType<CatalogApiObject> = ({ idItem, xIdSociete }) =>
        api
            .apiCataloguesIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<CatalogApiObject>;

    const { slice: catalogsSlice, dataSourcesThunks: catalogsDataSourcesThunks } =
        SliceFactory.createSlice<CatalogApiObject, Catalogs>(
            CATALOGS_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchCatalogsCollection,
            fetchCatalogsItem,
            mapperCatalogs,
        );

    return { catalogsSlice, catalogsDataSourcesThunks };
}
