import { useSelector } from 'react-redux';
import { AxiosRequestConfig } from 'axios';
import useLazyDispatch from './useLazyDispatch';
import { useSelectorsForger } from './useSelectorsForger';

export const useSliceUtilities = ({
    sliceName,
    dataSourceName = 'main',
}: {
    sliceName: string;
    dataSourceName?: string;
}) => {
    // Génère les sélecteurs dynamiques pour le slice
    const { selectedSelector, datasourceSelector, statusSelector } = useSelectorsForger({
        reducerName: 'achats', // Exemple, peut être adapté si nécessaire
        slicerName: sliceName,
        dataSourceName,
    });

    // Récupère les données depuis le store via les sélecteurs
    const selectedItem = useSelector(selectedSelector);
    const dataSource = useSelector(datasourceSelector);
    const status = useSelector(statusSelector);

    // Utilisation de `useLazyDispatch` pour les actions sur les targets
    const lazyDispatch = useLazyDispatch({
        sliceName,
        dataSourceName,
    });

    const actions = {
        selectItem: (id: string | number, options?: AxiosRequestConfig) =>
            lazyDispatch({
                target: 'Selected',
                action: 'set',
                payload: { id, options },
            })(),
        unselectItem: () =>
            lazyDispatch({
                target: 'Selected',
                action: 'delete',
            })(),
        setFilter: (key: string, value: string | number | boolean | Array<string | number>) =>
            lazyDispatch({
                target: 'Filter',
                action: 'set',
                payload: { key, value },
            })(),
        resetFilter: () =>
            lazyDispatch({
                target: 'Filter',
                action: 'reset',
            })(),
        setSearch: (search: string) =>
            lazyDispatch({
                target: 'Search',
                action: 'set',
                payload: { search },
            })(),
        resetSearch: () =>
            lazyDispatch({
                target: 'Search',
                action: 'reset',
            })(),
    };

    // Utilitaires pour simplifier les vérifications d'état
    const isLoading = ['idle', 'loading'].includes(status);
    const isFailed = status === 'failed';

    return {
        dataSource,
        selectedItem,
        status,
        isLoading,
        isFailed,
        actions,
    };
};
