import React from 'react';
import { useModal } from '@europrocurement/flexy-components';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

export type ValidationModalProps = {
    message?: string;
    onSubmit: () => void;
};

/**
 * validation modal with 'Annuler' & 'Valider' buttons. Annuler close modal, Valider strike the function passed in params
 * @param message message string to display in the modal. Default :  'Êtes-vous sûr de vouloir valider cette action ?'
 * @returns strike the function passed in parameters
 */
export const ValidationModal: React.FunctionComponent<ValidationModalProps> = function ({
    message,
    onSubmit,
}) {
    const { modalActions } = useModal();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <Typography style={{ marginBottom: '8px' }}>
                {message || 'Êtes-vous sûr de vouloir valider cette action ?'}
            </Typography>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                gap={2}
            >
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => modalActions.close()}
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                >
                    Annuler
                </Button>

                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                >
                    Valider
                </Button>
            </Box>
        </form>
    );
};
