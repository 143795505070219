import * as React from 'react';
import {
    styled,
    Switch as MuiSwitch,
    type SwitchProps as MuiSwitchProps,
    PaletteColor,
} from '@mui/material';
import './Switch.css';
import { FaOptionIcon, checkIcon, errorIcon } from '@europrocurement/l2d-icons';
import { Theme } from '@mui/system';

export type NativeSwitchProps = MuiSwitchProps;

export type SwitchProps = NativeSwitchProps & {
    value?: boolean;
    defaultValue?: boolean;
    color?: PaletteColor;
};

export type SwitchStyleProps = {
    theme: Theme;
    color: PaletteColor;
};

/**
 * Switch component :
 * Checked value priority : Value > Default value > false
 */
const Switch: React.FunctionComponent<SwitchProps> = styled(({ ...props }: SwitchProps) => {
    const { defaultValue = false, value } = props;
    let checked = defaultValue;

    const hasValue = typeof value !== 'undefined';
    if (hasValue) {
        checked = !!props.value;
    }

    const checkedIcon = <FaOptionIcon {...checkIcon.props} />;
    const icon = <FaOptionIcon {...errorIcon.props} />;

    return (
        <MuiSwitch
            {...(props as NativeSwitchProps)}
            role="switch"
            checked={checked}
            value={checked}
            icon={icon}
            checkedIcon={checkedIcon}
        />
    );
})(({ theme, color = theme.palette.primary }: SwitchStyleProps) => ({
    '& .MuiSwitch-switchBase': {
        backgroundColor: theme.palette.grey[200],
        '&:before': {
            backgroundColor: '#fff',
        },
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
        '.MuiTypography-root': {
            color: theme.palette.grey[500],
        },
        '&.Mui-checked': {
            backgroundColor: color.light,
            '&:before': {
                backgroundColor: color.main,
            },
            '&:hover': {
                backgroundColor: color.light,
            },
            '.MuiTypography-root': {
                color: color.main,
            },
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[100],
            '&:before': {
                backgroundColor: theme.palette.grey[100],
            },
            '&.Mui-checked': {
                backgroundColor: color.light,
                opacity: 0.5,
                '&:before': {
                    backgroundColor: color.main,
                },
            },
        },
    },
}));

export default Switch;
