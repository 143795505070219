import React, { useCallback } from 'react';

import { Box, CircularProgress, Stack, Tooltip } from '@mui/material';
import { FactureAchat, MediaObject } from '@europrocurement/l2d-domain';
import {
    FaOptionIcon,
    noteIcon,
    syncIcon,
    tagIcon,
    unpublishedIcon,
} from '@europrocurement/l2d-icons';
import { MAIL_TYPES } from '@b2d/pages/constants';
import { useTheme } from '@emotion/react';
import { FactureAchatTagsInner } from '@europrocurement/l2d-domain/openApi/ApiAchats';

type StatusListProps = {
    itemToWatch?: FactureAchat | MediaObject;
    direction?: 'row' | 'column';
};

/**
 * Liste les statuts de la facture et de son media object.
 *
 * @param content
 * @param onClick
 * @returns ReactNode
 */
const StatusList: React.FunctionComponent<StatusListProps> = function ({
    itemToWatch,
    direction = 'row',
}: StatusListProps) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const theme: any = useTheme();

    const formatTagsTextDisplay = useCallback(
        (tags: FactureAchatTagsInner[]) =>
            tags
                .map((tag) => {
                    let tagTextDisplay = '';
                    if (tag.label && tag.label in MAIL_TYPES) {
                        tagTextDisplay = `${MAIL_TYPES[tag.label]}`;
                    } else {
                        tagTextDisplay = `${tag.label}`;
                    }

                    if (tag.value) {
                        tagTextDisplay = `${tagTextDisplay} : ${tag.value}`;
                    }

                    return tagTextDisplay;
                })
                .join(', '),
        [],
    );

    return itemToWatch ? (
        <Stack
            sx={{
                display: 'flex',
                flexDirection: direction,
                alignItems: 'center',
                gap: 1,
            }}
        >
            {itemToWatch.syncG3 === false ? (
                <Tooltip title="Facture non synchronisée avec G3">
                    <Box>
                        <FaOptionIcon
                            {...syncIcon.props}
                            color="error"
                        />
                    </Box>
                </Tooltip>
            ) : null}
            {itemToWatch.cloture === false ? (
                <Tooltip title="Facture multiple non clôturée">
                    <Box>
                        <FaOptionIcon
                            {...unpublishedIcon.props}
                            color={theme.palette.warning.main}
                        />
                    </Box>
                </Tooltip>
            ) : null}
            {itemToWatch.tags && itemToWatch.tags.length > 0 ? (
                <Tooltip title={formatTagsTextDisplay(itemToWatch.tags)}>
                    <Box>
                        <FaOptionIcon
                            {...tagIcon.props}
                            color="error"
                        />
                    </Box>
                </Tooltip>
            ) : null}
            {itemToWatch.notes && itemToWatch.notes.length > 0 ? (
                <Tooltip title={`${itemToWatch.notes.length} note(s) associée(s)`}>
                    <Box>
                        <FaOptionIcon
                            {...noteIcon.props}
                            color="primary"
                        />
                    </Box>
                </Tooltip>
            ) : null}
        </Stack>
    ) : (
        <CircularProgress />
    );
};

export default StatusList;
