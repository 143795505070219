// React import
import React, { ReactNode } from 'react';
// Data / utils / custom hooks
import AccordionCard from '@b2d/components/AccordionCard';

import { usePublisherUtils } from '@b2d/hooks/publisherHooks';
import PublisherInformations from './PublisherInformations';

const MainInformations: React.FunctionComponent = function () {
    const { publisher } = usePublisherUtils();

    const publisherAccordionProps = {
        publisher,
    };

    type AccordionItem = {
        title: string;
        component: ReactNode;
        addAction?: () => void;
        addTooltip?: string;
    };

    const accordions: Array<AccordionItem> = [
        {
            title: 'Renseignements Société',
            component: <PublisherInformations {...publisherAccordionProps} />,
        },
    ];

    return (
        <>
            {accordions.map(({ title, component }) => (
                <AccordionCard
                    key={title}
                    title={title}
                    component={component}
                />
            ))}
        </>
    );
};

export default MainInformations;
