import React, { useCallback, useEffect, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { mediaObjectApi } from '@b2d/redux/RootStore';
import { myInvoiceIcon } from '@europrocurement/l2d-icons';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import WidgetWithAction from './components/WidgetWithAction';

// interne

const FacturesInternesNonAttribuees: React.FunctionComponent = function () {
    const navigate = useNavigate();

    const [countOfUnassignedUnregisteredInvoices, setCountOfUnassignedUnregisteredInvoices] =
        useState<string>();

    useEffect(() => {
        mediaObjectApi
            .listMediaObjectMediaObjectCollection({
                page: 1,
                itemsPerPage: 0,
                createdBy: 'service-account-api-formalite',
                forceInterne: true,
                dateDebutTraitementBefore: new Date(
                    new Date().getTime() - 2 * 60 * 1000,
                ).toISOString(),
                available: true,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setCountOfUnassignedUnregisteredInvoices(`${res.data['hydra:totalItems']}`);
                }
            });
    }, []);

    const actionCallback = useCallback(async () => {
        navigate(
            `${achatsViewPathResolver('listmediaobjectinternally')}?tab=Factures+non+attribuées`,
        );
    }, [navigate]);

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <WidgetWithAction
                title="Factures internes non attribuées"
                icon={myInvoiceIcon}
                actionMessage="Traiter"
                actionCallback={actionCallback}
                data={countOfUnassignedUnregisteredInvoices}
                paragraph="Factures int. non attribuées :"
                color="purple"
            />
        </Grid>
    );
};

export default FacturesInternesNonAttribuees;
