import { useFormaliteService } from '@europrocurement/l2d-domain';
import { DossierOpenSearchJsonldOpensearchDossierStatutRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { useState } from 'react';

const STATUS_TO_COMPLETE = 1;
const STATUS_PROCESSING = 3;
const STATUS_COMPLETED = 6;
const STATUS_SUSPENDED = 49;

export type DossierStatut = DossierOpenSearchJsonldOpensearchDossierStatutRead &
    Record<string, unknown>;

const useDossierStatut = () => {
    const { dossierOpenSearchApi } = useFormaliteService();
    const [prescriberDossierStatuses] = useState<Array<DossierStatut>>([
        { id: STATUS_TO_COMPLETE, libelle: 'À compléter' },
        { id: STATUS_PROCESSING, libelle: 'En traitement' },
        { id: STATUS_COMPLETED, libelle: 'À Finalisés' },
        { id: STATUS_SUSPENDED, libelle: 'Suspendus' },
    ]);

    let searchStatutTimeout: number | undefined;

    const searchDossierStatut = (): Promise<Array<DossierStatut>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchStatutTimeout);
            const requestParameters = {};

            searchStatutTimeout = setTimeout(() => {
                dossierOpenSearchApi
                    .apiOpensearchStatusesGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<DossierStatut>))
                    .catch(() => reject());
            }, 300);
        });

    return { prescriberDossierStatuses, searchDossierStatut };
};

export default useDossierStatut;
