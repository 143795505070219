import React, { MouseEventHandler } from 'react';
import { Box, Button } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import InformationChip from '../InformationChip';

export type BooleanChipProps = {
    label: string;
    isChecked: boolean;
    icon?: IconProp;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    isLoading?: boolean;
};

const BooleanChip: React.FunctionComponent<BooleanChipProps> = function ({
    label,
    isChecked = false,
    icon,
    onClick,
    isLoading = false,
}) {
    const buttonStyle = { marginRight: '16px', padding: '0px', opacity: isLoading ? '0.4' : '1' };
    const color = isChecked ? 'primary' : 'secondary';
    if (isLoading || !onClick || typeof onClick !== 'function') {
        return (
            <Box
                data-testid="BooleanChip-test-id"
                sx={buttonStyle}
            >
                <InformationChip
                    color={color}
                    label={label}
                    icon={icon}
                />
            </Box>
        );
    }
    return (
        <Button
            data-testid="BooleanChip-test-id"
            sx={buttonStyle}
            onClick={onClick}
        >
            <InformationChip
                color={color}
                label={label}
                icon={icon}
            />
        </Button>
    );
};

export default BooleanChip;
