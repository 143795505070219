import { OptionsType } from '@europrocurement/flexy-components';
import { FormStructure } from '@europrocurement/flexy-form';
import { User } from '../components/Utilisateur/UserTabs/type';

export type DefaultUserFormValues = {
    pole: { label: string; value: number };
    service: { label: string; value: number };
    idSociete: { label: string; value: number };
    idSocieteEmployeur: { label: string; value: number };
    marque: string;
};

export const companyOptions = [
    { value: 0, label: 'Aucune société' },
    { value: 2, label: 'Affiches Parisiennes' },
    { value: 3, label: 'Le Tout Lyon' },
    { value: 4, label: "L'Essor" },
    { value: 5, label: 'Les Publications Commerciales' },
    { value: 6, label: 'Le Patriote Beaujolais' },
    { value: 8, label: 'Claude et Goy' },
    { value: 9, label: 'Europrocurement' },
    { value: 10, label: 'Agence Juridique' },
    { value: 11, label: 'Cabinet AGA' },
    { value: 12, label: 'Legal2Digital' },
    { value: 13, label: 'Flaminem Factory' },
];

export const rightsOptions: OptionsType = [
    {
        label: 'Aucun droit',
        value: 0,
    },
    {
        label: 'Dossiers du formaliste uniquement',
        value: 1,
    },
    {
        label: 'Tout',
        value: 2,
    },
];

export const gestionRightsOptions: OptionsType = [
    {
        label: 'Aucun droit',
        value: 0,
    },
    {
        label: 'Gestion des documents et formalités uniquement',
        value: 1,
    },
    {
        label: 'Tout',
        value: 2,
    },
];

export const userDefaultValues: DefaultUserFormValues | User = {
    mail: null,
    pole: { label: 'Pas spécifié', value: 0 },
    service: { value: 0, label: 'Pas spécifié' },
    idSociete: { label: 'Aucune société', value: 0 },
    idSocieteEmployeur: { value: 0, label: 'Aucune société' },
    idAntenne: 1,
    marque: '/utilisateur/marques/1',
    formalistAdmin: 0,
    formalistDemo: false,
    hasAnnuaire: true,
    isAdminApps: false,
    isEleveur: false,
    isFormalist: false,
    hasG3Access: false,
    isG3Recetteur: false,
    hasAccessL2DF: false,
    formalistDroitLectureDossier: 0,
    formalistDroitLectureGestion: 0,
    formalistDroitModifDossier: 0,
    formalistDroitModifGestion: 0,
    formalistDroitMultiSociete: '',
    formalistDroitSupprDossier: 0,
    idUserG3: 0,
    idUserDematfacile: 0,
    dematFacile: '',
    binome: '',
    pc: '',
    hasVpn: false,
    droitUtilisateur: {
        usdAppsAnnuaire: true,
        usdAppsAnnuaireOption: false,
        usdAppsJalgest: true,
        usdAppsJalgestOption: '',
        usdAppsMdc: false,
        usdAppsMdcOption: '',
        usdAppsMdcSpel: false,
        usdAppsMdcSpelOption: '',
        usdAppsSupport: true,
        usdAppsSupportOption: false,
        usdAppsXy: false,
        usdAppsXyOption: '',
    },
};

export const createUserFormStructure = (
    services: OptionsType,
    poles: OptionsType,
    groupes: OptionsType,
) => {
    const userFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: 'Informations utilisateur',
            name: 'userInfos',
        },
        {
            type: 'text',
            name: 'nom',
            rules: { required: true },
            fullWidth: true,
            inputlabel: 'Nom',
            placeholder: 'Nom',
            lg: 5,
            md: 5,
            sm: 12,
        },
        {
            type: 'text',
            name: 'prenom',
            rules: { required: true },
            fullWidth: true,
            inputlabel: 'Prénom',
            placeholder: 'Prénom',
            lg: 5,
            md: 5,
            sm: 12,
        },
        {
            type: 'text',
            name: 'initiales',
            disabled: true,
            fullWidth: true,
            inputlabel: 'Initiales',
            placeholder: 'Initiales auto',
            lg: 2,
            md: 2,
            sm: 2,
        },
        {
            type: 'text',
            name: 'user',
            fullWidth: true,
            inputlabel: 'Login',
            placeholder: 'Login',
            rules: {
                required: true,
            },
            lg: 4,
            md: 4,
            sm: 12,
        },
        {
            type: 'email',
            name: 'mail',
            fullWidth: true,
            inputlabel: 'Mail',
            placeholder: 'Mail',
            lg: 6,
            md: 6,
            sm: 12,
            rules: {
                required: true,
            },
        },
        {
            type: 'text',
            name: 'poste',
            fullWidth: true,
            inputlabel: 'Poste interne',
            placeholder: 'N° Poste',
            rules: {
                maxLength: {
                    value: 5,
                    message: 'Le poste ne doit pas excéder 5 caractères.',
                },
            },
            lg: 2,
            md: 2,
            sm: 12,
        },
        {
            type: 'text',
            name: 'ligneDirecte',
            fullWidth: true,
            inputlabel: 'Ligne directe',
            placeholder: 'Ligne directe',
            lg: 3,
            md: 3,
            sm: 12,
        },
        {
            type: 'text',
            name: 'portable',
            fullWidth: true,
            inputlabel: 'Portable',
            placeholder: 'Portable',
            lg: 3,
            md: 3,
            sm: 12,
        },
        {
            type: 'text',
            name: 'fonction',
            fullWidth: true,
            inputlabel: 'Fonction',
            placeholder: 'Fonction',
            lg: 6,
            md: 6,
            sm: 12,
        },
        // Sera remplacé par le select Antenne par la suite
        // {
        //     type: 'number',
        //     name: 'idAntenne',
        //     fullWidth: true,
        //     inputlabel: 'Id Antenne',
        //     placeholder: 'Id Antenne',
        //     lg: 4,
        //     md: 4,
        //     sm: 12,
        // },
        {
            type: 'select',
            options: poles,
            name: 'pole',
            fullWidth: true,
            inputlabel: 'Pole',
            lg: 6,
            md: 6,
            sm: 12,
        },
        {
            type: 'select',
            options: services,
            name: 'service',
            fullWidth: true,
            inputlabel: 'Service',
            lg: 6,
            md: 6,
            sm: 12,
        },
        {
            type: 'select',
            options: groupes,
            name: 'userGroupe',
            fullWidth: true,
            inputlabel: 'Groupe GIE',
            placeholder: 'Sélectionner le groupe',
            rules: {
                required: true,
            },
            lg: 4,
            md: 4,
            sm: 12,
        },
        {
            type: 'select',
            options: companyOptions,
            name: 'idSociete',
            fullWidth: true,
            inputlabel: 'Société',
            lg: 4,
            md: 4,
            sm: 12,
        },
        {
            type: 'select',
            options: companyOptions,
            name: 'idSocieteEmployeur',
            fullWidth: true,
            inputlabel: 'Société Employeur',
            lg: 4,
            md: 4,
            sm: 12,
        },
    ];
    return userFormStructure;
};
