import {
    SliceFactory,
    FetchCollectionData,
    ApiCollectionResponse,
    FetchItemDataType,
} from '@europrocurement/l2d-redux-utils';
import {
    FormulaireFormalite as FormulaireFormaliteRead,
    ConfigurationParameters as FormulaireConfigurationParameters,
    Configuration,
    FormulaireFormaliteApi,
    FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest,
    FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest,
} from '@europrocurement/l2d-domain/openApi/ApiFormulaire';
import { FORMULAIRE_FORMALITE_SLICE_NAME, FORMULAIRE_REDUCER_NAME } from '../constants';

export type FormulaireFormaliteApiObject = FormulaireFormaliteRead;

export type FormulaireFormalite = FormulaireFormaliteApiObject;

export const mapperFormulaireFormalite = function (formulaire: FormulaireFormalite) {
    return {
        ...formulaire,
    } as FormulaireFormalite;
};

export function createFormulairesFormaliteSlice(configuration: FormulaireConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FormulaireFormaliteApi(conf);

    const fetchFormulaireFormaliteCollection: FetchCollectionData<FormulaireFormalite> = () => {
        const options = undefined;

        const requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest =
            {
                itemsPerPage: 1000,
            };

        return api
            .apiFormulaireFormalitesGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<FormulaireFormalite>>;
    };

    const fetchFormulaireFormaliteItem: FetchItemDataType<FormulaireFormalite> = ({ idItem }) => {
        const requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest = {
            id: String(idItem),
        };

        return api
            .apiFormulaireFormalitesIdGet(requestParameters)
            .then((res) => res.data) as Promise<FormulaireFormalite>;
    };

    const {
        slice: formulaireFormaliteSlice,
        dataSourcesThunks: formulaireFormaliteDataSourcesThunks,
    } = SliceFactory.createSlice<FormulaireFormalite, FormulaireFormalite>(
        FORMULAIRE_FORMALITE_SLICE_NAME,
        FORMULAIRE_REDUCER_NAME,
        [],
        fetchFormulaireFormaliteCollection,
        fetchFormulaireFormaliteItem,
        mapperFormulaireFormalite,
        {},
    );

    return { formulaireFormaliteSlice, formulaireFormaliteDataSourcesThunks };
}
