import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
    DossierStep,
    dossierStepsSelector,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import DossierStepContext from './DossierStepContext';

const DossierStepProvider: React.FunctionComponent<PropsWithChildren> = function ({ children }) {
    const [steps, setSteps] = useState<Array<DossierStep>>([]);
    const contextValue = useMemo(() => ({ steps, setSteps }), [steps, setSteps]);

    const dossierStepsDataSource: DataSource<DossierStep> = useSelector(dossierStepsSelector);
    const { getDossierSteps } = useFormaliteService();
    const dispatch = useDispatch();

    useEffect(() => {
        if (dossierStepsDataSource.status === 'idle') {
            dispatch(getDossierSteps({}) as unknown as AnyAction);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dossierStepsDataSource.status === 'succeeded') {
            setSteps(dossierStepsDataSource.data);
        }
    }, [dossierStepsDataSource]);

    return (
        <DossierStepContext.Provider value={contextValue}>{children}</DossierStepContext.Provider>
    );
};

export default DossierStepProvider;
