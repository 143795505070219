import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import {
    ColumnDatatable,
    FiltersDatatableList,
    FlexyDatatableMaxHeight,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { DossierFormalite, useFormaliteService } from '@europrocurement/l2d-domain';
import React, { MouseEvent } from 'react';

import { useModal } from '@europrocurement/flexy-components';
import { RootStateType } from '../../../../redux/types';
import { Nullable } from '../../constants/types';

export type SearchDossierFormaliteModalContentProps = {
    onCloseModal?: (dossier: Nullable<DossierFormalite>) => void;
};

const SearchDossierFormaliteModalContent: React.FunctionComponent<SearchDossierFormaliteModalContentProps> =
    function ({ onCloseModal }) {
        const { getDossierFormalite } = useFormaliteService();

        const dossiersFormaliteDatasource = useSelector(
            (s: RootStateType) => s.formalites.dosform.main,
        );

        const { modalActions } = useModal();

        const columns: ColumnDatatable<DossierFormalite>[] = [
            {
                label: 'Numéro Dossier Formalite',
                render: 'numero',
                isDisplayed: true,
            },
            {
                label: 'Dénomination société',
                render: 'societeDenomination',
                isDisplayed: true,
            },
            {
                label: 'Numéro DematFacile',
                render: 'idDematfacile',
                isDisplayed: true,
            },
            {
                label: 'Actions',
                onClickCell: () => {},
                render: () => null,
                isDisplayed: true,
            },
        ];

        const filters: FiltersDatatableList = [
            {
                field: 'societeDenomination',
                label: 'Dénomination société',
                type: 'text',
            },
            {
                field: 'idDematfacile',
                label: 'Numéro DematFacile',
                type: 'number',
            },
        ];

        return (
            <Box
                sx={{
                    height: FlexyDatatableMaxHeight,
                }}
            >
                <StoreDatatable
                    dataSource={dossiersFormaliteDatasource}
                    columns={columns}
                    fetchData={getDossierFormalite}
                    filters={filters}
                    mode="raw"
                    fetchWithoutFilter={false}
                    onClickRow={(e: MouseEvent, dossier: DossierFormalite) => {
                        if (onCloseModal)
                            modalActions.close(() => {
                                onCloseModal(dossier);
                            });
                    }}
                />
            </Box>
        );
    };

export default SearchDossierFormaliteModalContent;
