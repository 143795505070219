/* eslint-disable @typescript-eslint/no-explicit-any */
import useAsyncStatus from '@b2d/hooks/useAsyncStatus';
import { DataCard } from '@europrocurement/flexy-components';
import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';
import { Button, type Palette } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useHandleError } from '@europrocurement/l2d-hooks';
import CentralDataCardStat from './CentralDataCardStat';

type WidgetWithActionProps = {
    title: string;
    icon: NamedIconsType;
    actionMessage: string;
    actionCallback: () => Promise<void>;
    data?: string;
    paragraph?: string;
    color?: keyof Palette;
    loadingMessage?: string;
    beforeActionCallback?: () => void;
    onActionSuccessCallback?: () => void;
    onActionFailureCallback?: () => void;
};

const WidgetWithAction: React.FunctionComponent<WidgetWithActionProps> = function ({
    title,
    icon,
    actionMessage,
    actionCallback,
    data = undefined,
    paragraph = undefined,
    color = 'primary',
    loadingMessage = 'Chargement de la page...',
    beforeActionCallback = () => {},
    onActionSuccessCallback = () => {},
    onActionFailureCallback = () => {},
}: WidgetWithActionProps) {
    const { status, startLoading, setSuccess, setError } = useAsyncStatus();
    const { handleFailure } = useHandleError();

    const requestLists = useCallback(async () => {
        startLoading();
        await actionCallback();
    }, [actionCallback, startLoading]);

    const widgetContent = useMemo(() => {
        if (status.loading) {
            return (
                <CentralDataCardStat
                    paragraphDetail={loadingMessage}
                    sx={{ color: `${color}.main` }}
                />
            );
        }

        return (
            <CentralDataCardStat
                data={data}
                paragraphDetail={paragraph}
                sx={{ color: `${color}.main` }}
            />
        );
    }, [color, data, loadingMessage, paragraph, status.loading]);

    return (
        <DataCard
            title={title}
            color={color}
            iconBgColor="transparent"
            iconColor="transparent"
            icon={
                <FaOptionIcon
                    {...icon.props}
                    size="2x"
                    color={`${color}.main`}
                    optionColor={`${color}.dark`}
                    optionProps={{
                        ...icon.props.optionProps,
                    }}
                    iconProps={{
                        radius: 100,
                        ...icon.props.iconProps,
                    }}
                />
            }
            tools={
                <Button
                    color={color as any}
                    variant="outlined"
                    disabled={status.loading}
                    onClick={() => {
                        beforeActionCallback();

                        setTimeout(() => {
                            requestLists()
                                .then(() => {
                                    onActionSuccessCallback();

                                    setSuccess();
                                })
                                .catch((error: unknown) => {
                                    onActionFailureCallback();

                                    handleFailure({
                                        error,
                                        setError,
                                    });
                                });
                        }, 250);
                    }}
                >
                    {actionMessage}
                </Button>
            }
        >
            {widgetContent}
        </DataCard>
    );
};

export default WidgetWithAction;
