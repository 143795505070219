import React, { Fragment } from 'react';
import { Avatar, Box, useMediaQuery } from '@mui/material';
import { BorderedBox, CustomDivider, Information } from '@europrocurement/flexy-components';

import ApartmentIcon from '@mui/icons-material/Apartment';
// import ExistingPublishersInformation from '@b2d/pages/Prescripteurs/component/ExistingPublishersInformation';
import { usePublisherUtils } from '@b2d/hooks/publisherHooks';
import { Theme, useTheme } from '@mui/system';

const CompanyInformationsCard: React.FunctionComponent = function () {
    const { publisher } = usePublisherUtils();

    const { siret, siren, tvaIntracommunautaire } = publisher;

    const theme: Theme = useTheme();

    const lgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    const informations = [
        {
            label: 'SIRET',
            value: siret,
        },
        {
            label: 'SIREN',
            value: siren,
        },
        {
            label: 'TVA Intracommunautaire',
            value: tvaIntracommunautaire,
        },
    ];

    const avatarSize = 100;

    return (
        <BorderedBox
            boxTitle="Informations société"
            actionBarProps={{}}
            borderWidth={3}
        >
            <Box display="flex">
                <Box width={`${lgBreakpoint ? '66%' : '100%'}`}>
                    <CustomDivider />
                    {informations.map(({ label, value }) => (
                        <Fragment key={label}>
                            <Information
                                label={label}
                                value={value as string | undefined}
                                sx={{ marginBottom: '10px' }}
                            />
                            <CustomDivider />
                        </Fragment>
                    ))}
                </Box>
                <Box
                    width="30%"
                    display={`${lgBreakpoint ? 'flex' : 'none'}`}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ marginLeft: '22px' }}
                >
                    <Avatar
                        sx={{
                            bgcolor: 'primary.main',
                            width: avatarSize,
                            height: avatarSize,
                            marginBottom: '15px',
                        }}
                    >
                        <ApartmentIcon fontSize="large" />
                    </Avatar>
                </Box>
            </Box>
        </BorderedBox>
    );
};

export default CompanyInformationsCard;
