import { useContext } from 'react';
import {
    AnnonceLegaleApi,
    AnnoncesLegalesApi,
} from '@europrocurement/l2d-domain/openApi/ApiAnnoncesLegales';
import L2DApiContext from '../L2DApiContext';

export const useAnnoncesLegalesService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.annoncesLegales) {
        throw new Error('AnnoncesLegales service is not registred in L2DApiProvider');
    }
    const { apis } = context.annoncesLegales;

    return {
        legalAdvertApi: apis.legalAdvertApi as AnnonceLegaleApi,
        legalsAdvertApi: apis.legalsAdvertApi as AnnoncesLegalesApi,
    };
};
