/**
 * Create the message for the validation with the params given.
 * If no information passed, return undefined.
 * The default modal message will be "Êtes-vous sûr de valider cette action ?"
 * @param bool the toggle boolean value
 * @param field string : the keyof the toggle (key of User)
 * @returns string message for the modal
 */
export const getValidationMessage = (bool?: boolean, field?: string) => {
    let message;

    if (field !== undefined && bool !== undefined) {
        const status = bool ? 'retirer' : 'donner';
        const activate = bool ? 'activer' : 'désactiver';

        switch (field) {
            case 'isAdminApps':
                message = `Êtes-vous sûr de vouloir ${status} le droit Adminitrateur sur Apps ?`;
                break;
            case 'hasG3Access':
                message = `Êtes-vous sûr de vouloir ${status} l'accès à l'application G3 ?`;
                break;
            case 'hasAccessL2DF':
                message = `Êtes-vous sûr de vouloir ${status} l'accès à l'application L2D Formalités ?`;
                break;
            case 'usdAppsAnnuaire':
                message = `Êtes-vous sûr de vouloir ${status} le droit de lecture de l'annuaire ?`;
                break;
            case 'usdAppsAnnuaireOption':
                message = `Êtes-vous sûr de vouloir ${status} le droit de modification de l'annuaire ?`;
                break;
            case 'usdAppsJalgest':
                message = `Êtes-vous sûr de vouloir ${status} l'accès à l'application JAL GEST ?`;
                break;
            case 'usdAppsXy':
                message = `Êtes-vous sûr de vouloir ${status} l'accès à l'application Pointage des annonces XY ?`;
                break;
            case 'usdAppsMdc':
                message = `Êtes-vous sûr de vouloir ${status} l'accès à l'application Composition des annonces papier ?`;
                break;
            case 'usdAppsMdcSpel':
                message = `Êtes-vous sûr de vouloir ${status} l'accès à l'application Composition des annonces SPEL ?`;
                break;
            case 'isDeleted':
                message = `Êtes-vous sûr de vouloir ${activate} le compte de l'utilisateur ?`;
                break;
            case 'isEleveur':
                message = `Êtes-vous sûr de vouloir ${status} le statut d'Eleveur ?`;
                break;
            case 'isG3Recetteur':
                message = `Êtes-vous sûr de vouloir ${status} le statut de Recetteur ?`;
                break;
            case 'isFormalist':
                message = `Êtes-vous sûr de vouloir ${status} le statut de Formaliste ?`;
                break;
            case 'formalistDemo':
                message = `Êtes-vous sûr de vouloir ${status} l'accès au Compte de Démo ?`;
                break;
            case 'usdAppsSupport':
                message = `Êtes-vous sûr de vouloir ${status} l'accès à l'application de Support en ligne ?`;
                break;
            case 'usdAppsSupportOption':
                message = `Êtes-vous sûr de vouloir ${status} les droits d'administration du Support en ligne ?`;
                break;
            default:
                break;
        }

        return message;
    }
    return message;
};
