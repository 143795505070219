import {
    FichiersDossierJsonldDocumentfileRead,
    TblTypeDocumentJsonldDocumentfileRead,
    DossierJsonldDossierRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { Button, SxProps, Typography } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';
import { errorIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import { castIriToId } from '@europrocurement/l2d-utils';
import { NoteInformation, TypedFileValidation } from '../../atoms';

export type DocIaModalTypageProps = {
    docs: Array<FichiersDossierJsonldDocumentfileRead>;
    docTypes: Array<TblTypeDocumentJsonldDocumentfileRead>;
    sx?: SxProps;
    onClose?: () => void;
    onChange: (
        doc: FichiersDossierJsonldDocumentfileRead,
        newTypeId: number,
        libelle: string,
    ) => void;
    onSubmit: () => void;
    dossier?: DossierJsonldDossierRead;
};

type LiasseType = {
    doc: FichiersDossierJsonldDocumentfileRead;
    liasse: Array<FichiersDossierJsonldDocumentfileRead>;
};

type LiassesType = {
    liasses: {
        [x: string]: LiasseType;
    };
    noLiasse: Array<FichiersDossierJsonldDocumentfileRead>;
};

const getLiasse = function (docs: Array<FichiersDossierJsonldDocumentfileRead>) {
    const liasses: LiassesType = { noLiasse: [], liasses: {} };
    docs.forEach((main) => {
        let isLiasse = false;
        docs.forEach((sub) => {
            if (sub.fichier?.endsWith(`_${main.fichier}`) && main.id) {
                isLiasse = true;
                const mainId = String(main.id);
                if (!liasses.liasses[mainId]) {
                    liasses.liasses[mainId] = {
                        doc: main,
                        liasse: [],
                    };
                }
                liasses.liasses[mainId].liasse.push(sub);

                const index = liasses.noLiasse.indexOf(sub);
                if (index !== -1) {
                    liasses.noLiasse.splice(index, 1);
                }
            }
        });
        if (!isLiasse) {
            liasses.noLiasse.push(main);
        }
    });
    return liasses;
};

const DocIaModalTypage: React.FunctionComponent<DocIaModalTypageProps> = function ({
    docs,
    docTypes,
    sx,
    onClose,
    onChange,
    onSubmit,
    dossier,
}) {
    const liasses: LiassesType = React.useMemo(() => getLiasse(docs), [docs]);

    const innerDocTypes = React.useMemo(() => {
        if (dossier) {
            let currentDossierDocsRequired: number[] = [];
            if (dossier.documents) {
                currentDossierDocsRequired = [
                    ...dossier.documents
                        .map((doc) => doc.document)
                        .filter((type) => type !== null && type !== undefined)
                        .map(castIriToId),
                ];
            }
            const currentTypesId = [
                ...docs.map((fichier) => fichier.typedocIADoc?.id).filter((id) => id !== undefined),
            ];
            return docTypes.filter(
                (docType) =>
                    [...currentDossierDocsRequired, ...currentTypesId, 66].indexOf(
                        docType.id || 66,
                    ) !== -1,
            );
        }
        return docTypes;
    }, [docTypes, docs, dossier]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                ...sx,
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="l2dh6">Vos documents transmis</Typography>
                {onClose ? (
                    <Box onClick={onClose}>
                        <FaOptionIcon
                            ariaLabel="close-modal"
                            {...errorIcon.props}
                            color="text.gris70"
                        />
                    </Box>
                ) : null}
            </Box>
            <NoteInformation
                variant="success"
                sx={{
                    padding: '8px 10px',
                }}
            >
                <Box
                    gap="5px"
                    display="flex"
                >
                    <Typography
                        variant="l2dbodys"
                        color="text.gris70"
                        fontWeight={700}
                    >
                        Vos fichiers ont bien été importés.
                    </Typography>
                    <Typography
                        variant="l2dbodys"
                        color="text.gris70"
                    >
                        Veuillez préciser le type de document.
                    </Typography>
                </Box>
            </NoteInformation>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {liasses.noLiasse.map((doc) => (
                    <TypedFileValidation
                        onChange={(value) =>
                            onChange(
                                doc,
                                value,
                                innerDocTypes.find((curr) => curr.id === value)?.libelle || '',
                            )
                        }
                        libelle={doc.fichier || ''}
                        docTypes={innerDocTypes}
                        key={doc.id || 'error'}
                        value={doc.typedocIADoc?.id || undefined}
                    />
                ))}
                {Object.values(liasses.liasses).map((liasse) => (
                    <React.Fragment key={liasse.doc.typeDocDossier?.id}>
                        <Typography variant="l2dh7">
                            Liasse détéctée
                            <Typography
                                sx={{ marginLeft: '15px' }}
                                component="span"
                                color="text.gris70"
                            >
                                {liasse.doc.fichier}
                            </Typography>
                        </Typography>
                        {liasse.liasse.map((doc) => (
                            <TypedFileValidation
                                onChange={(value) =>
                                    onChange(
                                        doc,
                                        value,
                                        innerDocTypes.find((curr) => curr.id === value)?.libelle ||
                                            '',
                                    )
                                }
                                libelle={doc.fichier || ''}
                                docTypes={innerDocTypes}
                                key={doc.id || 'error'}
                                value={doc.typedocIADoc?.id || undefined}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        backgroundColor: 'primary.dark',
                        width: '150px',
                        padding: '16px 24px 16px 24px',
                        fontSize: '1rem',
                        lineHeight: '1.5',
                        borderRadius: '15px',
                    }}
                    onClick={onSubmit}
                >
                    Importer
                </Button>
            </Box>
        </Box>
    );
};

export default DocIaModalTypage;
