import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import * as React from 'react';
import { OptionValue } from './types';

type ComponentProps = { optionLabel: string; optionValues: OptionValue[] };

const OptionRadioPreview: React.FunctionComponent<ComponentProps> = function (props) {
    const { optionValues, optionLabel = '' } = props;

    // const [selectedOptionValue, setSelectedOptionValue] = React.useState<null | OptionValue>(null);

    return (
        <RadioGroup>
            {optionValues.map((optionValue, index) => {
                const { libelle = '', description = '' } = optionValue;
                return (
                    <Tooltip
                        key={`preview_${libelle}_${optionLabel}`}
                        title={description}
                    >
                        <FormControlLabel
                            value={index}
                            control={<Radio />}
                            label={libelle}
                            // onClick={() => setSelectedOptionValue(optionValue)}
                        />
                    </Tooltip>
                );
            })}

            {/* {selectedOptionValue && <OptionValuePreview optionValue={selectedOptionValue} />} */}
        </RadioGroup>
    );
};

export default OptionRadioPreview;
