import React, { useState } from 'react';
import { FormHelperText } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FlexyFormLabel, FlexyTextField } from '@europrocurement/flexy-components';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { validationPhoneNumberRule } from '@europrocurement/l2d-modules/validation/rules/phoneNumber';

export type ControlledNumberInputProps = {
    name: string;
    rules: RegisterOptions;
    label?: string;
    placeholder?: string;
    countryCode?: string;
    defaultValue?: string;
};

const ControlledNumberInput: React.FunctionComponent<ControlledNumberInputProps> = function ({
    name,
    rules,
    label,
    placeholder,
    countryCode = 'FR',
    defaultValue = '',
}) {
    const { control } = useFormContext();
    const [currentValue, setCurrentValue] = useState<string>(defaultValue);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        onChange: (value: string) => void,
    ) => {
        const targetValue = e.target.value;
        const phoneNumber = parsePhoneNumber(targetValue, countryCode as CountryCode);

        if (phoneNumber && phoneNumber.isValid()) {
            if (targetValue.substring(0, 1) === '+') {
                setCurrentValue(phoneNumber.formatInternational());
                onChange(phoneNumber.formatInternational());
            } else {
                setCurrentValue(phoneNumber.formatNational());
                onChange(phoneNumber.formatNational());
            }
        } else {
            setCurrentValue(targetValue);
            onChange(targetValue);
        }
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={{
                ...rules,
                validate: {
                    ...(typeof rules?.validate === 'function'
                        ? { customValidate: rules.validate }
                        : rules?.validate),
                    ...validationPhoneNumberRule(countryCode as CountryCode),
                },
            }}
            render={({ field: { onChange }, fieldState: { error } }) => (
                <>
                    {label && label !== '' ? (
                        <FlexyFormLabel
                            required={!!rules.required}
                            requiredTooltip={rules.required ? 'le champ est requis' : undefined}
                        >
                            {label}
                        </FlexyFormLabel>
                    ) : null}
                    <FlexyTextField
                        variant="outlined"
                        fullWidth
                        error={error !== undefined}
                        placeholder={placeholder || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(e, onChange)
                        }
                        value={currentValue}
                        name={name}
                    />
                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledNumberInput;
