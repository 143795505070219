import { combineReducers } from '@reduxjs/toolkit';
import {
    GROUPES_SLICE_NAME,
    MARQUES_SLICE_NAME,
    POLES_SLICE_NAME,
    SERVICES_SLICE_NAME,
    UTILISATEUR_SLICE_NAME,
} from './constants';
import {
    UtilisateursApi,
    UserApi,
    PolesApi,
    DroitsApi,
    GroupesApi,
    JournauxApi,
    MarquesApi,
    ServicesApi,
    Configuration as UtilisateursConfiguration,
} from '../../openApi/ApiUtilisateur';
import { type UtilisateursConfigurationParameters } from './types';
import { createUtilisateurSlice } from './slices/utilisateurSlice';
import { createServicesSlice } from './slices/servicesSlice';
import { createPolesSlice } from './slices/polesSlice';
import { createMarquesSlice } from './slices/marquesSlice';
import { createGroupesSlice } from './slices/groupesSlice';

export function createUtilisateurReducer(configuration: UtilisateursConfigurationParameters) {
    const { utilisateurSlice, utilisateurDataSourcesThunks } =
        createUtilisateurSlice(configuration);

    return {
        utilisateurSlice,
        utilisateurDataSourcesThunks,
    };
}

export function createServicesReducer(configuration: UtilisateursConfigurationParameters) {
    const { servicesSlice, servicesDataSourcesThunks } = createServicesSlice(configuration);

    return {
        servicesSlice,
        servicesDataSourcesThunks,
    };
}

export function createPolesReducer(configuration: UtilisateursConfigurationParameters) {
    const { polesSlice, polesDataSourcesThunks } = createPolesSlice(configuration);

    return {
        polesSlice,
        polesDataSourcesThunks,
    };
}

export function createMarquesReducer(configuration: UtilisateursConfigurationParameters) {
    const { marquesSlice, marquesDataSourcesThunks } = createMarquesSlice(configuration);

    return {
        marquesSlice,
        marquesDataSourcesThunks,
    };
}

export function createGroupesReducer(configuration: UtilisateursConfigurationParameters) {
    const { groupesSlice, groupesDataSourcesThunks } = createGroupesSlice(configuration);

    return {
        groupesSlice,
        groupesDataSourcesThunks,
    };
}

export function buildUtilisateurRedux(configuration: UtilisateursConfigurationParameters) {
    const utilisateursConfiguration = new UtilisateursConfiguration(configuration);

    const utilisateursApi = new UtilisateursApi(utilisateursConfiguration);

    const userKeycloakApi = new UserApi(utilisateursConfiguration);
    const polesApi = new PolesApi(utilisateursConfiguration);
    const droitsApi = new DroitsApi(utilisateursConfiguration);
    const groupesApi = new GroupesApi(utilisateursConfiguration);
    const journauxApi = new JournauxApi(utilisateursConfiguration);
    const marquesApi = new MarquesApi(utilisateursConfiguration);
    const servicesApi = new ServicesApi(utilisateursConfiguration);

    const { utilisateurSlice, utilisateurDataSourcesThunks } =
        createUtilisateurReducer(configuration);
    const { servicesSlice, servicesDataSourcesThunks } = createServicesReducer(configuration);
    const { polesSlice, polesDataSourcesThunks } = createPolesReducer(configuration);
    const { marquesSlice, marquesDataSourcesThunks } = createMarquesReducer(configuration);
    const { groupesSlice, groupesDataSourcesThunks } = createGroupesReducer(configuration);

    // Add all slices in the utilisateur reducer (access by utilisateur section in the store)
    const combinedUtilisateursReducers = combineReducers({
        [UTILISATEUR_SLICE_NAME]: utilisateurSlice.reducer,
        [SERVICES_SLICE_NAME]: servicesSlice.reducer,
        [POLES_SLICE_NAME]: polesSlice.reducer,
        [MARQUES_SLICE_NAME]: marquesSlice.reducer,
        [GROUPES_SLICE_NAME]: groupesSlice.reducer,
    });

    return {
        serviceConfigurationParameters: configuration,
        serviceConfiguration: utilisateursConfiguration,

        reducer: combinedUtilisateursReducers,

        apis: {
            utilisateursApi,
            userKeycloakApi,
            polesApi,
            droitsApi,
            groupesApi,
            journauxApi,
            marquesApi,
            servicesApi,
        },
        slices: {
            utilisateurSlice,
            servicesSlice,
            polesSlice,
            marquesSlice,
            groupesSlice,
        },
        thunks: {
            utilisateurDataSourcesThunks,
            servicesDataSourcesThunks,
            polesDataSourcesThunks,
            marquesDataSourcesThunks,
            groupesDataSourcesThunks,
        },

        fetchItems: {
            getUtilisateurs: utilisateurDataSourcesThunks.main.getData,
            getServices: servicesDataSourcesThunks.main.getData,
            getPoles: polesDataSourcesThunks.main.getData,
            getMarques: marquesDataSourcesThunks.main.getData,
            getGroupes: groupesDataSourcesThunks.main.getData,
        },

        getItems: {
            selectUtilisateurs: utilisateurDataSourcesThunks.main.getSelected,
        },

        models: {},
    };
}
