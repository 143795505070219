import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { TblTypeDocumentJsonldTypedocumentRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useDispatch } from 'react-redux';
import { addItem, emptyDocument, warningIcon } from '@europrocurement/l2d-icons';
import { useOffreService } from '@europrocurement/l2d-domain';
import ListingWithPreview from '../components/ListingWithPreview';
import LabeledIcon from '../components/LabeledIcon';
import ModalFormTitle from '../components/form/ModalFormTitle';
import { getStringColumn } from '../listings';
import Form from './Form';
import { documentStatusOptions } from './options';

const Panel: React.FunctionComponent = function () {
    const { modalActions } = React.useContext(ModalContext);

    const dispatch = useDispatch();

    const { documentModel: model } = useOffreService();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reloadList = () => dispatch(model.datasourcesThunks.main.getData({}) as any);

    const listingButton = {
        label: 'Créer un document',
        icon: addItem,
        action: () =>
            modalActions.call(
                <>
                    <ModalFormTitle
                        icon={model.icon}
                        title="Création d'un document"
                    />
                    <Form
                        isCreate
                        afterSubmit={reloadList}
                    />
                </>,
            ),
    };

    const getStatus = (statusId: number | null | undefined) => {
        const emptyStatus = {
            label: 'Aucun statut',
            icon: warningIcon,
        };
        if (!statusId) {
            return emptyStatus;
        }
        const status = documentStatusOptions.find((option) => option.value === statusId);
        if (!status) {
            return emptyStatus;
        }

        return status;
    };

    const columns: ColumnDatatable<TblTypeDocumentJsonldTypedocumentRead>[] = [
        getStringColumn('libelle'),
        {
            label: 'Type',
            displayLabel: true,
            render: () => <LabeledIcon icon={emptyDocument} />,
            isDisplayed: true,
            sortable: false,
        },
        {
            label: 'Statut',
            displayLabel: true,
            render: (item) => (
                <LabeledIcon
                    icon={getStatus(item.statut).icon}
                    label={`${getStatus(item.statut).label}`}
                />
            ),
            isDisplayed: true,
            sortable: false,
        },
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des documents"
            listingButton={listingButton}
            listingColumns={columns}
            form={Form}
            model={model}
        />
    );
};

export default Panel;
