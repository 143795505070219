import React, { useCallback, useEffect, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { customizerSelector, factureAchatApi, getFactureAchat } from '@b2d/redux/RootStore';
import { FACTURES_ACHATS_SLICE_NAME } from '@europrocurement/l2d-domain';
import { toSyncInvoiceIcon } from '@europrocurement/l2d-icons';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useLazyDispatch from '@b2d/hooks/useLazyDispatch';
import { AppDispatch } from '@b2d/redux/types';
import WidgetWithAction from './components/WidgetWithAction';

// interne

const FacturesASynchroniser: React.FunctionComponent = function () {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { xIdSociete } = useSelector(customizerSelector);

    const lazyDispatch = useLazyDispatch({
        sliceName: FACTURES_ACHATS_SLICE_NAME,
        dataSourceName: 'main',
    });

    const [countOfInvoicesToSynchronise, setCountOfInvoicesToSynchronise] = useState<string>();

    useEffect(() => {
        factureAchatApi
            .getFacturesAchatsFactureAchatCollection({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: true,
                syncG3: false,
                deleted: false,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setCountOfInvoicesToSynchronise(`${res.data['hydra:totalItems']}`);
                }
            });
    }, [xIdSociete]);

    const clearPreviousFilters = useCallback(() => {
        lazyDispatch({
            target: 'Filter',
            action: 'reset',
        })();
    }, [lazyDispatch]);

    const applyFilters = useCallback(() => {
        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload: {
                key: 'syncG3',
                value: false,
            },
        })();
    }, [lazyDispatch]);

    const beforeActionCallback = useCallback(() => {
        clearPreviousFilters();
        applyFilters();
    }, [applyFilters, clearPreviousFilters]);

    const actionCallback = useCallback(async () => {
        await dispatch(getFactureAchat({}));
    }, [dispatch]);

    const onActionSuccessCallback = useCallback(() => {
        navigate(achatsViewPathResolver('listfacture'));
    }, [navigate]);

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <WidgetWithAction
                title="Factures à synchroniser"
                icon={toSyncInvoiceIcon}
                actionMessage="Consulter"
                actionCallback={actionCallback}
                data={countOfInvoicesToSynchronise}
                paragraph="Factures à synchroniser avec G3 :"
                color="primary"
                loadingMessage="Application des filtres..."
                beforeActionCallback={beforeActionCallback}
                onActionSuccessCallback={onActionSuccessCallback}
            />
        </Grid>
    );
};

export default FacturesASynchroniser;
