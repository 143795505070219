// React imports
import React, { memo, ReactNode, useEffect } from 'react';

// Components imports
import { Avatar, Box, Typography } from '@mui/material';
import { CenterCircularProgress, LazyBreadcrumb } from '@europrocurement/flexy-components';

// Data fetching / utils / custom hooks
// import { useCurrentPublisher, useSelectPublisher } from '@b2d/hooks/publisherHooks';

import { useParams } from 'react-router';
import { useCurrentPublisher, useSelectPublisher } from '@b2d/hooks/publisherHooks';
import { useSociete } from '@europrocurement/l2d-domain';

import PublisherInformationsCard from './PublisherInformationsCard';

export type ModalFunctions = {
    toggleModal: () => void;
    closeModal: () => void;
    openModal: () => void;
    setModalContent: (element: ReactNode | null) => void;
};

const PublisherDetail: React.FunctionComponent = function () {
    const { idPublisher: urlPublisherId } = useParams();

    const publisher = useCurrentPublisher();
    const companyId = useSociete();
    const selectPublisher = useSelectPublisher();

    const isLoading = !publisher;

    useEffect(() => {
        const { signal } = new AbortController();
        if (urlPublisherId) {
            selectPublisher(Number(urlPublisherId), {
                signal,
            });
        }
    }, [selectPublisher, urlPublisherId]);

    if (isLoading) {
        return <CenterCircularProgress sx={{ height: '70vh' }} />;
    }

    const formatTitle = (): string => {
        const { libelle: socialReason, societe: companyRelation } = publisher;

        const relation = companyRelation?.id === companyId ? companyRelation : undefined;

        const customerCode = relation?.raisonSociale;
        const separator = ' - ';

        return customerCode
            ? `${socialReason}${separator}${customerCode}`
            : `${socialReason ?? 'Non défini'}`;
    };
    const title = formatTitle();

    return (
        <Box
            sx={{
                overflow: 'hidden',
            }}
        >
            <LazyBreadcrumb
                path={[
                    { path: 'Fournisseurs', link: '/fournisseurs/liste' },
                    "Details d'un fournisseur",
                ]}
            />

            <Box display="flex">
                <Typography variant="h1">{title}</Typography>
                {publisher.rsfInclus && (
                    <Avatar sx={{ bgcolor: 'red', width: 50, height: 50, margin: '0px 15px' }}>
                        <Typography fontSize="50">RSF Inclus</Typography>
                    </Avatar>
                )}
            </Box>

            <PublisherInformationsCard />
        </Box>
    );
};

export default memo(PublisherDetail);
