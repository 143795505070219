import { MessagesTypes } from '@b2d/pages/Achats/components/modals/ConfirmationModal';

export const defaultModalMessages: MessagesTypes = {
    question: 'Êtes-vous sûr de vouloir effectuer cette action ?',
    validation: 'Oui',
    annulation: 'Non',
};

export const multiAnnonceModalMessages: MessagesTypes = {
    question: 'Êtes-vous sûr de vouloir passer en mode multi-annonces ?',
    detail: {
        message: 'Le formulaire sera réinitialisé !',
        severity: 'warning',
    },
    validation: 'Oui, passer en multi-annonces',
    annulation: 'Non, rester en annonce unique',
};

export const invoiceStatementModalMessages: MessagesTypes = {
    question: 'Êtes-vous sûr de vouloir passer en mode relevé de factures ?',
    detail: {
        message: 'Le formulaire sera réinitialisé !',
        severity: 'warning',
    },
    validation: 'Oui, passer en relevé de factures',
    annulation: 'Non, rester en annonce unique',
};

export const removeProductsModalMessages: MessagesTypes = {
    question: "Au moins un produit n'est pas entièrement rempli",
    detail: {
        message: 'Voulez-vous supprimer ce(s) produit(s) avant de finaliser cette saisie ?',
        severity: 'info',
    },
    annulation: 'Non, revenir au formulaire',
    validation: 'Oui, supprimer et clôturer',
    alternative: 'Oui, supprimer et passer à la saisie suivante',
};

export const updateTotauxUnblockedModalMessages: MessagesTypes = {
    question: 'Êtes-vous sûr de vouloir bloquer les totaux après votre saisie ?',
    detail: {
        message:
            'Vous venez de bloquer à nouveaux les totaux après modification manuelle. En continuant, vos précédentes modifications seront écrasées par la mise à jour automatique. Calculer automatiquement les totaux ?',
        severity: 'warning',
    },
    validation: 'Annuler et conserver mes modifications',
    annulation: 'Continuer et mettre à jour',
};

export const saisieDossierCommandeModalMessages: MessagesTypes = {
    question: 'Dossier de commande remonté',
    detail: {
        message:
            'Ce type de dossier doit être traité en interne. Voulez-vous rechercher un autre dossier ou passer à la facture suivante ?',
        severity: 'warning',
    },
    validation: 'Annuler la saisie et passer à la facture suivante',
    annulation: 'Continuer la saisie et changer de dossier',
};

export const saisieDossierFormaliteModalMessages: MessagesTypes = {
    question: 'Dossier de formalité remonté',
    detail: {
        message:
            'Ce type de dossier doit être traité en interne. Voulez-vous rechercher un autre dossier ou passer à la facture suivante ?',
        severity: 'warning',
    },
    validation: 'Annuler la saisie et passer à la facture suivante',
    annulation: 'Continuer la saisie et changer de dossier',
};

export const confirmationSaisieFactureModalMessages: MessagesTypes = {
    question: 'Êtes-vous certains de vouloir enregistrer cette facture ?',
    validation: "Confirmer l'enregistrement de la saisie",
    annulation: "Annuler l'enregistrement de la saisie",
};

export const confirmationEditFactureModalMessages: MessagesTypes = {
    question: 'Êtes-vous certains de vouloir modifier cette facture ?',
    validation: "Confirmer l'enregistrement de la modification",
    annulation: "Annuler l'enregistrement de la modification",
};

export const refuseFactureAchatModalMessages: MessagesTypes = {
    question: "Êtes-vous sûr de vouloir refuser cette facture d'achat ?",
    detail: {
        message: 'La facture ne sera pas clôturée et vous retournerez à la liste des PDFs.',
        severity: 'warning',
    },
    validation: 'Oui, refuser la facture',
    annulation: 'Non, rester sur le récapitulatif',
};

export const repriseMultiAnnonceModalMessages: MessagesTypes = {
    question: 'Facture non clôturée',
    detail: {
        message:
            "Cette facture est de type multi annonces, et n'a pas été clôturée, voulez vous la reprendre ?",
        severity: 'info',
    },
    validation: 'Oui, Reprendre la saisie',
    annulation: 'Non',
};

export const cancelAddDossier: MessagesTypes = {
    question: 'Formulaire incomplet',
    detail: {
        message:
            'Le formulaire est incomplet, voulez vous annuler la saisie de ce dossier et passer au récapitulatif ?',
        severity: 'info',
    },
    validation: 'Passer au récapitulatif',
    annulation: 'Continuer la saisie',
};

export const pauseSaisieFacture: MessagesTypes = {
    question:
        'Le formulaire est incomplet ou invalide, êtes-vous sûr de vouloir mettre en pause la saisie ?',
    detail: {
        message:
            'Vos modifications ne seront pas enregistrées, tant que la formulaire ne sera pas valide.',
        severity: 'warning',
    },
    validation: 'Oui, annuler les modifications de ce dossier',
    annulation: 'Non, continuer la saisie',
};

export const inUseFactureModalMessages: MessagesTypes = {
    question:
        'Cette facture est déjà en cours de traitement ! Souhaitez-vous y accéder malgré tout ?',
    validation: 'Oui, y accéder',
    annulation: 'Non, rester sur la liste',
};

export const removeNoteModalMessages: MessagesTypes = {
    question: 'Souhaitez-vous définitivement supprimer cette note ?',
    validation: 'Oui, supprimer la note',
    annulation: 'Non, conserver la note',
};

export const removeMediaObjectModalMessages: MessagesTypes = {
    question: 'Souhaitez-vous définitivement supprimer ce PDF ?',
    validation: 'Oui, supprimer le PDF',
    annulation: 'Non, conserver le PDF',
};

export const removeReminderMailModalMessages: MessagesTypes = {
    question: 'Souhaitez-vous définitivement supprimer ce mail de rappel ?',
    validation: 'Oui, supprimer ce mail',
    annulation: 'Non, conserver ce mail',
};

export const sendReminderMailsModalMessages = {
    question: 'Souhaitez-vous envoyer les mails aux fournisseurs ?',
    validation: (count: number) => `Oui, envoyer les ${count} mails`,
    annulation: "Non, annuler l'envoi",
};

export const swapSocieteMediaObjectModalMessages: MessagesTypes = {
    question: 'Souhaitez-vous basculer ce PDF de société?',
    validation: 'Oui, basculer',
    annulation: 'Non, conserver',
};

export const removeFactureModalMessages: MessagesTypes = {
    question: 'Souhaitez-vous également supprimer le PDF lié à cette facture ?',
    detail: {
        message:
            "Si le PDF n'est pas supprimé, il sera de nouveau disponible dans la liste des documents à traiter.",
        severity: 'info',
    },
    validation: 'Oui, aussi supprimer le PDF',
    alternative: 'Non, conserver le PDF',
};
