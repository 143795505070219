import React from 'react';
import { CustomItemRenderFieldProps, ExtandedFormContext } from '@europrocurement/flexy-form';
import QuoteDetail from './QuoteDetail';
import SellDetail from './SellDetail';
import useFinderMVSell from '../../hooks/useFinderMVSell';

type AdditionalDetailProps = {
    formContext: ExtandedFormContext;
    props: CustomItemRenderFieldProps;
};

const AdditionalDetail: React.FunctionComponent<AdditionalDetailProps> = function (
    props: AdditionalDetailProps,
) {
    const sellDetail = <SellDetail {...props} />;
    const quoteDetail = <QuoteDetail {...props} />;

    const { sellFinder } = useFinderMVSell();

    if (sellFinder()) {
        return sellDetail;
    }

    if (quoteDetail !== null) {
        return quoteDetail;
    }

    return null;
};

export default AdditionalDetail;
