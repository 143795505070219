import React from 'react';
import { InformationCard } from '@europrocurement/flexy-components';
import { useTheme } from '@mui/material';

export type DossierCompanyProps = {
    companyName: string;
    companyIdentifier: string;
    companyAddress: string;
};

const DossierCompany: React.FunctionComponent<DossierCompanyProps> = function (props) {
    const { companyName, companyIdentifier, companyAddress } = props;
    const { palette } = useTheme();

    const items = [
        {
            label: 'Dénomination sociale',
            value: companyName,
        },
        {
            label: 'Numéro SIREN',
            value: companyIdentifier,
        },
        {
            label: 'Adresse du client',
            value: companyAddress,
        },
    ];

    return (
        <InformationCard
            title="Société"
            subtitle={<div />}
            items={items}
            sx={{
                marginBottom: '20px',
                boxShadow: `2px 2px 6px 0px ${palette.grey[300]}`,
            }}
        />
    );
};

export default DossierCompany;
