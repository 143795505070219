import {
    useAnnoncesLegalesService,
    useGeolocService,
    useOffreService,
} from '@europrocurement/l2d-domain';

import {
    updateCachedDocuments,
    updateCachedSubTypes,
    updateCachedDepartments,
    updateCachedOrigins,
    updateCachedOffers,
    updateCachedGroups,
    updateCachedDomains,
} from '@europrocurement/l2d-domain/reducers/offre/slices/offerCacheSlice';
import { AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem } from '@europrocurement/l2d-domain/openApi/ApiAnnoncesLegales';
import { DPartementDepartementRead } from '@europrocurement/l2d-domain/openApi/ApiGeoloc';
import { createFetchActions } from './createFetchActions';
import { GroupedSelectOptions } from '../forms/types';
import useCacheData from './useCacheData';

const defaultPagination = { itemsPerPage: 1000 };

/** Offer cached data redux store managment  :
 * Set here all offer related data that's need to be fetched once at module loading (article specifics, departments options...)
 * - Hooks : generated with createFetchActions ( useFetch ... some data )
 * - storeActions : action dispatched to store result
 * - callbacks (optional) : apply format callback on result before storing result in redux tree
 */
const useOfferCache = () => {
    const { documentsApi, originApi, offersApi, groupementApi, domaineApi } = useOffreService();
    const { legalsAdvertApi } = useAnnoncesLegalesService();
    const { departmentApi } = useGeolocService();

    const fetchActions = {
        documents: () => documentsApi.apiTypesDocumentsGetCollection(defaultPagination),
        subTypes: () =>
            legalsAdvertApi.getListAnnoncesLegalesAnnonceLegaleCollection(defaultPagination),
        departments: () => departmentApi.apiDepartementsGetCollection(defaultPagination),
        origins: () => originApi.apiOriginesGetCollection(defaultPagination),
        offers: () => offersApi.apiOffresGetCollection(defaultPagination),
        groups: () => groupementApi.apiGroupementsGetCollection(defaultPagination),
        domains: () => domaineApi.apiDomainesGetCollection(defaultPagination),
    };

    const actions = createFetchActions(fetchActions);
    const {
        useDocuments,
        useSubTypes,
        useDepartments,
        useOrigins,
        useOffers,
        useGroups,
        useDomains,
    } = actions;

    /** Department option format */
    const formatDeparments = (res: DPartementDepartementRead[]) =>
        res.map(({ codgeo, libelle }) => ({
            value: codgeo,
            label: libelle,
        }));

    /** Group subtypes by categories */
    const formatSubtypes = (result: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem[]) => {
        const newItem = ({ libelle, id }: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem) => ({
            label: libelle ?? '',
            value: id,
        });
        const createCategory = (
            addedItem: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem,
            list: GroupedSelectOptions,
        ) => {
            list.push({
                label: addedItem.categorie?.libelle ?? '',
                options: [newItem(addedItem)],
            });
        };

        const addSubTypeInCategory = (
            categoryLabel: string,
            addedItem: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem,
            list: GroupedSelectOptions,
        ) => {
            const categoryIndex = list.findIndex((category) => category.label === categoryLabel);
            if (categoryIndex === -1) {
                addSubTypeInCategory('Autres', addedItem, list);
            } else {
                list[categoryIndex].options.push(newItem(addedItem));
            }
        };

        const defaultOptions = [
            {
                label: 'Autres',
                options: [],
            },
        ];

        return result.reduce((acc: GroupedSelectOptions, item) => {
            const itemCategory = item.categorie?.libelle;
            const categoryExist = acc.some(({ label }) => label === itemCategory);
            if (!itemCategory) {
                addSubTypeInCategory('Autres', item, acc);
            } else if (!categoryExist) {
                createCategory(item, acc);
            } else {
                addSubTypeInCategory(itemCategory, item, acc);
            }
            return acc;
        }, defaultOptions);
    };

    useCacheData({
        hook: useDocuments,
        action: updateCachedDocuments,
    });
    useCacheData({
        hook: useOrigins,
        action: updateCachedOrigins,
    });
    useCacheData({
        hook: useOffers,
        action: updateCachedOffers,
    });
    useCacheData({
        hook: useGroups,
        action: updateCachedGroups,
    });
    useCacheData({
        hook: useDomains,
        action: updateCachedDomains,
    });
    useCacheData({
        hook: useDepartments,
        action: updateCachedDepartments,
        callback: formatDeparments,
    });
    useCacheData({
        hook: useSubTypes,
        action: updateCachedSubTypes,
        callback: formatSubtypes,
    });
};
export default useOfferCache;
