import { AxiosRequestConfig } from 'axios';
import { CodeRejet, useSociete } from '@europrocurement/l2d-domain';
import { useSliceUtilities } from './useSliceUtilities';

export type UseRejectCodeProps = {
    dataSourceName?: string;
};

/**
 * useRejectCode
 *
 * Fournit des utilitaires pour interagir avec le slice `coderejet`.
 * Utilise `useSliceUtilities` pour centraliser les sélecteurs et actions.
 */

export const useRejectCode = ({ dataSourceName = 'main' }: UseRejectCodeProps) => {
    const {
        dataSource: rejectCodeDatasource,
        selectedItem: rejectCode,
        status,
        isLoading,
        isFailed,
        actions,
    } = useSliceUtilities({
        sliceName: 'coderejet',
        dataSourceName,
    });

    const companyId = useSociete();

    // Méthodes spécifiques à `coderejet`
    const selectRejectCode = (id: string, options?: AxiosRequestConfig, reload = false) => {
        if (reload) {
            actions.selectItem(id, options);
        } else if (!rejectCode || rejectCode.code !== id) {
            actions.selectItem(id, options);
        }
    };

    const unselectRejectCode = () => {
        actions.unselectItem();
    };

    const reloadRejectCode = (options?: AxiosRequestConfig) => {
        if (!rejectCode || !rejectCode.code) {
            console.warn(
                'ReloadRejectCode: No reject code is selected. This action requires a selected reject code.',
            );
            return;
        }
        unselectRejectCode();
        selectRejectCode(rejectCode.code, options, true);
    };

    const safeMethods = {
        selectRejectCode,
        unselectRejectCode,
        reloadRejectCode: (options?: AxiosRequestConfig) => {
            if (!rejectCode) {
                console.warn('ReloadRejectCode: No reject code is selected. Action is ignored.');
                return;
            }
            reloadRejectCode(options);
        },
    };

    return {
        rejectCode: rejectCode as CodeRejet | null,
        dataSource: rejectCodeDatasource,
        status,
        isLoading,
        isFailed,
        companyId,
        actions: safeMethods,
    };
};
