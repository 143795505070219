import React from 'react';
import { rejectCodesApi } from '@b2d/redux/RootStore';
import { RejectCodeConfigurationCard } from '@europrocurement/flexy-components';
import { Grid } from '@mui/material';
import { useRejectCodeUtils } from '@b2d/hooks/rejectCodeHooks';
import { CodeRejet, useSociete } from '@europrocurement/l2d-domain';

const ConfigurationRow: React.FunctionComponent = function () {
    const {
        methods: { reloadRejectCode },
        rejectCode,
    } = useRejectCodeUtils();

    const xIdSociete = useSociete();

    const updateRejectCodeManual = async () => {
        if (!rejectCode.code) {
            return;
        }

        await rejectCodesApi.patchCodeRejetItem({
            code: rejectCode.code,
            codeRejetCodeRejetUpdate: {
                manuel: !rejectCode.manuel,
            },
            xIdSociete,
        });

        reloadRejectCode();
    };

    const updateRejectCodeBlocked = async () => {
        if (!rejectCode.code) {
            return;
        }

        await rejectCodesApi.patchCodeRejetItem({
            code: rejectCode.code,
            codeRejetCodeRejetUpdate: {
                bloquant: !rejectCode.bloquant,
            },
            xIdSociete,
        });

        reloadRejectCode();
    };

    const updateRejectCodeAutoFollowUp = async () => {
        if (!rejectCode.code) {
            return;
        }

        await rejectCodesApi.patchCodeRejetItem({
            code: rejectCode.code,
            codeRejetCodeRejetUpdate: {
                relanceAuto: !rejectCode.relanceAuto,
            },
            xIdSociete,
        });

        reloadRejectCode();
    };

    const changeCodeRejetsConfigurationAction = (field: keyof CodeRejet) => {
        switch (field) {
            case 'manuel':
                return updateRejectCodeManual();
            case 'bloquant':
                return updateRejectCodeBlocked();
            case 'relanceAuto':
                return updateRejectCodeAutoFollowUp();
            default:
                throw new Error(`Error field to change not found ${field}`);
        }
    };

    return (
        <Grid
            item
            xs={12}
        >
            <RejectCodeConfigurationCard
                rejectCode={rejectCode}
                changeConfiguration={changeCodeRejetsConfigurationAction}
            />
        </Grid>
    );
};

export default ConfigurationRow;
