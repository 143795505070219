import {
    Box,
    Button,
    MenuItem,
    Pagination,
    PaginationItem,
    PaginationRenderItemParams,
    Select,
    SelectChangeEvent,
    Typography,
    useTheme,
} from '@mui/material';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import './FlexyDatatablePagination.css';

export type FlexyDatatablePaginationProps = {
    page: number;
    rowsPerPage: number;
    count: number;
    rowsPerPageOptions?: Array<number>;
    onRowsPerPageChange: (n: number) => void;
    onPageChange: (n: number) => void;
};

const FlexyDatatablePagination: React.FunctionComponent<FlexyDatatablePaginationProps> = function (
    props,
) {
    const {
        page,
        rowsPerPage,
        count,
        onRowsPerPageChange,
        onPageChange,
        rowsPerPageOptions = [10, 20, 50, 100],
    } = props;
    const [pageState, setPageState] = useState<number>(page);
    const [perPageState, setPerPageState] = useState<number>(rowsPerPage);
    const [totalPagesCount, setTotalPageCount] = useState<number>(Math.ceil(count / rowsPerPage));
    const theme = useTheme();
    const hoverBackground = theme.palette.primary.dark;
    const hoverColor = theme.palette.getContrastText(hoverBackground);

    useEffect(() => {
        setPerPageState(rowsPerPage);
    }, [rowsPerPage]);

    useEffect(() => {
        setPageState(page + 1);
    }, [page]);

    useEffect(() => {
        setTotalPageCount(Math.ceil(count / rowsPerPage));
    }, [count, rowsPerPage]);

    const handleRowsPerPageChange = (event: SelectChangeEvent) => {
        const n = event.target.value as unknown as number;
        setPerPageState(n);
        onRowsPerPageChange(n);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, n: number) => {
        setPageState(n);
        onPageChange(n - 1);
    };

    return (
        <Box
            data-testid="flexy-datatable-pagination"
            sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: '0 10px',
                    alignItems: 'center',
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontSize: '13px',
                        fontWeight: '500',
                        lineHeight: '20.8px',
                        textAlign: 'left',
                    }}
                >
                    Afficher
                </Typography>
                <Select
                    size="small"
                    value={perPageState as unknown as string}
                    onChange={handleRowsPerPageChange}
                    data-testid="select-rows-per-page"
                    sx={{ backgroundColor: theme.palette.background.paper }}
                >
                    {rowsPerPageOptions.map((perPageCount) => (
                        <MenuItem
                            key={perPageCount}
                            value={perPageCount}
                        >
                            {perPageCount}
                        </MenuItem>
                    ))}
                </Select>
                <Typography
                    component="span"
                    sx={{
                        fontSize: '13px',
                        fontWeight: '500',
                        lineHeight: '20.8px',
                    }}
                >
                    résultat(s) sur
                    <Typography
                        sx={{
                            fontSize: '13px',
                            lineHeight: '20.8px',
                            fontWeight: '700',
                        }}
                        component="span"
                    >
                        &nbsp;{count}
                    </Typography>
                </Typography>
            </Box>
            {totalPagesCount > 1 && (
                <Box className="flexy-datatable-pagination-pagination-list">
                    <Pagination
                        page={pageState}
                        count={totalPagesCount}
                        shape="rounded"
                        onChange={handlePageChange}
                        renderItem={(params: PaginationRenderItemParams) => {
                            const item = { ...params };
                            if (params.type === 'previous') {
                                return (
                                    <Button
                                        disabled={pageState <= 1}
                                        sx={{ color: 'inherit' }}
                                        onClick={item.onClick}
                                        data-testid="previous-page"
                                    >
                                        <FaOptionIcon icon={faArrowLeft} />
                                        Précédent
                                    </Button>
                                );
                            }

                            if (params.type === 'next') {
                                return (
                                    <Button
                                        disabled={pageState >= totalPagesCount}
                                        sx={{ color: 'inherit' }}
                                        onClick={item.onClick}
                                        data-testid="next-page"
                                    >
                                        Suivant
                                        <FaOptionIcon icon={faArrowRight} />
                                    </Button>
                                );
                            }

                            if (params.type === 'page') {
                                return (
                                    <Button
                                        sx={{
                                            backgroundColor: item.selected ? hoverBackground : '',
                                            color: item.selected ? hoverColor : 'inherit',
                                            ':hover': {
                                                backgroundColor: hoverBackground,
                                                color: hoverColor,
                                            },
                                        }}
                                        onClick={item.onClick}
                                        data-testid={`page-${item.page}`}
                                    >
                                        {item.page}
                                    </Button>
                                );
                            }

                            return (
                                <PaginationItem
                                    {...item}
                                    color="primary"
                                />
                            );
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default FlexyDatatablePagination;
