import { RootStateType } from '@b2d/redux/types';
import { FactureFormSelector } from '@b2d/redux/RootStore';
import { DevisLigne } from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import _ from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UseFormReturn } from 'react-hook-form';
import { useSyncAchatProduitsWithQuote } from '../../functions/produitFunctions';
import useAbstractFolioDataSourceSelected from './useAbstractFolioDataSourceSelected';
import { FactureFormObject } from '../../types';

type UseHandleQuoteData = {
    formContext: UseFormReturn<Partial<FactureFormObject>, unknown, undefined>;
    isQuoteFound: boolean;
};

/**
 * Handle if there is a quote or not,
 * and wrap all the needed logic to interact with quote or its lines.
 */
const useHandleQuoteData = ({ formContext, isQuoteFound }: UseHandleQuoteData) => {
    const quoteLinesDataSource: DataSource<DevisLigne> = useSelector(
        (s: RootStateType) => s.facturation.devisLigne.main,
        _.isEqual,
    );

    const folioSelected = useAbstractFolioDataSourceSelected();

    const syncAchatProduitsWithQuote = useSyncAchatProduitsWithQuote();
    // Invoice categories with all the modifiers already applied
    const invoiceCategories = useSelector(FactureFormSelector).rubFacts;

    /**
     * Add purchase lines matching the count of quote lines found.
     */
    useEffect(() => {
        // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect

        if (isQuoteFound) {
            syncAchatProduitsWithQuote(
                quoteLinesDataSource.data || undefined,
                formContext,
                invoiceCategories,
                folioSelected,
            );
        }
    }, [
        folioSelected,
        formContext,
        invoiceCategories,
        isQuoteFound,
        quoteLinesDataSource.data,
        syncAchatProduitsWithQuote,
    ]);
};

export default useHandleQuoteData;
