import { Box, Skeleton } from '@mui/material';
import React from 'react';

const TabsSkeleton: React.FunctionComponent = function () {
    const tabsCount = 3;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'start',
                width: '100%',
                gap: 1,
            }}
        >
            {Array(tabsCount)
                .fill(1)
                .map((tab, index) => {
                    const key = `tab-skeleton-${tab}-${index}`;
                    return (
                        <Skeleton
                            key={key}
                            variant="rounded"
                            height={48}
                            width={`${60 / tabsCount}%`}
                        />
                    );
                })}
        </Box>
    );
};

export default TabsSkeleton;
