import { useContext } from 'react';
import { DpartementApi } from '@europrocurement/l2d-domain/openApi/ApiGeoloc';
import L2DApiContext from '../L2DApiContext';

export const useGeolocService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.geoloc) {
        throw new Error('geoloc service is not registred in L2DApiProvider');
    }
    const { apis } = context.geoloc;

    return {
        departmentApi: apis.departmentApi as DpartementApi,
    };
};
