import * as React from 'react';

import { Box } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import AsyncEditableListItem, { EditableListItem } from './AsyncEditableItem';
import AsyncSelect, { AsyncSelectProps } from '../AsyncSelect';

export type AsyncEditableListProps = {
    defaultList?: EditableListItem[];
    formContext: UseFormReturn<FieldValues, unknown>;
    formKey: string;
    formatItem?: (item: EditableListItem) => unknown;
    resetField?: boolean;
    disabled?: boolean;
    overridableField?: {
        label: string;
        name: string;
        value: unknown;
    };
} & Omit<AsyncSelectProps, 'onSelectItem' | 'renderField'>;

const AsyncEditableList: React.FunctionComponent<AsyncEditableListProps> = function (props) {
    const {
        formContext,
        formKey,
        inputLabel,
        required,
        placeholder,
        model,
        defaultList,
        formatItem,
        resetField,
        disabled = false,
        overridableField,
    } = props;

    const [list, setList] = React.useState<EditableListItem[]>(defaultList ?? []);

    React.useEffect(() => {
        if (resetField) {
            setList(defaultList ?? []);
        }
    }, [resetField, defaultList]);

    React.useEffect(() => {
        const formItems = formatItem ? list.map((listItem) => formatItem(listItem)) : list;

        formContext.setValue(formKey, formItems);
    }, [formContext, formKey, formatItem, list]);

    const removeItem = (item: EditableListItem) => {
        setList(list.filter((listItem: EditableListItem) => listItem['@id'] !== item['@id']));
    };

    const addItem = (newItem: EditableListItem) => {
        const itemExistInList =
            list.filter((listItem: EditableListItem) => listItem['@id'] === newItem['@id']).length >
            0;
        if (itemExistInList) {
            return;
        }
        setList([...list, newItem]);
    };

    // Append overridableField if present
    const getItem = (item: EditableListItem) => ({
        ...item,
        ...(!!overridableField && { overridableField }),
    });

    const keyGenerate = (index: number) => `editable_${model}_list_${index}`;

    /** Update an item in the list by adding it a property ( key / value pair ) */
    const setNewItemProperty = (key: string, value: unknown, index: number) => {
        const updatedList = list.map((item, itemIndex) => {
            if (itemIndex !== index) {
                return item;
            }
            return {
                ...item,
                [key]: value,
            };
        });
        setList(updatedList);
    };

    return (
        <>
            <AsyncSelect
                renderField=""
                inputLabel={inputLabel}
                onSelectItem={(item: EditableListItem) => addItem(item)}
                required={required}
                placeholder={placeholder}
                model={model}
                reset={resetField}
                disabled={disabled}
            />

            <Box>
                {list.map((item: EditableListItem, index: number) => (
                    <AsyncEditableListItem
                        key={keyGenerate(index)}
                        model={model}
                        item={getItem(item)}
                        removeItem={removeItem}
                        setNewItemProperty={(key, value) => setNewItemProperty(key, value, index)}
                    />
                ))}
            </Box>
        </>
    );
};

export default AsyncEditableList;
