import { CatalogApiObject, useOffreService } from '@europrocurement/l2d-domain';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useEffect, useState } from 'react';

export type Group = {
    id: number;
    label: string;
    sortOrder: number;
    checked: boolean;
    icon: IconName | null;
};

export type Catalog = {
    id: number;
    groupId: number;
    groupLabel: string;
    groupSortOrder: number;
    label: string;
    checked: boolean;
    apiObject: CatalogApiObject;
};

const useSelectFormality = (
    prescripteurId: number,
    origine: number,
    domaine: number,
    defaultGroups: Array<number>,
    defaultCatalogs: Array<number>,
) => {
    const [groups, setGroups] = useState<Array<Group>>([]);
    const [catalogs, setCatalogs] = useState<Array<Catalog>>([]);
    const { catalogModel: model } = useOffreService();
    const { request, requestState } = useApiRequest();

    useEffect(() => {
        const query = {
            page: 1,
            itemsPerPage: 1000,
            domaine,
            prescripteur: prescripteurId,
            origine,
        };

        request(model.listByPrescripteur(query), {
            successCallback: (response) => {
                const catalogsFormatted = response.data['hydra:member']
                    .filter(
                        (catalog: CatalogApiObject) =>
                            catalog?.groupement?.id &&
                            catalog?.groupement?.libelle &&
                            catalog?.package?.id,
                    )
                    .map(
                        (catalog: CatalogApiObject): Catalog => ({
                            id: catalog.id as number,
                            groupId: catalog?.groupement?.id as number,
                            groupLabel: catalog?.groupement?.libelle as string,
                            groupSortOrder: catalog?.groupement?.rang as number,
                            label: catalog.nomCommercial as string,
                            checked: defaultCatalogs.includes(catalog.id as number),
                            apiObject: catalog,
                        }),
                    );
                const uniqueGroups: Array<number> = [];
                const groupsFormatted = response.data['hydra:member']
                    .filter(
                        (catalog: CatalogApiObject) =>
                            catalog?.groupement?.id && catalog?.groupement?.libelle,
                    )
                    .reduce((accumulator: Array<CatalogApiObject>, catalog: CatalogApiObject) => {
                        if (!catalog.groupement || uniqueGroups.includes(catalog.groupement.id)) {
                            return accumulator;
                        }

                        uniqueGroups.push(catalog.groupement.id);
                        accumulator.push(catalog);

                        return accumulator;
                    }, [])
                    .map(
                        (catalog: CatalogApiObject): Group => ({
                            id: catalog?.groupement?.id as number,
                            label: catalog?.groupement?.libelle as string,
                            sortOrder: catalog?.groupement?.rang as number,
                            icon: catalog?.groupement?.iconeName
                                ? (catalog?.groupement?.iconeName as IconName)
                                : null,
                            checked: defaultGroups.includes(catalog?.groupement?.id as number),
                        }),
                    );
                setCatalogs(catalogsFormatted);
                setGroups(groupsFormatted);
            },
            withToaster: false,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkGroup = (groupId: number) => {
        let hasChange = false;
        const tmpGroups = groups.map((group: Group) => {
            const check = group.id === groupId || group.checked;
            hasChange = hasChange || group.checked !== check;

            return {
                ...group,
                checked: check,
            };
        });
        if (hasChange) {
            setGroups(tmpGroups);
            const groupCatalogs = catalogs.filter((catalog) => catalog.groupId === groupId);

            if (groupCatalogs.length === 1) {
                const tmpCatalogs = catalogs.map((catalog) => ({
                    ...catalog,
                    checked: catalog.groupId !== groupId ? catalog.checked : true,
                }));
                setCatalogs(tmpCatalogs);
            }
        }
    };

    const uncheckGroup = (groupId: number) => {
        let hasChange = false;
        const tmpGroups = groups.map((group: Group) => {
            const uncheck = group.id === groupId || !group.checked;
            hasChange = hasChange || group.checked !== !uncheck;

            return {
                ...group,
                checked: !uncheck,
            };
        });
        if (hasChange) {
            const tmpCatalogs = catalogs.map((catalog) => ({
                ...catalog,
                checked: catalog.groupId !== groupId ? catalog.checked : false,
            }));
            setCatalogs(tmpCatalogs);
            setGroups(tmpGroups);
        }
    };

    const checkCatalog = (catalogId: number) => {
        let hasChange = false;
        const tmpCatalogs = catalogs.map((catalog: Catalog) => {
            const check = catalog.id === catalogId || catalog.checked;
            hasChange = hasChange || catalog.checked !== check;

            return {
                ...catalog,
                checked: check,
            };
        });
        if (hasChange) {
            setCatalogs(tmpCatalogs);
            const updatedCatalog = catalogs.find((catalog) => catalog.id === catalogId);

            if (updatedCatalog) {
                const { groupId } = updatedCatalog;
                checkGroup(groupId);
            }
        }
    };

    const uncheckCatalog = (catalogId: number) => {
        let hasChange = false;
        const tmpCatalogs = catalogs.map((catalog: Catalog) => {
            const uncheck = catalog.id === catalogId || !catalog.checked;
            hasChange = hasChange || catalog.checked !== !uncheck;

            return {
                ...catalog,
                checked: !uncheck,
            };
        });

        if (hasChange) {
            setCatalogs(tmpCatalogs);
            const updatedCatalog = catalogs.find((catalog) => catalog.id === catalogId);

            if (updatedCatalog) {
                const { groupId } = updatedCatalog;
                const groupsCatalogsCheckedCount = tmpCatalogs.filter(
                    (catalog) => catalog.checked && catalog.groupId === groupId,
                ).length;

                if (groupsCatalogsCheckedCount <= 0) {
                    uncheckGroup(groupId);
                }
            }
        }
    };

    const getFilteredCatalogs = (term: string) =>
        catalogs.filter((catalog) => catalog.label.toLowerCase().includes(term.toLowerCase()));

    return {
        catalogs,
        groups,
        requestState,
        getFilteredCatalogs,
        checkGroup,
        uncheckGroup,
        checkCatalog,
        uncheckCatalog,
    };
};

export default useSelectFormality;
