import { AppDispatch, RootStateType } from '@b2d/redux/types';
import { Fournisseur, useSociete, useTiersService } from '@europrocurement/l2d-domain';
import { AxiosRequestConfig } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';

/**
 * Hooks that regroup publisher actions / methods / accessors
 */

export const SelectedPublishersSelector = (s: RootStateType) => s.tiers.fournisseur.main.selected;

export const PublishersDatasourceSelector = (s: RootStateType) => s.tiers.fournisseur.main;

export const PublishersDatasourceStatusSelector = (s: RootStateType) =>
    s.tiers.fournisseur.main.status;

export const useCurrentPublisher = () => {
    const publisher: Fournisseur | null = useSelector(SelectedPublishersSelector);

    return publisher;
};

export const usePublisherDatasource = () => {
    const datasource: DataSource<Fournisseur> = useSelector(PublishersDatasourceSelector);

    return datasource;
};

export const usePublisherStatus = () => {
    const status: EuroprocApiResponseStatus = useSelector(PublishersDatasourceStatusSelector);

    return status;
};

export const useSelectPublisher = () => {
    const publisher = useCurrentPublisher();
    const publisherStatus = usePublisherStatus();
    const dispatch = useDispatch<AppDispatch>();

    const { selectFournisseur } = useTiersService();

    return (publisherId: number, options?: AxiosRequestConfig, reload: boolean = false) => {
        if (reload) {
            dispatch(selectFournisseur({ id: publisherId, options }));
        } else if ((!publisher || publisher.id !== publisherId) && publisherStatus !== 'loading') {
            dispatch(selectFournisseur({ id: publisherId, options }));
        }
    };
};

export const useUnSelectPublisher = () => {
    const dispatch = useDispatch<AppDispatch>();

    return () => {
        dispatch({ type: 'fournisseur/deletemainSelected' });
    };
};

export const useReloadPublisher = () => {
    const publisher = useCurrentPublisher();

    const unselect = useUnSelectPublisher();
    const select = useSelectPublisher();
    return (options?: AxiosRequestConfig) => {
        if (!publisher || !publisher.id) {
            console.error('cant relaod publisher, publisher is not selected');
            return;
        }
        unselect();
        select(publisher.id, options, true);
    };
};

export const getRelationSocietePublisher = (publisher: Fournisseur | null, companyId: number) => {
    if (!publisher) {
        return null;
    }

    const defaultCompany = publisher.societe ?? null;

    if (!companyId) {
        return defaultCompany;
    }

    const relatedCompany = publisher.societe?.id === companyId;

    return relatedCompany ?? defaultCompany;
};

export const useCompanyRelationCurrentPublisher = () => {
    const publisher = useCurrentPublisher();
    const companyId = useSociete();

    return getRelationSocietePublisher(publisher, companyId);
};

export const usePublisherUtils = () => {
    const publisher = useCurrentPublisher();

    if (!publisher) {
        throw new Error('missing selected publisher');
    }

    const companyId = useSociete();
    const companyRelation = useCompanyRelationCurrentPublisher();

    if (!companyRelation) {
        throw new Error('missing company relation for this publisher');
    }

    const unselectPublisher = useUnSelectPublisher();
    const selectPublisher = useSelectPublisher();
    const reloadPublisher = useReloadPublisher();

    return {
        publisher,
        companyId,
        companyRelation,
        methods: {
            unselectPublisher,
            selectPublisher,
            reloadPublisher,
        },
    };
};
