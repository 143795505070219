import React from 'react';
import { Box } from '@mui/material';
import { PeoplePreview } from '../../liste';

export type ContactChipProps = {
    firstName: string;
    lastName: string;
};

const ContactChip: React.FunctionComponent<ContactChipProps> = function (props) {
    const { firstName, lastName } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '0 8px',
                alignItems: 'center',
            }}
        >
            <PeoplePreview
                firstName={firstName}
                lastName={lastName}
                sx={{
                    borderRadius: '8px',
                    width: '16px',
                    height: '16px',
                    margin: '0',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'background.paper',
                }}
            />
            {firstName} {lastName}
        </Box>
    );
};

export default ContactChip;
