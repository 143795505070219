import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { Box, Card, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { CodeRejet, CodeRejetApiObject } from '@europrocurement/l2d-domain';
import BooleanBadge from '../../atoms/BooleanBadge';
import CenterCircularProgress from '../CenterCircularProgress/CenterCircularProgress';

export type RejectCodeConfigurationCardProps = {
    rejectCode: CodeRejetApiObject;
    changeConfiguration: (field: keyof CodeRejet) => Promise<unknown>;
};

export type RejectCodeBadgeLoadingItem = {
    field: string;
    isLoading: boolean;
};

export type RejectCodeBadgeLoadingCollection = RejectCodeBadgeLoadingItem[];

const RejectCodeConfigurationCard: React.FunctionComponent<RejectCodeConfigurationCardProps> =
    function ({ rejectCode, changeConfiguration }: RejectCodeConfigurationCardProps) {
        const { manuel, bloquant, relanceAuto } = rejectCode;

        type BooleanBadgeProps = {
            label: string;
            field: keyof CodeRejet;
            value?: boolean;
        };

        const booleanBadges: Array<BooleanBadgeProps> = [
            {
                label: 'Manuel',
                field: 'manuel',
                value: manuel,
            },
            {
                label: 'Bloquant',
                field: 'bloquant',
                value: bloquant,
            },
            {
                label: 'Relance Automatique',
                field: 'relanceAuto',
                value: relanceAuto,
            },
        ];

        const [isConfigurationUpdating, setIsConfigurationUpdating] = useState<boolean>(false);

        const { enqueueSnackbar } = useSnackbar();
        const toggleValue = (field: keyof CodeRejet) => {
            setIsConfigurationUpdating(true);

            changeConfiguration(field)
                .then(() => {
                    setIsConfigurationUpdating(false);
                })
                .catch((error) => {
                    console.error('error');
                    console.error(error);
                    setIsConfigurationUpdating(false);
                    if (
                        error instanceof AxiosError &&
                        error.response &&
                        error.response.data.exception &&
                        error.response.data.exception.message
                    ) {
                        enqueueSnackbar(
                            <Typography>{error.response.data.exception.message}</Typography>,
                            {
                                variant: 'error',
                            },
                        );
                    } else if (error instanceof Error) {
                        enqueueSnackbar(<Typography>{error.message}</Typography>, {
                            variant: 'error',
                        });
                    }
                });
        };

        return (
            <Card sx={{ gap: '15px', padding: '15px', display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px',
                        padding: '0px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                    >
                        Configuration{' '}
                    </Typography>

                    {booleanBadges.map(({ label, field, value }) => (
                        <BooleanBadge
                            key={label}
                            label={label}
                            icon
                            value={value}
                            toggleAction={() => toggleValue(field)}
                            disabled={isConfigurationUpdating}
                        />
                    ))}

                    {isConfigurationUpdating && (
                        <CenterCircularProgress sx={{ width: 'inherit', maxHeight: '20px' }} />
                    )}
                </Box>
            </Card>
        );
    };

export default RejectCodeConfigurationCard;
