import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DataSource } from '@europrocurement/l2d-redux-utils';

import { ExceptionFacturante } from '@europrocurement/l2d-domain/reducers/achats/slices/exceptionFacturante';
import { Box } from '@mui/material';
import { addItem, publisherIcon } from '@europrocurement/l2d-icons';
import {
    ActionButton,
    ActionButtonProps,
    ListingTitle,
    useModal,
} from '@europrocurement/flexy-components';
import CreateExceptionForm from '@b2d/pages/Fournisseurs/views/detail/tabs/purchaseInvoiceSettings/CreateExceptionForm';
import { ExceptionFacturanteSelector, getExceptionFacturante } from '@b2d/redux/RootStore';

import { AppDispatch } from '@b2d/redux/types';

import ExceptionsFacturantesListComponent from '../../components/lists/ExceptionsFacturantesListComponent';

const PublishersRejectCodesList: React.FunctionComponent = function () {
    const exceptionsFacturantesDataSource: DataSource<ExceptionFacturante> = useSelector(
        ExceptionFacturanteSelector,
    ).main;

    const dispatch = useDispatch<AppDispatch>();
    const { modalActions } = useModal();
    const reloadList = useCallback(() => {
        dispatch(getExceptionFacturante({}));
    }, [dispatch]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    const handleOnClickCreatePublisherException = useCallback<ActionButtonProps['action']>(() => {
        modalActions.call(<CreateExceptionForm afterSubmit={reloadList} />);
    }, [modalActions, reloadList]);

    return (
        <Box>
            <Box display="flex">
                <ListingTitle
                    modelIcon={publisherIcon}
                    title="Liste des exceptions associées au fournisseur"
                />
                <ActionButton
                    label="Créer une exception"
                    icon={addItem}
                    action={handleOnClickCreatePublisherException}
                />
            </Box>
            <Box display="flex">
                <ExceptionsFacturantesListComponent
                    filtersControl
                    dataSource={exceptionsFacturantesDataSource}
                    fetchData={getExceptionFacturante}
                />
            </Box>
        </Box>
    );
};

export default PublishersRejectCodesList;
