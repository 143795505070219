import React from 'react';

import { SocieteSearchBar, useSocietesSearch } from '@europrocurement/l2d-modules';
import { useParcoursFormalitreHeader } from '@europrocurement/l2d-modules/modules/ParcoursFormalite/provider';
import { Box, Container } from '@mui/system';
import { Stepper } from '@europrocurement/flexy-components';

const B2DCustomHeader: React.FunctionComponent = function () {
    const { isSearchSocietePath } = useSocietesSearch();
    const { isParcoursPath, steps } = useParcoursFormalitreHeader();
    if (isSearchSocietePath) {
        return <SocieteSearchBar />;
    }

    if (isParcoursPath) {
        return (
            <Box sx={{ width: '100%', padding: 'auto' }}>
                <Container maxWidth="md">
                    <Stepper steps={steps} />
                </Container>
            </Box>
        );
    }

    return null;
};

export default B2DCustomHeader;
