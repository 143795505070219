import React, { useState, useCallback, useEffect, useRef } from 'react';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import ScrollBar from 'react-perfect-scrollbar';
import { pdfjs, Document, Page } from 'react-pdf';
import {
    faExternalLinkAlt,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
    faRotateLeft,
    faRotateRight,
    faRulerHorizontal,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type SxProps, Pagination, Box, IconButton, Tooltip, Typography } from '@mui/material';

import PdfRuler from '../../atoms/PdfRuler/PdfRuler';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type IconSizeProps = 'small' | 'medium' | 'large';

export type PdfViewerProps = {
    title?: string | null;
    pdfUrl?: string | null;
    defaultPage?: number;
    iconSize?: IconSizeProps;
    onPageChange?: (page: number) => void;
    sx?: SxProps;
    markerDisable?: boolean;
    showTitle?: boolean;
};

type PDFDocumentProxy = {
    numPages: number;
};

const PdfViewer: React.FunctionComponent<PdfViewerProps> = function ({
    title,
    pdfUrl,
    defaultPage = 1,
    iconSize = 'small',
    onPageChange = () => {},
    sx,
    markerDisable,
    showTitle = true,
}: PdfViewerProps) {
    const [pages, setPages] = useState<number>(0);
    const [page, setPage] = useState<number>(defaultPage);
    const [scale, setScale] = useState<number>(1);
    const [rotate, setRotate] = useState<number>(0);
    const [pdfWidth, setPdfWidth] = useState<number>(0);
    const [pdfHeight, setPdfHeight] = useState<number>(0);
    const [toolsAvailable, setToolsAvailable] = useState<boolean>(true);
    const [isMarkerVisible, setIsMarkerVisible] = useState<boolean>(false);
    const viewerRef = useRef<HTMLDivElement>(null);

    const divRef: React.RefCallback<HTMLElement> = useCallback((node) => {
        if (node !== null) {
            setPdfWidth(node.getBoundingClientRect().width);
            setPdfHeight(node.getBoundingClientRect().height);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (viewerRef.current && isMarkerVisible) {
                setPdfHeight(viewerRef.current.offsetHeight);
                setPdfWidth(viewerRef.current.offsetWidth);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (viewerRef.current) {
            resizeObserver.observe(viewerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [isMarkerVisible]);

    const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
        setPages(numPages);
        setToolsAvailable(true);
    };

    const onDocumentError = (err: Error) => {
        console.error('pdf viewer error:', err);
        setToolsAvailable(false);
    };

    const onSetScale = (type: number) => {
        let newScale = type ? scale + 0.1 : scale - 0.1;

        if (newScale > 5) {
            newScale = 5;
        } else if (newScale < 0.1) {
            newScale = 0.1;
        }

        setScale(newScale);
    };

    const handleToggleMarker = () => {
        setIsMarkerVisible((prevState) => !prevState);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        onPageChange(value);
        window.scrollTo(0, 0);
    };

    return (
        <Box
            role="application"
            sx={{
                zIndex: '1',
                width: '100%',
                height: '95%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ...sx,
            }}
        >
            <Box
                ref={viewerRef}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    {showTitle && (
                        <Typography
                            color="secondary"
                            fontSize="large"
                            fontWeight="bold"
                        >
                            {title}
                        </Typography>
                    )}
                    <Box
                        sx={{
                            display: toolsAvailable ? 'flex' : 'none',
                            flexDirection: 'row-reverse',
                            marginLeft: 'auto',
                        }}
                    >
                        {markerDisable ? (
                            ''
                        ) : (
                            <Tooltip
                                title={isMarkerVisible ? 'Cacher la règle' : 'Afficher la règle'}
                            >
                                <IconButton
                                    data-testid="ruler"
                                    size={iconSize}
                                    color="secondary"
                                    aria-label="ruler"
                                    onClick={handleToggleMarker}
                                >
                                    <FontAwesomeIcon
                                        icon={faRulerHorizontal}
                                        swapOpacity={isMarkerVisible}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="Ouvrir le pdf dans un onglet">
                            <IconButton
                                data-testid="download"
                                size={iconSize}
                                color="secondary"
                                aria-label="download"
                                onClick={() => {
                                    if (pdfUrl) {
                                        const link = document.createElement('a');
                                        link.href = pdfUrl;
                                        link.target = '_blank';
                                        link.type = 'application/octet-stream';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`Zoomer (${Math.round((scale + 0.1) * 100)}%)`}>
                            <IconButton
                                data-testid="zoomIn"
                                size={iconSize}
                                color="secondary"
                                aria-label="zoom in"
                                onClick={() => {
                                    onSetScale(1);
                                }}
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`Dézoomer (${Math.round((scale - 0.1) * 100)}%)`}>
                            <IconButton
                                data-testid="zoomOut"
                                size={iconSize}
                                color="secondary"
                                aria-label="zoom out"
                                onClick={() => {
                                    onSetScale(0);
                                }}
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={`Tourner à gauche (${(((rotate - 90) % 360) - 360) % 360}°)`}
                        >
                            <IconButton
                                data-testid="rotationLeft"
                                size={iconSize}
                                color="secondary"
                                aria-label="rotate left"
                                onClick={() => {
                                    setRotate((((rotate - 90) % 360) + 360) % 360);
                                }}
                            >
                                <FontAwesomeIcon icon={faRotateLeft} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`Tourner à droite (${(rotate + 90) % 360}°)`}>
                            <IconButton
                                data-testid="rotationRight"
                                size={iconSize}
                                color="secondary"
                                aria-label="rotate right"
                                onClick={() => {
                                    setRotate((rotate + 90) % 360);
                                }}
                            >
                                <FontAwesomeIcon icon={faRotateRight} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box
                    ref={divRef}
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <ScrollBar>
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onDocumentError}
                        >
                            <Page
                                pageNumber={page}
                                scale={scale}
                                width={pdfWidth}
                                rotate={rotate}
                            />
                        </Document>
                        <PdfRuler
                            pdfHeight={pdfHeight}
                            isMarkerVisible={isMarkerVisible}
                        />
                    </ScrollBar>
                </Box>
            </Box>
            <Box>
                {pages > 1 ? (
                    <Pagination
                        sx={{
                            marginTop: '10px',
                        }}
                        data-testid="pagination"
                        size={iconSize}
                        color="secondary"
                        count={pages}
                        page={page}
                        onChange={handleChange}
                        showFirstButton
                        showLastButton
                    />
                ) : null}
            </Box>
        </Box>
    );
};

export default PdfViewer;
