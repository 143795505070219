import React from 'react';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/system';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import { Alert, Button, CircularProgress } from '@mui/material';
import { FaOptionIcon, filtersIcon } from '@europrocurement/l2d-icons';
import { PackagePackageRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { SelectInputOption, SelectOptions } from '../../forms/types';
import TextField from '../../components/form/TextField';
import { domainIds } from '../../constants';
import FormalityFilters from '../Component/FormalityFilter';
import SelectField from '../../components/FormInputs/SelectField';

type ContentFormProps = {
    formObject: UseFormReturn<FieldValues>;
    isFetching?: boolean;
    selectOptions: {
        domains: SelectOptions;
        packages: SelectOptions;
        groups: SelectOptions;
    };
    isCreate?: boolean;
};
/** Content form inputs (first step) : Content of catalog
 * Domain selection / Commecial name / Package / Group
 */
const ContentForm: React.FunctionComponent<ContentFormProps> = function (props) {
    const { formObject, selectOptions, isFetching, isCreate = true } = props;

    const [isFilterShown, showFilters] = React.useState(false);
    const [availableFormalitiesId, setAvailableFormalitiesId] = React.useState<number[]>([]);

    const {
        control,
        formState: { errors },
        watch,
    } = formObject;

    const resetDomainRelatedValues = () => {
        formObject.setValue('commercialName', '');
        formObject.setValue('packageId', null);
        formObject.setValue('groupId', null);
        formObject.setValue('catalogArticlesOverrides', []);
        formObject.setValue('offerId', null);
        formObject.setValue('originId', null);
    };

    const { domainId } = formObject.getValues();

    const hasDomain = typeof domainId === 'number'; // Cannot use false check because domaine use id 0
    const isFormalityDomain = domainId === domainIds.formality;

    const domainRelatedSelectProps = (fieldLabel: string) => ({
        isDisabled: !hasDomain || isFetching,
        placeholder: !hasDomain ? "Choisissez d'abord un domaine" : `Sélectionnez un ${fieldLabel}`,
        noOptionsMessage: () => {
            if (isFilterShown && fieldLabel === 'package') {
                return `Aucun résultats pour ces filtres`;
            }

            return `Aucun ${fieldLabel} n'est disponible pour ce domaine`;
        },
    });

    const toggleFilters = () => {
        showFilters(!isFilterShown);
    };

    const getFormalityId = (packageId: number) => {
        const packageItems = formObject.getValues().domainRelatedInformations.packages
            .values as PackagePackageRead[];

        const packageValue = packageItems?.find(
            (item: PackagePackageRead) => item.id === packageId,
        );

        return packageValue?.formalite?.id || null;
    };

    const filteredPackagesOptions = () => {
        if (!isFilterShown) {
            return selectOptions.packages;
        }

        return selectOptions.packages?.filter((packageOption: SelectInputOption) => {
            const id = packageOption.value;
            if (typeof id !== 'number') {
                return false;
            }

            const formalityId = getFormalityId(id);
            if (!formalityId) {
                return false;
            }

            return availableFormalitiesId.includes(formalityId);
        });
    };

    return (
        <Box sx={{ marginTop: '15px' }}>
            <Box sx={{ marginBottom: '10px' }}>
                <FlexyHeaderForm
                    sx={{ marginTop: 0 }}
                    label="Domaine & nom commercial"
                    outlined
                />
            </Box>
            <form noValidate>
                <Controller
                    name="domainId"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            value={watch(field.name)}
                            placeholder="Sélectionnez un domaine"
                            name={field.name}
                            isDisabled={!isCreate}
                            onInputChange={resetDomainRelatedValues}
                            label="Domaine"
                            options={selectOptions.domains}
                            errors={errors}
                        />
                    )}
                />
                <Controller
                    name="commercialName"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            sx={{ width: '100%' }}
                            {...field}
                            value={watch(field.name) ?? ''}
                            name={field.name}
                            label="Nom commercial"
                            errors={errors}
                            required
                        />
                    )}
                />

                <Box sx={{ marginBottom: '10px' }}>
                    <FlexyHeaderForm
                        sx={{ marginTop: 0 }}
                        label="Contenu du catalogue"
                        outlined
                    />
                </Box>

                {isFormalityDomain && (
                    <Button
                        component="label"
                        role={undefined}
                        variant={isFilterShown ? 'contained' : 'outlined'}
                        tabIndex={-1}
                        startIcon={<FaOptionIcon {...filtersIcon.props} />}
                        onClick={toggleFilters}
                    >
                        Filtrer les packages disponibles par formalité
                    </Button>
                )}

                {isFormalityDomain && isFilterShown && (
                    <FormalityFilters filtersSetterCallback={setAvailableFormalitiesId} />
                )}

                {!hasDomain && (
                    <Alert
                        sx={{ marginTop: '10px' }}
                        severity="info"
                    >
                        Selectionnez un domaine pour afficher les packages et groupements
                        disponibles
                    </Alert>
                )}

                {isFetching && <CircularProgress />}
                <Controller
                    name="packageId"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            value={watch(field.name)}
                            name={field.name}
                            label="Package"
                            options={filteredPackagesOptions() as SelectOptions}
                            errors={errors}
                            {...domainRelatedSelectProps('package')}
                        />
                    )}
                />
                <Controller
                    name="groupId"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            value={watch(field.name)}
                            name={field.name}
                            label="Groupement"
                            options={selectOptions.groups}
                            errors={errors}
                            {...domainRelatedSelectProps('groupement')}
                        />
                    )}
                />
            </form>
        </Box>
    );
};

export default ContentForm;
