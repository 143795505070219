import React, { useEffect } from 'react';

import { CenterCircularProgress } from '@europrocurement/flexy-components';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import { Outlet } from 'react-router';

const PublisherRoot = function () {
    // const optionsToLoadAtStartUp = React.useMemo(
    //     () => [
    //         { dataSource: paysDatasource, action: getPays },
    //         { dataSource: walletOptionsDatasource, action: getWalletOptions },
    //         { dataSource: cspOptionsDatasource, action: getCspOptions },
    //         { dataSource: civilitiesDatasource, action: getCivilities },
    //     ],
    //     [civilitiesDatasource, cspOptionsDatasource, paysDatasource, walletOptionsDatasource],
    // );

    // const dispatch = useAppDispatch();

    const loadInitialOptions = React.useCallback(() => {
        // optionsToLoadAtStartUp.forEach(({ dataSource, action }) => {
        //     const { status } = dataSource;
        //     if (status === 'idle') {
        //         dispatch(action({}));
        //     }
        // });
    }, []);

    // const optionsLoadingStatus = optionsToLoadAtStartUp.map(({ dataSource }) => {
    //     const { status } = dataSource;

    //     return status;
    // });

    // const allOptionsAreLoaded = optionsLoadingStatus.every((status) => status === 'succeeded');

    useEffect(() => {
        loadInitialOptions();
    }, [loadInitialOptions]);

    const isApplicationReady = () =>
        // return allOptionsAreLoaded
        true;

    return isApplicationReady() ? (
        <KeycloakRoute roles="realm:interne">
            <Outlet />
        </KeycloakRoute>
    ) : (
        <CenterCircularProgress />
    );
};

export default PublisherRoot;
