import React from 'react';
import { Navigate } from 'react-router-dom';

// import html2canvas from 'html2canvas';

/** layouts */
import { BlankLayout, NotFound, Forbidden, Unauthorized } from '@europrocurement/flexy-components';

import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
/** end layouts */

/** pages */

import { FormalityRouter } from '@b2d/pages/Formalities/FormalityRouter';
import {
    Debug,
    OfferRouter,
    ParcoursFormaliteRouterBack,
    SocietesRouterBack,
} from '@europrocurement/l2d-modules';
import { B2DSymboleIcon } from '@europrocurement/l2d-icons';
import { PublisherRouter } from '@b2d/pages/Fournisseurs/PublisherRouter';
import UtilisateursRouter from '@b2d/pages/Utilisateurs/UtilisateursRouter';
import { SignatureRouter } from '../pages/Signature/SignatureRouter';
import Accueil from '../pages/Accueil';
import { DossierRouter } from '../pages/Dossiers';
import { OrderRouter } from '../pages/Orders';
import PrescripteurRouter from '../pages/Prescripteurs';
/** end pages */

/** menu */

import menuEntries from './menuEntries';
/** end menu */

import AchatsRouter from '../pages/Achats/AchatsRouter';
import B2DLayout from './B2DLayout';

import { version } from '../../package.json';

const Router = [
    {
        path: '/',
        element: (
            <B2DLayout
                menuEntries={menuEntries}
                footerProps={{
                    children: <B2DSymboleIcon style={{ maxHeight: '24px' }} />,
                }}
            />
        ),
        children: [
            { path: '/', element: <Navigate to="/accueil" /> },
            {
                path: 'accueil',
                exact: true,
                element: <Accueil />,
            },
            {
                path: 'debug',
                element: (
                    <KeycloakRoute roles="realm:debug">
                        <Debug panelEnvironnement={{ version }} />
                    </KeycloakRoute>
                ),
            },
            PrescripteurRouter,
            FormalityRouter,
            PublisherRouter,
            AchatsRouter,
            OfferRouter,
            SignatureRouter,
            UtilisateursRouter,
            ParcoursFormaliteRouterBack,
            DossierRouter,
            OrderRouter,
            SocietesRouterBack,
        ],
    },
    {
        path: '/errors',
        element: <BlankLayout />,
        children: [
            { path: '404', element: <NotFound /> },
            { path: '403', element: <Forbidden /> },
            { path: '401', element: <Unauthorized /> },
        ],
    },
    {
        path: '*',
        element: <NotFound />,
    },
];

export default Router;
