import React from 'react';
import { Box, SxProps } from '@mui/material';

type Props = {
    sx?: SxProps;
};
const VerticalDivider: React.FunctionComponent<Props> = function ({ sx }) {
    const verticalDividerStyle = {
        maxWidth: '1px',
        minWidth: '1px',
        minHeight: '55px',
        backgroundColor: 'gray',
        opacity: 0.2,
        ...sx,
    };

    return (
        <Box
            sx={verticalDividerStyle}
            data-testid="VerticalDivider-test-id"
        />
    );
};

export default VerticalDivider;
