/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    ReminderMailJsonldReminderMailRead,
    ReminderMailApi,
    ReminderMailApiGetReminderMailsReminderMailCollectionRequest,
} from '../../../openApi/ApiAchats';
import { ACHATS_REDUCER_NAME } from '../constants';
import { Order } from '../../../types/apiGenericTypes';

export type ReminderMailApiObject = ReminderMailJsonldReminderMailRead & Record<string, unknown>;

export type ReminderMail = ReminderMailApiObject;

export const REMINDER_MAIL_SLICE_NAME = 'rappel';

export const additionalRemindersMailsDataSources: Array<string> = [];

const mapperReminderMail = function (reminderMail: ReminderMail) {
    return {
        ...reminderMail,
    } as ReminderMail;
};

export const ReminderMailStatus = {
    PENDING: 'pending',
    ERROR: 'error',
    SENT: 'sent',
} as const;

export type ReminderMailStatusType = (typeof ReminderMailStatus)[keyof typeof ReminderMailStatus];

export function createReminderMailSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new ReminderMailApi(conf);

    const fetchReminderMailCollection: FetchCollectionData<ReminderMail> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let statuses: Array<ReminderMailStatusType> | undefined;
        let tags: Array<string> | undefined;
        let orderId: Order;
        let orderStatus: Order;

        const STATUS = Object.values(ReminderMailStatus);

        const statusesFromFilters = filters.statuses as string;

        if (statusesFromFilters) {
            const inputStatuses = statusesFromFilters
                .split(',')
                .map((status) => status.toLowerCase().trim())
                .filter((value, index, self) => self.indexOf(value) === index);

            const validStatuses = inputStatuses.filter((status) =>
                STATUS.includes(status as ReminderMailStatusType),
            );

            statuses = validStatuses.length > 0 ? (validStatuses as typeof statuses) : undefined;
        }

        if (filters.tags && Array.isArray(filters.tags) && filters.tags.length > 0) {
            tags = filters.tags;
        }

        orders.forEach((item) => {
            if (item.field === 'id') {
                orderId = item.value;
            }
            if (item.field === 'status') {
                orderStatus = item.value;
            }
        });

        const requestParameters: ReminderMailApiGetReminderMailsReminderMailCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            statuses,
            tags,
            orderId,
            orderStatus,
        };

        return api
            .getReminderMailsReminderMailCollection(requestParameters)
            .then((res) => res.data) as Promise<ApiCollectionResponse<ReminderMail>>;
    };

    const fetchReminderMailItem: FetchItemDataType<ReminderMail> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getReminderMailItem({
                id: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<ReminderMail>;

    const { slice: reminderMailSlice, dataSourcesThunks: reminderMailDataSourcesThunks } =
        SliceFactory.createSlice<ReminderMail, ReminderMail>(
            REMINDER_MAIL_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            additionalRemindersMailsDataSources,
            fetchReminderMailCollection,
            fetchReminderMailItem,
            mapperReminderMail,
            {
                cloture: true,
                deleted: false,
            },
            undefined,
            [
                {
                    field: 'updatedAt',
                    value: 'desc',
                },
            ],
        );

    return { reminderMailSlice, reminderMailDataSourcesThunks };
}
