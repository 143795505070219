import {
    Configuration,
    MarquesApi,
    MarquesApiApiMarquesGetCollectionRequest,
} from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import {
    ApiCollectionResponse,
    FetchCollectionData,
    FetchItemDataType,
    SliceFactory,
} from '@europrocurement/l2d-redux-utils';
import {
    type Mutable,
    type MarquesUtilisateurApiObject,
    type UtilisateursConfigurationParameters,
} from '../types';
import { MARQUES_SLICE_NAME, UTILISATEUR_REDUCER_NAME } from '../constants';

export type Marques = MarquesUtilisateurApiObject;

// function declared for the slice Factory
const mapperServices = function (marques: Marques) {
    return {
        ...marques,
    } as Marques;
};

/**
 * Function that fetch the collection of marques and store it into the global state Redux
 * @param configuration API configuration parameters
 * @returns the response of API request (get collection/get by id)
 */
export function createMarquesSlice(configuration: UtilisateursConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new MarquesApi(conf);

    const fetchMarquesCollection: FetchCollectionData<Marques> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) => {
        const input: Mutable<MarquesApiApiMarquesGetCollectionRequest> = {
            xIdSociete,
            page: page + 1,
            itemsPerPage,
        };

        return api
            .apiMarquesGetCollection(input)
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<Marques>>;
    };

    // No /marques/id route on API but needed to the sliceFactory
    const fetchMarquesItem: FetchItemDataType<Marques> = () =>
        new Promise(() => {
            console.error('fetchMarquesItem called but should not');
        });

    // Use the utilisateur reducer for the constant because it depends to Utilisateur API
    const { slice: marquesSlice, dataSourcesThunks: marquesDataSourcesThunks } =
        SliceFactory.createSlice<MarquesUtilisateurApiObject, Marques>(
            MARQUES_SLICE_NAME,
            UTILISATEUR_REDUCER_NAME,
            [],
            fetchMarquesCollection,
            fetchMarquesItem,
            mapperServices,
            {},
        );
    return { marquesSlice, marquesDataSourcesThunks };
}
