import * as React from 'react';
import { Provider } from 'react-redux';
import asyncDispatchMiddleware from 'async-dispatch';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    FACTURATION_REDUCER_NAME,
    FORMALITES_REDUCER_NAME,
    FORMULAIRE_REDUCER_NAME,
    OFFRE_REDUCER_NAME,
    TIERS_REDUCER_NAME,
    useFacturationService,
    useFormaliteService,
    useFormulaireService,
    useOffreService,
    useTiersService,
    useUtilisateurService,
    UTILISATEUR_REDUCER_NAME,
} from '@europrocurement/l2d-domain';
import { standaloneReducerBase } from './redux/RootStore';

const ReduxProvider: React.FunctionComponent<React.PropsWithChildren> = function ({ children }) {
    const { reducer: offreReducer } = useOffreService();
    const { reducer: formaliteReducer } = useFormaliteService();
    const { reducer: tiersReducer } = useTiersService();
    const { reducer: formulaireReducer } = useFormulaireService();
    const { reducer: utilisateurReducer } = useUtilisateurService();
    const { reducer: facturationReducer } = useFacturationService();

    const rootReducer = combineReducers({
        [FORMALITES_REDUCER_NAME]: formaliteReducer,
        [OFFRE_REDUCER_NAME]: offreReducer,
        [TIERS_REDUCER_NAME]: tiersReducer,
        [FORMULAIRE_REDUCER_NAME]: formulaireReducer,
        [UTILISATEUR_REDUCER_NAME]: utilisateurReducer,
        [FACTURATION_REDUCER_NAME]: facturationReducer,
        ...standaloneReducerBase,
    });

    const rootStore = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(asyncDispatchMiddleware),
    });

    return <Provider store={rootStore}>{children}</Provider>;
};

export default ReduxProvider;
