import {
    Configuration,
    ServicesApi,
    ServicesApiApiServicesGetCollectionRequest,
} from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import {
    ApiCollectionResponse,
    FetchCollectionData,
    FetchItemDataType,
    SliceFactory,
} from '@europrocurement/l2d-redux-utils';
import {
    type Mutable,
    type ServicesUtilisateurApiObject,
    type UtilisateursConfigurationParameters,
} from '../types';
import { SERVICES_SLICE_NAME, UTILISATEUR_REDUCER_NAME } from '../constants';

export type Services = ServicesUtilisateurApiObject;

const mapperServices = function (services: Services) {
    return {
        ...services,
    } as Services;
};

export function createServicesSlice(configuration: UtilisateursConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new ServicesApi(conf);

    const fetchServicesCollection: FetchCollectionData<Services> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) => {
        const input: Mutable<ServicesApiApiServicesGetCollectionRequest> = {
            xIdSociete,
            page: page + 1,
            itemsPerPage,
        };

        return api
            .apiServicesGetCollection(input)
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<Services>>;
    };

    // No /services/id on API but needed to the sliceFactory
    const fetchServicesItem: FetchItemDataType<Services> = () =>
        new Promise(() => {
            console.error('fetchServicesItem called but should not');
        });

    const { slice: servicesSlice, dataSourcesThunks: servicesDataSourcesThunks } =
        SliceFactory.createSlice<ServicesUtilisateurApiObject, Services>(
            SERVICES_SLICE_NAME,
            UTILISATEUR_REDUCER_NAME,
            [],
            fetchServicesCollection,
            fetchServicesItem,
            mapperServices,
            {},
        );
    return { servicesSlice, servicesDataSourcesThunks };
}
