import { Box, Typography, useTheme } from '@mui/material';
// import { useTheme } from '@mui/system';
import * as React from 'react';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { FormaliteFormaliteRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import { hexToRgba } from '@europrocurement/l2d-utils';

type ComponentProps = {
    icon?: FormaliteFormaliteRead['iconeName'];
    label: string;
    action?: () => void;
    disabled?: boolean;
    isSelected?: boolean;
    size?: number;
};

const InlineCategoryButton: React.FunctionComponent<ComponentProps> = function (props) {
    // const SvgShape =
    const { label, icon, action, disabled = false, isSelected = false } = props;
    const theme = useTheme();

    const isClickable = typeof action === 'function' && !disabled;

    const [onHover, setHover] = React.useState<boolean>(false);

    const borderColor = onHover || isSelected ? theme.palette.primary.main : 'lightGray';
    const borderSize = () => {
        if (onHover || isSelected) {
            return '2px';
        }

        return '1px';
    };
    const buttonPadding = () => {
        // Border size compensation
        if (onHover || isSelected) {
            return '0px';
        }
        return '1px';
    };

    const height = 60;
    const width = 250;
    const buttonStyle: React.CSSProperties = {
        display: 'flex',
        maxWidth: width,
        maxHeight: height,
        minWidth: width,
        minHeight: height,
        border: `solid ${borderSize()} ${borderColor}`,
        borderRadius: '20px',
        cursor: onHover && isClickable ? 'pointer' : '',
        margin: '5px',
        transition: 'background ease-out 0.6s',
        padding: buttonPadding(),
        backgroundColor: isSelected ? hexToRgba(theme.palette.primary.light, 0.4) : '',
    };

    const hoverProps = {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
    };

    return (
        <Box
            sx={buttonStyle}
            {...hoverProps}
            onClick={() => (action ? action() : () => {})}
        >
            <Box sx={{ paddingLeft: '10px', fontSize: '25px', marginTop: '10px' }}>
                {icon ? (
                    <FaOptionIcon
                        icon={['fad', icon as IconName]}
                        withShape
                        shapeSize={40}
                    />
                ) : (
                    <FaOptionIcon
                        icon={['fad', 'question' as IconName]}
                        withShape
                        shapeSize={40}
                    />
                )}
            </Box>
            <Box
                sx={{
                    padding: '7px',
                    display: 'flex',
                    justifyCOntent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    fontWeight={500}
                    textAlign="center"
                >
                    {label}
                </Typography>
            </Box>
        </Box>
    );
};
export default InlineCategoryButton;
