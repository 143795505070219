import React from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { Alert, Table, TableContainer } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableBody from './TableBody';
import TableHeading from './TableHeading';

export type CompatibilitiesTableProps = {
    formalities: Formalite[];
    filteredFormalities: Formalite[];
    onToggleCompatibility: (formality: Formalite, compatibility: Formalite) => void;
};

const CompatibilitiesTable: React.FunctionComponent<CompatibilitiesTableProps> = function (props) {
    const { formalities, filteredFormalities, onToggleCompatibility } = props;

    if (!filteredFormalities.length) {
        return <Alert severity="warning">Votre recherche n&apos;a donné aucun résultat</Alert>;
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                >
                    <TableHeading formalities={filteredFormalities} />
                    <TableBody
                        formalities={formalities}
                        compatibilities={filteredFormalities}
                        onChange={onToggleCompatibility}
                    />
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default CompatibilitiesTable;
