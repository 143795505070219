import { ArticlesApi, ArticlesArticleUpdate } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { articleIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { ARTICLES_SLICE_NAME } from '../constants';

import { type ModelConfiguration, type ModelMethods, type ModelItem } from '../../..';
import { baseArticlesSelector } from '../selectors';

const modelKey = 'article';
const modelName = 'Article';
const sliceName = ARTICLES_SLICE_NAME;

type ModelStructure = ArticlesArticleUpdate;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
    getItemSecondaryLabel: (model: ModelStructure) => `${model.libelle} (${model.prixUnitaire} €)`,
};

export type ArticleEndpoints = {
    list: InstanceType<typeof ArticlesApi>['apiArticlesGetCollection'];
    delete: InstanceType<typeof ArticlesApi>['apiArticlesIdDelete'];
    dependencies: InstanceType<typeof ArticlesApi>['apiArticlesIdDependenciesGet'];
    read: InstanceType<typeof ArticlesApi>['apiArticlesIdGet'];
    update: InstanceType<typeof ArticlesApi>['apiArticlesIdPut'];
    create: InstanceType<typeof ArticlesApi>['apiArticlesPost'];
};

export type ArticleModel = ModelItem<ModelStructure> & ArticleEndpoints;

export const buildArticleModel = (
    api: ArticlesApi,
    datasourcesThunks: DataSourcesThunksType<ArticlesArticleUpdate>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Articles',
        sliceName,
        selector: baseArticlesSelector,
        api,
        datasourcesThunks,
        icon: articleIcon,
    };

    const endpoints: ArticleEndpoints = {
        list: api.apiArticlesGetCollection.bind(api),
        delete: api.apiArticlesIdDelete.bind(api),
        dependencies: api.apiArticlesIdDependenciesGet.bind(api),
        read: api.apiArticlesIdGet.bind(api),
        update: api.apiArticlesIdPut.bind(api),
        create: api.apiArticlesPost.bind(api),
    };

    const model: ArticleModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
