import * as React from 'react';

import { Box, Typography } from '@mui/material';
import { UtilisateurApiObject } from '@europrocurement/l2d-domain';

export type FullNameCompilerProps = {
    user: UtilisateurApiObject;
};

const FullNameCompiler: React.FunctionComponent<FullNameCompilerProps> = function ({ user }) {
    const { nom, prenom, initiales } = user;

    return (
        <Box
            sx={{ verticalAlign: 'center' }}
            data-testid="compilerbox"
        >
            <Typography
                variant="l2dbodym"
                data-testid="fullname"
            >
                {`${nom} ${prenom}`}
            </Typography>
            {'  '}
            <Typography
                variant="l2dbodys"
                color="text.gris70"
                data-testid="initials"
            >
                ({initiales})
            </Typography>
        </Box>
    );
};

export default FullNameCompiler;
