import * as React from 'react';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import './L2DFSidebar.css';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import MenuItems from '../MenuItems';
import { MenuItemProps } from '../../molecules/MenuItem';
import Scrollbar from '../../molecules/Scrollbar';
import L2DFSidebarAction, { L2DFSidebarActionProps } from '../L2DFSidebarAction';

export type L2DFSidebarProps = {
    logo: React.ReactNode;
    logoFolded: React.ReactNode;
    actions?: L2DFSidebarActionProps[];
    items: MenuItemProps[];
    folded: boolean;
    onClose?: () => void;
};

const L2DFSidebar: React.FunctionComponent<L2DFSidebarProps> = function (props) {
    const { logo, logoFolded, actions = [], items = [], folded = false, onClose } = props;
    const theme = useTheme();
    const bgColor = theme.palette.background.dark;
    const classNames = ['l2df-sidebar'];
    const smBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const isFolded = folded && smBreakpoint === true;
    let closeBtn = null;

    if (isFolded) {
        classNames.push('l2df-sidebar-folded');
    }

    if (!smBreakpoint && onClose) {
        closeBtn = (
            <Button
                onClick={onClose}
                data-testid="l2df-sidebar-close-btn"
            >
                <FaOptionIcon
                    icon={['fasl', 'close']}
                    sx={{ display: 'flex', alignItems: 'center', color: theme.palette.grey[600] }}
                />
            </Button>
        );
    }

    return (
        <Box
            className={classNames.join(' ')}
            sx={{ backgroundColor: bgColor }}
        >
            <Box className="l2df-sidebar-heading">
                <Box className="l2df-sidebar-logo">{isFolded ? logoFolded : logo}</Box>
                {closeBtn}
            </Box>
            {actions.map((action: L2DFSidebarActionProps) => (
                <L2DFSidebarAction
                    key={action.label}
                    {...action}
                    folded={isFolded}
                />
            ))}
            <Scrollbar>
                <MenuItems
                    items={items}
                    folded={folded && smBreakpoint === true}
                    onSidebarClose={onClose}
                />
            </Scrollbar>
        </Box>
    );
};

export default L2DFSidebar;
