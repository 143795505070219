import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons';
import { faLeftRight } from '@fortawesome/pro-solid-svg-icons';
import { Typography } from '@mui/material';
import { MediaObjectApiPatchMediaObjectItemRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { ContextActionType, useModal } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { MediaObject } from '@europrocurement/l2d-domain';
import ConfirmationModalContent from '@b2d/pages/Achats/components/modals/ConfirmationModalContent';
import { swapSocieteMediaObjectModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import { mediaObjectApi } from '@b2d/redux/RootStore';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { filtersHasher } from '@europrocurement/l2d-utils';

export type UpdateSocieteProps = {
    mediaObject: MediaObject;
};

const useUpdateSociete = ({ mediaObject }: UpdateSocieteProps): ContextActionType => {
    const { enqueueSnackbar } = useSnackbar();
    const { modalActions } = useModal();
    const { request } = useApiRequest();

    const switchSociete = useCallback(
        async (selectedMediaObjectFromUrl: MediaObject) => {
            if (!selectedMediaObjectFromUrl.id) return;

            // Suppression physique actuellement impossible à cause de :
            // 1. Contrainte SQL (même si aucune facture liée n'est active, elle reste liée en base)
            if (selectedMediaObjectFromUrl && selectedMediaObjectFromUrl.facturesAchat) {
                const isOneFactureInUse = selectedMediaObjectFromUrl.facturesAchat.find(
                    (factureFromMediaObject) => !factureFromMediaObject.deleted,
                );

                if (isOneFactureInUse) {
                    enqueueSnackbar(
                        <Typography>
                            Ce PDF ne peut pas être supprimé car il est lié à une ou plusieurs
                            facture(s) d&apos;achat en cours de traitement(s), ou traitée(s).
                        </Typography>,
                        {
                            variant: 'error',
                        },
                    );

                    return;
                }
            }

            const successCallback = () => {
                window.location.reload();
            };

            const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                id: selectedMediaObjectFromUrl.id.toString(),
                mediaObject: {
                    idSociete: selectedMediaObjectFromUrl.idSociete === 12 ? 2 : 12,
                },
            };

            // Suppression logique
            request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                withToaster: true,
                successCallback,
            });
        },
        [enqueueSnackbar, request],
    );

    return {
        name: 'Modifier la société',
        icon: (
            <FaOptionIcon
                icon={faBuilding}
                option={faLeftRight}
                optionProps={
                    {
                        //   size: 'xl',
                    }
                }
            />
        ),
        action: () => {
            modalActions.call(
                <ConfirmationModalContent
                    key={`${mediaObject?.['@id']}-${swapSocieteMediaObjectModalMessages}`}
                    messages={swapSocieteMediaObjectModalMessages}
                    actionOnValidation={() => {
                        if (!mediaObject) {
                            enqueueSnackbar(
                                <Typography>Il n&apos;y a aucun PDF sélectionné !</Typography>,
                                {
                                    variant: 'error',
                                },
                            );
                            return;
                        }

                        switchSociete(mediaObject);
                    }}
                />,
            );
        },
        resetHash: filtersHasher(mediaObject),
    };
};

export default useUpdateSociete;
