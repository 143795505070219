export const TIERS_REDUCER_NAME = 'tiers';
export const CIVILITIES_SLICE_NAME = 'civilities';
export const CSP_OPTIONS_SLICE_NAME = 'cspOptions';
export const FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME = 'autocompleteFactures';
export const FOURNISSEUR_SLICE_NAME = 'fournisseur';
export const PAYS_SLICE_NAME = 'pays';
export const PRESCRIPTEUR_SLICE_NAME = 'prescripteur';
export const SOUS_CLIENT_SLICE_NAME = 'sousClient';
export const TIERS_FOURNISSEUR_SLICE_NAME = 'tiersFournisseur';
export const WALLET_OPTIONS_SLICE_NAME = 'walletOptions';
export const OBJECT_GROUP_SLICE_NAME = 'objectGroup';
