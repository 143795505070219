import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters,
    Configuration,
    FournisseurFournisseurFournisseurRead,
    FournisseursApi,
    FournisseursApiGetListTiersFournisseurCollectionRequest,
    FournisseursApiGetFournisseurFournisseurItemRequest,
} from '../../../openApi/ApiTiers';
import {
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
    FOURNISSEUR_SLICE_NAME,
    TIERS_REDUCER_NAME,
} from '../constants';

export const additionalFournisseursDataSources: Array<string> = [
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
];

export type FournisseurApi = FournisseurFournisseurFournisseurRead & Record<string, unknown>;

export type Fournisseur = FournisseurApi;

const mapperFournisseur = function (fournisseur: FournisseurApi) {
    return {
        nomDentreprise: fournisseur.raisonSociale,
        ...fournisseur,
    } as Fournisseur;
};

export function createFournisseurSlice(configuration: ConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FournisseursApi(conf);

    const fetchFournisseursCollection: FetchCollectionData<FournisseurApi> = ({
        search,
        pagination,
        filters,
        // orders,
        xIdSociete,
        // rootstate,
    }) => {
        const xIdUser = undefined;
        const page = pagination.page + 1;
        const itemsPerPage = 20;
        // let recherche = undefined;
        // const societeId = xIdSociete;
        // const societeId2 = undefined;
        const options = undefined;

        let deleted;
        let ddmId: number | undefined;
        let siren: string | undefined;
        let libelle: string | undefined;

        if (filters.ddmId) {
            ddmId = filters.ddmId as number;
        }

        if (filters.siren) {
            siren = filters.siren as string;
        }

        if (filters.libelle) {
            libelle = filters.libelle as string;
        }

        if (filters.deleted === false) {
            deleted = false;
        }
        if (filters.deleted === true) {
            deleted = true;
        }

        const requestParameters: FournisseursApiGetListTiersFournisseurCollectionRequest = {
            xIdSociete,
            xIdUser,
            page,
            itemsPerPage,
            deleted,
            siren,
            libelle,
            recherche: search,
            ddmId,
        };

        return api
            .getListTiersFournisseurCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<FournisseurApi>>;
    };

    const fetchFournisseursCollectionBySiren: FetchCollectionData<FournisseurApi> = ({
        search,
        pagination,
        filters,
        // orders,
        xIdSociete,
        // rootstate,
    }) => {
        const options = undefined;

        let deleted;
        let ddmId: number | undefined;

        if (filters.ddmId) {
            ddmId = filters.ddmId as number;
        }
        if (filters.deleted === false) {
            deleted = false;
        }
        if (filters.deleted === true) {
            deleted = true;
        }

        const requestParameters: FournisseursApiGetListTiersFournisseurCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: 20,
            deleted,
            recherche: search,
            ddmId,
        };

        return api
            .getListTiersFournisseurCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<FournisseurApi>>;
    };

    const fetchFournisseursItem: FetchItemDataType<FournisseurApi> = ({
        idItem,
        // search,
        // pagination,
        // filters,
        // orders,
        xIdSociete,
        // rootstate,
        options,
    }) => {
        const requestParameters: FournisseursApiGetFournisseurFournisseurItemRequest = {
            id: String(idItem),
            xIdSociete,
        };

        return api
            .getFournisseurFournisseurItem(requestParameters, options)
            .then((res) => res.data) as Promise<FournisseurApi>;
    };

    const { slice: fournisseurSlice, dataSourcesThunks: fournisseurDataSourcesThunks } =
        SliceFactory.createSlice<FournisseurApi, Fournisseur>(
            FOURNISSEUR_SLICE_NAME,
            TIERS_REDUCER_NAME,
            additionalFournisseursDataSources,
            fetchFournisseursCollection,
            fetchFournisseursItem,
            mapperFournisseur,
            {
                deleted: false,
            },
            {
                data: {
                    fetchBySiren: fetchFournisseursCollectionBySiren,
                },
            },
        );

    return { fournisseurSlice, fournisseurDataSourcesThunks };
}
