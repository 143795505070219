import React, { useState } from 'react';
import { deleteIcon, FaOptionIcon, preview } from '@europrocurement/l2d-icons';

import {
    BeneficiaireEffectif,
    BeneficiaireEffectifWidgetCallbackArgs,
    useModal,
} from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { CustomOptionResolver, CustomOptionResolvers } from '@europrocurement/flexy-form';
import {
    FormulaireFormaliteApiObject,
    useFormaliteService,
    useFormulaireService,
} from '@europrocurement/l2d-domain';
import { useParcoursFormalite } from '@europrocurement/l2d-modules/modules/ParcoursFormalite/provider';
import { FormulaireFormaliteJsonld } from '@europrocurement/l2d-domain/openApi/ApiFormulaire';
import { castIriToId } from '@europrocurement/l2d-utils';
import { useSnackbar } from 'notistack';
import { jsonForm } from '@europrocurement/flexy-form/utils/types/formTypes';

import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';

import BeneficiaireEffectifList from '@europrocurement/flexy-components/components/organisms/BeneficiaireEffectifList';
// eslint-disable-next-line import/no-cycle
import { customItemJsonProps } from '../customItemRenderer';
import { useListeSelectionDataResolver } from '../../../hooks/useListeSelectionDataResolver';
import BeneficiaireJson from './Beneficiaire.json';
import BeneficiaireModal from './BeneficiaireModal';

const BeneficiaireView: React.FunctionComponent<customItemJsonProps> = function ({
    formContext,
    props,
}) {
    formContext.watch(props.name);

    const { enqueueSnackbar } = useSnackbar();
    const { getCurrentPresta } = useParcoursFormalite();
    const presta = getCurrentPresta();
    const { formulairesApi } = useFormulaireService();
    const { formaliteApi } = useFormaliteService();
    const createListeSelectionDataResolver = useListeSelectionDataResolver();
    const [form, setForm] = useState<FormulaireFormaliteApiObject | null>(null);
    const { modalActions } = useModal();
    const theme = useTheme();

    React.useEffect(() => {
        if (!form && presta) {
            const fetchForm = async () => {
                const resFormality = await formaliteApi.apiFormalitesIdGet({
                    id: `${castIriToId(presta.formalite as unknown as string)}`,
                });
                if (resFormality.data.formulaire) {
                    const resFormulaire = await formulairesApi.apiFormulaireFormalitesIdGet({
                        id: castIriToId(resFormality.data.formulaire),
                    });
                    if (resFormulaire.data) {
                        setForm(resFormulaire.data as FormulaireFormaliteJsonld);
                    }
                } else {
                    enqueueSnackbar("La formalité n'a pas de formulaire", { variant: 'error' });

                    throw new Error('no formulaire on formality');
                }
            };
            fetchForm();
        }
    }, [enqueueSnackbar, form, formaliteApi, formulairesApi, presta]);

    // if (!form || !dossier) {
    //     return null;
    // }

    const formjson = BeneficiaireJson;

    const createFakeListResolvers = () => {
        const listeSelectionDataResolver: CustomOptionResolver = () => [
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2 },
            { label: 'Option 3', value: 3 },
            { label: 'Option 4', value: 4 },
            { label: 'Option 5', value: 5 },
            { label: 'Option 6', value: 6 },
            { label: 'Option 7', value: 7 },
            { label: 'Option 8', value: 8 },
            { label: 'Option 9', value: 9 },
            { label: 'Option 10', value: 10 },
        ];
        return listeSelectionDataResolver;
    };

    const customOptionResolvers: CustomOptionResolvers = {
        listeSelectionDataResolver: form
            ? createListeSelectionDataResolver(form)
            : createFakeListResolvers(),
    };

    const onAdd = () => {
        modalActions.call(
            <BeneficiaireModal
                onChange={async (curr, pagelenth, data) => {
                    formContext.setValue(props.name, [
                        ...(formContext.getValues(props.name) || []),
                        {
                            beneficiaire: {
                                ...data,
                            },
                        },
                    ]);
                    modalActions.close();
                }}
                json={formjson as jsonForm}
                defaultValues={{}}
                customOptionResolvers={customOptionResolvers}
            />,
            false,
            true,
        );
    };

    const onPreview: BeneficiaireEffectifWidgetCallbackArgs = (
        beneficiaire: BeneficiaireEffectif,
        index: number,
    ) => {
        modalActions.call(
            <BeneficiaireModal
                onChange={async (curr, pagelenth, data) => {
                    const newValues = [...formContext.getValues(props.name)];
                    newValues.splice(index, 1, {
                        beneficiaire: {
                            ...data,
                        },
                    });
                    formContext.setValue(props.name, newValues);
                    modalActions.close();
                }}
                json={formjson as jsonForm}
                defaultValues={{
                    ...beneficiaire,
                }}
                customOptionResolvers={customOptionResolvers}
            />,
            false,
            true,
        );
    };

    const onDelete: (index: number) => void = (index: number) => {
        const newValues = [...formContext.getValues(props.name)];

        newValues.splice(index, 1);

        formContext.setValue(props.name, newValues);

        return index;
    };

    return (
        <Box>
            <Typography style={{ paddingTop: '40px', fontWeight: '500' }}>
                Veuillez ajouter tout·e·s les bénéficiaire·s effectif de la société :
            </Typography>
            <BeneficiaireEffectifList
                items={(formContext.getValues(props.name) || []).map(
                    (benef: { beneficiaire: BeneficiaireEffectif }) => ({
                        ...benef,
                        actions: [
                            {
                                name: 'preview',
                                icon: <FaOptionIcon {...preview.props} />,
                                onClick: onPreview,
                            },
                            {
                                name: 'delete',
                                icon: <FaOptionIcon {...deleteIcon.props} />,
                                onClick: onDelete,
                            },
                        ],
                    }),
                )}
            />
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                />
                <Grid
                    item
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                >
                    <Button
                        onClick={onAdd}
                        color="secondary"
                        style={{
                            width: '100%',
                            marginTop: '30px',
                            padding: '5px 15px',
                            fontSize: '1rem',
                            borderRadius: '15px',
                            right: '0',
                            border: '1px solid #aaa',
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.secondary.main,
                        }}
                    >
                        <FaOptionIcon
                            size="sm"
                            data-testid="test-id-mimetype-icone"
                            icon={faUserPlus}
                            color={theme.palette.secondary.main}
                            sx={{ margin: '0 5px' }}
                        />
                        Ajouter un·e bénéficiaire
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BeneficiaireView;
