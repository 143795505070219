import { FormStructure } from '@europrocurement/flexy-form';
import type { TextAreaMailType, TextMailType } from './mailTypes';

export const from: TextMailType = {
    type: 'text',
    name: 'from',
    inputlabel: 'De (adresse)',
    disabled: true,
    isDisplayable: false,
};

export const fromName: TextMailType = {
    type: 'text',
    name: 'fromName',
    inputlabel: 'De (nom)',
    disabled: true,
    isDisplayable: false,
};

export const tos: TextMailType = {
    type: 'text',
    name: 'tos',
    inputlabel: 'À',
};

export const replies: TextMailType = {
    type: 'text',
    name: 'replies',
    inputlabel: 'Répondre à',
    disabled: true,
    isDisplayable: false,
};

export const ccs: TextMailType = {
    type: 'text',
    name: 'ccs',
    inputlabel: 'Cc',
};

export const bccs: TextMailType = {
    type: 'text',
    name: 'bccs',
    inputlabel: 'Bcc',
};

export const tags: TextMailType = {
    type: 'text',
    name: 'tags',
    inputlabel: 'Tags',
    disabled: true,
};

export const subject: TextMailType = {
    type: 'text',
    name: 'subject',
    inputlabel: 'Sujet',
};

export const body: TextAreaMailType = {
    type: 'textarea',
    name: 'body',
    inputlabel: 'Corps du message',
    multiline: true,
    rows: 7,
};

export const mailFormStructure = [from, fromName, tos, replies, ccs, bccs, tags, subject, body];
export const mailStructureMap = { from, fromName, tos, replies, ccs, bccs, tags, subject, body };
export type MailFormKeys = keyof typeof mailStructureMap;
export type MailStructureMapType = typeof mailStructureMap;

export const overrideMailStructure = (override: Partial<MailStructureMapType>): FormStructure[] =>
    Object.entries(mailStructureMap).map(([key, formStructure]) => {
        const overrideConfig = override[key as MailFormKeys];
        return {
            ...formStructure,
            ...(overrideConfig ?? {}), // Merge the override config if available
        };
    });
