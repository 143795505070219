/* eslint-disable @typescript-eslint/no-unused-vars */
import { FlexyInput, FlexyInputProps } from '@europrocurement/flexy-components';
import * as React from 'react';
import { ControllerRenderProps, FieldErrors, FieldValues } from 'react-hook-form';
import { SxProps } from '@mui/system';
import { getErrorMessage, getErrorStatus } from '../../forms/errors';

type ComponentProps = {
    name: string;
    label?: string;
    required: boolean;
    errors: FieldErrors<FieldValues>;
    sx?: SxProps;
    placeholder?: string;
    disabled?: boolean;
} & ControllerRenderProps<FieldValues, string> & {
        inputRef?: FlexyInputProps['inputRef'];
    };

const TextField = React.forwardRef<HTMLElement, ComponentProps>(
    ({ name, label, required = false, errors, ...rest }: ComponentProps, ref) => (
        <FlexyInput
            name={name}
            inputlabel={label ?? null}
            error={getErrorStatus(name, errors)}
            helperText={getErrorMessage(name, errors)}
            required={required}
            size="small"
            {...rest}
            type="text"
        />
    ),
);

export default TextField;
