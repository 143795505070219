import { createContext } from 'react';
import { Tiers } from '../hooks/useDossierPrescripteur';

let prescriberId: number | undefined;
const setPrescriberId: React.Dispatch<React.SetStateAction<number | undefined>> = () => {};
let prescriber: Tiers | undefined;
const setPrescriber: React.Dispatch<React.SetStateAction<Tiers | undefined>> = () => {};
const sousClients: Tiers[] = [];
const prescriberLoaded: boolean = false;

const DossierPrescriberContext = createContext({
    prescriberId,
    setPrescriberId,
    prescriber,
    setPrescriber,
    sousClients,
    prescriberLoaded,
});

export default DossierPrescriberContext;
