import React from 'react';

import { Grid } from '@mui/material';
import { AddressCard } from '@europrocurement/flexy-components';
import { Address, Fournisseur } from '@europrocurement/l2d-domain';
import { AdresseJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import CompanyInformationsCard from './CompanyInformationsCard';

type PublisherInformationsProps = {
    publisher: Fournisseur;
};

const PublisherInformations: React.FunctionComponent<PublisherInformationsProps> = function (
    props,
) {
    const { publisher } = props;

    const referenceAddress: AdresseJsonldTiersRead & Record<string, unknown> = {
        adresse1: '',
        adresse2: undefined,
        adresse3: undefined,
        adresse4: undefined,
        codePostal: '',
        ville: '',
        codePays: '',
        defaut: false,
        facturation: false,
        latitude: undefined,
        longitude: undefined,
        placeId: undefined,
        adresseApi: undefined,
        temporaryAddress: undefined,
        dateCreation: null,
        dateMaj: null,
    };

    /**
     * Extracts fields from the `source` object that are present in the `reference` object.
     * This is useful for dynamically creating objects that match a predefined structure.
     *
     * @template T - Type of the source object (e.g., publisher).
     * @template U - Type of the reference object (e.g., an address template).
     * @param {T} source - The source object from which to extract fields.
     * @param {U} reference - The reference object defining which fields should be extracted.
     * @returns {Partial<U>} - A new object containing only the fields from the source that match the keys of the reference.
     */
    function extractMatchingFields<
        T extends Record<string, unknown>,
        U extends Record<string, unknown>,
    >(source: T, reference: U): Partial<U> {
        const matchingFields: Partial<U> = {};

        (Object.keys(reference) as (keyof U)[]).forEach((key) => {
            if (key in source) {
                matchingFields[key] = source[key as keyof T] as unknown as U[keyof U];
            }
        });

        return matchingFields;
    }

    const mainAddress = extractMatchingFields(publisher, referenceAddress);

    return (
        <Grid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
            <Grid
                item
                xs={12}
                lg={6}
            >
                <CompanyInformationsCard />
            </Grid>

            <Grid
                item
                xs={12}
                lg={6}
            >
                <AddressCard
                    isBordered
                    address={mainAddress as Address}
                />
            </Grid>
        </Grid>
    );
};

export default PublisherInformations;
