import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faPeriod } from '@fortawesome/pro-solid-svg-icons';
import { Box, SxProps } from '@mui/material';
import React from 'react';

type EllipsisLoaderProps = {
    sx?: SxProps;
};

const EllipsisLoader: React.FunctionComponent<EllipsisLoaderProps> = function ({
    sx,
}: EllipsisLoaderProps) {
    const cssWrapper: SxProps = {
        ...sx,
        width: '100%',
        padding: '0px',
        margin: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        scale: '0.5',
    };

    const cssPeriodsGroup: SxProps = {
        ...sx,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    const cssPeriod: SxProps = {
        width: 'fit-content',
        margin: '-12px',
        padding: 0,
    };

    return (
        <Box sx={cssWrapper}>
            <Box sx={cssPeriodsGroup}>
                <FaOptionIcon
                    key="period-1"
                    icon={faPeriod}
                    size="sm"
                    iconProps={{
                        bounce: true,
                        style: {
                            animationDuration: '1.5s',
                            animationDelay: '0s',
                        },
                    }}
                    sx={cssPeriod}
                />
                <FaOptionIcon
                    key="period-2"
                    icon={faPeriod}
                    size="sm"
                    iconProps={{
                        bounce: true,
                        style: {
                            animationDuration: '1.5s',
                            animationDelay: '0.5s',
                        },
                    }}
                    sx={cssPeriod}
                />
                <FaOptionIcon
                    key="period-3"
                    icon={faPeriod}
                    size="sm"
                    iconProps={{
                        bounce: true,
                        style: {
                            animationDuration: '1.5s',
                            animationDelay: '1s',
                        },
                    }}
                    sx={cssPeriod}
                />
            </Box>
        </Box>
    );
};

export default EllipsisLoader;
