import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import {
    OffresJsonldOffreRead,
    TblDefDomaineJsonldOffreRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useDispatch } from 'react-redux';
import { FaOptionIcon, addItem } from '@europrocurement/l2d-icons';
import { AnyAction } from '@reduxjs/toolkit';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import { visibilityTranslation } from '@europrocurement/l2d-utils';
import { useOffreService } from '@europrocurement/l2d-domain';
import OfferForm from './Form';
import ListingWithPreview from '../components/ListingWithPreview';
import ModalFormTitle from '../components/form/ModalFormTitle';
import { displayCustomValue, getStringColumn } from '../listings';

const Panel: React.FunctionComponent = function () {
    const { modalActions } = React.useContext(ModalContext);

    const { offerModel: model } = useOffreService();

    const dispatch = useDispatch();

    const reloadList = () =>
        dispatch(model.datasourcesThunks.main.getData({}) as unknown as AnyAction);

    const listingButton = {
        label: 'Créer une nouvelle offre',
        icon: addItem,
        action: () =>
            modalActions.call(
                <>
                    <ModalFormTitle
                        icon={model.icon}
                        title="Création d'une offre"
                    />
                    <OfferForm
                        isCreate
                        afterSubmit={reloadList}
                    />
                </>,
            ),
    };

    const columns: ColumnDatatable<OffresJsonldOffreRead>[] = [
        getStringColumn('id'),
        displayCustomValue(
            'visibilite',
            (visibility) => visibilityTranslation[visibility as 'FULL' | 'PARTIAL'],
            'Visibilité',
        ),
        getStringColumn('libelle', 'Libellé'),
        {
            label: 'Icône',
            displayLabel: true,
            render: ({ iconeName }) => {
                if (iconeName === null) return null;

                return <FaOptionIcon icon={['fad', iconeName as IconName]} />;
            },
            isDisplayed: true,
        },
        displayCustomValue(
            'domaine',
            (domaine: TblDefDomaineJsonldOffreRead) => domaine?.libelle ?? '---',
            'Domaine',
        ),
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des offres"
            listingButton={listingButton}
            listingColumns={columns}
            filtersControl={false}
            form={OfferForm}
            model={model}
        />
    );
};

export default Panel;
