import React from 'react';
import { Box, lighten, PaletteColor, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

export type TimelineStepProps = {
    label: string;
    icon?: IconName;
    current: boolean;
};

export type TimelineProps = {
    steps: Array<TimelineStepProps>;
    color?: PaletteColor;
};

const Timeline: React.FunctionComponent<TimelineProps> = function (props: TimelineProps) {
    const { steps, color } = props;
    const { palette, breakpoints } = useTheme();
    const downMdBreakpoint = useMediaQuery(breakpoints.down('md'));
    const mainColor = color || palette.primary;
    let currentDone = false;
    const currentIndex = steps.reduce((accumulator, step) => {
        currentDone = currentDone || step.current;

        return !currentDone || step.current ? accumulator + 1 : accumulator;
    }, -1);

    const stepsWrapperStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: downMdBreakpoint ? 'column' : 'row',
        justifyContent: 'space-between',
        position: 'relative',
    };

    const stepStyle = {
        display: 'flex',
        flexDirection: downMdBreakpoint ? 'row' : 'column',
        alignItems: 'center',
        position: 'relative',
        flexGrow: '1',
        textAlign: 'center',
        gap: downMdBreakpoint ? '0 10px' : '10px 0',
        padding: downMdBreakpoint ? '30px 0' : '0',
        '&:first-of-type': {
            paddingTop: '0',
        },
        '&:last-child': {
            paddingBottom: '0',
        },
    };

    const stepStateStyle = {
        width: '18px',
        height: '18px',
        borderRadius: '9px',
        alignSelf: 'auto',
        padding: '3px',
        zIndex: 2,
    };

    const insideStepStateStyle = {
        width: '12px',
        height: '12px',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
    };

    const iconStyle = {
        fontWeight: '500',
        fontSize: '6px',
        lineHeight: '14.4px',
        color: palette.background.paper,
    };

    const textStyle = {
        fontWeight: '500',
        fontSize: downMdBreakpoint ? '14px ' : '12px',
        lineHeight: '14.4px',
        color: palette.grey[500],
    };

    const lineStyle = {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        height: '2px',
    };

    return (
        <Box
            sx={stepsWrapperStyle}
            data-testid="timeline"
        >
            {steps.map((step, index) => {
                const disabled = currentIndex < index;
                const beforeHorizontalWidth =
                    index === steps.length - 1 ? 'calc(100% - 12px)' : 'calc(50% - 12px)';
                const afterHorizontalWidth = index === 0 ? 'calc(100% - 12px)' : 'calc(50% - 12px)';
                const beforeHorizontalHeight =
                    index === steps.length - 1 ? 'calc(100% - 9px)' : 'calc(50% - 9px)';
                const afterHorizontalHeight = index === 0 ? '100%' : '50%';
                const afterHorizontalTop = index === 0 ? '0' : '50%';
                const currentStepStyle = {
                    ...stepStyle,
                    '&:before': {
                        ...lineStyle,
                        display: index > 0 ? 'block' : 'none',
                        left: downMdBreakpoint ? '8px' : '0',
                        top: downMdBreakpoint ? '0' : '8px',
                        width: downMdBreakpoint ? '2px' : beforeHorizontalWidth,
                        height: downMdBreakpoint ? beforeHorizontalHeight : '2px',
                        backgroundColor:
                            currentIndex >= index ? mainColor.main : lighten(mainColor.main, 0.92),
                        borderTopRightRadius: '1px',
                        borderBottomRightRadius: '1px',
                    },
                    '&:after': {
                        ...lineStyle,
                        display: index < steps.length - 1 ? 'block' : 'none',
                        left: downMdBreakpoint ? '8px' : 'auto',
                        right: downMdBreakpoint ? 'auto' : '0',
                        top: downMdBreakpoint ? afterHorizontalTop : '8px',
                        width: downMdBreakpoint ? '2px' : afterHorizontalWidth,
                        height: downMdBreakpoint ? afterHorizontalHeight : '2px',
                        backgroundColor:
                            currentIndex > index ? mainColor.main : lighten(mainColor.main, 0.92),
                        borderTopLeftRadius: '1px',
                        borderBottomLeftRadius: '1px',
                    },
                };
                const currentStepStateStyle = {
                    ...stepStateStyle,
                    backgroundColor: step.current ? mainColor.light : palette.background.paper,
                };

                const currentInsideStepStateStyle = {
                    ...insideStepStateStyle,
                    backgroundColor: !disabled ? mainColor.main : lighten(mainColor.main, 0.92),
                };

                if (index === 0) {
                    currentStepStyle.alignItems = 'flex-start';
                    currentStepStateStyle.alignSelf = 'flex-start';
                }

                if (index === steps.length - 1) {
                    currentStepStyle.alignItems = 'flex-end';
                    currentStepStateStyle.alignSelf = 'flex-end';
                }

                return (
                    <Box
                        sx={currentStepStyle}
                        key={`timeline-step-${step.label}`}
                    >
                        <Box sx={currentStepStateStyle}>
                            <Box sx={currentInsideStepStateStyle}>
                                {step.icon && (
                                    <FaOptionIcon
                                        sx={iconStyle}
                                        icon={['fasdl', step.icon]}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Typography
                            component="span"
                            sx={textStyle}
                        >
                            {step.label}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

export default Timeline;
