import React, { useCallback, useEffect, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import models from '@b2d/pages/Achats/models';
import { pausedStatementIcon } from '@europrocurement/l2d-icons';
import { customizerSelector } from '@europrocurement/l2d-modules';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import WidgetWithAction from './components/WidgetWithAction';

// interne

const RelevesNonClotures: React.FunctionComponent = function () {
    const navigate = useNavigate();

    const { xIdSociete } = useSelector(customizerSelector);

    const [countOfUnclosedStatements, setCountOfUnclosedStatements] = useState<string>();

    useEffect(() => {
        models.invoiceStatement
            .list({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: false,
                deleted: false,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setCountOfUnclosedStatements(`${res.data['hydra:totalItems']}`);
                }
            });
    }, [xIdSociete]);

    const actionCallback = useCallback(async () => {
        navigate(achatsViewPathResolver('listInvoiceStatement'));
    }, [navigate]);

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <WidgetWithAction
                title="Relevés non clôturés"
                icon={pausedStatementIcon}
                actionMessage="Traiter"
                actionCallback={actionCallback}
                data={countOfUnclosedStatements}
                paragraph="Relevés non clôturés :"
                color={models.invoiceStatement.color}
            />
        </Grid>
    );
};

export default RelevesNonClotures;
