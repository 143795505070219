/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement API Utilisateur
 * API de gestion des utilisateurs
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApiDomainesGetCollection200Response
 */
export interface ApiDomainesGetCollection200Response {
    /**
     * 
     * @type {Array<DomaineJsonldDomaineRead>}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:member': Array<DomaineJsonldDomaineRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection200ResponseHydraSearch
 */
export interface ApiDomainesGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<ApiDomainesGetCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiDomainesGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiDomainesGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiDomainesGetCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiDomainesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection200ResponseHydraView
 */
export interface ApiDomainesGetCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection401Response
 */
export interface ApiDomainesGetCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection403Response
 */
export interface ApiDomainesGetCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiDomainesGetCollection403ResponseException}
     * @memberof ApiDomainesGetCollection403Response
     */
    'exception'?: ApiDomainesGetCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection403ResponseException
 */
export interface ApiDomainesGetCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDomainesGetCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection404Response
 */
export interface ApiDomainesGetCollection404Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection404Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiDomainesGetCollection404ResponseException}
     * @memberof ApiDomainesGetCollection404Response
     */
    'exception'?: ApiDomainesGetCollection404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection404ResponseException
 */
export interface ApiDomainesGetCollection404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDomainesGetCollection404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDomainesGetCollection404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection200Response
 */
export interface ApiDroitGetCollection200Response {
    /**
     * 
     * @type {Array<DroitsJsonldDroitReadCollection>}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:member': Array<DroitsJsonldDroitReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiGroupesGetCollection200Response
 */
export interface ApiGroupesGetCollection200Response {
    /**
     * 
     * @type {Array<GroupesJsonldGroupReadCollection>}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:member': Array<GroupesJsonldGroupReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiGroupestravailGetCollection200Response
 */
export interface ApiGroupestravailGetCollection200Response {
    /**
     * 
     * @type {Array<GroupesDeTravailJsonldUtilisateurReadDomaineRead>}
     * @memberof ApiGroupestravailGetCollection200Response
     */
    'hydra:member': Array<GroupesDeTravailJsonldUtilisateurReadDomaineRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiGroupestravailGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiGroupestravailGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiGroupestravailGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiGroupestravailPost400Response
 */
export interface ApiGroupestravailPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiGroupestravailPost400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiGroupestravailPost400ResponseException}
     * @memberof ApiGroupestravailPost400Response
     */
    'exception'?: ApiGroupestravailPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiGroupestravailPost400ResponseException
 */
export interface ApiGroupestravailPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiGroupestravailPost400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGroupestravailPost400ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiJournauxGetCollection200Response
 */
export interface ApiJournauxGetCollection200Response {
    /**
     * 
     * @type {Array<JournauxJsonldJournauxReadCollection>}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:member': Array<JournauxJsonldJournauxReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
 */
export interface ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response {
    /**
     * 
     * @type {Array<UserJsonld>}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:member': Array<UserJsonld>;
    /**
     * 
     * @type {number}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiMarquesGetCollection200Response
 */
export interface ApiMarquesGetCollection200Response {
    /**
     * 
     * @type {Array<MarquesJsonldSocieteMarqueReadCollection>}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:member': Array<MarquesJsonldSocieteMarqueReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiPolesGetCollection200Response
 */
export interface ApiPolesGetCollection200Response {
    /**
     * 
     * @type {Array<PolesJsonldDefPoleReadCollection>}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:member': Array<PolesJsonldDefPoleReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiServicesGetCollection200Response
 */
export interface ApiServicesGetCollection200Response {
    /**
     * 
     * @type {Array<ServicesJsonldServiceReadCollection>}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:member': Array<ServicesJsonldServiceReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiUtilisateurgroupetravailGetCollection200Response
 */
export interface ApiUtilisateurgroupetravailGetCollection200Response {
    /**
     * 
     * @type {Array<JointureUtilisateurGroupeTravailJsonldUserworkgroupRead>}
     * @memberof ApiUtilisateurgroupetravailGetCollection200Response
     */
    'hydra:member': Array<JointureUtilisateurGroupeTravailJsonldUserworkgroupRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiUtilisateurgroupetravailGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiUtilisateurgroupetravailGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiUtilisateurgroupetravailGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiUtilisateursGetCollection200Response
 */
export interface ApiUtilisateursGetCollection200Response {
    /**
     * 
     * @type {Array<UtilisateursJsonldUtilisateurRead>}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:member': Array<UtilisateursJsonldUtilisateurRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraView}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:view'?: ApiDomainesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDomainesGetCollection200ResponseHydraSearch}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:search'?: ApiDomainesGetCollection200ResponseHydraSearch;
}
/**
 * Returns the id corresponding domaine
 * @export
 * @interface DomaineDomaineRead
 */
export interface DomaineDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof DomaineDomaineRead
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomaineDomaineRead
     */
    'ddmLibelle'?: string | null;
}
/**
 * Returns the id corresponding domaine
 * @export
 * @interface DomaineJsonldDomaineRead
 */
export interface DomaineJsonldDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof DomaineJsonldDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomaineJsonldDomaineRead
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldDomaineRead
     */
    'ddmLibelle'?: string | null;
}
/**
 * @type DomaineJsonldDomaineReadContext
 * @export
 */
export type DomaineJsonldDomaineReadContext = DomaineJsonldDomaineReadContextOneOf | string;

/**
 * 
 * @export
 * @interface DomaineJsonldDomaineReadContextOneOf
 */
export interface DomaineJsonldDomaineReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldDomaineReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldDomaineReadContextOneOf
     */
    'hydra': DomaineJsonldDomaineReadContextOneOfHydraEnum;
}

export const DomaineJsonldDomaineReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type DomaineJsonldDomaineReadContextOneOfHydraEnum = typeof DomaineJsonldDomaineReadContextOneOfHydraEnum[keyof typeof DomaineJsonldDomaineReadContextOneOfHydraEnum];

/**
 * Returns the id corresponding domaine
 * @export
 * @interface DomaineJsonldUtilisateurReadDomaineRead
 */
export interface DomaineJsonldUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof DomaineJsonldUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomaineJsonldUtilisateurReadDomaineRead
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldUtilisateurReadDomaineRead
     */
    'ddmLibelle'?: string | null;
}
/**
 * Returns the id corresponding domaine
 * @export
 * @interface DomaineJsonldWorkgroupReadUtilisateurReadDomaineRead
 */
export interface DomaineJsonldWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof DomaineJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomaineJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomaineJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'ddmLibelle'?: string | null;
}
/**
 * Returns the id corresponding domaine
 * @export
 * @interface DomaineUtilisateurReadDomaineRead
 */
export interface DomaineUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof DomaineUtilisateurReadDomaineRead
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomaineUtilisateurReadDomaineRead
     */
    'ddmLibelle'?: string | null;
}
/**
 * Returns the id corresponding domaine
 * @export
 * @interface DomaineWorkgroupReadUtilisateurReadDomaineRead
 */
export interface DomaineWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof DomaineWorkgroupReadUtilisateurReadDomaineRead
     */
    'ddmId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomaineWorkgroupReadUtilisateurReadDomaineRead
     */
    'ddmLibelle'?: string | null;
}
/**
 * Returns all resources from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsDroitReadCollection
 */
export interface DroitsDroitReadCollection {
    /**
     * 
     * @type {number}
     * @memberof DroitsDroitReadCollection
     */
    'usdId'?: number;
    /**
     * 
     * @type {UtilisateursDroitReadCollection}
     * @memberof DroitsDroitReadCollection
     */
    'iduser'?: UtilisateursDroitReadCollection | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns all resources from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldDroitReadCollection
 */
export interface DroitsJsonldDroitReadCollection {
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdId'?: number;
    /**
     * 
     * @type {UtilisateursJsonldDroitReadCollection}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'iduser'?: UtilisateursJsonldDroitReadCollection | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldUtilisateurPatchUtilisateur
 */
export interface DroitsJsonldUtilisateurPatchUtilisateur {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurPatchUtilisateur
     */
    'usdAppsMdcSpelOption'?: string | null;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldUtilisateurRead
 */
export interface DroitsJsonldUtilisateurRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof DroitsJsonldUtilisateurRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldUtilisateurReadDomaineRead
 */
export interface DroitsJsonldUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurReadDomaineRead
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
 */
export interface DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsMdcSpelOption'?: string | null;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldUtilisateurWrite
 */
export interface DroitsJsonldUtilisateurWrite {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurWrite
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
 */
export interface DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsUtilisateurPatchUtilisateur
 */
export interface DroitsUtilisateurPatchUtilisateur {
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurPatchUtilisateur
     */
    'usdAppsMdcSpelOption'?: string | null;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsUtilisateurRead
 */
export interface DroitsUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof DroitsUtilisateurRead
     */
    'usdId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsUtilisateurReadDomaineRead
 */
export interface DroitsUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurReadDomaineRead
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
 */
export interface DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur {
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'usdAppsMdcSpelOption'?: string | null;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsUtilisateurWrite
 */
export interface DroitsUtilisateurWrite {
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurWrite
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsWorkgroupReadUtilisateurReadDomaineRead
 */
export interface DroitsWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsWorkgroupReadUtilisateurReadDomaineRead
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Creates a new workgroup
 * @export
 * @interface GroupesDeTravail
 */
export interface GroupesDeTravail {
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravail
     */
    'idGroupeTravail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravail
     */
    'lib'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravail
     */
    'domaine'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravail
     */
    'fDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravail
     */
    'iduserReferent'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravail
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravail
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravail
     */
    'dCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravail
     */
    'dCloture'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupesDeTravail
     */
    'inscriptions'?: Array<string> | null;
}
/**
 * Creates a new workgroup
 * @export
 * @interface GroupesDeTravailJsonld
 */
export interface GroupesDeTravailJsonld {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof GroupesDeTravailJsonld
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    'idGroupeTravail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    'lib'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    'domaine'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravailJsonld
     */
    'fDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    'iduserReferent'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    'dCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonld
     */
    'dCloture'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupesDeTravailJsonld
     */
    'inscriptions'?: Array<string> | null;
}
/**
 * Delete the user workgroup relation
 * @export
 * @interface GroupesDeTravailJsonldLock
 */
export interface GroupesDeTravailJsonldLock {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof GroupesDeTravailJsonldLock
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldLock
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldLock
     */
    '@type'?: string;
}
/**
 * Returns the id corresponding workgroup
 * @export
 * @interface GroupesDeTravailJsonldUserworkgroupRead
 */
export interface GroupesDeTravailJsonldUserworkgroupRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof GroupesDeTravailJsonldUserworkgroupRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUserworkgroupRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUserworkgroupRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupesDeTravailJsonldUserworkgroupRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUserworkgroupRead
     */
    'lib': string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravailJsonldUserworkgroupRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {UtilisateursJsonldUserworkgroupRead}
     * @memberof GroupesDeTravailJsonldUserworkgroupRead
     */
    'iduserReferent'?: UtilisateursJsonldUserworkgroupRead | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUserworkgroupRead
     */
    'dCloture'?: string | null;
}
/**
 * Returns the id corresponding workgroup
 * @export
 * @interface GroupesDeTravailJsonldUtilisateurRead
 */
export interface GroupesDeTravailJsonldUtilisateurRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    'lib': string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    'domaine'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {UtilisateursJsonldUtilisateurRead}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    'iduserReferent'?: UtilisateursJsonldUtilisateurRead | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    'dCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurRead
     */
    'dCloture'?: string | null;
}
/**
 * Returns the workgroups collection
 * @export
 * @interface GroupesDeTravailJsonldUtilisateurReadDomaineRead
 */
export interface GroupesDeTravailJsonldUtilisateurReadDomaineRead {
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {number}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    'lib': string;
    /**
     * 
     * @type {DomaineJsonldUtilisateurReadDomaineRead}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    'domaine'?: DomaineJsonldUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {UtilisateursJsonldUtilisateurReadDomaineRead}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    'iduserReferent'?: UtilisateursJsonldUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    'dCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldUtilisateurReadDomaineRead
     */
    'dCloture'?: string | null;
}
/**
 * Returns the id corresponding workgroup
 * @export
 * @interface GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
 */
export interface GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'lib': string;
    /**
     * 
     * @type {DomaineJsonldWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'domaine'?: DomaineJsonldWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'iduserReferent'?: UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'dCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'dCloture'?: string | null;
    /**
     * 
     * @type {Array<UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead>}
     * @memberof GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'members'?: Array<UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead>;
}
/**
 * Returns the id corresponding workgroup
 * @export
 * @interface GroupesDeTravailUserworkgroupRead
 */
export interface GroupesDeTravailUserworkgroupRead {
    /**
     * 
     * @type {number}
     * @memberof GroupesDeTravailUserworkgroupRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUserworkgroupRead
     */
    'lib': string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravailUserworkgroupRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {UtilisateursUserworkgroupRead}
     * @memberof GroupesDeTravailUserworkgroupRead
     */
    'iduserReferent'?: UtilisateursUserworkgroupRead | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUserworkgroupRead
     */
    'dCloture'?: string | null;
}
/**
 * Returns the id corresponding workgroup
 * @export
 * @interface GroupesDeTravailUtilisateurRead
 */
export interface GroupesDeTravailUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof GroupesDeTravailUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurRead
     */
    'lib': string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurRead
     */
    'domaine'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravailUtilisateurRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {UtilisateursUtilisateurRead}
     * @memberof GroupesDeTravailUtilisateurRead
     */
    'iduserReferent'?: UtilisateursUtilisateurRead | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurRead
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurRead
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurRead
     */
    'dCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurRead
     */
    'dCloture'?: string | null;
}
/**
 * Returns the workgroups collection
 * @export
 * @interface GroupesDeTravailUtilisateurReadDomaineRead
 */
export interface GroupesDeTravailUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof GroupesDeTravailUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurReadDomaineRead
     */
    'lib': string;
    /**
     * 
     * @type {DomaineUtilisateurReadDomaineRead}
     * @memberof GroupesDeTravailUtilisateurReadDomaineRead
     */
    'domaine'?: DomaineUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravailUtilisateurReadDomaineRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {UtilisateursUtilisateurReadDomaineRead}
     * @memberof GroupesDeTravailUtilisateurReadDomaineRead
     */
    'iduserReferent'?: UtilisateursUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurReadDomaineRead
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurReadDomaineRead
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurReadDomaineRead
     */
    'dCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailUtilisateurReadDomaineRead
     */
    'dCloture'?: string | null;
}
/**
 * Returns the id corresponding workgroup
 * @export
 * @interface GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
 */
export interface GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'lib': string;
    /**
     * 
     * @type {DomaineWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'domaine'?: DomaineWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {UtilisateursWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'iduserReferent'?: UtilisateursWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'dCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'dCloture'?: string | null;
    /**
     * 
     * @type {Array<UtilisateursWorkgroupReadUtilisateurReadDomaineRead>}
     * @memberof GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'members'?: Array<UtilisateursWorkgroupReadUtilisateurReadDomaineRead>;
}
/**
 * Returns the groups collection
 * @export
 * @interface GroupesGroupReadCollection
 */
export interface GroupesGroupReadCollection {
    /**
     * 
     * @type {number}
     * @memberof GroupesGroupReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesGroupReadCollection
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesGroupReadCollection
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesGroupReadCollection
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesGroupReadCollection
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesGroupReadCollection
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupesGroupReadCollection
     */
    'group'?: number;
}
/**
 * Returns the groups collection
 * @export
 * @interface GroupesJsonldGroupReadCollection
 */
export interface GroupesJsonldGroupReadCollection {
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'group'?: number;
}
/**
 * 
 * @export
 * @interface GroupesJsonldUtilisateurRead
 */
export interface GroupesJsonldUtilisateurRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof GroupesJsonldUtilisateurRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupesJsonldUtilisateurReadDomaineRead
 */
export interface GroupesJsonldUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof GroupesJsonldUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurReadDomaineRead
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurReadDomaineRead
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesJsonldUtilisateurReadDomaineRead
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldUtilisateurReadDomaineRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurReadDomaineRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
 */
export interface GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupesUtilisateurRead
 */
export interface GroupesUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof GroupesUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesUtilisateurRead
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesUtilisateurRead
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesUtilisateurRead
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesUtilisateurRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesUtilisateurRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupesUtilisateurReadDomaineRead
 */
export interface GroupesUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof GroupesUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesUtilisateurReadDomaineRead
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesUtilisateurReadDomaineRead
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesUtilisateurReadDomaineRead
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesUtilisateurReadDomaineRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesUtilisateurReadDomaineRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupesWorkgroupReadUtilisateurReadDomaineRead
 */
export interface GroupesWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof GroupesWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesWorkgroupReadUtilisateurReadDomaineRead
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesWorkgroupReadUtilisateurReadDomaineRead
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesWorkgroupReadUtilisateurReadDomaineRead
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesWorkgroupReadUtilisateurReadDomaineRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesWorkgroupReadUtilisateurReadDomaineRead
     */
    'dateMaj'?: string | null;
}
/**
 * Get user/workgroup inscription collection
 * @export
 * @interface JointureUtilisateurGroupeTravailJsonldUserworkgroupRead
 */
export interface JointureUtilisateurGroupeTravailJsonldUserworkgroupRead {
    /**
     * 
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailJsonldUserworkgroupRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailJsonldUserworkgroupRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof JointureUtilisateurGroupeTravailJsonldUserworkgroupRead
     */
    'id'?: number;
    /**
     * 
     * @type {UtilisateursJsonldUserworkgroupRead}
     * @memberof JointureUtilisateurGroupeTravailJsonldUserworkgroupRead
     */
    'user'?: UtilisateursJsonldUserworkgroupRead | null;
    /**
     * 
     * @type {GroupesDeTravailJsonldUserworkgroupRead}
     * @memberof JointureUtilisateurGroupeTravailJsonldUserworkgroupRead
     */
    'groupetravail'?: GroupesDeTravailJsonldUserworkgroupRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof JointureUtilisateurGroupeTravailJsonldUserworkgroupRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailJsonldUserworkgroupRead
     */
    'dDebut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailJsonldUserworkgroupRead
     */
    'dFin'?: string | null;
}
/**
 * Returns the id corresponding inscription
 * @export
 * @interface JointureUtilisateurGroupeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
 */
export interface JointureUtilisateurGroupeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof JointureUtilisateurGroupeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof JointureUtilisateurGroupeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
}
/**
 * Get user/workgroup inscription collection
 * @export
 * @interface JointureUtilisateurGroupeTravailUserworkgroupRead
 */
export interface JointureUtilisateurGroupeTravailUserworkgroupRead {
    /**
     * 
     * @type {number}
     * @memberof JointureUtilisateurGroupeTravailUserworkgroupRead
     */
    'id'?: number;
    /**
     * 
     * @type {UtilisateursUserworkgroupRead}
     * @memberof JointureUtilisateurGroupeTravailUserworkgroupRead
     */
    'user'?: UtilisateursUserworkgroupRead | null;
    /**
     * 
     * @type {GroupesDeTravailUserworkgroupRead}
     * @memberof JointureUtilisateurGroupeTravailUserworkgroupRead
     */
    'groupetravail'?: GroupesDeTravailUserworkgroupRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof JointureUtilisateurGroupeTravailUserworkgroupRead
     */
    'fDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailUserworkgroupRead
     */
    'dDebut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailUserworkgroupRead
     */
    'dFin'?: string | null;
}
/**
 * Returns the id corresponding inscription
 * @export
 * @interface JointureUtilisateurGroupeTravailWorkgroupReadUtilisateurReadDomaineRead
 */
export interface JointureUtilisateurGroupeTravailWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof JointureUtilisateurGroupeTravailWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
}
/**
 * Returns all resources from Tbl_Utilisateur_Journaux
 * @export
 * @interface JournauxJournauxReadCollection
 */
export interface JournauxJournauxReadCollection {
    /**
     * 
     * @type {number}
     * @memberof JournauxJournauxReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournauxJournauxReadCollection
     */
    'name'?: string | null;
}
/**
 * Returns all resources from Tbl_Utilisateur_Journaux
 * @export
 * @interface JournauxJsonldJournauxReadCollection
 */
export interface JournauxJsonldJournauxReadCollection {
    /**
     * 
     * @type {string}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    'name'?: string | null;
}
/**
 * Returns the society\'s brands list
 * @export
 * @interface MarquesJsonldSocieteMarqueReadCollection
 */
export interface MarquesJsonldSocieteMarqueReadCollection {
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'piedDePage'?: string | null;
}
/**
 * 
 * @export
 * @interface MarquesJsonldUtilisateurRead
 */
export interface MarquesJsonldUtilisateurRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof MarquesJsonldUtilisateurRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'piedDePage'?: string | null;
}
/**
 * 
 * @export
 * @interface MarquesJsonldUtilisateurReadDomaineRead
 */
export interface MarquesJsonldUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurReadDomaineRead
     */
    'piedDePage'?: string | null;
}
/**
 * 
 * @export
 * @interface MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
 */
export interface MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'piedDePage'?: string | null;
}
/**
 * Returns the society\'s brands list
 * @export
 * @interface MarquesSocieteMarqueReadCollection
 */
export interface MarquesSocieteMarqueReadCollection {
    /**
     * 
     * @type {number}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'piedDePage'?: string | null;
}
/**
 * 
 * @export
 * @interface MarquesUtilisateurRead
 */
export interface MarquesUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesUtilisateurRead
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurRead
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'piedDePage'?: string | null;
}
/**
 * 
 * @export
 * @interface MarquesUtilisateurReadDomaineRead
 */
export interface MarquesUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurReadDomaineRead
     */
    'piedDePage'?: string | null;
}
/**
 * 
 * @export
 * @interface MarquesWorkgroupReadUtilisateurReadDomaineRead
 */
export interface MarquesWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesWorkgroupReadUtilisateurReadDomaineRead
     */
    'piedDePage'?: string | null;
}
/**
 * Returns the TBL poles collection
 * @export
 * @interface PolesDefPoleReadCollection
 */
export interface PolesDefPoleReadCollection {
    /**
     * 
     * @type {number}
     * @memberof PolesDefPoleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesDefPoleReadCollection
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesDefPoleReadCollection
     */
    'info'?: string | null;
}
/**
 * Returns the TBL poles collection
 * @export
 * @interface PolesJsonldDefPoleReadCollection
 */
export interface PolesJsonldDefPoleReadCollection {
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface PolesJsonldUtilisateurRead
 */
export interface PolesJsonldUtilisateurRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof PolesJsonldUtilisateurRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PolesJsonldUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface PolesJsonldUtilisateurReadDomaineRead
 */
export interface PolesJsonldUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof PolesJsonldUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PolesJsonldUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurReadDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurReadDomaineRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface PolesJsonldWorkgroupReadUtilisateurReadDomaineRead
 */
export interface PolesJsonldWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof PolesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PolesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface PolesUtilisateurRead
 */
export interface PolesUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof PolesUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesUtilisateurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesUtilisateurRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface PolesUtilisateurReadDomaineRead
 */
export interface PolesUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof PolesUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesUtilisateurReadDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesUtilisateurReadDomaineRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface PolesWorkgroupReadUtilisateurReadDomaineRead
 */
export interface PolesWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof PolesWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesWorkgroupReadUtilisateurReadDomaineRead
     */
    'info'?: string | null;
}
/**
 * Returns all resources from Tbl_Def_Service
 * @export
 * @interface ServicesJsonldServiceReadCollection
 */
export interface ServicesJsonldServiceReadCollection {
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldServiceReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldServiceReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServicesJsonldServiceReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldServiceReadCollection
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldServiceReadCollection
     */
    'info'?: string | null;
}
/**
 * Resources from Tbl_Def_Service
 * @export
 * @interface ServicesJsonldUtilisateurRead
 */
export interface ServicesJsonldUtilisateurRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof ServicesJsonldUtilisateurRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServicesJsonldUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurRead
     */
    'info'?: string | null;
}
/**
 * Resources from Tbl_Def_Service
 * @export
 * @interface ServicesJsonldUtilisateurReadDomaineRead
 */
export interface ServicesJsonldUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof ServicesJsonldUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServicesJsonldUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurReadDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurReadDomaineRead
     */
    'info'?: string | null;
}
/**
 * Resources from Tbl_Def_Service
 * @export
 * @interface ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead
 */
export interface ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'info'?: string | null;
}
/**
 * Returns all resources from Tbl_Def_Service
 * @export
 * @interface ServicesServiceReadCollection
 */
export interface ServicesServiceReadCollection {
    /**
     * 
     * @type {number}
     * @memberof ServicesServiceReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesServiceReadCollection
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesServiceReadCollection
     */
    'info'?: string | null;
}
/**
 * Resources from Tbl_Def_Service
 * @export
 * @interface ServicesUtilisateurRead
 */
export interface ServicesUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof ServicesUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesUtilisateurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesUtilisateurRead
     */
    'info'?: string | null;
}
/**
 * Resources from Tbl_Def_Service
 * @export
 * @interface ServicesUtilisateurReadDomaineRead
 */
export interface ServicesUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof ServicesUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesUtilisateurReadDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesUtilisateurReadDomaineRead
     */
    'info'?: string | null;
}
/**
 * Resources from Tbl_Def_Service
 * @export
 * @interface ServicesWorkgroupReadUtilisateurReadDomaineRead
 */
export interface ServicesWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof ServicesWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesWorkgroupReadUtilisateurReadDomaineRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'emailVerified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'idPrescripteur'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserCreateUser
 */
export interface UserCreateUser {
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'username': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUser
     */
    'enabled': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUser
     */
    'emailVerified': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'password': string | null;
    /**
     * 
     * @type {number}
     * @memberof UserCreateUser
     */
    'idPrescripteur': number | null;
}
/**
 * 
 * @export
 * @interface UserCreateUserJsonld
 */
export interface UserCreateUserJsonld {
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'username': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUserJsonld
     */
    'enabled': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUserJsonld
     */
    'emailVerified': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'password': string | null;
    /**
     * 
     * @type {number}
     * @memberof UserCreateUserJsonld
     */
    'idPrescripteur': number | null;
}
/**
 * 
 * @export
 * @interface UserJsonld
 */
export interface UserJsonld {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UserJsonld
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'username'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserJsonld
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserJsonld
     */
    'emailVerified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserJsonld
     */
    'idPrescripteur'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserUpdateUser
 */
export interface UserUpdateUser {
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateUser
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateUser
     */
    'emailVerified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateUser
     */
    'idPrescripteur'?: Array<string> | null;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursDroitReadCollection
 */
export interface UtilisateursDroitReadCollection {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursDroitReadCollection
     */
    'id'?: number;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursJsonldDroitReadCollection
 */
export interface UtilisateursJsonldDroitReadCollection {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UtilisateursJsonldDroitReadCollection
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldDroitReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldDroitReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldDroitReadCollection
     */
    'id'?: number;
}
/**
 * Delete the user
 * @export
 * @interface UtilisateursJsonldLock
 */
export interface UtilisateursJsonldLock {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UtilisateursJsonldLock
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldLock
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldLock
     */
    '@type'?: string;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursJsonldUserworkgroupRead
 */
export interface UtilisateursJsonldUserworkgroupRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UtilisateursJsonldUserworkgroupRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUserworkgroupRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUserworkgroupRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUserworkgroupRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUserworkgroupRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUserworkgroupRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUserworkgroupRead
     */
    'mail'?: string | null;
}
/**
 * Updates only the selected informations of the current user
 * @export
 * @interface UtilisateursJsonldUtilisateurPatchUtilisateur
 */
export interface UtilisateursJsonldUtilisateurPatchUtilisateur {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'userGroupe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'marque'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsJsonldUtilisateurPatchUtilisateur}
     * @memberof UtilisateursJsonldUtilisateurPatchUtilisateur
     */
    'droitUtilisateur'?: DroitsJsonldUtilisateurPatchUtilisateur | null;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursJsonldUtilisateurRead
 */
export interface UtilisateursJsonldUtilisateurRead {
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'login'?: string | null;
    /**
     * 
     * @type {GroupesJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'userGroupe'?: GroupesJsonldUtilisateurRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'user'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'userKeycloakId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {PolesJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'pole'?: PolesJsonldUtilisateurRead | null;
    /**
     * 
     * @type {ServicesJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'service'?: ServicesJsonldUtilisateurRead | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {MarquesJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'marque'?: MarquesJsonldUtilisateurRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'droitUtilisateur'?: DroitsJsonldUtilisateurRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'libelleSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'libelleSocieteEmployeur'?: string | null;
    /**
     * 
     * @type {Array<GroupesDeTravailJsonldUtilisateurRead>}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'groupeTravail'?: Array<GroupesDeTravailJsonldUtilisateurRead>;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursJsonldUtilisateurReadDomaineRead
 */
export interface UtilisateursJsonldUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'login'?: string | null;
    /**
     * 
     * @type {GroupesJsonldUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'userGroupe'?: GroupesJsonldUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'user'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'userKeycloakId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {PolesJsonldUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'pole'?: PolesJsonldUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {ServicesJsonldUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'service'?: ServicesJsonldUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {MarquesJsonldUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'marque'?: MarquesJsonldUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'droitUtilisateur'?: DroitsJsonldUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'libelleSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'libelleSocieteEmployeur'?: string | null;
    /**
     * 
     * @type {Array<GroupesDeTravailJsonldUtilisateurReadDomaineRead>}
     * @memberof UtilisateursJsonldUtilisateurReadDomaineRead
     */
    'groupeTravail'?: Array<GroupesDeTravailJsonldUtilisateurReadDomaineRead>;
}
/**
 * Updates the selected user informations
 * @export
 * @interface UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
 */
export interface UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'userGroupe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'marque'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'droitUtilisateur'?: DroitsJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur | null;
}
/**
 * Create a user
 * @export
 * @interface UtilisateursJsonldUtilisateurWrite
 */
export interface UtilisateursJsonldUtilisateurWrite {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'userGroupe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'marque'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsJsonldUtilisateurWrite}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'droitUtilisateur'?: DroitsJsonldUtilisateurWrite | null;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
 */
export interface UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {DomaineJsonldDomaineReadContext}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@context'?: DomaineJsonldDomaineReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'login'?: string | null;
    /**
     * 
     * @type {GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'userGroupe'?: GroupesJsonldWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'user'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'userKeycloakId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {PolesJsonldWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'pole'?: PolesJsonldWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'service'?: ServicesJsonldWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'marque'?: MarquesJsonldWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'droitUtilisateur'?: DroitsJsonldWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelleSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelleSocieteEmployeur'?: string | null;
    /**
     * 
     * @type {Array<GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead>}
     * @memberof UtilisateursJsonldWorkgroupReadUtilisateurReadDomaineRead
     */
    'groupeTravail'?: Array<GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead>;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursUserworkgroupRead
 */
export interface UtilisateursUserworkgroupRead {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUserworkgroupRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUserworkgroupRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUserworkgroupRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUserworkgroupRead
     */
    'mail'?: string | null;
}
/**
 * Updates only the selected informations of the current user
 * @export
 * @interface UtilisateursUtilisateurPatchUtilisateur
 */
export interface UtilisateursUtilisateurPatchUtilisateur {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'userGroupe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'marque'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsUtilisateurPatchUtilisateur}
     * @memberof UtilisateursUtilisateurPatchUtilisateur
     */
    'droitUtilisateur'?: DroitsUtilisateurPatchUtilisateur | null;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursUtilisateurRead
 */
export interface UtilisateursUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'login'?: string | null;
    /**
     * 
     * @type {GroupesUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'userGroupe'?: GroupesUtilisateurRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'user'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'userKeycloakId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {PolesUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'pole'?: PolesUtilisateurRead | null;
    /**
     * 
     * @type {ServicesUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'service'?: ServicesUtilisateurRead | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {MarquesUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'marque'?: MarquesUtilisateurRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'droitUtilisateur'?: DroitsUtilisateurRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'libelleSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'libelleSocieteEmployeur'?: string | null;
    /**
     * 
     * @type {Array<GroupesDeTravailUtilisateurRead>}
     * @memberof UtilisateursUtilisateurRead
     */
    'groupeTravail'?: Array<GroupesDeTravailUtilisateurRead>;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursUtilisateurReadDomaineRead
 */
export interface UtilisateursUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'login'?: string | null;
    /**
     * 
     * @type {GroupesUtilisateurReadDomaineRead}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'userGroupe'?: GroupesUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'user'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'userKeycloakId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {PolesUtilisateurReadDomaineRead}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'pole'?: PolesUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {ServicesUtilisateurReadDomaineRead}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'service'?: ServicesUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {MarquesUtilisateurReadDomaineRead}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'marque'?: MarquesUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsUtilisateurReadDomaineRead}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'droitUtilisateur'?: DroitsUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'libelleSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'libelleSocieteEmployeur'?: string | null;
    /**
     * 
     * @type {Array<GroupesDeTravailUtilisateurReadDomaineRead>}
     * @memberof UtilisateursUtilisateurReadDomaineRead
     */
    'groupeTravail'?: Array<GroupesDeTravailUtilisateurReadDomaineRead>;
}
/**
 * Updates only the selected informations of the current user
 * @export
 * @interface UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
 */
export interface UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'userGroupe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'marque'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur}
     * @memberof UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
     */
    'droitUtilisateur'?: DroitsUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur | null;
}
/**
 * Create a user
 * @export
 * @interface UtilisateursUtilisateurWrite
 */
export interface UtilisateursUtilisateurWrite {
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'userGroupe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'marque'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsUtilisateurWrite}
     * @memberof UtilisateursUtilisateurWrite
     */
    'droitUtilisateur'?: DroitsUtilisateurWrite | null;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursWorkgroupReadUtilisateurReadDomaineRead
 */
export interface UtilisateursWorkgroupReadUtilisateurReadDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'login'?: string | null;
    /**
     * 
     * @type {GroupesWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'userGroupe'?: GroupesWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'user'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'userKeycloakId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {PolesWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'pole'?: PolesWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {ServicesWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'service'?: ServicesWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'idUserG3'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasComptaSoftware'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'idUserDematfacile'?: number;
    /**
     * 
     * @type {MarquesWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'marque'?: MarquesWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasAccessL2DF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'hasAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'isAdminApps'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsWorkgroupReadUtilisateurReadDomaineRead}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'droitUtilisateur'?: DroitsWorkgroupReadUtilisateurReadDomaineRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelleSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'libelleSocieteEmployeur'?: string | null;
    /**
     * 
     * @type {Array<GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead>}
     * @memberof UtilisateursWorkgroupReadUtilisateurReadDomaineRead
     */
    'groupeTravail'?: Array<GroupesDeTravailWorkgroupReadUtilisateurReadDomaineRead>;
}

/**
 * DomaineApi - axios parameter creator
 * @export
 */
export const DomaineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Domaine resource.
         * @summary Retrieves a Domaine resource.
         * @param {string} ddmId Domaine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesDdmIdGet: async (ddmId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ddmId' is not null or undefined
            assertParamExists('apiDomainesDdmIdGet', 'ddmId', ddmId)
            const localVarPath = `/utilisateur/domaines/{ddmId}`
                .replace(`{${"ddmId"}}`, encodeURIComponent(String(ddmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Domaine resources.
         * @summary Retrieves the collection of Domaine resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/domaines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomaineApi - functional programming interface
 * @export
 */
export const DomaineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomaineApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a Domaine resource.
         * @summary Retrieves a Domaine resource.
         * @param {string} ddmId Domaine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDomainesDdmIdGet(ddmId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomaineJsonldDomaineRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDomainesDdmIdGet(ddmId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomaineApi.apiDomainesDdmIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the collection of Domaine resources.
         * @summary Retrieves the collection of Domaine resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDomainesGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDomainesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDomainesGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomaineApi.apiDomainesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DomaineApi - factory interface
 * @export
 */
export const DomaineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomaineApiFp(configuration)
    return {
        /**
         * Retrieves a Domaine resource.
         * @summary Retrieves a Domaine resource.
         * @param {DomaineApiApiDomainesDdmIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesDdmIdGet(requestParameters: DomaineApiApiDomainesDdmIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DomaineJsonldDomaineRead> {
            return localVarFp.apiDomainesDdmIdGet(requestParameters.ddmId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Domaine resources.
         * @summary Retrieves the collection of Domaine resources.
         * @param {DomaineApiApiDomainesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesGetCollection(requestParameters: DomaineApiApiDomainesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiDomainesGetCollection200Response> {
            return localVarFp.apiDomainesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDomainesDdmIdGet operation in DomaineApi.
 * @export
 * @interface DomaineApiApiDomainesDdmIdGetRequest
 */
export interface DomaineApiApiDomainesDdmIdGetRequest {
    /**
     * Domaine identifier
     * @type {string}
     * @memberof DomaineApiApiDomainesDdmIdGet
     */
    readonly ddmId: string
}

/**
 * Request parameters for apiDomainesGetCollection operation in DomaineApi.
 * @export
 * @interface DomaineApiApiDomainesGetCollectionRequest
 */
export interface DomaineApiApiDomainesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DomaineApiApiDomainesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DomaineApiApiDomainesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * DomaineApi - object-oriented interface
 * @export
 * @class DomaineApi
 * @extends {BaseAPI}
 */
export class DomaineApi extends BaseAPI {
    /**
     * Retrieves a Domaine resource.
     * @summary Retrieves a Domaine resource.
     * @param {DomaineApiApiDomainesDdmIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomaineApi
     */
    public apiDomainesDdmIdGet(requestParameters: DomaineApiApiDomainesDdmIdGetRequest, options?: RawAxiosRequestConfig) {
        return DomaineApiFp(this.configuration).apiDomainesDdmIdGet(requestParameters.ddmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Domaine resources.
     * @summary Retrieves the collection of Domaine resources.
     * @param {DomaineApiApiDomainesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomaineApi
     */
    public apiDomainesGetCollection(requestParameters: DomaineApiApiDomainesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DomaineApiFp(this.configuration).apiDomainesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DroitsApi - axios parameter creator
 * @export
 */
export const DroitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Droit
         * @summary Returns all resources from Tbl_Utilisateur_Droit
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDroitGetCollection: async (page?: number, itemsPerPage?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/droit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DroitsApi - functional programming interface
 * @export
 */
export const DroitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DroitsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Droit
         * @summary Returns all resources from Tbl_Utilisateur_Droit
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDroitGetCollection(page?: number, itemsPerPage?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDroitGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDroitGetCollection(page, itemsPerPage, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DroitsApi.apiDroitGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DroitsApi - factory interface
 * @export
 */
export const DroitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DroitsApiFp(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Droit
         * @summary Returns all resources from Tbl_Utilisateur_Droit
         * @param {DroitsApiApiDroitGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDroitGetCollection(requestParameters: DroitsApiApiDroitGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiDroitGetCollection200Response> {
            return localVarFp.apiDroitGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDroitGetCollection operation in DroitsApi.
 * @export
 * @interface DroitsApiApiDroitGetCollectionRequest
 */
export interface DroitsApiApiDroitGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Enable or disable pagination
     * @type {boolean}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly pagination?: boolean
}

/**
 * DroitsApi - object-oriented interface
 * @export
 * @class DroitsApi
 * @extends {BaseAPI}
 */
export class DroitsApi extends BaseAPI {
    /**
     * Returns all resources from Tbl_Utilisateur_Droit
     * @summary Returns all resources from Tbl_Utilisateur_Droit
     * @param {DroitsApiApiDroitGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DroitsApi
     */
    public apiDroitGetCollection(requestParameters: DroitsApiApiDroitGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DroitsApiFp(this.configuration).apiDroitGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupesApi - axios parameter creator
 * @export
 */
export const GroupesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the groups collection.
         * @summary Returns the groups collection.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [flagDelete] 
         * @param {boolean} [gieGroup] Filter by group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupesGetCollection: async (page?: number, itemsPerPage?: number, flagDelete?: boolean, gieGroup?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/groupes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (flagDelete !== undefined) {
                localVarQueryParameter['flagDelete'] = flagDelete;
            }

            if (gieGroup !== undefined) {
                localVarQueryParameter['gieGroup'] = gieGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupesApi - functional programming interface
 * @export
 */
export const GroupesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the groups collection.
         * @summary Returns the groups collection.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [flagDelete] 
         * @param {boolean} [gieGroup] Filter by group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupesGetCollection(page?: number, itemsPerPage?: number, flagDelete?: boolean, gieGroup?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGroupesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupesGetCollection(page, itemsPerPage, flagDelete, gieGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupesApi.apiGroupesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GroupesApi - factory interface
 * @export
 */
export const GroupesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupesApiFp(configuration)
    return {
        /**
         * Returns the groups collection.
         * @summary Returns the groups collection.
         * @param {GroupesApiApiGroupesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupesGetCollection(requestParameters: GroupesApiApiGroupesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiGroupesGetCollection200Response> {
            return localVarFp.apiGroupesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.gieGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiGroupesGetCollection operation in GroupesApi.
 * @export
 * @interface GroupesApiApiGroupesGetCollectionRequest
 */
export interface GroupesApiApiGroupesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly flagDelete?: boolean

    /**
     * Filter by group id
     * @type {boolean}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly gieGroup?: boolean
}

/**
 * GroupesApi - object-oriented interface
 * @export
 * @class GroupesApi
 * @extends {BaseAPI}
 */
export class GroupesApi extends BaseAPI {
    /**
     * Returns the groups collection.
     * @summary Returns the groups collection.
     * @param {GroupesApiApiGroupesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupesApi
     */
    public apiGroupesGetCollection(requestParameters: GroupesApiApiGroupesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return GroupesApiFp(this.configuration).apiGroupesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.gieGroup, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupesDeTravailApi - axios parameter creator
 * @export
 */
export const GroupesDeTravailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the workgroups collection
         * @summary Returns the workgroups collection
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [domaineDdmLibelle] 
         * @param {string} [lib] 
         * @param {ApiGroupestravailGetCollectionOrderDomaineDdmLibelleEnum} [orderDomaineDdmLibelle] 
         * @param {ApiGroupestravailGetCollectionOrderLibEnum} [orderLib] 
         * @param {ApiGroupestravailGetCollectionOrderDCreationEnum} [orderDCreation] 
         * @param {string} [multiFieldSearch] Partial search in chosen fields
         * @param {boolean} [fDeleteStatus] filter active or all workgroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailGetCollection: async (page?: number, itemsPerPage?: number, domaineDdmLibelle?: string, lib?: string, orderDomaineDdmLibelle?: ApiGroupestravailGetCollectionOrderDomaineDdmLibelleEnum, orderLib?: ApiGroupestravailGetCollectionOrderLibEnum, orderDCreation?: ApiGroupestravailGetCollectionOrderDCreationEnum, multiFieldSearch?: string, fDeleteStatus?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/groupestravail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (domaineDdmLibelle !== undefined) {
                localVarQueryParameter['domaine.ddmLibelle'] = domaineDdmLibelle;
            }

            if (lib !== undefined) {
                localVarQueryParameter['lib'] = lib;
            }

            if (orderDomaineDdmLibelle !== undefined) {
                localVarQueryParameter['order[domaine.ddmLibelle]'] = orderDomaineDdmLibelle;
            }

            if (orderLib !== undefined) {
                localVarQueryParameter['order[lib]'] = orderLib;
            }

            if (orderDCreation !== undefined) {
                localVarQueryParameter['order[dCreation]'] = orderDCreation;
            }

            if (multiFieldSearch !== undefined) {
                localVarQueryParameter['MultiFieldSearch'] = multiFieldSearch;
            }

            if (fDeleteStatus !== undefined) {
                localVarQueryParameter['fDeleteStatus'] = fDeleteStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the user workgroup relation
         * @summary Delete the user workgroup relation
         * @param {string} id Groupes de travail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGroupestravailIdDelete', 'id', id)
            const localVarPath = `/utilisateur/groupestravail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Groupes de travail resource.
         * @summary Retrieves a Groupes de travail resource.
         * @param {string} id Groupes de travail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGroupestravailIdGet', 'id', id)
            const localVarPath = `/utilisateur/groupestravail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a workgroup
         * @summary Updates a workgroup
         * @param {string} idGroupeTravail Groupes de travail identifier
         * @param {GroupesDeTravailJsonld} groupesDeTravailJsonld The updated Groupes de travail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailIdGroupeTravailPut: async (idGroupeTravail: string, groupesDeTravailJsonld: GroupesDeTravailJsonld, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idGroupeTravail' is not null or undefined
            assertParamExists('apiGroupestravailIdGroupeTravailPut', 'idGroupeTravail', idGroupeTravail)
            // verify required parameter 'groupesDeTravailJsonld' is not null or undefined
            assertParamExists('apiGroupestravailIdGroupeTravailPut', 'groupesDeTravailJsonld', groupesDeTravailJsonld)
            const localVarPath = `/utilisateur/groupestravail/{idGroupeTravail}`
                .replace(`{${"idGroupeTravail"}}`, encodeURIComponent(String(idGroupeTravail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupesDeTravailJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new workgroup
         * @summary Creates a new workgroup
         * @param {GroupesDeTravailJsonld} groupesDeTravailJsonld The new Groupes de travail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailPost: async (groupesDeTravailJsonld: GroupesDeTravailJsonld, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupesDeTravailJsonld' is not null or undefined
            assertParamExists('apiGroupestravailPost', 'groupesDeTravailJsonld', groupesDeTravailJsonld)
            const localVarPath = `/utilisateur/groupestravail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupesDeTravailJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupesDeTravailApi - functional programming interface
 * @export
 */
export const GroupesDeTravailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupesDeTravailApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the workgroups collection
         * @summary Returns the workgroups collection
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [domaineDdmLibelle] 
         * @param {string} [lib] 
         * @param {ApiGroupestravailGetCollectionOrderDomaineDdmLibelleEnum} [orderDomaineDdmLibelle] 
         * @param {ApiGroupestravailGetCollectionOrderLibEnum} [orderLib] 
         * @param {ApiGroupestravailGetCollectionOrderDCreationEnum} [orderDCreation] 
         * @param {string} [multiFieldSearch] Partial search in chosen fields
         * @param {boolean} [fDeleteStatus] filter active or all workgroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupestravailGetCollection(page?: number, itemsPerPage?: number, domaineDdmLibelle?: string, lib?: string, orderDomaineDdmLibelle?: ApiGroupestravailGetCollectionOrderDomaineDdmLibelleEnum, orderLib?: ApiGroupestravailGetCollectionOrderLibEnum, orderDCreation?: ApiGroupestravailGetCollectionOrderDCreationEnum, multiFieldSearch?: string, fDeleteStatus?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGroupestravailGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupestravailGetCollection(page, itemsPerPage, domaineDdmLibelle, lib, orderDomaineDdmLibelle, orderLib, orderDCreation, multiFieldSearch, fDeleteStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupesDeTravailApi.apiGroupestravailGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the user workgroup relation
         * @summary Delete the user workgroup relation
         * @param {string} id Groupes de travail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupestravailIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupestravailIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupesDeTravailApi.apiGroupestravailIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Groupes de travail resource.
         * @summary Retrieves a Groupes de travail resource.
         * @param {string} id Groupes de travail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupestravailIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupestravailIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupesDeTravailApi.apiGroupestravailIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates a workgroup
         * @summary Updates a workgroup
         * @param {string} idGroupeTravail Groupes de travail identifier
         * @param {GroupesDeTravailJsonld} groupesDeTravailJsonld The updated Groupes de travail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupestravailIdGroupeTravailPut(idGroupeTravail: string, groupesDeTravailJsonld: GroupesDeTravailJsonld, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupesDeTravailJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupestravailIdGroupeTravailPut(idGroupeTravail, groupesDeTravailJsonld, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupesDeTravailApi.apiGroupestravailIdGroupeTravailPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new workgroup
         * @summary Creates a new workgroup
         * @param {GroupesDeTravailJsonld} groupesDeTravailJsonld The new Groupes de travail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupestravailPost(groupesDeTravailJsonld: GroupesDeTravailJsonld, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupesDeTravailJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupestravailPost(groupesDeTravailJsonld, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupesDeTravailApi.apiGroupestravailPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GroupesDeTravailApi - factory interface
 * @export
 */
export const GroupesDeTravailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupesDeTravailApiFp(configuration)
    return {
        /**
         * Returns the workgroups collection
         * @summary Returns the workgroups collection
         * @param {GroupesDeTravailApiApiGroupestravailGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailGetCollection(requestParameters: GroupesDeTravailApiApiGroupestravailGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiGroupestravailGetCollection200Response> {
            return localVarFp.apiGroupestravailGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineDdmLibelle, requestParameters.lib, requestParameters.orderDomaineDdmLibelle, requestParameters.orderLib, requestParameters.orderDCreation, requestParameters.multiFieldSearch, requestParameters.fDeleteStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the user workgroup relation
         * @summary Delete the user workgroup relation
         * @param {GroupesDeTravailApiApiGroupestravailIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailIdDelete(requestParameters: GroupesDeTravailApiApiGroupestravailIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiGroupestravailIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Groupes de travail resource.
         * @summary Retrieves a Groupes de travail resource.
         * @param {GroupesDeTravailApiApiGroupestravailIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailIdGet(requestParameters: GroupesDeTravailApiApiGroupestravailIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupesDeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead> {
            return localVarFp.apiGroupestravailIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a workgroup
         * @summary Updates a workgroup
         * @param {GroupesDeTravailApiApiGroupestravailIdGroupeTravailPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailIdGroupeTravailPut(requestParameters: GroupesDeTravailApiApiGroupestravailIdGroupeTravailPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupesDeTravailJsonld> {
            return localVarFp.apiGroupestravailIdGroupeTravailPut(requestParameters.idGroupeTravail, requestParameters.groupesDeTravailJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new workgroup
         * @summary Creates a new workgroup
         * @param {GroupesDeTravailApiApiGroupestravailPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupestravailPost(requestParameters: GroupesDeTravailApiApiGroupestravailPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupesDeTravailJsonld> {
            return localVarFp.apiGroupestravailPost(requestParameters.groupesDeTravailJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiGroupestravailGetCollection operation in GroupesDeTravailApi.
 * @export
 * @interface GroupesDeTravailApiApiGroupestravailGetCollectionRequest
 */
export interface GroupesDeTravailApiApiGroupestravailGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof GroupesDeTravailApiApiGroupestravailGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof GroupesDeTravailApiApiGroupestravailGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailApiApiGroupestravailGetCollection
     */
    readonly domaineDdmLibelle?: string

    /**
     * 
     * @type {string}
     * @memberof GroupesDeTravailApiApiGroupestravailGetCollection
     */
    readonly lib?: string

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof GroupesDeTravailApiApiGroupestravailGetCollection
     */
    readonly orderDomaineDdmLibelle?: ApiGroupestravailGetCollectionOrderDomaineDdmLibelleEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof GroupesDeTravailApiApiGroupestravailGetCollection
     */
    readonly orderLib?: ApiGroupestravailGetCollectionOrderLibEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof GroupesDeTravailApiApiGroupestravailGetCollection
     */
    readonly orderDCreation?: ApiGroupestravailGetCollectionOrderDCreationEnum

    /**
     * Partial search in chosen fields
     * @type {string}
     * @memberof GroupesDeTravailApiApiGroupestravailGetCollection
     */
    readonly multiFieldSearch?: string

    /**
     * filter active or all workgroups
     * @type {boolean}
     * @memberof GroupesDeTravailApiApiGroupestravailGetCollection
     */
    readonly fDeleteStatus?: boolean
}

/**
 * Request parameters for apiGroupestravailIdDelete operation in GroupesDeTravailApi.
 * @export
 * @interface GroupesDeTravailApiApiGroupestravailIdDeleteRequest
 */
export interface GroupesDeTravailApiApiGroupestravailIdDeleteRequest {
    /**
     * Groupes de travail identifier
     * @type {string}
     * @memberof GroupesDeTravailApiApiGroupestravailIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiGroupestravailIdGet operation in GroupesDeTravailApi.
 * @export
 * @interface GroupesDeTravailApiApiGroupestravailIdGetRequest
 */
export interface GroupesDeTravailApiApiGroupestravailIdGetRequest {
    /**
     * Groupes de travail identifier
     * @type {string}
     * @memberof GroupesDeTravailApiApiGroupestravailIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiGroupestravailIdGroupeTravailPut operation in GroupesDeTravailApi.
 * @export
 * @interface GroupesDeTravailApiApiGroupestravailIdGroupeTravailPutRequest
 */
export interface GroupesDeTravailApiApiGroupestravailIdGroupeTravailPutRequest {
    /**
     * Groupes de travail identifier
     * @type {string}
     * @memberof GroupesDeTravailApiApiGroupestravailIdGroupeTravailPut
     */
    readonly idGroupeTravail: string

    /**
     * The updated Groupes de travail resource
     * @type {GroupesDeTravailJsonld}
     * @memberof GroupesDeTravailApiApiGroupestravailIdGroupeTravailPut
     */
    readonly groupesDeTravailJsonld: GroupesDeTravailJsonld
}

/**
 * Request parameters for apiGroupestravailPost operation in GroupesDeTravailApi.
 * @export
 * @interface GroupesDeTravailApiApiGroupestravailPostRequest
 */
export interface GroupesDeTravailApiApiGroupestravailPostRequest {
    /**
     * The new Groupes de travail resource
     * @type {GroupesDeTravailJsonld}
     * @memberof GroupesDeTravailApiApiGroupestravailPost
     */
    readonly groupesDeTravailJsonld: GroupesDeTravailJsonld
}

/**
 * GroupesDeTravailApi - object-oriented interface
 * @export
 * @class GroupesDeTravailApi
 * @extends {BaseAPI}
 */
export class GroupesDeTravailApi extends BaseAPI {
    /**
     * Returns the workgroups collection
     * @summary Returns the workgroups collection
     * @param {GroupesDeTravailApiApiGroupestravailGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupesDeTravailApi
     */
    public apiGroupestravailGetCollection(requestParameters: GroupesDeTravailApiApiGroupestravailGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return GroupesDeTravailApiFp(this.configuration).apiGroupestravailGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineDdmLibelle, requestParameters.lib, requestParameters.orderDomaineDdmLibelle, requestParameters.orderLib, requestParameters.orderDCreation, requestParameters.multiFieldSearch, requestParameters.fDeleteStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the user workgroup relation
     * @summary Delete the user workgroup relation
     * @param {GroupesDeTravailApiApiGroupestravailIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupesDeTravailApi
     */
    public apiGroupestravailIdDelete(requestParameters: GroupesDeTravailApiApiGroupestravailIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return GroupesDeTravailApiFp(this.configuration).apiGroupestravailIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Groupes de travail resource.
     * @summary Retrieves a Groupes de travail resource.
     * @param {GroupesDeTravailApiApiGroupestravailIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupesDeTravailApi
     */
    public apiGroupestravailIdGet(requestParameters: GroupesDeTravailApiApiGroupestravailIdGetRequest, options?: RawAxiosRequestConfig) {
        return GroupesDeTravailApiFp(this.configuration).apiGroupestravailIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a workgroup
     * @summary Updates a workgroup
     * @param {GroupesDeTravailApiApiGroupestravailIdGroupeTravailPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupesDeTravailApi
     */
    public apiGroupestravailIdGroupeTravailPut(requestParameters: GroupesDeTravailApiApiGroupestravailIdGroupeTravailPutRequest, options?: RawAxiosRequestConfig) {
        return GroupesDeTravailApiFp(this.configuration).apiGroupestravailIdGroupeTravailPut(requestParameters.idGroupeTravail, requestParameters.groupesDeTravailJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new workgroup
     * @summary Creates a new workgroup
     * @param {GroupesDeTravailApiApiGroupestravailPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupesDeTravailApi
     */
    public apiGroupestravailPost(requestParameters: GroupesDeTravailApiApiGroupestravailPostRequest, options?: RawAxiosRequestConfig) {
        return GroupesDeTravailApiFp(this.configuration).apiGroupestravailPost(requestParameters.groupesDeTravailJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiGroupestravailGetCollectionOrderDomaineDdmLibelleEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiGroupestravailGetCollectionOrderDomaineDdmLibelleEnum = typeof ApiGroupestravailGetCollectionOrderDomaineDdmLibelleEnum[keyof typeof ApiGroupestravailGetCollectionOrderDomaineDdmLibelleEnum];
/**
 * @export
 */
export const ApiGroupestravailGetCollectionOrderLibEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiGroupestravailGetCollectionOrderLibEnum = typeof ApiGroupestravailGetCollectionOrderLibEnum[keyof typeof ApiGroupestravailGetCollectionOrderLibEnum];
/**
 * @export
 */
export const ApiGroupestravailGetCollectionOrderDCreationEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiGroupestravailGetCollectionOrderDCreationEnum = typeof ApiGroupestravailGetCollectionOrderDCreationEnum[keyof typeof ApiGroupestravailGetCollectionOrderDCreationEnum];


/**
 * JointureUtilisateurGroupeTravailApi - axios parameter creator
 * @export
 */
export const JointureUtilisateurGroupeTravailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all relations between users and workgroups
         * @summary Returns all relations between users and workgroups
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [groupetravail] 
         * @param {Array<string>} [groupetravail2] 
         * @param {boolean} [historicInscription] Active or deleted/ended status of inscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateurgroupetravailGetCollection: async (page?: number, itemsPerPage?: number, groupetravail?: string, groupetravail2?: Array<string>, historicInscription?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/utilisateurgroupetravail/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (groupetravail !== undefined) {
                localVarQueryParameter['groupetravail'] = groupetravail;
            }

            if (groupetravail2) {
                localVarQueryParameter['groupetravail[]'] = groupetravail2;
            }

            if (historicInscription !== undefined) {
                localVarQueryParameter['historicInscription'] = historicInscription;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Jointure Utilisateur / Groupe Travail resource.
         * @summary Retrieves a Jointure Utilisateur / Groupe Travail resource.
         * @param {string} id Jointure Utilisateur / Groupe Travail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateurgroupetravailIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilisateurgroupetravailIdGet', 'id', id)
            const localVarPath = `/utilisateur/utilisateurgroupetravail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JointureUtilisateurGroupeTravailApi - functional programming interface
 * @export
 */
export const JointureUtilisateurGroupeTravailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JointureUtilisateurGroupeTravailApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all relations between users and workgroups
         * @summary Returns all relations between users and workgroups
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [groupetravail] 
         * @param {Array<string>} [groupetravail2] 
         * @param {boolean} [historicInscription] Active or deleted/ended status of inscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateurgroupetravailGetCollection(page?: number, itemsPerPage?: number, groupetravail?: string, groupetravail2?: Array<string>, historicInscription?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUtilisateurgroupetravailGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateurgroupetravailGetCollection(page, itemsPerPage, groupetravail, groupetravail2, historicInscription, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JointureUtilisateurGroupeTravailApi.apiUtilisateurgroupetravailGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a Jointure Utilisateur / Groupe Travail resource.
         * @summary Retrieves a Jointure Utilisateur / Groupe Travail resource.
         * @param {string} id Jointure Utilisateur / Groupe Travail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateurgroupetravailIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JointureUtilisateurGroupeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateurgroupetravailIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JointureUtilisateurGroupeTravailApi.apiUtilisateurgroupetravailIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JointureUtilisateurGroupeTravailApi - factory interface
 * @export
 */
export const JointureUtilisateurGroupeTravailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JointureUtilisateurGroupeTravailApiFp(configuration)
    return {
        /**
         * Returns all relations between users and workgroups
         * @summary Returns all relations between users and workgroups
         * @param {JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateurgroupetravailGetCollection(requestParameters: JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiUtilisateurgroupetravailGetCollection200Response> {
            return localVarFp.apiUtilisateurgroupetravailGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.groupetravail, requestParameters.groupetravail2, requestParameters.historicInscription, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Jointure Utilisateur / Groupe Travail resource.
         * @summary Retrieves a Jointure Utilisateur / Groupe Travail resource.
         * @param {JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateurgroupetravailIdGet(requestParameters: JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<JointureUtilisateurGroupeTravailJsonldWorkgroupReadUtilisateurReadDomaineRead> {
            return localVarFp.apiUtilisateurgroupetravailIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiUtilisateurgroupetravailGetCollection operation in JointureUtilisateurGroupeTravailApi.
 * @export
 * @interface JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollectionRequest
 */
export interface JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollection
     */
    readonly groupetravail?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollection
     */
    readonly groupetravail2?: Array<string>

    /**
     * Active or deleted/ended status of inscription
     * @type {boolean}
     * @memberof JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollection
     */
    readonly historicInscription?: boolean
}

/**
 * Request parameters for apiUtilisateurgroupetravailIdGet operation in JointureUtilisateurGroupeTravailApi.
 * @export
 * @interface JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailIdGetRequest
 */
export interface JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailIdGetRequest {
    /**
     * Jointure Utilisateur / Groupe Travail identifier
     * @type {string}
     * @memberof JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailIdGet
     */
    readonly id: string
}

/**
 * JointureUtilisateurGroupeTravailApi - object-oriented interface
 * @export
 * @class JointureUtilisateurGroupeTravailApi
 * @extends {BaseAPI}
 */
export class JointureUtilisateurGroupeTravailApi extends BaseAPI {
    /**
     * Returns all relations between users and workgroups
     * @summary Returns all relations between users and workgroups
     * @param {JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JointureUtilisateurGroupeTravailApi
     */
    public apiUtilisateurgroupetravailGetCollection(requestParameters: JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return JointureUtilisateurGroupeTravailApiFp(this.configuration).apiUtilisateurgroupetravailGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.groupetravail, requestParameters.groupetravail2, requestParameters.historicInscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Jointure Utilisateur / Groupe Travail resource.
     * @summary Retrieves a Jointure Utilisateur / Groupe Travail resource.
     * @param {JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JointureUtilisateurGroupeTravailApi
     */
    public apiUtilisateurgroupetravailIdGet(requestParameters: JointureUtilisateurGroupeTravailApiApiUtilisateurgroupetravailIdGetRequest, options?: RawAxiosRequestConfig) {
        return JointureUtilisateurGroupeTravailApiFp(this.configuration).apiUtilisateurgroupetravailIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JournauxApi - axios parameter creator
 * @export
 */
export const JournauxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Journaux
         * @summary Returns all resources from Tbl_Utilisateur_Journaux
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJournauxGetCollection: async (page?: number, itemsPerPage?: number, pagination?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/journaux`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JournauxApi - functional programming interface
 * @export
 */
export const JournauxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JournauxApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Journaux
         * @summary Returns all resources from Tbl_Utilisateur_Journaux
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJournauxGetCollection(page?: number, itemsPerPage?: number, pagination?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiJournauxGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJournauxGetCollection(page, itemsPerPage, pagination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JournauxApi.apiJournauxGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JournauxApi - factory interface
 * @export
 */
export const JournauxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JournauxApiFp(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Journaux
         * @summary Returns all resources from Tbl_Utilisateur_Journaux
         * @param {JournauxApiApiJournauxGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJournauxGetCollection(requestParameters: JournauxApiApiJournauxGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiJournauxGetCollection200Response> {
            return localVarFp.apiJournauxGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiJournauxGetCollection operation in JournauxApi.
 * @export
 * @interface JournauxApiApiJournauxGetCollectionRequest
 */
export interface JournauxApiApiJournauxGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Enable or disable pagination
     * @type {boolean}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly pagination?: boolean
}

/**
 * JournauxApi - object-oriented interface
 * @export
 * @class JournauxApi
 * @extends {BaseAPI}
 */
export class JournauxApi extends BaseAPI {
    /**
     * Returns all resources from Tbl_Utilisateur_Journaux
     * @summary Returns all resources from Tbl_Utilisateur_Journaux
     * @param {JournauxApiApiJournauxGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournauxApi
     */
    public apiJournauxGetCollection(requestParameters: JournauxApiApiJournauxGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return JournauxApiFp(this.configuration).apiJournauxGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MarquesApi - axios parameter creator
 * @export
 */
export const MarquesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the society\'s brands list.
         * @summary Returns the society\'s brands list.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [flagDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarquesGetCollection: async (page?: number, itemsPerPage?: number, flagDelete?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/marques`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (flagDelete !== undefined) {
                localVarQueryParameter['flagDelete'] = flagDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarquesApi - functional programming interface
 * @export
 */
export const MarquesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarquesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the society\'s brands list.
         * @summary Returns the society\'s brands list.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [flagDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarquesGetCollection(page?: number, itemsPerPage?: number, flagDelete?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMarquesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarquesGetCollection(page, itemsPerPage, flagDelete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarquesApi.apiMarquesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MarquesApi - factory interface
 * @export
 */
export const MarquesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarquesApiFp(configuration)
    return {
        /**
         * Returns the society\'s brands list.
         * @summary Returns the society\'s brands list.
         * @param {MarquesApiApiMarquesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarquesGetCollection(requestParameters: MarquesApiApiMarquesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiMarquesGetCollection200Response> {
            return localVarFp.apiMarquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiMarquesGetCollection operation in MarquesApi.
 * @export
 * @interface MarquesApiApiMarquesGetCollectionRequest
 */
export interface MarquesApiApiMarquesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly flagDelete?: boolean
}

/**
 * MarquesApi - object-oriented interface
 * @export
 * @class MarquesApi
 * @extends {BaseAPI}
 */
export class MarquesApi extends BaseAPI {
    /**
     * Returns the society\'s brands list.
     * @summary Returns the society\'s brands list.
     * @param {MarquesApiApiMarquesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarquesApi
     */
    public apiMarquesGetCollection(requestParameters: MarquesApiApiMarquesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return MarquesApiFp(this.configuration).apiMarquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PolesApi - axios parameter creator
 * @export
 */
export const PolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the TBL poles collection.
         * @summary Returns the TBL poles collection.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPolesGetCollection: async (page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/poles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PolesApi - functional programming interface
 * @export
 */
export const PolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the TBL poles collection.
         * @summary Returns the TBL poles collection.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPolesGetCollection(page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPolesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPolesGetCollection(page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PolesApi.apiPolesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PolesApi - factory interface
 * @export
 */
export const PolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PolesApiFp(configuration)
    return {
        /**
         * Returns the TBL poles collection.
         * @summary Returns the TBL poles collection.
         * @param {PolesApiApiPolesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPolesGetCollection(requestParameters: PolesApiApiPolesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiPolesGetCollection200Response> {
            return localVarFp.apiPolesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiPolesGetCollection operation in PolesApi.
 * @export
 * @interface PolesApiApiPolesGetCollectionRequest
 */
export interface PolesApiApiPolesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof PolesApiApiPolesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PolesApiApiPolesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * PolesApi - object-oriented interface
 * @export
 * @class PolesApi
 * @extends {BaseAPI}
 */
export class PolesApi extends BaseAPI {
    /**
     * Returns the TBL poles collection.
     * @summary Returns the TBL poles collection.
     * @param {PolesApiApiPolesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolesApi
     */
    public apiPolesGetCollection(requestParameters: PolesApiApiPolesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return PolesApiFp(this.configuration).apiPolesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServicesApi - axios parameter creator
 * @export
 */
export const ServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all resources from Tbl_Def_Service
         * @summary Returns all resources from Tbl_Def_Service
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {boolean} [assignable] dsv_id 0 and 999 filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesGetCollection: async (page?: number, itemsPerPage?: number, pagination?: boolean, assignable?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (assignable !== undefined) {
                localVarQueryParameter['assignable'] = assignable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServicesApi - functional programming interface
 * @export
 */
export const ServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all resources from Tbl_Def_Service
         * @summary Returns all resources from Tbl_Def_Service
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {boolean} [assignable] dsv_id 0 and 999 filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicesGetCollection(page?: number, itemsPerPage?: number, pagination?: boolean, assignable?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiServicesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicesGetCollection(page, itemsPerPage, pagination, assignable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServicesApi.apiServicesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServicesApi - factory interface
 * @export
 */
export const ServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServicesApiFp(configuration)
    return {
        /**
         * Returns all resources from Tbl_Def_Service
         * @summary Returns all resources from Tbl_Def_Service
         * @param {ServicesApiApiServicesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesGetCollection(requestParameters: ServicesApiApiServicesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiServicesGetCollection200Response> {
            return localVarFp.apiServicesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.assignable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiServicesGetCollection operation in ServicesApi.
 * @export
 * @interface ServicesApiApiServicesGetCollectionRequest
 */
export interface ServicesApiApiServicesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Enable or disable pagination
     * @type {boolean}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly pagination?: boolean

    /**
     * dsv_id 0 and 999 filter
     * @type {boolean}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly assignable?: boolean
}

/**
 * ServicesApi - object-oriented interface
 * @export
 * @class ServicesApi
 * @extends {BaseAPI}
 */
export class ServicesApi extends BaseAPI {
    /**
     * Returns all resources from Tbl_Def_Service
     * @summary Returns all resources from Tbl_Def_Service
     * @param {ServicesApiApiServicesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public apiServicesGetCollection(requestParameters: ServicesApiApiServicesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ServicesApiFp(this.configuration).apiServicesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.assignable, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {string} id User identifier
         * @param {UserUpdateUser} userUpdateUser The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurIdPatch: async (id: string, userUpdateUser: UserUpdateUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiKeycloakUtilisateurIdPatch', 'id', id)
            // verify required parameter 'userUpdateUser' is not null or undefined
            assertParamExists('apiKeycloakUtilisateurIdPatch', 'userUpdateUser', userUpdateUser)
            const localVarPath = `/utilisateur/keycloak-utilisateur/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserCreateUserJsonld} userCreateUserJsonld The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurPost: async (userCreateUserJsonld: UserCreateUserJsonld, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateUserJsonld' is not null or undefined
            assertParamExists('apiKeycloakUtilisateurPost', 'userCreateUserJsonld', userCreateUserJsonld)
            const localVarPath = `/utilisateur/keycloak-utilisateur`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateUserJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {string} prescripteurId User identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateursPrescripteurIdGetCollection: async (prescripteurId: string, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescripteurId' is not null or undefined
            assertParamExists('apiKeycloakUtilisateursPrescripteurIdGetCollection', 'prescripteurId', prescripteurId)
            const localVarPath = `/utilisateur/keycloak-utilisateurs/{prescripteurId}`
                .replace(`{${"prescripteurId"}}`, encodeURIComponent(String(prescripteurId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {string} id User identifier
         * @param {UserUpdateUser} userUpdateUser The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakUtilisateurIdPatch(id: string, userUpdateUser: UserUpdateUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakUtilisateurIdPatch(id, userUpdateUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiKeycloakUtilisateurIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserCreateUserJsonld} userCreateUserJsonld The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakUtilisateurPost(userCreateUserJsonld: UserCreateUserJsonld, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakUtilisateurPost(userCreateUserJsonld, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiKeycloakUtilisateurPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {string} prescripteurId User identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakUtilisateursPrescripteurIdGetCollection(prescripteurId: string, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakUtilisateursPrescripteurIdGetCollection(prescripteurId, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiKeycloakUtilisateursPrescripteurIdGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {UserApiApiKeycloakUtilisateurIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurIdPatch(requestParameters: UserApiApiKeycloakUtilisateurIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserJsonld> {
            return localVarFp.apiKeycloakUtilisateurIdPatch(requestParameters.id, requestParameters.userUpdateUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserApiApiKeycloakUtilisateurPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurPost(requestParameters: UserApiApiKeycloakUtilisateurPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserJsonld> {
            return localVarFp.apiKeycloakUtilisateurPost(requestParameters.userCreateUserJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters: UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response> {
            return localVarFp.apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters.prescripteurId, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiKeycloakUtilisateurIdPatch operation in UserApi.
 * @export
 * @interface UserApiApiKeycloakUtilisateurIdPatchRequest
 */
export interface UserApiApiKeycloakUtilisateurIdPatchRequest {
    /**
     * User identifier
     * @type {string}
     * @memberof UserApiApiKeycloakUtilisateurIdPatch
     */
    readonly id: string

    /**
     * The updated User resource
     * @type {UserUpdateUser}
     * @memberof UserApiApiKeycloakUtilisateurIdPatch
     */
    readonly userUpdateUser: UserUpdateUser
}

/**
 * Request parameters for apiKeycloakUtilisateurPost operation in UserApi.
 * @export
 * @interface UserApiApiKeycloakUtilisateurPostRequest
 */
export interface UserApiApiKeycloakUtilisateurPostRequest {
    /**
     * The new User resource
     * @type {UserCreateUserJsonld}
     * @memberof UserApiApiKeycloakUtilisateurPost
     */
    readonly userCreateUserJsonld: UserCreateUserJsonld
}

/**
 * Request parameters for apiKeycloakUtilisateursPrescripteurIdGetCollection operation in UserApi.
 * @export
 * @interface UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest
 */
export interface UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest {
    /**
     * User identifier
     * @type {string}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly prescripteurId: string

    /**
     * The collection page number
     * @type {number}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Updates the User resource.
     * @summary Updates the User resource.
     * @param {UserApiApiKeycloakUtilisateurIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiKeycloakUtilisateurIdPatch(requestParameters: UserApiApiKeycloakUtilisateurIdPatchRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiKeycloakUtilisateurIdPatch(requestParameters.id, requestParameters.userUpdateUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {UserApiApiKeycloakUtilisateurPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiKeycloakUtilisateurPost(requestParameters: UserApiApiKeycloakUtilisateurPostRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiKeycloakUtilisateurPost(requestParameters.userCreateUserJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of User resources.
     * @summary Retrieves the collection of User resources.
     * @param {UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters: UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters.prescripteurId, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilisateursApi - axios parameter creator
 * @export
 */
export const UtilisateursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the users collection
         * @summary Returns the users collection
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [isDeleted] 
         * @param {boolean} [isFormalist] 
         * @param {boolean} [hasG3Access] 
         * @param {boolean} [hasComptaSoftware] 
         * @param {boolean} [hasAccessL2DF] 
         * @param {boolean} [isAdminApps] 
         * @param {string} [mail] 
         * @param {Array<string>} [mail2] 
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {number} [idSocieteEmployeur] 
         * @param {Array<number>} [idSocieteEmployeur2] 
         * @param {ApiUtilisateursGetCollectionOrderIdEnum} [orderId] 
         * @param {ApiUtilisateursGetCollectionOrderNomEnum} [orderNom] 
         * @param {ApiUtilisateursGetCollectionOrderUserEnum} [orderUser] 
         * @param {ApiUtilisateursGetCollectionOrderMailEnum} [orderMail] 
         * @param {ApiUtilisateursGetCollectionOrderPoleIdEnum} [orderPoleId] 
         * @param {ApiUtilisateursGetCollectionOrderIdSocieteEmployeurEnum} [orderIdSocieteEmployeur] 
         * @param {string} [multiFieldSearch] Partial search in chosen fields
         * @param {string} [libGroupeTravail] workgroup name partial filter
         * @param {boolean} [libGroupeTravailDeleteStatus] delete status workgroup filter with name partial filter
         * @param {number} [idGroupeTravail] workgroup id search filter
         * @param {boolean} [idInscriptionDeleteStatus] delete status inscription filter with workgroup id filter
         * @param {number} [iduserReferent] The manager\&#39;s ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursGetCollection: async (page?: number, itemsPerPage?: number, isDeleted?: boolean, isFormalist?: boolean, hasG3Access?: boolean, hasComptaSoftware?: boolean, hasAccessL2DF?: boolean, isAdminApps?: boolean, mail?: string, mail2?: Array<string>, user?: string, user2?: Array<string>, idSocieteEmployeur?: number, idSocieteEmployeur2?: Array<number>, orderId?: ApiUtilisateursGetCollectionOrderIdEnum, orderNom?: ApiUtilisateursGetCollectionOrderNomEnum, orderUser?: ApiUtilisateursGetCollectionOrderUserEnum, orderMail?: ApiUtilisateursGetCollectionOrderMailEnum, orderPoleId?: ApiUtilisateursGetCollectionOrderPoleIdEnum, orderIdSocieteEmployeur?: ApiUtilisateursGetCollectionOrderIdSocieteEmployeurEnum, multiFieldSearch?: string, libGroupeTravail?: string, libGroupeTravailDeleteStatus?: boolean, idGroupeTravail?: number, idInscriptionDeleteStatus?: boolean, iduserReferent?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/utilisateurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (isDeleted !== undefined) {
                localVarQueryParameter['isDeleted'] = isDeleted;
            }

            if (isFormalist !== undefined) {
                localVarQueryParameter['isFormalist'] = isFormalist;
            }

            if (hasG3Access !== undefined) {
                localVarQueryParameter['hasG3Access'] = hasG3Access;
            }

            if (hasComptaSoftware !== undefined) {
                localVarQueryParameter['hasComptaSoftware'] = hasComptaSoftware;
            }

            if (hasAccessL2DF !== undefined) {
                localVarQueryParameter['hasAccessL2DF'] = hasAccessL2DF;
            }

            if (isAdminApps !== undefined) {
                localVarQueryParameter['isAdminApps'] = isAdminApps;
            }

            if (mail !== undefined) {
                localVarQueryParameter['mail'] = mail;
            }

            if (mail2) {
                localVarQueryParameter['mail[]'] = mail2;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (user2) {
                localVarQueryParameter['user[]'] = user2;
            }

            if (idSocieteEmployeur !== undefined) {
                localVarQueryParameter['idSocieteEmployeur'] = idSocieteEmployeur;
            }

            if (idSocieteEmployeur2) {
                localVarQueryParameter['idSocieteEmployeur[]'] = idSocieteEmployeur2;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderNom !== undefined) {
                localVarQueryParameter['order[nom]'] = orderNom;
            }

            if (orderUser !== undefined) {
                localVarQueryParameter['order[user]'] = orderUser;
            }

            if (orderMail !== undefined) {
                localVarQueryParameter['order[mail]'] = orderMail;
            }

            if (orderPoleId !== undefined) {
                localVarQueryParameter['order[pole.id]'] = orderPoleId;
            }

            if (orderIdSocieteEmployeur !== undefined) {
                localVarQueryParameter['order[idSocieteEmployeur]'] = orderIdSocieteEmployeur;
            }

            if (multiFieldSearch !== undefined) {
                localVarQueryParameter['MultiFieldSearch'] = multiFieldSearch;
            }

            if (libGroupeTravail !== undefined) {
                localVarQueryParameter['libGroupeTravail'] = libGroupeTravail;
            }

            if (libGroupeTravailDeleteStatus !== undefined) {
                localVarQueryParameter['libGroupeTravailDeleteStatus'] = libGroupeTravailDeleteStatus;
            }

            if (idGroupeTravail !== undefined) {
                localVarQueryParameter['idGroupeTravail'] = idGroupeTravail;
            }

            if (idInscriptionDeleteStatus !== undefined) {
                localVarQueryParameter['idInscriptionDeleteStatus'] = idInscriptionDeleteStatus;
            }

            if (iduserReferent !== undefined) {
                localVarQueryParameter['iduserReferent'] = iduserReferent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the user
         * @summary Delete the user
         * @param {string} id Utilisateurs identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilisateursIdDelete', 'id', id)
            const localVarPath = `/utilisateur/utilisateurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the selected user informations
         * @summary Returns the selected user informations
         * @param {string} id Utilisateurs identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilisateursIdGet', 'id', id)
            const localVarPath = `/utilisateur/utilisateurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates only the selected informations of the current user
         * @summary Updates only the selected informations of the current user
         * @param {string} id Utilisateurs identifier
         * @param {UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur} utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur The updated Utilisateurs resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdPatch: async (id: string, utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilisateursIdPatch', 'id', id)
            // verify required parameter 'utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur' is not null or undefined
            assertParamExists('apiUtilisateursIdPatch', 'utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur', utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur)
            const localVarPath = `/utilisateur/utilisateurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the selected user informations
         * @summary Updates the selected user informations
         * @param {string} id Utilisateurs identifier
         * @param {UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur} utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur The updated Utilisateurs resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdPut: async (id: string, utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur: UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilisateursIdPut', 'id', id)
            // verify required parameter 'utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur' is not null or undefined
            assertParamExists('apiUtilisateursIdPut', 'utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur', utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur)
            const localVarPath = `/utilisateur/utilisateurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a user
         * @summary Create a user
         * @param {UtilisateursJsonldUtilisateurWrite} utilisateursJsonldUtilisateurWrite The new Utilisateurs resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursPost: async (utilisateursJsonldUtilisateurWrite: UtilisateursJsonldUtilisateurWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilisateursJsonldUtilisateurWrite' is not null or undefined
            assertParamExists('apiUtilisateursPost', 'utilisateursJsonldUtilisateurWrite', utilisateursJsonldUtilisateurWrite)
            const localVarPath = `/utilisateur/utilisateurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(utilisateursJsonldUtilisateurWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilisateursApi - functional programming interface
 * @export
 */
export const UtilisateursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilisateursApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the users collection
         * @summary Returns the users collection
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [isDeleted] 
         * @param {boolean} [isFormalist] 
         * @param {boolean} [hasG3Access] 
         * @param {boolean} [hasComptaSoftware] 
         * @param {boolean} [hasAccessL2DF] 
         * @param {boolean} [isAdminApps] 
         * @param {string} [mail] 
         * @param {Array<string>} [mail2] 
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {number} [idSocieteEmployeur] 
         * @param {Array<number>} [idSocieteEmployeur2] 
         * @param {ApiUtilisateursGetCollectionOrderIdEnum} [orderId] 
         * @param {ApiUtilisateursGetCollectionOrderNomEnum} [orderNom] 
         * @param {ApiUtilisateursGetCollectionOrderUserEnum} [orderUser] 
         * @param {ApiUtilisateursGetCollectionOrderMailEnum} [orderMail] 
         * @param {ApiUtilisateursGetCollectionOrderPoleIdEnum} [orderPoleId] 
         * @param {ApiUtilisateursGetCollectionOrderIdSocieteEmployeurEnum} [orderIdSocieteEmployeur] 
         * @param {string} [multiFieldSearch] Partial search in chosen fields
         * @param {string} [libGroupeTravail] workgroup name partial filter
         * @param {boolean} [libGroupeTravailDeleteStatus] delete status workgroup filter with name partial filter
         * @param {number} [idGroupeTravail] workgroup id search filter
         * @param {boolean} [idInscriptionDeleteStatus] delete status inscription filter with workgroup id filter
         * @param {number} [iduserReferent] The manager\&#39;s ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursGetCollection(page?: number, itemsPerPage?: number, isDeleted?: boolean, isFormalist?: boolean, hasG3Access?: boolean, hasComptaSoftware?: boolean, hasAccessL2DF?: boolean, isAdminApps?: boolean, mail?: string, mail2?: Array<string>, user?: string, user2?: Array<string>, idSocieteEmployeur?: number, idSocieteEmployeur2?: Array<number>, orderId?: ApiUtilisateursGetCollectionOrderIdEnum, orderNom?: ApiUtilisateursGetCollectionOrderNomEnum, orderUser?: ApiUtilisateursGetCollectionOrderUserEnum, orderMail?: ApiUtilisateursGetCollectionOrderMailEnum, orderPoleId?: ApiUtilisateursGetCollectionOrderPoleIdEnum, orderIdSocieteEmployeur?: ApiUtilisateursGetCollectionOrderIdSocieteEmployeurEnum, multiFieldSearch?: string, libGroupeTravail?: string, libGroupeTravailDeleteStatus?: boolean, idGroupeTravail?: number, idInscriptionDeleteStatus?: boolean, iduserReferent?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUtilisateursGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursGetCollection(page, itemsPerPage, isDeleted, isFormalist, hasG3Access, hasComptaSoftware, hasAccessL2DF, isAdminApps, mail, mail2, user, user2, idSocieteEmployeur, idSocieteEmployeur2, orderId, orderNom, orderUser, orderMail, orderPoleId, orderIdSocieteEmployeur, multiFieldSearch, libGroupeTravail, libGroupeTravailDeleteStatus, idGroupeTravail, idInscriptionDeleteStatus, iduserReferent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the user
         * @summary Delete the user
         * @param {string} id Utilisateurs identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the selected user informations
         * @summary Returns the selected user informations
         * @param {string} id Utilisateurs identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilisateursJsonldUtilisateurRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates only the selected informations of the current user
         * @summary Updates only the selected informations of the current user
         * @param {string} id Utilisateurs identifier
         * @param {UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur} utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur The updated Utilisateurs resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursIdPatch(id: string, utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilisateursJsonldUtilisateurPatchUtilisateur>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursIdPatch(id, utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the selected user informations
         * @summary Updates the selected user informations
         * @param {string} id Utilisateurs identifier
         * @param {UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur} utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur The updated Utilisateurs resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursIdPut(id: string, utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur: UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursIdPut(id, utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a user
         * @summary Create a user
         * @param {UtilisateursJsonldUtilisateurWrite} utilisateursJsonldUtilisateurWrite The new Utilisateurs resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursPost(utilisateursJsonldUtilisateurWrite: UtilisateursJsonldUtilisateurWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilisateursJsonldUtilisateurWrite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursPost(utilisateursJsonldUtilisateurWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilisateursApi - factory interface
 * @export
 */
export const UtilisateursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilisateursApiFp(configuration)
    return {
        /**
         * Returns the users collection
         * @summary Returns the users collection
         * @param {UtilisateursApiApiUtilisateursGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursGetCollection(requestParameters: UtilisateursApiApiUtilisateursGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiUtilisateursGetCollection200Response> {
            return localVarFp.apiUtilisateursGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.isDeleted, requestParameters.isFormalist, requestParameters.hasG3Access, requestParameters.hasComptaSoftware, requestParameters.hasAccessL2DF, requestParameters.isAdminApps, requestParameters.mail, requestParameters.mail2, requestParameters.user, requestParameters.user2, requestParameters.idSocieteEmployeur, requestParameters.idSocieteEmployeur2, requestParameters.orderId, requestParameters.orderNom, requestParameters.orderUser, requestParameters.orderMail, requestParameters.orderPoleId, requestParameters.orderIdSocieteEmployeur, requestParameters.multiFieldSearch, requestParameters.libGroupeTravail, requestParameters.libGroupeTravailDeleteStatus, requestParameters.idGroupeTravail, requestParameters.idInscriptionDeleteStatus, requestParameters.iduserReferent, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the user
         * @summary Delete the user
         * @param {UtilisateursApiApiUtilisateursIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdDelete(requestParameters: UtilisateursApiApiUtilisateursIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiUtilisateursIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the selected user informations
         * @summary Returns the selected user informations
         * @param {UtilisateursApiApiUtilisateursIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdGet(requestParameters: UtilisateursApiApiUtilisateursIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UtilisateursJsonldUtilisateurRead> {
            return localVarFp.apiUtilisateursIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates only the selected informations of the current user
         * @summary Updates only the selected informations of the current user
         * @param {UtilisateursApiApiUtilisateursIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdPatch(requestParameters: UtilisateursApiApiUtilisateursIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<UtilisateursJsonldUtilisateurPatchUtilisateur> {
            return localVarFp.apiUtilisateursIdPatch(requestParameters.id, requestParameters.utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the selected user informations
         * @summary Updates the selected user informations
         * @param {UtilisateursApiApiUtilisateursIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdPut(requestParameters: UtilisateursApiApiUtilisateursIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur> {
            return localVarFp.apiUtilisateursIdPut(requestParameters.id, requestParameters.utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a user
         * @summary Create a user
         * @param {UtilisateursApiApiUtilisateursPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursPost(requestParameters: UtilisateursApiApiUtilisateursPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UtilisateursJsonldUtilisateurWrite> {
            return localVarFp.apiUtilisateursPost(requestParameters.utilisateursJsonldUtilisateurWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiUtilisateursGetCollection operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursGetCollectionRequest
 */
export interface UtilisateursApiApiUtilisateursGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly isDeleted?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly isFormalist?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly hasG3Access?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly hasComptaSoftware?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly hasAccessL2DF?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly isAdminApps?: boolean

    /**
     * 
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly mail?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly mail2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly user?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly user2?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly idSocieteEmployeur?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly idSocieteEmployeur2?: Array<number>

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderId?: ApiUtilisateursGetCollectionOrderIdEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderNom?: ApiUtilisateursGetCollectionOrderNomEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderUser?: ApiUtilisateursGetCollectionOrderUserEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderMail?: ApiUtilisateursGetCollectionOrderMailEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderPoleId?: ApiUtilisateursGetCollectionOrderPoleIdEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderIdSocieteEmployeur?: ApiUtilisateursGetCollectionOrderIdSocieteEmployeurEnum

    /**
     * Partial search in chosen fields
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly multiFieldSearch?: string

    /**
     * workgroup name partial filter
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly libGroupeTravail?: string

    /**
     * delete status workgroup filter with name partial filter
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly libGroupeTravailDeleteStatus?: boolean

    /**
     * workgroup id search filter
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly idGroupeTravail?: number

    /**
     * delete status inscription filter with workgroup id filter
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly idInscriptionDeleteStatus?: boolean

    /**
     * The manager\&#39;s ID
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly iduserReferent?: number
}

/**
 * Request parameters for apiUtilisateursIdDelete operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursIdDeleteRequest
 */
export interface UtilisateursApiApiUtilisateursIdDeleteRequest {
    /**
     * Utilisateurs identifier
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiUtilisateursIdGet operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursIdGetRequest
 */
export interface UtilisateursApiApiUtilisateursIdGetRequest {
    /**
     * Utilisateurs identifier
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiUtilisateursIdPatch operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursIdPatchRequest
 */
export interface UtilisateursApiApiUtilisateursIdPatchRequest {
    /**
     * Utilisateurs identifier
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursIdPatch
     */
    readonly id: string

    /**
     * The updated Utilisateurs resource
     * @type {UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur}
     * @memberof UtilisateursApiApiUtilisateursIdPatch
     */
    readonly utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
}

/**
 * Request parameters for apiUtilisateursIdPut operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursIdPutRequest
 */
export interface UtilisateursApiApiUtilisateursIdPutRequest {
    /**
     * Utilisateurs identifier
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursIdPut
     */
    readonly id: string

    /**
     * The updated Utilisateurs resource
     * @type {UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur}
     * @memberof UtilisateursApiApiUtilisateursIdPut
     */
    readonly utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur: UtilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur
}

/**
 * Request parameters for apiUtilisateursPost operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursPostRequest
 */
export interface UtilisateursApiApiUtilisateursPostRequest {
    /**
     * The new Utilisateurs resource
     * @type {UtilisateursJsonldUtilisateurWrite}
     * @memberof UtilisateursApiApiUtilisateursPost
     */
    readonly utilisateursJsonldUtilisateurWrite: UtilisateursJsonldUtilisateurWrite
}

/**
 * UtilisateursApi - object-oriented interface
 * @export
 * @class UtilisateursApi
 * @extends {BaseAPI}
 */
export class UtilisateursApi extends BaseAPI {
    /**
     * Returns the users collection
     * @summary Returns the users collection
     * @param {UtilisateursApiApiUtilisateursGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursGetCollection(requestParameters: UtilisateursApiApiUtilisateursGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.isDeleted, requestParameters.isFormalist, requestParameters.hasG3Access, requestParameters.hasComptaSoftware, requestParameters.hasAccessL2DF, requestParameters.isAdminApps, requestParameters.mail, requestParameters.mail2, requestParameters.user, requestParameters.user2, requestParameters.idSocieteEmployeur, requestParameters.idSocieteEmployeur2, requestParameters.orderId, requestParameters.orderNom, requestParameters.orderUser, requestParameters.orderMail, requestParameters.orderPoleId, requestParameters.orderIdSocieteEmployeur, requestParameters.multiFieldSearch, requestParameters.libGroupeTravail, requestParameters.libGroupeTravailDeleteStatus, requestParameters.idGroupeTravail, requestParameters.idInscriptionDeleteStatus, requestParameters.iduserReferent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the user
     * @summary Delete the user
     * @param {UtilisateursApiApiUtilisateursIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursIdDelete(requestParameters: UtilisateursApiApiUtilisateursIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the selected user informations
     * @summary Returns the selected user informations
     * @param {UtilisateursApiApiUtilisateursIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursIdGet(requestParameters: UtilisateursApiApiUtilisateursIdGetRequest, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates only the selected informations of the current user
     * @summary Updates only the selected informations of the current user
     * @param {UtilisateursApiApiUtilisateursIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursIdPatch(requestParameters: UtilisateursApiApiUtilisateursIdPatchRequest, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursIdPatch(requestParameters.id, requestParameters.utilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the selected user informations
     * @summary Updates the selected user informations
     * @param {UtilisateursApiApiUtilisateursIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursIdPut(requestParameters: UtilisateursApiApiUtilisateursIdPutRequest, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursIdPut(requestParameters.id, requestParameters.utilisateursJsonldUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a user
     * @summary Create a user
     * @param {UtilisateursApiApiUtilisateursPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursPost(requestParameters: UtilisateursApiApiUtilisateursPostRequest, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursPost(requestParameters.utilisateursJsonldUtilisateurWrite, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderIdEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderIdEnum = typeof ApiUtilisateursGetCollectionOrderIdEnum[keyof typeof ApiUtilisateursGetCollectionOrderIdEnum];
/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderNomEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderNomEnum = typeof ApiUtilisateursGetCollectionOrderNomEnum[keyof typeof ApiUtilisateursGetCollectionOrderNomEnum];
/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderUserEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderUserEnum = typeof ApiUtilisateursGetCollectionOrderUserEnum[keyof typeof ApiUtilisateursGetCollectionOrderUserEnum];
/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderMailEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderMailEnum = typeof ApiUtilisateursGetCollectionOrderMailEnum[keyof typeof ApiUtilisateursGetCollectionOrderMailEnum];
/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderPoleIdEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderPoleIdEnum = typeof ApiUtilisateursGetCollectionOrderPoleIdEnum[keyof typeof ApiUtilisateursGetCollectionOrderPoleIdEnum];
/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderIdSocieteEmployeurEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderIdSocieteEmployeurEnum = typeof ApiUtilisateursGetCollectionOrderIdSocieteEmployeurEnum[keyof typeof ApiUtilisateursGetCollectionOrderIdSocieteEmployeurEnum];


