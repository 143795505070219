import * as React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

export type FormStep<T = Record<string, unknown>> = {
    title: string;
    component: React.FunctionComponent<T>;
};

type FormContext = UseFormReturn<FieldValues> | undefined;
type ReactUseSetState<Type> = React.Dispatch<React.SetStateAction<Type>>;

export type MultiStepHook = {
    currentStep: {
        index: number;
        step: FormStep;
        formContext: FormContext;
    };
    stepState: {
        isFirstStep: boolean;
        isLastStep: boolean;
        isStepValid: boolean;
        isStepValidating: boolean;
        isStepDisabled: boolean;
    };
    isFormSubmitting: boolean;
    setCurrentFormContext: ReactUseSetState<FormContext>;
    formValues: FieldValues;
    updateFormValues: (formValues: FieldValues) => FieldValues;
    saveFormValues: () => void;
    onPrevious: () => void;
    onNext: () => void;
    setIsStepValid: ReactUseSetState<boolean>;
    setIsStepDisabled: ReactUseSetState<boolean>;
};

/** Custom hook that contains multi step form logic :
 * - Shared formValues
 * - Current step : state / formContext
 * - Step navigation logic
 * - Submitting form boolean state
 */
const useMultiStep = (
    steps: FormStep[],
    initialFormValues: FieldValues,
    submitAction: (formData: FieldValues) => void,
): MultiStepHook => {
    const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
    const [formValues, setFormValues] = React.useState(initialFormValues);
    const [currentFormContext, setCurrentFormContext] = React.useState<FormContext>(undefined);

    const [isStepValid, setIsStepValid] = React.useState(false);
    const [isStepValidating, setIsStepValidating] = React.useState(false);
    const [isStepDisabled, setIsStepDisabled] = React.useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);

    const isFirstStep = currentStepIndex === 0;
    const isLastStep = currentStepIndex === steps.length - 1;

    const nextStep = () => !isLastStep && setCurrentStepIndex(currentStepIndex + 1);
    const previousStep = () => !isFirstStep && setCurrentStepIndex(currentStepIndex - 1);

    const updateFormValues = (newValues: FieldValues) => {
        const newState = {
            ...formValues,
            ...newValues,
        };
        setFormValues(newState);
        return newState;
    };

    const saveFormValues = () => {
        if (currentFormContext) {
            const { getValues } = currentFormContext;
            updateFormValues(getValues());
        }
    };

    const submitValidation = async () => {
        if (currentFormContext) {
            setIsStepValidating(true);
            await currentFormContext.trigger();
            setIsStepValidating(false);
            if (!isStepValid) {
                return false;
            }
            return true;
        }
        return false;
    };

    const submitForm = async () => {
        if (currentFormContext) {
            setIsFormSubmitting(true);
            const { getValues } = currentFormContext;

            const formData = updateFormValues(getValues());
            await submitAction(formData);
            setIsFormSubmitting(false);
        }
    };

    const onPrevious = () => {
        saveFormValues();
        previousStep();
    };

    const onNext = () => {
        submitValidation();
        if (!isStepValid) {
            return;
        }
        saveFormValues();
        if (isLastStep) {
            submitForm();
        }

        nextStep();
    };

    const hookReturn = {
        currentStep: {
            index: currentStepIndex,
            step: steps[currentStepIndex],
            formContext: currentFormContext,
        },
        stepState: {
            isFirstStep,
            isLastStep,
            isStepValid,
            isStepValidating,
            isStepDisabled,
        },
        isFormSubmitting,
        setCurrentFormContext,
        formValues,
        updateFormValues,
        saveFormValues,
        onPrevious,
        onNext,
        setIsStepValid,
        setIsStepDisabled,
    };

    return hookReturn;
};
export default useMultiStep;
