import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';
import { Box, Typography } from '@mui/material';
import * as React from 'react';

type ComponentProps = { icon: NamedIconsType; title: string };

const ModalFormTitle: React.FunctionComponent<ComponentProps> = function (props) {
    const { icon, title } = props;

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <FaOptionIcon
                {...icon.props}
                sx={{ marginRight: '12px' }}
            />
            <Typography fontSize={18}>{title}</Typography>
        </Box>
    );
};

export default ModalFormTitle;
