import * as React from 'react';

import { Box } from '@mui/material';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { NamedIconsType } from '@europrocurement/l2d-icons';

import { ChoiceProps, SubmitActionProps } from './types';
import Header from './Header';
import Content from './Content/Content';
import Actions from './Actions';

type FormModalProps = {
    title: string;
    context: string;
    choices: Array<ChoiceProps>;
    submitActions?: Array<SubmitActionProps>;
    hint?: string;
    icon?: NamedIconsType;
    inputNature?: 'select' | 'toggle';
    uniqChoice?: boolean;
};

/**
 * FormModal Component
 *
 * A flexible modal form with input options (toggle switches or dropdown select),
 * a customizable header, content, and action buttons.
 *
 * Props:
 * - `title`: Modal header title.
 * - `context`: Contextual text above the inputs.
 * - `choices`: Array of options, each with `label`, `action`, and optional `defaultSelected`.
 * - `submitActions`: Array of confirm or cancel button actions.
 * - `hint`: Optional hint text above inputs.
 * - `icon`: Header icon.
 * - `inputNature`: Input type: 'toggle' (default) or 'select'.
 * - `uniqChoice`: Restrict to one active choice (default: false).
 *
 * Example:
 * ```
 * <FormModal
 *   title="My Modal"
 *   context="Make a selection"
 *   choices={[
 *     { label: "Option 1", action: handleOption1, defaultSelected: true },
 *     { label: "Option 2", action: handleOption2 },
 *   ]}
 *   submitActions={[
 *     { type: "confirm", action: handleConfirm },
 *     { type: "cancel", action: handleCancel },
 *   ]}
 * />
 * ```
 */
const FormModal: React.FunctionComponent<FormModalProps> = function ({
    title,
    context,
    choices,
    submitActions,
    hint,
    icon = {
        name: 'undefined',
        displayName: 'Non défini',
        props: {
            icon: faFileInvoice,
            option: faXmark,
            size: '1x',
            color: 'secondary.main',
            optionColor: 'secondary.dark',
            iconProps: {
                radius: 100,
            },
        },
    },
    inputNature = 'toggle',
    uniqChoice = false,
}) {
    const [formState, setFormState] = React.useState<Record<string, boolean>>(() =>
        choices.reduce(
            (state, choice) => {
                // eslint-disable-next-line no-param-reassign
                state[choice.label] = choice.defaultSelected || false;
                return state;
            },
            {} as Record<string, boolean>,
        ),
    );

    const handleCheckboxChange = React.useCallback(
        (choice: ChoiceProps) => {
            setFormState((prevState) => {
                const updatedState = uniqChoice
                    ? choices.reduce(
                          (state, c) => {
                              // eslint-disable-next-line no-param-reassign
                              state[c.label] = c.label === choice.label;
                              return state;
                          },
                          {} as Record<string, boolean>,
                      )
                    : { ...prevState, [choice.label]: !prevState[choice.label] };
                return updatedState;
            });
        },
        [choices, uniqChoice],
    );

    return (
        <Box
            id={`form_modal_${title}`}
            sx={{
                display: 'flex',
                padding: '40px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '35px',
            }}
        >
            <Header
                title={title}
                icon={icon}
            />
            <Content
                context={context}
                choices={choices}
                formState={formState}
                setFormState={setFormState}
                hint={hint}
                uniqChoice={uniqChoice}
                inputNature={inputNature}
                handleCheckboxChange={handleCheckboxChange}
            />
            {submitActions ? <Actions submitActions={submitActions} /> : null}
        </Box>
    );
};

export default FormModal;
