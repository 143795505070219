import { useContext } from 'react';
import {
    AdressesApi,
    CategorieSocioProfessionnelleApi,
    CivilitesApi,
    ContactsApi,
    CoordonneesApi,
    LogoApi,
    ObjectGroupApi,
    PortefeuilleApi,
    PrescripteursApi,
    SousClientsApi,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';

import { type TiersReducerType } from '@europrocurement/l2d-domain/reducers/tiers/types';
import { getDataThunkType } from '@europrocurement/l2d-redux-utils';
import {
    FournisseurApi,
    PrescripteurApi,
    SousClientApi,
} from '@europrocurement/l2d-domain/reducers';
import L2DApiContext from '../L2DApiContext';

export const useTiersService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.tiers) {
        throw new Error('Tiers service is not registred in L2DApiProvider');
    }

    const { apis, slices, thunks, fetchItems, getItems, reducer } = context.tiers;

    return {
        reducer: reducer as TiersReducerType,
        prescribersApi: apis.prescribersApi as PrescripteursApi,
        coordonneesApi: apis.coordonneesApi as CoordonneesApi,
        contactsApi: apis.contactsApi as ContactsApi,
        adressesApi: apis.adressesApi as AdressesApi,
        categorieSocioProfessionnelleApi:
            apis.categorieSocioProfessionnelleApi as CategorieSocioProfessionnelleApi,
        civilitesApi: apis.civilitesApi as CivilitesApi,
        portefeuilleApi: apis.portefeuilleApi as PortefeuilleApi,
        logoSpecificationsApi: apis.logoSpecificationsApi as LogoApi,
        sousClientsApi: apis.sousClientsApi as SousClientsApi,
        objectGroupApi: apis.objectGroupApi as ObjectGroupApi,

        prescripteurSlice: slices.prescripteurSlice,
        sousClientSlice: slices.sousClientSlice,
        fournisseurSlice: slices.fournisseurSlice,
        tiersFournisseurSlice: slices.tiersFournisseurSlice,
        paysSlice: slices.paysSlice,
        walletOptionsSlice: slices.walletOptionsSlice,
        cspOptionsSlice: slices.cspOptionsSlice,
        civilitiesSlice: slices.civilitiesSlice,
        objectGroupSlice: slices.objectGroupSlice,

        prescripteurDataSourcesThunks: thunks.prescripteurDataSourcesThunks,
        sousClientDataSourcesThunks: thunks.sousClientDataSourcesThunks,
        fournisseurDataSourcesThunks: thunks.fournisseurDataSourcesThunks,
        tiersFournisseurDataSourcesThunks: thunks.tiersFournisseurDataSourcesThunks,
        paysDataSourcesThunks: thunks.paysDataSourcesThunks,
        walletOptionsDataSourcesThunks: thunks.walletOptionsDataSourcesThunks,
        cspOptionsDataSourcesThunks: thunks.cspOptionsDataSourcesThunks,
        civilitiesDataSourcesThunks: thunks.civilitiesDataSourcesThunks,
        objectGroupDataSourcesThunks: thunks.objectGroupDataSourcesThunks,

        getFournisseurs: fetchItems.getFournisseurs as getDataThunkType<FournisseurApi>,
        getFournisseurAc: fetchItems.getFournisseurAc as getDataThunkType<FournisseurApi>,
        getPrescripteurs: fetchItems.getPrescripteurs as getDataThunkType<PrescripteurApi>,
        getExportPrescripteurs: fetchItems.getExportPrescripteurs,
        getSousClients: fetchItems.getSousClients as getDataThunkType<SousClientApi>,
        getTiersFournisseurs: fetchItems.getTiersFournisseurs,
        getPays: fetchItems.getPays,
        getCivilities: fetchItems.getCivilities,
        getWalletOptions: fetchItems.getWalletOptions,
        getCspOptions: fetchItems.getCspOptions,
        getObjectGroup: fetchItems.getObjectGroup as getDataThunkType<ObjectGroupApi>,

        selectFournisseur: getItems.selectFournisseur,
        selectFournisseurAc: getItems.selectFournisseurAc,
        selectPrescripteur: getItems.selectPrescripteur,
        selectExportPrescripteurs: getItems.selectExportPrescripteurs,
        selectSousClients: getItems.selectSousClients,
        selectTiersFournisseur: getItems.selectTiersFournisseur,
        selectPays: getItems.selectPays,
        selectCivilities: getItems.selectCivilities,
        selectWalletOptions: getItems.selectWalletOptions,
        selectCspOptions: getItems.selectCspOptions,
    };
};
