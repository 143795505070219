import React from 'react';
import { SxProps } from '@mui/material';
import {
    ColumnDatatable,
    FiltersDatatableList,
    PreFilterDatatableList,
    RowSelectionActionList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { DossierOpenSearch, useFormaliteService } from '@europrocurement/l2d-domain';
import { useSelector } from 'react-redux';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';

export type DossierTableProps = {
    datasource: DataSource<DossierOpenSearch>;
    columns: ColumnDatatable<DossierOpenSearch>[];
    preFilters?: PreFilterDatatableList;
    filters: FiltersDatatableList;
    rowActions?: RowSelectionActionList;
    onRowClick: (item: DossierOpenSearch) => void;
};

const DossierTable: React.FunctionComponent<DossierTableProps> = function ({
    datasource,
    columns,
    preFilters,
    filters,
    rowActions,
    onRowClick: handleRowClick,
}) {
    const { xIdSociete } = useSelector(customizerSelector);
    const { getOpenSearchDossiers } = useFormaliteService();

    return (
        <StoreDatatable
            dataSource={datasource}
            columns={columns}
            computeSxRow={(item: DossierOpenSearch) =>
                item.critical
                    ? ({
                          '&>td:first-of-type': {
                              borderLeft: '4px solid',
                              borderLeftColor: 'error.main',
                          },
                      } as SxProps)
                    : {}
            }
            fetchData={getOpenSearchDossiers}
            localStorageKey="FlexyStoreDatatable"
            observers={[xIdSociete]}
            sx={{ marginBottom: '2.25em' }}
            preFilters={preFilters}
            filters={filters}
            onClickRow={(_: React.MouseEvent<HTMLElement>, item: DossierOpenSearch) => {
                handleRowClick(item);
            }}
            rowsActions={rowActions}
        />
    );
};

export default DossierTable;
