import { checkIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import React from 'react';
import { Box, Palette, PaletteColor, Typography, useTheme } from '@mui/material';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import { Color } from '../../organisms/ColorSelector';

export type ObjectGroupColor = Color;

export type ObjectGroupType = {
    id: number;
    type: string;
    label: string;
    color?: ObjectGroupColor;
    icone?: IconName;
    items?: Array<unknown>;
};

export type ObjectGroupProps = ObjectGroupType & {
    isSelected?: boolean;
    onClick?: (objectGroup: ObjectGroupType) => void;
};

const ObjectGroup: React.FunctionComponent<ObjectGroupProps> = function ({
    onClick,
    ...objectGroup
}) {
    const { icone, color, type, id, label, isSelected = false } = objectGroup;
    const { palette } = useTheme();
    const displayedColor = palette[color as keyof Palette] as PaletteColor;

    const onClickWrapper = () => {
        if (onClick) {
            onClick(objectGroup);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '36px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'space-around',
                alignItems: 'center',
                backgroundColor: isSelected ? 'primary.light' : null,
                m: 'auto',
                cursor: 'pointer',
                borderRadius: '12px',
                padding: '0 16px',
            }}
            data-type={type}
            onClick={onClickWrapper}
        >
            {icone ? (
                <Box
                    sx={{
                        display: 'flex',
                        width: '36px',
                        height: '36px',
                        marginRight: '5px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        component="span"
                        color={displayedColor?.main}
                        sx={{
                            display: 'flex',
                            textAlign: 'center',
                        }}
                    >
                        <FaOptionIcon icon={['fasl', icone]} />
                    </Typography>
                </Box>
            ) : null}
            <Box
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexGrow: '2',
                    alignItems: 'center',
                }}
            >
                <Typography
                    component="span"
                    data-id={id}
                    sx={{
                        fontSize: '15px',
                        fontWeight: '500',
                        lineHeight: '24px',
                        textAlign: 'left',
                    }}
                >
                    {label}
                </Typography>
            </Box>
            <Box
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <FaOptionIcon
                    {...checkIcon.props}
                    color={color}
                    sx={{
                        display: isSelected ? 'block' : 'none',
                    }}
                />
            </Box>
        </Box>
    );
};
export default ObjectGroup;
