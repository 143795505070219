import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import ActionButton2 from '../ActionButton2';

type Action = {
    icon: IconProp;
    label: string;
    onClick: () => void;
    disabled?: boolean;
};
export type ActionsDropdownProps = {
    actions: Array<Action>;
};

const ActionsDropdown: React.FunctionComponent<ActionsDropdownProps> = function (props) {
    const { actions } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ActionButton2
                onClick={handleClick}
                icon={faEllipsis}
            />
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {actions.map(({ icon, label, disabled = false, onClick }, index) => {
                    const itemKey = index;
                    return (
                        <MenuItem
                            key={itemKey}
                            value={index}
                            onClick={onClick}
                            sx={{ height: '48px' }}
                            disabled={disabled}
                        >
                            <FaOptionIcon icon={icon} />
                            <Typography sx={{ marginLeft: '13px' }}>{label}</Typography>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default ActionsDropdown;
