import {
    ContextActionType,
    LazyBreadcrumb,
    useDumbActionsContext,
} from '@europrocurement/flexy-components';
import { DossierOpenSearch, DossierOpenSearchSelector } from '@europrocurement/l2d-domain';
import { DossierTable, parcoursFormalitePathResolver } from '@europrocurement/l2d-modules';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { dossiersBasePath } from '../../constants';
import { useDossierTable } from '../../hooks';

const ListeDossiers: React.FunctionComponent = function () {
    const navigate = useNavigate();
    const datasource: DataSource<DossierOpenSearch> = useSelector(DossierOpenSearchSelector);
    const { columns, filters, preFilters, rowActions } = useDossierTable();
    const breadcrumbItems = [
        {
            link: '/accueil',
            icon: {
                icon: ['fasl', 'house'] as IconProp,
                sx: { display: 'flex', alignItems: 'center' },
            },
        },
        {
            link: '/dossiers',
            path: 'Formalités',
        },
        'Dossiers',
    ];

    const handleRowClick = ({ id }: DossierOpenSearch) => {
        navigate(`/${dossiersBasePath}/${id}`);
    };

    const contextActions: ContextActionType[] = [
        {
            name: 'Ajouter prescripteur',
            icon: <FaOptionIcon icon={['fad', 'folder-plus' as IconName]} />,
            action: () => {
                navigate(parcoursFormalitePathResolver('stepFormalite'));
            },
        },
    ];

    useDumbActionsContext(contextActions);

    return (
        <>
            <LazyBreadcrumb
                path={breadcrumbItems}
                separator=" / "
                sx={{ marginBottom: '1.5em' }}
            />
            <DossierTable
                datasource={datasource}
                columns={columns}
                filters={filters}
                preFilters={preFilters}
                rowActions={rowActions}
                onRowClick={handleRowClick}
            />
        </>
    );
};

export default ListeDossiers;
