import * as React from 'react';
import { Box, SxProps, useTheme } from '@mui/material';
import {
    FaOptionIcon,
    NamedIconsType,
    alertErrorIcon,
    alertInfoIcon,
    alertWarningIcon,
    alertSuccessIcon,
} from '@europrocurement/l2d-icons';

export type NoteInformationColorProps = {
    bg?: string;
    vibrant?: string;
    border?: string;
};
export type NoteInformationProps = {
    title?: React.ReactNode;
    children?: React.ReactNode;
    sx?: SxProps;
    variant?: 'info' | 'warning' | 'error' | 'success';
    color?: NoteInformationColorProps;
};

export const variantToIcone = (variant: NoteInformationProps['variant']): NamedIconsType => {
    switch (variant) {
        case 'warning':
            return alertWarningIcon;
        case 'error':
            return alertErrorIcon;
        case 'success':
            return alertSuccessIcon;
        case 'info':
        default:
            return alertInfoIcon;
    }
};

const NoteInformation: React.FunctionComponent<NoteInformationProps> = function ({
    title,
    sx,
    variant = 'info',
    children,
    color,
}) {
    const theme = useTheme();

    const variantToBgColor = (noteVariant: NoteInformationProps['variant']): string => {
        switch (noteVariant) {
            case 'warning':
                return theme.palette.warning.light;
            case 'error':
                return theme.palette.error.light;
            case 'success':
                return theme.palette.success.light;
            case 'info':
            default:
                return theme.palette.primary.light;
        }
    };
    const variantToColor = (noteVariant: NoteInformationProps['variant']): string => {
        switch (noteVariant) {
            case 'warning':
                return theme.palette.warning.dark;
            case 'error':
                return theme.palette.error.main;
            case 'success':
                return theme.palette.success.main;
            case 'info':
            default:
                return theme.palette.primary.main;
        }
    };
    const borderColor = color?.border ? color?.border : variantToColor(variant);
    const vibrantColor = color?.vibrant ? color?.vibrant : variantToColor(variant);
    const bgColor = color?.bg ? color?.bg : variantToBgColor(variant);

    return (
        <Box
            data-testid="test-id-NoteInformation"
            sx={{
                borderTop: `1px solid ${borderColor}`,
                borderBottom: `1px solid ${borderColor}`,
                borderLeft: `7px solid ${borderColor}`,
                borderRight: `1px solid ${borderColor}`,
                backgroundColor: `${bgColor}`,
                borderRadius: '5px',
                padding: '0px',
                color: 'text.gris70',
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    padding: '10px',
                    alignItems: 'start',
                }}
            >
                <FaOptionIcon
                    {...variantToIcone(variant).props}
                    color={vibrantColor}
                />
                {title}
                {children}
            </Box>
        </Box>
    );
};

export default NoteInformation;
