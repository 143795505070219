import { useOffreService } from '@europrocurement/l2d-domain';
import articleLibSlice from '@europrocurement/l2d-domain/reducers/offre/slices/articleLibSlice';
import React from 'react';
import { useDispatch } from 'react-redux';

/**  * Fetch api LIB article and store ID as a const in REDUX store  */
const useLoadLibArticle = () => {
    const { articlesApi } = useOffreService();

    const dispatch = useDispatch();
    const fetchLibArticle = async () => {
        await articlesApi
            .apiArticleLibGet()
            .then((result) => {
                const apiResponse = result as unknown as {
                    data: { 'hydra:member': Array<{ id: number; libelle: string }> };
                };
                const results = apiResponse.data['hydra:member'];
                if (results.length > 0) {
                    dispatch(articleLibSlice.actions.updateSpecificsArticleLib(results));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        fetchLibArticle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {};
};

export default useLoadLibArticle;
