import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { limitStringLength } from '@europrocurement/l2d-utils';
import { OptionValue } from './types';

type ComponentProps = { optionLabel: string; optionValues: OptionValue[] };
const OptionSelectPreview: React.FunctionComponent<ComponentProps> = function (props) {
    const { optionValues, optionLabel = 'Option' } = props;

    const [selectedOptionValue, setSelectedOptionValue] = React.useState<null | OptionValue>(null);

    return (
        <Box sx={{ minWidth: '100%' }}>
            <FormControl fullWidth>
                <InputLabel>{optionLabel}</InputLabel>
                <Select
                    fullWidth
                    value={selectedOptionValue?.id ?? null}
                >
                    {optionValues.map((optionValue: OptionValue) => {
                        const { libelle = '', description = '' } = optionValue;
                        const menuItemContent = `${libelle} - ${description}`;

                        return (
                            <MenuItem
                                value={optionValue.id}
                                sx={{
                                    textOverflow: 'ellipsis',
                                }}
                                onClick={() => setSelectedOptionValue(optionValue)}
                            >
                                <Box
                                    sx={{
                                        maxWidth: '70px',
                                    }}
                                >
                                    {' '}
                                    {limitStringLength(menuItemContent, 60)}
                                </Box>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {/* {selectedOptionValue && <OptionValuePreview optionValue={selectedOptionValue} />} */}
        </Box>
    );
};

export default OptionSelectPreview;
