import { RootStateType } from '@b2d/redux/types';
import { achatsConfiguration, factureAchatDataSourcesThunks } from '@b2d/redux/RootStore';
import {
    ACHATS_REDUCER_NAME,
    Configuration,
    FactureAchat,
    FACTURES_ACHATS_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import ICONS from '@b2d/icons';
import {
    FactureAchatApi,
    FactureAchatLigneApi,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { ModelConfiguration, ModelItem, ModelMethods } from '@b2d/pages/Achats/models/types';

const modelKey = 'purchase';
const modelName = 'InvoicePurchase';
const sliceName = FACTURES_ACHATS_SLICE_NAME;
const apiConfiguration = new Configuration(achatsConfiguration);
const api = new FactureAchatApi(apiConfiguration);
const apiLines = new FactureAchatLigneApi(apiConfiguration);
const baseSelector = (state: RootStateType) => state[ACHATS_REDUCER_NAME][sliceName];
const datasourcesThunks = factureAchatDataSourcesThunks;

type ModelStructure = FactureAchat & Record<string, unknown>;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'InvoicePurchase',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: ICONS.models[modelKey],
    color: 'blue',
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.numeroFacture}`,
    getItemSecondaryLabel: (model: ModelStructure) =>
        `${model.dateFacture} (${model.multiAnnonces ? 'Multiple' : 'Simple'})`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.getFacturesAchatsFactureAchatCollection>) =>
        api.getFacturesAchatsFactureAchatCollection(...args),
    delete: (...args: Parameters<typeof api.deleteFactureAchatFactureAchatItem>) =>
        api.deleteFactureAchatFactureAchatItem(...args),
    deleteLine: (
        ...args: Parameters<typeof apiLines.deleteFactureAchatLigneFactureAchatLigneItem>
    ) => apiLines.deleteFactureAchatLigneFactureAchatLigneItem(...args),
    patch: (...args: Parameters<typeof api.patchFactureAchatFactureAchatItem>) =>
        api.patchFactureAchatFactureAchatItem(...args),
    patchLine: (
        ...args: Parameters<typeof apiLines.updateFactureAchatLigneFactureAchatLigneItem>
    ) => apiLines.updateFactureAchatLigneFactureAchatLigneItem(...args),
    read: (...args: Parameters<typeof api.getFactureAchatFactureAchatItem>) =>
        api.getFactureAchatFactureAchatItem(...args),
    readLines: (
        ...args: Parameters<typeof apiLines.getLignesFactureAchatFactureAchatLigneCollection>
    ) => apiLines.getLignesFactureAchatFactureAchatLigneCollection(...args),
    update: (...args: Parameters<typeof api.updateFactureAchatFactureAchatItem>) =>
        api.updateFactureAchatFactureAchatItem(...args),
    create: (...args: Parameters<typeof api.createFactureAchatFactureAchatCollection>) =>
        api.createFactureAchatFactureAchatCollection(...args),
    createLine: (
        ...args: Parameters<typeof apiLines.createFactureAchatLigneFactureAchatLigneCollection>
    ) => apiLines.createFactureAchatLigneFactureAchatLigneCollection(...args),
    syncG3: (...args: Parameters<typeof api.syncG3FactureAchatFactureAchatItem>) =>
        api.syncG3FactureAchatFactureAchatItem(...args),
};
type Endpoints = typeof endpoints;

const model: ModelItem<ModelStructure> & Endpoints = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export default model;
