// React imports
import React, { memo, ReactNode, useEffect } from 'react';

// Components imports
import { Box, Typography } from '@mui/material';
import { CenterCircularProgress, LazyBreadcrumb } from '@europrocurement/flexy-components';

// Data fetching / utils / custom hooks

import { useParams } from 'react-router';
import {
    useCurrentRejectCode,
    useRejectCodeDatasource,
    useSelectRejectCode,
} from '@b2d/hooks/rejectCodeHooks';
import RejectCodeInformationsCard from './RejectCodeInformationsCard';

export type ModalFunctions = {
    toggleModal: () => void;
    closeModal: () => void;
    openModal: () => void;
    setModalContent: (element: ReactNode | null) => void;
};

const RejectCodeDetail: React.FunctionComponent = function () {
    const { idRejectCode: urlRejectCodeId } = useParams();

    const rejectCode = useCurrentRejectCode();
    const selectRejectCode = useSelectRejectCode();
    const dataSource = useRejectCodeDatasource();
    const { selectedStatus } = dataSource;
    const loadingRejectCode = selectedStatus === 'loading';
    const failedRejectCode = selectedStatus === 'failed';

    useEffect(() => {
        const { signal } = new AbortController();
        console.log('urlRejectCodeId: ', urlRejectCodeId, 'rejectCode: ', rejectCode);
        if (
            (urlRejectCodeId && rejectCode && rejectCode.code !== urlRejectCodeId) ||
            (!rejectCode && urlRejectCodeId)
        ) {
            selectRejectCode(urlRejectCodeId, {
                signal,
            });
        }
    }, [rejectCode, selectRejectCode, urlRejectCodeId]);

    if (failedRejectCode) {
        return <Box>Code Rejet introuvable.</Box>;
    }

    if (loadingRejectCode || !rejectCode) {
        return <CenterCircularProgress sx={{ height: '70vh' }} />;
    }

    const formatTitle = (): string => {
        const { code } = rejectCode;

        return `${code ?? 'Non défini'}`;
    };
    const title = formatTitle();

    return (
        <Box
            sx={{
                overflow: 'hidden',
            }}
        >
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/liste' },
                    { path: 'Code Rejets', link: '/achats/liste/rejets' },
                    'Details du code rejet',
                ]}
            />

            <Box display="flex">
                <Typography variant="h1">{title}</Typography>
            </Box>

            <RejectCodeInformationsCard />
        </Box>
    );
};

export default memo(RejectCodeDetail);
