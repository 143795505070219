import { LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';

const LoadingScreen: React.FunctionComponent = function () {
    return (
        <Box
            display="flex"
            flexDirection="column"
            width="75%"
            margin="auto"
        >
            <Typography
                textAlign="center"
                fontSize={20}
                sx={{ opacity: 0.7, margin: '10% 0 50px' }}
            >
                Chargement du formulaire ...
            </Typography>
            <LinearProgress />
        </Box>
    );
};

export default LoadingScreen;
