import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import React from 'react';
import { useFullLayout } from '../../templates/FullLayout/FullLayoutContext';

const CloseSidebarButton: React.FunctionComponent = function () {
    const { isSidebarOpen, setSidebarOpen } = useFullLayout();

    return (
        <>
            {/* Pour le mode Desktop */}
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setSidebarOpen(!isSidebarOpen)}
                size="small"
                sx={{
                    display: {
                        lg: 'flex',
                        xs: 'none',
                    },
                }}
            >
                <FontAwesomeIcon
                    icon={faBars}
                    size="lg"
                />
            </IconButton>

            {/* Pour le Mobile */}
            <IconButton
                size="small"
                color="inherit"
                aria-label="menu"
                onClick={() => setSidebarOpen(!isSidebarOpen)}
                sx={{
                    display: {
                        lg: 'none',
                        xs: 'flex',
                    },
                }}
            >
                <FontAwesomeIcon
                    icon={faBars}
                    size="lg"
                    width="24"
                    height="24"
                />
            </IconButton>
        </>
    );
};

export default CloseSidebarButton;
