import React, { useCallback, useState } from 'react';

import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux/es/exports';
import { useSnackbar } from 'notistack';
import { MediaObject } from '@europrocurement/l2d-domain';
import {
    MediaObject as MediaObjectApiObject,
    WorkflowMailApiSendWorkflowMailWorkflowMailCollectionRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { FlexyHeaderForm, useModal } from '@europrocurement/flexy-components';
import { customizerSelector, workflowMailApi } from '@b2d/redux/RootStore';
import { MAIL_TYPES } from '@b2d/pages/constants';
import WorkflowMailForm, {
    TYPE_MAIL_LIST,
    TypeMailList,
} from '@b2d/pages/Achats/components/forms/WorkflowMailForm';
import HeaderStructure from '@b2d/pages/Achats/components/fragments/HeaderStructure';
import { waitOrSupport } from '@europrocurement/l2d-utils/function-utils';

type WorkflowMailSenderModalProps = {
    paramsToSend: {
        invoiceId: string;
        invoiceUrl: string;
    };
    mediaObject: MediaObject;
};

const WorkflowMailSenderModal: React.FunctionComponent<WorkflowMailSenderModalProps> = function ({
    paramsToSend,
    mediaObject,
}: WorkflowMailSenderModalProps) {
    const { xIdSociete } = useSelector(customizerSelector);

    const [refusalReason, setRefusalReason] = useState<TypeMailList>();

    const { modalActions } = useModal();

    const handleChange = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        value: TypeMailList,
    ) => {
        // const newReason = event.target.name;

        // if (TYPE_MAIL_LIST.includes(newReason as TypeMailList)) {
        setRefusalReason(value);
        // }
    };

    const sendMailFromAchatsApi = useCallback(
        async (
            typeMail: string,
            mediaObjectId: string,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            additionalInfo: { [key: string]: any },
        ) => {
            async function getFileFromUrl(url: string, fileName: string): Promise<File> {
                // Fetch the contents of the URL
                const response = await fetch(url);

                // Get the response body as a blob
                const blob = await response.blob();

                // Create a File object from the blob
                // You can specify the file name and MIME type if needed
                const file = new File([blob], fileName, {
                    type: blob.type,
                });

                return file;
            }

            const retreivedFile = await getFileFromUrl(
                mediaObject.contentUrl || '',
                mediaObject.originalName || '',
            );

            const files: File[] = [retreivedFile];

            // const attachmentsWithKeys = files.map((file, index) => ({
            //     [`attachments${index}`]: file,
            // }));

            const requestParameters: WorkflowMailApiSendWorkflowMailWorkflowMailCollectionRequest =
                {
                    details: {
                        typeMail,
                        mediaObjectId,
                        additionalInfo,
                    },
                    attachments: files,
                    xIdSociete,
                };

            return workflowMailApi.sendWorkflowMailWorkflowMailCollection(requestParameters);
        },
        [mediaObject.contentUrl, mediaObject.originalName, xIdSociete],
    );

    const { enqueueSnackbar } = useSnackbar();

    /**
     * Promise a rejection in case of undefined mandatory parameters.
     *
     * @param suspiciousRefusalReason
     * @param suspiciousMediaObjectId
     * @returns Promise rejection with accurate error message.
     */
    const undefinedMandatoryParams = (
        suspiciousRefusalReason: TypeMailList | undefined,
        suspiciousMediaObjectId: number | undefined,
    ) => {
        let errorMessage = 'Erreur inconnue';

        if (suspiciousRefusalReason === undefined) {
            errorMessage = 'Raison du refus non sélectionnée';
        } else if (suspiciousMediaObjectId === undefined) {
            errorMessage = 'Document de la facture introuvable';
        }

        enqueueSnackbar(errorMessage, {
            variant: 'error',
        });

        return Promise.reject(new Error(errorMessage));
    };

    /**
     * Send refusal treatment mail.
     *
     * Handle possible errors due to undefined mandatory parameters.
     *
     * @returns Promise a Axios response or an accurate error message.
     */
    const sendRefusalTreatmentMail = (comment: string) => {
        if (refusalReason === undefined || mediaObject.id === undefined)
            return undefinedMandatoryParams(refusalReason, mediaObject.id);

        return sendMailFromAchatsApi(refusalReason, String(mediaObject.id), {
            ...paramsToSend,
            comment,
        });
    };

    const handleSubmission = (comment: string | null | undefined) => {
        const listOfPromises = [sendRefusalTreatmentMail(comment || '')];

        Promise.allSettled(listOfPromises).then((results) => {
            let countErrors = 0;

            results.forEach((result) => {
                if (result.status === 'rejected') {
                    countErrors++;
                }
            });

            const nonSpecificErrorMessage = `${countErrors} erreur(s) remontée(s). ${waitOrSupport}`;

            if (countErrors > 0) {
                enqueueSnackbar(nonSpecificErrorMessage, {
                    variant: 'error',
                });

                throw Error(nonSpecificErrorMessage);
            } else {
                enqueueSnackbar('Mail envoyé avec succès', {
                    variant: 'success',
                });
                modalActions.close();
            }
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <FlexyHeaderForm
                outlined
                label={
                    <HeaderStructure
                        icon={
                            <FaOptionIcon
                                icon={faFileInvoice}
                                option={faXmark}
                                size="1x"
                                color="blueGrey.main"
                                optionColor="blueGrey.dark"
                                iconProps={{
                                    radius: 100,
                                }}
                            />
                        }
                        title="Refus de traitement de la facture"
                        controls={undefined}
                    />
                }
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <ToggleButtonGroup
                    color="primary"
                    value={refusalReason}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton value={TYPE_MAIL_LIST[4]}>
                        {MAIL_TYPES[TYPE_MAIL_LIST[4]]}
                    </ToggleButton>
                    <ToggleButton value={TYPE_MAIL_LIST[0]}>
                        {MAIL_TYPES[TYPE_MAIL_LIST[0]]}
                    </ToggleButton>
                    <ToggleButton value={TYPE_MAIL_LIST[1]}>
                        {MAIL_TYPES[TYPE_MAIL_LIST[1]]}
                    </ToggleButton>
                    <ToggleButton value={TYPE_MAIL_LIST[2]}>
                        {MAIL_TYPES[TYPE_MAIL_LIST[2]]}
                    </ToggleButton>
                    <ToggleButton value={TYPE_MAIL_LIST[3]}>
                        {MAIL_TYPES[TYPE_MAIL_LIST[3]]}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Box
                sx={{
                    minHeight: '500px',
                }}
            >
                {refusalReason && mediaObject ? (
                    <WorkflowMailForm
                        refusalReason={refusalReason}
                        mediaObject={mediaObject as MediaObjectApiObject}
                        callbackOverride={(data) => {
                            handleSubmission(data.details?.body);
                        }}
                    />
                ) : (
                    <Typography variant="h4">Sélectionnez un type de mail</Typography>
                )}
            </Box>
        </Box>
    );
};

export default WorkflowMailSenderModal;
