import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Catalog } from '../hooks/useSelectFormality';
import SelectFormalitySearchResult from './SelectFormalitySearchResult';

export type SelectFormalitySearchResultsProps = {
    catalogs: Array<Pick<Catalog, 'checked' | 'id' | 'label' | 'groupId' | 'groupLabel'>>;
    onCheckCatalog: (id: number) => void;
    onUncheckCatalog: (id: number) => void;
};

const SelectFormalitySearchResults: React.FunctionComponent<SelectFormalitySearchResultsProps> =
    function (props) {
        const { catalogs, onCheckCatalog, onUncheckCatalog } = props;
        let currentDisplayedGroup: number | null = null;

        const handleChange = (id: number, checked: boolean) => {
            if (checked) {
                onCheckCatalog(id);
            } else {
                onUncheckCatalog(id);
            }
        };

        if (!catalogs.length) {
            return null;
        }

        return (
            <Box>
                <List
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {catalogs.map((catalog) => {
                        const resultItem = (
                            <ListItem
                                key={`result-catalog-${catalog.id}`}
                                sx={{ padding: 0 }}
                            >
                                <SelectFormalitySearchResult
                                    label={catalog.label}
                                    checked={catalog.checked}
                                    onChange={(checked) => handleChange(catalog.id, checked)}
                                />
                            </ListItem>
                        );

                        if (catalog.groupId !== currentDisplayedGroup) {
                            currentDisplayedGroup = catalog.groupId;

                            return (
                                <Box key={`result-catalog-label-${catalog.id}`}>
                                    <Typography
                                        component="h2"
                                        sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            lineHeight: '32px',
                                        }}
                                    >
                                        {catalog.groupLabel}
                                    </Typography>
                                    {resultItem}
                                </Box>
                            );
                        }

                        return resultItem;
                    })}
                </List>
            </Box>
        );
    };

export default SelectFormalitySearchResults;
