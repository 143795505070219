import React, { ReactNode, useCallback, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import {
    Dossier,
    DossierFormalite,
    FactureAchat,
    Fournisseur,
    MediaObject,
} from '@europrocurement/l2d-domain';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import useSwitchLockValues from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import { navigateToRecapStatement } from '@b2d/utils/navigationHelper';
import { useNavigate, useParams } from 'react-router';
import UpdateTotalsSectionForm from './sections/UpdateTotalsSectionForm';
import UpdateProductsSectionForm from './sections/UpdateProductsSectionForm';
import UpdatePublisherSectionForm from './sections/UpdatePublisherSectionForm';
import UpdateFolioPublicationSectionForm from './sections/UpdateFolioPublicationSectionForm';
import UpdateHeaderSectionForm from './sections/UpdateHeaderSectionForm';
import UpdateFolioFormalitySectionForm from './sections/UpdateFolioFormalitySectionForm';

type UpdateSimpleInvoiceFormProps = {
    mediaObject: MediaObject;
    invoice: FactureAchat;
    lines: Array<FactureAchatLigneJsonldFactureAchatRead>;
    fetchLines: () => void;
    publisher?: Fournisseur;
    folioPublication?: Dossier;
    folioFormality?: DossierFormalite;
};

const UpdateSimpleInvoiceForm: React.FunctionComponent<UpdateSimpleInvoiceFormProps> = function ({
    mediaObject,
    invoice,
    lines,
    fetchLines,
    publisher,
    folioPublication,
    folioFormality,
}: UpdateSimpleInvoiceFormProps) {
    const { getSwitchLockValues, stateSwitchLockValues } = useSwitchLockValues();

    const { statementid } = useParams();

    const navigate = useNavigate();

    const goToResumeAction = useCallback(
        (statementId: string) => {
            navigateToRecapStatement(navigate, +statementId);
        },
        [navigate],
    );

    const renderedActionButtonsStatement: ReactNode = useMemo(
        () =>
            statementid ? (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <Button
                        onClick={() => goToResumeAction(statementid)}
                        variant="contained"
                        style={{ marginTop: '16px', marginBottom: '16px' }}
                    >
                        Récapitulatif de Relevé
                    </Button>
                </Box>
            ) : null,
        [goToResumeAction, statementid],
    );

    return (
        <Box key={`form-${mediaObject?.contentUrl}`}>
            <UpdateHeaderSectionForm
                invoice={invoice}
                switchLockValues={{
                    getSwitchLockValues,
                    stateSwitchLockValues,
                }}
            />
            {folioFormality && !folioPublication ? (
                <UpdateFolioFormalitySectionForm
                    key={`form-publisher-${folioFormality?.id}`}
                    folioFormality={folioFormality}
                    invoice={invoice}
                    lines={lines}
                    onFetchNewLines={fetchLines}
                    switchLockValues={{
                        getSwitchLockValues,
                        stateSwitchLockValues,
                    }}
                />
            ) : null}
            {folioPublication && !folioFormality ? (
                <UpdateFolioPublicationSectionForm
                    key={`publication-form-folio-${folioPublication?.id}`}
                    invoice={invoice}
                    folio={folioPublication}
                    lines={lines}
                    onFetchNewLines={fetchLines}
                    switchLockValues={{
                        getSwitchLockValues,
                        stateSwitchLockValues,
                    }}
                />
            ) : null}
            {publisher ? (
                <UpdatePublisherSectionForm
                    key={`form-publisher-${publisher?.id}`}
                    publisher={publisher}
                    invoice={invoice}
                    lines={lines}
                    switchLockValues={{
                        getSwitchLockValues,
                        stateSwitchLockValues,
                    }}
                />
            ) : null}
            <UpdateProductsSectionForm
                key={`form-products-${invoice?.id}-${lines && lines[0] ? lines[0].id : 'N/A'}-${lines && lines[0] ? lines[0].idDossier : 'N/A'}`}
                invoice={invoice}
                lines={lines}
                onFetchNewLines={fetchLines}
                switchLockValues={{
                    getSwitchLockValues,
                    stateSwitchLockValues,
                }}
            />
            <UpdateTotalsSectionForm
                key={`form-totals-${invoice?.id}`}
                invoice={invoice}
                switchLockValues={{
                    getSwitchLockValues,
                    stateSwitchLockValues,
                }}
            />
            {renderedActionButtonsStatement}
        </Box>
    );
};

export default UpdateSimpleInvoiceForm;
