import {
    Configuration,
    PolesApi,
    PolesApiApiPolesGetCollectionRequest,
} from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import {
    ApiCollectionResponse,
    FetchCollectionData,
    FetchItemDataType,
    SliceFactory,
} from '@europrocurement/l2d-redux-utils';
import {
    type Mutable,
    type PolesUtilisateurApiObject,
    type UtilisateursConfigurationParameters,
} from '../types';
import { POLES_SLICE_NAME, UTILISATEUR_REDUCER_NAME } from '../constants';

export type Poles = PolesUtilisateurApiObject;

// function declared for the slice Factory
const mapperServices = function (poles: Poles) {
    return {
        ...poles,
    } as Poles;
};

/**
 * Function that fetch the collection of poles and store it into the global state Redux
 * @param configuration API configuration parameters
 * @returns the response of API request (get collection/get by id)
 */
export function createPolesSlice(configuration: UtilisateursConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new PolesApi(conf);

    const fetchPolesCollection: FetchCollectionData<Poles> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) => {
        const input: Mutable<PolesApiApiPolesGetCollectionRequest> = {
            xIdSociete,
            page: page + 1,
            itemsPerPage,
        };

        return api
            .apiPolesGetCollection(input)
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<Poles>>;
    };

    // No /poles/id route on API but needed to the sliceFactory
    const fetchPolesItem: FetchItemDataType<Poles> = () =>
        new Promise(() => {
            console.error('fetchPolesItem called but should not');
        });

    // Use the utilisateur reducer for the constant because it depends to Utilisateur API
    const { slice: polesSlice, dataSourcesThunks: polesDataSourcesThunks } =
        SliceFactory.createSlice<PolesUtilisateurApiObject, Poles>(
            POLES_SLICE_NAME,
            UTILISATEUR_REDUCER_NAME,
            [],
            fetchPolesCollection,
            fetchPolesItem,
            mapperServices,
            {},
        );
    return { polesSlice, polesDataSourcesThunks };
}
