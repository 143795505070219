import * as React from 'react';

import { getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { CodeRejetApiObject } from '@europrocurement/l2d-domain';
import { SwitchAsync } from '@europrocurement/flexy-components';
import { NamedAxiosPromise } from '@europrocurement/flexy-components/types/NamedAxiosPromise';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@b2d/redux/types';
import { Stack, Tooltip, Typography } from '@mui/material';
import { ExceptionFacturanteApiObject } from '@europrocurement/l2d-domain/reducers/achats/slices/exceptionFacturante';

type LabeledAsyncSwitchProps = {
    requestsOnToggle: () => Array<NamedAxiosPromise>;
    fetchData: getDataThunkType<CodeRejetApiObject | ExceptionFacturanteApiObject>;
    defaultChecked?: boolean;
    label?: string;
    tooltip?: boolean;
    disabled?: boolean;
};

const LabeledAsyncSwitch: React.FunctionComponent<LabeledAsyncSwitchProps> = function ({
    requestsOnToggle,
    fetchData,
    defaultChecked,
    label,
    tooltip = false,
    disabled = false,
}) {
    const dispatch = useDispatch<AppDispatch>();

    const handleLoaded = React.useCallback(() => {
        dispatch(fetchData({}));
    }, [dispatch, fetchData]);

    const displayLabel: boolean = !!(label && !tooltip);
    const displayLabelAsTooltip: boolean = !!(label && tooltip);

    return (
        <Stack>
            {displayLabel ? <Typography>{label}</Typography> : null}
            <Tooltip title={displayLabelAsTooltip}>
                <SwitchAsync
                    requestsOnToggle={requestsOnToggle}
                    defaultChecked={defaultChecked}
                    onLoaded={handleLoaded}
                    disabled={disabled}
                />
            </Tooltip>
        </Stack>
    );
};

export default LabeledAsyncSwitch;
