import { RootStateType } from '@b2d/redux/types';

export type SelectorsForgerProps = {
    reducerName: string;
    slicerName: string;
    dataSourceName: string;
};

export const useSelectorsForger = ({
    reducerName,
    slicerName,
    dataSourceName = 'main',
}: SelectorsForgerProps) => {
    const dataSourcePath = `${reducerName}.${slicerName}.${dataSourceName}`;

    return {
        // Selector pour l'élément sélectionné
        selectedSelector: (state: RootStateType) => {
            const path = `${dataSourcePath}.selected`;
            return path.split('.').reduce((acc, key) => acc?.[key], state);
        },

        // Selector pour la datasource complète
        datasourceSelector: (state: RootStateType) => {
            const path = dataSourcePath;
            return path.split('.').reduce((acc, key) => acc?.[key], state);
        },

        // Selector pour l'état de la datasource
        statusSelector: (state: RootStateType) => {
            const path = `${dataSourcePath}.status`;
            return path.split('.').reduce((acc, key) => acc?.[key], state);
        },
    };
};
