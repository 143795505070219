/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { FlexyDate, FlexyHeaderForm, FlexyInput } from '@europrocurement/flexy-components';
import { OffresOffreRead, OriginesOrigineRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { FormHelperText } from '@mui/material';
import { SelectInputOption, SelectInputOptions } from '../../forms/types';
import SelectField from '../../components/FormInputs/SelectField';
import { visibilityStepSchema } from './validation';
import CalendarInterval from '../Component/CalendarInterval';
import { MultiStepContextProps } from '../../components/form/MultiStepForm/types';
import useStepForm from '../../components/form/MultiStepForm/useStepForm';

// Todo investigate to determine the type returned by input ()
type LuxonObjectDate = Date & {
    day: number;
    hour: number;
    millisecond: number;
    minute: number;
    month: number;
    second: number;
    year: number;
};

type FormOptionsAndValues<T> = { values: Array<T>; options: SelectInputOptions };

/** Form second step : Visibility of catalog
 * 1) Offer and origin selection ( No origin mean all origin of offer)
 * 2) Available period range (start / end dates)
 */
const VisibilityStep: React.FunctionComponent<MultiStepContextProps> = function (props) {
    // 1a - Form definition / multi step behaviour

    const { multiStepForm } = props;
    const { setCurrentFormContext, formValues } = multiStepForm;

    const visibilityStepForm = useForm({
        mode: 'onChange',
        defaultValues: formValues,
        resolver: zodResolver(visibilityStepSchema),
    });

    useStepForm(visibilityStepForm, multiStepForm);

    React.useEffect(() => {
        setCurrentFormContext(visibilityStepForm);
    }, [visibilityStepForm, setCurrentFormContext]);

    // 1b - Form step destructured data

    const {
        control,
        formState: { errors },
        watch,
        getValues,
        setValue,
        trigger,
        setError,
    } = visibilityStepForm;

    const { startDate, endDate, domainRelatedInformations, offerId, originId } = getValues();
    const { offers, origins } =
        (domainRelatedInformations as {
            offers: FormOptionsAndValues<OffresOffreRead>;
            origins: FormOptionsAndValues<OriginesOrigineRead>;
        }) || {};

    // No end date toggle
    const [perpetualCatalog, setPerpetual] = React.useState(endDate === null);
    const [previousEndDate, setPreviousEndDate] = React.useState(null);

    const togglePerpetualCatalog = () => {
        if (!perpetualCatalog) {
            setPreviousEndDate(endDate);
            setValue('endDate', null);
        }
        if (perpetualCatalog && previousEndDate) {
            setValue('endDate', previousEndDate);
        } else if (perpetualCatalog && !previousEndDate) {
            setValue('endDate', undefined);
        }
        setPerpetual(!perpetualCatalog);
        trigger();
    };

    React.useEffect(() => {
        if (endDate) {
            setPerpetual(false);
        }
    }, [endDate]);
    /** Interval checks / disable logic */
    const shouldDisableStartDate = (date: LuxonObjectDate) => endDate && date >= endDate;
    const shouldDisableEndDate = (date: LuxonObjectDate) => date <= startDate;

    /** Limit available years to twenty years in the future */
    const colorInterval = (date: LuxonObjectDate) => {
        if (perpetualCatalog) {
            return date >= startDate;
        }
        return date >= startDate && date <= endDate;
    };

    /** Return available origins and 'all origins' case
     */
    const getOriginOptions = () => {
        const options = origins?.options;
        // Todo Filter origins by offers [TblRelOffreOrigine]
        const allValueOption = { value: null, label: 'Toutes les origines' };

        return [allValueOption, ...options];
    };

    const optionDoesntBelongToSelectedOrigin = (currentOfferOption: SelectInputOption) => {
        if (!originId) {
            return false;
        }

        const offerOption = offers.values.find(({ id }) => id === currentOfferOption.value);
        if (!offerOption) {
            return false;
        }

        if (!offerOption.origines) {
            return false;
        }
        const offerOriginIds = offerOption.origines.map((origin) => origin?.origine?.id);
        return offerOriginIds.includes(originId);
    };

    const optionDoesntBelongToSelectedOffer = (currentOriginOption: SelectInputOption) => {
        const originOption = origins.values.find(({ id }) => id === currentOriginOption.value);
        if (!originOption) {
            return false;
        }

        if (!originOption.offres) {
            return false;
        }
        const offerIds = originOption?.offres.map((offer) => offer?.offre?.id);

        return offerIds?.includes(offerId);
    };

    return (
        <Box sx={{ marginTop: '15px' }}>
            <Box sx={{ marginBottom: '10px' }}>
                <FlexyHeaderForm
                    sx={{ marginTop: 0 }}
                    label="Offre & origine"
                    outlined
                />
            </Box>

            <form noValidate>
                <Box
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box width="40%">
                        <Controller
                            name="offerId"
                            control={control}
                            render={({ field }) => (
                                <SelectField
                                    {...field}
                                    value={watch(field.name) ?? ''}
                                    placeholder="Sélectionnez une offre"
                                    name={field.name}
                                    label="Offre"
                                    options={offers?.options ?? []}
                                    errors={errors}
                                    isOptionDisabled={optionDoesntBelongToSelectedOrigin}
                                    required
                                />
                            )}
                        />
                    </Box>

                    <Box width="40%">
                        <Controller
                            name="originId"
                            control={control}
                            render={({ field }) => (
                                <SelectField
                                    {...field}
                                    value={watch(field.name) ?? null}
                                    placeholder="Sélectionnez une origine"
                                    name={field.name}
                                    label="Origine"
                                    options={getOriginOptions()}
                                    errors={errors}
                                    isOptionDisabled={optionDoesntBelongToSelectedOffer}
                                    required
                                />
                            )}
                        />
                    </Box>
                </Box>

                <Box sx={{ marginBottom: '10px', marginTop: '20px' }}>
                    <FlexyHeaderForm
                        sx={{ marginTop: 0 }}
                        label="Période de disponibilité du catalogue"
                        outlined
                    />
                </Box>

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    margin="20px 30px"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Box marginBottom="15px">
                            <p>Date de début (obligatoire) : </p>
                        </Box>
                        <Controller
                            name="startDate"
                            control={control}
                            render={({ field }) => {
                                const { ref, ...rest } = field;
                                const error = errors.startDate;
                                return (
                                    <>
                                        <FlexyDate
                                            type="calendar"
                                            {...rest}
                                            value={watch(field.name)}
                                            shouldDisableDate={shouldDisableStartDate}
                                            colorInterval={colorInterval}
                                            minDate={new Date()}
                                        />
                                        {error ? (
                                            <FormHelperText
                                                sx={{
                                                    margin: '4px 14px 0px 14px',
                                                }}
                                                error
                                            >
                                                Veuillez sélectionner une date de début
                                            </FormHelperText>
                                        ) : null}
                                    </>
                                );
                            }}
                        />
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <FlexyHeaderForm
                            sx={{ opacity: perpetualCatalog ? 1 : 0.3 }}
                            label="Catalogue perpétuel"
                        />

                        <FlexyInput
                            name="perpetual_switch"
                            type="boolean"
                            value={perpetualCatalog}
                            onChange={togglePerpetualCatalog}
                            sx={{ marginBottom: '15px' }}
                        />
                        <CalendarInterval
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Box marginBottom="15px">
                            <p>Date de fin : </p>
                        </Box>
                        <Controller
                            name="endDate"
                            control={control}
                            render={({ field }) => {
                                const { ref, ...rest } = field;
                                const error = errors.endDate;
                                return (
                                    <>
                                        <FlexyDate
                                            type="calendar"
                                            {...rest}
                                            value={watch(field.name)}
                                            shouldDisableDate={shouldDisableEndDate}
                                            colorInterval={colorInterval}
                                            minDate={new Date()}
                                        />
                                        {error ? (
                                            <FormHelperText
                                                sx={{
                                                    margin: '4px 14px 0px 14px',
                                                }}
                                                error
                                            >
                                                Veuillez sélectionner une date de fin ou
                                                l&apos;option &apos;catalogue perpétuel&apos;
                                            </FormHelperText>
                                        ) : null}
                                    </>
                                );
                            }}
                        />
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default VisibilityStep;
