import { KeycloakConfig } from 'keycloak-js';

export enum ENV {
    'DEV' = 'dev',
    'QUAL' = 'qual',
    'PREPROD' = 'preprod',
    'PROD' = 'prod',
}

export enum SOCIETES_G3_FULLNAME_FROM_ID {
    'Affiches Parisiennes' = 2,
    'Claude et Goy' = 8,
    'Europrocurement' = 9,
    'Agence Juridique' = 10,
}

export enum SOCIETES_LTL_FULLNAME_FROM_ID {
    'Le Tout Lyon' = 3,
    "L'Essor" = 4,
    'Nouvelles Publications Commerciales' = 5,
    'Le Patriote Beaujolais' = 6,
}

export enum SOCIETES_GIE_FULLNAME_FROM_ID {
    'Legal2Digital' = 12,
}

export const ALL_SOCIETES_FULLNAME_FROM_ID = {
    ...SOCIETES_G3_FULLNAME_FROM_ID,
    ...SOCIETES_LTL_FULLNAME_FROM_ID,
    ...SOCIETES_GIE_FULLNAME_FROM_ID,
};

/**
 * Sociétés référencées dans B2D
 *
 * Constante à modifier pour ajouter ou supprimer une société dans B2D.
 * Il est possible de faire référence à n'importe quelle société de G3, LTL ou GIE.
 */
export const BDD_SWITCH_SOCIETES_IDS_ARRAY = [2, 3, 5, 12] as const;

/**
 * Identifiants des sociétés référencées
 */
export type BDD_SWITCH_SOCIETES_IDS_TYPE = (typeof BDD_SWITCH_SOCIETES_IDS_ARRAY)[number];

/**
 * Libelles des sociétés référencées.
 */
export const BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID = BDD_SWITCH_SOCIETES_IDS_ARRAY.reduce(
    (acc, id) => {
        const name = ALL_SOCIETES_FULLNAME_FROM_ID[id];
        if (name) {
            acc[id] = name;
        }
        return acc;
    },
    {} as { [id in BDD_SWITCH_SOCIETES_IDS_TYPE]: string },
);

export type ENV_API = 'local' | ENV;

export type ApiEnvType = { [envName in ENV_API]?: string };

export enum APIS_ENUM {
    'ACHATS' = 'achats',
    'SIGN' = 'sign',
    'TIERS' = 'tiers',
    'DOSSIERS' = 'dossiers',
    'OFFRE' = 'offre',
    'FORMALITE' = 'formalite',
    'FACTURATION' = 'facturation',
    'MAIL' = 'mail',
    'FORMULAIRE' = 'formulaire',
    'AL' = 'annoncesLegales',
    'UTILISATEUR' = 'utilisateur',
    'SIREN' = 'siren',
    'GEOLOC' = 'geoloc',
}

export type APIS_ENV_OPTIONS = {
    [x in APIS_ENUM]: ApiEnvType;
};

export type KC_ENV_OPTIONS = {
    [x in ENV]: Omit<KeycloakConfig, 'clientId'>;
};

export type APIS_ENV_SELECTED = {
    [x in APIS_ENUM]: keyof ApiEnvType;
};

export type KC_ENV_SELECTED = keyof KC_ENV_OPTIONS;

export type APIS_KC_ENV_OPTIONS = {
    apis: APIS_ENV_OPTIONS;
    keycloak: KC_ENV_OPTIONS;
};

export type APIS_KC_ENV_SELECTED = {
    apis: APIS_ENV_SELECTED;
    keycloak: KC_ENV_SELECTED;
};

// Apis
const remoteUrls = {
    qual: 'https://api.qual.legal2digital.net',
    preprod: 'https://api.preprod.legal2digital.net',
    prod: 'https://api.legal2digital.net',
} as const;
const defaultPort = 8080;
const localPorts = {
    achats: 8086,
    tiers: defaultPort,
    sign: defaultPort,
    dossiers: 8087,
    offre: 8090,
    formalite: 8083,
    facturation: defaultPort,
    mail: 8081,
    formulaire: defaultPort,
    annoncesLegales: defaultPort,
    utilisateur: 8084,
    geoloc: 8085,
    siren: 8085,
};
const localPath = (apiName: APIS_ENUM) => `http://localhost:${localPorts[apiName]}`;
const apiConfig = (apiName: APIS_ENUM) => ({
    local: localPath(apiName),
    ...remoteUrls,
});
const keycloakConfig = (envName: ENV, url: string) => ({
    realm: envName,
    url,
});

const keycloakPreprodUrl = 'https://sso.preprod.legal2digital.net';
const keycloakProdUrl = 'https://sso.legal2digital.net';

const APIENV: APIS_KC_ENV_OPTIONS = {
    apis: {
        achats: apiConfig(APIS_ENUM.ACHATS),
        tiers: apiConfig(APIS_ENUM.TIERS),
        sign: apiConfig(APIS_ENUM.SIGN),
        dossiers: apiConfig(APIS_ENUM.DOSSIERS),
        offre: apiConfig(APIS_ENUM.OFFRE),
        formalite: apiConfig(APIS_ENUM.FORMALITE),
        facturation: apiConfig(APIS_ENUM.FACTURATION),
        mail: apiConfig(APIS_ENUM.MAIL),
        formulaire: apiConfig(APIS_ENUM.FORMULAIRE),
        annoncesLegales: apiConfig(APIS_ENUM.AL),
        utilisateur: apiConfig(APIS_ENUM.UTILISATEUR),
        geoloc: apiConfig(APIS_ENUM.GEOLOC),
        siren: apiConfig(APIS_ENUM.SIREN),
    },
    keycloak: {
        dev: keycloakConfig(ENV.DEV, keycloakPreprodUrl),
        qual: keycloakConfig(ENV.QUAL, keycloakPreprodUrl),
        preprod: keycloakConfig(ENV.PREPROD, keycloakPreprodUrl),
        prod: keycloakConfig(ENV.PROD, keycloakProdUrl),
    },
};

export const GOOGLE_MAPS_API_KEY = {
    dev: 'AIzaSyDjIuUmFWJu8DEf2A5WQF_RhBAH7m9l4UY',
    qual: 'AIzaSyDjIuUmFWJu8DEf2A5WQF_RhBAH7m9l4UY',
    preprod: 'AIzaSyDjIuUmFWJu8DEf2A5WQF_RhBAH7m9l4UY',
    prod: 'AIzaSyDjIuUmFWJu8DEf2A5WQF_RhBAH7m9l4UY',
};

export default APIENV;
