import React from 'react';
import { Box, Skeleton } from '@mui/material';

const SkeletonQuickSearch: React.FunctionComponent = function () {
    return (
        <Box sx={{ width: '100%', padding: '16px' }}>
            <Skeleton
                variant="rounded"
                width="100%"
                height={56}
            />
            <Box
                sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
                width="50%"
            >
                {Array.from({ length: 3 }).map((_, index) => {
                    const key = `skeleton-quick-search-item-${index}`;
                    return (
                        <Skeleton
                            key={key}
                            variant="text"
                            width="33%"
                            height={24}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

export default SkeletonQuickSearch;
