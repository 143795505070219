import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';
import {
    ColumnDatatable,
    FilterOptionValue,
    FiltersDatatableList,
} from '@europrocurement/flexy-datatable';
import { ArticlesJsonldArticleRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '@europrocurement/l2d-icons';
import { domainSelector, useOffreService } from '@europrocurement/l2d-domain';
import { formatOptionsByKeys } from '../forms/options';
import ArticleForm from './Form';
import ModalFormTitle from '../components/form/ModalFormTitle';
import { displayCustomValue, getPriceColumn, getStringColumn } from '../listings';
import ListingWithPreview from '../components/ListingWithPreview';

const Panel: React.FunctionComponent = function () {
    const { modalActions } = React.useContext(ModalContext);

    const { articleModel: model } = useOffreService();

    const fetchedDomainsOptions = useSelector(domainSelector).main.data;
    const domainsOptions = formatOptionsByKeys(fetchedDomainsOptions, 'id', 'libelle');

    const dispatch = useDispatch();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reloadList = () => dispatch(model.datasourcesThunks.main.getData({}) as any);

    const listingButton = {
        label: 'Créer un nouvel article',
        icon: addItem,
        action: () =>
            modalActions.call(
                <>
                    <ModalFormTitle
                        icon={model.icon}
                        title="Création d'un article"
                    />
                    <ArticleForm
                        isCreate
                        afterSubmit={reloadList}
                    />
                </>,
            ),
    };

    const columns: ColumnDatatable<ArticlesJsonldArticleRead>[] = [
        getStringColumn('id'),
        displayCustomValue('domaine', (domaine) => domaine.libelle, 'Domaine'),
        getStringColumn('libelle', 'Libellé'),
        getStringColumn('codeArticle'),
        getStringColumn('rubArticle', 'Rubrique'),
        getPriceColumn('prixUnitaire'),
    ];

    // Force string value as defined in FilterOptionValue
    const domainsSelectInputOptions: FilterOptionValue[] = domainsOptions.map((option) => ({
        ...option,
        value: option.value?.toString() ?? '',
    }));

    const filters: FiltersDatatableList = [
        {
            label: 'Domaine',
            field: 'domaineId',
            type: 'staticSelect',
            options: domainsSelectInputOptions,
            renderFilterList: (value) => {
                const selectedOption = domainsOptions.find(
                    (option) => option.value === value.toString(),
                );
                return selectedOption ? selectedOption.label : 'Aucun';
            },
        },
        {
            label: 'Article supprimé',
            field: 'deleted',
            type: 'boolean',
        },
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des articles"
            listingButton={listingButton}
            listingColumns={columns}
            form={ArticleForm}
            model={model}
            filters={filters}
            filtersControl
        />
    );
};

export default Panel;
