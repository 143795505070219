import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FlexyTextField, ObjectGroupColor } from '../../atoms';
import ColorSelector, { Color } from '../ColorSelector';

export type ObjectGroupFormData = {
    namegroup: string;
    color?: ObjectGroupColor;
};
export type ObjectGroupsCreateProps = {
    title: string;
    fieldLabel: string;
    submitButtonLabel: string;
    placeholderLabel?: string;
    colorFieldLabel?: string;
    colors?: Array<ObjectGroupColor>;
    onSubmit: (data: ObjectGroupFormData) => void;
    onCancel?: () => void;
};

const ObjectGroupsCreate: React.FunctionComponent<ObjectGroupsCreateProps> = function ({
    title,
    fieldLabel,
    submitButtonLabel,
    placeholderLabel,
    colorFieldLabel = 'Couleur du groupe',
    colors = [],
    onSubmit,
    onCancel,
}) {
    const [formData, setFormData] = React.useState<ObjectGroupFormData>({
        namegroup: '',
        color: undefined,
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectColor = (color: Color) => {
        setFormData({ ...formData, color: color ? (color as ObjectGroupColor) : undefined });
    };

    const handleCancel = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormData({ namegroup: '' });
        if (onCancel) {
            onCancel();
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(formData);
    };

    return (
        <Box className="groupment-modal">
            <form onSubmit={handleSubmit}>
                <Box
                    className="body"
                    sx={{ marginBottom: '20px' }}
                >
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        <Box className="title">{title}</Box>
                        <Box sx={{ width: '100%', borderRadius: '10px' }}>
                            <FlexyTextField
                                label={fieldLabel}
                                variant="outlined"
                                value={formData.namegroup}
                                name="namegroup"
                                onChange={handleInputChange}
                                placeholder={placeholderLabel || ''}
                                sx={{ width: '100%' }}
                                required
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ marginBottom: '20px' }}>
                    <ColorSelector
                        label={colorFieldLabel}
                        colors={colors}
                        onSelect={handleSelectColor}
                        required={false}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '0 24px',
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleCancel}
                        sx={{ color: 'primary.dark' }}
                    >
                        <Typography className="cancel-label">Annuler</Typography>
                    </Button>
                    <Button
                        type="submit"
                        className="submit-btn"
                        variant="contained"
                        color="primary"
                        data-test-id="submit-create-group"
                        sx={{ backgroundColor: 'primary.dark', padding: '14px 22px' }}
                    >
                        {submitButtonLabel || 'Ajouter'}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default ObjectGroupsCreate;
