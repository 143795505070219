import * as React from 'react';
import { Box } from '@mui/material';
import { warningIcon } from '@europrocurement/l2d-icons';
import { TooltipedIcon } from '@europrocurement/flexy-components';
import { Warning } from './types';

type ComponentProps = {
    warnings?: Warning[];
    lineIndex: number;
};

const LineWarnings: React.FunctionComponent<ComponentProps> = function ({ warnings, lineIndex }) {
    const keyGenerate = (name: string) => `warning_item_${name}_${lineIndex}`;

    const hasWarns = warnings?.some((warn) => warn.condition);

    if (!warnings || warnings.length === 0) {
        return null;
    }

    const getToolTipContent = () =>
        warnings.map(({ name, message, condition }) => {
            if (condition) {
                return <p key={keyGenerate(name)}>{message}</p>;
            }
            return null;
        });

    return (
        <Box
            alignItems="center"
            display="flex"
            sx={{ opacity: 0.3 }}
            justifyContent="space-between"
            width="40px"
            marginX="30px"
        >
            {hasWarns && (
                <TooltipedIcon
                    tooltip={getToolTipContent()}
                    icon={warningIcon}
                />
            )}
        </Box>
    );
};

export default LineWarnings;
