import React, { ReactElement, useEffect, useId, useState } from 'react';
import { FlexyFormLabel } from '@europrocurement/flexy-components';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
    Autocomplete,
    Box,
    Chip,
    FormHelperText,
    lighten,
    ListItem,
    TextField,
    useTheme,
} from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import AutocompleteListbox from './AutocompleteListbox';
import AutocompleteOption from './AutocompleteOption';

export type ControlledAutocompleteProps = {
    name: string;
    rules: RegisterOptions;
    inputlabel: string;
    required?: boolean;
    placeholder?: string;
    listboxTitle?: string;
    getOptionValue: (option: unknown) => number | string;
    getOptionLabel: (option: unknown) => ReactElement;
    onSearch: (term: string) => Promise<Array<unknown>>;
};

const ControlledAutocomplete: React.FunctionComponent<ControlledAutocompleteProps> = function ({
    name,
    rules,
    inputlabel,
    required,
    placeholder,
    listboxTitle,
    getOptionValue,
    getOptionLabel,
    onSearch,
}) {
    const { control } = useFormContext();
    const id = useId();
    const [options, setOptions] = useState<Array<unknown>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { palette } = useTheme();

    const refreshOptions = (term: string) => {
        setLoading(true);
        onSearch(term)
            .then((items) => {
                setOptions(items);
            })
            .catch(() => {
                setOptions([]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshOptions('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                    {inputlabel && inputlabel !== '' ? (
                        <FlexyFormLabel
                            data-testid="test-id-FlexyInput-label"
                            required={required}
                        >
                            {inputlabel}
                        </FlexyFormLabel>
                    ) : null}
                    <Autocomplete
                        multiple
                        id={id}
                        options={options}
                        value={value}
                        disableCloseOnSelect
                        loading={loading}
                        getOptionLabel={() => ''}
                        getOptionKey={getOptionValue}
                        clearIcon={false}
                        noOptionsText="Votre recherche ne correspond à aucun résultat."
                        loadingText="Recherche en cours"
                        popupIcon={null}
                        filterOptions={(opts) => opts}
                        isOptionEqualToValue={(a: unknown, b: unknown) =>
                            getOptionValue(a) === getOptionValue(b)
                        }
                        onInputChange={(_event, inputValue) => refreshOptions(inputValue)}
                        onChange={(_event, newValue) => {
                            onChange(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={value && value.length > 0 ? '' : placeholder}
                            />
                        )}
                        renderOption={(props, option, state) => {
                            // eslint-disable-next-line react/prop-types
                            const { key, ...otherProps } = props;
                            const itemProps = {
                                ...otherProps,
                                color: 'primary.main',
                            };

                            return (
                                <ListItem
                                    {...itemProps}
                                    key={key}
                                >
                                    <AutocompleteOption
                                        selected={state?.selected || false}
                                        option={option}
                                        getOptionLabel={getOptionLabel}
                                    />
                                </ListItem>
                            );
                        }}
                        ListboxComponent={AutocompleteListbox}
                        ListboxProps={{
                            title: listboxTitle,
                            sx: { padding: '10px' },
                        }}
                        renderTags={(tags, getTagProps) =>
                            tags.map((tag, tagIndex) => {
                                const baseTagProps = { ...getTagProps({ index: tagIndex }) };
                                const tagProps = {
                                    ...baseTagProps,
                                    label: getOptionLabel(tag),
                                    sx: {
                                        backgroundColor: 'primary.light',
                                        paddingRight: '6px',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        color: lighten(palette.secondary.main, 0.3),
                                    },
                                    deleteIcon: (
                                        <Box onClick={baseTagProps.onDelete}>
                                            <FaOptionIcon
                                                sx={{
                                                    lineHeight: 0,
                                                    color: 'primary.dark',
                                                    fontSize: '10px',
                                                    padding: 0,
                                                }}
                                                icon={['fas', 'close']}
                                            />
                                        </Box>
                                    ),
                                };

                                return <Chip {...tagProps} />;
                            })
                        }
                    />
                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledAutocomplete;
