import {
    ArticlesArticleRead,
    TblRelArticlePackageRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { z } from 'zod';
import { PRICE_REGEX } from '@europrocurement/l2d-modules/validation';
import { ADVERT_CATEGORY_CODE } from '../../Article/rules';
import { ArticleFormPricingState } from './types';

const FORMALITY_DOMAIN_ID = 3;

/**  Zod validation schema : Form schema and validation patterns */

export const configurationStepSchema = z
    .object({
        label: z.string().min(1, 'Champs requis').max(255, 'Le libellé est trop long'),
        commercialName: z
            .string()
            .min(1, 'Champs requis')
            .max(255, 'Le nom commercial est trop long'),
        domainId: z.number(),
        formalityId: z.number().nullable().optional(),
    })
    .superRefine(({ formalityId, domainId }, context) => {
        if (domainId === FORMALITY_DOMAIN_ID && !formalityId) {
            return context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Le choix d'une formalité est obligatoire",
                path: ['formalityId'],
            });
        }

        return null;
    });

export const articlesObject = z
    .object({
        articleId: z.number(),
        label: z.string().max(255, 'Le libellé est trop long').nullable(),
        price: z.string().regex(PRICE_REGEX, 'Le format du prix est incorrect').nullable(),
        quantity: z.number(),
        subTypeId: z.number().nullable().optional(),
        invoicingCategory: z.string().nullable(),
        regle: z.string().trim().nullable().optional(), // Option value specific field
    })
    .superRefine(({ invoicingCategory, subTypeId }, context) => {
        if (invoicingCategory === ADVERT_CATEGORY_CODE && !subTypeId) {
            return context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Le choix d'un sous-type est obligatoire",
                path: ['subTypeId'],
            });
        }

        return null;
    });
const arrayOfItemWithIds = z.array(z.object({ id: z.number() }));

export const articlesValidation = z
    .array(articlesObject)
    .nonempty()
    .superRefine((articles, context) => {
        const articlesContainsAtLeastOneArticle = (): boolean => {
            const numberOfArticles = articles.filter(
                (item) => item.invoicingCategory !== 'LIB',
            ).length;
            return numberOfArticles > 0;
        };

        if (!articlesContainsAtLeastOneArticle()) {
            return context.addIssue({
                code: z.ZodIssueCode.custom,
                // message: 'Il faut choisir au minimum un article',
            });
        }

        return null;
    });

export const articlesStepSchema = z.object({
    articles: articlesValidation,
});

export const relationshipsStepSchema = z.object({
    actions: arrayOfItemWithIds,
    documents: arrayOfItemWithIds,
    additionalFields: arrayOfItemWithIds,
});

export type ConfigurationSchema = z.infer<typeof configurationStepSchema>;

type ArticleFormItem = z.infer<typeof articlesStepSchema>['articles'][number];
export type ArticleAdditionalInformations = {
    id: string; // DraggableID
    originalArticle: ArticlesArticleRead | TblRelArticlePackageRead['article'];
    priceInformations?: ArticleFormPricingState; // Informations de tartification au forfait
};

export type ArticlesSchema = { articles: Array<ArticleFormItem & ArticleAdditionalInformations> };
export type RelationShipsSchema = z.infer<typeof relationshipsStepSchema>;

export type PackageFormData = ConfigurationSchema & ArticlesSchema & RelationShipsSchema;
