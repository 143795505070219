import React, { ReactElement, ReactNode } from 'react';

import _ from 'lodash';

import { Box, Typography } from '@mui/material';
import { FactureAchatNote } from '@europrocurement/l2d-domain';
import { EmptyBox, FlexyHeaderForm } from '@europrocurement/flexy-components';

import type { NoteFactureForm } from '@b2d/pages/Achats/components/forms/types';
import NoteObject from '@b2d/pages/Achats/components/notes/NoteObject';

export type ListNotesObjectProps = {
    title?: ReactNode;
    notes?: FactureAchatNote[];
    listActions?: ReactElement;
    removeItem?: (note: FactureAchatNote) => void;
    editItem?: (note: FactureAchatNote) => void;
    onEditModeChange?: (editionMode: boolean) => void; // Partage l'état de l'editionMode entre La modal de notes et la note.
};

const ListNotesObject: React.FunctionComponent<ListNotesObjectProps> = function ({
    title,
    notes,
    listActions,
    removeItem,
    editItem,
    onEditModeChange,
}) {
    return notes ? (
        <Box>
            <FlexyHeaderForm
                label={
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Typography
                            variant="h2"
                            color="secondary"
                        >
                            {title}
                        </Typography>
                        <Box>{listActions}</Box>
                    </Box>
                }
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                }}
            >
                {notes.length > 0 ? (
                    notes.map((note) => (
                        <Box key={`${note['@id']}`}>
                            {removeItem || editItem ? (
                                <NoteObject
                                    note={note as NoteFactureForm}
                                    onRemove={() => {
                                        if (!removeItem) return;
                                        removeItem(note);
                                    }}
                                    onEdit={(noteContent) => {
                                        if (!editItem) return;
                                        const edited = _.cloneDeep(note);
                                        edited.note = noteContent;
                                        editItem(edited);
                                    }}
                                    onEditModeChange={onEditModeChange}
                                />
                            ) : (
                                <NoteObject note={note as NoteFactureForm} />
                            )}
                        </Box>
                    ))
                ) : (
                    <EmptyBox
                        text="Pas de notes"
                        sx={{
                            marginTop: '10px',
                        }}
                    />
                )}
            </Box>
        </Box>
    ) : (
        <EmptyBox
            text="Pas de notes"
            sx={{
                marginTop: '10px',
            }}
        />
    );
};

export default ListNotesObject;
