/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { UseFetchCollection } from '../Package/Form/useFetchCollection';

type UseStoreArgument = {
    hook: UseFetchCollection<unknown>;
    action: ActionCreatorWithPayload<unknown>;
    callback?: (result: unknown) => void;
};
type UseStoreResult = (arg: UseStoreArgument) => void;

/** This hook dispatch related action on result
 * - You can pass an optional format callback to format the result before caching it.
 */
const useCacheData: UseStoreResult = ({ hook, action, callback }) => {
    const dispatch = useDispatch();
    const { fetch, result, isLoaded, isFetching } = hook;
    const resultFetched = isLoaded && !isFetching;
    const hasCallback = callback && typeof callback === 'function';

    // On mount fetch all ressources
    useEffect(() => {
        fetch();
    }, []);

    // Store result ( optionally formated ) in redux tree
    useEffect(() => {
        if (resultFetched) {
            const payload = hasCallback ? callback(result) : result;
            dispatch(action(payload));
        }
    }, [result, isLoaded, isFetching]);
};
export default useCacheData;
