import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { Catalog } from '../hooks/useSelectFormality';

export type SearchChipsProps = {
    catalogs: Array<Pick<Catalog, 'id' | 'label'>>;
    uncheckCatalog: (catalogId: number) => void;
};

const SearchChips: React.FunctionComponent<SearchChipsProps> = function (props) {
    const { catalogs, uncheckCatalog } = props;
    const theme = useTheme();
    const { palette } = theme;

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px 15px' }}>
            {catalogs.map((catalog) => (
                <Button
                    sx={{
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '2px 8px',
                        gap: '0 5px',
                        color: palette.grey[700],
                        backgroundColor: palette.primary.light,
                        '&:hover': {
                            backgroundColor: palette.grey[200],
                        },
                    }}
                    key={`chip-${catalog.id}`}
                    onClick={() => {
                        uncheckCatalog(catalog.id);
                    }}
                >
                    <Typography variant="l2dcaption">{catalog.label}</Typography>
                    <FaOptionIcon
                        sx={{ color: palette.primary.dark, fontSize: '10px' }}
                        icon={faTimes}
                    />
                </Button>
            ))}
        </Box>
    );
};

export default SearchChips;
