import { LigneProduit } from '@b2d/pages/Achats/components/forms/types';
import React, { useMemo } from 'react';
import { convertToNumberAndFormatToString } from '@b2d/pages/Achats/components/forms/functions/calculsProduits';
import AdditionalDataLine from './AdditionalDataLine';

type AdditionalSellLineProps = {
    lineProductSell: LigneProduit;
};

const AdditionalSellLine: React.FunctionComponent<AdditionalSellLineProps> = function ({
    lineProductSell,
}: AdditionalSellLineProps) {
    const data: Array<string | [string, string]> = useMemo(() => {
        const { rubriqueFacturation, libelle, ht, tva, ttc } = lineProductSell;

        return [
            rubriqueFacturation?.code || '?',
            libelle || '?',
            ['ht', convertToNumberAndFormatToString(ht) || '?'],
            ['tva', convertToNumberAndFormatToString(tva) || '?'],
            ['ttc', convertToNumberAndFormatToString(ttc) || '?'],
        ];
    }, [lineProductSell]);

    return (
        <AdditionalDataLine
            libelle="Vente"
            data={data}
        />
    );
};

export default AdditionalSellLine;
