import React from 'react';
import { Avatar, ListItemAvatar, Tooltip, Typography, useTheme } from '@mui/material';
import { faArrowRotateBack } from '@fortawesome/pro-duotone-svg-icons';
import { formatPrice } from '@europrocurement/l2d-utils';
import { Box } from '@mui/system';
import { boxIcon, euroIcon, tagIcon } from '@europrocurement/l2d-icons';
import { TooltipedIcon } from '@europrocurement/flexy-components';
import { FormContextType } from '../../Package/Components/types';
import LineActionButton from '../../Package/Components/LineActionButton';
import OverrideField from '../../components/FormInputs/OverrideField';
import QuantityField from '../../components/FormInputs/QuantityField';

export type CatalogFormOverridableArticleItem = {
    originalLabel: string;
    originalPrice: string;
    originalQuantity: number;
    label: CatalogFormOverridableArticleItem['originalLabel'] | null;
    price: CatalogFormOverridableArticleItem['originalPrice'] | null;
    quantity: CatalogFormOverridableArticleItem['originalQuantity'] | null;
};

type ComponentProps = {
    article: CatalogFormOverridableArticleItem;
    context: FormContextType;
    index: number;
};

/** Catalog line override : This component display inputs / overrided statuses for label / price & article quantity */
const CatalogArticleOverrideLine: React.FunctionComponent<ComponentProps> = function ({
    article,
    context,
    index,
}) {
    const { originalLabel, originalPrice, originalQuantity, label, price, quantity } = article;
    const { setValue, getValues, watch } = context;

    const inputBasePath = `catalogArticlesOverrides.${index}`;
    const articleFormData = getValues().catalogArticlesOverrides[index];
    watch(articleFormData);

    const lineNumber = index + 1;

    /** Determine overrided statuses for label / price / quantity and article */
    const isLabelOverrided = !!(label && label.trim() !== originalLabel);
    const isPriceOverrided = price && parseFloat(price) !== parseFloat(originalPrice);
    const isQuantityOverrided = quantity && originalQuantity !== quantity;
    const isArticleOverrided = isLabelOverrided || isPriceOverrided || isQuantityOverrided;

    const overridedBackgroundColor = useTheme().palette.primary;

    const resetOverridedValues = () => {
        setValue(`${inputBasePath}.label`, null);
        setValue(`${inputBasePath}.price`, null);
        setValue(`${inputBasePath}.quantity`, null);
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            minHeight="65px"
            maxHeight="65px"
            sx={{
                bgcolor: isArticleOverrided ? overridedBackgroundColor.light : '',
                borderRadius: '10px',
                marginY: '10px',
                padding: '10px',
            }}
        >
            <Tooltip title={isArticleOverrided ? 'Article surchargé' : ''}>
                <ListItemAvatar sx={{ padding: '10px' }}>
                    <Avatar
                        sx={{ bgcolor: isArticleOverrided ? overridedBackgroundColor.main : '' }}
                    >
                        <Typography>{lineNumber}</Typography>
                    </Avatar>
                </ListItemAvatar>
            </Tooltip>
            <Box sx={{ marginX: '20px' }}>
                <OverrideField
                    context={context}
                    value={label}
                    inputName={`${inputBasePath}.label`}
                    placeholder={originalLabel ?? ''}
                />
            </Box>
            <Box
                sx={{ marginX: '20px' }}
                maxWidth="205px"
                height="40px"
            >
                <OverrideField
                    context={context}
                    inputName={`${inputBasePath}.price`}
                    placeholder={originalPrice ?? '(Prix non défini)'}
                    value={price}
                />
            </Box>
            <QuantityField
                formContext={context}
                inputName={`${inputBasePath}.quantity`}
                value={quantity}
                originalValue={originalQuantity}
            />
            <Tooltip title="Valeur par défaut">
                <LineActionButton
                    actionName="reset overrided values"
                    icon={faArrowRotateBack}
                    onClick={resetOverridedValues}
                    disabled={!isArticleOverrided}
                    bordered
                    sx={{ marginRight: '15px' }}
                />
            </Tooltip>

            <Box
                sx={{ opacity: 0.5 }}
                display="flex"
                justifyContent="center"
                marginX={5}
            >
                <Box
                    marginRight={3}
                    sx={{ visibility: isLabelOverrided ? '' : 'hidden' }}
                >
                    <TooltipedIcon
                        tooltip={`Libellé surchargé  (Ancien libéllé : ${originalLabel}) `}
                        icon={tagIcon}
                    />
                </Box>

                <Box
                    marginRight={3}
                    sx={{ visibility: isPriceOverrided ? '' : 'hidden', opacity: 0.4 }}
                >
                    <TooltipedIcon
                        tooltip={`Prix surchargé  (Ancien prix : ${originalPrice ? `${formatPrice(originalPrice)} €` : 'Aucun tarif'})`}
                        icon={euroIcon}
                    />
                </Box>
                <Box sx={{ visibility: isQuantityOverrided ? '' : 'hidden', opacity: 0.5 }}>
                    <TooltipedIcon
                        tooltip={`Quantité surchargée (Ancienne quantité : ${originalQuantity}`}
                        icon={boxIcon}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default CatalogArticleOverrideLine;
