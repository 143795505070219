import { UseFormReturn } from 'react-hook-form';
import { FormObject } from '@europrocurement/flexy-form';
import { useSelector } from 'react-redux';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import {
    FormaliteFormaliteRead,
    TblRelTypeDocumentArticleRead,
    TblRelTypeDocumentPackageRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { articleIcon, formalityIcon } from '@europrocurement/l2d-icons';
import { useFormaliteService } from '@europrocurement/l2d-domain';
import { castIriToId } from '@europrocurement/l2d-utils';
import { OfferStateType } from '@europrocurement/l2d-domain/reducers/offre/types';
import { ReadOnlyDocumentItem, ReadOnlyDocuments } from './types';
import { ArticleAdditionalInformations } from '../../Package/Form/validation';

const FORM_CONTEXT_KEY = 'readOnlyDocuments';

/** Package & Catalog read only documents management :
 * Related methods
 */
const useReadOnlyDocuments = (formContext: UseFormReturn<FormObject>) => {
    const { formaliteApi } = useFormaliteService();

    const documents = (state: OfferStateType) => state.offre.cached.documents;
    const cachedDocuments = useSelector(documents);
    const { request } = useApiRequest();

    const readOnlyDocuments = formContext.getValues().readOnlyDocuments as ReadOnlyDocuments;
    const setReadOnlyDocuments = (values: ReadOnlyDocuments) => {
        formContext.setValue(FORM_CONTEXT_KEY, values);
    };

    /** Append formalities related documents to the read only list */
    const updateFomalitiesDocuments = (payload: ReadOnlyDocumentItem[]) => {
        const currentValues = formContext.getValues().readOnlyDocuments as ReadOnlyDocuments;
        if (currentValues) {
            setReadOnlyDocuments({
                ...currentValues,
                formalities: payload,
            });
        }
    };

    /** Append articles or options related documents to the read only list */
    const addDocumentsInCategory = (
        category: 'articles' | 'options',
        newDocuments: ReadOnlyDocumentItem[],
    ) => {
        setReadOnlyDocuments({
            ...readOnlyDocuments,
            [category]: [...readOnlyDocuments[category], ...newDocuments],
        });
    };

    /** Remove articles or options related documents from the read only list */
    const removeDocumentsFromCategory = (
        category: 'articles' | 'options',
        articleDocumentIds: number[],
    ) => {
        const filteredDocuments = readOnlyDocuments[category].filter(
            (document) => articleDocumentIds.includes(document.id) === false,
        );

        const newList = {
            ...readOnlyDocuments,
            [category]: filteredDocuments,
        };
        setReadOnlyDocuments(newList);
    };

    /** Clear readonly documents list for a given category */
    const clearDocumentsInCategory = (category: 'articles' | 'options' | 'formalities') => {
        const updatedList = {
            ...readOnlyDocuments,
            [category]: [],
        };
        setReadOnlyDocuments(updatedList);
    };
    const actions = {
        updateFomalitiesDocuments,
        addDocumentsInCategory,
        removeDocumentsFromCategory,
        clearDocumentsInCategory,
    };

    const storeFormalitiesReadOnlyDocuments = (result: { data: FormaliteFormaliteRead }) => {
        const resultData = result.data as { documents: string[]; libelle: string };
        const formalityName = resultData.libelle;
        const documentsResult = resultData.documents.map((documentIri) => {
            const documentId = castIriToId(documentIri);
            if (!documentId) {
                throw new Error('invalid document id');
            }
            const document = cachedDocuments?.find(
                (cachedDocument) => cachedDocument?.id === documentId,
            );

            if (!document) {
                throw new Error("Document doesn't exist in cached values");
            }

            return {
                id: document.id as number,
                label: document.libelle as string,
                subTitle: document.help as unknown as string,
                originEntityName: 'Formalité',
                originIcon: formalityIcon,
                originName: formalityName as string,
            };
        });
        actions.updateFomalitiesDocuments(documentsResult);
    };

    const generateArticleDocument = (
        label: string,
        subTitle: string,
        originName: string,
        id: number,
    ) => ({
        label,
        subTitle,
        originEntityName: 'Article',
        originName,
        originIcon: articleIcon,
        id,
    });

    const formatArticleDocumentItems = (
        documentsToFormat:
            | TblRelTypeDocumentArticleRead[]
            | TblRelTypeDocumentPackageRead[]
            | undefined,
        originalArticle: ArticleAdditionalInformations['originalArticle'],
    ) => {
        if (!documentsToFormat) {
            return [];
        }

        const formatedArticles = documentsToFormat.map((document) => {
            const label = document.typeDocument?.libelle;
            const id = document.typeDocument?.id;
            const subTitle = document.typeDocument?.help as string | null;
            if (!id) {
                throw new Error(`Document ${label} is missing id`);
            }
            return generateArticleDocument(
                label ?? '',
                subTitle ?? '',
                originalArticle?.libelle ?? '',
                id,
            );
        });

        return formatedArticles;
    };

    const formatUtils = {
        formatArticleDocumentItems,
    };

    const onFormalityUpdate = (formalityId: string) => {
        request(formaliteApi.apiFormalitesIdGet({ id: formalityId }), {
            withToaster: false,
            successCallback: (res) => storeFormalitiesReadOnlyDocuments(res),
        });
    };

    return {
        readOnlyDocuments,
        actions,
        formatUtils,
        onFormalityUpdate,
    };
};
export default useReadOnlyDocuments;
