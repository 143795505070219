import ConfirmationModalContent from '@b2d/pages/Achats/components/modals/ConfirmationModalContent';
import { removeReminderMailModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import { AppDispatch } from '@b2d/redux/types';
import { getReminderMail, mailApi } from '@b2d/redux/RootStore';
import { ListActionButton, ModalContext } from '@europrocurement/flexy-components';
import { ReminderMail } from '@europrocurement/l2d-domain';
import { MailApiDeleteQueuedMailMailCollectionRequest } from '@europrocurement/l2d-domain/openApi/ApiMail';
import { deleteIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import { KeycloakHasRole } from '@europrocurement/l2d-keycloak';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useApiRequest } from '@europrocurement/l2d-hooks';

type DeleteReminderMailButtonProps = {
    reminderMailId: NonNullable<ReminderMail['id']>;
};

const DeleteReminderMailButton: React.FunctionComponent<DeleteReminderMailButtonProps> = function ({
    reminderMailId,
}: DeleteReminderMailButtonProps) {
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const { modalActions } = useContext(ModalContext);
    const { request } = useApiRequest();

    const onDeleteActionValidated = useCallback(
        (id: NonNullable<ReminderMail['id']>) => {
            if (!id) {
                enqueueSnackbar(
                    <Typography>Il n&apos;y a aucun mail de rappel sélectionné !</Typography>,
                    {
                        variant: 'error',
                    },
                );
                return;
            }

            const successCallback = () => {
                dispatch(getReminderMail({}));
            };

            const requestParameters: MailApiDeleteQueuedMailMailCollectionRequest = {
                id,
            };

            // Suppression logique
            request(mailApi.deleteQueuedMailMailCollection(requestParameters), {
                withToaster: true,
                successCallback,
            });
        },
        [dispatch, enqueueSnackbar, request],
    );

    const onDelete = React.useCallback(
        (id: NonNullable<ReminderMail['id']>) => {
            modalActions.call(
                <ConfirmationModalContent
                    messages={removeReminderMailModalMessages}
                    actionOnValidation={() => onDeleteActionValidated(id)}
                />,
            );
        },
        [modalActions, onDeleteActionValidated],
    );

    return (
        <KeycloakHasRole kcRole="realm:interne">
            <ListActionButton
                tooltip="Supprimer le mail"
                onClick={() => {
                    onDelete(reminderMailId);
                }}
                iconComponent={<FaOptionIcon {...deleteIcon.props} />}
            />
        </KeycloakHasRole>
    );
};

export default DeleteReminderMailButton;
