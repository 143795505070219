import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { FaOptionIcon, preview } from '@europrocurement/l2d-icons';

type EmptyCardPreviewProps = {
    loading?: boolean;
};

const EmptyCardPreview: React.FunctionComponent<EmptyCardPreviewProps> = function (props) {
    const { loading = false } = props;

    const fakeCardStyle = {
        padding: '40px',
        border: '3px dashed lightgray',
        borderRadius: '10px',
        height: '100%',
        opacity: '0.3',
        textAlign: 'center',
    };

    return (
        <Box
            sx={{
                ...fakeCardStyle,
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <FaOptionIcon
                        {...preview.props}
                        sx={{ fontSize: '30px', marginBottom: '20px', opacity: '0.6' }}
                    />

                    <Typography sx={{ marginTop: '30px' }}>
                        Sélectionnez un élément de la liste pour en obtenir l&apos;aperçu
                    </Typography>
                </>
            )}
        </Box>
    );
};

export default EmptyCardPreview;
