import { RootStateType } from '@b2d/redux/types';
import { Devis, DevisLigne } from '@europrocurement/l2d-domain';
import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import usePrevious from '@b2d/hooks/usePrevious';
import { useVerbose } from '@europrocurement/flexy-components';

/**
 * The purpose of this hook is to find the Most Valuable Quote in the given data from Redux.
 *
 * MVS Definition below:
 *
 * ???
 */
const useFinderMVQuote = () => {
    const quoteLinesDataSource: DataSource<DevisLigne> = useSelector(
        (s: RootStateType) => s.facturation.devisLigne.main,
        _.isEqual,
    );

    const quoteSelected: Devis | undefined = useSelector(
        (s: RootStateType) => s.facturation.devis.main.selected,
        _.isEqual,
    );

    const previousQuoteId = usePrevious(quoteLinesDataSource.data[0]?.devis?.id);

    const isMoreThanZeroQuoteLines = useMemo(
        () => quoteLinesDataSource.data.length > 0,
        [quoteLinesDataSource.data.length],
    );

    const quoteId = useMemo(
        () => (isMoreThanZeroQuoteLines ? quoteLinesDataSource.data[0]?.devis?.id : null),
        [isMoreThanZeroQuoteLines, quoteLinesDataSource.data],
    );

    const isQuoteExists = useMemo(() => quoteId !== null, [quoteId]);

    const isQuoteDifferentThanPreviousOne = useMemo(
        () => isQuoteExists && quoteId !== previousQuoteId,
        [isQuoteExists, previousQuoteId, quoteId],
    );

    const verbose = useVerbose();

    const isQuoteFound = useMemo<boolean>(() => {
        verbose.log(quoteSelected, isMoreThanZeroQuoteLines, isQuoteDifferentThanPreviousOne);
        return !!(quoteSelected && isMoreThanZeroQuoteLines && isQuoteDifferentThanPreviousOne);
    }, [isMoreThanZeroQuoteLines, isQuoteDifferentThanPreviousOne, quoteSelected, verbose]);

    return {
        isQuoteFound,
    };
};

export default useFinderMVQuote;
