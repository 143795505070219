/* eslint-disable @typescript-eslint/no-unused-vars */
import { FlexyInput, FlexySelectProps } from '@europrocurement/flexy-components';
import { SxProps } from '@mui/material';
import * as React from 'react';
import { ControllerRenderProps, FieldErrors, FieldValues } from 'react-hook-form';
import { Box } from '@mui/system';
import { getErrorMessage } from '../../forms/errors';
import {
    SelectOptions,
    GroupedSelectOptions,
    SelectInputOptions,
    SelectInputOption,
} from '../../forms/types';

type ComponentProps = {
    name: string;
    label?: string;
    options: SelectOptions;
    errors?: FieldErrors<FieldValues>;
    placeholder?: string;
    required?: boolean;
    sx?: SxProps;
} & Partial<ControllerRenderProps<FieldValues, string>> &
    Omit<FlexySelectProps, 'options'>;
const SelectField = React.forwardRef<HTMLElement, ComponentProps>(
    (
        {
            name,
            label,
            options,
            errors = {},
            isDisabled = false,
            required = false,
            ...field
        }: ComponentProps,
        ref,
    ) => {
        /** Helper function to infer option list type (Grouped / Classic) */
        const isGroupedOptions = (optionList: SelectOptions): optionList is GroupedSelectOptions =>
            optionList.every((option) => 'label' in option && 'options' in option);

        /** Get the option value in classic options list */
        const getValueInOptionList = (optionList: SelectInputOptions) =>
            optionList.find((option) => option.value === field.value);

        /** Get the option value in grouped options list */
        const getValueInGroupedOptionList = (optionList: GroupedSelectOptions) => {
            let value;

            optionList.forEach(({ options: groupedOptions }) => {
                const matchedOption = getValueInOptionList(groupedOptions);
                if (matchedOption) {
                    value = matchedOption;
                }
            });

            return value;
        };

        /** Get the option value in grouped or classic options list */
        const getValue = () => {
            if (isGroupedOptions(options)) {
                return getValueInGroupedOptionList(options);
            }
            return getValueInOptionList(options);
        };

        /** Override select value to pick only the value instead of whole option */
        const setValue = (option: SelectInputOption) => {
            if (typeof field.onChange === 'function') {
                field.onChange(option.value);
            }
        };

        const flatFieldError = name in errors;

        const errorFromPath = (path: (keyof FieldErrors<FieldValues>)[]) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let errorValue: any; //  undefined | Record<string, unknown> | FieldError;
            for (let i = 0, n = path.length; i < n; ++i) {
                if (i === 0) {
                    errorValue = errors[path[i]];
                } else if (errorValue) {
                    errorValue = errorValue[path[i]];
                }
            }

            return errorValue;
        };
        const nestedFieldError = (): boolean => {
            const splittedPath = name.split('.');
            return !!errorFromPath(splittedPath);
        };

        const hasError = flatFieldError || nestedFieldError();

        return (
            <Box sx={{ opacity: isDisabled ? 0.5 : 1 }}>
                <FlexyInput
                    name={name}
                    error={hasError}
                    type="select"
                    inputlabel={label}
                    options={options}
                    {...field}
                    // inputRef={field.ref}
                    value={getValue() ?? null}
                    onChange={setValue}
                    required={required}
                    isDisabled={isDisabled}
                    helperText={getErrorMessage(name, errors)}
                />
            </Box>
        );
    },
);

export default SelectField;
