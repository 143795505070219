import React, { useState } from 'react';

import { Box, Typography, Card, Select, MenuItem } from '@mui/material';

import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { TblTypeDocumentJsonldDocumentfileRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { mimetypeToIcone } from '../DisplayFile';

export type TypedFileValidationProps = {
    libelle: string;
    value?: number;
    docTypes: Array<TblTypeDocumentJsonldDocumentfileRead>;
    onChange: (id: number) => void;
};

const TypedFileValidation: React.FunctionComponent<TypedFileValidationProps> = function ({
    libelle,
    value,
    docTypes,
    onChange,
}) {
    // const { enqueueSnackbar } = useSnackbar();

    const [innerValue, setInnerValue] = useState(value || 66);

    return (
        <Card
            sx={{
                padding: '10px 15px 10px 15px',
                margin: '0px',
                borderRadius: '12px',
                boxShadow: '0px 2px 2.5px 0px rgba(193, 202, 215, 0.25)',
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                gap="10px"
            >
                <Box
                    data-testid="test-id-status-color-box"
                    sx={{
                        backgroundColor: value === 66 || !value ? 'warning.light' : 'success.light',
                        padding: '8px',
                        borderRadius: '10px',
                        fontSize: '14px',
                        width: 36,
                        height: 36,
                    }}
                >
                    <FaOptionIcon
                        data-testid="test-id-mimetype-icone"
                        icon={mimetypeToIcone('application/pdf')}
                        color={value === 66 || !value ? 'warning.main' : 'success.main'}
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        color="text.cyan"
                        variant="l2dcaption"
                    >
                        {libelle}
                    </Typography>
                    <Select
                        sx={{
                            minWidth: '248px',
                            height: '38px',
                        }}
                        value={innerValue}
                        onChange={(event) => {
                            if (event.target.value) {
                                onChange(event.target.value as number);
                                setInnerValue(event.target.value as number);
                            }
                        }}
                    >
                        {docTypes.map((item: TblTypeDocumentJsonldDocumentfileRead) => (
                            <MenuItem
                                key={item.id}
                                value={item.id}
                            >
                                {item.libelle}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Box>
        </Card>
    );
};

export default TypedFileValidation;
