import React from 'react';
import { Box, Typography } from '@mui/material';
import DocumentItem from './DocumentItem';
import { GenerateKeyFunction, ReadOnlyDocumentItem, ReadOnlyDocuments } from './types';

type ComponentProps = {
    documents: ReadOnlyDocuments;
};

const DocumentsList: React.FunctionComponent<ComponentProps> = function (props) {
    const { documents } = props;

    const { articles, options, formalities } = documents;

    const documentsToDisplay = () => {
        const valuesToDisplay = [articles, options, formalities];
        const result: Array<ReadOnlyDocumentItem> = [];
        valuesToDisplay.forEach((values) => {
            if (values && values.length > 0) {
                result.push(...values);
            }
        });

        return result;
    };
    const listLength = articles.length + options.length + formalities.length;
    const listContainItems = listLength > 0;
    const hasSingleItem = listLength === 1;
    const listHeaderLabel = hasSingleItem
        ? '    Document (automatiquement rattaché / non-éditable)'
        : '    Documents (automatiquement rattachés / non-éditables)';

    if (!listContainItems) {
        return null;
    }

    const keyGenerate: GenerateKeyFunction<ReadOnlyDocumentItem> = (document, index) =>
        `related_document-item-${document}-${index}`;

    const header = (
        <Typography sx={{ opacity: 0.6, marginTop: '18px' }}>
            {listContainItems ? `(${listLength})` : ''}
            {listHeaderLabel}
        </Typography>
    );

    const tableHeaderProps = {
        sx: { opacity: 0.6, margin: '18px 0  0 18px' },
        fontStyle: 'italic',
    };
    const tableHeader = (
        <Box sx={{ marginTop: '10px ', display: 'flex' }}>
            <Typography
                fontStyle={tableHeaderProps.fontStyle}
                sx={{ ...tableHeaderProps.sx, minWidth: '65%' }}
            >
                Nom / Description
            </Typography>
            <Typography {...tableHeaderProps}>Origine</Typography>
        </Box>
    );

    return (
        <>
            {header}
            <hr />
            {tableHeader}
            <Box sx={{ marginTop: '10px ' }}>
                {documentsToDisplay().map((document, index) => (
                    <DocumentItem
                        key={keyGenerate(document, index)}
                        item={document}
                    />
                ))}
            </Box>
        </>
    );
};

export default DocumentsList;
