import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { FormValidationRule } from './types';

export const validationPhoneNumber = (data: string, countryCode: CountryCode) => {
    console.log(data);
    if (data) {
        const phoneNumber = parsePhoneNumber(data, countryCode);
        console.log(phoneNumber?.isPossible());

        if (!phoneNumber || !phoneNumber.isPossible() || !phoneNumber.isValid()) {
            return false;
        }
    }

    return true;
};

export function validationPhoneNumberRule(countryCode: CountryCode): FormValidationRule {
    const errorMessage = `Ce numéro de téléphone n'est pas valide`;
    const validationRule = (value: string) =>
        validationPhoneNumber(value, countryCode) || errorMessage;
    return { phoneNumber: validationRule };
}
