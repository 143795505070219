import React, { useCallback, useEffect } from 'react';
import {
    DocIaModalTypage,
    DocIaProcessing,
    FileUpload,
    getBase64Statut,
    Previous,
    UploadedFile,
    useModal,
    useUploader,
} from '@europrocurement/flexy-components';
import { useFormaliteService } from '@europrocurement/l2d-domain';
import { Button, Collapse, Divider, List, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import { TransitionGroup } from 'react-transition-group';
import {
    FichiersDossierJsonldDocumentfileRead,
    DossierJsonldDossierRead,
    TypeDeDocumentJsonldTypedocumentRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { useSnackbar } from 'notistack';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { useParcoursFormalite } from '../provider/useParcoursFormalite';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import CurrentFormalityAndClient from '../components/currentFormalityAndClient';
import { loadDossier, uploadDossierFile, uploadDossierFiles } from '../functions/docFunctions';
import { useFileUploadProps } from '../hooks/useFileUploadProps';
import { DocToSend } from '../components/Docs/DocToSend';
import { useParcoursFormalitreHeader } from '../provider';

const StepDocFirst: React.FunctionComponent = function () {
    const { getCurrentDossier, containerWidth, setLoading } = useParcoursFormalite();

    React.useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    const { enqueueSnackbar } = useSnackbar();

    const { files, removeFile, filesBase64, clearFiles } = useUploader();
    const [uploadingFiles, setUploadingFiles] = React.useState<Array<number>>([]);

    const [currentDocs, setCurrentDocs] =
        React.useState<Array<FichiersDossierJsonldDocumentfileRead> | null>(null);

    const [syncrone] = React.useState(true);
    const [docTypes, setDocTypes] = React.useState<TypeDeDocumentJsonldTypedocumentRead[]>([]);

    const [iaDocLoading, setIaDocLoading] = React.useState(false);

    const dossier = getCurrentDossier();
    const navigate = useNavigate();
    const theme = useTheme();
    const { fichiersDossierApi, typeDocApi, documentsApi } = useFormaliteService();

    useEffect(() => {
        const getTypeDoc = async () => {
            const resTypeDoc = await typeDocApi.apiTypesDocumentsGetCollection({
                domaineId: 3,
                itemsPerPage: 1000,
                page: 1,
            });
            setDocTypes(resTypeDoc.data['hydra:member']);
        };
        getTypeDoc();
    }, [typeDocApi]);

    const reloadDossierAndDocs = useCallback(async () => {
        if (dossier) {
            const prom = loadDossier(dossier, fichiersDossierApi);
            return await prom.then((res) => {
                const fichiers = res.data['hydra:member'];
                setCurrentDocs(fichiers);
            });
        }
        return Promise.resolve();
    }, [dossier, fichiersDossierApi]);

    useEffect(() => {
        if (dossier && dossier.id && !currentDocs) {
            reloadDossierAndDocs();
        }
    }, [currentDocs, dossier, fichiersDossierApi, reloadDossierAndDocs]);

    const onPrevious = () => {
        navigate(parcoursFormalitePathResolver('stepClient'));
    };

    const { changeParcoursSteps, setIsParcoursPath } = useParcoursFormalitreHeader();

    const { setProfileHeaderVisible } = useFullLayout();
    React.useEffect(() => {
        changeParcoursSteps(1, 100);
        setProfileHeaderVisible(false);
        setIsParcoursPath(true);
        return () => {
            setIsParcoursPath(false);
            setProfileHeaderVisible(true);
        };
    }, [setProfileHeaderVisible, changeParcoursSteps, setIsParcoursPath]);

    const onDropFileWrapper = useCallback(
        (file: File, docType: string, idDoc: number) => {
            if (dossier) {
                setUploadingFiles((curr) => [...curr, idDoc]);
                uploadDossierFile(dossier, fichiersDossierApi, file, docType, true)
                    .then(reloadDossierAndDocs)
                    .then(() => {
                        setUploadingFiles((curr) => {
                            const index = curr.indexOf(idDoc);
                            if (index > -1) {
                                return [...curr.splice(index, 1)];
                            }
                            return [...curr];
                        });
                    });
            }
        },
        [dossier, fichiersDossierApi, reloadDossierAndDocs],
    );

    const { modalActions } = useModal();

    const fileUploadProps = useFileUploadProps();

    const updateFileType = useCallback(
        async (docId: number, newTypeId: number, newLibele: string) => {
            try {
                await documentsApi.apiTypesDocDossierIdPatch({
                    id: `${docId}`,
                    typeDeDocumentDesDossiersTypedocumentPatch: {
                        libelle: newLibele,
                        document: `/formalite/types-documents/${newTypeId}`,
                    },
                });
                enqueueSnackbar('Type de document mis a jours', { variant: 'success' });
            } catch (e) {
                console.error(e);
                enqueueSnackbar('Erreur lors de la mise a jours du type de document', {
                    variant: 'error',
                });
            }
        },
        [documentsApi, enqueueSnackbar],
    );

    const openModalTypage = useCallback(
        async (innerDoss: DossierJsonldDossierRead) => {
            const fichierRes = await loadDossier(innerDoss, fichiersDossierApi);
            const fichiers = fichierRes.data['hydra:member'];
            modalActions.call(
                <DocIaModalTypage
                    onSubmit={() => {
                        clearFiles();
                        navigate(parcoursFormalitePathResolver('stepForm'));
                        modalActions.close();
                    }}
                    onChange={(doc, newTypeId, libelle) => {
                        if (doc.typeDocDossier && doc.typeDocDossier.id) {
                            updateFileType(doc.typeDocDossier.id, newTypeId, libelle);
                        } else {
                            enqueueSnackbar('Erreur lors de la mise a jours du type de document', {
                                variant: 'error',
                            });
                        }
                    }}
                    docTypes={docTypes}
                    dossier={dossier || undefined}
                    docs={fichiers}
                />,
                false,
                false,
                () => {
                    clearFiles();
                    navigate(parcoursFormalitePathResolver('stepForm'));
                },
            );
        },
        [
            dossier,
            clearFiles,
            docTypes,
            enqueueSnackbar,
            fichiersDossierApi,
            modalActions,
            navigate,
            updateFileType,
        ],
    );

    const validateDoc = useCallback(async () => {
        if (dossier && files.length > 0) {
            if (syncrone) {
                setIaDocLoading(true);
                await uploadDossierFiles(dossier, fichiersDossierApi, files, true, documentsApi);
                openModalTypage(dossier);
            } else {
                setLoading(true);
                uploadDossierFiles(dossier, fichiersDossierApi, files, true, documentsApi);
                clearFiles();
                navigate(parcoursFormalitePathResolver('stepForm'));
            }
        } else {
            clearFiles();
            navigate(parcoursFormalitePathResolver('stepForm'));
        }
    }, [
        clearFiles,
        documentsApi,
        dossier,
        fichiersDossierApi,
        files,
        navigate,
        openModalTypage,
        setLoading,
        syncrone,
    ]);

    return iaDocLoading ? (
        <DocIaProcessing />
    ) : (
        <Container maxWidth={containerWidth}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                }}
            >
                <CurrentFormalityAndClient />
            </Box>
            <Previous onClick={onPrevious} />
            <Typography variant="l2dh4">Accélérez la saisie des informations</Typography>
            <Typography
                sx={{ fontWeight: 500 }}
                variant="l2dbodyxs"
                color="text.secondary"
            >
                Formats : {fileUploadProps.formatInfo} - Poids maximum : 10Mo
            </Typography>
            <Box sx={{ paddingTop: '40px' }}>
                <FileUpload {...fileUploadProps} />
            </Box>

            <List>
                <TransitionGroup>
                    {files.map((file: File, index) => (
                        <Collapse
                            timeout={300}
                            key={`fichier-${file.name}-${file.size}-${file.lastModified}`}
                        >
                            <UploadedFile
                                mode="inline"
                                key={`${file.name}-${file.size}-${file.lastModified}`}
                                file={file}
                                status={getBase64Statut(filesBase64[index])}
                                onDelete={() => {
                                    removeFile(index);
                                }}
                            />
                            {index < files.length - 1 ? <Divider /> : null}
                        </Collapse>
                    ))}
                </TransitionGroup>
            </List>

            {dossier ? (
                <DocToSend
                    currentDocs={currentDocs}
                    dossier={dossier}
                    onDropFileWrapper={onDropFileWrapper}
                    uploadingFiles={uploadingFiles}
                />
            ) : null}

            <Button
                type="submit"
                className="submit-btn"
                variant="contained"
                color="primary"
                data-test-id="submit-create-group"
                onClick={validateDoc}
                sx={{
                    backgroundColor: theme.palette.primary.dark,
                    width: '100%',
                    marginTop: '30px',
                    padding: '15px',
                    fontSize: '1rem',
                    borderRadius: '15px',
                }}
            >
                Valider et remplir automatiquement
            </Button>
        </Container>
    );
};

export default StepDocFirst;
