import * as React from 'react';

import { Box, FormGroup, Typography } from '@mui/material';

import { ChoiceProps } from '../types';
import ToggleShape from './ToggleShape';
import SelectShape from './SelectShape';

type ContentProps = {
    context: string;
    choices: Array<ChoiceProps>;
    formState: Record<string, boolean>;
    setFormState: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    hint?: string;
    uniqChoice?: boolean;
    inputNature: 'select' | 'toggle';
    handleCheckboxChange: (choice: ChoiceProps) => void;
};

const Content: React.FunctionComponent<ContentProps> = function ({
    context,
    choices,
    formState,
    setFormState,
    hint,
    uniqChoice = false,
    inputNature,
    handleCheckboxChange,
}) {
    return (
        <Box
            id="form_modal_content"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '46px',
                alignSelf: 'stretch',
            }}
        >
            <Typography variant="body1">{context}</Typography>
            <FormGroup
                id="form_modal_form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                }}
            >
                {hint && <Typography variant="body1">{hint}</Typography>}
                {inputNature === 'toggle' ? (
                    <ToggleShape
                        choices={choices}
                        formState={formState}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                ) : (
                    <SelectShape
                        choices={choices}
                        formState={formState}
                        setFormState={setFormState}
                        uniqChoice={uniqChoice}
                    />
                )}
            </FormGroup>
        </Box>
    );
};

export default Content;
