import { SxProps, Typography } from '@mui/material';
import React, { ReactNode, useMemo } from 'react';
import EllipsisLoader from './EllipsisLoader';

type CentralDataCardStatProps = {
    data?: string;
    paragraphDetail?: string;
    sx?: SxProps;
};

const CentralDataCardStat: React.FunctionComponent<CentralDataCardStatProps> = function ({
    data,
    paragraphDetail,
    sx,
}: CentralDataCardStatProps) {
    const paragraphComponent = useMemo<ReactNode>(
        () => (
            <Typography
                sx={{
                    fontSize: '18px',
                    textAlign: 'center',
                }}
            >
                {paragraphDetail}
            </Typography>
        ),
        [paragraphDetail],
    );

    const dataComponent = useMemo<ReactNode>(
        () => (
            <Typography
                component="h2"
                sx={{
                    fontSize: '32px',
                    textAlign: 'center',
                    ...sx,
                }}
            >
                <b>{data || <EllipsisLoader sx={{ ...sx }} />}</b>
            </Typography>
        ),
        [data, sx],
    );

    return (
        <>
            {paragraphComponent}
            {dataComponent}
        </>
    );
};

export default CentralDataCardStat;
