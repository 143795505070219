import React, { useCallback, useEffect, useReducer, useState, useMemo } from 'react';

import _ from 'lodash';
import type { Reducer } from '@reduxjs/toolkit';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import Sell from '@mui/icons-material/Sell';
import Folder from '@mui/icons-material/Folder';
import Receipt from '@mui/icons-material/Receipt';
import Category from '@mui/icons-material/Category';
import Newspaper from '@mui/icons-material/Newspaper';
import ShortText from '@mui/icons-material/ShortText';
import Difference from '@mui/icons-material/Difference';
import EuroSymbol from '@mui/icons-material/EuroSymbol';
import AccountBalance from '@mui/icons-material/AccountBalance';
import ProductionQuantityLimits from '@mui/icons-material/ProductionQuantityLimits';

import {
    CategorieStats,
    CodeRejetStats,
    Dossier,
    FactureAchat,
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
    Fournisseur,
    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID,
    Journal,
    BDD_SWITCH_SOCIETES_IDS_TYPE,
    FournisseurSelector,
} from '@europrocurement/l2d-domain';
import {
    FlexyInput,
    FlexyTabs,
    FlexyFormLabel,
    FlexyDateRange,
    FlexyDateRangeProps,
} from '@europrocurement/flexy-components';
import { AutocompleteStore } from '@europrocurement/flexy-form';
import { DataSource } from '@europrocurement/l2d-redux-utils';

import { DateRange } from '@europrocurement/flexy-components/components/molecules/DateRangePicker';
import { toISOString } from '@europrocurement/l2d-utils';
import { RootStateType, AppDispatch } from '@b2d/redux/types';
import {
    CategorieStatsSelector,
    CodeRejetStatsSelector,
    FactureachatSelector,
    getCategorieStats,
    getCodeRejetStats,
    getFactureAchatStats,
    fournisseurDataSourcesThunks,
    dossierDataSourcesThunks,
    journalDataSourcesThunks,
} from '@b2d/redux/RootStore';

import FactureAchatList from '@b2d/pages/Achats/components/lists/FactureAchatList';
import CategoriesPanel from '@b2d/pages/Achats/components/CategoriesPanel';
import CodesRejetPanel from '@b2d/pages/Achats/components/CodeRejetsPanel';
import RienAAfficher from '@b2d/pages/Achats/components/RienAAfficher';
import useLoadingStatus from '@b2d/hooks/useLoading';
import { SupportAgent } from '@mui/icons-material';
import SkeletonLoader from './SkeletonLoader';

type statsFiltersReducerType = {
    afficherZeros: boolean;
    dateFacture: string[] | null[];
    createdAt: string[] | null[];
};

const initState: statsFiltersReducerType = {
    afficherZeros: false,
    dateFacture: [null, null],
    createdAt: [null, null],
};

/**
 * Actions à appeler lorsque l'on souhaite dispatch une action sur le
 * reducer des filtres
 */
const actions = {
    RESET: 'statsFilters.reset',
    SWITCH_AFFICHER_ZEROS: 'statsFilters.switchAfficherZeros',
    CHANGE_DATE_FACTURE: 'statsFilters.changeDateFacture',
    CHANGE_CREATED_AT: 'statsFilters.changeCreatedAt',
};

// eslint-disable-next-line default-param-last
const statesFiltersReducer: Reducer<statsFiltersReducerType> = (state = initState, action) => {
    let newState: statsFiltersReducerType = { ...state };

    switch (action.type) {
        case actions.RESET:
            newState = { ...initState };
            break;
        case actions.SWITCH_AFFICHER_ZEROS:
            newState = {
                ...state,
                afficherZeros: !state.afficherZeros,
            };
            break;
        case actions.CHANGE_DATE_FACTURE:
            newState = {
                ...state,
                dateFacture: [action.payload.before, action.payload.after],
            };
            break;
        case actions.CHANGE_CREATED_AT:
            newState = {
                ...state,
                createdAt: [action.payload.before, action.payload.after],
            };
            break;
        default:
            newState = { ...state };
            break;
    }

    return newState;
};

const StatsTabs: React.FunctionComponent = function () {
    const categoriesDataSource: DataSource<CategorieStats> = useSelector(
        CategorieStatsSelector,
        shallowEqual,
    ).main;

    const codesRejetsDataSource: DataSource<CodeRejetStats> = useSelector(
        CodeRejetStatsSelector,
        shallowEqual,
    ).main;

    const facturesAchatsDataSource: DataSource<FactureAchat> =
        useSelector(FactureachatSelector)[FACTURES_ACHATS_STATS_DATASOURCE_NAME];

    const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
        FournisseurSelector,
        shallowEqual,
    ).main;

    const dossiersDataSource: DataSource<Dossier> = useSelector(
        (s: RootStateType) => s.dossiers.dos.main,
        shallowEqual,
    );

    const journalsDataSource: DataSource<Journal> = useSelector(
        (s: RootStateType) => s.dossiers.jal.main,
        shallowEqual,
    );

    const listCategories = categoriesDataSource.data;
    const listCodesRejets = codesRejetsDataSource.data;

    // TODO: Vérifier ce qui est encore utile et nettoyer
    const catProps: { [key: string]: JSX.Element } = {
        ERDN: <AccountBalance fontSize="large" />,
        ERCA: <Category fontSize="large" />,
        ERDO: <Folder fontSize="large" />,
        EREN: <ShortText fontSize="large" />,
        ERJA: <Newspaper fontSize="large" />,
        ERJU: <Receipt fontSize="large" />,
        ERLO: <Receipt fontSize="large" />,
        ERPF: <EuroSymbol fontSize="large" />,
        ERPU: <EuroSymbol fontSize="large" />,
        ERRC: <ProductionQuantityLimits fontSize="large" />,
        ERRE: <Sell fontSize="large" />,
        ERSO: <AccountBalance fontSize="large" />,
        ERNF: <Difference fontSize="large" />,
        VE: <SupportAgent fontSize="large" />,
    };

    const codProps: { [key: string]: JSX.Element } = useMemo(
        () => ({
            ERCA1: <Category fontSize="large" />,
            ERCA2: <Category fontSize="large" />,
            ERCA3: <Category fontSize="large" />,
            ERCA4: <Category fontSize="large" />,
            ERCA5: <Category fontSize="large" />,
            ERCA6: <Category fontSize="large" />,

            ERDN1: <AccountBalance fontSize="large" />,
            ERDN2: <AccountBalance fontSize="large" />,

            ERDO1: <Folder fontSize="large" />,
            ERDO2: <Folder fontSize="large" />,
            ERDO3: <Folder fontSize="large" />,
            ERDO4: <Folder fontSize="large" />,
            ERDO5: <Folder fontSize="large" />,
            ERDO6: <Folder fontSize="large" />,

            EREN1: <ShortText fontSize="large" />,
            EREN10: <ShortText fontSize="large" />,
            EREN2: <ShortText fontSize="large" />,
            EREN3: <ShortText fontSize="large" />,
            EREN4: <ShortText fontSize="large" />,
            EREN5: <ShortText fontSize="large" />,
            EREN6: <ShortText fontSize="large" />,
            EREN7: <ShortText fontSize="large" />,
            EREN8: <ShortText fontSize="large" />,
            EREN9: <ShortText fontSize="large" />,

            ERJA1: <Newspaper fontSize="large" />,
            ERJA2: <Newspaper fontSize="large" />,

            ERJU1: <Receipt fontSize="large" />,
            ERJU2: <Receipt fontSize="large" />,

            ERLO1: <Receipt fontSize="large" />,
            ERLO2: <Receipt fontSize="large" />,
            ERLO3: <Receipt fontSize="large" />,

            ERPF1: <EuroSymbol fontSize="large" />,
            ERPF2: <EuroSymbol fontSize="large" />,

            ERNF1: <Difference fontSize="large" />,
            ERNF2: <Difference fontSize="large" />,

            ERPU1: <EuroSymbol fontSize="large" />,
            ERPU2: <EuroSymbol fontSize="large" />,
            ERPU3: <EuroSymbol fontSize="large" />,
            ERPU4: <EuroSymbol fontSize="large" />,
            ERPU5: <EuroSymbol fontSize="large" />,

            ERRC1: <ProductionQuantityLimits fontSize="large" />,
            ERRC2: <ProductionQuantityLimits fontSize="large" />,

            ERRE1: <Sell fontSize="large" />,
            ERRE2: <Sell fontSize="large" />,
            ERRE3: <Sell fontSize="large" />,
            ERRE4: <Sell fontSize="large" />,
            ERRE5: <Sell fontSize="large" />,
            ERRE6: <Sell fontSize="large" />,

            ERSO1: <AccountBalance fontSize="large" />,
            ERSO2: <AccountBalance fontSize="large" />,
            ERSO3: <AccountBalance fontSize="large" />,
            ERSO4: <AccountBalance fontSize="large" />,
            ERSO5: <AccountBalance fontSize="large" />,

            VEAA: <SupportAgent fontSize="large" />,
            VEADJ: <SupportAgent fontSize="large" />,
            VEMP: <SupportAgent fontSize="large" />,
        }),
        [],
    );

    const dispatch = useDispatch<AppDispatch>();
    // Le reducer ne contient que les states des filtres
    const [statsFiltersState, statsFiltersDispatch] = useReducer(statesFiltersReducer, initState);
    const [categoriesProps] = useState<string[]>(Object.keys(catProps));
    const [codesProps, setCodesProps] = useState<string[]>(Object.keys(codProps));
    const [dateFactureReset, setDateFactureReset] = useState<boolean>(false);
    const [createdAtReset, setCreatedAtReset] = useState<boolean>(false);
    const [fournisseurReset, setFournisseurReset] = useState<boolean>(false);
    const [dossierReset, setDossierReset] = useState<boolean>(false);
    const [journalReset, setJournalReset] = useState<boolean>(false);

    let categoriesCount: number = 0;
    let codesRejetsCount: number = 0;
    const facturesEnRejetCount: number | undefined = facturesAchatsDataSource.pagination.total;

    /**
     * Filtre 'Afficher les éléments vides'
     *
     * Modifie les compteurs pour chaque DataSource en prenant en compte ou
     * non les éléments vides
     */
    if (!statsFiltersState.afficherZeros) {
        listCategories.forEach((categorie) => {
            categoriesCount = categorie.count !== 0 ? categoriesCount + 1 : categoriesCount;
        });

        listCodesRejets.forEach((codeRejet) => {
            codesRejetsCount = codeRejet.count !== 0 ? codesRejetsCount + 1 : codesRejetsCount;
        });
    } else {
        categoriesCount = listCategories.length;
        codesRejetsCount = listCodesRejets.length;
    }

    // Options de l'input select des Categories
    const categoriesOptions: Array<{
        value: string;
        label: string;
    }> = categoriesProps.map((value) => ({ value, label: value }));

    // Options de l'input select des Codes rejets
    const codesRejetsOptions: Array<{
        value: string;
        label: string;
    }> = codesProps.map((value) => ({ value, label: value }));

    const societesOptions: Array<{
        value: string;
        label: string;
    }> = Object.entries(BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID).map(([value, label]) => ({
        value,
        label,
    }));

    // Reset state est temporairement activé
    const onResetActive = useCallback(
        (
            field:
                | 'dateFacture'
                | 'createdAt'
                | 'idEntiteFacturante'
                | 'idDossier'
                | 'idJal'
                | 'all',
        ) => {
            switch (field) {
                case 'all':
                    if (
                        !dateFactureReset &&
                        !createdAtReset &&
                        !fournisseurReset &&
                        !dossierReset &&
                        !journalReset
                    ) {
                        setDateFactureReset(true);
                        setCreatedAtReset(true);
                        setFournisseurReset(true);
                        setDossierReset(true);
                        setJournalReset(true);
                        setTimeout(() => {
                            setDateFactureReset(false);
                            setCreatedAtReset(false);
                            setFournisseurReset(false);
                            setDossierReset(false);
                            setJournalReset(false);
                        }, 1000);
                    }
                    break;
                case 'dateFacture':
                    if (!dateFactureReset) {
                        setDateFactureReset(true);
                        setTimeout(() => {
                            setDateFactureReset(false);
                        }, 1000);
                    }
                    break;
                case 'createdAt':
                    if (!createdAtReset) {
                        setCreatedAtReset(true);
                        setTimeout(() => {
                            setCreatedAtReset(false);
                        }, 1000);
                    }
                    break;
                case 'idEntiteFacturante':
                    if (!fournisseurReset) {
                        setFournisseurReset(true);
                        setTimeout(() => {
                            setFournisseurReset(false);
                        }, 1000);
                    }
                    break;
                case 'idDossier':
                    if (!dossierReset) {
                        setDossierReset(true);
                        setTimeout(() => {
                            setDossierReset(false);
                        }, 1000);
                    }
                    break;
                case 'idJal':
                    if (!journalReset) {
                        setJournalReset(true);
                        setTimeout(() => {
                            setJournalReset(false);
                        }, 1000);
                    }
                    break;
                default:
                    break;
            }
        },
        [createdAtReset, dateFactureReset, fournisseurReset, dossierReset, journalReset],
    );

    // Met à jour les filtres de date
    const onUpdateDateRange = useCallback(
        (filter: string, range: DateRange) => {
            if (!range.endDate || !range.startDate) return;

            const before = toISOString(range.endDate, false);
            const after = toISOString(range.startDate, true);

            let actionTypeName;

            switch (filter) {
                case 'dateFacture':
                    actionTypeName = actions.CHANGE_DATE_FACTURE;
                    break;
                case 'createdAt':
                    actionTypeName = actions.CHANGE_CREATED_AT;
                    break;
                default:
                    break;
            }

            statsFiltersDispatch({
                type: actionTypeName,
                payload: {
                    before,
                    after,
                },
            });
            dispatch({
                type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                payload: { key: filter, value: [before, after] },
            });

            dispatch(getCategorieStats({}));
        },
        [categoriesDataSource.name, categoriesDataSource.slicename, dispatch],
    );

    // Actions de modification ou de suppression sur les trois DataSources
    const dispatchTabs = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (action: 'delete' | 'set', payload: any) => {
            dispatch({
                type: `${categoriesDataSource.slicename}/${action}${categoriesDataSource.name}Filter`,
                payload,
            });
            dispatch({
                type: `${codesRejetsDataSource.slicename}/${action}${codesRejetsDataSource.name}Filter`,
                payload,
            });
            dispatch({
                type: `${facturesAchatsDataSource.slicename}/${action}${facturesAchatsDataSource.name}Filter`,
                payload,
            });
            dispatch(getCategorieStats({}));
            dispatch(getCodeRejetStats({}));
            dispatch(getFactureAchatStats({}));
        },
        [
            categoriesDataSource.name,
            categoriesDataSource.slicename,
            codesRejetsDataSource.name,
            codesRejetsDataSource.slicename,
            dispatch,
            facturesAchatsDataSource.name,
            facturesAchatsDataSource.slicename,
        ],
    );

    // Comportement lorsque l'on souhaite modifier un filtre
    const onFilterChange = useDebouncedCallback(
        (field: string, value: number | string | Array<{ value: string }>) => {
            dispatchTabs('set', {
                key: field,
                value: Array.isArray(value)
                    ? // eslint-disable-next-line @typescript-eslint/no-shadow
                      value.map(({ value }: { value: string }) => value)
                    : value,
            });
        },
        500,
    );

    // Comportement lorsque l'on souhaite supprimer un filtre
    const onFilterDelete = useCallback(
        (field: string) => {
            dispatchTabs('delete', { field });

            switch (field) {
                case 'idEntiteFacturante':
                    onResetActive(field as 'idEntiteFacturante');
                    break;
                case 'idDossier':
                    onResetActive(field as 'idDossier');
                    break;
                case 'idJal':
                    onResetActive(field as 'idJal');
                    break;
                case 'dateFacture':
                    onResetActive(field as 'dateFacture');
                    statsFiltersDispatch({
                        type: actions.CHANGE_DATE_FACTURE,
                        payload: {
                            before: null,
                            after: null,
                        },
                    });
                    break;
                case 'createdAt':
                    onResetActive(field as 'createdAt');
                    statsFiltersDispatch({
                        type: actions.CHANGE_CREATED_AT,
                        payload: {
                            before: null,
                            after: null,
                        },
                    });
                    break;
                default:
                    break;
            }
        },
        [dispatchTabs, onResetActive],
    );

    // TODO: Est-ce qu'il faut le garder ou pas ?
    // Sinon pourquoi ne pas remplacer xIdSociete par facturesAchatsDataSource.filters.idSociete ?
    // L'idée c'est de trouver à quelle valeur de idSociete on doit se fier pour synchroniser les filtres de chaque tab de stats.
    // Sachant qu'on ne se base plus sur l'xIdSociete mais sur un filtre dissocier : idSociete.
    // // Met à jour le filtre idSociete
    useEffect(() => {
        // Filtre idEntiteFacturante des DataSources des tabs
        const catIdSocieteFilter = categoriesDataSource.filters.idSociete as
            | BDD_SWITCH_SOCIETES_IDS_TYPE
            | undefined;
        const codIdSocieteFilter = codesRejetsDataSource.filters.idSociete as
            | BDD_SWITCH_SOCIETES_IDS_TYPE
            | undefined;
        const facIdSocieteFilter = facturesAchatsDataSource.filters.idSociete as
            | BDD_SWITCH_SOCIETES_IDS_TYPE
            | undefined;

        if (catIdSocieteFilter) {
            if (
                codIdSocieteFilter !== catIdSocieteFilter ||
                facIdSocieteFilter !== catIdSocieteFilter
            ) {
                onFilterChange('idSociete', catIdSocieteFilter);
            }
        }
    }, [
        categoriesDataSource.filters.idSociete,
        codesRejetsDataSource.filters.idSociete,
        facturesAchatsDataSource.filters,
        onFilterChange,
        onFilterDelete,
    ]);

    // Met à jour le filtre idEntiteFacturante
    useEffect(() => {
        // Libelle du fournisseur sélectionné
        const idSelectedFournisseur = fournisseursDataSource?.selected?.id;
        // Filtre idEntiteFacturante des DataSources des tabs
        const catLibFactFilter = categoriesDataSource.filters.idEntiteFacturante;
        const codLibFactFilter = codesRejetsDataSource.filters.idEntiteFacturante;
        const facLibFactFilter = facturesAchatsDataSource.filters.idEntiteFacturante;

        if (idSelectedFournisseur) {
            if (
                catLibFactFilter !== idSelectedFournisseur ||
                codLibFactFilter !== idSelectedFournisseur ||
                facLibFactFilter !== idSelectedFournisseur
            ) {
                onFilterChange('idEntiteFacturante', idSelectedFournisseur);
            }
        } else if (catLibFactFilter || codLibFactFilter || facLibFactFilter) {
            /**
             * Si une des DataSources a le filtre,
             * alors qu'il n'y a pas de fournisseur sélectionné,
             * alors le filtre est supprimé.
             */
            onFilterDelete('idEntiteFacturante');
        }
    }, [
        categoriesDataSource.filters.idEntiteFacturante,
        codesRejetsDataSource.filters.idEntiteFacturante,
        facturesAchatsDataSource.filters.idEntiteFacturante,
        fournisseursDataSource.selected,
        onFilterChange,
        onFilterDelete,
    ]);

    // Met à jour le filtre idDossier
    useEffect(() => {
        // Id du dossier sélectionné
        const idSelectedDossier = dossiersDataSource?.selected?.id;
        // Filtre idDossier des DataSources des tabs
        const catDossierFilter = categoriesDataSource.filters.idDossier;
        const codDossierFilter = codesRejetsDataSource.filters.idDossier;
        const facDossierFilter = facturesAchatsDataSource.filters.idDossier;

        if (idSelectedDossier) {
            if (
                catDossierFilter !== idSelectedDossier ||
                codDossierFilter !== idSelectedDossier ||
                facDossierFilter !== idSelectedDossier
            ) {
                onFilterChange('idDossier', idSelectedDossier);
            }
        } else if (catDossierFilter || codDossierFilter || facDossierFilter) {
            /**
             * Si une des DataSources a le filtre,
             * alors qu'il n'y a pas de dossier sélectionné,
             * alors le filtre est supprimé.
             */
            onFilterDelete('idDossier');
        }
    }, [
        categoriesDataSource.filters.idDossier,
        codesRejetsDataSource.filters.idDossier,
        facturesAchatsDataSource.filters.idDossier,
        dossiersDataSource.selected,
        onFilterChange,
        onFilterDelete,
    ]);

    // Met à jour le filtre idJal
    useEffect(() => {
        // Id du journal sélectionné
        const idSelectedJal = journalsDataSource?.selected?.id;
        // Filtre idJal des DataSources des tabs
        const catJalFilter = categoriesDataSource.filters.idJal;
        const codJalFilter = codesRejetsDataSource.filters.idJal;
        const facJalFilter = facturesAchatsDataSource.filters.idJal;

        if (idSelectedJal) {
            if (
                catJalFilter !== idSelectedJal ||
                codJalFilter !== idSelectedJal ||
                facJalFilter !== idSelectedJal
            ) {
                onFilterChange('idJal', idSelectedJal);
            }
        } else if (catJalFilter || codJalFilter || facJalFilter) {
            /**
             * Si une des DataSources a le filtre,
             * alors qu'il n'y a pas de journal sélectionné,
             * alors le filtre est supprimé.
             */
            onFilterDelete('idJal');
        }
    }, [
        categoriesDataSource.filters.idJal,
        codesRejetsDataSource.filters.idJal,
        facturesAchatsDataSource.filters.idJal,
        journalsDataSource.selected,
        onFilterChange,
        onFilterDelete,
    ]);

    /**
     * Synchroniser les filtres de la DataSource de categoriesstats vers codesrejetsstats et factureachat
     *
     * !! ATENTION !! L'ajout de codesRejets et codesRejets.filters dans les dépendances fait boucler
     *
     */
    useEffect(() => {
        if (!_.isEqual(categoriesDataSource.filters, codesRejetsDataSource.filters)) {
            const payloadValue: (filter: string) => string | string[] = (filter: string) => {
                let res: string | string[];

                // Prise en compte des cas particuliers de filtres multiples
                // (categories et codes rejets)
                if (Array.isArray(categoriesDataSource.filters[filter])) {
                    res = ((categoriesDataSource.filters[filter] || []) as Array<string>).map(
                        (value: string) => value,
                    );
                } else {
                    res = categoriesDataSource.filters[filter] as string;
                }

                return res;
            };

            // Pour chaque filtres de categoriesDataSource, les appliquer
            // aux autres DataSources
            Object.keys(categoriesDataSource.filters).forEach((filter) => {
                dispatch({
                    type: `${codesRejetsDataSource.slicename}/set${codesRejetsDataSource.name}Filter`,
                    payload: { key: filter, value: payloadValue(filter) },
                });
                dispatch({
                    type: `${facturesAchatsDataSource.slicename}/set${facturesAchatsDataSource.name}Filter`,
                    payload: { key: filter, value: payloadValue(filter) },
                });
            });

            // Actualiser les tabs 'Par codes rejets' et 'Détails'
            dispatch(getCodeRejetStats({}));
            dispatch(getFactureAchatStats({}));
        }
    }, [
        categoriesDataSource.filters,
        codesRejetsDataSource.filters,
        codesRejetsDataSource.name,
        codesRejetsDataSource.slicename,
        facturesAchatsDataSource.name,
        facturesAchatsDataSource.slicename,
        dispatch,
    ]);

    // Afficher uniquement les codes rejets des categories sélectionnées
    useEffect(() => {
        if (categoriesDataSource.filters.categories) {
            setCodesProps(
                Object.keys(codProps).filter((code: string) =>
                    ((categoriesDataSource.filters.categories || []) as Array<string>).some(
                        (categorie: string) => code.startsWith(categorie),
                    ),
                ),
            );
        } else {
            setCodesProps(Object.keys(codProps));
        }
    }, [categoriesDataSource.filters, codProps]);

    // À cause de problèmes de typages,
    // l'assignation des paramètres de FlexyDateRange
    // doit se faire dans un objet en amont.
    const dateFactureRangeProps: FlexyDateRangeProps = {
        type: 'text',
        size: 'small',
        variant: 'outlined',
        placeholder: 'Sélectionnez une plage de dates...',
        inputlabel: 'Par date de facturation',
        name: 'dashboard-datefacture-filter',
        key: 'dashboard-datefacture-filter',
        reset: dateFactureReset,
        onChange: (dateRange: DateRange) => onUpdateDateRange('dateFacture', dateRange),
    } as FlexyDateRangeProps;

    const createdAtRangeProps: FlexyDateRangeProps = {
        type: 'text',
        size: 'small',
        variant: 'outlined',
        placeholder: 'Sélectionnez une plage de dates...',
        inputlabel: 'Par date de création',
        name: 'dashboard-createdat-filter',
        key: 'dashboard-createdat-filter',
        reset: createdAtReset,
        onChange: (dateRange: DateRange) => onUpdateDateRange('createdAt', dateRange),
    } as FlexyDateRangeProps;

    const loadDataCategories = useCallback(
        () => !!(categoriesDataSource.status !== 'loading'),
        [categoriesDataSource.status],
    );

    const { loading: loadingCategories } = useLoadingStatus({ checkReady: loadDataCategories });

    const loadDataRejectCodes = useCallback(
        () => !!(codesRejetsDataSource.status !== 'loading'),
        [codesRejetsDataSource.status],
    );

    const { loading: loadingRejectCodes } = useLoadingStatus({ checkReady: loadDataRejectCodes });

    return (
        <>
            <Card
                sx={{
                    overflow: 'visible',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                    >
                        Filtres
                    </Typography>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                        >
                            <FlexyInput
                                isMulti
                                type="select"
                                inputlabel="Categories"
                                name="dashboard-categories-filter"
                                key="dashboard-categories-filter"
                                value={(
                                    (categoriesDataSource.filters.categories || []) as Array<string>
                                ).map((code: string) =>
                                    categoriesOptions.find(
                                        ({ value }: { value: string }) => value === code,
                                    ),
                                )}
                                options={categoriesOptions}
                                onChange={(values: Array<{ value: string }>) => {
                                    if (values.length === 0) {
                                        onFilterDelete('categories');
                                    } else {
                                        dispatch({
                                            type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                            payload: {
                                                key: 'categories',
                                                value: values.map(
                                                    ({ value }: { value: string }) => value,
                                                ),
                                            },
                                        });
                                        dispatch(getCategorieStats({}));
                                    }
                                }}
                                sx={{
                                    zIndex: 10,
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                        >
                            <FlexyInput
                                isMulti
                                type="select"
                                inputlabel="Codes rejets"
                                name="dashboard-codesrejets-filter"
                                key="dashboard-codesrejets-filter"
                                value={(
                                    (categoriesDataSource.filters.codesRejets ||
                                        []) as Array<string>
                                ).map((code: string) =>
                                    codesRejetsOptions.find(
                                        ({ value }: { value: string }) => value === code,
                                    ),
                                )}
                                options={codesRejetsOptions}
                                onChange={(values: Array<{ value: string }>) => {
                                    if (values.length === 0) {
                                        onFilterDelete('codesRejets');
                                    } else {
                                        dispatch({
                                            type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                            payload: {
                                                key: 'codesRejets',
                                                value: values.map(
                                                    ({ value }: { value: string }) => value,
                                                ),
                                            },
                                        });
                                        dispatch(getCategorieStats({}));
                                    }
                                }}
                                sx={{
                                    zIndex: 10,
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                        >
                            <AutocompleteStore
                                key={fournisseursDataSource.name}
                                dataSource={fournisseursDataSource}
                                fetchData={fournisseurDataSourcesThunks.main.getData}
                                renderField="libelle"
                                idProperty="id"
                                inputlabel="Fournisseur"
                                inputProps={{
                                    variant: 'outlined',
                                    size: 'small',
                                }}
                                getOptionLabel={(item: Fournisseur) =>
                                    `${item.libelle} - ${item.siren}` || ''
                                }
                                simpleRenderOption={(item: Fournisseur) =>
                                    `${item.siren} (${item.libelle})`
                                }
                                reset={fournisseurReset}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                        >
                            <AutocompleteStore
                                key={dossiersDataSource.name}
                                dataSource={dossiersDataSource}
                                fetchData={dossierDataSourcesThunks.main.getData}
                                renderField="numero"
                                idProperty="id"
                                inputlabel="Dossier"
                                inputProps={{
                                    variant: 'outlined',
                                    size: 'small',
                                }}
                                getOptionLabel={(item: Dossier) => `${item.numero}` || ''}
                                simpleRenderOption={(item: Dossier) =>
                                    `${item.numero} - ${
                                        item.prestations && item.prestations[0]?.jal?.nom
                                    } - ${item.annonceur}`
                                }
                                reset={dossierReset}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                        >
                            <AutocompleteStore
                                key={journalsDataSource.name}
                                dataSource={journalsDataSource}
                                fetchData={journalDataSourcesThunks.main.getData}
                                renderField="nom"
                                idProperty="id"
                                inputlabel="Journal"
                                inputProps={{
                                    variant: 'outlined',
                                    size: 'small',
                                }}
                                getOptionLabel={(item: Journal) => `${item.nom}` || ''}
                                simpleRenderOption={(item: Journal) => `${item.id} - ${item.nom}`}
                                reset={journalReset}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                        >
                            <FlexyInput
                                type="select"
                                inputlabel="Société"
                                name="dashboard-id-societe-filter"
                                key="dashboard-id-societe-filter"
                                value={
                                    societesOptions.find(
                                        ({ value }: { value: string }) =>
                                            value === categoriesDataSource.filters.idSociete,
                                    ) || null
                                }
                                options={societesOptions}
                                onChange={(entry: {
                                    value: BDD_SWITCH_SOCIETES_IDS_TYPE;
                                    label: string;
                                }) => {
                                    dispatch({
                                        type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                        payload: {
                                            key: 'idSociete',
                                            value: entry.value,
                                        },
                                    });
                                    dispatch(getCategorieStats({}));
                                }}
                                sx={{
                                    zIndex: 10,
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                        >
                            <Box>
                                <FlexyFormLabel>
                                    <Typography component="span">
                                        {dateFactureRangeProps.inputlabel}
                                    </Typography>
                                </FlexyFormLabel>
                                <FlexyDateRange {...dateFactureRangeProps} />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                        >
                            <Box>
                                <FlexyFormLabel>
                                    <Typography component="span">
                                        {createdAtRangeProps.inputlabel}
                                    </Typography>
                                </FlexyFormLabel>
                                <FlexyDateRange {...createdAtRangeProps} />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                        >
                            <Box
                                sx={{
                                    width: 'fit-content',
                                }}
                            >
                                <FlexyInput
                                    type="boolean"
                                    inputlabel="Afficher les éléments vides"
                                    name="dashboard-afficherzero-filter"
                                    key="dashboard-afficherzero-filter"
                                    value={statsFiltersState.afficherZeros}
                                    onChange={() => {
                                        statsFiltersDispatch({
                                            type: actions.SWITCH_AFFICHER_ZEROS,
                                        });
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <FlexyTabs
                status={undefined}
                sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
                // activeTab={activeTab}
                // handleActiveTab={handleActiveTab}
                tabs={[
                    {
                        tabName: 'categorie',
                        tabTitle: `Par catégories (${
                            categoriesDataSource.status === 'loading' ? '...' : categoriesCount
                        })`,
                        tabPanel: (
                            <SkeletonLoader
                                isLoading={loadingCategories}
                                type="Mosaic"
                            >
                                {categoriesCount === 0 ? (
                                    <RienAAfficher />
                                ) : (
                                    <CategoriesPanel
                                        cardsProps={catProps}
                                        afficherZeros={statsFiltersState.afficherZeros}
                                    />
                                )}
                            </SkeletonLoader>
                        ),
                    },
                    {
                        tabName: 'codeRejet',
                        tabTitle: `Par codes rejets (${
                            codesRejetsDataSource.status === 'loading' ? '...' : codesRejetsCount
                        })`,
                        tabPanel: (
                            <SkeletonLoader
                                isLoading={loadingRejectCodes}
                                type="Mosaic"
                            >
                                {codesRejetsCount === 0 ? (
                                    <RienAAfficher />
                                ) : (
                                    <CodesRejetPanel
                                        cardsProps={catProps}
                                        afficherZeros={statsFiltersState.afficherZeros}
                                    />
                                )}
                            </SkeletonLoader>
                        ),
                    },
                    {
                        tabName: 'detail',
                        tabTitle: `Détails (${
                            facturesAchatsDataSource.status === 'loading' ||
                            facturesEnRejetCount === undefined
                                ? '...'
                                : facturesEnRejetCount
                        })`,
                        tabPanel: (
                            <FactureAchatList
                                filtersControl={false}
                                dataSource={facturesAchatsDataSource}
                                fetchData={getFactureAchatStats}
                            />
                        ),
                    },
                ]}
            />
        </>
    );
};

export default StatsTabs;
