import { useContext } from 'react';
import {} from '@europrocurement/l2d-domain/openApi/ApiTiers';

import { getDataThunkType, getItemThunkType } from '@europrocurement/l2d-redux-utils';
import {
    GroupsUtilisateurApiObject,
    MarquesUtilisateurApiObject,
    PolesUtilisateurApiObject,
    ServicesUtilisateurApiObject,
    UtilisateurApiObject,
    UtilisateursReducerType,
} from '@europrocurement/l2d-domain/reducers/utilisateurs';

import {
    DroitsApi,
    GroupesApi,
    JournauxApi,
    MarquesApi,
    PolesApi,
    ServicesApi,
    UserApi,
    UtilisateursApi,
} from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import L2DApiContext from '../L2DApiContext';

export const useUtilisateurService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.utilisateur) {
        throw new Error('Utilisateurs service is not registred in L2DApiProvider');
    }

    const { apis, slices, thunks, fetchItems, getItems, reducer } = context.utilisateur;

    return {
        reducer: reducer as UtilisateursReducerType,

        utilisateurApi: apis.utilisateursApi as UtilisateursApi,
        userKeycloakApi: apis.userKeycloakApi as UserApi,
        polesApi: apis.polesApi as PolesApi,
        droitsApi: apis.droitsApi as DroitsApi,
        groupesApi: apis.groupesApi as GroupesApi,
        journauxApi: apis.journauxApi as JournauxApi,
        marquesApi: apis.marquesApi as MarquesApi,
        servicesApi: apis.servicesApi as ServicesApi,

        utilisateurSlice: slices.utilisateurSlice,
        servicesSlice: slices.servicesSlice,
        polesSlice: slices.polesSlice,
        marquesSlice: slices.marquesSlice,
        groupesSlice: slices.groupesSlice,

        utilisateurDataSourcesThunks: thunks.utilisateurDataSourcesThunks,
        servicesDataSourcesThunks: thunks.servicesDataSourcesThunks,
        polesDataSourcesThunks: thunks.polesDataSourcesThunks,
        marquesDataSourcesThunks: thunks.marquesDataSourcesThunks,
        groupesDataSourcesThunks: thunks.groupesDataSourcesThunks,

        getUtilisateurs: fetchItems.getUtilisateurs as getDataThunkType<UtilisateurApiObject>,
        getServices: fetchItems.getServices as getDataThunkType<ServicesUtilisateurApiObject>,
        getPoles: fetchItems.getPoles as getDataThunkType<PolesUtilisateurApiObject>,
        getMarques: fetchItems.getMarques as getDataThunkType<MarquesUtilisateurApiObject>,
        getGroupes: fetchItems.getGroupes as getDataThunkType<GroupsUtilisateurApiObject>,

        selectUtilisateurs: getItems.selectUtilisateurs as getItemThunkType<UtilisateurApiObject>,
    };
};
