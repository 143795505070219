/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Annonces Legale Api
 * API de gestion des annonces légales
 *
 * The version of the OpenAPI document: 1.3.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
 */
export interface AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection {
    /**
     * 
     * @type {number}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'typeLibelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'typeLibelleWeb'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'libreonly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'b2babre'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'rang'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'actif'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'spelAccepte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'bodacc'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'auForfait'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'tarifHt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'tarifMajore'?: string;
    /**
     * 
     * @type {CategorieAnnonceLegaleReadCollection}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'categorie'?: CategorieAnnonceLegaleReadCollection;
    /**
     * 
     * @type {FormulaireAnnonceLegaleReadCollection}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'formulaire'?: FormulaireAnnonceLegaleReadCollection;
    /**
     * 
     * @type {TypeAnnonceLegaleReadCollection}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'type'?: TypeAnnonceLegaleReadCollection;
    /**
     * 
     * @type {InformationSeoAnnonceLegaleReadCollection}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'informationSeo'?: InformationSeoAnnonceLegaleReadCollection;
    /**
     * 
     * @type {Array<FormeJuridiqueAnnonceLegaleReadCollection>}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadCollection
     */
    'formesJuridiques'?: Array<FormeJuridiqueAnnonceLegaleReadCollection>;
}
/**
 * 
 * @export
 * @interface AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
 */
export interface AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem {
    /**
     * 
     * @type {number}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'typeLibelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'typeLibelleWeb'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'libreonly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'b2babre'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'rang'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'actif'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'spelAccepte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'bodacc'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'auForfait'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'tarifHt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'tarifMajore'?: string;
    /**
     * 
     * @type {CategorieAnnonceLegaleReadItem}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'categorie'?: CategorieAnnonceLegaleReadItem;
    /**
     * 
     * @type {FormulaireAnnonceLegaleReadItem}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'formulaire'?: FormulaireAnnonceLegaleReadItem;
    /**
     * 
     * @type {TypeAnnonceLegaleReadItem}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'type'?: TypeAnnonceLegaleReadItem;
    /**
     * 
     * @type {InformationSeoAnnonceLegaleReadItem}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'informationSeo'?: InformationSeoAnnonceLegaleReadItem;
    /**
     * 
     * @type {Array<FormeJuridiqueAnnonceLegaleReadItem>}
     * @memberof AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem
     */
    'formesJuridiques'?: Array<FormeJuridiqueAnnonceLegaleReadItem>;
}
/**
 * 
 * @export
 * @interface AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
 */
export interface AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection {
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'typeLibelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'typeLibelleWeb'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'libreonly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'b2babre'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'rang'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'actif'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'spelAccepte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'bodacc'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'auForfait'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'tarifHt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'tarifMajore'?: string;
    /**
     * 
     * @type {CategorieJsonldAnnonceLegaleReadCollection}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'categorie'?: CategorieJsonldAnnonceLegaleReadCollection;
    /**
     * 
     * @type {FormulaireJsonldAnnonceLegaleReadCollection}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'formulaire'?: FormulaireJsonldAnnonceLegaleReadCollection;
    /**
     * 
     * @type {TypeJsonldAnnonceLegaleReadCollection}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'type'?: TypeJsonldAnnonceLegaleReadCollection;
    /**
     * 
     * @type {InformationSeoJsonldAnnonceLegaleReadCollection}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'informationSeo'?: InformationSeoJsonldAnnonceLegaleReadCollection;
    /**
     * 
     * @type {Array<FormeJuridiqueJsonldAnnonceLegaleReadCollection>}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection
     */
    'formesJuridiques'?: Array<FormeJuridiqueJsonldAnnonceLegaleReadCollection>;
}
/**
 * 
 * @export
 * @interface AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
 */
export interface AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'typeLibelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'typeLibelleWeb'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'libreonly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'b2babre'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'rang'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'actif'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'spelAccepte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'bodacc'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'auForfait'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'tarifHt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'tarifMajore'?: string;
    /**
     * 
     * @type {CategorieJsonldAnnonceLegaleReadItem}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'categorie'?: CategorieJsonldAnnonceLegaleReadItem;
    /**
     * 
     * @type {FormulaireJsonldAnnonceLegaleReadItem}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'formulaire'?: FormulaireJsonldAnnonceLegaleReadItem;
    /**
     * 
     * @type {TypeJsonldAnnonceLegaleReadItem}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'type'?: TypeJsonldAnnonceLegaleReadItem;
    /**
     * 
     * @type {InformationSeoJsonldAnnonceLegaleReadItem}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'informationSeo'?: InformationSeoJsonldAnnonceLegaleReadItem;
    /**
     * 
     * @type {Array<FormeJuridiqueJsonldAnnonceLegaleReadItem>}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem
     */
    'formesJuridiques'?: Array<FormeJuridiqueJsonldAnnonceLegaleReadItem>;
}
/**
 * @type AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext
 * @export
 */
export type AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext = AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOf | string;

/**
 * 
 * @export
 * @interface AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOf
 */
export interface AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOf
     */
    'hydra': AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOfHydraEnum;
}

export const AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOfHydraEnum = typeof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOfHydraEnum[keyof typeof AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface AnnonceLegaleJsonldAnnonceLegaleReadPricing
 */
export interface AnnonceLegaleJsonldAnnonceLegaleReadPricing {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof AnnonceLegaleJsonldAnnonceLegaleReadPricing
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleJsonldAnnonceLegaleReadPricing
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnonceLegaleJsonldAnnonceLegaleReadPricing
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface CategorieAnnonceLegaleReadCollection
 */
export interface CategorieAnnonceLegaleReadCollection {
    /**
     * 
     * @type {number}
     * @memberof CategorieAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieAnnonceLegaleReadCollection
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieAnnonceLegaleReadItem
 */
export interface CategorieAnnonceLegaleReadItem {
    /**
     * 
     * @type {number}
     * @memberof CategorieAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieAnnonceLegaleReadItem
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieJsonldAnnonceLegaleReadCollection
 */
export interface CategorieJsonldAnnonceLegaleReadCollection {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof CategorieJsonldAnnonceLegaleReadCollection
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldAnnonceLegaleReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldAnnonceLegaleReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategorieJsonldAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldAnnonceLegaleReadCollection
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieJsonldAnnonceLegaleReadItem
 */
export interface CategorieJsonldAnnonceLegaleReadItem {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof CategorieJsonldAnnonceLegaleReadItem
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldAnnonceLegaleReadItem
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldAnnonceLegaleReadItem
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategorieJsonldAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldAnnonceLegaleReadItem
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface FormeJuridiqueAnnonceLegaleReadCollection
 */
export interface FormeJuridiqueAnnonceLegaleReadCollection {
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueAnnonceLegaleReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueAnnonceLegaleReadCollection
     */
    'abreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueAnnonceLegaleReadCollection
     */
    'dirigeant'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueAnnonceLegaleReadCollection
     */
    'dirigeance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueAnnonceLegaleReadCollection
     */
    'objetDuree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueAnnonceLegaleReadCollection
     */
    'capvar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueAnnonceLegaleReadCollection
     */
    'agrement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueAnnonceLegaleReadCollection
     */
    'sci'?: boolean;
}
/**
 * 
 * @export
 * @interface FormeJuridiqueAnnonceLegaleReadItem
 */
export interface FormeJuridiqueAnnonceLegaleReadItem {
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueAnnonceLegaleReadItem
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueAnnonceLegaleReadItem
     */
    'abreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueAnnonceLegaleReadItem
     */
    'dirigeant'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueAnnonceLegaleReadItem
     */
    'dirigeance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueAnnonceLegaleReadItem
     */
    'objetDuree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueAnnonceLegaleReadItem
     */
    'capvar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueAnnonceLegaleReadItem
     */
    'agrement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueAnnonceLegaleReadItem
     */
    'sci'?: boolean;
}
/**
 * 
 * @export
 * @interface FormeJuridiqueJsonldAnnonceLegaleReadCollection
 */
export interface FormeJuridiqueJsonldAnnonceLegaleReadCollection {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    'abreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    'dirigeant'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    'dirigeance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    'objetDuree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    'capvar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    'agrement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadCollection
     */
    'sci'?: boolean;
}
/**
 * 
 * @export
 * @interface FormeJuridiqueJsonldAnnonceLegaleReadItem
 */
export interface FormeJuridiqueJsonldAnnonceLegaleReadItem {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    'abreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    'dirigeant'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    'dirigeance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    'objetDuree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    'capvar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    'agrement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldAnnonceLegaleReadItem
     */
    'sci'?: boolean;
}
/**
 * 
 * @export
 * @interface FormulaireAnnonceLegaleReadCollection
 */
export interface FormulaireAnnonceLegaleReadCollection {
    /**
     * 
     * @type {number}
     * @memberof FormulaireAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireAnnonceLegaleReadCollection
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface FormulaireAnnonceLegaleReadItem
 */
export interface FormulaireAnnonceLegaleReadItem {
    /**
     * 
     * @type {number}
     * @memberof FormulaireAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireAnnonceLegaleReadItem
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface FormulaireJsonldAnnonceLegaleReadCollection
 */
export interface FormulaireJsonldAnnonceLegaleReadCollection {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof FormulaireJsonldAnnonceLegaleReadCollection
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof FormulaireJsonldAnnonceLegaleReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireJsonldAnnonceLegaleReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormulaireJsonldAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireJsonldAnnonceLegaleReadCollection
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface FormulaireJsonldAnnonceLegaleReadItem
 */
export interface FormulaireJsonldAnnonceLegaleReadItem {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof FormulaireJsonldAnnonceLegaleReadItem
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof FormulaireJsonldAnnonceLegaleReadItem
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormulaireJsonldAnnonceLegaleReadItem
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormulaireJsonldAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormulaireJsonldAnnonceLegaleReadItem
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface GetListAnnoncesLegalesAnnonceLegaleCollection200Response
 */
export interface GetListAnnoncesLegalesAnnonceLegaleCollection200Response {
    /**
     * 
     * @type {Array<AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection>}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200Response
     */
    'hydra:member': Array<AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200Response
     */
    'hydra:view'?: GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView;
    /**
     * 
     * @type {GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearch}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200Response
     */
    'hydra:search'?: GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearch
 */
export interface GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearchHydraMappingInner
 */
export interface GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView
 */
export interface GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface GetListAnnoncesLegalesAnnonceLegaleCollection400Response
 */
export interface GetListAnnoncesLegalesAnnonceLegaleCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection400Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetListAnnoncesLegalesAnnonceLegaleCollection400ResponseException}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection400Response
     */
    'exception'?: GetListAnnoncesLegalesAnnonceLegaleCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface GetListAnnoncesLegalesAnnonceLegaleCollection400ResponseException
 */
export interface GetListAnnoncesLegalesAnnonceLegaleCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection400ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetListAnnoncesLegalesAnnonceLegaleCollection401Response
 */
export interface GetListAnnoncesLegalesAnnonceLegaleCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetListAnnoncesLegalesAnnonceLegaleCollection403Response
 */
export interface GetListAnnoncesLegalesAnnonceLegaleCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetListAnnoncesLegalesAnnonceLegaleCollection403ResponseException}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection403Response
     */
    'exception'?: GetListAnnoncesLegalesAnnonceLegaleCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface GetListAnnoncesLegalesAnnonceLegaleCollection403ResponseException
 */
export interface GetListAnnoncesLegalesAnnonceLegaleCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetListAnnoncesLegalesAnnonceLegaleCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection200ResponseInner
 */
export interface GetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection200ResponseInner
     */
    'idstype': string;
    /**
     * 
     * @type {string}
     * @memberof GetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection200ResponseInner
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof GetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection200ResponseInner
     */
    'shorturl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection200ResponseInner
     */
    'presta_count': string;
}
/**
 * 
 * @export
 * @interface GetPricingAnnonceLegaleItem200Response
 */
export interface GetPricingAnnonceLegaleItem200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetPricingAnnonceLegaleItem200Response
     */
    'stype_au_forfait'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPricingAnnonceLegaleItem200Response
     */
    'montant_forfait'?: string;
}
/**
 * 
 * @export
 * @interface GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
 */
export interface GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'IDSType'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'IDOrigine'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'flg_main'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'IDTypeAnnonce'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'TypeAnnonce'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'type_libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'shorturl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'TypeSociete'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'libellepardef'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'abreviationtypedetail'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'listestypedetail'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'libreonly'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'idformulaire'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'b2babre'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'f_actif'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'metadescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'f_spelaccepte'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner
     */
    'F_Tarif_Forfait'?: number;
}
/**
 * 
 * @export
 * @interface GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404Response
 */
export interface GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404Response {
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404ResponseException}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404Response
     */
    'exception'?: GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404ResponseException;
}
/**
 * 
 * @export
 * @interface GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404ResponseException
 */
export interface GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface InformationSeoAnnonceLegaleReadCollection
 */
export interface InformationSeoAnnonceLegaleReadCollection {
    /**
     * 
     * @type {number}
     * @memberof InformationSeoAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoAnnonceLegaleReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoAnnonceLegaleReadCollection
     */
    'titre'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoAnnonceLegaleReadCollection
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoAnnonceLegaleReadCollection
     */
    'shortUrl'?: string;
}
/**
 * 
 * @export
 * @interface InformationSeoAnnonceLegaleReadItem
 */
export interface InformationSeoAnnonceLegaleReadItem {
    /**
     * 
     * @type {number}
     * @memberof InformationSeoAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoAnnonceLegaleReadItem
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoAnnonceLegaleReadItem
     */
    'titre'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoAnnonceLegaleReadItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoAnnonceLegaleReadItem
     */
    'shortUrl'?: string;
}
/**
 * 
 * @export
 * @interface InformationSeoJsonldAnnonceLegaleReadCollection
 */
export interface InformationSeoJsonldAnnonceLegaleReadCollection {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof InformationSeoJsonldAnnonceLegaleReadCollection
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof InformationSeoJsonldAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadCollection
     */
    'titre'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadCollection
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadCollection
     */
    'shortUrl'?: string;
}
/**
 * 
 * @export
 * @interface InformationSeoJsonldAnnonceLegaleReadItem
 */
export interface InformationSeoJsonldAnnonceLegaleReadItem {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof InformationSeoJsonldAnnonceLegaleReadItem
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadItem
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadItem
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof InformationSeoJsonldAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadItem
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadItem
     */
    'titre'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationSeoJsonldAnnonceLegaleReadItem
     */
    'shortUrl'?: string;
}
/**
 * 
 * @export
 * @interface PalmaresSTypesAnnoncesLegales
 */
export interface PalmaresSTypesAnnoncesLegales {
    /**
     * 
     * @type {number}
     * @memberof PalmaresSTypesAnnoncesLegales
     */
    'nTopResults': number;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegales
     */
    'idstype'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegales
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegales
     */
    'shorturl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegales
     */
    'presta_count'?: string;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegales
     */
    'iDSType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegales
     */
    'prestaCount'?: string | null;
}
/**
 * 
 * @export
 * @interface PalmaresSTypesAnnoncesLegalesJsonld
 */
export interface PalmaresSTypesAnnoncesLegalesJsonld {
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegalesJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegalesJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PalmaresSTypesAnnoncesLegalesJsonld
     */
    'nTopResults': number;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegalesJsonld
     */
    'idstype'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegalesJsonld
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegalesJsonld
     */
    'shorturl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegalesJsonld
     */
    'presta_count'?: string;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegalesJsonld
     */
    'iDSType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PalmaresSTypesAnnoncesLegalesJsonld
     */
    'prestaCount'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeAnnonceLegaleReadCollection
 */
export interface TypeAnnonceLegaleReadCollection {
    /**
     * 
     * @type {number}
     * @memberof TypeAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeAnnonceLegaleReadCollection
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TypeAnnonceLegaleReadItem
 */
export interface TypeAnnonceLegaleReadItem {
    /**
     * 
     * @type {number}
     * @memberof TypeAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeAnnonceLegaleReadItem
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TypeJsonldAnnonceLegaleReadCollection
 */
export interface TypeJsonldAnnonceLegaleReadCollection {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof TypeJsonldAnnonceLegaleReadCollection
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof TypeJsonldAnnonceLegaleReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeJsonldAnnonceLegaleReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeJsonldAnnonceLegaleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeJsonldAnnonceLegaleReadCollection
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TypeJsonldAnnonceLegaleReadItem
 */
export interface TypeJsonldAnnonceLegaleReadItem {
    /**
     * 
     * @type {AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext}
     * @memberof TypeJsonldAnnonceLegaleReadItem
     */
    '@context'?: AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItemContext;
    /**
     * 
     * @type {string}
     * @memberof TypeJsonldAnnonceLegaleReadItem
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeJsonldAnnonceLegaleReadItem
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeJsonldAnnonceLegaleReadItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeJsonldAnnonceLegaleReadItem
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TypesAnnoncesLegalesParOrigine
 */
export interface TypesAnnoncesLegalesParOrigine {
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'IDSType'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'IDOrigine': number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'flg_main'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'IDTypeAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'TypeAnnonce'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'type_libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'shorturl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'TypeSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'libellepardef'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'abreviationtypedetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'listestypedetail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'libreonly'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'idformulaire'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'b2babre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'rang'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'f_actif'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'metadescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'f_spelaccepte'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'F_Tarif_Forfait'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'iDSType'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'iDOrigine'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'flgMain'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'iDTypeAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'typeAnnonce'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'typeLibelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'typeSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'factif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'fspelaccepte'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigine
     */
    'fTarifForfait'?: number | null;
}
/**
 * 
 * @export
 * @interface TypesAnnoncesLegalesParOrigineJsonld
 */
export interface TypesAnnoncesLegalesParOrigineJsonld {
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'IDSType'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'IDOrigine': number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'flg_main'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'IDTypeAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'TypeAnnonce'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'type_libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'shorturl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'TypeSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'libellepardef'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'abreviationtypedetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'listestypedetail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'libreonly'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'idformulaire'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'b2babre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'rang'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'f_actif'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'metadescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'f_spelaccepte'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'F_Tarif_Forfait'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'iDSType'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'iDOrigine'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'flgMain'?: number;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'iDTypeAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'typeAnnonce'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'typeLibelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'typeSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'factif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'fspelaccepte'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineJsonld
     */
    'fTarifForfait'?: number | null;
}

/**
 * AnnonceLegaleApi - axios parameter creator
 * @export
 */
export const AnnonceLegaleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a AnnonceLegale resource.
         * @summary Retrieves a AnnonceLegale resource.
         * @param {number} id L\&#39;ID du sous type
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [departmentCode] Code departement
         * @param {string} [date] date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricingAnnonceLegaleItem: async (id: number, xIdSociete?: number, departmentCode?: string, date?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPricingAnnonceLegaleItem', 'id', id)
            const localVarPath = `/annonces-legales/annonces-legales/tarification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (departmentCode !== undefined) {
                localVarQueryParameter['departmentCode'] = departmentCode;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnonceLegaleApi - functional programming interface
 * @export
 */
export const AnnonceLegaleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnonceLegaleApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a AnnonceLegale resource.
         * @summary Retrieves a AnnonceLegale resource.
         * @param {number} id L\&#39;ID du sous type
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [departmentCode] Code departement
         * @param {string} [date] date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPricingAnnonceLegaleItem(id: number, xIdSociete?: number, departmentCode?: string, date?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPricingAnnonceLegaleItem200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPricingAnnonceLegaleItem(id, xIdSociete, departmentCode, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnonceLegaleApi.getPricingAnnonceLegaleItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnnonceLegaleApi - factory interface
 * @export
 */
export const AnnonceLegaleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnonceLegaleApiFp(configuration)
    return {
        /**
         * Retrieves a AnnonceLegale resource.
         * @summary Retrieves a AnnonceLegale resource.
         * @param {AnnonceLegaleApiGetPricingAnnonceLegaleItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricingAnnonceLegaleItem(requestParameters: AnnonceLegaleApiGetPricingAnnonceLegaleItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetPricingAnnonceLegaleItem200Response> {
            return localVarFp.getPricingAnnonceLegaleItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.departmentCode, requestParameters.date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPricingAnnonceLegaleItem operation in AnnonceLegaleApi.
 * @export
 * @interface AnnonceLegaleApiGetPricingAnnonceLegaleItemRequest
 */
export interface AnnonceLegaleApiGetPricingAnnonceLegaleItemRequest {
    /**
     * L\&#39;ID du sous type
     * @type {number}
     * @memberof AnnonceLegaleApiGetPricingAnnonceLegaleItem
     */
    readonly id: number

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AnnonceLegaleApiGetPricingAnnonceLegaleItem
     */
    readonly xIdSociete?: number

    /**
     * Code departement
     * @type {string}
     * @memberof AnnonceLegaleApiGetPricingAnnonceLegaleItem
     */
    readonly departmentCode?: string

    /**
     * date
     * @type {string}
     * @memberof AnnonceLegaleApiGetPricingAnnonceLegaleItem
     */
    readonly date?: string
}

/**
 * AnnonceLegaleApi - object-oriented interface
 * @export
 * @class AnnonceLegaleApi
 * @extends {BaseAPI}
 */
export class AnnonceLegaleApi extends BaseAPI {
    /**
     * Retrieves a AnnonceLegale resource.
     * @summary Retrieves a AnnonceLegale resource.
     * @param {AnnonceLegaleApiGetPricingAnnonceLegaleItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnonceLegaleApi
     */
    public getPricingAnnonceLegaleItem(requestParameters: AnnonceLegaleApiGetPricingAnnonceLegaleItemRequest, options?: RawAxiosRequestConfig) {
        return AnnonceLegaleApiFp(this.configuration).getPricingAnnonceLegaleItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.departmentCode, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AnnoncesLegalesApi - axios parameter creator
 * @export
 */
export const AnnoncesLegalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les détails d\'un type d\'annonce légale
         * @summary Récupérer les détails d\'un type d\'annonce légale
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnonceLegaleAnnonceLegaleItem: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnnonceLegaleAnnonceLegaleItem', 'id', id)
            const localVarPath = `/annonces-legales/annonces-legales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des types d\'annonces légales
         * @summary Récupérer la liste des types d\'annonces légales
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id] 
         * @param {Array<number>} [id2] 
         * @param {number} [origine] 
         * @param {Array<number>} [origine2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListAnnoncesLegalesAnnonceLegaleCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, id?: number, id2?: Array<number>, origine?: number, origine2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annonces-legales/annonces-legales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (origine !== undefined) {
                localVarQueryParameter['origine'] = origine;
            }

            if (origine2) {
                localVarQueryParameter['origine[]'] = origine2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnoncesLegalesApi - functional programming interface
 * @export
 */
export const AnnoncesLegalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnoncesLegalesApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les détails d\'un type d\'annonce légale
         * @summary Récupérer les détails d\'un type d\'annonce légale
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnonceLegaleAnnonceLegaleItem(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnonceLegaleAnnonceLegaleItem(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnoncesLegalesApi.getAnnonceLegaleAnnonceLegaleItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer la liste des types d\'annonces légales
         * @summary Récupérer la liste des types d\'annonces légales
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id] 
         * @param {Array<number>} [id2] 
         * @param {number} [origine] 
         * @param {Array<number>} [origine2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListAnnoncesLegalesAnnonceLegaleCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, id?: number, id2?: Array<number>, origine?: number, origine2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListAnnoncesLegalesAnnonceLegaleCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListAnnoncesLegalesAnnonceLegaleCollection(xIdSociete, page, itemsPerPage, id, id2, origine, origine2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnoncesLegalesApi.getListAnnoncesLegalesAnnonceLegaleCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnnoncesLegalesApi - factory interface
 * @export
 */
export const AnnoncesLegalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnoncesLegalesApiFp(configuration)
    return {
        /**
         * Récupérer les détails d\'un type d\'annonce légale
         * @summary Récupérer les détails d\'un type d\'annonce légale
         * @param {AnnoncesLegalesApiGetAnnonceLegaleAnnonceLegaleItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnonceLegaleAnnonceLegaleItem(requestParameters: AnnoncesLegalesApiGetAnnonceLegaleAnnonceLegaleItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<AnnonceLegaleAnnonceLegaleJsonldAnnonceLegaleReadItem> {
            return localVarFp.getAnnonceLegaleAnnonceLegaleItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des types d\'annonces légales
         * @summary Récupérer la liste des types d\'annonces légales
         * @param {AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListAnnoncesLegalesAnnonceLegaleCollection(requestParameters: AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetListAnnoncesLegalesAnnonceLegaleCollection200Response> {
            return localVarFp.getListAnnoncesLegalesAnnonceLegaleCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.id, requestParameters.id2, requestParameters.origine, requestParameters.origine2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAnnonceLegaleAnnonceLegaleItem operation in AnnoncesLegalesApi.
 * @export
 * @interface AnnoncesLegalesApiGetAnnonceLegaleAnnonceLegaleItemRequest
 */
export interface AnnoncesLegalesApiGetAnnonceLegaleAnnonceLegaleItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AnnoncesLegalesApiGetAnnonceLegaleAnnonceLegaleItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AnnoncesLegalesApiGetAnnonceLegaleAnnonceLegaleItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getListAnnoncesLegalesAnnonceLegaleCollection operation in AnnoncesLegalesApi.
 * @export
 * @interface AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollectionRequest
 */
export interface AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollection
     */
    readonly id?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollection
     */
    readonly id2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollection
     */
    readonly origine?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollection
     */
    readonly origine2?: Array<number>
}

/**
 * AnnoncesLegalesApi - object-oriented interface
 * @export
 * @class AnnoncesLegalesApi
 * @extends {BaseAPI}
 */
export class AnnoncesLegalesApi extends BaseAPI {
    /**
     * Récupérer les détails d\'un type d\'annonce légale
     * @summary Récupérer les détails d\'un type d\'annonce légale
     * @param {AnnoncesLegalesApiGetAnnonceLegaleAnnonceLegaleItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLegalesApi
     */
    public getAnnonceLegaleAnnonceLegaleItem(requestParameters: AnnoncesLegalesApiGetAnnonceLegaleAnnonceLegaleItemRequest, options?: RawAxiosRequestConfig) {
        return AnnoncesLegalesApiFp(this.configuration).getAnnonceLegaleAnnonceLegaleItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des types d\'annonces légales
     * @summary Récupérer la liste des types d\'annonces légales
     * @param {AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLegalesApi
     */
    public getListAnnoncesLegalesAnnonceLegaleCollection(requestParameters: AnnoncesLegalesApiGetListAnnoncesLegalesAnnonceLegaleCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return AnnoncesLegalesApiFp(this.configuration).getListAnnoncesLegalesAnnonceLegaleCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.id, requestParameters.id2, requestParameters.origine, requestParameters.origine2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PalmaresSTypesAnnoncesLegalesApi - axios parameter creator
 * @export
 */
export const PalmaresSTypesAnnoncesLegalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer le top nTopResults de la liste des sous-types d\'annonces légales
         * @summary Récupérer le top nTopResults de la liste des sous-types d\'annonces légales
         * @param {number} nTopResults Nombre de résultat voulu dans le top de la liste
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection: async (nTopResults: number, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nTopResults' is not null or undefined
            assertParamExists('getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection', 'nTopResults', nTopResults)
            const localVarPath = `/annonces-legales/annonces-legales/palmares_stype/{nTopResults}`
                .replace(`{${"nTopResults"}}`, encodeURIComponent(String(nTopResults)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PalmaresSTypesAnnoncesLegalesApi - functional programming interface
 * @export
 */
export const PalmaresSTypesAnnoncesLegalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PalmaresSTypesAnnoncesLegalesApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer le top nTopResults de la liste des sous-types d\'annonces légales
         * @summary Récupérer le top nTopResults de la liste des sous-types d\'annonces légales
         * @param {number} nTopResults Nombre de résultat voulu dans le top de la liste
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection(nTopResults: number, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection(nTopResults, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PalmaresSTypesAnnoncesLegalesApi.getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PalmaresSTypesAnnoncesLegalesApi - factory interface
 * @export
 */
export const PalmaresSTypesAnnoncesLegalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PalmaresSTypesAnnoncesLegalesApiFp(configuration)
    return {
        /**
         * Récupérer le top nTopResults de la liste des sous-types d\'annonces légales
         * @summary Récupérer le top nTopResults de la liste des sous-types d\'annonces légales
         * @param {PalmaresSTypesAnnoncesLegalesApiGetPalmaresStypePalmaresSTypesAnnoncesLegalesCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection(requestParameters: PalmaresSTypesAnnoncesLegalesApiGetPalmaresStypePalmaresSTypesAnnoncesLegalesCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection200ResponseInner>> {
            return localVarFp.getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection(requestParameters.nTopResults, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection operation in PalmaresSTypesAnnoncesLegalesApi.
 * @export
 * @interface PalmaresSTypesAnnoncesLegalesApiGetPalmaresStypePalmaresSTypesAnnoncesLegalesCollectionRequest
 */
export interface PalmaresSTypesAnnoncesLegalesApiGetPalmaresStypePalmaresSTypesAnnoncesLegalesCollectionRequest {
    /**
     * Nombre de résultat voulu dans le top de la liste
     * @type {number}
     * @memberof PalmaresSTypesAnnoncesLegalesApiGetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection
     */
    readonly nTopResults: number

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PalmaresSTypesAnnoncesLegalesApiGetPalmaresStypePalmaresSTypesAnnoncesLegalesCollection
     */
    readonly xIdSociete?: number
}

/**
 * PalmaresSTypesAnnoncesLegalesApi - object-oriented interface
 * @export
 * @class PalmaresSTypesAnnoncesLegalesApi
 * @extends {BaseAPI}
 */
export class PalmaresSTypesAnnoncesLegalesApi extends BaseAPI {
    /**
     * Récupérer le top nTopResults de la liste des sous-types d\'annonces légales
     * @summary Récupérer le top nTopResults de la liste des sous-types d\'annonces légales
     * @param {PalmaresSTypesAnnoncesLegalesApiGetPalmaresStypePalmaresSTypesAnnoncesLegalesCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalmaresSTypesAnnoncesLegalesApi
     */
    public getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection(requestParameters: PalmaresSTypesAnnoncesLegalesApiGetPalmaresStypePalmaresSTypesAnnoncesLegalesCollectionRequest, options?: RawAxiosRequestConfig) {
        return PalmaresSTypesAnnoncesLegalesApiFp(this.configuration).getPalmaresStypePalmaresSTypesAnnoncesLegalesCollection(requestParameters.nTopResults, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TypesAnnoncesLegalesParOrigineApi - axios parameter creator
 * @export
 */
export const TypesAnnoncesLegalesParOrigineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des types et des sous-types d\'annonces légales suivant un IDOrigine
         * @summary Récupérer la liste des types et des sous-types d\'annonces légales suivant un IDOrigine
         * @param {number} iDOrigine L\&#39;ID de l\&#39;origine
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection: async (iDOrigine: number, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iDOrigine' is not null or undefined
            assertParamExists('getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection', 'iDOrigine', iDOrigine)
            const localVarPath = `/annonces-legales/annonces-legales/types/{IDOrigine}`
                .replace(`{${"IDOrigine"}}`, encodeURIComponent(String(iDOrigine)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypesAnnoncesLegalesParOrigineApi - functional programming interface
 * @export
 */
export const TypesAnnoncesLegalesParOrigineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypesAnnoncesLegalesParOrigineApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des types et des sous-types d\'annonces légales suivant un IDOrigine
         * @summary Récupérer la liste des types et des sous-types d\'annonces légales suivant un IDOrigine
         * @param {number} iDOrigine L\&#39;ID de l\&#39;origine
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection(iDOrigine: number, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection(iDOrigine, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypesAnnoncesLegalesParOrigineApi.getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TypesAnnoncesLegalesParOrigineApi - factory interface
 * @export
 */
export const TypesAnnoncesLegalesParOrigineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypesAnnoncesLegalesParOrigineApiFp(configuration)
    return {
        /**
         * Récupérer la liste des types et des sous-types d\'annonces légales suivant un IDOrigine
         * @summary Récupérer la liste des types et des sous-types d\'annonces légales suivant un IDOrigine
         * @param {TypesAnnoncesLegalesParOrigineApiGetTypesParOrigineTypesAnnoncesLegalesParOrigineCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection(requestParameters: TypesAnnoncesLegalesParOrigineApiGetTypesParOrigineTypesAnnoncesLegalesParOrigineCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection200ResponseInner>> {
            return localVarFp.getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection(requestParameters.iDOrigine, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection operation in TypesAnnoncesLegalesParOrigineApi.
 * @export
 * @interface TypesAnnoncesLegalesParOrigineApiGetTypesParOrigineTypesAnnoncesLegalesParOrigineCollectionRequest
 */
export interface TypesAnnoncesLegalesParOrigineApiGetTypesParOrigineTypesAnnoncesLegalesParOrigineCollectionRequest {
    /**
     * L\&#39;ID de l\&#39;origine
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineApiGetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection
     */
    readonly iDOrigine: number

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TypesAnnoncesLegalesParOrigineApiGetTypesParOrigineTypesAnnoncesLegalesParOrigineCollection
     */
    readonly xIdSociete?: number
}

/**
 * TypesAnnoncesLegalesParOrigineApi - object-oriented interface
 * @export
 * @class TypesAnnoncesLegalesParOrigineApi
 * @extends {BaseAPI}
 */
export class TypesAnnoncesLegalesParOrigineApi extends BaseAPI {
    /**
     * Récupérer la liste des types et des sous-types d\'annonces légales suivant un IDOrigine
     * @summary Récupérer la liste des types et des sous-types d\'annonces légales suivant un IDOrigine
     * @param {TypesAnnoncesLegalesParOrigineApiGetTypesParOrigineTypesAnnoncesLegalesParOrigineCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypesAnnoncesLegalesParOrigineApi
     */
    public getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection(requestParameters: TypesAnnoncesLegalesParOrigineApiGetTypesParOrigineTypesAnnoncesLegalesParOrigineCollectionRequest, options?: RawAxiosRequestConfig) {
        return TypesAnnoncesLegalesParOrigineApiFp(this.configuration).getTypesParOrigineTypesAnnoncesLegalesParOrigineCollection(requestParameters.iDOrigine, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



