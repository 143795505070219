import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { FaOptionIcon, productsIcon } from '@europrocurement/l2d-icons';
import { FlexyHeaderForm, FlexyInput, useVerbose } from '@europrocurement/flexy-components';
import {
    Dossier,
    DossierFormalite,
    FactureAchat,
    FactureAchatApiObject,
    RubriqueFacturation,
    TauxTvaApiObject,
} from '@europrocurement/l2d-domain';
import { FlexyForm, defaultGridProps, inputDefaultProps } from '@europrocurement/flexy-form';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { customizerSelector, selectFactureAchat } from '@b2d/redux';

import { RootStateType, useAppDispatch } from '@b2d/redux/types';

import invoicePurchase from '@b2d/pages/Achats/models/invoicePurchase';
import { useSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';
import useLoadingStatus from '@b2d/hooks/useLoading';
import {
    FactureFormObject,
    LigneProduit,
    RSFRubFac,
    TableauProduits,
    ToggableSection,
} from '../../types';
import { findRubFactById } from '../../functions/produitFunctions';
import { ModeProps } from '../../formElements/types';
import productsArrayStructure from '../../formElements/fragments/productsSection/productsArrayStructure';
import HeaderStructure from '../../../fragments/HeaderStructure';
import SkeletonLoader from '../../../SkeletonLoader';
import { useDeleteLigne, useSynchroG3Facture } from '../../functions/dataHooks';
import apiLinesToFormProducts from '../../functions/dataTransformers/apiToForm/apiLinesToFormProducts';
import hasProductChanged from '../../functions/dataTransformers/formToApi/hasProductChanged';
import {
    compareApiLines,
    convertToNumber,
    convertToNumberAndFormatToString,
    round,
} from '../../functions/calculsProduits';
import formProductLineToApiProductLine from '../../functions/dataTransformers/formToApi/formProductLineToApiProductLine';

export type UpdateProductsSectionFormProps = {
    invoice: FactureAchat;
    lines: Array<FactureAchatLigneJsonldFactureAchatRead>;
    onFetchNewLines: () => void;
    isAlreadySyncG3?: boolean;
} & ToggableSection;

const UpdateProductsSectionForm: React.FunctionComponent<UpdateProductsSectionFormProps> =
    function ({ invoice, lines, onFetchNewLines, switchLockValues, isAlreadySyncG3 = false }) {
        const verbose = useVerbose();
        const { xIdSociete } = useSelector(customizerSelector);
        const { getSwitchLockValues, stateSwitchLockValues } = switchLockValues;
        const { enqueueSnackbar } = useSnackbar();
        const synchroG3Facture = useSynchroG3Facture();
        const deleteLine = useDeleteLigne();

        const [isLoading, setIsLoading] = useState<boolean>(false);

        const invoiceCategoriesDataSource: DataSource<RubriqueFacturation> = useSelector(
            (s: RootStateType) => s.dossiers.rubfac.main,
            _.isEqual,
        );

        const folioSelected: Dossier | undefined = useSelector(
            (s: RootStateType) => s.dossiers.dos.main.selected,
            _.isEqual,
        );

        const folioFormalitySelected: DossierFormalite | undefined = useSelector(
            (s: RootStateType) => s.formalites.dosform.main.selected,
            _.isEqual,
        );

        const vatRatesDataSource: DataSource<TauxTvaApiObject> = useSelector(
            (s: RootStateType) => s.dossiers.txtva.main,
            _.isEqual,
        );

        const invoiceCategories = useMemo(
            () => [...invoiceCategoriesDataSource.data, RSFRubFac],
            [invoiceCategoriesDataSource.data],
        );

        const vatRates = useMemo<Array<number>>(
            () =>
                vatRatesDataSource.data
                    .filter(
                        (vatRate) => vatRate.tauxAchat !== null && vatRate.tauxAchat !== undefined,
                    )
                    .map((vatRate) => round(vatRate.tauxAchat)),
            [vatRatesDataSource.data],
        );

        const productsMode = useMemo<ModeProps>(
            () => ({ type: 'update', lock: stateSwitchLockValues.products.value }),
            [stateSwitchLockValues.products.value],
        );

        const productsStructure = useMemo(
            () =>
                productsArrayStructure({
                    rubFacts: invoiceCategories,
                    txTva: vatRates,
                    mode: productsMode,
                }),
            [invoiceCategories, productsMode, vatRates],
        );

        const dispatch = useAppDispatch();

        const linesSort = lines.sort(compareApiLines);

        type UpdateApiLinesArrayProps = {
            product: Partial<LigneProduit>;
            invoiceIri?: string;
        };

        const updateApiLinesArray = useCallback(
            ({ product, invoiceIri }: UpdateApiLinesArrayProps) => {
                const { idLigne, rubriqueFacturation, ht, tva, txtva } = product;

                if (idLigne) {
                    return () =>
                        invoicePurchase.patchLine({
                            id: idLigne.toString(),
                            factureAchatLigneJsonldFactureAchatLigneWrite: {
                                details: [
                                    {
                                        idRubriqueFacturation: rubriqueFacturation?.id,
                                        ht: ht?.toString(),
                                        tva: tva?.toString(),
                                        tauxTva:
                                            txtva?.value || txtva?.value === 0
                                                ? txtva.value * 100
                                                : undefined,
                                    },
                                ],
                            },
                            xIdSociete,
                        });
                }

                if (invoiceIri) {
                    return () =>
                        invoicePurchase.createLine({
                            factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate:
                                formProductLineToApiProductLine({
                                    formProductLine: product,
                                    invoiceIri,
                                    folioPublication: folioSelected,
                                    folioFormality: folioFormalitySelected,
                                }),
                            xIdSociete,
                        });
                }

                verbose.log('Skipping incomplete product:', product);
                return undefined;
            },
            [verbose, folioFormalitySelected, folioSelected, xIdSociete],
        );

        type RemoveProductProps = {
            linesForRemove: Array<FactureAchatLigneJsonldFactureAchatRead>;
            productsArray: TableauProduits;
        };

        const removeProduct = useCallback(
            ({ linesForRemove, productsArray }: RemoveProductProps) => {
                const isProductRemove = linesForRemove.length > productsArray.length;

                if (!isProductRemove) return [];

                const linesIds = linesForRemove
                    .map((line) => {
                        if (!line.id) return undefined;
                        return line.id.toString();
                    })
                    .filter((id) => id !== undefined);

                const productsIds = productsArray
                    .map((product) => {
                        if (!product.idLigne) return undefined;
                        return product.idLigne.toString();
                    })
                    .filter((id) => id !== undefined);

                const removedIds = linesIds.filter((id) => !productsIds.includes(id));

                return removedIds.map(
                    (id) => () =>
                        deleteLine({
                            idLigne: id,
                        }),
                );
            },
            [deleteLine],
        );

        type PatchProductProps = {
            invoiceForPatch: FactureAchatApiObject;
            linesForPatch: Array<FactureAchatLigneJsonldFactureAchatRead>;
            productsArray: TableauProduits;
        };

        const patchProduct = useCallback(
            ({ invoiceForPatch, linesForPatch, productsArray }: PatchProductProps) => {
                const originalProducts: TableauProduits = apiLinesToFormProducts(linesForPatch);

                return productsArray
                    .map((product, index) => {
                        const originalProduct = originalProducts[index];

                        if (
                            !originalProduct ||
                            (hasProductChanged(originalProduct, product) &&
                                product.tva !== undefined &&
                                product.ttc !== undefined)
                        ) {
                            return updateApiLinesArray({
                                product,
                                invoiceIri: invoiceForPatch['@id'],
                            });
                        }

                        verbose.log('No changes detected for product:', product);
                        return undefined;
                    })
                    .filter((productRequest) => productRequest !== undefined) as Array<
                    () => Promise<AxiosResponse>
                >;
            },
            [updateApiLinesArray, verbose],
        );

        const handleSubmit = useCallback<SubmitHandler<Pick<FactureFormObject, 'produits'>>>(
            async (values) => {
                setIsLoading(true);

                const productsArray: TableauProduits =
                    values.produits as unknown as TableauProduits;

                const productToRemoveRequestList: Array<() => ReturnType<typeof deleteLine>> =
                    removeProduct({
                        linesForRemove: linesSort,
                        productsArray,
                    });
                const productToPatchRequestList = patchProduct({
                    invoiceForPatch: invoice,
                    linesForPatch: linesSort,
                    productsArray,
                });
                const syncRequest = () => synchroG3Facture(invoice.id?.toString() || '-1');

                const requestPromiseList: Array<
                    (typeof productToPatchRequestList)[0] | (typeof productToRemoveRequestList)[0]
                > = [];

                requestPromiseList.push(...productToRemoveRequestList);
                requestPromiseList.push(...productToPatchRequestList);

                try {
                    await Promise.all(requestPromiseList.map((req) => req()));

                    if (isAlreadySyncG3) {
                        syncRequest();
                    }

                    onFetchNewLines();

                    if (invoice.id) {
                        dispatch(selectFactureAchat({ id: invoice.id }));
                    }

                    enqueueSnackbar(`Les produits ont été mis à jour avec succès`);
                } catch (error) {
                    console.error('Error updating product lines:', error);

                    enqueueSnackbar(`Une erreur est survenue lors de la mise à jour des produits`, {
                        variant: 'error',
                    });
                } finally {
                    stateSwitchLockValues.products.set(true);

                    setIsLoading(false);
                }
            },
            [
                dispatch,
                enqueueSnackbar,
                invoice,
                isAlreadySyncG3,
                linesSort,
                onFetchNewLines,
                patchProduct,
                removeProduct,
                stateSwitchLockValues.products,
                synchroG3Facture,
            ],
        );

        const formatLineValues = useCallback(
            (line: FactureAchatLigneJsonldFactureAchatRead) => {
                const lineValues = {
                    rubrique: 'N/A',
                    ht: 'N/A',
                    txTva: 'N/A',
                    tva: 'N/A',
                    ttc: 'N/A',
                };

                if (line.details && line.details.length > 0) {
                    if (line.details[0].idRubriqueFacturation) {
                        const foundRubFact = findRubFactById(
                            invoiceCategories,
                            line.details[0].idRubriqueFacturation,
                        );

                        if (foundRubFact && foundRubFact.code) {
                            lineValues.rubrique = foundRubFact.code;
                        }
                    }

                    if (line.details[0].ht) {
                        lineValues.ht = convertToNumberAndFormatToString(line.details[0].ht);
                    }

                    if (line.details[0].tauxTva || line.details[0].tauxTva === 0) {
                        lineValues.txTva =
                            line.details[0].tauxTva !== 0
                                ? (line.details[0].tauxTva / 100).toString()
                                : '0';
                    }

                    if (line.details[0].tva) {
                        lineValues.tva = convertToNumberAndFormatToString(line.details[0].tva);
                    }

                    if (line.details[0].ht && line.details[0].tva) {
                        lineValues.ttc = convertToNumberAndFormatToString(
                            parseFloat(line.details[0].ht) + parseFloat(line.details[0].tva),
                        );
                    }
                }

                return lineValues;
            },
            [invoiceCategories],
        );

        const formatApiLineToFormLine = useCallback(
            (line: FactureAchatLigneJsonldFactureAchatRead): Partial<LigneProduit> => {
                const productLine: Partial<LigneProduit> = {
                    idLigne: line.id,
                    rubriqueFacturation: {
                        id: 0,
                        code: undefined,
                    },
                    ht: 0,
                    txtva: {
                        label: 0,
                        value: 0,
                    },
                    tva: '',
                    ttc: '',
                };

                if (line.details && line.details.length > 0) {
                    if (line.details[0].idRubriqueFacturation) {
                        const foundRubFact = findRubFactById(
                            invoiceCategories,
                            line.details[0].idRubriqueFacturation,
                        );

                        if (foundRubFact && foundRubFact.id && foundRubFact.code) {
                            productLine.rubriqueFacturation = {
                                id: foundRubFact.id,
                                code: foundRubFact.code,
                            };
                        }

                        if (line.details[0].ht) {
                            productLine.ht = convertToNumber(line.details[0].ht);
                        }

                        if (!!line.details[0].tauxTva || line.details[0].tauxTva === 0) {
                            productLine.txtva = {
                                label:
                                    line.details[0].tauxTva !== 0
                                        ? line.details[0].tauxTva / 100
                                        : 0,
                                value:
                                    line.details[0].tauxTva !== 0
                                        ? line.details[0].tauxTva / 100
                                        : 0,
                            };
                        }

                        if (line.details[0].tva) {
                            productLine.tva = convertToNumberAndFormatToString(line.details[0].tva);
                        }

                        if (line.details[0].ht && line.details[0].tva) {
                            productLine.ttc = convertToNumberAndFormatToString(
                                parseFloat(line.details[0].ht) + parseFloat(line.details[0].tva),
                            );
                        }
                    }
                }

                return productLine;
            },
            [invoiceCategories],
        );

        const formObject = useMemo<Partial<FactureFormObject>>(
            () => ({
                produits: linesSort.map((line) => formatApiLineToFormLine(line)),
            }),
            [formatApiLineToFormLine, linesSort],
        );

        const readyToDisplay = useMemo<boolean>(
            () => !!(!isLoading && invoice && lines && lines.length > 0),
            [invoice, isLoading, lines],
        );

        const { loading } = useLoadingStatus({
            checkReady: () => readyToDisplay,
        });

        return (
            <SkeletonLoader
                isLoading={loading}
                type="FormSection"
            >
                <>
                    <FlexyHeaderForm
                        label={
                            <HeaderStructure
                                icon={
                                    <FaOptionIcon
                                        {...productsIcon.props}
                                        size="sm"
                                    />
                                }
                                title={productsIcon.displayName}
                            />
                        }
                        switches={[
                            ...(!invoice?.syncCegid
                                ? [getSwitchLockValues({ sectionToBlock: 'products' })]
                                : []),
                        ]}
                        outlined
                    />
                    {stateSwitchLockValues.products.value ? (
                        <Box sx={{ marginTop: '10px' }}>
                            {linesSort.map((line) => {
                                const formattedLine = formatLineValues(line);

                                return (
                                    <Grid
                                        key={`line-${line.id}-${line.details ? line.details[0].id : 'no-details'}`}
                                        container
                                        {...defaultGridProps}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="rubriqueFacturation"
                                                type="displayData"
                                                inputlabel="Rubrique"
                                                value={formattedLine.rubrique}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="ht"
                                                type="displayData"
                                                inputlabel="HT"
                                                value={`${formattedLine.ht} €`}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="txtva"
                                                type="displayData"
                                                inputlabel="Taux TVA"
                                                value={`${formattedLine.txTva} %`}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="tva"
                                                type="displayData"
                                                inputlabel="TVA"
                                                value={`${formattedLine.tva} €`}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="ttc"
                                                type="displayData"
                                                inputlabel="TTC"
                                                value={`${formattedLine.ttc} €`}
                                                readOnly
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Box>
                    ) : (
                        <FlexyForm
                            onSubmit={handleSubmit}
                            formObject={formObject}
                            formStructure={productsStructure}
                        />
                    )}
                </>
            </SkeletonLoader>
        );
    };

export default UpdateProductsSectionForm;
