import React from 'react';
import { RouteObject } from 'react-router-dom';
import { dossiersBasePath, listeDossiersPath, detailDossierPath } from './constants';
import DossierRoot, { DetailDossier, ListeDossiers } from './views';

export const DossierRouter: RouteObject = {
    path: dossiersBasePath,
    element: <DossierRoot />,
    children: [
        {
            path: listeDossiersPath,
            element: <ListeDossiers />,
        },
        {
            path: detailDossierPath,
            element: <DetailDossier />,
        },
    ],
};
