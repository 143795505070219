import React, { useContext, useState } from 'react';
import { ModificationDate, Toolbar, ToolbarAction } from '@europrocurement/flexy-components';
import { Box } from '@mui/material';
import { DossierRoutingContext } from '../../../providers';
import DetailDossierActionMenu, {
    DossierAction,
} from '../DetailDossierActionMenu/DetailDossierActionMenu';

export type DetailDossierToolbarProps = {
    id: number;
    reference: string;
    orderId: number;
    orderReference: string;
    createdAt: string;
    actions: Array<DossierAction>;
};

const DetailDossierToolbar: React.FunctionComponent<DetailDossierToolbarProps> = function (props) {
    const { id, reference, orderId, orderReference, createdAt, actions } = props;
    const { getOrderRoute, getDossierRoute, getDossiersListeRoute } =
        useContext(DossierRoutingContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const mainActions = actions.filter((action) => action?.isAdditional === false);
    const additionalActions = actions.filter((action) => action?.isAdditional === true);

    const toolbarActions: ToolbarAction[] = mainActions.map((action) => ({
        onClick: action.onClick,
        icon: action.icon,
    }));

    if (additionalActions.length > 0) {
        toolbarActions.push({
            onClick: (event: React.MouseEvent<HTMLElement>) => {
                setAnchorEl(event.currentTarget);
            },
            icon: ['fasl', 'ellipsis'],
        });
    }

    const breadcrumbPath = [
        {
            link: getOrderRoute(orderId),
            path: `Commande #${orderReference}`,
        },
        {
            link: getDossierRoute(id),
            path: `Dossier #${reference}`,
        },
        '',
    ];

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Toolbar
                path={breadcrumbPath}
                actions={toolbarActions}
                previousPath={getDossiersListeRoute()}
            >
                <Box sx={{ opacity: 0.5 }}>
                    <ModificationDate
                        date={new Date(createdAt)}
                        isCreationDate
                    />
                </Box>
            </Toolbar>
            <DetailDossierActionMenu
                anchorEl={anchorEl}
                actions={actions}
                onClose={handleCloseMenu}
            />
        </>
    );
};

export default DetailDossierToolbar;
