import { useSociete, useTiersService } from '@europrocurement/l2d-domain';
import {
    AdresseJsonldTiersRead,
    PrescripteursApiGetTiersTiersItem0Request,
    TiersTiersJsonldTiersRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { filtersToElasticQuery } from '@europrocurement/l2d-redux-utils';

export type Tiers = TiersTiersJsonldTiersRead & Record<string, unknown>;

const useDossierPrescripteur = () => {
    const { prescribersApi, sousClientsApi } = useTiersService();
    const xIdSociete = useSociete();

    let getPrescriberTimeout: number | undefined;

    const getPrescriber = (prescriberId: number): Promise<Tiers> =>
        new Promise((resolve, reject) => {
            clearTimeout(getPrescriberTimeout);
            const requestParameters: PrescripteursApiGetTiersTiersItem0Request = {
                id: prescriberId as unknown as string,
            };

            getPrescriberTimeout = setTimeout(() => {
                prescribersApi
                    .getTiersTiersItem(requestParameters)
                    .then((res) => resolve(res.data as Tiers))
                    .catch(() => reject());
            }, 300);
        });

    const getSousClients = (prescriberId: number): Promise<Tiers[]> =>
        new Promise((resolve, reject) => {
            const query = filtersToElasticQuery({
                isSousclient: true,
                idPrescripteur: prescriberId,
            });
            sousClientsApi
                .getListTiersTiersCollection({ xIdSociete, search: query })
                .then((res) => resolve(res.data['hydra:member'] as Array<Tiers>))
                .catch(() => reject());
        });

    const formatTiersAddress = (address: AdresseJsonldTiersRead): string =>
        [
            address.adresse1,
            address.adresse2,
            address.adresse3,
            address.adresse4,
            address.codePostal,
            address.ville,
        ]
            .filter((item) => Boolean(item))
            .join(', ');

    const getBillingAddress = (tiersObject: Tiers) => {
        const address = tiersObject.adresses.find((addr) => addr.facturation);

        return address ? formatTiersAddress(address) : '';
    };

    return { getPrescriber, getSousClients, getBillingAddress, formatTiersAddress };
};

export default useDossierPrescripteur;
