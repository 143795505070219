import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BillingAddressCard from '../BillingAddressCard/BillingAddressCard';

export type AddressChoice = {
    clientId: number;
    label: string;
    siren: string;
    address: string;
};

export type SelectBillingAddressProps = {
    clientId: number;
    addresses: Array<AddressChoice>;
    onChange: (clientId: number) => void;
};

const SelectBillingAddress: React.FunctionComponent<SelectBillingAddressProps> = function (props) {
    const { clientId, addresses, onChange } = props;
    const [selectedAddressId, setSelectedAddressId] = useState<number | undefined>(clientId);

    const handleChange = (event: SelectChangeEvent) => {
        const newClientId = event.target.value as unknown as number;
        setSelectedAddressId(newClientId);
        onChange(newClientId);
    };

    useEffect(() => {
        setSelectedAddressId(clientId);
    }, [clientId]);

    return (
        <FormControl fullWidth>
            <Select
                data-testid="select-billing-address"
                labelId="select-billing-address"
                id="select-billing-address"
                value={selectedAddressId as unknown as string}
                onChange={handleChange}
            >
                {addresses.map((address) => (
                    <MenuItem
                        data-testid="select-billing-address-item"
                        value={address.clientId}
                        key={`address-choice-${address.clientId}`}
                    >
                        <BillingAddressCard
                            label={address.label}
                            siren={address.siren}
                            address={address.address}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectBillingAddress;
