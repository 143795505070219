import React from 'react';
import { ObjectGroup, ObjectGroupProps, ObjectGroupType } from '../../atoms';

export type ObjectGroupListProps = {
    objectGroups: Array<ObjectGroupType>;
    selected?: Array<number>;
    onClick?: (objectGroup: ObjectGroupType) => void;
};

const ObjectGroupList: React.FunctionComponent<ObjectGroupListProps> = ({
    objectGroups,
    selected = [],
    onClick,
}) => {
    const onClickWrapper: ObjectGroupProps['onClick'] = (objectGroup) => {
        if (onClick) {
            onClick(objectGroup);
        }
    };

    return objectGroups.map((objectGroup) => (
        <ObjectGroup
            isSelected={selected.includes(objectGroup.id)}
            key={objectGroup.id}
            {...objectGroup}
            onClick={onClickWrapper}
        />
    ));
};

export default ObjectGroupList;
