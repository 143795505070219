import React from 'react';
import { Container } from '@mui/system';
import { ChoiceList } from '@europrocurement/flexy-components';

const SelectionSocieteShow: React.FunctionComponent = function () {
    const onSubmitWrapper = () => {};
    const onSearchWrapper = () => {};
    return (
        <Container maxWidth="md">
            <ChoiceList
                title="Pour quelle(s) société(s) est destinée la commande ?"
                description="Sélectionnez plusieurs structures pour créer un dossier Multi-structures avec des données de formalité identiques et une facturation unique."
                createButtonLabel="Ajouter une société"
                submitButtonLabel="Continuer"
                listChoice={[]}
                multiple
                onSubmit={onSubmitWrapper}
                search={onSearchWrapper}
            />
        </Container>
    );
};

export default SelectionSocieteShow;
