import React, { useContext } from 'react';
import { TimelineStepProps } from '@europrocurement/flexy-components';
import { DossierStepContext, SelectedDossierContext } from '../../../providers';
import DossierInformations from '../DossierInformations/DossierInformations';
import DossierFormalites from '../DossierFormalites/DossierFormalites';
import DossierCompany from '../DossierCompany/DossierCompany';
import DossierSiblings from '../DossierSiblings/DossierSiblings';

const InformationsTab: React.FunctionComponent = function () {
    const { dossier, siblings } = useContext(SelectedDossierContext);
    const { steps } = useContext(DossierStepContext);

    let currentFound = false;
    const displayedSteps: Array<TimelineStepProps> = steps.map((step) => {
        const isCurrent = Number(step.id) === Number(dossier.stepId);
        currentFound = currentFound || isCurrent;

        return {
            label: step.label as string,
            icon: !currentFound || isCurrent ? 'check' : undefined,
            current: isCurrent,
        };
    });
    const siblingsFiltered = siblings.filter((item) => Number(item.id) !== Number(dossier.id));

    return (
        <>
            <DossierInformations
                steps={displayedSteps}
                contactNom={dossier.contactNom as string}
                contactPrenom={dossier.contactPrenom as string}
                formalisteNom={dossier.formalisteNom as string}
                formalistePrenom={dossier.formalistePrenom as string}
                critical={dossier.critical as boolean}
                dateUpdate={dossier.dateUpdate as string}
            />
            <DossierFormalites
                formalities={dossier.formalites.map((formality) => ({
                    label: formality.libelle,
                }))}
            />
            <DossierCompany
                companyName={dossier.societeDenomination as string}
                companyIdentifier={dossier.clientOpereSiren as string}
                companyAddress={dossier.clientOpereAdresseComplete as string}
            />
            {siblingsFiltered.length > 0 && (
                <DossierSiblings
                    orderNumber={dossier.collectionNumero as string}
                    siblings={siblingsFiltered}
                />
            )}
        </>
    );
};

export default InformationsTab;
