import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Box, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import React from 'react';

export const testId = 'ActionButton2-test-id';
export type ActionButton2Props = {
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    icon: IconProp;
    label?: string;
    displayLabel?: boolean;
    tooltip?: boolean | string;
    color?: string;
    sx?: SxProps;
    disabled?: boolean;
};

const ActionButton2: React.FunctionComponent<ActionButton2Props> = function ({
    onClick,
    icon,
    label = '',
    displayLabel = true,
    tooltip = false,
    color = '',
    sx,
    disabled = false,
}) {
    const hasLabel = displayLabel && label;
    const getColor = () => (!disabled ? color : 'gray');

    const buttonStyle = {
        borderRadius: '5px',
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': { backgroundColor: '#11111111' },
        ...(disabled ? { opacity: 0.5 } : {}),
        ...sx,
    };
    const innerIconStyle = {
        color: getColor(),
        fontSize: '25px',
        ...(hasLabel ? { marginLeft: '6px' } : {}),
    };
    const labelStyle = {
        marginX: '10px',
        color: getColor(),
    };

    const tooltipContent = typeof tooltip === 'string' ? tooltip : label;
    const button = (
        <IconButton
            data-testid={testId}
            onClick={onClick}
            disabled={disabled}
            sx={buttonStyle}
        >
            <Box>
                <FaOptionIcon
                    icon={icon}
                    sx={innerIconStyle}
                    size="xs"
                />
            </Box>
            {hasLabel && <Typography sx={labelStyle}>{label}</Typography>}
        </IconButton>
    );
    return tooltip ? <Tooltip title={tooltipContent}>{button}</Tooltip> : button;
};

export default ActionButton2;
