import { jsonSection } from '@europrocurement/flexy-form/utils/types/formTypes';
import {
    OptionsApi,
    OptionsJsonldOptionRead,
    TblRelOptionJsonldCatalogueRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { castIriToId } from '@europrocurement/l2d-utils';
import { AxiosResponse } from 'axios';

export const optionsComplementaire = async (
    offresApi: OptionsApi,
    options: Array<TblRelOptionJsonldCatalogueRead>,
) => {
    let resOption: Promise<AxiosResponse<OptionsJsonldOptionRead, unknown>>;
    const pageOpt = {
        titre: 'Options complémentaires',
        sections: [
            {
                type: 'header',
                label: 'Options Complémentaires',
                name: 'options_complementaires',
            } as jsonSection,
        ],
    };

    const optionsResults: Array<Promise<AxiosResponse<OptionsJsonldOptionRead, unknown>>> = [];
    options.forEach((option) => {
        if (option.option && option.option['@id'])
            resOption = offresApi.apiOptionsIdGet({
                id: `${castIriToId(option.option['@id'])}`,
            });
        optionsResults.push(resOption);
    });

    return Promise.all(optionsResults)
        .then((optionsRes) => {
            optionsRes.forEach((optionres) => {
                const optionOption: OptionsJsonldOptionRead = optionres.data;
                if (optionOption.visibleFront === true) {
                    const section = {
                        type: 'text',
                        name: `option_complementaire_${optionOption.id}`,
                        inputlabel: optionOption.libelle,
                        valuesfrom: {} as { options?: Record<string | number, string> },
                    };

                    const optionsValues: Record<string | number, string> = {};
                    if (optionOption.values) {
                        optionOption.values.forEach((optionValue, index) => {
                            optionsValues[optionValue.id?.toString() ?? index.toString()] =
                                optionValue.libelle || 'Option sans libellé';
                        });
                    }
                    if (optionOption.htmlFieldType === 'radio') {
                        section.type = 'radio';
                        section.valuesfrom.options = optionsValues;
                    }
                    if (optionOption.htmlFieldType === 'select') {
                        section.type = 'select';
                        section.valuesfrom.options = optionsValues;
                    }
                    if (optionOption.htmlFieldType === 'checkbox') section.type = 'boolean';
                    pageOpt.sections.push(section as jsonSection);
                }
            });

            if (pageOpt.sections.length === 1) return null;

            return pageOpt;
        })
        .catch((reason) => {
            console.log(reason);
        });
};
