/** JS Date to ISO Y-m-d */
export const dateToISOYmd = (date: Date) => date.toISOString().substring(0, 10);

const FRENCH_MONTHS = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
] as const;

const getMonthInFrench = (index: number) => FRENCH_MONTHS[index];

/** JS Date to french format */
export const dateToFrenchFormat = (date: Date) => {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const month = getMonthInFrench(monthIndex);
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
};
