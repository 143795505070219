import { useSociete, Fournisseur, useTiersService } from '@europrocurement/l2d-domain';

import { RootStateType } from '@b2d/redux/types';
import { LazyBreadcrumb, ListActionButton } from '@europrocurement/flexy-components';
import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { faWrench } from '@fortawesome/pro-duotone-svg-icons';
import _ from 'lodash';
import React, { MouseEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const PublishersListing: React.FunctionComponent = function () {
    const publishersDataSource: DataSource<Fournisseur> = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.main,
        _.isEqual,
    );

    const { getFournisseurs } = useTiersService();

    const navigate = useNavigate();
    const xIdSociete = useSociete();

    const columns: ColumnDatatable<Fournisseur>[] = useMemo(
        () => [
            {
                label: 'Siren',
                render: ({ siren }) => siren,
                isDisplayed: true,
                sortable: true,
                field: 'siren',
            },
            {
                label: 'Libelle',
                render: ({ libelle }) => libelle,
                isDisplayed: true,
                sortable: true,
                field: 'libelle',
            },
            {
                label: 'Actions',
                onClickCell: () => {},
                render: (publisher: Fournisseur) =>
                    publisher.id ? (
                        <ListActionButton
                            tooltip="Configurer"
                            onClick={() => navigate(`${publisher.id}`)}
                            icon={faWrench}
                        />
                    ) : null,
                isDisplayed: true,
            },
        ],
        [navigate],
    );

    const filters: FiltersDatatableList = [
        {
            field: 'siren',
            label: 'siren',
            type: 'text',
        },
        {
            field: 'libelle',
            label: 'libelle',
            type: 'text',
        },
    ];

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Fournisseurs', link: '/fournisseurs/liste' },
                    'Liste des fournisseurs',
                ]}
            />
            <StoreDatatable
                dataSource={publishersDataSource}
                columns={columns}
                fetchData={getFournisseurs}
                filters={filters}
                localStorageKey="FlexyStoreDatatablePublishers"
                onClickRow={(__: MouseEvent<HTMLElement>, { id }: Fournisseur) => navigate(`${id}`)}
                observers={[xIdSociete]}
                fetchWithoutFilter
            />
        </>
    );
};

export default PublishersListing;
