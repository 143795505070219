import * as React from 'react';
import { Box } from '@mui/material';
import { formatPrice } from '@europrocurement/l2d-utils';

const getDefaultLabelFromField = (key: string): string => {
    // Example : 'fooBarBaz' => 'Foo bar baz'
    const arrayOfFormattedWords = key.split(/(?=[A-Z])/).map((word, index) => {
        if (index === 0) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word.toLowerCase();
    });

    return arrayOfFormattedWords.join(' ');
};

const shrinkString = (value: string, limit: number) => {
    if (!value || typeof value !== 'string') {
        return '';
    }
    let result;
    const appendDots = value.length > limit;
    result = value.substring(0, limit);
    result = appendDots ? `${result}...` : result;
    return result;
};

export const getStringColumn = <T,>(
    field: keyof T,
    labelValue?: string,
    maxCharacters?: number,
) => {
    const label = labelValue ?? getDefaultLabelFromField(field as string);

    return {
        label,
        displayLabel: true,
        render: (item: T) => {
            let value = item[field] as string;
            if (maxCharacters) {
                value = shrinkString(value, maxCharacters);
            }
            return (
                <Box sx={{ justifyContent: 'end' }}>
                    <p>{value}</p>
                </Box>
            );
        },
        isDisplayed: true,
        sortable: false,
        field,
    };
};

export const getPriceColumn = <T,>(field: keyof T, labelValue?: string) => {
    const label = labelValue ?? getDefaultLabelFromField(field as string);
    return {
        label,
        displayLabel: true,
        render: (item: T) => {
            const value = item[field] as string;
            const priceValue = parseFloat(value);

            if (priceValue === 0) {
                return '(offert)';
            }
            if (!priceValue) {
                return '- - -';
            }

            return (
                <Box sx={{ justifyContent: 'end' }}>
                    <p>{formatPrice(priceValue)} €</p>
                </Box>
            );
        },
        isDisplayed: true,
        sortable: false,
        field,
    };
};

export const displayCustomValue = <T,>(
    field: keyof T,
    formatFunction: (fieldValue: string | [] | T) => string,
    labelValue?: string,
    maxCharacters?: number,
) => {
    const label = labelValue ?? getDefaultLabelFromField(field as string);

    return {
        label,
        displayLabel: true,
        render: (item: T) => {
            let value = item[field] as string;
            value = formatFunction(value);
            if (maxCharacters) {
                value = shrinkString(value, maxCharacters);
            }
            return (
                <Box sx={{ justifyContent: 'end' }}>
                    <p>{value}</p>
                </Box>
            );
        },
        isDisplayed: true,
        sortable: false,
        field,
    };
};
