// Zod validation schema for option values
import { z } from 'zod';
import { articlesObject } from '../../Package/Form/validation';

const documentObject = z.object({
    regle: z.string().trim().nullable(), // Option value specific field
    typeDocument: z.number(),
});
const additionalFieldObject = z.object({
    champComplementaire: z.number(),
});
export const optionValueRelationshipsValidationSchema = z.object({
    articles: z.array(articlesObject),
    documents: z.array(documentObject),
    champsComplementaires: z.array(additionalFieldObject),
});
