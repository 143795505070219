import * as React from 'react';
import { Box } from '@mui/material';
import { ModalContext } from '@europrocurement/flexy-components';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { useDispatch } from 'react-redux';
import { ChampComplMentaireJsonldChampcomplementaireReadHtmlFieldTypeEnum } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { addItem } from '@europrocurement/l2d-icons';
import { useOffreService } from '@europrocurement/l2d-domain';
import ListingWithPreview from '../components/ListingWithPreview';
import Form from './Form';

import { getStringColumn } from '../listings';
import { getHtmlFieldTypeLabel } from '../forms/htmlFields';

const Panel: React.FunctionComponent = function () {
    const { modalActions } = React.useContext(ModalContext);

    const { additionalFieldModel: model } = useOffreService();

    const dispatch = useDispatch();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reloadList = () => dispatch(model.datasourcesThunks.main.getData({}) as any);

    const listingButton = {
        label: 'Créer nouveau champ complémentaire',
        icon: addItem,
        action: () =>
            modalActions.call(
                <Form
                    isCreate
                    afterSubmit={reloadList}
                />,
            ),
    };

    const columns: ColumnDatatable[] = [
        getStringColumn('id'),
        getStringColumn('libelle', 'Libellé'),
        getStringColumn('description'),
        {
            label: 'Type de champ HTML',
            displayLabel: true,
            render: (item: {
                htmlFieldType: ChampComplMentaireJsonldChampcomplementaireReadHtmlFieldTypeEnum;
            }) => (
                <Box sx={{ justifyContent: 'end' }}>
                    <p>{getHtmlFieldTypeLabel(item.htmlFieldType)}</p>
                </Box>
            ),
            isDisplayed: true,
            sortable: false,
            field: 'htmlFieldType',
        },
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des champs complémentaires"
            listingButton={listingButton}
            listingColumns={columns}
            form={Form}
            model={model}
        />
    );
};

export default Panel;
