import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Dialog, IconButton, PaperProps, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { enqueueSnackbar } from 'notistack';
import { DossierActionContext } from '../../../providers';
import ExportDossierForm from '../ExportDossierForm/ExportDossierForm';
import CreateContactForm from '../CreateContactForm/CreateContactForm';
import {
    CompanyItem,
    ContactFormData,
    ContactItem,
    ExportFormData,
    useExportDossier,
} from '../../../hooks';

type subAction = 'creatingClient' | 'exporting';

const PaperComponent = function ({ sx, ...props }: PaperProps) {
    return (
        <Box
            {...props}
            sx={{
                ...sx,
                width: 600,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}
        />
    );
};

const ExportDossierModal: React.FunctionComponent = function () {
    const { currentAction, confirmCurrentAction, cancelCurrentAction, dossiers } =
        useContext(DossierActionContext);
    const { createContact, submitExport } = useExportDossier();
    const [subAction, setSubAction] = useState<subAction>('exporting');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedContacts, setSelectedContacts] = useState<Array<ContactItem>>([]);
    const [companies, setCompanies] = useState<Array<CompanyItem>>([]);
    const [creatingContact, setCreatingContact] = useState<boolean>(false);
    const [exporting, setExporting] = useState<boolean>(false);
    const { palette } = useTheme();

    useEffect(() => {
        const shouldShowModal = currentAction === 'export';
        if (shouldShowModal !== showModal) {
            if (shouldShowModal) {
                setSubAction('exporting');
            }
            setShowModal(shouldShowModal);
        }

        if (!shouldShowModal) {
            setSelectedContacts([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAction]);

    useEffect(() => {
        const buffer: Array<number> = [];
        setCompanies(
            dossiers
                .filter((dossier) => {
                    const prescripteurId = dossier?.prescripteurId as number;
                    if (!buffer.includes(prescripteurId)) {
                        buffer.push(prescripteurId);

                        return true;
                    }

                    return false;
                })
                .map((dossier) => ({
                    label: dossier.prescripteurLibelle as string,
                    value: dossier.prescripteurId as number,
                })),
        );
    }, [dossiers]);

    const handleCloseModal = () => {
        cancelCurrentAction();
    };

    const handleExportSubmit = async (formData: ExportFormData) => {
        setExporting(true);
        try {
            await submitExport(formData, dossiers);
            enqueueSnackbar('Votre récapitulatif a bien été envoyé');
            confirmCurrentAction();
        } catch (err) {
            enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
            console.error(err);
        } finally {
            setExporting(false);
        }
    };

    const handleAddContact = async (contactFormData: ContactFormData) => {
        setCreatingContact(true);
        try {
            const item = await createContact(contactFormData);
            setSelectedContacts([...selectedContacts, item]);
            setSubAction('exporting');
        } catch (err) {
            enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
            console.error(err);
        } finally {
            setCreatingContact(false);
        }
    };

    const createContactBtn = (
        <Button
            variant="text"
            sx={{
                padding: '16px 44px 16px 0',
                display: 'flex',
                alignItems: 'center',
                color: 'primary.dark',
                gap: '16px',
            }}
            onClick={() => setSubAction('creatingClient')}
            disabled={exporting}
        >
            <FaOptionIcon
                icon={['fas', 'plus']}
                sx={{ lineHeight: 0 }}
            />
            Créer un nouveau contact
        </Button>
    );

    return (
        <Dialog
            open={showModal}
            PaperComponent={PaperComponent}
        >
            <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                    fontSize: '16px',
                    position: 'absolute',
                    right: '20px',
                    top: '30px',
                    color: palette.grey[500],
                }}
            >
                <FaOptionIcon icon={['fas', 'close']} />
            </IconButton>

            {subAction === 'exporting' && (
                <ExportDossierForm
                    processing={exporting}
                    companies={companies}
                    selectedContacts={selectedContacts}
                    onSelectedContactsChanged={(items) => setSelectedContacts(items)}
                    additionalAction={createContactBtn}
                    onSubmit={handleExportSubmit}
                />
            )}
            {subAction === 'creatingClient' && (
                <CreateContactForm
                    processing={creatingContact}
                    companies={companies}
                    onCancel={() => setSubAction('exporting')}
                    onSubmit={handleAddContact}
                />
            )}
        </Dialog>
    );
};

export default ExportDossierModal;
