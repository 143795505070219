import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';

const FlexyDatatableBodyIdle: React.FunctionComponent = function () {
    return (
        <TableBody data-testid="FlexyDatatableBody">
            <TableRow>
                <TableCell
                    colSpan={12}
                    sx={{ height: '45vh' }}
                >
                    <Typography>En attente d&apos;une recherche...</Typography>
                </TableCell>
            </TableRow>
        </TableBody>
    );
};

export default FlexyDatatableBodyIdle;
