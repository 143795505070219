import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import type { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { useDispatch } from 'react-redux';
import { SxProps } from '@mui/material';
import { DateTime } from 'luxon';
import { isValidDate } from '@europrocurement/flexy-components';
import { AnyAction } from '@reduxjs/toolkit';
import { ColumnDatatable } from '../ColumnDatatable';
import { FlexyDatatable } from '../FlexyDatatable';
import {
    FiltersDatatableList,
    FiltersOpts,
    OrdersOpts,
    PreFilterDatatable,
    PreFilterDatatableList,
    RowSelectionActionList,
} from '../DatatableHeader';
import { DatatablePagination, FlexyDatatableProps } from '../FlexyDatatableTable';
import { FlexyDatatableRaw } from '../FlexyDatatableRaw';

export type ExportMapper<T> = (item: T) => [...(string | number | Date)[]];

export type ExportOps<T> = {
    exportMapper: ExportMapper<T>;
    // getExportData: AsyncThunk<ExportDataAsyncThunk, ExportMapper<T>, Record<string, unknown>>;
};

export type StoreDatatableProps<T extends Record<string, unknown>> = {
    dataSource: DataSource<T>;
    fetchData: getDataThunkType<T>;
    columns: ColumnDatatable<T>[];
    filters?: FiltersDatatableList;
    preFilters?: PreFilterDatatableList;
    observers?: Array<unknown>;
    showSearch?: boolean;
    /**
     * info : If this props is defined, the columns parameters is saved in localStorage
     *
     * Warning : if you use localStorage with localStorageKey, columns property need to have memoized with React.useMemo
     */
    localStorageKey?: string;
    localStorageRefreshDate?: Date;
    exportOpts?: ExportOps<T>;
    onClickRow?: FlexyDatatableProps<T>['onClickRow'];
    onWheelClickRow?: FlexyDatatableProps<T>['onWheelClickRow'];
    computeSxRow?: FlexyDatatableProps<T>['computeSxRow'];
    filtersControl?: boolean;
    mode?: 'classic' | 'raw';
    fetchWithoutFilter?: boolean;
    sx?: SxProps;
    hideColumnOptions?: boolean;
    selectedId?: number;
    rowsActions?: RowSelectionActionList;
    buttonAction?: ReactNode;
};

export const StoreDatatable = function <T extends Record<string, unknown>>({
    dataSource,
    fetchData,
    columns,
    showSearch = false,
    filters,
    preFilters,
    localStorageKey,
    localStorageRefreshDate,
    onClickRow,
    onWheelClickRow,
    computeSxRow,
    selectedId,
    observers = [],
    filtersControl = true,
    mode = 'classic',
    fetchWithoutFilter = true,
    hideColumnOptions = false,
    sx = {},
    rowsActions = [],
    buttonAction,
}: StoreDatatableProps<T>) {
    const dispatch = useDispatch();

    const sliceName = dataSource.slicename;

    /**
     * Changement de pagination
     */
    const onPageChange = useCallback(
        (page: number) => {
            dispatch({
                type: `${sliceName}/set${dataSource.name}Pagination`,
                payload: { ...dataSource.pagination, page },
            });
            dispatch(fetchData({}) as unknown as AnyAction);
        },
        [dispatch, sliceName, dataSource.name, dataSource.pagination, fetchData],
    );

    /**
     * Changement du nombre d'éléments sur la page
     */
    const onItemsPerPageChange = useCallback(
        (itemsPerPage: number) => {
            dispatch({
                type: `${sliceName}/set${dataSource.name}Pagination`,
                payload: { ...dataSource.pagination, itemsPerPage, page: 0 },
            });
            dispatch(fetchData({}) as unknown as AnyAction);
        },
        [dispatch, sliceName, dataSource.name, dataSource.pagination, fetchData],
    );

    const onSearchChange = useDebouncedCallback((search) => {
        dispatch({
            type: `${sliceName}/set${dataSource.name}Search`,
            payload: { search },
        });
        dispatch(fetchData({}) as unknown as AnyAction);
    }, 1000);

    const onFilterChangeDatasourceBouncedFetch = useDebouncedCallback(() => {
        dispatch(fetchData({}) as unknown as AnyAction);
    }, 500);

    const onFilterChangeDatasource = useCallback(
        (key: unknown, filterValue: unknown) => {
            let filterValueFormated = filterValue;
            if (isValidDate(filterValue)) {
                filterValueFormated = DateTime.fromJSDate(filterValue as Date).toISODate();
                dispatch({
                    type: `${sliceName}/set${dataSource.name}Filter`,
                    payload: { key, value: filterValueFormated },
                });
            } else {
                dispatch({
                    type: `${sliceName}/set${dataSource.name}Filter`,
                    payload: { key, value: filterValue },
                });
            }

            onFilterChangeDatasourceBouncedFetch();
        },
        [dataSource.name, dispatch, onFilterChangeDatasourceBouncedFetch, sliceName],
    );

    const onOrdersChange = useCallback(
        (field: string, value: 'asc' | 'desc' | undefined) => {
            dispatch({
                type: `${sliceName}/set${dataSource.name}Order`,
                payload: { field, value },
            });
            dispatch(fetchData({}) as unknown as AnyAction);
        },
        [dataSource.name, dispatch, fetchData, sliceName],
    );

    const onFilterDelete = useCallback(
        (field: string) => {
            dispatch({
                type: `${sliceName}/delete${dataSource.name}Filter`,
                payload: { field },
            });
            dispatch(fetchData({}) as unknown as AnyAction);
            const newFilters = { ...dataSource.filters };
            delete newFilters[field];
            // setActiveFilters(newFilters);
        },
        [dataSource.name, dataSource.filters, dispatch, fetchData, sliceName],
    );

    const onFiltersClear = useCallback(() => {
        if (filters) {
            filters
                .map((item) => item.field)
                .forEach((f: string) => {
                    dispatch({
                        type: `${sliceName}/delete${dataSource.name}Filter`,
                        payload: { field: f },
                    });
                });
        }

        dispatch(fetchData({}) as unknown as AnyAction);
    }, [filters, dataSource.name, dispatch, fetchData, sliceName]);

    const onUpdateFilters = useCallback(
        (preFilter: PreFilterDatatable) => {
            dispatch({
                type: `${sliceName}/set${dataSource.name}Pagination`,
                payload: { ...dataSource.pagination, page: 0 },
            });
            dispatch({
                type: `${sliceName}/set${dataSource.name}PreFilter`,
                payload: preFilter,
            });
            dispatch(fetchData({}) as unknown as AnyAction);
        },
        [dataSource.name, dataSource.pagination, dispatch, fetchData, sliceName],
    );

    const filtersOpts: FiltersOpts | undefined = useMemo(
        () =>
            filters
                ? {
                      onFilterChange: (key, value) => {
                          if (
                              (value instanceof Date && !isValidDate(value)) ||
                              value === null ||
                              value === undefined ||
                              value === ''
                          )
                              return;
                          const newFilters = { ...dataSource.filters };
                          newFilters[key] = value;
                          onFilterChangeDatasource(key, value);
                      },
                      filters,
                      activeFilters: dataSource.filters,
                      onFilterDelete,
                      onFiltersClear,
                      onUpdateFilters,
                      preFilters,
                      activePreFilter: dataSource.preFilters as unknown as PreFilterDatatable,
                  }
                : undefined,
        [
            dataSource.filters,
            filters,
            onFilterChangeDatasource,
            onFilterDelete,
            onUpdateFilters,
            onFiltersClear,
            dataSource.preFilters,
            preFilters,
        ],
    );

    const ordersOpts: OrdersOpts = useMemo(
        () => ({ onOrdersChange, orders: dataSource.orders }),
        [dataSource.orders, onOrdersChange],
    );

    const pagination: DatatablePagination = useMemo(
        () => ({
            ...dataSource.pagination,
            onPageChange,
            onItemsPerPageChange,
        }),
        [dataSource.pagination, onItemsPerPageChange, onPageChange],
    );

    const [currentRetryCount, setCurrentRetryCount] = useState(0);

    /**
     * Retry to fetch data if failed to get it at this point.
     *
     * To avoid infinite loop searching for data until it succeed or die,
     * there is a max attempts limit setup.
     */
    useEffect(() => {
        if (dataSource.status === 'loading' || dataSource.status === 'succeeded') return;
        if (dataSource.status === 'idle' || dataSource.data.length === 0) {
            const maxRetry = 3;
            const dataSourceFailing = dataSource.status === 'failed';
            const limitedRetryInCaseOfFail: boolean = !!(
                dataSourceFailing && currentRetryCount < maxRetry
            );

            if (fetchWithoutFilter && limitedRetryInCaseOfFail) {
                dispatch(fetchData({}) as unknown as AnyAction);
                setCurrentRetryCount(currentRetryCount + 1);
            }
        }
    }, [
        currentRetryCount,
        dataSource.data.length,
        dataSource.status,
        dispatch,
        fetchData,
        fetchWithoutFilter,
    ]);

    useEffect(() => {
        if (observers.length > 0) {
            dispatch(fetchData({}) as unknown as AnyAction);
        }
    }, [dispatch, fetchData, observers.length]);

    const [updatedColumns, setUpdatedColumns] = useState<ColumnDatatable<T>[]>(columns);

    const saveUpdatedColumns = function (toUpdate: ColumnDatatable[], key: string) {
        const ColumnsBeforeStorage = toUpdate.map(({ label, isDisplayed }) => ({
            label,
            isDisplayed,
        }));
        if (localStorage.getItem(key) !== JSON.stringify(ColumnsBeforeStorage)) {
            localStorage.setItem(key, JSON.stringify(ColumnsBeforeStorage));
            localStorage.setItem(`${key}_date`, new Date().toISOString());
        }
    };

    // Check if columns exist in localstorage and merge his with initialsColumns
    useEffect(() => {
        if (localStorageKey) {
            if (localStorageRefreshDate) {
                const columnsDate = localStorage.getItem(`${localStorageKey}_date`);
                if (!columnsDate || new Date(columnsDate) < localStorageRefreshDate) {
                    setUpdatedColumns(columns);
                    saveUpdatedColumns(columns, localStorageKey);
                    return;
                }
            }

            const columnsStored = localStorage.getItem(localStorageKey);

            if (columnsStored) {
                const columnsParsed: ColumnDatatable[] = JSON.parse(
                    columnsStored,
                ) as ColumnDatatable[];

                const columnsMerged = columns.map((col) => {
                    let obj = { ...col };
                    for (let i = 0; i < columnsParsed.length; i += 1) {
                        const cp = columnsParsed[i];
                        if (cp.label === col.label) {
                            obj = { ...col, ...cp };
                        }
                    }
                    return obj as ColumnDatatable;
                });
                setUpdatedColumns(columnsMerged);
                saveUpdatedColumns(columnsMerged, localStorageKey);
            }
        }
    }, [columns, localStorageKey, localStorageRefreshDate]);

    // const handleExport: FlexyDatatableProps['handleExport'] = async () => {
    //     if (exportOpts) {
    //         // const { exportMapper } = exportOpts;
    //         // dispatch(getExportData(exportMapper));
    //     } else
    //         console.error(
    //             'exportOpts is undefined (File: StoreDatatable.tsx; function: handleExport',
    //         );
    // };

    const datatableProps: FlexyDatatableProps<T> = useMemo(
        () => ({
            status: dataSource.status,
            data: dataSource.data,
            columns: updatedColumns,
            pagination,
            // handleExport: exportOpts && handleExport,
            onClickRow,
            onWheelClickRow,
            filtersControl: filtersOpts && filtersControl,
            setColumns: (settedcolumns: ColumnDatatable[]) => {
                setUpdatedColumns(settedcolumns);
                if (localStorageKey) {
                    saveUpdatedColumns(settedcolumns, localStorageKey);
                }
            },
            computeSxRow,
            hideColumnOptions,
            selectedId,
            searchOpts: {
                showSearch,
                onSearchChange,
                search: dataSource.search,
            },
            sx: { ...sx },
            rowsActions,
            buttonAction,
        }),
        [
            dataSource.status,
            dataSource.data,
            dataSource.search,
            updatedColumns,
            pagination,
            onClickRow,
            onWheelClickRow,
            filtersOpts,
            filtersControl,
            computeSxRow,
            hideColumnOptions,
            selectedId,
            showSearch,
            onSearchChange,
            sx,
            rowsActions,
            localStorageKey,
            buttonAction,
        ],
    );

    if (datatableProps.searchOpts) {
        if (filtersOpts) {
            datatableProps.searchOpts.filtersOpts = filtersOpts;
        }
        if (ordersOpts) {
            datatableProps.searchOpts.ordersOpts = ordersOpts;
        }
    }

    return mode === 'raw' ? (
        <FlexyDatatableRaw {...datatableProps} />
    ) : (
        <FlexyDatatable {...datatableProps} />
    );
};

export default StoreDatatable;
