import React, { useState } from 'react';
import {
    ContactsContactJsonldContactUpdate,
    ContactTiersRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { faPen, faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { limitStringLength } from '@europrocurement/l2d-utils';
import { faClose, faLock } from '@fortawesome/pro-duotone-svg-icons';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { useSociete, useTiersService } from '@europrocurement/l2d-domain';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import CustomAvatar from '../CustomAvatar';
import { ActionsDropdown, CardSection, LabeledIcon, RoleChip } from '../../atoms';

export type ContactInformationsProps = {
    prescriberHasB2BAccess: boolean;
    contact: ContactTiersRead;
    openForm: (c: ContactTiersRead) => void;
    sx?: SxProps;
};

const ContactInformations: React.FunctionComponent<ContactInformationsProps> = function (props) {
    const { prescriberHasB2BAccess, contact, openForm, sx } = props;
    const [isExit, setIsExit] = useState(contact.isExit);
    const [isUpdating, setIsUpdating] = useState(false);
    const {
        civilite,
        prenomContact,
        nomContact,
        fonction,
        note,
        principal,
        comptabilite,
        administrateur,
    } = contact;

    const { contactsApi } = useTiersService();
    const theme = useTheme();
    const xIdSociete = useSociete();

    const updateForm = () => openForm(contact);

    const toggleKeycloakAccess = () => {
        const contactId = contact.id;
        if (!contactId || isUpdating) {
            return;
        }

        setIsUpdating(true);
        const contactPayload: ContactsContactJsonldContactUpdate = {
            civilite,
            prenomContact,
            nomContact,
            fonction,
            note,
            principal,
            comptabilite,
            isExit: !isExit,
            administrateur,
        };

        contactsApi
            .updateContactContactsItem({
                xIdSociete,
                id: contactId.toString(),
                contactsContactJsonldContactUpdate: contactPayload,
            })
            .then((result) => {
                setIsExit(result.data.isExit);
                setIsUpdating(false);
            })
            .catch((error: AxiosError) => {
                setIsUpdating(false);
                if (error.response) {
                    enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
                }
            });
    };

    const accessAction = {
        icon: faLock,
        label: `${isExit ? 'A' : 'Désa'}ctiver accès`,
        disabled: isUpdating,
        onClick: toggleKeycloakAccess,
    };

    const actions = [{ icon: faPen, label: 'Éditer le contact', onClick: updateForm }];
    if (prescriberHasB2BAccess) {
        actions.push(accessAction);
    }

    const fullName = `${contact.prenomContact} ${contact.nomContact}`;
    const role = 'Formaliste';
    const mail = contact.emailsContact[0]?.email;
    const phone = contact.telephonesContact[0]?.telephone;

    const isAdmin = contact.administrateur;
    const isBilling = contact.comptabilite;
    const isMain = contact.principal;

    const avatarSize = 40;
    const avatarStyle = {
        width: avatarSize,
        height: avatarSize,
        marginTop: '10px',
        marginRight: '15px',
        borderRadius: '40px',
    };

    const lastNoteStyle = { borderRadius: '15px', padding: '13px', backgroundColor: '#f2f3f6' };
    const deletedIcon = (
        <FaOptionIcon
            sx={{ fontSize: '40px' }}
            icon={faClose}
        />
    );

    const avatar = (
        <CustomAvatar
            firstName={contact.prenomContact}
            lastName={contact.nomContact}
            sx={avatarStyle}
        />
    );

    const transparent = 'rgba(0,0,0,0)';
    const contactCardStyle = {
        marginY: '8px',
        display: 'flex',
        border: '1px solid lightgray',
        backgroundColor: isExit ? transparent : theme.palette.background.paper,
    };

    return (
        <CardSection
            header={null}
            sx={{ ...contactCardStyle, ...sx }}
        >
            <Box
                display="flex"
                sx={{
                    ...(isExit && {
                        opacity: 0.5,
                        height: '100%',
                        // UI deleted-style 2  background:
                        //     'repeating-linear-gradient( -45deg, #efefef, #efefef 20px, #ffffff 20px, #ffffff 150px )',
                    }),
                }}
            >
                <Box>{isExit ? deletedIcon : avatar}</Box>
                <Box>
                    <Box display="flex">
                        <Typography
                            fontWeight="600"
                            lineHeight="2"
                            sx={{
                                marginRight: '13px',
                            }}
                        >
                            {fullName}
                        </Typography>
                        <Box
                            display="flex"
                            sx={{ flexWrap: 'wrap' }}
                        >
                            {isMain && <RoleChip label="Principal" />}
                            {isBilling && <RoleChip label="Comptabilité" />}
                            {isAdmin && <RoleChip label="Administrateur" />}
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            opacity: '0.8',
                            marginBottom: '15px',
                            marginTop: '2px',
                        }}
                        fontSize={14}
                    >
                        {role}
                    </Typography>
                    {mail && (
                        <LabeledIcon
                            icon={faEnvelope}
                            label={mail}
                        />
                    )}
                    {phone && (
                        <LabeledIcon
                            icon={faPhone}
                            label={phone}
                        />
                    )}
                    {contact.note && contact.note !== '' && (
                        <Box sx={lastNoteStyle}>
                            <Box display="flex">
                                <Typography
                                    fontWeight="600"
                                    sx={{ marginRight: '5px', opacity: 0.8, marginBottom: '10px' }}
                                >
                                    Dernière note
                                </Typography>
                            </Box>
                            <Typography fontWeight="400">
                                {limitStringLength(contact.note, 79)}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={{ alignSelf: 'start' }}>
                    <ActionsDropdown actions={actions} />
                </Box>
            </Box>
        </CardSection>
    );
};

export default ContactInformations;
