import { Order } from '@europrocurement/l2d-redux-utils';

export type Orders = { [field: string]: 'asc' | 'desc' };
type OrderSchema = { [key: string]: string };

const capitalize = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);

/** Generic method to format orders
 *
 * @param  orders - orders defined.
 * @param  defaultOrders - default orders.
 * @param  ordersSchema - Mapping method used to map field name > order name if necessary.
 * @returns  The formated orders.
 * */
export const getOrderParameters = (
    orders: Array<Order>,
    defaultOrders: Orders,
    ordersSchema: OrderSchema = {},
) => {
    if (orders.length === 0) {
        return defaultOrders;
    }
    const prefix = 'order';
    return orders.reduce((acc, { field, value }) => {
        const keyName = ordersSchema[field as keyof typeof ordersSchema] ?? field;
        const key = `${prefix}${capitalize(keyName)}`;
        return {
            ...acc,
            [key]: value,
        };
    }, {});
};
