import { TypedRequiredFile } from '@europrocurement/flexy-components';
import {
    DossierJsonldDossierRead,
    FichiersDossierJsonldDocumentfileRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

interface DocToSendProps {
    dossier: DossierJsonldDossierRead;
    currentDocs: FichiersDossierJsonldDocumentfileRead[] | null;
    uploadingFiles: number[];
    onDropFileWrapper: (file: File, docType: string, idDoc: number) => void;
}

export const DocToSend: React.FunctionComponent<DocToSendProps> = function ({
    dossier,
    currentDocs,
    uploadingFiles,
    onDropFileWrapper,
}) {
    return (
        <>
            <Box sx={{ paddingTop: '40px' }}>
                <Typography
                    variant="l2dbodym"
                    fontWeight={700}
                >
                    Liste des documents à envoyer
                </Typography>
                <Typography
                    sx={{ marginLeft: '5px' }}
                    variant="l2dbodym"
                    color="text.secondary"
                >
                    (facultatif)
                </Typography>
            </Box>
            {dossier.documents
                ?.map((typedoc) => ({
                    ...typedoc,
                    libelle: typedoc.libelle || 'pas de libelle',
                    id: typedoc.id || -1,
                }))
                .map((requiredType) => {
                    if (currentDocs) {
                        const docOk = currentDocs?.find(
                            (item) =>
                                item.typeDocDossier && item.typeDocDossier.id === requiredType.id,
                        );
                        if (docOk) {
                            return (
                                <TypedRequiredFile
                                    {...requiredType}
                                    key={requiredType.id}
                                    isUploaded
                                    skipUploaderProvider
                                    pending={uploadingFiles.indexOf(requiredType.id) !== -1}
                                    fileName={docOk.fichier || undefined}
                                />
                            );
                        }
                        return (
                            <TypedRequiredFile
                                {...requiredType}
                                key={requiredType.id}
                                skipUploaderProvider
                                pending={uploadingFiles.indexOf(requiredType.id) !== -1}
                                onDrop={(file: File) => {
                                    if (requiredType.document) {
                                        onDropFileWrapper(
                                            file,
                                            requiredType.document,
                                            requiredType.id,
                                        );
                                    }
                                }}
                            />
                        );
                    }
                    return (
                        <TypedRequiredFile
                            {...requiredType}
                            key={requiredType.id}
                            skipUploaderProvider
                            pending={uploadingFiles.indexOf(requiredType.id) !== -1}
                            onDrop={(file: File) => {
                                if (requiredType.document) {
                                    onDropFileWrapper(file, requiredType.document, requiredType.id);
                                }
                            }}
                        />
                    );
                })}
        </>
    );
};

export default DocToSend;
