import React from 'react';
import { Box } from '@mui/material';
import FlexyFilters from '@europrocurement/flexy-datatable/components/FlexyFilters/FlexyFilters';
import { SearchOpts } from '@europrocurement/flexy-datatable';

export type CompatibilitiesSearchProps = {
    searchOpts: SearchOpts;
};

const CompatibilitiesSearch: React.FunctionComponent<CompatibilitiesSearchProps> = function (
    props,
) {
    const { searchOpts } = props;

    return (
        <Box sx={{ marginBottom: '25px' }}>
            <FlexyFilters searchOpts={searchOpts} />
        </Box>
    );
};

export default CompatibilitiesSearch;
