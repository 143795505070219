import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { CustomAvatar } from '@europrocurement/flexy-components';
import { User } from '../UserTabs/type';

export type HeaderUser = Pick<User, 'prenom' | 'nom' | 'id' | 'isDeleted' | 'initiales'>;

export type UserHeaderProps = {
    user: HeaderUser;
};

const UserHeader: React.FunctionComponent<UserHeaderProps> = function ({ user }) {
    return (
        <Box
            display="flex"
            sx={{ margin: '0px 0px 18px 0px' }}
        >
            <CustomAvatar
                firstName={user.prenom}
                lastName={user.nom || 'Inconnu'}
                sx={{ width: 64, height: 64, marginRight: '15px', borderRadius: '8px' }}
            />
            <Box
                display="flex"
                flexDirection="column"
            >
                <Typography
                    variant="l2dh5"
                    sx={{ lineHeight: '1.4' }}
                >
                    {`${user.prenom} ${user.nom}`}

                    <Typography
                        data-testid="initiales"
                        variant="l2dh7"
                        component="span"
                        color="text.secondary"
                        sx={{ lineHeight: '1.4' }}
                    >
                        {user.initiales && `  (${user.initiales})`}
                    </Typography>

                    {user.isDeleted ? (
                        <Chip
                            sx={{ marginLeft: '8px' }}
                            color="default"
                            label="Compte désactivé"
                        />
                    ) : (
                        <Chip
                            sx={{ marginLeft: '8px' }}
                            color="primary"
                            label="Compte actif"
                        />
                    )}
                </Typography>
                <Typography
                    data-testid="idUser"
                    sx={{ lineHeight: '2' }}
                    variant="l2dbodys"
                    color="text.gris70"
                >
                    <b>Identifiant : </b>
                    {user.id ? `${user.id}` : 'non défini'}
                </Typography>
            </Box>
        </Box>
    );
};
export default UserHeader;
