import * as React from 'react';

import {
    Button,
    InputLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { hexToRgba } from '@europrocurement/l2d-utils';
import { deleteIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import { ModelItem } from '@europrocurement/l2d-domain';

export type EditableListItem = {
    id: number;
    ['@id']: string;
    entityType: string;
    content: unknown;
    label: string;
    model: ModelItem<unknown>;
    // Overidable field specs
    overridableField?: {
        label: string;
        name: string;
        value: unknown; // default value
    };
    // Overrided field
    [key: string]: unknown;
};

type ComponentProps = {
    item: EditableListItem;
    removeItem: (item: EditableListItem) => void;
    model: ModelItem<unknown>;
    setNewItemProperty?: (key: string, value: unknown) => void;
};

const AsyncEditableListItem: React.FunctionComponent<ComponentProps> = function (props) {
    const { item, removeItem, model, setNewItemProperty } = props;
    const { overridableField } = item;
    const itemHasOverridableField = !!overridableField;

    const { icon, getItemDefaultLabel, getItemSecondaryLabel } = model;

    // Hover effect
    const [onHover, setHover] = React.useState(true);
    const opacityFilter = onHover ? 0.05 : 0.01;
    const getBackgroundItem = () => {
        const hexColor = '#000000';
        return hexToRgba(hexColor, opacityFilter);
    };

    if (!item) {
        return null;
    }

    const secondaryLabel = getItemSecondaryLabel ? getItemSecondaryLabel(item) : '';

    const hoverProps = {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
    };

    return (
        <Box>
            <ListItem
                style={{
                    // cursor: onHover ? 'pointer' : 'inherit',
                    background: getBackgroundItem(),
                    transition: 'background 0.3s',
                    margin: '5px',
                }}
                {...hoverProps}
            >
                {icon && (
                    <ListItemIcon>
                        <FaOptionIcon
                            {...icon.props}
                            size="xl"
                        />
                    </ListItemIcon>
                )}

                <ListItemText
                    primary={getItemDefaultLabel(item)}
                    secondary={secondaryLabel}
                />

                {itemHasOverridableField && (
                    <>
                        <InputLabel sx={{ marginRight: '10px' }}>
                            {overridableField.label}
                        </InputLabel>
                        <TextField
                            name={overridableField.name}
                            value={item[overridableField.name]}
                            label=""
                            required={false}
                            onBlur={() => {}}
                            // sx={}
                            // //value={article.quantity}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                const { value } = event.currentTarget;
                                if (setNewItemProperty) {
                                    setNewItemProperty(overridableField.name, value);
                                }
                            }}
                        />
                    </>
                )}

                <Tooltip title="Supprimer l'élément">
                    <Button
                        color="secondary"
                        aria-label="ajout"
                        size="small"
                        sx={{
                            height: 'fit-content',
                        }}
                        onClick={() => removeItem(item)}
                    >
                        <FaOptionIcon
                            {...deleteIcon.props}
                            size="lg"
                        />
                    </Button>
                </Tooltip>
            </ListItem>
        </Box>
    );
};

export default AsyncEditableListItem;
