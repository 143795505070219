import {
    CatalogueCatalogueRead,
    TblPackageCatalogueRead,
    CatalogueJsonldCatalogueCreate,
    CatalogueJsonldCatalogueUpdate,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { DateTime } from 'luxon';
import { articleIcon } from '@europrocurement/l2d-icons';
import { formatPrice } from '@europrocurement/l2d-utils';
import { CatalogFormData } from '../Catalog/Form/validation';
import { ReadOnlyDocumentItem } from '../components/DocumentsList/types';

/** Format Catalog form values to valid creation | update payload */
export const formValuesToPayload = (
    formData: CatalogFormData,
): CatalogueJsonldCatalogueCreate | CatalogueJsonldCatalogueUpdate => {
    // Date typing to correct / refactor
    const startDate = formData.startDate as unknown as DateTime;
    const endDate = formData.endDate as unknown as DateTime;

    return {
        dateDebut: startDate.setLocale('fr').toFormat('yyyy-MM-dd'),
        dateFin: endDate ? endDate.setLocale('fr').toFormat('yyyy-MM-dd') : null,
        tarif: null, // Stand by / v2
        offre: formData.offerId as unknown as string,
        origine: formData.originId,
        societe: formData.companyId,
        nomCommercial: formData.commercialName,
        package: formData.packageId,
        groupement: formData.groupId,
        surchargeArticles: formData.catalogArticlesOverrides.map((catalogOverride) => ({
            libelle: catalogOverride.label,
            tarif: catalogOverride.price,
            quantite: catalogOverride.quantity,
            relationArticle: catalogOverride.articleRelationId,
        })),
        documents: formData.documents.map(({ id }) => ({
            typeDocument: id,
        })),
        champsComplementaires: formData.additionalFields.map(({ id }) => ({
            champComplementaire: id,
        })),
        options: formData.options.map(({ id }) => ({
            option: id as unknown as string, // API Offre accept iri string or number plain identifier. Fix needed on API Offre side
        })),
        domaine: formData.domainId as unknown as string, // idem ...
    } as CatalogueJsonldCatalogueCreate | CatalogueJsonldCatalogueUpdate;
};

/** Collect all document and format them with related article label */
const formatDocumentsFromArticles: (
    packageArticles: TblPackageCatalogueRead['articles'],
) => ReadOnlyDocumentItem[] = (packageArticles) => {
    // Empty array as fallback value
    if (!packageArticles || packageArticles.length === 0) {
        return [];
    }

    const collectedAndFormattedDocuments = packageArticles
        ?.map((packageArticle) =>
            packageArticle.article?.documents?.map(({ typeDocument: articleDocument }) => {
                const articleLabel = packageArticle.article?.libelle ?? '';
                const document = {
                    label: (articleDocument?.libelle as string) ?? '',
                    subTitle: (articleDocument?.help as unknown as string) ?? '',
                    originEntityName: 'Article',
                    originName: articleLabel as string,
                    originIcon: articleIcon,
                    id: articleDocument?.id as number,
                };
                return document;
            }),
        )
        .flat();
    const result = collectedAndFormattedDocuments?.filter((data) => data !== undefined);

    return result ?? [];
};

const getCatalogArticlesOverride = (entity: CatalogueCatalogueRead) => {
    const catalogOverrides = entity.surchargeArticles ?? [];
    return catalogOverrides.map((catalogOverride) => {
        const { relationArticle } = catalogOverride;
        const {
            libelle: packageLabel,
            tarif: packagePrice,
            quantite: packageQuantity,
            article,
            id: relationshipId,
        } = relationArticle || {};
        const articleLabel = article?.libelle;
        const articlePrice = article?.prixUnitaire;

        const overridedOriginalPrice = packagePrice ?? articlePrice;
        const originalPrice = overridedOriginalPrice ? formatPrice(overridedOriginalPrice) : null;
        const overridedPrice = catalogOverride.tarif;
        const price = overridedPrice ? formatPrice(overridedPrice) : null;

        return {
            originalLabel: packageLabel ?? articleLabel,
            originalPrice,
            originalQuantity: packageQuantity ?? 1,
            articleRelationId: relationshipId as number,
            label: catalogOverride.libelle ?? null,
            price,
            quantity: catalogOverride.quantite ?? null,
        };
    });
};
export const ressourceToFormValues = (entity: CatalogueCatalogueRead): CatalogFormData => {
    // To remove all thoses forced type cast : Sync/Correct API Types generation

    const packageId = entity.package?.id as number;
    const domainId = entity.domaine?.id as number;
    const groupId = entity.groupement?.id as number;
    const offerId = entity.offre?.id as number;
    const originId = entity.origine?.id ?? null;
    const companyId = null as unknown as number; // Nullable in database,should be number | null
    const additionalFields =
        (entity.champsComplementaires?.map(
            (additionalField) => additionalField.champComplementaire,
        ) as unknown as { id: number }[]) ?? [];
    const documents =
        (entity.documents?.map((document) => document.typeDocument) as unknown as {
            id: number;
        }[]) ?? [];
    const options =
        (entity.options?.map((option) => option.option) as unknown as {
            id: number;
        }[]) ?? [];
    const packageArticles = entity.package?.articles ?? [];
    const startDate = DateTime.fromISO(entity.dateDebut as string) as unknown as { ts: number };
    const endDate = entity.dateFin
        ? (DateTime.fromISO(entity.dateFin) as unknown as { ts: number })
        : null;

    const catalogArticlesOverrides = getCatalogArticlesOverride(entity);

    const readOnlyDocuments = {
        articles: formatDocumentsFromArticles(packageArticles ?? []),
        options: [],
        formalities: [],
    };

    const formValues = {
        commercialName: entity.nomCommercial ?? '',
        packageId,
        domainId,
        groupId,
        additionalFields,
        options,
        documents,
        startDate,
        endDate,
        catalogArticlesOverrides,
        companyId,
        offerId,
        originId,
        readOnlyDocuments,
    };

    return formValues;
};
