import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { FaOptionIcon, productsIcon } from '@europrocurement/l2d-icons';
import { FlexyHeaderForm, FlexyInput } from '@europrocurement/flexy-components';
import { FactureAchat, RubriqueFacturation, TauxTvaApiObject } from '@europrocurement/l2d-domain';
import { FlexyForm, defaultGridProps, inputDefaultProps } from '@europrocurement/flexy-form';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { RootStateType } from '@b2d/redux/types';
import useLoadingStatus from '@b2d/hooks/useLoading';
import useSectionDataManager from '@b2d/hooks/useSectionDataManager';
import { FactureFormObject, LigneProduit, RSFRubFac, ToggableSection } from '../../types';
import { findRubFactById } from '../../functions/produitFunctions';
import { ModeProps } from '../../formElements/types';
import productsArrayStructure from '../../formElements/fragments/productsSection/productsArrayStructure';
import HeaderStructure from '../../../fragments/HeaderStructure';
import SkeletonLoader from '../../../SkeletonLoader';
import {
    compareApiLines,
    compareProduits,
    convertToNumberAndFormatToString,
    updateFormTotaux,
} from '../../functions/calculsProduits';

export type UpdateProductsSectionFormProps = {
    invoice: FactureAchat;
    lines: Array<FactureAchatLigneJsonldFactureAchatRead>;
    onFetchNewLines: () => void;
} & ToggableSection;

const UpdateProductsSectionForm: React.FunctionComponent<UpdateProductsSectionFormProps> =
    function ({ invoice, lines, onFetchNewLines, switchLockValues }) {
        const { getSwitchLockValues, stateSwitchLockValues } = switchLockValues;

        const [isLoading, setIsLoading] = useState<boolean>(false);

        const invoiceCategoriesDataSource: DataSource<RubriqueFacturation> = useSelector(
            (s: RootStateType) => s.dossiers.rubfac.main,
            _.isEqual,
        );

        const vatRatesDataSource: DataSource<TauxTvaApiObject> = useSelector(
            (s: RootStateType) => s.dossiers.txtva.main,
            _.isEqual,
        );

        const invoiceCategories = useMemo(
            () => [...invoiceCategoriesDataSource.data, RSFRubFac],
            [invoiceCategoriesDataSource.data],
        );

        const vatRates = useMemo<Array<number>>(
            () =>
                vatRatesDataSource.data
                    .filter(
                        (vatRate) => vatRate.tauxAchat !== null && vatRate.tauxAchat !== undefined,
                    )
                    .map((vatRate) =>
                        Number.parseFloat(convertToNumberAndFormatToString(vatRate.tauxAchat)),
                    ),
            [vatRatesDataSource.data],
        );

        const productsMode = useMemo<ModeProps>(
            () => ({ type: 'update', lock: stateSwitchLockValues.products.value }),
            [stateSwitchLockValues.products.value],
        );

        const productsStructure = useMemo(
            () =>
                productsArrayStructure({
                    rubFacts: invoiceCategories,
                    txTva: vatRates,
                    mode: productsMode,
                    handleTotalsLock: (formContextOnChangeInput) => {
                        updateFormTotaux({
                            formContext: formContextOnChangeInput,
                        });
                    },
                }),
            [invoiceCategories, productsMode, vatRates],
        );

        const formatApiLineToFormLine = useCallback(
            (line: FactureAchatLigneJsonldFactureAchatRead): Partial<LigneProduit> => {
                const productLine: Partial<LigneProduit> = {
                    idLigne: line.id,
                    rubriqueFacturation: {
                        id: 0,
                        code: undefined,
                    },
                    ht: 0,
                    txtva: {
                        label: 0,
                        value: 0,
                    },
                    tva: '',
                    ttc: '',
                };

                if (line.details && line.details.length > 0) {
                    if (line.details[0].idRubriqueFacturation) {
                        const foundRubFact = findRubFactById(
                            invoiceCategories,
                            line.details[0].idRubriqueFacturation,
                        );

                        if (foundRubFact && foundRubFact.id && foundRubFact.code) {
                            productLine.rubriqueFacturation = {
                                id: foundRubFact.id,
                                code: foundRubFact.code,
                            };
                        }

                        if (line.details[0].ht) {
                            productLine.ht = parseFloat(line.details[0].ht);
                        }

                        if (!!line.details[0].tauxTva || line.details[0].tauxTva === 0) {
                            productLine.txtva = {
                                label:
                                    line.details[0].tauxTva !== 0
                                        ? line.details[0].tauxTva / 100
                                        : 0,
                                value:
                                    line.details[0].tauxTva !== 0
                                        ? line.details[0].tauxTva / 100
                                        : 0,
                            };
                        }

                        if (line.details[0].tva) {
                            productLine.tva = convertToNumberAndFormatToString(line.details[0].tva);
                        }

                        if (line.details[0].ht && line.details[0].tva) {
                            productLine.ttc = convertToNumberAndFormatToString(
                                parseFloat(line.details[0].ht) + parseFloat(line.details[0].tva),
                            );
                        }
                    }
                }

                return productLine;
            },
            [invoiceCategories],
        );

        const linesSort = lines.sort(compareApiLines);

        const formObject = useMemo<Partial<FactureFormObject>>(
            () => ({
                produits: linesSort
                    .map((line) => formatApiLineToFormLine(line))
                    .sort(compareProduits),
            }),
            [formatApiLineToFormLine, linesSort],
        );

        const { products: productsSection } = useSectionDataManager();
        const { updateProducts } = productsSection();

        const handleSubmit = useCallback<SubmitHandler<Pick<FactureFormObject, 'produits'>>>(
            async (values) => {
                setIsLoading(true);

                updateProducts({
                    invoice,
                    lines,
                    newProducts: values,
                    onFetchNewLines,
                }).finally(() => {
                    stateSwitchLockValues.products.set(true);

                    setIsLoading(false);
                });
            },
            [invoice, lines, onFetchNewLines, stateSwitchLockValues.products, updateProducts],
        );

        const formatLineValues = useCallback(
            (line: FactureAchatLigneJsonldFactureAchatRead) => {
                const lineValues = {
                    rubrique: 'N/A',
                    ht: 'N/A',
                    txTva: 'N/A',
                    tva: 'N/A',
                    ttc: 'N/A',
                };

                if (line.details && line.details.length > 0) {
                    if (line.details[0].idRubriqueFacturation) {
                        const foundRubFact = findRubFactById(
                            invoiceCategories,
                            line.details[0].idRubriqueFacturation,
                        );

                        if (foundRubFact && foundRubFact.code) {
                            lineValues.rubrique = foundRubFact.code;
                        }
                    }

                    if (line.details[0].ht) {
                        lineValues.ht = convertToNumberAndFormatToString(line.details[0].ht);
                    }

                    if (line.details[0].tauxTva || line.details[0].tauxTva === 0) {
                        lineValues.txTva =
                            line.details[0].tauxTva !== 0
                                ? (line.details[0].tauxTva / 100).toString()
                                : '0';
                    }

                    if (line.details[0].tva) {
                        lineValues.tva = convertToNumberAndFormatToString(line.details[0].tva);
                    }

                    if (line.details[0].ht && line.details[0].tva) {
                        lineValues.ttc = convertToNumberAndFormatToString(
                            parseFloat(line.details[0].ht) + parseFloat(line.details[0].tva),
                        );
                    }
                }

                return lineValues;
            },
            [invoiceCategories],
        );

        const readyToDisplay = useMemo<boolean>(
            () => !!(!isLoading && invoice && lines && lines.length > 0),
            [invoice, isLoading, lines],
        );

        const { loading } = useLoadingStatus({
            checkReady: () => readyToDisplay,
        });

        return (
            <SkeletonLoader
                isLoading={loading}
                type="FormSection"
            >
                <>
                    <FlexyHeaderForm
                        label={
                            <HeaderStructure
                                icon={
                                    <FaOptionIcon
                                        {...productsIcon.props}
                                        size="sm"
                                    />
                                }
                                title={productsIcon.displayName}
                            />
                        }
                        switches={[
                            ...(!invoice?.syncCegid
                                ? [getSwitchLockValues({ sectionToBlock: 'products' })]
                                : []),
                        ]}
                        outlined
                    />
                    {stateSwitchLockValues.products.value ? (
                        <Box sx={{ marginTop: '10px' }}>
                            {linesSort.map((line) => {
                                const formattedLine = formatLineValues(line);

                                return (
                                    <Grid
                                        key={`line-${line.id}-${line.details ? line.details[0].id : 'no-details'}`}
                                        container
                                        {...defaultGridProps}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="rubriqueFacturation"
                                                type="displayData"
                                                inputlabel="Rubrique"
                                                value={formattedLine.rubrique}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="ht"
                                                type="displayData"
                                                inputlabel="HT"
                                                value={`${formattedLine.ht} €`}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="txtva"
                                                type="displayData"
                                                inputlabel="Taux TVA"
                                                value={`${formattedLine.txTva} %`}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="tva"
                                                type="displayData"
                                                inputlabel="TVA"
                                                value={`${formattedLine.tva} €`}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={12 / 5}
                                        >
                                            <FlexyInput
                                                {...inputDefaultProps}
                                                variant="outlined"
                                                name="ttc"
                                                type="displayData"
                                                inputlabel="TTC"
                                                value={`${formattedLine.ttc} €`}
                                                readOnly
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Box>
                    ) : (
                        <FlexyForm
                            onSubmit={handleSubmit}
                            formObject={formObject}
                            formStructure={productsStructure}
                        />
                    )}
                </>
            </SkeletonLoader>
        );
    };

export default UpdateProductsSectionForm;
