import { useUploader } from '@europrocurement/flexy-components';
import { Box, Typography } from '@mui/material';
import React from 'react';

export function useFileUploadProps() {
    const { addFiles } = useUploader();

    return {
        btnText: 'Sélectionnez',
        dropText: ' ou glissez vos fichiers ici',
        formatInfo: '.pdf .doc .jpg .xls',
        disableFormat: true,
        maxMbSize: 10,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target.files?.length > 0) {
                addFiles(event.target.files);
            }
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            addFiles(event.dataTransfer.files);
        },
        accept: 'application/pdf,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        additionalInfo: (
            <Box sx={{ marginTop: '-10px', textAlign: 'center' }}>
                <Typography
                    variant="l2dbodyxs"
                    sx={{ fontWeight: 700 }}
                    color="text.secondary"
                >
                    Gagnez du temps :
                </Typography>
                <Typography
                    variant="l2dbodyxs"
                    sx={{ fontWeight: 500 }}
                    color="text.secondary"
                >
                    {' '}
                    déposez ici tous les documents nécessaires,
                </Typography>
                <Typography
                    component="p"
                    variant="l2dbodyxs"
                    color="text.secondary"
                >
                    nous les trierons pour vous.
                </Typography>
            </Box>
        ),
    };
}
