/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Mail
 * API d\'envoi de mails
 *
 * The version of the OpenAPI document: 1.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface MailAttachmentJsonldMailRead
 */
export interface MailAttachmentJsonldMailRead {
    /**
     * 
     * @type {MailJsonldMailReadContext}
     * @memberof MailAttachmentJsonldMailRead
     */
    '@context'?: MailJsonldMailReadContext;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MailAttachmentJsonldMailRead
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MailAttachmentJsonldMailRead
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface MailAttachmentMailRead
 */
export interface MailAttachmentMailRead {
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MailAttachmentMailRead
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MailAttachmentMailRead
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface MailJsonldMailRead
 */
export interface MailJsonldMailRead {
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {MailJsonldMailReadContext}
     * @memberof MailJsonldMailRead
     */
    '@context'?: MailJsonldMailReadContext;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'tos': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'ccs': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'bccs': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'replies': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'subject': string | null;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'body': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'tags': Array<string> | null;
    /**
     * 
     * @type {Array<MailAttachmentJsonldMailRead>}
     * @memberof MailJsonldMailRead
     */
    'attachments'?: Array<MailAttachmentJsonldMailRead>;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'status'?: string | null;
}
/**
 * @type MailJsonldMailReadContext
 * @export
 */
export type MailJsonldMailReadContext = MailJsonldMailReadContextOneOf | string;

/**
 * 
 * @export
 * @interface MailJsonldMailReadContextOneOf
 */
export interface MailJsonldMailReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailReadContextOneOf
     */
    'hydra': MailJsonldMailReadContextOneOfHydraEnum;
}

export const MailJsonldMailReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type MailJsonldMailReadContextOneOfHydraEnum = typeof MailJsonldMailReadContextOneOfHydraEnum[keyof typeof MailJsonldMailReadContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface MailJsonldMailSend
 */
export interface MailJsonldMailSend {
    /**
     * 
     * @type {MailJsonldMailReadContext}
     * @memberof MailJsonldMailSend
     */
    '@context'?: MailJsonldMailReadContext;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'tos': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'ccs': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'bccs': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'replies': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    'subject': string | null;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    'body': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'tags': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface MailMailRead
 */
export interface MailMailRead {
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'tos': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'ccs': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'bccs': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'replies': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'subject': string | null;
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'body': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'tags': Array<string> | null;
    /**
     * 
     * @type {Array<MailAttachmentMailRead>}
     * @memberof MailMailRead
     */
    'attachments'?: Array<MailAttachmentMailRead>;
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface MailMailSend
 */
export interface MailMailSend {
    /**
     * 
     * @type {string}
     * @memberof MailMailSend
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof MailMailSend
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'tos': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'ccs': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'bccs': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'replies': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MailMailSend
     */
    'subject': string | null;
    /**
     * 
     * @type {string}
     * @memberof MailMailSend
     */
    'body': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'tags': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MailMailSend
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface QueuedMailsMailCollection200Response
 */
export interface QueuedMailsMailCollection200Response {
    /**
     * 
     * @type {Array<MailJsonldMailRead>}
     * @memberof QueuedMailsMailCollection200Response
     */
    'hydra:member': Array<MailJsonldMailRead>;
    /**
     * 
     * @type {number}
     * @memberof QueuedMailsMailCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {QueuedMailsMailCollection200ResponseHydraView}
     * @memberof QueuedMailsMailCollection200Response
     */
    'hydra:view'?: QueuedMailsMailCollection200ResponseHydraView;
    /**
     * 
     * @type {QueuedMailsMailCollection200ResponseHydraSearch}
     * @memberof QueuedMailsMailCollection200Response
     */
    'hydra:search'?: QueuedMailsMailCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface QueuedMailsMailCollection200ResponseHydraSearch
 */
export interface QueuedMailsMailCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<QueuedMailsMailCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof QueuedMailsMailCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<QueuedMailsMailCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface QueuedMailsMailCollection200ResponseHydraSearchHydraMappingInner
 */
export interface QueuedMailsMailCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QueuedMailsMailCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface QueuedMailsMailCollection200ResponseHydraView
 */
export interface QueuedMailsMailCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueuedMailsMailCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface SendQueuedMailsMailCollection200Response
 */
export interface SendQueuedMailsMailCollection200Response {
    /**
     * 
     * @type {string}
     * @memberof SendQueuedMailsMailCollection200Response
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof SendQueuedMailsMailCollection200Response
     */
    'total_sent'?: number;
    /**
     * 
     * @type {number}
     * @memberof SendQueuedMailsMailCollection200Response
     */
    'total_errors'?: number;
}
/**
 * 
 * @export
 * @interface SendQueuedMailsMailCollection500Response
 */
export interface SendQueuedMailsMailCollection500Response {
    /**
     * 
     * @type {string}
     * @memberof SendQueuedMailsMailCollection500Response
     */
    'error'?: string;
}

/**
 * MailApi - axios parameter creator
 * @export
 */
export const MailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Mail resource.
         * @summary Removes the Mail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMailItem', 'id', id)
            const localVarPath = `/mail/mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a mail entry from DynamoDB based on its ID.
         * @summary Delete a queued mail
         * @param {string} id The unique identifier of the mail to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQueuedMailMailCollection: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteQueuedMailMailCollection', 'id', id)
            const localVarPath = `/mail/delete-queued-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Mail resource.
         * @summary Retrieves a Mail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMailItem', 'id', id)
            const localVarPath = `/mail/mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Mail resource.
         * @summary Updates the Mail resource.
         * @param {string} id Resource identifier
         * @param {MailMailSend} mailMailSend The updated Mail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailItem: async (id: string, mailMailSend: MailMailSend, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchMailItem', 'id', id)
            // verify required parameter 'mailMailSend' is not null or undefined
            assertParamExists('patchMailItem', 'mailMailSend', mailMailSend)
            const localVarPath = `/mail/mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailMailSend, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Mail resource.
         * @summary Replaces the Mail resource.
         * @param {string} id Resource identifier
         * @param {MailJsonldMailSend} mailJsonldMailSend The updated Mail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailItem: async (id: string, mailJsonldMailSend: MailJsonldMailSend, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putMailItem', 'id', id)
            // verify required parameter 'mailJsonldMailSend' is not null or undefined
            assertParamExists('putMailItem', 'mailJsonldMailSend', mailJsonldMailSend)
            const localVarPath = `/mail/mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailJsonldMailSend, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Mail resources.
         * @summary Récupérer la liste des mails en attente
         * @param {Array<'pending' | 'sent' | 'error'>} [statuses] Statuts des mails à remonter
         * @param {Array} [tags] Liste des tags pour filtrer les mails
         * @param {'asc' | 'desc'} [orderId] Trier par ID (asc ou desc)
         * @param {'asc' | 'desc'} [orderStatus] Trier par statut (asc ou desc)
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queuedMailsMailCollection: async (statuses?: Array<'pending' | 'sent' | 'error'>, tags?: Array, orderId?: 'asc' | 'desc', orderStatus?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/queued-mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses.join(COLLECTION_FORMATS.csv);
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Click the link to view the rendered HTML template in a new browser window.
         * @summary Render a Twig template
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renderTemplateMailCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/render-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a synchronous e-mail by directly setting up mailer, then send e-mail and finally register a new entry in DynamoDB.
         * @summary Send a synchronous e-mail
         * @param {MailMailSend} [details] 
         * @param {Array<File>} [attachments] Attachments list, you must name them attachments0, attachments1, etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailMailCollection: async (details?: MailMailSend, attachments?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/send-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


            if (details !== undefined) { 
                localVarFormParams.append('details', new Blob([JSON.stringify(details)], { type: "application/json", }));
            }
                if (attachments) {
                attachments.forEach((element) => {
                    localVarFormParams.append('attachments', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes and sends all pending mails in the queue. Optionally supports parallel processing via AWS Lambda.
         * @summary Send all queued mails
         * @param {boolean} [parallelProcessing] Set to \&#39;true\&#39; to enable AWS Lambda parallel execution. Default is \&#39;false\&#39;.
         * @param {Array} [tags] Tags to filter mails for sending. Only mails containing ALL specified tags will be processed.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQueuedMailsMailCollection: async (parallelProcessing?: boolean, tags?: Array, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/send-queued-mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (parallelProcessing !== undefined) {
                localVarQueryParameter['parallelProcessing'] = parallelProcessing;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add an e-mail to the queue by defining a status to \'pending\'.
         * @summary Add an e-mail to the queue
         * @param {MailMailSend} [details] 
         * @param {Array<File>} [attachments] Attachments list, you must name them attachments0, attachments1, etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToQueueMailCollection: async (details?: MailMailSend, attachments?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/send-to-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


            if (details !== undefined) { 
                localVarFormParams.append('details', new Blob([JSON.stringify(details)], { type: "application/json", }));
            }
                if (attachments) {
                attachments.forEach((element) => {
                    localVarFormParams.append('attachments', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailApi - functional programming interface
 * @export
 */
export const MailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Mail resource.
         * @summary Removes the Mail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMailItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a mail entry from DynamoDB based on its ID.
         * @summary Delete a queued mail
         * @param {string} id The unique identifier of the mail to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQueuedMailMailCollection(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQueuedMailMailCollection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Mail resource.
         * @summary Retrieves a Mail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailJsonldMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Mail resource.
         * @summary Updates the Mail resource.
         * @param {string} id Resource identifier
         * @param {MailMailSend} mailMailSend The updated Mail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMailItem(id: string, mailMailSend: MailMailSend, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailJsonldMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMailItem(id, mailMailSend, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Mail resource.
         * @summary Replaces the Mail resource.
         * @param {string} id Resource identifier
         * @param {MailJsonldMailSend} mailJsonldMailSend The updated Mail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailItem(id: string, mailJsonldMailSend: MailJsonldMailSend, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailJsonldMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailItem(id, mailJsonldMailSend, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Mail resources.
         * @summary Récupérer la liste des mails en attente
         * @param {Array<'pending' | 'sent' | 'error'>} [statuses] Statuts des mails à remonter
         * @param {Array} [tags] Liste des tags pour filtrer les mails
         * @param {'asc' | 'desc'} [orderId] Trier par ID (asc ou desc)
         * @param {'asc' | 'desc'} [orderStatus] Trier par statut (asc ou desc)
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queuedMailsMailCollection(statuses?: Array<'pending' | 'sent' | 'error'>, tags?: Array, orderId?: 'asc' | 'desc', orderStatus?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueuedMailsMailCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queuedMailsMailCollection(statuses, tags, orderId, orderStatus, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Click the link to view the rendered HTML template in a new browser window.
         * @summary Render a Twig template
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renderTemplateMailCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueuedMailsMailCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderTemplateMailCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send a synchronous e-mail by directly setting up mailer, then send e-mail and finally register a new entry in DynamoDB.
         * @summary Send a synchronous e-mail
         * @param {MailMailSend} [details] 
         * @param {Array<File>} [attachments] Attachments list, you must name them attachments0, attachments1, etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMailMailCollection(details?: MailMailSend, attachments?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailJsonldMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMailMailCollection(details, attachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Processes and sends all pending mails in the queue. Optionally supports parallel processing via AWS Lambda.
         * @summary Send all queued mails
         * @param {boolean} [parallelProcessing] Set to \&#39;true\&#39; to enable AWS Lambda parallel execution. Default is \&#39;false\&#39;.
         * @param {Array} [tags] Tags to filter mails for sending. Only mails containing ALL specified tags will be processed.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendQueuedMailsMailCollection(parallelProcessing?: boolean, tags?: Array, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendQueuedMailsMailCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendQueuedMailsMailCollection(parallelProcessing, tags, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add an e-mail to the queue by defining a status to \'pending\'.
         * @summary Add an e-mail to the queue
         * @param {MailMailSend} [details] 
         * @param {Array<File>} [attachments] Attachments list, you must name them attachments0, attachments1, etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendToQueueMailCollection(details?: MailMailSend, attachments?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailJsonldMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendToQueueMailCollection(details, attachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailApi - factory interface
 * @export
 */
export const MailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailApiFp(configuration)
    return {
        /**
         * Removes the Mail resource.
         * @summary Removes the Mail resource.
         * @param {MailApiDeleteMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailItem(requestParameters: MailApiDeleteMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteMailItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a mail entry from DynamoDB based on its ID.
         * @summary Delete a queued mail
         * @param {MailApiDeleteQueuedMailMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQueuedMailMailCollection(requestParameters: MailApiDeleteQueuedMailMailCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteQueuedMailMailCollection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Mail resource.
         * @summary Retrieves a Mail resource.
         * @param {MailApiGetMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailItem(requestParameters: MailApiGetMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<MailJsonldMailRead> {
            return localVarFp.getMailItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Mail resource.
         * @summary Updates the Mail resource.
         * @param {MailApiPatchMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailItem(requestParameters: MailApiPatchMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<MailJsonldMailRead> {
            return localVarFp.patchMailItem(requestParameters.id, requestParameters.mailMailSend, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Mail resource.
         * @summary Replaces the Mail resource.
         * @param {MailApiPutMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailItem(requestParameters: MailApiPutMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<MailJsonldMailRead> {
            return localVarFp.putMailItem(requestParameters.id, requestParameters.mailJsonldMailSend, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Mail resources.
         * @summary Récupérer la liste des mails en attente
         * @param {MailApiQueuedMailsMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queuedMailsMailCollection(requestParameters: MailApiQueuedMailsMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueuedMailsMailCollection200Response> {
            return localVarFp.queuedMailsMailCollection(requestParameters.statuses, requestParameters.tags, requestParameters.orderId, requestParameters.orderStatus, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Click the link to view the rendered HTML template in a new browser window.
         * @summary Render a Twig template
         * @param {MailApiRenderTemplateMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renderTemplateMailCollection(requestParameters: MailApiRenderTemplateMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueuedMailsMailCollection200Response> {
            return localVarFp.renderTemplateMailCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Send a synchronous e-mail by directly setting up mailer, then send e-mail and finally register a new entry in DynamoDB.
         * @summary Send a synchronous e-mail
         * @param {MailApiSendMailMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailMailCollection(requestParameters: MailApiSendMailMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MailJsonldMailRead> {
            return localVarFp.sendMailMailCollection(requestParameters.details, requestParameters.attachments, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes and sends all pending mails in the queue. Optionally supports parallel processing via AWS Lambda.
         * @summary Send all queued mails
         * @param {MailApiSendQueuedMailsMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQueuedMailsMailCollection(requestParameters: MailApiSendQueuedMailsMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SendQueuedMailsMailCollection200Response> {
            return localVarFp.sendQueuedMailsMailCollection(requestParameters.parallelProcessing, requestParameters.tags, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Add an e-mail to the queue by defining a status to \'pending\'.
         * @summary Add an e-mail to the queue
         * @param {MailApiSendToQueueMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToQueueMailCollection(requestParameters: MailApiSendToQueueMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MailJsonldMailRead> {
            return localVarFp.sendToQueueMailCollection(requestParameters.details, requestParameters.attachments, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteMailItem operation in MailApi.
 * @export
 * @interface MailApiDeleteMailItemRequest
 */
export interface MailApiDeleteMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MailApiDeleteMailItem
     */
    readonly id: string
}

/**
 * Request parameters for deleteQueuedMailMailCollection operation in MailApi.
 * @export
 * @interface MailApiDeleteQueuedMailMailCollectionRequest
 */
export interface MailApiDeleteQueuedMailMailCollectionRequest {
    /**
     * The unique identifier of the mail to delete.
     * @type {string}
     * @memberof MailApiDeleteQueuedMailMailCollection
     */
    readonly id: string
}

/**
 * Request parameters for getMailItem operation in MailApi.
 * @export
 * @interface MailApiGetMailItemRequest
 */
export interface MailApiGetMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MailApiGetMailItem
     */
    readonly id: string
}

/**
 * Request parameters for patchMailItem operation in MailApi.
 * @export
 * @interface MailApiPatchMailItemRequest
 */
export interface MailApiPatchMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MailApiPatchMailItem
     */
    readonly id: string

    /**
     * The updated Mail resource
     * @type {MailMailSend}
     * @memberof MailApiPatchMailItem
     */
    readonly mailMailSend: MailMailSend
}

/**
 * Request parameters for putMailItem operation in MailApi.
 * @export
 * @interface MailApiPutMailItemRequest
 */
export interface MailApiPutMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MailApiPutMailItem
     */
    readonly id: string

    /**
     * The updated Mail resource
     * @type {MailJsonldMailSend}
     * @memberof MailApiPutMailItem
     */
    readonly mailJsonldMailSend: MailJsonldMailSend
}

/**
 * Request parameters for queuedMailsMailCollection operation in MailApi.
 * @export
 * @interface MailApiQueuedMailsMailCollectionRequest
 */
export interface MailApiQueuedMailsMailCollectionRequest {
    /**
     * Statuts des mails à remonter
     * @type {Array<'pending' | 'sent' | 'error'>}
     * @memberof MailApiQueuedMailsMailCollection
     */
    readonly statuses?: Array<'pending' | 'sent' | 'error'>

    /**
     * Liste des tags pour filtrer les mails
     * @type {Array}
     * @memberof MailApiQueuedMailsMailCollection
     */
    readonly tags?: Array

    /**
     * Trier par ID (asc ou desc)
     * @type {'asc' | 'desc'}
     * @memberof MailApiQueuedMailsMailCollection
     */
    readonly orderId?: 'asc' | 'desc'

    /**
     * Trier par statut (asc ou desc)
     * @type {'asc' | 'desc'}
     * @memberof MailApiQueuedMailsMailCollection
     */
    readonly orderStatus?: 'asc' | 'desc'

    /**
     * The collection page number
     * @type {number}
     * @memberof MailApiQueuedMailsMailCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MailApiQueuedMailsMailCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for renderTemplateMailCollection operation in MailApi.
 * @export
 * @interface MailApiRenderTemplateMailCollectionRequest
 */
export interface MailApiRenderTemplateMailCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof MailApiRenderTemplateMailCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MailApiRenderTemplateMailCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for sendMailMailCollection operation in MailApi.
 * @export
 * @interface MailApiSendMailMailCollectionRequest
 */
export interface MailApiSendMailMailCollectionRequest {
    /**
     * 
     * @type {MailMailSend}
     * @memberof MailApiSendMailMailCollection
     */
    readonly details?: MailMailSend

    /**
     * Attachments list, you must name them attachments0, attachments1, etc.
     * @type {Array<File>}
     * @memberof MailApiSendMailMailCollection
     */
    readonly attachments?: Array<File>
}

/**
 * Request parameters for sendQueuedMailsMailCollection operation in MailApi.
 * @export
 * @interface MailApiSendQueuedMailsMailCollectionRequest
 */
export interface MailApiSendQueuedMailsMailCollectionRequest {
    /**
     * Set to \&#39;true\&#39; to enable AWS Lambda parallel execution. Default is \&#39;false\&#39;.
     * @type {boolean}
     * @memberof MailApiSendQueuedMailsMailCollection
     */
    readonly parallelProcessing?: boolean

    /**
     * Tags to filter mails for sending. Only mails containing ALL specified tags will be processed.
     * @type {Array}
     * @memberof MailApiSendQueuedMailsMailCollection
     */
    readonly tags?: Array

    /**
     * The collection page number
     * @type {number}
     * @memberof MailApiSendQueuedMailsMailCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MailApiSendQueuedMailsMailCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for sendToQueueMailCollection operation in MailApi.
 * @export
 * @interface MailApiSendToQueueMailCollectionRequest
 */
export interface MailApiSendToQueueMailCollectionRequest {
    /**
     * 
     * @type {MailMailSend}
     * @memberof MailApiSendToQueueMailCollection
     */
    readonly details?: MailMailSend

    /**
     * Attachments list, you must name them attachments0, attachments1, etc.
     * @type {Array<File>}
     * @memberof MailApiSendToQueueMailCollection
     */
    readonly attachments?: Array<File>
}

/**
 * MailApi - object-oriented interface
 * @export
 * @class MailApi
 * @extends {BaseAPI}
 */
export class MailApi extends BaseAPI {
    /**
     * Removes the Mail resource.
     * @summary Removes the Mail resource.
     * @param {MailApiDeleteMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public deleteMailItem(requestParameters: MailApiDeleteMailItemRequest, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).deleteMailItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a mail entry from DynamoDB based on its ID.
     * @summary Delete a queued mail
     * @param {MailApiDeleteQueuedMailMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public deleteQueuedMailMailCollection(requestParameters: MailApiDeleteQueuedMailMailCollectionRequest, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).deleteQueuedMailMailCollection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Mail resource.
     * @summary Retrieves a Mail resource.
     * @param {MailApiGetMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public getMailItem(requestParameters: MailApiGetMailItemRequest, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).getMailItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Mail resource.
     * @summary Updates the Mail resource.
     * @param {MailApiPatchMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public patchMailItem(requestParameters: MailApiPatchMailItemRequest, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).patchMailItem(requestParameters.id, requestParameters.mailMailSend, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Mail resource.
     * @summary Replaces the Mail resource.
     * @param {MailApiPutMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public putMailItem(requestParameters: MailApiPutMailItemRequest, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).putMailItem(requestParameters.id, requestParameters.mailJsonldMailSend, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Mail resources.
     * @summary Récupérer la liste des mails en attente
     * @param {MailApiQueuedMailsMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public queuedMailsMailCollection(requestParameters: MailApiQueuedMailsMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).queuedMailsMailCollection(requestParameters.statuses, requestParameters.tags, requestParameters.orderId, requestParameters.orderStatus, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Click the link to view the rendered HTML template in a new browser window.
     * @summary Render a Twig template
     * @param {MailApiRenderTemplateMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public renderTemplateMailCollection(requestParameters: MailApiRenderTemplateMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).renderTemplateMailCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a synchronous e-mail by directly setting up mailer, then send e-mail and finally register a new entry in DynamoDB.
     * @summary Send a synchronous e-mail
     * @param {MailApiSendMailMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public sendMailMailCollection(requestParameters: MailApiSendMailMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).sendMailMailCollection(requestParameters.details, requestParameters.attachments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes and sends all pending mails in the queue. Optionally supports parallel processing via AWS Lambda.
     * @summary Send all queued mails
     * @param {MailApiSendQueuedMailsMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public sendQueuedMailsMailCollection(requestParameters: MailApiSendQueuedMailsMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).sendQueuedMailsMailCollection(requestParameters.parallelProcessing, requestParameters.tags, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add an e-mail to the queue by defining a status to \'pending\'.
     * @summary Add an e-mail to the queue
     * @param {MailApiSendToQueueMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public sendToQueueMailCollection(requestParameters: MailApiSendToQueueMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).sendToQueueMailCollection(requestParameters.details, requestParameters.attachments, options).then((request) => request(this.axios, this.basePath));
    }
}


