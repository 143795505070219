import {
    faBell,
    faXmarkToSlot,
    faFileImport,
    faFileInvoice,
    faFileLines,
    faGauge,
    faReceipt,
} from '@fortawesome/pro-duotone-svg-icons';

import { faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
import { NamedIconsType } from '../../types/types';
import {
    bugIcon,
    checkIcon,
    manualActionIcon,
    pauseIcon,
    syncIcon,
    todoIcon,
    userIcon,
} from './general';

export const invoiceIcon: NamedIconsType = {
    name: 'invoice',
    displayName: 'Facture',
    props: {
        icon: faFileInvoice,
    },
};

export const quoteIcon: NamedIconsType = {
    name: 'quote',
    displayName: 'Devis',
    props: {
        icon: faFileLines,
    },
};

export const quoteLineIcon: NamedIconsType = {
    name: 'quoteLine',
    displayName: 'LigneDevis',
    props: {
        icon: faReceipt,
    },
};

export const addInvoiceIcon: NamedIconsType = {
    name: 'addInvoice',
    displayName: 'Ajout Factures',
    props: {
        icon: faFileImport,
    },
};

export const invoiceStatementIcon: NamedIconsType = {
    props: {
        icon: faFolderOpen,
        option: invoiceIcon.props.icon,
        iconProps: {
            style: {
                rotate: '90deg',
                scale: '0.9',
            },
        },
    },
    name: 'invoiceStatement',
    displayName: 'Relevé de facture',
};

export const todoInvoiceIcon: NamedIconsType = {
    props: {
        icon: invoiceIcon.props.icon,
        option: todoIcon.props.icon,
    },
    name: 'todoInvoice',
    displayName: 'Factures à traiter',
};

export const pausedInvoiceIcon: NamedIconsType = {
    props: {
        icon: invoiceIcon.props.icon,
        option: pauseIcon.props.icon,
        color: 'warning.main',
    },
    name: 'pausedInvoice',
    displayName: 'Factures en pause',
};

export const myInvoiceIcon: NamedIconsType = {
    props: {
        icon: invoiceIcon.props.icon,
        option: userIcon.props.icon,
    },
    name: 'myInvoice',
    displayName: 'Mes factures',
};

export const toSyncInvoiceIcon: NamedIconsType = {
    props: {
        icon: invoiceIcon.props.icon,
        option: syncIcon.props.icon,
        color: 'secondary.main',
        optionColor: 'secondary.dark',
    },
    name: 'toSyncInvoice',
    displayName: 'Factures à synchroniser',
};

export const errorInvoiceIcon: NamedIconsType = {
    props: {
        icon: invoiceIcon.props.icon,
        option: bugIcon.props.icon,
        color: 'danger.main',
        optionColor: 'danger.dark',
    },
    name: 'errorInvoice',
    displayName: 'Factures en erreurs',
};

export const doneInvoiceIcon: NamedIconsType = {
    props: {
        icon: invoiceIcon.props.icon,
        option: checkIcon.props.icon,
    },
    name: 'doneInvoice',
    displayName: 'Factures traitées',
};

export const pausedStatementIcon: NamedIconsType = {
    props: {
        ...invoiceStatementIcon.props,
        color: 'warning.main',
    },
    name: 'pausedStatement',
    displayName: 'Relevés non clôturés',
};

export const rejectCodesOnInvoicesIcon: NamedIconsType = {
    props: {
        icon: faXmarkToSlot,
        color: 'primary.main',
    },
    name: 'statsOnInvoices',
    displayName: 'Gestion des erreurs',
};

export const statsOnInvoicesIcon: NamedIconsType = {
    props: {
        icon: faGauge,
        color: 'primary.main',
    },
    name: 'statsOnInvoices',
    displayName: 'Gestion des erreurs',
};

export const remindersOnRejectsCodesIcon: NamedIconsType = {
    props: {
        icon: faBell,
        color: 'primary.main',
    },
    name: 'statsOnInvoices',
    displayName: 'Gestion des erreurs',
};

export const invoiceToTreatManuallyIcon: NamedIconsType = {
    props: {
        icon: invoiceIcon.props.icon,
        option: manualActionIcon.props.icon,
    },
    name: 'doneInvoice',
    displayName: 'Factures traitées',
};

export const invoiceNamedIcons: NamedIconsType[] = [
    invoiceIcon,
    todoInvoiceIcon,
    pausedInvoiceIcon,
    myInvoiceIcon,
    errorInvoiceIcon,
    toSyncInvoiceIcon,
    doneInvoiceIcon,
    addInvoiceIcon,
    invoiceStatementIcon,
    pausedStatementIcon,
    rejectCodesOnInvoicesIcon,
    statsOnInvoicesIcon,
    remindersOnRejectsCodesIcon,
    invoiceToTreatManuallyIcon,
    quoteIcon,
    quoteLineIcon,
];
