import React, { useState } from 'react';
import { Box, FormLabel, List, ListItem, Palette, PaletteColor, useTheme } from '@mui/material';

export type Color =
    | 'primary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'lightGreen'
    | 'red'
    | 'pink'
    | 'purple'
    | 'deepPurple'
    | 'amber'
    | 'blueGrey'
    | 'teal'
    | 'deepOrange'
    | 'brown'
    | 'cyan'
    | 'green'
    | 'indigo'
    | 'lightBlue'
    | 'lime'
    | 'orange'
    | 'yellow'
    | 'blue';

export type ColorSelectorProps = {
    label?: string;
    colors: Array<Color>;
    multiple?: boolean;
    required?: boolean;
    onSelect: (data?: Array<Color> | Color) => void;
};

const ColorSelector: React.FunctionComponent<ColorSelectorProps> = function (props) {
    const { label, colors, multiple = false, required = false, onSelect } = props;
    const [selectedColors, setSelectedColors] = useState<Array<Color>>([]);
    const { palette } = useTheme();

    if (!colors.length) {
        return null;
    }

    const handleSelectItem = (color: Color) => {
        const newColors = [...selectedColors];

        if (newColors.indexOf(color) >= 0) {
            newColors.splice(newColors.indexOf(color), 1);
        } else {
            newColors.unshift(color);
        }

        const filtered = newColors.filter((value, index, array) => array.indexOf(value) === index);

        if (multiple) {
            setSelectedColors(filtered);
            onSelect(filtered);
        } else if (filtered[0] !== undefined) {
            setSelectedColors([filtered[0]]);
            onSelect(filtered[0]);
        } else {
            setSelectedColors([]);
            onSelect();
        }
    };

    const handleSelectEmptyValue = () => {
        setSelectedColors([]);
        onSelect();
    };

    const listItemSx = {
        display: 'list-item',
        width: 'auto',
        padding: '2px',
        borderWidth: '2px',
        borderStyle: 'solid',
    };

    const boxSx = {
        width: '32px',
        height: '32px',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: '0.25s ease',
        '&:hover': {
            opacity: '0.75',
        },
    };

    return (
        <Box data-testid="color-selector">
            <FormLabel sx={{ display: 'block', marginBottom: '16px', color: palette.text.primary }}>
                {label}
            </FormLabel>
            <List
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '24px',
                    padding: '0 24px',
                }}
            >
                {!required && (
                    <ListItem
                        sx={{
                            ...listItemSx,
                            borderColor: !selectedColors.length ? 'primary.dark' : 'transparent',
                            backgroundColor: !selectedColors.length
                                ? 'primary.light'
                                : 'transparent',
                        }}
                    >
                        <Box
                            onClick={() => handleSelectEmptyValue()}
                            sx={{
                                ...boxSx,
                                backgroundColor: palette.grey[400],
                            }}
                        />
                    </ListItem>
                )}
                {colors.map((color) => {
                    const isSelected = selectedColors.find((c) => c === color);
                    const displayedColor = palette[color as keyof Palette] as PaletteColor;

                    return (
                        <ListItem
                            sx={{
                                ...listItemSx,
                                borderColor: isSelected ? 'primary.dark' : 'transparent',
                                backgroundColor: isSelected ? 'primary.light' : 'transparent',
                            }}
                            key={color}
                        >
                            <Box
                                onClick={() => handleSelectItem(color)}
                                sx={{
                                    ...boxSx,
                                    backgroundColor: displayedColor
                                        ? displayedColor.main
                                        : palette.grey[300],
                                }}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default ColorSelector;
