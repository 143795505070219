import React from 'react';
import { BooleanBadge, FlexySelect, OptionType } from '@europrocurement/flexy-components';
import { Card, Grid, TextField, Typography, useTheme } from '@mui/material';
import { UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { Box } from '@mui/system';
import { User } from '../UserTabs/type';

type UserPatch = UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur;

export type UserL2DFProps = {
    user: User;
    handlePatchData: (payload: UserPatch) => void;
    handleValidationModal: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => void;
};

type RightsSelectType = {
    key: keyof UserPatch;
    title: string;
    value?: number | null;
    options: Array<OptionType>;
};

export const UserL2DF: React.FunctionComponent<UserL2DFProps> = function ({
    user,
    handlePatchData,
    handleValidationModal,
}) {
    const theme = useTheme();

    const booleanRights = [
        {
            field: 'hasAccessL2DF',
            label: 'Accès à L2D Formalités',
            value: user.hasAccessL2DF || false,
        },
        { field: 'isFormalist', label: 'Formaliste', value: user.isFormalist || false },
        {
            field: 'formalistDemo',
            label: 'Compte de Démo',
            value: user.formalistDemo || false,
        },
    ];

    // Array to create all rights selects
    const rights: Array<RightsSelectType> = [
        {
            key: 'formalistAdmin',
            title: 'Droits - Administrateur',
            value: user.formalistAdmin || 0,
            options: [
                {
                    label: 'Aucun droit',
                    value: 0,
                },
                {
                    label: 'Utilisateur',
                    value: 1,
                },
                {
                    label: 'Administrateur',
                    value: 2,
                },
                {
                    label: 'Super Administrateur',
                    value: 3,
                },
            ],
        },
        {
            key: 'formalistDroitLectureGestion',
            title: 'Droits - Lecture de paramètres',
            value: user.formalistDroitLectureGestion || 0,
            options: [
                {
                    label: 'Aucun droit',
                    value: 0,
                },
                {
                    label: 'Lecture des paramètres de base',
                    value: 1,
                },
                {
                    label: 'Lecture intégrale des paramètres',
                    value: 2,
                },
            ],
        },
        {
            key: 'formalistDroitModifGestion',
            title: 'Droits - Modification de paramètres',
            value: user.formalistDroitModifGestion || 0,
            options: [
                {
                    label: 'Aucun droit',
                    value: 0,
                },
                {
                    label: 'Modification des paramètres de base',
                    value: 1,
                },
                {
                    label: 'Modification intégrale des paramètres',
                    value: 2,
                },
            ],
        },
        {
            key: 'formalistDroitLectureDossier',
            title: 'Droits - Lecture de dossier',
            value: user.formalistDroitLectureDossier || 0,
            options: [
                {
                    label: 'Aucun droit',
                    value: 0,
                },
                {
                    label: 'Lecture des dossiers du formaliste',
                    value: 1,
                },
                {
                    label: 'Lecture de tous les dossiers',
                    value: 2,
                },
            ],
        },
        {
            key: 'formalistDroitModifDossier',
            title: 'Droits - Modification de dossier',
            value: user.formalistDroitModifDossier || 0,
            options: [
                {
                    label: 'Aucun droit',
                    value: 0,
                },
                {
                    label: 'Modification des dossiers du formaliste',
                    value: 1,
                },
                {
                    label: 'Modification de tous les dossiers',
                    value: 2,
                },
            ],
        },
        {
            key: 'formalistDroitSupprDossier',
            title: 'Droits - Suppression de dossier',
            value: user.formalistDroitSupprDossier || 0,
            options: [
                {
                    label: 'Aucun droit',
                    value: 0,
                },
                {
                    label: 'Suppression des dossiers du formaliste',
                    value: 1,
                },
                {
                    label: 'Suppression de tous les dossiers',
                    value: 2,
                },
            ],
        },
    ];

    const handleMultiSocieteInput = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    ) => {
        const newValue = event.target.value;

        if (newValue !== '' && newValue !== undefined && newValue !== null) {
            // verify if the value has been changed before send it to the API
            if (newValue !== user.formalistDroitMultiSociete) {
                handlePatchData({ formalistDroitMultiSociete: newValue });
            }
        }
    };

    const handleIDUserInput = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    ) => {
        const newInputValue = event.target.value;

        // convert the string into integer to match to the API type
        const newIDValue = parseInt(newInputValue, 10);

        // check only if the data matches the type (null, undefined, empty and string won't pass)
        if (typeof newIDValue === 'number') {
            // verify if the value has been changed before send it to the API
            if (newIDValue !== user.idUserDematfacile) {
                handlePatchData({ idUserDematfacile: newIDValue });
            }
        }
    };

    const handleSelect = (option: OptionType, key: RightsSelectType['key']) => {
        handlePatchData({ [key]: option.value });
    };

    return (
        <Card
            sx={{
                width: '100%',
                margin: '15px 0',
                padding: '15px',
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Typography
                variant="l2dh6"
                sx={{
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                    padding: '5px 0 5px 30px',
                    marginLeft: '-15px',
                }}
            >
                Application L2D Formalités
            </Typography>
            <Grid
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '15px',
                    width: '100%',
                    margin: '15px 0 15px 10px',
                }}
            >
                {booleanRights.map(({ field, label, value }) => (
                    <BooleanBadge
                        key={field}
                        label={label}
                        value={value}
                        icon
                        toggleAction={() =>
                            handleValidationModal({ [field]: !value }, field, value)
                        }
                    />
                ))}
            </Grid>

            <Box
                sx={{
                    margin: '15px 0 15px 15px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '15px',
                    width: '100%',
                }}
            >
                <Box sx={{ width: '48%' }}>
                    <Typography sx={{ marginY: '10px' }}>
                        Sociétés accessibles sur L2D Formalités
                    </Typography>
                    <TextField
                        data-testid="l2df"
                        placeholder={
                            user.formalistDroitMultiSociete || 'ID des sociétés autorisées'
                        }
                        onBlur={(event) => handleMultiSocieteInput(event)}
                        fullWidth
                        defaultValue={user.formalistDroitMultiSociete}
                    />
                </Box>

                <Box sx={{ width: '48%' }}>
                    <Typography sx={{ marginY: '10px' }}>
                        ID utilisateur Dematfacile (L2D)
                    </Typography>
                    <TextField
                        data-testid="iddematfacile"
                        placeholder={`${user.idUserDematfacile}`}
                        onBlur={(event) => handleIDUserInput(event)}
                        fullWidth
                        defaultValue={user.idUserDematfacile}
                    />
                </Box>
                {rights.map(({ title, key, value, options }) => (
                    <Box
                        sx={{
                            width: '31%',
                        }}
                        key={key}
                        data-testid={key}
                    >
                        <Typography sx={{ marginY: '10px' }}>{title}</Typography>
                        <FlexySelect
                            placeholder="Choisir le type de droit"
                            onChange={(option: OptionType) => handleSelect(option, key)}
                            options={options}
                            value={options.find((option) => option.value === value)}
                        />
                    </Box>
                ))}
            </Box>
        </Card>
    );
};
