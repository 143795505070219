/**
 * Preview card component :
 * ------------------------
 * Used in Listing with preview component
 */

import * as React from 'react';
import { Card } from '@mui/material';
import { useOffreService } from '@europrocurement/l2d-domain';
import AdditionalFieldPreview from '../AdditionalField/PreviewCard';
import ArticlePreview from '../Article/PreviewCard';
import OfferPreview from '../Offer/PreviewCard';
import OfferOptionPreview from '../Option/PreviewCard';
import DocumentPreview from '../Document/PreviewCard';
import PackagePreview from '../Package/PreviewCard';
import CatalogPreview from '../Catalog/PreviewCard';
import GroupPreview from '../Group/PreviewCard';
import { AbstractJsonLdData } from '../forms/types';
import EmptyCardPreview from './EmptCardPreview';

type PreviewCardProps = {
    isPreviewLoading: boolean;
    isPreviewEmpty: boolean;
    selectedEntity: AbstractJsonLdData;
    onEdit: () => void;
};

const PreviewCard: React.FunctionComponent<PreviewCardProps> = function (props) {
    const {
        additionalFieldModel,
        articleModel,
        catalogModel,
        documentModel,
        offerModel,
        offerGroupModel,
        offerOptionModel,
        offerPackageModel,
    } = useOffreService();

    const { isPreviewLoading, isPreviewEmpty, selectedEntity, onEdit } = props;

    const previewCardStyle = {
        margin: '0 0 0 15px',
    };

    const entityType = selectedEntity?.['@type'];

    if (isPreviewEmpty || isPreviewLoading) {
        return (
            <Card sx={previewCardStyle}>
                <EmptyCardPreview loading={isPreviewLoading} />
            </Card>
        );
    }

    const getContentFromEntityType = (entityTypeString: string) => {
        switch (entityTypeString) {
            case additionalFieldModel.type:
                return (
                    <AdditionalFieldPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case offerOptionModel.type:
                return (
                    <OfferOptionPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );

            case offerModel.type:
                return (
                    <OfferPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case articleModel.type:
                return (
                    <ArticlePreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case documentModel.type:
                return (
                    <DocumentPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case offerGroupModel.type:
                return (
                    <GroupPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case offerPackageModel.type:
                return (
                    <PackagePreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case catalogModel.type:
                return (
                    <CatalogPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );

            default:
                return (
                    <p>
                        (aucune carte d&apos;aperçu n&apos;est défini pour : `${entityTypeString}`)
                    </p>
                );
        }
    };

    return <Card sx={previewCardStyle}>{getContentFromEntityType(entityType)}</Card>;
};

export default PreviewCard;
