import React, { useCallback, useMemo, useState } from 'react';

import { Dossier, DossierApiObject, FactureAchatApiObject } from '@europrocurement/l2d-domain';
import { FaOptionIcon, folioIcon } from '@europrocurement/l2d-icons';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import { FlexyForm, FormObject } from '@europrocurement/flexy-form';
import { RootStateType } from '@b2d/redux/types';
import { getAutocompleteDossier } from '@b2d/redux/RootStore';
import { SubmitHandler } from 'react-hook-form';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';

import { DataSource } from '@europrocurement/l2d-redux-utils';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import useLoadingStatus from '@b2d/hooks/useLoading';
import useSectionDataManager from '@b2d/hooks/useSectionDataManager';
import { ModeProps } from '../../formElements/types';
import { FactureFormObject, ToggableSection } from '../../types';
import ViewFolioPublicationSection from '../../formElements/fragments/ViewFolioPublicationSection';
import { folioInvoiceSectionStructure } from '../../formElements/fragments/folioInvoiceSection';
import HeaderStructure from '../../../fragments/HeaderStructure';
import SkeletonLoader from '../../../SkeletonLoader';

export type UpdateFolioPublicationSectionFormProps = {
    invoice: FactureAchatApiObject;
    lines: FactureAchatLigneJsonldFactureAchatRead[];
    folio: DossierApiObject;
    onFetchNewLines: () => void;
} & ToggableSection;

const UpdateFolioPublicationSectionForm: React.FunctionComponent<UpdateFolioPublicationSectionFormProps> =
    function ({ invoice, lines, folio, onFetchNewLines, switchLockValues }) {
        const { getSwitchLockValues, stateSwitchLockValues } = switchLockValues;

        const [isLoading, setIsLoading] = useState<boolean>(false);

        const folioMode = useMemo<ModeProps>(
            () => ({ type: 'update', lock: stateSwitchLockValues.folio.value }),
            [stateSwitchLockValues.folio.value],
        );

        const foliosDatasource: DataSource<Dossier> = useSelector(
            (s: RootStateType) => s.dossiers.dos.autocompleteDossiers,
            _.isEqual,
        );

        const folioPublicationStructure = useMemo(
            () =>
                folioInvoiceSectionStructure({
                    disabled: true,
                    mode: folioMode,
                    autocompleteProps: {
                        folio: {
                            dataSource: foliosDatasource,
                            fetchData: getAutocompleteDossier,
                            defaultValue: folio?.numero?.toString() || undefined,
                        },
                        provision: {
                            dataSource: foliosDatasource,
                            fetchData: getAutocompleteDossier,
                            defaultValue:
                                (folio?.prestations &&
                                    folio.prestations.length > 0 &&
                                    folio?.prestations[0]?.numero) ||
                                undefined,
                        },
                    },
                    onSelectOption: (event, newValue: Dossier, reason, details, formContext) => {
                        formContext.setValue('nom_annonceur', newValue.annonceur);
                        if (newValue.prestations && newValue.prestations[0]) {
                            formContext.setValue('nom_journal', newValue.prestations[0].jal?.nom);
                            if (newValue.prestations[0].dateParutionDemandee) {
                                formContext.setValue(
                                    'date_parution',
                                    new Date(
                                        newValue.prestations[0].dateParutionDemandee,
                                    ).toLocaleDateString(),
                                );
                            }
                        }
                    },
                    hideHeader: true,
                }),
            [folio?.numero, folio?.prestations, folioMode, foliosDatasource],
        );

        const { folio: folioSection } = useSectionDataManager();
        const { updateFolioPublication } = folioSection();

        const handleSubmit = useCallback<SubmitHandler<FormObject>>(
            async (values: Pick<FactureFormObject, 'numero_annonce' | 'numero_dossier'>) => {
                setIsLoading(true);

                updateFolioPublication({
                    invoice,
                    lines,
                    newFolio: values,
                    onFetchNewLines,
                    isAlreadySyncG3: invoice.syncG3,
                }).finally(() => {
                    stateSwitchLockValues.folio.set(true);

                    setIsLoading(false);
                });
            },
            [invoice, lines, onFetchNewLines, stateSwitchLockValues.folio, updateFolioPublication],
        );

        const formattedFolioData = useMemo(() => {
            const folioData = {
                numero_dossier: 'N/A',
                numero_prestation: 'N/A',
                nom_journal: 'N/A',
                date_parution: 'N/A',
                nom_annonceur: 'N/A',
            };

            if (!folio || !folio.prestations || folio.prestations.length === 0) {
                return folioData;
            }

            if (folio?.numero) {
                folioData.numero_dossier = folio?.numero.toString();
            }

            if (folio?.prestations[0]?.numero) {
                folioData.numero_prestation = folio.prestations[0].numero;
            }

            if (folio?.prestations[0]?.jal?.nom) {
                folioData.nom_journal = folio.prestations[0].jal.nom;
            }

            if (folio?.prestations[0]?.dateParutionDemandee) {
                folioData.date_parution = new Date(
                    folio.prestations[0].dateParutionDemandee,
                ).toLocaleDateString();
            }

            if (folio?.prestations[0]?.annonceur) {
                folioData.nom_annonceur = folio.prestations[0].annonceur;
            }

            return folioData;
        }, [folio]);

        const formObject = useMemo<Partial<FactureFormObject>>(
            () => ({
                nom_journal: formattedFolioData.nom_journal,
                date_parution: formattedFolioData.date_parution,
                nom_annonceur: formattedFolioData.nom_annonceur,
            }),
            [formattedFolioData],
        );

        const readyToDisplay = useMemo<boolean>(
            () => !!(!isLoading && invoice?.id && folio?.id),
            [isLoading, invoice?.id, folio?.id],
        );

        const { loading } = useLoadingStatus({ checkReady: () => readyToDisplay });

        return (
            <SkeletonLoader
                isLoading={loading}
                type="FormSection"
            >
                <>
                    <FlexyHeaderForm
                        label={
                            <HeaderStructure
                                icon={
                                    <FaOptionIcon
                                        {...folioIcon.props}
                                        size="sm"
                                    />
                                }
                                title={`${folioIcon.displayName} Annonce`}
                            />
                        }
                        switches={[
                            ...(!invoice?.syncCegid
                                ? [getSwitchLockValues({ sectionToBlock: 'folio' })]
                                : []),
                        ]}
                        outlined
                    />
                    {stateSwitchLockValues.folio.value ? (
                        <ViewFolioPublicationSection
                            numeroDossier={formattedFolioData.numero_dossier}
                            numeroPrestation={formattedFolioData.numero_prestation}
                            journal={formattedFolioData.nom_journal}
                            dateParution={formattedFolioData.date_parution}
                            libelleAnnonceur={formattedFolioData.nom_annonceur}
                        />
                    ) : (
                        <FlexyForm
                            onSubmit={handleSubmit}
                            formObject={formObject}
                            formStructure={folioPublicationStructure}
                        />
                    )}
                </>
            </SkeletonLoader>
        );
    };

export default UpdateFolioPublicationSectionForm;
