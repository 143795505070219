import { rejectCodesApi, selectCodeRejet } from '@b2d/redux/RootStore';
import { AppDispatch, RootStateType } from '@b2d/redux/types';
import { CodeRejet, useSociete } from '@europrocurement/l2d-domain';
import { AxiosRequestConfig } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import {
    CodeRejetApiPatchCodeRejetItemRequest,
    CodeRejetCodeRejetUpdate,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';

/**
 * Hooks that regroup rejectCode actions / methods / accessors
 */

export const SelectedRejectCodesSelector = (s: RootStateType) => s.achats.coderejet.main.selected;

export const RejectCodesDatasourceSelector = (s: RootStateType) => s.achats.coderejet.main;

export const RejectCodesDatasourceStatusSelector = (s: RootStateType) =>
    s.achats.coderejet.main.status;

export const useCurrentRejectCode = (): CodeRejet | null => {
    const rejectCode: CodeRejet | null = useSelector(SelectedRejectCodesSelector);

    return rejectCode;
};

export const useRejectCodeDatasource = (): DataSource<CodeRejet> => {
    const datasource: DataSource<CodeRejet> = useSelector(RejectCodesDatasourceSelector);

    return datasource;
};

export const useRejectCodeStatus = (): EuroprocApiResponseStatus => {
    const status: EuroprocApiResponseStatus = useSelector(RejectCodesDatasourceStatusSelector);

    return status;
};

export const useRejectCodeIsLoading = (): boolean => {
    const statusRejectCode = useRejectCodeStatus();
    const statusOfLoading: Array<EuroprocApiResponseStatus> = ['idle', 'loading'];

    return statusOfLoading.includes(statusRejectCode);
};

export const useRejectCodeIsFailed = (): boolean => {
    const statusRejectCode = useRejectCodeStatus();
    const statusFailing: Array<EuroprocApiResponseStatus> = ['failed'];

    return statusFailing.includes(statusRejectCode);
};

export const useSelectRejectCode = () => {
    const rejectCode = useCurrentRejectCode();
    const rejectCodeStatus = useRejectCodeStatus();
    const dispatch = useDispatch<AppDispatch>();

    return (rejectCodeId: string, options?: AxiosRequestConfig, reload: boolean = false) => {
        if (reload) {
            dispatch(selectCodeRejet({ id: rejectCodeId, options }));
        } else if (
            (!rejectCode || rejectCode.id !== rejectCodeId) &&
            rejectCodeStatus !== 'loading'
        ) {
            dispatch(selectCodeRejet({ id: rejectCodeId, options }));
        }
    };
};

export const useUnSelectRejectCode = () => {
    const dispatch = useDispatch<AppDispatch>();

    return () => {
        dispatch({ type: 'coderejet/deletemainSelected' });
    };
};

export const useReloadRejectCode = () => {
    const rejectCode = useCurrentRejectCode();

    const unselect = useUnSelectRejectCode();
    const select = useSelectRejectCode();
    return (options?: AxiosRequestConfig) => {
        if (!rejectCode || !rejectCode.code) {
            console.error("Can't reload rejectCode, rejectCode is not selected");
            return;
        }
        unselect();
        select(rejectCode.code, options, true);
    };
};

export const usePatchRejectCode = () => {
    const xIdSociete = useSociete();

    return (code: string, payload: CodeRejetCodeRejetUpdate, options?: AxiosRequestConfig) => {
        const requestParameters: CodeRejetApiPatchCodeRejetItemRequest = {
            code,
            codeRejetCodeRejetUpdate: payload,
            xIdSociete,
        };

        return [
            {
                name: 'patchRejectCodeItem',
                promise: rejectCodesApi.patchCodeRejetItem(requestParameters, options),
            },
        ];
    };
};

export const useRejectCodeUtils = (rejectCodeToSelect?: string) => {
    const dispatch = useDispatch<AppDispatch>();
    const rejectCode = useCurrentRejectCode();

    if (!rejectCode && !rejectCodeToSelect) {
        throw new Error('missing selected rejectCode and no forced rejectCode');
    }

    if (
        rejectCodeToSelect ||
        (rejectCodeToSelect && rejectCode && rejectCode.code !== rejectCodeToSelect)
    ) {
        dispatch(selectCodeRejet({ id: rejectCodeToSelect }));
    }

    const companyId = useSociete();

    const unselectRejectCode = useUnSelectRejectCode();
    const selectRejectCode = useSelectRejectCode();
    const reloadRejectCode = useReloadRejectCode();
    const patchRejectCode = usePatchRejectCode();

    return {
        rejectCode,
        companyId,
        methods: {
            unselectRejectCode,
            selectRejectCode,
            reloadRejectCode,
            patchRejectCode,
        },
    };
};
