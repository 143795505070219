import React, { ReactNode, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { CompanyItem, ContactItem, ExportFormData, useExportDossier } from '../../../hooks';
import ExportDossierFormTitle from '../ExportDossierFormTitle/ExportDossierFormTitle';
import ContactChip from '../ContactChip/ContactChip';

export type ExportDossierFormProps = {
    processing: boolean;
    selectedContacts: Array<ContactItem>;
    onSelectedContactsChanged: (items: Array<ContactItem>) => void;
    companies: Array<CompanyItem>;
    additionalAction?: ReactNode;
    onSubmit: (formData: ExportFormData) => void;
};

const ExportDossierForm: React.FunctionComponent<ExportDossierFormProps> = function (props) {
    const {
        processing,
        selectedContacts,
        onSelectedContactsChanged,
        companies,
        additionalAction,
        onSubmit,
    } = props;
    const { searchContacts } = useExportDossier();
    const kc = UseKeycloakService();

    const handleSubmit: SubmitHandler<ExportFormData> = (formData: ExportFormData) => {
        onSubmit(formData);
    };

    const formStructure: FormStructure[] = [
        {
            type: 'autocomplete',
            name: 'recipients',
            inputlabel: 'Envoyer à',
            listboxTitle: 'Contacts',
            placeholder: 'Rechercher un contact',
            rules: {
                required: 'Ce champs est requis',
            },
            onSearch: (term: string) =>
                searchContacts(
                    term,
                    companies.map((company) => company.value),
                ),
            getOptionValue: (option: ContactItem) => option.id,
            // eslint-disable-next-line react/no-unstable-nested-components
            getOptionLabel: (option: ContactItem) => (
                <ContactChip
                    firstName={option.firstName}
                    lastName={option.lastName}
                />
            ),
        },
        {
            type: 'textarea',
            name: 'message',
            inputlabel: 'Message',
            placeholder: 'Votre message',
            rules: {
                required: 'Ce champs est requis',
            },
        },
    ];

    const defaultValues: ExportFormData = {
        recipients: selectedContacts,
        message: `Bonjour,\nVeuillez trouver ci-joint le récapitulatif des dossiers de formalité.\n\nCordialement\n${kc.getName()}`,
    };

    const formContext = useForm<FieldValues>({
        mode: 'onChange',
        defaultValues,
    });

    const formProps = {
        formContext,
        formObject: defaultValues,
        onSubmit: handleSubmit,
        submitButton: {
            displayed: false,
        },
    };

    const { recipients } = formContext.getValues();
    formContext.watch('recipients');

    useEffect(() => {
        onSelectedContactsChanged(recipients);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipients]);

    return (
        <Box>
            <Typography variant="l2dh6">Envoyer un récapitulatif</Typography>
            <Box sx={{ padding: '26px 0' }}>
                <ExportDossierFormTitle />
                <FlexyForm
                    formStructure={formStructure}
                    {...formProps}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant="contained"
                    sx={{ backgroundColor: 'primary.dark', padding: '16px 24px' }}
                    onClick={formContext.handleSubmit(handleSubmit)}
                    disabled={processing}
                >
                    Envoyer
                </Button>
                {additionalAction}
            </Box>
        </Box>
    );
};

export default ExportDossierForm;
