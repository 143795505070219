import { useLocation, useParams } from 'react-router';

export const useBasePath = () => {
    const location = useLocation();
    const params = useParams<Record<string, string>>();

    return () =>
        Object.values(params).reduce(
            (path, param) => (path && param ? path.replace(`/${param}`, '') : null),
            location.pathname,
        );
};
