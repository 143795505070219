import React, { useState } from 'react';
import { Box, useTheme, useMediaQuery, IconButton, Typography } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { ActionButton, ContactInformations, ModalContext } from '@europrocurement/flexy-components';
import {
    ContactJsonldTiersRead,
    ContactTiersRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ContactForm from '@b2d/pages/Prescripteurs/forms/ContactForm';
import { PrescripteurApi } from '@europrocurement/l2d-domain';
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons';
import { PrescriberTabProps } from '../sections/types';

export type ContactListProps = {
    contacts: Array<ContactTiersRead>;
    type: 'active' | 'inactive';
    sectionTitle: string;
    limit?: number;
} & PrescriberTabProps;

/** Display the firsts contact ( setted by the limit )
 * - List can be extended to display every contacts
 */
const ContactList: React.FunctionComponent<ContactListProps> = function (props) {
    const { prescriber, fetchPrescriber, contacts, sectionTitle, limit = 2, type } = props;
    const [isListShown, setIsListShown] = useState(false);
    const toggleList = () => setIsListShown(!isListShown);
    const listToggleIcon: IconProp = ['fasl', `${isListShown ? 'minus' : 'plus'}`];
    const listToggleLabel = `Afficher ${isListShown ? 'moins' : 'plus'} de contacts`;
    const theme = useTheme();
    const { breakpoints } = theme;
    const lgBreakpoint = useMediaQuery(breakpoints.down('lg'));
    const { modalActions } = React.useContext(ModalContext);

    const companyRelationship = prescriber.relationsSocietes[0] ?? null;
    const prescriberHasB2BAccess = companyRelationship?.plateformeB2BAutorise ?? false;

    const emptyList = contacts.length === 0;

    /**
     * Call contact form modal ( creation or udate )
     * @param contact - contact update form if provided
     */
    const contactForm = (contact?: ContactJsonldTiersRead) => {
        const afterSubmit = () => {
            modalActions.close();
            fetchPrescriber();
        };
        modalActions.call(
            <ContactForm
                prescriber={prescriber as PrescripteurApi}
                afterSubmit={afterSubmit}
                contact={contact}
            />,
        );
    };

    const flexItemSize = () => {
        if (lgBreakpoint) {
            return '98%';
        }
        return '49%';
    };
    const indexShown = (index: number): boolean => {
        if (isListShown) {
            return true;
        }
        return index < limit;
    };
    const shownWrapper = (index: number) => ({
        maxHeight: indexShown(index) ? '100%' : 0,
        visibility: indexShown(index) ? 'inherit' : 'hidden',
        flex: flexItemSize,
        maxWidth: flexItemSize,
        opacity: indexShown(index) ? 1 : 0,
        overflow: 'hidden',
        transition: 'visibility 0s, opacity 0.5s linear',
    });

    if (emptyList) return null;
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                minWidth="100%"
                marginTop="10px"
            >
                <Typography
                    fontWeight={500}
                    fontSize={18}
                >
                    {sectionTitle}
                </Typography>
                {type === 'active' && (
                    <IconButton
                        onClick={() => contactForm()}
                        sx={{ borderRadius: '5px' }}
                    >
                        <Box>
                            <FaOptionIcon
                                icon={faUserPlus}
                                sx={{ opacity: 0.8, fontSize: '14px', marginRight: '13px' }}
                            />
                        </Box>
                        <Typography>Ajouter un contact</Typography>
                    </IconButton>
                )}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                {contacts &&
                    contacts.map((contact, index) => {
                        const key = `${type}_contacts_${index}`;
                        return (
                            <Box
                                key={key}
                                sx={shownWrapper(index)}
                            >
                                <ContactInformations
                                    contact={contact}
                                    openForm={contactForm}
                                    prescriberHasB2BAccess={prescriberHasB2BAccess}
                                />
                            </Box>
                        );
                    })}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {contacts && contacts.length > limit && (
                    <ActionButton
                        onClick={toggleList}
                        icon={listToggleIcon}
                        label={listToggleLabel}
                        color="#177BC2"
                        sx={{ marginTop: '25px' }}
                    />
                )}
            </Box>
        </>
    );
};

export default ContactList;
