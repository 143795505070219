import { FactureAchat } from '@europrocurement/l2d-domain';
import { Chip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircleCheck, faCircleEuro, faCircleTrash } from '@fortawesome/pro-duotone-svg-icons';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';

type FactureAchatEtatsProps = {
    factureAchats: FactureAchat;
};

const FactureAchatEtats: React.FunctionComponent<FactureAchatEtatsProps> = function ({
    factureAchats,
}: FactureAchatEtatsProps) {
    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    return (
        <Box
            display="box"
            sx={{ maxWidth: '100%' }}
        >
            <Typography
                variant="h5"
                display="inline-block"
                fontWeight="bold"
            >
                Etats :
            </Typography>

            {factureAchats.deleted ? (
                <Chip
                    color="danger"
                    label={
                        <Typography
                            display="inline-block"
                            color="danger.dark"
                        >
                            <FontAwesomeIcon
                                scale={1.5}
                                icon={faCircleTrash}
                                style={{
                                    marginRight: '3px',
                                }}
                            />
                            Supprimée
                        </Typography>
                    }
                    size="small"
                    variant="outlined"
                    sx={{
                        margin: '0px 5px 0px 15px',
                        color: '#3e3e3e', // Nécessaire pour l'accessibilité
                    }}
                />
            ) : null}

            {factureAchats.avoir ? (
                <Chip
                    color="secondary"
                    label={
                        <Typography
                            display="inline-block"
                            color="secondary.dark"
                        >
                            <FontAwesomeIcon
                                scale={1.5}
                                icon={faCircleEuro}
                                style={{
                                    marginRight: '3px',
                                }}
                            />
                            Avoir
                        </Typography>
                    }
                    size="small"
                    variant="outlined"
                    sx={{
                        margin: '0px 5px 0px 15px',
                        color: '#3e3e3e', // Nécessaire pour l'accessibilité
                    }}
                />
            ) : null}

            <Chip
                color={factureAchats.cloture ? 'primary' : 'warning'}
                label={
                    <Typography
                        display="inline-block"
                        color={factureAchats.cloture ? 'primary' : 'warning.dark'}
                    >
                        <FontAwesomeIcon
                            scale={1.5}
                            icon={faCircleCheck}
                            style={{
                                marginRight: '3px',
                            }}
                        />
                        {factureAchats.cloture ? 'Cloturée' : 'Non cloturée'}
                    </Typography>
                }
                size="small"
                variant="outlined"
                sx={{
                    margin: '0px 5px 0px 15px',
                    color: '#3e3e3e', // Nécessaire pour l'accessibilité
                }}
            />

            <Chip
                color={factureAchats.syncG3 ? 'secondary' : 'warning'}
                label={
                    <Typography
                        display="inline-block"
                        color={factureAchats.syncG3 ? 'secondary' : 'warning.dark'}
                    >
                        <FontAwesomeIcon
                            scale={1.5}
                            icon={faCircleCheck}
                            style={{
                                marginRight: '3px',
                            }}
                        />
                        {factureAchats.syncG3
                            ? `Synchronisée ${
                                  factureAchats.idFactureAchatJal
                                      ? `( id g3 : ${factureAchats.idFactureAchatJal} )`
                                      : null
                              }`
                            : 'Non synchronisée'}
                    </Typography>
                }
                size="small"
                variant="outlined"
                sx={{
                    margin: '0px 5px 0px 15px',
                    color: '#2e7d32', // Nécessaire pour l'accessibilité
                }}
            />

            {isInterne && factureAchats.syncCegid ? (
                <Chip
                    color="success"
                    label={
                        <Typography
                            display="inline-block"
                            color="success"
                        >
                            <FontAwesomeIcon
                                scale={1.5}
                                icon={faCircleCheck}
                                style={{
                                    marginRight: '3px',
                                }}
                            />
                            Exportée vers CEGID
                        </Typography>
                    }
                    size="small"
                    variant="outlined"
                    sx={{
                        margin: '0px 5px 0px 15px',
                        color: '#2e7d32', // Nécessaire pour l'accessibilité
                    }}
                />
            ) : null}
        </Box>
    );
};

export default FactureAchatEtats;
