import { CardSection, CardHeader } from '@europrocurement/flexy-components';
import * as React from 'react';
import { PrescriberTabProps } from './types';

const Pricing: React.FunctionComponent<PrescriberTabProps> = function () {
    const cardHeader = (
        <CardHeader
            title="Grille tarifaire"
            border={false}
        />
    );
    return <CardSection header={cardHeader}>Grille tarifaire ...</CardSection>;
};

export default Pricing;
