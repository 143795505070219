import { PRICE_REGEX } from '@europrocurement/l2d-modules/validation';

import { z } from 'zod';

/**  Zod validation schema : Form schema and validation patterns */

const requiredIdMessage = {
    message: 'Le champs est requis',
};

export const contentStepSchema = z.object({
    commercialName: z
        .string()
        .min(1, 'Le champs requis')
        .max(255, 'Le nom commercial est trop long'),
    domainId: z.number(),
    packageId: z.number(requiredIdMessage),
    groupId: z.number(requiredIdMessage),
    companyId: z.number(),
});

const luxonDate = z.object({ ts: z.number() });

export const visibilityStepSchema = z.object({
    offerId: z.number(requiredIdMessage),
    originId: z.number().nullable(),
    startDate: luxonDate,
    endDate: luxonDate.nullable(),
});

const articleOverrideObject = z.object({
    articleRelationId: z.number(requiredIdMessage),
    label: z.string().max(255, 'Le libellé est trop long').nullable().optional(),
    price: z // Price value decimal 19.4 | Empty string | Null
        .string()
        .regex(PRICE_REGEX, 'Le format du prix est incorrect')
        .or(z.string().max(0))
        .nullable()
        .optional(),
    quantity: z.number().nullable(),
});

export const overrideStepSchema = z.object({
    catalogArticlesOverrides: z.array(articleOverrideObject),
});

const apiObjectsWithId = z.array(
    z.object({
        id: z.number(requiredIdMessage),
    }),
);

export const optionStepSchema = z.object({
    documents: apiObjectsWithId,
    additionalFields: apiObjectsWithId,
    options: apiObjectsWithId,
});

export type ContentSchema = z.infer<typeof contentStepSchema>;
export type VisibiltySchema = z.infer<typeof visibilityStepSchema>;
export type OverrideSchema = z.infer<typeof overrideStepSchema>;
export type OptionsStep = z.infer<typeof optionStepSchema>;

export type CatalogFormData = ContentSchema & VisibiltySchema & OverrideSchema & OptionsStep;
