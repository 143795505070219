/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import * as React from 'react';

import { useDebounce } from 'use-debounce';
import { FlexyFormLabel, FlexyHeaderForm, FlexyInput } from '@europrocurement/flexy-components';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import {
    ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites,
    TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import {
    Box,
    CircularProgress,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import usePromises from '@europrocurement/l2d-hooks/hooks/usePromises';
import { useSociete, useTiersService } from '@europrocurement/l2d-domain';
import PartnerLogo from './PartnerLogo';
import SwitchGroup from './SwitchGroup';
import { ImageSpecificationsType } from './ImageSpecifications';

export type BooleanField = {
    label: string;
    value: unknown;
    apiFieldName: string;
};

type CommercialConditionsType = {
    formData: ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites;
    isWaitingUpdate: boolean;
};

type FormalitiesSettingsProps = {
    prescriberId: string;
};

type LogoSpecificationsType = {
    id: number;
    name: 'LOGO_LARGEUR' | 'LOGO_HAUTEUR' | 'LOGO_POIDS' | 'LOGO_EXTENSION';
    value: string;
    description: string;
};

const FormalitiesSettings: React.FunctionComponent<FormalitiesSettingsProps> = function (props) {
    const { logoSpecificationsApi, prescribersApi } = useTiersService();
    const { prescriberId } = props;
    const companyId = useSociete();
    const { request, requestState } = useApiRequest();

    // 1 - Component Data / On mount fetch data

    const [commercialConditions, setCommercialConditions] = React.useState<
        CommercialConditionsType | undefined
    >();

    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

    const [logoSpecifications, setLogoSpecifications] = React.useState<
        ImageSpecificationsType | undefined
    >();
    const [dataFetched, setDataFetched] = React.useState<boolean>(false);

    const {
        auForfait,
        factureCourrier,
        factureSimplifiee,
        logoFormalite,
        partenaireFormalite,
        depotDossierFtp,
        emailSousclientObligatoire,
        signatureElectronique,
        typeFacture,
    } = commercialConditions?.formData || {};

    const id = prescriberId.toString();
    const getSettings = React.useCallback(
        () =>
            prescribersApi.getGrilleForfaitairePrescripteurTiersItem({
                id,
                xIdSociete: companyId,
            }),
        [companyId, id, prescribersApi],
    );

    const getLogoSpecifications = React.useCallback(
        () => logoSpecificationsApi.getLogoSpecificationsTblParametreReferencesCollection(),
        [logoSpecificationsApi],
    );

    const { isFetching, isLoaded, fetchedData, call: fetchApi } = usePromises();

    const formalitiesSettingsPromises = React.useMemo(
        () => [
            {
                name: 'getSettings',
                promise: getSettings(),
                accessorKey: 'conditionsCommerciales',
            },
            {
                name: 'getLogoSpecifications',
                promise: getLogoSpecifications(),
            },
        ],
        [getLogoSpecifications, getSettings],
    );

    React.useEffect(() => {
        fetchApi(formalitiesSettingsPromises);
    }, [fetchApi, formalitiesSettingsPromises]);

    // 2 - Component state & debounce logic

    const updateFormField = (field: string, value: boolean | string | number) => {
        const { formData } = commercialConditions || {};

        if (!formData) {
            return;
        }

        setCommercialConditions({
            formData: {
                ...formData,
                logoFormalite: logoFormalite ?? '', // Prevent sending null | undefined
                [field]: value,
            },
            isWaitingUpdate: true,
        });
    };

    const [debouncedForm] = useDebounce(commercialConditions, 1000);
    const [skipDebounce, setSkipDebounce] = React.useState<boolean>(false);

    const updateCommercialConditionsRequest = React.useCallback(
        (
            commercialConditionsParams: TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites,
        ) => {
            console.log('commercialConditionsParams');
            console.log(commercialConditionsParams);
            setIsUpdating(true);
            return prescribersApi.updateConditionsCommercialesFormalitesPrescripteurTiersItem({
                id: prescriberId,
                tiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites:
                    commercialConditionsParams,
                xIdSociete: companyId,
            });
        },
        [companyId, prescriberId, prescribersApi],
    );

    const updateCommercialConditions = React.useCallback(
        (commercialConditionsParams: {
            conditionsCommerciales: TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites;
        }) => {
            const successCallback = (
                result: any,
                // result: JsonLDFullFilledResponse['value'] & {
                //     data: TiersPrescripteurPrescripteurWriteConditionsCommercialesFormalites;
                // },
            ) => {
                const updatedCommercialConditions = result.data?.conditionsCommerciales[0];
                setIsUpdating(false);
                setCommercialConditions({
                    formData: updatedCommercialConditions,
                    isWaitingUpdate: false,
                });
            };
            if (!isUpdating) {
                request(updateCommercialConditionsRequest(commercialConditionsParams as any), {
                    successCallback,
                });
            }
        },
        [isUpdating, request, updateCommercialConditionsRequest],
    );

    React.useEffect(() => {
        const { formData, isWaitingUpdate } = commercialConditions || {};

        if (skipDebounce) {
            setSkipDebounce(false);
        } else if (isWaitingUpdate) {
            updateCommercialConditions({
                conditionsCommerciales: [formData] as any,
            });
        }
    }, [commercialConditions, debouncedForm, skipDebounce, updateCommercialConditions]);

    const updateWithoutDebounce = (field: string, value: string | number | boolean) => {
        setSkipDebounce(true);

        if (!commercialConditions) {
            return;
        }

        const { formData } = commercialConditions;

        updateCommercialConditions({
            conditionsCommerciales: [
                {
                    ...formData,
                    logoFormalite: logoFormalite ?? '', // Prevent sending null | undefined
                    [field]: value,
                },
            ] as any,
        });
        updateFormField(field, value);
    };

    const handleCommercialConditionsData = React.useCallback(
        (
            commercialConditionsData: ConditionsCommercialesJsonldPrescripteurReadConditionsCommercialesFormalites[],
        ) => {
            setCommercialConditions({
                formData: commercialConditionsData[0],
                isWaitingUpdate: false,
            });
        },
        [],
    );

    const getLogoSpecificationValueBasedOnName = (
        name: LogoSpecificationsType['name'],
        specifications: LogoSpecificationsType[],
    ) => {
        const specificationValue = specifications.find((logoSpec) => logoSpec.name === name);

        if (!specificationValue) {
            throw new TypeError('Specification value must be defined');
        }

        const { value } = specificationValue;

        return value;
    };

    const getLogoSpecificationAllowedTypes = (specifications: LogoSpecificationsType[]) => {
        const specificationValue = specifications.find(
            (logoSpec) => logoSpec.name === 'LOGO_EXTENSION',
        );

        if (!specificationValue) {
            throw new TypeError('Specification value must be defined');
        }

        const { value } = specificationValue;
        const arrayOfExtensions = value.split(',');
        const trimmedArray = arrayOfExtensions.map((arrayItem: string) => arrayItem.trim());
        return trimmedArray;
    };

    const handleLogoSpecificationsData = React.useCallback(
        (logoSpecificationsData: LogoSpecificationsType[]) => {
            if (!logoSpecificationsData || logoSpecificationsData.length === 0) {
                return;
            }

            setLogoSpecifications({
                width: getLogoSpecificationValueBasedOnName('LOGO_LARGEUR', logoSpecificationsData),
                height: getLogoSpecificationValueBasedOnName(
                    'LOGO_HAUTEUR',
                    logoSpecificationsData,
                ),
                maxSize: getLogoSpecificationValueBasedOnName('LOGO_POIDS', logoSpecificationsData),
                allowedTypes: getLogoSpecificationAllowedTypes(logoSpecificationsData),
            });
        },
        [],
    );

    // 3 - On result / update component state

    React.useEffect(() => {
        if (isLoaded && fetchedData) {
            handleCommercialConditionsData(fetchedData.getSettings ?? []);
            handleLogoSpecificationsData(fetchedData.getLogoSpecifications ?? []);
            setDataFetched(true);
        }
    }, [isLoaded, fetchedData, handleLogoSpecificationsData, handleCommercialConditionsData]);

    // 4 - CommercialConditions update

    const { isProcessing } = requestState || isUpdating;

    // 5 - Render

    // 5a - Form structure / inputs

    const invoicesFlags = [
        {
            label: 'Au forfait',
            value: auForfait,
            apiFieldName: 'auForfait',
        },
        {
            label: 'Envoi postal de la facture',
            value: factureCourrier,
            apiFieldName: 'factureCourrier',
        },
        {
            label: 'Facture simplifiée',
            value: factureSimplifiee,
            apiFieldName: 'factureSimplifiee',
        },
    ];

    const settingsFlags = [
        {
            label: 'Dépôt des dossiers via FTP',
            value: depotDossierFtp,
            apiFieldName: 'depotDossierFtp',
        },
        {
            label: 'Signature électronique',
            value: signatureElectronique,
            apiFieldName: 'signatureElectronique',
        },
        {
            label: 'Email sous client obligatoire',
            value: emailSousclientObligatoire,
            apiFieldName: 'emailSousclientObligatoire',
        },
    ];

    const invoiceTypes = [
        {
            label: 'Prescripteur',
            value: 1,
        },
        {
            label: 'Client envoyé au prescripteur',
            value: 2,
        },
        {
            label: 'Prescripteur',
            value: 3,
        },
    ];

    // 5b - render logic

    if (isFetching || dataFetched === false) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const switchWrapperStyle = {
        flex: `1 0 60%`,
        marginRight: '15px',
    };

    return (
        <Grid
            container
            spacing={4}
            rowSpacing={1}
        >
            <Grid
                item
                xl={7}
                lg={12}
            >
                <FlexyHeaderForm
                    label="Configuration"
                    outlined
                />
                <SwitchGroup
                    booleanFields={settingsFlags}
                    isProcessing={isProcessing}
                    updateFormField={updateFormField}
                    keyPrefix="commercial_conditions_boolean_switch"
                />
                <FlexyHeaderForm
                    label="Facturation"
                    outlined
                />
                <SwitchGroup
                    booleanFields={invoicesFlags}
                    isProcessing={isProcessing}
                    updateFormField={updateFormField}
                    keyPrefix="commercial_conditions_boolean_switch"
                />
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <FlexyFormLabel paddingRight={8}>
                        <Typography component="span">Type de facture</Typography>
                    </FlexyFormLabel>
                    {typeFacture && (
                        <RadioGroup
                            sx={{ flexGrow: '1' }}
                            aria-labelledby="invoice-type-radio-buttons-group-label"
                            name="invoice-type-buttons-group"
                            value={typeFacture}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = parseInt(event.target.value, 10);
                                updateFormField('typeFacture', newValue);
                            }}
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                            >
                                {invoiceTypes.map(({ value, label }) => (
                                    <FormControlLabel
                                        value={value}
                                        control={<Radio />}
                                        label={label}
                                        disabled={isProcessing}
                                    />
                                ))}
                            </Box>
                        </RadioGroup>
                    )}
                </Box>
            </Grid>
            <Grid
                item
                xl={5}
                lg={12}
            >
                <Box
                    sx={switchWrapperStyle}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <FlexyHeaderForm label="Partenaire formalité" />

                    <FlexyInput
                        name="partner_switch"
                        type="boolean"
                        value={partenaireFormalite}
                        disabled={isProcessing}
                        onChange={() => {
                            updateWithoutDebounce('partenaireFormalite', !partenaireFormalite);
                        }}
                    />
                </Box>

                {/* Divider / Keep theme consistency */}
                <FlexyHeaderForm
                    label=""
                    sx={{ margin: '0', padding: '0' }}
                    outlined
                />
                {logoSpecifications ? (
                    <PartnerLogo
                        isActive={partenaireFormalite === true}
                        imageSpecifications={logoSpecifications}
                        base64StringLogo={logoFormalite ?? ''}
                        updateLogo={(value: string) =>
                            updateWithoutDebounce('logoFormalite', value)
                        }
                    />
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        </Grid>
    );
};

export default FormalitiesSettings;
