import React from 'react';
import { Box, Button } from '@mui/material';
import { SubmitActionProps } from './types';

type ActionsProps = {
    submitActions: Array<SubmitActionProps>;
};

const Actions: React.FunctionComponent<ActionsProps> = function ({ submitActions }) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '10px',
                alignSelf: 'stretch',
            }}
        >
            {submitActions.map(({ type, action }) => (
                <Button
                    key={type}
                    variant="contained"
                    color={type === 'confirm' ? 'primary' : 'error'}
                    onClick={action}
                >
                    {type === 'confirm' ? 'Confirmer' : 'Annuler'}
                </Button>
            ))}
        </Box>
    );
};

export default Actions;
