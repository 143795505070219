import React, { useCallback, useMemo } from 'react';

import { PdfViewer } from '@europrocurement/flexy-components';
import { MediaObject } from '@europrocurement/l2d-domain';
import { Card, SxProps } from '@mui/material';
import useLoadingStatus from '@b2d/hooks/useLoading';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@b2d/redux/types';
import { selectMediaObject } from '@b2d/redux/RootStore';
import SkeletonLoader from '../SkeletonLoader';

export type MediaObjectReaderType = {
    mediaObject?: MediaObject;
    sx?: SxProps;
};

export const MediaObjectReader = function ({ mediaObject, sx }: MediaObjectReaderType) {
    const { mediaobjectid } = useParams();
    const dispatch = useDispatch<AppDispatch>();

    const fetchCurrentMediaObjectPage = useMemo<number>(() => {
        if (!mediaObject) {
            console.error('Missing media object to fetch page !');
            if (mediaobjectid) {
                console.warn('Force select media object from URL params');
                dispatch(selectMediaObject({ id: mediaobjectid }));
            }
        }

        const mediaObjectReaderParam = localStorage.getItem('mediaObjectReaderParam');
        let fetchedPage = 1;

        if (mediaObjectReaderParam && mediaObject?.id === JSON.parse(mediaObjectReaderParam).id) {
            fetchedPage = JSON.parse(mediaObjectReaderParam).currentPage;
        }

        return fetchedPage;
    }, [dispatch, mediaObject, mediaobjectid]);

    const loadData = useCallback(() => !!mediaObject, [mediaObject]);

    const { loading } = useLoadingStatus({
        checkReady: loadData,
    });

    return (
        <SkeletonLoader isLoading={loading}>
            <Card
                id="cardPdfReader"
                sx={{ ...sx }}
            >
                <PdfViewer
                    title={mediaObject?.originalName}
                    pdfUrl={mediaObject?.contentUrl ?? undefined}
                    defaultPage={fetchCurrentMediaObjectPage}
                    onPageChange={(page) => {
                        localStorage.setItem(
                            'mediaObjectReaderParam',
                            JSON.stringify({
                                id: mediaObject?.id,
                                currentPage: page,
                            }),
                        );
                    }}
                />
            </Card>
        </SkeletonLoader>
    );
};

export default MediaObjectReader;
