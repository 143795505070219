import { useApiRequest } from '@europrocurement/l2d-hooks';

import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import {
    OptionValueJsonldOptionValueCreate,
    OptionValueJsonldOptionValueUpdate,
    OptionsJsonldOptionRead,
    TblOptionValueOptionRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { Button, Divider, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { FaOptionIcon, offerOptionValueIcon, warningIcon } from '@europrocurement/l2d-icons';
import { useOffreService } from '@europrocurement/l2d-domain';

import { ZodError } from 'zod';
import { maximumLengthRule } from '@europrocurement/l2d-modules/validation';
import OptionValueRow from './OptionValueRow';
import AsyncEditableList from '../../components/form/AsyncEditableList/AsyncEditableList';

import OfferOptionPreview from '../preview/OptionValuesPreview';
import AddOptionButton from './AddOptionButton';
import { OptionValue } from '../preview/types';
import { EditableListItem } from '../../components/form/AsyncEditableList/AsyncEditableItem';
import { JsonLdData } from '../../forms/types';
import ArticlesModule from '../../Package/Components/ArticlesModule';
import { useRank } from '../../Package/Form/useRank';
import { apiArticlesToFormEditableArticles } from '../../Article/format';
import { optionValueRelationshipsValidationSchema } from './validation';

type ComponentProps = {
    formContext: UseFormReturn<FieldValues, unknown>;
    companyId: number;
    moduleMode: 'list' | 'preview';
    domainId: number;
    isCreate?: boolean;
    setOptionValueInEdition?: React.Dispatch<React.SetStateAction<boolean>>;
};

const OptionValuesModule: React.FunctionComponent<ComponentProps> = function ({
    formContext: parentFormContext,
    companyId,
    moduleMode,
    isCreate,
    domainId,
    setOptionValueInEdition,
}) {
    const {
        documentModel,
        additionalFieldModel,
        offerOptionValueModel: optionValueModel,
    } = useOffreService();

    const defaultValues = {
        // Blank option value form
        id: '',
        libelle: '',
        description: '',
        regle: null,
        inverse: false,
        articles: [],
        champsComplementaires: [],
        actions: [],
        documents: [],
        htmlFieldType: 'checkbox',
        domainId,
    };

    const formContext = useForm<FieldValues, unknown>({
        mode: 'onTouched',
        defaultValues,
    });

    const [currentError, setCurrentError] = React.useState<null | ZodError>();
    const validateRelationships = React.useCallback(() => {
        const data = formContext.getValues();
        try {
            optionValueRelationshipsValidationSchema.parse(data);
            setCurrentError(null);
            return true;
        } catch (error) {
            setCurrentError(error as ZodError);
            return false;
        }
    }, [formContext]);

    const [activeTabIndex, setActiveTabIndex] = React.useState(0);

    const { request, requestState } = useApiRequest();

    const { isProcessing } = requestState;

    // Component state
    type ComponentState = {
        optionValues: OptionValue[];
        resetForm: boolean;
        selectedOptionValue:
            | OptionValueJsonldOptionValueCreate
            | OptionValueJsonldOptionValueUpdate
            | null;
        modeEdition: boolean;
    };

    const defaultState: ComponentState = {
        optionValues: isCreate ? [] : (parentFormContext.getValues().values ?? []), // Displayed option values list
        resetForm: false, // Trigger option value form clear
        selectedOptionValue: null, // Form current value
        modeEdition: false,
    };

    const [state, setState] = React.useState<ComponentState>(defaultState);
    const [resetAsyncSelects, setResetAsyncSelects] = React.useState(false);
    const { optionValues, resetForm, selectedOptionValue, modeEdition } = state;
    const { getRankByArrayIndex } = useRank();

    const formatFormValues = (formValues: FieldValues) => ({
        ...formValues,
        articles: formValues.articles.map((formArticle, arrayIndex: number) => {
            const stringifiedPrice = formArticle.price?.toString();
            const priceOrNull = !stringifiedPrice ? null : stringifiedPrice;
            const articlePrice = formArticle.priceInformations?.isFlatRate ? null : priceOrNull;
            return {
                article: formArticle.articleId,
                libelle: formArticle.label,
                quantite: formArticle.quantity,
                rang: getRankByArrayIndex(arrayIndex),
                tarif: articlePrice ?? null,
                sType: formArticle.subTypeId,
                regle: formArticle.regle ?? null,
            };
        }),
    });

    // State mutators
    const updateState = React.useCallback(
        (key: keyof ComponentState, value: ComponentState[keyof ComponentState]) => {
            setState((prevState) => ({
                ...prevState,
                [key]: value,
            }));
        },
        [],
    );

    const setResetForm = React.useCallback(
        (value: boolean) => {
            updateState('resetForm', value);
        },
        [updateState],
    );

    const setOptionValues = (values: ComponentState['optionValues']) => {
        updateState('optionValues', values);
    };
    const setSelectedOptionValue = React.useCallback(
        (value: ComponentState['selectedOptionValue']) => {
            updateState('selectedOptionValue', value);
        },
        [updateState],
    );
    const setModeEdition = (value: ComponentState['modeEdition']) => {
        updateState('modeEdition', value);
        if (typeof setOptionValueInEdition === 'function') {
            setOptionValueInEdition(value);
        }
    };

    const fillFormContext = (optionValue: OptionValue) => {
        const fields = [
            'id',
            'libelle',
            'regle',
            'inverse',
            'description',
            'articles',
            'champsComplementaires',
            'actions',
            'documents',
        ] as const;

        fields.forEach((field) => {
            const optionValueDocuments = optionValue?.documents ?? [];
            const formatedArticles = apiArticlesToFormEditableArticles(
                optionValue?.articles ?? [],
            ).articles;
            switch (field) {
                case 'articles':
                    formContext.setValue('articles', formatedArticles);
                    break;
                case 'documents':
                    formContext.setValue(
                        'documents',
                        optionValueDocuments.map((doc) => ({
                            regle: doc.regle ?? null,
                            typeDocument: doc.typeDocument?.id,
                        })),
                    );
                    break;
                default:
                    formContext.setValue(field, optionValue[field]);
            }
        });
    };
    // Infer htmlfieldtype value of parent form & resetForm handling
    React.useEffect(() => {
        const getHtmlFieldTypeValue = () => {
            const numberOfOptionValues = parentFormContext.getValues().values?.length ?? 0;
            if (numberOfOptionValues > 3) {
                return 'select';
            }
            if (numberOfOptionValues > 1) {
                return 'radio';
            }
            return 'checkbox';
        };

        if (resetForm) {
            formContext.reset();
            setResetForm(false);
            setSelectedOptionValue(null);
            parentFormContext.setValue('htmlFieldType', getHtmlFieldTypeValue());
        }

        if (resetAsyncSelects) {
            setResetAsyncSelects(false);
        }
    }, [
        formContext,
        parentFormContext,
        resetForm,
        resetAsyncSelects,
        setResetForm,
        setSelectedOptionValue,
    ]);

    React.useEffect(() => {
        validateRelationships();
    }, [formContext, validateRelationships]);
    const ARTICLE_TAB_INDEX = 0;
    const ADDITIONAL_FIELD_TAB_INDEX = 1;
    const DOCUMENT_TAB_INDEX = 2;

    const getDisplayStatus = (tabIndex: number) =>
        activeTabIndex === tabIndex ? 'inherit' : 'none';

    const getRelationshipErrorStatus = (relationPath: string) => {
        if (!currentError) {
            return false;
        }

        const zodErrors = currentError.issues;
        const filteredByPath = zodErrors.filter((issue) => issue.path.includes(relationPath));
        return filteredByPath.length > 0;
    };

    const getWarningIcon = (relationPath: string) => {
        if (getRelationshipErrorStatus(relationPath)) {
            return (
                <FaOptionIcon
                    {...warningIcon.props}
                    iconProps={{
                        style: {
                            fontSize: '16px',
                            opacity: '0.6',
                            color: 'red',
                        },
                    }}
                />
            );
        }
        return null;
    };

    const documentOverridableField = {
        label: 'Règle',
        name: 'regle',
        value: null,
    };

    const optionValueFormStructure: FormStructure[] = [
        {
            type: 'text',
            name: 'libelle',
            inputlabel: 'Libellé',
            disabled: !modeEdition,
            rules: {
                required: 'Vous devez renseigner un libellé',
            },
            xs: 12,
            placeholder: 'Nom de la valeur',
        },
        {
            type: 'text',
            name: 'regle',
            inputlabel: 'Règle',
            disabled: !modeEdition,
            xs: 10,
            placeholder: 'Nom de la règle',
        },
        {
            type: 'boolean',
            name: 'inverse',
            inputlabel: 'Inverse',
            disabled: !modeEdition,
            defaultValue: false,
            xs: 2,
            verticalSwitch: true,
        },
        {
            type: 'textarea',
            name: 'description',
            disabled: !modeEdition,
            inputlabel: 'Description de la valeur',
            rules: {
                required: 'Vous devez renseigner un description',
                validate: {
                    ...maximumLengthRule(255),
                },
            },
            multiline: true,
            rows: 2,
            xs: 12,
            placeholder: 'Description de la valeur',
        },
        {
            type: 'customItem',
            name: 'tabHeader', // Not used but required for customItem | todo : typing fix
            renderField: () => (
                <Tabs
                    sx={{ marginY: '12px' }}
                    value={activeTabIndex}
                    onChange={(_event, tabIndex) => setActiveTabIndex(tabIndex)}
                    aria-label="option relationships tabs"
                >
                    <Tab
                        disabled={!modeEdition}
                        label="Article(s)"
                        icon={getWarningIcon('articles')}
                    />
                    <Tab
                        disabled={!modeEdition}
                        label="Champ(s) complémentaire(s)"
                        icon={getWarningIcon('champsComplementaires')}
                    />
                    <Tab
                        disabled={!modeEdition}
                        label="Document(s)"
                        icon={getWarningIcon('documents')}
                    />
                </Tabs>
            ),
        },
        {
            type: 'customItem',
            name: 'articles', // Not used but required for customItem | todo : typing fix
            renderField: () => (
                <Box display={getDisplayStatus(ARTICLE_TAB_INDEX)}>
                    <ArticlesModule
                        key={`articleModule_${domainId}`}
                        formContext={formContext}
                        isStepValidating={false}
                        disabled={!modeEdition}
                        domainId={domainId}
                        displayHeader={false}
                        isOptionForm // Props used to display 'regle' field
                    />
                </Box>
            ),
        },

        {
            type: 'customItem',
            name: 'champsComplementaires', // Not used but required for customItem | todo : typing fix
            renderField: () => (
                <Box display={getDisplayStatus(ADDITIONAL_FIELD_TAB_INDEX)}>
                    <AsyncEditableList
                        inputLabel="Champ(s) complémentaire(s)"
                        formContext={formContext}
                        formKey="champsComplementaires"
                        defaultList={
                            (selectedOptionValue?.champsComplementaires?.map(
                                ({ champComplementaire }) => champComplementaire,
                            ) ?? []) as unknown as EditableListItem[]
                        }
                        formatItem={(item) =>
                            !item
                                ? null
                                : {
                                      champComplementaire: item.id,
                                  }
                        }
                        placeholder="Sélectionnez le ou les champ(s) complementaire(s) lié(s) à cette valeur"
                        model={additionalFieldModel}
                        resetField={resetAsyncSelects}
                        disabled={!modeEdition}
                    />
                </Box>
            ),
        },
        {
            type: 'customItem',
            name: 'documents', // Not used but required for customItem | todo : typing fix
            renderField: () => (
                <Box display={getDisplayStatus(DOCUMENT_TAB_INDEX)}>
                    <AsyncEditableList
                        inputLabel="Document(s)"
                        formContext={formContext}
                        formKey="documents"
                        defaultList={
                            (selectedOptionValue?.documents?.map(({ typeDocument, regle }) => ({
                                ...typeDocument,
                                regle,
                            })) ?? []) as unknown as EditableListItem[]
                        }
                        formatItem={(item) =>
                            !item
                                ? null
                                : {
                                      regle: item.regle ?? null,
                                      typeDocument: item.id,
                                  }
                        }
                        placeholder="Sélectionnez le ou les document(s) lié(s) à cette valeur"
                        model={documentModel}
                        resetField={resetAsyncSelects}
                        disabled={!modeEdition}
                        overridableField={documentOverridableField}
                    />
                </Box>
            ),
        },
    ];

    const closeEdition = () => {
        setResetForm(true);
        setResetAsyncSelects(true);
        setModeEdition(false);
    };
    const openEdition = () => {
        setResetAsyncSelects(true);
        setModeEdition(true);
    };

    const addOptionValue = (data: OptionValue) => {
        const formOptionValues = parentFormContext.getValues().values ?? [];
        parentFormContext.setValue('values', [...formOptionValues, data]);
        setOptionValues([...optionValues, data]);
    };
    const updateOptionValue = (data: OptionValue) => {
        const formOptionValues = parentFormContext.getValues().values ?? [];
        const updatedFormValues = formOptionValues.filter(
            (value: TblOptionValueOptionRead) => value.id !== data.id,
        );
        parentFormContext.setValue('values', [...updatedFormValues, data]);
        setOptionValues([...updatedFormValues, data]);
    };

    const selectOptionValue = (optionValue: OptionValue) => {
        setSelectedOptionValue(optionValue);
        fillFormContext(optionValue);
        openEdition();
    };

    const unselectOptionValue = () => {
        setSelectedOptionValue(null);
        closeEdition();
    };

    const removeOptionValue = (optionValue: OptionValue) => {
        const formOptionValues = parentFormContext.getValues().values ?? [];
        parentFormContext.setValue(
            'values',
            formOptionValues.filter(
                (value: TblOptionValueOptionRead) => value.id !== optionValue.id,
            ),
        );
        setOptionValues(optionValues.filter(({ id }) => id !== optionValue.id));
        setResetForm(true);
    };

    const onCreateSuccess = ({ data }: { data: Record<string, unknown> }) => {
        addOptionValue(data);
        closeEdition();
    };

    const onUpdateSuccess = ({ data }: { data: Record<string, unknown> }) => {
        updateOptionValue(data);
        unselectOptionValue();
    };

    const updateModel = async () => {
        const formValues =
            formContext.getValues() as unknown as OptionValueJsonldOptionValueUpdate & {
                id: string;
            };

        const updateRequest = optionValueModel.update({
            id: formValues.id,
            optionValueJsonldOptionValueUpdate: formatFormValues(formValues),
            xIdSociete: companyId,
        });

        await request(updateRequest, {
            successCallback: onUpdateSuccess,
        });
    };

    const createModel = async () => {
        const formValues = formContext.getValues() as OptionValueJsonldOptionValueCreate;

        const createRequest = optionValueModel.create({
            optionValueJsonldOptionValueCreate: {
                ...formatFormValues(formValues),
                rang: 0,
            },
            xIdSociete: companyId,
        });

        await request(createRequest, {
            successCallback: onCreateSuccess,
        });
    };

    const handleSubmit = async () => {
        const validation = await formContext.trigger();
        if (!validation) {
            return;
        }

        if (selectedOptionValue) {
            updateModel();
        } else {
            createModel();
        }
    };

    const submitButton = {
        render: () => (
            <Box
                display="flex"
                justifyContent="right"
                width="100%"
            >
                <Button
                    sx={{ margin: '15px 15px 0px' }}
                    type="submit"
                    onClick={(event) => {
                        event.preventDefault();
                        setModeEdition(false);
                        unselectOptionValue();
                    }}
                    variant="outlined"
                    disabled={!modeEdition}
                >
                    Annuler
                </Button>
                <Button
                    sx={{ marginTop: '15px' }}
                    type="submit"
                    onClick={(event) => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                    variant="contained"
                    disabled={!modeEdition || isProcessing || currentError !== null}
                >
                    {`${selectedOptionValue ? 'Éditer la' : 'Ajouter une'} valeur`}
                </Button>
            </Box>
        ),
    };

    const optionValueForm = (
        <Box sx={{ opacity: `${!modeEdition ? 0.3 : 1}` }}>
            <FlexyForm
                formContext={formContext as never}
                formObject={{}}
                formStructure={optionValueFormStructure}
                onSubmit={handleSubmit}
                submitButton={submitButton}
            />
        </Box>
    );

    const optionValuesEditableList = (
        <Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ margin: '15px 0px' }}
            >
                <FaOptionIcon
                    {...offerOptionValueIcon.props}
                    size="xl"
                    sx={{ marginRight: '20px' }}
                />
                <Typography>Valeur(s) de l&apos;option</Typography>
            </Box>
            <Divider />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {optionValues.length === 0 && (
                    <p style={{ opacity: '0.4' }}>
                        (Ajoutez une valeur à l&apos;option pour en obtenir l&apos;aperçu)
                    </p>
                )}
            </Box>

            {optionValues.map((optionValue, index) => {
                const value = selectedOptionValue as { id: number };
                const rowKey = `optn_val_row_${value}_${index}`;
                return (
                    <OptionValueRow
                        key={rowKey}
                        selectedId={value && value.id}
                        rank={index + 1}
                        optionValue={optionValue}
                        onEdit={selectOptionValue}
                        onDelete={removeOptionValue}
                        onRollback={unselectOptionValue}
                    />
                );
            })}
        </Box>
    );

    const offerOption = {
        ...formContext.getValues(),
        values: optionValues,
    } as JsonLdData<OptionsJsonldOptionRead> & {
        values: OptionValueJsonldOptionValueCreate[];
    };

    const optionValuesPreview = <OfferOptionPreview entity={offerOption} />;

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            minHeight="350px"
            paddingTop="30px"
        >
            <Box sx={{ width: '60%' }}>{optionValueForm}</Box>
            <Divider
                orientation="vertical"
                flexItem
            />
            <Box
                sx={{ width: '32%' }}
                style={{ opacity: modeEdition ? 0.6 : 1 }}
            >
                {moduleMode === 'list' ? optionValuesEditableList : optionValuesPreview}
                {!modeEdition && <AddOptionButton onClick={() => setModeEdition(true)} />}
            </Box>
        </Box>
    );
};

export default OptionValuesModule;
