import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { FiltersDatatableList } from '@europrocurement/flexy-datatable';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { AppDispatch } from '@b2d/redux/types';
import { InvoiceStatementSelector, getInvoiceStatement } from '@b2d/redux/RootStore';

import {
    INVOICE_STATEMENT_SLICE_NAME,
    ReleveAchat,
} from '@europrocurement/l2d-domain/reducers/achats/slices/invoiceStatementSlice';
import UnclosedInvoiceStatementsList from '../../components/lists/UnclosedInvoiceStatementsList';

const UnclosedInvoiceStatementsView: React.FunctionComponent = function () {
    const dispatch = useDispatch<AppDispatch>();

    const statementDataSource: DataSource<ReleveAchat> = useSelector(InvoiceStatementSelector).main;

    const additionalFilters: FiltersDatatableList = [];

    const resetStatementFilters = useCallback(() => {
        dispatch({
            type: `${INVOICE_STATEMENT_SLICE_NAME}/resetmainFilter`,
        });
    }, [dispatch]);

    const applyClosedStatementFilters = useCallback(() => {
        dispatch({
            type: `${INVOICE_STATEMENT_SLICE_NAME}/setmainFilter`,
            payload: {
                key: 'cloture',
                value: false,
            },
        });
        dispatch(getInvoiceStatement({}));
    }, [dispatch]);

    useEffect(() => {
        resetStatementFilters();
        applyClosedStatementFilters();
    }, [applyClosedStatementFilters, resetStatementFilters]);

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/liste' },
                    'Relevés de facturation non clôturés',
                ]}
            />
            {statementDataSource.filters.cloture === false ? (
                <UnclosedInvoiceStatementsList additionalFilters={additionalFilters} />
            ) : null}
        </>
    );
};

export default UnclosedInvoiceStatementsView;
