import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, Button, ButtonGroup, Pagination, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftFromLine } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import {
    FactureAchatApiUpdateFactureAchatFactureAchatItemRequest,
    FactureAchatDossiersInner,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    CustomizerReducerType,
    FactureAchat,
    FactureAchatApiObject,
    Fournisseur,
    MediaObject,
    MediaObjectApiObject,
    setXIdSociete,
    useTiersService,
} from '@europrocurement/l2d-domain';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { CenterCircularProgress, FlexyTabs, HeaderSwitch } from '@europrocurement/flexy-components';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import {
    FactureachatSelector,
    factureAchatApi,
    selectFactureAchat,
    customizerSelector,
    MediaObjectSelector,
} from '@b2d/redux/RootStore';
import { RootStateType, AppDispatch } from '@b2d/redux/types';

import {
    useBackFromMediaObjectEdit,
    useNavigateToDernierDossier,
    useNavigateToMediaObjectsList,
} from '@b2d/pages/Achats/achatRouterHooks';

import { navigateToNewFacture, navigateToRecapStatement } from '@b2d/utils/navigationHelper';
import useUpdateTotals from '@b2d/pages/Achats/hooks/useUpdateTotals';
import useSwitchLockValues from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import useShowMessage from '@b2d/hooks/useShowMessage';
import { refuseFactureAchatModalMessages } from '../../../../constants/wording/modals';
import { DossierLinesAccordeon } from '../../../informationWidget/DossierLinesAccordeon';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import { NotesFacture } from '../../../widgets/NotesFacture';
import DossiersList from '../../../fragments/DossiersList';
import {
    useGetDossiersFromAchat,
    useGetLignes,
    useSynchroG3Facture,
} from '../../functions/dataHooks';
import { groupLinesByDossiers } from '../../functions/dossierFonctions';
import { getTotaux } from '../../functions/produitFunctions';
import { type FactureFormObject } from '../../types';
import { EnteteFacture } from '../EnteteFacture';
import { NotesMediaObject } from '../../../widgets/NotesMediaObject';
import useTotalsSectionStructure from '../../formElements/hooks/useTotalsSectionStructure';
import { ModeProps } from '../../formElements/types';
import formRecapTotalsToApiInvoicePurchase from '../../functions/dataTransformers/formToApi/formRecapTotalsToApiInvoicePurchase';
import { convertToNumberAndFormatToString } from '../../functions/calculsProduits';

export const RecapSaisieMultiple: React.FunctionComponent = function () {
    const { selectFournisseur } = useTiersService();
    const { factureachatid } = useParams();
    const { xIdSociete } = useSelector(customizerSelector);
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const onCancel = useBackFromMediaObjectEdit();
    const navigateToDernierDossier = useNavigateToDernierDossier();

    const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.main,
        shallowEqual,
    );

    const mediaObjectSelected: MediaObject | undefined =
        useSelector(MediaObjectSelector).main.selected;
    const factureAchatSelected: FactureAchat | undefined =
        useSelector(FactureachatSelector).main.selected;

    const formContext = useForm({
        mode: 'onTouched',
    });

    const { getSwitchLockValues, stateSwitchLockValues } = useSwitchLockValues();
    const { updateTotals } = useUpdateTotals({ formContext, stateSwitchLockValues });

    const [modalRefuseFactureAchatOpen, setModalRefuseFactureAchatOpen] = useState<boolean>(false);

    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }

    // const [dossiers, setDossiers] = useState<DossierApiObject[]>([]);
    const [dossiersAchat, setDossiersAchat] = useState<FactureAchatDossiersInner[]>([]);
    const [dossiersAchatStatus, setDossiersAchatStatus] =
        useState<EuroprocApiResponseStatus>('idle');
    const [lignes, setLignes] = useState<FactureAchatLigneJsonldFactureAchatRead[]>([]);
    const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');

    const [grouped, setGrouped] = useState<{
        [x: string]: FactureAchatLigneJsonldFactureAchatRead[];
    }>({});

    const [page, setPage] = useState<number>(1);
    const [itemPerPage] = useState<number>(5);

    const getDossiersFromAchat = useGetDossiersFromAchat();
    const getLignes = useGetLignes();
    const synchroG3Facture = useSynchroG3Facture();
    const navigateToList = useNavigateToMediaObjectsList();
    const showMessage = useShowMessage();

    const modeTotals = useMemo<ModeProps>(
        () => ({
            type: 'register',
            nature: 'multiple',
            lock: stateSwitchLockValues.totals.value,
        }),
        [stateSwitchLockValues.totals.value],
    );

    const totalsSwitchesList = useMemo<Array<HeaderSwitch>>(() => {
        const component = [];

        if (!factureAchatSelected?.syncCegid) {
            component.push(
                getSwitchLockValues({
                    sectionToBlock: 'totals',
                    overwriteAction: () => {
                        updateTotals({
                            reasonToTriggerConfirmationModal: !stateSwitchLockValues.totals.value,
                        });
                    },
                }),
            );
        }

        return component;
    }, [
        factureAchatSelected?.syncCegid,
        getSwitchLockValues,
        stateSwitchLockValues.totals.value,
        updateTotals,
    ]);

    const { totalsSectionStructure } = useTotalsSectionStructure({
        mode: modeTotals,
        headerSwitches: totalsSwitchesList,
        isMulti: false,
    });

    useEffect(() => {
        if (
            !factureachatid ||
            dossiersAchatStatus === 'loading' ||
            dossiersAchatStatus === 'succeeded'
        ) {
            return;
        }

        setDossiersAchatStatus('loading');
        getDossiersFromAchat(+factureachatid).then((data) => {
            setDossiersAchatStatus('succeeded');
            setDossiersAchat(data);
        });
    }, [dossiersAchatStatus, factureachatid, getDossiersFromAchat]);

    useEffect(() => {
        if (
            dossiersAchat &&
            dossiersAchat.length > 0 &&
            factureAchatSelected &&
            factureAchatSelected.id &&
            lignesStatus !== 'loading' &&
            lignesStatus !== 'succeeded'
        ) {
            const ids = dossiersAchat
                .map((item: FactureAchatDossiersInner) => item.idDossier)
                .filter((id: number | undefined) => id !== undefined)
                .slice(itemPerPage * (page - 1), itemPerPage * page);

            setLignesStatus('loading');
            getLignes(factureAchatSelected.id, ids as number[]).then((data) => {
                setLignesStatus('succeeded');
                setLignes(data);
            });
        }
    }, [dossiersAchat, factureAchatSelected, getLignes, itemPerPage, lignesStatus, page]);

    /**
     * S'il existe une facture d'achat,
     * alors additionner les valeurs des lignes de produits de chaque dossier,
     * puis les enregistrer dans le formulaire.
     */
    useEffect(() => {
        if (!factureAchatSelected) return;

        const totaux = getTotaux(factureAchatSelected);
        if (factureAchatSelected.avoir === true) {
            formContext.setValue('total_ht', convertToNumberAndFormatToString(-totaux.ht));
            formContext.setValue('total_tva', convertToNumberAndFormatToString(-totaux.tva));
            formContext.setValue('total_ttc', convertToNumberAndFormatToString(-totaux.ttc));
        } else {
            formContext.setValue('total_ht', convertToNumberAndFormatToString(totaux.ht));
            formContext.setValue('total_tva', convertToNumberAndFormatToString(totaux.tva));
            formContext.setValue('total_ttc', convertToNumberAndFormatToString(totaux.ttc));
        }
    }, [factureAchatSelected, formContext]);

    /**
     * Si la facture d'achat a un idSociete,
     * alors le définir comme xIdSociete de l'utilisateur
     */
    useEffect(() => {
        if (factureAchatSelected?.idSociete) {
            dispatch(
                setXIdSociete(
                    factureAchatSelected?.idSociete as CustomizerReducerType['xIdSociete'],
                ),
            );
        }
    }, [dispatch, factureAchatSelected?.idSociete]);

    /**
     * Si le paramètre factureachatid est trouvé,
     * alors sélectionner la facture d'achat correspondante.
     */
    useEffect(() => {
        if (factureachatid && factureAchatSelected?.id !== +factureachatid) {
            setTimeout(() => {
                dispatch(selectFactureAchat({ id: +factureachatid }));
            }, 250);
        }
    }, [dispatch, factureAchatSelected?.id, factureachatid]);

    /**
     * ??
     * S'il existe un fournisseur lié à la facture d'achat,
     * alors sélectionner le fournisseur.
     */
    useEffect(() => {
        if (!factureAchatSelected) return;

        setGrouped(groupLinesByDossiers(lignes));

        if (factureAchatSelected.idEntiteFacturante && !fournisseursDataSource.selected) {
            dispatch(selectFournisseur({ id: +factureAchatSelected.idEntiteFacturante }));
        }
    }, [
        dispatch,
        factureAchatSelected,
        fournisseursDataSource.selected,
        lignes,
        selectFournisseur,
    ]);

    /**
     * Formate le formulaire avant de mettre à jour les infos de la facture.
     * Si la mise à jour est un succès, alors l'utilisateur est envoyé vers
     * la liste des MediaObjects.
     *
     * @param factureForm
     * @returns void
     */
    const handleSubmit = async function (factureForm: FactureFormObject) {
        if (!factureachatid || !factureAchatSelected || !factureAchatSelected['@id']) {
            return;
        }

        const factureToPost = formRecapTotalsToApiInvoicePurchase(
            factureForm,
            factureAchatSelected,
            stateSwitchLockValues.totals.value,
        );

        const requestParameters: FactureAchatApiUpdateFactureAchatFactureAchatItemRequest = {
            xIdSociete,
            id: factureachatid,
            factureAchatJsonldFactureAchatWrite: factureToPost,
        };

        await factureAchatApi.updateFactureAchatFactureAchatItem(requestParameters);

        const resSync = await synchroG3Facture(factureachatid);

        if (!resSync) {
            enqueueSnackbar(<Typography>La synchronisation G3 a échouée !</Typography>, {
                variant: 'error',
            });
        }

        if (factureToPost.idReleveAchat && factureForm.goToNext) {
            navigateToNewFacture({
                showMessage,
                dispatch,
                navigate,
                navigateToList,
                idCurrentMediaObject: !factureToPost.pdfFacture
                    ? undefined
                    : factureToPost.pdfFacture,
                idStatement: !factureToPost.idReleveAchat ? undefined : factureToPost.idReleveAchat,
            });
        } else if (factureToPost.idReleveAchat && !factureForm.goToNext) {
            navigateToRecapStatement(navigate, factureToPost.idReleveAchat);
        } else {
            navigateToList();
        }
    };

    const changePage = useCallback((newPage: number) => {
        setLignes([]);
        setLignesStatus('idle');
        setPage(newPage);
    }, []);

    const factureFormStructure: FormStructure[] = useMemo(
        () => [...totalsSectionStructure],
        [totalsSectionStructure],
    );

    // TODO: Find a way to know which group of d
    // const colorPickDependingOnNotesAvailability: "primary" | "black" = (groupLibelle: string) => {

    //     return "primary";
    // }

    const listOfGroupedLinesByFiles = useCallback(
        (mediaObjectToUse: MediaObjectApiObject, factureAchatToUse: FactureAchatApiObject) =>
            Object.keys(grouped).map((libelle) => (
                <DossierLinesAccordeon
                    mediaObject={mediaObjectToUse}
                    facture={factureAchatToUse}
                    key={`${libelle}`}
                    lines={grouped[libelle]}
                    displayName={libelle}
                />
            )),
        [grouped],
    );

    return !mediaObjectSelected || !factureAchatSelected || !fournisseursDataSource.selected ? (
        <CenterCircularProgress sx={{ height: '500px' }} />
    ) : (
        <>
            <ConfirmationModal
                isModalOpen={modalRefuseFactureAchatOpen}
                closeModal={() => {
                    setModalRefuseFactureAchatOpen(false);
                }}
                messages={refuseFactureAchatModalMessages}
                actionOnValidation={() => {
                    onCancel();
                    setModalRefuseFactureAchatOpen(false);
                }}
                actionOnCancellation={() => {
                    setModalRefuseFactureAchatOpen(false);
                }}
            />

            <EnteteFacture
                invoice={factureAchatSelected}
                publisher={fournisseursDataSource.selected}
                headerControllers={
                    <ButtonGroup
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px',
                            flex: 1,
                        }}
                    >
                        <DossiersList
                            facture={factureAchatSelected}
                            dossiers={dossiersAchat}
                        />
                    </ButtonGroup>
                }
            />

            <FlexyTabs
                scrollbar={false}
                tabs={[
                    {
                        tabName: 'dossiers',
                        tabTitle: 'Dossiers',
                        tabPanel: (
                            <>
                                {listOfGroupedLinesByFiles(
                                    mediaObjectSelected,
                                    factureAchatSelected,
                                )}
                                <Stack
                                    alignItems="center"
                                    sx={{ marginTop: '15px' }}
                                >
                                    <Pagination
                                        page={page}
                                        count={Math.ceil(dossiersAchat.length / itemPerPage)}
                                        onChange={(
                                            event: React.ChangeEvent<unknown>,
                                            newPage: number,
                                        ) => {
                                            changePage(newPage);
                                        }}
                                    />
                                </Stack>
                            </>
                        ),
                    },
                    {
                        tabName: 'notes-generales',
                        tabTitle: 'Notes générales',
                        tabPanel: (
                            <>
                                <NotesMediaObject title="Notes de traitement du PDF" />
                                <NotesFacture
                                    title="Notes de la facture"
                                    facture={factureAchatSelected}
                                    displayNoteAllDossiers={false}
                                />
                            </>
                        ),
                    },
                ]}
            />

            <FlexyForm
                formObject={{}}
                formStructure={factureFormStructure}
                onSubmit={(formData: FactureFormObject) => {
                    handleSubmit(formData);
                }}
                formContext={formContext}
                submitButton={{
                    render: ({ isSubmitSuccessful, isSubmitting, isLoading, isValidating }) => (
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <Button
                                key={`reprendre-saisie-${faLeftFromLine}`}
                                color="primary"
                                disabled={
                                    isSubmitSuccessful || isSubmitting || isLoading || isValidating
                                }
                                variant="outlined"
                                endIcon={<FontAwesomeIcon icon={faLeftFromLine} />}
                                onClick={() => {
                                    navigateToDernierDossier(factureAchatSelected);
                                }}
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                Reprendre la saisie
                            </Button>
                            {factureAchatSelected.idReleveAchat ? (
                                <Button
                                    key={`recap-statement-${faCheck}`}
                                    color="primary"
                                    disabled={
                                        isSubmitSuccessful ||
                                        isSubmitting ||
                                        isLoading ||
                                        isValidating
                                    }
                                    variant="contained"
                                    endIcon={<FontAwesomeIcon icon={faCheck} />}
                                    onClick={() => {
                                        formContext.handleSubmit((factureForm) => {
                                            const cloneFactureToSubmit = { ...factureForm };

                                            if (!stateSwitchLockValues.totals.value) {
                                                cloneFactureToSubmit.ht =
                                                    formContext.getValues('total_ht');
                                                cloneFactureToSubmit.tva =
                                                    formContext.getValues('total_tva');
                                                cloneFactureToSubmit.ttc =
                                                    formContext.getValues('total_ttc');
                                            }
                                            handleSubmit(cloneFactureToSubmit as FactureFormObject);
                                        })();
                                    }}
                                    style={{
                                        marginTop: '16px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    Récapitulatif de Relevé
                                </Button>
                            ) : null}
                            <Button
                                key={`valider-facture-${faArrowRight}`}
                                color="primary"
                                disabled={
                                    isSubmitSuccessful || isSubmitting || isLoading || isValidating
                                }
                                variant={
                                    factureAchatSelected.idReleveAchat ? 'outlined' : 'contained'
                                }
                                endIcon={
                                    <FontAwesomeIcon
                                        icon={
                                            factureAchatSelected.idReleveAchat
                                                ? faArrowRight
                                                : faCheck
                                        }
                                    />
                                }
                                onClick={() => {
                                    formContext.handleSubmit((factureForm) => {
                                        const cloneFactureToSubmit = { ...factureForm };
                                        cloneFactureToSubmit.goToNext = true;

                                        if (!stateSwitchLockValues.totals.value) {
                                            cloneFactureToSubmit.ht =
                                                formContext.getValues('total_ht');
                                            cloneFactureToSubmit.tva =
                                                formContext.getValues('total_tva');
                                            cloneFactureToSubmit.ttc =
                                                formContext.getValues('total_ttc');
                                        }
                                        handleSubmit(cloneFactureToSubmit as FactureFormObject);
                                    })();
                                }}
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                {factureAchatSelected.idReleveAchat
                                    ? 'Ajouter Facture au Relevé'
                                    : "Valider la facture d'achat"}
                            </Button>
                        </Box>
                    ),
                }}
            />
        </>
    );
};

export default RecapSaisieMultiple;
