import {
    jsonBooleanInput,
    jsonCustom,
    jsonDateInput,
    jsonDatetimeInput,
    jsonDayMonthInput,
    jsonHeader,
    jsonRadioInput,
    jsonSection,
    jsonSelectInput,
    jsonSeparator,
    jsonSubForm,
    jsonTextInput,
} from '../types/formTypes';
import {
    jsonTextInputToFormElement,
    jsonBooleanInputToFormElement,
    jsonHeaderToFormElement,
    jsonSeparatorToFormElement,
    jsonSelectInputToFormElement,
    jsonDateInputToFormElement,
    jsonRadioInputToFormElement,
    jsonDatetimeInputToFormElement,
} from '../inputs';

import { CustomItemFormStructure, FormStructure } from '../../components';
// eslint-disable-next-line import/no-cycle
import jsonSubformToStructure from './jsonSubformToStructure';
import jsonCustomToFormElement from '../inputs/jsonCustomToFormElement';
import jsonDayMonthInputToFormElement from '../inputs/jsonDayMonthInputToFormElement';

export const renderError: (message: string) => FormStructure = (message) => ({
    type: 'header',
    label: message,
    name: 'error_message',
});

const jsonPageSectionToStructure: (
    section: jsonSection,
    customItemRenderer: CustomItemFormStructure['renderField'],
) => FormStructure = (section, customItemRenderer) => {
    if (!section.type) {
        console.warn('pas de type sur cette section');
        // passer en textinfo
        return renderError(
            `Impossible d'afficher l'élément, pas de type ${JSON.stringify(section)}`,
        );
    }
    if (!section.name) {
        console.warn('pas de name sur cette section');
        // passer en textinfo
        return renderError(
            `Impossible d'afficher l'élément, pas de name ${JSON.stringify(section)}`,
        );
    }

    switch (section.type) {
        case 'header':
            return jsonHeaderToFormElement(section as jsonHeader);
        case 'separator':
            return jsonSeparatorToFormElement(section as jsonSeparator);
        case 'textinfo':
            return renderError(`To Implement`);
        case 'text':
            return jsonTextInputToFormElement(section as jsonTextInput);
        case 'date':
            return jsonDateInputToFormElement(section as jsonDateInput);
        case 'datetime':
            return jsonDatetimeInputToFormElement(section as jsonDatetimeInput);
        case 'dayMonth':
            return jsonDayMonthInputToFormElement(section as jsonDayMonthInput);
        case 'boolean':
            return jsonBooleanInputToFormElement(section as jsonBooleanInput);
        case 'radio':
            return jsonRadioInputToFormElement(section as jsonRadioInput);
        case 'select':
            return jsonSelectInputToFormElement(section as jsonSelectInput);

        case 'subform':
            return jsonSubformToStructure(section as jsonSubForm, customItemRenderer);
        case 'custom':
            return jsonCustomToFormElement(section as jsonCustom, customItemRenderer);

        default:
            return renderError(`élément non pris en charge :  ${section.type}`);
    }
};

export default jsonPageSectionToStructure;
