import {
    ChampComplmentaireApi,
    ChampComplMentaireChampcomplementaireCreate,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { additionalFieldIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { ModelConfiguration, ModelItem, ModelMethods } from '@europrocurement/l2d-domain/types';
import { OFFER_ADDITIONAL_FIELDS_SLICE_NAME } from '../constants';
import { baseAdditionalFieldsSelector } from '../selectors';

const modelKey = 'additional_field';
const modelName = 'AdditionalField';
const sliceName = OFFER_ADDITIONAL_FIELDS_SLICE_NAME;

type ModelStructure = ChampComplMentaireChampcomplementaireCreate;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
    getItemSecondaryLabel: (model: ModelStructure) => `${model.description}`,
};

export type ChampComplMentairEendpoints = {
    create: InstanceType<typeof ChampComplmentaireApi>['apiChampsComplementairesPost'];
    read: InstanceType<typeof ChampComplmentaireApi>['apiChampsComplementairesIdGet'];
    update: InstanceType<typeof ChampComplmentaireApi>['apiChampsComplementairesIdPut'];
    delete: InstanceType<typeof ChampComplmentaireApi>['apiChampsComplementairesIdDelete'];
};

type ModelType = ModelItem<ModelStructure> & ChampComplMentairEendpoints;

export type AdditionalFieldModel = ModelType;

export const buildAdditionalFieldModel = (
    api: ChampComplmentaireApi,
    datasourcesThunks: DataSourcesThunksType<ChampComplMentaireChampcomplementaireCreate>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Champ Complémentaire',
        sliceName,
        selector: baseAdditionalFieldsSelector,
        api,
        datasourcesThunks,
        icon: additionalFieldIcon,
    };

    const endpoints: ChampComplMentairEendpoints = {
        create: api.apiChampsComplementairesPost.bind(api),
        read: api.apiChampsComplementairesIdGet.bind(api),
        update: api.apiChampsComplementairesIdPut.bind(api),
        delete: api.apiChampsComplementairesIdDelete.bind(api),
    };

    const model: AdditionalFieldModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
