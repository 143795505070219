import { TypedUseSelectorHook, useSelector } from 'react-redux';
import {
    UTILISATEUR_SLICE_NAME,
    UTILISATEUR_REDUCER_NAME,
    SERVICES_SLICE_NAME,
    POLES_SLICE_NAME,
    MARQUES_SLICE_NAME,
    GROUPES_SLICE_NAME,
} from './constants';
import { UtilisateursStateType } from './types';

export const utilisateursSelector = (s: UtilisateursStateType) =>
    s[UTILISATEUR_REDUCER_NAME][UTILISATEUR_SLICE_NAME].main;

export const useUtilisateursServiceSelector: TypedUseSelectorHook<UtilisateursStateType> =
    useSelector;

export const servicesSelector = (s: UtilisateursStateType) =>
    s[UTILISATEUR_REDUCER_NAME][SERVICES_SLICE_NAME].main;

export const polesSelector = (s: UtilisateursStateType) =>
    s[UTILISATEUR_REDUCER_NAME][POLES_SLICE_NAME].main;

export const marquesSelector = (s: UtilisateursStateType) =>
    s[UTILISATEUR_REDUCER_NAME][MARQUES_SLICE_NAME].main;

export const groupesSelector = (s: UtilisateursStateType) =>
    s[UTILISATEUR_REDUCER_NAME][GROUPES_SLICE_NAME].main;
