import React, { ReactNode, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

export type ClipboardLineProps = {
    label: string;
    value?: ReactNode;
    overridedValue?: string;
};

/** Display key value pair with following specs :
 * - Value can be copied to clipboard
 *   - A dash is displayed for nullish value ( can't be copied to clipboard )
 *   */
const ClipboardLine: React.FunctionComponent<ClipboardLineProps> = function (props) {
    const { label, value, overridedValue } = props;
    const getValue = () => {
        if (value && value !== '') {
            return value;
        }
        return null;
    };
    const [copied, setCopied] = useState(false);
    const getCopiedValue = () => {
        if (value && typeof value === 'string' && value !== '') {
            return value;
        }
        if (overridedValue && overridedValue !== '') {
            return overridedValue;
        }

        return null;
    };
    const copyValueToClipBoard = async () => {
        const copiedValue = getCopiedValue();
        if (!copiedValue || typeof copiedValue !== 'string') {
            return;
        }
        try {
            await navigator.clipboard.writeText(copiedValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 2500);
        } catch (err) {
            console.error('Failed to copy text: ', err);
            setCopied(false);
        }
    };
    const wrapper = {
        display: ' flex',
        alignItems: 'center',
        width: '50%',
    };

    const copiedFeedbackStyle = {
        display: 'flex',
        backgroundColor: 'lightgray',
        padding: '10px',
        borderRadius: '5px',
        opacity: copied ? 0.4 : 0,
        transition: 'opacity 1s',
    };

    return (
        <Box display="flex">
            <Typography sx={{ ...wrapper, opacity: 0.7 }}>{label}</Typography>
            <Box sx={wrapper}>
                <Typography sx={{ fontSize: '16px' }}>{getValue() ?? '-'}</Typography>
                {value && (
                    <IconButton onClick={copyValueToClipBoard}>
                        <FaOptionIcon
                            icon={faCopy}
                            sx={{ opacity: 0.3, fontSize: '14px' }}
                        />
                    </IconButton>
                )}
                <Box sx={copiedFeedbackStyle}>
                    <FaOptionIcon
                        icon={faCheck}
                        sx={{ opacity: 0.5 }}
                        size="xs"
                    />
                    <Typography>Copié</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default ClipboardLine;
