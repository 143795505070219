import React from 'react';
import { Card, Grid, Typography, useTheme } from '@mui/material';
import { UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { BooleanBadge } from '@europrocurement/flexy-components';
import { User } from '../UserTabs/type';

type UserAnnuaireProps = {
    user: User;
    handleValidationModal: (
        payload: UtilisateursUtilisateurUpdateUtilisateurUtilisateurPatchUtilisateur,
        field: string,
        value: boolean,
    ) => void;
};

export const UserAnnuaire: React.FunctionComponent<UserAnnuaireProps> = function ({
    user,
    handleValidationModal,
}) {
    const theme = useTheme();

    return (
        <Card
            sx={{
                width: '100%',
                margin: '15px 0',
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography
                variant="l2dh6"
                sx={{
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                    padding: '5px 0 5px 30px',
                    marginLeft: '-15px',
                }}
            >
                Annuaire
            </Typography>
            <Grid
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '15px',
                    width: '100%',
                    margin: '10px',
                }}
            >
                <BooleanBadge
                    label="Annuaire - Droit de lecture"
                    value={user.droitUtilisateur?.usdAppsAnnuaire}
                    icon
                    toggleAction={() =>
                        handleValidationModal(
                            {
                                droitUtilisateur: {
                                    usdAppsAnnuaire: !user.droitUtilisateur?.usdAppsAnnuaire,
                                },
                            },
                            'usdAppsAnnuaire',
                            user.droitUtilisateur?.usdAppsAnnuaire as boolean,
                        )
                    }
                />

                <BooleanBadge
                    label="Annuaire - Droit de modification"
                    value={user.droitUtilisateur?.usdAppsAnnuaireOption}
                    icon
                    toggleAction={() =>
                        handleValidationModal(
                            {
                                droitUtilisateur: {
                                    usdAppsAnnuaireOption:
                                        !user.droitUtilisateur?.usdAppsAnnuaireOption,
                                },
                            },
                            'usdAppsAnnuaireOption',
                            user.droitUtilisateur?.usdAppsAnnuaireOption as boolean,
                        )
                    }
                />
            </Grid>
        </Card>
    );
};
