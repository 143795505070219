import React, { PropsWithChildren } from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

export type InfoCardProps = {
    label: string;
    sx?: SxProps;
};

const InfoCard: React.FunctionComponent<PropsWithChildren<InfoCardProps>> = function ({
    label,
    sx = {},
    children,
}) {
    const { palette } = useTheme();

    const labelSx = {
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '19.2px',
        color: palette.grey[500],
    };

    return (
        <Box sx={sx}>
            <Typography
                component="span"
                sx={labelSx}
            >
                {label}
            </Typography>
            <Box
                sx={{ minHeight: '48px', display: 'flex', alignItems: 'center', padding: '8px 0' }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default InfoCard;
