import useExistingPrescribers from '@b2d/pages/Prescripteurs/hooks/useExistingsPrescribers';
import { isLuhnValid } from '@europrocurement/l2d-modules/validation';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';

/*
 * Siret Hook content :
 * 1 - Siret validation rules
 * 2 - Related fields behaviour (Siren / intra VAT)
 * 3 - Fetch existing prescribers at validation
 */

type SiretStatus = {
    isValid: boolean;
    isEmpty: boolean;
    value: string;
};

const defaultSiretInitialState = {
    isValid: true,
    isEmpty: true,
    value: '',
};

const getSirenFromSiret = (siretStatus: SiretStatus) => {
    if (siretStatus.isEmpty || !siretStatus.isValid) {
        return '';
    }
    return siretStatus.value.substring(0, 9);
};

const getIntraVatValue = (siretStatus: SiretStatus) => {
    if (siretStatus.isEmpty || !siretStatus.isValid) {
        return '';
    }

    const siren = getSirenFromSiret(siretStatus);

    const intraVatKey = (12 + ((3 * parseInt(siren, 10)) % 97)) % 97;
    const intraVatValue = `FR${intraVatKey.toString()}${siren}`;

    return intraVatValue;
};

export const useSiret = (
    formContext: UseFormReturn,
    initialState: SiretStatus = defaultSiretInitialState,
) => {
    const [siretStatus, setSiretStatus] = React.useState<SiretStatus>(initialState);
    const siret = siretStatus.value;
    const { prescribersRelatedToSiret, getExistingPrescribers, prescribersLoaded } =
        useExistingPrescribers(siret);

    const siren = getSirenFromSiret(siretStatus);

    const intraVatValue = getIntraVatValue(siretStatus);

    const resetRelatedFormContextFields = React.useCallback(() => {
        formContext.setValue('siren', '');
        formContext.setValue('tvaIntracommunautaire', '');
    }, [formContext]);

    // Set formContext related valuesrelated values
    const setRelatedFormContextFields = React.useCallback(() => {
        formContext.setValue('siren', siren);
        formContext.setValue('tvaIntracommunautaire', intraVatValue);
    }, [formContext, intraVatValue, siren]);

    React.useEffect(() => {
        if (siretStatus.isValid && !siretStatus.isEmpty) {
            getExistingPrescribers(siret);
            setRelatedFormContextFields();
        }
        if (siretStatus.isValid && siretStatus.isEmpty) {
            resetRelatedFormContextFields();
        }
    }, [
        getExistingPrescribers,
        prescribersRelatedToSiret,
        resetRelatedFormContextFields,
        setRelatedFormContextFields,
        siret,
        siretStatus,
    ]);

    // SIRET related values compuration (SIREN & INTRAVAT)

    // Validation logic (SIRET)

    const siretValidation = (siretToValidate: string) => {
        if (siretToValidate === siretStatus.value) {
            return;
        }

        const isSiretValid =
            siretToValidate === '' ||
            (isLuhnValid(siretToValidate) && siretToValidate.length === 14);

        setSiretStatus({
            value: siretToValidate,
            isEmpty: siretToValidate === '',
            isValid: isSiretValid,
        });
    };

    // FormContext mutators

    return {
        siretStatus,
        siren,
        setSiretStatus,
        defaultSiretInitialState,
        siretValidation,
        prescribersRelatedToSiret,
        intraVatValue,
        prescribersLoaded,
    };
};

export default useSiret;
