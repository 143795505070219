import {
    ActionDetailActionDetailRead,
    ActionDetailApi,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { actionIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { baseActionsSelector } from '../selectors';
import {
    type ModelItem,
    type ActionApiObject,
    type ModelConfiguration,
    type ModelMethods,
} from '../../..';

import { OFFER_ACTIONS_SLICE_NAME } from '../constants';

const modelKey = 'action';
const modelName = 'Action';
const sliceName = OFFER_ACTIONS_SLICE_NAME;

type ModelStructure = ActionDetailActionDetailRead;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
};

export type ActionEndpoints = {
    list: InstanceType<typeof ActionDetailApi>['apiActionDetailsGetCollection'];
    get: InstanceType<typeof ActionDetailApi>['apiActionDetailsIdGet'];
};

export type ActionModel = ModelItem<ModelStructure> & ActionEndpoints;

export const buildActionModel = (
    api: ActionDetailApi,
    datasourcesThunks: DataSourcesThunksType<ActionApiObject>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Actions',
        sliceName,
        api,
        selector: baseActionsSelector,
        datasourcesThunks,
        icon: actionIcon,
    };

    const endpoints: ActionEndpoints = {
        list: api.apiActionDetailsGetCollection.bind(api),
        get: api.apiActionDetailsIdGet.bind(api),
    };

    const model: ActionModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
