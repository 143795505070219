import React, { useContext } from 'react';
import {
    DetailDossierTitle,
    DetailDossierToolbar,
    SelectedDossierContext,
} from '@europrocurement/l2d-modules';
import { useDossierDetail } from '../../hooks';

const DetailDossierHeader: React.FunctionComponent = function () {
    const { dossier } = useContext(SelectedDossierContext);
    const { getAvailableActions } = useDossierDetail();

    return (
        <>
            <DetailDossierToolbar
                id={dossier.id as number}
                reference={dossier.numero as string}
                orderId={dossier.collectionId as number}
                orderReference={dossier.collectionNumero as string}
                createdAt={dossier.dateCreation as string}
                actions={getAvailableActions(dossier)}
            />
            <DetailDossierTitle
                color="primary"
                title={dossier.societeDenomination as string}
                statusName={dossier.statutLibelle as string}
                clientReference={dossier.societeRef as string}
                prescriberName={dossier.prescripteurLibelle as string}
                clientName={dossier.clientOpereLibelle as string}
            />
        </>
    );
};

export default DetailDossierHeader;
