import React from 'react';
import { CardSection, CardHeader, AddressLink } from '@europrocurement/flexy-components';
import { Box, Typography } from '@mui/material';
import { AdresseTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { formatPrescriberAddress } from '@europrocurement/l2d-utils';
import ClipboardLine from '../components/ClipboardLine';
import { PrescriberTabProps } from './types';
import ContactList from '../components/ContactList';

const Contacts: React.FunctionComponent<PrescriberTabProps> = function (props) {
    const { prescriber } = props;
    const { contacts } = prescriber;
    const activeContacts = contacts.filter((contact) => contact.isExit === false);
    const inactiveContacts = contacts.filter((contact) => contact.isExit);

    const mainAddress = prescriber?.adresses?.find((address) => address.defaut === true);

    const informationHeader = (
        <CardHeader
            title="Informations générales"
            border={false}
        />
    );

    const getAddressLabel = (address?: AdresseTiersRead) => {
        if (!address) {
            return '';
        }
        const isDefaultAddress = address.defaut;
        const isBillingAddress = address.facturation;
        if (isDefaultAddress && isBillingAddress) return 'Adresse Principale et de Facturation';
        if (isDefaultAddress && !isBillingAddress) return 'Adresse Principale';
        if (!isDefaultAddress && isBillingAddress) return 'Adresse de Facturation';
        return '';
    };

    const copyableLines: Array<{ label: string; value?: string | null }> = [
        {
            label: 'SIRET',
            value: prescriber.siret,
        },
        {
            label: 'SIREN',
            value: prescriber.siren,
        },
        {
            label: 'TVA Intracommunataire',
            value: prescriber.tvaIntracommunautaire,
        },
        {
            label: 'Régime TVA',
            value: prescriber.regimeTva,
        },
    ];

    const societeDotComLinkStyle = {
        color: 'inherit',
        textDecoration: 'inherit',
    };

    return (
        <Box sx={{ paddingRight: '5px' }}>
            <CardSection header={informationHeader}>
                {mainAddress && (
                    <ClipboardLine
                        label={getAddressLabel(mainAddress)}
                        value={<AddressLink address={mainAddress} />}
                        overridedValue={formatPrescriberAddress(mainAddress, 'short')}
                    />
                )}
                {copyableLines.map(({ label, value }, index) => {
                    const key = `prescriber_contacts_infoline_${index}`;
                    return (
                        <ClipboardLine
                            key={key}
                            label={label}
                            value={value}
                        />
                    );
                })}

                {prescriber.urlSocieteDotCom && (
                    <a
                        href={prescriber.urlSocieteDotCom}
                        target="_blank"
                        rel="noreferrer"
                        style={societeDotComLinkStyle}
                    >
                        <Typography
                            fontSize={18}
                            color="#177BC2"
                            sx={{ marginTop: '15px' }}
                        >
                            {'Fiche société.com >'}
                        </Typography>
                    </a>
                )}
            </CardSection>
            <ContactList
                contacts={activeContacts}
                sectionTitle="Contacts principaux"
                type="active"
                {...props}
            />
            <ContactList
                contacts={inactiveContacts}
                sectionTitle="Contacts inactifs"
                type="inactive"
                {...props}
            />
        </Box>
    );
};

export default Contacts;
