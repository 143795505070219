import { useContext } from 'react';
import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import { DossierActionContext } from '@europrocurement/l2d-modules';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const useDossierDetail = () => {
    const { triggerAction } = useContext(DossierActionContext);

    const getAvailableActions = (dossier: DossierOpenSearch) => [
        {
            label: 'Envoyer un récapitulatif',
            icon: ['fasl', 'paper-plane'] as IconProp,
            isAdditional: false,
            onClick: () => triggerAction('export', [dossier]),
        },
    ];

    return { getAvailableActions };
};

export default useDossierDetail;
