import * as React from 'react';
import { Button, PaletteColor, useTheme } from '@mui/material';
import { FaOptionIcon, FaOptionIconProps } from '@europrocurement/l2d-icons';
import './L2DFSidebarAction.css';
import { Box } from '@mui/system';
import TextIcon from '../../atoms/TextIcon';

export type L2DFSidebarActionProps = {
    label: string;
    icon: FaOptionIconProps;
    folded?: boolean;
    color?: PaletteColor;
    onClick: () => void;
};

const L2DFSidebarAction: React.FunctionComponent<L2DFSidebarActionProps> = function (props) {
    const theme = useTheme();
    const { label, icon, folded = false, color, onClick } = props;
    const mainColor = color || theme.palette.primary;
    const textColor = theme.palette.background.paper;
    const classNames = ['l2df-sidebar-action'];

    if (folded) {
        classNames.push('l2df-sidebar-action-folded');
        icon.size = 'lg';
    }

    return (
        <Button
            className={classNames.join(' ')}
            data-testid="l2df-sidebar-action"
            onClick={onClick}
            sx={{
                backgroundColor: mainColor.dark,
                padding: folded ? '13px 24px 11px 24px ' : '16px 24px 16px 24px ',
                color: textColor,
                margin: folded ? '0 8px' : '0 20px',
                verticalAlign: 'middle',
                ':hover': { backgroundColor: mainColor.main },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    verticalAlign: 'middle',
                }}
            >
                {folded ? (
                    <FaOptionIcon {...icon} />
                ) : (
                    <TextIcon
                        gap="8px"
                        icon={{
                            props: {
                                icon: icon.icon,
                            },
                        }}
                    >
                        {label}
                    </TextIcon>
                )}
            </Box>
        </Button>
    );
};

export default L2DFSidebarAction;
