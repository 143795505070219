import React from 'react';

import { MenuEntry } from '@europrocurement/flexy-components';

import { utilisateursBasePath, utilisateursListePath } from './constants/paths';

import UtilisateursList from './views/Utilisateurs/UtilisateursList';
import UtilisateurFiche from './views/Utilisateurs/UtilisateurFiche';
import { UtilisateursOutlet } from './UtilisateursOutlet';

const UtilisateursRouter = {
    path: utilisateursBasePath,
    element: <UtilisateursOutlet />,
    children: [
        {
            path: utilisateursListePath,
            children: [
                {
                    path: '',
                    index: true,
                    element: <UtilisateursList />,
                },
                {
                    path: ':idUtilisateur',
                    element: <UtilisateurFiche />,
                },
            ],
        },
    ],
};

// Administration Sub-menu entry.
export const UtilisateursMenuEntries: MenuEntry[] = [
    {
        title: 'Gestion des Utilisateurs',
        icon: 'users',
        role: 'realm:interne',
        children: [
            {
                title: 'Utilisateurs',
                icon: 'user',
                href: `${utilisateursBasePath}/${utilisateursListePath}`,
            },
        ],
    },
];

export default UtilisateursRouter;
