import React, { useEffect } from 'react';

import { LazyBreadcrumb, OptionType, useModal } from '@europrocurement/flexy-components';
import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';

import { customizerSelector, useAppSelector } from '@b2d/redux';

import {
    groupesSelector,
    marquesSelector,
    polesSelector,
    servicesSelector,
    useUtilisateurService,
    useUtilisateursServiceSelector,
    UtilisateurApiObject,
    UtilisateursAppDispatch,
    utilisateursSelector,
} from '@europrocurement/l2d-domain';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import { FlexyForm } from '@europrocurement/flexy-form';
import { UtilisateursJsonldUtilisateurWrite } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { Button, useTheme } from '@mui/material';
import UtilisateurActionMenu from '../../components/molecule/UtilisateurActionMenu';
import FullNameCompiler from '../../components/atom/FullNameCompiler';
import RightsChips from '../../components/molecule/RightsChips';
import {
    createUserFormStructure,
    userDefaultValues,
} from '../../functions/createUserFormStructure';
import { errorNotification, successNotification } from '../../functions/notificationToasters';
import { User } from '../../components/Utilisateur/UserTabs/type';

const filters: FiltersDatatableList = [
    {
        label: 'Login',
        field: 'user',
        type: 'text',
    },
    {
        label: 'Mail',
        field: 'mail',
        type: 'text',
    },
    {
        label: 'Supprimé',
        field: 'isDeleted',
        type: 'boolean',
    },
    {
        label: 'Formaliste',
        field: 'isFormalist',
        type: 'boolean',
    },
    {
        label: 'G3',
        field: 'hasG3Access',
        type: 'boolean',
    },
    {
        label: 'Cegid',
        field: 'hasComptaSoftware',
        type: 'boolean',
    },
    {
        label: 'L2DF',
        field: 'hasAccessL2DF',
        type: 'boolean',
    },
    {
        label: 'Admin Apps',
        field: 'formalistAdmin',
        type: 'boolean',
    },
];

export type PostFormType = {
    nom: string;
    prenom: string;
    pole: { label: string; value: number };
    service: { label: string; value: number };
    marque: null;
    userGroupe: { label: string; value: number };
    idSociete: { label: string; value: number };
    idSocieteEmployeur: { label: string; value: number };
    formalistAdmin: number;
    formalistDroitLectureDossier: { label: string; value: number };
    formalistDroitLectureGestion: { label: string; value: number };
    formalistDroitSupprDossier: { label: string; value: number };
    formalistDroitModifDossier: { label: string; value: number };
    formalistDroitModifGestion: { label: string; value: number };
    usdAppsAnnuaire: boolean;
    usdAppsAnnuaireOption: boolean;
    usdAppsJalgest: boolean;
    usdAppsJalgestOption: string | null;
    usdAppsMdc: boolean;
    usdAppsMdcOption: string | null;
    usdAppsMdcSpel: boolean;
    usdAppsMdcSpelOption: string | null;
    usdAppsSupport: boolean;
    usdAppsSupportOption: boolean;
    usdAppsXy: boolean;
    usdAppsXyOption: string | null;
    idAntenne: number;
    idUserG3: string;
    idUserDematfacile: string;
} & User;

const UtilisateursList: React.FunctionComponent = function () {
    const { palette } = useTheme();
    const columns: ColumnDatatable<UtilisateurApiObject>[] = [
        {
            label: 'ID',
            render: 'id',
            isDisplayed: true,
            sortable: true,
            field: 'id',
            sx: {
                width: '50px',
            },
        },
        {
            label: 'Nom',
            isDisplayed: true,
            sortable: true,
            field: 'nom',
            render: (user: UtilisateurApiObject) => <FullNameCompiler user={user} />,
        },
        {
            label: 'Login',
            render: 'user',
            isDisplayed: true,
            sortable: true,
            field: 'user',
        },
        {
            label: 'Mail',
            render: 'mail',
            isDisplayed: true,
            sortable: true,
            field: 'mail',
        },
        {
            label: 'Société employeur',
            render: (user: UtilisateurApiObject) => {
                if (user.libelleSocieteEmployeur) {
                    return user.libelleSocieteEmployeur;
                }
                return 'Non renseigné';
            },
            isDisplayed: true,
            sortable: false,
            field: 'idSocieteEmployeur',
        },
        {
            label: 'Droits',
            render: (user: UtilisateurApiObject) => <RightsChips user={user} />,
            isDisplayed: true,
        },
        {
            label: 'Actions',
            render: (user: UtilisateurApiObject) =>
                user.id && <UtilisateurActionMenu itemId={user.id} />,
            isDisplayed: true,
            displayLabel: false,
            rowReverse: true,
        },
    ];

    const dataSource = useUtilisateursServiceSelector(utilisateursSelector);
    const { xIdSociete } = useAppSelector(customizerSelector);
    const { getUtilisateurs, getServices, getPoles, getMarques, getGroupes, utilisateurApi } =
        useUtilisateurService();

    const navigate = useNavigate();
    const dispatch = useDispatch<UtilisateursAppDispatch>();
    const { modalActions } = useModal();

    // dispatch all constant data for selects in the user cards at component mount
    useEffect(() => {
        dispatch(getServices({}));
        dispatch(getPoles({}));
        dispatch(getMarques({}));
        dispatch(getGroupes({}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // on mount only

    // get the lists for the creation form
    const services = useUtilisateursServiceSelector(servicesSelector).data;
    const poles = useUtilisateursServiceSelector(polesSelector).data;
    const marques = useUtilisateursServiceSelector(marquesSelector).data;
    const groupes = useUtilisateursServiceSelector(groupesSelector).data;

    // format all data for the selects
    const servicesOptions: Array<OptionType> = [];
    services.map((service) =>
        servicesOptions.push({
            label: service.libelle as string,
            value: service.id as number,
        }),
    );

    const polesOptions: Array<OptionType> = [];
    poles.map((pole) =>
        polesOptions.push({
            label: pole.libelle as string,
            value: pole.id as number,
        }),
    );

    const marquesOptions: Array<OptionType> = [];
    marques.map((marque) =>
        marquesOptions.push({
            label: marque.libelle as string,
            value: marque.idMarque as number,
        }),
    );

    const groupesOptions: Array<OptionType> = [];
    groupes.map((groupe) =>
        groupesOptions.push({
            label: groupe.groupName as string,
            value: groupe.id as number,
        }),
    );

    /**
     * Get the form data, format them for the API, then post them to create a new user
     * @param formData data from the form submission
     */
    const handleNewUserSubmit = (formData: PostFormType) => {
        const generatedInitiales = formData.prenom[0] + formData.nom[0];
        let idSocieteData: UtilisateursJsonldUtilisateurWrite['idSociete'] =
            formData.idSociete.value;
        let idSocieteEmployeurData: UtilisateursJsonldUtilisateurWrite['idSocieteEmployeur'] =
            formData.idSocieteEmployeur.value;

        if (formData.idSociete !== undefined && formData.idSociete !== null) {
            idSocieteData = formData.idSociete.value;
        }
        if (formData.idSocieteEmployeur !== undefined && formData.idSocieteEmployeur !== null) {
            idSocieteEmployeurData = formData.idSocieteEmployeur.value;
        }

        // Formates form data to match with the post data format
        const formatedPostData: UtilisateursJsonldUtilisateurWrite = {
            ...formData,
            pole: `/utilisateur/poles/${formData.pole.value}`,
            service: `/utilisateur/services/${formData.service.value}`,
            idSociete: idSocieteData,
            idSocieteEmployeur: idSocieteEmployeurData,
            userGroupe: `/utilisateur/groupes/${formData.userGroupe.value}`,
            initiales: generatedInitiales,
        };

        utilisateurApi
            .apiUtilisateursPost({
                utilisateursJsonldUtilisateurWrite: formatedPostData,
            })
            .then(() => {
                successNotification("L'utilisateur a bien été créé.");
                dispatch(getUtilisateurs({}));
                modalActions.close();
            })
            .catch((error) => {
                console.error(error);
                if (error.response.data.status === 422) {
                    errorNotification('Cet email existe déjà');
                } else {
                    errorNotification();
                }
            });
    };

    /**
     * Creates a new user
     */
    const createUser = () => {
        modalActions.call(
            <FlexyForm
                formObject={userDefaultValues}
                formStructure={createUserFormStructure(
                    servicesOptions,
                    polesOptions,
                    groupesOptions,
                )}
                onSubmit={handleNewUserSubmit}
                gridProps={{ maxWidth: 'md', rowSpacing: 0, columnSpacing: 2 }}
            />,
        );
    };

    const hoverBackground = palette.primary.light;
    const hoverColor = palette.getContrastText(hoverBackground);
    const addUserBtn = (
        <Button
            sx={{
                alignItems: 'baseline',
                marginRight: '10px',
                padding: '12px 16px 12px 16px',
                borderRadius: '12px',
                lineHeight: '24px',
                fontFamily: 'Inter',
                fontSize: '16px',
                width: 'max-content',
                fontWeight: 500,
                backgroundColor: palette.primary.dark,
                color: palette.getContrastText(palette.primary.dark),
                ':hover': {
                    backgroundColor: hoverBackground,
                    color: hoverColor,
                },
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
            }}
            onClick={createUser}
        >
            Nouvel utilisateur
        </Button>
    );

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <LazyBreadcrumb
                    path={[{ path: 'utilisateurs', link: '/utilisateurs' }, 'Portail Utilisateurs']}
                />
            </Box>
            <StoreDatatable
                dataSource={dataSource}
                columns={columns}
                fetchData={getUtilisateurs}
                filters={filters}
                showSearch
                localStorageKey="FlexyStoreDatatable"
                onClickRow={(_: React.MouseEvent<HTMLElement>, user: UtilisateurApiObject) => {
                    navigate(`${user.id}`);
                }}
                buttonAction={addUserBtn}
                observers={[xIdSociete]}
            />
        </>
    );
};

export default UtilisateursList;
