import React, { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
import { Typography } from '@mui/material';
import { MediaObjectApiPatchMediaObjectItemRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { ContextActionType, useModal } from '@europrocurement/flexy-components';
import { MediaObject } from '@europrocurement/l2d-domain';
import generateB2DPath, { GenerateB2DPathResponseType } from '@b2d/utils/generateB2DPath';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import ConfirmationModalContent from '@b2d/pages/Achats/components/modals/ConfirmationModalContent';
import { removeMediaObjectModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import { mediaObjectApi } from '@b2d/redux/RootStore';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { filtersHasher } from '@europrocurement/l2d-utils';

export type DeleteMediaObjectProps = {
    mediaObject: MediaObject;
};

const useDeleteMediaObject = ({ mediaObject }: DeleteMediaObjectProps): ContextActionType => {
    const { enqueueSnackbar } = useSnackbar();
    const { modalActions } = useModal();
    const { request } = useApiRequest();
    const location = useLocation();
    const navigate = useNavigate();
    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const exitFormAfterDelete = useCallback(async () => {
        let response: GenerateB2DPathResponseType = {
            status: 'STAY',
        };

        if (location.pathname.match(/\/achats\/liste\/saisir\/(\d+)/)) {
            response = isInterne
                ? generateB2DPath('listmediaobjectinternally')
                : generateB2DPath('listmediaobject');
        }
        if (
            location.pathname.match(/\/achats\/liste\/modifier/) ||
            location.pathname.match(/\/achats\/liste\/recap/)
        ) {
            response = generateB2DPath('listfacture');
        }

        if (response.status === 'OK') {
            navigate(response.path);
        }
    }, [isInterne, location.pathname, navigate]);

    const onDelete = useCallback(
        async (selectedMediaObjectFromUrl: MediaObject) => {
            if (!selectedMediaObjectFromUrl.id) return;

            // Suppression physique actuellement impossible à cause de :
            // 1. Contrainte SQL (même si aucune facture liée n'est active, elle reste liée en base)
            if (selectedMediaObjectFromUrl && selectedMediaObjectFromUrl.facturesAchat) {
                const isOneFactureInUse = selectedMediaObjectFromUrl.facturesAchat.find(
                    (factureFromMediaObject) => !factureFromMediaObject.deleted,
                );

                if (isOneFactureInUse) {
                    enqueueSnackbar(
                        <Typography>
                            Ce PDF ne peut pas être supprimé car il est lié à une ou plusieurs
                            facture(s) d&apos;achat en cours de traitement(s), ou traitée(s).
                        </Typography>,
                        {
                            variant: 'error',
                        },
                    );

                    return;
                }
            }

            const successCallback = () => {
                exitFormAfterDelete();
            };

            const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                id: selectedMediaObjectFromUrl.id.toString(),
                mediaObject: { deleted: true },
            };

            // Suppression logique
            request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                withToaster: true,
                successCallback,
            });
        },
        [enqueueSnackbar, request, exitFormAfterDelete],
    );

    return {
        name: 'Supprimer le pdf',
        icon: (
            <FontAwesomeIcon
                icon={faTrashCan}
                size="xl"
            />
        ),
        action: () => {
            modalActions.call(
                <ConfirmationModalContent
                    key={`${mediaObject?.['@id']}-${removeMediaObjectModalMessages}`}
                    messages={removeMediaObjectModalMessages}
                    actionOnValidation={() => {
                        if (!mediaObject) {
                            enqueueSnackbar(
                                <Typography>Il n&apos;y a aucun PDF sélectionné !</Typography>,
                                {
                                    variant: 'error',
                                },
                            );
                            return;
                        }

                        onDelete(mediaObject);
                    }}
                />,
            );
        },
        resetHash: filtersHasher(mediaObject),
    };
};

export default useDeleteMediaObject;
