import * as React from 'react';

import { Select, MenuItem } from '@mui/material';

import { ChoiceProps } from '../types';

type SelectShapeProps = {
    choices: Array<ChoiceProps>;
    formState: Record<string, boolean>;
    setFormState: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    uniqChoice: boolean;
};

const SelectShape: React.FunctionComponent<SelectShapeProps> = function ({
    choices,
    formState,
    setFormState,
    uniqChoice,
}) {
    const selectedChoices = choices.filter((choice) => formState[choice.label]);
    const selectedValues = selectedChoices.map((choice) => choice.label);

    return (
        <Select
            value={uniqChoice ? selectedValues[0] || '' : selectedValues}
            multiple={!uniqChoice}
            renderValue={(selected) => {
                const selectedArray = Array.isArray(selected) ? selected : [selected];
                if (selectedArray.length === 1) return selectedArray[0];
                if (selectedArray.length > 1)
                    return `${selectedArray[0]}, ${selectedArray[1].slice(0, 5)}...`;
                return '';
            }}
            onChange={(event) => {
                const selectedLabels = uniqChoice
                    ? [event.target.value as string]
                    : (event.target.value as string[]);

                setFormState(() =>
                    choices.reduce(
                        (state, choice) => {
                            // eslint-disable-next-line no-param-reassign
                            state[choice.label] = selectedLabels.includes(choice.label);
                            return state;
                        },
                        {} as Record<string, boolean>,
                    ),
                );

                choices.forEach((choice) => {
                    const isSelected = selectedLabels.includes(choice.label);
                    const wasSelected = formState[choice.label];
                    if (isSelected && !wasSelected && choice.action) {
                        choice.action(event as React.ChangeEvent, true);
                    } else if (!isSelected && wasSelected && choice.action) {
                        choice.action(event as React.ChangeEvent, false);
                    }
                });
            }}
        >
            {choices.map((choice) => (
                <MenuItem
                    key={choice.label}
                    value={choice.label}
                >
                    {choice.label}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectShape;
