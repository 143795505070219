import { offerGroupIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';

import {
    GroupementApi,
    GroupementGroupementRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { type ModelConfiguration, type ModelMethods, type ModelItem } from '../../..';
import { baseOfferGroupsSelector } from '../selectors';
import { OFFER_GROUP_SLICE_NAME } from '../constants';

const modelKey = 'offer_group';
const modelName = 'OfferGroup';
const sliceName = OFFER_GROUP_SLICE_NAME;

type ModelStructure = GroupementGroupementRead;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
};

export type OfferGroupEndpoints = {
    list: InstanceType<typeof GroupementApi>['apiGroupementsGetCollection'];
    delete: InstanceType<typeof GroupementApi>['apiGroupementsIdDelete'];
    read: InstanceType<typeof GroupementApi>['apiGroupementsIdGet'];
    update: InstanceType<typeof GroupementApi>['apiGroupementsIdPut'];
    create: InstanceType<typeof GroupementApi>['apiGroupementsPost'];
};

export type OfferGroupModel = ModelItem<ModelStructure> & OfferGroupEndpoints;

export const buildOfferGroupModel = (
    api: GroupementApi,
    datasourcesThunks: DataSourcesThunksType<GroupementGroupementRead>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Groupement',
        sliceName,
        api,
        selector: baseOfferGroupsSelector,
        datasourcesThunks,
        icon: offerGroupIcon,
    };

    const endpoints = {
        list: api.apiGroupementsGetCollection.bind(api),
        delete: api.apiGroupementsIdDelete.bind(api),
        read: api.apiGroupementsIdGet.bind(api),
        update: api.apiGroupementsIdPut.bind(api),
        create: api.apiGroupementsPost.bind(api),
    };

    const model: OfferGroupModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
