import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as DossierObjectGroupParameters,
    Configuration,
    ObjectGroupsApi,
    ObjectGroupsApiApiObjectGroupsGetCollectionRequest,
    ObjectGroupsApiApiObjectGroupsIdGetRequest,
    DossierOpenSearchJsonldOpensearchDossierObjectGroupRead,
} from '../../../openApi/ApiFormalite';
import { DOSSIER_OBJECT_GROUP_SLICE_NAME, FORMALITES_REDUCER_NAME } from '../constants';

export type DossierObjectGroupApiObject = DossierOpenSearchJsonldOpensearchDossierObjectGroupRead;

export type DossierObjectGroup = DossierObjectGroupApiObject;

export const mapperDossierObjectGroups = function (objectGroup: DossierObjectGroup) {
    return {
        ...objectGroup,
    } as DossierObjectGroup;
};

export function createDossierObjectGroupSlice(configuration: DossierObjectGroupParameters) {
    const conf = new Configuration(configuration);
    const api = new ObjectGroupsApi(conf);

    const fetchDossierObjectGroupsCollection: FetchCollectionData<DossierObjectGroup> = ({
        pagination,
    }) => {
        const options = undefined;

        const requestParameters: ObjectGroupsApiApiObjectGroupsGetCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
        };

        return api
            .apiObjectGroupsGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<DossierObjectGroup>>;
    };

    const fetchDossierObjectGroupItem: FetchItemDataType<DossierObjectGroup> = ({ idItem }) => {
        const requestParameters: ObjectGroupsApiApiObjectGroupsIdGetRequest = {
            id: String(idItem),
        };

        return api
            .apiObjectGroupsIdGet(requestParameters)
            .then((res) => res.data) as Promise<DossierObjectGroup>;
    };

    const {
        slice: dossierObjectGroupSlice,
        dataSourcesThunks: dossierObjectGroupDataSourcesThunks,
    } = SliceFactory.createSlice<DossierObjectGroup, DossierObjectGroup>(
        DOSSIER_OBJECT_GROUP_SLICE_NAME,
        FORMALITES_REDUCER_NAME,
        [],
        fetchDossierObjectGroupsCollection,
        fetchDossierObjectGroupItem,
        mapperDossierObjectGroups,
        {},
        undefined,
    );

    return { dossierObjectGroupSlice, dossierObjectGroupDataSourcesThunks };
}
