import {
    DossierJsonldDossierRead,
    FichiersDossierApi,
    TypeDeDocumentDesDossiersApi,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export const loadDossier = (
    dossier: DossierJsonldDossierRead,
    fichiersDossierApi: FichiersDossierApi,
) =>
    fichiersDossierApi.apiDossiersDossierFichiersGetCollection({
        dossier: `${dossier.id}`,
    });

export const uploadDossierFiles = async (
    dossier: DossierJsonldDossierRead,
    fichiersDossierApi: FichiersDossierApi,
    files: File[],
    typageAuto: boolean = true,
    documentsApi: TypeDeDocumentDesDossiersApi | null = null,
) => {
    await Promise.all(
        files.map(async (file) => {
            await fichiersDossierApi.apiDossiersDossierFichiersPost({
                dossier: `${dossier.id}`,
                typageAuto,
                file,
            });
        }),
    );
    if (!documentsApi) {
        return;
    }
    const fichierRes = await loadDossier(dossier, fichiersDossierApi);
    const fichiers = fichierRes.data['hydra:member'];
    await Promise.all(
        fichiers.map((fichier) => {
            if (fichier.typedocIADoc && fichier.typeDocDossier && fichier.typeDocDossier.id) {
                if (fichier.typedocIADoc.id !== 66 && fichier.typeDocDossier.document?.id === 66) {
                    return documentsApi.apiTypesDocDossierIdPatch({
                        id: `${fichier.typeDocDossier.id}`,
                        typeDeDocumentDesDossiersTypedocumentPatch: {
                            libelle: fichier.typedocIADoc.libelle,
                            document: `/formalite/types-documents/${fichier.typedocIADoc.id}`,
                        },
                    });
                }
            }
            return Promise.resolve();
        }),
    );
};
export const uploadDossierFile = async (
    dossier: DossierJsonldDossierRead,
    fichiersDossierApi: FichiersDossierApi,
    file: File,
    typeDoc: string,
    typageAuto: boolean = false,
) =>
    await fichiersDossierApi.apiDossiersDossierFichiersPost({
        dossier: `${dossier.id}`,
        typeDoc,
        file,
        typageAuto,
    });
