import React, { ComponentType } from 'react';
import { Box, IconProps, Typography } from '@mui/material';
import { FaOptionIcon, warningIcon } from '@europrocurement/l2d-icons';

type NoResultPreviewProps = {
    message: string;
    Icon?: ComponentType<IconProps>;
};

const NoResultPreview: React.FunctionComponent<NoResultPreviewProps> = function (props) {
    const { message = "Votre recherche n'a donné aucun résultat" } = props;

    const boxStyle = {
        padding: '40px',
        border: '3px dashed lightgray',
        borderRadius: '10px',
        opacity: '0.3',
        textAlign: 'center',
        minHeight: '250px',
    };

    return (
        <Box
            sx={{
                ...boxStyle,
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <FaOptionIcon
                {...warningIcon.props}
                iconProps={{
                    style: {
                        fontSize: '60px',
                        opacity: '0.6',
                    },
                }}
            />
            <Typography sx={{ marginTop: '30px' }}>{message}</Typography>
        </Box>
    );
};

export default NoResultPreview;
