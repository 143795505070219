import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import Previous from '@europrocurement/flexy-components/components/atoms/Previous';
import { useUploader } from '@europrocurement/flexy-components';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import SelectSociete from '../components/SelectSociete';
import { useParcoursFormalite, useParcoursFormalitreHeader } from '../provider';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import CurrentFormalityAndClient from '../components/currentFormalityAndClient';

const StepSelectionClient: React.FunctionComponent = function () {
    const { idFormalites, create, containerWidth, setLoading } = useParcoursFormalite();

    React.useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    const { clearFiles } = useUploader();

    const navigate = useNavigate();

    const onPrevious = () => {
        navigate(parcoursFormalitePathResolver('stepFormalite'));
    };

    const { changeParcoursSteps, setIsParcoursPath } = useParcoursFormalitreHeader();

    const { setProfileHeaderVisible } = useFullLayout();
    React.useEffect(() => {
        changeParcoursSteps(1);
        setProfileHeaderVisible(false);
        setIsParcoursPath(true);
        return () => {
            setIsParcoursPath(false);
            setProfileHeaderVisible(true);
        };
    }, [setProfileHeaderVisible, changeParcoursSteps, setIsParcoursPath]);

    return (
        <Container maxWidth={containerWidth}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                }}
            >
                <CurrentFormalityAndClient />
                <Previous onClick={onPrevious} />
            </Box>
            <SelectSociete
                onSelect={async (ids, sousClients) => {
                    setLoading(true);
                    await create(ids, idFormalites, sousClients[0]);
                    clearFiles();
                    navigate(parcoursFormalitePathResolver('stepPremiumDoc'));
                }}
            />
        </Container>
    );
};

export default StepSelectionClient;
