import { useSociete, ReminderMail, ReminderMailStatus } from '@europrocurement/l2d-domain';

import { getReminderMail, mailApi } from '@b2d/redux/RootStore';
import { AppDispatch, RootStateType } from '@b2d/redux/types';
import {
    ChipStatus,
    LazyBreadcrumb,
    ModalContext,
    TruncatedHtmlText,
    UuidDisplay,
    ActionButton,
} from '@europrocurement/flexy-components';
import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Box, Chip, Tooltip } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMails } from '@europrocurement/l2d-icons';
import { MailApiSendQueuedMailsMailCollectionRequest } from '@europrocurement/l2d-domain/openApi/ApiMail';
import ConfirmationModalContent from '@b2d/pages/Achats/components/modals/ConfirmationModalContent';
import { sendReminderMailsModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import DeleteReminderMailButton from './DeleteReminderMailButton';

const PublishersRemindersListing: React.FunctionComponent = function () {
    const reminderMailDataSource: DataSource<ReminderMail> = useSelector(
        (s: RootStateType) => s.achats.rappel.main,
        _.isEqual,
    );

    const xIdSociete = useSociete();
    const { request } = useApiRequest();
    const dispatch = useDispatch<AppDispatch>();
    const { modalActions } = useContext(ModalContext);

    const mailCount = useMemo(
        () =>
            reminderMailDataSource.data.filter((mail) => mail.status === ReminderMailStatus.PENDING)
                .length,
        [reminderMailDataSource.data],
    );

    const onSendReminderMailsAction = React.useCallback(() => {
        const requestParameters: MailApiSendQueuedMailsMailCollectionRequest = {};

        const successCallback = () => {
            dispatch(getReminderMail({}));
        };

        request(mailApi.sendQueuedMailsMailCollection(requestParameters), {
            withToaster: true,
            successCallback,
        });
    }, [dispatch, request]);

    const handleOnClickSendReminderMails = React.useCallback(() => {
        modalActions.call(
            <ConfirmationModalContent
                messages={{
                    ...sendReminderMailsModalMessages,
                    validation: sendReminderMailsModalMessages.validation(mailCount),
                }}
                actionOnValidation={() => onSendReminderMailsAction()}
            />,
        );
    }, [mailCount, modalActions, onSendReminderMailsAction]);

    const columns: ColumnDatatable<ReminderMail>[] = useMemo(
        () => [
            {
                label: 'Id',
                render: ({ id }) => (
                    <Tooltip title={id}>
                        <UuidDisplay id={id} />
                    </Tooltip>
                ),
                isDisplayed: true,
                sortable: true,
                field: 'id',
            },
            {
                label: 'De',
                render: ({ from }) => from,
                isDisplayed: false,
                field: 'from',
            },
            {
                label: 'Destinataire(s)',
                render: ({ tos }) => tos,
                isDisplayed: true,
                field: 'tos',
            },
            {
                label: 'En réponse à',
                render: ({ replies }) => replies,
                isDisplayed: false,
                field: 'replies',
            },
            {
                label: 'Copie(s)',
                render: ({ ccs }) => ccs,
                isDisplayed: false,
                field: 'ccs',
            },
            {
                label: 'Objet',
                render: ({ subject }) => subject,
                isDisplayed: true,
                field: 'subject',
            },
            {
                label: 'Corps',
                render: ({ body }) => <TruncatedHtmlText content={body} />,
                isDisplayed: false,
                field: 'body',
            },
            {
                label: 'Statut',
                render: ({ status }) => <ChipStatus status={status} />,
                isDisplayed: true,
                sortable: true,
                field: 'status',
            },
            {
                label: 'Tags',
                render: ({ tags }) => {
                    if (!tags || tags.length === 0) {
                        return <Chip label="Pas de tags" />;
                    }

                    const defaultTags = tags.slice(0, 3);
                    const customTags = tags.slice(3);

                    if (customTags.length > 0) {
                        return (
                            <>
                                <Tooltip title={`Tags par défaut : ${defaultTags.join(', ')}`}>
                                    <Chip
                                        label="..."
                                        size="small"
                                        sx={{ mr: 1, mb: 1 }}
                                    />
                                </Tooltip>
                                {customTags.map((ctag) => (
                                    <Chip
                                        label={ctag}
                                        size="small"
                                        sx={{ mr: 1, mb: 1 }}
                                    />
                                ))}
                            </>
                        );
                    }

                    return (
                        <Tooltip title={`Tags par défaut : ${defaultTags.join(', ')}`}>
                            <Chip
                                label="..."
                                size="small"
                            />
                        </Tooltip>
                    );
                },
                isDisplayed: true,
                field: 'tags',
            },
            {
                label: 'Actions',
                onClickCell: () => {},
                render: (reminderMail: ReminderMail) =>
                    reminderMail.id ? (
                        <DeleteReminderMailButton reminderMailId={reminderMail.id} />
                    ) : null,
                isDisplayed: true,
            },
        ],
        [],
    );

    const filters: FiltersDatatableList = [
        {
            field: 'statuses',
            label: 'status',
            type: 'staticSelect',
            options: Object.entries(ReminderMailStatus).map(([key, value]) => ({
                value: key,
                label: value as string,
            })),
        },
        {
            field: 'libelle',
            label: 'libelle',
            type: 'text',
        },
    ];

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <LazyBreadcrumb
                    path={[
                        { path: 'Fournisseurs', link: '/fournisseurs/liste' },
                        'Liste des rappels',
                    ]}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        height: '75px',
                    }}
                >
                    <Box> </Box>
                    <ActionButton
                        label="Envoyer les mails"
                        icon={sendMails}
                        action={handleOnClickSendReminderMails}
                    />
                </Box>
            </Box>
            <StoreDatatable
                dataSource={reminderMailDataSource}
                columns={columns}
                fetchData={getReminderMail}
                filters={filters}
                localStorageKey="FlexyStoreDatatablePublishersReminderList"
                onClickRow={() => {}}
                // onClickRow={(__: MouseEvent<HTMLElement>, { id }: Fournisseur) => navigate(`${id}`)}
                observers={[xIdSociete]}
                fetchWithoutFilter
            />
        </>
    );
};

export default PublishersRemindersListing;
