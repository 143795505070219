import {
    BooleanChip,
    CardSection,
    CustomerServiceWidget,
    DataIndicatorWidget,
    CardHeader,
    HorizontalDivider,
    VerticalDivider,
    ActionButton,
    DataIndicatorWidgetProps,
    BooleanChipProps,
    CustomerServiceWidgetProps,
} from '@europrocurement/flexy-components';
import { Box, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { TiersTiersPrescripteurReadCommerciaux } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import React, { useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useOffreService, useSociete, useTiersService } from '@europrocurement/l2d-domain';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { useParams } from 'react-router';
import { getSanitizedId } from '@b2d/utils/sanitize';
import { formatPrice } from '@europrocurement/l2d-utils';
import { enqueueSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';
import { PrescriberTabProps } from './types';

const L2D_FORMALITE_ORIGIN = 13;
const FORMALITE_DOMAIN = 3;

type LoadedValue<T> = {
    value: T | null;
    loaded: boolean;
};

type ResumeState = {
    hasB2BAccess: LoadedValue<boolean>;
    offerLabel: LoadedValue<string>;
    outstanding: LoadedValue<string>;
    annualSales: LoadedValue<string>;
    customerServiceUsers: LoadedValue<CustomerServiceWidgetProps[]>;
};

const Resume: React.FunctionComponent<PrescriberTabProps> = function ({
    prescriber,
    fetchPrescriber,
}) {
    const theme = useTheme();
    const [isListShown, setIsListShown] = useState(false);

    const xIdSociete = useSociete();
    const { prescribersApi } = useTiersService();
    const { catalogsApi } = useOffreService();
    const defaultFetchedValueState = {
        value: null,
        loaded: false,
    };

    const { relationsSocietes, formaliste } = prescriber;
    const companyRelationship = relationsSocietes[0] ?? null;
    const prescriberHasB2BAccess = companyRelationship?.plateformeB2BAutorise ?? false;
    const recoverResponsible = companyRelationship?.chargeDeRecouvrement;
    const defaultResumeState = {
        outstanding: defaultFetchedValueState,
        annualSales: defaultFetchedValueState,
        offerLabel: defaultFetchedValueState,
        hasB2BAccess: {
            value: prescriberHasB2BAccess,
            loaded: true, // Already loaded because data is present in prescriber get
        },
        customerServiceUsers: {
            value: [],
            loaded: false,
        },
    };

    const [resumeState, setResumeState] = useState<ResumeState>(defaultResumeState);
    const { hasB2BAccess, outstanding, offerLabel, annualSales, customerServiceUsers } =
        resumeState;

    const updateResumeState = (key: keyof ResumeState, value: ResumeState[keyof ResumeState]) => {
        setResumeState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const updateB2BAccess = async () => {
        const newValue = !hasB2BAccess.value;
        const payload = { accesPlateformeB2B: newValue };
        let success = false;
        updateResumeState('hasB2BAccess', {
            value: newValue,
            loaded: false,
        });

        await prescribersApi
            .updateDroitsPrescripteurTiersItem({
                id: `${prescriber.id}`,
                updateDroitsPrescripteurTiersItemRequest: payload,
                xIdSociete,
            })
            .then(() => {
                success = true;
            })
            .catch(() => {
                enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
            });

        updateResumeState('hasB2BAccess', {
            value: success ? newValue : hasB2BAccess.value,
            loaded: true,
        });
        if (success) {
            fetchPrescriber();
        }
    };

    const booleanChips: Array<BooleanChipProps & { key: string }> = [
        {
            label: 'Accès B2B',
            icon: ['fasl', 'suitcase'],
            isChecked: !!hasB2BAccess.value,
            isLoading: !hasB2BAccess.loaded,
            key: 'prescriber_header_chip_1',
            onClick: updateB2BAccess,
        },
        {
            label: offerLabel.value ?? '',
            icon: ['fasl', 'star-circle'],
            isChecked: true,
            key: 'prescriber_header_chip_3',
            isLoading: !offerLabel.loaded,
        },
    ];

    const { request } = useApiRequest();
    const { idPrescripteur: id } = useParams();
    const getOutstanding = () =>
        prescribersApi.getPrescriberOutstandingTiersItem({ id: id ?? '', xIdSociete });
    const getAnnualSales = () =>
        prescribersApi.getPrescriberAnnualSalesTiersItem({ id: id ?? '', xIdSociete });
    const getCommercial = () =>
        prescribersApi.getCommerciauxPrescripteurTiersItem({ id: id ?? '', xIdSociete });
    const getPrescriberOfferName = () => {
        const query = {
            page: 1,
            itemsPerPage: 1,
            domaine: FORMALITE_DOMAIN,
            prescripteur: prescriber.id,
            origine: L2D_FORMALITE_ORIGIN,
        };

        return catalogsApi.apiCataloguesGetCollection(query);
    };
    const { breakpoints } = theme;
    const lgBreakpoint = useMediaQuery(breakpoints.down('lg'));
    const mdBreakpoint = useMediaQuery(breakpoints.down('md'));
    const booleanChipsStyle = { display: 'flex', margin: '15px 32px 0px' };
    const flexWrapper = { display: 'flex', flexWrap: 'wrap' };
    const flexItemSize = () => {
        if (mdBreakpoint) {
            return '100%';
        }
        if (lgBreakpoint) {
            return '50%';
        }
        return '25%';
    };
    const flexItem = {
        display: 'flex',
        flex: flexItemSize,
        minWidth: flexItemSize,
        maxWidth: flexItemSize,
        marginY: '8px',
    };

    const onFetchOutstandingSuccess = (res: AxiosResponse) => {
        const result = res?.data?.encours;
        updateResumeState('outstanding', {
            value: result ? `${formatPrice(result)} €` : '-',
            loaded: true,
        });
    };
    const onFetchAnnualSalesSuccess = (res: AxiosResponse) => {
        const result = res?.data?.chiffre_affaire_annuel_HT;
        updateResumeState('annualSales', {
            value: result ? `${formatPrice(result)} €` : '-',
            loaded: true,
        });
    };

    const onFetchOfferNameSuccess = (res: AxiosResponse) => {
        const result = res?.data['hydra:member'][0].offre.libelle;
        if (result) {
            updateResumeState('offerLabel', {
                value: `Formule l2f-formalité : ${result}`,
                loaded: true,
            });
        }
    };

    const getPrescriberDomainStatus = (domainId?: number) => {
        if (!domainId) {
            return null;
        }
        const prescriberDomain = prescriber.domaines?.find((domain) => domain.id === domainId);
        const prescriberDomainStatus = prescriberDomain.statut;
        return {
            id: prescriberDomainStatus.id,
            label: prescriberDomainStatus.libelle,
        };
    };

    const onFetchCommercialSuccess = (res: AxiosResponse) => {
        const result: TiersTiersPrescripteurReadCommerciaux['commerciaux'] = res?.data?.commerciaux;
        if (result) {
            const users = result.map((user): CustomerServiceWidgetProps => {
                const status = getPrescriberDomainStatus(user.domaine?.id); // todo
                return {
                    firstName: user.utilisateur?.prenom ?? '',
                    lastName: user.utilisateur?.nom ?? '',
                    sector: user.domaine?.libelle ?? '',
                    status: status ?? undefined,
                };
            });

            if (formaliste) {
                const formalistUser: CustomerServiceWidgetProps = {
                    firstName: formaliste.prenom ?? '',
                    lastName: formaliste.nom ?? '',
                    sector: 'Formaliste',
                };
                users.splice(2, 0, formalistUser);
            }

            if (recoverResponsible) {
                const recoverResponsibleUser: CustomerServiceWidgetProps = {
                    firstName: recoverResponsible.prenom ?? '',
                    lastName: recoverResponsible.nom ?? '',
                    sector: 'Chargé de recouvrement',
                };
                users.splice(3, 0, recoverResponsibleUser);
            }
            updateResumeState('customerServiceUsers', {
                value: users,
                loaded: true,
            });
        }
    };

    const fetchableRessources = [
        {
            call: getOutstanding, // Encours
            successCallback: onFetchOutstandingSuccess,
        },
        {
            call: getAnnualSales,
            successCallback: onFetchAnnualSalesSuccess,
        },
        {
            call: getPrescriberOfferName, // Chiffre affaire annuel
            successCallback: onFetchOfferNameSuccess,
        },
        {
            call: getCommercial,
            successCallback: onFetchCommercialSuccess,
        },
    ];

    useEffect(() => {
        if (getSanitizedId(id)) {
            fetchableRessources.forEach(({ call, successCallback }) => {
                request(call(), {
                    successCallback,
                    withToaster: false,
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const cardHeader = (
        <Box>
            <CardHeader
                title="Indicateurs"
                border={false}
            />
            <Box sx={booleanChipsStyle}>
                {booleanChips.map((chip) => (
                    <BooleanChip {...chip} />
                ))}
            </Box>
        </Box>
    );

    const isVerticalDivider = (index: number): boolean => {
        if (index < 1 || mdBreakpoint) {
            return false;
        }
        if (lgBreakpoint) {
            return index % 2 !== 0;
        }
        return index % 4 !== 0;
    };

    const toggleList = () => setIsListShown(!isListShown);
    const listToggleIcon: IconProp = ['fasl', `${isListShown ? 'minus' : 'plus'}`];
    const listToggleLabel = `Voir ${isListShown ? 'moins' : 'plus'}`;
    const MIN_ITEMS_SHOWN = 4;

    const indicators: Array<DataIndicatorWidgetProps & { key: string }> = [
        {
            label: "Chiffres d'affaires annuel",
            value: annualSales.value,
            key: 'prescriber_header_widget_1',
            loading: !annualSales.loaded,
        },
        {
            label: 'Encours',
            value: outstanding.value,
            key: 'prescriber_header_widget_2',
            loading: !outstanding.loaded,
        },
    ];

    const users = customerServiceUsers.value;
    return (
        <>
            <CardSection header={cardHeader}>
                <Box sx={flexWrapper}>
                    {indicators.map((indicator) => (
                        <Box sx={flexItem}>
                            <DataIndicatorWidget {...indicator} />
                        </Box>
                    ))}
                </Box>
                <HorizontalDivider sx={{ margin: '30px 0px 20px' }} />
                <Box sx={flexWrapper}>
                    {users &&
                        users.map((user, index) => {
                            if (index + 1 > MIN_ITEMS_SHOWN && !isListShown) {
                                return null;
                            }
                            const key = `prescriber_header_customer_service_${index}`;
                            return (
                                <Box
                                    key={key}
                                    sx={flexItem}
                                >
                                    {isVerticalDivider(index) && (
                                        <VerticalDivider sx={{ marginRight: '25px' }} />
                                    )}
                                    <CustomerServiceWidget {...user} />
                                </Box>
                            );
                        })}
                </Box>
                {users && users.length > MIN_ITEMS_SHOWN && (
                    <ActionButton
                        onClick={toggleList}
                        icon={listToggleIcon}
                        label={listToggleLabel}
                        color="#177BC2"
                        sx={{ marginTop: '25px' }}
                    />
                )}
            </CardSection>
            <CardSection header={<CardHeader title="Historique d'activités" />}>
                <Skeleton sx={{ height: '250px' }} />
            </CardSection>
        </>
    );
};

export default Resume;
