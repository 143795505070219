import { Box, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import * as React from 'react';
import { OptionValue } from './types';

type OptionCheckBoxPreviewProps = {
    optionValue: OptionValue;
};

const OptionCheckBoxPreview: React.FunctionComponent<OptionCheckBoxPreviewProps> = function (
    props,
) {
    const { optionValue } = props;
    const { libelle = '', description = '' } = optionValue;

    const [isChecked, setChecked] = React.useState(false);

    return (
        <Box
            marginY={2}
            display="flex"
        >
            <Tooltip title={`Description : ${description}`}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isChecked}
                            onChange={() => setChecked(!isChecked)}
                        />
                    }
                    label={libelle}
                />
            </Tooltip>

            {/* {checkedStatus && <OptionValuePreview optionValue={optionValue} />} */}
        </Box>
    );
};

export default OptionCheckBoxPreview;
