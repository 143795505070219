import React, { ReactNode } from 'react';
import { Box, Typography, useTheme, lighten, SxProps } from '@mui/material';
import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';
import ActionsBar, { ActionsBarProps } from '../ActionsBar';

export type BorderedBoxProps = {
    children: ReactNode;
    boxTitle?: ReactNode;
    actionBarProps?: ActionsBarProps;
    sx?: SxProps;
    borderWidth?: number;
    icon?: NamedIconsType;
};

const BorderedBox: React.FunctionComponent<BorderedBoxProps> = function (props) {
    const { boxTitle, children, actionBarProps, sx: sxProps = {}, borderWidth = 2, icon } = props;

    const theme = useTheme();

    const absoluteBoxStyle = {
        position: 'relative',
        top: actionBarProps ? '-31px' : '-20px',
        marginBottom: '-20px',
        backgroundColor: theme.palette.background.paper,
        width: 'max-content',
        padding: '8px',
    };

    const offsetValue = '15px';

    return (
        <Box
            sx={{
                marginTop: '20px',
                border: `${borderWidth}px solid ${lighten(theme.palette.primary.main, 0.8)}`,
                borderRadius: '10px',
                ...sxProps,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        ...absoluteBoxStyle,
                        backgroundColor: boxTitle ? theme.palette.background.paper : 'none',
                        left: offsetValue,
                    }}
                >
                    <>
                        {icon && (
                            <FaOptionIcon
                                {...icon.props}
                                sx={{ marginRight: '8px' }}
                            />
                        )}
                        <span>{boxTitle}</span>
                    </>
                </Typography>
                {actionBarProps && (
                    <Box
                        sx={{
                            ...absoluteBoxStyle,
                            right: offsetValue,
                        }}
                    >
                        <ActionsBar
                            editAction={actionBarProps.editAction}
                            deleteAction={actionBarProps.deleteAction}
                            localisationAction={actionBarProps.localisationAction}
                            additionalActions={actionBarProps.additionalActions || []}
                            sx={actionBarProps.sx}
                        />
                    </Box>
                )}
            </Box>

            <Box
                sx={{
                    padding: boxTitle !== '' ? '5px 20px 20px' : '20px 20px',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default BorderedBox;
