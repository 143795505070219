import ConfirmationModalContent from '@b2d/pages/Achats/components/modals/ConfirmationModalContent';
import { removeMediaObjectModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import { getFromSpecifiedDataSourceThunks, mediaObjectApi } from '@b2d/redux/RootStore';
import { ListActionButton, ModalContext } from '@europrocurement/flexy-components';
import { MediaObject } from '@europrocurement/l2d-domain';
import { MediaObjectApiPatchMediaObjectItemRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { deleteIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import { KeycloakHasRole } from '@europrocurement/l2d-keycloak';
import { useApiRequest } from '@europrocurement/l2d-hooks';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext } from 'react';
import { useAppDispatch } from '@b2d/redux/types';

type DeletePdfButtonProps = {
    mediaObject: MediaObject;
    getMediaObject: ReturnType<typeof getFromSpecifiedDataSourceThunks>;
};

const DeletePdfButton: React.FunctionComponent<DeletePdfButtonProps> = function ({
    mediaObject,
    getMediaObject,
}: DeletePdfButtonProps) {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { modalActions } = useContext(ModalContext);
    const { request } = useApiRequest();

    const onDelete = useCallback(
        async (mediaObjectToDelete: MediaObject) => {
            modalActions.call(
                <ConfirmationModalContent
                    messages={removeMediaObjectModalMessages}
                    actionOnValidation={() => {
                        if (!mediaObjectToDelete || !mediaObjectToDelete.id) {
                            enqueueSnackbar(
                                <Typography>Il n&apos;y a aucun PDF sélectionné !</Typography>,
                                {
                                    variant: 'error',
                                },
                            );
                            return;
                        }

                        // Suppression physique actuellement impossible à cause de :
                        // 1. Contrainte SQL (même si aucune facture liée n'est active, elle reste liée en base)
                        if (mediaObjectToDelete && mediaObjectToDelete.facturesAchat) {
                            const isOneFactureInUse = mediaObjectToDelete.facturesAchat.find(
                                (facture) => !facture.deleted,
                            );

                            if (isOneFactureInUse) {
                                enqueueSnackbar(
                                    <Typography>
                                        Ce PDF ne peut pas être supprimé car il est lié à une ou
                                        plusieurs facture(s) d&apos;achat en cours de traitement(s),
                                        ou traitée(s).
                                    </Typography>,
                                    {
                                        variant: 'error',
                                    },
                                );

                                return;
                            }
                        }

                        const successCallback = () => {
                            dispatch(getMediaObject({}));
                        };

                        const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                            id: mediaObjectToDelete.id.toString(),
                            mediaObject: { deleted: true },
                        };

                        // Suppression logique
                        request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                            withToaster: true,
                            successCallback,
                        });
                    }}
                />,
            );
        },
        [dispatch, enqueueSnackbar, getMediaObject, modalActions, request],
    );

    return (
        <KeycloakHasRole kcRole="realm:interne">
            <ListActionButton
                tooltip="Supprimer le pdf"
                onClick={() => {
                    onDelete(mediaObject);
                }}
                iconComponent={<FaOptionIcon {...deleteIcon.props} />}
            />
        </KeycloakHasRole>
    );
};

export default DeletePdfButton;
