import React from 'react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { TiersPrescripteurTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import AddressLink from '../AddressLink';

type SubTitleElements = Array<{
    icon: IconProp;
    label?: string;
    onClick?: () => void;
}>;

export type PrescriberHeaderProps = {
    prescriber: TiersPrescripteurTiersRead;
};

const PrescriberHeader: React.FunctionComponent<PrescriberHeaderProps> = function ({ prescriber }) {
    const name = prescriber?.raisonSociale ?? '';
    const prescriberCompany = prescriber?.relationsSocietes[0]; // Todo define default company / behaviour in case of multiples companies
    const billingCode = prescriberCompany?.codeClient ?? '';
    const walletCategory = prescriberCompany?.portefeuille?.libelle ?? '';
    const jobCategory = prescriberCompany?.categorieSocioProfessionnelle?.libelle ?? '';
    const mainAddress = prescriber?.adresses?.find((address) => address.defaut === true);
    const locationIcon: IconProp = ['fasl', 'location-dot'];

    const headerStyle = { display: 'flex', alignItems: 'center', padding: '10px' };
    const avatarSize = '75px';
    const avatarStyle = {
        borderRadius: '8px',
        backgroundColor: 'rgba(255,130,41,0.08)',
        minHeight: avatarSize,
        minWidth: avatarSize,
        marginRight: '20px',
    };
    const avatarIconStyle = {
        opacity: 1,
        color: 'rgba(255, 130, 41, 1)',
    };
    const nameStyle = {
        fontSize: '24px',
        fontWeight: '500',
        marginRight: '10px',
    };
    const billingCodeStyle = { opacity: 0.8, fontWeight: '400' };
    const subtitleElementStyle = {
        display: 'flex',
        opacity: 0.6,
        fontSize: '12px',
        fontWeight: '300',
        alignItems: 'center',
        marginRight: '10px',
        color: 'inherit',
    };

    const subtitleElements: SubTitleElements = [
        {
            icon: ['fasl', 'tag'],
            label: walletCategory,
        },
        {
            icon: ['fasl', 'suitcase'],
            label: jobCategory,
        },
    ];

    const labelFontSize = '13px';

    return (
        <Box
            sx={headerStyle}
            data-testid="PrescriberHeader-test-id"
        >
            <Box>
                <Avatar sx={avatarStyle}>
                    <FaOptionIcon
                        icon={['fasl', 'buildings']}
                        sx={avatarIconStyle}
                    />
                </Avatar>
            </Box>
            <Box>
                <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="10px"
                >
                    <Typography sx={nameStyle}>{name}</Typography>
                    <Typography sx={billingCodeStyle}>#{billingCode}</Typography>
                </Box>
                <Box display="flex">
                    {mainAddress && (
                        <AddressLink
                            address={mainAddress}
                            icon={locationIcon}
                            type="button"
                        />
                    )}
                    {subtitleElements.map(({ icon, label, onClick }) => {
                        if (!label) {
                            return null;
                        }

                        if (onClick && typeof onClick === 'function') {
                            return (
                                <Button
                                    key={label}
                                    onClick={onClick}
                                    sx={subtitleElementStyle}
                                >
                                    <FaOptionIcon
                                        sx={{ marginRight: '4px' }}
                                        icon={icon}
                                    />
                                    <Typography sx={{ fontSize: labelFontSize }}>
                                        {label}
                                    </Typography>
                                </Button>
                            );
                        }
                        return (
                            <Box
                                key={label}
                                sx={subtitleElementStyle}
                            >
                                <FaOptionIcon
                                    sx={{ marginRight: '4px' }}
                                    icon={icon}
                                />
                                <Typography sx={{ fontSize: labelFontSize }}>{label}</Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default PrescriberHeader;
