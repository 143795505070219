/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FieldValues, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { Box, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ContactJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { useSelector } from 'react-redux';
import { OptionsSelector } from '@b2d/redux';
import { SelectOptions } from '@europrocurement/l2d-modules/modules/Offres/forms/types';
import SelectField from '@europrocurement/l2d-modules/modules/Offres/components/FormInputs/SelectField';
import { useSociete, useTiersService } from '@europrocurement/l2d-domain';
import { PrescriberFormProps } from './common';
import {
    ContactFormValues,
    contactSchema,
    formToContactCreationPayload,
    formToContactUpdatePayload,
    getContactFormInitialValues,
    formPhonesToPayload,
    formEmailsToPayload,
} from './form';
import OptionalContactList from './OptionalContactList';

export type ContactFormProps = PrescriberFormProps & {
    contact?: ContactJsonldTiersRead;
};

const ContactForm: React.FunctionComponent<ContactFormProps> = function (props) {
    const { afterSubmit, contact, prescriber } = props;
    const { civilities } = useSelector(OptionsSelector);
    const prescriberId = prescriber?.id;
    const contactId = contact?.id;
    const isCreationForm = !contact;

    const { prescribersApi, contactsApi } = useTiersService();

    const theme = useTheme();

    const { breakpoints } = theme;
    const mdBreakpoint = useMediaQuery(breakpoints.down('md'));
    const xIdSociete = useSociete();

    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: getContactFormInitialValues(contact),
        resolver: zodResolver(contactSchema),
    });

    const {
        control,
        watch,
        formState: { errors, isSubmitted },
    } = formContext;

    // FormState
    const [noteHasAlreadyBeenFocused, setNoteHasAlreadyBeenFocused] =
        React.useState<boolean>(false);

    const keycloakService = UseKeycloakService();
    const currentUserName = keycloakService.getName();
    const todayDate = new Date().toLocaleDateString();

    const noteField = formContext.register('note');

    const setCaretPosition = (introLength: number) => {
        const textAreas = document.getElementsByTagName('textarea');
        const noteTextArea = textAreas.namedItem('note_textarea');

        if (noteTextArea) {
            noteTextArea.focus();
            noteTextArea.setSelectionRange(introLength - 1, introLength); // Place caret at new line break
        }
    };

    const setNoteIntroduction = (introduction: string) => {
        formContext.setValue('note', introduction);
        // TODO Reuse formContext.setValue('note', introduction + (formContactObject.note ?? ''));
    };

    const onNoteFocus = () => {
        const noteIntroduction = `${todayDate} - ${currentUserName} : \n\n`;

        if (!noteHasAlreadyBeenFocused) {
            setNoteIntroduction(noteIntroduction);
            setCaretPosition(noteIntroduction.length - 1);
            setNoteHasAlreadyBeenFocused(true);
        }
    };

    /**
     * Proceed contact update
     *
     * The contact update call three different api endpoints
     * - 1. Contact main informations
     * - 2. Contact phones
     * - 3. Contact emails
     *
     * @param formValues - Values provided by contact form */
    const updateContact: SubmitHandler<ContactFormValues> = async (formValues) => {
        const canUpdate = typeof contactId !== 'undefined' && typeof prescriberId !== 'undefined';

        if (!canUpdate) {
            return;
        }
        const contactPayload = formToContactUpdatePayload(formValues);
        const phonesPayload = formPhonesToPayload(formValues);
        const emailsPayload = formEmailsToPayload(formValues);

        const promises = [
            // Contact main informations (base model without relations)
            contactsApi.updateContactContactsItem({
                xIdSociete,
                id: contactId.toString(),
                contactsContactJsonldContactUpdate: contactPayload,
            }),
            // Relations - Contact phones
            contactsApi.updateTelephonesContactPrescripteurContactsItem({
                xIdSociete,
                id: contactId.toString(),
                tiers: prescriberId.toString(),
                contactsContactJsonldContactWriteTelsPrescripteur: phonesPayload,
            }),
            // Contact email
            contactsApi.updateEmailsContactPrescripteurContactsItem({
                xIdSociete,
                id: contactId.toString(),
                tiers: prescriberId.toString(),
                contactsContactJsonldContactWriteEmailsPrescripteur: emailsPayload,
            }),
        ];

        Promise.allSettled(promises).then((_results) => {
            afterSubmit();
            formContext.reset();
        });
    };

    const createContact: SubmitHandler<ContactFormValues> = async (formValues) => {
        const payload = formToContactCreationPayload(formValues);
        const canCreate = prescriber && prescriberId;
        if (canCreate) {
            await prescribersApi.createPrescripteurContactContactsCollection({
                xIdSociete,
                tiers: prescriberId,
                contactsContactJsonldContactCreate: payload,
            });
            afterSubmit();
            formContext.reset();
        }
    };

    /**
     * Submit the contact form
     *
     * Format the valid payload based on form type ( creation or update )
     *
     * @param formValues - Values provided by contact form
     */
    const submitForm: SubmitHandler<ContactFormValues> = (formValues: ContactFormValues) => {
        if (isCreationForm) {
            createContact(formValues);
        } else {
            updateContact(formValues);
        }
    };

    // RENDER / Structure & style

    /** Display grid rules :
     * gridLine : always one line
     * gridHalf = : half line for high resolutions | one line for low resolution
     */
    const gridLine = {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
    };

    const gridHalf = {
        ...gridLine,
        md: 6,
        lg: 6,
    };

    /** Form elements ( FlexyForm form structures ) */

    const formHeader = {
        type: 'customItem' as const,
        sx: {
            marginTop: '0px',
        },
        name: 'info',
        renderField: () => (
            <Typography
                fontWeight={500}
                fontSize="24px"
            >
                Informations du contact
            </Typography>
        ),
    };

    const horizontalDivider = {
        type: 'customItem' as const,
        name: 'horizontalDivider',
        renderField: () => (
            <Box sx={{ marginY: '20px', opacity: 0.3 }}>
                <hr />
            </Box>
        ),
    };

    const spacer = {
        type: 'customItem' as const,
        name: 'spacer',
        renderField: () => <Box sx={{ marginY: '20px' }} />,
    };

    /**
     * Civilities options formated for select
     */
    const civilitiesOptions: SelectOptions = [
        ...civilities.map((apiCivility) => ({
            label: apiCivility.libelle ?? '( Aucune valeur )',
            value: apiCivility.id,
        })),
        { label: 'Non renseignée', value: 0 },
    ];

    /** Form sections */

    /** Section that contains inputs for names, civility ... */
    const generalInformationsSection: FormStructure[] = [
        {
            type: 'customItem',
            name: 'civilityId',
            ...gridLine,
            renderField: () => (
                <Controller
                    name="civilityId"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            value={watch(field.name)}
                            name={field.name}
                            label="Civilité"
                            options={civilitiesOptions}
                            errors={errors}
                        />
                    )}
                />
            ),
        },
        {
            type: 'text',
            name: 'lastName',
            inputlabel: 'Nom',
            ...gridHalf,
        },
        {
            type: 'text',
            name: 'firstName',
            inputlabel: 'Prénom',
            ...gridHalf,
        },
        {
            type: 'text',
            name: 'position',
            inputlabel: 'Fonction',
            ...gridLine,
        },
    ];
    /** Section that contains inputs for phones and emails */
    const contactsSection: FormStructure[] = [
        {
            type: 'text',
            name: 'mainPhone',
            inputlabel: 'Téléphone principal',
            ...gridLine,
        },
        {
            type: 'customItem',
            name: 'optionalPhones',
            ...gridLine,
            renderField: () => (
                <Box sx={{ marginTop: '7px' }}>
                    <OptionalContactList
                        type="phone"
                        context={formContext}
                    />
                </Box>
            ),
        },
        {
            type: 'text',
            name: 'mainEmail',
            inputlabel: 'E-mail principal',
            ...gridLine,
        },
        {
            type: 'customItem',
            name: 'optionalEmails',
            ...gridLine,
            renderField: () => (
                <Box sx={{ marginTop: '7px' }}>
                    <OptionalContactList
                        type="email"
                        context={formContext}
                    />
                </Box>
            ),
        },
    ];

    const contactSettingsSection: FormStructure[] = [
        {
            type: 'selectItems',
            name: 'isAdmin',
            mode: 'inline',
            inputlabel: 'Rôle',
            colInline: 6,
            items: [
                {
                    label: 'Administrateur',
                    value: true,
                },
                {
                    label: 'Collaborateur',
                    value: false,
                },
            ],
            multiple: false,
            rules: {},
        },
        spacer,
        {
            type: 'boolean',
            name: 'isMainContact',
            inputlabel: 'Contact principal',
            sx: { marginTop: '15px' },
            ...gridLine,
        },
        spacer,
        {
            type: 'boolean',
            name: 'isBillingContact',
            inputlabel: 'Contact comptabilité',
            ...gridLine,
        },
        spacer,
        {
            type: 'boolean',
            name: 'hasLeft',
            inputlabel: 'À quitté',
            inputSublabel: 'Le contact à quitté la société',
            display: !isCreationForm,
            ...gridLine,
        },
    ];

    const noteSection: FormStructure[] = [
        {
            type: 'customItem',
            name: 'notes',
            ...gridLine,
            renderField: () => (
                <>
                    <Typography
                        fontWeight={500}
                        fontSize="16px"
                    >
                        Notes
                    </Typography>
                    <TextField
                        id="note_textarea"
                        sx={{ marginTop: 2 }}
                        variant="outlined"
                        onFocus={() => onNoteFocus()}
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={25}
                        placeholder="Notes"
                        {...noteField}
                    />
                </>
            ),
        },
    ];

    const formStructure: FormStructure[] = [
        formHeader,
        ...generalInformationsSection,
        horizontalDivider,
        ...contactsSection,
        horizontalDivider,
        ...contactSettingsSection,
        horizontalDivider,
        ...noteSection,
    ];

    const wrapperStyle = {
        maxWidth: mdBreakpoint ? '100%' : '60%',
        margin: 'auto',
    };

    const submitLabel = isCreationForm ? 'Créer un nouveau contact' : 'Mettre à jour le contact';

    return (
        <Box sx={wrapperStyle}>
            <FlexyForm
                formContext={formContext as unknown as UseFormReturn<FieldValues>} // Typing issue
                formStructure={formStructure}
                onSubmit={submitForm}
                isSubmitting={isSubmitted}
                submitButton={{
                    label: submitLabel,
                }}
            />
        </Box>
    );
};

export default ContactForm;
