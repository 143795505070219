import React, { MouseEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Box, Typography } from '@mui/material';

export type FlexyMenuProps = {
    title: string;
    items: Array<string>;
    onClick: (item: string) => void;
};

const FlexyMenu: React.FunctionComponent<FlexyMenuProps> = function ({
    title,
    items,
    onClick,
}: FlexyMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // TODO: Pour faire fonctionner la navigation vers les fichiers il va falloir
    // mettre en place le modification de dossiers pour une saisie multiple.

    return (
        <Box sx={{ marginLeft: 'auto' }}>
            <Button
                id="fade-button"
                color="secondary"
                variant="outlined"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {title}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {items.length > 0 ? (
                    items.map((item) => (
                        <MenuItem
                            key={item}
                            onClick={() => {
                                onClick(item);
                                handleClose();
                            }}
                        >
                            {item}
                        </MenuItem>
                    ))
                ) : (
                    <Typography
                        typography="overline"
                        color="GrayText"
                    >
                        Pas de dossiers disponibles...
                    </Typography>
                )}
            </Menu>
        </Box>
    );
};

export default FlexyMenu;
