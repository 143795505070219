import { capitalizeFirstLetter } from '@europrocurement/l2d-utils';
import useFetchCollection, { UseFetchCollection } from '../Package/Form/useFetchCollection';

type NamedFetchActions = {
    [actionName: string]: () => Promise<unknown>;
};

type StartsWithUse = `use${string}`;
type UseFetchCollectionHooks = {
    [key: StartsWithUse]: UseFetchCollection<unknown>;
};

/** Fuction that create fetch hooks for a given list of named fetch actions */
export function createFetchActions(fetchActions: NamedFetchActions) {
    const hooks: UseFetchCollectionHooks = {};
    const actionNames = Object.keys(fetchActions);
    actionNames.forEach((actionName) => {
        const fetchAction = fetchActions[actionName];
        const capitalizedName = capitalizeFirstLetter(actionName);
        hooks[`use${capitalizedName}`] = useFetchCollection(fetchAction);
    });
    return hooks;
}
