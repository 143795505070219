import { FormFieldInput, FormValidationRule } from './types';

function getLength(value: FormFieldInput) {
    if (typeof value === 'number') {
        return value.toString().length;
    }
    return value.length;
}

function maximumLength(value: FormFieldInput, lengthValue: number): boolean {
    const length = getLength(value);
    return length <= lengthValue;
}

function minimumLength(value: FormFieldInput, lengthValue: number): boolean {
    const length = getLength(value);
    return length >= lengthValue;
}

function exactLength(value: FormFieldInput, lengthValue: number): boolean {
    const length = getLength(value);
    return length === lengthValue;
}

function onlyDigits(value: FormFieldInput): boolean {
    if (typeof value === 'number') {
        return /^\d+$/.test(value.toString());
    }
    return /^\d+$/.test(value);
}

export function minimumLengthRule(length: number, fieldName?: string): FormValidationRule {
    const errorMessage = `Le ${fieldName ?? 'champ'} doit contenir au moins ${length} caractères`;
    const validationRule = (value: string) => minimumLength(value, length) || errorMessage;
    return { minimumlLength: validationRule };
}

export function maximumLengthRule(length: number, fieldName?: string): FormValidationRule {
    const errorMessage = `Le ${
        fieldName ?? 'champ'
    } ne peut pas contenir plus de ${length} caractères`;
    const validationRule = (value: string) => maximumLength(value, length) || errorMessage;
    return { maximumlLength: validationRule };
}

export function exactLengthRule(length: number, fieldName?: string): FormValidationRule {
    const errorMessage = `Le ${fieldName ?? 'champ'} doit contenir exactement ${length} caractères`;
    const validationRule = (value: string) => exactLength(value, length) || errorMessage;
    return { exactLength: validationRule };
}

export function exactLengthDigitsRule(length: number, fieldName?: string): FormValidationRule {
    const errorMessage = `Le ${fieldName ?? 'champ'} doit être constitué de ${length} chiffres`;
    const validationRule = (value: string) =>
        (exactLength(value, length) && onlyDigits(value)) || errorMessage;
    return { exactDigitLength: validationRule };
}

export function exactOnlyDigits(fieldName?: string): FormValidationRule {
    const errorMessage = `Le ${fieldName ?? 'champ'} doit être un nombre`;
    const validationRule = (value: number) => onlyDigits(value) || errorMessage;
    return { exactDigitLength: validationRule };
}
