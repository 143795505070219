import React, { useEffect, useState } from 'react';

import { Typography, Box } from '@mui/material';

export type DataItemProps = {
    title: string;
    content: string | number;
    contentType?: 'currency';
};

const DataItem: React.FunctionComponent<DataItemProps> = function ({
    title,
    content,
    contentType,
}: DataItemProps) {
    const [contentValueFormated, setContentValueFormated] = useState<string | number>(content);

    useEffect(() => {
        if (contentType) {
            const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
            });

            switch (contentType) {
                case 'currency':
                    if (typeof content === 'string') {
                        setContentValueFormated(euro.format(Number.parseFloat(content)));
                    } else {
                        setContentValueFormated(euro.format(content));
                    }

                    break;

                default:
                    break;
            }
        }
    }, [content, contentType]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'flex-start',
                gap: 2,
            }}
            data-testid="data-item"
        >
            <Typography
                variant="body1"
                color="secondary"
                fontWeight="bold"
            >
                {title} :
            </Typography>
            <Typography variant="body1">{contentValueFormated}</Typography>
        </Box>
    );
};

export default DataItem;
