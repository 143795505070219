import React, { useCallback, useEffect, useState } from 'react';

import {
    CategorieStatsSelector,
    customizerSelector,
    factureAchatApi,
    getFactureAchatStats,
} from '@b2d/redux/RootStore';
import { CategorieStats } from '@europrocurement/l2d-domain';
import { pausedInvoiceIcon } from '@europrocurement/l2d-icons';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useLazyDispatch from '@b2d/hooks/useLazyDispatch';
import { AppDispatch } from '@b2d/redux/types';
import WidgetWithAction from './components/WidgetWithAction';

// interne

const FacturesEnERDO5: React.FunctionComponent = function () {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { xIdSociete } = useSelector(customizerSelector);

    const categoriesDataSource: DataSource<CategorieStats> =
        useSelector(CategorieStatsSelector).main;

    const lazyDispatch = useLazyDispatch({
        sliceName: categoriesDataSource.slicename,
        dataSourceName: categoriesDataSource.name,
    });

    const [countOfInvoicesAsERDO5, setCountOfInvoicesAsERDO5] = useState<string>();

    useEffect(() => {
        factureAchatApi
            .getFacturesAchatsFactureAchatCollection({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: true,
                deleted: false,
                codesRejetsCodeRejetCode: 'ERDO5',
                codesRejetsEtat: 'todo',
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setCountOfInvoicesAsERDO5(`${res.data['hydra:totalItems']}`);
                }
            });
    }, [xIdSociete]);

    const clearPreviousFilters = useCallback(() => {
        lazyDispatch({
            target: 'Filter',
            action: 'reset',
        })();
    }, [lazyDispatch]);

    const applyFilters = useCallback(() => {
        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload: {
                key: 'codesRejetsEtat',
                value: 'todo',
            },
        })();
        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload: {
                key: 'codesRejets',
                value: ['ERDO5'],
            },
        })();
    }, [lazyDispatch]);

    const beforeActionCallback = useCallback(() => {
        clearPreviousFilters();
        applyFilters();
    }, [applyFilters, clearPreviousFilters]);

    const actionCallback = useCallback(async () => {
        await dispatch(getFactureAchatStats({}));
    }, [dispatch]);

    const onActionSuccessCallback = useCallback(() => {
        navigate('/achats/stats?tab=detail');
    }, [navigate]);

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <WidgetWithAction
                title="Factures en ERDO5"
                icon={pausedInvoiceIcon}
                actionMessage="Consulter"
                actionCallback={actionCallback}
                data={countOfInvoicesAsERDO5}
                paragraph="Factures en ERDO5 :"
                color="ERDO"
                loadingMessage="Application des filtres..."
                beforeActionCallback={beforeActionCallback}
                onActionSuccessCallback={onActionSuccessCallback}
            />
        </Grid>
    );
};

export default FacturesEnERDO5;
